import React from "react";
import { Select } from "antd";

function NewSelectComponent(props) {
  const { state, questionList, onChange, questionIndex } = props;
  // Splitting the options string into an array
  const options = questionList.options
    .split(",")
    .map((option) => option.trim());

  const children = [];
  for (let i = 0; i < options.length; i++) {
    children.push(<Select.Option key={options[i]}>{options[i]}</Select.Option>);
  }

  return (
    <div key={"sub" + questionIndex} className="my-4">
      <h1>{questionList.question}</h1>
      {questionList.description != null ? (
        <h6>{questionList.description}</h6>
      ) : null}
      <Select
        className="autoComplete"
        defaultValue={
          state.answersPrefer[questionList.preferenceUId] != undefined
            ? state.answersPrefer[questionList.preferenceUId].preferencesanswer
                .trim()
                .split(",")
                .sort()
                .filter(function (el) {
                  return el != "";
                })
            : []
        }
        mode="multiple"
        style={{ width: "100%" }}
        onChange={(e) => onChange(questionList.preferenceUId, "input", e)}
        tokenSeparators={[","]}
      >
        {children}
      </Select>
      {state.activitiesError &&
        state.answersPrefer[state.activitiesArray[0].preferenceUId]
          .preferencesanswer == "" &&
        state.answersPrefer[state.keyWordsArray[0].preferenceUId]
          .preferencesanswer == "" && (
          <div className="tooltip-validation">
            <div className="tooltip-inner">{state.toolTipText}</div>
          </div>
        )}
    </div>
  );
}

export default NewSelectComponent;
