import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import NewHeader from "../../../common/header/newHeader";
function NewHomePage() {
  return (
    <div className="reloHome">
      <NewHeader />

      <div className="container">
        <div className="bannetTextView">
          <h1>
            The only Platform to <span> Manage </span> Destination Services
            <span> End to End </span>
          </h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
          </p>
          <div>
            <button type="button" className="btn me-2">
              Log In
            </button>
            <button type="button" className="btn btn-2 me-2">
              <i className="fa-solid fa-play me-2"></i> Request a Demo
            </button>
          </div>
        </div>
      </div>
      <div className="bg-relo text-center py-5">
        <div className="my-sm-4">
          <h2>Tailored Support for All</h2>
          <p>
            Empowering Companies. Supporting Consultants. Guiding Individuals
          </p>

          <div className="tials">
            <div className="box">
              <img src="assets/images/companies.svg" alt="" />
              <h3>DS Companies</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <a href="">
                Learn More <i className="fa-regular fa-angle-right ms-2"></i>
              </a>
            </div>
            <div className="box">
              <img src="assets/images/consultants.svg" alt="" />
              <h3>DS Consultants</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <a href="">
                Learn More <i className="fa-regular fa-angle-right ms-2"></i>
              </a>
            </div>
            <div className="box">
              <img src="assets/images/individuals.svg" alt="" />
              <h3>Relocating Individuals</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <a href="">
                Learn More <i className="fa-regular fa-angle-right ms-2"></i>
              </a>
            </div>
          </div>
          <div>
            <button type="button" className="btn me-2">
              <i className="fa-solid fa-play me-2"></i> Request a Demo
            </button>
          </div>
        </div>
      </div>
      <div className="bg-dark py-5">
        <h1 className="text-white py-5 text-center">
          Our Patented Software, <span>Your Relocation Advantage</span>
        </h1>
      </div>
      <div className="bg-relo text-center py-5">
        <div className="my-sm-4 container">
          <h2>Key Features That Make a Difference</h2>
          <div className="tials slider">
            <OwlCarousel
              className="owl-theme owl-carousel btn-left"
              {...{
                autoplay: true,
                center: true,
                loop: true,
                nav: true,
                margin: 30,
                dots: false,
                navContainerClass: "owl-buttons text-right",
                navText: [
                  "<i class='fa-solid fa-angle-left'></i>",
                  "<i class='fa-solid fa-angle-right'></i>",
                ],
                responsive: {
                  0: {
                    items: 1,
                  },

                  600: {
                    items: 2,
                  },

                  1024: {
                    items: 3,
                  },
                },
              }}
            >
              <div className="box">
                <img
                  src="assets/images/area-info-icon.svg"
                  alt=""
                  style={{ width: "50px" }}
                />
                <h3>Area Information 1</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
                  dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <a href="">
                  {" "}
                  <i className="fa-solid fa-circle-plus"></i>
                </a>
              </div>
              <div className="box">
                <img
                  src="assets/images/area-info-icon.svg"
                  alt=""
                  style={{ width: "50px" }}
                />
                <h3>Area Information 2</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
                  dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <a href="">
                  {" "}
                  <i className="fa-solid fa-circle-plus"></i>
                </a>
              </div>
              <div className="box">
                <img
                  src="assets/images/area-info-icon.svg"
                  alt=""
                  style={{ width: "50px" }}
                />
                <h3>Area Information 3</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
                  dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <a href="">
                  {" "}
                  <i className="fa-solid fa-circle-plus"></i>
                </a>
              </div>
              <div className="box">
                <img
                  src="assets/images/area-info-icon.svg"
                  alt=""
                  style={{ width: "50px" }}
                />
                <h3>Area Information 4</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
                  dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <a href="">
                  {" "}
                  <i className="fa-solid fa-circle-plus"></i>
                </a>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewHomePage;
