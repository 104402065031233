// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!./styles.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js!./fonts.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../../node_modules/css-loader/dist/cjs.js!./common.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../../node_modules/css-loader/dist/cjs.js!./resposive.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* All Css files */



`, "",{"version":3,"sources":["webpack://./public/assets/css/allstyles.css"],"names":[],"mappings":"AAAA,kBAAkB","sourcesContent":["/* All Css files */\r\n@import url(\"styles.css\");\r\n@import url(\"fonts.css\");\r\n@import url(\"common.css\");\r\n@import url(\"resposive.css\");\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
