import { Spin, Popover, Progress } from "antd";
import Header from "../../../common/header/Header";
import Footer from "../../../common/footer/Footer";
//import "../../Transferee/cityLanding/citysLanding.css";
//import "./myProperties.css";
import { useState, useEffect } from "react";
import WalkScore from "../../../common/walkscore";
import AppController from "../../../controllers/appController";
import GoogleApiWrapper from "../../../controllers/mapController1";
// import Schools from "../../Transferee/cityLanding/schoolsSearch";
// import Poi from "../../../common/";
import Weather from "../../../common/weatherNewDesign";
import NewWeather from "../../../common/weather";
//import TavelTimeMap from "../citysLanding/TravelTimeMap";
import PublicTransit from "../../Transferee/cityLanding/publicTransit";
import Mapview from "../../../common/mapview";
//import DoughnutChart from "../citysLanding/DoughnutChart";
import apiServices from "../../../../services/apiServices";
//import RentalChart from "../citysLanding/rentalsChart";
//import ApexChart from "../citysLanding/apexChart";
//import SingleBedRoomChart from "../citysLanding/singleBedroomChart";
import moment from "moment";
//import GreatSchools from "../../../common/greatSchools";
import SchoolsSearch from "../../Transferee/cityLanding/schoolsSearch";
import SchoolSearchMapBox from "../../Transferee/cityLanding/schoolSearchMapbox";
import PoiSearch from "../../Transferee/cityLanding/poinOfInterestsSearch";
import OwlCarousel from "react-owl-carousel";
//import Rater from "react-rater";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import LoginStore from "../../../../services/thirdPartyService";
import { confirmAlert } from "react-confirm-alert";
import ThirdPartyApiService from "../../../../services/thirdPartyService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWalking,
  faBicycle,
  faTrain,
} from "@fortawesome/fontawesome-free-solid";

const MyPropertyDetails = () => {
  let _isMounted = false;

  const [state, setState] = useState({
    myPropertyDetails: {},
    data: {
      latitude: "",
      longitude: "",
      state_name: "CA",
      city_name: "San Francisco",
      city: "San Francisco",
      address: "185 Channel Street",
      greatSchools: [],
    },
    poi: [],
    poiCategories: [],
    isSuccessPoi: false,
    walkScoreDetails: [],
    travelMapLoading: false,
    lines: "",
    communityObject: {
      latitude: 37.7739852,
      longitude: -122.3912407,
    },
    ageLivingPelople: [],
    HomeValueForecast: [],
    crimeAssult: [],
    answersPrefer: [],
    commuteReport: [],
    rentBedRooms: [],
    bedroomData: [],
    citiesLoader: true,
    bedroomLabels: [],
    singleFamilyData: [],
    singleFamilyLabels: [],
    allMultiHomesData: [],
    allMultiHomesLabels: [],
    multiHomeRentalsData: [],
    multiHomeRentalsLabels: [],
    homeValues: [
      "1bedroom",
      "2bedroom",
      "3bedroom",
      "4bedroom",
      "5BedroomOrMore",
    ],
    selectedHomeValue: "1bedroom",
    isChanged: false,
    isBedRoom: true,
    rentalLabels: [],
    rentalData: [],
    homeLoader: false,
    rentalValue: "AllHomesPlusMultifamily",
    allHomesValue: "",
    latitude: 37.7739852,
    longitude: -122.3912407,
    nearByNeighbourHoods: [],
    neighbourhoodLoader: false,
    nearByCities: [],
    nearByCitiesWithStates: [],
    greatSchools: [],
    transitDetails: [],
    soundScoreDetails: {
      result: [],
    },
    ispoi: false,
    loader: false,
    explore: false,
    isProperty: true,
    WalkscoreLoader: false,
    items: "",
    tabIttems: "",
    startPosition: "0",
    viewNeighborhoodGenerate: false,
    ProgressValue: 0,
    SchoolDisplay: false,
    ViewStatus: "overview",
  });
  useEffect(() => {
    const fetch = async () => {
      $(".modal-backdrop").remove(), $("body").removeAttr("style");
      const script = document.createElement("script");
      script.src =
        "https://apicdn.walkscore.com/api/v1/traveltime_widget/js?wsid=8daf3ec7b708129023cb6ebcf8e99b56";
      script.async = true;
      document.body.appendChild(script);
      let myPropertyDetails = JSON.parse(
        await localStorage.getItem("myPropertyDetails")
      );
      if (myPropertyDetails == null || myPropertyDetails == undefined) {
        window.location.href = "/my_property";
      } else {
        getSingleBedRoomData("1");
        getRentalsFamilyResult("AllHomesPlusMultifamily");
        let communityData = {
          latitude: Number(myPropertyDetails.latitude),
          longitude: Number(myPropertyDetails.longitude),
          state_name: myPropertyDetails.state,
          city_name: myPropertyDetails.city,
          city: myPropertyDetails.city,
        };
        let lines = await AppController.busrailslines(communityData);
        setState((prev) => ({
          ...prev,
          data: {
            latitude: Number(myPropertyDetails.latitude),
            longitude: Number(myPropertyDetails.longitude),
            state_name: myPropertyDetails.state,
            city_name: myPropertyDetails.city,
            city: myPropertyDetails.city,
            address: myPropertyDetails.address,
          },
          communityObject: {
            latitude: Number(myPropertyDetails.latitude),
            longitude: Number(myPropertyDetails.longitude),
          },
          lines: lines,
          latitude: communityData.latitude,
          longitude: communityData.longitude,
        }));
      }

      let neighbourhoodScountResult = await AppController.neighbourhoodScount(
        myPropertyDetails
      );

      if (neighbourhoodScountResult.success == true) {
        let neighbourhoodsDataResult =
          neighbourhoodScountResult.data.data_products;
        let ageLivingPelople = [];
        if (
          neighbourhoodsDataResult["neighborhood.demographics"] != undefined
        ) {
          let DemographicsAge =
            neighbourhoodsDataResult["neighborhood.demographics"];

          ageLivingPelople.push(DemographicsAge["pctage_0_4"]);
          ageLivingPelople.push(DemographicsAge["pctage_5_17"]);
          ageLivingPelople.push(DemographicsAge["pctage_0_17"]);
          ageLivingPelople.push(DemographicsAge["pctage_18_29"]);
          ageLivingPelople.push(DemographicsAge["pctage_30_44"]);
          ageLivingPelople.push(DemographicsAge["pctage_45_64"]);
          ageLivingPelople.push(DemographicsAge["pctage_65_up"]);
        }
        let HomeValueForecast = [];
        let rentBedRooms = [];
        if (neighbourhoodsDataResult["neighborhood.real_estate"] != undefined) {
          let realEstate = neighbourhoodsDataResult["neighborhood.real_estate"];
          HomeValueForecast.push(realEstate["mhv_pred_1q"]);
          HomeValueForecast.push(realEstate["mhv_pred_2q"]);
          HomeValueForecast.push(realEstate["mhv_pred_3q"]);
          HomeValueForecast.push(realEstate["mhv_pred_4q"]);
          HomeValueForecast.push(realEstate["mhv_pred_5q"]);
          HomeValueForecast.push(realEstate["mhv_pred_6q"]);
          HomeValueForecast.push(realEstate["mhv_pred_7q"]);
          HomeValueForecast.push(realEstate["mhv_pred_8q"]);
          HomeValueForecast.push(realEstate["mhv_pred_9q"]);
          HomeValueForecast.push(realEstate["mhv_pred_10q"]);
          HomeValueForecast.push(realEstate["mhv_pred_11q"]);
          HomeValueForecast.push(realEstate["mhv_pred_1y"]);
          HomeValueForecast.push(realEstate["mhv_pred_2y"]);
          HomeValueForecast.push(realEstate["mhv_pred_3y"]);

          rentBedRooms.push(realEstate["rent_br0"]);
          rentBedRooms.push(realEstate["rent_br1"]);
          rentBedRooms.push(realEstate["rent_br2"]);
          rentBedRooms.push(realEstate["rent_br3"]);
        }
        let crimeReport = [];
        if (neighbourhoodsDataResult["city.crime"] != undefined) {
          let crimeAssult = neighbourhoodsDataResult["city.crime"];
          crimeReport.push(crimeAssult["pt_assault"]);
          crimeReport.push(crimeAssult["pt_murder"]);
          crimeReport.push(crimeAssult["pt_rape"]);
          crimeReport.push(crimeAssult["pt_robbery"]);
          crimeReport.push(crimeAssult["pt_violent"]);
        }
        let commuteReport = [];
        if (
          neighbourhoodsDataResult["neighborhood.econ_and_empl"] != undefined
        ) {
          let commuteAnalasys =
            neighbourhoodsDataResult["neighborhood.econ_and_empl"];
          commuteReport.push(commuteAnalasys["commtime15_pct"]);
          commuteReport.push(commuteAnalasys["commtime30_pct"]);
          commuteReport.push(commuteAnalasys["commtime45_pct"]);
          commuteReport.push(commuteAnalasys["commtime60_pct"]);
          commuteReport.push(commuteAnalasys["commtime90_pct"]);
        }

        setState((prev) => ({
          ...prev,
          ageLivingPelople: ageLivingPelople,
          HomeValueForecast: HomeValueForecast,
          crimeAssult: crimeReport,
          commuteReport: commuteReport,
          rentBedRooms: rentBedRooms,
        }));
      }
    };
    fetch();
  }, []);

  const updateCarouselPosition = (object) => {
    if (object.item.index != state.startPosition) {
      setState((prev) => ({ ...prev, startPosition: object.item.index }));
    }
  };

  useEffect(() => {
    const fetch = async () => {
      let items =
        parseInt(screen.availWidth) <= 767
          ? 1
          : parseInt(screen.availWidth) <= 991
          ? 1
          : 1;
      setState((prev) => ({ ...prev, items: items }));
      let tabItems =
        parseInt(screen.availWidth) <= 767
          ? 1
          : parseInt(screen.availWidth) <= 991
          ? 1
          : 1;
      setState((prev) => ({ ...prev, tabItems: tabItems }));
      setState((prev) => ({
        ...prev,
        loader: true,
        WalkscoreLoader: true,
      }));
      await localStorage.setItem("isProperty", state.isProperty);
      let myPropertyDetails = JSON.parse(
        await localStorage.getItem("myPropertyDetails")
      );

      if (
        myPropertyDetails.pdfupdatedat != null &&
        myPropertyDetails.pdfupdatedat != "" &&
        myPropertyDetails.pdfupdatedat != undefined
      ) {
        let date1 = new Date();
        let date2 = new Date(
          moment(myPropertyDetails.pdfupdatedat).format("MM/DD/YYYY")
        );
        let diffTime = Math.abs(date2 - date1);
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays >= 28) {
          myPropertyDetails.pdffilepath = "";
        }
        //myPropertyDetails.pdffilepath = ""
        //console.log(diffTime + " milliseconds");
        // console.log(diffDays + " days", myPropertyDetails);
      }

      setState((prev) => ({ ...prev, myPropertyDetails: myPropertyDetails }));
      if (myPropertyDetails != null || myPropertyDetails != undefined) {
        setState((prev) => ({
          ...prev,
          data: {
            latitude: Number(myPropertyDetails.latitude),
            longitude: Number(myPropertyDetails.longitude),
            state_name: myPropertyDetails.state,
            city_name: myPropertyDetails.city,
            city: myPropertyDetails.city,
            address: myPropertyDetails.address,
          },
          communityObject: {
            latitude: Number(myPropertyDetails.latitude),
            longitude: Number(myPropertyDetails.longitude),
          },

          city: myPropertyDetails.city,
          latitude: Number(myPropertyDetails.latitude),
          longitude: Number(myPropertyDetails.longitude),
        }));
        let getSessionData = await LoginStore.getLoginInfo({});
        console.log(getSessionData, "getSessionData");
        let userObject = {
          transfereeuniqeuid:
            getSessionData.hasOwnProperty("istemporary") &&
            getSessionData.istemporary !== ""
              ? getSessionData.userUniqueId
              : myPropertyDetails.transfereeUId,
          istemporary:
            getSessionData.hasOwnProperty("istemporary") &&
            getSessionData.istemporary !== ""
              ? getSessionData.istemporary
              : false,
        };
        let getQuestionList = await apiServices.GetPreferencesList(userObject);
        let userPreferanceObject = {
          transfereeuniqeuid:
            getSessionData.hasOwnProperty("istemporary") &&
            getSessionData.istemporary !== ""
              ? getSessionData.userUniqueId
              : myPropertyDetails.transfereeUId,
          istemporary:
            getSessionData.hasOwnProperty("istemporary") &&
            getSessionData.istemporary !== ""
              ? getSessionData.istemporary
              : false,
        };
        let userSavedPreferances = await apiServices.GetTransfereePreferences(
          userPreferanceObject
        );
        if (userSavedPreferances.error == 0) {
          let userAnswerPreferanceObject =
            userSavedPreferances.message.preferences;
          if (userAnswerPreferanceObject != undefined) {
            let answersPrefer = [];
            _.forEach(userAnswerPreferanceObject, function (answerObject) {
              answersPrefer[answerObject.preferencesmasteruniqueid] =
                answerObject;
            });
            getCommuteTime(
              answersPrefer,
              myPropertyDetails,
              getQuestionList,
              getSessionData
            );
            setState((prev) => ({ ...prev, answersPrefer: answersPrefer }));
          }
        }

        // jQuery(function ($) {
        //     $.getJSON("http://getnearbycities.geobytes.com/GetNearbyCities?callback=?&radius=4000&latitude=" + myPropertyDetails.latitude + "&longitude=" + myPropertyDetails.longitude, (res) => {
        //         setState((prev)=>({...prev, nearByCities: res });

        //         var nearbycities = state.nearByCities.map(function (item) {
        //             return {
        //                 city: item[1],
        //                 state: item[2],
        //                 latitude: item[8],
        //                 longitude: item[10]
        //             }
        //         })
        //         setState((prev)=>({...prev, nearByCitiesWithStates: nearbycities, citiesLoader: false })
        //         // $.getJSON("https://api.foursquare.com/v2/venues/explore?&client_id=EIIQYCCRMHK5OWTDW41G1UUPDRVO0OC0LYYTM2QD3YRBHBX5&client_secret=AF2SB2JFJ53YPJQDFAN0FUM5ITAQEWWKOUNVOHAU1YRJBPL4&v=20190921&ll=" + myPropertyDetails.latitude + "," + myPropertyDetails.longitude + "&radius=650&limit=10&categoryId=4f2a25ac4b909258e854f55f", (neighbourhoods) => {
        //         //     setState((prev)=>({...prev, nearByNeighbourHoods: neighbourhoods.response.groups[0].items, neighbourhoodLoader: false })

        //         // })

        //     })

        // }.bind(this));
        let response = mapView([
          {
            latitude: state.data.latitude,
            longitude: state.data.longitude,
          },
        ]);
      } else {
        location.href = "my_property";
      }
      walkAndSchoolScore(myPropertyDetails);
    };
    fetch();
  }, []);

  const getCommuteTime = async (
    answersPrefer,
    myPropertyDetails,
    getQuestionList,
    getSessionData
  ) => {
    if (getQuestionList.message.preferencesList != undefined) {
      let questionList = getQuestionList.message.preferencesList;
      let workAddressArray = _.filter(questionList, function (o) {
        return o.question == "Work address";
      });
      let Whoismoving = _.filter(questionList, function (o) {
        return o.question == "Who is moving?";
      });
      let commuteTimeArray = _.filter(questionList, function (o) {
        return o.question == "Commute time";
      });
      await localStorage.setItem(
        "workAddress",
        JSON.stringify(answersPrefer[workAddressArray[0].preferenceUId])
      );
      await localStorage.setItem(
        "commuteTime",
        JSON.stringify(answersPrefer[commuteTimeArray[0]?.preferenceUId])
      );
      let whoismovingObject = answersPrefer[Whoismoving[0].preferenceUId];

      if (whoismovingObject?.preferencesanswer == "FAMILY") {
        setState((prev) => ({ ...prev, SchoolDisplay: true }));
      }
    }
    let commuteTime = "undefined";
    if (
      localStorage.getItem("commuteTime") != "undefined" &&
      localStorage.getItem("commuteTime") != null &&
      localStorage.getItem("commuteTime") != ""
    ) {
      commuteTime = JSON.parse(await localStorage.getItem("commuteTime"));
    }

    let commuteTimeInMin =
      commuteTime == "undefined"
        ? 15
        : commuteTime.preferencesanswer.split(",")[1];
    let commuteRadius = (commuteTimeInMin * 45) / 60;

    let reqObj = {
      latitude: myPropertyDetails.latitude,
      longitude: myPropertyDetails.longitude,
      radious: Math.round(commuteRadius),
      transfereeuid:
        getSessionData.hasOwnProperty("istemporary") &&
        getSessionData.istemporary !== ""
          ? getSessionData.userUniqueId
          : myPropertyDetails.transfereeUId,
    };
    let nearbyCity = await apiServices.GetAllCities(reqObj);
    console.log(nearbyCity, "nearbyCity");
    setState((prev) => ({
      ...prev,
      nearByCities: nearbyCity.message.citieslist,
    }));
    //console.log(state.nearByCities.length, "state.nearByCities.length")

    nearByCitiesWithStates(nearbyCity.message.citieslist);
  };

  const nearByCitiesWithStates = (nearbyCity) => {
    if (Array.isArray(nearbyCity) && nearbyCity.length > 1) {
      let nearbycities = nearbyCity.map(function (item) {
        item.city = item.city;
        item.state = item.state_id;
        item.latitude = item.lat;
        item.longitude = item.lng;
        item.imageUrl = item.imageUrl;
        item.cityHighlights = item.cityHighlights;
        item.demoGraphics = item.demoGraphics;
        item.cityDetails = item.cityDetails;
        item.WalkScore = item.WalkScore;
        item.BikeScore = item.BikeScore;
        item.miles = Math.floor(item.distanc_in_mils);

        return item;
      });
      console.log(nearbycities, "nearbycities");
      setState((prev) => ({
        ...prev,
        nearByCitiesWithStates: nearbycities,
        citiesLoader: false,
      }));
    }
  };

  const walkAndSchoolScore = async (myPropertyDetails) => {
    let getSessionData = await LoginStore.getLoginInfo({});
    let data = {
      latitude: Number(myPropertyDetails.latitude),
      longitude: Number(myPropertyDetails.longitude),
      state_name: myPropertyDetails.state,
      city_name: myPropertyDetails.city,
      city: myPropertyDetails.city,
      address: myPropertyDetails.address,
    };
    const greatSchools = await AppController.greatSchools(data);
    setState((prev) => ({ ...prev, greatSchools: greatSchools }));
    const walkScoreDetails_original = await AppController.wallsckoreFun(data);

    const walkScoreDetails =
      walkScoreDetails_original != undefined
        ? _.filter(walkScoreDetails_original, function (o) {
            return o.Score != null;
          })
        : [];

    const weather = await AppController.weather(data);
    const weather2 = await AppController.weather2(data);

    var results = weather.list.map(function (el) {
      var o = Object.assign({}, el);
      o.date = o.dt_txt.split(" ")[0];
      return o;
    });
    var data1 = _.chain(results)
      .groupBy("date")
      .map(function (currentItem) {
        let minTEMP = _.minBy(currentItem, function (o) {
          return o.main.temp_min;
        });
        let maxTEMP = _.maxBy(currentItem, function (o) {
          return o.main.temp_max;
        });
        return {
          weatherData: currentItem[0],
          weatherDataMax: maxTEMP.main.temp_max,
          weatherDataMin: minTEMP.main.temp_min,
        };
      })
      .value();
    setState((prev) => ({
      ...prev,
      weather: data1.splice(1, 4),
      weather_current: weather2,
      weather_currentCityDetails: weather.city,
    }));

    const poi = await AppController.poi(data);
    let Categories = [];
    poi.results.forEach((element) => {
      for (let i = 0; i < element.types.length; i++) {
        if (Categories.indexOf(element.types[i]) === -1) {
          Categories.push(element.types[i]);
        }
      }
    });
    setState((prev) => ({ ...prev, poiCategories: Categories }));
    setState((prev) => ({
      ...prev,
      walkScoreDetails,
      WalkscoreLoader: false,
      poi: poi.results,
      isSuccessPoi: poi.isSuccess,
    }));
    await AppController.logData(
      "Overview",
      "PropertyPage",
      location.pathname,
      getSessionData.userUniqueId,
      ""
    );
    const soundScoreDetails = await AppController.soundScoreDada(data);
    const transitDetails = await AppController.transitDada(data);
    setState((prev) => ({
      ...prev,
      transitDetails,
      soundScoreDetails,
      loader: false,
    }));
  };
  const changeStatus = async (typeMenu) => {
    let getSessionData = await LoginStore.getLoginInfo({});
    if (typeMenu == "poi") {
      setState((prev) => ({
        ...prev,
        ispoi: true,
        explore: false,
        ViewStatus: typeMenu,
      }));
      await AppController.logData(
        "Points of Interest",
        "PropertyPage",
        location.pathname,
        getSessionData.userUniqueId,
        ""
      );
    }
    if (typeMenu == "schools") {
      setState((prev) => ({
        ...prev,
        ispoi: false,
        explore: false,
        ViewStatus: typeMenu,
      }));
      await AppController.logData(
        "Schools",
        "PropertyPage",
        location.pathname,
        getSessionData.userUniqueId,
        ""
      );
    }
    if (typeMenu == "explore") {
      setState((prev) => ({
        ...prev,
        ispoi: false,
        explore: true,
        ViewStatus: typeMenu,
      }));
      await AppController.logData(
        "Explore the Area",
        "PropertyPage",
        location.pathname,
        getSessionData.userUniqueId,
        ""
      );
    }
    if (typeMenu == "transit") {
      setState((prev) => ({
        ...prev,
        ispoi: false,
        travelMapLoading: true,
        explore: false,
        ViewStatus: typeMenu,
      }));
      await AppController.logData(
        "Commute",
        "PropertyPage",
        location.pathname,
        getSessionData.userUniqueId,
        ""
      );
    }
    if (typeMenu == "overview") {
      setState((prev) => ({
        ...prev,
        ispoi: false,
        travelMapLoading: true,
        explore: false,
        ViewStatus: typeMenu,
      }));
      await AppController.logData(
        "Overview",
        "PropertyPage",
        location.pathname,
        getSessionData.userUniqueId,
        ""
      );
    }
  };
  function mapView(data) {
    // GoogleApiWrapper.initExploreMap(data, true);
  }
  const getSingleBedRoomData = async (value) => {
    setState((prev) => ({
      ...prev,
      bedroomData: [],
      bedroomLabels: [],
      homeLoader: true,
    }));

    let myPropertyDetails = JSON.parse(
      await localStorage.getItem("myPropertyDetails")
    );

    let req = {
      zipcode: "",
      cityname:
        myPropertyDetails.city.charAt(0).toUpperCase() +
        myPropertyDetails.city.slice(1),
      roomtype: value,
    };
    let bedroomResult = await apiServices.ListAllSingleBedroomByZip(req);
    let cityData = JSON.parse(bedroomResult.message.list);
    if (cityData.length > 0) {
      let cityObj = cityData[0];
      // if (cityObj.length > 0) {
      delete cityObj.RegionID;
      delete cityObj.RegionName;
      delete cityObj.State;
      delete cityObj.Metro;
      delete cityObj.CountyName;
      delete cityObj.SizeRank;
      delete cityObj.City;
      let obj = cityObj;
      let keys = [2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020];
      let yearsRage = _.range(2011, 2021, 1);
      let graphValues = [];
      _.forEach(yearsRage, function (yearValue) {
        if (yearValue == 2020) {
          graphValues.push(
            Math.round(
              ((isNaN(parseInt(obj[yearValue + "-01"]))
                ? 0
                : parseInt(obj[yearValue + "-01"])) +
                (isNaN(parseInt(obj[yearValue + "-02"]))
                  ? 0
                  : parseInt(obj[yearValue + "-02"]))) /
                2
            )
          );
        } else {
          graphValues.push(
            Math.round(
              (isNaN(parseInt(obj[yearValue + "-01"]))
                ? 0
                : parseInt(obj[yearValue + "-01"]) +
                  (isNaN(parseInt(obj[yearValue + "-02"]))
                    ? 0
                    : parseInt(obj[yearValue + "-02"])) +
                  (isNaN(parseInt(obj[yearValue + "-03"]))
                    ? 0
                    : parseInt(obj[yearValue + "-03"])) +
                  (isNaN(parseInt(obj[yearValue + "-04"]))
                    ? 0
                    : parseInt(obj[yearValue + "-04"])) +
                  (isNaN(parseInt(obj[yearValue + "-05"]))
                    ? 0
                    : parseInt(obj[yearValue + "-05"])) +
                  (isNaN(parseInt(obj[yearValue + "-06"]))
                    ? 0
                    : parseInt(obj[yearValue + "-06"])) +
                  (isNaN(parseInt(obj[yearValue + "-07"]))
                    ? 0
                    : parseInt(obj[yearValue + "-07"])) +
                  (isNaN(parseInt(obj[yearValue + "-08"]))
                    ? 0
                    : parseInt(obj[yearValue + "-08"])) +
                  (isNaN(parseInt(obj[yearValue + "-09"]))
                    ? 0
                    : parseInt(obj[yearValue + "-09"])) +
                  (isNaN(parseInt(obj[yearValue + "-10"]))
                    ? 0
                    : parseInt(obj[yearValue + "-10"])) +
                  (isNaN(parseInt(obj[yearValue + "-11"]))
                    ? 0
                    : parseInt(obj[yearValue + "-11"])) +
                  (isNaN(parseInt(obj[yearValue + "-12"]))
                    ? 0
                    : parseInt(obj[yearValue + "-12"]))) / 12
            )
          );
        }
      });
      setState((prev) => ({
        ...prev,
        bedroomData: graphValues,
        bedroomLabels: keys,
        homeLoader: false,
      }));
    }
  };
  const getRentalsFamilyResult = async (type) => {
    setState((prev) => ({
      ...prev,
      rentalData: [],
      rentalLabels: [],
      rentalValue: type,
    }));

    let myPropertyDetails = JSON.parse(
      await localStorage.getItem("myPropertyDetails")
    );

    let req = {
      zipcode: "",
      cityname:
        myPropertyDetails.city.charAt(0).toUpperCase() +
        myPropertyDetails.city.slice(1),
      listtype: type,
    };
    let SingleFamilyResult = await apiServices.ListAllSingleFamilyByZip(req);
    let cityData = JSON.parse(SingleFamilyResult.message.list);
    if (cityData.length > 0) {
      let cityObj = cityData[0];
      delete cityObj.RegionID;
      delete cityObj.RegionName;
      delete cityObj.State;
      delete cityObj.Metro;
      delete cityObj.CountyName;
      delete cityObj.SizeRank;
      delete cityObj.City;
      let obj = cityObj;
      let keys = [2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020];
      let yearsRage = _.range(2011, 2021, 1);
      let graphValues = [];
      _.forEach(yearsRage, function (yearValue) {
        if (yearValue == 2020) {
          let duplica = [];
          duplica.push(
            Math.round(
              ((isNaN(parseInt(obj[yearValue + "-01"]))
                ? 0
                : parseInt(obj[yearValue + "-01"])) +
                (isNaN(parseInt(obj[yearValue + "-02"]))
                  ? 0
                  : parseInt(obj[yearValue + "-02"]))) /
                2
            )
          );
          console.log(obj[yearValue + "-02"]);
          if (
            obj[yearValue + "-02"] != undefined &&
            obj[yearValue + "-02"] != undefined
          ) {
            graphValues.push(
              Math.round(
                ((isNaN(parseInt(obj[yearValue + "-01"]))
                  ? 0
                  : parseInt(obj[yearValue + "-01"])) +
                  (isNaN(parseInt(obj[yearValue + "-02"]))
                    ? 0
                    : parseInt(obj[yearValue + "-02"]))) /
                  2
              )
            );
          } else {
            graphValues.push(
              (isNaN(parseInt(obj[yearValue + "-01"]))
                ? 0
                : parseInt(obj[yearValue + "-01"])) +
                (isNaN(parseInt(obj[yearValue + "-02"]))
                  ? 0
                  : parseInt(obj[yearValue + "-02"]))
            );
          }
          if (duplica.includes(0)) {
            keys = _.filter(keys, function (o) {
              return o != yearValue;
            });
          }
        } else {
          let duplicat = [];
          duplicat.push(
            Math.round(
              (isNaN(parseInt(obj[yearValue + "-01"]))
                ? 0
                : parseInt(obj[yearValue + "-01"]) +
                  (isNaN(parseInt(obj[yearValue + "-02"]))
                    ? 0
                    : parseInt(obj[yearValue + "-02"])) +
                  (isNaN(parseInt(obj[yearValue + "-03"]))
                    ? 0
                    : parseInt(obj[yearValue + "-03"])) +
                  (isNaN(parseInt(obj[yearValue + "-04"]))
                    ? 0
                    : parseInt(obj[yearValue + "-04"])) +
                  (isNaN(parseInt(obj[yearValue + "-05"]))
                    ? 0
                    : parseInt(obj[yearValue + "-05"])) +
                  (isNaN(parseInt(obj[yearValue + "-06"]))
                    ? 0
                    : parseInt(obj[yearValue + "-06"])) +
                  (isNaN(parseInt(obj[yearValue + "-07"]))
                    ? 0
                    : parseInt(obj[yearValue + "-07"])) +
                  (isNaN(parseInt(obj[yearValue + "-08"]))
                    ? 0
                    : parseInt(obj[yearValue + "-08"])) +
                  (isNaN(parseInt(obj[yearValue + "-09"]))
                    ? 0
                    : parseInt(obj[yearValue + "-09"])) +
                  (isNaN(parseInt(obj[yearValue + "-10"]))
                    ? 0
                    : parseInt(obj[yearValue + "-10"])) +
                  (isNaN(parseInt(obj[yearValue + "-11"]))
                    ? 0
                    : parseInt(obj[yearValue + "-11"])) +
                  (isNaN(parseInt(obj[yearValue + "-12"]))
                    ? 0
                    : parseInt(obj[yearValue + "-12"]))) / 12
            )
          );

          graphValues.push(
            Math.round(
              (isNaN(parseInt(obj[yearValue + "-01"]))
                ? 0
                : parseInt(obj[yearValue + "-01"]) +
                  (isNaN(parseInt(obj[yearValue + "-02"]))
                    ? 0
                    : parseInt(obj[yearValue + "-02"])) +
                  (isNaN(parseInt(obj[yearValue + "-03"]))
                    ? 0
                    : parseInt(obj[yearValue + "-03"])) +
                  (isNaN(parseInt(obj[yearValue + "-04"]))
                    ? 0
                    : parseInt(obj[yearValue + "-04"])) +
                  (isNaN(parseInt(obj[yearValue + "-05"]))
                    ? 0
                    : parseInt(obj[yearValue + "-05"])) +
                  (isNaN(parseInt(obj[yearValue + "-06"]))
                    ? 0
                    : parseInt(obj[yearValue + "-06"])) +
                  (isNaN(parseInt(obj[yearValue + "-07"]))
                    ? 0
                    : parseInt(obj[yearValue + "-07"])) +
                  (isNaN(parseInt(obj[yearValue + "-08"]))
                    ? 0
                    : parseInt(obj[yearValue + "-08"])) +
                  (isNaN(parseInt(obj[yearValue + "-09"]))
                    ? 0
                    : parseInt(obj[yearValue + "-09"])) +
                  (isNaN(parseInt(obj[yearValue + "-10"]))
                    ? 0
                    : parseInt(obj[yearValue + "-10"])) +
                  (isNaN(parseInt(obj[yearValue + "-11"]))
                    ? 0
                    : parseInt(obj[yearValue + "-11"])) +
                  (isNaN(parseInt(obj[yearValue + "-12"]))
                    ? 0
                    : parseInt(obj[yearValue + "-12"]))) / 12
            )
          );
          if (duplicat.includes(0)) {
            keys = _.filter(keys, function (o) {
              return o != yearValue;
            });
          }
          console.log("keys", keys);
        }
      });
      let values = _.filter(graphValues, function (o) {
        return o != 0;
      });

      setState((prev) => ({ ...prev, rentalData: values, rentalLabels: keys }));
    }
  };
  const getSingleFamilyResult = async (type) => {
    setState((prev) => ({
      ...prev,
      singleFamilyData: [],
      singleFamilyLabels: [],
      isBedRoom: false,
      homeLoader: true,
      allHomesValue: type,
    }));
    let myPropertyDetails = JSON.parse(
      await localStorage.getItem("myPropertyDetails")
    );

    let req = {
      zipcode: "",
      cityname:
        myPropertyDetails.city.charAt(0).toUpperCase() +
        myPropertyDetails.city.slice(1),
      listtype: type,
    };

    let SingleFamilyResult = await apiServices.ListAllSingleFamilyByZip(req);

    let cityData = JSON.parse(SingleFamilyResult.message.list);
    if (cityData.length > 0) {
      let cityObj = cityData[0];
      // if (cityObj.length > 0) {
      delete cityObj.RegionID;
      delete cityObj.RegionName;
      delete cityObj.State;
      delete cityObj.Metro;
      delete cityObj.CountyName;
      delete cityObj.SizeRank;
      delete cityObj.City;
      let obj = cityObj;
      let keys = [
        2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011,
        2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020,
      ];
      let yearsRage = _.range(2000, 2021, 1);
      let graphValues = [];
      _.forEach(yearsRage, function (yearValue) {
        if (yearValue == 2020) {
          let duplica = [];
          duplica.push(
            Math.round(
              ((isNaN(parseInt(obj[yearValue + "-01"]))
                ? 0
                : parseInt(obj[yearValue + "-01"])) +
                (isNaN(parseInt(obj[yearValue + "-02"]))
                  ? 0
                  : parseInt(obj[yearValue + "-02"]))) /
                2
            )
          );
          console.log(obj[yearValue + "-02"]);
          if (
            obj[yearValue + "-02"] != undefined &&
            obj[yearValue + "-02"] != undefined
          ) {
            graphValues.push(
              Math.round(
                ((isNaN(parseInt(obj[yearValue + "-01"]))
                  ? 0
                  : parseInt(obj[yearValue + "-01"])) +
                  (isNaN(parseInt(obj[yearValue + "-02"]))
                    ? 0
                    : parseInt(obj[yearValue + "-02"]))) /
                  2
              )
            );
          } else {
            graphValues.push(
              (isNaN(parseInt(obj[yearValue + "-01"]))
                ? 0
                : parseInt(obj[yearValue + "-01"])) +
                (isNaN(parseInt(obj[yearValue + "-02"]))
                  ? 0
                  : parseInt(obj[yearValue + "-02"]))
            );
          }
          if (duplica.includes(0)) {
            keys = _.filter(keys, function (o) {
              return o != yearValue;
            });
          }
        } else {
          let duplicat = [];
          duplicat.push(
            Math.round(
              (isNaN(parseInt(obj[yearValue + "-01"]))
                ? 0
                : parseInt(obj[yearValue + "-01"]) +
                  (isNaN(parseInt(obj[yearValue + "-02"]))
                    ? 0
                    : parseInt(obj[yearValue + "-02"])) +
                  (isNaN(parseInt(obj[yearValue + "-03"]))
                    ? 0
                    : parseInt(obj[yearValue + "-03"])) +
                  (isNaN(parseInt(obj[yearValue + "-04"]))
                    ? 0
                    : parseInt(obj[yearValue + "-04"])) +
                  (isNaN(parseInt(obj[yearValue + "-05"]))
                    ? 0
                    : parseInt(obj[yearValue + "-05"])) +
                  (isNaN(parseInt(obj[yearValue + "-06"]))
                    ? 0
                    : parseInt(obj[yearValue + "-06"])) +
                  (isNaN(parseInt(obj[yearValue + "-07"]))
                    ? 0
                    : parseInt(obj[yearValue + "-07"])) +
                  (isNaN(parseInt(obj[yearValue + "-08"]))
                    ? 0
                    : parseInt(obj[yearValue + "-08"])) +
                  (isNaN(parseInt(obj[yearValue + "-09"]))
                    ? 0
                    : parseInt(obj[yearValue + "-09"])) +
                  (isNaN(parseInt(obj[yearValue + "-10"]))
                    ? 0
                    : parseInt(obj[yearValue + "-10"])) +
                  (isNaN(parseInt(obj[yearValue + "-11"]))
                    ? 0
                    : parseInt(obj[yearValue + "-11"])) +
                  (isNaN(parseInt(obj[yearValue + "-12"]))
                    ? 0
                    : parseInt(obj[yearValue + "-12"]))) / 12
            )
          );

          graphValues.push(
            Math.round(
              (isNaN(parseInt(obj[yearValue + "-01"]))
                ? 0
                : parseInt(obj[yearValue + "-01"]) +
                  (isNaN(parseInt(obj[yearValue + "-02"]))
                    ? 0
                    : parseInt(obj[yearValue + "-02"])) +
                  (isNaN(parseInt(obj[yearValue + "-03"]))
                    ? 0
                    : parseInt(obj[yearValue + "-03"])) +
                  (isNaN(parseInt(obj[yearValue + "-04"]))
                    ? 0
                    : parseInt(obj[yearValue + "-04"])) +
                  (isNaN(parseInt(obj[yearValue + "-05"]))
                    ? 0
                    : parseInt(obj[yearValue + "-05"])) +
                  (isNaN(parseInt(obj[yearValue + "-06"]))
                    ? 0
                    : parseInt(obj[yearValue + "-06"])) +
                  (isNaN(parseInt(obj[yearValue + "-07"]))
                    ? 0
                    : parseInt(obj[yearValue + "-07"])) +
                  (isNaN(parseInt(obj[yearValue + "-08"]))
                    ? 0
                    : parseInt(obj[yearValue + "-08"])) +
                  (isNaN(parseInt(obj[yearValue + "-09"]))
                    ? 0
                    : parseInt(obj[yearValue + "-09"])) +
                  (isNaN(parseInt(obj[yearValue + "-10"]))
                    ? 0
                    : parseInt(obj[yearValue + "-10"])) +
                  (isNaN(parseInt(obj[yearValue + "-11"]))
                    ? 0
                    : parseInt(obj[yearValue + "-11"])) +
                  (isNaN(parseInt(obj[yearValue + "-12"]))
                    ? 0
                    : parseInt(obj[yearValue + "-12"]))) / 12
            )
          );
          if (duplicat.includes(0)) {
            keys = _.filter(keys, function (o) {
              return o != yearValue;
            });
          }
          console.log("keys", keys);
        }
      });
      let values = _.filter(graphValues, function (o) {
        return o != 0;
      });
      setState((prev) => ({
        ...prev,
        singleFamilyData: values,
        singleFamilyLabels: keys,
        homeLoader: false,
      }));
    }
  };
  const onChangeHomeValueEvent = async (e) => {
    e.persist();
    let value = "";
    if (e.target.value == "1bedroom") {
      value = "1";
    } else if (e.target.value == "2bedroom") {
      value = "2";
    } else if (e.target.value == "3bedroom") {
      value = "3";
    } else if (e.target.value == "4bedroom") {
      value = "4";
    } else {
      value = "5";
    }
    setState((prev) => ({
      ...prev,
      selectedHomeValue: e.target.value,
      isBedRoom: true,
      allHomesValue: "",
    }));
    getSingleBedRoomData(value);
    console.log(e.target.value);
  };
  const reset = async () => {
    let response = mapView([
      {
        latitude: 37.7739852,
        longitude: -122.3912407,
        communityObject: {
          latitude: 37.7739852,
          longitude: -122.3912407,
        },
      },
    ]);
    let cityDetails = JSON.parse(await localStorage.getItem("cDetails"));
    if (cityDetails != null || cityDetails != undefined) {
      let response = mapView([
        {
          latitude: Number(cityDetails.latitude),
          longitude: Number(cityDetails.longitude),
          communityObject: {
            latitude: Number(cityDetails.latitude),
            longitude: Number(cityDetails.longitude),
          },
        },
      ]);
    }
    $("#txtPlaces").val("");
  };
  const destinationLocation = () => {
    var address = "";
    const _this = this;
    var places = new google.maps.places.Autocomplete(
      document.getElementById("txtPlaces")
    );
    google.maps.event.addListener(places, "place_changed", function () {
      var place = places.getPlace();
      address = place.formatted_address;
      setState((prev) => ({ ...prev, destinationAdd: address }));
    });
  };
  const handleFormSubmit = async () => {
    if (document.getElementById("txtPlaces").value.trim() != "") {
      let s3BucketUrl_WebsiteImages =
        "https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/";
      const _this = this;
      var locations = {
        lat: Number(state.communityObject.latitude),
        lng: Number(state.communityObject.longitude),
      };
      const customMap = new google.maps.Map(
        document.getElementById("mapExplore"),
        {
          zoom: 7,
          center: locations,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
        }
      );
      setState((prev) => ({ ...prev, customMap }));
      await GoogleApiWrapper.customMarkers(
        state.communityObject,
        "singlemark",
        this,
        "",
        s3BucketUrl_WebsiteImages + "SuiteAmerica-Map-Marker-Red.png",
        customMap
      );

      const community_lat = state.communityObject.latitude;
      const community_long = state.communityObject.longitude;
      var request = {
        origin: { lat: Number(community_lat), lng: Number(community_long) },
        destination: state.destinationAdd,
        travelMode: google.maps.DirectionsTravelMode.DRIVING,
      };

      state.directionsService.route(request, function (response, status) {
        if (status == google.maps.DirectionsStatus.OK) {
          state.directionsDisplay.setDirections(response);
          var distance = response.routes[0].legs[0].distance.text;
          var duration = response.routes[0].legs[0].duration.text;
          function makeMarker(position, icon, title, map) {
            new google.maps.Marker({
              position: position,
              map: map,
              icon: icon,
              title: title,
            });
          }
          var leg = response.routes[0].legs[0];
          var locations = {
            lat: Number(community_lat),
            lng: Number(community_long),
          };

          makeMarker(
            leg.start_location,
            s3BucketUrl_WebsiteImages + "SuiteAmerica-Map-Marker-Red.png",
            "title",
            state.customMap
          );
          makeMarker(
            leg.end_location,
            s3BucketUrl_WebsiteImages + "SuiteAmerica-Map-Marker-White.png",
            "title",
            state.customMap
          );
        }
      });
    }
  };
  const NeighborhoodScoutPdfError = async (errorResult) => {
    confirmAlert({
      title: "",
      message: errorResult,
      buttons: [
        {
          label: "Ok",
        },
      ],
    });
  };
  const GeneratePdfLoader = async () => {
    if (state.ProgressValue < 100) {
      setTimeout(async () => {
        setState((prev) => ({
          ...prev,
          ProgressValue: state.ProgressValue + 3,
        }));
        await GeneratePdfLoader();
      }, 1000);
    }
  };

  const cityDetails = async (row) => {
    await localStorage.setItem("cDetails", JSON.stringify(row));
    //location.href = '/city-landing';
    window.open("/city-landing", "myWindow");
  };
  return (
    <div className="city-landing-page newOne propertyDetails">
      {/* <Header /> */}
      {state.WalkscoreLoader ? <div className="loading"></div> : null}
      {/* <SchoolsSearch {...state}/> */}
      <div className="">
        {state.ViewStatus == "overview" && (
          <header>
            <h1>
              {state.myPropertyDetails && state.myPropertyDetails.propertyname}{" "}
              -{" "}
              {state.myPropertyDetails &&
              state.myPropertyDetails.propertyrankitems != undefined ? (
                <span className="DetailsPercentage">
                  <b>
                    <Popover
                      placement="leftTop"
                      content={
                        <div>
                          {state.myPropertyDetails.propertyrankitems.propertyrankingitems.map(
                            (Pitem) => {
                              return (
                                <p key={"mmy" + Pitem.prioritylistitem}>
                                  {Pitem.prioritylistitem == "Must Haves" ? (
                                    <Popover
                                      placement="leftTop"
                                      content={
                                        <div>
                                          {Pitem.matcheditems.map(
                                            (matcheditem) => {
                                              return (
                                                matcheditem.itemname != "" && (
                                                  <p
                                                    key={
                                                      "mmypps" +
                                                      matcheditem.itemname
                                                    }
                                                  >
                                                    {matcheditem.itemname}
                                                    {matcheditem.ismatched ==
                                                      true && (
                                                      <i className="fa fa-check"></i>
                                                    )}
                                                    {matcheditem.ismatched ==
                                                      false && (
                                                      <i className="fa fa-times"></i>
                                                    )}
                                                  </p>
                                                )
                                              );
                                            }
                                          )}
                                        </div>
                                      }
                                      title="Matched Must Haves"
                                      trigger="hover"
                                    >
                                      {Pitem.prioritylistitem}{" "}
                                      <i
                                        className="fa fa-info-circle"
                                        style={{ color: "#000000" }}
                                      ></i>
                                    </Popover>
                                  ) : (
                                    Pitem.prioritylistitem
                                  )}
                                  {Pitem.ismatched == true &&
                                    Pitem.isallmatched == false && (
                                      <i className="fa fa-check yellow_clr"></i>
                                    )}
                                  {Pitem.ismatched == true &&
                                    Pitem.isallmatched == true && (
                                      <i className="fa fa-check"></i>
                                    )}
                                  {Pitem.ismatched == true &&
                                    Pitem.isallmatched == null && (
                                      <i className="fa fa-check"></i>
                                    )}
                                  {Pitem.ismatched == false && (
                                    <i className="fa fa-times"></i>
                                  )}
                                </p>
                              );
                            }
                          )}
                        </div>
                      }
                      title="Matched Priorities"
                      trigger="hover"
                    >
                      {Math.trunc(
                        state.myPropertyDetails.propertyrankitems.matchpercent
                      )}
                      %
                    </Popover>
                  </b>
                </span>
              ) : (
                ""
              )}
            </h1>
            <p>{state.myPropertyDetails.address}</p>
          </header>
        )}

        <div className="tabs-view">
          <ul className="nav nav-pills" role="tablist">
            <li>
              <a
                className={
                  state.ViewStatus == "overview"
                    ? "nav-link active"
                    : "nav-link"
                }
                data-toggle="pill"
                onClick={() => changeStatus("overview")}
              >
                Overview
              </a>
            </li>

            <li>
              <a
                className={
                  state.ViewStatus == "poi" ? "nav-link active" : "nav-link"
                }
                data-toggle="pill"
                onClick={() => changeStatus("poi")}
              >
                Points of Interest
              </a>
            </li>
            <li>
              <a
                className={
                  state.ViewStatus == "transit" ? "nav-link active" : "nav-link"
                }
                data-toggle="pill"
                onClick={() => changeStatus("transit")}
              >
                Commute
              </a>
            </li>
            {state.SchoolDisplay == true && (
              <li>
                <a
                  className={
                    state.ViewStatus == "schools"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  data-toggle="pill"
                  onClick={() => changeStatus("schools")}
                >
                  Schools
                </a>
              </li>
            )}
            <li>
              <a
                className={
                  state.ViewStatus == "explore" ? "nav-link active" : "nav-link"
                }
                data-toggle="pill"
                onClick={() => changeStatus("explore")}
              >
                Explore the Area
              </a>
            </li>
          </ul>
          <div className="tab-content">
            {state.ViewStatus == "overview" && (
              <div id="overview" className="tab-pane active container">
                <div className="row">
                  <div className="col-md-8">
                    <div className="">
                      {state.myPropertyDetails.property_image != null &&
                      state.myPropertyDetails.property_image != "" ? (
                        <div className="bb-white city-padding  b-radius-8">
                          {/* <h1>Pictures</h1> */}

                          <div className="image">
                            <OwlCarousel
                              className="owl-theme"
                              nav
                              dots={false}
                              items={state.tabItems}
                              startPosition={state.startPosition}
                              onDragged={(object) =>
                                updateCarouselPosition(object)
                              }
                              onTranslated={(object) =>
                                updateCarouselPosition(object)
                              }
                            >
                              {state.myPropertyDetails.property_image
                                .split(",")
                                .map((pImg, pIndex) => {
                                  return (
                                    <div className="item" key={pIndex}>
                                      <img src={pImg} alt="" />
                                    </div>
                                  );
                                })}
                            </OwlCarousel>
                          </div>
                        </div>
                      ) : (
                        <div className="my_property_details cityImage">
                          <img
                            src="https://www.westernunion.com/blog/wp-content/uploads/2016/06/Travel_Spotlight_USA_02.jpg"
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                    <div className="city-bg mt-4 mb-4">
                      <h1 className="h1">Amenities</h1>
                      {state.myPropertyDetails.amenities != undefined &&
                        state.myPropertyDetails.amenities != "" &&
                        state.myPropertyDetails.amenities != null && (
                          <div className="">
                            {state.myPropertyDetails.amenities != undefined &&
                              state.myPropertyDetails.amenities != "" &&
                              state.myPropertyDetails.amenities != null && (
                                <div className="amenities">
                                  <ul className="d-flex">
                                    {state.myPropertyDetails.amenities
                                      .split(",")
                                      .map((matcheditem) => {
                                        return (
                                          matcheditem != "" && (
                                            <li key={matcheditem}>
                                              <img
                                                src={
                                                  "./assets/images/amenities/" +
                                                  matcheditem
                                                    .replace(/\s/g, "")
                                                    .replace("/", "") +
                                                  ".svg"
                                                }
                                                alt=""
                                              />
                                              <span> {matcheditem}</span>
                                            </li>
                                          )
                                        );
                                      })}
                                  </ul>
                                </div>
                              )}
                          </div>
                        )}
                    </div>
                    <div
                      className={
                        state.myPropertyDetails.property_image != null &&
                        state.myPropertyDetails.property_image != ""
                          ? "city-bg"
                          : "city-bg"
                      }
                    >
                      <h1>Sound Score</h1>

                      <div className="propertypage">
                        <div className="d-flex justify-content-between mrgtop-80 text-center ">
                          <div>
                            <div className="soundscore">
                              <i className="fa fa-bell"></i>
                              <h1>
                                SOUNDSCORE <sub>TM</sub>
                              </h1>
                              <p>
                                Powered by How Loud, Inc.
                                {state.soundScoreDetails.result != undefined
                                  ? state.soundScoreDetails.result.length
                                  : 0}
                              </p>

                              <h2>
                                {state.soundScoreDetails != undefined &&
                                state.soundScoreDetails.result != undefined &&
                                state.soundScoreDetails.result.length > 0 &&
                                state.soundScoreDetails.result[0].score !=
                                  undefined
                                  ? state.soundScoreDetails.result[0].score
                                  : 0}
                              </h2>
                              <h3>
                                {state.soundScoreDetails != undefined &&
                                state.soundScoreDetails.result != undefined &&
                                state.soundScoreDetails.result.length > 0 &&
                                state.soundScoreDetails.result[0].scoretext !=
                                  undefined
                                  ? state.soundScoreDetails.result[0].scoretext
                                  : "BUSY"}
                              </h3>
                            </div>
                            <div className="soundscorepro">
                              <div className="item">
                                <h1>TRAFFIC</h1>
                                <p>
                                  {state.soundScoreDetails != undefined &&
                                  state.soundScoreDetails.result != undefined &&
                                  state.soundScoreDetails.result.length > 0 &&
                                  state.soundScoreDetails.result[0]
                                    .traffictext != undefined
                                    ? state.soundScoreDetails.result[0]
                                        .traffictext
                                    : "BUSY"}
                                </p>
                              </div>
                              <div className="item">
                                <h1>AIRPORT</h1>
                                <p>
                                  {state.soundScoreDetails != undefined &&
                                  state.soundScoreDetails.result != undefined &&
                                  state.soundScoreDetails.result.length > 0 &&
                                  state.soundScoreDetails.result[0]
                                    .airportstext != undefined
                                    ? state.soundScoreDetails.result[0]
                                        .airportstext
                                    : "BUSY"}
                                </p>
                              </div>
                              <div className="item">
                                <h1>SCORE</h1>
                                <p>
                                  {state.soundScoreDetails != undefined &&
                                  state.soundScoreDetails.result != undefined &&
                                  state.soundScoreDetails.result.length > 0 &&
                                  state.soundScoreDetails.result[0].scoretext !=
                                    undefined
                                    ? state.soundScoreDetails.result[0]
                                        .scoretext
                                    : "BUSY"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="soundscore-image-view">
                            <img src="/assets/images/scorebar.png" alt="" />
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                    {state.transitDetails.length > 0 ? (
                      <div className="city-margin-top bb-white city-padding b-radius-8">
                        <h1>Nearby Transit</h1>
                        {state.loader == false ? (
                          <div className="propertypage">
                            {state.transitDetails.length > 0 ? (
                              <div className="nearbypoints">
                                <p>Distance From The {state.data.city_name}</p>
                                <div className="table-scroll-view">
                                  <table width="100%" className="transit-table">
                                    <thead>
                                      <tr>
                                        <th>Route Name</th>
                                        <th>Category - Details</th>
                                        <th>Distance</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {state.transitDetails.map(function (
                                        RoutDetails,
                                        index
                                      ) {
                                        return (
                                          <tr key={index}>
                                            <td>{RoutDetails.name}</td>
                                            <td>
                                              {_.hasIn(
                                                RoutDetails.route_summary[0],
                                                "category"
                                              )
                                                ? RoutDetails.route_summary[0]
                                                    .category +
                                                  " - " +
                                                  RoutDetails.route_summary[0]
                                                    .name
                                                : ""}
                                            </td>
                                            <td>
                                              {RoutDetails.distance.toFixed(2)}{" "}
                                              mi
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <Spin />
                        )}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-4">
                    <div className="city-bg">
                      <div className="">
                        <div className="">
                          <h1 className="mb-3">My Property Details</h1>
                          <span>
                            {state.myPropertyDetails.propertylink != null &&
                              state.myPropertyDetails.propertylink != "" && (
                                <a
                                  href={state.myPropertyDetails.propertylink}
                                  target="_blank"
                                  className=""
                                >
                                  Property Link{" "}
                                  <span className="side-arrow-view-icon"></span>
                                </a>
                              )}
                            {state.myPropertyDetails.virtual360tour != null &&
                              state.myPropertyDetails.virtual360tour != "" && (
                                <span className="ms-4">
                                  <a
                                    href={
                                      state.myPropertyDetails.virtual360tour
                                    }
                                    target="_blank"
                                  >
                                    360view
                                  </a>{" "}
                                  <span className="side-arrow-view-icon"></span>
                                </span>
                              )}
                          </span>
                        </div>
                      </div>
                      <ul className="propertyDetails mt-4">
                        <li>
                          <label>Property Name</label>
                          <label className="lbl">
                            {state.myPropertyDetails.propertyname}
                          </label>
                        </li>
                        <li>
                          <label> Address</label>
                          <label className="lbl">
                            {state.myPropertyDetails.address}
                          </label>
                        </li>
                        <li>
                          <label>Available Date</label>
                          <label className="lbl">
                            {state.myPropertyDetails.available_till_date != null
                              ? moment(
                                  state.myPropertyDetails.available_till_datee
                                ).format("MM/DD/YYYY")
                              : "---"}
                          </label>
                        </li>
                        <li>
                          <label>Monthly Rent($)</label>
                          <label className="lbl">
                            {state.myPropertyDetails.monthlyrent}
                          </label>
                        </li>
                        <li>
                          <label>Home Type </label>
                          <label className="lbl">
                            {state.myPropertyDetails.hometype}
                          </label>
                        </li>

                        <li>
                          <label>Bed Rooms</label>
                          <label className="lbl">
                            {state.myPropertyDetails.bedrooms}
                          </label>
                        </li>
                        <li>
                          <label>Note</label>
                          <label className="lbl">
                            {state.myPropertyDetails.notes}
                          </label>
                        </li>
                      </ul>
                    </div>
                    {state.walkScoreDetails.length > 0 ? (
                      <div className="city-bg mb-4 mt-4">
                        <h1 className="h1">
                          Scores{" "}
                          <i
                            className="fa fa-info-circle"
                            data-toggle="modal"
                            data-target="#walkscoreInfoMadal"
                            onClick={() => walkScoreModal()}
                          ></i>
                        </h1>{" "}
                        <div className="scoreView">
                          {state.walkScoreDetails.map((row, index) => {
                            return row.iconImg == 1 ? (
                              <div className="item" key={index}>
                                <i className="">
                                  <FontAwesomeIcon icon={faWalking} />
                                </i>

                                <div className="new-walkcircle">
                                  <h5
                                    className={
                                      row.Score < 30 ? "belowScore" : ""
                                    }
                                  >
                                    WalkScore
                                  </h5>
                                  <Progress
                                    // type="circle"
                                    percent={row.Score}
                                    format={(percent) => `${percent}`}
                                  />
                                </div>
                              </div>
                            ) : row.iconImg == 2 ? (
                              <div className="item" key={index}>
                                <i className="">
                                  <FontAwesomeIcon icon={faBicycle} />
                                </i>

                                <div className="new-walkcircle">
                                  <h5
                                    className={
                                      row.Score < 30 ? "belowScore" : ""
                                    }
                                  >
                                    BikeScore
                                  </h5>
                                  <Progress
                                    // type="circle"
                                    percent={row.Score}
                                    format={(percent) => `${percent}`}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="item" key={index}>
                                <i className="">
                                  <FontAwesomeIcon icon={faTrain} />
                                </i>

                                <div className="new-walkcircle">
                                  <h5
                                    className={
                                      row.Score < 30 ? "belowScore" : ""
                                    }
                                  >
                                    TransitScore
                                  </h5>
                                  <Progress
                                    // type="circle"
                                    percent={row.score}
                                    format={(percent) => `${percent}`}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <div className="min-height-200 divSpin">
                        <Spin />
                      </div>
                    )}
                    <div className="city-bg ">
                      {state.nearByCitiesWithStates.length > 0 && (
                        <div className="bb-white city-padding city-margin-top  b-radius-8">
                          <h1>Nearby Cities</h1>
                          {state.nearByCitiesWithStates.length > 0 ? (
                            <ul className="near-city">
                              {state.nearByCitiesWithStates &&
                                state.nearByCitiesWithStates.map(
                                  (row, index) => {
                                    return (
                                      <li key={index}>
                                        <a href="#">{row.city}</a>
                                      </li>
                                    );
                                  }
                                )}
                            </ul>
                          ) : (
                            <div className="aboutcarson progressbar">
                              <Spin />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="city-margin-top bb-white city-padding b-radius-8">
                      <div className="">
                        {state.weather_current != undefined ? (
                          <NewWeather
                            {...state}
                            weather={state.weather}
                            weather_current={state.weather_current}
                            weather_currentCityDetails={
                              state.weather_currentCityDetails
                            }
                          />
                        ) : (
                          <div className="aboutcarson progressbar">
                            <div className="min-height-200 divSpin">
                              <Spin />
                            </div>
                          </div>
                        )}{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {state.ViewStatus == "schools" && (
              <div id="school" className="">
                {state.ispoi == false &&
                state.data.latitude != "" &&
                state.data.longitude != "" ? (
                  // <SchoolsSearch {...state} isPoi={false} />
                  <SchoolSearchMapBox {...state} isPoi={false} />
                ) : null}
              </div>
            )}

            {state.ViewStatus == "poi" && (
              <div className="">
                {state.ispoi == true &&
                state.data.latitude != "" &&
                state.data.longitude != "" ? (
                  <PoiSearch {...state} />
                ) : null}
              </div>
            )}

            {state.ViewStatus == "transit" && (
              <div id="transit" className="">
                {state.travelMapLoading == true && (
                  <PublicTransit
                    lines={state.lines}
                    latitude={state.data.latitude}
                    longitude={state.data.longitude}
                    isProperty={state.isProperty}
                    isSaproperty={false}
                  />
                )}
              </div>
            )}

            {state.ViewStatus == "explore" && (
              <div id="explore-the-area" className="">
                <div className="shresultsmap">
                  <div className="map_top_view">
                    <Mapview
                      {...state}
                      latitude={state.communityObject.latitude}
                      longitude={state.communityObject.longitude}
                      communityName={state.communityObject.community_name}
                      communityObject={state.communityObject}
                    />
                    {/* <MapViewFunctional
                        state={state}
                        latitude={state.communityObject.latitude}
                        longitude={state.communityObject.longitude}
                        communityName={state.communityObject.community_name}
                        communityObject={state.communityObject}
                      /> */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {state.ViewStatus == "overview" && <Footer />}
    </div>
  );
};
export default MyPropertyDetails;
