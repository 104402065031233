import React from "react";
import { Popover } from "antd";

import { confirmAlert } from "react-confirm-alert";

import ApiServices from "../../../../../services/apiServices";

const RentalCard = ({
  propertyDetails,
  changeEditMode,
  isNotIntrested,
  getMyProperties,
}) => {
  function renderPriorityItems(propertyDetails) {
    return propertyDetails.propertyrankitems.propertyrankingitems.map(
      (item) => (
        <div key={`priority-${item.prioritylistitem}`}>
          <span>{item.prioritylistitem}</span>
          <span>
            {item.ismatched ? (
              <i
                className={`fa ${item.isallmatched === true ? "fa-check" : ""}
                ${item.isallmatched === false ? "fa-check yellow_clr" : ""}
                ${item.isallmatched === null ? "fa-check" : ""}
                `}
              ></i>
            ) : (
              <i className="fa fa-times"></i>
            )}
          </span>
        </div>
      )
    );
  }

  function renderMatchedPriorities(propertyDetails) {
    return (
      <Popover
        placement="leftTop"
        content={renderPriorityItems(propertyDetails)}
        title="Matched Priorities"
        trigger="hover"
      >
        <b>{Math.trunc(propertyDetails.propertyrankitems.matchpercent)}%</b>
      </Popover>
    );
  }

  function renderScoreLabel(propertyDetails) {
    return (
      <span className="ScoreLabel">
        {renderMatchedPriorities(propertyDetails)}
      </span>
    );
  }

  function onClickEditProperty(propertyDetails) {
    changeEditMode(true, propertyDetails);
  }

  function deleteConfirmation(propertyDetails) {
    confirmAlert({
      title: "",
      message: "Are you sure do you want to delete this rental.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteSaProperties(propertyDetails),
        },
        {
          label: "No",
        },
      ],
    });
  }

  async function deleteSaProperties(row) {
    let propertyObjectDelete = {
      apiId: row.apiId,
      transfereeuniqueId: row.transfereeUId,
      propertyanalyticsuniqueId: row.propertyanalyticsuniqueId,
      propertyuniqueId: row.propertyuniqueId,
      isdelete: true,
    };
    let addPropertyResultDelete = await ApiServices.CreateTransfereeProperties(
      propertyObjectDelete
    );
    getMyProperties(row.transfereeUId);
  }

  async function moreInfoWithDetails(propertyDetails) {
    await localStorage.setItem(
      "myPropertyDetails",
      JSON.stringify(propertyDetails)
    );
    window.open("/supplierSADetails", "myWindow");
  }

  return (
    <div className="item">
      {/* <span className="ScoreLabel">
        <b>
          <Popover
            placement="leftTop"
            content={
              <div>
                {row.propertyrankitems.propertyrankingitems.map((Pitem) => {
                  return (
                    <p key={"mmys2" + index + Pitem.prioritylistitem}>
                      {Pitem.prioritylistitem == "Must Haves" ? (
                        <Popover
                          placement="leftTop"
                          content={
                            <div>
                              {Pitem.matcheditems.map((matcheditem) => {
                                return (
                                  matcheditem.itemname != "" && (
                                    <p
                                      key={
                                        "mmys2pps" +
                                        index +
                                        matcheditem.itemname
                                      }
                                    >
                                      {matcheditem.itemname}
                                      {matcheditem.ismatched == true && (
                                        <i className="fa fa-check"></i>
                                      )}
                                      {matcheditem.ismatched == false && (
                                        <i className="fa fa-times"></i>
                                      )}
                                    </p>
                                  )
                                );
                              })}
                            </div>
                          }
                          title="Matched Must Haves"
                          trigger="hover"
                        >
                          {Pitem.prioritylistitem}{" "}
                          <i
                            className="fa fa-info-circle"
                            style={{ color: "#000000" }}
                          ></i>
                        </Popover>
                      ) : (
                        Pitem.prioritylistitem
                      )}
                      {Pitem.ismatched == true &&
                        Pitem.isallmatched == false && (
                          <i className="fa fa-check yellow_clr"></i>
                        )}
                      {Pitem.ismatched == true &&
                        Pitem.isallmatched == true && (
                          <i className="fa fa-check"></i>
                        )}
                      {Pitem.ismatched == true &&
                        Pitem.isallmatched == null && (
                          <i className="fa fa-check"></i>
                        )}
                      {Pitem.ismatched == false && (
                        <i className="fa fa-times"></i>
                      )}
                    </p>
                  );
                })}
              </div>
            }
            title="Matched Priorities"
            trigger="hover"
          >
            {Math.trunc(row.propertyrankitems.matchpercent)}%
          </Popover>
        </b>
      </span> */}
      {renderScoreLabel(propertyDetails)}
      <label>
        <img
          src={
            propertyDetails.property_image != null &&
            propertyDetails.property_image != ""
              ? propertyDetails.property_image.split(",")[0]
              : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
          }
          alt=""
        />
        <div className="content">
          <p>
            {propertyDetails.propertyname} <br />
            {propertyDetails.city} {propertyDetails.state}
          </p>
          <span>
            {!isNotIntrested && (
              <>
                <div
                  data-bs-toggle="modal"
                  data-bs-target="#recommend-edit-rental"
                  onClick={() => onClickEditProperty(propertyDetails)}
                >
                  <i className="fa fa-edit"></i>
                </div>
                <div onClick={() => deleteConfirmation(propertyDetails)}>
                  <i className="fa fa-trash"></i>
                </div>
              </>
            )}

            {isNotIntrested && (
              <>
                <div></div>
              </>
            )}
            {propertyDetails.latitude != "" &&
            propertyDetails.latitude != null ? (
              <div onClick={() => moreInfoWithDetails(propertyDetails)}>
                More
              </div>
            ) : (
              ""
            )}
          </span>
        </div>
      </label>
    </div>
  );
};

export default RentalCard;
