import React from "react";

function CheckBoxComponent(props) {
  const { state, questionList, setDie, questionIndex } = props;
  return (
    <div key={"sub" + questionIndex} className="custom-checkbox my-4">
      {questionList.question}
      <input
        type="checkbox"
        checked={
          state.answersPrefer[questionList.preferenceUId] != undefined &&
          (state.answersPrefer[questionList.preferenceUId].preferencesanswer ==
            true ||
            state.answersPrefer[questionList.preferenceUId].preferencesanswer ==
              "true")
            ? true
            : false
        }
        onChange={(e) => setDie("CheckBox", e, questionList.preferenceUId)}
      />

      <span class="checkmark"></span>
      {state.checkError &&
        state.answersPrefer[questionList.preferenceUId] != undefined &&
        (state.answersPrefer[questionList.preferenceUId].preferencesanswer ==
          "false" ||
          state.answersPrefer[questionList.preferenceUId].preferencesanswer ==
            false) && (
          <div className="tooltip-validation">
            <div className="tooltip-inner">{state.toolTipText}</div>
          </div>
        )}
    </div>
  );
}

export default CheckBoxComponent;
