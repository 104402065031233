import React from "react";
// import Modal from "react-modal";

import { Select, Modal } from "antd";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import ApiServices from "../../../../../services/apiServices";
import ThirdParty from "../../../../../services/thirdPartyService";
import ReactDatePicker from "react-datepicker";

const Premove = ({
  isOpen,
  onClose,
  state,
  setState,
  getScheduleList,
  getbusinessHours,
  getTransfareeData,
  filterEvents,
  handleSelectdate,
  filterEventsBusinessHours,
  getMonthlybusinessHours,
  setIsModalOpen,
}) => {
  const deleteEventConfirm = async () => {
    // e.preventDefault();
    let eventDetails = state.eventDetails;
    confirmAlert({
      title: "",
      message: "Are you sure you want to cancel the appointment?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteEvent(eventDetails);
          },
        },
        {
          label: "No",
          onClick: () => "",
        },
      ],
    });
  };
  const deleteEvent = async (eventDetails) => {
    let indexEvent = _.findIndex(state.events, function (o) {
      return o.scheduleuniqueId == eventDetails.id;
    });

    let events = Object.assign([], state.events);
    events.splice(indexEvent, 1);
    getScheduleList();
    getbusinessHours();
    getTransfareeData();
    // await setState((prev)=>({...prev, events});
    let event = {
      start: moment(eventDetails.scheduledate).format("YYYY-MM-DD"),
    };
    handleSelectdate(event);
    setIsModalOpen(false);
    if (eventDetails.id != undefined) {
      let delScheduleObject = {
        scheduleuniqueId: eventDetails.id,
        IsDelete: true,
      };
      let Title = "";

      _.each(eventDetails.scheduleServicesList, async (serviceId, index) => {
        Title = serviceId.ServiceName + "," + Title;

        await setState((prev) => ({
          ...prev,
          Title: Title.replace(/,([^,]*)$/, "$1"),
        }));
      });
      let delschedules = await ApiServices.UpdateTransfereeSchedule(
        delScheduleObject
      );
      let getSessionData = await ThirdParty.getLoginInfo({});
      if (delschedules.error == 0) {
        let data = {
          email: eventDetails.emailid,
          title: Title,
          pickupLocation: eventDetails.pickuplocation,
          notes: eventDetails.servicenotes,
          startTime: eventDetails.schedulefromtime,
          endTime: eventDetails.scheduletotime,
          popupDate: moment(eventDetails.scheduledate).format("LL"),
          subject: "Appointment is Cancelled",
          appointmenttype: state.scheduleType,
          consultantemailid: eventDetails.consultantemailid,
          consultantName:
            eventDetails.consultantfirstname +
            " " +
            eventDetails.consultantlastname,
          theDelete: true,
          timezone: state.timezone,
          cc: getSessionData.supplieremailId,
        };

        let sendSms = await ThirdParty.sendEmail(data);
        await getMonthlybusinessHours();
      }
    }
  };
  const handleDateChange = async (editDate) => {
    await setState((prev) => ({ ...prev, EditMoveDate: new Date(editDate) }));
  };
  const moveEvent = async () => {
    let eventDetails = state.eventDetails;
    let EditMoveDate = moment(state.EditMoveDate).format("YYYY/MM/DD");
    let startTime =
      new Date(eventDetails.start).getHours() +
      ":" +
      new Date(this).getMinutes();
    let endTime =
      new Date(eventDetails.end).getHours() +
      ":" +
      new Date(eventDetails.end).getMinutes();
    let start = new Date(EditMoveDate + " " + state.startTimeId);
    let end = new Date(EditMoveDate + " " + state.endTimeId);
    let result = await filterEvents(start, end);
    result = _.filter(result, function (o) {
      return o.scheduleuniqueId != eventDetails.scheduleuniqueId;
    });
    let resultBusinessHours = await filterEventsBusinessHours(
      state.startTimeId,
      state.endTimeId,
      EditMoveDate
    );
    let eventPreviousData = Object.assign([], state.events);
    let indexEvent = _.findIndex(state.events, function (o) {
      return o.scheduleuniqueId == eventDetails.id;
    });
    if (result.length > 0) {
      await setState((prev) => ({
        ...prev,
        moveModalerror: true,
        ErrorMsgExitsMsg:
          "Another event exists during this time slot. Please choose another time slot.",
      }));
    } else if (resultBusinessHours.length == 0) {
      await setState((prev) => ({
        ...prev,
        moveModalerror: true,
        ErrorMsgExitsMsg:
          "There is no availability. Please adjust the appointment time.",
      }));
    } else if (
      indexEvent >= 0 &&
      start != "Invalid Date" &&
      !state.moveModalerror &&
      state.ErrorMsgExitsMsg == ""
    ) {
      let newObject = Object.assign({}, eventPreviousData[indexEvent]);
      eventPreviousData.splice(indexEvent, 1);
      newObject.start = start;
      newObject.end = end;
      newObject.scheduledate = moment(EditMoveDate).format("MM/DD/YYYY");
      eventPreviousData.push(newObject);
      await setState((prev) => ({ ...prev, events: eventPreviousData }));

      let userInfo = await ThirdParty.getLoginInfo({});
      let moveEventObject = {
        scheduledate: moment(start).format("YYYY/MM/DD"),
        schedulefromtime: state.startTimeId,
        scheduletotime: state.endTimeId,
        transfereeuniqueid: userInfo.userUniqueId,
        scheduleuniqueId: eventDetails.id,
        pickuplocation: state.pickupLocation,
        servicenotes: state.serviceNote,
        IsDelete: false,
      };
      let Moveschedules = await ApiServices.UpdateTransfereeSchedule(
        moveEventObject
      );
      await getMonthlybusinessHours();

      setIsModalOpen(false);
      let startDatewe = moment(start).format("YYYY-MM-DD");
      const dateTime = moment(
        `${startDatewe} ${state.startTimeId}`,
        "YYYY-MM-DD h:mm A"
      ).format();
      const dateTime2 = moment(
        `${startDatewe} ${state.endTimeId}`,
        "YYYY-MM-DD h:mm A"
      ).format();
      let date1 = new Date(dateTime);
      let date2 = new Date(dateTime2);
      let timeDiff = Math.abs(date2.getTime() - date1.getTime());
      let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      await setState((prev) => ({
        ...prev,
        EmailId: eventDetails.emailid,
        diffTime: timeDiff / (1000 * 3600),
        Title: eventDetails.title,
        scheduleType: "",
        popupDate: moment(start).format("LL"),
        startTime: state.startTimeId,
        endTime: state.endTimeId,
        pickupLocation: eventDetails.pickuplocation,
        serviceNote: state.serviceNote,
        dayName: new Date(start).getDay(),
        isConfirmed: true,
        countrycode: eventDetails.countrycode,
        updateAppointmentDetails: true,
        events: state.events,
      }));
      // console.log(eventDetails, "eventDetails");
      if (Moveschedules.error == 0) {
        getTransfareeData();
        let event = {
          start: moment(eventDetails.scheduledate).format("YYYY-MM-DD"),
        };
        handleSelectdate(event);
        await setState((prev) => ({ ...prev, modalPopUp: true }));
      }
    } else {
    }
  };
  const inputHandler = async (event) => {
    const inputVal = event.target.value;
    const inputName = event.target.name;
    let newState = { ...state };
    newState[inputName] = inputVal;
    await setState(newState);
  };
  const onChangeTimeSlotEvent = async (type, value) => {
    if (type == "startTimeId") {
      await setState((prev) => ({ ...prev, startTimeId: value }));
    } else {
      await setState((prev) => ({ ...prev, endTimeId: value }));
    }
    let startedTimeId =
      type == "startTimeId" && state.startTimeId != value
        ? value
        : state.startTimeId;
    let endedTimeId =
      type == "endTimeId" && state.endTimeId != value ? value : state.endTimeId;
    let startTime = moment(startedTimeId, "HH:mm A");
    let endTime = moment(endedTimeId, "HH:mm A");
    if (endTime.diff(startTime, "hours") < 1) {
      await setState((prev) => ({
        ...prev,
        moveModalerror: true,
        ErrorMsgExitsMsg:
          "The minimum duration for an appointment is one hour. Please schedule appointments for at least an hour with your consultant.",
      }));
    } else {
      await setState((prev) => ({
        ...prev,
        moveModalerror: false,
        ErrorMsgExitsMsg: "",
        moveModalerror: false,
        ErrorMsgExitsMsg: "",
      }));
    }
  };
  return (
    <div className="modal-dialog">
      {/* <Modal isOpen={isOpen} onAfterClose={onClose} ariaHideApp={false}> */}
      <Modal open={isOpen} footer={null} onCancel={onClose}>
        <div className="modal-body">
          <div className="main-pop">
            <h4 className="modal-title">Event - {state.EditEvent}</h4>
            {state.moveModalerror == true ? (
              <div className="alert alert-danger" role="alert">
                {state.ErrorMsgExitsMsg}
              </div>
            ) : null}
            <div className="form-group">
              <label className="col-form-label" id="inputGroup-sizing-sm">
                Change Date To Move
              </label>
              <ReactDatePicker
                selected={state.EditMoveDate}
                onChange={handleDateChange}
                minDate={new Date()}
                onKeyDown={(e) => e.preventDefault()}
                className="form-control"
              />
            </div>
            <div className="form-group ant-slect-custom ">
              <label className="col-form-label">Start</label>
              <Select
                id="startTimeId"
                name="startTimeId"
                value={state.startTimeId}
                // style={{ width: 470 }}
                onChange={(e) => onChangeTimeSlotEvent("startTimeId", e)}
              >
                {_.uniq(state.timeSlots).map((row, index) => {
                  if (_.uniq(state.timeSlots).length > index + 4)
                    return (
                      <Select.Option key={"ee" + index} value={row}>
                        {row}
                      </Select.Option>
                    );
                })}
              </Select>
            </div>
            <div className="form-group ant-slect-custom">
              <label className="col-form-label">End</label>
              <Select
                id="endTimeId"
                name="endTimeId"
                value={state.endTimeId}
                // style={{ width: 470 }}
                onChange={(e) => onChangeTimeSlotEvent("endTimeId", e)}
              >
                {_.uniq(state.timeSlots).map((row, index) => {
                  if (_.uniq(state.timeSlots).length > index)
                    return (
                      <Select.Option key={"sse" + index} value={row}>
                        {row}
                      </Select.Option>
                    );
                })}
              </Select>
            </div>
            <div className="focus-input-label">
              <div className="floating-label">
                <textarea
                  value={state.serviceNote}
                  className="form-control"
                  rows="6"
                  placeholder="Leave a note here!"
                  id="serviceNote"
                  name="serviceNote"
                  onChange={(e) => inputHandler(e)}
                />
                <label>Notes</label>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mb-3">
          {/* <button type="button" className="btn btn-danger mr-2" data-dismiss="modal" onClick={closeDalog}>Close</button> */}
          <button
            type="button"
            className="btn theme-btn me-2"
            onClick={moveEvent}
          >
            Update
          </button>
          <button
            type="button"
            className="btn theme-btn btn-dark"
            onClick={(e) => deleteEventConfirm(e)}
          >
            Delete
          </button>
        </div>
      </Modal>
      {/* </Modal> */}
    </div>
  );
};

export default Premove;
