import React from "react";
import * as $ from "jquery";

function SocialPopUp() {
  const closeModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#myModalSocialSecurity").modal("hide");
      });
    });
  };
  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header border-0">
          <div class="only_close">
            <span
              aria-hidden="true"
              className="pointer close"
              onClick={() => closeModal()}
              data-dismiss="modal"
            >
              ×
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div className="widthModal">
            <div className="item-view">
              <h2>Social Security</h2>
              <p>
                A Social Security Number (SSN) is necessary in order to get a
                job, collect benefits, and apply for other government services.
                SSNs are located on your Social Security card. As this card is
                extremely important, it must be kept in a safe and secure place.
                It is important that you do not provide your SSN to random
                people. Typically, you will only need to supply your SSN for
                government agencies, like when you do your taxes.
              </p>
            </div>

            <div className="tails-row">
              <div className="tails">
                <div className="item-view">
                  <h2>How to Locate an SSA Office</h2>
                  <p>
                    To find the nearest Social Security office to you, visit:{" "}
                    <a
                      target="_blank"
                      href="https://secure.ssa.gov/ICON/main.jsp"
                    >
                      secure.ssa.gov
                    </a>
                    , or call the toll-free number: 1-800-772-1213 and ask for
                    an SSA office near you.
                  </p>
                </div>

                <div className="item-view">
                  <h2>
                    What do I need to bring to the Social Security Office?
                  </h2>
                  <p>
                    You need to prove your identity and work-authorized
                    immigration status. To do so, show your current U.S.
                    immigration documents and your unexpired foreign passport.
                    Acceptable immigration documents include your: 
                  </p>
                  <ul>
                    <li>
                      Form I-551 (Lawful Permanent Resident Card,
                      Machine-Readable Immigrant Visa);
                    </li>
                    <li>
                      Admission stamp showing a class of admission permitting
                      work;
                    </li>
                    <li>Form I-94 (Arrival/Departure Record); or</li>
                    <li>Form I-766 (Employment Authorization Document/EAD).</li>
                  </ul>
                  <h2>You need to prove age</h2>
                  <p>
                    If you have it or can get it within 10 business days, you
                    must present your foreign birth certificate. If not, other
                    documents, such as your unexpired passport or a document
                    issued by DHS, can be considered, as evidence of your age.
                  </p>
                  <h2>You need to complete an application.</h2>
                  <p>
                    You will need to complete an Application for a Social
                    Security Card (Form SS-5). You can do this at the Social
                    Security office. A printable copy of this form is also
                    available online. Be sure to take it with you. 
                  </p>
                  <p>
                    <b>
                      Important: All documents must either be originals or
                      copies certified by the issuing agency.
                    </b>{" "}
                    Photocopies, notarized copies, and receipts showing you
                    applied for the documents are all NOT acceptable. 
                  </p>
                  <p>
                    One document may be used for two purposes. For example, your
                    DHS EAD may be used to prove both your identity and
                    work-authorized immigration status. Your birth certificate
                    or passport may be used as proof of age. However, you must
                    provide at least two separate documents.
                  </p>
                  {/* <h2>When should I apply? </h2>
                                                    <p>It is recommended that you wait for 10 days after arriving in the United States to apply for Social Security number to make it easier to verify your DHS documents online, which will speed the processing of your Social Security number application. Your Social Security number card will be mailed to you as soon as SS has all of your information and has verified your documents with issuing offices.</p> */}
                </div>
              </div>
              <div className="tails">
                <div className="item-view">
                  <h3>
                    If you are an Immigrant, you can apply for SSN in two ways:
                  </h3>
                  {/* <p>Apply through your home country before immigrating to the U.S. when filing an immigrant visa application through the U.S. Department of State. More often than not, if you do this process (applying for a SSN and card in your immigrant visa application), you will not need to visit a Social Security office in the U.S. For more information, visit:  <a target="_blank" href="https://www.socialsecurity-ssnvisa">socialsecurity-ssnvisa</a></p> */}

                  <ul>
                    <li>
                      Apply through your home country before immigrating to the
                      U.S. when filing an immigrant visa application through the
                      U.S. Department of State. More often than not, if you do
                      this process (applying for a SSN and card in your
                      immigrant visa application), you will not need to visit a
                      Social Security office in the U.S. For more information,
                      visit:{" "}
                      <a
                        target="_blank"
                        href="https://www.socialsecurity.gov/ssnvisa"
                      >
                        socialsecurity-ssnvisa
                      </a>
                      .
                    </li>
                    <li>
                      Visit a Social Security office in person. Please refer to{" "}
                      <b>
                        What do I need to bring to the Social Security Office?
                      </b>{" "}
                      section for the documents you need to take with you.
                    </li>
                  </ul>
                  <h3>
                    If you are a nonimmigrant, depending on your visa status,
                    you can apply in two ways:
                  </h3>
                  <ul>
                    <li>
                      If you are lawfully present in the United States and plan
                      to apply for work authorization from the DHS, U.S.
                      Citizenship and Immigration Services (USCIS), you can
                      apply for your SSN on the same USCIS application Form
                      I-765 (Application for Employment Authorization). For more
                      information, visit:{" "}
                      <a
                        target="_blank"
                        href="https://www.ssa.gov/ssnvisa/ebe.html"
                      >
                        ssa-ssnvisa
                      </a>
                    </li>
                    <li>
                      If you are lawfully present in the United States and your
                      visa status allows you to work, then you must visit a
                      social security office in person to apply.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>When Should I apply?</h2>
                  <p>
                    It is recommended to wait 10 days after arriving in the
                    United States to apply for a SSN. This makes it easier to
                    verify your DHS documents online, which will speed up the
                    processing of your SSN application. As soon as Social
                    Security has verified all your information with the issuing
                    offices, a Social Security card with your SSN will be mailed
                    to you. 
                  </p>
                </div>
              </div>
            </div>
            <p>
              Source:&nbsp;
              <a target="_blank" href="http://www.ssa.gov/ssnumber/">
                ssa.gov
              </a>{" "}
              & &nbsp;
              <a
                target="_blank"
                href="https://www.ssa.gov/pubs/EN-05-10096.pdf"
              >
                ssa.gov/pubs
              </a>
            </p>
          </div>
        </div>
        {/* <div className="modal-footer"></div> */}
      </div>
    </div>
  );
}

export default SocialPopUp;
