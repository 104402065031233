import React from "react";
import { Link, useLocation } from "react-router-dom";
import * as $ from "jquery";
import { useEffect, useState, useRef, useMemo } from "react";
// import "./footer.css";
import FeedBackPopUp from "./feedBackPopUp";
import { Select, Spin } from "antd";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/fontawesome-free-solid";
import LoginStore from "../../../../services/thirdPartyService";
import ApiServices from "../../../../services/apiServices";

var ChatProxy;
var SignalrConnection;
var chatItineraryPropertyID = "";
var propertyChat = [];
var chatMessagesMap = new Map();
var responseTransfreeProperties = [];
var sessionData = {};

const FooterWithchat = (props) => {
  const chatMsgsRef = useRef(null);
  const [state, setState] = useState({
    popUpType: "",
    feedBackPopUp: false,
    openPropetyChatList: false,
    openChatBox: false,
    ChartLitst: [],
    ChatProperty: {},
    sessionData: {},
    chatItineraryPropertyID: 0,
    TextMessage: "",
    propertyChat: [],
    openChatboxPopup: false,
    chatMessage: [],
    chatIdSignalR: "",
    getSessionData: "",
    FromEmail: "",
    transfereeProperties: [],
    responseTransfreeProperties: [],
    countUnread: [],
    isPropertySelected: false,
    TotalUnreadCount: 0,
    countReadSort: true,
    showChatMsg: false,
    generalProperty: {
      id: "0000111122223333",
      apiId: "0000111122223333",
      propertyanalyticsuniqueId: "0000111122223333",
      property_image: "assets/images/default-img.jpg",
      address: "General",
      countUnread: 0,
    },
    profilePic: "",
    openLoader: false,
    PropertiesSortDate: [],
    popoverText: "",
    displayname: "",
    feedbacktype: "General feedback",
    experience: "",
    feeddescription: "",
    TransfereesList: [],
    totalTransfereeList: [],
    countUnreadId: {},
    loader: false,
    propertyList: false,
  });
  const [TextMessage, setTextMessage] = useState("");
  const location = useLocation();

  const feedBack = async (type) => {
    if (type == "feedback") {
      $("document").ready(function () {
        $("#contact-us-modal-feedback").modal("show");
      });
      setState((prev) => ({
        ...prev,
        popUpType: type,
        feedBackPopUp: true,
        succesMsg: "",
        contactError: "",
        subject: "",
        description: "",
        firstname: "",
        lastname: "",
        phoneNumber: "",
        FromEmail: "",
      }));
    } else if (type == "policy") {
      $(function () {
        $("document").ready(async function () {
          $("#ModalTersm-of-use2").modal("show");
        });
      });
      setState((prev) => ({
        ...prev,
        popUpType: type,
        feedBackPopUp: true,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        popUpType: type,
        feedBackPopUp: true,
      }));
      $(function () {
        $("document").ready(async function () {
          $("#ModalTersm-of-use").modal("show");
        });
      });
    }
  };

  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  useEffect(() => {
    (async () => {
      await transfereeList();
    })();
    // componentDidMountLogic();
    makeConnectionSignalR();
  }, []);

  async function scrollDivBottom() {
    var objDiv = $("#chatPopup").height();
    $("#chatPopup").scrollTop(objDiv * 100);
    var objDiv2 = $("#chat_msgs").height();
    $("#chat_msgs").scrollTop(objDiv2 * 100);
  }
  function deepCopy(source) {
    return JSON.parse(JSON.stringify(source));
  }
  function getUreadCount(AppId, TransfereeId) {
    let countUnread = state.countUnread?.filter(function (o) {
      return (
        o.APISource == AppId &&
        o.ToId == sessionData.userUniqueId &&
        o.FromId == TransfereeId
      );
    });

    // Check if countUnread is defined before accessing its properties
    if (countUnread && countUnread.length > 0) {
      return countUnread[0].Count;
    } else {
      return 0;
    }
  }
  function processLogoutUsersData(onlineusers) {
    if (onlineusers != undefined && onlineusers.length > 0) {
      localStorage.setItem("onlineusers", onlineusers);
      $(".fa-circle").removeClass("online");
      $(".fa-circle").addClass("offline");
      onlineusers.map((onlineUsers) => {
        $("#online" + onlineUsers).removeClass("offline");
        $("#online" + onlineUsers).addClass("online");
      });
    }
  }
  function openChatBox(Id, property_image_pic, row) {
    console.log(state.activeTransferee, "transferee");

    let property_image_img = property_image_pic
      ? property_image_pic.split(",")[0]
      : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png";
    updateState({
      propertyStatus: row.isinterested,
      displayname: row.propertyname,
      showChatMsg: false,
      profilePic: property_image_img,
      isPropertySelected: true,
      propertyChat: [],
      chatItineraryPropertyID: Id,
      ChatProperty: "",
      openChatBox: true,
      chatStatus: true,
      ChatMsg: true,
      openPropetyChatList: false,
    });
    chatItineraryPropertyID = Id;
    $("#transCount" + state.TransfereeId).text(
      parseInt($("#transCount" + state.TransfereeId).text()) > 0
        ? parseInt($("#transCount" + state.TransfereeId).text()) -
            parseInt($("#bd" + Id).text())
        : 1
    );

    if (
      parseInt($("#transCount" + state.TransfereeId).text()) -
        parseInt($("#bd" + Id).text()) <=
      0
    ) {
      $("#bdCount" + state.TransfereeId).hide();
    }
    $(".activeItem").removeClass("activeItem");
    $("#highlight" + state.TransfereeId).addClass("activeItem");
    $("#highlight" + Id).addClass("activeItem");
    $("#bd" + Id).text(0);
    $("#" + Id).hide();
    let ReadObj = {
      toId: sessionData.userUniqueId,
      fromId: state.TransfereeId,
      apiId: Id,
    };
    ChatProxy.invoke("MessageReadComplete", ReadObj);
    getSignalRConnection();
  }

  function getSignalRConnection() {
    SignalrConnection.start()
      .done(function (data) {
        updateState({ chatIdSignalR: data.id });
        var objC = {};
        objC.userId = sessionData.userUniqueId;
        objC.apiId = 0;
        objC.connectionId = data.id;
        objC.isconsultent = true;
        ChatProxy.invoke("RegisterOnlineUser", objC);
      })
      .fail(function () {
        console.log("failed in connecting to the signalr server");
      });
  }
  //   ---------- properties List of a transferee------------------

  async function getData(transfereeProperties, TransfereeId) {
    let ZillowBanglow = transfereeProperties.filter(function (element) {
      return (
        element.source != "zillow" &&
        element.propertyname != "" &&
        element.propertyname != null
      );
    });
    let i = 0;
    const promises = ZillowBanglow.map((Properties) => {
      if (
        state.PropertiesSortDate[Properties.propertyanalyticsuniqueId] !=
          undefined &&
        Properties.isinterested == true &&
        Properties.isactive == true
      ) {
        let Length =
          state.PropertiesSortDate[Properties.propertyanalyticsuniqueId].length;

        Properties.updatedDate =
          state.PropertiesSortDate[Properties.propertyanalyticsuniqueId][
            Length - 1
          ].datetime;
      } else {
        if (Properties.isinterested == true && Properties.isactive == true) {
          Properties.updatedDate = moment("07/07/1977 01:01:00")
            .add(i, "hours")
            .format("MM/DD/YYYY hh:mm:ss");
        } else {
          Properties.isinterested = false;
          Properties.updatedDate = moment("07/07/1976 01:01:00")
            .add(i, "hours")
            .format("MM/DD/YYYY hh:mm:ss");
        }
        i = i + 1;
        let countUnread = getUreadCount(
          Properties.propertyanalyticsuniqueId,
          TransfereeId
        );
        Properties.countUnread = countUnread;
      }
      return Properties;
    });

    // Wait for all promises to complete
    // await Promise.all(promises);
    responseTransfreeProperties = ZillowBanglow;
    sortResponseTransfereeProperties(chatMessagesMap);
    updateState({
      PropertiesLoader: 2,
      responseTransfreeProperties: ZillowBanglow,
    });

    let generalCategory = { ...state.generalProperty };
    generalCategory.countUnread = getUreadCount(
      generalCategory.id,
      TransfereeId
    );

    updateState({ generalProperty: generalCategory });
  }
  async function sortTransfereeList(TransfereeId) {
    console.log(TransfereeId, "TransfereeId");

    let propertyWithUnreadMsg = state.TransfereesList.filter(
      (property) => property.userUniqueId === TransfereeId
    );
    let otherProperties = state.TransfereesList.filter(
      (property) => property.userUniqueId !== TransfereeId
    );
    const updatedTransfereesList = [
      ...propertyWithUnreadMsg,
      ...otherProperties,
    ];
    $("#scroll-Top").scrollTop(0);
    updateState({
      TransfereesList: updatedTransfereesList,
    });
    console.log(updatedTransfereesList, "updatedTransfereesList");

    // Return the updated value if needed
    return updatedTransfereesList;
  }

  async function getChatPropertiesList(TransfereeId, Id) {
    // console.log(TransfereeId, Id, "TransfereeId, Id");
    let onlineUsers = localStorage.getItem("onlineusers");
    // console.log(onlineUsers, "onlineUsers");
    // chatItineraryPropertyID = "";
    let activeTransferee = state.TransfereesList.filter((transferee, id) => {
      return transferee.userUniqueId === TransfereeId;
    });
    // Check if activeTransferee is not empty and add the 'isOnline' key
    if (activeTransferee.length > 0) {
      activeTransferee = activeTransferee.map((transferee) => {
        return {
          ...transferee,
          isOnline: onlineUsers.includes(transferee.userUniqueId),
        };
      });
    }

    // activeTransferee now includes the isOnline key
    console.log(activeTransferee);

    getSignalRConnection();
    changeLoaderState("start");
    let GetTransfereePropertiesObject = {
      transfereeuniqueId: TransfereeId,
    };
    updateState({
      // showChatMsg: false,
      PropertiesLoader: 1,
      chatStatus: false,
      propertyList: true,
      activeTransferee: activeTransferee[0],
    });
    let transfereeProperties = await ApiServices.GetTransfereeProperties(
      GetTransfereePropertiesObject
    );
    let transfereepropertyList =
      transfereeProperties.message.transfereepropertyList;
    let myPropertiesList = transfereepropertyList.filter(function (element) {
      return element.source != "zillow";
    });
    if (transfereeProperties.error == 0) {
      updateState({
        TransfereeId: TransfereeId,
        activeTransferee: activeTransferee[0],
        userID: Id,
        GetTransfereeProperties: myPropertiesList,
      });
      changeLoaderState("stop");
    }
    getData(myPropertiesList, TransfereeId);
  }

  //   ------------------Transferee list------------------

  async function getSessionData() {
    try {
      let apiSessionData = await LoginStore.getLoginInfo({});
      apiSessionData.subscriptioncode =
        localStorage.getItem("loginSubscribeDetails") != null
          ? JSON.parse(localStorage.getItem("loginSubscribeDetails"))
              .subscriptioncode
          : "";
      return apiSessionData;
    } catch (err) {
      console.log(err);
    }
  }

  async function transfereeList() {
    changeLoaderState("start");
    sessionData = await getSessionData();
    updateState({
      loader: true,
      pageLoader: true,
    });
    let getTransfereeObj = {
      consultantuniqueid: sessionData.userUniqueId,
    };
    let TransfereesList_Result = await ApiServices.GetConsultantTransferees(
      getTransfereeObj
    );
    if (TransfereesList_Result.error == 0) {
      let transferees = TransfereesList_Result.message.transfereeslist;

      transferees = transferees.map((transferee) => {
        const createdAtDate = new Date(transferee.transfereecreatedat); // Convert the date string to a Date object
        const formattedDate = `${createdAtDate.getDate()} ${createdAtDate.toLocaleString(
          "default",
          { month: "short" }
        )}`; // Format it to "7 Oct"

        return {
          ...transferee,
          createdDate: formattedDate,
        };
      });

      updateState({
        TransfereesList: transferees,
        totalTransfereeList: transferees,
      });
      changeLoaderState("stop");
    }
    getSignalRConnection();
  }

  function increasePropertyMessageCount(propertyanalyticsuniqueId) {
    // console.log(responseTransfreeProperties, "responseTransfreeProperties");
    const propertyObject = responseTransfreeProperties.find(
      (obj) => obj.propertyanalyticsuniqueId === propertyanalyticsuniqueId
    );

    if (propertyObject) {
      // Check if countUnread key exists, if not, create it and set to 1, else increment by 1
      if (!propertyObject.hasOwnProperty("countUnread")) {
        propertyObject.countUnread = 1;
      } else {
        propertyObject.countUnread += 1;
      }
    }
  }

  async function processArrayResponse(data) {
    // console.log(
    //   data,
    //   " In function processArrayResponse , received single message from server"
    // );
    data.map((row, index) => {
      // push message into existing Map() propertyId: [messages]
      updateChatMessagesMap(row.apiId, row);
      increasePropertyMessageCount(row.apiId);
      sortResponseTransfereeProperties(chatMessagesMap);

      // destructuring just to trigger useEffect
      // responseTransfreeProperties = [...responseTransfreeProperties];

      // Jquery to show badge and increment count in transferees list
      $("#transCount" + row.fromId).text(
        parseInt($("#transCount" + row.fromId).text()) + parseInt(data.length)
      );
      $("#bdCount" + row.fromId).show();

      // logic for the general property uncount
      if (
        row.apiId != chatItineraryPropertyID &&
        row.fromId == state.TransfereeId
      ) {
        console.log("for general");
        $("#bd" + row.apiId).text(
          parseInt($("#bd" + row.apiId).text()) > 0
            ? parseInt($("#bd" + row.apiId).text()) + 1
            : 1
        );
        $("#" + row.apiId).show();
      }

      // when the property chat is open and message is for that property
      if (
        row.apiId == chatItineraryPropertyID &&
        row.fromId == state.TransfereeId
      ) {
        let ReadObj = {
          toId: sessionData.userUniqueId,
          fromId: state.TransfereeId,
          apiId: chatItineraryPropertyID,
        };
        ChatProxy.invoke("MessageReadComplete", ReadObj);
      }
    });

    updateState({
      showChatMsg: true,
    });
  }

  function TransfereegetUreadCount(count) {
    // console.log(count, "count");

    $(".transBdCount").text(0);
    //$(".msg_count").hide();
    if (count != undefined) {
      const sumByFromId = {};
      // Iterate through the data array
      count.forEach((entry) => {
        const fromId = entry.FromId;
        const count = entry.Count;

        if (!sumByFromId[fromId]) {
          sumByFromId[fromId] = count;
        } else {
          sumByFromId[fromId] += count;
        }
      });

      for (const fromId in sumByFromId) {
        const countUnread = sumByFromId[fromId];
        updateState({
          countUnreadId: sumByFromId,
        });
        if (countUnread > 0) {
          $("#transCount" + fromId).text(countUnread);
          $("#bdCount" + fromId).show();
        } else {
          $("#bdCount" + fromId).hide();
        }
      }
      return;
    } else {
      $("#bdCount").hide();
    }
  }

  function unreadTransfereeList(list, count, countIds) {
    // Sort function for state.TransfereesList using userUniqueId
    const sortedList = [...list].sort((a, b) => {
      const priorityA = countIds[a.userUniqueId] || Infinity; // Assign Infinity if not found
      const priorityB = countIds[b.userUniqueId] || Infinity; // Assign Infinity if not found

      return priorityA - priorityB;
    });

    return sortedList; // Return the sorted list
  }

  function processObjectResponse(data) {
    // push the data.messagesList into propertyChat
    let count = Object.assign([], state.countUnread);
    count.push(data.count);
    updateState({ countUnread: count });
    TransfereegetUreadCount(data.count);

    if (data.messagesList) {
      updateState({ loader: false });
      //   propertyChat = deepCopy(data.messagesList);
      chatMessagesMap = prepareMapFromMessagesList(data.messagesList);
    }

    updateState({ showChatMsg: true });
  }

  //   ------------------component did mount logic -----------

  function sortResponseTransfereeProperties(chatMessagesMap) {
    responseTransfreeProperties.sort((a, b) => {
      let a_apiId = a.propertyanalyticsuniqueId,
        b_apiId = b.propertyanalyticsuniqueId;

      let a_msgList = chatMessagesMap.get(a_apiId) || [], // Use empty array if undefined
        b_msgList = chatMessagesMap.get(b_apiId) || [];

      let a_msgListLength = a_msgList.length,
        b_msgListLength = b_msgList.length;

      let a_lastMsgTime =
          a_msgListLength > 0 ? a_msgList[a_msgListLength - 1].datetime : 0,
        b_lastMsgTime =
          b_msgListLength > 0 ? b_msgList[b_msgListLength - 1].datetime : 0;

      return new Date(b_lastMsgTime) - new Date(a_lastMsgTime);
    });

    responseTransfreeProperties = deepCopy(responseTransfreeProperties);
    updateState({
      responseTransfreeProperties: deepCopy(responseTransfreeProperties),
    });
  }

  async function processMessageFromServer(data) {
    // console.log("Processing message from server", data);

    if (data.messagesList == undefined && data.onlineusers == undefined) {
      // single message from server
      console.log("Proceeding with array logic");
      processArrayResponse(data);
    } else if (data.count != undefined) {
      console.log("Proceeding with object logic");

      processObjectResponse(data);
    }
    if (data.onlineusers != undefined && data.onlineusers.length > 0) {
      processLogoutUsersData(data.onlineusers);
      updateState({ onlineusers: data.onlineusers });
    }

    scrollDivBottom();
  }

  async function makeConnectionSignalR() {
    $(function () {
      var ChatServerUrl = "https://apis.relonavigator.com/";
      var ChatUrl = ChatServerUrl + "signalr";
      SignalrConnection = $.hubConnection(ChatUrl, {
        useDefaultPath: false,
      });

      ChatProxy = SignalrConnection.createHubProxy("ChatHub");

      // getSignalRConnection();
      ChatProxy.on("getLogoutUsersList", (data) =>
        processLogoutUsersData(data.onlineusers)
      );

      ChatProxy.on("messageFromServer", processMessageFromServer.bind(this));

      SignalrConnection.start()
        .done(function (data) {
          updateState({ chatIdSignalR: data.id });

          var objC = {};
          objC.userId = sessionData.userUniqueId;
          objC.apiId = chatItineraryPropertyID;
          objC.connectionId = data.id;
          objC.isconsultent = true;
          // let changeSession = localStorage.getItem("ChatSession");
          // if (changeSession == null || changeSession == undefined) {
          //   ChatProxy.invoke("RegisterOnlineUser", objC);
          //   ChatProxy.invoke("LogoutUserChatt", { userId: "" });
          // }
          // logoutFromChat();
          var ReadObj = {
            fromId: sessionData.userUniqueId,
            toId: state.TransfereeId,
            apiId: chatItineraryPropertyID,
          };
          ChatProxy.invoke("MessageReadComplete", ReadObj);
          ChatProxy.invoke("LogoutUserChatt", { userId: "" });
        })
        .fail(function () {
          console.log("failed in connecting to the signalr server");
        });

      changeLoaderState("stop");
    });
  }

  function messageText(event) {
    event.persist();
    let textMessage = event.target.value;
    setTextMessage(textMessage);
  }

  function sendMessage() {
    let sendMessageObj = {
      apiId: chatItineraryPropertyID,
      message: TextMessage.trim(),
      fromId: sessionData.userUniqueId,
      toId: state.TransfereeId,
      datetime: moment()
        .tz("America/Los_Angeles")
        .format("MM/DD/YYYY HH:mm:ss"),
      isconsultant: true,
    };

    if (TextMessage.trim() !== "") {
      sortTransfereeList(state.TransfereeId);
      // let updatedPropertiesList = movePropertyToTop(
      //   responseTransfreeProperties,
      //   chatItineraryPropertyID
      // );
      // responseTransfreeProperties = deepCopy(updatedPropertiesList);

      updateChatMessagesMap(chatItineraryPropertyID, sendMessageObj);
      sortResponseTransfereeProperties(chatMessagesMap);

      ChatProxy.invoke("SendMessage", sendMessageObj);

      scrollDivBottom();
      $("#InputMsg").val("");
      $("#InputMsg2").val("");
      $("#InputMsg2").focus();

      setTextMessage("");
    }
  }
  function searchTransferee(value) {
    const { TransfereesList } = state;
    let list = state.totalTransfereeList;
    if (value != "") {
      const filterTransferee = TransfereesList.filter(
        (o) =>
          // Object.keys(o).some((k) =>
          //   String(o[k]).toLowerCase().includes(value.toLowerCase())
          o.userFirstName.toLowerCase().includes(value.toLowerCase()) ||
          o.EmailId.toLowerCase().includes(value.toLowerCase())
      );
      // console.log(filterTransferee, "filterTransferee");
      setState((prev) => ({ ...prev, TransfereesList: filterTransferee }));
    } else {
      setState((prev) => ({ ...prev, TransfereesList: list }));
    }
  }

  function searchProperty(value) {
    const propertyList = responseTransfreeProperties;
    let list = state.responseTransfreeProperties;
    if (value != "") {
      const filterProperty = propertyList.filter(
        (o) =>
          // Object.keys(o).some((k) =>
          //   String(o[k]).toLowerCase().includes(value.toLowerCase())
          o.propertyname.toLowerCase().includes(value.toLowerCase()) ||
          o.address.toLowerCase().includes(value.toLowerCase())
      );
      console.log(filterProperty, "filterProperty");

      updateState({ responseTransfreeProperties: filterProperty });
    } else {
      updateState({ responseTransfreeProperties: propertyList });
    }
  }
  function prepareMapFromMessagesList(messages) {
    const groupedMessages = messages.reduce((grouped, message) => {
      const { apiId } = message;
      if (!grouped.has(apiId)) {
        grouped.set(apiId, []);
      }
      grouped.get(apiId).push(message);
      return grouped;
    }, new Map());

    // Sort each group by datetime
    groupedMessages.forEach((messages) => {
      messages.sort((a, b) => new Date(a.datetime) - new Date(b.datetime));
    });

    return groupedMessages;
  }

  function updateChatMessagesMap(apiId, sendMessageObj) {
    let propertyMessagesList = chatMessagesMap.get(apiId);
    if (!propertyMessagesList) {
      propertyMessagesList = [];
    }
    if (Array.isArray(sendMessageObj)) {
      propertyMessagesList.push(sendMessageObj[0]);
    } else {
      propertyMessagesList.push(sendMessageObj);
    }
    chatMessagesMap.set(apiId, propertyMessagesList);
  }

  useEffect(() => {
    if (sessionData.consultantUId && state.onlineusers?.length) {
      processLogoutUsersData(state.onlineusers);
    }
  }, [sessionData.consultantUId, state.onlineusers, state.TransfereesList]);

  useEffect(() => {
    if (chatMsgsRef.current) {
      chatMsgsRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [chatMessagesMap]);

  const sortedList = useMemo(() => {
    if (state.TransfereesList.length > 0 && state.countUnread.length > 0) {
      return unreadTransfereeList(
        state.TransfereesList,
        state.countUnread,
        state.countUnreadId
      );
    }
    return state.TransfereesList;
  }, [state.TransfereesList, state.countUnread, state.countUnreadId]);

  useEffect(() => {
    // Only update the state if `sortedList` is different
    if (JSON.stringify(sortedList) !== JSON.stringify(state.TransfereesList)) {
      updateState({ TransfereesList: sortedList });
    }
  }, [sortedList, state.TransfereesList]);

  function openChatboxPopup() {
    updateState({ openChatboxPopup: true });
  }
  function closeChatList() {
    updateState({
      openChatboxPopup: false,
      chatItineraryPropertyID: 0,
      isPropertySelected: false,
      openChatBox: false,
      chatStatus: false,
      ChatMsg: false,
      propertyList: false,
      responseTransfreeProperties: [],
    });
  }

  function changeLoaderState(mode) {
    if (mode === "start") {
      updateState({ loader: true });
    } else {
      updateState({ loader: false });
    }
  }
  const closeFeedBack = () => {
    setState((prev) => ({
      ...prev,
      feedBackPopUp: false,
      succesMsg: "",
      experience: "",
      feedbacktype: "General feedback",
      feeddescription: "",
      FromEmail: "",
    }));
  };

  let backgroundColor = location.pathname == "/city-landing" ? "clrchange" : "";
  let displayone = { propertyname: "General" };
  const children = [];
  var dataSource = [
    "Feature request",
    "General feedback",
    "Report an issue",
    "Support/Account/Billing",
    "UI feedback",
  ];
  const Close = () => {
    $(function () {
      $("document").ready(async function () {
        $("#ModalTersm-of-use").modal("hide");
      });
    });
  };
  const closePopUP = () => {
    $(function () {
      $("document").ready(async function () {
        $("#ModalTersm-of-use2").modal("hide");
      });
    });
  };
  for (let i = 0; i < dataSource.length; i++) {
    children.push(
      <Select.Option key={dataSource[i]}>{dataSource[i]}</Select.Option>
    );
  }
  return (
    <div className="ds-profile-page chat-footer-icon">
      <div className="chat_footer chat_width chat_footer-fullview">
        <div>
          <a
            href="javascript:void(0)"
            className="chaticon"
            onClick={openChatboxPopup}
          >
            <img src="/assets/images/Group 74.png" alt="" />
          </a>
        </div>

        {state.openChatboxPopup == true ? (
          <div className="bd-example-modal-lg communitie_pop modal-backdrop ">
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content max-width-initial ">
                <div className="only_close">
                  <span
                    className="close"
                    data-dismiss="modal"
                    onClick={closeChatList}
                  >
                    &times;
                  </span>
                </div>
                <div className="modal-body text-left p-0 ">
                  <div className="communitie_pop">
                    <div className="d-flex d-flex-chat">
                      <div className="item">
                        <h5>Transferees</h5>
                        <div class="input-group mb-3">
                          <span class="input-group-text">
                            <i class="bi bi-search"></i>
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Username"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={(e) => searchTransferee(e.target.value)}
                          />
                        </div>
                        <div className="contacts_list" id="scroll-Top">
                          {Array.isArray(state.TransfereesList) &&
                            state.TransfereesList.map((row, index) => {
                              return row.EmailId != "" &&
                                row.subscriptioncode != "ReloNavigator" &&
                                row.userFirstName !=
                                  "v5QCdDCaM9UdpHurW0z8nw==" ? (
                                <div
                                  key={index}
                                  className={
                                    state.TransfereeId == row.userUniqueId
                                      ? "chat_item d-flex align-items-center justify-content-start activeItem"
                                      : "chat_item d-flex align-items-center justify-content-start"
                                  }
                                  id={"highlight" + row.userUniqueId}
                                  onClick={() =>
                                    getChatPropertiesList(
                                      row.userUniqueId,
                                      row.userId
                                    )
                                  }
                                >
                                  <div className="item_img bd-highlight">
                                    <FontAwesomeIcon icon={faUserCircle} />
                                    <div
                                      className="msg_count hideBadge"
                                      id={"bdCount" + row.userUniqueId}
                                    >
                                      <span
                                        className="badge transBdCount"
                                        id={"transCount" + row.userUniqueId}
                                      >
                                        0
                                      </span>
                                    </div>
                                  </div>
                                  <div className="chat_dtls">
                                    <p>
                                      {row.userFirstName}{" "}
                                      <i
                                        className="fa fa-circle offline"
                                        id={"online" + row.userUniqueId}
                                      ></i>
                                    </p>
                                    <span>{row.EmailId}</span>
                                    <span className="dataTime">
                                      {" "}
                                      {row.createdDate}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                ""
                              );
                            })}
                        </div>
                      </div>

                      {state.propertyList && (
                        <div className="item properties">
                          <h5>Properties</h5>
                          <div class="input-group mb-3">
                            <span class="input-group-text">
                              <i class="bi bi-search"></i>
                            </span>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Propertyname"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              onChange={(e) => searchProperty(e.target.value)}
                            />
                          </div>
                          {state.PropertiesLoader == 2 ? (
                            <div className="contacts_list">
                              <div
                                key={100}
                                className="chat_item d-flex align-items-center justify-content-start"
                                id={
                                  "highlight" +
                                  state.generalProperty
                                    .propertyanalyticsuniqueId
                                }
                                onClick={() =>
                                  openChatBox(
                                    state.generalProperty
                                      .propertyanalyticsuniqueId,
                                    state.generalProperty.property_image,
                                    state.generalProperty
                                  )
                                }
                              >
                                <div className="item_img bd-highlight">
                                  <i className="bi bi-check-circle-fill"></i>
                                  <img
                                    src={
                                      state.generalProperty.property_image !=
                                        undefined &&
                                      state.generalProperty.property_image != ""
                                        ? state.generalProperty.property_image.split(
                                            ","
                                          )[0]
                                        : "assets/images/default-img.jpg"
                                    }
                                    alt=""
                                  />
                                  <div
                                    className={
                                      "msg_count" +
                                      (state.generalProperty.countUnread > 0
                                        ? ""
                                        : " hideBadge")
                                    }
                                    id={
                                      state.generalProperty
                                        .propertyanalyticsuniqueId
                                    }
                                  >
                                    <span
                                      className="badge"
                                      id={
                                        "bd" +
                                        state.generalProperty
                                          .propertyanalyticsuniqueId
                                      }
                                    >
                                      {state.generalProperty.countUnread}
                                    </span>
                                  </div>
                                </div>
                                <div className="chat_dtls">
                                  <span>{state.generalProperty.address} </span>
                                </div>
                              </div>

                              {state.responseTransfreeProperties.map(
                                (row, index) => {
                                  return (
                                    <IndividualPropertyTab
                                      openChatBox={openChatBox}
                                      state={state}
                                      row={row}
                                      key={index}
                                    />
                                  );
                                }
                              )}
                            </div>
                          ) : (
                            state.PropertiesLoader === 1 && (
                              <div
                                className="contacts_list"
                                style={{ paddingTop: "100px" }}
                              >
                                <Spin />
                              </div>
                            )
                          )}
                        </div>
                      )}

                      {state.chatStatus && (
                        <div className="item item2">
                          <div className="">
                            <div className="contactDP">
                              <img
                                src="../assets/images/profile-pic.svg"
                                alt=""
                              />
                              <h5>
                                {state.activeTransferee.userFirstName}
                                <div
                                  className={
                                    state.activeTransferee.isOnline === true
                                      ? "circle online"
                                      : "circle offline"
                                  }
                                  // id={"online" + state.activeTransferee.userUniqueId}
                                >
                                  {" "}
                                  Online{" "}
                                </div>{" "}
                              </h5>
                              <i className="bi bi-three-dots-vertical"></i>
                            </div>
                          </div>

                          <div
                            className="chat_msgs"
                            id="chatPopup"
                            ref={chatMsgsRef}
                          >
                            {state.ChatMsg == true &&
                              chatMessagesMap?.size &&
                              chatMessagesMap
                                .get(chatItineraryPropertyID)
                                ?.map((row, index) => {
                                  return (state.showChatMsg == true &&
                                    sessionData.userUniqueId == row.fromId &&
                                    row.toId == state.TransfereeId &&
                                    row.apiId ==
                                      state.chatItineraryPropertyID) ||
                                    (state.showChatMsg == true &&
                                      sessionData.userUniqueId == row.toId &&
                                      row.fromId == state.TransfereeId &&
                                      row.apiId ==
                                        state.chatItineraryPropertyID) ? (
                                    <div key={index}>
                                      {sessionData.userUniqueId ==
                                      row.fromId ? (
                                        <div className="right_chat_view ">
                                          <div>
                                            <div className="right-user-time">
                                              {moment(row.datetime).calendar()}
                                            </div>
                                            <div className="right_chat">
                                              {row.message}
                                              <i className="bi bi-check-all ms-2"></i>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="left_chat_view ">
                                          <div>
                                            <div className="left-user-time">
                                              {row.firstname} {row.lastname},{" "}
                                              {moment(row.datetime).calendar()}
                                            </div>
                                            <div
                                              key={index}
                                              className="left_chat"
                                            >
                                              {row.message}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  );
                                })}

                            {state.showChatMsg == false &&
                            state.chatItineraryPropertyID != 0 ? (
                              <div style={{ paddingTop: "100px" }}>
                                <Spin />
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="clearfix"></div>
                          </div>

                          {state.ChatMsg == true && (
                            <div className="sent_box d-flex align-items-center">
                              <div className="position-relative w-100">
                                <i class="bi bi-emoji-smile"></i>
                                <input
                                  type="text"
                                  // onKeyPress={handleKeyPress}
                                  id="InputMsg2"
                                  defaultValue={TextMessage}
                                  placeholder={
                                    state.propertyStatus == true
                                      ? "Send message"
                                      : "Property deactivated"
                                  }
                                  disabled={
                                    state.propertyStatus == true ? false : true
                                  }
                                  onChange={messageText}
                                />
                                {/* <i class="bi bi-plus-circle"></i> */}
                              </div>

                              <span
                                tabIndex="2"
                                className={
                                  TextMessage != "" ? "" : "chatIconColor"
                                }
                                onClick={sendMessage}
                              >
                                <img src="../assets/images/Vector.svg" />
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="footer_main">
        <div className="container ">
          <div
            className={
              "footer_copyright text-center pt-2 pb-1 " + backgroundColor
            }
          >
            <div className="footer_copyright ">
              <ul className="footer-ul-list">
                <li>
                  <a
                    to="#"
                    data-toggle="modal"
                    data-target="#ModalTersm-of-use2"
                    onClick={() => feedBack("policy")}
                  >
                    Privacy Policy{" "}
                  </a>
                </li>
                <li>
                  <a
                    to="#"
                    data-toggle="modal"
                    data-target="#ModalTersm-of-use"
                    style={{ zIndex: 1060 }}
                    onClick={() => feedBack("terms")}
                  >
                    Terms of Use{" "}
                  </a>
                </li>
                <li>
                  <a
                    className="feedback-view"
                    href="javascript:void(0)"
                    onClick={() => feedBack("feedback")}
                  >
                    Give feedback
                  </a>
                </li>
                <li>
                  <a>Patent Pending</a>
                </li>
              </ul>
            </div>
            <p style={{ fontSize: "12px" }}>
              Made with <i className="fa fa-heart"></i> in California, USA
            </p>
          </div>
        </div>
      </div>
      {state.popUpType == "policy" && (
        <div
          className="modal fade "
          id="ModalTersm-of-use2"
          tabIndex="-1"
          style={{
            opacity: "1",
            transition: "none",
            zIndex: 1060,
          }}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="width-80per pd-y-50">
                <div className="only_close">
                  <span
                    className="pointer close"
                    data-dismiss="modal"
                    onClick={() => closePopUP()}
                  >
                    {" "}
                    ×{" "}
                  </span>
                </div>
                <h6>Privacy Policy</h6>
                <div className="ifram-html-view">
                  <iframe
                    src="https://app.termly.io/document/privacy-policy/ba9c43e8-c650-4086-8e0e-dc0d4e603322"
                    title="Terms and Conditions"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {state.popUpType == "feedback" && (
        <FeedBackPopUp
          state={state}
          closeFeedBack={closeFeedBack}
          setState={setState}
        />
      )}
      {state.popUpType == "terms" && (
        <div
          className="modal fade "
          id="ModalTersm-of-use"
          tabIndex="-1"
          style={{
            opacity: "1",
            transition: "none",
            zIndex: 1060,
          }}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="width-80per pd-y-50">
                <div className="only_close">
                  <span
                    className="pointer close"
                    data-dismiss="modal"
                    onClick={() => Close()}
                  >
                    {" "}
                    ×{" "}
                  </span>
                </div>
                <h6>Terms of use</h6>
                <div className="ifram-html-view">
                  <iframe
                    src="https://app.termly.io/document/terms-and-conditions/eedb777d-0087-44e5-8a20-0168e9e1a364"
                    title="Terms and Conditions"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const IndividualPropertyTab = ({ openChatBox, state, row }) => {
  // console.log(row, "row");
  return (
    <div
      className={
        chatItineraryPropertyID == row.propertyanalyticsuniqueId
          ? "chat_item d-flex align-items-start justify-content-start activeItem"
          : "chat_item d-flex align-items-start justify-content-start"
      }
      id={
        "highlight" +
        (row.id != undefined ? row.id : row.propertyanalyticsuniqueId)
      }
      onClick={() =>
        openChatBox(
          row.id != undefined ? row.id : row.propertyanalyticsuniqueId,
          row.property_image,
          row
        )
      }
    >
      <div className="item_img bd-highlight">
        <i className="bi bi-check-circle-fill"></i>
        <img
          src={
            row.property_image
              ? row.property_image.split(",")[0]
              : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
          }
          alt=""
        />
        <div
          className={"msg_count" + (row.countUnread > 0 ? "" : " hideBadge")}
          id={row.propertyanalyticsuniqueId}
        >
          <span className="badge" id={"bd" + row.propertyanalyticsuniqueId}>
            {row.countUnread}
          </span>
        </div>
      </div>
      <div className="chat_dtls">
        <span>
          {row.propertyname} - {row.address}
        </span>
        <b className="userPropertyStatus">
          {row.isinterested != true ? "Inactive" : ""}
        </b>
      </div>
    </div>
  );
};
export default FooterWithchat;
