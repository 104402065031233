import React, { useEffect, useState } from "react";
import * as $ from "jquery";
import { GoogleMap } from "@react-google-maps/api";
import MapPoi from "./mapPointofInterests";
import AppController from "../../../controllers/appController";

function PoiSearch(props) {
  // console.log(alert("transfree from consultant"));
  const [state, setState] = useState({
    propertysList: [],
    mapRes: [],
    mapIndex: 0,
    propertysLoading: false,
    address: "",
    addressLat: 0.0,
    addressLng: 0.0,
    index: 0,
    map: {},
    completeAddress: {
      street_number: "short_name",
      route: "long_name",
      locality: "long_name",
      administrative_area_level_1: "short_name",
      country: "long_name",
      postal_code: "short_name",
    },
    searchLoader: false,
    modalShow: false,
    modalpopupInfo: {},
    activeNumber: -1,
    zillowdata: false,
    sharedLiving: true,
    activeProperties: [],
    GetTransfereeProperties: [],
    isSharedliving: "",
    bungalowMarkets: [],
    slug: "",
    isLoad: false,
    profileAddress: "",
    tooltipText: "",
    searchedData: [],
    propertyloader: false,
    pageLoader: true,
    data: {
      latitude: 37.7739852,
      longitude: -122.3912407,
      state_name: "CA",
      city_name: "San Francisco",
      city: "San Francisco",
      address: "185 Channel Street",
    },
    center: {
      lat:
        Object.keys(props.communityObject).length > 0
          ? props.communityObject.latitude
          : 0,
      lng:
        Object.keys(props.communityObject).length > 0
          ? props.communityObject.longitude
          : 0,
    },
    schoolsList: [],
    greatSchools: [],
    types: [
      {
        name: "Restaurants",
        type: "restaurant",
        faicon: "fa fa-cutlery",
        icon: "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/restarent.png",
      },
      {
        name: "Bars",
        type: "bar",
        faicon: "fa fa-glass",
        icon: "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/bars.png",
      },
      {
        name: "Groceries",
        type: "supermarket",
        faicon: "fa fa-shopping-bag",
        icon: "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/groceries.png",
      },
      {
        name: "Parks",
        type: "park",
        faicon: "fa fa-tree",
        icon: "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/parks.png",
      },
      {
        name: "Shopping",
        type: "shopping_mall",
        faicon: "fa fa-shopping-cart",
        icon: "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/shopping.png",
      },
      {
        name: "Entertainment",
        type: "night_club",
        faicon: "fa fa-film",
        icon: "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/entertainment.png",
      },
    ],
    type: ["restaurant"],
    typeName: "",
    location: [],
    placeSearched: [],
    placeSearchedMarkers: [],
    markerList: [],
    showlist: false,
    showSearch: false,
    typeicon: 0,
    directionsService: {},
    directionsDisplay: {},
    zipId: "",
    commuteData: {
      latitude: "",
      longitude: "",
      state_name: "",
      city_name: "",
      address: "",
    },
    poiList: [],
    poi: [],
    pointofin: [],
    Loader: false,
    isProperty: false,
    workAddress: "",
    worklatitude: "",
    worklongitude: "",
  });
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      data: props.data,
      isProperty: props.isProperty,
    }));
    async function fetchData() {
      try {
        let workAddress = JSON.parse(await localStorage.getItem("workAddress"));
        workAddress = workAddress.preferencesanswer;
        // console.log(workAddress);
        await setState((prev) => ({
          ...prev,
          workAddress: workAddress,
        }));
        jQuery(function ($) {
          $.getJSON(
            "https://maps.googleapis.com/maps/api/geocode/json?address=" +
              workAddress +
              "&key=AIzaSyDiSo4yN97tS6CIfMqJCXItYCwgzVkIJVc",
            (success) => {
              // console.log(workAddress, "success", success);
              //       setState((prev) => ({
              //         ...prev,
              //         worklatitude: success.results[0].geometry.location.lat,
              //         worklongitude: success.results[0].geometry.location.lng,
              //       }));
            }
          );
        });
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  const loadSchoolMarkers = async (type) => {
    // console.log(type, "type", state.data);
    setState((prev) => ({
      ...prev,
      poiList: [],
      mapRes: [],
      pointofin: [],
      Loader: true,
    }));

    const poi = await AppController.fourSquarData(state.data, type);
    setState((prev) => ({ ...prev, poi: poi }));
    // let list;
    // for (let i = 0; i <= poi.length; i++) {
    //   list += poi[i].venue;
    //   return list;
    // }
    // console.log(list, "list");
    // setState((prev) => ({ ...prev, poiList: list }));
    if (state.pointofin.length > 0) {
      setState((prev) => ({
        ...prev,
        mapRes: _.concat(state.mapRes, {
          address: state.data.address,
          addressCity: "",
          city: state.data.city,
          latitude: state.data.latitude,
          longitude: state.data.longitude,

          name: state.data.city,
          images: "",
          zoom: 10 + i,
          iconShowCustom: true,
          isWork: false,

          isProperty: state.isProperty,
          communityObject: {
            latitude: state.data.latitude,
            longitude: state.data.longitude,
          },
        }),
      }));
      await setState((prev) => ({
        ...prev,
        mapRes: _.concat(state.mapRes, {
          address: state.workAddress,
          addressCity: "",
          city: state.workAddress,
          latitude: state.worklatitude,
          longitude: state.worklongitude,
          name: state.workAddress,
          images: "",
          zoom: 10 + i,
          iconShowCustom: false,
          isWork: true,
          isProperty: state.isProperty,
          communityObject: {
            latitude: state.worklatitude,
            longitude: state.worklongitude,
          },
        }),
      }));
      for (var i = 0; i < state.pointofin.length; i++) {
        setState((prev) => ({
          ...prev,
          mapRes: _.concat(state.mapRes, {
            address:
              state.pointofin[i].venue.location.formattedAddress.toString(),
            addressCity: "",
            city: state.pointofin[i].venue.name,
            latitude: state.pointofin[i].venue.location.lat,
            longitude: state.pointofin[i].venue.location.lng,
            name: state.pointofin[i].venue.location.name,
            schoolName: state.pointofin[i].venue.name,
            images: "",
            zoom: 10 + i,
            iconShowCustom: false,
            isWork: false,

            isProperty: state.isProperty,
            communityObject: {
              latitude: state.pointofin[i].venue.location.lat,
              longitude: state.pointofin[i].venue.location.lng,
            },
          }),
        }));

        mapView(state.mapRes);
      }

      setTimeout(async () => {
        setState((prev) => ({ ...prev, Loader: false }));
      }, 1500);
    } else {
      setState((prev) => ({
        ...prev,
        mapRes: _.concat(state.mapRes, {
          address: state.data.city,
          addressCity: "",
          city: state.data.city,
          latitude: state.data.latitude,
          longitude: state.data.longitude,
          name: state.data.city,
          images: "",
          zoom: 10 + i,
          iconShowCustom: true,
          isWork: false,

          isProperty: state.isProperty,
          communityObject: {
            latitude: state.data.latitude,
            longitude: state.data.longitude,
          },
        }),
      }));
      setState((prev) => ({
        ...prev,
        mapRes: _.concat(state.mapRes, {
          address: state.workAddress,
          addressCity: "",
          city: state.workAddress,
          latitude: state.worklatitude,
          longitude: state.worklongitude,
          name: state.workAddress,
          images: "",
          zoom: 10 + i,
          iconShowCustom: false,
          isWork: true,
          isProperty: state.isProperty,
          communityObject: {
            latitude: state.worklatitude,
            longitude: state.worklongitude,
          },
        }),
      }));
      // console.log(state.mapRes, " state.mapRes");
      // mapView(state.mapRes);
      setState((prev) => ({ ...prev, Loader: false }));
    }
  };
  return (
    <div>
      <MapPoi
        poiData={props.data}
        state={state}
        loadSchoolMarkers={loadSchoolMarkers}
      />
    </div>
  );
}

export default PoiSearch;
