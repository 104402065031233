import React, { useEffect } from "react";

function ForCompaniesPage() {
  useEffect(() => {
    var shadow = "0px 10px 20px #080808;";
    $("#first").click(function () {
      styles("#first", 0, 40, 0, 1, shadow, 1, 0.8); // Inactive
      styles("#second", 0, 0, 0, 1, shadow, 99, 1); // Active
      styles("#third", 0, 20, 0, 1, shadow, 9, 0.9); // Inactive
      activateDot("#dot2");
    });

    $("#second").click(function () {
      styles("#first", 0, 20, 0, 1, shadow, 9, 0.9); // Inactive
      styles("#second", 0, 40, 0, 1, shadow, 1, 0.8); // Inactive
      styles("#third", 0, 0, 0, 1, shadow, 99, 1); // Active
      activateDot("#dot3");
    });

    $("#third").click(function () {
      styles("#first", 0, 0, 0, 1, shadow, 99, 1); // Active
      styles("#second", 0, 20, 0, 1, shadow, 9, 0.9); // Inactive
      styles("#third", 0, 40, 0, 1, shadow, 1, 0.8); // Inactive
      activateDot("#dot1");
    });

    $(".dot").click(function () {
      var dotId = $(this).attr("id");
      if (dotId === "dot1") {
        $("#third").trigger("click");
      } else if (dotId === "dot2") {
        $("#first").trigger("click");
      } else if (dotId === "dot3") {
        $("#second").trigger("click");
      }
    });

    function styles(item_id, x, y, z, opacity, shadow, zIndex, scaleX) {
      //   console.log(item_id, "===", shadow);
      $(item_id).css({
        transform:
          "translate3d(" +
          x +
          "px, " +
          y +
          "px, " +
          z +
          "px) scaleX(" +
          scaleX +
          ")",
        opacity: opacity,
        "box-shadow": shadow,
        "z-index": zIndex, // Update z-index dynamically
      });
    }

    function activateDot(dotId) {
      $(".dot").removeClass("active"); // Remove 'active' from all dots
      $(dotId).addClass("active"); // Add 'active' to the clicked dot
    }
  }, []);

  const demoBtn = () => {
    location.href = "/contact-us";
  };
  return (
    <div>
      <div className="bannerPart innerPage">
        <div className="container d-flex justify-content-center align-items-center text-center h-100">
          <div className="forsubText d-md-grid" data-aos="fade-up">
            <h1 className="mb-3">
              Empower Your Destination Services with ReloNavigator
            </h1>
            <p className="col-xl-8 d-flex justify-content-between flex-column align-items-start">
              ReloNavigator provides Destination Service companies with the
              tools they need to efficiently manage consultants, streamline
              operations, and deliver high-quality service to clients. From
              reporting to full visibility into transferee progress, our
              platform helps you stay in control.
              <button
                type="button"
                className="btn mt-4"
                onClick={() => demoBtn()}
              >
                Request a Demo
              </button>
            </p>
          </div>
        </div>
      </div>

      <div className="row m-0">
        <div className="col-lg-7 mb-3 mb-lg-0 ps-lg-0" data-aos="fade-up">
          <img
            src="assets/publicImages/companiesImages/for-companies-1.png"
            alt=""
            width="100%"
            className="h-100"
          />
        </div>
        <div className="col-lg-5 pe-lg-0" data-aos="fade-up">
          <img
            src="assets/publicImages/companiesImages/for-companies-2.png"
            alt=""
            width="100%"
            className="h-100"
          />
        </div>
      </div>

      <div className="my-6rem">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-6">
              <h2>
                Manage Consultants, Track Performance, and Deliver Results
              </h2>
            </div>
            <div className="col-lg-6">
              <p>
                ReloNavigator is designed to give DS companies everything they
                need to manage relocation consultants and deliver seamless
                services. Our platform offers tools to track consultant
                performance, manage appointments, and ensure transferee
                satisfaction. With full visibility into every step of the
                relocation process, you can enhance your operations and meet the
                needs of your clients with ease.
              </p>
            </div>
          </div>

          <div className="tials for-companiesTails mb-0">
            <div className="box active" data-aos="fade-up">
              <img
                src="assets/publicImages/companiesImages/person-edit.png"
                alt=""
              />
              <h3>Manage Consultants</h3>
              <p>
                Track and manage consultant activities, appointments, and
                performance in one centralized dashboard.
              </p>
              {/* <a href="#">Read More</a> */}
            </div>
            <div className="box active" data-aos="fade-up">
              <img
                src="assets/publicImages/companiesImages/calendar-edit.png"
                alt=""
              />
              <h3>Streamlined Scheduling</h3>
              <p>
                Coordinate consultant schedules and transferee appointments with
                efficiency and transparency.
              </p>
              {/* <a href="#">Read More</a> */}
            </div>
            <div className="box active" data-aos="fade-up">
              <img
                src="assets/publicImages/companiesImages/chart-multiple.png"
                alt=""
              />
              <h3>Performance Insights</h3>
              <p>
                Monitor consultant performance with real-time data, ensuring
                your team stays on track and delivers top-tier service.
              </p>
              {/* <a href="#">Read More</a> */}
            </div>
          </div>
        </div>
      </div>

      <div className="relo-activities-section">
        <div className="container">
          <div className="relocation-content col-lg-6 pe-5">
            <h2>
              Achieve Complete Transparency Across All Relocation Activities
            </h2>
            <p>
              ReloNavigator offers DS companies full visibility into every
              aspect of the transferee journey. From home finding to Area Tours,
              you'll have a holistic view of each transferee’s progress,
              ensuring that every step is optimized and no detail is missed.
            </p>

            <div className="card-view mt-5">
              <div className="item active">
                <h3>Comprehensive Tracking</h3>
                <p>
                  Monitor every phase of the relocation process with real-time
                  updates.
                </p>
                <a href="#">Read More</a>
              </div>
              <div className="item">
                <h3>Holistic View</h3>
                <p>
                  Gain insights into transferee preferences, property
                  recommendations, and key milestones.
                </p>
                <a href="#">Read More</a>
              </div>
              <div className="item">
                <h3>Operational Efficiency</h3>
                <p>
                  Identify bottlenecks and optimize workflows for maximum
                  efficiency.
                </p>
                <a href="#">Read More</a>
              </div>
            </div>
          </div>

          <div className=" laptop-image col-lg-6">
            <img
              src="assets/publicImages/companiesImages/laptop-work.png"
              alt="Laptop and work essentials"
            />
          </div>
        </div>
      </div>

      <div className="my-6rem">
        <div className="container py-5">
          <div className="for-companies-slider bg-black rounded-3">
            <div className="row">
              <div className="col-lg-6 d-flex flex-column align-items-center justify-content-center">
                <h2 className="text-white">
                  Generate Detailed Reports for Data-Driven Decisions
                </h2>
                <p className="text-white">
                  With ReloNavigator, DS companies can generate detailed,
                  real-time reports to track performance, monitor progress, and
                  provide critical insights to clients. Custom reporting options
                  allow you to focus on the data points that matter most to your
                  operations, ensuring informed decision-making and continuous
                  improvement.
                </p>
              </div>

              <div className="col-lg-6">
                <div className="vSlider">
                  <div className="l-realise-slider-card-block">
                    <div className="card a" id="first">
                      <img src="assets/publicImages/companiesImages/slider.png" />
                      <div class="card-text">
                        <h4>Real-Time Reporting</h4>
                        <p>
                          Generate reports in real time to get up-to-date
                          insights into every relocation process.
                        </p>
                      </div>
                    </div>
                    <div className="card b" id="second">
                      <img src="assets/publicImages/companiesImages/slider.png" />
                      <div class="card-text">
                        <h4>Custom Reports</h4>
                        <p>
                          Tailor reports to highlight specific data points, such
                          as consultant performance, transferee progress, or
                          property feedback.
                        </p>
                      </div>
                    </div>
                    <div className="card c" id="third">
                      <img src="assets/publicImages/companiesImages/slider.png" />
                      <div class="card-text">
                        <h4>Actionable Insights</h4>
                        <p>
                          Use data to identify trends, streamline operations,
                          and deliver better results for your clients.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="slider-dots">
                  <span className="dot active" id="dot1"></span>
                  <span className="dot" id="dot2"></span>
                  <span className="dot" id="dot3"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-6rem">
        <div className="container pb-5">
          <div className="for-companiesWhyCHoose d-lg-grid">
            <div className="d-flex align-items-center justify-content-center flex-column col-lg-10 mb-5 md-lg-0">
              <h2>Why DS Companies Trust ReloNavigator</h2>
              <p>
                ReloNavigator offers DS companies a powerful platform that
                delivers efficiency, transparency, and real-time insights. By
                simplifying operations and providing the tools needed to manage
                consultants effectively, ReloNavigator helps you improve service
                quality and client satisfaction.
              </p>
            </div>
            <div className="box">
              <div className="item bg-white" data-aos="fade-up">
                <img
                  src="assets/publicImages/companiesImages/rocket.png"
                  alt=""
                  width="100%"
                />
                <h4>Efficiency</h4>
                <p>
                  Automate appointment scheduling, reporting, and consultant
                  management, saving valuable time and resources.
                </p>
              </div>
              <div className="item bg-white" data-aos="fade-up">
                <img
                  src="assets/publicImages/companiesImages/search-sparkle.png"
                  alt=""
                  width="100%"
                />
                <h4>Transparency</h4>
                <p>
                  Achieve 360-degree visibility into the relocation process,
                  ensuring every transferee's journey is smooth and optimized.
                </p>
              </div>
              <div className="item bg-white" data-aos="fade-up">
                <img
                  src="assets/publicImages/companiesImages/data-usage-edit.png"
                  alt=""
                  width="100%"
                />
                <h4>Data-Driven</h4>
                <p>
                  Leverage custom reports and real-time data to make informed
                  decisions and continuously improve service delivery.
                </p>
              </div>
              <div className="item bg-white" data-aos="fade-up">
                <img
                  src="assets/publicImages/companiesImages/cube-tree.png"
                  alt=""
                  width="100%"
                />
                <h4>Scalability</h4>
                <p>
                  Whether you're managing a small team or a large organization,
                  ReloNavigator scales to meet your needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-6rem">
        <div className="container">
          <div className="YourTeamSection">
            <img
              src="assets/publicImages/companiesImages/YourTeamImage.png"
              alt=""
            />
            <div className="teamText">
              <h4>Manage Your Team for Maximum Efficiency</h4>
              <p>
                ReloNavigator helps DS companies stay organized and manage their
                consultants with ease. Track consultant activity, schedule
                appointments, and measure performance to ensure your team is
                working efficiently and meeting client expectations.
              </p>
            </div>
          </div>

          <div className="tials pb-5">
            <div className="box active rounded-4" data-aos="fade-up">
              <h3>Centralized Management</h3>
              <p>
                Oversee all consultant activities from a single dashboard,
                giving you full control over schedules and performance metrics.
              </p>
              <a href="#" className="text-black text-decoration-none fw-bold">
                Read More
              </a>
            </div>
            <div className="box rounded-4" data-aos="fade-up">
              <h3>Appointment Coordination</h3>
              <p>
                Easily manage consultant-transferee appointments, ensuring that
                every meeting is aligned with transferee needs and timelines.
              </p>
              <a href="#" className="text-black text-decoration-none fw-bold">
                Read More
              </a>
            </div>
            <div className="box rounded-4" data-aos="fade-up">
              <h3>Performance Tracking</h3>
              <p>
                Monitor key performance indicators (KPIs) for consultants to
                ensure high-quality service delivery and efficiency.
              </p>
              <a href="#" className="text-black text-decoration-none fw-bold">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForCompaniesPage;
