import React, { useState } from "react";

function RentCalculatorPage() {
  const [monthlyIncome, setMonthlyIncome] = useState("");
  const [monthlyRent, setMonthlyRent] = useState("");
  const [monthlyStatus, setMonthlyStatus] = useState(false);
  const [enteredIncome, setEnteredIncome] = useState("");

  const handleKeyPress = (e) => {
    const key = e.key;
    const regex = /[0-9.]/; // Allow numeric digits and a period (dot)

    if (e.keyCode === 8 || regex.test(key)) {
      return;
    }
    e.preventDefault();
  };
  const CalculateMonthlyBudget = (inputVal) => {
    if (inputVal < 1 && inputVal != "" && inputVal == "e" && inputVal == "E") {
      inputVal = "";
    } else if (
      inputVal === "" ||
      (parseInt(inputVal) >= 0 && !isNaN(parseInt(inputVal)))
    ) {
      setMonthlyIncome(inputVal);
      setEnteredIncome(inputVal);
    } else {
      setMonthlyIncome("");
    }
  };
  const calculateRent = () => {
    if (enteredIncome != "") {
      const monthlyRent = (30 / 100) * enteredIncome;
      setMonthlyRent(Number(monthlyRent.toFixed(0)));
      setMonthlyStatus(true);
      setEnteredIncome("");

      // $("#CalculationModal").modal("show");
    } else {
      $("#monthlyIncome").addClass("is-invalid");
    }
  };
  const closeModal = () => {
    setMonthlyIncome("");
    setMonthlyRent("");
    $(function () {
      $("document").ready(async function () {
        $("#CalculationModal").modal("hide");
        $("#monthlyIncome").removeClass("is-invalid");
      });
    });
  };

  return (
    <div className="container r-a-c-view pt-5">
      <h2>Rent Affordability Calculator</h2>
      <p>
        Finding a place to live is an important part of any relocation. To help
        you navigate your budget, our Rent Affordability Calculator will guide
        you in determining how much you should spend on rent based on your
        monthly salary. Plus, we've included expert tips to help you find the
        perfect rental property.
      </p>
      <p>
        <span>
          * We do not store any of the below entered information. It is solely
          used to calculate & recommend the rent.
        </span>
      </p>
      <div className="text-center">
        <p className="fw-medium">Enter your gross monthly income($) ?</p>
        <textarea
          name="message"
          className="form-control"
          id="monthlyIncome"
          rows="2"
          placeholder="Enter the total amount you earn per month before taxes and deductions."
          value={enteredIncome}
          onKeyDown={handleKeyPress}
          onChange={(e) => CalculateMonthlyBudget(e.target.value)}
        ></textarea>
        <button
          type="button"
          className="btn my-4"
          onClick={() => calculateRent()}
        >
          Calculate Rent
        </button>
      </div>
      {monthlyStatus && (
        <div className=" container w-50 modal-contentView mb-5 rounded-2 p-4">
          <p className="">
            <b>
              Based on your monthly income of ${monthlyIncome}, we recommend
              spending no more than ${monthlyRent} on rent each month.{" "}
            </b>
          </p>
          <p>
            <b>Note: </b>This calculation is based on the standard rule that 30%
            of your income should go toward rent. Adjust this figure based on
            your lifestyle and other financial commitments.
          </p>
        </div>
      )}
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              <img
                src="/assets/images/resorce-bulb.png"
                alt=""
                className="me-4"
              />{" "}
              Rental Tips
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              <ol>
                <li>
                  Stick to Your Budget:
                  <ul>
                    <li>
                      When searching for rentals, set a firm budget and avoid
                      stretching beyond it. Keeping rent within 30% of your
                      income ensures you have room for other living expenses.
                    </li>
                  </ul>
                </li>
                <li>
                  Factor in Additional Costs:
                  <ul>
                    <li>
                      Remember, rent isn't the only cost. Make sure to account
                      for utilities, renter's insurance, and maintenance fees
                      (if applicable).
                    </li>
                  </ul>
                </li>
                <li>
                  Choose the Right Location:
                  <ul>
                    <li>
                      Consider your daily commute and proximity to essential
                      services like grocery stores, schools, and hospitals. A
                      lower rent in a distant location might come with higher
                      transportation costs.
                    </li>
                  </ul>
                </li>
                <li>
                  Read the Lease Carefully:
                  <ul>
                    <li>
                      Before signing, thoroughly review the lease for key
                      details such as the length of the lease term, notice
                      period for ending the lease, and rules about security
                      deposits.
                    </li>
                  </ul>
                </li>
                <li>
                  Know Your Rights as a Renter:
                  <ul>
                    <li>
                      Be familiar with tenant rights in your new area. This can
                      include rules about security deposits, eviction
                      procedures, and your landlord's obligations for repairs
                      and maintenance.
                    </li>
                  </ul>
                </li>
                <li>
                  Consider Renters Insurance:
                  <ul>
                    <li>
                      Renters insurance is an inexpensive way to protect your
                      belongings in case of theft, fire, or other unexpected
                      events. It's highly recommended, even if not required by
                      your landlord.
                    </li>
                  </ul>
                </li>
                <li>
                  Negotiate Rent (If Possible):
                  <ul>
                    <li>
                      In some cases, landlords may be open to negotiation,
                      especially if you're signing a longer lease or offering
                      upfront payments. Don't hesitate to ask!
                    </li>
                  </ul>
                </li>
                <li>
                  Inspect the Property:
                  <ul>
                    <li>
                      Before committing, inspect the property to ensure
                      everything is in good condition. Look for issues like
                      plumbing leaks, electrical faults, or appliance
                      malfunctions.
                    </li>
                  </ul>
                </li>
                <li>
                  Ask About Rent Increases:
                  <ul>
                    <li>
                      Check whether rent increases are allowed during your lease
                      term. Understanding this upfront can help you plan for the
                      future.
                    </li>
                  </ul>
                </li>
                <li>
                  Get Everything in Writing:
                  <ul>
                    <li>
                      Any agreement you make with your landlord, such as repairs
                      or rent discounts, should be documented in writing for
                      future reference.
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/* monthly rent pop up */}
      {/* <div
        className="modal  fadeInDown animated tab-modal"
        id="CalculationModal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content p-4">
            <div className="">
              <div className="only_close">
                <span
                  aria-hidden="true"
                  className="pointer close"
                  onClick={() => closeModal()}
                  data-dismiss="modal"
                >
                  ×
                </span>
              </div>
            </div>
            <div className="modal-body">
              <div className="">
                <p>
                  Based on your monthly income of ${monthlyIncome}, we recommend
                  spending no more than ${monthlyRent} on rent each month.
                </p>
                <p>
                  <b>Note: </b>This calculation is based on the standard rule
                  that 30% of your income should go toward rent. Adjust this
                  figure based on your lifestyle and other financial
                  commitments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default RentCalculatorPage;
