import React from "react";

function RentersInsuranceModal() {
  const closeModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#RentersInsurance").modal("hide");
      });
    });
  };
  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header border-0">
          <div class="only_close">
            <span
              aria-hidden="true"
              className="pointer close"
              onClick={() => closeModal()}
              data-dismiss="modal"
            >
              ×
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div className="widthModal">
            <div className="item-view">
              <h2>Renters Insurance </h2>
              <p className="mb-0">
                Renters insurance is an affordable and essential safeguard for
                tenants. It protects your personal belongings, provides
                liability coverage, and can even help with temporary housing if
                your rental becomes uninhabitable. Here are some key tips to
                keep in mind when considering renters insurance:
              </p>
            </div>

            <div className="tails-row">
              <div className="tails">
                <div className="item-view">
                  <h2>1. Understand What It Covers</h2>
                  <p className="ps-4">
                    Renters insurance typically covers three main areas:{" "}
                  </p>
                  <ul>
                    <li>
                      <b>Personal Property:</b> Protects your belongings (like
                      furniture, electronics, clothing) from theft, fire, or
                      certain natural disasters.
                    </li>
                    <li>
                      <b>Liability Protection:</b> Provides coverage if you
                      accidentally damage someone else’s property or if someone
                      is injured while in your rental.
                    </li>
                    <li>
                      <b>Loss of Use: </b> Covers additional living expenses if
                      your home becomes uninhabitable due to a covered event,
                      such as a fire, and you need to stay somewhere else
                      temporarily.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>2. Check Your Lease</h2>
                  <p className="ps-4">
                    Some landlords require tenants to have renters insurance, so
                    review your lease agreement carefully to see if it's
                    mandatory. Even if it’s not required, it’s a good idea to
                    protect your personal property and ensure you’re covered in
                    case of unexpected accidents.{" "}
                  </p>
                </div>
                <div className="item-view">
                  <h2>3. Assess Your Coverage Needs</h2>
                  <p className="ps-4">
                    Take inventory of your belongings and estimate their total
                    value. Make sure the policy you choose offers enough
                    coverage to replace your items in case of loss or damage.
                    Many insurance providers offer tools to help you calculate
                    the right amount of coverage for your possessions.
                  </p>
                </div>
                <div className="item-view">
                  <h2>4. Bundle Policies to Save Money</h2>
                  <p className="ps-4">
                    Many insurance companies offer discounts if you bundle
                    renters insurance with other types of coverage, such as auto
                    or health insurance. This can save you money while ensuring
                    you're covered in multiple areas.
                  </p>
                </div>
                <div className="item-view">
                  <h2>5. Understand Deductibles and Limits</h2>
                  <p className="ps-4">
                    A deductible is the amount you’ll pay out of pocket before
                    your insurance kicks in. Choose a deductible that fits your
                    budget; higher deductibles often result in lower premiums
                    but will cost you more if you file a claim. Additionally,
                    pay attention to the coverage limits, especially on
                    high-value items like jewelry or electronics, which may have
                    specific limits in standard policies.
                  </p>
                </div>
              </div>

              <div className="tails">
                <div className="item-view">
                  <h2>6. Document Your Belongings</h2>
                  <p className="ps-4">
                    Keep a record of your belongings, including photos or videos
                    and receipts. This will make it easier to file a claim and
                    prove the value of your possessions in case of loss. Many
                    insurance companies also allow you to upload documents or
                    lists directly to their platforms.
                  </p>
                </div>
                <div className="item-view">
                  <h2>7. Ask About Additional Coverage</h2>
                  <p className="ps-4">
                    Standard renters insurance policies may not cover certain
                    disasters like floods or earthquakes. If you’re moving to an
                    area prone to these events, consider purchasing additional
                    coverage or a rider to protect against these specific risks.
                  </p>
                </div>
                <div className="item-view">
                  <h2>8. Shop Around for the Best Deal</h2>
                  <p className="ps-4">
                    Not all insurance policies are created equal. Take the time
                    to compare multiple quotes and policy details from different
                    insurance providers to ensure you’re getting the best
                    coverage at the best price.
                  </p>
                </div>
                <div className="item-view">
                  <h2>9. Liability Coverage Is Key</h2>
                  <p className="ps-4">
                    Liability coverage in renters insurance can protect you if
                    someone is injured in your rental, and you're held
                    responsible. This could cover legal expenses or medical
                    costs, making it an important part of your policy.
                  </p>
                </div>
                <div className="item-view">
                  <h2>10. Don’t Overlook Renters Insurance</h2>
                  <p className="ps-4">
                    Even if you feel your belongings are not of high value,
                    renters insurance is affordable and can save you from
                    significant financial loss in case of an unforeseen event.
                    It provides peace of mind knowing that you're protected no
                    matter what happens.
                  </p>
                </div>
              </div>
            </div>
            <div className="item-view mt-2">
              <p className="mb-0">
                Renters insurance is a simple and effective way to safeguard
                your personal property and ensure you're covered in case of
                accidents or unexpected disasters. It’s an inexpensive
                investment that can save you a lot of stress and money in the
                long run.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RentersInsuranceModal;
