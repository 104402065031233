import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faHome,
  faMoneyBill,
  faCheck,
  faBed,
} from "@fortawesome/fontawesome-free-solid";

function DragDrop(props) {
  const { state, handleRightItemsUpdate } = props;
  const [leftItems, setLeftItems] = useState([]);
  const [rightItems, setRightItems] = useState([]);
  const [error, setError] = useState(false);
  const allOptions = [
    { id: "1", icon: "fa-thin fa-person-walking", name: "Commute" },
    { id: "2", icon: "fa-light fa-house", name: "Home-Type" },
    { id: "3", icon: "fa-light fa-money-check-dollar", name: "Budget" },
    { id: "4", icon: "fa-light fa-circle-check", name: "Must-Haves" },
    { id: "5", icon: "fa-light fa-bed-front", name: "Bedrooms" },
  ];
  {
    /* <FontAwesomeIcon icon={faHouse} /><i class="fa-solid fa-person-walking"></i> <i class="fa-thin fa-bed-front"></i> <i class="fa-regular fa-money-check-dollar"></i> <i class="fa-regular fa-circle-check"></i>*/
  }
  useEffect(() => {
    if (state?.answersPrefer["03b0317a-9dd7-401f-bf46-f06ec35ca802"]) {
      let preferredNames =
        state.answersPrefer["03b0317a-9dd7-401f-bf46-f06ec35ca802"]
          .preferencesanswer;
      filterOptionsByNames(preferredNames, allOptions);
    }
  }, [state?.answersPrefer["03b0317a-9dd7-401f-bf46-f06ec35ca802"]]);

  const filterOptionsByNames = (preferredNames, allOptions) => {
    const preferredNamesArray = preferredNames
      .split(",")
      .map((name) => name.trim());

    // Initialize arrays for filtered and remaining options
    const filteredOptions = [...rightItems];
    const remainingOptions = [...leftItems];

    // Loop through allOptions and categorize based on preferredNames
    allOptions.forEach((option) => {
      if (preferredNamesArray.includes(option.name)) {
        filteredOptions.push(option);
      } else {
        remainingOptions.push(option);
      }
    });
    // Sort filteredOptions based on the order in preferredNamesArray
    const sortedFilteredOptions = preferredNamesArray
      .map((name) => filteredOptions.find((option) => option.name === name))
      .filter((option) => option !== undefined);

    // Update the ID for sorted filtered options based on their new positions
    const updatedFilteredOptions = sortedFilteredOptions.map(
      (option, index) => ({
        ...option,
        id: (index + 1).toString(), // Update ID based on position (1-based index)
      })
    );
    setLeftItems(remainingOptions);
    setRightItems(filteredOptions);

    // setRightItems(updatedFilteredOptions);

    // return { filteredOptions, remainingOptions };
  };

  const handleDragEnd = (result) => {
    let answersPrefer = state.answersPrefer;
    // console.log(result, "123");
    if (!result.destination) {
      // Item was dropped outside a valid droppable area.
      return;
    }

    const sourceList = result.source.droppableId;
    const destinationList = result.destination.droppableId;
    const draggedItem =
      sourceList === "left"
        ? leftItems[result.source.index]
        : rightItems[result.source.index];

    if (sourceList !== destinationList) {
      // console.log("moving");
      // Move item between lists
      if (sourceList === "left") {
        // console.log("left");
        const newLeftItems = [...leftItems];
        newLeftItems.splice(result.source.index, 1);
        setLeftItems(newLeftItems);

        setRightItems((prevRightItems) => {
          const newRightItems = [...prevRightItems];
          newRightItems.splice(result.destination.index, 0, draggedItem);
          return newRightItems;
        });
        setError(false);
      } else {
        // console.log("right");
        const newRightItems = [...rightItems];
        newRightItems.splice(result.source.index, 1);
        setRightItems(newRightItems);

        setLeftItems((prevLeftItems) => {
          const newLeftItems = [...prevLeftItems];
          newLeftItems.splice(result.destination.index, 0, draggedItem);
          return newLeftItems;
        });
        if (newRightItems.length == 0) {
          // console.log("empty");
          setError(true);
        } else {
          setError(false);
        }
      }
    } else {
      // console.log("reorder");
      // Reorder items within the same list
      const itemsToReorder = sourceList === "left" ? leftItems : rightItems;
      const reorderedItems = [...itemsToReorder];
      const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
      reorderedItems.splice(result.destination.index, 0, reorderedItem);

      if (sourceList === "left") {
        setLeftItems(reorderedItems);
      } else {
        setRightItems(reorderedItems);
      }
    }
  };
  setTimeout(() => {
    handleRightItemsUpdate(rightItems);
  }, 200);
  return (
    <>
      {state.listOfProperties && state.listOfProperties.length > 0 ? (
        <div className="list-priority-view-heading">
          {error && state.DragOptionsCheck == "" && (
            <div className="tooltip-validation">
              <div className="tooltip-inner">{state.toolTipText}</div>
            </div>
          )}
          <h1 className="">
            {state.listOfProperties[0].question.charAt(0).toUpperCase() +
              state.listOfProperties[0].question.slice(1).toLowerCase()}
          </h1>
          <p className="" style={{ paddingTop: "10px" }}>
            {state.listOfProperties[0].description == "" ||
            state.listOfProperties[0].description == undefined
              ? "Drag and drop the categories in to the box below in the order of importance."
              : state.listOfProperties[0].description}
          </p>
        </div>
      ) : null}
      {state.listOfProperties && state.listOfProperties.length > 0 ? (
        <div className="demo-drag-drop">
          <DragDropContext onDragEnd={handleDragEnd}>
            <div className="leftcontainer">
              <Droppable droppableId="left" direction="horizontal">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="letfitems list-priority-view "
                  >
                    {leftItems.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            className="item"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="drag">
                              <i class={item.icon}></i>
                              {/* <FontAwesomeIcon
                                icon={item.icon}
                                style={{
                                  backgroundColor: snapshot.isDragging
                                    ? "lightGreen"
                                    : "white",
                                }}
                              /> */}
                              <p>
                                {/* <span>{index + 1}</span> */}
                                {item.name}
                              </p>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
            <div className="">
              <Droppable droppableId="right" direction="horizontal">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    id="example2-right"
                    className="rightitems list-priority-view "
                  >
                    <span className="drag-droptext">Drag and Drop here</span>
                    {rightItems &&
                      rightItems.length > 0 &&
                      rightItems.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="correctoptions item"
                            >
                              <div className="drag">
                                <i class={item.icon}></i>
                                {/* <FontAwesomeIcon
                                  icon={item.icon}
                                  style={{
                                    backgroundColor: snapshot.isDragging
                                      ? "lightGreen"
                                      : "white",
                                  }}
                                /> */}
                                <p>
                                  {/* <span>{index + 1}</span> */}

                                  {item.name}
                                </p>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default DragDrop;
