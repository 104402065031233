import React, { useState, useEffect } from "react";
import Select from "react-select";
import * as $ from "jquery";
import { Spin, Modal, Radio } from "antd";
import axios from "axios";
import moment from "moment";

import { closeModal } from "../../../../common/utils";

import ApiServices from "../../../../../services/apiServices";
import LoginStore from "../../../../../services/thirdPartyService";

import S3Upload from "react-s3-uploader/s3upload";

const EditProperty = (props) => {
  var dataSource1 = [
    "Has AC",
    "Business Center",
    "Carpeted",
    "Ceiling Fan",
    "Clubhouse",
    "Concierge",
    "Fireplace",
    "Fitness Center",
    "Gated Community",
    "Granite Counters",
    "Hardwood Floors",
    "Laundry Facility",
    "Non Smoking",
    "Parking / Garage",
    "Patio",
    "Pet Friendly",
    "Pool",
    "Renovated",
    "Spa",
    "Stainless Appliances",
    "Has Storage",
    "Walk-in Closet",
    "Washer / Dryer",
    "Playground / Tot Lot",
  ];
  const options = [];
  for (let i = 0; i < dataSource1.length; i++) {
    options.push({
      key: Math.floor(),
      value: dataSource1[i],
      label: dataSource1[i],
    });
  }

  const managementcompanylist = [];
  var managementcompanies = [
    "Greystar Real Estate Partners",
    "Lincoln Property Company",
    "Pinnacle",
    "FPI Management",
    "Winn Companies",
    "MAA",
    "Apartment Management Consultants LLC",
    "BH Management",
    "Equity Residential",
    "AvalonBay Communities Inc",
    "The Bozzuto Group",
    "Starwood Capital Group",
    "Prometheus Real Estate Group",
  ];
  for (let i = 0; i < managementcompanies.length; i++) {
    managementcompanylist.push({
      value: managementcompanies[i],
      label: managementcompanies[i],
    });
  }

  const { editProperty, getMyProperties } = props;

  const initialState = {
    isPropertyError: false,
    ShowSuccess: false,
    successMessage: "",
    errorMessage: "",
    propertyName: "",
    address: "",
    propertyLink: "",
    amenities: [],
    managementcompany: "",
    propertyNotes: "",
    loader: false,
    uploadLoader: false,
    amenitityList: "",
    uploadImages: [],
    propertyanalyticsuniqueId: "",
    uploadImageError: "",
    uploadLoader: false,
    supplieruid: "",
    propertyuniqueId: "",
    isDelete: false,
    imageError: false,
  };

  const [state, setState] = useState(initialState);

  const {
    address,
    amenities,
    managementcompany,
    propertyLink,
    propertyName,
    propertyNotes,
    allData,
    property_image,
    supplieruid,
    row,
  } = editProperty;

  async function editValues() {
    console.log(editProperty, "editProperty");

    // console.log(amenities, "amenities");
    const ammenityy = [];
    if (amenities.length > 0) {
      const dataAmmeniries = amenities.split(",");

      for (let i = 0; i < dataAmmeniries.length; i++) {
        ammenityy.push({
          value: dataAmmeniries[i],
          label: dataAmmeniries[i],
        });
      }
    }
    updateState({
      propertyName: propertyName,
      address: address,
      propertyLink: propertyLink,
      amenities: ammenityy,
      managementcompany: managementcompany?.replace(/^"|"$/g, ""),
      propertyNotes: propertyNotes,
      propertyanalyticsuniqueId: allData.propertyuniqueId,
      propertyuniqueId: allData.propertyuniqueId,
      supplieruid: supplieruid,
      uploadImages: prepareListFromImageUrlString(property_image),
    });
  }

  async function setLocation(Id, e) {
    // if (e.target.value.trim() == null || e.target.value.trim() == "") {
    //   $("#" + Id).addClass("is-invalid");
    // } else {
    //   $("#" + Id).removeClass("is-invalid");
    // }
    if (e.target.value.trim() == null || e.target.value.trim() == "") {
      e.target.classList.add("is-invalid");
    } else {
      e.target.classList.remove("is-invalid");
    }
    let LocationObje = {};
    LocationObje[Id] = e.target.value;
    updateState(LocationObje);
    var places = await new google.maps.places.Autocomplete(
      document.getElementById(Id),
      { types: ["address"] }
    );
    google.maps.event.addListener(places, "place_changed", async () => {
      var place = places.getPlace();
      var placeDetails = {
        locality: "long_name",
        administrative_area_level_1: "short_name",
        country: "long_name",
      };
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (placeDetails[addressType]) {
          var val = place.address_components[i][placeDetails[addressType]];
          placeDetails[addressType] = val;
        }
      }
      let relocatedAddress = place.formatted_address;

      await updateState({
        address: relocatedAddress,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        state: placeDetails.administrative_area_level_1,
        city: placeDetails.locality,
      });
    });
  }

  // async function handleChange(inputName, e) {
  //   const inputVal = e.target.value;
  //   const inputObject = { [inputName]: inputVal };
  //   if (inputName === "" && inputVal < 1 && inputVal !== "") {
  //     inputObject[inputName] = "";
  //     $("#" + inputName).addClass("is-invalid");
  //   } else if (!inputVal.trim()) {
  //     $("#" + inputName).addClass("is-invalid");
  //   } else {
  //     $("#" + inputName).removeClass("is-invalid");
  //   }
  //   updateState(inputObject);
  // }
  async function handleChange(inputName, e) {
    let inputVal = e.target.value;
    let inputObject = {};
    inputObject[inputName] = inputVal;
    if (inputVal.trim() == null || inputVal.trim() == "") {
      e.target.classList.add("is-invalid");
    } else {
      e.target.classList.remove("is-invalid");
    }
    updateState(inputObject);
  }
  async function updateState(values) {
    setState((prev) => ({ ...prev, ...values }));
  }

  async function handleSelect(amenities) {
    if (amenities == null || amenities == "") {
      $("#amenities").addClass("is-invalid");
    } else {
      $("#amenities").removeClass("is-invalid");
    }

    updateState({ amenities: amenities });

    const datas = state.amenities.map((each) => {
      return each["value"];
    });

    updateState({ amenitityList: datas.toString() });
  }

  async function onChangeCheckbox(e) {
    let checkboxValue = e.target.checked;

    updateState({ showfortransferee: checkboxValue });
  }

  function imageChangeHandler(e) {
    let files = e.target.files;
    if (isFileLimitExceeded(e)) {
      return;
    }
    if (!isFileFormatSupported(e)) {
      return;
    }
    uploadImages(e);
  }

  function isFileLimitExceeded(event) {
    let files = event.target.files;
    if (files.length > 5) {
      event.target.value = null;
      updateState({
        uploadImageError: "Only 5 images can be uploaded at a time.",
        imageError: true,
      });
      return true;
    }
    return false;
  }

  function isFileFormatSupported(event) {
    const types = ["image/png", "image/jpeg", "image/gif"];
    const maxFileSize = 1 * 1024 * 1024; // 1MB in bytes
    let files = event.target.files;
    let err = "";
    for (let i = 0; i < files.length; i++) {
      if (types.every((type) => files[i].type !== type)) {
        err = "Only supported png, jpeg, gif files format supported.";
      } else if (files[i].size > maxFileSize) {
        err = `${files[i].name}: File size exceeds 1MB.`;
      }
    }
    if (err) {
      updateState({
        uploadImageError: err,
        imageError: true,
      });
      $("#edit-property-modal #propertyImage").addClass("is-invalid");
      return false;
    }
    return true;
  }

  function uploadImages(event) {
    let files = event.target.files;
    const data = new FormData();
    for (var x = 0; x < files.length; x++) {
      data.append("file", files[x]);
    }
    updateState({
      uploadImageError: "",
      uploadLoader: true,
      imageError: false,
    });
    axios
      .post(`/profileimagesInfo`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        let data = [...state.uploadImages];
        _.forEach(response.data, async (response) => {
          data.push(response);
        });
        updateState({
          uploadImages: data,
          // uploadImageError: "Successfully uploaded.",
          uploadLoader: false,
        });
      })
      .catch((error) => {
        updateState({
          uploadImageError: "something went wrong while uploading images",
          uploadLoader: false,
          imageError: true,
        });
      });
  }

  function removeimage(value, id) {
    console.log(value, "value");
    let list = Object.assign([], state.uploadImages);
    let arr = list.filter((item) => item !== value);
    console.log(arr, "98089");
    updateState({ uploadImages: arr });
  }

  function prepareUploadImagesString(obj) {
    let arr = [];
    obj.forEach((item) => {
      item.Location && arr.push(item.Location);
    });
    return arr.toString();
  }

  function prepareListFromImageUrlString(str) {
    if (!str) {
      return [];
    }
    let images = str.split(",");
    let list = [];
    if (images.length > 0) {
      images.forEach((image) => {
        list.push({
          Location: image,
          key: image.replace(
            /^https:\/\/relonavigator\.s3\.us-west-1\.amazonaws\.com\//,
            ""
          ),
        });
      });
      return list;
    }
    return [];
  }

  async function editMyProperties() {
    updateState({ loader: true });
    let propertyObject = {
      propertyuniqueId: state.propertyuniqueId,
      apiId:
        row.apiId || Math.floor(1000000000000 + Math.random() * 9000000000000),
      transfereeuniqueId: "",
      source: row.source,
      city: row.city,
      propertyname: state.propertyName,
      address: state.address,
      state: row.state,
      latitude: row.latitude,
      longitude: row.longitude,
      propertylink: state.propertyLink,
      notes: state.propertyNotes,
      isincluded: false,
      detailspage_link: "",
      dsassignmentId: "",
      dspropertyId: "",
      propertyjson: "",
      amenities: state.amenities.map((item) => item.value).join(","),
      monthlyrent: "",
      hometype: "",
      bedrooms: "",
      property_image: prepareUploadImagesString(state.uploadImages),
      available_till_date: moment(row.available_till_date).format("YYYY/MM/DD"),
      supplieruid: state.supplieruid,
      createdby: state.consultantuid,
      modifiedby: state.supplieruid,
      issupplierproperty: true,
      isdelete: state.isDelete == true ? state.isDelete : false,
      isactive: state.isDelete == true ? state.isDelete : false,
      managementcompany: state.managementcompany,
      showfortransferee: row.showfortransferee,
    };

    console.log(propertyObject, "editPropertyResultUpdate");

    let editPropertyResultUpdate = await ApiServices.UpdateProperty(
      propertyObject
    );
    if (editPropertyResultUpdate.error == 0) {
      getMyProperties();
      //   show success message
      updateState({
        successMessage: "Property updated successfully!",
        ShowSuccess: true,
      });
    } else {
      //  show error message
      updateState({
        errorMessage: editPropertyResultUpdate.message.status, //"something went wrong while updating property",
        isPropertyError: true,
      });
    }
    updateState({ loader: false });
  }

  async function xx() {
    let userInfo = await LoginStore.getLoginInfo({});
    await updateState({
      supplieruid: userInfo.supplieruid,
      consultantuid: userInfo.userUniqueId,
    });
  }

  useEffect(() => {
    editValues();
  }, [editProperty]);

  const onChange = async (e) => {
    updateState({
      isDelete: e.target.value,
    });
  };
  async function onClickClose() {
    editValues();
    updateState({
      ShowSuccess: false,
      uploadImageError: "",
      imageError: false,
    });
    $(document).ready(function () {
      // Clear input values
      $("#edit-property-modal").find("input,select").val("");
      $("#propertyImage").removeClass("is-invalid");
      // Remove validation classes
      $("#edit-property-modal").find("input,select").removeClass("is-invalid");
    });
  }

  return (
    <div
      className="modal fade"
      id="edit-property-modal"
      tabIndex="-1"
      aria-labelledby="edit-property-modal"
      aria-hidden="true"
      data-bs-backdrop="static"
      style={{ zIndex: 1060 }}
    >
      <div className="modal-dialog modal-lg modal-lg-2">
        <div className="modal-content ">
          <div className="width-80per pd-y-60">
            <div className="only_close">
              <span
                className="pointer close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => onClickClose()}
              >
                &times;
              </span>
            </div>
            <form
              method="post"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              {state.ShowSuccess ? "" : <h6>Edit property</h6>}
              {state.isPropertyError == true ? (
                <h2 className="alert alert-danger fs-5 text-center">
                  {state.errorMessage}
                </h2>
              ) : (
                ""
              )}

              {state.ShowSuccess == true ? (
                <h2 className="alert alert-success fs-5 text-center">
                  {state.successMessage}
                </h2>
              ) : (
                <div className="pop-form-controls focus-input-label mt-4">
                  <div className="floating-label">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name of property/community"
                      value={state.propertyName}
                      autoComplete="new-password"
                      id="propertyName"
                      onChange={(e) => handleChange("propertyName", e)}
                    />
                    <label>Name of property/community</label>
                  </div>
                  <div className="floating-label">
                    <input
                      placeholder="Enter Location"
                      autoComplete="off"
                      className="form-control"
                      type="text"
                      value={state.address}
                      // autoComplete="new-password"
                      id="address"
                      onChange={(e) => setLocation("address", e)}
                    />
                    <label>Address</label>
                  </div>
                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder="If you found a property online,paste the link here"
                      value={state.propertyLink}
                      autoComplete="off"
                      type="text"
                      id="propertyLink"
                      onChange={(e) => handleChange("propertyLink", e)}
                    />
                    <label>
                      If you found a property online,paste the link here
                    </label>
                  </div>
                  <div className="floating-label">
                    <label className="label_cls">Amenities</label>
                    <Select
                      id="amenities"
                      //   className="form-control"
                      placeholder="Select"
                      isMulti // Enable multiple selection
                      options={options}
                      value={state.amenities}
                      onChange={handleSelect}
                      closeMenuOnSelect={false}
                    />
                  </div>
                  <div className="floating-label">
                    <label className="label_cls">Management Company</label>
                    <select
                      id="managementcompany"
                      className="form-control"
                      placeholder="Select"
                      // options={managementcompanylist}
                      value={state.managementcompany || ""}
                      onChange={(e) => handleChange("managementcompany", e)}
                    >
                      <option
                        value=""
                        disabled
                        selected={state.managementcompany === null}
                      >
                        Select
                      </option>
                      {managementcompanies.map((each, i) => {
                        return (
                          <option key={i} value={each}>
                            {each}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="floating-label">
                    <label className="label_cls">Notes</label>
                    <input
                      type="text"
                      className="form-control"
                      id="propertyNotes"
                      value={state.propertyNotes}
                      autoComplete="off"
                      onChange={(e) => handleChange("propertyNotes", e)}
                    />
                  </div>
                  <div className="floating-label">
                    {state.propertyanalyticsuniqueId != "" && (
                      <>
                        <label className="label_cls">
                        Property Images
                        </label>
                      </>
                    )}
                  </div>
                  {state.uploadImages.length < 10 &&
                  state.propertyanalyticsuniqueId != "" ? (
                    <div className="custom-file">
                      {state.uploadLoader ? (
                        <Spin />
                      ) : (
                        <input
                          type="file"
                          onChange={(e) => imageChangeHandler(e)}
                          id="propertyImage"
                          className="form-control fileupload"
                          multiple
                          accept=".png, .jpeg, .jpg, .gif"
                        />
                      )}
                    </div>
                  ) : (
                    state.propertyanalyticsuniqueId != "" && (
                      <div className="custom-file">
                        Maximum 10 pictures allow
                      </div>
                    )
                  )}

                  {/* {state.propertyanalyticsuniqueId != "" &&
                  state.uploadImages.length > 0 ? (
                    <ImageTable
                      images={state.uploadImages}
                      removeimage={removeimage}
                    />
                  ) : null} */}
                  {state.propertyanalyticsuniqueId != "" && (
                    <div className="custom-file-upimg_multy d-flex">
                      {state.uploadImages &&
                        state.uploadImages.map((row, index) => {
                          return (
                            <div key={index} className="custom-file-upimg">
                              <img
                                src={`${state.uploadImages[index].Location}`}
                              />
                              <i
                                className="fa fa-trash"
                                onClick={() => removeimage(row, index)}
                              ></i>
                            </div>
                          );
                        })}
                    </div>
                  )}
                  {state.imageError && (
                    <span className="" style={{ color: "red" }}>
                      {state.uploadImageError}
                    </span>
                  )}
                  <div>
                    {state.propertyanalyticsuniqueId != "" ? (
                      <Radio.Group onChange={onChange} value={state.isDelete}>
                        <Radio value={false}>Active</Radio>
                        <Radio value={true}>Inactive</Radio>
                      </Radio.Group>
                    ) : null}
                  </div>

                  <div className="text-center mt-3 mb-3">
                    {state.loader || state.uploadLoader ? (
                      <Spin />
                    ) : (
                      <button
                        type="submit"
                        className="btn theme-btn"
                        onClick={(e) => editMyProperties(e)}
                      >
                        {" "}
                        Update
                        <span></span>{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProperty;

const ImageTable = ({ images, removeimage }) => {
  const [deletingRows, setDeletingRows] = useState([]);

  function onDelete(key) {
    removeimage(key);
    setDeletingRows([...deletingRows, key]);
  }
  return (
    <table>
      <thead>
        <tr>
          <th>Serial Number</th>
          <th>Key</th>
          <th>Image</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {images.map((image, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{image.key}</td>
            <td>
              <img
                src={image.Location}
                alt={`Image ${index + 1}`}
                style={{ maxWidth: "50px" }}
              />
            </td>
            <td>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => onDelete(image.key)}
                disabled={deletingRows.includes(image.key)}
              >
                Delete
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

// <div>
//       <Modal open={state.showModal} footer={null} onCancel={onClose}>
//         <form
//           method="post"
//           onSubmit={(e) => {
//             e.preventDefault();
//           }}
//         >
//           <h2>Edit property</h2>
//           {state.isPropertyError == true ? (
//             <h2 className="alert alert-danger">{state.errorMessage}</h2>
//           ) : (
//             ""
//           )}

//           {state.ShowSuccess == true ? (
//             <h2 className="alert alert-success">{state.successMessage}</h2>
//           ) : (
//             <div className="pop-form-controls focus-input-label mt-4">
//               <div className="floating-label">
//                 <input
//                   type="text"
//                   className="form-control"
//                   placeholder="Name of property/community"
//                   value={state.propertyName}
//                   autoComplete="new-password"
//                   id="propertyName"
//                   onChange={(e) => handleChange("propertyName", e)}
//                 />
//                 <label>Name of property/community</label>
//               </div>
//               <div className="floating-label">
//                 <input
//                   placeholder="Enter Location"
//                   autoComplete="off"
//                   className="form-control"
//                   type="text"
//                   value={state.address}
//                   // autoComplete="new-password"
//                   id="address"
//                   onChange={(e) => setLocation("address", e)}
//                 />
//                 <label>Address</label>
//               </div>
//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   placeholder="If you found a property online,paste the link here"
//                   value={state.propertyLink}
//                   autoComplete="off"
//                   type="text"
//                   id="propertyLink"
//                   onChange={(e) => handleChange("propertyLink", e)}
//                 />
//                 <label>
//                   If you found a property online,paste the link here
//                 </label>
//               </div>

//               <label className="label_cls">Amenities</label>
//               <Select
//                 id="amenities"
//                 //   className="form-control"
//                 isMulti // Enable multiple selection
//                 options={options}
//                 value={state.amenities}
//                 onChange={handleSelect}
//               />

//               <label className="label_cls">Management Company</label>

//               <select
//                 id="managementcompany"
//                 className="form-control"
//                 // options={managementcompanylist}
//                 value={state.managementcompany}
//                 onChange={(e) => handleChange("managementcompany", e)}
//               >
//                 {managementcompanies.map((each, i) => {
//                   return (
//                     <option key={i} value={each}>
//                       {each}
//                     </option>
//                   );
//                 })}
//               </select>

//               <label className="label_cls">Notes</label>
//               <input
//                 type="text"
//                 className="form-control"
//                 id="propertyNotes"
//                 value={state.propertyNotes}
//                 autoComplete="off"
//                 onChange={(e) => handleChange("propertyNotes", e)}
//               />

//               {state.propertyanalyticsuniqueId != "" && (
//                 <label className="label_cls">
//                   Upload Property Image <span>(only images) </span>{" "}
//                 </label>
//               )}
//               {state.uploadImages.length < 10 &&
//               state.propertyanalyticsuniqueId != "" ? (
//                 <div className="custom-file">
//                   {state.uploadLoader ? (
//                     <Spin />
//                   ) : (
//                     state.propertyanalyticsuniqueId != "" && (
//                       <input
//                         type="file"
//                         // onChange={(e) => onImageChangeHandler(e)}
//                         multiple
//                         id="propertyImage"
//                         className="form-control fileupload"
//                       />
//                     )
//                   )}
//                 </div>
//               ) : (
//                 state.propertyanalyticsuniqueId != "" && (
//                   <div className="custom-file">Maximum 10 pictures allow</div>
//                 )
//               )}

//               {state.propertyanalyticsuniqueId != "" && (
//                 <div className="custom-file-upimg_multy">
//                   {state.uploadImages.map((row, index) => {
//                     console.log("uploadImages", row);
//                     return (
//                       <div key={index} className="custom-file-upimg">
//                         <img src={row} />
//                         <i
//                           className="fa fa-trash"
//                           //   onClick={() => removeimage(row, index)}
//                         ></i>
//                       </div>
//                     );
//                   })}
//                 </div>
//               )}

//               <div className="text-center mt-3 mb-3">
//                 {state.loader || state.uploadLoader ? (
//                   <Spin />
//                 ) : (
//                   <button
//                     type="submit"
//                     className="btn theme-btn"
//                     // onClick={(e) => editMyProperties(e)}
//                   >
//                     {" "}
//                     Update
//                     <span></span>{" "}
//                   </button>
//                 )}
//               </div>
//             </div>
//           )}
//         </form>
//       </Modal>
//     </div>
