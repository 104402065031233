import React, { useEffect, useState } from "react";
import { AutoComplete, Table, Button, Select, Spin, Popover } from "antd";
import ApiServices from "../../../../services/apiServices";
import LoginStore from "../../../../services/thirdPartyService";
import "./clientProfileList.css";
import * as $ from "jquery";
import AddClientModal from "./AddClientModal";
import { confirmAlert } from "react-confirm-alert";

const ClientProfileList = () => {
  const [state, setState] = useState({
    filterTable: null,
    dataSource: [],
    columns: [
      {
        title: "Company Name",
        dataIndex: "companyname",
        key: Math.random(),
      },
      {
        title: "EmailId",
        dataIndex: "email",
        key: "emailid",
      },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
      },
      {
        title: "Action",
        dataIndex: "",
        key: "x",
        render: (text, client) => (
          <span>
            <a
              data-bs-toggle="modal"
              data-bs-target="#add-supplier-modal"
              onClick={() => onClickEditProperty(client)}
            >
              <i className="fa fa-edit"></i>
            </a>
            &nbsp;
            <a onClick={() => openDeleteClientModal(client)}>
              <i className="fa fa-trash"></i>
            </a>
          </span>
        ),
      },
    ],
    editid: false,
    errorMessage: "",
    ShowSuccess: false,
    showing: "",
    companyname: "",
    email: "",
    address: "",
    uploadImages: [],
    uploadLoader: false,
    loader: false,
    selectedClient: null,
  });

  function handleSearch(value) {
    const { dataSource } = state;
    const filterTable = dataSource.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    updateState({ filterTable: filterTable });
  }

  function onClickAddClient() {
    updateState({ editid: false, selectedClient: "" });
    $("#add-supplier-modal").modal("show");
  }

  function onClickEditProperty(client) {
    console.log(client, "client of the editor");
    updateState({ editid: true, selectedClient: client });
    $("#add-supplier-modal").modal("show");
  }

  async function deleteProfile(client) {
    let data = {
      companyname: client.companyname,
      address: client.address,
      email: client.email,
      clientuniqueId: client.clientuniqueId,
      supplieruid: client.supplieruid,
      imageurl: client.imageurl,
      isdelete: 1,
    };
    updateState({ loader: true });
    const UpdateTransferee = await ApiServices.UpdateClientProfile(data);
    if (UpdateTransferee.error == 0) {
      getSuppliers();
    } else {
      getSuppliers();
    }
  }

  function openDeleteClientModal(client) {
    $("#delete-client-modal").modal("show");
    updateState({ selectedClient: client });
  }

  function closeDeleteClientModal() {
    $("#delete-client-modal").modal("hide");
    updateState({ selectedClient: null });
  }

  async function getSuppliers() {
    updateState({ loader: true });
    const getSessionData = await LoginStore.getLoginInfo({});
    let getTransfereeObj = {
      supplieruid: getSessionData.userUniqueId,
    };
    let ClientProfile_Result = await ApiServices.GetAllClientProfile(
      getTransfereeObj
    );
    let dataSource = [];
    if (ClientProfile_Result.error == 0) {
      let consultants = ClientProfile_Result.message.clientProfileList;

      for (const transferee of consultants) {
        if (transferee.companyname !== "") {
          transferee.key = transferee.clientuniqueId;
          dataSource.push(transferee);
        }
      }
      const uniqueArray = dataSource.filter((thing, index) => {
        const _thing = JSON.stringify(thing);
        return (
          index ===
          dataSource.findIndex((obj) => {
            return JSON.stringify(obj) === _thing;
          })
        );
      });
      updateState({ dataSource: uniqueArray, loader: false });
    } else {
      updateState({ loader: false });
    }
  }

  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  useEffect(() => {
    getSuppliers();
  }, []);

  return (
    <>
      <div className="main_content trans-anttable">
        <div className="container">
          <div className="table-top-input-btn">
            <AutoComplete
              style={{ marginTop: "10px", marginBottom: "10px", width: 400 }}
              onSearch={handleSearch}
              placeholder="Search by..."
            />
            <span className="cursor add-list">
              <button
                type="submit"
                onClick={onClickAddClient}
                className="btn theme-btn"
                data-bs-toggle="modal"
                data-bs-target="#add-supplier-modal"
              >
                Add Client{" "}
                <i className="fa fa-plus-square-o" aria-hidden="true"></i>{" "}
              </button>{" "}
            </span>
          </div>
          {state.loader ? (
            <Spin />
          ) : (
            <Table
              dataSource={
                state.filterTable ? state.filterTable : state.dataSource
              }
              columns={state.columns}
              pagination={{
                defaultPageSize: 20,
                pageSizeOptions: ["20", "25", "30"],
                showSizeChanger: true,
                locale: { items_per_page: "" },
              }}
            />
          )}
        </div>
      </div>

      <AddClientModal
        getSuppliers={getSuppliers}
        selectedClient={state.selectedClient}
        editid={state.editid}
      />

      <ConfirmAlert
        deleteProfile={deleteProfile}
        client={state.selectedClient}
        closeDeleteClientModal={closeDeleteClientModal}
      />
    </>
  );
};

export default ClientProfileList;

const ConfirmAlert = ({ deleteProfile, client, closeDeleteClientModal }) => {
  return (
    <div
      id="delete-client-modal"
      className="modal fade  "
      role="dialog"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-lg ">
        <div className="modal-content">
          <div className="width-50per pd-y-70 height-30per m-30">
            <div
              className="only_close"
              onClick={() => closeDeleteClientModal()}
            >
              <span className="pointer close" data-dismiss="modal">
                &times;
              </span>
              <p className="text-center fw-normal fs-5">
                {" "}
                Are you sure you want to Delete Client{" "}
              </p>
              <div className="mt-3 text-center ">
                <button
                  type="button"
                  className="btn theme-btn me-3"
                  onClick={() => closeDeleteClientModal(client)}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-dark theme-btn"
                  onClick={() => deleteProfile(client)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
