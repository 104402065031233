// import "./iternaryProperties.css";
//import * as types from "../../../constants/types";
import ApiServices from "../../../../../services/apiServices";
import ThirdParty from "../../../../../services/thirdPartyService";
import { Spin } from "antd";
import { useState, useEffect } from "react";
import { faStar } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as $ from "jquery";
import DatePicker from "react-datepicker";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import Understand from "twilio/lib/rest/preview/Understand";
import { confirmAlert } from "react-confirm-alert";

const IterenaryProperties = ({ state1, showItenaries }) => {
  let _isMounted = false;
  const [state, setState] = useState({
    consultantName: state1.consultantName,
    consultantpic: state1.consultantpic,
    consultantemail: state1.consultantemail,
    itineraryPropertiesList: state1.itineraryPropertiesList,
    consultantphone: state1.consultantphone,
    transfareeName: state1.transfareeName,
    pickupLocation: state1.pickupLocation,
    phonenumber: state1.phonenumber,
    emailId: state1.emailId,
    notes: "",
    propertyAnalytics: [],
    scheduleuniqueId: state1.scheduleuniqueId,
    stateObject: [],
    overallfeedback: state1.overallfeedback,
    stateFeedback: "",
    isUpdated: false,
    transfereeEmail: "",
    succesMsg: "Your Feedback have been saved successfully!",
  });

  const confirmationFunc = async () => {
    confirmAlert({
      title: "",
      message: state.succesMsg,
      buttons: [
        {
          label: "Close",
          onClick: () => "",
        },
      ],
    });
  };

  useEffect(() => {
    const fetch = async () => {
      let getSessionData = await ThirdParty.getLoginInfo({});
      setState((prev) => ({
        ...prev,
        transfereeEmail: getSessionData.EmailId,
      }));
      updatePropertyData();
    };
    fetch();
  }, []);

  const updatePropertyData = async () => {
    setState((prev) => ({
      ...prev,
      stateObject: [],
      stateFeedback: "",
      isUpdated: false,
    }));
    let stateObject = [];
    let updateProperties = Object.assign([], state1.itineraryPropertiesList);
    _.forEach(updateProperties, function (value) {
      stateObject[value.propertyanalyticsuniqueId] = {
        propertyanalyticuniqueId: value.propertyanalyticsuniqueId,
        rating: value.rating != undefined ? value.rating : 0,
        notes: value.notes != undefined ? value.notes : "",
        isvisited: false,
        isinterested: false,
        isliked: false,
        isstaying: false,
        property_image: value.property_image,
        address: value.address,
        source: value.source,
        propertyjson: value.propertyjson,
      };
    });
    setState((prev) => ({
      ...prev,
      stateObject: stateObject,
      stateFeedback: state.overallfeedback,
      isUpdated: true,
    }));
  };

  useEffect(() => {
    return async () => {
      _isMounted = false;
    };
  }, []);

  const sendEmail = async (finalObject) => {
    let propertyDetailsArray = state.stateObject;

    setState((prev) => ({ ...prev, succesMsg: "" }));
    let data = {
      propertyDetailsArray: finalObject,
      overallfeedback: state.stateFeedback,
      scheduledDetails: state1.RowResult,
      consultantName: state.consultantName,
      consultantemail: state.consultantemail,
      consultantphone: state.consultantphone,
      subject: "Feedback details",
      isFeedback: true,
      email: state.consultantemail,
      transfereeEmail: state.transfereeEmail,
    };
    let sendSms = await ThirdParty.sendEmail(data);
    if (sendSms.response == true) {
      setState((prev) => ({
        ...prev,
        succesMsg: "Feedback Submitted successfully",
      }));
      confirmationFunc();
    } else {
      setState((prev) => ({
        ...prev,
        confirmError: "Please try again!",
      }));
    }
  };

  const saveProperties = async (data) => {
    setState((prev) => ({ ...prev, succesMsg: "" }));
    let propertyAnalyticsArray = state.stateObject;

    let transfereepropertiesrating = [];
    Object.keys(propertyAnalyticsArray).map(function (key, value) {
      transfereepropertiesrating.push(propertyAnalyticsArray[key]);
    });
    let finalObject = {
      transfereepropertiesrating,
      transfereescheduleuniqueId: state.scheduleuniqueId,
      overallfeedback: state.stateFeedback,
    };

    let result = await ApiServices.UpdateTransfereePropertyAnalytics(
      finalObject
    );
    if (result.error == 0) {
      if (data.isMail) {
        await sendEmail(finalObject);
      } else {
        setState((prev) => ({
          ...prev,
          succesMsg: "Your Feedback have been saved successfully",
        }));
        confirmationFunc();
      }
    } else {
      setState((prev) => ({ ...prev, succesMsg: result.message }));
      confirmationFunc();
    }
  };
  const updateRating = async (row, rating) => {
    // $('.rating-num-block li.rating').removeClass('rating');

    $("#" + row.propertyanalyticsuniqueId + rating).addClass("rating");
    let propertyArray = state.stateObject;
    if (propertyArray[row.propertyanalyticsuniqueId] == undefined) {
      let propertyObj = {
        propertyanalyticuniqueId: row.propertyanalyticsuniqueId,
        rating: rating,
        notes: "",
        isvisited: false,
        isinterested: false,
        isliked: false,
        isstaying: false,
      };
      propertyArray[row.propertyanalyticsuniqueId] = propertyObj;
    } else {
      propertyArray[row.propertyanalyticsuniqueId].notes =
        propertyArray[row.propertyanalyticsuniqueId].notes;
      propertyArray[row.propertyanalyticsuniqueId].rating = rating;
    }
    setState((prev) => ({ ...prev, stateObject: propertyArray }));
  };
  async function inputHandler(inputName, row, event) {
    event.preventDefault();
    if (inputName == "overallfeedback") {
      setState((prev) => ({
        ...prev,
        stateFeedback: event.target.value,
      }));
    } else {
      let propArray = state.stateObject;
      if (propArray[row.propertyanalyticsuniqueId] != undefined) {
        propArray[row.propertyanalyticsuniqueId].notes = event.target.value;
      } else {
        let propertyObj = {
          propertyanalyticuniqueId: row.propertyanalyticsuniqueId,
          rating: "",
          notes: event.target.value,
          isvisited: false,
          isinterested: false,
          isliked: false,
          isstaying: false,
        };
        propArray[row.propertyanalyticsuniqueId] = propertyObj;
      }
      setState((prev) => ({ ...prev, stateObject: propArray }));
    }
  }
  return (
    <div className="ds_newlanding">
      <div className="DSitinerary-today">
        <div className="container">
          <div className="sch_intinery_back" onClick={() => showItenaries()}>
            <i className="fa fa-long-arrow-left"></i> Back
          </div>
          <div className="sch_appointments_thumbs">
            <div className="item">
              <h1>{state1.RowResult.title}</h1>
              <ul>
                <li>{moment(state1.RowResult.scheduledate).format("LL")}</li>
                <li>{state1.RowResult.pickuplocation}</li>
                <li> {state1.RowResult.schedulefromtime} </li>
              </ul>
              <h2>With</h2>
              <p> {state1.consultantName} </p>
              <p>
                {" "}
                <i className="fa fa-phone"></i> {state1.consultantphone}{" "}
              </p>
              <p>
                <i className="fa fa-envelope-open"></i> {state1.consultantemail}{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="ItineraryMap-block">
        <div className="container">
          {console.log(state1.itineraryPropertiesList, "ititit")}
          {state1.itineraryPropertiesList &&
            state1.itineraryPropertiesList.length > 0 && (
              <div className="row">
                {state1.itineraryPropertiesList.map((row, index) => {
                  return row.address != "" && row.address != null ? (
                    <div className="col-md-6" key={index}>
                      <a href="javascript:void(0)">
                        <div className="propertyMapbox-block">
                          <div className="propertyMapbox-head">
                            <div className="mapbox-address">
                              <div className="mapbox-pointer">
                                <div className="mapbox-count">
                                  <span className="font-blod-x">
                                    {index + 1}
                                  </span>
                                </div>
                              </div>
                              <span>{row.address} </span>{" "}
                            </div>
                            <div className="mapbox-time">
                              <span className="font-blod color-dark-grey">
                                {row.available_till_date != null
                                  ? moment(row.available_till_date).format(
                                      "MM/DD/YYYY"
                                    )
                                  : ""}
                              </span>
                            </div>
                          </div>

                          <div className="propertyMapbox-map">
                            <img
                              src={
                                row.property_image != null &&
                                row.property_image != ""
                                  ? row.property_image.split(",")[0]
                                  : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      </a>
                    </div>
                  ) : (
                    ""
                  );
                })}
              </div>
            )}
        </div>
      </div>

      <div className="ItineraryList-block">
        <div className="container">
          {state1.itineraryPropertiesList &&
            state1.itineraryPropertiesList.map((row, index) => {
              return row.address != "" && row.address != null ? (
                <div className="property-listbox-block" key={index}>
                  <div className="property-listbox-flex">
                    <div className="propertyMaplist-block">
                      <div className="propertyMaplist-block_first-c">
                        <div className="mapbox-pointer">
                          <div className="mapbox-count">
                            <span className="font-blod-x">{index + 1}</span>
                          </div>
                        </div>

                        <div className="propertyMaplist-image-block">
                          {" "}
                          <img
                            src={
                              row.property_image != null &&
                              row.property_image != ""
                                ? row.property_image.split(",")[0]
                                : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
                            }
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="propertyMaplist-address-block">
                        <div>
                          <h1 className="font-blod">{row.propertyname}</h1>
                          <p> {row.address}</p>
                        </div>
                        <div className="rating-block">
                          <div className="property-rate-head">
                            {" "}
                            <FontAwesomeIcon icon={faStar} /> Rate this property{" "}
                          </div>
                          <div className="rating-num-block">
                            <ul>
                              <li
                                className={
                                  (state.stateObject[
                                    row.propertyanalyticsuniqueId
                                  ] != undefined
                                    ? state.stateObject[
                                        row.propertyanalyticsuniqueId
                                      ].rating
                                    : 0) == "1"
                                    ? "font-blod rating"
                                    : "font-blod"
                                }
                                value="1"
                                id={row.propertyanalyticsuniqueId + "1"}
                                onClick={() => updateRating(row, "1")}
                              >
                                1
                              </li>
                              <li
                                className={
                                  (state.stateObject[
                                    row.propertyanalyticsuniqueId
                                  ] != undefined
                                    ? state.stateObject[
                                        row.propertyanalyticsuniqueId
                                      ].rating
                                    : 0) == "2"
                                    ? "font-blod rating"
                                    : "font-blod"
                                }
                                value="2"
                                id={row.propertyanalyticsuniqueId + "2"}
                                onClick={() => updateRating(row, "2")}
                              >
                                2
                              </li>
                              <li
                                className={
                                  (state.stateObject[
                                    row.propertyanalyticsuniqueId
                                  ] != undefined
                                    ? state.stateObject[
                                        row.propertyanalyticsuniqueId
                                      ].rating
                                    : 0) == "3"
                                    ? "font-blod rating"
                                    : "font-blod"
                                }
                                value="3"
                                id={row.propertyanalyticsuniqueId + "3"}
                                onClick={() => updateRating(row, "3")}
                              >
                                3
                              </li>
                              <li
                                className={
                                  (state.stateObject[
                                    row.propertyanalyticsuniqueId
                                  ] != undefined
                                    ? state.stateObject[
                                        row.propertyanalyticsuniqueId
                                      ].rating
                                    : 0) == "4"
                                    ? "font-blod rating"
                                    : "font-blod"
                                }
                                value="4"
                                id={row.propertyanalyticsuniqueId + "4"}
                                onClick={() => updateRating(row, "4")}
                              >
                                4
                              </li>
                              <li
                                className={
                                  (state.stateObject[
                                    row.propertyanalyticsuniqueId
                                  ] != undefined
                                    ? state.stateObject[
                                        row.propertyanalyticsuniqueId
                                      ].rating
                                    : 0) == "5"
                                    ? "font-blod rating"
                                    : "font-blod"
                                }
                                value="5"
                                id={row.propertyanalyticsuniqueId + "5"}
                                onClick={() => updateRating(row, "5")}
                              >
                                5
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {state.stateObject[row.propertyanalyticsuniqueId] !=
                      undefined && (
                      <div className="propertyMaplist-rating-block">
                        <textarea
                          name=""
                          id="text0"
                          cols="35"
                          rows="5"
                          className="form-control"
                          placeholder="Notes"
                          defaultValue={
                            state.stateObject[row.propertyanalyticsuniqueId] !=
                            undefined
                              ? state.stateObject[row.propertyanalyticsuniqueId]
                                  .notes
                              : ""
                          }
                          onChange={(e) => inputHandler(e, "notes", row)}
                        ></textarea>
                      </div>
                    )}
                  </div>
                </div>
              ) : null;
            })}
          <div className="bottom_line">
            <h1>How was your overall tour with {state1.consultantName}?</h1>
            {state.isUpdated && (
              <textarea
                className="form-control"
                rows="5"
                defaultValue={
                  state.stateFeedback != "" ? state.stateFeedback : ""
                }
                onChange={(e) => inputHandler(e, "overallfeedback", {})}
              ></textarea>
            )}
            <div className="text-center">
              {/* <button className="btn" onClick={() => saveProperties({ isMail: true })} style={{ textTransform: "none" }}>Send to my Email</button> 
                                <button className="btn" onClick={() => saveProperties({ isMail: true })}>Submit</button>
                                <button className="btn" onClick={updatePropertyData}> <i className="fa fa-refresh"></i> </button>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IterenaryProperties;
