import React, { useEffect, useState } from "react";
import LoginStore from "../../../../services/thirdPartyService";
import ApiServices from "../../../../services/apiServices";
import NearByPlaces from "../../../common/nearByPlaces";
import NewNearByPlaces from "../../../common/newNearByPlaces";

function NewMapList(props) {
  const [state, setState] = useState({
    userUniqueId: "",
    myPropertiesList: "",
    removeBoundary: "",
    mapText: "",
    propertyLoader: "",
    saPropertiesList: "",
    propertyLoader: false,
    propertyDetails: true,
    data: {
      latitude: "",
      longitude: "",
      state_name: "CA",
      city_name: "San Francisco",
      city: "San Francisco",
      address: "185 Channel Street",
    },
    types: [
      {
        name: "Restaurants",
        type: "restaurant",
        faicon: "fa fa-cutlery",
        icon: "../assets/images/restaurant-new.svg",
      },
      {
        name: "Groceries",
        type: "supermarket",
        faicon: "fa fa-shopping-bag",
        icon: "../assets/images/grocery-1.svg",
      },
      {
        name: "Parks",
        type: "park",
        faicon: "fa fa-tree",
        icon: "../assets/images/park-1.svg",
      },
      {
        name: "Shopping",
        type: "shopping_mall",
        faicon: "fa fa-shopping-cart",
        icon: "../assets/images/shopping-3.svg",
      },
      {
        name: "Entertainment",
        type: "night_club",
        faicon: "fa fa-film",
        icon: "../assets/images/entertainment-1.svg",
      },
      {
        name: "Hospitals",
        type: "hospital",
        faicon: "fa fa-h-square",
        icon: "../assets/images/hos-1.svg",
      },
    ],
    type: ["restaurant"],
    typeName: "",
    Loader: false,
    items: [],
    startPosition: "0",
    categories: [],
    RestaurantsMainArray: [],
    restaurantsList: [],
    typesList: [],
    RestauType: [],
    worklatitude: "",
    worklongitude: "",
    location: [],
    placeSearched: [],
    placeSearchedMarkers: [],
    markerList: [],
    showlist: false,
    showSearch: false,
    typeicon: 0,
    directionsService: {},
    directionsDisplay: {},
    restData: [],
    workAddress: "",
    answersPrefer: "",
    activeNumber: -1,
    propertyLat: "",
    propertyLng: "",
    myProp: "",
  });
  useEffect(() => {
    async function fetchData() {
      await getData();
    }
    fetchData();
  }, []);
  const getData = async () => {
    let getSessionData = await LoginStore.getLoginInfo({});
    // console.log(getSessionData, "getSessionData");
    setState((prev) => ({
      ...prev,
      workAddress: getSessionData.worklocationaddress1,
    }));
    let userObject = {
      transfereeuniqeuid: getSessionData.userUniqueId,
      istemporary: getSessionData.istemporary,
    };
    let getQuestionList = await ApiServices.GetPreferencesList(userObject);
    let userPreferanceObject = {
      transfereeuniqeuid: getSessionData.userUniqueId,
      istemporary: getSessionData.istemporary,
    };

    let userSavedPreferances = await ApiServices.GetTransfereePreferences(
      userPreferanceObject
    );
    if (userSavedPreferances.error == 0) {
      let userAnswerPreferanceObject = userSavedPreferances.message.preferences;
      if (userAnswerPreferanceObject != undefined) {
        let answersPrefer = {};
        _.forEach(userAnswerPreferanceObject, function (answerObject) {
          answersPrefer[answerObject.preferencesmasteruniqueid] = answerObject;
        });
        setState((prev) => ({ ...prev, answersPrefer: answersPrefer }));
        getWorkAddress(answersPrefer, getQuestionList);
      }
    }
  };
  const getWorkAddress = async (answersPrefer, getQuestionList) => {
    let getSessionData = await LoginStore.getLoginInfo({});
    if (getQuestionList.message.preferencesList != undefined) {
      let questionList = getQuestionList.message.preferencesList;
      let workAddressArray = _.filter(questionList, function (o) {
        return o.question == "Work address";
      });
      await localStorage.setItem(
        "workAddress",
        JSON.stringify(answersPrefer[workAddressArray[0].preferenceUId])
      );
    }
    const data = JSON.parse(await localStorage.getItem("workAddress"));
    // console.log(data, "data");
    setState((prev) => ({
      ...prev,
      userUniqueId: getSessionData.userUniqueId,
      worklatitude: data.latitude,
      worklongitude: data.longitude,
      data: {
        latitude: data.latitude,
        longitude: data.longitude,
        address: data.preferencesanswer,
      },
      workAddress: data.preferencesanswer,
    }));
    getSaMyProperties();
  };
  const getSaMyProperties = async () => {
    setState((prev) => ({ ...prev, propertyLoader: true, Loader: true }));
    let compareProperties = props.finalObjectCampare;
    // console.log(compareProperties, "===");
    let sorted = await compare_qty(
      "propertyrankitems.matchpercent",
      compareProperties
    );

    setState((prev) => ({
      ...prev,
      myProp: sorted,
      myPropertiesList: compareProperties,
      propertyLoader: false,
    }));
  };
  const compare_qty = async (prop, arr) => {
    if (!Array.isArray(arr)) {
      throw new Error("arr is not an array.");
    }

    prop = prop.split(".");
    var len = prop.length;

    arr.sort(function (a, b) {
      var i = 0;
      while (i < len) {
        a = a[prop[i]];
        b = b[prop[i]];
        i++;
      }
      if (a > b) {
        return -1;
      } else if (a < b) {
        return 1;
      } else {
        return 0;
      }
    });

    return arr;
  };

  return (
    <div>
      {/* <div className="map_top_view"> */}
      {/* <NearByPlaces
        {...state}
        latitude={state.worklatitude}
        longitude={state.worklongitude}
        communityName={state.workAddress}
        // communityName={state.workAddress}
        setState={setState}
        // compareMap={props.compareMap}
      /> */}
      <NewNearByPlaces
        {...state}
        latitude={state.worklatitude}
        longitude={state.worklongitude}
        communityName={state.workAddress}
        // communityName={state.workAddress}
        setState={setState}
        compareMap={props.compareMap}
        // newIndex={Math.random()}
      />
      {/* </div> */}
    </div>
  );
}

export default NewMapList;
