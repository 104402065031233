import React from "react";

function LocalPetRegulationModal() {
  const closeModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#localPetModal").modal("hide");
      });
    });
  };
  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header border-0">
          <div class="only_close">
            <span
              aria-hidden="true"
              className="pointer close"
              onClick={() => closeModal()}
              data-dismiss="modal"
            >
              ×
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div className="widthModal">
            <div className="item-view">
              <h2>Local Pet Regulations </h2>
              <p className="mb-0">
                When moving to a new location with a pet, it's essential to be
                aware of the local laws and regulations to ensure you're
                compliant and to keep your pet safe. Here’s a breakdown of
                common pet regulations and how they may vary depending on where
                you’re moving.
              </p>
            </div>

            <div className="tails-row">
              <div className="tails">
                <div className="item-view">
                  <h2>1. Pet Licensing and Registration </h2>
                  <p className="ps-4">
                    Most cities and states require that dogs, and sometimes
                    cats, be licensed. Pet licenses typically need to be renewed
                    annually and can help reunite you with your pet if they get
                    lost. Requirements usually include:
                  </p>
                  <ul>
                    <li>
                      <b>Proof of Rabies Vaccination: </b> In most areas, pets
                      must have up-to-date rabies vaccinations before being
                      licensed.
                    </li>
                    <li>
                      <b>Fees: </b> Some locations offer discounted fees for
                      spayed or neutered pets, seniors, or low-income residents.
                    </li>
                    <li>
                      <b>Renewal Requirements: </b> Licenses often need to be
                      renewed yearly or every few years, depending on local
                      laws.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>2. Leash Laws </h2>
                  <p className="ps-4">
                    Leash laws ensure pets are controlled in public areas to
                    protect both the pet and others. These laws vary by city but
                    often include:
                  </p>
                  <ul>
                    <li>
                      <b>Public Areas: </b>In most places, dogs must be on a
                      leash when in public, such as on sidewalks, parks, or
                      trails.
                    </li>
                    <li>
                      <b>Designated Off-Leash Areas:</b> Some cities have dog
                      parks or trails where dogs are allowed off-leash under
                      supervision.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>3. Pet Waste Disposal </h2>
                  <p className="ps-4">
                    Cleaning up after your pet is required in almost all cities.
                    Failure to pick up waste can result in fines. Here are
                    typical requirements:
                  </p>
                  <ul>
                    <li>
                      <b>Pooper Scooper Laws: </b>Owners must clean up after
                      their dogs in public areas.
                    </li>
                    <li>
                      <b>Fines: </b>Penalties for not cleaning up can range from
                      small fines to larger penalties for repeat offenses.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>4. Breed Restrictions </h2>
                  <p className="ps-4">
                    Some cities and counties have breed-specific laws or
                    restrictions, particularly for breeds considered
                    “aggressive.” These restrictions may include:
                  </p>
                  <ul>
                    <li>
                      <b>Breed Bans: </b> Some places have outright bans on
                      certain breeds (e.g., Pit Bulls, Rottweilers, etc.).
                    </li>
                    <li>
                      <b>Insurance Requirements: </b> In some cases, owners of
                      restricted breeds must carry special liability insurance.
                    </li>
                    <li>
                      <b>Exemptions: </b> Service dogs are typically exempt from
                      breed restrictions, but you may need to provide
                      documentation.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>5. Pet Limits </h2>
                  <p className="ps-4">
                    Cities often have regulations on how many pets (dogs, cats,
                    etc.) you can keep at one time. These limits are usually
                    based on:
                  </p>
                  <ul>
                    <li>
                      <b>Type and Number of Pets: </b> Cities may limit the
                      number of dogs or cats per household (e.g., no more than
                      three dogs).
                    </li>
                    <li>
                      <b>Zoning Laws: </b> Some areas may have stricter limits
                      in multi-family housing or apartment complexes.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="tails">
                <div className="item-view">
                  <h2>6. Noise Ordinances </h2>
                  <p className="ps-4">
                    Pets, particularly dogs, can sometimes cause noise
                    complaints from neighbors. Most cities have rules regarding
                    how much barking is considered excessive:
                  </p>
                  <ul>
                    <li>
                      <b>Quiet Hours: </b> Many cities enforce quiet hours
                      during certain times of the day or night.
                    </li>
                    <li>
                      <b>Fines: </b> Owners may be fined if their pets
                      consistently violate noise ordinances by barking
                      excessively.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>7. Exotic Pets </h2>
                  <p className="ps-4">
                    If you own an exotic pet (e.g., reptiles, birds, or other
                    unusual animals), local laws may restrict or require special
                    permits for keeping them:
                  </p>
                  <ul>
                    <li>
                      <b>Banned Species: </b> Some cities ban certain exotic
                      animals altogether due to safety or environmental
                      concerns.
                    </li>
                    <li>
                      <b>Permit Requirements: </b> In places where exotic pets
                      are allowed, you may need to obtain a special permit or
                      license.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>8. Pet-Friendly Spaces and Public Access </h2>
                  <p className="ps-4">
                    Many cities provide areas where pets are welcome, but there
                    are also places where pets may be restricted, such as:
                  </p>
                  <ul>
                    <li>
                      <b>Parks and Recreation Areas: </b> Some parks and
                      recreational areas allow pets, but others may have
                      restrictions or designated pet zones.
                    </li>
                    <li>
                      <b>Public Transportation: </b> Rules about pets on public
                      transportation (e.g., buses, trains) vary by city. In many
                      places, small pets in carriers are allowed, but larger
                      animals may not be.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>How to Stay Informed </h2>
                  <p className="ps-4">
                    Each city or county has its own specific regulations, so
                    it’s crucial to check with your local animal control office
                    or city website for detailed information.
                  </p>
                  <ul>
                    <li>
                      <b>Local Government Websites: </b> Visit your city or
                      county’s official website for pet licensing and regulation
                      details.
                    </li>
                    <li>
                      <b>Veterinarian Offices: </b> Many local vets are familiar
                      with the area’s pet laws and can provide guidance.
                    </li>
                    <li>
                      <b>Pet Stores and Shelters: </b> These establishments
                      often have informational brochures on local pet
                      ordinances.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="item-view mt-2">
              <h2>Final Tips </h2>
              <ul>
                <li>
                  <b>Keep Vaccinations Up to Date: </b> Rabies vaccinations are
                  often legally required and are essential for keeping your pet
                  healthy and protected.
                </li>
                <li>
                  <b>Carry Proof of Licensing: </b>Keep your pet’s license tag
                  on them at all times to avoid fines.
                </li>
                <li>
                  <b>Follow the Rules: </b> Leash laws, waste disposal, and
                  noise ordinances ensure you, your pet, and your community stay
                  safe and happy.
                </li>
              </ul>
              <p className="mb-0">
                By following local pet regulations, you can ensure a smooth
                transition to your new home while keeping your pet safe and
                compliant with local laws.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocalPetRegulationModal;
