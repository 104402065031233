import React from "react";
// import Modal from "react-modal";
import { Popover, Spin, Modal } from "antd";

const inactModal = ({
  isOpen,
  onClose,
  state,
  activeProperty,
  moreInfoWithDetails,
}) => {
  const Close = () => {
    $("#not-MyProperties-interested-community-Modal").modal("hide");
  };
  return (
    <div
      className="modal fade"
      id="not-MyProperties-interested-community-Modal"
      style={{
        transition: "none",
        zIndex: 1060,
      }}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content max-width-initial">
          <div className="modal-header">
            <h4 className="modal-title">Inactive Rentals</h4>
            <button
              type="button"
              onClick={() => Close()}
              data-dismiss="modal"
              className="close m-0"
              style={{ color: "#262625" }}
            >
              X
            </button>
          </div>
          <div className="modal-body mb-4 scorllInterested">
            {state.myPropertiesListInactive &&
            state.myPropertiesListInactive.length > 0 ? (
              <div className="main_grid" style={{ borderTop: "none" }}>
                {state.myPropertiesListInactive.map((row, index) => {
                  return (
                    <div className="item" key={"poup" + index}>
                      <span className="ScoreLabel">
                        <b>
                          <Popover
                            placement="leftTop"
                            content={
                              <div>
                                {row.propertyrankitems.propertyrankingitems.map(
                                  (Pitem) => {
                                    return (
                                      <p
                                        key={
                                          "mymmysa2" +
                                          index +
                                          Pitem.prioritylistitem
                                        }
                                      >
                                        {Pitem.prioritylistitem ==
                                        "Must Haves" ? (
                                          <Popover
                                            placement="leftTop"
                                            content={
                                              <div>
                                                {Pitem.matcheditems.map(
                                                  (matcheditem) => {
                                                    return (
                                                      <p
                                                        key={
                                                          "mmysa2pps" +
                                                          index +
                                                          matcheditem.itemname
                                                        }
                                                      >
                                                        {matcheditem.itemname}
                                                        {matcheditem.ismatched ==
                                                          true && (
                                                          <i className="fa fa-check"></i>
                                                        )}
                                                        {matcheditem.ismatched ==
                                                          false && (
                                                          <i className="fa fa-times"></i>
                                                        )}
                                                      </p>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            }
                                            title="Matched Must Haves"
                                            trigger="hover"
                                          >
                                            {Pitem.prioritylistitem}{" "}
                                            <i
                                              className="fa fa-info-circle"
                                              style={{ color: "#000000" }}
                                            ></i>
                                          </Popover>
                                        ) : (
                                          Pitem.prioritylistitem
                                        )}
                                        {Pitem.ismatched == true &&
                                          Pitem.isallmatched == false && (
                                            <i className="fa fa-check yellow_clr"></i>
                                          )}
                                        {Pitem.ismatched == true &&
                                          Pitem.isallmatched == true && (
                                            <i className="fa fa-check"></i>
                                          )}
                                        {Pitem.ismatched == true &&
                                          Pitem.isallmatched == null && (
                                            <i className="fa fa-check"></i>
                                          )}
                                        {Pitem.ismatched == false && (
                                          <i className="fa fa-times"></i>
                                        )}
                                      </p>
                                    );
                                  }
                                )}
                              </div>
                            }
                            title="Matched Priorities"
                            trigger="hover"
                          >
                            {Math.trunc(row.propertyrankitems.matchpercent)}%
                          </Popover>
                        </b>
                      </span>
                      <label htmlFor={"my2myCheckbox" + index}>
                        <img
                          src={
                            row.property_image != null &&
                            row.property_image != "" &&
                            row.property_image != undefined
                              ? row.property_image.split(",")[0]
                              : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
                          }
                          alt=""
                        />
                        <div className="content">
                          <p>
                            {row.propertyname} <br />
                            {row.city} {row.state}
                          </p>
                          <span>
                            <span>
                              <a
                                href="javascript:void(0)"
                                onClick={() => activeProperty(row)}
                              >
                                Active
                              </a>
                            </span>{" "}
                            <a
                              href="javascript:void(0)"
                              className="linkColor"
                              onClick={() => moreInfoWithDetails(row)}
                            >
                              More
                            </a>{" "}
                          </span>
                        </div>
                      </label>
                    </div>
                  );
                })}
              </div>
            ) : state.propertyLoader ? (
              <Spin />
            ) : (
              <div className="main_grid">No data available</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default inactModal;
