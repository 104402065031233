import React from "react";

const CompanyDropDownComponent = ({ company, companydata, inputHandler }) => {
  return (
    <div className="form-group col">
      <label htmlFor="inputStatea">Company</label>
      <select
        value={company}
        className="form-control"
        id="Company"
        onChange={(e) => inputHandler("company", e.target.value)}
      >
        <option value="">Choose...</option>
        {companydata.map((row, index) => {
          return (
            <option key={index} value={row.companyname}>
              {row.companyname}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default CompanyDropDownComponent;
