import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Select, Spin } from "antd";
import moment from "moment";

import LoginStore from "../../../../../services/thirdPartyService";
import ApiServices from "../../../../../services/apiServices";

import { closeModal } from "../../../../common/utils";

const RecommendEditRental = ({
  editMode,
  transfereeuniqueId,
  editRentalData,
  getMyProperties,
}) => {
  const intialState = {
    errorMessage: "",
    successMessage: "",
    supplieruid: "",
    consultantuid: "",
    ConsultantsaPropertiesList: [],
    propertyListNew: [],
    loader: false,

    propertyName: "",
    virtual360tour: "",
    monthlyrent: "",
    hometype: "",
    bedrooms: "",
    bathrooms: "",
    unitnumber: "",
    flooring: "",
    sqfeet: "",
    availableTillDate: "",
    EditMoveDate: null,
    uploadImages: [],
    city: "",
    transfereeuniqueId: transfereeuniqueId,
    isDelete: false,
    propertyuniqueId: "",
    apiId: "",
    comments: "",
  };
  const [state, setState] = useState(intialState);

  const dropDownLists = {
    children: ["APARTMENT", "CONDO", "HOUSE", "TOWNHOME", "DUPLEX"],
    bedrromslist: ["1", "2", "3", "4+"],
    bathroomslist: ["1", "1.5", "2", "2.5", "3", "3.5", "4", "4.5", "5+"],
  };

  function refreshState() {
    let ConsultantsaPropertiesList = [...state.ConsultantsaPropertiesList];
    let propertyListNew = [...state.propertyListNew];
    updateState({
      ...intialState,
      ConsultantsaPropertiesList,
      propertyListNew,
    });
    // updateState({
    //   propertyName: "",
    //   virtual360tour: "",
    //   monthlyrent: "",
    //   hometype: "",
    //   bedrooms: "",
    //   bathrooms: "",
    //   unitnumber: "",
    //   flooring: "",
    //   sqfeet: "",
    //   availableTillDate: "",
    //   EditMoveDate: null,
    // });
  }

  function generateSelectoptions(options) {
    return options.map((option) => (
      <Select.Option key={option}>{option}</Select.Option>
    ));
  }

  function inputHandler(inputName, event) {
    const inputVal = event.target.value;
    const inputObject = { [inputName]: inputVal };

    if (
      (inputName === "monthlyrent" || inputName === "flooring") &&
      inputVal < 1 &&
      inputVal !== ""
    ) {
      inputObject[inputName] = "";
      $("#" + inputName).addClass("is-invalid");
    } else if (!inputVal.trim()) {
      if (
        ["flooring", "sqfeet", "unitnumber", "comments"].includes(inputName)
      ) {
        $("#" + inputName).removeClass("is-invalid");
      } else {
        $("#" + inputName).addClass("is-invalid");
      }
    } else {
      $("#" + inputName).removeClass("is-invalid");
    }

    updateState(inputObject);
  }

  function handleDateChange(editDate) {
    updateState({
      EditMoveDate: new Date(editDate),
      availableTillDate: moment(editDate).format("YYYY/MM/DD"),
    });
  }

  function onChangeTypes(type, value) {
    console.log(type, value, "value");

    let inputVal = value;
    let inputObject = {};
    inputObject[type] = inputVal.toString();
    if (inputVal == null || inputVal == "") {
      $("#" + type).addClass("is-invalid");
    } else {
      $("#" + type).removeClass("is-invalid");
    }
    setState((prev) => ({ ...prev, [type]: value }));
  }

  async function getConsultantProperties() {
    let userInfo = await LoginStore.getLoginInfo({});
    updateState({
      supplieruid: userInfo.supplieruid,
      consultantuid: userInfo.userUniqueId,
    });
    let GetTransfereePropertiesObject = {
      supplieruid: userInfo.supplieruid,
    };
    let GetTransfereeProperties = await ApiServices.GetConsultantProperties(
      GetTransfereePropertiesObject
    );
    let transfereepropertyList =
      GetTransfereeProperties.message.transfereepropertyList;

    let saPropertiesList = transfereepropertyList.filter(function (element) {
      if (element.propertyjson) {
        element.property_image =
          JSON.parse(element.propertyjson).Photo != null
            ? "https://s3-us-west-1.amazonaws.com/destination-services-itinerary/" +
              JSON.parse(element.propertyjson).Photo +
              ".jpg"
            : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png";
      }
      return element.isactive == true && element.apiId != "";
    });

    let propertyListNew = [];
    _.uniqBy(saPropertiesList, "propertyuniqueId").map(
      (propertyId, indexId) => {
        propertyListNew.push(
          <Select.Option
            key={indexId}
            value={propertyId.propertyuniqueId}
            label={propertyId.propertyname}
          >
            {propertyId.propertyname}
          </Select.Option>
        );
      }
    );

    updateState({
      ConsultantsaPropertiesList: saPropertiesList,
      propertyListNew: propertyListNew,
    });
  }

  async function updateState(data) {
    setState((prevState) => ({
      ...prevState,
      ...data,
    }));
  }

  async function addMyProperties() {
    let errorUpdate = 0;
    updateState({
      loader: true,
      errorMessage: "",
    });

    if (state.propertyName.trim() == "" || state.propertyName == null) {
      $(".ant-validation").addClass("is-invalid");
      updateState({ loader: false });
      errorUpdate++;
    } else {
      $(".ant-validation").removeClass("is-invalid");
    }
    if (state.bedrooms == "" || state.bedrooms == null) {
      updateState({ loader: false });
      $(".ant-validation").addClass("is-invalid");
      errorUpdate++;
    } else {
      $(".ant-validation").removeClass("is-invalid");
    }
    if (state.bathrooms == "" || state.bathrooms == null) {
      updateState({ loader: false });
      $(".ant-validation").addClass("is-invalid");
      errorUpdate++;
    } else {
      $(".ant-validation").removeClass("is-invalid");
    }

    if (state.hometype == "" || state.hometype == null) {
      updateState({ loader: false });
      $(".ant-validation").addClass("is-invalid");
      errorUpdate++;
    } else {
      $(".ant-validation").removeClass("is-invalid");
    }

    if (state.monthlyrent == "" || state.monthlyrent == null) {
      updateState({ loader: false });
      $("#monthlyrent").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#monthlyrent").removeClass("is-invalid");
    }

    if (
      state.flooring <= 99 ||
      state.flooring == null ||
      state.flooring == ""
    ) {
      $("#flooring").removeClass("is-invalid");
    } else {
      await updateState({ loader: false });
      errorUpdate++;
      $("#flooring").add("is-invalid");
    }

    if (errorUpdate == 0) {
      let propertyObject = {
        transfereeuniqueId: state.transfereeuniqueId,
        propertyanalyticsuniqueId: state.propertyanalyticsuniqueId,
        apiId: state.apiId,
        source: "SA_Properties",
        city: state.city,
        isdelete: false,
        propertyname: state.propertyName,
        address: state.address,
        state: state.state,
        latitude: state.latitude,
        longitude: state.longitude,
        propertylink: state.propertyLink,
        notes: state.propertyNotes,
        isIncluded: false,
        amenities: state.amenities,
        monthlyrent: state.monthlyrent,
        hometype: state.hometype,
        isDelete: state.isDelete,
        bedrooms: state.bedrooms,
        bathrooms: state.bathrooms,
        property_image: state.uploadImages.toString(),
        available_till_date: state.availableTillDate,
        unitnumber: state.unitnumber,
        flooring: state.flooring,
        sqfeet: state.sqfeet,
        comments: state.comments,
        virtual360tour: state.virtual360tour,
        isinterested: true,
      };

      if (editMode) {
        updateProperty(propertyObject);
      } else {
        createTransfereeProperties(propertyObject);
      }
      console.log(state.transfereeuniqueId);
    }
  }

  async function createTransfereeProperties(propertyObject) {
    let saproperties = state.ConsultantsaPropertiesList;
    let saPropertyObj = _.filter(saproperties, function (element) {
      return element.propertyuniqueId == propertyObject.propertyname;
    });
    let Obj = saPropertyObj[0];

    let propertyObjectCreate = {
      propertyuniqueId: Obj.propertyuniqueId,
      transfereeuniqueId: state.transfereeuniqueId,
      apiId: Obj.apiId,
      source: "SA_Properties",
      city: state.city,
      isdelete: false,
      propertyname: Obj.propertyname,
      address: Obj.address,
      state: Obj.state,
      latitude: Obj.latitude,
      longitude: Obj.longitude,
      propertylink: Obj.propertylink,
      notes: Obj.notes,
      isIncluded: false,
      amenities: Obj.amenities,
      monthlyrent: state.monthlyrent,
      hometype: state.hometype,
      isDelete: state.isDelete,
      bedrooms: state.bedrooms,
      bathrooms: state.bathrooms,
      property_image: Obj.property_image,
      available_till_date: state.availableTillDate,
      supplieruid: state.supplieruid,
      issupplierproperty: true,
      isactive: Obj.isactive,
      unitnumber: state.unitnumber,
      flooring: state.flooring,
      sqfeet: state.sqfeet,
      comments: state.comments,
      virtual360tour: state.virtual360tour,
      isinterested: true,
    };

    let addPropertyResult = await ApiServices.CreateTransfereeProperties(
      propertyObjectCreate
    );

    if (addPropertyResult.error == 0) {
      $("document").ready(function () {
        $("#add-property-modal").find("input").val("").end();
      });
      setTimeout(() => {
        closeModal("recommend-edit-rental");
        window.scroll(0, 0);
        refreshState();
        getMyProperties(state.transfereeuniqueId);
      }, 5000);

      //getMyProperties(); //-------------------refresh properties--------------------------------//

      updateState({
        successMessage: "Recommended rental added successfully.",
        loader: false,
        propertySuccess: true,
        propertyName: "",
        propertyLink: "",
        address: "",
        propertyNotes: "",
        uploadImages: [],
        availableTillDate: null,
        EditMoveDate: null,
      });
    } else {
      updateState({
        checkExceed: false,
        isPropertyError: true,
        loader: false,
        errorMessage: addPropertyResult.message.status,
      });
    }
  }

  async function updateProperty(propertyObject) {
    propertyObject.propertyuniqueId = state.propertyuniqueId;
    propertyObject.apiId = state.apiId;

    let addPropertyResultUpdate = await ApiServices.UpdateProperty(
      propertyObject
    );
    if (addPropertyResultUpdate.error == 0) {
      $("document").ready(function () {
        $("#add-property-modal").find("input").val("").end();
      });

      // getMyProperties(); //----------------------refresh my properties----------------------//
      updateState({
        successMessage: "Rental has been updated successfully",
        loader: false,
        propertySuccess: true,
        propertyName: "",
        propertyLink: "",
        address: "",
        propertyNotes: "",
        uploadImages: [],
        availableTillDate: null,
        EditMoveDate: null,
      });
    } else {
      updateState({
        isPropertyError: true,
        loader: false,
        errorMessage: addPropertyResultUpdate.message.status,
      });
    }
    setTimeout(() => {
      closeModal("recommend-edit-rental");
      window.scroll(0, 0);
      refreshState();
      getMyProperties(state.transfereeuniqueId);
    }, 5000);
  }

  function fillEditDataInState(editRentalData) {
    // console.log(editRentalData);
    updateState({
      propertyName: editRentalData.propertyname,
      virtual360tour: editRentalData.virtual360tour,
      monthlyrent: editRentalData.monthlyrent,
      hometype: editRentalData.hometype,
      bedrooms: editRentalData.bedrooms,
      bathrooms: editRentalData.bathrooms,
      unitnumber: editRentalData.unitnumber,
      flooring: editRentalData.flooring,
      sqfeet: editRentalData.sqfeet,
      comments: editRentalData.Comments,
      availableTillDate: editRentalData.available_till_date,
      EditMoveDate: new Date(editRentalData.available_till_date),
      propertyuniqueId: editRentalData.propertyuniqueId,
      apiId: editRentalData.apiId,
      address: editRentalData.address,
      city: editRentalData.city,
      state: editRentalData.state,
      propertyanalyticsuniqueId: editRentalData.propertyanalyticsuniqueId,
      latitude: editRentalData.latitude,
      longitude: editRentalData.longitude,
      propertyNotes: editRentalData.notes,
      propertyLink: editRentalData.propertylink,
      amenities: editRentalData.propertylink || "",
    });
  }

  useEffect(() => {
    getConsultantProperties();
  }, []);
  const close = () => {
    $("#recommend-edit-rental").modal("hide");
    $("#monthlyrent").removeClass("is-invalid");
    $(".ant-validation").removeClass("is-invalid");
    setState((prev) => ({ ...prev, ShowSuccess: false }));
  };
  useEffect(() => {
    if (editRentalData) {
      fillEditDataInState(editRentalData);
    } else {
      refreshState();
    }
    console.log(editRentalData);
  }, [editRentalData]);

  function xxx() {}

  useEffect(() => {
    const myModalEl = document.getElementById("recommend-edit-rental");

    function handleHiddenModal(event) {
      refreshState();
    }

    function handleShownModal(event) {
      console.log("opened", editMode, editRentalData);
      editMode && fillEditDataInState(editRentalData);
    }
    myModalEl.addEventListener("hidden.bs.modal", handleHiddenModal);
    myModalEl.addEventListener("show.bs.modal", handleShownModal);

    return () => {
      myModalEl.removeEventListener("hidden.bs.modal", handleHiddenModal);
      myModalEl.removeEventListener("show.bs.modal", handleShownModal);
    };
  });

  return (
    <div
      className="modal fade"
      id="recommend-edit-rental"
      tabIndex="-1"
      aria-labelledby="recommend-edit-rental"
      aria-hidden="true"
      data-bs-backdrop="static"
      style={{ zIndex: 1060 }}
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content ">
          <div className="width-80per pd-y-60">
            <div className="only_close">
              <span
                className="pointer close"
                data-bs-dismiss="modal"
                // aria-label="Close"
                onClick={() => close()}
              >
                &times;
              </span>
            </div>
            <form
              method="post"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              {state.errorMessage && (
                <h1 className="valid">{state.errorMessage}</h1>
              )}

              {state.successMessage && (
                <div className="pop-form-controls focus-input-label mt-4">
                  <div className="floating-label">
                    <h6 className="alert alert-success">
                      {editMode
                        ? "Recommended rental updated successfully."
                        : "Recommended rental added successfully."}
                    </h6>
                  </div>
                </div>
              )}

              {!state.successMessage && (
                <>
                  <h6>
                    {editMode
                      ? "Edit rental"
                      : "Please add the rental details below"}
                  </h6>
                  <div className="pop-form-controls focus-input-label mt-4">
                    {editMode ? (
                      <div className="floating-label">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name of property/community"
                          value={state.propertyName}
                          autoComplete="new-password"
                          id="propertyName"
                          // onChange={(e) => inputHandler("propertyName", e)}
                          disabled
                        />
                      </div>
                    ) : (
                      <div>
                        <label>Name of property/community*</label>
                        <div className="ant-validation">
                          <Select
                            showSearch
                            className="autoComplete form-control p-0"
                            id="propertyName"
                            value={state.propertyName}
                            style={{ width: "100%" }}
                            onChange={(e) => onChangeTypes("propertyName", e)}
                            filterOption={
                              (input, option) =>
                                (option?.label ?? "")
                                  .replace(/\s+/g, "") // Remove spaces from option label
                                  .toLowerCase()
                                  .includes(
                                    input.replace(/\s+/g, "").toLowerCase()
                                  ) // Remove spaces from input
                            }
                          >
                            {state.propertyListNew}
                          </Select>
                        </div>
                      </div>
                    )}

                    <div className="floating-label">
                      <input
                        className="form-control"
                        placeholder="Virtual/360 Tour link here"
                        value={state.virtual360tour}
                        autoComplete="off"
                        type="text"
                        id="propertyLink"
                        onChange={(e) => inputHandler("virtual360tour", e)}
                      />
                      <label>Virtual/360 Tour</label>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label className="label_cls">Available Date</label>
                        <div className="date-pic-mypro mb-2">
                          <DatePicker
                            selected={state.EditMoveDate}
                            onChange={handleDateChange}
                            minDate={new Date()}
                            dateFormat="MM/dd/yyyy"
                            placeholderText={"MM/DD/YYYY"}
                            onKeyDown={(e) => e.preventDefault()}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="label_cls">Monthly Rent ($)*</label>
                        <input
                          className="form-control"
                          type="number"
                          id="monthlyrent"
                          value={state.monthlyrent}
                          autoComplete="off"
                          onChange={(e) => inputHandler("monthlyrent", e)}
                        />
                      </div>
                    </div>

                    <label className="label_cls">Home Type*</label>
                    <div className="ant-validation">
                      <Select
                        className="autoComplete form-control p-0"
                        id="hometype"
                        value={state.hometype}
                        style={{ width: "100%" }}
                        onChange={(e) => onChangeTypes("hometype", e)}
                      >
                        {generateSelectoptions(dropDownLists.children.sort())}
                      </Select>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <label className="label_cls">Bedrooms*</label>
                        <div className="ant-validation">
                          <Select
                            className="autoComplete form-control p-0"
                            id="bedrooms"
                            style={{ width: "100%" }}
                            value={state.bedrooms}
                            onChange={(e) => onChangeTypes("bedrooms", e)}
                          >
                            {generateSelectoptions(dropDownLists.bedrromslist)}
                          </Select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="label_cls">Bathrooms*</label>
                        <div className="ant-validation">
                          <Select
                            className="autoComplete form-control p-0"
                            id="bathrooms"
                            style={{ width: "100%" }}
                            value={state.bathrooms}
                            onChange={(e) => onChangeTypes("bathrooms", e)}
                          >
                            {generateSelectoptions(dropDownLists.bathroomslist)}
                          </Select>
                        </div>
                      </div>
                    </div>

                    <div>
                      <label className="label_cls">Unit Number </label>
                      <input
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={state.unitnumber}
                        id="unitnumber"
                        onChange={(e) => inputHandler("unitnumber", e)}
                      />
                      <div className="row">
                        <div className="col-md-6">
                          <label className="label_cls">Floor </label>
                          <input
                            type="number"
                            className="form-control"
                            autoComplete="off"
                            value={state.flooring}
                            id="flooring"
                            min="1"
                            max="99"
                            size="1"
                            maxLength="2"
                            onChange={(e) => inputHandler("flooring", e)}
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="label_cls">Square Feet</label>
                          <input
                            type="number"
                            className="form-control"
                            autoComplete="off"
                            value={state.sqfeet}
                            id="sqfeet"
                            onChange={(e) => inputHandler("sqfeet", e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Comments</label>
                      <textarea
                        className="form-control"
                        rows="4"
                        value={state.comments}
                        //placeholder="Notes"
                        id="comments"
                        onChange={(e) => inputHandler("comments", e)}
                      />
                    </div>

                    {
                      <div className="text-center mt-3 mb-3">
                        {state.loader ? (
                          <Spin />
                        ) : (
                          <button
                            type="submit"
                            className="btn theme-btn"
                            onClick={() => addMyProperties("true")}
                          >
                            {editMode ? "SAVE" : "RECOMMEND TO TRANSFEREE"}
                            <span></span>
                          </button>
                        )}
                      </div>
                    }
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendEditRental;
