import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Spin, AutoComplete, Select } from "antd";
import ApiStore from "../../../../services/apiServices";
import LogInStore from "../../../../services/thirdPartyService";
import QuestionConsultant from "./Modals/QuestionConsultant";
import TransfereeModal from "./Modals/TransfereeModal";

const SupplierTransfereeList = () => {
  const navigate = useNavigate();
  const [State, setState] = useState({
    filterTable: null,
    dataSource: [],
    columns: [
      {
        title: "Id",
        dataIndex: "userId",
        key: "userId",
        sorter: (a, b) => a.userId - b.userId,
      },
      {
        title: "Name",
        dataIndex: "name",
        key: Math.random(),
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text, record) => (
          <a
            onClick={() => onClickRow(record)}
            data-bs-toggle="modal"
            data-bs-target="#transferee-preferences-modal"
          >
            {" "}
            {text}
          </a>
        ),
      },
      {
        title: "Email",
        dataIndex: "EmailId",
        key: "EmailId",
        sorter: (a, b) => a.EmailId.localeCompare(b.EmailId),
      },
      {
        title: "Phone",
        dataIndex: "PhoneNumber",
        key: "PhoneNumber",
        sorter: (a, b) => a.PhoneNumber - b.PhoneNumber,
      },
      {
        title: "Type",
        dataIndex: "subscriptioncode",
        key: "subscriptioncode",
        sorter: (a, b) => a.subscriptioncode.localeCompare(b.subscriptioncode),
      },
      {
        title: "Total",
        dataIndex: "transfereehours",
        key: "transfereehours",
        sorter: (a, b) => {
          const extractHours = (str) => parseInt(str.split(" ")[0]);

          const hoursA = extractHours(a.transfereehours);
          const hoursB = extractHours(b.transfereehours);

          return hoursA - hoursB;
        },
      },
      {
        title: "Used",
        dataIndex: "transfereescheduledhours",
        key: "transfereescheduledhours",
        sorter: (a, b) => {
          const extractHours = (str) => parseInt(str.split(" ")[0]);

          const hoursA = extractHours(a.transfereescheduledhours);
          const hoursB = extractHours(b.transfereescheduledhours);

          return hoursA - hoursB;
        },
      },
      {
        title: "Consultant",
        dataIndex: "consultantname",
        key: "consultantname",
        sorter: (a, b) => a.consultantname.localeCompare(b.consultantname),
        render: (text) => {
          return <span>{text}</span>;
        },
      },
      {
        title: "Action",
        dataIndex: "",
        key: "x",
        render: (text, record) => (
          <span>
            <a style={{ color: "black" }} onClick={() => onClickmore(record)}>
              <i className="fa fa-edit" aria-hidden="true"></i>
            </a>
            <a
              style={{ color: "black" }}
              onClick={() => onClickStatus(record)}
              data-bs-toggle="modal"
              data-bs-target="#all-Status"
            >
              <i className="fa fa-bell"></i>
            </a>
          </span>
        ),
      },
    ],
    answersPrefer: [],
    answersPreferLoading: false,
    QuestionPop: false,
    keyWordsArray: [],
    activitiesArray: [],
    listOfProperties: [],
    questions: [],
    TransfereeStatus: [],
    getQuestionListStatus: false,
    allStatus: false,
    allstatuslist: [],
    addTransfereeModal: false,
    loader: false,
    consultantList: [],
    consultantData: [],
  });

  useEffect(() => {
    GetStatusList();
    getTransferee();
  }, []);

  async function GetStatusList() {
    const GetStatusList = await ApiStore.GetStatusList();

    if (GetStatusList.error == 0) {
      GetStatusList.message.noteslist.forEach((valueObject) => {
        if (valueObject.identifier == "Registration email sent")
          valueObject.orderId = 1;
        if (valueObject.identifier == "Transferee Registered successfully")
          valueObject.orderId = 2;
        if (valueObject.identifier == "Transferee logged in for the first time")
          valueObject.orderId = 3;
        if (valueObject.identifier == "Updated Home Preferences")
          valueObject.orderId = 4;
        if (valueObject.identifier == "Utilized digital checklists")
          valueObject.orderId = 5;
        if (valueObject.identifier == "Scheduled appointments with consultant")
          valueObject.orderId = 6;
      });

      let SortStatus = GetStatusList.message.noteslist.sort((o) => {
        return parseInt(o.orderId, 10);
      });
      updateState({ allstatuslist: SortStatus });
    }
  }
  const onClickStatus = async (record) => {
    updateState({ loader: true });
    let data = {
      transfereeuid: record.userUniqueId,
    };
    const GetTransfereeStatus = await ApiStore.GetTransfereeStatus(data);

    if (GetTransfereeStatus.error == 0) {
      updateState({
        loader: false,
        TransfereeStatus: GetTransfereeStatus.message.noteslist,
        allStatus: true,
      });
    }
  };

  async function getTransferee() {
    try {
      updateState({ loader: true });
      let consultantlist = await fetchConsultantsData();
      let consultantData = consultantlist.message.consultants;
      // updateState({ consultantData: consultantlist.message.consultants });
      if (consultantlist.error == 0) {
        let getsession = await LogInStore.getLoginInfo();

        let getTransfereeObj = {
          supplieruniqueid: getsession.userUniqueId,
        };
        let TransfereesList_Result = await ApiStore.GetConsultantTransferees(
          getTransfereeObj
        );

        let dataSource = State.dataSource;
        if (TransfereesList_Result.error == 0) {
          let transferees = TransfereesList_Result.message.transfereeslist;
          transferees.forEach((each) => {
            if (each.userFirstName != "") {
              each.key = each.userId;
              each.name = each.userFirstName + " " + each.userLastName;
              each.transfereescheduledhours =
                each.transfereescheduledhours + " Hrs";
              each.transfereehours = each.transfereehours + " Hrs";
              each.consultantname = consultantName(
                each.consultantUId,
                consultantData
              );
              if (each.userFirstName != "v5QCdDCaM9UdpHurW0z8nw==")
                dataSource.push(each);
            }
          });

          const uniqueArray = dataSource.filter((thing, index) => {
            const _thing = JSON.stringify(thing);
            return (
              index ===
              dataSource.findIndex((obj) => {
                return JSON.stringify(obj) === _thing;
              })
            );
          });

          updateState({
            dataSource: _.orderBy(uniqueArray, ["userId"], ["desc"]),
            loader: false,
          });
        } else {
          updateState({
            loader: false,
          });
        }
      } else {
        updateState({
          loader: false,
        });
      }
    } catch (error) {}
  }
  async function fetchConsultantsData() {
    let getsession = await LogInStore.getLoginInfo();
    let getTransfereeObject = {
      supplieruid: getsession.userUniqueId,
    };
    let ConsultantList_Result = await ApiStore.GetAllConsultants(
      getTransfereeObject
    );
    let dataSource = [];
    if (ConsultantList_Result.error == 0) {
      let consultants = ConsultantList_Result.message.consultants;
      _.forEach(consultants, async (transferee, index) => {
        if (consultants.userFirstName != "") {
          transferee.key = transferee.uniqueId;
          transferee.name = transferee.firstname + " " + transferee.lastname;

          dataSource.push(transferee);
        }
      });
      const uniqueArray = dataSource.filter((thing, index) => {
        const _thing = JSON.stringify(thing);
        return (
          index ===
          dataSource.findIndex((obj) => {
            return JSON.stringify(obj) === _thing;
          })
        );
      });

      updateState({ consultantList: uniqueArray });
      return ConsultantList_Result;
    } else {
    }
  }
  const consultantName = (id, consultantData) => {
    // console.log(id, "xxxx", consultantData);
    const consultant = consultantData.find((c) => c.uniqueId === id);
    if (consultant) {
      return `${consultant.firstname} ${consultant.lastname}`;
    } else {
      return "Unknown";
    }
  };
  async function onClickRow(record) {
    getUserPreferences(record.userUniqueId);
  }

  async function getUserPreferences(userUniqueId) {
    updateLoaderState("start");
    let userPreferanceObject = {
      transfereeuniqeuid: userUniqueId,
      istemporary: false,
    };

    try {
      let userSavedPreferances = await ApiStore.GetTransfereePreferences(
        userPreferanceObject
      );
      if (userSavedPreferances.error == 0) {
        let userAnswerPreferanceObject =
          userSavedPreferances.message.preferences;
        console.log(userAnswerPreferanceObject, "userAnswerPreferanceObject");

        updateState({
          answersPrefer: userAnswerPreferanceObject,
        });
      }
    } catch (e) {
      console.log(e);
    }
    updateLoaderState("stop");
  }

  function updateLoaderState(action) {
    if (action === "start") {
      updateState({ answersPreferLoading: true });
    } else if (action === "stop") {
      updateState({ answersPreferLoading: false });
    }
  }
  const handleSearch = (value) => {
    const { dataSource } = State;
    const filterTable = dataSource.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    updateState({ filterTable });
  };

  async function onClickmore(row) {
    // console.log(row, "row of the data");
    await localStorage.setItem("moredetails", JSON.stringify(row));
    navigate("/transfereeMore-details", { state: { row } });
    // window.open("/more-details", "myWindow", { state: { row } });
  }

  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }
  return (
    <div>
      {/* {State.loader ? <div className="loading">Loading</div> : ""} */}

      <div className="main_content trans-anttable">
        <div className="container">
          <div className="table-top-input-btn">
            <AutoComplete
              style={{ marginTop: "10px", marginBottom: "10px", width: 400 }}
              onSearch={handleSearch}
              placeholder="Search by..."
            />
            {/* <span className="cursor add-list">
              <button
                type="submit"
                data-bs-toggle="modal"
                data-bs-target="#add-transferee-modal"
                className="btn theme-btn"
              >
                Add Transferee{" "}
                <i className="fa fa-plus-square-o" aria-hidden="true"></i>{" "}
              </button>{" "}
            </span> */}
          </div>
          {State.loader ? (
            <Spin />
          ) : State.dataSource.length > 0 ? (
            <Table
              dataSource={
                State.filterTable == null ? State.dataSource : State.filterTable
              }
              columns={State.columns}
              pagination={{
                defaultPageSize: 20,
                pageSizeOptions: ["20", "25", "30"],
                showSizeChanger: true,
                locale: { items_per_page: "" },
              }}
            />
          ) : (
            // <div className="loading">Loading</div>
            <div>No records found</div>
          )}
        </div>
      </div>
      <QuestionConsultant state={State} updateState={updateState} />
      <TransfereeModal State={State} />
    </div>
  );
};
export default SupplierTransfereeList;
