import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import FilterComponent from "./common/FilterComponent";
import TableComponent from "./common/TableComponent";
import { Spin } from "antd";
import moment from "moment";
import LoginStore from "../../../../services/thirdPartyService";
import "./common/css/sreports.css";
import "./common/css/reports.css";
const AppointementReports = () => {
  const [state, setState] = useState({
    supplierid: null,
    loader: false,
    reportslist: [],
    columns: [
      {
        title: "Consultant Email",
        dataIndex: "consultantemail",
        key: Math.random(),
      },
      {
        title: "Transferee Email",
        dataIndex: "transfereeemail",
        key: "transfereeemail",
      },
      {
        title: "Service level",
        dataIndex: "servicelevel",
        key: "servicelevel",
      },
      {
        title: "Appointment",
        dataIndex: "appointmenttype",
        key: "appointmenttype",
      },
      {
        title: "Mode",
        dataIndex: "mode",
        key: "mode",
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "Time",
        dataIndex: "",
        key: "x",
        render: (text, record) => {
          return (
            <span>
              {record.fromtime}-{record.totime}
            </span>
          );
        },
      },
    ],
    startDate: null,
    endDate: null,
    companydata: [],
    company: "",
    servicelevel: "",
  });

  function handleChangeStart(startDate) {
    updateState({ startDate });
  }
  function handleChangeEnd(endDate) {
    updateState({ endDate });
  }

  function inputHandler(field, value) {
    updateState({ [field]: value });
  }

  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  async function fetchUserInfoAndUpdateToState() {
    let getSessionData = await LoginStore.getLoginInfo({});
    updateState({ supplierid: getSessionData.userUniqueId });
  }

  useEffect(() => {
    fetchUserInfoAndUpdateToState();
  }, []);

  return (
    <div className="dashboard-ds">
      <div className="consultant-report-views">
        <div className="container">
          <div className="consultant-report-views-inputvalues">
            <div className="heaidng-view">
              <h1>Appointment Report</h1>
              {state.reportslist.length > 0 && (
                <CSVLink
                  filename={"TableContent.csv"}
                  data={state.reportslist}
                  className="btn"
                >
                  Export CSV
                </CSVLink>
              )}
            </div>
            <FilterComponent
              reportType={"appointment"}
              fromDate={{
                startDate: state.startDate,
                endDate: state.endDate,
                handleChangeStart,
              }}
              toDate={{
                startDate: state.startDate,
                endDate: state.endDate,
                handleChangeEnd,
              }}
              company={{
                company: state.company,
                companydata: state.companydata,
                inputHandler: inputHandler,
              }}
              servicelevel={{
                inputHandler: inputHandler,
                servicelevel: state.servicelevel,
              }}
              updateState={updateState}
              supplierid={state.supplierid}
            />
          </div>
          {state.loader ? (
            <Spin />
          ) : (
            <TableComponent
              reportslist={state.reportslist}
              columns={state.columns}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AppointementReports;
