import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import jsPDF from "jspdf";
import moment from "moment";

function PetPdfDownload() {
  const location = useLocation();
  //   const state = location.state;

  const queryParams = new URLSearchParams(location.search);

  // Convert query parameters into an object
  const state = Object.fromEntries(queryParams.entries());
  console.log(state, "state");

  useEffect(() => {
    // Generate and download PDF immediately when component mounts
    if (state) {
      generatePDF();

      // Optionally redirect back or to another page after a short delay
      setTimeout(() => {
        window.history.back(); // Go back to previous page
        // OR window.location.href = '/some-other-page'; // Redirect elsewhere
      }, 100);
    }
  }, []);

  const generatePDF = () => {
    const doc = new jsPDF("p", "pt");
    doc.text(39, 30, "Pet Resume");

    // Create all tables first
    createPDFContent(doc);

    // Try to add image, but don't wait for it
    try {
      var img = document.createElement("img");
      img.src = "https://relonavigator.com/assets/newImages/relo-b&WLogin.png";
      doc.addImage(img, "JPEG", 400, 10, 150, 13);
    } catch (e) {
      console.error("Failed to add image:", e);
    }

    // Save the PDF
    doc.save("petresume.pdf");
  };

  const createPDFContent = (doc) => {
    // Contact info table
    doc.autoTable({
      columnStyles: {
        petdetails: { fillColor: [255, 255, 255], cellWidth: 190 },
        colon: { fillColor: [255, 255, 255], cellWidth: 50 },
        text: { fillColor: [255, 255, 255] },
      },
      headStyles: { fillColor: [220, 220, 220], textColor: [0, 0, 0] },
      body: [
        { petdetails: "Name", colon: ":", text: state.firstname },
        { petdetails: "Age", colon: ":", text: state.age },
        { petdetails: "Breed", colon: ":", text: state.Breed },
        { petdetails: "Weight", colon: ":", text: state.Weight },
        { petdetails: "Gender", colon: ":", text: state.Gender },
        { petdetails: "About:", colon: "", text: "" },
        [{ content: state.About, colSpan: 4, styles: {} }],
      ],
      columns: [
        { header: "Pet Details", dataKey: "petdetails" },
        { header: "", dataKey: "colon" },
        { header: "", dataKey: "text" },
      ],
    });
    doc.autoTable({
      columnStyles: {
        ownersdetails: { fillColor: [255, 255, 255], cellWidth: 190 },
        colon: { fillColor: [255, 255, 255], cellWidth: 50 },
        text: { fillColor: [255, 255, 255] },
      },
      headStyles: { fillColor: [220, 220, 220], textColor: [0, 0, 0] },

      body: [
        { ownersdetails: "Pet owner", colon: "", text: "" },
        { ownersdetails: "Name", colon: ":", text: state.OwnerName },
        {
          ownersdetails: "Mobile",
          colon: ":",
          text: "+" + state.Ownercountrycode + " " + state.OwnerMobile,
        },
        { ownersdetails: "Email", colon: ":", text: state.OwnerEmail },
        { ownersdetails: "Address", colon: ":", text: state.OwnerAddress },
      ],
      columns: [
        { header: "Owners Details", dataKey: "ownersdetails" },
        { header: "", dataKey: "colon" },
        { header: "", dataKey: "text" },
      ],
    });
    doc.autoTable({
      columnStyles: {
        healthroutine: { fillColor: [255, 255, 255], cellWidth: 190 },
        colon: { fillColor: [255, 255, 255], cellWidth: 50 },
        text: { fillColor: [255, 255, 255] },
      },
      headStyles: { fillColor: [220, 220, 220], textColor: [0, 0, 0] },
      body: [
        {
          healthroutine: "Qualities",
          colon: ":",
          text: state.Qualities.length > 0 ? state.Qualities : "",
        },
        { healthroutine: "Health issues in the past?:", colon: "", text: "" },
        [{ content: state.Healthissues, colSpan: 4, styles: {} }],
        { healthroutine: "Care Routine:", colon: "", text: "" },
        [{ content: state.CareRoutine, colSpan: 4, styles: {} }],
      ],
      columns: [
        { header: "Health & Routine (Optional)", dataKey: "healthroutine" },
        { header: "", dataKey: "colon" },
        { header: "", dataKey: "text" },
      ],
    });
    doc.autoTable({
      columnStyles: {
        trainingroutine: { fillColor: [255, 255, 255], cellWidth: 190 },
        colon: { fillColor: [255, 255, 255], cellWidth: 50 },
        text: { fillColor: [255, 255, 255] },
      },
      headStyles: { fillColor: [220, 220, 220], textColor: [0, 0, 0] },
      body: [
        {
          trainingroutine: "Has your pet graduated from a training class?",
          colon: ":",
          text: state.graduated,
        },
        { trainingroutine: "Groomer", colon: "", text: "" },
        {
          trainingroutine: "Groomer name",
          colon: ":",
          text: state.Groomername,
        },
        {
          trainingroutine: "Mobile",
          colon: ":",
          text:
            state.GroomerMobile != ""
              ? "+" + state.Groomercountrycode + " " + state.GroomerMobile
              : "",
        },
        { trainingroutine: "Vet", colon: "", text: "" },
        { trainingroutine: "Vet name", colon: ":", text: state.Vetname },
        {
          trainingroutine: "Mobile",
          colon: ":",
          text:
            state.VetMobile != ""
              ? "+" + state.Vetcountrycode + " " + state.VetMobile
              : "",
        },
        { trainingroutine: "References", colon: "", text: "" },
        {
          trainingroutine: "Previous landlord name",
          colon: ":",
          text: state.landlord,
        },
        {
          trainingroutine: "Mobile",
          colon: ":",
          text:
            state.ReferencesMobile != ""
              ? "+" + state.Refcountrycode + " " + state.ReferencesMobile
              : "",
        },
      ],
      columns: [
        { header: "Training & Other (Optional)", dataKey: "trainingroutine" },
        { header: "", dataKey: "colon" },
        { header: "", dataKey: "text" },
      ],
    });
  };

  return null;
}

export default PetPdfDownload;
