import React, { useState, useEffect, useRef } from "react";
import { Select, AutoComplete, Spin } from "antd";
import DatePicker from "react-datepicker";
import ApiServices from "../../../../../services/apiServices";
import LoginStore from "../../../../../services/thirdPartyService";
// import { countryCodesData } from "../../../../../common/InternationalCountryCodes/CountryCodeData";
import * as $ from "jquery";
import "intl-tel-input/build/css/intlTelInput.css"; // Import CSS file
import intlTelInput from "intl-tel-input";
import moment from "moment";
const AddTransferee = (props) => {
  const { getTransferee } = props;
  const phoneNumberRef = useRef(null);
  const initialState = {
    commanderError: "",
    serviceError: "",
    dataSource: [],
    errorMessage: "",
    ShowSuccess: "",
    editid: "",
    showing: "",
    firstname: "",
    lastname: "",
    email: "",
    phoneNumber: "",
    clientuniqueid: null,
    servicelevel: null,
    companydata: [],
    clientuniqueid: "",
    companyvirtualhours: null,
    individualvirtualhours: null,
    companyinpersonhours: null,
    individualinpersonhours: null,
    referenceid: "",
    po: "",
    approvedhours: "",
    sendemail: false,
    editid: false,
    usernotes: "",
    uploadLoader: false,
    startDate: null,
    endDate: null,
    countryDetails: "",
    loader: false,
    recordval: "",
    notesuccess: "",
    notesslist: [],
    allstatuslist: [],
    TransfereeStatus: [],
    getSessionData: "",
    isTemporary: false,
  };
  const [state, setstate] = useState(initialState);
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    const iti = intlTelInput(phoneNumberRef.current, {
      separateDialCode: true,
      initialCountry: "us",
    });

    phoneNumberRef.current.addEventListener("countrychange", () => {
      const selectedCountryData = iti.getSelectedCountryData();
      updateState({ countryDetails: selectedCountryData["dialCode"] });
      // console.log("Selected country:", selectedCountryData["dialCode"]);
    });

    return () => {
      iti.destroy();
    };
  }, []);

  useEffect(() => {
    companyData();
  }, []);

  async function companyData() {
    const GetStatusList = await ApiServices.GetStatusList();

    if (GetStatusList.error == 0) {
      GetStatusList.message.noteslist.forEach((valueObject) => {
        if (valueObject.identifier == "Registration email sent")
          valueObject.orderId = 1;
        if (valueObject.identifier == "Transferee Registered successfully")
          valueObject.orderId = 2;
        if (valueObject.identifier == "Transferee logged in for the first time")
          valueObject.orderId = 3;
        if (valueObject.identifier == "Updated Home Preferences")
          valueObject.orderId = 4;
        if (valueObject.identifier == "Utilized digital checklists")
          valueObject.orderId = 5;
        if (valueObject.identifier == "Scheduled appointments with consultant")
          valueObject.orderId = 6;
      });

      let SortStatus = _.sortBy(GetStatusList.message.noteslist, [
        function (o) {
          return parseInt(o.orderId, 10);
        },
      ]);

      await updateState({
        allstatuslist: SortStatus,
      });
    }
    let getSessionData = await LoginStore.getLoginInfo({});

    updateState({ getSessionData: getSessionData });
    // console.log("getSessionData", getSessionData);
    let getTransfereeObj = {
      supplieruid: getSessionData.supplieruid,

      // supplieruid: "90f04e8c-0f75-44a8-92d0-4e089a17d113", //Change this after login functionality
    };
    let ClientProfile_Result = await ApiServices.GetAllClientProfile(
      getTransfereeObj
    );

    let mainn = ClientProfile_Result.message.clientProfileList;
    var newArray = mainn.sort((first, second) => {
      var a = first.companyname.toLowerCase();
      var b = second.companyname.toLowerCase();
      if (a > b) {
        return 1;
      } else if (a < b) {
        return -1;
      } else {
        return 0;
      }
    });

    await updateState({ companydata: newArray });
  }

  useEffect(() => {
    return () => {
      console.log("Component will unmount");
    };
  }, []);

  function handleChange(name, e) {
    console.log(name, "handle change", e.target.value);
    let value = e.target ? e.target.value : e;

    let object = {};
    object[name] = value;
    // updateState(object);
    setstate((prevState) => ({
      ...prevState,
      ...object,
    }));
    console.log(state, "shitty update");
    var summ = 0;
    if (
      object.servicelevel == "Commander" ||
      state.servicelevel == "Commander"
    ) {
      console.log(object.servicelevel, state.approvedhours, "obj1");
      let companyInPersonHours =
        object.companyinpersonhours != "" && !isNaN(object.companyinpersonhours)
          ? parseInt(object.companyinpersonhours)
          : state.companyinpersonhours || 0;
      let companyVirtualHours =
        object.companyvirtualhours != "" && !isNaN(object.companyvirtualhours)
          ? parseInt(object.companyvirtualhours)
          : state.companyvirtualhours || 0;
      let individualInPersonHours =
        object.individualinpersonhours != "" &&
        !isNaN(object.individualinpersonhours)
          ? parseInt(object.individualinpersonhours)
          : state.individualinpersonhours || 0;
      let individualVirtualHours =
        object.individualvirtualhours != "" &&
        !isNaN(object.individualvirtualhours)
          ? parseInt(object.individualvirtualhours)
          : state.individualvirtualhours || 0;
      summ =
        parseInt(companyInPersonHours) +
        parseInt(companyVirtualHours) +
        parseInt(individualInPersonHours) +
        parseInt(individualVirtualHours);
      setstate((prev) => ({ ...prev, approvedhours: summ }));
    }
    if (
      object.servicelevel == "ReloNavigator" ||
      state.servicelevel == "ReloNavigator"
    ) {
      setstate((prev) => ({ ...prev, approvedhours: 0 }));
    }
    if (object.servicelevel == "Explorer" || state.servicelevel == "Explorer") {
      let summ1 = 0;
      let companyVirtualHours =
        object.companyvirtualhours != "" && !isNaN(object.companyvirtualhours)
          ? parseInt(object.companyvirtualhours)
          : state.companyvirtualhours || 0;
      let individualVirtualHours =
        object.individualvirtualhours != "" &&
        !isNaN(object.individualvirtualhours)
          ? parseInt(object.individualvirtualhours)
          : state.individualvirtualhours || 0;
      summ1 = parseInt(companyVirtualHours) + parseInt(individualVirtualHours);
      setstate((prev) => ({ ...prev, approvedhours: summ1 }));
    }
    const regex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const phoneRegex = /^(\d{0,3})(\d{0,3})(\d{0,4})$/;
    const numReplace = /\D/g;

    if (name === "email") {
      if (regex.test(value)) {
        $("#" + name).removeClass("is-invalid");
      } else {
        $("#" + name).addClass("is-invalid");
      }
    }
    //  else if (name === "phoneNumber") {
    //   const formattedPhone = value.replace(numReplace, "").match(phoneRegex);

    //   if (formattedPhone) {
    //     e.target.value = !formattedPhone[2]
    //       ? formattedPhone[1]
    //       : `(${formattedPhone[1]}) ${formattedPhone[2]}${
    //           formattedPhone[3] ? "-" + formattedPhone[3] : ""
    //         }`;
    //     $("#" + name).removeClass("is-invalid");
    //   } else {
    //     $("#" + name).addClass("is-invalid");
    //   }
    // }
    else {
      if (!value.trim()) {
        $("#" + name).addClass("is-invalid");
      } else {
        $("#" + name).removeClass("is-invalid");
      }
    }
  }

  function updateState(data) {
    setstate((prevState) => ({
      ...prevState,
      ...data,
    }));
  }

  function handleDateChange(e, date) {
    let object = {};
    object[date] = e;
    if (date == "startDate") {
      updateState(object);
    } else {
      updateState(object);
    }
  }

  async function addProperty(e) {
    e.preventDefault();
    let error = 0;
    const phoneNumberRegex =
      /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const validationFields = [
      "firstname",
      "lastname",
      // "phoneNumber",
      "email",
      "servicelevel",
    ];
    validationFields.forEach((element) => {
      if (!state[element]) {
        error++;
      }
    });

    if (error != 0) {
      if (state.firstname.trim() == "") {
        $("#firstname").addClass("is-invalid");
        error++;
      } else {
        $("#firstname").removeClass("is-invalid");
      }
      if (state.lastname.trim() == "") {
        $("#lastname").addClass("is-invalid");
        error++;
      } else {
        $("#lastname").removeClass("is-invalid");
      }
      if (state.email == "") {
        $("#email").addClass("is-invalid");
        error++;
      } else {
        $("#email").removeClass("is-invalid");
      }
      // if (state.phoneNumber == "") {
      //   $("#phoneNumber").addClass("is-invalid");
      //   error++;
      // } else {
      //   $("#phoneNumber").removeClass("is-invalid");
      // }

      if (state.endDate == null) {
        $("#enddate").addClass("is-invalid");

        error++;
      } else {
        $("#enddate").removeClass("is-invalid");
      }
      if (state.startDate == null) {
        $("#startdate").addClass("is-invalid");

        error++;
      } else {
        $("#startdate").removeClass("is-invalid");
      }
      if (state.clientuniqueid == null) {
        $("#clientuniqueid").addClass("is-invalid");
      } else {
        $("#clientuniqueid").removeClass("is-invalid");
      }
      if (
        state.servicelevel == null ||
        state.servicelevel == "Select" ||
        state.servicelevel == " "
      ) {
        $("#servicelevel").addClass("is-invalid");

        // updateState({ serviceError: "please select the service level" });
        error++;
      } else {
        $("#servicelevel").removeClass("is-invalid");
        // updateState({ serviceError: "" });
      }
      if (state.servicelevel == "Commander") {
        if (
          state.companyinpersonhours == null ||
          state.companyvirtualhours == null ||
          state.individualinpersonhours == null ||
          state.individualvirtualhours == null
        ) {
          if (state.companyinpersonhours == null) {
            $("#companyinpersonhours").addClass("is-invalid");
            error++;
          } else {
            $("#companyinpersonhours").removeClass("is-invalid");
          }
          if (state.companyvirtualhours == null) {
            error++;
            $("#companyvirtualhours").addClass("is-invalid");
          } else {
            $("#companyvirtualhours").removeClass("is-invalid");
          }

          if (state.individualinpersonhours == null) {
            error++;
            $("#individualinpersonhours").addClass("is-invalid");
          } else {
            $("#individualinpersonhours").removeClass("is-invalid");
          }
          if (state.individualvirtualhours == null) {
            error++;
            $("#individualvirtualhours").addClass("is-invalid");
          } else {
            $("#individualvirtualhours").removeClass("is-invalid");
          }

          error++;
        }
      }
      if (state.servicelevel == "Explorer") {
        if (
          state.companyvirtualhours == null ||
          state.individualvirtualhours == null
        ) {
          if (state.companyvirtualhours == null) {
            error++;
            $("#companyvirtualhours").addClass("is-invalid");
          } else {
            $("#companyvirtualhours").removeClass("is-invalid");
          }
          if (state.individualvirtualhours == null) {
            error++;
            $("#individualvirtualhours").addClass("is-invalid");
          } else {
            $("#individualvirtualhours").removeClass("is-invalid");
          }

          error++;
        } else {
          // updateState({ commanderError: "" });
        }
      }
    }

    if (error == 0) {
      let data = {
        consultantUId: state.getSessionData.userUniqueId,

        firstname: state.firstname,
        lastname: state.lastname,
        clientuniqueid: state.clientuniqueid,
        countrycode: state.countryDetails,
        phonenumber: state.phoneNumber,
        emailid: state.email,
        password: "",
        middlename: "",
        age: "",
        dob: "",
        gender: "",
        profilepic: "",
        worklocationaddress1: "",
        worklocationaddress2: "",
        city: "",
        state: "",
        zipcode: "",
        // supplieruid: "90f04e8c-0f75-44a8-92d0-4e089a17d113",
        supplieruid: state.getSessionData.supplieruid,
        approvedhours: state.approvedhours,
        companyinpersonhours: state.companyinpersonhours,
        companyvirtualhours: state.companyvirtualhours,
        individualinpersonhours: state.individualinpersonhours,
        individualvirtualhours: state.individualvirtualhours,
        accessfromdate: moment(state.startDate).format("MM/DD/YYYY"),
        accesstodate: moment(state.endDate).format("MM/DD/YYYY"),
        clientuniqueid: state.clientuniqueid,
        servicelevel: state.servicelevel,
        notes: state.usernotes,
        usernotes: state.usernotes,
        po: state.po,
        referenceid: state.referenceid,
        sendemail: state.sendemail,
        housingrequests: [],
        istemporary: state.isTemporary,
      };

      updateState({ uploadLoader: true });
      console.log(data, "data");
      const CreateTransferee = await ApiServices.CreateTransferee(data);

      if (CreateTransferee.error == 0) {
        $("document").ready(function () {
          $("#add-property-modal").find("input").val("").end();
        });
        getTransferee();
        updateState({
          errorMessage: false,
          ShowSuccess: true,
          uploadLoader: false,
          showing: "Transferee created",
        });

        if (
          CreateTransferee.message != undefined &&
          CreateTransferee.message.transfereeuid != undefined
        ) {
          let data2 = {
            transfereeuid: CreateTransferee.message.transfereeuid,
          };
          const GetTransfereeStatus = await ApiServices.GetTransfereeStatus(
            data2
          );
          let ress = [];
          if (GetTransfereeStatus.error == 0) {
            ress = await _.filter(
              GetTransfereeStatus.message.noteslist,
              function (o) {
                return o.statusuid == "deb8d951-c80b-43df-b711-dad2c49baa03";
              }
            );
          }
          let StatusUpdateSendWelcome = {
            uniqueid:
              ress.length == 0 ? "" : "bd3ac619-7173-4a1c-b72c-ef0d9c2f8393",
            transfereeuid: CreateTransferee.message.transfereeuid,
            statusuid: "bd3ac619-7173-4a1c-b72c-ef0d9c2f8393",
            isactive: true,
          };
          const ManageTransfereeStatus =
            await ApiServices.ManageTransfereeStatus(StatusUpdateSendWelcome);
        }
      } else {
        updateState({
          errorMessage: true,
          existsmsg: CreateTransferee.message.status,
          ShowSuccess: false,
          uploadLoader: false,
        });
      }
      // }
    }
  }
  const handleClick = (event) => {
    setstate((prev) => ({ ...prev, clientuniqueid: event }));
  };
  async function onClickClose() {
    // updateState(initialState);
    setstate((prev) => ({
      ...prev,
      commanderError: "",
      serviceError: "",
      dataSource: [],
      errorMessage: "",
      ShowSuccess: "",
      editid: "",
      showing: "",
      firstname: "",
      lastname: "",
      email: "",
      phoneNumber: "",
      clientuniqueid: null,
      servicelevel: null,
      //companydata: companydatachildren,
      //clientuniqueid: "",
      companyvirtualhours: null,
      individualvirtualhours: null,
      companyinpersonhours: null,
      individualinpersonhours: null,
      referenceid: "",
      po: "",
      approvedhours: "",
      sendemail: false,
      editid: false,
      usernotes: "",
      uploadLoader: false,
      startDate: null,
      endDate: null,
      countryDetails: "",
      loader: false,
      recordval: "",
      notesuccess: "",
      notesslist: [],
      allstatuslist: [],
      TransfereeStatus: [],
      getSessionData: "",
      isTemporary: false,
    }));
    $(document).ready(function () {
      // Clear input values
      $("#add-transferee-modal").find("input,select").val("");

      // Remove validation classes
      $("#add-transferee-modal").find("input,select").removeClass("is-invalid");
    });

    // $("#clientuniqueid").removeClass("is-invalid");
    // $("#firstname").removeClass("is-invalid");
    // $("#lastname").removeClass("is-invalid");
    // $("#email").removeClass("is-invalid");
    // $("#phoneNumber").removeClass("is-invalid");
    // $("#servicelevel").removeClass("is-invalid");
    // $("#companyvirtualhours").removeClass("is-invalid");
    // $("#individualvirtualhours").removeClass("is-invalid");
    // $("#companyinpersonhours").removeClass("is-invalid");
    // $("#individualinpersonhours").removeClass("is-invalid");
    // $("#individualinpersonhours").removeClass("is-invalid");
    // $("#individualinpersonhours").removeClass("is-invalid");
    // $("#startdate").removeClass("is-invalid");
    // $("#enddate").removeClass("is-invalid");
  }

  // let countryDetails = [];
  // for (let i = 0; i < state.countrycode.length; i++) {
  //   countryDetails.push(

  //   );
  // }

  let companydatachildren = [];

  for (let i = 0; i < state.companydata.length; i++) {
    companydatachildren.push(
      <Select.Option key={state.companydata[i].clientuniqueId}>
        {state.companydata[i].companyname}
      </Select.Option>
    );
  }
  let servicele = [];
  var serv = ["", "Commander", "Explorer", "ReloNavigator"];
  for (let i = 0; i < serv.length; i++) {
    servicele.push(serv[i]);
  }

  const children = [];
  for (let i = 0; i < 31; i++) {
    children.push(i);
  }
  async function updatesendmail(value) {
    // value.persist();
    updateState({ sendemail: value.target.checked });
  }
  const handleKeyPress = (e) => {
    const key = e.key;
    const regex = /[0-9.]/; // Allow numeric digits and a period (dot)

    if (e.keyCode === 8 || regex.test(key)) {
      return;
    }
    e.preventDefault();
  };
  return (
    <div
      className="modal fade "
      id="add-transferee-modal"
      tabIndex="-1"
      aria-labelledby="add-transferee-modal"
      aria-hidden="true"
      data-bs-backdrop="static"
      style={{ zIndex: 1060 }}
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content ">
          <div className="width-70per pd-y-60">
            <div className="only_close">
              <span
                onClick={() => onClickClose()}
                className="pointer close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                &times;
              </span>
            </div>
            <form
              method="post"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              {state.editid == true ? (
                <h6>Edit Transferee</h6>
              ) : (
                <h6>Add Transferee</h6>
              )}
              {state.errorMessage == true ? (
                <div className="pop-form-controls focus-input-label mt-4">
                  <div className="floating-label">
                    <h6 className="alert alert-warning">{state.existsmsg}</h6>
                  </div>
                </div>
              ) : (
                ""
              )}
              {state.ShowSuccess == true ? (
                <div className="pop-form-controls focus-input-label mt-4">
                  <div className="floating-label">
                    <h6 className="alert alert-success">
                      {state.editid == true ? state.showing : state.showing}{" "}
                    </h6>
                  </div>
                </div>
              ) : (
                <div className="pop-form-controls focus-input-label mt-4">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="">
                        <label>First Name</label>
                        <input
                          placeholder="First Name"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          value={state.firstname}
                          id="firstname"
                          onChange={(e) => handleChange("firstname", e)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label>Last Name</label>
                        <input
                          placeholder="Last Name"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          value={state.lastname}
                          id="lastname"
                          onChange={(e) => handleChange("lastname", e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="">
                        <label>Email</label>
                        <input
                          placeholder="Email"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          value={state.email}
                          id="email"
                          onChange={(e) => handleChange("email", e)}
                          disabled={state.editid}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label>Phone Number</label>
                        <div className="phone-flag">
                          <input
                            ref={phoneNumberRef}
                            type="tel"
                            maxLength={10}
                            className="form-control"
                            autoComplete="off"
                            value={state.phoneNumber}
                            onKeyDown={handleKeyPress}
                            placeholder="phoneNumber"
                            id="phoneNumber"
                            onChange={(e) => handleChange("phoneNumber", e)}
                            // onBlur={  inputHandler.bind(this, "phoneNumber")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="">
                        <label className="label_cls">Company</label>

                        <Select
                          className="form-control"
                          id="clientuniqueid"
                          showSearch
                          value={state.clientuniqueid}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(e) => handleClick(e)}
                        >
                          {companydatachildren}
                        </Select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      {/* <p style={{ color: "red" }}>{state.serviceError}</p> */}
                      <div className="">
                        <label className="label_cls">Service Level</label>
                        <select
                          // className="autoComplete"
                          className="form-control"
                          id="servicelevel"
                          value={state.servicelevel}
                          style={{ width: "100%" }}
                          onChange={(e) => handleChange("servicelevel", e)}
                        >
                          {servicele.map((each, ind) => {
                            return (
                              <option key={ind} value={each}>
                                {" "}
                                {each}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>

                  {state.servicelevel == "Commander" ||
                  state.servicelevel == "Explorer" ? (
                    <div className="row">
                      <p style={{ color: "red" }}>{state.commanderError}</p>
                      <div className="col-md-6">
                        <div className="floating-label">
                          <label className="label_cls">
                            Company Virtual Hours
                          </label>

                          <select
                            // className="autoComplete"
                            className="form-control"
                            id="companyvirtualhours"
                            value={state.companyvirtualhours}
                            style={{ width: "100%" }}
                            onChange={(e) =>
                              handleChange("companyvirtualhours", e)
                            }
                          >
                            {children.map((each, ind) => {
                              return (
                                <option key={ind} value={each}>
                                  {each}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        {/* <Select
                          className="autoComplete"
                          // className="form-control"
                          id="companyvirtualhours"
                          value={state.companyvirtualhours}
                          style={{ width: "100%" }}
                        >
                          {children}
                        </Select> */}
                      </div>
                      <div className="col-md-6">
                        <div className="floating-label">
                          <label className="label_cls">
                            Individual Virtual hours
                          </label>
                          <select
                            // className="autoComplete"
                            className="form-control"
                            id="individualvirtualhours"
                            value={state.individualvirtualhours}
                            style={{ width: "100%" }}
                            onChange={(e) =>
                              handleChange("individualvirtualhours", e)
                            }
                          >
                            {children.map((each, ind) => {
                              return (
                                <option key={ind} value={each}>
                                  {each}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {state.servicelevel == "Commander" ? (
                    <div className="row">
                      <div className="col-md-6">
                        <div className="floating-label">
                          <label className="label_cls">
                            Company in Person Hours
                          </label>
                          <select
                            // className="autoComplete"
                            className="form-control"
                            id="companyinpersonhours"
                            value={state.companyinpersonhours}
                            style={{ width: "100%" }}
                            onChange={(e) =>
                              handleChange("companyinpersonhours", e)
                            }
                          >
                            {children.map((each, ind) => {
                              return (
                                <option key={ind} value={each}>
                                  {each}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="floating-label">
                          <label className="label_cls">
                            Individual in Person Hours
                          </label>
                          <select
                            // className="autoComplete"
                            className="form-control"
                            id="individualinpersonhours"
                            value={state.individualinpersonhours}
                            style={{ width: "100%" }}
                            onChange={(e) =>
                              handleChange("individualinpersonhours", e)
                            }
                          >
                            {children.map((each, ind) => {
                              return (
                                <option key={ind} value={each}>
                                  {each}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="floating-label">
                        <label className="label_cls">Access start date</label>
                        <div className="date-pic-mypro mb-2">
                          <DatePicker
                            id="startdate"
                            dateFormat="MM/dd/yyyy"
                            selected={state.startDate}
                            selectsStart
                            minDate={new Date()}
                            maxDate={state.endDate}
                            onChange={(e) => handleDateChange(e, "startDate")}
                            placeholderText="Select start date ..."
                            className="form-control"
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="floating-label">
                        <label className="label_cls">Access end date</label>
                        <div className="date-pic-mypro mb-2">
                          <DatePicker
                            id="enddate"
                            dateFormat="MM/dd/yyyy"
                            selected={state.endDate}
                            selectsEnd
                            autoComplete="off"
                            startDate={state.startDate}
                            endDate={state.endDate}
                            onChange={(e) => handleDateChange(e, "endDate")}
                            minDate={
                              state.startDate == null
                                ? new Date()
                                : state.startDate
                            }
                            placeholderText="Select end date ..."
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="floating-label">
                        <input
                          placeholder="PO"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          value={state.po}
                          id="po"
                          onChange={(e) => handleChange("po", e)}
                        />
                        <label>PO</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="floating-label">
                        <input
                          placeholder="ReferenceId"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          value={state.referenceid}
                          id="referenceid"
                          onChange={(e) => handleChange("referenceid", e)}
                        />
                        <label>Reference ID</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="floating-label">
                        <label className="label_cls">Approved Hours</label>
                        <Select
                          // className="autoComplete"
                          className="form-control"
                          id="approvedhours"
                          value={state.approvedhours}
                          style={{ width: "100%" }}
                          disabled
                        ></Select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="label_cls">Is Temporary</label>
                      <select
                        className="form-control"
                        value={state.isTemporary}
                        style={{ width: "100%" }}
                        onChange={(e) => handleChange("isTemporary", e)}
                      >
                        <option key={0} value={true}>
                          {"Temporary"}
                        </option>
                        <option key={1} value={false}>
                          {"Permanent"}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="floating-label">
                        <label className="label_cls">Note</label>
                        <textarea
                          className="form-control"
                          rows="6"
                          value={state.usernotes}
                          placeholder="Notes"
                          id="usernotes"
                          onChange={(e) => handleChange("usernotes", e)}
                        />
                      </div>
                    </div>
                  </div>
                  {state.editid == true ? (
                    ""
                  ) : (
                    <div className="row">
                      <div className="col-md-14">
                        <input
                          type="checkbox"
                          style={{ marginTop: "-7px" }}
                          className="btn btn-clr mrgrt-10"
                          value={state.sendemail}
                          checked={state.sendemail}
                          onChange={(e) => updatesendmail(e)}
                        />

                        <label className="label_cls">Send Welcome Email</label>
                      </div>
                    </div>
                  )}
                  <div className="text-center mt-3 mb-3">
                    {state.uploadLoader ? (
                      <Spin />
                    ) : (
                      <button
                        type="submit"
                        className="btn theme-btn"
                        onClick={(e) => addProperty(e)}
                      >
                        {state.editid != "" ? "UPDATE" : "SAVE"} <span></span>{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTransferee;
