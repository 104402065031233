import React from "react";

function ForConsultant() {
  return (
    <div>
      <div className="bannerPart innerPage">
        <div className="container d-flex justify-content-center align-items-center text-center h-100">
          <div className="forsubText d-md-grid" data-aos="fade-up">
            <h1 className="mb-3">
              Empower Your Destination Services with Relo Navigator
            </h1>
            <p className="col-xl-9 d-md-flex align-items-end">
              Relo Navigator equips consultants with the tools to manage every
              aspect of relocation services efficiently. From property
              recommendations to real-time data tracking and reporting, our
              platform helps you deliver a seamless, personalized experience to
              every transferee.
            </p>
          </div>
        </div>
      </div>

      <div className="row m-0">
        <div className="col-lg-7 mb-3 mb-lg-0 ps-lg-0" data-aos="fade-up">
          <img
            src="assets/publicImages/forcompany/for-companies-1.png"
            alt=""
            width="100%"
            className="h-100"
          />
        </div>
        <div className="col-lg-5 pe-lg-0" data-aos="fade-up">
          <img
            src="assets/publicImages/forcompany/for-companies-2.png"
            alt=""
            width="100%"
            className="h-100"
          />
        </div>
      </div>

      <div className="my-6rem">
        <div className="container py-4">
          <h2 className="text-center">
            Tailored Tools for Consultants
            <span className="h2-span">
              Manage Your Work with Confidence and Precision
            </span>
          </h2>
          <p className="text-center m-auto mb-4 col-lg-9">
            Relo Navigator is designed with consultants in mind, providing all
            the features you need to deliver high-quality, personalized service
            to your clients. Our platform offers real-time updates, streamlined
            communication, and powerful reporting tools to ensure every detail
            is handled with ease.
          </p>
          <div className="accrodianView for-companies m-auto col-lg-8">
            <div className="accordion" id="regularAccordionRobots">
              <div className="accordion-item">
                <h2 id="regularHeadingFirst" className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#regularCollapseFirst"
                    aria-expanded="true"
                    aria-controls="regularCollapseFirst"
                  >
                    <img
                      src="assets/publicImages/forcompany/building-people.png"
                      alt=""
                    />
                    Property Recommendations
                  </button>
                </h2>
                <div
                  id="regularCollapseFirst"
                  className="accordion-collapse collapse show"
                  aria-labelledby="regularHeadingFirst"
                  data-bs-parent="#regularAccordionRobots"
                >
                  <div className="accordion-body">
                    <p>
                      Offer transferees personalized home options based on their
                      preferences, with up-to-date listings and comparison tools
                      at your fingertips.
                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="regularHeadingSecond">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#regularCollapseSecond"
                    aria-expanded="false"
                    aria-controls="regularCollapseSecond"
                  >
                    <img
                      src="assets/publicImages/forcompany/calendar-edit.png"
                      alt=""
                    />
                    Appointment Scheduling
                  </button>
                </h2>
                <div
                  id="regularCollapseSecond"
                  className="accordion-collapse collapse"
                  aria-labelledby="regularHeadingSecond"
                  data-bs-parent="#regularAccordionRobots"
                >
                  <div className="accordion-body">
                    Easily schedule and manage meetings with transferees and
                    other service providers, ensuring everyone stays on the same
                    page.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="regularHeadingSecond2">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#regularCollapseSecond2"
                    aria-expanded="false"
                    aria-controls="regularCollapseSecond2"
                  >
                    <img
                      src="assets/publicImages/forcompany/chat-sparkle.png"
                      alt=""
                    />
                    Message Center
                  </button>
                </h2>
                <div
                  id="regularCollapseSecond2"
                  className="accordion-collapse collapse"
                  aria-labelledby="regularHeadingSecond2"
                  data-bs-parent="#regularAccordionRobots"
                >
                  <div className="accordion-body">
                    Communicate directly with transferees in real-time,
                    providing quick updates and answering questions to keep the
                    process moving smoothly.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-6rem">
        <div className="container pb-4">
          <h2 className="text-center">Personalized Property Recommendations</h2>
          <p className="m-auto col-lg-9 text-center">
            Find and recommend the perfect homes for transferees with just a few
            clicks. Relo Navigator allows consultants to access a wide range of
            real-time property data, ensuring that every recommendation is
            aligned with transferee preferences and needs.
          </p>

          <div className="for-companies-tails d-lg-grid mt-4">
            <div className="box" data-aos="fade-up">
              <img
                src="assets/publicImages/forcompany/for-companies-3.png"
                alt=""
                width="100%"
              />
              <p className="px-5 py-3 text-center">
                Deliver tailored recommendations with detailed insights, such as
                school proximity, commute times, and local points of interest.
              </p>
            </div>
            <div className="box" data-aos="fade-up">
              <img
                src="assets/publicImages/forcompany/for-companies-4.png"
                alt=""
                width="100%"
              />
              <p className="px-5 py-3 text-center">
                Compare homes, neighborhoods, and key amenities based on
                transferee priorities.
              </p>
            </div>
            <div className="box" data-aos="fade-up">
              <img
                src="assets/publicImages/forcompany/for-companies-3.png"
                alt=""
                width="100%"
              />
              <p className="px-5 py-3 text-center">
                Ensure a smooth home selection process with real-time updates on
                availability and pricing.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="my-6rem">
        <div className="container">
          <div className="for-companies-calendar">
            <div className="box textView">
              <h3>Streamline Your Appointment Scheduling</h3>
              <p>
                With Relo Navigator's scheduling tools, consultants can easily
                manage their calendar and book appointments with transferees and
                other service providers. Whether it's a home tour or a
                consultation, you can keep everything organized in one place.
              </p>

              <h3>Key Benefits:</h3>

              <ul className="mb-0">
                <li>
                  Automate appointment scheduling and reminders to reduce manual
                  tracking.
                </li>
                <li>
                  Easily reschedule or update appointments in real-time, keeping
                  transferees informed.
                </li>
                <li className="mb-0">
                  Sync appointments with transferees' schedules for a more
                  convenient experience.
                </li>
              </ul>
            </div>
            <div className="box imageView">
              <h3>Calendar</h3>

              <div className="position-relative">
                <img
                  src="assets/publicImages/forcompany/for-companies-5.png"
                  alt=""
                  width="100%"
                />
              </div>
            </div>
          </div>
          <div className="for-companies-calendar mt-4">
            <div className="box imageView">
              <div className="position-relative imgBg">
                <img
                  src="assets/publicImages/forcompany/for-companies-6.png"
                  alt=""
                  width="100%"
                />
              </div>
            </div>
            <div className="box textView">
              <h3>Access Real-Time Data at Your Fingertips</h3>
              <p>
                Stay on top of transferee progress with real-time updates and
                notifications. Track key milestones, property preferences,
                appointments, and all essential relocation tasks in one
                centralized dashboard.
              </p>

              <h3>Details :</h3>

              <ul className="mb-0">
                <li>
                  Get instant updates on transferee progress, ensuring no steps
                  are missed.
                </li>
                <li>
                  View property feedback, appointment outcomes, and next steps,
                  all in real time.
                </li>
                <li className="mb-0">
                  Monitor all key tasks, from property recommendations to lease
                  signing, with full transparency.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="my-6rem">
        <div className="container py-5">
          <h2 className="ms-auto me-auto col-lg-7 text-center">
            Seamless Communication for Better Collaboration
          </h2>
          <p className="ms-auto me-auto col-lg-9 text-center">
            Stay connected with transferees throughout the relocation process
            using Relo Navigator's built-in message center. Respond to
            questions, send updates, and keep everyone aligned for a smooth
            relocation experience.
          </p>

          <div className="for-companiesImageTextView mb-4">
            <img
              src="assets/publicImages/forcompany/for-companies-7.png"
              alt=""
              width="100%"
            />
            <h6>
              <span>
                Send and receive instant messages with transferees and other
                consultants.
              </span>
            </h6>
            <h6>
              <span>
                Keep all communication in one place, ensuring that nothing gets
                lost in the shuffle.
              </span>
            </h6>
            <h6>
              <span>
                Share updates, property listings, and important documents
                directly within the chat feature.
              </span>
            </h6>
          </div>
        </div>
      </div>

      <div className="mb-6rem">
        <div className="container pb-5">
          <div className="for-companiesWhyCHoose d-lg-grid">
            <div className="d-flex align-items-center justify-content-center flex-column col-lg-10 mb-5 md-lg-0">
              <h2>Why Consultants Choose Relo Navigator</h2>
              <p>
                Relo Navigator streamlines the relocation process, making it
                easy for consultants to deliver personalized, high-quality
                service. By leveraging real-time data, seamless communication,
                and powerful reporting tools, you can offer a truly tailored
                relocation experience that meets the unique needs of every
                transferee.
              </p>
            </div>
            <div className="box">
              <div className="item" data-aos="fade-up">
                <img
                  src="assets/publicImages/individuals/choose-icon-1.png"
                  alt=""
                  width="100%"
                />
                <h4>Efficiency</h4>
                <p>
                  Save time with automated scheduling, messaging, and reporting
                  tools.
                </p>
              </div>
              <div className="item" data-aos="fade-up">
                <img
                  src="assets/publicImages/individuals/choose-icon-1.png"
                  alt=""
                  width="100%"
                />
                <h4>Personalization</h4>
                <p>
                  Offer customized home recommendations and personalized
                  services based on transferee preferences.
                </p>
              </div>
              <div className="item" data-aos="fade-up">
                <img
                  src="assets/publicImages/individuals/choose-icon-1.png"
                  alt=""
                  width="100%"
                />
                <h4>Transparency</h4>
                <p>
                  Access real-time updates and insights, ensuring both
                  consultants and transferees stay informed throughout the
                  process.
                </p>
              </div>
              <div className="item" data-aos="fade-up">
                <img
                  src="assets/publicImages/individuals/choose-icon-1.png"
                  alt=""
                  width="100%"
                />
                <h4>Data-Driven Insights</h4>
                <p>
                  Generate custom reports that help you make better, faster
                  decisions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForConsultant;
