import React from "react";
import { Select, Radio } from "antd";
import ThirdParty from "../../../services/thirdPartyService";

function FeedBackPopUp(props) {
  const { state, closeFeedBack, setState } = props;
  const children = [];
  var dataSource = [
    "Feature request",
    "General feedback",
    "Report an issue",
    "Support/Account/Billing",
    "UI feedback",
  ];
  for (let i = 0; i < dataSource.length; i++) {
    children.push(
      <Select.Option key={dataSource[i]}>{dataSource[i]}</Select.Option>
    );
  }
  const Fclose = async () => {
    $("document").ready(function () {
      $("#contact-us-modal-feedback").modal("hide");
    });
    await setState((prev) => ({
      ...prev,
      feedBackPopUp: false,
      feedbacktype: "General feedback",
      succesMsg: " ",
      experience: "",
      feeddescription: "",
      FromEmail: "",
    }));
  };
  const inputHandler = async (event) => {
    // const { name, value } = event.target;
    // setState({
    //   ...state,
    //   [name]: value,
    // });
    let inputVal = event.target.value;
    let inputName = event.target.name;
    let newState = { ...state };
    newState[inputName] = inputVal;
    if (
      inputName == "FromEmail" &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputVal.trim())
    ) {
      await setState((prev) => ({
        ...prev,
        contactError: "Please enter a valid emailid",
      }));
      $("#" + inputName).addClass("is-invalid");
    } else {
      await setState((prev) => ({ ...prev, contactError: "" }));
      $("#" + inputName).removeClass("is-invalid");
    }
    if (inputVal.trim() == null || inputVal.trim() == "") {
      $("#" + inputName).addClass("is-invalid");
    } else {
      $("#" + inputName).removeClass("is-invalid");
    }
    setState(newState);
  };
  const onChangeTypes = (event) => {
    let selectedValue = event;
    setState((prev) => ({
      ...prev,
      feedbacktype: selectedValue,
    }));
  };
  const onChange = (e) => {
    setState({ ...state, experience: e.target.value });
  };
  const CancelContactUs = async () => {
    $("document").ready(function () {
      $("#contact-us-modal-feedback").modal("hide");
      $("#contact-us-modal-feedback").find("input,textarea").val("").end();
    });
    await setState((prev) => ({
      ...prev,
      feedBackPopUp: false,
      feedbacktype: "General feedback",
      succesMsg: " ",
      experience: "",
      feeddescription: "",
      FromEmail: "",
    }));
  };
  const saveContactUs = async (event) => {
    event.preventDefault();
    let errorUpdate = 0;
    if (state.feedbacktype.trim() == "") {
      $("#feedbacktype").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#feedbacktype").removeClass("is-invalid");
    }
    if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.FromEmail)
    ) {
      $("#FromEmail").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#FromEmail").removeClass("is-invalid");
    }
    if (state.feeddescription.trim() == "") {
      $("#feeddescription").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#feeddescription").removeClass("is-invalid");
    }
    if (state.experience.trim() == "" || state.experience.trim() == null) {
      $("#nill").addClass("is-invalid");
      $("#nills").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#nill").removeClass("is-invalid");
      $("#nills").removeClass("is-invalid");
    }
    console.log(errorUpdate, "coming here");
    if (errorUpdate == 0) {
      let datase = {
        subject: state.feedbacktype,
        description: state.feeddescription,
        FromEmail: state.FromEmail,
        typefeedback: true,
        experience: state.experience,
        email: "hello@relonavigator.com",
        cc: state.FromEmail,
      };
      let sendSms = await ThirdParty.sendEmail(datase);
      if (sendSms.response == true) {
        setState({
          ...state,
          succesMsg: "Thank you for your feedback",
          experience: "",
          feedbacktype: "General feedback",
          feeddescription: "",
          FromEmail: "",
        });
      } else {
        await setState((prev) => ({ ...prev, error: "Please try again!" }));
      }
    }
  };
  return (
    // <div id="contact-us-modal-feedback" className="modal fade new_modal_pop"
    // role="dialog"
    // data-backdrop="static">
    //   <div className="top-view">
    //     <h6>Feedback </h6>
    //   </div>
    //   <div className="">
    //     <div className="m-5">
    //       {state.succesMsg != "" && (
    //         <h6 className="alert alert-success">{state.succesMsg}</h6>
    //       )}
    //     </div>
    //     <div className="focus-input-label mt-3 ">
    //       {state.succesMsg == "" && (
    //         <form>
    //           <div className="padding-x-50">
    //             <h1 style={{ fontSize: "20px" }}> Your Feedback Matters!</h1>
    //             <div className="main-row">
    //               <div className="item">
    //                 <p>Choose the type of feedback you are submitting.</p>
    //               </div>
    //             </div>
    //             <div>
    //               <Select
    //                 className="form-control ant-select ant-select-enabled ant-select-selection"
    //                 id="feedbacktype"
    //                 value={state.feedbacktype}
    //                 onChange={(e) => onChangeTypes(e)}
    //               >
    //                 {children}
    //               </Select>
    //             </div>
    //             <div className="floating-label">
    //               <input
    //                 placeholder="Enter your email"
    //                 value={state.FromEmail}
    //                 type="text"
    //                 className="form-control"
    //                 name="FromEmail"
    //                 id="FromEmail"
    //                 onChange={(event) => inputHandler(event)}
    //               />
    //               <label>Email</label>
    //             </div>
    //             <div className="floating-label">
    //               <textarea
    //                 className="form-control"
    //                 rows="6"
    //                 placeholder="Describe Feedback"
    //                 value={state.feeddescription}
    //                 name="feeddescription"
    //                 id="feeddescription"
    //                 onChange={(event) => inputHandler(event)}
    //               />
    //               <label>Enter your message below</label>
    //             </div>
    //             <div>
    //               <label>Are you satisfied with your experience?</label>
    //               <div>
    //                 <Radio.Group
    //                   onChange={onChange}
    //                   id="experience"
    //                   value={state.experience}
    //                 >
    //                   <Radio id="nill" value={"Yes"}>
    //                     Yes
    //                   </Radio>

    //                   <Radio id="nills" value={"No"}>
    //                     No
    //                   </Radio>
    //                 </Radio.Group>
    //               </div>
    //             </div>
    //           </div>
    //           <div className="form-group footer">
    //             <button
    //               className="btn mr-3"
    //               type="button"
    //               onClick={CancelContactUs}
    //             >
    //               Cancel{" "}
    //             </button>
    //             <button
    //               className="btn theme-btn"
    //               type="button"
    //               onClick={(event) => saveContactUs(event)}
    //             >
    //               Submit
    //             </button>
    //           </div>
    //         </form>
    //       )}
    //     </div>
    //   </div>
    // </div>
    <div
      id="contact-us-modal-feedback"
      className="modal fade "
      role="dialog"
      style={{ zIndex: 1060 }}
      data-backdrop="static"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="width-80per pd-y-60">
            <div className="">
              <div className="only_close">
                <span className="close" data-dismiss="modal" onClick={Fclose}>
                  &times;
                </span>
              </div>
              <h6>Feedback </h6>
            </div>

            <div className="m-5">
              {state.succesMsg != "" && (
                <h6 className="alert alert-success">{state.succesMsg}</h6>
              )}
            </div>
            <div className="focus-input-label mt-3 ">
              {state.succesMsg == "" && (
                <form
                  method="post"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="padding-x-50">
                    <div className="main-row">
                      <div className="item">
                        {/* <label className="top-label">Type</label> */}
                        <p>Choose the type of feedback you are submitting.</p>
                      </div>
                    </div>
                    <div className="floating-label">
                      <Select
                        //className="autoComplete"
                        className="form-control"
                        id="feedbacktype"
                        value={state.feedbacktype}
                        onChange={(e) => onChangeTypes(e)}
                      >
                        {children}
                      </Select>
                    </div>
                    <div className="floating-label">
                      <input
                        placeholder="Enter your email"
                        type="text"
                        name="FromEmail"
                        className="form-control"
                        id="FromEmail"
                        onChange={(e) => inputHandler(e)}
                      />
                      <label>Email</label>
                    </div>
                    <div className="floating-label">
                      <textarea
                        className="form-control"
                        rows="6"
                        name="feeddescription"
                        placeholder="Describe Feedback"
                        id="feeddescription"
                        onChange={(e) => inputHandler(e)}
                      />
                      <label>Enter your message below</label>
                    </div>
                    <div className="floating-label">
                      <label>Are you satisfied with your experience?</label>
                      <Radio.Group onChange={onChange} value={state.experience}>
                        <Radio id="nill" value={"Yes"}>
                          Yes
                        </Radio>
                        <Radio id="nills" value={"No"}>
                          No
                        </Radio>
                      </Radio.Group>
                    </div>
                  </div>
                  <div className="form-group footer">
                    <button
                      className="btn theme-btn btn-dark me-3"
                      type="button"
                      onClick={(event) => CancelContactUs(event)}
                    >
                      Cancel{" "}
                    </button>
                    <button
                      className="btn theme-btn"
                      type="button"
                      onClick={(event) => saveContactUs(event)}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeedBackPopUp;
