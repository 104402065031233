// import "./soloprof.css";
import ApiServices from "../../../../services/apiServices";
import LoginStore from "../../../../services/thirdPartyService";
import { useState, useEffect } from "react";
import * as $ from "jquery";
import EditModal from "./editSolo";
const publicIp = require("react-public-ip");
import { Spin, DatePicker, Tooltip } from "antd";
import moment from "moment";
import CountriesJson from "./Countries.json";
import authDetails from "../../../common/authDetails";
import Footer from "../../../common/footer/Footer";
import Gdpr from "../gdpr/gdpr";

const DsSoloProfile = (props) => {
  var _isMounted = false;
  const initialState = {
    GetTransfereeProperties: [],
    responseTransfreeProperties: [],
    isShowSchedule: false,
    userName: "",
    changePasswordErrorStatus: false,
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    errorMessage: "",
    loader: false,
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    phoneNumber: "",
    EmailId: "",
    state: "",
    city: "",
    zipcode: "",
    worklocationaddress1: "",
    worklocationaddress2: "",
    dob: "",
    countrycode: "",
    ErrorEditStatus: false,
    loaderEdit: false,
    userUniqueId: "",
    consultantUId: "",
    errorMsg: "",
    EditStatusSuccess: false,
    PasswordStatusSuccess: false,
    countryIso: "",
    profileLoader: false,
    alternateEmail: "",
    alternateUserSuccess: false,
    alternateUserErrorStatus: false,
    issharedliving: "",
    consultantname: "",
    type: "",
    statesList: [],
    citiesLIst: [],
    stateuid: "",
    cityuid: "",
    citiesLoader: false,
    consultantfavcities: [],
    consultantcountrycode: "",
    consultantphone: "",
    consultantemail: "",
    supplieruid: "",
    consultantnotes: "",
    notes: "",
    accessToken: "",
    auth0Response: {},
    error: true,
    accesstodate: null,
    accessfromdate: null,
    timezone: "",
    individualinpersonhours: "",
    individualvirtualhours: "",
    companyinpersonhours: "",
    companyvirtualhours: "",
    po: "",
    referenceid: "",
    clientuniqueid: "",
    servicelevel: "",
    supplieremailId: "",
  };
  const [state, setState] = useState(initialState);
  const [displayData, setDisplaydata] = useState(initialState);
  const sessionData = JSON.parse(localStorage.getItem("loginSubscribeDetails"));
  // console.log(sessionData.subscriptioncode, "subscriptioncode");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  function handleCountryCodeChange(code) {
    setState((prev) => ({ ...prev, countrycode: code }));
  }
  const editProfile = async (event) => {
    setModalIsOpen(true);
    await setState((prevState) => ({
      ...prevState,
      EditStatusSuccess: false,
      errorMsg: false,
    }));
    getUserDetails();
  };
  const OnchangeEmail = async (inputName, event) => {
    setState((prevState) => ({
      ...prevState,
      alternateUserErrorStatus: false,
      alternateUserSuccess: false,
    }));

    event.persist();
    let inputVal = event.target.value;

    if (inputVal.trim() == null || inputVal.trim() == "") {
      $("#" + inputName).addClass("is-invalid");
    } else if (
      inputName == "alternateEmailId" &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputVal.trim())
    ) {
      setState((prevState) => ({
        ...prevState,
        alternateUserErrorStatus: true,
        errorMessage: "Please Enter Valid Email Id",
      }));

      $("#" + inputName).addClass("is-invalid");
    } else {
      $("#" + inputName).removeClass("is-invalid");
    }
    await setState((prevState) => ({ ...prevState, alternateEmail: inputVal }));
  };
  const alternateUser = async () => {
    let errorVal = 0;
    event.preventDefault();
    if (state.alternateEmail.trim() == "") {
      $("#alternateEmailId").addClass("is-invalid");
      await setState((prevState) => ({ ...prevState, loader: false }));
      errorVal++;
    } else {
      $("#alternateEmailId").removeClass("is-invalid");
    }

    if (errorVal == 0) {
      let alternateUserObject = {
        transfereeUId: state.userUniqueId,
        alternateemailid: state.alternateEmail,
      };
      if (!state.alternateUserErrorStatus) {
        let alternateUserResult = await ApiServices.UpdateTransferee(
          alternateUserObject
        );

        if (alternateUserResult.error == 0) {
          let userInfo = {
            userFirstName: state.firstName,
            userId: state.EmailId,
            userLastName: state.lastName,
            userMiddleName: state.middleName,
            EmailId: state.EmailId,
            worklocationaddress1: state.worklocationaddress1,
            worklocationaddress2: state.worklocationaddress2,
            zipcode: state.zipcode,
            dob: state.dob,
            PhoneNumber: state.phoneNumber,
            state: state.state,
            city: state.city,
            gender: state.gender,
            userUniqueId: state.userUniqueId,
            consultantUId: state.consultantUId,
            countrycode: state.countrycode,
            alternateemailId: state.alternateEmail,
            issharedliving: state.issharedliving,
            type: state.type,
            consultantname: state.consultantname,
            stateuid: state.stateuid,
            cityuid: state.cityuid,
            consultantfavcities: state.consultantfavcities,
            consultantcountrycode: state.consultantcountrycode,
            consultantphone: state.consultantphone,
            consultantemail: state.consultantemail,
            supplieruid: state.supplieruid,
            consultantnotes: state.consultantnotes,
            accesstodate: state.accesstodate,
            accessfromdate: state.accessfromdate,
            timezone: state.timezone,
            supplieremailId: state.supplieremailId,
            istemporary: state.istemporary,
          };
          await LoginStore.LoginDetailsStore(userInfo);
          let getSessionData = await LoginStore.getLoginInfo({});
          // props.UpdateHeader();
          setState((prevState) => ({
            ...prevState,
            loader: false,
            alternateUserSuccess: true,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            alternateUserErrorStatus: true,
            loader: false,
            errorMessage: alternateUserResult.message.status,
          }));
        }
      }
    }
  };

  const UpdateTransferee = async () => {
    // console.log(event, "====");
    // event.preventDefault();
    await setState((prevState) => ({
      ...prevState,
      loaderEdit: true,
      EditStatusSuccess: false,
    }));
    let errorUpdate = 0;
    if (state.firstName.trim() == "") {
      await setState((prevState) => ({ ...prevState, loaderEdit: false }));
      errorUpdate++;
    } else {
      $("#firstName").removeClass("is-invalid");
    }
    if (state.lastName.trim() == "") {
      await setState((prevState) => ({ ...prevState, loaderEdit: false }));
      $("#lastName").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#lastName").removeClass("is-invalid");
    }
    if (
      /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(
        state.phoneNumber
      ) !== true
    ) {
      $("#phoneNumber").addClass("is-invalid");
      await setState((prevState) => ({ ...prevState, loaderEdit: false }));
      errorUpdate++;
    } else {
      $("#phoneNumber").removeClass("is-invalid");
    }
    // if (state.countrycode.trim() == "") {
    //   $("#countrycode").addClass("is-invalid");
    //   errorUpdate++;
    //   await setState((prevState) => ({ ...prevState, loaderEdit: false }));
    // } else {
    //   $("#countrycode").removeClass("is-invalid");
    // }

    if (errorUpdate == 0) {
      let updateProfileObj = {
        transfereeUId: state.userUniqueId,
        firstname: state.firstName,
        lastname: state.lastName,
        middlename: state.middleName,
        dob: moment(state.dob).format("YYYY/MM/DD"),
        gender: state.gender,
        emailid: state.EmailId,
        countrycode: state.countrycode,
        phonenumber: state.phoneNumber,
        worklocationaddress1: state.worklocationaddress1,
        worklocationaddress2: state.worklocationaddress2,
        city: state.cityuid,
        state: state.stateuid,
        zipcode: state.zipcode,
        approvedhours: state.transfereehours,
        transfereescheduledhours: state.transfereescheduledhours,
        notes: state.notes,
        accessfromdate: state.accessfromdate,
        accesstodate: state.accesstodate,
        companyinpersonhours: state.companyinpersonhours,
        companyvirtualhours: state.companyvirtualhours,
        individualinpersonhours: state.individualinpersonhours,
        individualvirtualhours: state.individualvirtualhours,
        po: state.po,
        referenceid: state.referenceid,
        clientuniqueid: state.clientuniqueid,
        servicelevel: state.servicelevel,
        supplieruid: state.supplieruid,
      };
      console.log(updateProfileObj, "updateProfileObj");
      let updateProfileResult = await ApiServices.UpdateTransferee(
        updateProfileObj
      );

      if (updateProfileResult.error == 0) {
        let userInfo = {
          userFirstName: state.firstName,
          userId: state.EmailId,
          userLastName: state.lastName,
          userMiddleName: state.middleName,
          EmailId: state.EmailId,
          worklocationaddress1: state.worklocationaddress1,
          worklocationaddress2: state.worklocationaddress2,
          zipcode: state.zipcode,
          dob: state.dob,
          PhoneNumber: state.phoneNumber,
          state: state.state,
          city: state.city,
          gender: state.gender,
          userUniqueId: state.userUniqueId,
          consultantUId: state.consultantUId,
          countrycode: state.countrycode,
          alternateemailId: state.alternateEmail,
          issharedliving: state.issharedliving,
          type: state.type,
          consultantname: state.consultantname,
          stateuid: state.stateuid,
          cityuid: state.cityuid,
          consultantfavcities: state.consultantfavcities,
          consultantprofilepic: state.consultantprofilepic,
          transfereehours: state.transfereehours,
          transfereescheduledhours: state.transfereescheduledhours,
          consultantcountrycode: state.consultantcountrycode,
          consultantphone: state.consultantphone,
          consultantemail: state.consultantemail,
          supplieruid: state.supplieruid,
          consultantnotes: state.consultantnotes,
          notes: state.notes,
          accessfromdate: state.accessfromdate,
          accesstodate: state.accesstodate,
          timezone: state.timezone,
          supplieremailId: state.supplieremailId,
          istemporary: state.istemporary,
        };
        console.log(userInfo, "afterupdate the profile");
        await LoginStore.LoginDetailsStore(userInfo);
        let getSessionData = await LoginStore.getLoginInfo({});
        await setState((prevState) => ({
          ...prevState,
          ErrorEditStatus: false,
          loaderEdit: false,
          EditStatusSuccess: true,
        }));
        // props.UpdateHeader();
      } else if (updateProfileResult.message) {
        await setState((prevState) => ({
          ...prevState,
          ErrorEditStatus: true,
          errorMsg: updateProfileResult.message.status,
          loaderEdit: false,
        }));
      }
      setDisplaydata({ ...state });
    } else {
      await setState((prevState) => ({ ...prevState, loaderEdit: false }));
    }
  };

  const getTransfareeData = async () => {
    let userInfo = await LoginStore.getLoginInfo({});

    let getTransfereeObj = {
      consultantuniqueid: userInfo.consultantUId,
      transfereeuniqueid: userInfo.userUniqueId,
    };
    let TransfereesList_Result = await ApiServices.GetConsultantTransferees(
      getTransfereeObj
    );
    await setState((prevState) => ({
      ...prevState,
      clientuniqueid: TransfereesList_Result.message.userInfo.clientuniqueid,
      referenceid: TransfereesList_Result.message.userInfo.referenceid,
      po: TransfereesList_Result.message.userInfo.po,
      individualvirtualhours:
        TransfereesList_Result.message.userInfo.individualvirtualhours,
      individualinpersonhours:
        TransfereesList_Result.message.userInfo.individualinpersonhours,
      companyvirtualhours:
        TransfereesList_Result.message.userInfo.companyvirtualhours,
      companyinpersonhours:
        TransfereesList_Result.message.userInfo.companyinpersonhours,
      transfereescheduledhours:
        TransfereesList_Result.message.userInfo.transfereescheduledhours,
      transfereehours: TransfereesList_Result.message.userInfo.transfereehours,
      servicelevel: TransfereesList_Result.message.userInfo.subscriptioncode,
    }));
    console.log(TransfereesList_Result, "TransfereesList_Result");
  };

  const getStates = async () => {
    let getStatesResult = await ApiServices.GetStates({});
    if (getStatesResult.error == 0) {
      await setState((prevState) => ({
        ...prevState,
        statesList: getStatesResult.message.stateslist,
      }));
    }
  };

  const getUserDetails = async () => {
    if (_isMounted) {
      setDisplaydata((prevState) => ({ ...prevState, profileLoader: true }));
    }
    let getSessionData = await LoginStore.getLoginInfo({});
    console.log(getSessionData, "getSessionData");

    _isMounted = true;
    if (_isMounted) {
      getStates();
      setDisplaydata((prevState) => ({
        ...prevState,
        firstName: getSessionData.userFirstName,
        lastName: getSessionData.userLastName,
        middleName: getSessionData.userMiddleName,
        gender: getSessionData.gender,
        EmailId: getSessionData.EmailId,
        phoneNumber: getSessionData.PhoneNumber,
        state: getSessionData.state,
        city: getSessionData.city,
        zipcode: getSessionData.zipcode,
        worklocationaddress1: getSessionData.worklocationaddress1,
        worklocationaddress2: getSessionData.worklocationaddress2,
        userUniqueId: getSessionData.userUniqueId,
        dob: moment(getSessionData.dob).format("MM/DD/YYYY"),
        countrycode: getSessionData.countrycode,
        consultantUId: getSessionData.consultantUId,
        alternateEmail: getSessionData.alternateemailId,
        issharedliving: getSessionData.issharedliving,
        type: getSessionData.type,
        consultantname: getSessionData.consultantname,
        stateuid: getSessionData.stateuid,
        cityuid: getSessionData.cityuid,
        consultantfavcities: getSessionData.consultantfavcities,
        consultantprofilepic: getSessionData.consultantprofilepic,
        consultantcountrycode: getSessionData.consultantcountrycode,
        consultantphone: getSessionData.consultantphone,
        consultantemail: getSessionData.consultantemail,
        supplieruid: getSessionData.supplieruid,
        consultantnotes: getSessionData.consultantnotes,
        notes: getSessionData.notes,
        accesstodate: getSessionData.accesstodate,
        accessfromdate: getSessionData.accessfromdate,
        timezone: getSessionData.timezone,
        companyinpersonhours: state.companyinpersonhours,
        companyvirtualhours: state.companyvirtualhours,
        individualinpersonhours: state.individualinpersonhours,
        individualvirtualhours: state.individualvirtualhours,
        po: state.po,
        referenceid: state.referenceid,
        clientuniqueid: state.clientuniqueid,
        servicelevel: state.servicelevel,
        supplieremailId: getSessionData.supplieremailId,
        istemporary: getSessionData.istemporary,
      }));
      setDisplaydata((prevState) => ({ ...prevState, profileLoader: false }));
      setState((prevState) => ({
        ...prevState,
        firstName: getSessionData.userFirstName,
        lastName: getSessionData.userLastName,
        middleName: getSessionData.userMiddleName,
        gender: getSessionData.gender,
        EmailId: getSessionData.EmailId,
        phoneNumber: getSessionData.PhoneNumber,
        state: getSessionData.state,
        city: getSessionData.city,
        zipcode: getSessionData.zipcode,
        worklocationaddress1: getSessionData.worklocationaddress1,
        worklocationaddress2: getSessionData.worklocationaddress2,
        userUniqueId: getSessionData.userUniqueId,
        dob: moment(getSessionData.dob).format("MM/DD/YYYY"),
        countrycode: getSessionData.countrycode,
        consultantUId: getSessionData.consultantUId,
        alternateEmail: getSessionData.alternateemailId,
        issharedliving: getSessionData.issharedliving,
        type: getSessionData.type,
        consultantname: getSessionData.consultantname,
        stateuid: getSessionData.stateuid,
        cityuid: getSessionData.cityuid,
        consultantfavcities: getSessionData.consultantfavcities,
        consultantprofilepic: getSessionData.consultantprofilepic,

        consultantcountrycode: getSessionData.consultantcountrycode,
        consultantphone: getSessionData.consultantphone,
        consultantemail: getSessionData.consultantemail,
        supplieruid: getSessionData.supplieruid,
        consultantnotes: getSessionData.consultantnotes,
        notes: getSessionData.notes,
        accesstodate: getSessionData.accesstodate,
        accessfromdate: getSessionData.accessfromdate,
        timezone: getSessionData.timezone,

        companyinpersonhours: state.companyinpersonhours,
        companyvirtualhours: state.companyvirtualhours,
        individualinpersonhours: state.individualinpersonhours,
        individualvirtualhours: state.individualvirtualhours,
        po: state.po,
        referenceid: state.referenceid,
        clientuniqueid: state.clientuniqueid,
        servicelevel: state.servicelevel,
        supplieremailId: getSessionData.supplieremailId,
        istemporary: getSessionData.istemporary,
      }));
      setState((prevState) => ({ ...prevState, profileLoader: false }));
      if (state.stateuid != "") {
        getCities();
      }
      $(document).ready(function () {
        jQuery(function ($) {
          setTimeout(async function () {
            //$("#phoneNumber").intlTelInput();
            let countryDetails = CountriesJson.Countries.find(
              (x) => x.dialCode === getSessionData.countrycode
            );
            //$("#phoneNumber").intlTelInput("setCountry", countryDetails.iso2);
          }, 100);
        });
      });
    }
  };

  useEffect(() => {
    _isMounted = true;
    const fetch = async () => {
      await getUserDetails();
      await getTransfareeData();
    };
    fetch();
  }, []);
  const getCities = async () => {
    await setState((prevState) => ({ ...prevState, citiesLoader: true }));
    let CitiesReq = {
      stateuid: state.stateuid,
    };
    let citiesResult = await ApiServices.GetCities(CitiesReq);
    if (citiesResult.error == 0) {
      await setState((prevState) => ({
        ...prevState,
        citiesLIst: citiesResult.message.citieslist,
        citiesLoader: false,
      }));
    }
  };
  useEffect(() => {
    return async () => {
      _isMounted = false;
    };
  });
  const inputHandler = async (event) => {
    //event.preventDefault();
    let inputVal = event.target.value;
    let inputName = event.target.name;
    let inputObject = {};
    setState((prevState) => ({
      ...prevState,
      [inputName]: inputVal, // Update the corresponding state variable
    }));
    setState((prevState) => ({
      ...prevState,
      changePasswordErrorStatus: false,
    }));
    //event.persist();
    if (inputName != "" && inputVal != "") {
      setState((prevState) => ({
        ...prevState,
        [inputName]: inputVal,
      }));
    }
    if (inputVal.trim() == null || inputVal.trim() == "") {
      $("#" + inputName).addClass("is-invalid");
    } else {
      $("#" + inputName).removeClass("is-invalid");
    }
    if (inputName == "zipcode") {
      if (
        inputVal.trim() == null ||
        inputVal.trim() == "" ||
        inputVal.trim().length < 4
      ) {
        $("#zipcode").addClass("is-invalid");
      } else {
        $("#zipcode").removeClass("is-invalid");
      }
    }
    if (inputName == "oldPassword") {
      await setState((prevState) => ({
        ...prevState,
        oldPassword: event.target.value,
      }));
    }
    var regExp = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{9,})"
    );
    if (inputName == "newPassword" && !regExp.test(inputVal.trim())) {
      $(".ant-tooltip").removeClass("ant-tooltip-hidden");
      $("#newPassword").addClass("is-invalid");
      setState((prevState) => ({ ...prevState, error: true }));
      await setState((prevState) => ({
        ...prevState,
        newPassword: event.target.value,
      }));
      if (
        state.confirmPassword != "" &&
        state.newPassword != state.confirmPassword
      ) {
        // setState((prevState)=>({...prevState, changePasswordErrorStatus: true, errorMessage: 'Password and Confirm Password mismatch' });
      }
    }
    if (inputName == "newPassword" && regExp.test(inputVal.trim())) {
      $(".ant-tooltip").addClass("ant-tooltip-hidden");
      setState((prevState) => ({ ...prevState, error: false }));
    }
    if (inputName == "confirmPassword") {
      await setState((prevState) => ({
        ...prevState,
        confirmPassword: event.target.value,
      }));
      if (state.newPassword != state.confirmPassword) {
        //setState((prevState)=>({...prevState, changePasswordErrorStatus: true, errorMessage: 'Password and Confirm Password mismatch' });
      }
    }
  };
  const accessTokenFunc = async () => {
    await fetch(
      "https://cors-anywhere.herokuapp.com/https://" +
        authDetails.authCredentails().hostName +
        "/oauth/token",
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          grant_type: "client_credentials",
          client_id: authDetails.authCredentails().clientId,
          client_secret: authDetails.authCredentails().clientSecret,
          audience:
            "https://" + authDetails.authCredentails().hostName + "/api/v2/",
        }),
      }
    )
      .then((res) => res.json())
      .catch((error) => console.log("Error:", error))
      .then((response) =>
        setState((prevState) => ({ ...prevState, accessToken: response }))
      );
  };
  const changePassword = async () => {
    event.preventDefault();
    let errorVal = 0;
    await setState((prevState) => ({
      ...prevState,
      errorStatus: false,
      loader: true,
      PasswordStatusSuccess: false,
    }));
    if (state.oldPassword.trim() == "") {
      $("#oldPassword").addClass("is-invalid");
      await setState((prevState) => ({ ...prevState, loader: false }));
      errorVal++;
    } else {
      $("#oldPassword").removeClass("is-invalid");
    }
    if (state.confirmPassword.trim() == "") {
      $("#confirmPassword").addClass("is-invalid");
      await setState((prevState) => ({ ...prevState, loader: false }));
      errorVal++;
    } else {
      $("#confirmPassword").removeClass("is-invalid");
    }

    if (state.newPassword.trim() == "") {
      $("#newPassword").addClass("is-invalid");
      await setState((prevState) => ({ ...prevState, loader: false }));
      $(".ant-tooltip").removeClass("ant-tooltip-hidden");
      errorVal++;
    } else {
      $("#newPassword").removeClass("is-invalid");
    }

    var regExp = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{9,})"
    );
    if (
      state.newPassword.trim() != "" &&
      !regExp.test(state.newPassword.trim())
    ) {
      errorVal++;
      setState((prevState) => ({ ...prevState, loader: false, error: true }));
      $(".ant-tooltip").removeClass("ant-tooltip-hidden");
      $("#newPassword").addClass("is-invalid");
    } else if (
      state.confirmPassword.trim() != "" &&
      state.newPassword.trim() != ""
    ) {
      if (state.confirmPassword.trim() == state.newPassword.trim()) {
        setState((prevState) => ({
          ...prevState,
          changePasswordErrorStatus: false,
          errorMessage: "",
        }));
      } else {
        $(".ant-tooltip").addClass("ant-tooltip-hidden");

        await setState((prevState) => ({ ...prevState, loader: false }));
        errorVal++;
        setState((prevState) => ({
          ...prevState,
          error: false,
          changePasswordErrorStatus: true,
          errorMessage: "Password and Confirm Password mismatch",
        }));
      }
    }

    if (errorVal == 0) {
      let ipv4 = await publicIp.v4().then(
        (ip) => {
          return ip;
        },
        function (error) {
          return "Coudn't find your IP";
        }
      );
      let passwordObject = {
        newpassword: state.newPassword,
        oldpassword: state.oldPassword,
        ipaddress: ipv4,
        username: state.EmailId,
      };

      await accessTokenFunc();

      await fetch(
        "https://cors-anywhere.herokuapp.com/https://" +
          authDetails.authCredentails().hostName +
          "/oauth/token",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + state.accessToken.access_token,
            "content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            grant_type: "password",
            username: _.toLower(state.EmailId),
            password: state.oldPassword,
            audience: "",
            scope: "",
            client_id: authDetails.authCredentails().clientId,
            client_secret: authDetails.authCredentails().clientSecret,
          }),
        }
      )
        .then((res) => res.json())
        .catch((error) => console.log("Error:", error))
        .then((response) =>
          setState((prevState) => ({ ...prevState, auth0Response: response }))
        );

      if (
        state.auth0Response.error != "" &&
        state.auth0Response.error != undefined
      ) {
        setState((prevState) => ({
          ...prevState,
          changePasswordErrorStatus: true,
          loader: false,
          errorMessage: "Current Password is wrong.",
        }));
      } else {
        await fetch(
          "https://cors-anywhere.herokuapp.com/https://" +
            authDetails.authCredentails().hostName +
            "/api/v2/users-by-email?email=" +
            state.EmailId,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + state.accessToken.access_token,
            },
          }
        )
          .then((res) => res.json())
          .catch((error) => console.log("Error:", error))
          .then((response) =>
            setState((prevState) => ({
              ...prevState,
              auth0UserExist: response,
            }))
          );
        await accessTokenFunc();
        if (state.auth0UserExist.length > 0) {
          await fetch(
            "https://cors-anywhere.herokuapp.com/https://" +
              authDetails.authCredentails().hostName +
              "/api/v2/users/" +
              state.auth0UserExist[0].user_id,
            {
              method: "PATCH",
              headers: {
                "content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: "Bearer " + state.accessToken.access_token,
              },
              body: JSON.stringify({
                password: state.newPassword,
                connection: "Username-Password-Authentication",
              }),
            }
          )
            .then((res) => res.json())
            .catch((error) => console.log("Error:", error))
            .then((response) =>
              setState((prevState) => ({
                ...prevState,
                auth0Response: response,
              }))
            );
        }
        setState((prevState) => ({
          ...prevState,
          loader: false,
          error: true,
          PasswordStatusSuccess: true,
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        }));
        $("document").ready(function () {
          $("#change-password").modal("show");
          $("#change-password").find("input").val("").end();
        });
      }
    }
  };
  const handleGdpr = () => {
    $(function () {
      $("document").ready(async function () {
        $("#myModalGdpr").modal("show");
      });
    });
  };

  return (
    <div id="outer-container">
      <div className="ds-solo-profile">
        <div className="container">
          <div className="tabs-view">
            <div className="tab-content pb-5">
              <div
                id="ds-profile"
                className="bb-white b-radius-8 profile-padding tab-pane active"
                role="tabpanel"
              >
                <div
                  id="change-password"
                  className="modal fade"
                  role="dialog"
                  data-backdrop="static"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="width-60per pd-y-70">
                        <div className="only_close">
                          <span className="pointer close" data-dismiss="modal">
                            &times;
                          </span>
                        </div>
                        <h6>Change Password</h6>
                        <div className="padding-x-50 focus-input-label">
                          <div>
                            {state.PasswordStatusSuccess == true ? (
                              <h6 className="alert alert-success">
                                Password has been changed successfully
                              </h6>
                            ) : (
                              ""
                            )}

                            {state.changePasswordErrorStatus == true ? (
                              <h2 className="alert alert-danger">
                                {state.errorMessage}
                              </h2>
                            ) : (
                              ""
                            )}

                            {state.PasswordStatusSuccess != true && (
                              <form
                                method="post"
                                onSubmit={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                <div className="floating-label">
                                  <input
                                    className="form-control"
                                    placeholder="Current Password"
                                    type="password"
                                    id="oldPassword"
                                    onChange={inputHandler.bind(
                                      this,
                                      "oldPassword"
                                    )}
                                  />
                                  <label>
                                    <b>Current Password</b>
                                  </label>
                                </div>
                                <div className="floating-label">
                                  <Tooltip
                                    placement="right"
                                    className="ant-tooltip-hidden"
                                    title={
                                      state.error == true
                                        ? "Your password must contain a mininum of 9 characters included with at least 1 upper case letter, 1 lower case letter, 1 number and 1 special charater."
                                        : "Strong password"
                                    }
                                  >
                                    <input
                                      className="form-control"
                                      placeholder="New Password"
                                      type="password"
                                      id="newPassword"
                                      onChange={inputHandler.bind(
                                        this,
                                        "newPassword"
                                      )}
                                    />
                                  </Tooltip>
                                  <label>New Password</label>
                                </div>
                                <div className="floating-label">
                                  <input
                                    className="form-control"
                                    placeholder="Confirm Password"
                                    type="password"
                                    id="confirmPassword"
                                    onChange={inputHandler.bind(
                                      this,
                                      "confirmPassword"
                                    )}
                                  />
                                  <label>Confirm Password</label>
                                </div>
                                <div className="text-center mt-3 mb-3">
                                  {state.loader == true ? (
                                    <Spin />
                                  ) : (
                                    <div>
                                      {" "}
                                      <button
                                        type="submit"
                                        className="btn theme-btn rounded-btn  mt-3"
                                        onClick={(event) =>
                                          changePassword(event)
                                        }
                                      >
                                        Submit <span></span>{" "}
                                      </button>{" "}
                                    </div>
                                  )}
                                </div>
                              </form>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="alternate-user-modal"
                  className="modal fade"
                  role="dialog"
                  data-backdrop="static"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content padding-x-50">
                      <div className="only_close">
                        <span className="pointer close" data-dismiss="modal">
                          &times;
                        </span>
                      </div>

                      <form
                        method="post"
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <h6>alternate User </h6>
                        <h3 className="mt-3 mb-5">
                          Please enter the email address to the individual you
                          wish to share your account with and we'll send them an
                          invitation
                        </h3>
                        <div className="pop-form-controls mb-5">
                          {state.alternateUserErrorStatus == true ? (
                            <h6 className="in-validerror">
                              {state.errorMessage}
                            </h6>
                          ) : (
                            ""
                          )}
                          {state.alternateUserSuccess == true ? (
                            <h6 className="alert alert-success">
                              Saved Successfully
                            </h6>
                          ) : (
                            ""
                          )}

                          <input
                            type="email"
                            className="form-control"
                            defaultValue={state.alternateEmail}
                            id="alternateEmailId"
                            onKeyUp={OnchangeEmail.bind(
                              this,
                              "alternateEmailId"
                            )}
                          />
                        </div>

                        <div className="text-center mt-3 mb-5">
                          <button
                            className="btn theme-btn"
                            type="button"
                            onClick={(event) => alternateUser(event)}
                          >
                            Submit <span></span>{" "}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {state.profileLoader == true ? (
                  <div className="loading">Loading</div>
                ) : null}
                <div
                  className={
                    sessionData.subscriptioncode != "ReloNavigator"
                      ? "profile-main-div"
                      : "profile-main-div notable"
                  }
                >
                  <div className="pic">
                    <img
                      src="../assets/images/profile-pic.svg"
                      width="100%"
                      alt=""
                    />
                    <span>
                      <h1>
                        {displayData.firstName} {displayData.middleName}{" "}
                        {displayData.lastName}
                      </h1>
                      <h6>{displayData.EmailId}</h6>
                      <h6>  +{displayData.countrycode}  {displayData.phoneNumber}</h6>
                      <button
                        className="btn theme-btn rounded-btn  mt-3 btn-dark"
                        onClick={editProfile}
                      >
                        Update
                      </button>
                    </span>
                  </div>

                  <div className="content container">
                    {/* <img className="edit-icon" src="../assets/images/edit-image.png" alt="" onClick={editProfile} /> */}
                    <div className="">
                      <div className="d-flex row accessData">
                        <div className="col-md-6 col-6">
                          <span>Access Start</span>
                          <h3>
                            {moment(displayData.accessfromdate).format(
                              "MM/DD/YYYY"
                            )}
                          </h3>
                        </div>
                        <div className="col-md-6 col-6">
                          <span>Access End</span>
                          <h3>
                            {moment(displayData.accesstodate).format(
                              "MM/DD/YYYY"
                            )}
                          </h3>
                        </div>
                      </div>
                      <div className="d-flex row mt-2">
                        <div className="col-md-6">
                          {sessionData.subscriptioncode != "ReloNavigator" && (
                            <>
                              <span>Allotted Hours </span>
                              <h3>{state.transfereehours} Hrs</h3>
                            </>
                          )}
                        </div>
                        <div className="col-md-6">
                          {sessionData.subscriptioncode != "ReloNavigator" && (
                            <>
                              <span>Used Hours </span>
                              <h3>{state.transfereescheduledhours} Hrs</h3>
                            </>
                          )}
                        </div>
                      </div>

                      {sessionData.subscriptioncode != "ReloNavigator" &&
                        (state.companyinpersonhours > 0 ||
                          state.companyvirtualhours > 0 ||
                          state.individualinpersonhours > 0 ||
                          state.individualvirtualhours > 0) && (
                          <div className="mt-4">
                            <div className="row">
                              <div className="col-md-12">
                                <h2>Company</h2>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <span>In-person</span>
                                <h3>
                                  {" "}
                                  {state.companyinpersonhours > 0
                                    ? state.companyinpersonhours
                                    : 0}{" "}
                                  HRS
                                </h3>
                              </div>
                              <div className="col-md-6">
                                <span>Virtual</span>
                                <h3>
                                  {" "}
                                  {state.companyvirtualhours > 0
                                    ? state.companyvirtualhours
                                    : 0}{" "}
                                  HRS
                                </h3>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-12">
                                <h2>Individual</h2>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <span>In-person</span>
                                <h3>
                                  {" "}
                                  {state.individualinpersonhours > 0
                                    ? state.individualinpersonhours
                                    : 0}{" "}
                                  HRS
                                </h3>
                              </div>
                              <div className="col-md-6">
                                <span>Virtual</span>
                                <h3>
                                  {" "}
                                  {state.individualvirtualhours > 0
                                    ? state.individualvirtualhours
                                    : 0}{" "}
                                  HRS
                                </h3>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>

                  <p className="note">
                    Please click <a onClick={handleGdpr}>here</a> to submit a
                    data privacy related request or to place request to delete
                    your information.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <EditModal
                isOpen={modalIsOpen}
                closeModal={closeModal}
                state={state}
                handleCountryCodeChange={handleCountryCodeChange}
                inputHandler={inputHandler}
                UpdateTransferee={UpdateTransferee}
              />
            </div>

            <div
              className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
              id="myModalGdpr"
              tabIndex="-1"
              role="dialog"
              aria-hidden="true"
            >
              <Gdpr />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default DsSoloProfile;
