import React from "react";

function TenantRightsPopUP() {
  const closeModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#TenantRights").modal("hide");
      });
    });
  };
  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header border-0">
          <div class="only_close">
            <span
              aria-hidden="true"
              className="pointer close"
              onClick={() => closeModal()}
              data-dismiss="modal"
            >
              ×
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div className="widthModal">
            <div className="item-view">
              <h2>Tenant Rights and Responsibilities </h2>
              <p>
                As a renter, it’s important to understand both your rights and
                responsibilities to ensure a positive rental experience. Whether
                you’re renting an apartment, house, or other living space,
                knowing what to expect from your landlord—and what’s expected
                from you—can help prevent disputes and protect your interests.
              </p>
            </div>

            <div className="tails-row">
              <div className="tails">
                <div className="item-view">
                  <h2>Tenant Rights </h2>
                  <h3>1. Right to a Habitable Living Space</h3>
                  <p className="ps-4">
                    Every tenant has the right to live in a property that is
                    safe, clean, and livable. Landlords are required to maintain
                    certain standards of habitability, including:{" "}
                  </p>

                  <ul>
                    <li>Proper plumbing, heating, and electricity.</li>

                    <li>Safe, secure windows and doors.</li>

                    <li>
                      A structure free of serious leaks, mold, or pest
                      infestations.
                    </li>

                    <li>Compliance with health and safety codes.</li>
                  </ul>
                  <h3>2. Right to Privacy</h3>
                  <p className="ps-4">
                    As a tenant, you are entitled to privacy in your rental
                    property. Landlords cannot enter your home without proper
                    notice unless there is an emergency, such as a fire or
                    significant water leak. In most cases, landlords must
                    provide 24-48 hours’ notice before entering the unit for
                    repairs or inspections.{" "}
                  </p>
                  <h3>3. Protection Against Discrimination</h3>
                  <p className="ps-4">
                    Tenants are protected by federal, state, and local
                    anti-discrimination laws. The Fair Housing Act prohibits
                    landlords from refusing to rent to someone based on race,
                    color, national origin, religion, sex, familial status, or
                    disability. Additionally, many local laws offer protections
                    for sexual orientation, gender identity, and more.
                  </p>
                  <h3>4. Security Deposit Rights</h3>
                  <p className="ps-4">
                    Tenants are entitled to receive their security deposit back
                    at the end of the lease, provided they leave the rental in
                    good condition. Landlords can only deduct from the deposit
                    for legitimate damages beyond normal wear and tear. Security
                    deposit laws vary by state, but typically landlords must
                    return the deposit within a set timeframe after the tenant
                    moves out (usually between 14 to 30 days).
                  </p>
                  <h3>5. Right to Fair Rent Increases</h3>
                  <p className="ps-4">
                    If your lease includes provisions for rent increases,
                    landlords must follow legal guidelines regarding the amount
                    of notice they provide and any rent control or rent
                    stabilization regulations in your area. You have the right
                    to contest rent increases if they seem excessive or unfair,
                    depending on local laws.
                  </p>
                  <h3>6. Right to Repairs and Maintenance</h3>
                  <p className="ps-4">
                    Tenants have the right to request necessary repairs and
                    maintenance for their rental unit. Landlords are obligated
                    to make repairs in a timely manner, particularly for issues
                    that affect safety or habitability, such as broken heaters,
                    plumbing issues, or faulty wiring.
                  </p>
                  <h3>7. Right to Withhold Rent in Some Cases</h3>
                  <p className="ps-4">
                    In some jurisdictions, if your landlord fails to make
                    critical repairs after repeated requests, tenants may have
                    the right to withhold rent or make the repairs themselves
                    and deduct the cost from the rent. However, this should be
                    done only after confirming the legal requirements in your
                    area to avoid potential eviction.
                  </p>
                </div>
              </div>

              <div className="tails">
                <div className="item-view">
                  <h2>Tenant Responsibilities </h2>
                  <h3>1. Paying Rent on Time</h3>
                  <p className="ps-4">
                    As a tenant, you are legally obligated to pay your rent on
                    time, according to the terms of your lease agreement. Late
                    or missed payments can result in late fees or eviction,
                    depending on your lease terms. Be sure to communicate with
                    your landlord if you’re experiencing financial difficulties,
                    as some landlords may offer a grace period or payment plan.
                  </p>
                  <h3>2. Keeping the Property Clean and Safe</h3>
                  <p className="ps-4">
                    Tenants are responsible for maintaining the cleanliness of
                    the rental unit and avoiding damage beyond normal wear and
                    tear. This includes routine cleaning, taking out trash, and
                    properly using appliances and fixtures. Tenants should also
                    report any issues that may affect safety, such as water
                    leaks or electrical hazards, to the landlord promptly.
                  </p>
                  <h3>3. Respecting the Lease Agreement</h3>
                  <p className="ps-4">
                    The lease agreement is a legal document that outlines the
                    rights and responsibilities of both the landlord and tenant.
                    As a tenant, you are required to follow the terms of the
                    lease, including:
                  </p>
                  <ul>
                    <li>
                      Adhering to rules regarding pets, smoking, and noise.{" "}
                    </li>
                    <li>
                      Limiting the number of occupants (unless otherwise
                      approved).
                    </li>
                    <li>
                      Not subletting the unit without permission from the
                      landlord (if your lease restricts subletting).
                    </li>
                    <li>
                      Providing proper notice if you plan to move out before the
                      lease ends.
                    </li>
                  </ul>
                  <h3>4. Notifying Landlord of Repairs</h3>
                  <p className="ps-4">
                    While landlords are responsible for major repairs, tenants
                    are expected to report issues in a timely manner. If you
                    delay reporting a problem (such as a leak or a broken
                    window), the damage may worsen, and you could be held
                    responsible for the repair costs.
                  </p>
                  <h3>5. Avoiding Property Damage</h3>
                  <p className="ps-4">
                    Tenants must take care of the rental property and avoid
                    causing damage. This includes preventing excessive wear on
                    carpets, walls, and fixtures. At the end of your tenancy,
                    you should return the property in the condition you received
                    it, minus normal wear and tear. Damage beyond normal wear
                    may result in deductions from your security deposit.
                  </p>
                  <h3>6. Respecting Neighbors and Common Areas</h3>
                  <p className="ps-4">
                    Tenants are expected to respect their neighbors’ right to
                    quiet enjoyment. This means avoiding excessive noise, not
                    interfering with neighbors' peace, and respecting shared
                    spaces like hallways, laundry rooms, and parking lots.
                  </p>
                  <h3>7. Giving Proper Notice Before Moving Out</h3>
                  <p className="ps-4">
                    When you decide to move out, you are required to give proper
                    notice, typically 30 to 60 days before your intended
                    move-out date. The exact notice period will depend on the
                    terms of your lease and local laws. Failure to provide
                    notice can result in penalties, including loss of part or
                    all of your security deposit.
                  </p>
                </div>
              </div>

              <div className="tails">
                <div className="item-view">
                  <h2>Final Tips for Tenants </h2>
                  <ul>
                    <li>
                      <b>Know Your Local Tenant Laws: </b>
                      Tenant rights and responsibilities can vary depending on
                      your location. Be sure to research state and local
                      regulations to ensure you’re fully informed of your
                      rights.
                    </li>

                    <li>
                      <b>Communicate with Your Landlord: </b>
                      Establish open communication with your landlord to address
                      any issues early on and avoid disputes.
                    </li>
                    <li>
                      <b>Document Everything: </b>Always keep written records of
                      rent payments, repair requests, and any agreements made
                      with your landlord. Documentation can help protect you if
                      any legal issues arise.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="item-view mt-4">
              <p>
                By understanding and adhering to both your rights and
                responsibilities as a tenant, you’ll foster a positive
                relationship with your landlord and create a more comfortable
                living environment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TenantRightsPopUP;
