import React from "react";
import { Select } from "antd";

function SelectComponent(props) {
  const { state, questionList, onChange, questionIndex } = props;

  const children = [];
  const values = state.jData;
  var dataSource = values;
  for (let i = 0; i < dataSource.length; i++) {
    children.push(
      <Select.Option key={dataSource[i].name}>
        {dataSource[i].name}
      </Select.Option>
    );
  }
  return (
    <div key={"sub" + questionIndex} className="my-4">
      {questionList.description != null ? (
        <h6>{questionList.description}</h6>
      ) : null}
      <Select
        className="autoComplete"
        defaultValue={
          state.answersPrefer[questionList.preferenceUId] != undefined
            ? state.answersPrefer[questionList.preferenceUId].preferencesanswer
                .trim()
                .split(",")
                .sort()
                .filter(function (el) {
                  return el != "";
                })
            : ""
        }
        mode="multiple"
        style={{ width: "100%" }}
        onChange={(e) => onChange(questionList.preferenceUId, "input", e)}
        tokenSeparators={[","]}
      >
        {children}
      </Select>
      {state.activitiesError &&
        state.answersPrefer[state.activitiesArray[0].preferenceUId]
          .preferencesanswer == "" &&
        state.answersPrefer[state.keyWordsArray[0].preferenceUId]
          .preferencesanswer == "" && (
          <div className="tooltip-validation">
            <div className="tooltip-inner">{state.toolTipText}</div>
          </div>
        )}
    </div>
  );
}

export default SelectComponent;
