// import "./dsProfile.css";
import {
  faUser,
  faTasks,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoginStore from "../../../../services/thirdPartyService";

const dsGetSettled = () => {
  const [state, setState] = useState({
    userName: "",
  });
  useEffect(() => {
    const fetch = async () => {
      let getSessionData = await LoginStore.getLoginInfo({});
      setState((prevState) => ({
        ...prevState,
        userName:
          getSessionData.userFirstName + " " + getSessionData.userLastName,
      }));
    };
    fetch();
  }, []);
  return (
    <div>
      <div className="tab-pane active" id="getsettled" role="tabpanel">
        <div className="tab-contentGet">
          <div className="profilepic">
            <i className="fa fa-user">
              <FontAwesomeIcon icon={faUser} />
            </i>
            <h1>{state.userName}</h1>
            <p>NIKE EMPLOYEE</p>
          </div>
          <h2>
            Hi there! After you’ve decided on a city and place to live, there
            are a few more steps in your relocation journey. Don’t forget to
            look into the following.
          </h2>
        </div>

        <h3>APPLY FOR A SOCIAL SECURITY NUMBER</h3>
        <h4>
          If you do not already have a Social Security card, you will need to
          apply for one for U.S. tax and payroll purposes. You will need to
          visit the nearest Social Security Administration office. You must
          apply in person and will need the following documents with you. They
          must be printed.
        </h4>

        <div className="item">
          <ul>
            <li>
              {" "}
              <FontAwesomeIcon icon={faTasks} />{" "}
              <a href="javascript:void(0)">
                Arrival/ Departure record (I-94) received upon your entry{" "}
              </a>
            </li>
            <li>
              {" "}
              <FontAwesomeIcon icon={faTasks} />{" "}
              <a href="javascript:void(0)"> Employment Letter </a>
            </li>
            <li>
              {" "}
              <FontAwesomeIcon icon={faTasks} />{" "}
              <a href="javascript:void(0)"> Passport </a>
            </li>
            <li>
              {" "}
              <FontAwesomeIcon icon={faTasks} />{" "}
              <a href="javascript:void(0)"> Visa </a>
            </li>
            <li>
              {" "}
              <FontAwesomeIcon icon={faTasks} />{" "}
              <a href="javascript:void(0)"> Marriage License (if applicable)</a>
            </li>
            <li>
              {" "}
              <FontAwesomeIcon icon={faTasks} />{" "}
              <a href="javascript:void(0)">
                {" "}
                The Portland US Social Security Adminsitration office is located
                at 1538 SW Yamhill St. The phone number is 800-772-1213.
              </a>
            </li>
          </ul>
          <p>
            {" "}
            More information is available{" "}
            <a href="javascript:void(0)"> HERE </a>.
          </p>
        </div>
        <hr></hr>

        <h3>CHANGE YOUR MAILING ADDRESS</h3>
        <h4>
          You can change your address online through the US Postal Service at{" "}
          <a href="javascript:void(0)"> USPS </a> .
        </h4>

        <hr></hr>
        <h3>UPDATE YOUR DRIVER’S LICENSE</h3>
        <h4>
          Here are several Portland DMV locations where you can get an Oregon
          Driver’s License. For more DMV locations are available HERE. Call
          503-299-9999 for more information.
        </h4>

        <div className="item item2">
          <ul>
            <li>
              <FontAwesomeIcon icon={faTasks} />{" "}
              <a href="javascript:void(0)">
                {" "}
                <b> Beaverton DMV -</b> 10280 SW Park Way Portland, OR 97225{" "}
              </a>
            </li>
            <li>
              <FontAwesomeIcon icon={faTasks} />{" "}
              <a href="javascript:void(0)">
                <b> Downtown Portland DMV -</b> Northwest (NW12) 1502 SW 6th
                Ave. Portland, OR 97201{" "}
              </a>
            </li>
            <li>
              <FontAwesomeIcon icon={faTasks} />{" "}
              <a href="javascript:void(0)">
                {" "}
                <b> North Portland DMV -</b> North (N5) 1186 N Hayden Meadows
                Dr. Portland, OR 97217{" "}
              </a>
            </li>
            <li>
              <FontAwesomeIcon icon={faTasks} />{" "}
              <a href="javascript:void(0)">
                <b>Northeast Portland DMV -</b> Northeast (NE17) 1836 NE 82nd
                Ave. Portland, OR 97200{" "}
              </a>
            </li>
            <li>
              <FontAwesomeIcon icon={faTasks} />{" "}
              <a href="javascript:void(0)">
                <b> Southeast Portland DMV -</b> East (E11) 8710 SE Powell Blvd.
                Portland, OR 97266
              </a>
            </li>
          </ul>
        </div>
        <hr></hr>
        <div className="item">
          <p>
            <a href="javascript:void(0)">BankofAmerica</a>
          </p>
          <p>
            <a href="javascript:void(0)">ChaseBank</a>
          </p>
          <p>
            <a href="javascript:void(0)">CitiBank</a>
          </p>
          <p>
            <a href="javascript:void(0)">WellsFargo</a>
          </p>
        </div>
      </div>
    </div>
  );
};
export default dsGetSettled;
