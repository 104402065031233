import React from "react";
// import Modal from "../../../../common/Modal/Modal";
import { Spin } from "antd";
const QuestionConsultant = (props) => {
  const { answersPrefer, answersPreferLoading } = props.state;
  const { updateState } = props;

  return (
    <div
      className="modal fade "
      id="transferee-preferences-modal"
      tabIndex="-1"
      aria-labelledby="transferee-preferences-modal"
      aria-hidden="true"
      style={{ zIndex: 1060 }}
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="width-80per pd-y-60">
            <div class="only_close">
              <span
                class="pointer close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => updateState({ QuestionPop: false })}
              >
                ×
              </span>
            </div>

            <h6>Preferences</h6>

            <div className="tab-pane fade mt-5 tabPreferences active show">
              <div className="lis-view">
                {answersPreferLoading ? <Spin /> : null}
                {answersPrefer.length > 0 && !answersPreferLoading
                  ? answersPrefer.map((row, index) => {
                      return (
                        <div key={"question" + index}>
                          <label>{row.question}</label>
                          {row.type == "money-range" ? (
                            <span>
                              $
                              {row.preferencesanswer
                                .replace(/(^,)|(,$)/g, "")
                                .replace(",", " To $")}
                            </span>
                          ) : row.type == "Time-range" ? (
                            <span>
                              From{" "}
                              {row.preferencesanswer
                                .replace(/(^,)|(,$)/g, "")
                                .replace(",", " Mins To ")}{" "}
                              Mins
                            </span>
                          ) : (
                            <span>
                              {row.type == "CheckBox"
                                ? row.preferencesanswer == "true"
                                  ? "Yes"
                                  : "No"
                                : row.preferencesanswer.replace(
                                    /(^,)|(,$)/g,
                                    ""
                                  )}
                            </span>
                          )}
                        </div>
                      );
                    })
                  : null}
                {answersPrefer.length === 0 && !answersPreferLoading ? (
                  <div className="modal-body">
                    <label>Yet to fill the preferences</label>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionConsultant;
