import React, { useState, useEffect } from "react";
// import "antd/dist/antd.css";
// import "../../DestinationServicesStatic/myProperties/myProperties.css";
import Header from "../header and footer/Header";
// import Footer from "../header and footer/Footer";
import Footer from "../../../common/footer/footerWithoutchat";
import { Table, AutoComplete, Spin, Select, Radio } from "antd";
import LoginStore from "../../../../services/thirdPartyService";
import ApiStore from "../../../../services/apiServices";
import moment from "moment";
// import * as $ from "jquery";
import { confirmAlert } from "react-confirm-alert";
import Axios from "axios";
import S3Upload from "react-s3-uploader/s3upload";
import AddProperty from "./Modals/AddProperty";
import EditProperty from "./Modals/EditProperty";

const Properties = () => {
  const intialState = {
    Loader: false,
    filterTable: null,
    dataSource: [],
    supplieruid: "",
    consultantuid: "",
    columns: [
      {
        title: "Property Name",
        dataIndex: "propertyname",
        key: Math.random(),
        render: (text, record) => <span>{text}</span>,
        sorter: (a, b) => a.propertyname.localeCompare(b.propertyname),
      },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
        sorter: (a, b) => {
          const extractParts = (str) =>
            str
              .match(/(\d+|\D+)/g)
              .map((part) => (isNaN(part) ? part : Number(part)));
          const aParts = extractParts(a.address);
          const bParts = extractParts(b.address);

          for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
            if (aParts[i] !== bParts[i]) {
              if (aParts[i] === undefined) return -1;
              if (bParts[i] === undefined) return 1;
              if (typeof aParts[i] === typeof bParts[i]) {
                return aParts[i] < bParts[i] ? -1 : 1;
              }
              return typeof aParts[i] === "number" ? -1 : 1;
            }
          }
          return 0;
        },
      },
      {
        title: "City",
        dataIndex: "city",
        key: "city",
        sorter: (a, b) => a.city.localeCompare(b.city),
      },
      {
        title: "State",
        dataIndex: "state",
        key: "state",
        sorter: (a, b) => a.state.localeCompare(b.state),
      },
      {
        title: "Action",
        dataIndex: "",
        key: "x",
        render: (text, record) => (
          <span>
            <a
              data-bs-toggle="modal"
              data-bs-target="#edit-property-modal"
              onClick={() => onClickEditProperty(record)}
            >
              <i className="fa fa-edit"></i>
            </a>
            &nbsp;
            <a onClick={() => deleteConfirmation(record)}>
              <i className="fa fa-trash"></i>
            </a>
            &nbsp;
          </span>
        ),
      },
    ],
    propertyLoader: false,
    saPropertiesList: [],
    myPropertiesList: [],
    propertyLoader: false,
    PrioritiesStatus: null,
  };
  const [state, setState] = useState(intialState);

  const editState = {
    allData: "",
    propertyName: "",
    address: "",
    propertyLink: "",
    amenities: [],
    managementcompany: "",
    propertyNotes: "",
    property_image: "",
    propertyanalyticsuniqueId: false,
    showModal: false,
    supplieruid: "",
    row: null,
  };

  const [editProperty, setEditProperty] = useState(editState);

  async function onClickAddProperty() {
    // Wait for the document to be ready before executing the code
    // $(document).ready(function () {

    // });
    // Remove the "is-invalid" class from elements with that class
    $(".is-invalid").removeClass("is-invalid");

    // Show the "add-property-modal"
    console.log(document.getElementById("add-property-modal"));
    $("#add-property-modal").modal("show");

    // Clear the input fields inside the modal
    $("#add-property-modal").find("input").val("");
  }

  async function onClickEditProperty(row) {
    // console.log(row, "row");
    setEditProperty((prev) => ({
      ...prev,
      allData: row,
      propertyName: row.propertyname,
      address: row.address,
      propertyLink: row.propertylink,
      amenities: row.amenities != "" ? row.amenities : [],
      managementcompany: row.managementcompany,
      propertyNotes: row.notes,
      property_image: row.property_image,
      showModal: true,
      supplieruid: state.supplieruid,
      row: row,
    }));
  }

  useEffect(() => {
    supplier();
    getMyProperties();
  }, [state.supplieruid]);

  async function supplier() {
    let userInfo = await LoginStore.getLoginInfo({});
    console.log("userInfo", userInfo);
    setState((prev) => ({
      ...prev,
      supplieruid: userInfo.supplieruid,
      consultantuid: userInfo.userUniqueId,
    }));
  }

  async function getMyProperties(PrioritiesStatus = null) {
    await setState((prev) => ({
      ...prev,
      propertyLoader: true,
      PrioritiesStatus: PrioritiesStatus,
      saPropertiesList: [],
      Loader: true,
    }));

    let GetTransfereePropertiesObject = {
      supplieruid: state.supplieruid,
    };

    let GetTransfereeProperties = await ApiStore.GetConsultantProperties(
      GetTransfereePropertiesObject
    );

    if (GetTransfereeProperties.message.status == "Property list") {
      let saPropertiesList = _.filter(
        GetTransfereeProperties.message.transfereepropertyList,
        function (element) {
          if (element.propertyjson != null && element.propertyjson != "") {
            element.property_image =
              JSON.parse(element.propertyjson).Photo != null
                ? "https://s3-us-west-1.amazonaws.com/destination-services-itinerary/" +
                  JSON.parse(element.propertyjson).Photo +
                  ".jpg"
                : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png";
          }
          return (
            element.source == "SA_Properties" &&
            element.isactive == true &&
            element.apiId != ""
          );
        }
      );
      setState((prev) => ({
        ...prev,
        Loader: false,
        dataSource: saPropertiesList,
        myPropertiesList: saPropertiesList,
        propertyLoader: false,
        PrioritiesStatus: null,
      }));
    }
  }

  async function handleSearch(value) {
    const filterTable = state.dataSource.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setState((prev) => ({ ...prev, filterTable: filterTable }));
  }

  async function deleteConfirmation(row) {
    confirmAlert({
      title: "",
      message: "Are you sure do you want to delete this property.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteSaProperties(row),
        },
        {
          label: "No",
        },
      ],
    });
  }

  async function deleteSaProperties(row) {
    let propertyObjectDelete = {
      propertyuniqueId: row.propertyuniqueId,
      isdelete: true,
    };
    let addPropertyResultDelete = await ApiStore.UpdateProperty(
      propertyObjectDelete
    );
    if (addPropertyResultDelete.error == 0) {
      getMyProperties();
    } else {
      confirmAlert({
        title: "",
        message: addPropertyResultDelete.message.status,
        buttons: [
          {
            label: "Close",
          },
        ],
      });
    }
  }
  return (
    <div>
      {/* <Header /> */}
      <div className="container">
        <div className="my_properties_lists">
          <h1>
            <b>Properties</b>
            <span className="cursor add-list">
              <button
                type="button"
                className="btn theme-btn"
                data-bs-toggle="modal"
                data-bs-target="#add-property-modal"
                // onClick={() => onClickAddProperty()}
              >
                Add Property{" "}
                <i className="fa fa-plus-square-o" aria-hidden="true"></i>{" "}
              </button>{" "}
            </span>
          </h1>
          <div className="main_content trans-anttable">
            <div className="container">
              <div className="table-top-input-btn">
                <AutoComplete
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: 400,
                  }}
                  onSearch={handleSearch}
                  placeholder="Search by..."
                />
              </div>

              {state.Loader == true ? (
                <div className="loading">Loading</div>
              ) : (
                <Table
                  dataSource={
                    state.filterTable == null
                      ? state.dataSource
                      : state.filterTable
                  }
                  columns={state.columns}
                  pagination={{
                    defaultPageSize: 20,
                    pageSizeOptions: ["20", "25", "30"],
                    showSizeChanger: true,
                    locale: { items_per_page: "" },
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <AddProperty getMyProperties={getMyProperties} />
      <EditProperty
        editProperty={editProperty}
        getMyProperties={getMyProperties}
      />
    </div>
  );
};

export default Properties;
