import React from "react";

const CompanyHoursCard = ({ moredetails }) => {
  function isCompanyHoursVisible() {
    return (
      moredetails.subscriptioncode == "Commander" ||
      moredetails.subscriptioncode == "Explorer"
    );
  }

  if (!isCompanyHoursVisible()) {
    return null;
  }

  return (
    <div className="profile-item-2">
      <ul>
        <li>
          <p>Company Hours</p>
        </li>
        <li>
          {" "}
          <span>In Person </span> <b className="dot"> : </b>{" "}
          <b>{moredetails.companyinpersonhours}</b>{" "}
        </li>
        <li>
          {" "}
          <span>Virtual </span> <b className="dot"> : </b>{" "}
          <b>{moredetails.companyvirtualhours}</b>{" "}
        </li>
      </ul>
    </div>
  );
};

export default CompanyHoursCard;
