'use strict'
import serviceBase2 from './apiServiceBase'
import serviceBase from './serviceBase'
const apiServices = {

    RegisterTransferee: (data) => serviceBase.post('/dotnet/post/RegisterTransferee', data),
    TransfereeLoginCheck: (data) => serviceBase.post('/dotnet/post/TransfereeLoginCheck', data),
    GetTransfereeSubscrptionServices: (data) => serviceBase.post('/dotnet/post/GetTransfereeSubscrptionServices', data),
    CreateTransfereeSchedule: (data) => serviceBase.post('/dotnet/post/CreateTransfereeSchedule', data),
    GetTransfereeSchedules: (data) => serviceBase.post('/dotnet/post/GetTransfereeSchedules', data),
    UpdateTransfereeSchedule: (data) => serviceBase.post('/dotnet/post/UpdateTransfereeSchedule', data),
    GetTransfereeBasicInfo: (data) => serviceBase.post('/dotnet/post/GetTransfereeBasicInfo', data),
    GetPreferencesList: (data) => serviceBase.post('/dotnet/post/GetPreferencesList', data),
    CreateTransfereePreferences: (data) => serviceBase.post('/dotnet/post/CreateTransfereePreferences', data),
    GetTransfereePreferences: (data) => serviceBase.post('/dotnet/post/GetTransfereePreferences', data),
    GetConsultantAvaliability: (data) => serviceBase.post('/dotnet/post/GetConsultantAvaliability', data),
    CreateTransfereeProperties: (data) => serviceBase.post('/dotnet/post/CreateTransfereeProperties', data),
    GetTransfereeProperties: (data) => serviceBase.post('/dotnet/post/GetTransfereeProperties', data),
    ChangePassword: (data) => serviceBase.post('/dotnet/post/ChangePassword', data),
    UpdateTransferee: (data) => serviceBase.post('/dotnet/post/UpdateTransferee', data),
    GetTransfereeFavoriteCities: (data) => serviceBase.post('/dotnet/post/GetTransfereeFavoriteCities', data),
    GetCitiesInformation: (data) => serviceBase.post('/dotnet/post/GetCitiesInformation', data),
    GetCityPopulationAnalysis: (data) => serviceBase.post('/dotnet/post/GetCityPopulationAnalysis', data),
    GetConsultantScheduledTransferees: (data) => serviceBase.post('/dotnet/post/GetConsultantScheduledTransferees', data),
    GetConsultantTransferees: (data) => serviceBase.post('/dotnet/post/GetConsultantTransferees', data),
    CreateConsultantSchedules: (data) => serviceBase.post('/dotnet/post/CreateConsultantSchedules', data),
    GetStates: (data) => serviceBase.post('/dotnet/post/GetStates', data),
    GetCities: (data) => serviceBase.post('/dotnet/post/GetCities', data),
    UpdateConsultantInfo: (data) => serviceBase.post('/dotnet/post/UpdateConsultantInfo', data),
    ForgotPassword: (data) => serviceBase.post('/dotnet/post/ForgotPassword', data),
    UpdateTransfereePropertyAnalytics: (data) => serviceBase.post('/dotnet/post/UpdateTransfereePropertyAnalytics', data),
    ListAllSingleBedroomByZip: (data) => serviceBase.post('/dotnet/post/ListAllSingleBedroomByZip', data),
    ListAllSingleFamilyByZip: (data) => serviceBase.post('/dotnet/post/ListAllSingleFamilyByZip', data),
    UpdateProperty: (data) => serviceBase.post('/dotnet/post/UpdateProperty', data),
    ManageTransfereePropRanking: (data) => serviceBase.post('/dotnet/post/ManageTransfereePropRanking', data),
    GetAllCities: (data) => serviceBase.post('/dotnet/post/GetAllCities', data),
    GetConsultantProperties: (data) => serviceBase.post('/dotnet/post/GetAllProperties', data),
    CreateConsultantProperties: (data) => serviceBase.post('/dotnet/post/CreateProperty', data),
    CreateTransferee: (data) => serviceBase.post('/dotnet/post/CreateTransferee', data),
    GetAllConsultants: (data) => serviceBase.post('/dotnet/post/GetAllConsultants', data),
    CreateConsultantInfo: (data) => serviceBase.post('/dotnet/post/CreateConsultantInfo', data),
    RegisterConsultant: (data) => serviceBase.post('/dotnet/post/RegisterConsultant', data),
    ManagerTransfereeCheckList: (data) => serviceBase.post('/dotnet/post/ManagerTransfereeCheckList', data),
    GetTransfereeCheckList: (data) => serviceBase.post('/dotnet/post/GetTransfereeCheckList', data),
    ResetPassword: (data) => serviceBase.post('/dotnet/post/ResetPassword', data),
    UpdateAuthtoken: (data) => serviceBase.post('/dotnet/post/UpdateAuthtoken', data),
    CheckTransfereeFeedback: (data) => serviceBase.post('/dotnet/post/CheckTransfereeFeedback', data),
    CreateTransfereeFeedback: (data) => serviceBase.post('/dotnet/post/CreateTransfereeFeedback', data),
    GetTransfereeNotes: (data) => serviceBase.post('/dotnet/post/GetTransfereeNotes', data),
    ManageTransfereeNotes: (data) => serviceBase.post('/dotnet/post/ManageTransfereeNotes', data),
    GetStatusList: (data) => serviceBase.post('/dotnet/post/GetStatusList', data),
    GetTransfereeStatus: (data) => serviceBase.post('/dotnet/post/GetTransfereeStatus', data),
    ManageTransfereeStatus: (data) => serviceBase.post('/dotnet/post/ManageTransfereeStatus', data),
    GetAllClientProfile: (data) => serviceBase.post('/dotnet/post/GetAllClientProfile', data),
    UpdateClientProfile: (data) => serviceBase.post('/dotnet/post/UpdateClientProfile', data),
    CreateClientProfile: (data) => serviceBase.post('/dotnet/post/CreateClientProfile', data),
    GetOverviewInfo: (data) => serviceBase.post('/dotnet/post/GetOverviewInfo', data),
    GetByservicelevelInfo: (data) => serviceBase.post('/dotnet/post/GetByservicelevelInfo', data),
    GetBycustomerlevelInfo: (data) => serviceBase.post('/dotnet/post/GetBycustomerlevelInfo', data),
    GetConsultantOverviewInfo: (data) => serviceBase.post('/dotnet/post/GetConsultantOverviewInfo', data),
    GetConsultantByServiceLevel: (data) => serviceBase.post('/dotnet/post/GetConsultantByServiceLevel', data),
    GetConsultantAppointments: (data) => serviceBase.post('/dotnet/post/GetConsultantAppointments', data),
    ManageAppAnalytics: (data) => serviceBase.post('/dotnet/post/ManageAppAnalytics', data),
    GetSupplierSignupReport: (data) => serviceBase.post('/dotnet/post/GetSupplierSignupReport', data),
    GetSupplierAppointmentsReport: (data) => serviceBase.post('/dotnet/post/GetSupplierAppointmentsReport', data),
    GetSupplierTransfereesReport: (data) => serviceBase.post('/dotnet/post/GetSupplierTransfereesReport', data),
    GetConsultantSignupReport: (data) => serviceBase.post('/dotnet/post/GetConsultantSignupReport', data),
    GetConsultantAppointmentsReport: (data) => serviceBase.post('/dotnet/post/GetConsultantAppointmentsReport', data),
    GetConsultantTransfereesReport: (data) => serviceBase.post('/dotnet/post/GetConsultantTransfereesReport', data),
    NeighborhoodScoutPdf: (data) => serviceBase.post('/dotnet/post/NeighborhoodScoutPdf', data),

    GetSchoolDataByState: (data) => serviceBase.post('/dotnet/get/GetSchoolDataByState&&state=florida'),
    GetTransfereeActivities: (data) => serviceBase.post('/dotnet/post/GetTransfereeActivities', data),
    CreateTransfereeActivities: (data) => serviceBase.post('/dotnet/post/CreateTransfereeActivities', data),
    UpdateTransfereeActivities: (data) => serviceBase.post('/dotnet/post/UpdateTransfereeActivities', data),
    GetSupportedLawsByState: (data) => serviceBase.post('/dotnet/get/GetSupportedLawsByState&&state=' + data.state),
    GetColurRuleByCategory: (data) => serviceBase.post('/dotnet/get/GetColurRuleByCategory&&category=' + data),
    GetOverViewByState: (data) => serviceBase.post('/dotnet/get/GetOverViewByState&&state=' + data),
    GetBillingEventsBySupplierId: (data) => serviceBase.post('/dotnet/post/GetBillingEventsBySupplierId', data),

    GetUserAnalytics: (data) => serviceBase.post('/dotnet/post/GetUserAnalytics', data),
    GetPageAnalyatics: (data) => serviceBase.post('/dotnet/post/GetPageAnalyatics', data),
    //GetFeedBackByTransferee: (data) => serviceBase.post('/dotnet/get/GetFeedBackByTransferee', data),
    GetFeedBackByTransferee: (data) => serviceBase.post('/dotnet/get/GetFeedBackByTransferee&&consultantuid=' + data.consultantuid),
    GetClientProfileById: (data) => serviceBase.post('/dotnet/post/GetClientProfileById', data),
    GetCountByDevices: (data) => serviceBase.post('/dotnet/post/GetCountByDevices', data),
    GetHousingByTransferId: (data) => serviceBase.post('/dotnet/get/GetHousingByTransferId&&transferuid=' + data.transfereeuniqeuid),
    CreateTransfereeFavoriteCities: (data) => serviceBase.post('/dotnet/post/CreateTransfereeFavoriteCities', data),



}
export default apiServices