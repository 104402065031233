import React from "react";
import "./solo.css";
import Header from "../../../common/header/Header";

function SoloLanding() {
  return (
    <div id="outer-container">
      <Header />
      <div className="mrgtop-50"></div>

      <div className="container-fluid"></div>
      <div className="home_banner">
        <img
          className="width100per"
          src="/assets/images/dslosohome_banner.png"
        />

        <img
          className="sm_stamp"
          src="/assets/images/navigater-banner-stamp.png"
        />
      </div>
      <div className="container-fluid">
        <div className="solohome_tumbs">
          <div className="container">
            <div className="mrgbtm-100">
              <h1>YOUR RELOCATION FEATURING OUR COMPREHENSIVE NETWORK.</h1>
              <p>
                Welcome to your next adventure. With access to our State-of-the
                art database and ReloNavigator, you will have our resources at
                your fingertips. Enjoy the freedom of relocating on your time
                and at your own pace.
              </p>
            </div>

            <div className="row">
              <div className="col-md-4 col-sm-6 col-6">
                <div className="card card_item">
                  <div className="halftop_img">
                    <img src="/assets/images/svg/sharlotte_a.svg" alt="" />
                  </div>
                  <div className="card_footer">
                    <h5>RELONAVIGATOR</h5>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-6">
                <div className="card card_item">
                  <div className="halftop_img">
                    <img
                      src="/assets/images/svg/criteriaandareas_a.svg"
                      alt=""
                    />
                  </div>
                  <div className="card_footer">
                    <h5>CRITERIA & AREAS</h5>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-6">
                <div className="card card_item">
                  <div className="halftop_img">
                    <img src="/assets/images/svg/lstingandinfo_a.svg" alt="" />
                  </div>
                  <div className="card_footer">
                    <h5>LISTINGS & INFO</h5>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-3 col-sm-6 col-6">
									<div className="card card_item">
										<div className="halftop_img"><img src="/assets/images/svg/housemate_a.svg" alt="" /></div>
										<div className="card_footer">
											<h5>SHARED LIVING</h5>
										</div>
									</div>
								</div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="solohome_section solohome_section_banner">
        <div className="container-fluid">
          <div className="row pt-5 pl-3 pr-2 align-items-center">
            <div className="col-md-6 text-center">
              <div className="text-center">
                <h4 className="mclr">RELONAVIGATOR</h4>
                <p>
                  Destination Services platform built on our partnerships,
                  resources, and area Insight.
                </p>
                <p>A one stop shop for your relocation.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <div>
                  <img
                    src="/assets/images/advinpage1.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="/assets/images/advinpage2.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-5 pl-3 pr-2 align-items-center flex-row-reverse">
            <div className="col-md-6 text-center">
              <div className="text-center">
                <h4 className="mclr ">CRITERIA &amp; AREAS</h4>
                <p className="mt-4">
                  Determine your Budget, Commute, and Amenities.
                </p>
                <p className="">Prioritize your criteria.</p>
                <p>
                  Focused results narrow your search to specific neighborhoods.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <div>
                  <img
                    src="/assets/images/advinpage3.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-5 pl-3 pr-2 align-items-center">
            <div className="col-md-6 text-center">
              <div className="text-center">
                <h4 className="mclr">LISTINGS &amp; INFO</h4>
                {/* <p className="mt-4">Explore premier listings from vetted partners</p> */}
                <p className="">
                  Each listing is surrounded by a wealth of area insight.
                </p>
                <p>
                  View Bike score, Walk score, Local school ratings, Crime
                  statistics, and much more.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <div>
                  <img
                    src="/assets/images/advinpage4.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="/assets/images/advinpage5.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="br-b-r-5"></div>
    </div>
  );
}

export default SoloLanding;
