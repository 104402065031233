import React from "react";

function PetFriendlyModal() {
  const closeModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#petFriendlyModal").modal("hide");
      });
    });
  };
  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header border-0">
          <div class="only_close">
            <span
              aria-hidden="true"
              className="pointer close"
              onClick={() => closeModal()}
              data-dismiss="modal"
            >
              ×
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div className="widthModal">
            <div className="item-view">
              <h2>Pet-Friendly Housing </h2>
              <p className="mb-0">
                Finding a rental that accommodates pets can sometimes be
                challenging, but with the right approach, you can secure a home
                that’s perfect for both you and your furry friend. Here are some
                key tips and strategies to help you in your search for
                pet-friendly housing.
              </p>
            </div>

            <div className="tails-row">
              <div className="tails">
                <div className="item-view">
                  <h2>1. Start Your Search Early </h2>
                  <p className="ps-4">
                    Pet-friendly rentals can be more competitive, so it’s
                    important to start looking as early as possible. Begin your
                    search well before your move date to allow enough time to
                    explore your options and negotiate terms if necessary.
                  </p>
                  <ul>
                    <li>
                      <b>Use Specialized Filters: </b> Many apartment rental
                      websites allow you to filter for pet-friendly properties.
                      Platforms like Zillow and Apartments.com have options that
                      show only properties that accept pets.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>2. Know the Pet Policies </h2>
                  <p className="ps-4">
                    Each rental property has different pet policies, so it’s
                    essential to clarify the rules before signing a lease. Some
                    common policies include:
                  </p>
                  <ul>
                    <li>
                      <b>Breed Restrictions: </b> Some landlords restrict
                      specific dog breeds, often larger or "aggressive" breeds
                      like Pit Bulls or Rottweilers. Make sure to ask about any
                      breed or size restrictions.
                    </li>
                    <li>
                      <b>Weight Limits: </b> Many apartment complexes have
                      weight limits on dogs, usually around 20-50 pounds. If you
                      have a larger dog, you may need to search more extensively
                      or negotiate with the landlord.
                    </li>
                    <li>
                      <b>Pet Deposits/Fees: </b> Be prepared to pay a pet
                      deposit or a monthly pet fee. Some landlords charge a
                      non-refundable fee upfront, while others may increase rent
                      for pets.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>3. Prepare a Pet Resume </h2>
                  <p className="ps-4">
                    Just as you would present yourself professionally during the
                    rental application process, creating a pet resume can help
                    highlight your pet’s best qualities and make a strong case
                    to landlords. Rather than building one from scratch, use our
                    <b> Pet Resume Generator </b>to easily compile all the
                    necessary information and present it in a polished format.
                  </p>
                  <p>A strong pet resume can include: </p>
                  <ul>
                    <li>
                      <b>Basic Information: </b> Your pet’s name, breed, age,
                      and weight.
                    </li>
                    <li>
                      <b>Vaccination Records: </b> Proof that your pet is up to
                      date on vaccinations, especially rabies.
                    </li>
                    <li>
                      <b>Behavior Training: </b>Mention if your pet has
                      undergone any obedience training or behavior
                      certifications.
                    </li>
                    <li>
                      <b>References: </b> Include references from previous
                      landlords, neighbors, or your vet to demonstrate your
                      pet’s good behavior and your responsibility as a pet
                      owner.
                    </li>
                  </ul>
                  <p>
                    By using our <b>Pet Resume Generator,</b> you can quickly
                    organize this information, helping you stand out and
                    reassure potential landlords that your pet is a responsible
                    tenant too!{" "}
                  </p>
                </div>
              </div>

              <div className="tails">
                <div className="item-view">
                  <h2>4. Be Ready to Negotiate </h2>
                  <p className="ps-4">
                    If a landlord or property management company has strict pet
                    policies, consider negotiating the terms:
                  </p>
                  <ul>
                    <li>
                      <b>Offer a Higher Deposit: </b> You can offer to pay a
                      larger security or pet deposit to cover any potential
                      damages your pet might cause.
                    </li>
                    <li>
                      <b>Provide Pet Insurance: </b> Offering to take out pet
                      liability insurance can reassure landlords that they won’t
                      be liable for any accidents caused by your pet.
                    </li>
                    <li>
                      <b>Highlight Your Responsibility: </b>Let landlords know
                      about your pet’s good behavior and how you plan to care
                      for the property, including setting boundaries for pets or
                      arranging regular cleaning.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>5. Explore Alternative Housing Options </h2>
                  <p className="ps-4">
                    If traditional apartment complexes don’t offer pet-friendly
                    options, consider alternative housing arrangements:
                  </p>
                  <ul>
                    <li>
                      <b>Private Rentals: </b> Look for individual landlords who
                      may be more flexible with pet policies compared to larger
                      management companies.
                    </li>
                    <li>
                      <b>Houses vs. Apartments: </b> Renting a house or
                      townhouse might give you more flexibility for pets,
                      especially larger dogs, and access to a yard.
                    </li>
                    <li>
                      <b>Roommates: </b>If living with roommates, make sure
                      everyone is comfortable with pets. Some roommates might
                      have allergies or concerns about having animals around.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>6. Check for Pet Amenities </h2>
                  <p className="ps-4">
                    To make your pet’s experience better, look for properties
                    that offer pet-friendly amenities:
                  </p>
                  <ul>
                    <li>
                      <b>Dog Parks or Play Areas: </b> Some apartment complexes
                      have on-site dog parks or designated pet areas where your
                      dog can run and play off-leash.
                    </li>
                    <li>
                      <b>Walking Paths and Nearby Parks: </b> If possible,
                      choose a property close to parks, walking trails, or other
                      green spaces to ensure your pet gets plenty of exercise.
                    </li>
                    <li>
                      <b>Pet-Friendly Apartment Features: </b>Ground-floor
                      units, easy access to outdoor spaces, or pet doors can
                      make life easier for both you and your pet.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>7. Understand Local Pet Regulations </h2>
                  <p className="ps-4">
                    Before committing to a property, be sure to review any local
                    pet regulations that may apply. This includes:
                  </p>
                  <ul>
                    <li>
                      <b>Leash Laws: </b> Understand the local leash laws and
                      whether there are any off-leash areas nearby.
                    </li>
                    <li>
                      <b>Noise Restrictions: </b> Some properties have noise
                      ordinances, especially in multi-unit buildings, which can
                      impact pets prone to barking.
                    </li>
                    <li>
                      <b>Pet Limits: </b> Some cities or counties limit the
                      number of pets per household, so be sure to verify those
                      rules if you have multiple animals.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="item-view mt-2">
              <h2>Final Thoughts </h2>
              <p className="mb-0">
                Securing pet-friendly housing can take extra time and effort,
                but with preparation and research, it’s possible to find a home
                that welcomes both you and your pet. Be sure to start early,
                understand the property’s pet policies, and be prepared to
                negotiate or offer additional assurances like a pet resume or
                insurance.
              </p>
              <p>
                With these strategies, you’ll be well on your way to finding a
                comfortable, pet-friendly home.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PetFriendlyModal;
