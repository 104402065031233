import React from "react";

function InputComponent(props) {
  const { state, questionList, setLocation, questionIndex } = props;
  return (
    <div key={"sub" + questionIndex} className="">
      {questionList.questionlabel == "WORK ADDRESS" ? (
        <h1>Work Location</h1>
      ) : (
        <h1>{questionList.question}</h1>
      )}
      {questionList.description != null ? (
        <h6>{questionList.description}</h6>
      ) : null}
      <input
        className="form-control"
        type="text"
        defaultValue={
          state.answersPrefer[questionList.preferenceUId] != undefined
            ? state.answersPrefer[questionList.preferenceUId].preferencesanswer
            : ""
        }
        id={questionList.type}
        onChange={(e) =>
          setLocation(questionList.type, e, questionList.preferenceUId)
        }
      />
      {state.isError &&
        (state.answersPrefer[questionList.preferenceUId] == undefined ||
          state.answersPrefer[questionList.preferenceUId].preferencesanswer ==
            "") && (
          <div className="tooltip-validation">
            <div className="tooltip-inner">{state.toolTipText}</div>
          </div>
        )}
      {/* {state.validationErrors &&
        state.validationErrors[questionList.preferenceUId] && (
          <div className="tooltip-validation">
            <div className="tooltip-inner">
              {state.validationErrors[questionList.preferenceUId]}
            </div>
          </div>
        )} */}
    </div>
  );
}

export default InputComponent;
