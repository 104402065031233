import React from "react";

const ServiceLevelDropDownComponent = ({ servicelevel, inputHandler }) => {
  return (
    <div className="form-group col">
      <label htmlFor="inputState">Service level</label>
      <select
        value={servicelevel}
        className="form-control"
        id="Servicelevel"
        onChange={(e) => inputHandler("servicelevel", e.target.value)}
      >
        <option value="">Choose...</option>
        <option value={"ReloNavigator"}>ReloNavigator</option>
        <option value={"Explorer"}>Explorer</option>
        <option value={"Commander"}>Commander</option>
      </select>
    </div>
  );
};

export default ServiceLevelDropDownComponent;
