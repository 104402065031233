import React from "react";
import "./solo.css";
import Header from "../../../common/header/Header";

function CommanderLanding() {
  return (
    <div id="outer-container">
      <Header />
      <div className="mrgtop-50"></div>

      <div className="container-fluid"></div>
      <div className="home_banner">
        <img
          className="width100per"
          src="/assets/images/dslosohome_banner.png"
        />

        <img
          className="sm_stamp"
          src="/assets/images/commander-banner-stamp.png"
        />
      </div>
      <div className="container-fluid">
        <div className="solohome_tumbs">
          <div className="container">
            <div className="mrgbtm-100">
              <h1>YOUR RELOCATION FEATURING OUR COMPREHENSIVE NETWORK.</h1>
              <p>
                Welcome to your next adventure. With access to our State-of-the
                art database and ReloNavigator, you will have our resources at
                your fingertips. Enjoy the freedom of relocating on your time
                and at your own pace.
              </p>
            </div>

            <div className="row">
              <div className="col-md-4 col-sm-6 col-6">
                <div className="card card_item">
                  <div className="halftop_img">
                    <img src="/assets/images/svg/sharlotte_a.svg" alt="" />
                  </div>
                  <div className="card_footer">
                    <h5>CONSULTANT</h5>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-6">
                <div className="card card_item">
                  <div className="halftop_img">
                    <img
                      src="/assets/images/svg/criteriaandareas_a.svg"
                      alt=""
                    />
                  </div>
                  <div className="card_footer">
                    <h5>CHAUFFEURED TOURS</h5>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-6">
                <div className="card card_item">
                  <div className="halftop_img">
                    <img src="/assets/images/svg/lstingandinfo_a.svg" alt="" />
                  </div>
                  <div className="card_footer">
                    <h5>SETTLING-IN SERVICES</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="solohome_section solohome_section_banner">
        <div className="container-fluid">
          <div className="row pt-5 pl-3 pr-2 align-items-center">
            <div className="col-md-6 text-center">
              <div className="text-center">
                <h4 className="mclr">CONSULTANT</h4>
                <p>
                  Moving to a new city can be daunting. So, we minimize your
                  stress with a single point of contact and provide live support
                  throughout your journey with us.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <div>
                  <img
                    src="/assets/images/advinpage1.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="/assets/images/advinpage2.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-5 pl-3 pr-2 align-items-center flex-row-reverse">
            <div className="col-md-6 text-center">
              <div className="text-center">
                <h4 className="mclr ">CHAUFFEURED TOURS</h4>
                <p className="mt-4">
                  2-days of chauffeured tours in our highly-equipped Destination
                  Services vehicle.
                </p>
                <p className="">
                  An interactive itinerary on an iPad allows you to view and
                  rate properties and take notes throughout your tour.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <div>
                  <img
                    src="/assets/images/advinpage3.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-5 pl-3 pr-2 align-items-center">
            <div className="col-md-6 text-center">
              <div className="text-center">
                <h4 className="mclr">SETTLING-IN SERVICES</h4>
                <p className="mt-4">
                  Comprehensive settling-in services including assistance with
                  school registration, DMV support, utility setup, Banking &
                  Insurance assistance, and many more.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <div>
                  <img
                    src="/assets/images/advinpage4.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="/assets/images/advinpage5.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="br-b-r-5"></div>
    </div>
  );
}

export default CommanderLanding;
