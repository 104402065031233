import "./iternaryProperties.css";
import { useState, useEffect } from "react";
import ApiServices from "../../../../services/apiServices";
import LoginStore from "../../../../services/thirdPartyService";
import _ from "lodash";
import moment from "moment";
import ItenaryProperties from "./iterenaryProperties";

const ItenaryAppointments = (props) => {
  let _isMounted = false;
  const [state, setState] = useState({
    consultantName: "",
    consultantpic: "",
    consultantemail: "",
    itineraryPropertiesList: [],
    consultantphone: "",
    transfareeName: "",
    pickupLocation: "",
    phonenumber: "",
    emailId: "",
    events: [],
    showDetails: false,
    iternaryLoader: true,
    scheduleuniqueId: "",
    overallfeedback: "",
  });

  useEffect(() => {
    getScheduleList();
  }, []);
  useEffect(() => {
    return () => {
      _isMounted = false;
    };
  }, []);

  const getScheduleList = async () => {
    let userInfo = await LoginStore.getLoginInfo({});
    let getScheduleObject = {
      transfereeuniqueid: userInfo.userUniqueId,
    };
    let schedules = await ApiServices.GetTransfereeSchedules(getScheduleObject);

    let events = [];
    _.forEach(
      schedules.message.scheduleslist.sort(
        (a, b) => new Date(b.scheduledate) - new Date(a.scheduledate)
      ),
      async (localEvent) => {
        let EditMoveDate = moment(localEvent.scheduledate).format("YYYY/MM/DD");
        let serviceTitle = "";
        if (localEvent.scheduleServicesList.length > 0) {
          _.forEach(localEvent.scheduleServicesList, async (service) => {
            serviceTitle = service.ServiceName + "," + serviceTitle;
          });
        }
        let cuurentDate = parseInt(moment(new Date()).format("YYYYMMDD"));
        let startDate = parseInt(moment(EditMoveDate).format("YYYYMMDD"));

        localEvent.start = new Date(
          EditMoveDate + " " + localEvent.schedulefromtime
        );
        localEvent.end = new Date(
          EditMoveDate + " " + localEvent.scheduletotime
        );

        // Calculate the allotted time using absolute difference
        let timeDiff = Math.abs(
          localEvent.end.getTime() - localEvent.start.getTime()
        );
        let hours = timeDiff / (1000 * 3600); // Extract hours
        let minutes = Math.round(timeDiff % (1000 * 3600)); // Extract remaining minutes

        // Format as "1:25 Hours"
        localEvent.allotedHours = ` ${hours} Hours`;
        (localEvent.title =
          serviceTitle != ""
            ? serviceTitle.replace(/,\s*$/, "")
            : localEvent.servicename),
          (localEvent.serviceDrodown = localEvent.packageserviceuniqueid),
          (localEvent.pickuplocation = localEvent.pickuplocation),
          (localEvent.serviceNote = localEvent.serviceNote),
          (localEvent.type = "guest");
        localEvent.scheduleuniqueId = localEvent.scheduleuniqueId;
        localEvent.id = localEvent.scheduleuniqueId;
        localEvent.backgroundColor = cuurentDate <= startDate ? "" : "#808080";
        events.push(localEvent);
      }
    );
    await state.events.push(events);
    await setState((prev) => ({ ...prev, events: events }));
    let sortevents = state.events;
    sortevents.sort(function (a, b) {
      return new Date(b.start) - new Date(a.start);
    });
    await setState((prev) => ({
      ...prev,
      events: sortevents,
      iternaryLoader: false,
    }));
    console.log(state.events, "state.events");
  };

  const showDeatils = async (row) => {
    await setState((prev) => ({
      ...prev,
      showDetails: false,
      itineraryPropertiesList: [],
      scheduleuniqueId: "",
      overallfeedback: row.overallfeedback,
    }));
    console.log(row);
    if (row.itineraryPropertiesList.length > 0) {
      let transfareeName = row.firstname + " " + row.lastname;
      let consultantName =
        row.consultantfirstname + " " + row.consultantlastname;
      await setState((prev) => ({
        ...prev,
        scheduleuniqueId: row.scheduleuniqueId,
        showDetails: true,
        itineraryPropertiesList: row.itineraryPropertiesList,
        phonenumber: row.phonenumber,
        emailId: row.emailid,
        pickupLocation: row.pickuplocation,
        transfareeName: transfareeName,
        consultantphone: row.consultantphone,
        consultantName: consultantName,
        consultantpic: row.consultantprofilepic,
        consultantemail: row.consultantemailid,
        RowResult: row,
      }));
    }
  };
  const showItenaries = async () => {
    await getScheduleList();
    await setState((prev) => ({ ...prev, showDetails: false }));
  };

  return (
    <div>
      {!state.showDetails && (
        <div className="sch_appointments">
          {state.iternaryLoader == true ? (
            <div className="loading">Loading</div>
          ) : null}
          <div className="top">
            <h1>Scheduled Appointments</h1>
          </div>
          {state.events[0] && state.events[0].length <= 0 && (
            <div className="top">
              <h1></h1>
              <h2> </h2>
              <h2>
                No Appointments have been scheduled yet. Please schedule an
                appointment with your consultant{" "}
                <a href="javascript:void(0)" onClick={props.showSchedules}>
                  here.
                </a>
              </h2>
            </div>
          )}
          <div className="container">
            {state.events && state.events.length > 0 && (
              <div className="sch_appointments_thumbs">
                {state.events.at(0).map((row, index) => {
                  return (
                    <div className="item" key={index}>
                      <b>{state.events[0].length - index}</b>
                      <h1>
                        <i className="fa fa-circle"></i> {row.title}{" "}
                      </h1>
                      <ul>
                        <li>{moment(row.scheduledate).format("LL")}</li>
                        {/* <li> {row.schedulefromtime} </li> */}
                        <li>{row.allotedHours}</li>
                      </ul>
                      <p>
                        {row.schedulefromtime}-{row.scheduletotime}
                      </p>
                      <p>{row.pickuplocation}</p>
                      <h2>With</h2>
                      <p>
                        {" "}
                        {row.consultantfirstname} {row.consultantlastname}{" "}
                      </p>
                      <p>
                        {" "}
                        <i className="fa fa-phone"></i> {row.consultantphone}{" "}
                      </p>
                      <p>
                        <i className="fa fa-envelope-open"></i>{" "}
                        {row.consultantemailid}{" "}
                      </p>
                      {row.meetinglink != null && row.meetinglink != "" ? (
                        <p className="view-more">
                          {" "}
                          <a href={row.meetinglink} target="_blank">
                            {" "}
                            Meeting Link
                          </a>{" "}
                        </p>
                      ) : null}
                      {row.itineraryPropertiesList > 0 ? (
                        <p className="view-more">
                          {" "}
                          <a
                            href="javascript:void(0)"
                            onClick={() => showDeatils(row)}
                          >
                            {" "}
                            View More{" "}
                          </a>{" "}
                        </p>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
      {state.showDetails && (
        <ItenaryProperties {...state} showItenaries={showItenaries} />
      )}
    </div>
  );
};
export default ItenaryAppointments;
