import React, { useEffect, useState } from "react";
// import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import ApiServices from "../../../../services/apiServices";
import Header from "../header and footer/Header";
// import Footer from "../header and footer/Footer";
import Footer from "../../../common/footer/footerWithoutchat";
import LoginStore from "../../../../services/thirdPartyService";
import moment from "moment";
import "./DashBoard.css";

const ConsultantDashboard = () => {
  const [state, setstate] = useState({
    overview: [],
    servicelevel: [],
    customerlevel: [],
    pageloader: true,
  });

  useEffect(() => {
    componentDidMount();
  }, []);

  async function componentDidMount() {
    let getSessionData = await LoginStore.getLoginInfo({});
    let data = {
      consultantuniqueId: getSessionData.userUniqueId,
      // consultantuniqueId: "89ee285f-0c2c-4359-b464-f7512d4e4239",
      fromdate: moment().format("MM/DD/YYYY"),
      todate: moment().format("MM/DD/YYYY"),
    };
    let appdata = {
      consultantuniqueId: getSessionData.userUniqueId,
      // consultantuniqueId: "89ee285f-0c2c-4359-b464-f7512d4e4239",
      fromdate: moment().format("MM/DD/YYYY"),
      todate: moment().format("MM/DD/YYYY"),
    };
    const overview = await ApiServices.GetConsultantOverviewInfo(data);
    const servicelevel = await ApiServices.GetConsultantByServiceLevel(data);
    const customerlevel = await ApiServices.GetConsultantAppointments(appdata);
    if (overview.error == "0") {
      setstate((prev) => ({ ...prev, overview: overview.overviewlist }));
    }
    if (servicelevel.error == 0) {
      servicelevel.overviewlist.forEach((element) => {
        if (element.ServiceLevel == "Commander") {
          element.orderId = 1;
        }
        if (element.ServiceLevel == "Explorer") {
          element.orderId = 2;
        }
        if (element.ServiceLevel == "ReloNavigator") {
          element.orderId = 3;
        }
        let SortStatus = _.sortBy(servicelevel.overviewlist, [
          function (o) {
            return parseInt(o.orderId, 10);
          },
        ]);
        setstate((prev) => ({ ...prev, servicelevel: SortStatus }));
      });
    }

    if (customerlevel.error == 0) {
      let sortappointments = customerlevel.overviewlist;
      sortappointments.sort(function (a, b) {
        return (
          new Date("1970/01/01 " + b.ScheduleFromTime) -
          new Date("1970/01/01 " + a.ScheduleFromTime)
        );
      });
      setstate((prev) => ({
        ...prev,
        customerlevel: sortappointments,
        pageloader: false,
      }));
    }
  }
  return (
    <div className="dashboard-ds">
      {/* <Header /> */}
      {state.pageloader == true && <div className="loading">Loading</div>}
      <div className="wrapper">
        <div className="page-title-box">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="state-information  d-sm-block">
                  <div className="state-graph">
                    <Link to="/ConsultantusageDashboard">
                      <button type="button" className="btn theme-btn">
                        Usage Dashboard
                      </button>
                    </Link>
                  </div>
                </div>
                <h4 className="page-title">Overview</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content-wrapper">
          <div className="container">
            {state.overview.length > 0
              ? state.overview.map((each, i) => {
                  return (
                    <div className="row-tails" key={i}>
                      <div className="card-body">
                        <div className="mini-stat-desc">
                          <div className="mini-stat-icon">
                            <img
                              src="assets/images/switch.svg"
                              width="100%"
                              alt=""
                            />
                          </div>
                          <div className="text-white">
                            <h3 className="mb-1 mt-0 text-dark">
                              {each.TotalActiveTransferees}
                            </h3>
                            <h6 className="mt-0 mb-0 "> Active Transferees</h6>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="mini-stat-desc">
                          <div className="mini-stat-icon">
                            <img
                              src="assets/images/clock.svg"
                              width="100%"
                              alt=""
                            />
                          </div>
                          <div className="text-white">
                            <h3 className="mb-1 mt-0 text-dark">
                              {each.TotalActualHrs != undefined &&
                              each.TotalActualHrs != null
                                ? each.TotalActualHrs.substring(
                                    0,
                                    each.TotalActualHrs.length - 3
                                  )
                                : 0}
                            </h3>
                            <h6 className=" mt-0 mb-0 "> Total Hours </h6>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="mini-stat-desc">
                          <div className="mini-stat-icon">
                            <img
                              src="assets/images/hourglass.svg"
                              width="100%"
                              alt=""
                            />
                          </div>
                          <div className="text-white">
                            <h3 className="mb-1 mt-0 text-dark">
                              {each.TotalUsedHrs != undefined &&
                              each.TotalUsedHrs != null
                                ? each.TotalUsedHrs.substring(
                                    0,
                                    each.TotalUsedHrs.length - 3
                                  )
                                : 0}
                            </h3>
                            <h6 className=" mt-0 mb-0 "> Used Hours </h6>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="mini-stat-desc">
                          <div className="mini-stat-icon">
                            <img
                              src="assets/images/hourglass.svg"
                              width="100%"
                              alt=""
                            />
                          </div>
                          <div className="text-white">
                            <h3 className="mb-1 mt-0 text-dark">
                              {each.TotalRemainingHrs != undefined &&
                              each.TotalRemainingHrs != null
                                ? each.TotalRemainingHrs.substring(
                                    0,
                                    each.TotalRemainingHrs.length - 3
                                  )
                                : 0}
                            </h3>
                            <h6 className=" mt-0 mb-0 "> Remaining Hours </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
            <div className="page-title-box">
              <h4 className="page-title">Assignee Analysis</h4>
            </div>

            <div className="row chart-row chart-box">
              <div className="col-xl-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">By service level</h4>

                    <div className="table-responsive ct-chart">
                      <table className="table mb-0">
                        <thead className="thead-default">
                          <tr>
                            <th width="10%"> </th>
                            <th width="20%" style={{ textAlign: "center" }}>
                              Active Users
                            </th>
                            <th width="20%" style={{ textAlign: "center" }}>
                              Total hours
                            </th>
                            <th width="20%" style={{ textAlign: "center" }}>
                              Used hours
                            </th>
                            <th width="30%" style={{ textAlign: "center" }}>
                              Remaining hours
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.servicelevel.length > 0 ? (
                            state.servicelevel.map((row, i) => {
                              return (
                                <tr key={i}>
                                  <th scope="row">{row.ServiceLevel}</th>
                                  <td style={{ textAlign: "center" }}>
                                    {row.TotalActiveTransferees}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {row.TotalActualHrs != null &&
                                    parseInt(row.TotalActualHrs) > 0
                                      ? row.TotalActualHrs.substring(
                                          0,
                                          row.TotalActualHrs.length - 3
                                        )
                                      : "0"}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {row.TotalUsedHrs != null
                                      ? row.TotalUsedHrs.substring(
                                          0,
                                          row.TotalUsedHrs.length - 3
                                        )
                                      : "0"}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {row.TotalRemainingHrs != null
                                      ? row.TotalRemainingHrs.substring(
                                          0,
                                          row.TotalRemainingHrs.length - 3
                                        )
                                      : "0"}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="4">No data</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive ct-chart">
              <div className="col-xl-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="mt-0 header-title mb-1">
                      Today’s Appointments{" "}
                    </h4>

                    <div className="table-responsive ct-chart table2">
                      <table className="table mb-0">
                        <thead className="thead-default">
                          <tr>
                            <th width="20%">Transferee Name</th>
                            <th width="20%" style={{ textAlign: "center" }}>
                              Tour Type
                            </th>
                            <th width="20%" style={{ textAlign: "center" }}>
                              Schedule Date
                            </th>
                            <th width="20%" style={{ textAlign: "center" }}>
                              Start Time
                            </th>
                            <th width="20%" style={{ textAlign: "center" }}>
                              End Time
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.customerlevel.length > 0 ? (
                            state.customerlevel.map((row, i) => {
                              return (
                                <tr key={i}>
                                  <th scope="row">
                                    {row.FirstName} {row.LastName}
                                  </th>
                                  <td style={{ textAlign: "center" }}>
                                    {row.FacilityName}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {row.ScheduleDate != null &&
                                    row.ScheduleDate != ""
                                      ? moment(row.ScheduleDate).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "--"}
                                  </td>

                                  <td style={{ textAlign: "center" }}>
                                    {row.ScheduleFromTime}{" "}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {row.ScheduleToTime}{" "}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="5" style={{ textAlign: "center" }}>
                                No data
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ConsultantDashboard;
