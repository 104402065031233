import React from "react";
import RentalQandA from "./RentalQ&A.json";

function RentalFAQ() {
  let data = RentalQandA.faq;
  //   console.log(RentalQandA, "RentalQandA");

  return (
    <div>
      <div className="container r-a-c-view py-5 rental-faq">
        <h2 className="text-center">FrequentlyAskedQuestions</h2>

        <div className="accordion accordion-flush" id="accordionFlushExample">
          <div className="accordion-item">
            {data.map((row, index) => (
              <div key={index}>
                <h2
                  className="accordion-header"
                  id={"flush-headingOne" + index}
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={"#flush-collapseOne" + index}
                    aria-expanded="false"
                    aria-controls={"flush-collapseOne" + index}
                  >
                    {row.question}
                  </button>
                </h2>

                <div
                  id={"flush-collapseOne" + index}
                  className="accordion-collapse collapse"
                  aria-labelledby={"flush-headingOne" + index}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    {row.question ==
                    "What is a renter’s resume, and how can it help me?" ? (
                      <span>
                        A renter’s resume is a document that outlines your
                        rental history, employment details, and references. It
                        can help you stand out in competitive rental markets by
                        presenting you as a reliable tenant. Please visit{" "}
                        <a href="/rentersresume">Renters Resume Generator </a>to
                        create your renter’s resume.
                      </span>
                    ) : (
                      row.answer
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RentalFAQ;
