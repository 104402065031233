// import "../dsProfile/dsProfile.css";
import ApiServices from "../../../../services/apiServices";
import { useState, useEffect } from "react";
import LoginStore from "../../../../services/thirdPartyService";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const SchedulesSearchProperties = (props) => {
  var _isMounted = false;
  const [state, setState] = useState({
    serviceslist: [],
    GetTransfereeProperties: [],
    responseTransfreeProperties: [],
    isShowSchedule: false,
    isShowfavProperties: false,
    updatedDate: "",
    items: "",
    tabIttems: "",
    startPosition: "0",
    startPosition1: "0",
    propertyIds: props.propertyListIds,
    myPropertiesList: [],
    propertyLoader: false,
    ZillowBanglow: [],
    saProperties: [],
    startPosition2: "0",
  });

  const checkBox = () => {};
  const updateDate = async (date) => {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
    if (_isMounted) {
      await setState((prev) => ({
        ...prev,
        updatedDate: day + " " + monthNames[monthIndex] + " " + year,
      }));
    }
    return day + " " + monthNames[monthIndex] + " " + year;
  };
  useEffect(() => {
    return async () => {
      _isMounted = false;
    };
  }, []);
  useEffect(() => {
    const fetch = async () => {
      let items =
        parseInt(screen.availWidth) <= 767
          ? 1
          : parseInt(screen.availWidth) <= 991
          ? 2
          : 3;
      setState((prev) => ({ ...prev, items: items }));
      let tabItems =
        parseInt(screen.availWidth) <= 767
          ? 1
          : parseInt(screen.availWidth) < 991
          ? 2
          : 3;
      setState((prev) => ({ ...prev, tabItems: tabItems }));
      console.log("tabItems", tabItems);
      _isMounted = true;
      await setState((prev) => ({ ...prev, propertyLoader: true }));
      updateDate(new Date());
      let getSessionData = await LoginStore.getLoginInfo({});
      let GetTransfereePropertiesObject = {
        transfereeuniqueId: getSessionData.userUniqueId,
      };
      let GetTransfereeProperties = await ApiServices.GetTransfereeProperties(
        GetTransfereePropertiesObject
      );
      if (GetTransfereeProperties.error == 0) {
        if (_isMounted) {
          setState((prev) => ({
            ...prev,
            GetTransfereeProperties:
              GetTransfereeProperties.message.transfereepropertyList,
          }));
        }
      }
      let myPropertiesList = _.filter(
        state.GetTransfereeProperties,
        function (element) {
          return (
            element.source == "my_property" &&
            element.isinterested == true &&
            element.isactive == true
          );
        }
      );
      let ZillowBanglow = _.filter(
        state.GetTransfereeProperties,
        function (element) {
          return (
            element.source != "my_property" && element.source != "SA_Properties"
          );
        }
      );
      let SaProperties = _.filter(
        state.GetTransfereeProperties,
        function (element) {
          return (
            element.source == "SA_Properties" &&
            element.isinterested == true &&
            element.isactive == true
          );
        }
      );
      console.log(ZillowBanglow, "ZillowBanglow");

      if (_isMounted) {
        await setState((prev) => ({
          ...prev,
          saProperties: SaProperties,
          myPropertiesList: myPropertiesList,
          ZillowBanglow: ZillowBanglow,
          propertyLoader: false,
        }));
      }

      if (_isMounted) {
        await setState((prev) => ({ ...prev, isShowfavProperties: true }));
      }
    };
    fetch();
  }, []);

  const updateCarouselPosition1 = (object) => {
    if (object.item.index != state.startPosition) {
      setState((prev) => ({ ...prev, startPosition1: object.item.index }));
    }
  };
  const updateCarouselPosition2 = (object) => {
    if (object.item.index != state.startPosition) {
      setState((prev) => ({ ...prev, startPosition2: object.item.index }));
    }
  };

  const checkboxvalue = async (row, e) => {
    e.persist();
    let Ids = state.propertyIds;
    if (e.target.checked) {
      Ids.push(row.propertyuniqueId);
    } else {
      if (Ids.indexOf(row.propertyuniqueId) > -1) {
        Ids.splice(state.propertyIds.indexOf(row.propertyuniqueId), 1);
      }
    }
    console.log(Ids);
    await setState((prev) => ({ ...prev, propertyIds: Ids }));
  };

  return (
    <div className="properties_list_owl-carousel" id="myModalService_propery">
      {state.ZillowBanglow.length > 0 && <div className="list-divider"></div>}
      <div className="row">
        {state.myPropertiesList.length > 0 && (
          <div className="col-md-12 mt-3">
            <div className="p_list">
              <h1> My Rentals </h1>
              <ul>
                <OwlCarousel
                  className="owl-theme"
                  dots={false}
                  nav
                  items={state.tabItems}
                  startPosition={state.startPosition1}
                  onDragged={(object) => updateCarouselPosition1(object)}
                  onTranslated={(object) => updateCarouselPosition1(object)}
                >
                  {state.myPropertiesList.map((row, index) => {
                    return (
                      <li key={"myproperty-li" + index}>
                        <input
                          type="checkbox"
                          id={"mybox" + index}
                          onChange={(e) => checkboxvalue(row, e)}
                        />
                        <label htmlFor={"mybox" + index}>
                          <img
                            key={"myproperty-img" + index}
                            src={
                              row.property_image != null &&
                              row.property_image != ""
                                ? row.property_image.split(",")[0]
                                : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
                            }
                          />
                          <p>{row.address}</p>
                        </label>
                      </li>
                    );
                  })}
                </OwlCarousel>
              </ul>
            </div>
          </div>
        )}
        {state.saProperties.length > 0 && (
          <div className="col-md-12 mt-3">
            <div className="p_list">
              <h1> SA Rentals </h1>
              <ul>
                <OwlCarousel
                  className="owl-theme"
                  dots={false}
                  nav
                  items={state.tabItems}
                  startPosition={state.startPosition2}
                  onDragged={(object) => updateCarouselPosition2(object)}
                  onTranslated={(object) => updateCarouselPosition2(object)}
                >
                  {state.saProperties.map((row, index) => {
                    return (
                      <li key={"saproperty-li" + index}>
                        <input
                          type="checkbox"
                          id={"sabox" + index}
                          onChange={(e) => checkboxvalue(row, e)}
                        />
                        <label htmlFor={"sabox" + index}>
                          <img
                            key={"saproperty-img" + index}
                            src={
                              row.property_image != null &&
                              row.property_image != "" &&
                              row.property_image != undefined
                                ? row.property_image.split(",")[0]
                                : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
                            }
                          />
                          <p>{row.address}</p>
                        </label>
                      </li>
                    );
                  })}
                </OwlCarousel>
              </ul>
            </div>
          </div>
        )}
      </div>
      {state.myPropertiesList.length > 0 && (
        <div className="list-divider"></div>
      )}
    </div>
  );
};
export default SchedulesSearchProperties;
