import ButtonComponent from "../components/buttonComponent";
import CheckBoxComponent from "../components/checkBoxComponent";
import DatePickerComponent from "../components/datePickerComponent";
import InputComponent from "../components/inputComponent";
import NewSelectComponent from "../components/newSelectComponent";
import RadioButtonComponent from "../components/radioButtonComponent";
import RangeComponent from "../components/rangeComponent";
import SelectComponent from "../components/selectComponent";

function SelectInputComponent(props) {
  const {
    questionList,
    state,
    questionIndex,
    setLocation,
    showAnnualMonthlyRent,
    onChange,
    handleChangeStart,
    setDie,
  } = props;
  if (
    questionList.type == "input" &&
    questionList.questionlabel != "WORK ADDRESS" &&
    questionList.questionlabel != "Current living address"
  ) {
    return (
      <SelectComponent
        state={state}
        key={"sub" + questionIndex}
        questionList={questionList}
        questionIndex={questionIndex}
        onChange={onChange}
      />
    );
  } else if (
    questionList.type == "input" &&
    questionList.questionlabel == "WORK ADDRESS"
  ) {
    return (
      <InputComponent
        state={state}
        key={"sub" + questionIndex}
        questionList={questionList}
        questionIndex={questionIndex}
        setLocation={setLocation}
      />
    );
  } else if (
    questionList.type == "input" &&
    questionList.questionlabel == "Current living address"
  ) {
    return (
      <InputComponent
        state={state}
        key={"sub" + questionIndex}
        questionList={questionList}
        questionIndex={questionIndex}
        setLocation={setLocation}
      />
    );
  } else if (
    questionList.type == "options" &&
    questionList.questionlabel != "ACTIVITIES" &&
    questionList.questionlabel != "BEDROOMS" &&
    questionList.questionlabel != "MOST-HAVES"
  ) {
    return (
      <ButtonComponent
        state={state}
        key={"sub" + questionIndex}
        questionList={questionList}
        questionIndex={questionIndex}
        onChange={onChange}
      />
    );
  } else if (
    questionList.type == "options" &&
    questionList.questionlabel == "ACTIVITIES" &&
    questionList.questionlabel != "MOST-HAVES"
  ) {
    return (
      <ButtonComponent
        state={state}
        key={"sub" + questionIndex}
        questionList={questionList}
        questionIndex={questionIndex}
        onChange={onChange}
      />
    );
  } else if (
    questionList.type == "options" &&
    questionList.questionlabel == "MOST-HAVES"
  ) {
    return (
      // <ButtonComponent
      //   state={state}
      //   key={"sub" + questionIndex}
      //   questionList={questionList}
      //   questionIndex={questionIndex}
      //   onChange={onChange}
      // />
      <NewSelectComponent
        state={state}
        key={"sub" + questionIndex}
        questionList={questionList}
        questionIndex={questionIndex}
        onChange={onChange}
      />
    );
  } else if (
    questionList.type == "options" &&
    questionList.questionlabel == "BEDROOMS" &&
    questionList.questionlabel != "MOST-HAVES"
  ) {
    return (
      <ButtonComponent
        state={state}
        key={"sub" + questionIndex}
        questionList={questionList}
        questionIndex={questionIndex}
        onChange={onChange}
      />
    );
  } else if (
    questionList.type == "RadioButtons" &&
    questionList.questionlabel == "Do you have a pet?" &&
    questionList.questionlabel != "MOST-HAVES"
  ) {
    return (
      <ButtonComponent
        state={state}
        key={"sub" + questionIndex}
        questionList={questionList}
        questionIndex={questionIndex}
        onChange={onChange}
      />
      // <RadioButtonComponent
      //   state={state}
      //   key={"sub" + questionIndex}
      //   questionList={questionList}
      //   questionIndex={questionIndex}
      //   onChange={onChange}
      // />
    );
  } else if (
    questionList.type == "RadioButtons" &&
    questionList.questionlabel == "School going Children?" &&
    state.showCheck == true &&
    questionList.questionlabel != "MOST-HAVES"
  ) {
    return (
      <ButtonComponent
        state={state}
        key={"sub" + questionIndex}
        questionList={questionList}
        questionIndex={questionIndex}
        onChange={onChange}
      />
      // <RadioButtonComponent
      //   state={state}
      //   key={"sub" + questionIndex}
      //   questionList={questionList}
      //   questionIndex={questionIndex}
      //   onChange={onChange}
      // />
    );
  } else if (questionList.type == "Time-range") {
    return (
      <RangeComponent
        state={state}
        key={"sub" + questionIndex}
        questionList={questionList}
        questionIndex={questionIndex}
        onChange={onChange}
      />
    );
  } else if (questionList.type == "money-range") {
    return (
      <RangeComponent
        state={state}
        key={"sub" + questionIndex}
        questionList={questionList}
        questionIndex={questionIndex}
        showAnnualMonthlyRent={showAnnualMonthlyRent}
        onChange={onChange}
      />
    );
  } else if (questionList.type == "DatePicker") {
    return (
      <DatePickerComponent
        state={state}
        key={"sub" + questionIndex}
        questionList={questionList}
        questionIndex={questionIndex}
        handleChangeStart={handleChangeStart}
      />
    );
  } else if (questionList.type == "CheckBox") {
    return (
      <CheckBoxComponent
        state={state}
        key={"sub" + questionIndex}
        questionList={questionList}
        questionIndex={questionIndex}
        setDie={setDie}
      />
    );
  }
}

export default SelectInputComponent;
