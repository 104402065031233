import React, { useState, useEffect } from "react";
// import "../../../common/header/Sidebar.css";
import { Link } from "react-router-dom";

import { Menu } from "antd";
const { SubMenu } = Menu;

const Header = () => {
  const [state, setState] = useState({
    profileMenu: "",
    loggedIn: 0,
    getSessionData: {
      userFirstName: "",
      userLastName: "",
    },
    openKeys: ["sub1"],
  });
  return (
    <div>
      <div className="fixed-top ">
        <div className="navbar navbar-expand-lg dssolonavbar">
          <div className="container-fluid justify-content-between pr-0">
            <Link className="navbar-brand" to="/ConsultantDashboard">
              {" "}
              <img
                src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/ReloNavigator_Logo.png"
                width="100%"
              />{" "}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#collapsibleNavbar"
            >
              <span className="navbar-toggler-icon">
                {" "}
                {/* <FontAwesomeIcon icon={faBars} />{" "} */}
              </span>
            </button>
            {location.pathname != "/ConsultantScheduless" ||
            location.pathname != "/Consultantss" ? (
              <div className="collapse navbar-collapse" id="collapsibleNavbar">
                <ul className="navbar-nav m-auto">
                  <li className="nav-item">
                    <Link
                      className={
                        location.pathname == "/ConsultantDashboard"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/ConsultantDashboard"
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        location.pathname == "/ConsultantSchedules"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/ConsultantSchedules"
                    >
                      Schedules
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a
                      href="/Consultant"
                      className={
                        location.pathname == "/Consultant"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      {" "}
                      CHAT{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/ConsultantTransfereeList"
                      className={
                        location.pathname == "/ConsultantTransfereeList"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      TRANSFEREES LIST
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/Properties"
                      className={
                        location.pathname == "/Properties"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      {" "}
                      PROPERTIES
                    </Link>
                  </li>
                  <li className="dropdown nav-item nav-item-reports">
                    <a
                      className={
                        location.pathname == "/consultanttransfereereports" ||
                        location.pathname == "/consultantappointementreports" ||
                        location.pathname == "/consultantreports"
                          ? "nav-link active dropdown-toggle"
                          : "nav-link dropdown-toggle"
                      }
                      data-toggle="dropdown"
                    >
                      Reports
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to="/consultantreports">
                          SignUp Report
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/consultantappointementreports"
                        >
                          Appointment Report
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/consultanttransfereereports"
                        >
                          Transferee Reports
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>

                <ul className="navbar-nav consultant-icon">
                  <li className="nav-item dropdown">
                    {/* <div className="dropdown-menu" aria-labelledby="navbarDropdown"> */}

                    <Menu
                      mode="inline"
                      //   onOpenChange={this.onOpenChange}
                      style={{ width: 256 }}
                    >
                      <SubMenu
                        key="sub1"
                        title={
                          <span>
                            <span>
                              <i className="fa fa-user-circle-o"></i>
                            </span>
                          </span>
                        }
                      >
                        <Menu.Item key="1">
                          <a
                            className="dropdown-item"
                            // onClick={this.profileNavigate}
                          >
                            Profile
                          </a>
                        </Menu.Item>
                        <Menu.Item key="2">
                          <a
                            className="dropdown-item"
                            // onClick={this.logoutFunc}
                          >
                            Logout
                          </a>
                        </Menu.Item>
                      </SubMenu>{" "}
                    </Menu>

                    {/* </div> */}
                  </li>
                </ul>
              </div>
            ) : null}
            <ul className="navbar-nav ml-auto login-ul">
              <li className="nav-item">
                <Link className="nav-link" to="/login">
                  Login
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="nav_bottom_height"></div>
    </div>
  );
};

export default Header;
