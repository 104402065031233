import React from "react";

function ShowDetailsPopUp(props) {
  const { closeDetailsPopUp, state } = props;
  return (
    <div className="modal-dialog modal-lg ">
      <div className="modal-content  p-4 p-md-5">
        <div className="only_close">
          <span
            className="pointer close"
            data-dismiss="modal"
            onClick={closeDetailsPopUp}
          >
            &times;
          </span>
        </div>
        <h6 className="title">Appointment Details {state.consultantName} </h6>

        <div className="modal-body px-md-5">
          <div className="ul_select_list text_overfloww">
            <span>Appointment Type : </span>
            <span className="main_span"> {state.EditEvent}</span>
          </div>
          <ul className="appoint_list">
            <li>
              <a href="javascript:void(0)">
                {" "}
                Date <span>{state.popupDate}</span>{" "}
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                {" "}
                Time{" "}
                <span>
                  {state.startTime} - {state.endTime}
                </span>{" "}
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                {" "}
                Total Hours <span>{state.diffTime} Hours</span>{" "}
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                {" "}
                Day <span>{state.dayNameLabel[state.dayName]}</span>{" "}
              </a>
            </li>
          </ul>
          <div className="ul_select_list text_overfloww mt-3">
            <span>Pickup Location : </span>
            <span className="main_span"> {state.pickupLocation}</span>
          </div>
          <div className="ul_select_list text_overfloww">
            <span>Notes : </span>
            <span className="main_span"> {state.serviceNote}</span>
          </div>
        </div>
        <div className="px-md-5">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  Transferee Details
                </button>
              </h2>

              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <div className="form-group form-group2">
                    <label style={{ color: "#a7a7a7" }}>Name:</label>
                    <p>
                      {state.transfareefirstname} {state.transfareelastname}
                    </p>
                  </div>
                  <div className="form-group form-group2">
                    <label style={{ color: "#a7a7a7" }}>Email</label>
                    <p>{state.transfareeemailId}</p>
                  </div>
                  <div className="form-group form-group2">
                    <label style={{ color: "#a7a7a7" }}>Location</label>
                    <p>{state.pickupLocation}</p>
                  </div>
                  <div className="form-group form-group2">
                    <label style={{ color: "#a7a7a7" }}>Notes</label>
                    <p>{state.serviceNote}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowDetailsPopUp;
