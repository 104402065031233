// import "../myProperties/myProperties.css";
import ApiServices from "../../../../services/apiServices";
import LoginStore from "../../../../services/thirdPartyService";
import { Spin, Popover } from "antd";
import * as $ from "jquery";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import _ from "lodash";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";

const SaProperties = (props) => {
  var _isMounted = false;
  const [state, setState] = useState({
    cityLoader: false,
    latitude: "",
    answersPrefer: [],
    longitude: "",
    nearByCitiesWithStates: [],
    nearByCities: [],
    workLocationAddress: "",
    updatedDate: "",
    propertyName: "",
    propertyLink: "",
    address: "",
    propertyNotes: "",
    loader: false,
    latitude: "",
    longitude: "",
    isPropertyError: false,
    userUniqueId: "",
    errorMessage: "",
    propertySuccess: false,
    saPropertiesList: "",
    propertyLoader: true,
    state: "",
    city: "",
    country: "",
    EditMoveDate: null,
    uploadImages: [],
    uploadLoader: false,
    availableTillDate: null,
    modalpopupInfo: {},
    showModal: false,
    saPropertiesListInactive: [],
  });
  const navigate = useNavigate();
  const activeProperty = async (row) => {
    confirmAlert({
      title: "",
      message: "Please confirm that you are interested in this rental option?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            confirmActive(row);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  const confirmActive = async (row) => {
    let propertyObjectDelete2 = {
      transfereeuniqueId: state.userUniqueId,
      propertyuniqueId: row.propertyuniqueId,
      propertyanalyticsuniqueId: row.propertyanalyticsuniqueId,
      isdelete: false,
      isactiverents: true,
    };

    let propertyObjectDelete = {
      propertyanalyticsuniqueId: row.propertyanalyticsuniqueId,
      transfereeuniqueId: state.userUniqueId,
      apiId: row.apiId,
      source: row.source,
      city: row.city,
      isdelete: false,
      propertyname: row.propertyname,
      address: row.address,
      state: row.state,
      latitude: row.latitude,
      longitude: row.longitude,
      propertylink: row.propertylink,
      notes: row.notes,
      isIncluded: false,
      amenities: row.amenities,
      monthlyrent: row.monthlyrent,
      hometype: row.hometype,
      isDelete: false,
      isactive: row.isactive,
      bedrooms: row.bedrooms,
      bathrooms: row.bathrooms,
      unitnumber: row.unitnumber,
      flooring: row.flooring,
      sqfeet: row.sqfeet,
      virtual360tour: row.virtual360tour,
      property_image: row.property_image,
      available_till_date: row.available_till_date,
      isinterested: true,
    };

    let addPropertyResultDelete = await ApiServices.UpdateProperty(
      propertyObjectDelete
    );
    await setState((prevState) => ({
      ...prevState,
      isactiverents: true,
    }));
    if (addPropertyResultDelete.error == 0) {
      getMyProperties();
    }
    setTimeout(async () => {
      await setState((prevState) => ({
        ...prevState,
        isactiverents: false,
      }));
    }, 3000);
  };
  const deleteConfirmation = async (row) => {
    confirmAlert({
      title: "",
      message: "Are you sure you are not interested in this rental option?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteSaProperties(row),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const deleteSaProperties = async (row) => {
    let propertyObjectDelete2 = {
      transfereeuniqueId: row.transfereeUId,
      propertyuniqueId: row.propertyuniqueId,
      propertyanalyticsuniqueId: row.propertyanalyticsuniqueId,
      isdelete: true,
      isDelete: true,
    };

    let propertyObjectDelete = {
      propertyanalyticsuniqueId: row.propertyanalyticsuniqueId,
      transfereeuniqueId: state.userUniqueId,
      apiId: row.apiId,
      source: row.source,
      city: row.city,
      isdelete: false,
      propertyname: row.propertyname,
      address: row.address,
      state: row.state,
      latitude: row.latitude,
      longitude: row.longitude,
      propertylink: row.propertylink,
      notes: row.notes,
      isIncluded: false,
      amenities: row.amenities,
      monthlyrent: row.monthlyrent,
      hometype: row.hometype,
      isDelete: false,
      isactive: row.isactive,
      bedrooms: row.bedrooms,
      bathrooms: row.bathrooms,
      unitnumber: row.unitnumber,
      flooring: row.flooring,
      sqfeet: row.sqfeet,
      virtual360tour: row.virtual360tour,
      property_image: row.property_image,
      available_till_date: row.available_till_date,
      isinterested: false,
    };

    let addPropertyResultDelete = await ApiServices.UpdateProperty(
      propertyObjectDelete
    );
    setState((prevState) => ({
      ...prevState,
      isDelete: true,
    }));
    if (addPropertyResultDelete.error == 0) {
      sendEmail(row);
    }
    getMyProperties();
    setTimeout(async () => {
      await setState((prevState) => ({
        ...prevState,
        isDelete: false,
      }));
    }, 3000);
  };
  const sendEmail = async (row) => {
    let getSessionData = await LoginStore.getLoginInfo({});

    let data = {
      name: getSessionData.userFirstName + "" + getSessionData.userLastName,
      consultantname: getSessionData.consultantname,
      notInterested: true,
      transfereeEmail: getSessionData.EmailId,
      email: getSessionData.consultantemail,
      propertyname: row.propertyname,
      unitnumber: row.unitnumber,
      size: row.size,
      monthlyrent: row.monthlyrent,
      subject: "Transferee rental listing feedback",
      description: "Transferee is not interested in the below Rental details",
    };
    let sendSms = await LoginStore.sendEmail(data);
  };

  const updateDate = async (date) => {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
    if (_isMounted) {
      await setState((prev) => ({
        ...prev,
        updatedDate: day + " " + monthNames[monthIndex] + " " + year,
      }));
    }
    return day + " " + monthNames[monthIndex] + " " + year;
  };
  const getMyProperties = async () => {
    // _isMounted = true;
    // if (_isMounted) {
    // console.log("mounted is true");
    await setState((prevState) => ({ ...prevState, propertyLoader: true }));
    // }
    let getSessionData = await LoginStore.getLoginInfo({});
    let GetTransfereePropertiesObject = {
      transfereeuniqueId: getSessionData.userUniqueId,
    };
    let GetTransfereeProperties = await ApiServices.GetTransfereeProperties(
      GetTransfereePropertiesObject
    );
    // console.log(GetTransfereeProperties, "GetTransfereeProperties");
    if (GetTransfereeProperties.error == 0) {
      let saPropertiesList = _.filter(
        GetTransfereeProperties.message.transfereepropertyList,
        function (element) {
          if (element.propertyjson != null && element.propertyjson != "") {
            // console.log(element, "element");
            element.property_image =
              JSON.parse(element.propertyjson).Photo != null
                ? "https://s3-us-west-1.amazonaws.com/destination-services-itinerary/" +
                  JSON.parse(element.propertyjson).Photo +
                  ".jpg"
                : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png";
          }
          return (
            element.source == "SA_Properties" &&
            element.isinterested == true &&
            element.isactive == true &&
            element.apiId != ""
          );
        }
      );
      // console.log(saPropertiesList, "saPropertiesList first");
      let saPropertiesListInactive = _.filter(
        GetTransfereeProperties.message.transfereepropertyList,
        function (element) {
          return (
            element.source == "SA_Properties" &&
            element.isinterested == false &&
            element.isactive == true &&
            element.apiId != ""
          );
        }
      );
      // console.log(saPropertiesListInactive, "saPropertiesListInactive");
      // if (_isMounted) {
      await setState((prevState) => ({
        ...prevState,
        saPropertiesList: saPropertiesList,
        saPropertiesListInactive: saPropertiesListInactive,
        propertyLoader: false,
      }));
      // }
    }
  };
  useEffect(() => {
    _isMounted = true;
    const fetch = async () => {
      updateDate(new Date());
      let getSessionData = await LoginStore.getLoginInfo({});
      if (_isMounted) {
        setState((prev) => ({
          ...prev,
          userUniqueId: getSessionData.userUniqueId,
        }));
      }
      // console.log(state.userUniqueId, "tutu");
      // console.log(_isMounted, "iou");
      setState((prev) => ({
        ...prev,
        workAddress: getSessionData.worklocationaddress1,
      }));
      let userObject = {
        transfereeuniqeuid: getSessionData.userUniqueId,
        istemporary: getSessionData.istemporary,
      };
      let getQuestionList = await ApiServices.GetPreferencesList(userObject);
      let userPreferanceObject = {
        transfereeuniqeuid: getSessionData.userUniqueId,
        istemporary: getSessionData.istemporary,
      };

      let userSavedPreferances = await ApiServices.GetTransfereePreferences(
        userPreferanceObject
      );
      if (userSavedPreferances.error == 0) {
        let userAnswerPreferanceObject =
          userSavedPreferances.message.preferences;
        if (userAnswerPreferanceObject != undefined) {
          let answersPrefer = [];
          _.forEach(userAnswerPreferanceObject, function (answerObject) {
            answersPrefer[answerObject.preferencesmasteruniqueid] =
              answerObject;
          });
          await setState((prev) => ({ ...prev, answersPrefer: answersPrefer }));
        }
      }
      if (getQuestionList.message.preferencesList != undefined) {
        let questionList = getQuestionList.message.preferencesList;
        let workAddressArray = _.filter(questionList, function (o) {
          return o.question == "Work address";
        });
        await localStorage.setItem(
          "workAddress",
          JSON.stringify(state.answersPrefer[workAddressArray[0].preferenceUId])
        );
      }
      getMyProperties();
    };
    fetch();
    return () => {
      _isMounted = false;
    };
  }, []);
  useEffect(
    (nextProps) => {
      if (props.PrioritiesStatus == "priorties") {
        getMyProperties();
      }
    },
    [props.PrioritiesStatus]
  );
  const getMoreInfo = async (row) => {
    let GetTransfereePropertiesObject = {
      transfereeuniqueId: row.transfereeUId,
      propertyuniqueId: row.propertyuniqueId,
    };
    let GetTransfereeProperties = await ApiServices.GetTransfereeProperties(
      GetTransfereePropertiesObject
    );
    if (GetTransfereeProperties.error == 0) {
      if (GetTransfereeProperties.message != undefined) {
        if (
          GetTransfereeProperties.message.transfereepropertyList[0] != undefined
        ) {
          let propertyDetails =
            GetTransfereeProperties.message.transfereepropertyList[0];
          (row.pdffilepath = propertyDetails.pdffilepath),
            (row.pdfupdatedat = propertyDetails.pdfupdatedat),
            await localStorage.setItem(
              "myPropertyDetails",
              JSON.stringify(row)
            );
          // window.open("/my_property_details");
          navigate("/my_property_details");
        }
      }
    }
  };
  const moreInfoWithDetails = async (row) => {
    $("document").ready(function () {
      $("#not-interested-community-Modal").modal("hide");
    });
    await getMoreInfo(row);
  };

  const changeActiveImg = (id) => {
    $(".thumb").removeClass("active");
    $(".carousel-item").removeClass("active");
    $("#MIG" + id).addClass("active");
    $("#thumb" + id).addClass("active");
  };
  const handleDateChange = async (editDate) => {
    await setState((prev) => ({
      ...prev,
      EditMoveDate: new Date(editDate),
      availableTillDate: moment(editDate).format("YYYY/MM/DD"),
    }));
  };
  const openNoteInterested = () => {
    $("document").ready(function () {
      $("#not-interested-community-Modal").modal("show");
    });
  };
  const closeNoteInterested = () => {
    $("document").ready(function () {
      $("#not-interested-community-Modal").modal("hide");
    });
  };

  return (
    <div className="my_properties_lists  mt-5 pt-4 ">
      <h1 className="h1">
        Recommended Rentals
        {state.saPropertiesListInactive &&
        state.saPropertiesListInactive.length > 0 ? (
          <a className="inactiveRentals" onClick={() => openNoteInterested()}>
            Not Interested Rentals
          </a>
        ) : null}
      </h1>
      {state.isDelete == true ? (
        <div>
          <div className="floating-label">
            <h6
              style={{
                //display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // height: "100vh",
              }}
              className="alert alert-success mt-3"
            >
              Rental has been moved to not interested section
            </h6>
          </div>
        </div>
      ) : (
        ""
      )}
      {/** onClick={() => moreInfoWithDetails(row)}  */}
      {state.saPropertiesList && state.saPropertiesList.length > 0 ? (
        <div className="main_grid">
          {_.sortBy(state.saPropertiesList, function (obj) {
            return parseInt(obj.propertyrankitems.matchpercent, 10);
          })
            .reverse()
            .map((row, index) => {
              return row.isinterested == true ? (
                <div className="item" key={index}>
                  <span className="ScoreLabel">
                    <Popover
                      placement="leftTop"
                      content={
                        <div>
                          {row.propertyrankitems.propertyrankingitems.map(
                            (Pitem) => {
                              return (
                                <p
                                  key={"mmys2" + index + Pitem.prioritylistitem}
                                >
                                  {Pitem.prioritylistitem == "Must Haves" ? (
                                    <Popover
                                      placement="leftTop"
                                      content={
                                        <div>
                                          {Pitem.matcheditems.map(
                                            (matcheditem) => {
                                              return (
                                                matcheditem.itemname != "" && (
                                                  <p
                                                    key={
                                                      "mmys2pps" +
                                                      index +
                                                      matcheditem.itemname
                                                    }
                                                  >
                                                    {matcheditem.itemname}
                                                    {matcheditem.ismatched ==
                                                      true && (
                                                      <i className="fa fa-check"></i>
                                                    )}
                                                    {matcheditem.ismatched ==
                                                      false && (
                                                      <i className="fa fa-times"></i>
                                                    )}
                                                  </p>
                                                )
                                              );
                                            }
                                          )}
                                        </div>
                                      }
                                      title="Matched Must Haves"
                                      trigger="hover"
                                    >
                                      {Pitem.prioritylistitem}{" "}
                                      <i
                                        className="fa fa-info-circle"
                                        style={{ color: "#000000" }}
                                      ></i>
                                    </Popover>
                                  ) : (
                                    Pitem.prioritylistitem
                                  )}
                                  {Pitem.ismatched == true &&
                                    Pitem.isallmatched == false && (
                                      <i className="fa fa-check yellow_clr"></i>
                                    )}
                                  {Pitem.ismatched == true &&
                                    Pitem.isallmatched == true && (
                                      <i className="fa fa-check"></i>
                                    )}
                                  {Pitem.ismatched == true &&
                                    Pitem.isallmatched == null && (
                                      <i className="fa fa-check"></i>
                                    )}
                                  {Pitem.ismatched == false && (
                                    <i className="fa fa-times"></i>
                                  )}
                                </p>
                              );
                            }
                          )}
                        </div>
                      }
                      title="Matched Priorities"
                      trigger="hover"
                    >
                      {Math.trunc(row.propertyrankitems.matchpercent)}%
                    </Popover>
                  </span>
                  <input
                    type="checkbox"
                    id={"sa2myCheckbox" + index}
                    onChange={() => props.compareFunc(row)}
                    checked={
                      props.compareProperties[row.propertyanalyticsuniqueId] !=
                      undefined
                        ? true
                        : false
                    }
                  />
                  <label htmlFor={"sa2myCheckbox" + index}>
                    <img
                      src={
                        row.property_image != null &&
                        row.property_image != "" &&
                        row.property_image != undefined
                          ? row.property_image.split(",")[0]
                          : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
                      }
                      alt=""
                    />

                    <div className="content">
                      <a
                        href="javascript:void(0)"
                        onClick={() => moreInfoWithDetails(row)}
                      >
                        {row.propertyname}
                        <i class="fa-regular fa-arrow-up-right"></i>{" "}
                      </a>
                      <p>
                        {row.address}
                        {/* {row.city} {row.state} */}
                      </p>

                      <span>
                        <a
                          href="javascript:void(0)"
                          onClick={() => deleteConfirmation(row)}
                        >
                          <i class="fa-regular fa-thumbs-down"></i>
                          {/* <i className="fa fa-thumbs-down"></i> */}
                        </a>{" "}
                        {/* <a
                          href="javascript:void(0)"
                          className="linkColor"
                          onClick={() => moreInfoWithDetails(row)}
                        >
                          More
                        </a>{" "} */}
                      </span>
                    </div>
                  </label>
                </div>
              ) : null;
            })}
        </div>
      ) : state.propertyLoader ? (
        <div className="min-height-500 divSpin">
          <Spin />
        </div>
      ) : (
        <div className="main_grid_new empty-pro">
          We have received your Home Preferences. We are working on providing
          the best rentals that match your criteria. Please check back later.
        </div>
      )}

      {/** details my property */}
      {state.showModal && (
        <div
          className="fade modal property_rrpopup propertyDetailsPopup"
          id="moreInfoModal"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="position-relative">
                      <div
                        id="carouselExampleFade"
                        className="carousel slide carousel-fade"
                        data-ride="carousel"
                      >
                        <div className="carousel-inner">
                          {state.modalpopupInfo.images.map(
                            (imgSignle, Imgindex) => {
                              return (
                                <div
                                  key={"tkey" + Imgindex}
                                  className={
                                    Imgindex == 0
                                      ? "carousel-item active"
                                      : "carousel-item "
                                  }
                                  id={"MIG" + Imgindex}
                                >
                                  <img
                                    src={
                                      imgSignle != "" && imgSignle != null
                                        ? imgSignle
                                        : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
                                    }
                                    className="d-block w-100"
                                    alt="Second slide"
                                  />
                                </div>
                              );
                            }
                          )}
                        </div>
                        <a
                          className="carousel-control-prev"
                          href="#carouselExampleFade"
                          role="button"
                          data-slide="prev"
                        >
                          <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="sr-only">Previous</span>
                        </a>
                        <a
                          className="carousel-control-next"
                          href="#carouselExampleFade"
                          role="button"
                          data-slide="next"
                        >
                          <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="sr-only">Next</span>
                        </a>
                        <ol className="carousel-indicators">
                          {state.modalpopupInfo.images.map(
                            (thumbimgSignle, thumbImgindex) => {
                              return (
                                <li
                                  data-target="#carousel-thumb"
                                  key={"thumbkey" + thumbImgindex}
                                  data-slide-to={thumbImgindex}
                                  id={"thumb" + thumbImgindex}
                                  onClick={() => changeActiveImg(thumbImgindex)}
                                  className={
                                    thumbImgindex == 0
                                      ? "thumb active"
                                      : "thumb active"
                                  }
                                >
                                  {" "}
                                  <img
                                    className="d-block w-100"
                                    src={thumbimgSignle}
                                  />
                                </li>
                              );
                            }
                          )}
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="popupmaintext">
                      <div className="form-group row">
                        <label className="col-4 col-form-label">
                          Property Name <b>:</b>{" "}
                        </label>
                        <div className="col-8">
                          <label className="cLabel-label col-form-label">
                            {state.modalpopupInfo.propertyname}
                          </label>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-4 col-form-label">
                          Address <b>:</b>{" "}
                        </label>
                        <div className="col-8">
                          <label className="cLabel-label col-form-label">
                            {state.modalpopupInfo.address}
                          </label>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-4 col-form-label">
                          Property Link <b>:</b>{" "}
                        </label>
                        <div className="col-8">
                          <label className="cLabel-label col-form-label">
                            <a
                              href={state.modalpopupInfo.propertylink}
                              target="_blank"
                            >
                              {state.modalpopupInfo.propertylink}{" "}
                            </a>
                          </label>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-4 col-form-label">
                          Available Date <b>:</b>{" "}
                        </label>
                        <div className="col-8">
                          <label className="cLabel-label col-form-label">
                            {state.modalpopupInfo.available_till_date != null
                              ? moment(
                                  state.modalpopupInfo.available_till_date
                                ).format("MM/DD/YYYY")
                              : "---"}{" "}
                          </label>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-4 col-form-label">
                          Notes <b>:</b>{" "}
                        </label>
                        <div className="col-8">
                          <label className="cLabel-label col-form-label">
                            {state.modalpopupInfo.notes}{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/** end details */}

      <div
        className="modal fade"
        id="not-interested-community-Modal"
        role="dialog"
        style={{ zIndex: 1060 }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="width-70per pd-y-60">
              <div className="only_close">
                <span
                  className="pointer close"
                  data-dismiss="modal"
                  onClick={closeNoteInterested}
                >
                  ×
                </span>
              </div>
              <h6 className="modal-title">Not Interested Rentals</h6>
              {state.isactiverents == true ? (
                <div>
                  <div className="floating-label">
                    <h6
                      style={{
                        //display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // height: "100vh",
                      }}
                      className="alert alert-success mt-3"
                    >
                      Rental has been moved to interested section
                    </h6>
                  </div>
                </div>
              ) : (
                ""
              )}
              {state.saPropertiesListInactive &&
              state.saPropertiesListInactive.length > 0 ? (
                <div className="main_grid" style={{ border: "none" }}>
                  {state.saPropertiesListInactive.map((row, index) => {
                    return row.isinterested != true ? (
                      <div className="item" key={"poup" + index}>
                        <span className="ScoreLabel">
                          <Popover
                            placement="leftTop"
                            content={
                              <div>
                                {row.propertyrankitems.propertyrankingitems.map(
                                  (Pitem) => {
                                    return (
                                      <p
                                        key={
                                          "mmysa2" +
                                          index +
                                          Pitem.prioritylistitem
                                        }
                                      >
                                        {Pitem.prioritylistitem ==
                                        "Must Haves" ? (
                                          <Popover
                                            placement="leftTop"
                                            content={
                                              <div>
                                                {Pitem.matcheditems.map(
                                                  (matcheditem) => {
                                                    return (
                                                      <p
                                                        key={
                                                          "mmysa2pps" +
                                                          index +
                                                          matcheditem.itemname
                                                        }
                                                      >
                                                        {matcheditem.itemname}
                                                        {matcheditem.ismatched ==
                                                          true && (
                                                          <i className="fa fa-check"></i>
                                                        )}
                                                        {matcheditem.ismatched ==
                                                          false && (
                                                          <i className="fa fa-times"></i>
                                                        )}
                                                      </p>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            }
                                            title="Matched Must Haves"
                                            trigger="hover"
                                          >
                                            {Pitem.prioritylistitem}{" "}
                                            <i
                                              className="fa fa-info-circle"
                                              style={{ color: "#000000" }}
                                            ></i>
                                          </Popover>
                                        ) : (
                                          Pitem.prioritylistitem
                                        )}
                                        {Pitem.ismatched == true &&
                                          Pitem.isallmatched == false && (
                                            <i className="fa fa-check yellow_clr"></i>
                                          )}
                                        {Pitem.ismatched == true &&
                                          Pitem.isallmatched == true && (
                                            <i className="fa fa-check"></i>
                                          )}
                                        {Pitem.ismatched == true &&
                                          Pitem.isallmatched == null && (
                                            <i className="fa fa-check"></i>
                                          )}
                                        {Pitem.ismatched == false && (
                                          <i className="fa fa-times"></i>
                                        )}
                                      </p>
                                    );
                                  }
                                )}
                              </div>
                            }
                            title="Matched Priorities"
                            trigger="hover"
                          >
                            {Math.trunc(row.propertyrankitems.matchpercent)}%
                          </Popover>
                        </span>
                        {/* <input type="checkbox" id={"sa2myCheckbox" + index} onClick={() => props.compareFunc(row)} /> */}
                        <label htmlFor={"sa2myCheckbox" + index}>
                          <img
                            src={
                              row.property_image != null &&
                              row.property_image != "" &&
                              row.property_image != undefined
                                ? row.property_image.split(",")[0]
                                : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
                            }
                            alt=""
                          />

                          <div className="content">
                            <p>
                              {row.propertyname} <br />
                              {row.city} {row.state}
                            </p>

                            <span>
                              <a
                                href="javascript:void(0)"
                                onClick={() => activeProperty(row)}
                              >
                                <i className="fa fa-thumbs-up"></i>
                              </a>{" "}
                              <a
                                href="javascript:void(0)"
                                className="linkColor"
                                onClick={() => moreInfoWithDetails(row)}
                              >
                                More
                              </a>{" "}
                            </span>
                          </div>
                        </label>
                      </div>
                    ) : null;
                  })}
                </div>
              ) : state.propertyLoader ? (
                <Spin />
              ) : (
                <div className="main_grid">No data available</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SaProperties;
