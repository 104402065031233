import React, { useState, useEffect } from "react";
import moment from "moment";
import ApiServices from "../../../../services/apiServices";
import LoginStore from "../../../../services/thirdPartyService";

import "./dashboard.css";

const Dashboard = () => {
  const [state, setState] = useState({
    overview: [],
    servicelevel: [],
    customerlevel: [],
  });

  async function fetchSessionData() {
    let getSessionData = await LoginStore.getLoginInfo({});
    return getSessionData;
  }

  async function fetchAndUpdateStateOverviewInfo(data) {
    const overviewInfo = await ApiServices.GetOverviewInfo(data);
    updateState({ overview: overviewInfo.overviewlist });
  }

  async function fetchAndUpdateStateServicelevelInfo(data) {
    const servicelevelInfo = await ApiServices.GetByservicelevelInfo(data);
    let servicelevellist = servicelevelInfo.servicelevellist;
    servicelevellist.forEach((valueObject) => {
      switch (valueObject.ServiceLevel) {
        case "Commander":
          valueObject.orderId = 1;
          break;
        case "Explorer":
          valueObject.orderId = 2;
          break;
        case "ReloNavigator":
          valueObject.orderId = 3;
          break;
      }
    });

    const SortStatus = servicelevellist.sort((a, b) => {
      return a.orderId - b.orderId;
    });
    updateState({ servicelevel: SortStatus });
  }

  async function fetchAndUpdateStateCustomerlevelInfo(data) {
    const customerlevelInfo = await ApiServices.GetBycustomerlevelInfo(data);
    updateState({
      customerlevel: customerlevelInfo.customerlevelList,
      pageloader: false,
    });
  }

  function loadDataForDashBoard() {
    fetchSessionData()
      .then((getSessionData) => {
        let data = {
          supplieruniqueId: getSessionData.userUniqueId,
          fromdate: moment().format("MM/DD/YYYY"),
          todate: moment().format("MM/DD/YYYY"),
        };
        fetchAndUpdateStateOverviewInfo(data);
        fetchAndUpdateStateServicelevelInfo(data);
        fetchAndUpdateStateCustomerlevelInfo(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  useEffect(() => {
    loadDataForDashBoard();
  }, []);

  return (
    <div className="dashboard-ds">
      <div className="wrapper">
        <div className="page-title-box">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="state-information  d-sm-block">
                  <div className="state-graph"></div>
                </div>

                <h4 className="page-title">Overview</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content-wrapper">
          <div className="container">
            {state.overview.length > 0
              ? state.overview.map((row, i) => {
                  return (
                    <div className="row-tails" key={i}>
                      <div className="card-body">
                        <div className="mini-stat-desc">
                          <div className="mini-stat-icon">
                            <img
                              src="assets/images/consultation.svg"
                              width="100%"
                              alt=""
                            />
                          </div>
                          <div className="text-white">
                            <h3 className="mb-1 mt-0 text-dark">
                              {row.TotalConsultants}
                            </h3>
                            <h6 className=" mt-0 mb-0 "> Consultants </h6>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="mini-stat-desc">
                          <div className="mini-stat-icon">
                            <img
                              src="assets/images/switch.svg"
                              width="100%"
                              alt=""
                            />
                          </div>
                          <div className="text-white">
                            <h3 className="mb-1 mt-0 text-dark">
                              {row.TotalActiveTransferees}
                            </h3>
                            <h6 className="mt-0 mb-0 "> Active Transferees</h6>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="mini-stat-desc">
                          <div className="mini-stat-icon">
                            <img
                              src="assets/images/clock.svg"
                              width="100%"
                              alt=""
                            />
                          </div>
                          <div className="text-white">
                            <h3 className="mb-1 mt-0 text-dark">
                              {row.TotalActualHrs != undefined &&
                              row.TotalActualHrs != null
                                ? row.TotalActualHrs.substring(
                                    0,
                                    row.TotalActualHrs.length - 3
                                  )
                                : 0}
                            </h3>
                            <h6 className=" mt-0 mb-0 "> Total Hours </h6>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="mini-stat-desc">
                          <div className="mini-stat-icon">
                            <img
                              src="assets/images/hourglass.svg"
                              width="100%"
                              alt=""
                            />
                          </div>
                          <div className="text-white">
                            <h3 className="mb-1 mt-0 text-dark">
                              {row.TotalUsedHrs != undefined &&
                              row.TotalUsedHrs != null
                                ? row.TotalUsedHrs.substring(
                                    0,
                                    row.TotalUsedHrs.length - 3
                                  )
                                : 0}
                            </h3>
                            <h6 className=" mt-0 mb-0 "> Used Hours </h6>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="mini-stat-desc">
                          <div className="mini-stat-icon">
                            <img
                              src="assets/images/hourglass.svg"
                              width="100%"
                              alt=""
                            />
                          </div>
                          <div className="text-white">
                            <h3 className="mb-1 mt-0 text-dark">
                              {row.TotalRemainingHrs != undefined &&
                              row.TotalRemainingHrs != null
                                ? row.TotalRemainingHrs.substring(
                                    0,
                                    row.TotalRemainingHrs.length - 3
                                  )
                                : 0}
                            </h3>
                            <h6 className=" mt-0 mb-0 "> Remaining Hours </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}

            <div className="page-title-box">
              <h4 className="page-title">Assignee Analysis</h4>
            </div>

            <div className="row chart-row chart-box">
              <div className="col-xl-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">By service level</h4>

                    <div className="table-responsive ct-chart">
                      <table className="table mb-0">
                        <thead className="thead-default">
                          <tr>
                            <th width="10%"> </th>
                            <th width="20%" style={{ textAlign: "center" }}>
                              Active Users
                            </th>
                            <th width="20%" style={{ textAlign: "center" }}>
                              Total hours
                            </th>
                            <th width="20%" style={{ textAlign: "center" }}>
                              Used hours
                            </th>
                            <th width="30%" style={{ textAlign: "center" }}>
                              Remaining hours
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.servicelevel.length > 0 ? (
                            state.servicelevel.map((row, i) => {
                              return (
                                <tr key={i}>
                                  <th scope="row">{row.ServiceLevel}</th>
                                  <td style={{ textAlign: "center" }}>
                                    {row.TotalActiveTransferees}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {row.TotalActualHrs != null &&
                                    parseInt(row.TotalActualHrs) > 0
                                      ? row.TotalActualHrs.substring(
                                          0,
                                          row.TotalActualHrs.length - 3
                                        )
                                      : "0"}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {row.TotalUsedHrs != null
                                      ? row.TotalUsedHrs.substring(
                                          0,
                                          row.TotalUsedHrs.length - 3
                                        )
                                      : "0"}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {row.TotalRemainingHrs != null
                                      ? row.TotalRemainingHrs.substring(
                                          0,
                                          row.TotalRemainingHrs.length - 3
                                        )
                                      : "0"}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="5">No data</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row chart-row credit-card">
              <div className="col-xl-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="mt-0 header-title mb-1">
                      By customer level{" "}
                    </h4>

                    <div className="table-responsive ct-chart table2">
                      <table className="table mb-0">
                        <thead className="thead-default">
                          <tr>
                            <th width="10%"> </th>
                            <th width="20%" style={{ textAlign: "center" }}>
                              Active Users
                            </th>
                            <th width="20%" style={{ textAlign: "center" }}>
                              Total hours
                            </th>
                            <th width="20%" style={{ textAlign: "center" }}>
                              Used hours
                            </th>
                            <th width="20%" style={{ textAlign: "center" }}>
                              Remaining hours
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.customerlevel.length > 0 ? (
                            state.customerlevel.map((row, i) => {
                              return (
                                <tr key={i}>
                                  <th scope="row">{row.CompanyName}</th>
                                  <td style={{ textAlign: "center" }}>
                                    {row.TotalActiveTransferees}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {row.TotalActualHrs != null
                                      ? row.TotalActualHrs.substring(
                                          0,
                                          row.TotalActualHrs.length - 3
                                        )
                                      : "0"}
                                  </td>

                                  <td style={{ textAlign: "center" }}>
                                    {row.TotalUsedHrs != null
                                      ? row.TotalUsedHrs.substring(
                                          0,
                                          row.TotalUsedHrs.length - 3
                                        )
                                      : "0"}{" "}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {row.TotalRemainingHrs != null
                                      ? row.TotalRemainingHrs.substring(
                                          0,
                                          row.TotalRemainingHrs.length - 3
                                        )
                                      : "0"}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="5">No data</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
