import React, { useEffect, useState } from "react";

import jData from "./data.json";
// import "./getStarted.css";
import LoginStore from "../../../../services/thirdPartyService";
import ApiServices from "../../../../services/apiServices";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import _ from "lodash";
import * as $ from "jquery";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import Tooltip from "rc-tooltip";
import Slider from "rc-slider";
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
import { Select, Modal, Spin } from "antd";
const { Option } = Select;
import { confirmAlert } from "react-confirm-alert";
// import Modal from "react-modal";
import AppController from "../../../controllers/appController";
import Header from "../../../common/header/Header";
import Footer from "../../../common/footer/Footer";
import BudgetPopUp from "./modalPopUp/budgetPopUp";
import SubmitPopUp from "./modalPopUp/submitPopUp";
import DragDrop from "./components/dragDropComponent";
import ButtonComponent from "./components/buttonComponent";
import SelectInputComponent from "./utils/selectInputComponent";
import aiAssistant from "../../../../services/aiAssistant";
import NewDragDrop from "./components/newdragDropComponent";

function GetStarted() {
  const [state, setState] = useState({
    jData: _.sortBy(jData.data, ["name"]),
    questions: [],
    getQuestionListStatus: false,
    answersPrefer: [],
    draganswersPrefer: [],
    getSessionData: {},
    listOfProperties: [],
    preferencesanswer: [],
    isSharedLiving: false,
    isError: false,
    checkError: false,
    DragOptionsCheck: "",
    toolTipText: "Please fill this preference!",
    faIconsList: {
      ENVIRONMENT: "faBuilding",
      ACTIVITIES: "faBicycle",
      "HOME TYPE": "faHome",
      KEYWORDS: "faKeyboard",
      BEDROOMS: "faBed",
      "MUST-HAVES": "faClipboardList",
      DEFAULT: "faBuilding",
    },
    activitiesArray: [],
    keyWordsArray: [],
    activitiesError: false,

    autofield: "",
    showCheck: false,
    isgetStartedFirst: false,
    supplieruid: "",
    HouseMonthlyRent: "",
    submitPopUp: false,
    options: [],
    pageLoader: false,
  });
  const [rightItemsFromChild, setRightItemsFromChild] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      try {
        if (
          localStorage.getItem("getStartedFirst") !== null &&
          localStorage.getItem("getStartedFirst") !== undefined
        ) {
          setState((prevState) => ({ ...prevState, isgetStartedFirst: true }));
        }
        // await getTransfareeData();
        await getBasincInfo();
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  }, []);

  const handleRightItemsUpdate = (updatedRightItems) => {
    // console.log(updatedRightItems, "updatedrightitems");
    setRightItemsFromChild(updatedRightItems);
  };

  const getBasincInfo = async () => {
    // console.log("getBasic data");
    let getSessionData = await LoginStore.getLoginInfo({});
    let userObject = {
      transfereeuniqeuid: getSessionData.userUniqueId,
      istemporary: getSessionData.istemporary,
    };
    let getQuestionList = await ApiServices.GetPreferencesList(userObject);
    await AppController.logData(
      "My Preferences",
      "My Preferences",
      location.pathname,
      getSessionData.userUniqueId,
      ""
    );
    setState((prevState) => ({
      ...prevState,
      isSharedLiving: getSessionData.issharedliving,
      getSessionData: getSessionData,
    }));
    // console.log(getQuestionList, "getQuestionList");
    let userPreferanceObject = {
      transfereeuniqeuid: getSessionData.userUniqueId,
      istemporary: getSessionData.istemporary,
    };

    let userSavedPreferances = await ApiServices.GetTransfereePreferences(
      userPreferanceObject
    );
    if (userSavedPreferances.error == 0) {
      let userAnswerPreferanceObject = userSavedPreferances.message.preferences;
      if (userAnswerPreferanceObject != undefined) {
        let answersPrefer = {};
        if (userAnswerPreferanceObject.length > 0) {
          _.forEach(userAnswerPreferanceObject, function (answerObject) {
            answersPrefer[answerObject.preferencesmasteruniqueid] =
              answerObject;
          });
          let sampleAnswerArray = _.filter(
            getQuestionList.message.preferencesList,
            function (o) {
              return answersPrefer[o.preferenceUId] == undefined;
            }
          );
          if (sampleAnswerArray.length > 0) {
            let obj = sampleAnswerArray[0];
            obj.preferencesanswer = "";
            answersPrefer[sampleAnswerArray[0].preferenceUId] = obj;
          }
          // console.log(answersPrefer, "=====1");
          questionList(answersPrefer);
          setState((prevsState) => ({
            ...prevsState,
            answersPrefer: answersPrefer,
            draganswersPrefer: answersPrefer,
          }));
        } else {
          _.forEach(
            getQuestionList.message.preferencesList,
            function (answerObject) {
              let answerDefaultVal = "";
              if (answerObject.type == "money-range") {
                answerDefaultVal = "500, 1000";
              }
              if (answerObject.type == "Time-range") {
                answerDefaultVal = "15, 30";
              }
              answerObject.preferencesanswer = answerDefaultVal;
              answerObject.transfereeuniqeuid = getSessionData.userUniqueId;
              answerObject.preferencesmasteruniqueid =
                answerObject.preferenceUId;
              answerObject.latitude = null;
              answerObject.longitude = null;
              answersPrefer[answerObject.preferenceUId] = answerObject;
            }
          );
          // console.log(answersPrefer, "=====2");
          questionList(answersPrefer);
          setState((prevsState) => ({
            ...prevsState,
            answersPrefer: answersPrefer,
            draganswersPrefer: answersPrefer,
          }));
        }
      }
    }
  };

  const questionList = async (answersPrefer) => {
    let getSessionData = await LoginStore.getLoginInfo({});
    let userObject = {
      transfereeuniqeuid: getSessionData.userUniqueId,
      istemporary: getSessionData.istemporary,
    };
    let getQuestionList = await ApiServices.GetPreferencesList(userObject);
    console.log(getQuestionList, "getQuestionList");
    if (getQuestionList.message.preferencesList != undefined) {
      let questionList = getQuestionList.message.preferencesList;
      let listOfPropertiesArray = _.filter(questionList, function (o) {
        return o.type == "DragOptions";
      });
      let workAddressArray = _.filter(questionList, function (o) {
        return o.question == "Work address";
      });
      let keyWordsArray = _.filter(questionList, function (o) {
        return o.questionlabel == "KEYWORDS";
      });
      let activitiesArray = _.filter(questionList, function (o) {
        return o.questionlabel == "ACTIVITIES";
      });
      let whoisMovingArray = _.filter(questionList, function (o) {
        return o.questionlabel == "WHO IS MOVING";
      });
      await localStorage.setItem(
        "workAddress",
        JSON.stringify(answersPrefer[workAddressArray[0].preferenceUId])
      );

      {
        getSessionData.istemporary != true
          ? (listOfPropertiesArray[0].AnswerObject =
              answersPrefer[listOfPropertiesArray[0]?.preferenceUId])
          : "";
      }

      // console.log(listOfPropertiesArray, "11111");
      var questionResult = await chunkArray(questionList, 2);
      let answerString =
        answersPrefer[whoisMovingArray[0].preferenceUId] != undefined
          ? answersPrefer[whoisMovingArray[0].preferenceUId].preferencesanswer
              .replace(/^[,\s]+|[,\s]+$/g, "")
              .split(",")
          : [];
      if (answerString.length == 1 && answerString[0] == "FAMILY") {
        setState((prev) => ({ ...prev, showCheck: true }));
      }
      let option;
      if (listOfPropertiesArray.length > 0) {
        option = listOfPropertiesArray[0].options
          .split(",")
          .map((option) => option.trim());
      } else {
        console.log("====");
      }
      setState((prevsState) => ({
        ...prevsState,
        keyWordsArray: keyWordsArray,
        activitiesArray: activitiesArray,
        listOfProperties: listOfPropertiesArray,
        questions: questionResult,
        getQuestionListStatus: true,
        getSessionData: getSessionData,
        options: option,
      }));
      // setRightItems((prev) => ({ ...prev, listOfPropertiesArray }));
    }
  };

  const chunkArray = (myArray, chunk_size) => {
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];
    var myChunk = [];
    for (index = 0; index < arrayLength; index += chunk_size) {
      myChunk = myArray.slice(index, index + chunk_size);
      tempArray.push(myChunk);
    }

    return tempArray;
  };

  const errorMsgFunc = async () => {
    confirmAlert({
      title: "",
      message: "Please fill all the preferences!",
      buttons: [
        {
          label: "Close",
          onClick: () => "",
        },
      ],
    });
  };

  const submitHandler = async () => {
    setState((prev) => ({ ...prev, pageLoader: true }));
    let getSessionData = await LoginStore.getLoginInfo({});
    let answersPrefer = {};
    var activitiesError = 0;
    var showError = 0;
    let validationErrors = 0;
    let checkError = 0;
    var preferedOptions = rightItemsFromChild;
    let stringObject = preferedOptions.map((item) => item.name);
    // coverting object to string
    const DragOptions = stringObject.join(",");
    // if (getSessionData.istemporary != true) {
    //   setState((prev) => ({
    //     ...prev,
    //     DragOptionsCheck: DragOptions,
    //     submitPopUp: true,
    //   }));
    // } else {
    //   navigate("/citylist");
    // }

    let questionUID = state.listOfProperties[0]?.preferenceUId;
    // console.log(questionUID, "questionUID");
    answersPrefer[questionUID] = Object.assign(
      [questionUID],
      state.draganswersPrefer[questionUID]
    );
    if (answersPrefer[questionUID] != undefined) {
      let previousAnswer =
        answersPrefer[questionUID].preferencesanswer != undefined
          ? answersPrefer[questionUID].preferencesanswer.split(",")
          : [];
      // let checkValue = previousAnswer.indexOf(DragOptions.trim());
      previousAnswer = DragOptions;

      answersPrefer[questionUID] = {
        transfereeuniqeuid: state.getSessionData.userUniqueId,
        preferencesmasteruniqueid: questionUID,
        preferencesanswer: DragOptions.replace(/\s*,\s*/gi, ",").replace(
          /\d+/g,
          ""
        ),
        type: state.listOfProperties[0]?.type,
        options: state.listOfProperties[0]?.options,
      };
    } else {
      answersPrefer[questionUID] = {
        transfereeuniqeuid: state.getSessionData.userUniqueId,
        preferencesmasteruniqueid: questionUID,
        preferencesanswer: DragOptions.replace(/\s*,\s*/gi, ",").replace(
          /\d+/g,
          ""
        ),
        type: state.listOfProperties[0].type,
        options: state.listOfProperties[0].options,
      };
    }
    if (
      state.answersPrefer["5f97a1cc-2c27-4733-9af1-de48fed956a0"]
        .preferencesanswer === ""
    ) {
      console.log("error");
      validationErrors = 1;
    }
    if (Object.keys(state.answersPrefer).length > 0) {
      let finalCreateObject = [];
      let stateObject = Object.assign({}, state.answersPrefer);
      let dragObject = Object.assign({}, answersPrefer);
      let keywordsObj = Object.assign({}, state.keyWordsArray[0]);
      let activitiesObj = Object.assign({}, state.activitiesArray[0]);
      Object.keys(dragObject).map(function (key, value) {
        stateObject[key] = dragObject[key];
      });
      // console.log(stateObject, "stateObject");
      Object.keys(stateObject).map(function (key) {
        if (
          key != keywordsObj.preferenceUId &&
          key != activitiesObj.preferenceUId
        ) {
          if (
            (stateObject[key].preferencesanswer == undefined ||
              stateObject[key].preferencesanswer == "") &&
            stateObject[key].preferenceUId ==
              "e6198e16-43fe-43de-9287-a2cdbc635d48" &&
            state.showCheck == true
          ) {
            console.log("one");
            showError = 1;
          } else if (
            stateObject["a7fbe2dc-c3e1-43a8-a862-f068b79bce38"]
              .preferencesanswer == "ME" &&
            stateObject[key].preferencesmasteruniqueid ==
              "e6198e16-43fe-43de-9287-a2cdbc635d48"
          ) {
          } else if (
            getSessionData.istemporary != true &&
            stateObject[key].preferencesmasteruniqueid !=
              "f75ade99-8607-4756-9daf-9feeef3e1d1b" &&
            stateObject[key].preferenceUId !=
              "e6198e16-43fe-43de-9287-a2cdbc635d48" &&
            (stateObject[key].preferencesanswer == undefined ||
              stateObject[key].preferencesanswer == "") &&
            (stateObject[key].type != "money-range" ||
              stateObject[key].type != "Time-range")
          ) {
            console.log("one two", key);
            showError = 1;
          }
          //  else if (
          //   stateObject[key].type == "CheckBox" &&
          //   (stateObject[key].preferencesanswer == "false" ||
          //     stateObject[key].preferencesanswer == false)
          // ) {
          //   console.log("checkbox");
          //   checkError = 1;
          // }
        }

        if (
          stateObject[activitiesObj.preferenceUId].preferencesanswer == "" &&
          stateObject[keywordsObj.preferenceUId].preferencesanswer == ""
        ) {
          activitiesError = 1;
        }
        finalCreateObject.push(stateObject[key]);
      });

      if (showError == 1) {
        // console.log("showError == 1");
        // errorMsgFunc();
        setState((prev) => ({ ...prev, isError: true, pageLoader: true }));
      }
      if (checkError == 1) {
        // errorMsgFunc();

        setState((prev) => ({ ...prev, checkError: true, pageLoader: true }));
      }
      if (validationErrors == 1) {
        // errorMsgFunc();
        setState((prev) => ({ ...prev, isError: true, pageLoader: true }));
      }
      if (activitiesError == 1 && showError == 1) {
        console.log("activitiesError == 1 && showError == 1");
        errorMsgFunc();
        setState((prev) => ({
          ...prev,
          activitiesError: true,
          pageLoader: true,
        }));
      } else if (activitiesError == 1) {
        console.log("activitiesError == 1");
        errorMsgFunc();
        setState((prev) => ({
          ...prev,
          activitiesError: true,
          pageLoader: true,
        }));
      } else if (
        showError == 0 &&
        activitiesError == 0 &&
        validationErrors == 0 &&
        checkError == 0
      ) {
        // console.log(finalCreateObject, "finalCreateObject");
        let QuestionResult = await ApiServices.CreateTransfereePreferences(
          finalCreateObject
        );

        let transefereeUniqueId = {
          transferee_uid: getSessionData.userUniqueId,
        };
        let updateAiAssistant = await aiAssistant.aiLocationUpdate(
          transefereeUniqueId
        );
        let question = _.filter(finalCreateObject, function (o) {
          return (
            o.preferencesmasteruniqueid ==
            "a7fbe2dc-c3e1-43a8-a862-f068b79bce38"
          );
        });
        let answerString =
          question[0] != undefined
            ? question[0].preferencesanswer
                .replace(/^[,\s]+|[,\s]+$/g, "")
                .split(",")
            : [];
        if (answerString.length == 1 && answerString[0] == "ME") {
          confirmationFunc();
        } else {
          console.log(" confirmationFunc else part");
          confirmationFunc();
        }
      }
    }
    if (
      showError == 0 &&
      activitiesError == 0 &&
      validationErrors == 0 &&
      checkError == 0
    ) {
      console.log(validationErrors, "validationErrors");
      if (getSessionData.istemporary != true) {
        setState((prev) => ({
          ...prev,
          DragOptionsCheck: DragOptions,
          submitPopUp: true,
          pageLoader: true,
        }));
      } else {
        setState((prev) => ({ ...prev, pageLoader: true }));
        navigate("/citylist");
      }
    } else {
      errorMsgFunc();
      setState((prev) => ({
        ...prev,
        submitPopUp: false,
        pageLoader: true,
      }));
    }
  };

  const confirmationFunc = async () => {
    let data = {
      transfereeuid: state.getSessionData.userUniqueId,
    };
    const GetTransfereeStatus = await ApiServices.GetTransfereeStatus(data);
    let uhp = [];

    if (GetTransfereeStatus.error == 0) {
      uhp = await _.filter(GetTransfereeStatus.message.noteslist, function (o) {
        return o.statusuid == "f085be8b-4a33-4826-87ad-cdd6bde1b033";
      });
    }
    let StatusUpdateSendWelcome = {
      uniqueid: uhp.length == 0 ? "" : "f085be8b-4a33-4826-87ad-cdd6bde1b033",
      transfereeuid: state.getSessionData.userUniqueId,
      statusuid: "f085be8b-4a33-4826-87ad-cdd6bde1b033",
      isactive: true,
    };
    const ManageTransfereeStatus = await ApiServices.ManageTransfereeStatus(
      StatusUpdateSendWelcome
    );
  };

  const setDie = async (Id, e, questionUID) => {
    //e.persist();
    let valueCheck = e.target.checked;

    let getSessionData = await LoginStore.getLoginInfo({});
    let answersPrefer = state.answersPrefer;

    answersPrefer[questionUID] = {
      type: Id,
      preferencesmasteruniqueid: questionUID,
      preferencesanswer: valueCheck,
      latitude: null,
      longitude: null,
      transfereeuniqeuid: getSessionData.userUniqueId,
    };
    setState((prevState) => ({
      ...prevState,
      answersPrefer: answersPrefer,
    }));
  };

  const onChange = async (questionUID, type, value) => {
    // console.log(questionUID, type, value);
    let answersPrefer = state.answersPrefer;
    if (type == "options") {
      // console.log(answersPrefer[questionUID].questionlabel);
      if (answersPrefer[questionUID] != undefined) {
        if (answersPrefer[questionUID].questionlabel == "WHO IS MOVING") {
          let previousAnswer = value.trim();
          let answerString =
            answersPrefer[questionUID] != undefined
              ? previousAnswer.replace(/^[,\s]+|[,\s]+$/g, "").split(",")
              : [];

          if (answerString.length == 1 && answerString[0] == "FAMILY") {
            setState((prevState) => ({
              ...prevState,
              showCheck: true,
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              showCheck: false,
            }));
          }
          answersPrefer[questionUID] = {
            transfereeuniqeuid: state.getSessionData.userUniqueId,
            preferencesmasteruniqueid: questionUID,
            preferencesanswer: previousAnswer.replace(/^,|,$/g, ""),
            questionlabel: answersPrefer[questionUID].questionlabel,
          };
        } else {
          let previousAnswer =
            answersPrefer[questionUID].preferencesanswer.split(",");
          let checkValue = previousAnswer.indexOf(value.trim());
          if (checkValue == -1) {
            previousAnswer.push(value.trim());
          } else {
            previousAnswer.splice(checkValue, 1);
          }
          answersPrefer[questionUID] = {
            transfereeuniqeuid: state.getSessionData.userUniqueId,
            preferencesmasteruniqueid: questionUID,
            preferencesanswer: previousAnswer.toString().replace(/^,|,$/g, ""),
          };
        }
      } else {
        answersPrefer[questionUID] = {
          transfereeuniqeuid: state.getSessionData.userUniqueId,
          preferencesmasteruniqueid: questionUID,
          preferencesanswer: value,
        };
      }
    } else if (type == "Time-range" || type == "money-range") {
      answersPrefer[questionUID] = {
        transfereeuniqeuid: state.getSessionData.userUniqueId,
        preferencesmasteruniqueid: questionUID,
        preferencesanswer: value.toString(),
      };
    } else if (
      type == "RadioButtons" &&
      questionUID == "1b446dad-cd66-4360-8d2b-4f2f0a04933f"
    ) {
      answersPrefer[questionUID] = {
        transfereeuniqeuid: state.getSessionData.userUniqueId,
        preferencesmasteruniqueid: questionUID,
        preferencesanswer: value.toString(),
      };

      if (value.toString() == "Yes") {
        let previousAnswer =
          answersPrefer[
            "a11a3694-282e-4260-b67b-72a40363dd8d"
          ]?.preferencesanswer?.split(",") ?? [];
        // console.log(previousAnswer, "previousAnswer");
        let checkValue = previousAnswer.indexOf("Pet Friendly");
        if (checkValue < 0) {
          // console.log(checkValue, "checkValue");
          await onChange(
            "a11a3694-282e-4260-b67b-72a40363dd8d",
            "options",
            "Pet Friendly"
          );
        }
      }
    } else {
      answersPrefer[questionUID] = {
        transfereeuniqeuid: state.getSessionData.userUniqueId,
        preferencesmasteruniqueid: questionUID,
        preferencesanswer: value.toString(),
      };
    }

    setState((prevState) => ({
      ...prevState,
      answersPrefer: answersPrefer,
    }));
  };

  const setLocation = async (Id, e, questionUID) => {
    // console.log(e.target.value, "e.target.value");
    e.persist();
    let answersPrefer = state.answersPrefer;
    let getSessionData = await LoginStore.getLoginInfo({});
    let address = e.target.value;

    setState((prev) => ({
      ...prev,
      [Id]: address,
    }));
    var places = new google.maps.places.Autocomplete(
      document.getElementById(Id),
      { types: ["address"] }
    );
    places.setComponentRestrictions({ country: ["us"] });
    answersPrefer[questionUID].preferencesanswer = "";
    google.maps.event.addListener(places, "place_changed", async () => {
      var place = places.getPlace();
      // console.log(place, "place");
      answersPrefer[questionUID] = {
        preferencesmasteruniqueid: questionUID,
        preferencesanswer: e.target.value,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        transfereeuniqeuid: getSessionData.userUniqueId,
      };
      let workAddress = {
        preferencesmasteruniqueid: questionUID,
        preferencesanswer: e.target.value,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        transfereeuniqeuid: getSessionData.userUniqueId,
      };
      await localStorage.setItem("workAddress", JSON.stringify(workAddress));
    });
    setState((prev) => ({ ...prev, answersPrefer }));
  };

  const showAnnualMonthlyRent = () => {
    $("document").ready(function () {
      $("#HomeRentalCal").modal("show");
    });
    // setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setState((prev) => ({ ...prev, HouseMonthlyRent: "" }));
  };

  const handleChangeStart = async (Id, e, questionUID) => {
    // console.log(Id, e, questionUID, "====");
    let getSessionData = await LoginStore.getLoginInfo({});
    let answersPrefer = state.answersPrefer;
    answersPrefer[questionUID] = {
      preferencesmasteruniqueid: questionUID,
      preferencesanswer: moment(e).format("MM/DD/YYYY"),
      latitude: null,
      longitude: null,
      transfereeuniqeuid: getSessionData.userUniqueId,
    };
    console.log(moment(e).format("MM/DD/YYYY"), answersPrefer[questionUID]);
    setState((prevState) => ({
      ...prevState,
      answersPrefer: answersPrefer,
    }));
  };

  const children = [];
  const values = state.jData;
  var dataSource = values;
  for (let i = 0; i < dataSource.length; i++) {
    children.push(
      <Option key={dataSource[i].name}>{dataSource[i].name}</Option>
    );
  }

  return (
    <>
      <div>
        {state.getQuestionListStatus == false ? (
          <div className="loading"></div>
        ) : null}

        <div className="container main_getStartedPage">
          {/* <h1 className="d-md-none text-center">My Preferences</h1> */}
          <div className="bannerpart">
            <h1>Welcome to ReloNavigator</h1>
            <h5 className="mt-2">
              Let's build your preferences & get you settled in!
            </h5>
          </div>

          <div className="row align-items-center justify-content-center">
            <div className="col-md-8 formView">
              {/* state.questions start */}
              {state.questions &&
                state.questions.map((row, index) => {
                  return (
                    <div className="top-two" key={"main" + index}>
                      {row.map((questionList, questionIndex) => {
                        return (questionList = !undefined ? (
                          <SelectInputComponent
                            state={state}
                            questionList={questionList}
                            questionIndex={questionIndex}
                            onChange={onChange}
                            setLocation={setLocation}
                            showAnnualMonthlyRent={showAnnualMonthlyRent}
                            handleChangeStart={handleChangeStart}
                            setDie={setDie}
                            key={questionIndex}
                          />
                        ) : (
                          ""
                        ));
                      })}
                    </div>
                  );
                })}
              {/* state.questions end */}
              <div>
                {/* <DragDrop
                  state={state}
                  handleRightItemsUpdate={handleRightItemsUpdate}
                /> */}
                <NewDragDrop
                  state={state}
                  handleRightItemsUpdate={handleRightItemsUpdate}
                />
              </div>
            </div>
          </div>
          {/* <div id="shared-lists" className="row justify-content-center">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-12"></div>
              </div>
            </div>
          </div> */}
          <div className="tophm_solo get_startedpage text-center mrgtop-30">
            <button
              type="button"
              className="btn mr-2 theme-btn"
              onClick={submitHandler}
            >
              {" "}
              Save{" "}
            </button>
          </div>
        </div>
        <BudgetPopUp
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          state={state}
          setState={setState}
        />
        <SubmitPopUp state={state} setState={setState} />
        <Footer />
      </div>
    </>
  );
}

export default GetStarted;
