import React, { useEffect } from "react";
import AOS from "aos";
import sendMailFromMobile from "../../../../services/sendMailFromMobileAppServices";

function NewReloPublicPage() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
    // fetchData();
  }, []);

  const fetchData = async () => {
    let data = {
      firstname: "Somu Sekhar",
      Email: "karthikab@yopmail.com",
      Phonenumber: "+1 8795652625",
      countrycode: "1",

      Housing: "test",
      moveindate: "02/10/2025",
      Budget: "3",
      Bedrooms: "2",
      Bathrooms: "2",
      Pets: "2",
      DesiredLeaseterm: "2",

      Background: "",
      Employername: "",
      AnnualIncome: "",
      Previousrentalpropertyname: "",
      longstay: "",
      Contactinformation: "",
      Monthlyrent: "",
      leaving: "",
      Referencename: "",
      Relation: "",
      RefPhonenumber: "+ 3434342323",
      Refcountrycode: "",
      refEmail: "geetha@yopmail.com",
      requested: "No",
      requestChecked: true,
      // proof:   state.proof,

      email: "geetha.kunda@devrabbit.com",
      yournotes: "",
      therentalresume: true,
      subject: "Renters Resume",
    };

    await sendMailFromMobile.sendMailFromMobile(data);
  };
  const contacUsBtn = () => {
    location.href = "/contact-us";
  };
  return (
    <div>
      <div className="hero-section">
        <div className="container">
          <div className="item">
            <h1>Relocation Made Simple, Just for You</h1>
            <p className="mb-lg-5 mt-3">
              Discover tools, AI-powered support, and insights to make your move
              seamless and stress-free.
            </p>
            <button
              type="button"
              className="relo-btn mt-lg-4"
              onClick={() => contacUsBtn()}
            >
              Get Started Now
            </button>
          </div>
        </div>

        <div className="banner">
          <div className="img">
            {" "}
            <img src="assets/publicImages/new-relo/slider-1.png" alt="" />{" "}
          </div>
          <div className="img">
            {" "}
            <img src="assets/publicImages/new-relo/slider-2.png" alt="" />{" "}
          </div>
        </div>
      </div>

      <div className="keyFeatures-section text-center">
        <h1 data-aos="fade-up">Everything You Need for a Smooth Relocation</h1>
        <p data-aos="fade-up">
          {" "}
          Explore the tools and features designed to simplify your move.
        </p>

        <div className="grid-view" data-aos="fade-up">
          <div className="item">
            <img
              src="assets/publicImages/new-relo/keyFeatures/img-1.png"
              alt=""
            />
            <div className="textview">
              <h3>Stay Connected On-the-Go</h3>
              <p>
                Use the ReloNavigator mobile app to track progress, book
                appointments, and access insights anytime, anywhere.
              </p>
            </div>
          </div>
          <div className="item">
            <img
              src="assets/publicImages/new-relo/keyFeatures/img-2.png"
              alt=""
            />
            <div className="textview">
              <h3>Explore Your New Area</h3>
              <p>
                Learn about neighborhoods, schools, and points of interest
                before you move.
              </p>
            </div>
          </div>
          <div className="item">
            <img
              src="assets/publicImages/new-relo/keyFeatures/img-3.png"
              alt=""
            />
            <div className="textview">
              <h3>Access Essential Resources</h3>
              <p>
                From packing guides to utility setup tips, we have you covered.
              </p>
            </div>
          </div>
          <div className="item">
            <img
              src="assets/publicImages/new-relo/keyFeatures/img-4.png"
              alt=""
            />
            <div className="textview">
              <h3>Organize your Home Search</h3>
              <p>
                Compare properties, check amenities, and make informed decisions
                effortlessly.
              </p>
            </div>
          </div>
          <div className="item">
            <img
              src="assets/publicImages/new-relo/keyFeatures/img-5.png"
              alt=""
            />
            <div className="textview">
              <h3>Personalized Moving Tools</h3>
              <p>
                Create tailored checklists and reminders to stay on top of your
                tasks.
              </p>
            </div>
          </div>
          <div className="item">
            <img
              src="assets/publicImages/new-relo/keyFeatures/img-6.png"
              alt=""
            />
            <div className="textview">
              <h3>Relo Copilot, Always with you</h3>
              <p>
                Get instant answers and personalized guidance for your
                relocation questions with our intelligent chatbot, available
                24/7.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="yourCoPilot text-center">
        <div className="container">
          <h1 data-aos="fade-up">Your Personal CoPilot</h1>
          <p data-aos="fade-up">
            Meet our AI-powered assistant—your 24/7 guide to all things
            relocation.
          </p>

          <div className="instruction">
            <img
              src="assets/publicImages/new-relo/device.png"
              alt=""
              width="100%"
            />

            <ul>
              <li>
                <h3>Personalized Guidance</h3>
                <p>
                  Receive tailored recommendations based on your preferences and
                  moving needs.
                </p>
              </li>
              <li>
                <h3>Instant Answers</h3>
                <p>
                  Get quick responses to your relocation questions, anytime you
                  need them.
                </p>
              </li>
              <li>
                <h3>Seamless Support</h3>
                <p>
                  Stay connected and get help with everything from finding a
                  home to setting up utilities.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="seamlessRelo">
        <div className="container">
          <div className="gridView" data-aos="fade-up">
            <div className="item">
              <img
                src="assets/publicImages/new-relo/seamless.png"
                alt=""
                width="100%"
              />
            </div>
            <div className="item">
              <h3>Seamless Relocation in Simple Steps</h3>
              <ul>
                <li>
                  Explore Your Options
                  <span>
                    Access area insights, school data, and housing options
                    tailored to you.
                    <button
                      type="button"
                      className="relo-btn mt-2 d-block"
                      onClick={() => contacUsBtn()}
                    >
                      Start Your Journey Today
                    </button>
                  </span>
                </li>
                <li>
                  Organize Your Move
                  <span>
                    Create a personalized plan with checklists, reminders, and
                    resources.
                  </span>
                </li>
                <li>
                  Settle In with Confidence
                  <span>
                    Use our tools and app to finalize your move and feel at
                    home.
                  </span>
                </li>
                <li>
                  Get Instant Assistance
                  <span>
                    Use our AI chatbot for quick, reliable answers and support
                    anytime during your move.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mobileView">
          <h1 className="text-center"> Your Move, in Your Pocket</h1>
          <p className="text-center">
            {" "}
            Experience the convenience of managing your move anytime, anywhere.
          </p>

          <div className="gridView">
            <div className="item" data-aos="fade-up">
              <ul>
                <li>
                  <i>
                    <img src="assets/publicImages/new-relo/tick.png" alt="" />
                  </i>
                  <span>
                    <h3>Real-Time Updates</h3>
                    <p>
                      Stay informed with notifications about your appointments
                      and progress.
                    </p>
                  </span>
                </li>
                <li>
                  <i>
                    <img src="assets/publicImages/new-relo/tick.png" alt="" />
                  </i>
                  <span>
                    <h3>Essential Resources</h3>
                    <p>
                      Find everything you need to navigate the rental process,
                      manage your move, and get settled with ease.
                    </p>
                  </span>
                </li>
                <li>
                  <i>
                    <img src="assets/publicImages/new-relo/tick.png" alt="" />
                  </i>
                  <span>
                    <h3>Home Finding & Area Insights</h3>
                    <p>
                      {" "}
                      Compare properties and learn about your new neighborhood.
                    </p>
                  </span>
                </li>
              </ul>
            </div>
            <div className="item text-center" data-aos="fade-up">
              <img
                src="assets/publicImages/new-relo/mobile.png"
                alt=""
                className="mobile"
                width="100%"
              />
            </div>
            <div className="item" data-aos="fade-up">
              <ul>
                <li>
                  <i>
                    <img src="assets/publicImages/new-relo/tick.png" alt="" />
                  </i>
                  <span>
                    <h3>Checklists & Reminders</h3>
                    <p>
                      Stay organized with personalized lists and automated
                      reminders.
                    </p>
                  </span>
                </li>
                <li>
                  <i>
                    <img src="assets/publicImages/new-relo/tick.png" alt="" />
                  </i>
                  <span>
                    <h3>AI powered Copilot</h3>
                    <p>
                      Ask questions, get recommendations, and stay connected
                      with our chatbot directly from the app.
                    </p>
                    <a
                      href="https://apps.apple.com/us/app/relonavigator-app/id6499023704?uo=2"
                      target="_blank"
                    >
                      <img src="assets/publicImages/appStore.png" alt="" />
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewReloPublicPage;
