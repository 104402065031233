import AppController from "../controllers/appController";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import moment from "moment";
class Weather extends React.Component {
  render() {
    const itemsWeather =
      parseInt(screen.availWidth) <= 767
        ? 1
        : parseInt(screen.availWidth) <= 991
        ? 2
        : 3;
    const startPosition = "0";
    // console.log(this.props.weather_current, "====", itemsWeather)
    let dateee = new Date(this.props.weather_current.dt * 1000);
    let weather = this.props.weather;
    for (let i = 0; i < 4; i++) {
      weather[i]["week"] = moment(dateee)
        .add("days", i + 1)
        .format("YYYY/MM/DD");
    }
    return (
      <div className="bb-white b-radius-8">
        <div className="weather-view-page">
          <div className="weather-view-main-details">
            <img
              src={
                "https://openweathermap.org/img/w/" +
                this.props.weather_current.weather[0].icon +
                ".png"
              }
              alt=""
              title=""
            />
            <span style={{ fontSize: "40px" }}>
              {this.props.weather_current.main.temp.toFixed(0)}
              <sup>o</sup>F
            </span>
          </div>
          <p>
            {moment(dateee).format("LL")} {moment(dateee).format("dddd")}
          </p>

          <div className="weather-view-sub-details">
            <ul>
              <li>
                <img src="/assets/images/air-icon.svg" alt="" />
                <span>
                  {this.props.weather_current.wind != undefined
                    ? this.props.weather_current.wind.speed
                    : "N/A"}
                  <sup>mph</sup>
                </span>
                <p>WIND</p>
              </li>
              <li>
                <img src="/assets/images/rain-icon.svg" alt="" />
                <span>
                  {this.props.weather_current.clouds != undefined
                    ? this.props.weather_current.clouds.all
                    : "N/A"}
                  <sup>%</sup>
                </span>
                <p>Clouds</p>
              </li>
              <li>
                <img src="/assets/images/water-drop-icon.svg" alt="" />
                <span>
                  {this.props.weather_current.main != undefined
                    ? this.props.weather_current.main.humidity
                    : "N/A"}
                  <sup>%</sup>
                </span>
                <p>HUMIDITY</p>
              </li>
            </ul>
          </div>

          <div className="weather-view-week-details">
            {weather.map((row, index) => {
              let dateResult = row.weatherData.date.split("-");
              let date =
                dateResult[0] + "/" + dateResult[1] + "/" + dateResult[2];
              return index <= 2 ? (
                <div className="item" key={index}>
                  <span>{AppController.getDayName(row.week)}</span>
                  <img
                    src={
                      "https://openweathermap.org/img/w/" +
                      row.weatherData.weather[0].icon +
                      ".png"
                    }
                    alt=""
                    title=""
                  />
                  <p>
                    {row.weatherDataMax.toFixed(0)}
                    <sup>o</sup>F
                  </p>
                </div>
              ) : null;
            })}
          </div>
        </div>
      </div>
    );
  }
}
export default Weather;
