import React, { useState, useEffect, useSyncExternalStore } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as $ from "jquery";
import ApiServices from "../../../../../services/apiServices";
import DatePicker from "react-datepicker";
import IntTelDropDown from "../../../../common/internationalPhone/IntTelDropDown";
import { Select, Spin } from "antd";
import moment from "moment";
const EditTransferee = (props) => {
  // console.log(props, "consultant side props");
  const { editingTransferee, transferee, getmoredata } = props;

  const location = useLocation();
  const Navigate = useNavigate();
  const {
    firstname,
    lastname,
    approvedhours,
    istemporary,
    clientuniqueid,
    companyinpersonhours,
    companyvirtualhours,
    countrycode,
    email,
    endDate,
    individualinpersonhours,
    individualvirtualhours,
    phoneNumber,
    po,
    referenceid,
    servicelevel,
    startDate,
    usernotes,
    recordval,
  } = transferee;
  const storedUserDetails = localStorage.getItem("moredetails");
  const userDetails = JSON.parse(storedUserDetails);
  const user =
    location.state && location.state.row ? location.state.row : userDetails;
  async function editValues() {
    updateState({
      firstname: firstname,
      lastname: lastname,
      approvedhours: approvedhours,
      istemporary: istemporary != null ? istemporary : false,
      companyinpersonhours: companyinpersonhours,
      companyvirtualhours: companyvirtualhours,
      countrycode: countrycode,
      clientuniqueid: clientuniqueid,
      email: email,
      endDate: endDate,
      individualinpersonhours: individualinpersonhours,
      individualvirtualhours: individualvirtualhours,
      phoneNumber: phoneNumber,
      po: po,
      referenceid: referenceid,
      servicelevel: servicelevel,
      startDate: startDate,
      usernotes: usernotes,
      recordval: recordval,
    });
  }

  const intialState = {
    countrycode: "",
    companyvirtualhours: "",
    companyinpersonhours: "",
    approvedhours: "",
    istemporary: "",
    errorMessage: false,
    existsmsg: "",
    ShowSuccess: false,
    editid: false,
    showing: "",
    moredetails: user, //location.state.row,
    dataUpdate: "",
    firstname: "",
    lastname: "",
    email: "",
    endDate: "",
    individualinpersonhours: "",
    individualvirtualhours: "",
    phoneNumber: "",
    po: "",
    referenceid: "",
    servicelevel: "",
    startDate: null,
    usernotes: "",
    companydata: [],
    clientuniqueid: "",
    recordval: "",
    uploadLoader: false,
  };

  const [state, setState] = useState(intialState);

  function handleChange(name, e) {
    if (e && e.target) {
      let value = e.target.value;

      let object = {};
      object[name] = value;
      updateState(object);
      var summ = 0;
      if (
        object.servicelevel == "Commander" ||
        state.servicelevel == "Commander"
      ) {
        let companyInPersonHours =
          object.companyinpersonhours != "" &&
          !isNaN(object.companyinpersonhours)
            ? parseInt(object.companyinpersonhours)
            : state.companyinpersonhours || 0;
        let companyVirtualHours =
          object.companyvirtualhours != "" && !isNaN(object.companyvirtualhours)
            ? parseInt(object.companyvirtualhours)
            : state.companyvirtualhours || 0;
        let individualInPersonHours =
          object.individualinpersonhours != "" &&
          !isNaN(object.individualinpersonhours)
            ? parseInt(object.individualinpersonhours)
            : state.individualinpersonhours || 0;
        let individualVirtualHours =
          object.individualvirtualhours != "" &&
          !isNaN(object.individualvirtualhours)
            ? parseInt(object.individualvirtualhours)
            : state.individualvirtualhours || 0;
        summ =
          parseInt(companyInPersonHours) +
          parseInt(companyVirtualHours) +
          parseInt(individualInPersonHours) +
          parseInt(individualVirtualHours);
        setState((prev) => ({ ...prev, approvedhours: summ }));
      }
      if (
        object.servicelevel == "ReloNavigator" ||
        state.servicelevel == "ReloNavigator"
      ) {
        setState((prev) => ({ ...prev, approvedhours: 0 }));
      }
      if (
        object.servicelevel == "Explorer" ||
        state.servicelevel == "Explorer"
      ) {
        let summ1 = 0;
        let companyVirtualHours =
          object.companyvirtualhours != "" && !isNaN(object.companyvirtualhours)
            ? parseInt(object.companyvirtualhours)
            : state.companyvirtualhours || 0;
        let individualVirtualHours =
          object.individualvirtualhours != "" &&
          !isNaN(object.individualvirtualhours)
            ? parseInt(object.individualvirtualhours)
            : state.individualvirtualhours || 0;
        summ1 =
          parseInt(companyVirtualHours) + parseInt(individualVirtualHours);
        setState((prev) => ({ ...prev, approvedhours: summ1 }));
      }

      const regex =
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      // const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      const phone = /(d{0,3})(d{0,3})(d{0,4})/;
      const numReplace = /\D/g;

      if (name === "email") {
        if (regex.test(value)) {
          $("#" + name).removeClass("is-invalid");
        } else {
          $("#" + name).addClass("is-invalid");
        }
      }
      if (!value.trim()) {
        $("#" + name).addClass("is-invalid");
      } else {
        $("#" + name).removeClass("is-invalid");
      }
      if (name === "phoneNumber") {
        console.log(numReplace, "=========", phone);
        var x = e.target.value.replace(numReplace, "").match(phone);
        e.target.value = !x[2]
          ? x[1]
          : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        let countryCode = state.countryDetails;
      }
    }
  }

  useEffect(() => {
    editValues();
  }, [transferee]);

  function updateState(data) {
    setState((prevState) => ({
      ...prevState,
      ...data,
    }));
  }
  function handleCountryCodeChange(code) {
    updateState({ countrycode: code });
  }
  function handleDateChange(e, date) {
    let object = {};
    object[date] = e;
    if (date == "startDate") {
      updateState(object);
    } else {
      updateState(object);
    }
  }

  useEffect(() => {
    companyData();
  }, []);

  async function companyData() {
    const GetStatusList = await ApiServices.GetStatusList();

    if (GetStatusList.error == 0) {
      GetStatusList.message.noteslist.forEach((valueObject) => {
        if (valueObject.identifier == "Registration email sent")
          valueObject.orderId = 1;
        if (valueObject.identifier == "Transferee Registered successfully")
          valueObject.orderId = 2;
        if (valueObject.identifier == "Transferee logged in for the first time")
          valueObject.orderId = 3;
        if (valueObject.identifier == "Updated Home Preferences")
          valueObject.orderId = 4;
        if (valueObject.identifier == "Utilized digital checklists")
          valueObject.orderId = 5;
        if (valueObject.identifier == "Scheduled appointments with consultant")
          valueObject.orderId = 6;
      });

      let SortStatus = _.sortBy(GetStatusList.message.noteslist, [
        function (o) {
          return parseInt(o.orderId, 10);
        },
      ]);

      await updateState({
        allstatuslist: SortStatus,
      });
    }
    // let getSessionData = await LoginStore.getLoginInfo({});
    let getTransfereeObj = {
      // supplieruid: getSessionData.supplieruid,

      supplieruid: "90f04e8c-0f75-44a8-92d0-4e089a17d113", //Change this after login functionality
    };
    let ClientProfile_Result = await ApiServices.GetAllClientProfile(
      getTransfereeObj
    );

    let mainn = ClientProfile_Result.message.clientProfileList;

    var newArray = mainn.sort((first, second) => {
      var a = first.companyname.toLowerCase();
      var b = second.companyname.toLowerCase();
      if (a > b) {
        return 1;
      } else if (a < b) {
        return -1;
      } else {
        return 0;
      }
    });
    // console.log(newArray, "newarray");
    await updateState({ companydata: newArray });
  }

  let companydatachildren = [];

  for (let i = 0; i < state.companydata.length; i++) {
    companydatachildren.push(
      <Select.Option key={state.companydata[i].clientuniqueId}>
        {state.companydata[i].companyname}
      </Select.Option>
    );
  }

  let servicele = [];
  var serv = ["Commander", "Explorer", "ReloNavigator"];
  for (let i = 0; i < serv.length; i++) {
    servicele.push(serv[i]);
  }

  const children = [];
  for (let i = 0; i < 31; i++) {
    children.push(i);
  }

  async function addProperty(e) {
    e.preventDefault();
    let error = 0;
    if (state.firstname.trim() == "") {
      $("#firstname").addClass("is-invalid");
      error++;
    } else {
      $("#firstname").removeClass("is-invalid");
    }
    if (state.lastname.trim() == "") {
      $("#lastname").addClass("is-invalid");
      error++;
    } else {
      $("#lastname").removeClass("is-invalid");
    }
    if (state.email == "") {
      $("#email").addClass("is-invalid");
      error++;
    } else {
      $("#email").removeClass("is-invalid");
    }
    // if (state.phoneNumber == "") {
    //   // console.log(state.phoneNumber, "Empty phone number");
    //   $(".flag-posotion").addClass("is-invalid");
    //   error++;
    // } else if (!/^\d+$/.test(state.phoneNumber)) {
    //   // console.log(state.phoneNumber, "Not a number");
    //   $(".flag-posotion").addClass("is-invalid");
    //   error++;
    // } else {
    //   // console.log("Valid phone number", state.phoneNumber);
    //   $(".flag-posotion").removeClass("is-invalid");
    // }

    if (state.endDate == null) {
      $("#enddate").addClass("is-invalid");

      error++;
    } else {
      $("#enddate").removeClass("is-invalid");
    }
    if (state.startDate == null) {
      $("#startdate").addClass("is-invalid");

      error++;
    } else {
      $("#startdate").removeClass("is-invalid");
    }

    if (error == 0) {
      let data = {
        transfereeUId: state.recordval.userUniqueId,
        firstname: state.firstname,
        lastname: state.lastname,
        middlename: state.recordval.userMiddleName,
        password: "",
        age: state.recordval.age,
        dob: state.recordval.dob,
        gender: state.recordval.gender,
        emailid: state.email,
        countrycode: state.countrycode,
        phonenumber: state.phoneNumber,
        worklocationaddress1: state.recordval.worklocationaddress1,
        worklocationaddress2: state.recordval.worklocationaddress2,
        supplieruid: "90f04e8c-0f75-44a8-92d0-4e089a17d113",
        // supplieruid: this.state.getSessionData.supplieruid,
        city: state.recordval.city,
        state: state.recordval.state,
        zipcode: state.recordval.zipcode,
        profilepic: state.recordval.profilepic,
        approvedhours: state.approvedhours,
        istemporary: state.istemporary,
        companyinpersonhours: state.companyinpersonhours,
        companyvirtualhours: state.companyvirtualhours,
        individualinpersonhours: state.individualinpersonhours,
        individualvirtualhours: state.individualvirtualhours,
        accessfromdate: moment(state.startDate).format("MM/DD/YYYY"),
        accesstodate: moment(state.endDate).format("MM/DD/YYYY"),
        notes: state.usernotes,
        clientuniqueid: state.clientuniqueid,
        servicelevel: state.servicelevel,
        po: state.po,
        referenceid: state.referenceid,
      };

      updateState({ uploadLoader: true });
      const UpdateTransferee = await ApiServices.UpdateTransferee(data);

      if (UpdateTransferee.error == 0) {
        updateState({
          errorMessage: false,
          ShowSuccess: true,
          showing: "successfully updated!",
          uploadLoader: false,
        });

        //

        setTimeout($("#myModaledit").modal("show"), 4000);
        await getmoredata(state.moredetails.userUniqueId);
        // setTimeout(() => {
        //   updateState(intialState);
        //   //   closeModal("edit-property-modal");
        // }, 5000);
        // window.scrollTo(0, 0);

        // setTimeout(navigate(), 4000);
        // setTimeout($("#myModaledit").modal("hide"), 4000);
      } else {
        updateState({
          errorMessage: true,
          existsmsg: UpdateTransferee.message.status,
          ShowSuccess: false,
          uploadLoader: false,
        });
      }
    }
  }
  // const onClickClose = () => {
  //   $("#myModaledit").modal("hide");
  // };

  async function navigate() {
    // Navigate("/TransfereesList");
    history.push("/TransfereesList");
  }
  const handleClick = (event) => {
    setState((prev) => ({ ...prev, clientuniqueid: event }));
  };

  $("#myModaledit .close").click(function () {
    // Clear validations
    clearValidations();
  });

  function clearValidations() {
    // Remove is-invalid class from all input fields
    $("input").removeClass("is-invalid");
    $("select").removeClass("is-invalid");
    $("textarea").removeClass("is-invalid");
    $(".flag-posotion").removeClass("is-invalid");

    // Reset error count
    // error = 0;
  }
  const handleKeyPress = (e) => {
    const key = e.key;
    const regex = /[0-9.]/; // Allow numeric digits and a period (dot)

    if (e.keyCode === 8 || regex.test(key)) {
      return;
    }
    e.preventDefault();
  };
  return (
    <div
      className="modal fade "
      id="myModaledit"
      tabIndex="-1"
      aria-labelledby="myModaledit"
      aria-hidden="true"
      style={{ zIndex: 1060 }}
    >
      <div className="modal-dialog modal-lg modal-lg-2">
        <div className="modal-content ">
          <div className="width-70per pd-y-60">
            <div className="only_close">
              <span
                className="pointer close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                &times;
              </span>
            </div>
            <form
              method="post"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <h6>Edit Transferee</h6>
              {state.errorMessage == true ? (
                <div className="pop-form-controls focus-input-label mt-4">
                  <div className="floating-label">
                    <h6 className="alert alert-warning">{state.existsmsg}</h6>
                  </div>
                </div>
              ) : (
                ""
              )}
              {state.ShowSuccess == true ? (
                <div className="pop-form-controls focus-input-label mt-4">
                  <div className="floating-label">
                    <h6 className="alert alert-success">
                      {state.editid == true ? state.showing : state.showing}{" "}
                    </h6>
                  </div>
                </div>
              ) : (
                <div className="pop-form-controls focus-input-label mt-4">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="floating-label">
                        <input
                          placeholder="First Name"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          value={state.firstname}
                          id="firstname"
                          onChange={(e) => handleChange("firstname", e)}
                        />
                        <label>First Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="floating-label">
                        <input
                          placeholder="Last Name"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          value={state.lastname}
                          id="lastname"
                          onChange={(e) => handleChange("lastname", e)}
                        />
                        <label>Last Name</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="floating-label">
                        <input
                          placeholder="Email"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          value={state.email}
                          id="email"
                          onChange={(e) => handleChange("email", e)}
                          disabled
                        />
                        <label>Email</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="floating-label another-side-cntry-flag">
                        {/* <Select
                          value={state.countryData}
                          onChange={(e) =>
                            handleChange(e.target.value, "countryData")
                          }
                        >
                          {countryDetails}
                        </Select> */}
                        <div className="position-relative flag-posotion form-control">
                          <IntTelDropDown
                            handleCountryCodeChange={handleCountryCodeChange}
                            countrycode={state.countrycode}
                          />
                          <input
                            // ref={phoneNumberRef}
                            type="tel"
                            maxLength={10}
                            className="border-0"
                            autoComplete="off"
                            value={state.phoneNumber}
                            onKeyDown={handleKeyPress}
                            placeholder="phoneNumber"
                            id="phoneNumber"
                            onChange={(e) => handleChange("phoneNumber", e)}
                            // onBlur={this.inputHandler.bind(this, "phoneNumber")}
                          />
                        </div>
                        <label>Phone Number</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="label_cls">Company</label>
                      {/* <select
                        className="form-control"
                        id="clientuniqueid"
                        style={{ width: 200 }}
                        value={state.clientuniqueid}
                        onChange={(e) => handleChange("clientuniqueid", e)}
                      >
                        {companydatachildren.map((each, ind) => {
                          return (
                            <option key={ind} value={each}>
                              {" "}
                              {each}
                            </option>
                          );
                        })}
                      </select> */}
                      <Select
                        className="form-control"
                        id="clientuniqueid"
                        showSearch
                        style={{ width: "100%" }}
                        value={state.clientuniqueid}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) => handleClick(e)}
                      >
                        {companydatachildren}
                      </Select>
                    </div>
                    <div className="col-md-6">
                      <label className="label_cls">Service Level</label>
                      <select
                        className="form-control"
                        id="servicelevel"
                        value={state.servicelevel}
                        style={{ width: "100%" }}
                        onChange={(e) => handleChange("servicelevel", e)}
                      >
                        {servicele.map((each, ind) => {
                          return (
                            <option key={ind} value={each}>
                              {" "}
                              {each}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {state.servicelevel == "Commander" ||
                  state.servicelevel == "Explorer" ? (
                    <div className="row">
                      <div className="col-md-6">
                        <label className="label_cls">
                          Company Virtual Hours
                        </label>

                        <select
                          //   className="autoComplete"
                          className="form-control"
                          id="companyvirtualhours"
                          value={state.companyvirtualhours}
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            handleChange("companyvirtualhours", e)
                          }
                        >
                          {children.map((each, ind) => {
                            return (
                              <option key={ind} value={each}>
                                {each}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-md-6">
                        <label className="label_cls">
                          Individual Virtual hours
                        </label>
                        <select
                          //   className="autoComplete"
                          className="form-control"
                          id="individualvirtualhours"
                          value={state.individualvirtualhours}
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            handleChange("individualvirtualhours", e)
                          }
                        >
                          {children.map((each, ind) => {
                            return (
                              <option key={ind} value={each}>
                                {each}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {state.servicelevel == "Commander" ? (
                    <div className="row">
                      <div className="col-md-6">
                        <label className="label_cls">
                          Company in Person Hours
                        </label>
                        <select
                          //   className="autoComplete"
                          className="form-control"
                          id="companyinpersonhours"
                          value={state.companyinpersonhours}
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            handleChange("companyinpersonhours", e)
                          }
                        >
                          {children.map((each, ind) => {
                            return (
                              <option key={ind} value={each}>
                                {each}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-md-6">
                        <label className="label_cls">
                          Individual in Person Hours
                        </label>
                        <select
                          //   className="autoComplete"
                          className="form-control"
                          id="individualinpersonhours"
                          value={state.individualinpersonhours}
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            handleChange("individualinpersonhours", e)
                          }
                        >
                          {children.map((each, ind) => {
                            return (
                              <option key={ind} value={each}>
                                {each}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="row">
                    <div className="col-md-6">
                      <label className="label_cls">Access From Date</label>
                      <div className="date-pic-mypro mb-2">
                        <DatePicker
                          id="startdate"
                          dateFormat="MM/dd/yyyy"
                          selected={state.startDate}
                          selectsStart
                          minDate={new Date()}
                          maxDate={state.endDate}
                          onChange={(e) => handleDateChange(e, "startDate")}
                          placeholderText="Select start date ..."
                          className="form-control"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="label_cls">Access To Date</label>
                      <div className="date-pic-mypro mb-2">
                        <DatePicker
                          id="enddate"
                          dateFormat="MM/dd/yyyy"
                          selected={state.endDate}
                          selectsEnd
                          autoComplete="off"
                          startDate={state.startDate}
                          endDate={state.endDate}
                          onChange={(e) => handleDateChange(e, "endDate")}
                          minDate={
                            state.startDate == null
                              ? new Date()
                              : state.startDate
                          }
                          placeholderText="Select end date ..."
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="floating-label">
                        <input
                          placeholder="PO"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          value={state.po}
                          id="po"
                          onChange={(e) => handleChange("po", e)}
                        />
                        <label>PO</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="floating-label">
                        <input
                          placeholder="ReferenceId"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          value={state.referenceid}
                          id="referenceid"
                          onChange={(e) => handleChange("referenceid", e)}
                        />
                        <label>ReferenceId</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="label_cls">Approved Hours</label>

                      <Select
                        // className="autoComplete"
                        className="form-control"
                        id="approvedhours"
                        value={state.approvedhours}
                        style={{ width: "100%" }}
                        disabled
                      >
                        {state.approvedhours}
                      </Select>
                    </div>
                    <div className="col-md-6">
                      <label className="label_cls">Is Temporary</label>
                      <select
                        className="form-control"
                        value={state.istemporary}
                        style={{ width: "100%" }}
                        onChange={(e) => handleChange("istemporary", e)}
                      >
                        <option key={0} value={true}>
                          {"Temporary"}
                        </option>
                        <option key={1} value={false}>
                          {"Permanent"}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label className="label_cls">Note</label>
                      <textarea
                        className="form-control"
                        rows="6"
                        value={state.usernotes}
                        placeholder="Notes"
                        id="usernotes"
                        onChange={(e) => handleChange("usernotes", e)}
                      />
                    </div>
                  </div>

                  <div className="text-center mt-3 mb-3">
                    {state.uploadLoader ? (
                      <Spin />
                    ) : (
                      <button
                        type="submit"
                        className="btn theme-btn"
                        onClick={(e) => addProperty(e)}
                      >
                        UPDATE
                      </button>
                    )}
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTransferee;
