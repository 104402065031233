import React from "react";

function PetRelocationModal() {
  const closeModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#petRelocationModal").modal("hide");
      });
    });
  };

  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header border-0">
          <div class="only_close">
            <span
              aria-hidden="true"
              className="pointer close"
              onClick={() => closeModal()}
              data-dismiss="modal"
            >
              ×
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div className="widthModal">
            <div className="item-view">
              <h2>Pet Relocation Guide </h2>
              <p className="mb-0">
                Relocating with a pet involves careful planning to ensure your
                furry friend’s safety and comfort during the move. This guide
                covers everything you need to know about preparing for the move,
                traveling with your pet, and settling them into their new home.
              </p>
            </div>

            <div className="tails-row">
              <div className="tails">
                <div className="item-view">
                  <h2>1. Preparing for the Move </h2>
                  <p className="ps-4">
                    Moving with pets requires extra planning to help them adjust
                    to the change in environment. Here are some important steps
                    to take before moving day:
                  </p>
                  <ul>
                    <li>
                      <b>Vet Check-Up:</b> Schedule a visit to the vet for a
                      check-up before the move. Ensure your pet’s vaccinations
                      are up to date, and obtain a health certificate if you’re
                      moving across state lines or internationally, as this may
                      be required by airlines or state regulations.
                    </li>
                    <li>
                      <b>Update ID Tags and Microchip Info:</b> Ensure your
                      pet’s ID tags reflect your new address and phone number.
                      If your pet is microchipped, update the contact
                      information associated with the chip.
                    </li>
                    <li>
                      <b>Obtain Travel Documents: </b> For international moves,
                      research pet passport requirements in your destination
                      country. Depending on where you’re moving, you may need
                      additional documentation, like proof of rabies vaccination
                      or an import permit.
                    </li>
                    <li>
                      <b>Plan for Pet-Friendly Accommodations: </b> If your
                      journey involves overnight stays, make sure to book
                      pet-friendly hotels. Plan your travel route to include
                      regular rest stops to allow your pet to stretch, eat, and
                      drink.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>2. Choosing Pet-Friendly Transportation </h2>
                  <p className="ps-4">
                    Selecting the right mode of transportation is crucial to
                    keeping your pet safe and comfortable during the move:
                  </p>
                  <ul>
                    <li>
                      <b>Air Travel: </b>If you’re flying with your pet,
                      research airlines that allow pets in the cabin or cargo
                      hold. Smaller pets may be allowed to travel in the cabin,
                      while larger pets will need to be transported in the cargo
                      hold. Ensure you follow airline guidelines for crate size
                      and weight limits.
                      <ul>
                        <li>
                          <b>In-Cabin Travel: </b> Pets must be able to fit
                          comfortably in a travel carrier that fits under the
                          seat. Check the airline’s policies for fees,
                          restrictions, and size limits.{" "}
                        </li>
                        <li>
                          <b>Cargo Travel: </b>If your pet must travel in the
                          cargo hold, choose direct flights when possible to
                          minimize travel time and reduce stress. Ensure your
                          pet’s crate is sturdy, well-ventilated, and labeled
                          with emergency contact information.
                        </li>
                      </ul>
                    </li>

                    <li>
                      <b>Car Travel: </b>If driving, ensure your pet is
                      comfortable in a vehicle. Secure them with a pet seatbelt
                      or in a crate to prevent injuries in case of sudden stops.
                      Make frequent stops to let your pet relieve themselves and
                      move around.
                      <ul>
                        <li>
                          <b>Travel Essentials: </b>Pack food, water, bowls,
                          leashes, waste bags, and any necessary medications.
                          Keep their favorite toys or blankets nearby to provide
                          comfort.
                        </li>
                      </ul>
                    </li>

                    <li>
                      <b>Pet Transport Services: </b>For long-distance moves,
                      especially international relocations, consider hiring a
                      professional pet relocation service. These companies
                      specialize in transporting pets safely and handling the
                      necessary paperwork for customs and regulations.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>3. Pet-Proofing Your New Home </h2>
                  <p className="ps-4">
                    Before your pet arrives in their new home, take some time to
                    ensure the environment is safe:
                  </p>
                  <ul>
                    <li>
                      <b>Secure Potential Hazards: </b> Look for exposed wires,
                      toxic plants, or small objects that could be swallowed.
                      Set up gates or barriers if necessary to restrict access
                      to certain areas.
                    </li>
                    <li>
                      <b>Set Up a Safe Space: </b> When your pet first arrives,
                      they may be overwhelmed. Set up a quiet, comfortable space
                      with familiar items like their bed, toys, and food bowls
                      to help them feel secure.
                    </li>
                    <li>
                      <b>Introduce Them Gradually: </b> Let your pet explore
                      their new surroundings at their own pace. For dogs, start
                      with leashed walks around the neighborhood. For cats,
                      allow them to explore one room at a time before giving
                      them access to the entire home.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="tails">
                <div className="item-view">
                  <h2>4. International Pet Relocation </h2>
                  <p className="ps-4">
                    If you’re moving internationally, you’ll need to follow
                    specific regulations depending on the destination country:
                  </p>
                  <ul>
                    <li>
                      <b>Research Import Laws: </b> Many countries have strict
                      import laws regarding pets. Some may require quarantine
                      periods, specific vaccinations, or health certifications.
                      Check with the country’s consulate or agricultural
                      department for pet import requirements.
                    </li>
                    <li>
                      <b>Pet Quarantine: </b> If your destination country
                      requires quarantine, research local quarantine facilities
                      in advance. Make sure the facility is safe, clean, and
                      well-maintained. Some countries offer home quarantine
                      options, so be sure to explore all available choices.
                    </li>
                    <li>
                      <b>Pet Travel Crates for Long Distances: </b>Make sure the
                      travel crate meets International Air Transport Association
                      (IATA) standards. The crate should be spacious enough for
                      your pet to stand, turn around, and lie down comfortably.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>5. Settling in Your Pet </h2>
                  <p className="ps-4">
                    After the move, your pet will need time to adjust to their
                    new home and routine:
                  </p>
                  <ul>
                    <li>
                      <b>Maintain Their Routine: </b> Stick to your pet’s usual
                      feeding, walking, and play schedules to create a sense of
                      familiarity and reduce anxiety.
                    </li>
                    <li>
                      <b>Explore the Neighborhood: </b> Once your pet seems
                      comfortable indoors, start taking them outside to explore
                      the new environment. Keep walks on a leash initially,
                      especially if your pet is unfamiliar with the area.
                    </li>
                    <li>
                      <b>Locate Local Pet Services: </b>Find local
                      veterinarians, pet stores, groomers, and dog parks to
                      ensure your pet’s needs are met. Keep a list of emergency
                      vet services on hand.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>6. Moving Day Tips for Pets </h2>
                  <p className="ps-4">
                    On the actual moving day, it’s important to keep your pet
                    safe and comfortable amidst all the activity:
                  </p>
                  <ul>
                    <li>
                      <b>Keep Them Separated from the Chaos: </b>Consider
                      boarding your pet or leaving them with a trusted friend
                      while movers are in and out. If this isn’t possible, set
                      up a quiet, secure room for them with their essentials.
                    </li>
                    <li>
                      <b>Ensure Proper Identification: </b> In case your pet
                      gets spooked and escapes during the move, make sure
                      they’re wearing proper ID tags with updated contact
                      information. Microchipping can also provide extra
                      security.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="item-view mt-2">
              <h2>Final Thoughts </h2>
              <p className="mb-0">
                Relocating with a pet may seem daunting, but with careful
                planning and preparation, the transition can be smooth for both
                you and your furry companion. By ensuring your pet’s safety
                during travel, helping them adjust to a new home, and adhering
                to any required regulations, you’ll make the move a positive
                experience for everyone involved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PetRelocationModal;
