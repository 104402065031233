import React from "react";

function PublicFooter() {
  const demoBtn = () => {
    location.href = "/contact-us";
  };
  return (
    <div className="footer text-center mt-5">
      <div className="container pt-5">
        <h2 className="text-white mt-5 pt-4" data-aos="fade-up">
          Experience the Power of ReloNavigator.
        </h2>
        <h2 className="text-white" data-aos="fade-up">
          Tailored Solutions for Every Move
        </h2>
        <button
          type="button"
          className="btn mt-4 py-2"
          data-aos="fade-up"
          onClick={() => demoBtn()}
        >
          Request a Demo
        </button>

        <div className="spacing"></div>

        <div className="row">
          <div className="col-md-5 text-start mb-5" data-aos="fade-up">
            <img
              src="assets/publicImages/w-logo-svg.svg"
              alt=""
              className="logo"
            />

            <ul className="social my-5">
              <li>
                <a href="#">
                  <img src="assets/publicImages/footer/linkdin.png" alt="" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="assets/publicImages/footer/facebook.png" alt="" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="assets/publicImages/footer/insta.png" alt="" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="assets/images/footer/youtube.png" alt="" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="assets/publicImages/footer/twitter.png" alt="" />
                </a>
              </li>
            </ul>

            <div>
              <a
                href="https://apps.apple.com/us/app/relonavigator-app/id6499023704?uo=2"
                target="_blank"
              >
                <img
                  src="assets/publicImages/footer/appStore.png"
                  alt=""
                  className="me-4 mb-3"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.relonavigator&pli=1"
                target="_blank"
              >
                <img
                  src="assets/publicImages/footer/playStore.png"
                  alt=""
                  className="mb-3"
                />
              </a>
            </div>
          </div>
          <div className="col-md-7 gridView" data-aos="fade-up">
            <div className="">
              <h4>Company</h4>
              <ul>
                <li>
                  <a href="#"> About us </a>
                </li>
                <li>
                  <a href="#"> Blog </a>
                </li>
                <li>
                  <a href="#"> Carrers </a>
                </li>
              </ul>
            </div>
            <div>
              <h4>Resources</h4>
              <ul>
                <li>
                  <a href="/contact-us"> Request a Demo </a>
                </li>
                <li>
                  <a href="#"> Contact us </a>
                </li>
                <li>
                  <a href="#"> Carrers </a>
                </li>
              </ul>
            </div>
            <div>
              <h4>Legal</h4>
              <ul>
                <li>
                  <a href="#"> Legal </a>
                </li>
                <li>
                  <a href="#"> Security </a>
                </li>
                <li>
                  <a href="#"> Privacy Policy </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublicFooter;
