import React from "react";
import RentalCard from "../components/RentalCard";

const NotIntrestedRentalsModal = ({ saPropertiesListInactive }) => {
  return (
    <div
      className="modal fade"
      id="not-intrested-rentals"
      tabIndex="-1"
      aria-labelledby="not-intrested-rentals"
      aria-hidden="true"
      style={{ zIndex: 1060 }}
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="width-80per pd-y-60">
            <div className="only_close">
              <span
                className="pointer close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                &times;
              </span>
            </div>
            <h6>Not Interested Rentals</h6>

            <div className="modal-body mb-4 scorllInterested">
              {saPropertiesListInactive.length > 0 ? (
                <div className="my_properties_lists">
                  <div className="main_content">
                    <div className="container">
                      <div className="main_grid" style={{ border: "none" }}>
                        {saPropertiesListInactive.map((row, index) => {
                          return row.isinterested != true ? (
                            <RentalCard
                              propertyDetails={row}
                              isNotIntrested={true}
                              key={index}
                            />
                          ) : null;
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="main_grid">No data available</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotIntrestedRentalsModal;
