import React from "react";
import ConsultantmyItenaries from "../components/ConsultantmyItenaries";

const Itinerary = ({ userUniqueId }) => {
  return (
    <div id="tabItinerary" className={" tab-pane fade active show "}>
      {userUniqueId && <ConsultantmyItenaries userUniqueId={userUniqueId} />}
    </div>
  );
};

export default Itinerary;
