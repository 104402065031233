import React from "react";
import Slider from "rc-slider";
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
import "rc-slider/assets/index.css";

function RangeComponent(props) {
  const {
    state,
    questionList,
    onChange,
    questionIndex,
    showAnnualMonthlyRent,
  } = props;
  return (
    <div
      key={"sub" + questionIndex}
      className={
        questionList.type == "money-range" ? " range2" : " range2 range"
      }
    >
      <h1>
        {questionList.question}{" "}
        {questionList.type == "money-range" ? (
          <a
            className="help"
            href="javascript:void(0)"
            onClick={() => showAnnualMonthlyRent()}
          >
            Help me choose
          </a>
        ) : (
          ""
        )}
      </h1>
      {questionList.description != null ? (
        <h6>{questionList.description}</h6>
      ) : null}
      <div>
        <Range
          min={questionList.type == "money-range" ? 500 : 0}
          tipProps={{ visible: true }}
          marks={
            questionList.type == "money-range"
              ? { 500: "500", 15000: "15000$" }
              : { 0: "0", 60: "60 min" }
          }
          step={questionList.type == "money-range" ? 50 : 1}
          max={questionList.type == "money-range" ? 15000 : 60}
          onChange={(value) =>
            onChange(questionList.preferenceUId, questionList.type, value)
          }
          defaultValue={
            questionList.type == "money-range"
              ? [
                  state.answersPrefer[questionList.preferenceUId] != undefined
                    ? parseInt(
                        state.answersPrefer[
                          questionList.preferenceUId
                        ].preferencesanswer.split(",")[0]
                      )
                    : 500,
                  state.answersPrefer[questionList.preferenceUId] != undefined
                    ? parseInt(
                        state.answersPrefer[
                          questionList.preferenceUId
                        ].preferencesanswer.split(",")[1]
                      )
                    : 1000,
                ]
              : [
                  state.answersPrefer[questionList.preferenceUId] != undefined
                    ? parseInt(
                        state.answersPrefer[
                          questionList.preferenceUId
                        ].preferencesanswer.split(",")[0]
                      )
                    : 0,
                  state.answersPrefer[questionList.preferenceUId] != undefined
                    ? parseInt(
                        state.answersPrefer[
                          questionList.preferenceUId
                        ].preferencesanswer.split(",")[1]
                      )
                    : 30,
                ]
          }
          tipFormatter={
            questionList.type == "money-range"
              ? (value) => `$ ${value}`
              : (value) => `${value} min`
          }
        />
      </div>
    </div>
  );
}

export default RangeComponent;
