import React, { useEffect, useState, useRef, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExpand,
  faTimes,
  faPaperPlane,
  faChevronLeft,
  faUserCircle,
} from "@fortawesome/fontawesome-free-solid";
import { Popover, Spin } from "antd";
import moment from "moment";
import LoginStore from "../../../../services/thirdPartyService";
import ApiServices from "../../../../services/apiServices";
import { filterProperties } from "@turf/turf";
import EmojiPicker from "emoji-picker-react";

var chatMessagesMap = new Map();

const Chatting = () => {
  const [state, setState] = useState({
    loader: false,
  });
  const [sessionData, setSessionData] = useState({});
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [TransfereesList, setTransfereesList] = useState([]);
  const [SortedFilteredTransfereesList, setSortedFilteredTransfereesList] =
    useState([]);
  const [propertiesList, setPropertiesList] = useState([]);
  const [filteredPropertiesList, setFilteredPropertiesList] = useState([]);
  const [aggregatedMap, setAggregatedMap] = useState({});
  const [activeTransferee, setActiveTransferee] = useState(null);
  const [activeProperty, setActiveProperty] = useState(false);

  const chatMsgsRef = useRef(null);
  const HubConnection = useRef(null);
  const Hub = useRef(null);

  // =======================================================
  // Utilities
  // =======================================================

  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  function changeLoaderState(mode) {
    if (mode === "start") {
      updateState({ loader: true });
    } else {
      updateState({ loader: false });
    }
  }

  // =======================================================
  // sending message
  // =======================================================

  function sendMessage(text) {
    text = text.trim();
    if (!text) return;

    const { userUniqueId: loggedInUserId } = sessionData;
    const { userUniqueId: tranfereeId } = activeTransferee || {};
    const { propertyanalyticsuniqueId: propertyId } = activeProperty || {};

    if (!loggedInUserId || !tranfereeId || !propertyId) return;

    let message = {
      apiId: propertyId,
      message: text,
      fromId: loggedInUserId,
      toId: tranfereeId,
      datetime: moment()
        .tz("America/Los_Angeles")
        .format("MM/DD/YYYY HH:mm:ss"),
      isconsultant: true,
    };

    Hub.current.invoke("SendMessage", message);
    updateAggregatedMap(message, "sent");
  }

  // =======================================================
  // Fetch functions (API calls)
  // =======================================================
  async function getSessionData() {
    try {
      let apiSessionData = await LoginStore.getLoginInfo({});
      apiSessionData.subscriptioncode =
        localStorage.getItem("loginSubscribeDetails") != null
          ? JSON.parse(localStorage.getItem("loginSubscribeDetails"))
              .subscriptioncode
          : "";
      return apiSessionData;
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchPropertiesList(TransfereeId) {
    let transfereeProperties = await ApiServices.GetTransfereeProperties({
      transfereeuniqueId: TransfereeId,
    });
    let transfereepropertyList =
      transfereeProperties.message.transfereepropertyList;
    let filteredPropertiesList = transfereepropertyList.filter(function (
      element
    ) {
      return element.source != "zillow";
    });
    return filteredPropertiesList;
  }

  async function fetchTransfereeList(loggedInUserId) {
    let response = await ApiServices.GetConsultantTransferees({
      consultantuniqueid: loggedInUserId,
    });
    if (response.error == 0) {
      let transferees = response.message.transfereeslist;

      transferees = transferees.map((transferee) => {
        const createdAtDate = new Date(transferee.transfereecreatedat);
        const formattedDate = `${createdAtDate.getDate()} ${createdAtDate.toLocaleString(
          "default",
          { month: "short" }
        )}`;

        return {
          ...transferee,
          createdDate: formattedDate,
        };
      });

      const filteredTransfereeList = transferees.filter((transferee) => {
        const { EmailId, subscriptioncode, userFirstName } = transferee;
        return (
          EmailId &&
          subscriptioncode != "ReloNavigator" &&
          userFirstName != "v5QCdDCaM9UdpHurW0z8nw=="
        );
      });
      return filteredTransfereeList;
    }
    return [];
  }

  // =======================================================
  // click handlers
  // =======================================================

  async function handleClickTransfereeCard(TransfereeId) {
    changeLoaderState("start");

    setActiveProperty(null);

    let activeTransferee = SortedFilteredTransfereesList.filter(
      (transferee, id) => {
        return transferee.userUniqueId === TransfereeId;
      }
    );
    setActiveTransferee(activeTransferee[0]);

    const propertiesList = await fetchPropertiesList(TransfereeId);
    setPropertiesList(propertiesList);
    sortProperties(activeTransferee[0]);
    changeLoaderState("stop");
  }

  async function handleClickPropertyCard(property) {
    changeLoaderState("start");

    if (property) {
      setActiveProperty(property);
    }

    changeLoaderState("stop");
  }

  // =======================================================
  // Data aggregation
  // =======================================================
  function TransfereeUnreadMsgCount(count) {
    if (!count) return;
    const loggedInUserId = sessionData.userUniqueId;
    const mapTransfereeMsgCount = count.reduce(
      (acc, { FromId, ToId, APISource, Count }) => {
        if (!acc[FromId]) acc[FromId] = { totalUnreadMsgs: 0, properties: {} };
        if (!acc[FromId].properties[APISource]) {
          acc[FromId].properties[APISource] = 0;
        }
        if (ToId === loggedInUserId) {
          acc[FromId].totalUnreadMsgs = acc[FromId].totalUnreadMsgs + Count;
          acc[FromId].properties[APISource] =
            acc[FromId].properties[APISource] + Count;
        }
        return acc;
      },
      {}
    );

    return mapTransfereeMsgCount;
  }

  function prepareMapFromMessagesList(messagesList, mapTransfereeMsgCount) {
    /**
     * =================================================================
     * aggregated map format
     * =================================================================
     * {
     *   transfereeId: {
     *     properties:{
     *         propertyId: [...messagesList]
     *     },
     *     lastMsgTimestamp: "09/08/2020 08:09:10",
     *     unreadMsgCount : 3
     *   }
     * }
     *
     * =================================================================
     * mapTransfereeMsgCount format
     * =================================================================
     * {transfereeId: unreadCount}
     *
     * =================================================================
     * messagesList format
     * =================================================================
     * {
     *  message : "1234"
     *  fromId : "bf3103b5-5185-493e-897c-75e36847b220"
     *  toId : "8e7d6301-1cbe-4d59-895d-35765d5d1015"
     *  apiId : "0000111122223333"
     *  datetime : "04/26/2021 02:25:27"
     *  firstname : "Consultant"
     *  lastname : "Prod"
     *  email : "consultant@yopmail.com"
     *  profilepic : ""
     *  ismessageread : true
     * }
     */

    const loggedInUserId = sessionData.userUniqueId;
    const map = {};
    for (let i = 0; i < messagesList.length; i++) {
      const message = messagesList[i];
      const { toId, fromId, apiId } = message;
      const transfereeId = toId == loggedInUserId ? fromId : toId;
      if (!map[transfereeId]) {
        map[transfereeId] = {
          properties: {},
          lastMsgTimestamp: "",
          unreadMsgCount: 0,
        };
      }
      if (!map[transfereeId].properties[apiId]) {
        map[transfereeId].properties[apiId] = {
          messages: [],
          lastMsgTimestamp: "",
        };
      }
      map[transfereeId].properties[apiId].messages.push(message);
    }

    for (const transfereeId in map) {
      const properties = map[transfereeId].properties;
      let lastMsgTimestamp = "";
      for (const apiId in properties) {
        const messages = properties[apiId].messages;
        messages.sort((a, b) => new Date(a.datetime) - new Date(b.datetime));
        map[transfereeId].properties[apiId].lastMsgTimestamp =
          messages[messages.length - 1]?.datetime;
        map[transfereeId].properties[apiId].unreadMsgCount =
          mapTransfereeMsgCount[transfereeId]?.properties[apiId];
        if (
          lastMsgTimestamp === "" ||
          new Date(messages[messages.length - 1].datetime) >
            new Date(lastMsgTimestamp)
        ) {
          lastMsgTimestamp = messages[messages.length - 1].datetime;
        }
      }
      map[transfereeId].lastMsgTimestamp = lastMsgTimestamp;
      map[transfereeId].unreadMsgCount =
        mapTransfereeMsgCount[transfereeId]?.totalUnreadMsgs;
    }
    return map;
  }

  function updateAggregatedMap(message, type) {
    let transfereeId;
    const { apiId, datetime } = message;
    if (type == "received") {
      transfereeId = message.fromId;
    }

    if (type == "sent") {
      transfereeId = message.toId;
    }

    setAggregatedMap((prev) => {
      const updatedAggregatedMap = { ...prev };
      if (!updatedAggregatedMap[transfereeId]) {
        updatedAggregatedMap[transfereeId] = {
          properties: {},
          lastMsgTimestamp: "",
          unreadMsgCount: 0,
        };
      }
      if (!updatedAggregatedMap[transfereeId].unreadMsgCount) {
        updatedAggregatedMap[transfereeId].unreadMsgCount = 0;
      }

      if (!updatedAggregatedMap[transfereeId].properties[apiId]) {
        updatedAggregatedMap[transfereeId].properties[apiId] = {
          messages: [],
          lastMsgTimestamp: "",
          unreadMsgCount: 0,
        };
      }
      if (
        !updatedAggregatedMap[transfereeId].properties[apiId].unreadMsgCount
      ) {
        updatedAggregatedMap[transfereeId].properties[apiId].unreadMsgCount = 0;
      }

      updatedAggregatedMap[transfereeId].properties[apiId].messages.push(
        message
      );
      updatedAggregatedMap[transfereeId].properties[apiId].lastMsgTimestamp =
        datetime;
      if (type == "received")
        updatedAggregatedMap[transfereeId].properties[
          apiId
        ].unreadMsgCount += 1;

      updatedAggregatedMap[transfereeId].lastMsgTimestamp = datetime;
      if (type == "received")
        updatedAggregatedMap[transfereeId].unreadMsgCount += 1;

      return updatedAggregatedMap;
    });
  }

  // =======================================================
  // Sort transferee and properties
  // =======================================================

  function sortTransferees() {
    setTransfereesList((prev) => {
      let sortedList = [...prev].sort((a, b) => {
        const dateA = aggregatedMap[a.userUniqueId]?.lastMsgTimestamp
          ? new Date(aggregatedMap[a.userUniqueId].lastMsgTimestamp)
          : new Date(0);
        const dateB = aggregatedMap[b.userUniqueId]?.lastMsgTimestamp
          ? new Date(aggregatedMap[b.userUniqueId].lastMsgTimestamp)
          : new Date(0);

        return dateB - dateA;
      });

      return sortedList;
    });
  }

  function sortProperties(activeTransferee) {
    if (!activeTransferee) return;
    const tranfereeId = activeTransferee.userUniqueId;
    setPropertiesList((prev) => {
      let sortedList = [...prev].sort((a, b) => {
        const dateA = aggregatedMap[tranfereeId]?.properties[
          a.propertyanalyticsuniqueId
        ]?.lastMsgTimestamp
          ? new Date(
              aggregatedMap[tranfereeId].properties[
                a.propertyanalyticsuniqueId
              ].lastMsgTimestamp
            )
          : new Date(0);
        const dateB = aggregatedMap[tranfereeId]?.properties[
          b.propertyanalyticsuniqueId
        ]?.lastMsgTimestamp
          ? new Date(
              aggregatedMap[tranfereeId].properties[
                b.propertyanalyticsuniqueId
              ].lastMsgTimestamp
            )
          : new Date(0);
        return dateB - dateA;
      });

      return sortedList;
    });
  }

  // =======================================================
  // Search filters for transferee and properties
  // =======================================================

  function searchTransferee(value) {
    if (value) {
      const filterTransferee = TransfereesList.filter(
        (o) =>
          o.userFirstName.toLowerCase().includes(value.toLowerCase()) ||
          o.EmailId.toLowerCase().includes(value.toLowerCase())
      );
      setSortedFilteredTransfereesList(filterTransferee);
      return;
    }
    setSortedFilteredTransfereesList(TransfereesList);
  }

  function searchProperty(value) {
    if (value) {
      const filteredProperties = propertiesList.filter(
        (o) =>
          o.propertyname.toLowerCase().includes(value.toLowerCase()) ||
          o.address.toLowerCase().includes(value.toLowerCase())
      );

      setFilteredPropertiesList(filteredProperties);
      return;
    }
    setFilteredPropertiesList(propertiesList);
  }

  // =======================================================
  // Handling messages from Hub
  // =======================================================

  function markChatAsRead() {
    const loggedInUserId = sessionData.userUniqueId;
    const { userUniqueId: transfereeId } = activeTransferee || {};
    const { propertyanalyticsuniqueId: propertyId } = activeProperty || {};

    Hub.current.invoke("MessageReadComplete", {
      toId: loggedInUserId,
      fromId: transfereeId,
      apiId: propertyId,
    });

    setAggregatedMap((prev) => {
      const updatedAggregatedMap = { ...prev };

      if (!updatedAggregatedMap[transfereeId]) return updatedAggregatedMap;
      if (!updatedAggregatedMap[transfereeId].unreadMsgCount)
        return updatedAggregatedMap;

      if (!updatedAggregatedMap[transfereeId].properties[propertyId])
        return updatedAggregatedMap;
      if (
        !updatedAggregatedMap[transfereeId].properties[propertyId]
          .unreadMsgCount
      )
        return updatedAggregatedMap;

      updatedAggregatedMap[transfereeId].unreadMsgCount -=
        updatedAggregatedMap[transfereeId].properties[
          propertyId
        ].unreadMsgCount;
      updatedAggregatedMap[transfereeId].properties[
        propertyId
      ].unreadMsgCount = 0;
      return updatedAggregatedMap;
    });
  }

  async function processArrayResponse(data) {
    data.map((message) => {
      updateAggregatedMap(message, "received");
    });

    updateState({
      showChatMsg: true,
    });
  }

  function processObjectResponse(data) {
    const { messagesList, count } = data;

    const mapTransfereeMsgCount = TransfereeUnreadMsgCount(count);
    setAggregatedMap(
      prepareMapFromMessagesList(messagesList, mapTransfereeMsgCount)
    );
  }

  // =======================================================
  // Event Handlers for Hub
  // =======================================================

  async function processMessageFromServer(data) {
    // console.log(data, "processMessageFromServer");
    if (!data) return;
    const { messagesList, onlineusers, count } = data;

    // single message from server
    if (!messagesList && !onlineusers) {
      processArrayResponse(data);
    } else if (count) {
      processObjectResponse(data);
    }
    if (onlineusers && onlineusers.length > 0) {
      setOnlineUsers(onlineusers);
      updateState({ onlineusers: onlineusers });
    }
  }

  // =======================================================
  // function used when components loads
  // =======================================================

  async function transfereeList() {
    changeLoaderState("start");

    const sessionDataRes = await getSessionData();
    setSessionData(sessionDataRes);

    let response = await fetchTransfereeList(sessionDataRes.userUniqueId);
    setTransfereesList(response);

    changeLoaderState("stop");
  }

  async function makeConnectionSignalR() {
    $(async function () {
      var ChatUrl = "https://apis.relonavigator.com/" + "signalr";
      HubConnection.current = $.hubConnection(ChatUrl, {
        useDefaultPath: false,
      });

      Hub.current = await HubConnection.current.createHubProxy("ChatHub");
      Hub.current.on("getLogoutUsersList", (data) =>
        setOnlineUsers(data.onlineusers)
      );
      Hub.current.on("messageFromServer", processMessageFromServer.bind(this));

      connectToHub();

      changeLoaderState("stop");

      HubConnection.current.disconnected(function () {
        console.log("Disconnected from SignalR. Attempting to reconnect...");
        connectToHub();
      });
    });
  }

  function disconnectSignalR() {
    if (HubConnection.current) {
      HubConnection.current.stop();
      console.log("Disconnected from SignalR");
    } else {
      console.log("No active SignalR connection to disconnect.");
    }
  }

  function connectToHub() {
    HubConnection.current
      .start()
      .done(function (data) {
        updateState({ chatIdSignalR: data.id });
        Hub.current.invoke("RegisterOnlineUser", {
          userId: sessionData.userUniqueId,
          apiId: 0,
          connectionId: data.id,
          isconsultent: true,
        });
      })
      .fail(function () {
        console.log("failed in connecting to the signalr server");
      });
  }

  // ========================================================
  // useEffect
  // ========================================================

  useEffect(() => {
    transfereeList();
    return () => {
      Hub.current.invoke("LogoutUserChatt", { userId: "" });
      disconnectSignalR();
    };
  }, []);

  useEffect(() => {
    if (TransfereesList.length && !HubConnection.current) {
      makeConnectionSignalR();
    }
  }, [TransfereesList]);

  useEffect(() => {
    if (TransfereesList.length && onlineUsers.length) {
      setTransfereesList(
        TransfereesList.map((transferee) => {
          const isOnline = onlineUsers.indexOf(transferee.userUniqueId) > -1;
          return {
            ...transferee,
            isOnline,
          };
        })
      );
    }
  }, [onlineUsers]);

  useEffect(() => {
    if (TransfereesList.length) {
      setSortedFilteredTransfereesList([...TransfereesList]);
    }
  }, [TransfereesList]);

  useEffect(() => {
    if (chatMsgsRef.current) {
      chatMsgsRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [chatMessagesMap]);

  useEffect(() => {
    if (!aggregatedMap) return;
    if (!Object.keys(aggregatedMap).length) return;
    sortTransferees();
    sortProperties(activeTransferee);
  }, [aggregatedMap]);

  useEffect(() => {
    if (!propertiesList) return;
    setFilteredPropertiesList([...propertiesList]);
  }, [propertiesList]);

  useEffect(() => {
    if (!activeProperty) return;
    markChatAsRead();
  }, [activeProperty]);

  return (
    <div>
      <div className="chat_footer chat_footer-fullview">
        {state.loader && <div className="loading">Loading</div>}

        <div className="communitie_pop">
          <div className="d-flex d-flex-chat">
            <div className="item">
              <h5>Transferees</h5>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="bi bi-search"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Username"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={(e) => searchTransferee(e.target.value)}
                />
              </div>
              <div className="contacts_list" id="scroll-Top">
                {SortedFilteredTransfereesList.map((row, index) => (
                  <TransefereeCard
                    row={row}
                    handleClickTransfereeCard={handleClickTransfereeCard}
                    activeTransferee={activeTransferee}
                    aggregatedMap={aggregatedMap}
                    key={index}
                  />
                ))}
              </div>
            </div>

            <div className="item properties">
              <h5>Properties</h5>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="bi bi-search"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Propertyname"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={(e) => searchProperty(e.target.value)}
                />
              </div>
              {activeTransferee && (
                <>
                  {!state.loader ? (
                    <div className="contacts_list">
                      <GeneralPropertyCard
                        activeTransferee={activeTransferee}
                        activeProperty={activeProperty}
                        handleClickPropertyCard={handleClickPropertyCard}
                        aggregatedMap={aggregatedMap}
                      />

                      {filteredPropertiesList.map((row, index) => {
                        return (
                          <PropertyCard
                            handleClickPropertyCard={handleClickPropertyCard}
                            row={row}
                            aggregatedMap={aggregatedMap}
                            activeTransferee={activeTransferee}
                            activeProperty={activeProperty}
                            key={index}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <div
                      className="contacts_list"
                      style={{ paddingTop: "100px" }}
                    >
                      <Spin />
                    </div>
                  )}
                </>
              )}
            </div>

            {activeTransferee && activeProperty && (
              <ChatHistory
                state={state}
                sessionData={sessionData}
                activeTransferee={activeTransferee}
                activeProperty={activeProperty}
                aggregatedMap={aggregatedMap}
                chatMsgsRef={chatMsgsRef}
                sendMessage={sendMessage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const PropertyCard = ({
  row,
  aggregatedMap,
  activeTransferee,
  activeProperty,
  handleClickPropertyCard,
}) => {
  const {
    id,
    propertyanalyticsuniqueId,
    property_image,
    propertyname,
    address,
    isinterested,
  } = row;
  const { propertyanalyticsuniqueId: activePropertyId } = activeProperty || {};

  const countUnread =
    aggregatedMap[activeTransferee.userUniqueId]?.properties[
      propertyanalyticsuniqueId
    ]?.unreadMsgCount;

  return (
    <div
      className={`chat_item d-flex align-items-start justify-content-start ${
        activePropertyId == propertyanalyticsuniqueId ? "activeItem" : ""
      }`}
      onClick={() => handleClickPropertyCard(row)}
    >
      <div className="item_img bd-highlight">
        <i className="bi bi-check-circle-fill"></i>
        <img
          src={
            property_image
              ? property_image.split(",")[0]
              : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
          }
          alt=""
        />
        <div className={"msg_count" + (countUnread > 0 ? "" : " hideBadge")}>
          <span className="badge">{countUnread}</span>
        </div>
      </div>
      <div className="chat_dtls">
        <span>
          {propertyname} - {address}
        </span>
        <b className="userPropertyStatus">{isinterested ? "" : "Inactive"}</b>
      </div>
    </div>
  );
};

const GeneralPropertyCard = ({
  activeTransferee,
  activeProperty,
  handleClickPropertyCard,
  aggregatedMap,
}) => {
  const generalProperty = {
    id: "0000111122223333",
    propertyanalyticsuniqueId: "0000111122223333",
    property_image: "assets/images/default-img.jpg",
    address: "General-Category",
    countUnread: 0,
    isactive: true,
    isinterested: true,
  };
  const { userUniqueId: tranfereeId } = activeTransferee || {};
  const { propertyanalyticsuniqueId: activePropertyId } = activeProperty || {};
  const countUnread =
    aggregatedMap[tranfereeId]?.properties[
      generalProperty.propertyanalyticsuniqueId
    ]?.unreadMsgCount;
  return (
    <div
      key={100}
      className={`chat_item d-flex align-items-center justify-content-start ${
        activePropertyId == generalProperty.propertyanalyticsuniqueId
          ? "activeItem"
          : ""
      }`}
      onClick={() => handleClickPropertyCard(generalProperty)}
    >
      <div className="item_img bd-highlight">
        <i className="bi bi-check-circle-fill"></i>
        <img
          src={
            generalProperty.property_image?.split(",")[0] ||
            "assets/images/default-img.jpg"
          }
          alt=""
        />
        <div className={"msg_count" + (countUnread > 0 ? "" : " hideBadge")}>
          <span className="badge">{countUnread}</span>
        </div>
      </div>
      <div className="chat_dtls">
        <span>{generalProperty.address} </span>
      </div>
    </div>
  );
};

const TransefereeCard = ({
  row,
  handleClickTransfereeCard,
  activeTransferee,
  aggregatedMap,
}) => {
  const {
    userUniqueId: TransfereeId,
    userId,
    userFirstName,
    isOnline,
    EmailId,
    createdDate,
  } = row;
  const { userUniqueId: ActiveTransfereeId } = activeTransferee || {};
  const unreadMsgCount = aggregatedMap[TransfereeId]?.unreadMsgCount;

  return (
    <div
      className={`chat_item d-flex align-items-center justify-content-start ${
        ActiveTransfereeId == TransfereeId ? "activeItem" : ""
      } `}
      onClick={() => handleClickTransfereeCard(TransfereeId, userId)}
    >
      <div className="item_img bd-highlight">
        <FontAwesomeIcon icon={faUserCircle} />
        <div className={`msg_count ${unreadMsgCount ? "" : "hideBadge"}`}>
          <span className="badge transBdCount">{unreadMsgCount}</span>
        </div>
      </div>
      <div className="chat_dtls">
        <p>
          {userFirstName}{" "}
          <i className={`fa fa-circle ${isOnline ? "online" : "offline"}`}></i>
        </p>
        <span>{EmailId}</span>
        <span className="dataTime"> {createdDate}</span>
      </div>
    </div>
  );
};

const ChatHistory = ({
  sessionData,
  activeTransferee,
  activeProperty,
  aggregatedMap,
  chatMsgsRef,
  sendMessage,
}) => {
  const [TextMessage, setTextMessage] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const messageInputRef = useRef(null);
  const emojiPickerRef = useRef(null);

  const { userUniqueId: loggedInUserId } = sessionData;
  const { userUniqueId: tranfereeId } = activeTransferee;
  const { propertyanalyticsuniqueId: propertyId, isinterested } =
    activeProperty;
  const chatHistory =
    aggregatedMap[tranfereeId]?.properties[propertyId]?.messages;

  async function scrollDivBottom() {
    var objDiv = $("#chatPopup").height();
    $("#chatPopup").scrollTop(objDiv * 100);
    var objDiv2 = $("#chat_msgs").height();
    $("#chat_msgs").scrollTop(objDiv2 * 100);
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target) &&
        !event.target.classList.contains("bi-emoji-smile")
      ) {
        setShowEmojiPicker(false);
      }
    }

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleTextChange(event) {
    event.persist();
    let textMessage = event.target.value;
    setTextMessage(textMessage);
  }
  const toggleEmojiPicker = () => {
    console.log("hello");

    setShowEmojiPicker((prev) => !prev);
  };
  const handleEmojiClick = (emojiObject) => {
    setTextMessage((prev) => prev + emojiObject.emoji); // Append emoji
    messageInputRef.current.focus(); // Focus back on the input field
  };

  function handleSendMessage(event) {
    event.preventDefault();
    if (TextMessage.trim()) {
      sendMessage(TextMessage);
      setTextMessage("");
      setShowEmojiPicker(false);

      if (messageInputRef.current) {
        messageInputRef.current.focus();
      }
    }
  }

  useEffect(() => {
    scrollDivBottom();
  }, [activeProperty]);

  useEffect(() => {
    scrollDivBottom();
  });

  return (
    <div className="item item2">
      {/* Transferee Header */}
      <div className="contactDP">
        <img src="../assets/images/profile-pic.svg" alt="Profile" />
        <h5>
          {activeTransferee?.userFirstName}
          <div
            className={`circle ${
              activeTransferee?.isOnline ? "online" : "offline"
            }`}
          >
            Online
          </div>
        </h5>
        {/* <i className="bi bi-three-dots-vertical"></i> */}
      </div>

      {/* Chat Messages */}
      <div className="chat_msgs" id="chatPopup" ref={chatMsgsRef}>
        {chatHistory &&
          chatHistory.map((message, index) => {
            const isCurrentUser = loggedInUserId === message.fromId;

            return (
              <div key={index}>
                {isCurrentUser ? (
                  <div className="right_chat_view">
                    <div>
                      <div className="right-user-time">
                        {moment(message.datetime).calendar()}
                      </div>
                      <div className="right_chat">
                        {message.message}
                        <i className="bi bi-check-all ms-2"></i>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="left_chat_view">
                    <div>
                      <div className="left-user-time">
                        {message.firstname} {message.lastname},{" "}
                        {moment(message.datetime).calendar()}
                      </div>
                      <div className="left_chat">{message.message}</div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}

        <div className="clearfix"></div>
      </div>

      {/* Message Input */}
      <div className="sent_box d-flex align-items-center">
        <div className="position-relative w-100">
          <i
            className="bi bi-emoji-smile"
            onClick={toggleEmojiPicker}
            style={{ cursor: "pointer" }}
          ></i>
          {showEmojiPicker && (
            <div
              ref={emojiPickerRef}
              style={{
                position: "absolute",
                bottom: "50px",
                left: "0",
                zIndex: 10,
              }}
            >
              <EmojiPicker onEmojiClick={handleEmojiClick} />
            </div>
          )}
          <input
            ref={messageInputRef}
            type="text"
            id="InputMsg2"
            value={TextMessage}
            placeholder={isinterested ? "Send message" : "Property deactivated"}
            disabled={!isinterested}
            onChange={handleTextChange}
            onKeyDown={(e) => {
              // Check if the Enter key (key code 13) is pressed
              if (e.key === "Enter" && TextMessage.trim() !== "") {
                handleSendMessage(e); // Call the send message function
              }
            }}
          />
        </div>

        <span
          tabIndex="2"
          className={TextMessage ? "" : "chatIconColor"}
          onClick={(event) => handleSendMessage(event)}
        >
          <img src="../assets/images/Vector.svg" alt="Send" />
        </span>
      </div>
    </div>
  );
};

export default Chatting;
