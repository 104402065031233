import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import * as $ from "jquery";
import moment from "moment";
import { useLocation } from "react-router-dom";
// import { Select } from "antd";
import ApiServices from "../../../../../services/apiServices";
import LoginStore from "../../../../../services/thirdPartyService";
import { Spin } from "antd";

const Activity = (props) => {
  // console.log("Activity", props);
  const { timeLabels, getactivitys, handleClose, editActivityid } = props;
  const {
    activityShowSuccess,
    activityerrorMessage,
    activity,
    activeModal,
    billableevent,
    fromtimeselect,
    totimeselect,
    contactType,
    note,
    fromavailableTillDate,
    toavailableTillDate,
    transfereeactivityUId,
  } = props.data;
  const location = useLocation();
  const storedUserDetails = localStorage.getItem("moredetails");
  const userDetails = JSON.parse(storedUserDetails);
  const user =
    location.state && location.state.row ? location.state.row : userDetails;
  const intialState = {
    Activityshowingerror: "",
    editActivityid: editActivityid,
    activityShowSuccess: activityShowSuccess,
    activityerrorMessage: activityerrorMessage,
    activeModal: activeModal,
    Activityshowing: "",
    fromavailableTillDate: fromavailableTillDate,
    toavailableTillDate: toavailableTillDate,
    transfereeactivityUId: transfereeactivityUId,
    billableevent: billableevent,
    activityusernotes: "",
    activityuploadLoader: false,
    moredetails: user, //location.state.row,
    // timeLabels: timeLabels,
    fromtimeselect: fromtimeselect,
    totimeselect: totimeselect,
    note: note,
    twoarray: ["Select", "Phone", "Email", "In-Person", "Video call"],
    contactType: contactType,
    activityDropDown: [
      "Select ",
      "Introductory Call",
      "Pre-Move preparations",
      "Area Tours",
      "Property Tours",
      "Settling In services",
      "Touch Base",
      "Other",
    ],
    activity: activity,
    loader: false,
  };

  const [state, setState] = useState({});

  async function updateState(data) {
    await setState((prevState) => ({
      ...prevState,
      ...data,
    }));
  }

  async function handleChange(name, e) {
    // console.log(name, "=====", e.target.value);
    let value = e.target.value;
    let object = {};
    object[name] = value;
    if (name === "contactType") {
      object.toavailableTillDate = null;
    }
    if (value == null || value == "") {
      $("#" + name).addClass("is-invalid");
    } else {
      $("#" + name).removeClass("is-invalid");
    }
    await updateState(object);
  }

  async function updatesendbillableevent(e) {
    e.persist();
    updateState({ billableevent: !state.billableevent });
  }

  function handleDateChange(date, e) {
    let object = {};
    object[date] = e;
    if (date == "fromavailableTillDate") {
      updateState(object);
    } else {
      updateState(object);
    }
  }
  function closeModal() {
    // setState(intialState);

    handleClose("edit");
  }

  useEffect(() => {
    getactivitys();
  }, []);

  useEffect(() => {
    if (Object.keys(props.data).length) {
      console.log(props.data, "props.data");
      let data = props.data;
      setState({
        Activityshowingerror: "",
        editActivityid: editActivityid,
        activityShowSuccess: data.activityShowSuccess,
        activityerrorMessage: data.activityerrorMessage,
        activeModal: data.activeModal,
        Activityshowing: "",
        fromavailableTillDate: data.fromavailableTillDate,
        toavailableTillDate: data.toavailableTillDate,
        transfereeactivityUId: data.transfereeactivityUId,
        billableevent: data.billableevent,
        activityusernotes: data.note,
        activityuploadLoader: false,
        moredetails: user, //location.state.row,
        // timeLabels: timeLabels,
        fromtimeselect: data.fromtimeselect,
        totimeselect: data.totimeselect,
        note: "",
        twoarray: ["Select", "Phone", "Email", "In-Person", "Video call"],
        contactType: data.contactType,
        activityDropDown: [
          "Select ",
          "Introductory Call",
          "Pre-Move preparations",
          "Area Tours",
          "Property Tours",
          "Settling In services",
          "Touch Base",
          "Other",
        ],
        activity: data.activity,
        loader: false,
      });
    }
  }, [props.data]);

  async function addtheone(add, e) {
    e.preventDefault();
    let errorUpdate = 0;
    if (state.activity == "" || state.activity == null) {
      $("#activity").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#activity").removeClass("is-invalid");
    }
    if (state.contactType == "" || state.contactType == null) {
      $("#contactType").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#contactType").removeClass("is-invalid");
    }
    if (state.fromavailableTillDate == null) {
      $("#fromavailableTillDate").addClass("is-invalid");
      await updateState({ loader: false });
      errorUpdate++;
    } else {
      $("#fromavailableTillDate").removeClass("is-invalid");
    }
    // if (state.contactType !== "Phone") {
    //   console.log("checking", state.contactType);

    //   if (state.toavailableTillDate == null) {
    //     $("#toavailableTillDate").addClass("is-invalid");
    //     await updateState({ loader: false });
    //     errorUpdate++;
    //   } else {
    //     $("#toavailableTillDate").removeClass("is-invalid");
    //   }
    // }

    if (state.fromtimeselect == "" || state.fromtimeselect == null) {
      $("#fromtimeselect").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#fromtimeselect").removeClass("is-invalid");
    }
    if (state.totimeselect == "" || state.totimeselect == null) {
      $("#totimeselect").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#totimeselect").removeClass("is-invalid");
    }
    console.log(errorUpdate, "errorUpdate");

    let getSessionData = await LoginStore.getLoginInfo({});
    if (errorUpdate == 0) {
      if (state.editActivityid != true) {
        let data = {
          activity: state.activity,
          transfereeuid: state.moredetails.userUniqueId,
          activityfromdate: moment(state.fromavailableTillDate).format(
            "MM/DD/YYYY"
          ),
          activitytodate: moment(state.toavailableTillDate).format(
            "MM/DD/YYYY"
          ),
          activityfromtime: state.fromtimeselect,
          activitytotime: state.totimeselect,
          billableevent: state.billableevent,
          contacttype: state.contactType,
          supplieruid: getSessionData.supplieruid,
          notes: state.activityusernotes,
        };
        await updateState({ activityuploadLoader: true });
        const CreateTransfereeActivities =
          await ApiServices.CreateTransfereeActivities(data);
        if (CreateTransfereeActivities.error == 0) {
          await getactivitys();
          await updateState({
            activityerrorMessage: false,
            activityShowSuccess: true,
            activityuploadLoader: false,
            // Activityshowing: CreateTransfereeActivities.message,
            Activityshowing: "Activity created successfully!",
          });
        } else {
          await updateState({
            activityerrorMessage: true,
            activityShowSuccess: false,
            activityuploadLoader: false,
            Activityshowingerror: CreateTransfereeActivities.message,
          });
        }
      } else if (state.editActivityid == true) {
        let data = {
          transfereeactivityUId: state.transfereeactivityUId,
          activity: state.activity,
          transfereeuid: state.moredetails.userUniqueId,
          activityfromdate: moment(state.fromavailableTillDate).format(
            "MM/DD/YYYY"
          ),
          activitytodate: moment(state.toavailableTillDate).format(
            "MM/DD/YYYY"
          ),
          activityfromtime: state.fromtimeselect,
          activitytotime: state.totimeselect,
          billableevent: state.billableevent,
          contacttype: state.contactType,
          supplieruid: getSessionData.supplieruid,
          notes: state.activityusernotes,
        };
        await updateState({ activityuploadLoader: true });
        const UpdateTransfereeActivities =
          await ApiServices.UpdateTransfereeActivities(data);
        if (UpdateTransfereeActivities.error == 0) {
          await getactivitys();
          await updateState({
            activityerrorMessage: false,
            activityShowSuccess: true,
            activityuploadLoader: false,
            // Activityshowing: UpdateTransfereeActivities.message,
            Activityshowing: "Activity updated successfully.",
          });
        } else {
          await updateState({
            activityerrorMessage: true,
            activityShowSuccess: false,
            activityuploadLoader: false,
            Activityshowingerror: UpdateTransfereeActivities.message,
          });
        }
      } else {
        await updateState({
          activityerrorMessage: true,
          activityShowSuccess: false,
          activityuploadLoader: false,
          Activityshowingerror: "please enter all mandatory fields..!",
        });
      }
    }
  }

  return (
    <div
      className="modal fade modalAddActivity "
      id="myModalAddActivity"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      style={{ zIndex: 1060 }}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content ">
          <div className="width-70per pd-y-50">
            <div className="only_close">
              <span
                onClick={() => closeModal()}
                className="close pointer"
                aria-hidden="true"
                data-dismiss="modal"
                aria-label="Close"
              >
                &times;
              </span>
            </div>
            {state.activityShowSuccess == false ? (
              editActivityid == true ? (
                <h6 className="modal-title" id="exampleModalLongTitle">
                  Edit Activity
                </h6>
              ) : (
                <h6 className="modal-title" id="exampleModalLongTitle">
                  Add Activity
                </h6>
              )
            ) : (
              ""
            )}
            {state.activityerrorMessage == true ? (
              <div className="pop-form-controls focus-input-label">
                <div className="pt-3">
                  <h6 className="alert alert-warning">
                    {state.Activityshowingerror}
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}

            {state.activityShowSuccess == true ? (
              <div className="pop-form-controls focus-input-label mb-4">
                <div className="">
                  <h6 className="alert alert-success">
                    {editActivityid == true
                      ? state.Activityshowing
                      : state.Activityshowing}{" "}
                  </h6>
                </div>
              </div>
            ) : (
              <div className="modal-body">
                <div className="formAddActivity">
                  <form>
                    <div className="form-group">
                      <label>Activity</label>

                      <select
                        className="form-control"
                        id="activity"
                        value={state.activity}
                        style={{ width: "100%" }}
                        onChange={(e) => handleChange("activity", e)}
                      >
                        {state?.activityDropDown?.map((option, i) => {
                          return (
                            <option key={i} value={option}>
                              {option}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-6 calendar-icon-needed">
                        <label>Date</label>
                        <DatePicker
                          id="fromavailableTillDate"
                          dateFormat="MM/dd/yyyy"
                          selected={state.fromavailableTillDate}
                          selectsStart
                          // minDate={new Date()}
                          maxDate={state.toavailableTillDate}
                          onChange={(e) =>
                            handleDateChange("fromavailableTillDate", e)
                          }
                          // onChange={handleDateChange}
                          placeholderText="Select start date ..."
                          className="form-control"
                          autoComplete="off"
                        />
                      </div>
                      {/* <div className="col-sm-6 calendar-icon-needed">
                        <label>To Date</label>
                        <DatePicker
                          id="toavailableTillDate"
                          dateFormat="MM/dd/yyyy"
                          selected={state.toavailableTillDate}
                          selectsEnd
                          autoComplete="off"
                          startDate={state.fromavailableTillDate}
                          endDate={state.toavailableTillDate}
                          onChange={(e) =>
                            handleDateChange("toavailableTillDate", e)
                          }
                          minDate={
                            state.fromavailableTillDate == null
                              ? new Date()
                              : state.fromavailableTillDate
                          }
                          placeholderText="Select end date ..."
                          className="form-control"
                        />
                      </div> */}
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-6">
                        <label>From Time</label>

                        <select
                          className="form-control"
                          id="fromtimeselect"
                          value={state.fromtimeselect}
                          style={{ width: "100%" }}
                          onChange={(e) => handleChange("fromtimeselect", e)}
                        >
                          {timeLabels.map((option, i) => {
                            return (
                              <option key={i} value={option}>
                                {option}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-sm-6">
                        <label>To Time</label>
                        <select
                          className="form-control"
                          id="totimeselect"
                          value={state.totimeselect}
                          style={{ width: "100%" }}
                          onChange={(e) => handleChange("totimeselect", e)}
                        >
                          {timeLabels.map((option, i) => {
                            return (
                              <option key={i} value={option}>
                                {option}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-8">
                        <label>Contact Type</label>

                        <select
                          className="form-control"
                          id="contactType"
                          value={state.contactType}
                          style={{ width: "100%" }}
                          onChange={(e) => handleChange("contactType", e)}
                        >
                          {state?.twoarray?.map((option, i) => {
                            return (
                              <option key={i} value={option}>
                                {option}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-sm-4">
                        <div className="checbox-label">
                          <input
                            type="checkbox"
                            className="btn btn-clr mrgrt-10"
                            value={state.billableevent}
                            checked={state.billableevent ? true : false}
                            onChange={(e) => updatesendbillableevent(e)}
                          />
                          <label className="label_cls">Billable Event</label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Notes</label>
                      <textarea
                        className="form-control"
                        rows="4"
                        value={state.activityusernotes}
                        placeholder="Notes"
                        id="activityusernotes"
                        onChange={(e) => handleChange("activityusernotes", e)}
                      />
                    </div>
                  </form>
                </div>
                <div className="modal-footer mt-3 mb-3">
                  {state.activityuploadLoader ? (
                    <Spin />
                  ) : (
                    <button
                      type="submit"
                      className="btn theme-btn"
                      onClick={(e) => addtheone("true", e)}
                    >
                      {state.editActivityid != "" ? "UPDATE" : "SAVE"}{" "}
                      <span></span>{" "}
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activity;
