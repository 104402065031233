import React, { useEffect, useState, useRef } from "react";
import LoginStore from "../../../../services/thirdPartyService";
import aiAssistant from "../../../../services/aiAssistant";
import OwlCarousel from "react-owl-carousel";
import tabContent from "./chatGptTabQues.json";
import ReactMarkdown from "react-markdown";
import { findDOMNode } from "react-dom";

function AIAssistant() {
  const [state, setState] = useState({
    query: "",
    sessionData: "",
    response: false,
    messages: [],
    startPosition: 0,
    tabItems: "",
    optionTypes: [
      {
        name: "Getting Started",
        type: "Getting Started",
        faicon: "fa-regular fa-circle-chevron-right",
      },
      {
        name: "Housing",
        type: "Housing",
        faicon: "fa-regular fa-house",
      },
      {
        name: "Schools",
        type: "Schools",
        faicon: "fa-sharp fa-regular fa-school",
      },
      {
        name: "Culture",
        type: "Culture",
        faicon: "fa-light fa-people-group",
      },
      {
        name: "Services",
        type: "Services",
        faicon: "fa-regular fa-gear",
      },
      {
        name: "Travel",
        type: "Travel",
        faicon: "fa-solid fa-person-walking-luggage",
      },
      {
        name: "Finance",
        type: "Finance",
        faicon: "fa-regular fa-circle-dollar",
      },
      {
        name: "Pets",
        type: "Pets",
        faicon: "fa-regular fa-paw",
      },
      {
        name: "Language Assistance",
        type: "Language Assistance",
        faicon: "fa-light fa-language",
      },
      {
        name: "Community/Networking",
        type: "Community/Networking",
        faicon: "fa-light fa-people-group",
      },
    ],
    selectedType: "Getting Started",
  });
  const [displayedText, setDisplayedText] = useState("");
  const chatHistoryRef = useRef(null);
  const carouselRef = useRef(null);

  const updateState = (obj) => {
    setState((prev) => ({
      ...prev,
      ...obj,
    }));
  };

  useEffect(() => {
    fetchData();
    tab();
  }, []);

  useEffect(() => {
    const handleScroll = (event) => {
      event.preventDefault(); // Prevent default scroll behavior

      if (!carouselRef.current) return;

      const carouselElement = findDOMNode(carouselRef.current); // Get actual DOM element

      if (!carouselElement || !(carouselElement instanceof HTMLElement)) return; // Ensure it's a valid HTML element

      if (event.deltaY > 0) {
        $(carouselElement).trigger("next.owl.carousel");
      } else {
        $(carouselElement).trigger("prev.owl.carousel");
      }
    };

    const carouselElement = findDOMNode(carouselRef.current);
    if (carouselElement && carouselElement instanceof HTMLElement) {
      carouselElement.addEventListener("wheel", handleScroll);
    }

    return () => {
      if (carouselElement && carouselElement instanceof HTMLElement) {
        carouselElement.removeEventListener("wheel", handleScroll);
      }
    };
  }, [state.selectedType]);

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [state.messages]);

  useEffect(() => {
    if (state.messages.length === 0) return;

    const lastMessage = state.messages[state.messages.length - 1];

    // Ensure only receiver messages get animated
    if (
      lastMessage.type === "receiver" &&
      !displayedText[lastMessage.timestamp]
    ) {
      let text = lastMessage.message;
      let index = 0;

      setDisplayedText((prev) => ({
        ...prev,
        [lastMessage.timestamp]: "", // Initialize animation for the new message
      }));

      let interval = setInterval(() => {
        setDisplayedText((prev) => {
          const updatedText = prev[lastMessage.timestamp] + text[index];
          index++;

          // Stop when the full message is displayed
          if (index >= text.length) clearInterval(interval);

          return {
            ...prev,
            [lastMessage.timestamp]: updatedText, // Update only the current message
          };
        });
      }, 50); // Adjust typing speed

      return () => clearInterval(interval);
    }
  }, [state.messages]);

  const fetchData = async () => {
    let sessionData = await LoginStore.getLoginInfo({});
    updateState({ sessionData: sessionData });
  };

  const handleChange = (e) => {
    updateState({ query: e.target.value });
  };

  const formatTimestamp = (date) => {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const dateOptions = {
      month: "short",
      day: "2-digit",
    };
    const timeString = new Intl.DateTimeFormat("en-US", options).format(date);
    const dateString = new Intl.DateTimeFormat("en-US", dateOptions).format(
      date
    );

    return `${timeString}, ${dateString}`;
  };

  const sendQuery = async (query) => {
    if (!query.trim()) return;
    const timestamp = formatTimestamp(new Date());

    // Add user message
    updateState({
      messages: [
        ...state.messages,
        {
          message: query,
          timestamp,
          type: "sender",
        },
      ],
      response: true,
      query: "",
    });

    let object = {
      query: query,
      transferee_uid: state.sessionData.userUniqueId,
    };

    const aiResponse = await aiAssistant.aiAssistantResponse(object);

    if (aiResponse) {
      updateState({
        messages: [
          ...state.messages,
          {
            message: query,
            timestamp,
            type: "sender",
          },
          {
            message: aiResponse,
            timestamp: formatTimestamp(new Date()),
            type: "receiver",
          },
        ],
        response: false,
      });
    }
  };

  const handleClick = async () => {
    try {
      sendQuery(state.query);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const updateCarouselPosition = (object) => {
    if (object.item.index != state.startPosition) {
      setState((prevState) => ({
        ...prevState,
        startPosition: object.item.index,
      }));
    }
  };

  const tab = () => {
    const screenWidth = window.innerWidth;
    let tabItems =
      parseInt(screenWidth) <= 576
        ? 1
        : parseInt(screenWidth) <= 767
        ? 2
        : parseInt(screenWidth) <= 991
        ? 3
        : parseInt(screenWidth) <= 1199
        ? 3
        : 5;
    setState((prevState) => ({
      ...prevState,
      tabItems: tabItems,
    }));
  };

  const handleData = (selectedType) => {
    updateState({ selectedType: selectedType.type });
  };

  const handleQuestionTab = async (question) => {
    try {
      sendQuery(question);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const contentMapping = tabContent;

  return (
    <div
      className={
        state.messages.length > 0
          ? "relo-aiAssistant withChat"
          : "relo-aiAssistant "
      }
    >
      {/* <h1 className="d-md-none text-center">Your Copilot</h1> */}
      <h2>How can I help you?</h2>

      {/* Chat Container */}
      {state.messages.length > 0 && (
        <div className="historyScroll" ref={chatHistoryRef}>
          <div className="chatHistory">
            {state.messages.map((message, index) => (
              <div className={`chatView ${message.type}`} key={index}>
                <span>
                  {message.type === "receiver" && (
                    <img src="assets/images/new-logo.svg" alt="" />
                  )}
                  {message.timestamp}
                </span>

                {/* <p style={{ whiteSpace: "pre-wrap" }}>{message.message}</p> */}
                {message.type === "receiver" ? (
                  <ReactMarkdown
                    components={{
                      a: ({ node, ...props }) => (
                        <a {...props} target="_blank" rel="noopener noreferrer">
                          {props.children}
                        </a>
                      ),
                    }}
                  >
                    {message.message}
                    {/* {displayedText[message.timestamp] || ""} */}
                  </ReactMarkdown>
                ) : (
                  <p>{message.message}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Input and Submit Button */}
      <div className="textHere">
        <textarea
          value={state.query}
          onChange={(e) => handleChange(e)}
          rows="5"
          cols="40"
          placeholder="Type here"
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey && state.query.trim() !== "") {
              e.preventDefault();
              handleClick();
            }
          }}
        />
        {!state.response ? (
          <button onClick={handleClick} className="btn">
            <i className="fa-solid fa-arrow-up"></i>
          </button>
        ) : (
          <button className="btn">
            <i className="fa fa-spinner fa-spin" />
          </button>
        )}
      </div>

      {state.messages.length == 0 && (
        <>
          <div className="prompts-list">
            <OwlCarousel
              ref={carouselRef}
              key={state.selectedType}
              className="owl-theme"
              dots={false}
              // nav={true}
              autoWidth={true}
              margin={15}
              // navText={[
              //   "<i class='fa-light fa-angle-left'></i>",
              //   "<i class='fa-light fa-angle-right'></i>",
              // ]}
              items={state.tabItems}
              startPosition={state.startPosition}
              onDragged={(object) => updateCarouselPosition(object)}
              onTranslated={(object) => updateCarouselPosition(object)}
            >
              {state.optionTypes.map((type, index) => {
                return (
                  <div
                    key={type.name}
                    className={`tail ${
                      state.selectedType === type.type ? "active" : " "
                    }`}
                    onClick={() => handleData(type)}
                  >
                    <span onClick={() => handleData(type)}>
                      <i className={type.faicon}></i>
                      {type.name}
                    </span>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>

          <div className="tab-active-list">
            {contentMapping[state.selectedType].map((content, index) => (
              <div
                className="view"
                key={index}
                onClick={() => handleQuestionTab(content)}
              >
                {content}
              </div>
            ))}
          </div>
        </>
      )}
      <span className="aiNote">
        CoPilot uses AI and can make mistakes. ReloNavigator does not use any of
        the user data in training models.{" "}
      </span>
    </div>
  );
}

export default AIAssistant;
