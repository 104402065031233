import GdprService from "../../../../services/gdprService";
import React, { useEffect, useState } from "react";
import Header from "../../../common/header/Header";
import ReservationSuccesModal from "./reservationSuccesModal";
//import MainController from "../../controller/mainController";
import AppController from "../../../controllers/appController";
import Footer from "../../../common/footer/Footer";
//import ReCAPTCHA from "react-google-recaptcha";

const Gdpr = () => {
  const [state, setState] = useState({
    name: "",
    areyou: "",
    organisationname: "",
    vatnumber: "",
    email: "",
    wantData: "",
    wantDataDelete: "",
    wantDelete: "",
    thirdparty: "",
    wantDatatransfer: "",
    wanttransfer: "",
    informationaccess: "",
    questiondetails: "",
    incorrectdata: "",
    correctdata: "",
    moredetails: "",
    processingdata: "",
    quedetails: "",
    personalinfo: "",
    color: "",
    errorMessage: "",
    CaptchaValue: "",
    personarray: ["I'm an Individual", "I'm representing an organization "],
    wantDataarray: [
      "I want Relonavigator to delete my personal data ('right to be forgotten')",
      "I want Relonavigator to transfer my data to me or a third party",
      "I want to know how Relonavigator is using my personal information",
      "I want to rectify incorrect data Relonavigator has about me",
      "I want Relonavigator to stop using my information for direct marketing and profiling",
      "I want Relonavigator to keep my data, but stop processing it",
      "I want to Object to the way Relonavigator is using my personal information",
    ],
    wantDataDeletearray: [
      "Relonavigator no longer has any use for my data",
      "I want to withdraw my consent for processing my data",
      "I believe my data is being used unlawfully",
      "I want stop my data from being processed",
      "I want stop my data from being used in direct marketing",
      "I want my data to be deleted to comply with a legal order",
    ],
    transferdataarray: [
      "I want to receive it myself",
      "I want to transfer it to a third party",
    ],
    informationaccessarray: [
      "I want to Know why Relonavigator is processing my data",
      "I want to Know how Relonavigator received my personal information",
      "I want to Know the types of personal data that Relonavigator collects",
      "I want to Know all third parties Relonavigator shares my information with",
      "I want to Know the duration for which my data will be stored and the reason for that decision",
      "I want stop my data from being processed",
      "I want stop my data from being used in direct marketing",
      "I want to know if any automated decision making or profiling is being done using my personal information",
    ],
    processingdataarray: [
      "I believe the data Relonavigator has about me is inaccurate",
      "I believe my personal information is being used unlawfully",
      "There is no further need for my personal information to be used",
      "I have a pending complaint and i want Relonavigator to stop processing my data while this complaint is pending",
    ],
  });
  const setValue = (field, e) => {
    setState((prev) => ({
      ...prev,
      [field]: e.target.value, // Use computed property name syntax to directly update the state property
    }));
  };

  /* Service Request Details send to mail  */
  const serviceRequest = async (e) => {
    e.preventDefault();
    const {
      areyou,
      name,
      organisationname,
      vatnumber,
      email,
      wantData,
      wantDataDelete,
      wantDelete,
      wantDatatransfer,
      thirdparty,
      wanttransfer,
      informationaccess,
      questiondetails,
      incorrectdata,
      correctdata,
      moredetails,
      processingdata,
      quedetails,
      personalinfo,
      personarray,
      wantDataarray,
      transferdataarray,
    } = state;
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    let error = 0;
    const validationFields = [];
    if (areyou == personarray[0] && wantData == wantDataarray[0]) {
      validationFields.push(
        "areyou",
        "name",
        "email",
        "wantData",
        "wantDataDelete"
      );
    } else if (areyou == personarray[0] && wantData == wantDataarray[1]) {
      if (wantDatatransfer == transferdataarray[1]) {
        validationFields.push(
          "areyou",
          "name",
          "email",
          "wantData",
          "wantDatatransfer",
          "thirdparty"
        );
      } else {
        validationFields.push(
          "areyou",
          "name",
          "email",
          "wantData",
          "wantDatatransfer"
        );
      }
    } else if (areyou == personarray[0] && wantData == wantDataarray[2]) {
      validationFields.push(
        "areyou",
        "name",
        "email",
        "wantData",
        "informationaccess"
      );
    } else if (areyou == personarray[0] && wantData == wantDataarray[3]) {
      validationFields.push(
        "areyou",
        "name",
        "email",
        "wantData",
        "incorrectdata",
        "correctdata"
      );
    } else if (areyou == personarray[0] && wantData == wantDataarray[4]) {
      validationFields.push(
        "areyou",
        "name",
        "email",
        "wantData"
      );
    } else if (areyou == personarray[0] && wantData == wantDataarray[5]) {
      validationFields.push(
        "areyou",
        "name",
        "email",
        "wantData",
        "processingdata",
        "quedetails"
      );
    } else if (areyou == personarray[0] && wantData == wantDataarray[6]) {
      validationFields.push(
        "areyou",
        "name",
        "email",
        "wantData",
        "personalinfo"
      );
    }
    ///////
    else if (areyou == personarray[1] && wantData == wantDataarray[0]) {
      validationFields.push(
        "areyou",
        "name",
        "organisationname",
        "vatnumber",
        "email",
        "wantData",
        "wantDataDelete"
      );
    } else if (areyou == personarray[1] && wantData == wantDataarray[1]) {
      if (wantDatatransfer == transferdataarray[1]) {
        validationFields.push(
          "areyou",
          "name",
          "organisationname",
          "vatnumber",
          "email",
          "wantData",
          "wantDatatransfer",
          "thirdparty"
        );
      } else {
        validationFields.push(
          "areyou",
          "name",
          "organisationname",
          "vatnumber",
          "email",
          "wantData",
          "wantDatatransfer"
        );
      }
    } else if (areyou == personarray[1] && wantData == wantDataarray[2]) {
      validationFields.push(
        "areyou",
        "name",
        "organisationname",
        "vatnumber",
        "email",
        "wantData",
        "informationaccess"
      );
    } else if (areyou == personarray[1] && wantData == wantDataarray[3]) {
      validationFields.push(
        "areyou",
        "name",
        "organisationname",
        "vatnumber",
        "email",
        "wantData",
        "incorrectdata",
        "correctdata"
      );
    } else if (areyou == personarray[1] && wantData == wantDataarray[4]) {
      validationFields.push(
        "areyou",
        "name",
        "organisationname",
        "vatnumber",
        "email",
        "wantData"
      );
    } else if (areyou == personarray[1] && wantData == wantDataarray[5]) {
      validationFields.push(
        "areyou",
        "name",
        "organisationname",
        "vatnumber",
        "email",
        "wantData",
        "processingdata",
        "quedetails"
      );
    } else if (areyou == personarray[1] && wantData == wantDataarray[6]) {
      validationFields.push(
        "areyou",
        "name",
        "organisationname",
        "vatnumber",
        "email",
        "wantData",
        "personalinfo"
      );
    } else {
      validationFields.push("areyou", "name", "email", "wantData");
    }

    _.forEach(validationFields, function (value) {
      if (state[value] == "") {
        error++;
      }
    });
    _.forEach(validationFields, function (value) {
      if (!reg.test(state.email)) {
        console.log("email worng");
        error++;
      }
    });

    if (error == 0) {
      const reservation = {
        areyou: areyou,
        name: name,
        organisationname: organisationname,
        vatnumber: vatnumber,
        email: email,
        wantData: wantData,
        wantDataDelete: wantDataDelete,
        wantDelete: wantDelete,
        thirdparty: thirdparty,
        wantDatatransfer: wantDatatransfer,
        wanttransfer: wanttransfer,
        informationaccess: informationaccess,
        questiondetails: questiondetails,
        incorrectdata: incorrectdata,
        correctdata: correctdata,
        moredetails: moredetails,
        processingdata: processingdata,
        quedetails: quedetails,
        personalinfo: personalinfo,
      };
      const response = await GdprService.formSubmition(reservation);
      if (response.status) {
        jQuery(
          function ($) {
            $("#reservationsuccessmodal").modal("show");
          }.bind(this)
        );
        setTimeout(
          function () {
            jQuery("#reservationsuccessmodal").modal("hide");
            jQuery("#myModalGdpr").modal("hide");
            location.href = "/singleProfile";
          }.bind(this),
          3000
        );
      }
    } else {
      AppController.menuTopNavigation("gdpr");
      setState((prev) => ({
        ...prev,
        errorMessage: "Please fill in all the required fields",
        color: "red",
      }));
    }
  };

  //   useEffect(() => {
  //     MainController.getInitialInformation();
  //   }, []);
  const onChangeCaptcha = async (CaptchaValue) => {
    await setState({ CaptchaValue: CaptchaValue });
  };
  const closeModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#myModalGdpr").modal("hide");
      });
    });
    setState((prev)=>({
      ...prev,
      areyou:"",
      name:"",
      organisationname:"",
      vatnumber:"",
      email:"",
      wantData:"",
      wantDataDelete:"",
      wantDelete:"",
      wantDatatransfer:"",
      thirdparty:"",
      wanttransfer:"",
      informationaccess:"",
      questiondetails:"",
      incorrectdata:"",
      correctdata:"",
      moredetails:"",
      processingdata:"",
      quedetails:"",
      personalinfo:"",
      // personarray:"",
      // wantDataarray:"",
      // transferdataarray:"",
      errorMessage:"",
      color:""
    }))
  };
  /* It is invoked to return html content */
  const {
    personarray,
    wantDataarray,
    wantDataDeletearray,
    transferdataarray,
    informationaccessarray,
    processingdataarray,
  } = state;
  return (
    // <div>
    //   <div className="corporateHousing_Updates">
    //     <Header />
    //     <div className="p-request-form" id="gdpr">
    //       <form className="form-horizontal" method="post" action="#">
    //         <div>
    //           <h1>Exercise your rights under GDPR</h1>
    //           <p>
    //             Send your GDPR request to <strong>Relonavigator</strong> via the
    //             form below.
    //             <br />
    //             Your request will be sent to the Data Protection Officer at{" "}
    //             <strong>
    //               <a
    //                 href="https://www.relonavigator.com/"
    //                 rel="nofollow"
    //                 target="_blank"
    //               >
    //                 Relonavigator
    //               </a>
    //             </strong>
    //             .
    //           </p>
    //         </div>
    //         <p className="text-center" style={{ color: state.color }}>
    //           {state.errorMessage}
    //         </p>
    //         <div className="form-group">
    //           <label htmlFor="name" className="cols-sm-2 control-label">
    //             Are you a person or an organisation?
    //             <span className="clr-red-star">*</span>
    //           </label>
    //           <div className="dropdown">
    //             <select
    //               className="dropdown-select form-control"
    //               value={state.areyou}
    //               onChange={(e) => setValue("areyou", e)}
    //             >
    //               <option value="" disabled>
    //                 pick an option
    //               </option>
    //               {personarray.map((row, index) => {
    //                 return (
    //                   <option key={index} value={row}>
    //                     {row}
    //                   </option>
    //                 );
    //               })}
    //             </select>
    //           </div>
    //         </div>
    //         <div className="form-group">
    //           <label htmlFor="username" className="cols-sm-2 control-label">
    //             What's your name?<span className="clr-red-star">*</span>
    //           </label>
    //           <div className="cols-sm-10">
    //             <div className="input-group">
    //               <input
    //                 type="text"
    //                 className="form-control"
    //                 value={state.name}
    //                 onChange={(e) => setValue("name", e)}
    //               />
    //             </div>
    //           </div>
    //         </div>

    //         {state.areyou == personarray[1] ? (
    //           <div>
    //             <div className="form-group">
    //               <label htmlFor="username" className="cols-sm-2 control-label">
    //                 What's your organisation's name?
    //                 <span className="clr-red-star">*</span>
    //               </label>
    //               <div className="cols-sm-10">
    //                 <div className="input-group">
    //                   <input
    //                     type="text"
    //                     className="form-control"
    //                     value={state.organisationname}
    //                     onChange={(e) => setValue("organisationname", e)}
    //                   />
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="form-group">
    //               <label htmlFor="username" className="cols-sm-2 control-label">
    //                 VAT number for your organisation
    //                 <span className="clr-red-star">*</span>
    //               </label>
    //               <div className="cols-sm-10">
    //                 <div className="input-group">
    //                   <input
    //                     type="text"
    //                     className="form-control"
    //                     value={state.vatnumber}
    //                     onChange={(e) => setValue("vatnumber", e)}
    //                   />
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         ) : null}

    //         <div className="form-group">
    //           <label htmlFor="email" className="cols-sm-2 control-label">
    //             What's your email address?
    //             <span className="clr-red-star">*</span>
    //           </label>
    //           <div className="cols-sm-10">
    //             <div className="input-group">
    //               <input
    //                 type="text"
    //                 className="form-control"
    //                 name="email"
    //                 id="email"
    //                 value={state.email}
    //                 onChange={(e) => setValue("email", e)}
    //               />
    //             </div>
    //           </div>
    //         </div>

    //         <div className="form-group">
    //           <label htmlFor="name" className="cols-sm-2 control-label">
    //             What do you want to get done?
    //             <span className="clr-red-star">*</span>
    //           </label>
    //           <div className="dropdown">
    //             <select
    //               className="dropdown-select form-control"
    //               value={state.wantData}
    //               onChange={(e) => setValue("wantData", e)}
    //             >
    //               <option value="" disabled>
    //                 pick an option
    //               </option>
    //               {wantDataarray.map((row, index) => {
    //                 return (
    //                   <option key={index} value={row}>
    //                     {row}
    //                   </option>
    //                 );
    //               })}
    //             </select>
    //           </div>
    //         </div>

    //         {state.wantData == wantDataarray[0] ? (
    //           <div>
    //             <div className="form-group">
    //               <label htmlFor="name" className="cols-sm-2 control-label">
    //                 Why do you want your data te bo deleted?
    //                 <span className="clr-red-star">*</span>
    //               </label>
    //               <div className="dropdown">
    //                 <select
    //                   className="dropdown-select form-control"
    //                   value={state.wantDataDelete}
    //                   onChange={(e) => setValue("wantDataDelete", e)}
    //                 >
    //                   <option value="" disabled>
    //                     pick an option
    //                   </option>
    //                   {wantDataDeletearray.map((row, index) => {
    //                     return (
    //                       <option key={index} value={row}>
    //                         {row}
    //                       </option>
    //                     );
    //                   })}
    //                 </select>
    //               </div>
    //             </div>
    //             <div className="form-group">
    //               <label htmlFor="name" className="cols-sm-2 control-label">
    //                 Optionally, specify which data you want to make sure
    //                 Relonavigator will delete{" "}
    //                 <span className="clr-red-star">*</span>
    //               </label>
    //               <div className="cols-sm-10">
    //                 <div className="input-group">
    //                   <textarea
    //                     className="form-control"
    //                     value={state.wantDelete}
    //                     onChange={(e) => setValue("wantDelete", e)}
    //                   />
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         ) : (
    //           ""
    //         )}

    //         {state.wantData == wantDataarray[1] ? (
    //           <div>
    //             <div className="form-group">
    //               <label htmlFor="name" className="cols-sm-2 control-label">
    //                 To whom do you want Relonavigator to transfer your data?
    //                 <span className="clr-red-star">*</span>
    //               </label>
    //               <div className="dropdown">
    //                 <select
    //                   className="dropdown-select form-control"
    //                   value={state.wantDatatransfer}
    //                   onChange={(e) => setValue("wantDatatransfer", e)}
    //                 >
    //                   <option value="" disabled>
    //                     pick an option
    //                   </option>
    //                   {transferdataarray.map((row, index) => {
    //                     return (
    //                       <option key={index} value={row}>
    //                         {row}
    //                       </option>
    //                     );
    //                   })}
    //                 </select>
    //               </div>
    //             </div>
    //             {state.wantDatatransfer == transferdataarray[1] ? (
    //               <div className="form-group">
    //                 <label htmlFor="name" className="cols-sm-2 control-label">
    //                   Specify the third party
    //                   <span className="clr-red-star">*</span>
    //                 </label>
    //                 <div className="cols-sm-10">
    //                   <div className="input-group">
    //                     <textarea
    //                       className="form-control"
    //                       value={state.thirdparty}
    //                       onChange={(e) => setValue("thirdparty", e)}
    //                     />
    //                   </div>
    //                 </div>
    //               </div>
    //             ) : (
    //               ""
    //             )}
    //             <div className="form-group">
    //               <label htmlFor="name" className="cols-sm-2 control-label">
    //                 Optionally, describe your case in more detail. Which data
    //                 should be transferred?
    //                 <span className="clr-red-star">*</span>
    //               </label>
    //               <div className="cols-sm-10">
    //                 <div className="input-group">
    //                   <textarea
    //                     className="form-control"
    //                     value={state.wanttransfer}
    //                     onChange={(e) => setValue("wanttransfer", e)}
    //                   />
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         ) : (
    //           ""
    //         )}

    //         {state.wantData == wantDataarray[2] ? (
    //           <div>
    //             <div className="form-group">
    //               <label htmlFor="name" className="cols-sm-2 control-label">
    //                 What information about you would you like to access?
    //                 <span className="clr-red-star">*</span>
    //               </label>
    //               <div className="dropdown">
    //                 <select
    //                   className="dropdown-select form-control"
    //                   value={state.informationaccess}
    //                   onChange={(e) => setValue("informationaccess", e)}
    //                 >
    //                   <option value="" disabled>
    //                     pick an option
    //                   </option>
    //                   {informationaccessarray.map((row, index) => {
    //                     return (
    //                       <option key={index} value={row}>
    //                         {row}
    //                       </option>
    //                     );
    //                   })}
    //                 </select>
    //               </div>
    //             </div>
    //             <div className="form-group">
    //               <label htmlFor="name" className="cols-sm-2 control-label">
    //                 Optionally, explain your question in more detail
    //                 <span className="clr-red-star">*</span>
    //               </label>
    //               <div className="cols-sm-10">
    //                 <div className="input-group">
    //                   <textarea
    //                     className="form-control"
    //                     value={state.questiondetails}
    //                     onChange={(e) => setValue("questiondetails", e)}
    //                   />
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         ) : (
    //           ""
    //         )}

    //         {state.wantData == wantDataarray[3] ? (
    //           <div>
    //             <div className="form-group">
    //               <label htmlFor="name" className="cols-sm-2 control-label">
    //                 Describe the incorrect data that Relonavigator has about you
    //                 <span className="clr-red-star">*</span>
    //               </label>
    //               <div className="cols-sm-10">
    //                 <div className="input-group">
    //                   <textarea
    //                     className="form-control"
    //                     value={state.incorrectdata}
    //                     onChange={(e) => setValue("incorrectdata", e)}
    //                   />
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="form-group">
    //               <label htmlFor="name" className="cols-sm-2 control-label">
    //                 Describe the correct version of the same data
    //                 <span className="clr-red-star">*</span>
    //               </label>
    //               <div className="cols-sm-10">
    //                 <div className="input-group">
    //                   <textarea
    //                     className="form-control"
    //                     value={state.correctdata}
    //                     onChange={(e) => setValue("correctdata", e)}
    //                   />
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         ) : (
    //           ""
    //         )}
    //         {state.wantData == wantDataarray[4] ? (
    //           <div className="form-group">
    //             <label htmlFor="name" className="cols-sm-2 control-label">
    //               Optionally, specify more details.
    //               <span className="clr-red-star">*</span>
    //             </label>
    //             <div className="cols-sm-10">
    //               <div className="input-group">
    //                 <textarea
    //                   className="form-control"
    //                   value={state.moredetails}
    //                   onChange={(e) => setValue("moredetails", e)}
    //                 />
    //               </div>
    //             </div>
    //           </div>
    //         ) : (
    //           ""
    //         )}
    //         {state.wantData == wantDataarray[5] ? (
    //           <div>
    //             <div className="form-group">
    //               <label htmlFor="name" className="cols-sm-2 control-label">
    //                 Why do you want Relonavigator to stop processing your data?
    //                 <span className="clr-red-star">*</span>
    //               </label>
    //               <div className="dropdown">
    //                 <select
    //                   className="dropdown-select form-control"
    //                   value={state.processingdata}
    //                   onChange={(e) => setValue("processingdata", e)}
    //                 >
    //                   <option value="" disabled>
    //                     pick an option
    //                   </option>
    //                   {processingdataarray.map((row, index) => {
    //                     return (
    //                       <option key={index} value={row}>
    //                         {row}
    //                       </option>
    //                     );
    //                   })}
    //                 </select>
    //               </div>
    //             </div>
    //             <div className="form-group">
    //               <label htmlFor="name" className="cols-sm-2 control-label">
    //                 Optionally, explain your question in more detail
    //                 <span className="clr-red-star">*</span>
    //               </label>
    //               <div className="cols-sm-10">
    //                 <div className="input-group">
    //                   <textarea
    //                     className="form-control"
    //                     value={state.quedetails}
    //                     onChange={(e) => setValue("quedetails", e)}
    //                   />
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         ) : (
    //           ""
    //         )}

    //         {state.wantData == wantDataarray[6] ? (
    //           <div>
    //             <div className="form-group">
    //               <label htmlFor="name" className="cols-sm-2 control-label">
    //                 Please describe the behaviour of Relonavigator you are
    //                 objecting to, related to the usage of your personal
    //                 information.<span className="clr-red-star">*</span>
    //               </label>
    //               <div className="cols-sm-10">
    //                 <div className="input-group">
    //                   <textarea
    //                     className="form-control"
    //                     value={state.personalinfo}
    //                     onChange={(e) => setValue("personalinfo", e)}
    //                   />
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         ) : (
    //           ""
    //         )}
    //         {/* <ReCAPTCHA
    //           ref={(ref) => (recaptcha = ref)}
    //           sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
    //           onChange={onChangeCaptcha}
    //         /> */}
    //         <div className="form-group mrg-top-40 text-center">
    //           <button
    //             type="button"
    //             className="btn theme-btn m-auto"
    //             onClick={serviceRequest}
    //           >
    //             Send my request
    //           </button>
    //         </div>
    //         <div className="form-group securityMessage">
    //           <p>
    //             {" "}
    //             <i className="fa fa-lock"></i> We implement the best security
    //             &amp; privacy practices to process your request.
    //           </p>
    //         </div>
    //       </form>
    //       <ReservationSuccesModal />
    //     </div>
    //   </div>
    //   <Footer />
    // </div>
    <>
      <div className="modal-dialog" role="document">
        <div className="modal-content corporateHousing_Updates">
          <div className="modal-header border-0">
            <div class="only_close">
              <span
                aria-hidden="true"
                className="pointer close"
                onClick={() => closeModal()}
                data-dismiss="modal"
              >
                ×
              </span>
            </div>
          </div>
          <div className="modal-body">
            <div className="widthModal">
              <div className="p-request-form" id="gdpr">
                <form className="form-horizontal" method="post" action="#">
                  <div>
                    <h1>Exercise your rights under GDPR</h1>
                    <p>
                      Send your GDPR request to <strong>Relonavigator</strong>{" "}
                      via the form below.
                      <br />
                      Your request will be sent to the Data Protection Officer
                      at {/* <strong> */}
                      <a
                        // href="https://www.relonavigator.com/"
                        rel="nofollow"
                        // target="_blank"
                      >
                        Relonavigator
                      </a>
                      {/* </strong> */}.
                    </p>
                  </div>
                  <p className="text-center" style={{ color: state.color }}>
                    {state.errorMessage}
                  </p>
                  <div className="form-group">
                    <label htmlFor="name" className="cols-sm-2 control-label">
                      Are you an individual or an organization
                      <span className="clr-red-star">*</span>
                    </label>
                    <div className="dropdown">
                      <select
                        className="dropdown-select form-control"
                        value={state.areyou}
                        onChange={(e) => setValue("areyou", e)}
                      >
                        <option value="" disabled>
                          Select an option
                        </option>
                        {personarray.map((row, index) => {
                          return (
                            <option key={index} value={row}>
                              {row}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="username"
                      className="cols-sm-2 control-label"
                    >
                      Your Name<span className="clr-red-star">*</span>
                    </label>
                    <div className="cols-sm-10">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          value={state.name}
                          onChange={(e) => setValue("name", e)}
                        />
                      </div>
                    </div>
                  </div>

                  {state.areyou == personarray[1] ? (
                    <div>
                      <div className="form-group">
                        <label
                          htmlFor="username"
                          className="cols-sm-2 control-label"
                        >
                          What's your organisation's name?
                          <span className="clr-red-star">*</span>
                        </label>
                        <div className="cols-sm-10">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              value={state.organisationname}
                              onChange={(e) => setValue("organisationname", e)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="username"
                          className="cols-sm-2 control-label"
                        >
                          VAT number for your organisation
                          <span className="clr-red-star">*</span>
                        </label>
                        <div className="cols-sm-10">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              value={state.vatnumber}
                              onChange={(e) => setValue("vatnumber", e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="form-group">
                    <label htmlFor="email" className="cols-sm-2 control-label">
                      Your email address
                      <span className="clr-red-star">*</span>
                    </label>
                    <div className="cols-sm-10">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          id="email"
                          value={state.email}
                          onChange={(e) => setValue("email", e)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="name" className="cols-sm-2 control-label">
                      What do you want to get done?
                      <span className="clr-red-star">*</span>
                    </label>
                    <div className="dropdown">
                      <select
                        className="dropdown-select form-control"
                        value={state.wantData}
                        onChange={(e) => setValue("wantData", e)}
                      >
                        <option value="" disabled>
                          Select an option
                        </option>
                        {wantDataarray.map((row, index) => {
                          return (
                            <option key={index} value={row}>
                              {row}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  {state.wantData == wantDataarray[0] ? (
                    <div>
                      <div className="form-group">
                        <label
                          htmlFor="name"
                          className="cols-sm-2 control-label"
                        >
                          Why do you want your data to be deleted?
                          <span className="clr-red-star">*</span>
                        </label>
                        <div className="dropdown">
                          <select
                            className="dropdown-select form-control"
                            value={state.wantDataDelete}
                            onChange={(e) => setValue("wantDataDelete", e)}
                          >
                            <option value="" disabled>
                              Select an option
                            </option>
                            {wantDataDeletearray.map((row, index) => {
                              return (
                                <option key={index} value={row}>
                                  {row}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="name"
                          className="cols-sm-2 control-label"
                        >
                          Optionally, specify which data you want to make sure
                          Relonavigator will delete{" "}
                          <span className="clr-red-star"></span>
                        </label>
                        <div className="cols-sm-10">
                          <div className="input-group">
                            <textarea
                              className="form-control"
                              value={state.wantDelete}
                              onChange={(e) => setValue("wantDelete", e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {state.wantData == wantDataarray[1] ? (
                    <div>
                      <div className="form-group">
                        <label
                          htmlFor="name"
                          className="cols-sm-2 control-label"
                        >
                          To whom do you want Relonavigator to transfer your
                          data?
                          <span className="clr-red-star">*</span>
                        </label>
                        <div className="dropdown">
                          <select
                            className="dropdown-select form-control"
                            value={state.wantDatatransfer}
                            onChange={(e) => setValue("wantDatatransfer", e)}
                          >
                            <option value="" disabled>
                              Select an option
                            </option>
                            {transferdataarray.map((row, index) => {
                              return (
                                <option key={index} value={row}>
                                  {row}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      {state.wantDatatransfer == transferdataarray[1] ? (
                        <div className="form-group">
                          <label
                            htmlFor="name"
                            className="cols-sm-2 control-label"
                          >
                            Specify the third party
                            <span className="clr-red-star">*</span>
                          </label>
                          <div className="cols-sm-10">
                            <div className="input-group">
                              <textarea
                                className="form-control"
                                value={state.thirdparty}
                                onChange={(e) => setValue("thirdparty", e)}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="form-group">
                        <label
                          htmlFor="name"
                          className="cols-sm-2 control-label"
                        >
                          Optionally, describe your case in more detail. Which
                          data should be transferred?
                          <span className="clr-red-star"></span>
                        </label>
                        <div className="cols-sm-10">
                          <div className="input-group">
                            <textarea
                              className="form-control"
                              value={state.wanttransfer}
                              onChange={(e) => setValue("wanttransfer", e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {state.wantData == wantDataarray[2] ? (
                    <div>
                      <div className="form-group">
                        <label
                          htmlFor="name"
                          className="cols-sm-2 control-label"
                        >
                          What information about you would you like to access?
                          <span className="clr-red-star">*</span>
                        </label>
                        <div className="dropdown">
                          <select
                            className="dropdown-select form-control"
                            value={state.informationaccess}
                            onChange={(e) => setValue("informationaccess", e)}
                          >
                            <option value="" disabled>
                              Select an option
                            </option>
                            {informationaccessarray.map((row, index) => {
                              return (
                                <option key={index} value={row}>
                                  {row}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="name"
                          className="cols-sm-2 control-label"
                        >
                          Optionally, explain your question in more detail
                          <span className="clr-red-star"></span>
                        </label>
                        <div className="cols-sm-10">
                          <div className="input-group">
                            <textarea
                              className="form-control"
                              value={state.questiondetails}
                              onChange={(e) => setValue("questiondetails", e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {state.wantData == wantDataarray[3] ? (
                    <div>
                      <div className="form-group">
                        <label
                          htmlFor="name"
                          className="cols-sm-2 control-label"
                        >
                          Describe the incorrect data that Relonavigator has
                          about you
                          <span className="clr-red-star">*</span>
                        </label>
                        <div className="cols-sm-10">
                          <div className="input-group">
                            <textarea
                              className="form-control"
                              value={state.incorrectdata}
                              onChange={(e) => setValue("incorrectdata", e)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="name"
                          className="cols-sm-2 control-label"
                        >
                          Describe the correct version of the same data
                          <span className="clr-red-star">*</span>
                        </label>
                        <div className="cols-sm-10">
                          <div className="input-group">
                            <textarea
                              className="form-control"
                              value={state.correctdata}
                              onChange={(e) => setValue("correctdata", e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {state.wantData == wantDataarray[4] ? (
                    <div className="form-group">
                      <label htmlFor="name" className="cols-sm-2 control-label">
                        Optionally, specify more details.
                        <span className="clr-red-star"></span>
                      </label>
                      <div className="cols-sm-10">
                        <div className="input-group">
                          <textarea
                            className="form-control"
                            value={state.moredetails}
                            onChange={(e) => setValue("moredetails", e)}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {state.wantData == wantDataarray[5] ? (
                    <div>
                      <div className="form-group">
                        <label
                          htmlFor="name"
                          className="cols-sm-2 control-label"
                        >
                          Why do you want Relonavigator to stop processing your
                          data?
                          <span className="clr-red-star">*</span>
                        </label>
                        <div className="dropdown">
                          <select
                            className="dropdown-select form-control"
                            value={state.processingdata}
                            onChange={(e) => setValue("processingdata", e)}
                          >
                            <option value="" disabled>
                              Select an option
                            </option>
                            {processingdataarray.map((row, index) => {
                              return (
                                <option key={index} value={row}>
                                  {row}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="name"
                          className="cols-sm-2 control-label"
                        >
                          Optionally, explain your question in more detail
                          <span className="clr-red-star"></span>
                        </label>
                        <div className="cols-sm-10">
                          <div className="input-group">
                            <textarea
                              className="form-control"
                              value={state.quedetails}
                              onChange={(e) => setValue("quedetails", e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {state.wantData == wantDataarray[6] ? (
                    <div>
                      <div className="form-group">
                        <label
                          htmlFor="name"
                          className="cols-sm-2 control-label"
                        >
                          Please describe the behaviour of Relonavigator you are
                          objecting to, related to the usage of your personal
                          information.<span className="clr-red-star">*</span>
                        </label>
                        <div className="cols-sm-10">
                          <div className="input-group">
                            <textarea
                              className="form-control"
                              value={state.personalinfo}
                              onChange={(e) => setValue("personalinfo", e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* <ReCAPTCHA
              ref={(ref) => (recaptcha = ref)}
              sitekey="6Lcbw1AUAAAAAE5K5cYDyhoGu0RJY9C3CLtGFXAo"
              onChange={onChangeCaptcha}
            /> */}
                  <div className="form-group mrg-top-40 text-center">
                    <button
                      type="button"
                      className="btn theme-btn m-auto"
                      onClick={serviceRequest}
                    >
                      Send my request
                    </button>
                  </div>
                  <div className="form-group securityMessage">
                    <p>
                      {" "}
                      <i className="fa fa-lock"></i> We implement the best
                      security &amp; privacy practices to process your request.
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* <div className="modal-footer"></div> */}
        </div>
      </div>
      <ReservationSuccesModal />
    </>
  );
};
export default Gdpr;
