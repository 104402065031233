import React, { useEffect, useState, useRef } from "react";
import { Select, Slider } from "antd";
import jData from "./dataFoursquare.json";
import OwlCarousel from "react-owl-carousel";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import AppController from "../controllers/appController";
import GoogleMapsPlaces from "../../services/mapsPlaces";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import * as turf from "@turf/turf";
import fourSquarePlaces from "../../services/fourSquarePlaces";

mapboxgl.accessToken =
  "sk.eyJ1IjoidGVqYWt1bWFyNjIiLCJhIjoiY2x4b2I2M3phMDZ5cDJqcHMxZnVpMjB3bCJ9.uscOlHG0Tdfk3s2LEGX98w";

function MapViewFunctional(props) {
  const [state, setState] = useState({
    latitude: 0.0,
    longitude: 0.0,
    selectedCategory: "",
    mapRes: [],
    types: [
      {
        name: "Restaurants",
        type: "restaurant",
        faicon: "fa fa-cutlery",
        icon: "../assets/images/restaurant-new.svg",
      },
      {
        name: "Groceries",
        type: "supermarket",
        faicon: "fa fa-shopping-bag",
        icon: "../assets/images/grocery-1.svg",
      },
      {
        name: "Parks",
        type: "park",
        faicon: "fa fa-tree",
        icon: "../assets/images/park-1.svg",
      },
      {
        name: "Shopping",
        type: "shopping_mall",
        faicon: "fa fa-shopping-cart",
        icon: "../assets/images/shopping-3.svg",
      },
      {
        name: "Entertainment",
        type: "night_club",
        faicon: "fa fa-film",
        icon: "../assets/images/entertainment-1.svg",
      },
      {
        name: "Hospitals",
        type: "hospital",
        faicon: "fa fa-h-square",
        icon: "../assets/images/hos-1.svg",
      },
    ],
    type: ["restaurant"],
    typeName: ["Restaurants"],
    location: [],
    placeSearched: [],
    placeSearchedMarkers: [],
    markerList: [],
    showlist: false,
    showSearch: false,
    typeicon: 0,
    directionsService: {},
    directionsDisplay: {},
    communityName: "",
    restaurantsArr: [],
    restData: [],
    cityLanding: false,
    Loader: false,
    items: [],
    tabItems: "",
    startPosition: 0,
    categories: [],
    RestaurantsMainArray: [],
    restaurantsList: [],
    typesList: [],
    RestauType: [],
    removeBoundary: false,
    mapText: "",
    activeNumber: -1,
    restaurantsRendered: 0,
    restlist: [],
    restlight: "",
    data: {
      latitude: 37.7739852,
      longitude: -122.3912407,
      state_name: "CA",
      city_name: "San Francisco",
      city: "San Francisco",
      address: "185 Channel Street",
    },
    restlistarray: [],
    getlistarray: [],
    GetSquare: [],
    activeMaker: null,
    activeNumber: -1,
    propertyInfo: false,
    radiusinMiles: 5,
    totalData: [],
    totalSelectedData: [],
    rating: "",
    updatedTypes: [],
    selectedTypeData: [],
    typesArray: [],
    selectedTypeIcon: "",
    sidetabItems: "",
    startPosition1: 0,
  });
  const [map, setMap] = useState(null);
  const [currentPopup, setCurrentPopup] = useState(null);
  const divRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      // let items =
      //   parseInt(screen.availWidth) <= 767
      //     ? 1
      //     : parseInt(screen.availWidth) <= 991
      //     ? 1
      //     : 1;

      setState((prev) => ({
        ...prev,
        // items: items,
        latitude: props.latitude,
        longitude: props.longitude,
        showlist: false,
        communityName: props.communityName,
        data: props.data,
      }));
      // await onetype(state.types[0], 0);
      const checked = state.typeName.includes("Restaurants");
      // console.log(checked, "use effect");

      await multipleTypes(state.types[0], "Restaurants", 0, checked);
      tab();
      sideTab();
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const dataTypes = [
          { key: "Restaurants", id: "4bf58dd8d48988d14c941735" },
          { key: "Groceries", id: "4bf58dd8d48988d118951735" },
          { key: "Parks", id: "4bf58dd8d48988d163941735" },
          { key: "Shopping", id: "4bf58dd8d48988d1fd941735" },
          { key: "Entertainment", id: "4d4b7104d754a06370d81259" },
          { key: "Hospitals", id: "4bf58dd8d48988d196941735" },
        ];

        const currentType = state.types[0]?.name;
        if (!currentType) return;
        const obj = dataTypes.find((o) => o.key === currentType);
        if (!obj) return;
        const radiusInMeters = Math.round(state.radiusinMiles * 1609.34);
        const apiObject = {
          latitude: props.latitude,
          longitude: props.longitude,
          radius: radiusInMeters,
        };
        let fetchedData = await AppController.fourSquarData(apiObject, obj.id);
        console.log(fetchedData, "Fetched Data");
        const transformedData = fetchedData.map((data) => {
          const { fsq_id, geocodes, location, name, rating } = data;
          return {
            fsq_id,
            geocodes,
            location,
            name,
            rating,
            selectedName: currentType,
          };
        });

        setState((prev) => ({
          ...prev,
          latitude: props.latitude,
          longitude: props.longitude,
          showlist: false,
          selectedTypeData: transformedData,
          communityName: props.communityName,
          data: props.data,
          totalData: transformedData,
        }));
        const typesArray = [...state.typesArray, obj.id];
        insertMap(transformedData, currentType, typesArray);
        if (currentPopup) {
          currentPopup.remove();
        }
        tab();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    // Fetch data when selectedProperty changes

    if (props.selectedProperty) {
      fetchData();
    }
  }, [
    props.selectedProperty,

    //state.types,

    props.latitude,

    props.longitude,

    state.radiusinMiles,
  ]);

  const insertMap = (markersData, typeName, selectedId) => {
    // console.log("markersData", selectedId);

    const mapInstance = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/examples/clg45vm7400c501pfubolb0xz",
      center:
        props.latitude !== 0 &&
        props.latitude !== null &&
        props.longitude !== 0 &&
        props.longitude !== null
          ? {
              lat: props.latitude,
              lng: props.longitude,
            }
          : { lat: 37.7739852, lng: -122.3912407 }, // Initial map center [lng, lat]
      zoom: 13, // Initial map zoom level
    });

    setMap(mapInstance);

    const nav = new mapboxgl.NavigationControl({
      showZoom: true,
      // showCompass: true,
    });
    mapInstance.addControl(nav, "bottom-left");

    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
      styles: [
        // Style for the drawn lines (e.g., black color)
        {
          id: "gl-draw-line",
          type: "line",
          filter: ["==", "$type", "LineString"],
          paint: {
            "line-color": "#000000", // Black color for lines
            "line-width": 2, // Adjust the line width
          },
        },
        // Style for the drawn polygons (e.g., black fill)
        {
          id: "gl-draw-polygon-fill",
          type: "fill",
          filter: ["==", "$type", "Polygon"],
          paint: {
            "fill-color": "#000000", // Black color for fill
            "fill-opacity": 0.3, // Adjust opacity if desired
          },
        },
        // Style for polygon outlines (black color)
        {
          id: "gl-draw-polygon-stroke",
          type: "line",
          filter: ["==", "$type", "Polygon"],
          paint: {
            "line-color": "#000000", // Black color for polygon stroke
            "line-width": 2, // Adjust stroke width
          },
        },
        // Style for the drawn points (if you're using points too)
        {
          id: "gl-draw-point",
          type: "circle",
          filter: ["==", "$type", "Point"],
          paint: {
            "circle-radius": 5, // Adjust radius
            "circle-color": "#000000", // Black color for points
          },
        },
      ],
      defaultMode: "simple_select",
    });
    mapInstance.addControl(draw);

    let allMarkers = []; // Array to store all markers
    let drawnShapes = []; // Store drawn shapes
    const defaultMarkersData = markersData;
    let newMarkers = [];

    // Convert markersData into GeoJSON format
    const geojson = {
      type: "FeatureCollection",
      features: markersData.map((data) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            data.geocodes.main.longitude,
            data.geocodes.main.latitude,
          ],
        },
        properties: {
          name: data.name,
          lat: data.geocodes.main.latitude,
          lng: data.geocodes.main.longitude,
          vicinity: data.location.formatted_address.toString(),
          place_id: data.fsq_id,
          typeName: data.selectedName, // Add typeName to properties
        },
      })),
    };

    allMarkers = geojson;
    // Load images for all custom markers
    loadCustomMarkerImages(mapInstance);

    // Create the custom trash button
    // const customTrashButton = document.createElement("button");
    // customTrashButton.innerText = "";
    // customTrashButton.className = "custom-trash-btn"; // Add your own class for styling if needed
    // customTrashButton.onclick = () => {
    //   const selectedFeatures = draw.getSelected();
    //   const allDrawnFeatures = draw.getAll().features;

    //   // Check if there are no drawn features
    //   if (allDrawnFeatures.length === 0) {
    //     alert("Please draw the polygon");
    //   } else {
    //     // Check if any of the drawn features is a polygon and has at least 3 coordinates
    //     const validPolygon = allDrawnFeatures.some((feature) => {
    //       const coords = feature.geometry.coordinates;
    //       // console.log(coords, "coords", coords.length);

    //       return coords[0].length > 3;
    //     });

    //     // If no valid polygon is found, alert the user
    //     if (!validPolygon) {
    //       alert(
    //         "The drawn shape is not a valid polygon (should have at least 3 coordinates)"
    //       );
    //     } else if (selectedFeatures.features.length === 0) {
    //       alert("Please select the boundary you would like to delete.");
    //     } else {
    //       // Trigger the draw trash functionality
    //       draw.trash();
    //     }
    //   }
    // };

    // // Find and remove the previous trash button if it exists
    // const existingTrashButton = document.querySelector(".custom-trash-btn");
    // if (existingTrashButton) {
    //   existingTrashButton.remove();
    //   console.log("Removed existing custom trash button.");
    // }
    // // Find the existing Mapbox Draw trash button by class name
    // const existingMapboxTrashButton = document.querySelector(
    //   ".mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_trash"
    // );

    // if (existingMapboxTrashButton) {
    //   // Append the custom button as a sibling to the existing trash button
    //   existingMapboxTrashButton.parentElement.appendChild(customTrashButton);
    // } else {
    //   console.warn("Existing trash button not found");
    // }

    // Add the data source with clustering enabled
    mapInstance.on("load", () => {
      mapInstance.addSource("markers", {
        type: "geojson",
        data: geojson,
        cluster: true,
        clusterMaxZoom: 14, // Max zoom level to cluster points
        clusterRadius: 50, // Cluster radius in pixels
      });

      // Add the cluster circles to the map
      mapInstance.addLayer({
        id: "clusters",
        type: "circle",
        source: "markers",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#000000", // color for small clusters
            100,
            "#000000", // color for medium clusters
            750,
            "#000000", // color for large clusters
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            20, // radius for small clusters
            100,
            30, // radius for medium clusters
            750,
            40, // radius for large clusters
          ],
        },
      });

      // Add the cluster count label
      mapInstance.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "markers",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Bold"],
          "text-size": 15,
        },
        paint: {
          "text-color": "#FFFFFF", // Set text color to white
        },
      });

      // Add unclustered points as markers with custom icons
      mapInstance.addLayer({
        id: "unclustered-point",
        type: "symbol",
        source: "markers",
        filter: ["!", ["has", "point_count"]],
        layout: {
          "icon-image": ["concat", "custom-marker-", ["get", "typeName"]], // Dynamically get the correct image based on typeName
          "icon-size": 1.0,
        },
      });

      // When a cluster is clicked, zoom into the cluster
      mapInstance.on("click", "clusters", (e) => {
        const features = mapInstance.queryRenderedFeatures(e.point, {
          layers: ["clusters"],
        });
        const clusterId = features[0].properties.cluster_id;
        mapInstance
          .getSource("markers")
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;
            mapInstance.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
      });

      // Show popup for unclustered points
      mapInstance.on("click", "unclustered-point", (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const { name, vicinity, place_id, lat, lng } = e.features[0].properties;
        console.log(e.features[0].properties, "properties");

        const popup = new mapboxgl.Popup({ offset: [0, -15] })
          .setLngLat(coordinates)
          .setHTML(
            `
            <h6>${name}</h6>
            <p>${vicinity}</p>
            <a href="https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}" target="_blank">Get Directions</a>
          `
          )
          .addTo(mapInstance);
        setCurrentPopup(popup);
        setState((prev) => ({
          ...prev,
          // activeMaker: data,
          activeNumber: place_id,
          latitude: coordinates[1],
          longitude: coordinates[0],
        }));
      });

      // Change the cursor to pointer when hovering over a cluster
      mapInstance.on("mouseenter", "clusters", () => {
        mapInstance.getCanvas().style.cursor = "pointer";
      });

      mapInstance.on("mouseleave", "clusters", () => {
        mapInstance.getCanvas().style.cursor = "";
      });
      // Draw functionality: add polygons and filter markers
      mapInstance.on("draw.create", async (event) => {
        const shape = event.features[0];
        drawnShapes.push(shape); // Add new shape to the array
        const coordinates = shape.geometry.coordinates[0];
        const coordinatesString = `${coordinates
          .map((coord) => `${coord[1]},${coord[0]}`) // Format: "latitude,longitude"
          .join("~")}`;

        let dataaaa = [
          { key: "Restaurants", id: "4bf58dd8d48988d14c941735" },
          { key: "Groceries", id: "4bf58dd8d48988d118951735" },
          { key: "Parks", id: "4bf58dd8d48988d163941735" },
          { key: "Shopping", id: "4bf58dd8d48988d1fd941735" },
          { key: "Entertainment", id: "4d4b7104d754a06370d81259" },
          { key: "Hospitals", id: "4bf58dd8d48988d196941735" },
        ];
        let obj = dataaaa.find((o) => o.key == state.type.toString());
        // let apiObject = {
        //   polygon: coordinatesString,
        //   categories: selectedId,
        // };
        // console.log(apiObject, "apiObject");

        // const newAPiCall = await fourSquarePlaces.getFourSquarePlaces(
        //   apiObject
        // );

        const fetchPromises = selectedId.map(async (type) => {
          // Create a new apiObject for each type
          let apiObjectNew = {
            polygon: coordinatesString,
            categories: type,
          };

          // console.log(apiObjectNew, "apiObject for type:", type);

          // Make the API call
          const response = await fourSquarePlaces.getFourSquarePlaces(
            apiObjectNew
          );
          // console.log(response, "response");

          // Check if response has results and attach typeId
          if (response) {
            return response.map((item) => {
              const { fsq_id, geocodes, location, name, rating } = item;
              // Find the matching category from `dataaaa` based on `typeId`
              const category = dataaaa.find((cat) => cat.id === type);
              const selectedName = category ? category.key : "Restaurants";
              return {
                fsq_id,
                geocodes,
                location,
                name,
                rating,
                selectedName, // Add `selectedName` key with the value from the category
              };
            });
          }
          return [];
        });

        // Wait for all promises to resolve and flatten the results
        const results = await Promise.all(fetchPromises);
        // console.log("Combined results:", results.flat());
        let newAPiCall = results.flat();
        // console.log(newAPiCall, "newAPiCall");

        setState((prev) => ({
          ...prev,
          activeName: "",
        }));

        addNewMarkersToMap(newAPiCall, mapInstance, shape);
        draw.changeMode("simple_select");
        // console.log(newMarkers, "newMarkers", flattenedData);
      });

      mapInstance.on("draw.delete", (event) => {
        const deletedFeatures = event.features; // Get deleted shapes
        const existingMarkers = mapInstance.getSource("markers")._data; // Get existing markers data

        // Create a new array to hold the markers that will be kept
        const markersToKeep = existingMarkers.features.filter((data) => {
          const markerPosition = [
            data.geometry.coordinates[0],
            data.geometry.coordinates[1],
          ];

          // Check if the marker is within any of the deleted shapes
          return !deletedFeatures.some((deletedShape) => {
            if (deletedShape.geometry.type === "Polygon") {
              return turf.booleanPointInPolygon(
                turf.point(markerPosition),
                turf.polygon(deletedShape.geometry.coordinates)
              );
            }
            return false; // Non-polygon shapes shouldn't be processed
          });
        });
        // console.log(markersToKeep, "markersToKeep");
        // Update existingMarkers to only include markers that are not deleted
        existingMarkers.features = markersToKeep;
        const outputData = transformData(markersToKeep);
        updateMarkerList(outputData, typeName);
        // Update the markers source with the remaining markers
        mapInstance.getSource("markers").setData(existingMarkers);

        // Remove deleted shapes from the localdrawnShapes array
        drawnShapes = drawnShapes.filter(
          (shape) => !deletedFeatures.some((deleted) => deleted.id === shape.id)
        );

        // Restore the markers if there are no drawn shapes left
        if (drawnShapes.length === 0) {
          // console.log("no shape", existingMarkers);
          updateMarkerList(defaultMarkersData, typeName);
          // existingMarkers.features = [];
          // Restore all original markers
          mapInstance.getSource("markers").setData(allMarkers);
          newMarkers = [];
        }
      });
    });

    const addNewMarkersToMap = async (
      newMarkersData,
      mapInstance,
      drawnPolygon
    ) => {
      // Clear existing markers before adding new ones
      const apiMarkersGeoJson = {
        type: "FeatureCollection",
        features: [], // Initialize with an empty feature collection
      };

      // Filter new markers based on whether they are inside the drawn shape
      const filteredMarkers = newMarkersData.filter((position) => {
        const markerPosition = [
          position.geocodes.main.longitude,
          position.geocodes.main.latitude,
        ];

        // Use Turf.js to check if the marker is within the drawn polygon
        return turf.booleanPointInPolygon(
          turf.point(markerPosition),
          turf.polygon(drawnPolygon.geometry.coordinates)
        );
      });
      newMarkers.push(filteredMarkers);
      const flattenedData = await flattenArray(newMarkers);

      // Convert filtered markers to GeoJSON format
      flattenedData.forEach((data) => {
        apiMarkersGeoJson.features.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              data.geocodes.main.longitude,
              data.geocodes.main.latitude,
            ],
          },
          properties: {
            name: data.name,
            lat: data.geocodes.main.latitude,
            lng: data.geocodes.main.longitude,
            vicinity: data.location.formatted_address.toString(),
            place_id: data.fsq_id,
            typeName: data.selectedName,
            rating: data.rating,
          },
        });
      });
      const outputData = transformData(apiMarkersGeoJson.features);
      updateMarkerList(outputData, typeName);
      // console.log(outputData, "outputData");
      // console.log(apiMarkersGeoJson.features, "apiMarkersGeoJson");

      // Check if markers source exists before updating
      if (mapInstance.getSource("markers")) {
        mapInstance.getSource("markers").setData(apiMarkersGeoJson);
      } else {
        console.error("Markers source not found on the map.");
      }
    };
    // Function to load all custom marker images based on typeName
    function loadCustomMarkerImages(map) {
      const iconMappings = {
        Restaurants: "/assets/images/icons/restaurant-new.png",
        Groceries: "/assets/images/icons/grocery-1.png",
        Parks: "/assets/images/icons/park-1.png",
        Shopping: "/assets/images/icons/shopping-3.png",
        Entertainment: "/assets/images/icons/entertainment-1.png",
        Hospitals: "/assets/images/icons/hos-1.png",
      };

      Object.keys(iconMappings).forEach((key) => {
        map.loadImage(iconMappings[key], (error, image) => {
          if (error) throw error;
          map.addImage(`custom-marker-${key}`, image);
        });
      });
    }

    let dataArray = [];
    if (props.isProperty) {
      dataArray = Array.isArray(props.data) ? props.data : [props.data];
    }
    if (props.isProperty && Array.isArray(dataArray)) {
      dataArray.forEach((eachdata) => {
        const propertyMarker = new mapboxgl.Marker({
          element: createPropertyMarkerElement(),
        })
          .setLngLat([eachdata.longitude, eachdata.latitude])
          .addTo(mapInstance);

        const propertyPopup = new mapboxgl.Popup({ offset: [0, -15] }).setHTML(`
          <p>${eachdata.address}</p>
        `);

        propertyMarker.setPopup(propertyPopup);

        propertyPopup.on("open", () => {
          setCurrentPopup(propertyPopup);
          setState((prev) => ({ ...prev, propertyInfo: true }));
        });

        propertyPopup.on("close", () => {
          setState((prev) => ({ ...prev, propertyInfo: false }));
        });
      });
    } else {
      console.error("props.data is not an array:", dataArray);
    }

    function createPropertyMarkerElement() {
      const el = document.createElement("div");
      el.className = "marker";
      el.style.backgroundImage = `url(/assets/images/propertyMarker.svg)`;
      el.style.backgroundSize = "contain";
      el.style.backgroundRepeat = "no-repeat";
      el.style.backgroundPosition = "center";
      el.style.width = "32px";
      el.style.height = "32px";
      return el;
    }
  };
  const transformData = (data) => {
    return data.map((item) => ({
      fsq_id: item.properties.place_id,
      geocodes: {
        main: {
          latitude: item.properties.lat,
          longitude: item.properties.lng,
        },
        roof: {
          latitude: item.properties.lat,
          longitude: item.properties.lng,
        },
      },
      location: {
        formatted_address: item.properties.vicinity,
        locality: item.properties.vicinity.split(",")[1]?.trim(), // Extract city
      },
      name: item.properties.name,
      rating: item.properties.rating,
      selectedName: item.properties.typeName,
    }));
  };

  const updateMarkerList = (filteredMarkers, typeName) => {
    // console.log(filteredMarkers, typeName, "filteredMarkers, typeName");
    setState((prev) => ({
      ...prev,
      restlist: filteredMarkers,
      totalData: filteredMarkers,
      totalSelectedData: filteredMarkers,
      restlistarray: [1, 2],
      latitude: props.latitude,
      longitude: props.longitude,
    }));
  };
  // Logic to flatten the array of arrays into a single array of objects
  const flattenArray = (nestedArray) => {
    // Use reduce to combine all sub-arrays into a single array
    return nestedArray.reduce((acc, currentArray) => {
      return acc.concat(currentArray);
    }, []);
  };
  const onetype = async (row, type) => {
    // console.log(row, "===", type);
    closePopupIfOpen();
    setState((prev) => ({
      ...prev,
      restaurantsArr: [],
      typesList: [],
      restaurantsList: [],
      Loader: true,
      rating: "",
      activeNumber: "",
    }));
    if (row.type == "restaurant") {
      setState((prev) => ({
        ...prev,
        // type: [row.type],
        type: row.type,
        showlist: false,
        typeName: row.name,
        typeicon: type,
        // selectedCategory: "Wings Joint",
      }));
      let event = "4bf58dd8d48988d14c941735";
      setState((prev) => ({ ...prev, selectedCategory: event }));

      let marker = props.data;
      const radiusInMeters = Math.round(state.radiusinMiles * 1609.34);
      let apiObject = {
        latitude: marker.latitude,
        longitude: marker.longitude,
        radius: radiusInMeters,
      };
      // console.log("marker", marker);
      let GetFourSquare = await AppController.fourSquarData(apiObject, event);
      // console.log(GetFourSquare, "GetFourSquare2");

      // let GetFourSquare = await GoogleMapsPlaces.GetNearByPlaces({
      //   latitude: props.latitude,
      //   longitude: props.longitude,
      //   type: "restaurant",
      //   keyword: "restaurant",
      //   radius: radiusInMeters,
      // });

      setState((prev) => ({
        ...prev,
        restlist: GetFourSquare,
        totalData: GetFourSquare,
        totalSelectedData: GetFourSquare,
        GetSquare: "",
        restlistarray: [1, 2],
        latitude: props.latitude,
        longitude: props.longitude,
        Loader: false,
      }));
      insertMap(GetFourSquare, row.name, event);
    } else {
      setState((prev) => ({
        ...prev,
        type: [row.type],
        showlist: false,
        typeName: row.name,
        typeicon: type,
        restlistarray: [],
        Loader: true,
      }));
      let dataaaa = [
        { key: "restaurant", id: "4bf58dd8d48988d116941735" },
        { key: "bar", id: "4bf58dd8d48988d116941735" },
        { key: "supermarket", id: "4bf58dd8d48988d118951735" },
        { key: "park", id: "4bf58dd8d48988d163941735" },
        { key: "shopping_mall", id: "4bf58dd8d48988d1fd941735" },
        { key: "night_club", id: "4d4b7104d754a06370d81259" },
        { key: "hospital", id: "4bf58dd8d48988d196941735" },
      ];
      let obj = dataaaa.find((o) => o.key == row.type.toString());
      // console.log(obj, "obj");
      // let GetSquare = await AppController.fourSquarData(state.data, obj.id);
      // console.log(GetSquare, "GetSquare");
      const radiusInMeters = Math.round(state.radiusinMiles * 1609.34);
      let apiObject = {
        latitude: props.latitude,
        longitude: props.longitude,
        radius: radiusInMeters,
      };
      // console.log(apiObject, obj.id, "apiObject, obj.id");

      let GetSquare = await AppController.fourSquarData(apiObject, obj.id);

      // let GetSquare = await GoogleMapsPlaces.GetNearByPlaces({
      //   latitude: props.latitude,
      //   longitude: props.longitude,
      //   type: obj.key,
      //   keyword: obj.key,
      //   radius: radiusInMeters,
      // });

      setState((prev) => ({
        ...prev,
        getlistarray: [1, 2],
        GetSquare: GetSquare,
        totalData: GetSquare,
        totalSelectedData: GetSquare,
        selectedCategory: obj.id,
        restlist: "",
        latitude: props.latitude,
        longitude: props.longitude,
        Loader: false,
      }));
      insertMap(GetSquare, row.name, obj.id);
    }
  };

  useEffect(() => {
    if (state.updatedTypes.length > 0) {
      const matchingIcons = state.types.filter((type) =>
        state.updatedTypes.includes(type.name)
      );

      // console.log(matchingIcons, "matchingIcons");

      const filteredData = state.totalData.filter(
        (data) => data.selectedName === matchingIcons[0].name
      );
      // console.log(filteredData, "filteredData");

      setState((prev) => ({
        ...prev,
        selectedTypeData: filteredData,
        selectedTypeIcon: matchingIcons[0].name,
      }));
    }
  }, [state.updatedTypes, state.totalData]);

  const multipleTypes = async (row, type, index, isChecked) => {
    // console.log(row, "===", type, index, isChecked);
    closePopupIfOpen();

    setState((prev) => ({
      ...prev,
      // Loader: true,
      activeNumber: "",
    }));
    let updatedTypes = [...state.typeName];

    // If it's "Restaurants", ensure it behaves as per checked/unchecked state
    if (row.name === "Restaurants") {
      if (isChecked) {
        updatedTypes.push(type);
        let event = "4bf58dd8d48988d14c941735";
        setState((prev) => ({ ...prev, selectedCategory: event }));
      } else {
        // Remove "Restaurants" from the array when it's unchecked
        updatedTypes = updatedTypes.filter((item) => item !== type);
        setState((prev) => ({ ...prev, selectedTypeData: "" }));
      }
    } else {
      // For other types, toggle in and out of the array
      if (updatedTypes.includes(type)) {
        updatedTypes = updatedTypes.filter((item) => item !== type);
      } else {
        updatedTypes.push(type);
      }
    }

    setState((prev) => ({
      ...prev,
      typeName: updatedTypes,
      updatedTypes: updatedTypes,
    }));

    // let allData = [...state.totalData];

    if (isChecked) {
      console.log(isChecked, "ischecked");

      let dataaaa = [
        { key: "Restaurants", id: "4bf58dd8d48988d14c941735" },
        { key: "Groceries", id: "4bf58dd8d48988d118951735" },
        { key: "Parks", id: "4bf58dd8d48988d163941735" },
        { key: "Shopping", id: "4bf58dd8d48988d1fd941735" },
        { key: "Entertainment", id: "4d4b7104d754a06370d81259" },
        { key: "Hospitals", id: "4bf58dd8d48988d196941735" },
      ];
      let obj = dataaaa.find((o) => o.key == row.name.toString());
      let marker = props.data;
      const radiusInMeters = Math.round(state.radiusinMiles * 1609.34);
      let apiObject = {
        latitude: marker.latitude,
        longitude: marker.longitude,
        radius: radiusInMeters,
      };
      let GetFourSquare = await AppController.fourSquarData(apiObject, obj.id);

      GetFourSquare = GetFourSquare.map((data) => {
        const { fsq_id, geocodes, location, name, rating } = data;
        return {
          fsq_id,
          geocodes,
          location,
          name,
          rating,
          selectedName: row.name, // Add `selectedName` key with the value from `row.name`
        };
      });

      // allData = [...allData, ...GetFourSquare];
      let updatedTotalData = [...state.totalData, ...GetFourSquare];
      let typesArray = [...state.typesArray, obj.id];

      setState((prev) => ({
        ...prev,
        restlist: GetFourSquare,
        // selectedTypeData: GetFourSquare,
        // selectedCategory: obj.id,
        typesArray: [...prev.typesArray, obj.id],
        totalData: [...prev.totalData, ...GetFourSquare],
        totalSelectedData: [...prev.totalData, ...GetFourSquare],
        GetSquare: "",
        restlistarray: [1, 2],
        latitude: props.latitude,
        longitude: props.longitude,
        Loader: false,
      }));
      insertMap(updatedTotalData, row.name, typesArray);
    } else {
      if (updatedTypes.length != 0) {
        console.log(isChecked, "unchecked");
        let totalData = state.totalData;
        let filterData = totalData.filter((data) => {
          return data.selectedName !== row.name;
        });

        // Find the corresponding ID to remove
        let dataaaa = [
          { key: "Restaurants", id: "4bf58dd8d48988d14c941735" },
          { key: "Groceries", id: "4bf58dd8d48988d118951735" },
          { key: "Parks", id: "4bf58dd8d48988d163941735" },
          { key: "Shopping", id: "4bf58dd8d48988d1fd941735" },
          { key: "Entertainment", id: "4d4b7104d754a06370d81259" },
          { key: "Hospitals", id: "4bf58dd8d48988d196941735" },
        ];
        let obj = dataaaa.find((o) => o.key == row.name.toString());
        let typesArray = state.typesArray.filter((id) => id !== obj.id);
        setState((prev) => ({
          ...prev,
          totalData: filterData,
          totalSelectedData: filterData,
          typesArray: prev.typesArray.filter((id) => id !== obj.id),
          latitude: props.latitude,
          longitude: props.longitude,
          Loader: false,
        }));
        insertMap(filterData, row.name, typesArray);
      } else {
        console.log("unchecked by else");

        setState((prev) => ({
          ...prev,
          totalData: [],
          totalSelectedData: [],
          selectedTypeData: [],
          typesArray: [],
          latitude: props.latitude,
          longitude: props.longitude,
          Loader: false,
        }));
        insertMap([], row.name, "");
      }
    }

    // console.log(allData, "allData");
  };

  const iconData = (typeName) => {
    // console.log(typeName, "typeName", state.totalData);
    let totalData = state.totalData;
    let filterData = totalData.filter((data) => {
      return data.selectedName === typeName;
    });
    // console.log(filterData, "filterData");
    setState((prev) => ({
      ...prev,
      selectedTypeData: filterData,
      selectedTypeIcon: typeName,
    }));
  };

  const closePopupIfOpen = () => {
    const popupElement = document.querySelector(".mapboxgl-popup");
    if (popupElement) {
      // console.log("Popup is currently open. Closing it...");
      popupElement.parentNode.removeChild(popupElement);
      setCurrentPopup(null); // Reset the state if you're tracking it
    } else {
      console.log("No popup is currently open.");
    }
  };
  const onChangeCategory = async (event) => {
    closePopupIfOpen();
    // console.log(event, "event of selected category");
    setState((prev) => ({
      ...prev,
      selectedCategory: event,
      rating: "",
      activeNumber: "",
    }));

    const radiusInMeters = Math.round(state.radiusinMiles * 1609.34);

    let apiObject = {
      latitude: props.latitude,
      longitude: props.longitude,
      radius: radiusInMeters,
    };
    // console.log(apiObject, event, "apiObject");

    let GetFourSquare = await AppController.fourSquarData(apiObject, event);

    GetFourSquare = GetFourSquare.map((data) => {
      const { fsq_id, geocodes, location, name, rating } = data;
      return {
        fsq_id,
        geocodes,
        location,
        name,
        rating,
        selectedName: "Restaurants", // Add `selectedName` key with the value from `row.name`
      };
    });

    // Filter out any existing data with `selectedName: "Restaurants"`
    let filteredTotalData = state.totalData.filter(
      (data) => data.selectedName !== "Restaurants"
    );

    let updatedTotalData = [...filteredTotalData, ...GetFourSquare];
    const updatedTypesArray = state.typesArray.map((type) =>
      type === "4bf58dd8d48988d14c941735" ? event : type
    );

    setState((prev) => ({
      ...prev,
      restlist: GetFourSquare,
      totalData: updatedTotalData,
      totalSelectedData: updatedTotalData,
      restlistarray: [1, 2],
      latitude: props.latitude,
      longitude: props.longitude,
    }));
    insertMap(updatedTotalData, "Restaurants", updatedTypesArray);
  };

  const handleChanged = async (event) => {
    // console.log(event, "=====");
    closePopupIfOpen();

    const miles = event;
    let category = "4bf58dd8d48988d14c941735";
    setState((prev) => ({
      ...prev,
      radiusinMiles: event,
      rating: "",
      activeNumber: "",
      selectedCategory: category,
    }));
    // let category = state.selectedCategory;

    const radiusInMeters = Math.round(miles * 1609.34);
    // console.log(radiusInMeters, "radiusInMeters");

    let apiObject = {
      latitude: props.latitude,
      longitude: props.longitude,
      radius: radiusInMeters,
    };
    // console.log(apiObject, "apiObject", category);
    let dataaaa = [
      { key: "Restaurants", id: "4bf58dd8d48988d14c941735" },
      { key: "Groceries", id: "4bf58dd8d48988d118951735" },
      { key: "Parks", id: "4bf58dd8d48988d163941735" },
      { key: "Shopping", id: "4bf58dd8d48988d1fd941735" },
      { key: "Entertainment", id: "4d4b7104d754a06370d81259" },
      { key: "Hospitals", id: "4bf58dd8d48988d196941735" },
    ];

    const fetchPromises = state.typesArray.map(async (type) => {
      // Make the API call
      const response = await AppController.fourSquarData(apiObject, type);

      // Check if response has results and attach typeId and selectedName
      if (response) {
        return response.map((item) => {
          const { fsq_id, geocodes, location, name, rating } = item;

          // Find the matching category from `dataaaa` based on `typeId`
          const category = dataaaa.find((cat) => cat.id === type);
          const selectedName = category ? category.key : "Unknown";

          return {
            fsq_id,
            geocodes,
            location,
            name,
            rating,
            selectedName, // Add `selectedName` key with the value from the category
          };
        });
      }
      return [];
    });

    // Wait for all promises to resolve and flatten the results
    const results = await Promise.all(fetchPromises);
    // console.log("Combined results:", results.flat());
    let GetFourSquare = results.flat();

    // let GetFourSquare = await AppController.multipleFourSquareData(
    //   apiObject,
    //   state.typesArray
    // );

    // GetFourSquare = GetFourSquare.map((data) => {
    //   const { fsq_id, geocodes, location, name, rating, typeId } = data;
    //   // Find the matching category from dataaaa based on the typeId
    //   const category = dataaaa.find((item) => item.id === typeId);
    //   const selectedName = category ? category.key : "Unknown";
    //   return {
    //     fsq_id,
    //     geocodes,
    //     location,
    //     name,
    //     rating,
    //     selectedName: selectedName, // Add `selectedName` key with the value from `row.name`
    //   };
    // });
    // console.log(GetFourSquare, "GetFourSquare", state.typesArray);

    setState((prev) => ({
      ...prev,
      restlist: GetFourSquare,
      totalData: GetFourSquare,
      totalSelectedData: GetFourSquare,
      restlistarray: [1, 2],
      latitude: props.latitude,
      longitude: props.longitude,
    }));

    insertMap(GetFourSquare, state.typeName, state.typesArray);
  };

  const handleDataByRating = (rate) => {
    // console.log(rate, "rate");

    setState((prev) => ({ ...prev, rating: rate, activeNumber: "" }));

    let totalSelectedData = state.totalSelectedData;
    let totalData = state.totalData;
    let dataAfterFilter = filterByRating(rate, totalSelectedData);

    setState((prev) => ({ ...prev, totalData: dataAfterFilter }));
    insertMap(dataAfterFilter, state.typeName, state.typesArray);
  };
  // Function to filter places based on the rating
  const filterByRating = (ratingThreshold, data) => {
    // console.log(ratingThreshold, "ratingThreshold");

    if (ratingThreshold != "") {
      return data.filter((place) => place.rating >= ratingThreshold);
    } else {
      return data;
    }
  };
  const handleMarkerInfo = (data) => {
    // console.log("handlemarker", data);
    if (!map) {
      console.error("Map instance is not available.");
      return;
    }
    setState((prev) => ({
      ...prev,
      activeMaker: data,
      activeNumber: data.fsq_id,
      latitude: data.geocodes.main.latitude,
      longitude: data.geocodes.main.longitude,
    }));
    const { name, vicinity, geometry } = data; // Extract relevant info from row
    // Center the map on the coordinates
    map.flyTo({
      center: [data.geocodes.main.longitude, data.geocodes.main.latitude], // Update coordinates to your data
      zoom: 13, // Adjust zoom level as needed
    });
    // Close the current popup if it's open
    if (currentPopup) {
      currentPopup.remove();
    }

    // Create a popup with the information
    const popup = new mapboxgl.Popup()
      .setLngLat([data.geocodes.main.longitude, data.geocodes.main.latitude])
      .setHTML(
        ` <h6>${data.name}</h6>
          <p>${data.location.formatted_address}</p>
          <a href="https://www.google.com/maps/dir/?api=1&destination=${data.geocodes.main.latitude},${data.geocodes.main.longitude}" target="_blank">Get Directions</a>
        `
      )
      .addTo(map);
    // Update the currentPopup state
    setCurrentPopup(popup);
  };
  const clearActiveMarker = () => {
    setState((prev) => ({ ...prev, activeMaker: null }));
  };
  const loadPlacesWithMarkers = async (event) => {
    let GetFourSquare = await AppController.fourSquarData(state.data, event);
  };
  const propertyInfo = (city) => {
    setState((prev) => ({ ...prev, propertyInfo: true }));
  };
  const clearProperty = () => {
    setState((prev) => ({ ...prev, propertyInfo: false }));
  };
  const heightView = () => {
    $(".toggleArrow").toggleClass("full");
  };
  let children = jData.data;
  let categoryList = [
    { name: "Restaurants", id: "4bf58dd8d48988d116941735" },
    { name: "Groceries", id: "4bf58dd8d48988d118951735" },
    { name: "Parks", id: "4bf58dd8d48988d163941735" },
    { name: "Shopping", id: "4bf58dd8d48988d1fd941735" },
    { name: "Entertainment", id: "4d4b7104d754a06370d81259" },
    { name: "Hospitals", id: "4bf58dd8d48988d196941735" },
  ];
  const updateCarouselPosition = (object) => {
    if (object.item.index != state.startPosition) {
      setState((prevState) => ({
        ...prevState,
        startPosition: object.item.index,
      }));
    }
  };
  const tab = () => {
    const screenWidth = window.innerWidth;
    // console.log(screenWidth, "screenWidth");

    let tabItems =
      parseInt(screenWidth) <= 576
        ? 1
        : parseInt(screenWidth) <= 767
        ? 2
        : parseInt(screenWidth) <= 991
        ? 3
        : parseInt(screenWidth) <= 1199
        ? 3
        : 5;
    setState((prevState) => ({
      ...prevState,
      tabItems: tabItems,
    }));
  };
  const updateCarouselPosition1 = (object) => {
    if (object.item.index != state.startPosition1) {
      setState((prevState) => ({
        ...prevState,
        startPosition1: object.item.index,
      }));
    }
  };
  const sideTab = () => {
    if (divRef.current) {
      const divWidth = divRef.current.offsetWidth;
      console.log(divWidth, "divWidth");
      let sidetabItems = parseInt(divWidth) <= 500 ? 3 : 10;
      setState((prevState) => ({
        ...prevState,
        sidetabItems: sidetabItems,
      }));
    }
  };

  const addClasstoDiv = () => {
    $(".li_dropDown").toggleClass("active");
  };

  // $(document).ready(function () {
  //   const screenWidth = window.innerWidth;
  //   if (screenWidth <= 575) {
  //     $(".client-testimonial-carousel").owlCarousel({
  //       items: 2,
  //       margin: 30,
  //       loop: false,
  //       dots: false,
  //     });
  //   } else if (screenWidth <= 767) {
  //     $(".client-testimonial-carousel").owlCarousel({
  //       items: 3,
  //       margin: 30,
  //       loop: false,
  //       dots: false,
  //     });
  //   } else {
  //     $(".client-testimonial-carousel").owlCarousel({
  //       items: 2,
  //       margin: 30,
  //       loop: false,
  //       dots: false,
  //     });
  //   }
  // });

  return (
    <div className="row m-0 position-relative ">
      <div className="col-md-12 p-0">
        {" "}
        {/* <div className="">{console.log(state.showlist, "28mar")}</div>{" "} */}
      </div>
      <div className="col-md-8  p-0 rounded-start-4 overflow-hidden">
        <div className="listViewOnMap_poi">
          <div className="custommultiCheckBox">
            <OwlCarousel
              key={state.updatedTypes}
              className="owl-theme"
              dots={false}
              nav={true}
              autoWidth={true}
              navText={[
                "<i class='fa-solid fa-angle-left'></i>",
                "<i class='fa-solid fa-angle-right'></i>",
              ]}
              items={state.tabItems}
              startPosition={state.startPosition}
              onDragged={(object) => updateCarouselPosition(object)}
              onTranslated={(object) => updateCarouselPosition(object)}
            >
              {state.types.map((type, index) => {
                return (
                  <div className="item" key={type.name}>
                    <div className="formrow">
                      <input
                        className="checkbox"
                        type="checkbox"
                        name={`check${index}`}
                        id={`check${index}`}
                        checked={state.updatedTypes.includes(type.name)}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          multipleTypes(type, type.name, index, isChecked);
                        }}
                      />
                      <label className="checklabel" htmlFor={`check${index}`}>
                        <i className={type.faicon}></i> {type.name}
                      </label>
                    </div>

                    {/* {type.name === "Restaurants" &&
                      state.updatedTypes.includes(type.name) &&
                      state.restlistarray &&
                      state.restlistarray.length > 0 && (
                        <div id="multi-rest" className="li_dropDown">
                          <label>Select Restaurant</label>
                          <Select
                            value={state.selectedCategory}
                            placeholder="Please select a Restaurant"
                            onClick={(event) => event.stopPropagation()}
                            onChange={(event) => onChangeCategory(event)} // This should properly update selected restaurant
                          >
                            {_.sortBy(children, [
                              function (o) {
                                return o;
                              },
                            ]).map((row, i) => {
                              return (
                                <Select.Option value={row.id} key={i}>
                                  {row.name}
                                </Select.Option>
                              );
                            })}
                          </Select>

                          <Select
                            value={state.rating}
                            placeholder="Please select a rating"
                            style={{ width: 110 }}
                            onChange={(event) => handleDataByRating(event)}
                          >
                            {" "}
                            <Select.Option value={""}>All rating</Select.Option>
                            <Select.Option value={10}>10+</Select.Option>
                            <Select.Option value={9}>9+</Select.Option>
                            <Select.Option value={8}>8+</Select.Option>
                            <Select.Option value={7}>7+</Select.Option>
                            <Select.Option value={6}>6+</Select.Option>
                            <Select.Option value={5}>5+</Select.Option>
                            <Select.Option value={4}>4+</Select.Option>
                            <Select.Option value={3}>3+</Select.Option>
                            <Select.Option value={2}>2+</Select.Option>
                            <Select.Option value={1}>1+</Select.Option>
                          </Select>

                          <div style={{ textAlign: "center" }}>
                            <input
                              id="duration-slider"
                              name="radiusmiles"
                              type="range"
                              value={state.radiusinMiles}
                              min="0"
                              max="50"
                              step="5"
                              className="slider"
                              onChange={(event) =>
                                handleChanged(event.target.value)
                              }
                            />
                            <div>{state.radiusinMiles} min</div>{" "}
                          </div>
                        </div>
                      )} */}
                  </div>
                );
              })}
            </OwlCarousel>
            {state.updatedTypes.includes("Restaurants") &&
              state.restlistarray &&
              state.restlistarray.length > 0 && (
                <div id="multi-rest" className="li_dropDown">
                  {/* <i
                    className="fa fa-angle-right d-md-none"
                    onClick={() => addClasstoDiv()}
                  ></i> */}
                  <span className="filterIcon" onClick={() => addClasstoDiv()}>
                    <img src="assets/images/icons/filter.png" />
                  </span>

                  <div className="textView">
                    <label>Select Restaurant</label>
                    <Select
                      value={state.selectedCategory}
                      placeholder="Please select a Restaurant"
                      onClick={(event) => event.stopPropagation()}
                      onChange={(event) => onChangeCategory(event)} // This should properly update selected restaurant
                    >
                      {_.sortBy(children, [
                        function (o) {
                          return o;
                        },
                      ]).map((row, i) => {
                        return (
                          <Select.Option value={row.id} key={i}>
                            {row.name}
                          </Select.Option>
                        );
                      })}
                    </Select>

                    <Select
                      value={state.rating}
                      placeholder="Please select a rating"
                      style={{ width: 110 }}
                      onChange={(event) => handleDataByRating(event)}
                    >
                      {" "}
                      <Select.Option value={""}>All rating</Select.Option>
                      {/* <Select.Option value={10}>10+</Select.Option> */}
                      <Select.Option value={9}>9+</Select.Option>
                      <Select.Option value={8}>8+</Select.Option>
                      <Select.Option value={7}>7+</Select.Option>
                      <Select.Option value={6}>6+</Select.Option>
                      <Select.Option value={5}>5+</Select.Option>
                      <Select.Option value={4}>4+</Select.Option>
                      <Select.Option value={3}>3+</Select.Option>
                      <Select.Option value={2}>2+</Select.Option>
                      <Select.Option value={1}>1+</Select.Option>
                    </Select>

                    <div style={{ textAlign: "center" }}>
                      <input
                        id="duration-slider"
                        name="radiusmiles"
                        type="range"
                        value={state.radiusinMiles}
                        min="0"
                        max="50"
                        step="5"
                        className="slider"
                        onChange={(event) => handleChanged(event.target.value)}
                      />
                      <div>{state.radiusinMiles} min</div>{" "}
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>

        <div className="relomap-height">
          <div id="map" style={{ width: "100%", height: "100%" }}></div>
        </div>
      </div>

      {state.Loader == true ? <div className="loading">Loading</div> : null}

      <div className="col-md-4 p-0">
        <div className="map-right-view rounded-end-4">
          <span className="toggleArrow" onClick={() => heightView()}>
            <i className="fa-solid fa-angle-up"></i>
          </span>

          <div className="heightGrid" ref={divRef}>
            {state.updatedTypes.length > 0 ? (
              <>
                <div className="slider mb-3">
                  <ul>
                    <OwlCarousel
                      key={state.selectedTypeIcon}
                      className="owl-theme"
                      dots={false}
                      nav={false}
                      autoWidth={true}
                      items={state.sidetabItems}
                      startPosition={state.startPosition1}
                      onDragged={(object) => updateCarouselPosition1(object)}
                      onTranslated={(object) => updateCarouselPosition1(object)}
                    >
                      {_.sortBy(state.types, [
                        function (o) {
                          return o;
                        },
                      ]).map((type, index) => {
                        // Check if the type name is in the updatedTypes array
                        if (state.updatedTypes.includes(type.name)) {
                          return (
                            <li
                              key={index}
                              className={`nav-item${
                                state.selectedTypeIcon === type.name
                                  ? " active"
                                  : ""
                              }`}
                            >
                              <span onClick={() => iconData(type.name)}>
                                {type.name}{" "}
                                <i
                                  className="fa fa-close"
                                  onClick={(e) => {
                                    multipleTypes(
                                      type,
                                      type.name,
                                      index,
                                      false
                                    );
                                  }}
                                ></i>
                              </span>
                            </li>
                          );
                        }
                        return null;
                      })}
                    </OwlCarousel>
                  </ul>
                  {state.updatedTypes.includes("Restaurants") ? (
                    ""
                  ) : (
                    <div className="select-dropdown">
                      <Select
                        value={state.rating}
                        placeholder="Please select a rating"
                        onChange={(event) => handleDataByRating(event)}
                      >
                        {" "}
                        <Select.Option value={""}>All rating</Select.Option>
                        {/* <Select.Option value={10}>10+</Select.Option> */}
                        <Select.Option value={9}>9+</Select.Option>
                        <Select.Option value={8}>8+</Select.Option>
                        <Select.Option value={7}>7+</Select.Option>
                        <Select.Option value={6}>6+</Select.Option>
                        <Select.Option value={5}>5+</Select.Option>
                        <Select.Option value={4}>4+</Select.Option>
                        <Select.Option value={3}>3+</Select.Option>
                        <Select.Option value={2}>2+</Select.Option>
                        <Select.Option value={1}>1+</Select.Option>
                      </Select>
                      <Select
                        value={state.radiusinMiles}
                        placeholder="Please select a rating"
                        onChange={(event) => handleChanged(event)}
                      >
                        {" "}
                        <Select.Option value={5}>5 Miles</Select.Option>
                        <Select.Option value={10}>10 Miles</Select.Option>
                        <Select.Option value={15}>15 Miles</Select.Option>
                        <Select.Option value={20}>20 Miles</Select.Option>
                        <Select.Option value={25}>25 Miles</Select.Option>
                        <Select.Option value={30}>30 Miles</Select.Option>
                      </Select>
                    </div>
                  )}
                </div>

                <div className="point-tails">
                  {state.selectedTypeData &&
                  state.selectedTypeData.length > 0 ? (
                    state.selectedTypeData.map((row, index) => {
                      return (
                        <div
                          className={
                            row.fsq_id == state.activeNumber
                              ? "tail-view active"
                              : "tail-view"
                          }
                          key={index}
                          onClick={() => handleMarkerInfo(row)}
                        >
                          <ul>
                            <li>
                              <h1>{row.name}</h1>
                            </li>
                            <li>{row.location.formatted_address.toString()}</li>
                          </ul>
                        </div>
                      );
                    })
                  ) : (
                    <h3 className="no-content-msg MiddleText">
                      There are no data available at this time.
                    </h3>
                  )}
                </div>
              </>
            ) : (
              <h3 className="no-content-msg emptyText">
                Please select any one option.
              </h3>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapViewFunctional;
