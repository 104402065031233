import React from "react";

function UpdatePopUP(props) {
  // console.log(props, "props update ");

  const {
    closeEditPopUp,
    state,
    onChangeEvent,
    onChangeTimeSlotEvent,
    updateEvent,
  } = props;
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="width-70per pd-y-60">
          <div className="only_close">
            <span
              className="pointer close"
              data-dismiss="modal"
              onClick={closeEditPopUp}
            >
              &times;
            </span>
          </div>
          <h6>Edit slots</h6>

          <div className="modal-body">
            {state.Modalerror == true ? (
              <div className="alert alert-danger" role="alert">
                {state.ErrorMsg}
              </div>
            ) : null}

            <div className="row main-pop">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="col-form-label">Type</label>
                  <select
                    className="custom-select"
                    id="serviceDrodown"
                    name="serviceDrodown"
                    value={state.typeId}
                    onChange={onChangeEvent}
                  >
                    <option value="WORKING">WORKING</option>
                  </select>
                </div>
                <div className="form-group">
                  <label className="col-form-label">Date</label>
                  <p className="p2 form-control">{state.popupDate}</p>
                </div>
                <div className="form-group">
                  <label className="col-form-label">Start Time</label>
                  <select
                    className="custom-select"
                    id="startTimeId"
                    name="startTimeId"
                    value={state.startTimeId}
                    onChange={onChangeTimeSlotEvent}
                  >
                    {state.timeSlots &&
                      state.timeSlots.map((row, index) => {
                        return (
                          <option key={index} value={row}>
                            {row}
                          </option>
                        );
                      })}
                  </select>
                </div>

                <div className="form-group">
                  <label className="col-form-label">End Time</label>
                  <select
                    className="custom-select"
                    id="endTimeId"
                    name="endTimeId"
                    value={state.endTimeId}
                    onChange={onChangeTimeSlotEvent}
                  >
                    {state.timeSlots &&
                      state.timeSlots.map((row, index) => {
                        return (
                          <option key={index} value={row}>
                            {row}
                          </option>
                        );
                      })}
                  </select>
                </div>

                <div className="form-group">
                  <label className="col-form-label">Day</label>
                  <div className="form-para">
                    <ul>
                      <li className={state.dayName == 1 ? "active" : ""}>
                        <a>M</a>
                      </li>
                      <li className={state.dayName == 2 ? "active" : ""}>
                        <a>T</a>
                      </li>
                      <li className={state.dayName == 3 ? "active" : ""}>
                        <a href="javascript:void(0)">W</a>
                      </li>
                      <li className={state.dayName == 4 ? "active" : ""}>
                        <a>Th</a>
                      </li>
                      <li className={state.dayName == 5 ? "active" : ""}>
                        <a>F</a>
                      </li>
                      <li className={state.dayName == 6 ? "active" : ""}>
                        <a>S</a>
                      </li>
                      <li className={state.dayName == 0 ? "active" : ""}>
                        <a>Su</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="justify-content-center mb-4 text-center">
            <button
              className="btn theme-btn rounded-btn  mt-3 mr-3"
              onClick={() => updateEvent(false)}
            >
              Update
            </button>
            <button
              className="btn theme-btn rounded-btn  mt-3"
              onClick={() => updateEvent(true)}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdatePopUP;
