import React, { useEffect, useState } from "react";
// import "./diedetails.css";
import UsMap from "./usMapDetails";
import ScoreCards from "./scoreCards";
import ReligiousCenters from "./religiousCenters";
// import DiversityInNeighborhood from "./diversityInNeighborhood";
import AppController from "../../../controllers/appController";
import LoginStore from "../../../../services/thirdPartyService";
import Header from "../../../common/header/Header";
import Footer from "../../../common/footer/Footer";

function DieDetails() {
  const [state, setState] = useState({
    city: "Florida",
    changeTab: "",
    stateArray: [],
    activeTab: "EQUALITY",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    async function getSessionData() {
      let getSessionData = await LoginStore.getLoginInfo({});
      await AppController.logData(
        "Equality Index",
        "Diversity & Inclusion",
        location.pathname,
        getSessionData.userUniqueId,
        ""
      );
    }
    getSessionData();
  }, []);
  const handleChangeTab = async (stateArray) => {
    setState((prev) => ({
      ...prev,
      stateArray: stateArray,
      changeTab: "Score Cards",
      activeTab: "SCORE",
    }));
    let getSessionData = await LoginStore.getLoginInfo({});
    await AppController.logData(
      "Score Cards",
      "Diversity & Inclusion",
      location.pathname,
      getSessionData.userUniqueId,
      ""
    );
  };
  const getindex = async () => {
    setState((prev) => ({
      ...prev,
      changeTab: false,
      activeTab: "EQUALITY",
    }));
    let getSessionData = await LoginStore.getLoginInfo({});
    await AppController.logData(
      "Equality Index",
      "Diversity & Inclusion",
      location.pathname,
      getSessionData.userUniqueId
    );
  };
  const changeTap = async (tabName) => {
    if (tabName === "Score Cards") {
      setState((prev) => ({
        ...prev,
        changeTab: "Score Cards",
        activeTab: "SCORE",
      }));
    } else if (tabName === "Religious Centers") {
      setState((prev) => ({
        ...prev,
        changeTab: "Religious Centers",
        activeTab: "RELIGIOUS",
      }));
    } else if (tabName == "Diversity") {
      setState((prev) => ({
        ...prev,
        changeTab: "Diversity",
        activeTab: "DIVERSITY",
      }));
    } else {
      setState((prev) => ({
        ...prev,
        changeTab: "",
        activeTab: "EQUALITY",
      }));
    }
    let getSessionData = await LoginStore.getLoginInfo({});
    await AppController.logData(
      tabName,
      "Diversity & Inclusion",
      location.pathname,
      getSessionData.userUniqueId
    );
  };
  return (
    <>
      <div className="city-landing-page newOne die-dataview">
        <div className="tabs-view">
          <ul className="nav nav-tabs  border-0" role="tablist">
            <li className="nav-item">
              <a
                className={
                  state.changeTab != "" ? "nav-link" : "nav-link active"
                }
                onClick={() => getindex()}
                data-toggle="tab"
                href="javascript:void(0)"
              >
                Equality Index
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  state.changeTab == "Score Cards"
                    ? "nav-link active"
                    : "nav-link "
                }
                onClick={() => changeTap("Score Cards")}
                data-toggle="tab"
                href="javascript:void(0)"
              >
                Score Cards
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  state.changeTab == "Religious Centers"
                    ? "nav-link active"
                    : "nav-link "
                }
                data-toggle="tab"
                href="javascript:void(0)"
                onClick={() => changeTap("Religious Centers")}
              >
                Religious Centers
              </a>
            </li>
            {/* <li className="nav-item">
            <a
              className={
                state.changeTab == "Diversity" ? "nav-link active" : "nav-link "
              }
              data-toggle="tab"
              //   href="#tabDiversityNeighborhood"
              onClick={() => changeTap("Diversity")}
            >
              DIVERSITY IN YOUR NEIGHBORHOOD
            </a>
          </li> */}
          </ul>
        </div>
        {state.activeTab === "EQUALITY" && (
          <div id="tabEQUALITY" className="tab-content container">
            <UsMap tabChange={handleChangeTab} />
          </div>
        )}
        {state.activeTab === "SCORE" && (
          <div id="tabSCORE" className="tab-content container">
            <ScoreCards selectedState={state.stateArray} />
          </div>
        )}
        {state.activeTab === "RELIGIOUS" && (
          <div id="" className="">
            <ReligiousCenters />
          </div>
        )}
        {/* {state.activeTab === "DIVERSITY" && (
          <div id="tabDiversityNeighborhood" className="tab-content">
            <DiversityInNeighborhood />
          </div>
        )} */}
      </div>

      {state.activeTab != "RELIGIOUS" && <Footer />}
    </>
  );
}

export default DieDetails;
