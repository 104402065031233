import { Modal, Select } from "antd";
import React, { useEffect } from "react";
import moment from "moment";
import ApiServices from "../../../../../services/apiServices";
import LoginStore from "../../../../../services/thirdPartyService";
import SchedulesSearchProperties from "../SchedulesSearchProperties";

function AppointmentPopUp(props) {
  const { state, saveEvent, setState, serviceslist, sendEmail, sendSms } =
    props;

  const onChangeDropDownServiceType = async (e) => {
    setState((prev) => ({ ...prev, servicetype: e }));
  };
  const onChangeDropDownType = async (e) => {
    setState((prev) => ({ ...prev, scheduleType: e }));
  };
  const closeDetailsPopUp = () => {
    $("document").ready(function () {
      $("#appointmentPopUp").modal("hide");
    });
    setState((prev) => ({ ...prev, modalPopUp: false, succesMsg: "" }));
  };

  const onChangetimessslot = async (type, value) => {
    if (type == "startTime") {
      setState((prev) => ({ ...prev, startTime: value }));
    } else {
      setState((prev) => ({ ...prev, endTime: value }));
    }
    let startedTime =
      type == "startTime" && state.startTime != value ? value : state.startTime;
    let endedTime =
      type == "endTime" && state.endTime != value ? value : state.endTime;
    let startTime = moment(startedTime, "HH:mm A");
    let endTime = moment(endedTime, "HH:mm A");
    let startDatewe = moment(state.popupDate).format("YYYY-MM-DD");
    const dateTime = moment(
      `${startDatewe} ${startedTime}`,
      "YYYY-MM-DD h:mm A"
    ).format();
    const dateTime2 = moment(
      `${startDatewe} ${endedTime}`,
      "YYYY-MM-DD h:mm A"
    ).format();

    let date1 = new Date(dateTime);
    let date2 = new Date(dateTime2);

    let timeDiff = await Math.abs(date2.getTime() - date1.getTime());

    if (endTime.diff(startTime, "hours") < 1) {
      setState((prev) => ({
        ...prev,
        Modalerror: true,
        ErrorMsg:
          "The minimum duration for an appointment is one hour. Please schedule appointments for at least an hour with your consultant.",
        diffTime: timeDiff / (1000 * 3600),
      }));
    } else {
      setState((prev) => ({
        ...prev,
        Modalerror: false,
        ExitsErrorMsg: "",
        ErrorMsg: "",
        diffTime: timeDiff / (1000 * 3600),
      }));
    }
  };
  const onChangeDropDown = async (e) => {
    console.log(e, "hello", serviceslist);
    setState((prev) => ({ ...prev, packageserviceuniqueid: e }));
    let serviceList = Object.assign([], serviceslist);

    let serviceId = _.filter(serviceList, function (o) {
      return o.servicename == "Property Tours";
    });
    let settingId = _.filter(serviceList, function (o) {
      return o.servicename == "Settling in Services";
    });

    setState((prev) => ({ ...prev, serviceDrodown: e }));
    if (e.includes(serviceId[0].serviceuniqueId)) {
      setState((prev) => ({ ...prev, showProperties: true }));
    } else {
      setState((prev) => ({ ...prev, showProperties: false }));
    }
    if (e.includes(settingId[0].serviceuniqueId)) {
      setState((prev) => ({ ...prev, showServiceType: true }));
    } else {
      setState((prev) => ({ ...prev, showServiceType: false }));
    }
    await getServiceNameBinding(e);
  };
  const getServiceNameBinding = async (serviceDrodown) => {
    let Title = "";

    _.each(serviceDrodown, async (serviceId, index) => {
      let serviceName = await getServiceText(serviceId);
      Title = serviceName + "," + Title;
      setState((prev) => ({
        ...prev,
        Title: Title.replace(/,([^,]*)$/, "$1"),
      }));
    });
  };
  const getServiceText = async (SubscriptionId) => {
    console.log(SubscriptionId, "SubscriptionId");
    let SubscriptionService = _.filter(serviceslist, function (o) {
      return o.serviceuniqueId == SubscriptionId;
    });
    console.log(SubscriptionService, "SubscriptionService");
    return serviceslist.length > 0
      ? SubscriptionService[0].servicename
      : "servicename";
  };
  const setLocation = async (Id, e) => {
    let address = e.target.value;
    setState((prev) => ({
      ...prev,
      [Id]: address,
    }));

    var places = new google.maps.places.Autocomplete(
      document.getElementById(Id)
    );
    places.setComponentRestrictions({ country: ["us"] });
    google.maps.event.addListener(places, "place_changed", async () => {
      var place = places.getPlace();
      let relocatedAddress = place.formatted_address;
      // console.log(relocatedAddress, "relocatedAddress");
      setState((prev) => ({
        ...prev,
        pickupLocation2: relocatedAddress,
        pickupLocation: relocatedAddress,
      }));
    });
  };
  const inputHandler = async (inputName, event) => {
    event.persist();
    let inputVal = event.target.value;
    let inputObject = {};
    inputObject[inputName] = inputVal;
    setState((prev) => ({ ...prev, [inputName]: inputVal }));
  };
  const showMessage = () => {
    setState((prev) => ({ ...prev, modalPopUp: false, succesMsg: "" }));
  };

  return (
    <>
      {/* <Modal
        open={state.modalPopUp}
        footer={null}
        onCancel={closePopUP}
        width={600}
      > */}
      <div className="modal-dialog modal-lg ">
        <div className="modal-content  p-4 p-md-5">
          <div className="only_close">
            <span
              className="pointer close"
              data-dismiss="modal "
              onClick={closeDetailsPopUp}
            >
              &times;
            </span>
          </div>
          <div className="">
            <div>
              <h6 className="title">
                Appointment with {state.consultantName}{" "}
              </h6>
              <div className="modal-body px-md-5">
                {state.succesMsg != "" && (
                  <h5 className="alert alert-success">{state.succesMsg}</h5>
                )}
                {state.isConfirmed && state.confirmError != "" ? (
                  <div className="alert alert-danger" role="alert">
                    {state.confirmError}
                  </div>
                ) : null}
                {state.ExitsErrorMsg != "" ? (
                  <div className="alert alert-danger" role="alert">
                    {state.ExitsErrorMsg}
                  </div>
                ) : (
                  ""
                )}
                {state.Modalerror == true && !state.isConfirmed ? (
                  state.ErrorMsg != 1 ? (
                    <div className="alert alert-danger" role="alert">
                      {state.ErrorMsg}
                    </div>
                  ) : (
                    <div className="alert alert-danger" role="alert">
                      The current appointment you are scheduling exceeds the
                      remaining consultation time left in your account. Please
                      contact your consultant if you need additional support
                      through{" "}
                      <a href="javascript:void(0)" onClick={showMessage}>
                        message center.
                      </a>
                    </div>
                  )
                ) : null}
                {state.showRestriction == true && (
                  <div>
                    {!state.isConfirmed && (
                      <div>
                        <div
                          className="ul_select_list ant-slect-custom "
                          id="testPosition"
                        >
                          <span className="labelspan">Appointment Type</span>
                          <span className="main_span">
                            <Select
                              mode="multiple"
                              name="serviceDrodown"
                              value={state.packageserviceuniqueid}
                              getPopupContainer={() =>
                                document.getElementById("testPosition")
                              }
                              style={{ minWidth: 220 }}
                              onChange={(e) => onChangeDropDown(e)}
                              size="default"
                            >
                              {serviceslist &&
                                serviceslist.map((row, index) => {
                                  return (
                                    <Select.Option
                                      key={row.servicename}
                                      value={row.serviceuniqueId}
                                    >
                                      {row.servicename}
                                    </Select.Option>
                                  );
                                })}
                            </Select>{" "}
                          </span>
                        </div>
                      </div>
                    )}
                    {state.isConfirmed && (
                      <div className="ul_select_list text_overfloww">
                        <span>Appointment Type*</span>
                        <span className="main_span"> {state.Title}</span>
                      </div>
                    )}
                    {!state.isConfirmed && state.showServiceType == true && (
                      <div>
                        <div
                          className="ul_select_list ant-slect-custom "
                          id="testPosition"
                        >
                          <span className="labelspan">Service Type</span>
                          <span className="main_span">
                            <Select
                              name="serviceType"
                              value={state.servicetype}
                              getPopupContainer={() =>
                                document.getElementById("testPosition")
                              }
                              style={{ minWidth: 220 }}
                              onChange={onChangeDropDownServiceType}
                              size="default"
                            >
                              {state.masterServiceType.map((row, index) => {
                                return (
                                  <Select.Option key={row} value={row}>
                                    {row}
                                  </Select.Option>
                                );
                              })}
                            </Select>{" "}
                          </span>
                        </div>
                      </div>
                    )}
                    {state.isConfirmed && state.showServiceType == true && (
                      <div className="ul_select_list text_overfloww">
                        <span>Service Type*</span>
                        <span className="main_span"> {state.servicetype}</span>
                      </div>
                    )}
                    {!state.isConfirmed && (
                      <div>
                        <div
                          className="ul_select_list ant-slect-custom "
                          id="testPosition"
                        >
                          <span className="labelspan">Type</span>
                          <span className="main_span">
                            <Select
                              name="serviceDrodown"
                              value={state.scheduleType}
                              getPopupContainer={() =>
                                document.getElementById("testPosition")
                              }
                              style={{ minWidth: 220 }}
                              onChange={onChangeDropDownType}
                              size="default"
                            >
                              {state.typesofSchedules.map((row, index) => {
                                return (
                                  <Select.Option key={row} value={row}>
                                    {row}
                                  </Select.Option>
                                );
                              })}
                            </Select>{" "}
                          </span>
                        </div>
                      </div>
                    )}

                    {state.isConfirmed && state.scheduleType != "" && (
                      <div className="ul_select_list text_overfloww">
                        <span>Type*</span>
                        <span className="main_span"> {state.scheduleType}</span>
                      </div>
                    )}
                    {!state.isConfirmed && (
                      <div className="ul_select_list ant-slect-custom ">
                        <span className="labelspan">Start Time</span>
                        <span className="main_span border-0 mb-0">
                          {" "}
                          <Select
                            value={state.startTime}
                            style={{ minWidth: 220 }}
                            onChange={(e) => onChangetimessslot("startTime", e)}
                          >
                            {state.timessslot.map((row, index) => {
                              if (state.timessslot.length > index + 4)
                                return (
                                  <Select.Option
                                    key={"start" + index}
                                    value={row}
                                  >
                                    {row}
                                  </Select.Option>
                                );
                            })}
                          </Select>
                        </span>
                      </div>
                    )}
                    {!state.isConfirmed && (
                      <div className="ul_select_list ant-slect-custom ">
                        <span className="labelspan">End Time</span>
                        <span className="main_span mb-3 pb-3">
                          {" "}
                          <Select
                            value={state.endTime}
                            style={{ minWidth: 220 }}
                            onChange={(e) => onChangetimessslot("endTime", e)}
                          >
                            {state.timessslot.map((row, index) => {
                              if (index > 3)
                                return (
                                  <Select.Option
                                    key={"end" + index}
                                    value={row}
                                  >
                                    {row}
                                  </Select.Option>
                                );
                            })}
                          </Select>
                        </span>
                      </div>
                    )}

                    <ul className="appoint_list">
                      <li>
                        <a href="javascript:void(0)">
                          {" "}
                          Date <span>{state.popupDate}</span>{" "}
                        </a>
                      </li>
                      {state.isConfirmed && (
                        <li>
                          <a href="javascript:void(0)">
                            {" "}
                            Time{" "}
                            <span>
                              {state.startTime} - {state.endTime}
                            </span>{" "}
                          </a>
                        </li>
                      )}
                      <li>
                        <a href="javascript:void(0)">
                          {" "}
                          Total Hours <span>{state.diffTime} Hours</span>{" "}
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          {" "}
                          Day <span>
                            {state.dayNameLabel[state.dayName]}
                          </span>{" "}
                        </a>
                      </li>
                    </ul>
                    <div
                      className="focus-input-label"
                      style={{ marginTop: "10px" }}
                    ></div>
                    {state.isConfirmed && state.scheduleType == "In Person" && (
                      <div
                        className="ul_select_list text_overfloww"
                        style={{ marginTop: "20px" }}
                      >
                        <span>Pickup Location*</span>
                        <span className="main_span">
                          {" "}
                          {state.pickupLocation}
                        </span>
                      </div>
                    )}
                    {state.isConfirmed && (
                      <div className="ul_select_list text_overfloww">
                        <span>Notes</span>
                        <span className="main_span"> {state.serviceNote}</span>
                      </div>
                    )}

                    {!state.isConfirmed &&
                      state.scheduleType == "In Person" && (
                        <div
                          className="focus-input-label"
                          style={{ marginTop: "20px" }}
                        >
                          <div className="floating-label">
                            <input
                              className="form-control"
                              placeholder="Pickup Location"
                              type="text"
                              value={state.pickupLocation}
                              name="pickupLocation"
                              id="pickupLocation"
                              onChange={(e) => setLocation("pickupLocation", e)}
                            />
                            <label>Pickup Location</label>
                            <i
                              className="fa fa-map-marker"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      )}
                    {!state.isConfirmed && (
                      <div className="focus-input-label">
                        <div className="floating-label">
                          <textarea
                            className="form-control"
                            rows="6"
                            placeholder="Leave a note here!"
                            id="serviceNote"
                            onChange={(e) => inputHandler("serviceNote", e)}
                          />
                          <label>Notes</label>
                        </div>
                      </div>
                    )}
                    {state.showProperties && !state.isConfirmed && (
                      <div className="properties_list">
                        <SchedulesSearchProperties {...state} />
                      </div>
                    )}
                  </div>
                )}
              </div>
              {!state.isConfirmed && state.showRestriction == true && (
                <div className="text-center  mb-4 justify-content-center">
                  <button
                    className="btn theme-btn rounded-btn"
                    onClick={saveEvent}
                  >
                    Confirm
                  </button>
                </div>
              )}
              {state.isConfirmed && (
                <div className="text-center mb-3  justify-content-center">
                  {state.PhoneNumber != "" && state.PhoneNumber != undefined ? (
                    <button
                      className="btn theme-btn rounded-btn me-3"
                      onClick={sendSms}
                    >
                      Send to my phone
                    </button>
                  ) : (
                    ""
                  )}

                  <button
                    className="btn theme-btn rounded-btn "
                    onClick={sendEmail}
                  >
                    Send to my email
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* </Modal> */}
    </>
  );
}

export default AppointmentPopUp;
