import React from "react";

const TransfereeModal = (props) => {
  const { State } = props;
  return (
    <div
      className="modal fade"
      id="all-Status"
      tabIndex="-1"
      aria-labelledby="all-Status"
      aria-hidden="true"
      style={{ zIndex: 1060 }}
    >
      <div className="modal-dialog modal-lg modal-lg-2">
        <div className="modal-content ">
          <div className="width-70per pd-y-60">
            <div className="only_close">
              <span className="pointer close" data-bs-dismiss="modal">
                &times;
              </span>
            </div>
            <div className="container">
              <h2>Transferee Status</h2>
              <table className="table">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {State.allstatuslist.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>{row.identifier}</td>
                        <td>
                          <i
                            className={
                              _.filter(State.TransfereeStatus, function (o) {
                                return o.statusuid == row.statusUId;
                              }).length > 0
                                ? "fa fa-check"
                                : "fa fa-times"
                            }
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransfereeModal;
