
import './iternaryProperties.css';
import _ from 'lodash';
import { useState, useEffect } from 'react';
import ItenaryAppointments from './itenaryAppointments';

const MyItenaries =(props)=> {
  let _isMounted = false;
  
  useEffect(() =>{
    return()=>
    _isMounted = false;

  })

    return (
      <div>
        <ItenaryAppointments showSchedules={props.showSchedules} />
      </div>
    )
}

export default MyItenaries;