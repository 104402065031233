import React, { useEffect } from "react";
import PublicHeader from "../newpublicPage/common/publicHeader";
import PublicFooter from "./common/publicFooter";
import PublicBanner from "./common/publicBanner";
import AOS from "aos";
// import "aos/dist/aos.css";

function NewPublicPage() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);
  let items = document.querySelectorAll(".carousel .carousel-item");

  items.forEach((el) => {
    const minPerSlide = 4;
    let next = el.nextElementSibling;
    for (var i = 1; i < minPerSlide; i++) {
      if (!next) {
        // wrap carousel by using first child
        next = items[0];
      }
      let cloneChild = next.cloneNode(true);
      el.appendChild(cloneChild.children[0]);
      next = next.nextElementSibling;
    }
  });
  const demoBtn = () => {
    location.href = "/contact-us";
  };
  return (
    <div>
      {/* <PublicHeader /> */}
      <PublicBanner />
      <div className="container my-5">
        <h2 data-aos="fade-up">The All-in-One Solution</h2>
        <p data-aos="fade-up">
          Empowering Companies . Supporting Consultants . Guiding Individuals
        </p>

        <div className="tials">
          <div className="box" data-aos="fade-up">
            <img src="assets/publicImages/gridImage-3.png" alt="" />
            <h3>For Companies</h3>
            <p>
              ReloNavigator enables DS companies to efficiently manage
              consultants, streamline operations, and ensure top-tier service.
              With powerful reporting and tools to oversee consultant activities
              and appointments, you can deliver seamless, data-driven services
              to your clients.
            </p>
          </div>
          <div className="box" data-aos="fade-up">
            <img src="assets/publicImages/gridImage-2.png" alt="" />
            <h3>For Consultant</h3>
            <p>
              ReloNavigator equips consultants with the tools to manage
              appointments and offer personalized destination services. With
              features like personalized property recommendations, direct chat
              with transferees, and detailed reporting, you can deliver a
              tailored and seamless relocation experience
            </p>
          </div>

          <div className="box" data-aos="fade-up">
            <img src="assets/publicImages/gridImage-1.png" alt="" />
            <h3>For Individuals</h3>
            <p>
              ReloNavigator empowers individuals with personalized tools to
              simplify every step of the relocation. Access area information,
              school finder, neighborhood insights, home finding, DE&I data,
              moving resources, and much more to make informed decisions with
              ease
            </p>
          </div>
        </div>
      </div>

      <div className="pt-5 pb-4" data-aos="fade-up">
        <div className="container pb-2">
          <div className="view bg-imageView row rounded-3 overflow-hidden mb-5 ">
            <div className="box-item col-md-6 ps-5 d-flex justify-content-center flex-column align-items-start">
              <h2 className="text-white">Leading the Way with Innovation</h2>
              <p className="text-white">
                Our patented technology sets us apart. ReloNavigator ensures
                accurate, reliable, and efficient relocation support that's a
                step ahead of the rest.
              </p>
              <button type="button" className="btn" onClick={() => demoBtn()}>
                Request a Demo
              </button>
            </div>
            <div className="box-image col-md-6"></div>
          </div>
        </div>
      </div>

      <div>
        <div className="container">
          <div data-aos="fade-up">
            <h2 className="text-center">
              All the Tools You Need in One Platform
            </h2>
            <p className="text-center">
              Explore the features that make ReloNavigator the go-to platform
              for Destination Services
            </p>
          </div>

          <div className="my-5">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="consultants-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#consultants-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="consultants-tab-pane"
                  aria-selected="false"
                >
                  Consultants
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="individuals-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#individuals-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="individuals-tab-pane"
                  aria-selected="false"
                >
                  Relocating Individuals
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="companies-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#companies-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="companies-tab-pane"
                  aria-selected="true"
                >
                  Companies
                </button>
              </li>
            </ul>
            <div className="tab-content pt-4" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="consultants-tab-pane"
                role="tabpanel"
                aria-labelledby="consultants-tab"
                tabIndex="0"
              >
                <div className="grid-box">
                  <div className="items">
                    <div className="tail" data-aos="fade-up">
                      <img src="assets/publicImages/grdi-tails-1.png" alt="" />

                      <h3 className="text-white">
                        <span> Property Recommendations</span>
                        Access Real-time Data at Your Fingertips
                      </h3>
                      <p className="text-white">
                        Offer personalized home recommendations based on
                        transferee preferences, ensuring the best fit for their
                        relocation needs.
                      </p>
                    </div>
                    <div
                      className="subTail mt-4 text-center"
                      data-aos="fade-up"
                    >
                      <img
                        src="assets/publicImages/scheduleappointment-icon.png"
                        alt=""
                      />
                      <h3 className="mt-4">SCHEDULE APPOINTMENT</h3>
                    </div>
                  </div>
                  <div className="items full">
                    <div
                      className="tail"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <img src="assets/publicImages/grdi-tails-2.png" alt="" />
                      <h4 className="text-white">
                        <span>Real-time data</span>
                        Access Real-time Data at Your Fingertips
                      </h4>
                      <p className="text-white">
                        Stay up-to-date with real-time data on transferee
                        progress, appointments, property recommendations, and
                        other key relocation tasks.
                      </p>
                    </div>
                  </div>
                  <div className="items">
                    <div
                      className="subTail mb-4 text-center"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      <img
                        src="assets/publicImages/messagecenter-icon.png"
                        alt=""
                      />
                      <h3 className="mt-4">Message center</h3>
                      <p>Chat with consultants and transferees</p>
                    </div>
                    <div
                      className="tail tailHeight"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      <img src="assets/publicImages/grdi-tails-3.png" alt="" />
                      <h3 className="text-white">
                        <span>Custom Reporting</span>
                        Generate Custom Reports for Better Insights
                      </h3>
                      <p className="text-white">
                        Create detailed, custom reports to track progress,
                        highlight key data points, and provide valuable insights
                        to both consultants and clients.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="individuals-tab-pane"
                role="tabpanel"
                aria-labelledby="individuals-tab"
                tabIndex="0"
              >
                <div className="grid-box">
                  <div className="items d-grid">
                    <div className="tail" data-aos="fade-up">
                      <img src="assets/publicImages/homefinding.png" alt="" />

                      <h3 className="text-black text-uppercase">
                      Home finding
                      <span className="text-capitalize mt-2">Find and Compare Homes with Key Local Insights</span> 
                      </h3>
                      {/* <p className="text-white">
                        Easily compare homes, explore detailed information about
                        each property, and discover points of interest nearby to
                        make confident decisions.
                      </p> */}
                    </div>
                    <div
                      className="subTail mt-4 text-center"
                      data-aos="fade-up"
                    >
                      <img
                        src="assets/publicImages/schoolfinder-icon.png"
                        alt=""
                      />
                      <h3 className="mt-4">School Finder</h3>
                      <p>Find the best schools that fit your needs</p>
                    </div>
                  </div>
                  <div className="items widthFull">
                    <div className="boxView">
                      <div className="box-tail">
                        <img
                          src="assets/publicImages/neighborhoodData.png"
                          alt=""
                        />
                        <h3 className="text-black text-uppercase">
                          Neighborhood Data
                          <span className="text-capitalize mb-0 mt-1 fs-7">Get detailed neighborhood insights</span>
                       </h3>
                      
                      </div>
                      <div className="box-tail full">
                        <img
                          src="assets/publicImages/managingpreferences.png"
                          alt=""
                        />
                        <h2 className="text-black text-uppercase">
                          Managing Preferences
                          <span className="text-capitalize mt-2">Customize settings to fit your needs</span>
                          
                        </h2>
                        {/* <p>
                          Easily manage your preferences to tailor your
                          relocation experience and streamline the process.
                        </p> */}
                      </div>
                      <div className="box-tail text-center justify-content-end p-3">
                        <img src="assets/publicImages/de&iData.png" alt="" />
                        <h3 className=" text-uppercase m-0">DE&I Data</h3>
                        <p>
                        Access diversity and equity information
                        </p>
                      </div>
                    </div>
                    <div className="boxView boxView-2">
                      <div className="box-tail full" data-aos="fade-up">
                        <img src="assets/publicImages/checklist.png" alt="" />
                        <h2 className="text-black text-uppercase">
                        checklists and reminders
                          <span className="text-capitalize">personalized checklists and reminders</span>
                        </h2>
                        {/* <p>
                          Ensure no detail is missed with customized task lists
                          and reminders that keep you on track from start to
                          finish.
                        </p> */}
                      </div>
                      <div className="box-tail text-center" data-aos="fade-up">
                        <img
                          src="assets/publicImages/movingresources.png"
                          alt=""
                          className="static"
                        />
                        <h3 className="text-black mt-3">Moving Resources</h3>
                        <p className="text-black m-0 fs-7">
                          Get essential resources for your move
                        </p>
                      </div>
                      <div className="box-tail text-center">
                        <img
                          src="assets/publicImages/areainformation.png"
                          alt=""
                        />
                        <h3>Area Information</h3>
                        <p className="fs-7">Access local area data and insights</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="companies-tab-pane"
                role="tabpanel"
                aria-labelledby="companies-tab"
                tabIndex="0"
              >
                <div className="final-box">
                  <div className="tail" data-aos="fade-up">
                    <img
                      src="assets/publicImages/reporting.png  "
                      className="report"
                      alt=""
                    />

                    <h3 className="text-white">
                      <span>reporting</span>Generate reports to track progress
                    </h3>
                    <p className="text-white">
                      Generate detailed reports in real-time, providing
                      essential insights and updates to track progress and
                      improve efficiency.
                    </p>
                  </div>

                  <div className="tail bg-white" data-aos="fade-up">
                    <h3>
                      <span>360 degree visibility</span>Achieve complete
                      transparency across all relocation activities
                    </h3>
                    <p>
                      Gain a holistic view of each transferee's journey,
                      ensuring that every step of the relocation process is
                      optimized and on track
                    </p>
                  </div>

                  <div className="tail bg-white" data-aos="fade-up">
                    <h3>
                      <span>Custom Reporting</span>Generate Custom Reports for
                      Better Insights
                    </h3>
                    <p>
                      Generate detailed reports in real-time, providing
                      essential insights and updates to track progress and
                      improve efficiency.
                    </p>
                  </div>

                  <div className="tail" data-aos="fade-up">
                    <img
                      src="assets/publicImages/manageconsultants.png  "
                      className="report"
                      alt=""
                    />

                    <h3 className="text-white">
                      <span>Manage Consultants</span>Track consultant
                      performance and activity
                    </h3>
                    <p className="text-white">
                      Effectively manage consultant activities and appointments
                      to ensure timely and efficient service delivery.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pb-5 pt-3" data-aos="fade-up">
        <div className="container">
          <div className="bg-black rounded-3 p-5 text-white mb-4">
            <div className="row">
              <div className="col-lg-7">
                <h2>Your Move, in Your Pocket</h2>
                <p className="mb-4">
                  With the ReloNavigator mobile app, managing your relocation is
                  easier than ever. Stay connected, track progress, and access
                  all the essential tools—anytime, anywhere.
                </p>
                <ul className="tickList">
                  <li>
                    <span>Real-time Updates </span> Stay informed with instant
                    notifications and updates on your services, appointments,
                    and task progress
                  </li>
                  <li>
                    <span>Appointment Scheduling</span> Book and manage
                    appointments on the go with ease
                  </li>
                  <li>
                    <span>Home Finding & Area Info</span> Access home
                    comparisons, neighborhood data, and points of interest while
                    you're on the move
                  </li>
                  <li>
                    <span>Checklists & Reminders</span> Stay organized with
                    personalized checklists and automated reminders at your
                    fingertips
                  </li>
                </ul>

                <div className="mt-2 mb-0">
                  <a
                    href="https://apps.apple.com/us/app/relonavigator-app/id6499023704?uo=2"
                    target="_blank"
                  >
                    <img
                      src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg"
                      alt=""
                      className="me-4 mb-2"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="d-flex h-100 align-content-center justify-content-center">
                  <img
                    src="assets/publicImages/relologo-with-mobilebg.png"
                    alt=""
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="text-center mt-5">
        <div className="container">
          <h2>Innovate Destinations</h2>
          <p>
            The trusted platform designed to elevate your destination services
            with innovation and simplicity.
          </p>

          <div className="accrodianView">
            <div className="row">
              <div className="col-lg-6" data-aos="fade-up">
                <div className="accordion" id="regularAccordionRobots">
                  <div className="accordion-item">
                    <h2 id="regularHeadingFirst" className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#regularCollapseFirst"
                        aria-expanded="true"
                        aria-controls="regularCollapseFirst"
                      >
                        Tailored for Professionals
                      </button>
                    </h2>
                    <div
                      id="regularCollapseFirst"
                      className="accordion-collapse collapse show"
                      aria-labelledby="regularHeadingFirst"
                      data-bs-parent="#regularAccordionRobots"
                    >
                      <div className="accordion-body">
                        <h5>Patented Technology</h5>
                        <p>
                          Our unique, patented platform delivers cutting-edge
                          tools that make managing destination services easier
                          and more efficient.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="regularHeadingSecond">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#regularCollapseSecond"
                        aria-expanded="false"
                        aria-controls="regularCollapseSecond"
                      >
                        Comprehensive Solutions
                      </button>
                    </h2>
                    <div
                      id="regularCollapseSecond"
                      className="accordion-collapse collapse"
                      aria-labelledby="regularHeadingSecond"
                      data-bs-parent="#regularAccordionRobots"
                    >
                      <div className="accordion-body">
                        Comprehensive Solutions Text
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="regularHeadingSecond2">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#regularCollapseSecond2"
                        aria-expanded="false"
                        aria-controls="regularCollapseSecond2"
                      >
                        Flexibility & Customization
                      </button>
                    </h2>
                    <div
                      id="regularCollapseSecond2"
                      className="accordion-collapse collapse"
                      aria-labelledby="regularHeadingSecond2"
                      data-bs-parent="#regularAccordionRobots"
                    >
                      <div className="accordion-body">
                        Flexibility & Customization text
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="regularHeadingSecond3">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#regularCollapseSecond3"
                        aria-expanded="false"
                        aria-controls="regularCollapseSecond2"
                      >
                        Data-Driven Insights
                      </button>
                    </h2>
                    <div
                      id="regularCollapseSecond3"
                      className="accordion-collapse collapse"
                      aria-labelledby="regularHeadingSecond3"
                      data-bs-parent="#regularAccordionRobots"
                    >
                      <div className="accordion-body">
                        Data-Driven Insights Text
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6" data-aos="fade-up">
                <img src="assets/publicImages/lappy.png" alt="" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <PublicFooter /> */}
    </div>
  );
}

export default NewPublicPage;
