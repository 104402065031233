import { Spin, Popover, Progress, Select } from "antd";
import Footer from "../../../common/footer/Footer";
// import "../cityLanding/cityLanding.css";
import { useState, useEffect } from "react";
import AppController from "../../../controllers/appController";
import GoogleApiWrapper from "../../../controllers/mapController1";
import Weather from "../../../common/weatherNewDesign";
import NewWeather from "../../../common/weather";
import PublicTransit from "../cityLanding/publicTransit";
import Mapview from "../../../common/mapview";
import moment from "moment";
import SchoolsSearch from "../cityLanding/schoolsSearch";
import SchoolsSearch2 from "../cityLanding/schoolsSearch2";
import PoiSearch from "../cityLanding/poinOfInterestsSearch";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import LoginStore from "../../../../services/thirdPartyService";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";
import ThirdPartyApiService from "../../../../services/thirdPartyService";
import WalkScorePopUp from "../cityLanding/modalPopUp/walkScorePopUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWalking,
  faBicycle,
  faTrain,
} from "@fortawesome/fontawesome-free-solid";
import ApiServices from "../../../../services/apiServices";
import SchoolSearchNew from "../cityLanding/schoolSearchNew";
import SchoolSearchMapBox from "../cityLanding/schoolSearchMapbox";

const MyPropertyDetails = (props) => {
  var _isMounted = false;
  let answ = "";
  const [state, setState] = useState({
    startPosition: 0,
    startPositiondrag: 0,
    tabItems: "",
    mainMenu: [
      { name: "Overview", id: "overview" },
      { name: "Points of Interest", id: "poi" },
      { name: "Commute", id: "transit" },
      { name: "Schools", id: "schools" },
      { name: "Explore the Area", id: "explore" },
    ],
    types: [
      {
        name: "Overview",
        type: "overview",
      },
      {
        name: "Points of Interest",
        type: "poi",
      },
      {
        name: "Commute",
        type: "transit",
      },
      {
        name: "Explore the Area",
        type: "explore",
      },
    ],
    myPropertyDetails: {},
    ViewStatus: "overview",
    data: {
      latitude: "",
      longitude: "",
      state_name: "CA",
      city_name: "San Francisco",
      city: "San Francisco",
      address: "185 Channel Street",
      greatSchools: [],
    },
    poi: [],
    poiCategories: [],
    answersPrefer: [],
    isSuccessPoi: false,
    GeneratePdf: "",
    walkScoreDetails: [],
    citiesLoader: true,
    travelMapLoading: false,
    lines: "",
    communityObject: {
      latitude: 37.7739852,
      longitude: -122.3912407,
    },
    ageLivingPelople: [],
    HomeValueForecast: [],
    crimeAssult: [],
    commuteReport: [],
    rentBedRooms: [],
    bedroomData: [],
    weather: "",
    weather_current: "",
    weather_currentCityDetails: "",
    bedroomLabels: [],
    singleFamilyData: [],
    singleFamilyLabels: [],
    allMultiHomesData: [],
    allMultiHomesLabels: [],
    multiHomeRentalsData: [],
    multiHomeRentalsLabels: [],
    homeValues: [
      "1bedroom",
      "2bedroom",
      "3bedroom",
      "4bedroom",
      "5BedroomOrMore",
    ],
    selectedHomeValue: "1bedroom",
    isChanged: false,
    isBedRoom: true,
    rentalLabels: [],
    rentalData: [],
    homeLoader: false,
    rentalValue: "AllHomesPlusMultifamily",
    allHomesValue: "",
    latitude: 37.7739852,
    longitude: -122.3912407,
    nearByNeighbourHoods: [],
    neighbourhoodLoader: false,
    nearByCities: [],
    nearByCitiesWithStates: [],
    greatSchools: [],
    transitDetails: [],
    soundScoreDetails: {
      result: [],
    },
    ispoi: false,
    loader: false,
    explore: false,
    isProperty: true,
    WalkscoreLoader: false,
    items: "",
    tabIItems: "",
    startPosition: "0",

    viewNeighborhoodGenerate: false,
    ProgressValue: 0,
    SchoolDisplay: false,
    transfereepropertyList: [],
    myPropertiesList: [],
    myPropertiesListInactive: [],
    saPropertiesList: [],
    selectedProperty: "",
  });
  const [displayCount, setDisplayCount] = useState(9);
  const navigate = useNavigate();
  const fetch1 = async () => {
    const script = document.createElement("script");
    script.src =
      "https://apicdn.walkscore.com/api/v1/traveltime_widget/js?wsid=8daf3ec7b708129023cb6ebcf8e99b56";
    script.async = true;
    document.body.appendChild(script);
    let myPropertyDetails = JSON.parse(
      localStorage.getItem("myPropertyDetails")
    );
    if (myPropertyDetails == null || myPropertyDetails == undefined) {
      window.location.href = "/my_property";
    } else {
      getSingleBedRoomData("1");
      getRentalsFamilyResult("AllHomesPlusMultifamily");
      let communityData = {
        latitude: Number(myPropertyDetails.latitude),
        longitude: Number(myPropertyDetails.longitude),
        state_name: myPropertyDetails.state,
        city_name: myPropertyDetails.city,
        city: myPropertyDetails.city,
      };
      let lines = await AppController.busrailslines(communityData);
      let strData = JSON.stringify(lines);
      const camelCaseData = camelCase(strData);
      let convertData = JSON.parse(camelCaseData);
      setState((prevState) => ({
        ...prevState,
        data: {
          latitude: Number(myPropertyDetails.latitude),
          longitude: Number(myPropertyDetails.longitude),
          state_name: myPropertyDetails.state,
          city_name: myPropertyDetails.city,
          city: myPropertyDetails.city,
          address: myPropertyDetails.address,
        },
        communityObject: {
          latitude: Number(myPropertyDetails.latitude),
          longitude: Number(myPropertyDetails.longitude),
        },
        lines: convertData,
        latitude: communityData.latitude,
        longitude: communityData.longitude,
        selectedProperty: myPropertyDetails.propertyname,
      }));
    }

    let neighbourhoodScountResult = await AppController.neighbourhoodScount(
      myPropertyDetails
    );

    if (neighbourhoodScountResult.success == true) {
      let neighbourhoodsDataResult =
        neighbourhoodScountResult.data.data_products;
      let ageLivingPelople = [];
      if (neighbourhoodsDataResult["neighborhood.demographics"] != undefined) {
        let DemographicsAge =
          neighbourhoodsDataResult["neighborhood.demographics"];

        ageLivingPelople.push(DemographicsAge["pctage_0_4"]);
        ageLivingPelople.push(DemographicsAge["pctage_5_17"]);
        ageLivingPelople.push(DemographicsAge["pctage_0_17"]);
        ageLivingPelople.push(DemographicsAge["pctage_18_29"]);
        ageLivingPelople.push(DemographicsAge["pctage_30_44"]);
        ageLivingPelople.push(DemographicsAge["pctage_45_64"]);
        ageLivingPelople.push(DemographicsAge["pctage_65_up"]);
      }
      let HomeValueForecast = [];
      let rentBedRooms = [];
      if (neighbourhoodsDataResult["neighborhood.real_estate"] != undefined) {
        let realEstate = neighbourhoodsDataResult["neighborhood.real_estate"];
        HomeValueForecast.push(realEstate["mhv_pred_1q"]);
        HomeValueForecast.push(realEstate["mhv_pred_2q"]);
        HomeValueForecast.push(realEstate["mhv_pred_3q"]);
        HomeValueForecast.push(realEstate["mhv_pred_4q"]);
        HomeValueForecast.push(realEstate["mhv_pred_5q"]);
        HomeValueForecast.push(realEstate["mhv_pred_6q"]);
        HomeValueForecast.push(realEstate["mhv_pred_7q"]);
        HomeValueForecast.push(realEstate["mhv_pred_8q"]);
        HomeValueForecast.push(realEstate["mhv_pred_9q"]);
        HomeValueForecast.push(realEstate["mhv_pred_10q"]);
        HomeValueForecast.push(realEstate["mhv_pred_11q"]);
        HomeValueForecast.push(realEstate["mhv_pred_1y"]);
        HomeValueForecast.push(realEstate["mhv_pred_2y"]);
        HomeValueForecast.push(realEstate["mhv_pred_3y"]);

        rentBedRooms.push(realEstate["rent_br0"]);
        rentBedRooms.push(realEstate["rent_br1"]);
        rentBedRooms.push(realEstate["rent_br2"]);
        rentBedRooms.push(realEstate["rent_br3"]);
      }
      let crimeReport = [];
      if (neighbourhoodsDataResult["city.crime"] != undefined) {
        let crimeAssult = neighbourhoodsDataResult["city.crime"];
        crimeReport.push(crimeAssult["pt_assault"]);
        crimeReport.push(crimeAssult["pt_murder"]);
        crimeReport.push(crimeAssult["pt_rape"]);
        crimeReport.push(crimeAssult["pt_robbery"]);
        crimeReport.push(crimeAssult["pt_violent"]);
      }
      let commuteReport = [];
      if (neighbourhoodsDataResult["neighborhood.econ_and_empl"] != undefined) {
        let commuteAnalasys =
          neighbourhoodsDataResult["neighborhood.econ_and_empl"];
        commuteReport.push(commuteAnalasys["commtime15_pct"]);
        commuteReport.push(commuteAnalasys["commtime30_pct"]);
        commuteReport.push(commuteAnalasys["commtime45_pct"]);
        commuteReport.push(commuteAnalasys["commtime60_pct"]);
        commuteReport.push(commuteAnalasys["commtime90_pct"]);
      }

      await setState((prevState) => ({
        ...prevState,
        ageLivingPelople: ageLivingPelople,
        HomeValueForecast: HomeValueForecast,
        crimeAssult: crimeReport,
        commuteReport: commuteReport,
        rentBedRooms: rentBedRooms,
      }));
    }
  };
  function camelCase(str) {
    const words = str.split(" ");
    let camelizedWords = words.map((word, index) => {
      let letters = word.trim().split("");
      letters = letters.map((letter, letterIndex) => {
        if (letterIndex == 0) {
          return letter.toUpperCase();
        }
        return letter.toLowerCase();
      });
      return letters.join("");
    });
    return camelizedWords.join(" ");
  }

  const fetch2 = async () => {
    // let items =
    //   parseInt(screen.availWidth) <= 767
    //     ? 1
    //     : parseInt(screen.availWidth) <= 991
    //     ? 1
    //     : 1;
    // setState((prevState) => ({ ...prevState, items: items }));
    // let tabItems =
    //   parseInt(screen.availWidth) <= 767
    //     ? 1
    //     : parseInt(screen.availWidth) <= 991
    //     ? 1
    //     : 1;
    //setState((prevState) => ({ ...prevState, tabItems: tabItems }));
    setState((prevState) => ({
      ...prevState,
      loader: true,
      WalkscoreLoader: true,
    }));
    localStorage.setItem("isProperty", state.isProperty);
    let myPropertyDetails = JSON.parse(
      localStorage.getItem("myPropertyDetails")
    );

    if (
      myPropertyDetails.pdfupdatedat != null &&
      myPropertyDetails.pdfupdatedat != "" &&
      myPropertyDetails.pdfupdatedat != undefined
    ) {
      let date1 = new Date();
      let date2 = new Date(
        moment(myPropertyDetails.pdfupdatedat).format("MM/DD/YYYY")
      );
      let diffTime = Math.abs(date2 - date1);
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays >= 28) {
        myPropertyDetails.pdffilepath = "";
      }
    }

    await setState((prevState) => ({
      ...prevState,
      myPropertyDetails: myPropertyDetails,
    }));
    if (myPropertyDetails != null || myPropertyDetails != undefined) {
      await setState((prevState) => ({
        ...prevState,
        data: {
          latitude: Number(myPropertyDetails.latitude),
          longitude: Number(myPropertyDetails.longitude),
          state_name: myPropertyDetails.state,
          city_name: myPropertyDetails.city,
          city: myPropertyDetails.city,
          address: myPropertyDetails.address,
        },
        communityObject: {
          latitude: Number(myPropertyDetails.latitude),
          longitude: Number(myPropertyDetails.longitude),
        },
        city: myPropertyDetails.city,
        latitude: Number(myPropertyDetails.latitude),
        longitude: Number(myPropertyDetails.longitude),
      }));

      let getSessionData = await LoginStore.getLoginInfo({});
      let userObject = {
        transfereeuniqeuid: getSessionData.userUniqueId,
        istemporary: getSessionData.istemporary,
      };
      let getQuestionList = await ApiServices.GetPreferencesList(userObject);
      let userPreferanceObject = {
        transfereeuniqeuid: getSessionData.userUniqueId,
        istemporary: getSessionData.istemporary,
      };

      let userSavedPreferances = await ApiServices.GetTransfereePreferences(
        userPreferanceObject
      );
      if (userSavedPreferances.error == 0) {
        let userAnswerPreferanceObject =
          userSavedPreferances.message.preferences;
        if (userAnswerPreferanceObject != undefined) {
          let answersPrefer = [];
          _.forEach(userAnswerPreferanceObject, function (answerObject) {
            answersPrefer[answerObject.preferencesmasteruniqueid] =
              answerObject;
          });
          setState((prevState) => ({
            ...prevState,
            answersPrefer: answersPrefer,
          }));
          answ = answersPrefer;
        }
      }
      if (getQuestionList.message.preferencesList != undefined) {
        let questionList = getQuestionList.message.preferencesList;

        let workAddressArray = _.filter(questionList, function (o) {
          return o.question == "Work address";
        });
        let Whoismoving = _.filter(questionList, function (o) {
          return o.question == "Who is moving?";
        });
        let commuteTimeArray = _.filter(questionList, function (o) {
          return o.question == "Commute time";
        });
        await localStorage.setItem(
          "workAddress",
          JSON.stringify(answ[workAddressArray[0].preferenceUId])
        );
        await localStorage.setItem(
          "commuteTime",
          JSON.stringify(answ[commuteTimeArray[0]?.preferenceUId])
        );
        let whoismovingObject = answ[Whoismoving[0].preferenceUId];
        if (whoismovingObject.preferencesanswer == "FAMILY") {
          await setState((prevState) => ({
            ...prevState,
            SchoolDisplay: true,
          }));
        }
      }

      let commuteTime = "undefined";
      if (
        localStorage.getItem("commuteTime") != "undefined" &&
        localStorage.getItem("commuteTime") != null &&
        localStorage.getItem("commuteTime") != ""
      ) {
        commuteTime = JSON.parse(localStorage.getItem("commuteTime"));
      }

      let commuteTimeInMin =
        commuteTime == "undefined"
          ? 15
          : commuteTime.preferencesanswer.split(",")[1];
      let commuteRadius = (commuteTimeInMin * 45) / 60;

      let reqObj = {
        latitude: myPropertyDetails.latitude,
        longitude: myPropertyDetails.longitude,
        radious: Math.round(commuteRadius),
        transfereeuid: getSessionData.userUniqueId,
      };
      let nearbyCity = await ApiServices.GetAllCities(reqObj);
      await setState((prev) => ({
        ...prev,
        nearByCities: nearbyCity.message.citieslist,
        citiesLoader: false,
      }));
      let nearByList = nearbyCity.message.citieslist;
      if (nearByList.length > 1) {
        let nearbycities = nearByList.map(function (item) {
          item.city = item.city;
          item.state = item.state_id;
          item.latitude = item.lat;
          item.longitude = item.lng;
          item.imageUrl = item.imageUrl;
          item.cityHighlights = item.cityHighlights;
          item.demoGraphics = item.demoGraphics;
          item.cityDetails = item.cityDetails;
          item.WalkScore = item.WalkScore;
          item.BikeScore = item.BikeScore;
          item.miles = Math.floor(item.distanc_in_mils);

          return item;
        });
        await setState((prevState) => ({
          ...prevState,
          nearByCitiesWithStates: _.sortBy(nearbycities, function (obj) {
            return parseFloat(obj.miles, 10);
          }),
          citiesLoader: false,
        }));
      }

      let response = mapView([
        {
          latitude: state.data.latitude,
          longitude: state.data.longitude,
        },
      ]);
      let updateData = {
        latitude: Number(myPropertyDetails.latitude),
        longitude: Number(myPropertyDetails.longitude),
        state_name: myPropertyDetails.state,
        city_name: myPropertyDetails.city,
        city: myPropertyDetails.city,
        address: myPropertyDetails.address,
      };
      const greatSchools = await AppController.greatSchools(updateData);
      await setState((prevState) => ({
        ...prevState,
        greatSchools: greatSchools,
      }));
      const walkScoreDetails_original = await AppController.wallsckoreFun(
        updateData
      );

      const walkScoreDetails =
        walkScoreDetails_original != undefined
          ? _.filter(walkScoreDetails_original, function (o) {
              return o.Score != null;
            })
          : [];
      const weather = await AppController.weather(updateData);
      const weather2 = await AppController.weather2(updateData);

      var results = weather.list.map(function (el) {
        var o = Object.assign({}, el);
        o.date = o.dt_txt.split(" ")[0];
        return o;
      });
      var data1 = _.chain(results)
        .groupBy("date")
        .map(function (currentItem) {
          let minTEMP = _.minBy(currentItem, function (o) {
            return o.main.temp_min;
          });
          let maxTEMP = _.maxBy(currentItem, function (o) {
            return o.main.temp_max;
          });
          return {
            weatherData: currentItem[0],
            weatherDataMax: maxTEMP.main.temp_max,
            weatherDataMin: minTEMP.main.temp_min,
          };
        })
        .value();
      setState((prevState) => ({
        ...prevState,
        weather: data1.splice(1, 4),
        weather_current: weather2,
        weather_currentCityDetails: weather.city,
      }));
      const poi = await AppController.poi(updateData);
      let Categories = [];
      poi.results.forEach((element) => {
        for (let i = 0; i < element.types.length; i++) {
          if (Categories.indexOf(element.types[i]) === -1) {
            Categories.push(element.types[i]);
          }
        }
      });
      await setState((prevState) => ({
        ...prevState,
        poiCategories: Categories,
      }));
      await setState((prevState) => ({
        ...prevState,
        walkScoreDetails,
        WalkscoreLoader: false,
        poi: poi.results,
        isSuccessPoi: poi.isSuccess,
      }));
      await AppController.logData(
        "Overview",
        "PropertyPage",
        location.pathname,
        getSessionData.userUniqueId,
        ""
      );
      const soundScoreDetails = await AppController.soundScoreDada(updateData);
      const transitDetails = await AppController.transitDada(updateData);
      await setState((prevState) => ({
        ...prevState,
        transitDetails,
        soundScoreDetails,
        loader: false,
      }));
    } else {
      location.href = "my_property";
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      await fetch1();
      await fetch2();
    };
    fetchData();
  }, [state.selectedProperty]);

  useEffect(() => {
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
    window.scrollTo({ top: 0, behavior: "smooth" });
    tab();
    getMyProperties();
  }, []);
  // const changeStatus = async (typeMenu) => {
  //   setState((prev) => ({ ...prev, ViewStatus: typeMenu }));
  //   let getSessionData = await LoginStore.getLoginInfo({});

  //   if (typeMenu == "poi") {
  //     console.log("typeMenu:Points of Interests", typeMenu);

  //     setState((prev) => ({
  //       ...prev,
  //       ViewStatus: typeMenu,
  //       schoolApi: false,
  //       ispoi: true,
  //       explore: false,
  //     }));
  //     await AppController.logData(
  //       "Points of Interest",
  //       "PropertyPage",
  //       location.pathname,
  //       getSessionData.userUniqueId,
  //       ""
  //     );
  //   }
  //   if (typeMenu == "schools") {
  //     setState({
  //       ...state,
  //       ViewStatus: typeMenu,
  //       schoolApi: true,
  //       ispoi: false,
  //       explore: false,
  //     });
  //     await AppController.logData(
  //       "Schools",
  //       "PropertyPage",
  //       location.pathname,
  //       getSessionData.userUniqueId,
  //       ""
  //     );
  //   }
  //   if (typeMenu == "explore") {
  //     setState({
  //       ...state,
  //       ViewStatus: typeMenu,
  //       schoolApi: false,
  //       ispoi: false,
  //       explore: true,
  //     });
  //     await AppController.logData(
  //       "Explore the Area",
  //       "PropertyPage",
  //       location.pathname,
  //       getSessionData.userUniqueId,
  //       ""
  //     );
  //   }
  //   if (typeMenu == "transit") {
  //     setState({
  //       ...state,
  //       ViewStatus: typeMenu,
  //       schoolApi: false,
  //       ispoi: false,
  //       travelMapLoading: true,
  //       explore: false,
  //     });
  //     await AppController.logData(
  //       "Commute",
  //       "PropertyPage",
  //       location.pathname,
  //       getSessionData.userUniqueId,
  //       ""
  //     );
  //   }
  //   if (typeMenu == "overview") {
  //     setState({ ...state, ViewStatus: typeMenu });
  //     await AppController.logData(
  //       "Overview",
  //       "PropertyPage",
  //       location.pathname,
  //       getSessionData.userUniqueId,
  //       ""
  //     );
  //   }
  //   if (typeMenu == "Neighborhood") {
  //     setState({
  //       ...state,
  //       ViewStatus: typeMenu,
  //       ispoi: false,
  //       travelMapLoading: false,
  //       explore: false,
  //     });
  //     await AppController.logData(
  //       "Neighborhood",
  //       "PropertyPage",
  //       location.pathname,
  //       getSessionData.userUniqueId,
  //       ""
  //     );
  //   }
  // };

  const changeStatus = async (typeMenu) => {
    setState((prev) => ({ ...prev, ViewStatus: typeMenu }));

    const getSessionData = await LoginStore.getLoginInfo({});
    setState((prev) => ({
      ...prev,
      ViewStatus: typeMenu,
      schoolApi: typeMenu == "schools",
      ispoi: typeMenu == "poi",
      explore: typeMenu == "explore",
      travelMapLoading: typeMenu == "transit",
    }));

    await AppController.logData(
      typeMenu == "poi"
        ? "Points of Interest"
        : typeMenu == "schools"
        ? "Schools"
        : typeMenu == "explore"
        ? "Explore the Area"
        : typeMenu == "transit"
        ? "Commute"
        : "Overview",
      "CityPage",
      location.pathname,
      getSessionData.userUniqueId,
      ""
    );
  };
  const mapView = (data) => {
    // GoogleApiWrapper.initExploreMap(data, true);
  };
  const getSingleBedRoomData = async (value) => {
    setState((prevState) => ({
      ...prevState,
      bedroomData: [],
      bedroomLabels: [],
      homeLoader: true,
    }));

    let myPropertyDetails = JSON.parse(
      localStorage.getItem("myPropertyDetails")
    );

    let req = {
      zipcode: "",
      cityname:
        myPropertyDetails.city.charAt(0).toUpperCase() +
        myPropertyDetails.city.slice(1),
      roomtype: value,
    };
    let bedroomResult = await ApiServices.ListAllSingleBedroomByZip(req);
    let cityData = JSON.parse(bedroomResult.message.list);
    if (cityData.length > 0) {
      let cityObj = cityData[0];
      // if (cityObj.length > 0) {
      delete cityObj.RegionID;
      delete cityObj.RegionName;
      delete cityObj.State;
      delete cityObj.Metro;
      delete cityObj.CountyName;
      delete cityObj.SizeRank;
      delete cityObj.City;
      let obj = cityObj;
      let keys = [2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020];
      let yearsRage = _.range(2011, 2021, 1);
      let graphValues = [];
      _.forEach(yearsRage, function (yearValue) {
        if (yearValue == 2020) {
          graphValues.push(
            Math.round(
              ((isNaN(parseInt(obj[yearValue + "-01"]))
                ? 0
                : parseInt(obj[yearValue + "-01"])) +
                (isNaN(parseInt(obj[yearValue + "-02"]))
                  ? 0
                  : parseInt(obj[yearValue + "-02"]))) /
                2
            )
          );
        } else {
          graphValues.push(
            Math.round(
              (isNaN(parseInt(obj[yearValue + "-01"]))
                ? 0
                : parseInt(obj[yearValue + "-01"]) +
                  (isNaN(parseInt(obj[yearValue + "-02"]))
                    ? 0
                    : parseInt(obj[yearValue + "-02"])) +
                  (isNaN(parseInt(obj[yearValue + "-03"]))
                    ? 0
                    : parseInt(obj[yearValue + "-03"])) +
                  (isNaN(parseInt(obj[yearValue + "-04"]))
                    ? 0
                    : parseInt(obj[yearValue + "-04"])) +
                  (isNaN(parseInt(obj[yearValue + "-05"]))
                    ? 0
                    : parseInt(obj[yearValue + "-05"])) +
                  (isNaN(parseInt(obj[yearValue + "-06"]))
                    ? 0
                    : parseInt(obj[yearValue + "-06"])) +
                  (isNaN(parseInt(obj[yearValue + "-07"]))
                    ? 0
                    : parseInt(obj[yearValue + "-07"])) +
                  (isNaN(parseInt(obj[yearValue + "-08"]))
                    ? 0
                    : parseInt(obj[yearValue + "-08"])) +
                  (isNaN(parseInt(obj[yearValue + "-09"]))
                    ? 0
                    : parseInt(obj[yearValue + "-09"])) +
                  (isNaN(parseInt(obj[yearValue + "-10"]))
                    ? 0
                    : parseInt(obj[yearValue + "-10"])) +
                  (isNaN(parseInt(obj[yearValue + "-11"]))
                    ? 0
                    : parseInt(obj[yearValue + "-11"])) +
                  (isNaN(parseInt(obj[yearValue + "-12"]))
                    ? 0
                    : parseInt(obj[yearValue + "-12"]))) / 12
            )
          );
        }
      });
      await setState((prevState) => ({
        ...prevState,
        bedroomData: graphValues,
        bedroomLabels: keys,
        homeLoader: false,
      }));
    }
  };
  const getRentalsFamilyResult = async (type) => {
    setState((prevState) => ({
      ...prevState,
      rentalData: [],
      rentalLabels: [],
      rentalValue: type,
    }));

    let myPropertyDetails = JSON.parse(
      localStorage.getItem("myPropertyDetails")
    );

    let req = {
      zipcode: "",
      cityname:
        myPropertyDetails.city.charAt(0).toUpperCase() +
        myPropertyDetails.city.slice(1),
      listtype: type,
    };
    let SingleFamilyResult = await ApiServices.ListAllSingleFamilyByZip(req);
    let cityData = JSON.parse(SingleFamilyResult.message.list);
    if (cityData.length > 0) {
      let cityObj = cityData[0];
      delete cityObj.RegionID;
      delete cityObj.RegionName;
      delete cityObj.State;
      delete cityObj.Metro;
      delete cityObj.CountyName;
      delete cityObj.SizeRank;
      delete cityObj.City;
      let obj = cityObj;
      let keys = [2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020];
      let yearsRage = _.range(2011, 2021, 1);
      let graphValues = [];
      _.forEach(yearsRage, function (yearValue) {
        if (yearValue == 2020) {
          let duplica = [];
          duplica.push(
            Math.round(
              ((isNaN(parseInt(obj[yearValue + "-01"]))
                ? 0
                : parseInt(obj[yearValue + "-01"])) +
                (isNaN(parseInt(obj[yearValue + "-02"]))
                  ? 0
                  : parseInt(obj[yearValue + "-02"]))) /
                2
            )
          );
          if (
            obj[yearValue + "-02"] != undefined &&
            obj[yearValue + "-02"] != undefined
          ) {
            graphValues.push(
              Math.round(
                ((isNaN(parseInt(obj[yearValue + "-01"]))
                  ? 0
                  : parseInt(obj[yearValue + "-01"])) +
                  (isNaN(parseInt(obj[yearValue + "-02"]))
                    ? 0
                    : parseInt(obj[yearValue + "-02"]))) /
                  2
              )
            );
          } else {
            graphValues.push(
              (isNaN(parseInt(obj[yearValue + "-01"]))
                ? 0
                : parseInt(obj[yearValue + "-01"])) +
                (isNaN(parseInt(obj[yearValue + "-02"]))
                  ? 0
                  : parseInt(obj[yearValue + "-02"]))
            );
          }
          if (duplica.includes(0)) {
            keys = _.filter(keys, function (o) {
              return o != yearValue;
            });
          }
        } else {
          let duplicat = [];
          duplicat.push(
            Math.round(
              (isNaN(parseInt(obj[yearValue + "-01"]))
                ? 0
                : parseInt(obj[yearValue + "-01"]) +
                  (isNaN(parseInt(obj[yearValue + "-02"]))
                    ? 0
                    : parseInt(obj[yearValue + "-02"])) +
                  (isNaN(parseInt(obj[yearValue + "-03"]))
                    ? 0
                    : parseInt(obj[yearValue + "-03"])) +
                  (isNaN(parseInt(obj[yearValue + "-04"]))
                    ? 0
                    : parseInt(obj[yearValue + "-04"])) +
                  (isNaN(parseInt(obj[yearValue + "-05"]))
                    ? 0
                    : parseInt(obj[yearValue + "-05"])) +
                  (isNaN(parseInt(obj[yearValue + "-06"]))
                    ? 0
                    : parseInt(obj[yearValue + "-06"])) +
                  (isNaN(parseInt(obj[yearValue + "-07"]))
                    ? 0
                    : parseInt(obj[yearValue + "-07"])) +
                  (isNaN(parseInt(obj[yearValue + "-08"]))
                    ? 0
                    : parseInt(obj[yearValue + "-08"])) +
                  (isNaN(parseInt(obj[yearValue + "-09"]))
                    ? 0
                    : parseInt(obj[yearValue + "-09"])) +
                  (isNaN(parseInt(obj[yearValue + "-10"]))
                    ? 0
                    : parseInt(obj[yearValue + "-10"])) +
                  (isNaN(parseInt(obj[yearValue + "-11"]))
                    ? 0
                    : parseInt(obj[yearValue + "-11"])) +
                  (isNaN(parseInt(obj[yearValue + "-12"]))
                    ? 0
                    : parseInt(obj[yearValue + "-12"]))) / 12
            )
          );

          graphValues.push(
            Math.round(
              (isNaN(parseInt(obj[yearValue + "-01"]))
                ? 0
                : parseInt(obj[yearValue + "-01"]) +
                  (isNaN(parseInt(obj[yearValue + "-02"]))
                    ? 0
                    : parseInt(obj[yearValue + "-02"])) +
                  (isNaN(parseInt(obj[yearValue + "-03"]))
                    ? 0
                    : parseInt(obj[yearValue + "-03"])) +
                  (isNaN(parseInt(obj[yearValue + "-04"]))
                    ? 0
                    : parseInt(obj[yearValue + "-04"])) +
                  (isNaN(parseInt(obj[yearValue + "-05"]))
                    ? 0
                    : parseInt(obj[yearValue + "-05"])) +
                  (isNaN(parseInt(obj[yearValue + "-06"]))
                    ? 0
                    : parseInt(obj[yearValue + "-06"])) +
                  (isNaN(parseInt(obj[yearValue + "-07"]))
                    ? 0
                    : parseInt(obj[yearValue + "-07"])) +
                  (isNaN(parseInt(obj[yearValue + "-08"]))
                    ? 0
                    : parseInt(obj[yearValue + "-08"])) +
                  (isNaN(parseInt(obj[yearValue + "-09"]))
                    ? 0
                    : parseInt(obj[yearValue + "-09"])) +
                  (isNaN(parseInt(obj[yearValue + "-10"]))
                    ? 0
                    : parseInt(obj[yearValue + "-10"])) +
                  (isNaN(parseInt(obj[yearValue + "-11"]))
                    ? 0
                    : parseInt(obj[yearValue + "-11"])) +
                  (isNaN(parseInt(obj[yearValue + "-12"]))
                    ? 0
                    : parseInt(obj[yearValue + "-12"]))) / 12
            )
          );
          if (duplicat.includes(0)) {
            keys = _.filter(keys, function (o) {
              return o != yearValue;
            });
          }
        }
      });
      let values = _.filter(graphValues, function (o) {
        return o != 0;
      });

      await setState((prevState) => ({
        ...prevState,
        rentalData: values,
        rentalLabels: keys,
      }));
    }
  };
  const getSingleFamilyResult = async (type) => {
    setState((prevState) => ({
      ...prevState,
      singleFamilyData: [],
      singleFamilyLabels: [],
      isBedRoom: false,
      homeLoader: true,
      allHomesValue: type,
    }));
    let myPropertyDetails = JSON.parse(
      localStorage.getItem("myPropertyDetails")
    );

    let req = {
      zipcode: "",
      cityname:
        myPropertyDetails.city.charAt(0).toUpperCase() +
        myPropertyDetails.city.slice(1),
      listtype: type,
    };

    let SingleFamilyResult = await ApiServices.ListAllSingleFamilyByZip(req);

    let cityData = JSON.parse(SingleFamilyResult.message.list);
    if (cityData.length > 0) {
      let cityObj = cityData[0];
      // if (cityObj.length > 0) {
      delete cityObj.RegionID;
      delete cityObj.RegionName;
      delete cityObj.State;
      delete cityObj.Metro;
      delete cityObj.CountyName;
      delete cityObj.SizeRank;
      delete cityObj.City;
      let obj = cityObj;
      let keys = [
        2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011,
        2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020,
      ];
      let yearsRage = _.range(2000, 2021, 1);
      let graphValues = [];
      _.forEach(yearsRage, function (yearValue) {
        if (yearValue == 2020) {
          let duplica = [];
          duplica.push(
            Math.round(
              ((isNaN(parseInt(obj[yearValue + "-01"]))
                ? 0
                : parseInt(obj[yearValue + "-01"])) +
                (isNaN(parseInt(obj[yearValue + "-02"]))
                  ? 0
                  : parseInt(obj[yearValue + "-02"]))) /
                2
            )
          );
          if (
            obj[yearValue + "-02"] != undefined &&
            obj[yearValue + "-02"] != undefined
          ) {
            graphValues.push(
              Math.round(
                ((isNaN(parseInt(obj[yearValue + "-01"]))
                  ? 0
                  : parseInt(obj[yearValue + "-01"])) +
                  (isNaN(parseInt(obj[yearValue + "-02"]))
                    ? 0
                    : parseInt(obj[yearValue + "-02"]))) /
                  2
              )
            );
          } else {
            graphValues.push(
              (isNaN(parseInt(obj[yearValue + "-01"]))
                ? 0
                : parseInt(obj[yearValue + "-01"])) +
                (isNaN(parseInt(obj[yearValue + "-02"]))
                  ? 0
                  : parseInt(obj[yearValue + "-02"]))
            );
          }
          if (duplica.includes(0)) {
            keys = _.filter(keys, function (o) {
              return o != yearValue;
            });
          }
        } else {
          let duplicat = [];
          duplicat.push(
            Math.round(
              (isNaN(parseInt(obj[yearValue + "-01"]))
                ? 0
                : parseInt(obj[yearValue + "-01"]) +
                  (isNaN(parseInt(obj[yearValue + "-02"]))
                    ? 0
                    : parseInt(obj[yearValue + "-02"])) +
                  (isNaN(parseInt(obj[yearValue + "-03"]))
                    ? 0
                    : parseInt(obj[yearValue + "-03"])) +
                  (isNaN(parseInt(obj[yearValue + "-04"]))
                    ? 0
                    : parseInt(obj[yearValue + "-04"])) +
                  (isNaN(parseInt(obj[yearValue + "-05"]))
                    ? 0
                    : parseInt(obj[yearValue + "-05"])) +
                  (isNaN(parseInt(obj[yearValue + "-06"]))
                    ? 0
                    : parseInt(obj[yearValue + "-06"])) +
                  (isNaN(parseInt(obj[yearValue + "-07"]))
                    ? 0
                    : parseInt(obj[yearValue + "-07"])) +
                  (isNaN(parseInt(obj[yearValue + "-08"]))
                    ? 0
                    : parseInt(obj[yearValue + "-08"])) +
                  (isNaN(parseInt(obj[yearValue + "-09"]))
                    ? 0
                    : parseInt(obj[yearValue + "-09"])) +
                  (isNaN(parseInt(obj[yearValue + "-10"]))
                    ? 0
                    : parseInt(obj[yearValue + "-10"])) +
                  (isNaN(parseInt(obj[yearValue + "-11"]))
                    ? 0
                    : parseInt(obj[yearValue + "-11"])) +
                  (isNaN(parseInt(obj[yearValue + "-12"]))
                    ? 0
                    : parseInt(obj[yearValue + "-12"]))) / 12
            )
          );

          graphValues.push(
            Math.round(
              (isNaN(parseInt(obj[yearValue + "-01"]))
                ? 0
                : parseInt(obj[yearValue + "-01"]) +
                  (isNaN(parseInt(obj[yearValue + "-02"]))
                    ? 0
                    : parseInt(obj[yearValue + "-02"])) +
                  (isNaN(parseInt(obj[yearValue + "-03"]))
                    ? 0
                    : parseInt(obj[yearValue + "-03"])) +
                  (isNaN(parseInt(obj[yearValue + "-04"]))
                    ? 0
                    : parseInt(obj[yearValue + "-04"])) +
                  (isNaN(parseInt(obj[yearValue + "-05"]))
                    ? 0
                    : parseInt(obj[yearValue + "-05"])) +
                  (isNaN(parseInt(obj[yearValue + "-06"]))
                    ? 0
                    : parseInt(obj[yearValue + "-06"])) +
                  (isNaN(parseInt(obj[yearValue + "-07"]))
                    ? 0
                    : parseInt(obj[yearValue + "-07"])) +
                  (isNaN(parseInt(obj[yearValue + "-08"]))
                    ? 0
                    : parseInt(obj[yearValue + "-08"])) +
                  (isNaN(parseInt(obj[yearValue + "-09"]))
                    ? 0
                    : parseInt(obj[yearValue + "-09"])) +
                  (isNaN(parseInt(obj[yearValue + "-10"]))
                    ? 0
                    : parseInt(obj[yearValue + "-10"])) +
                  (isNaN(parseInt(obj[yearValue + "-11"]))
                    ? 0
                    : parseInt(obj[yearValue + "-11"])) +
                  (isNaN(parseInt(obj[yearValue + "-12"]))
                    ? 0
                    : parseInt(obj[yearValue + "-12"]))) / 12
            )
          );
          if (duplicat.includes(0)) {
            keys = _.filter(keys, function (o) {
              return o != yearValue;
            });
          }
        }
      });
      let values = _.filter(graphValues, function (o) {
        return o != 0;
      });
      await setState((prevState) => ({
        ...prevState,
        singleFamilyData: values,
        singleFamilyLabels: keys,
        homeLoader: false,
      }));
    }
  };
  const onChangeHomeValueEvent = async (e) => {
    e.persist();
    let value = "";
    if (e.target.value == "1bedroom") {
      value = "1";
    } else if (e.target.value == "2bedroom") {
      value = "2";
    } else if (e.target.value == "3bedroom") {
      value = "3";
    } else if (e.target.value == "4bedroom") {
      value = "4";
    } else {
      value = "5";
    }
    await setState((prevState) => ({
      ...prevState,
      selectedHomeValue: e.target.value,
      isBedRoom: true,
      allHomesValue: "",
    }));
    getSingleBedRoomData(value);
  };
  const reset = async () => {
    let response = mapView([
      {
        latitude: 37.7739852,
        longitude: -122.3912407,
        communityObject: {
          latitude: 37.7739852,
          longitude: -122.3912407,
        },
      },
    ]);
    let cityDetails = JSON.parse(localStorage.getItem("cDetails"));
    if (cityDetails != null || cityDetails != undefined) {
      let response = mapView([
        {
          latitude: Number(cityDetails.latitude),
          longitude: Number(cityDetails.longitude),
          communityObject: {
            latitude: Number(cityDetails.latitude),
            longitude: Number(cityDetails.longitude),
          },
        },
      ]);
    }
    $("#txtPlaces").val("");
  };
  const destinationLocation = () => {
    var address = "";
    var places = new google.maps.places.Autocomplete(
      document.getElementById("txtPlaces")
    );
    google.maps.event.addListener(places, "place_changed", function () {
      var place = places.getPlace();
      address = place.formatted_address;
      setState((prevState) => ({ ...prevState, destinationAdd: address }));
    });
  };
  const handleFormSubmit = async () => {
    if (document.getElementById("txtPlaces").value.trim() != "") {
      let s3BucketUrl_WebsiteImages =
        "https://s3-us-west-1.amazonaws.com/suiteam-website/Website-Images/";
      const _this = this;
      var locations = {
        lat: Number(state.communityObject.latitude),
        lng: Number(state.communityObject.longitude),
      };
      const customMap = new google.maps.Map(
        document.getElementById("mapExplore"),
        {
          zoom: 7,
          center: locations,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
        }
      );
      await setState((prevState) => ({ ...prevState, customMap }));
      await GoogleApiWrapper.customMarkers(
        state.communityObject,
        "singlemark",
        this,
        "",
        s3BucketUrl_WebsiteImages + "SuiteAmerica-Map-Marker-Red.png",
        customMap
      );

      const community_lat = state.communityObject.latitude;
      const community_long = state.communityObject.longitude;
      var request = {
        origin: { lat: Number(community_lat), lng: Number(community_long) },
        destination: state.destinationAdd,
        travelMode: google.maps.DirectionsTravelMode.DRIVING,
      };

      state.directionsService.route(request, function (response, status) {
        if (status == google.maps.DirectionsStatus.OK) {
          state.directionsDisplay.setDirections(response);
          var distance = response.routes[0].legs[0].distance.text;
          var duration = response.routes[0].legs[0].duration.text;
          function makeMarker(position, icon, title, map) {
            new google.maps.Marker({
              position: position,
              map: map,
              icon: icon,
              title: title,
            });
          }
          var leg = response.routes[0].legs[0];
          var locations = {
            lat: Number(community_lat),
            lng: Number(community_long),
          };

          makeMarker(
            leg.start_location,
            s3BucketUrl_WebsiteImages + "SuiteAmerica-Map-Marker-Red.png",
            "title",
            state.customMap
          );
          makeMarker(
            leg.end_location,
            s3BucketUrl_WebsiteImages + "SuiteAmerica-Map-Marker-White.png",
            "title",
            state.customMap
          );
        }
      });
    }
  };
  const NeighborhoodScoutPdfError = async (errorResult) => {
    confirmAlert({
      title: "",
      message: errorResult,
      buttons: [
        {
          label: "Ok",
        },
      ],
    });
  };
  const GeneratePdfLoader = async () => {
    const intervalId = setInterval(() => {
      if (state.ProgressValue < 100) {
        setState((prevState) => ({
          ...prevState,
          ProgressValue: prevState.ProgressValue + 3,
        }));
      } else {
        // When ProgressValue reaches 100, clear the interval
        clearInterval(intervalId);
      }
    }, 1000);
  };
  const GeneratePdf = async () => {
    await setState((prevState) => ({
      ...prevState,
      viewNeighborhoodGenerate: true,
    }));
    await GeneratePdfLoader();

    let datato = {
      address: state.myPropertyDetails.address,
    };
    let GeneratePdfto = await ThirdPartyApiService.GeneratePdf(datato);
    await setState((prevState) => ({
      ...prevState,
      GeneratePdf: GeneratePdfto,
    }));
    if (
      state.GeneratePdf != "" &&
      state.GeneratePdf.data != undefined &&
      state.GeneratePdf.errors == undefined
    ) {
      if (state.GeneratePdf != "") {
        if (state.GeneratePdf.data != undefined) {
          if (state.GeneratePdf.data.data_products != undefined) {
            if (
              state.GeneratePdf.data.data_products["location.pdf_report"] !=
              undefined
            ) {
              if (
                state.GeneratePdf.data.data_products["location.pdf_report"]
                  .pdf_address_url != undefined &&
                state.GeneratePdf.data.data_products["location.pdf_report"]
                  .pdf_address_url != ""
              ) {
                let GenerateObject = {
                  propertyuniqueId: state.myPropertyDetails.propertyuniqueId,
                  pdffilepath:
                    state.GeneratePdf.data.data_products["location.pdf_report"]
                      .pdf_address_url,
                  pdfupdatedat: moment(new Date()).format("YYYY/MM/DD"),
                };
                //await GeneratePdfLoader();
                // await setState((prevState)=>({...prevState, viewNeighborhoodGenerate: true });
                let myProbObject = Object.assign({}, state.myPropertyDetails);

                myProbObject.pdffilepath =
                  state.GeneratePdf.data.data_products[
                    "location.pdf_report"
                  ].pdf_address_url;
                myProbObject.pdfupdatedat = moment(new Date()).format(
                  "YYYY/MM/DD"
                );
                setTimeout(async () => {
                  await setState((prevState) => ({
                    ...prevState,
                    myPropertyDetails: myProbObject,
                    viewNeighborhoodGenerate: false,
                  }));
                }, 20000);

                localStorage.setItem(
                  "myPropertyDetails",
                  JSON.stringify(myProbObject)
                );
                let pdfGenerate = await ApiServices.NeighborhoodScoutPdf(
                  GenerateObject
                );
              }
            }
          }
        }
      }
    } else {
      await setState((prevState) => ({
        ...prevState,
        viewNeighborhoodGenerate: false,
        ProgressValue: 1,
      }));
      await NeighborhoodScoutPdfError(
        "Something went wrong, please try again later"
      );
    }
  };
  const cityDetails = async (row) => {
    localStorage.setItem("cDetails", JSON.stringify(row));
    //location.href = '/city-landing';
    window.open("/city-landing", "myWindow");
  };
  const handleShowMore = () => {
    setDisplayCount(displayCount + 10); // Increase the display count by 10 when "More" button is clicked
  };
  const walkScoreModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#walkscoreInfoMadal").modal("show");
      });
    });
    setState((prev) => ({ ...prev, scoreModal: true }));
  };

  const handlePropertyChange = (e) => {
    console.log(e.target.value, "e.target.value property");
    let propertyname = e.target.value;
    let index = state.transfereepropertyList.findIndex(function (property) {
      return property.propertyname === propertyname;
    });
    localStorage.setItem(
      "myPropertyDetails",
      JSON.stringify(state.transfereepropertyList[index])
    );

    setState((prevState) => ({
      ...prevState,
      selectedProperty: propertyname,
      citiesLoader: true,
    }));
    // window.location.href = window.location.href;
  };

  const getMyProperties = async (PrioritiesStatus = null) => {
    let getSessionData = await LoginStore.getLoginInfo({});

    let GetTransfereePropertiesObject = {
      transfereeuniqueId: getSessionData.userUniqueId,
    };
    let GetTransfereeProperties = await ApiServices.GetTransfereeProperties(
      GetTransfereePropertiesObject
    );
    if (GetTransfereeProperties.error == 0) {
      let myPropertiesList = _.filter(
        GetTransfereeProperties.message.transfereepropertyList,
        function (element) {
          return (
            element.source == "my_property" &&
            element.isinterested == true &&
            element.apiId != "" &&
            element.isactive == true
          );
        }
      );
      // let myPropertiesListInactive = _.filter(
      //   GetTransfereeProperties.message.transfereepropertyList,
      //   function (element) {
      //     return (
      //       element.source == "my_property" &&
      //       element.isinterested == false &&
      //       element.apiId != ""
      //     );
      //   }
      // );
      let saPropertiesList = _.filter(
        GetTransfereeProperties.message.transfereepropertyList,
        function (element) {
          if (element.propertyjson != null && element.propertyjson != "") {
            element.property_image =
              JSON.parse(element.propertyjson).Photo != null
                ? "https://s3-us-west-1.amazonaws.com/destination-services-itinerary/" +
                  JSON.parse(element.propertyjson).Photo +
                  ".jpg"
                : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png";
          }
          return (
            element.source == "SA_Properties" &&
            element.isinterested == true &&
            element.isactive == true &&
            element.apiId != ""
          );
        }
      );
      setState((prevState) => {
        return {
          ...prevState,
          myPropertiesList: myPropertiesList,
          // myPropertiesListInactive: myPropertiesListInactive,
          saPropertiesList: saPropertiesList,
          transfereepropertyList:
            GetTransfereeProperties.message.transfereepropertyList,
        };
      });
    }
  };

  const goBack = () => {
    navigate("/myProperty"); // Go back to the previous route
  };
  const updateCarouselPosition = (object) => {
    if (object.item.index != state.startPosition) {
      setState((prevState) => ({
        ...prevState,
        startPosition: object.item.index,
      }));
    }
  };
  const tab = () => {
    let tabItems;
    const screenWidth = window.innerWidth;
    if (screenWidth <= 991) {
      tabItems = 2;
    } else {
      tabItems = 2;
    }
    setState((prevState) => ({
      ...prevState,
      tabItems: tabItems,
    }));
  };
  // const tab = () => {
  //   let tabItems =
  //     parseInt(screen.availWidth) <= 767
  //       ? 2
  //       : parseInt(screen.availWidth) <= 991
  //       ? 2
  //       : 3;
  //   setState((prevState) => ({
  //     ...prevState,
  //     tabItems: tabItems,
  //   }));
  // };
  const updateCarouselPositiondrag = (object) => {
    // console.log(object.item.index, "object.item.index");
    if (object.item.index != state.startPositiondrag) {
      // console.log(object.item.index, "object.item.index");
      setState((prevState) => ({
        ...prevState,
        startPositiondrag: object.item.index,
      }));
    }
  };
  $(document).ready(function () {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 575) {
      $(".client-testimonial-carousel").owlCarousel({
        items: 2,
        //      autoplay:false,
        margin: 30,
        loop: false,
        dots: false,
        //      nav:true,
        //      navText:["<i class='fas fa-long-arrow-alt-left'></i>","<i class='fas fa-long-arrow-alt-right'></i>" ]
      });
    } else if (screenWidth <= 767) {
      $(".client-testimonial-carousel").owlCarousel({
        items: 3,
        //      autoplay:false,
        margin: 30,
        loop: false,
        dots: false,
        //      nav:true,
        //      navText:["<i class='fas fa-long-arrow-alt-left'></i>","<i class='fas fa-long-arrow-alt-right'></i>" ]
      });
    } else {
      $(".client-testimonial-carousel").owlCarousel({
        items: 3,
        //      autoplay:false,
        margin: 30,
        loop: false,
        dots: false,
        //      nav:true,
        //      navText:["<i class='fas fa-long-arrow-alt-left'></i>","<i class='fas fa-long-arrow-alt-right'></i>" ]
      });
    }
  });

  return (
    <div>
      {state.citiesLoader ? <div className="loading"></div> : null}
      <div className="city-landing-page newOne propertyDetails">
        <div className="">
          {state.ViewStatus == "overview" && (
            <header>
              <h1>
                {state.myPropertyDetails.propertyname} -{" "}
                {state.myPropertyDetails.propertyrankitems != undefined ? (
                  <span className="DetailsPercentage">
                    <b>
                      <Popover
                        placement="leftTop"
                        content={
                          <div>
                            {state.myPropertyDetails.propertyrankitems.propertyrankingitems.map(
                              (Pitem) => {
                                return (
                                  <p key={"mmy" + Pitem.prioritylistitem}>
                                    {Pitem.prioritylistitem == "Must Haves" ? (
                                      <Popover
                                        placement="leftTop"
                                        content={
                                          <div>
                                            {Pitem.matcheditems.map(
                                              (matcheditem) => {
                                                return (
                                                  matcheditem.itemname !=
                                                    "" && (
                                                    <p
                                                      key={
                                                        "mmypps" +
                                                        matcheditem.itemname
                                                      }
                                                    >
                                                      {matcheditem.itemname}
                                                      {matcheditem.ismatched ==
                                                        true && (
                                                        <i className="fa fa-check"></i>
                                                      )}
                                                      {matcheditem.ismatched ==
                                                        false && (
                                                        <i className="fa fa-times"></i>
                                                      )}
                                                    </p>
                                                  )
                                                );
                                              }
                                            )}
                                          </div>
                                        }
                                        title="Matched Must Haves"
                                        trigger="hover"
                                      >
                                        {Pitem.prioritylistitem}{" "}
                                        <i
                                          className="fa fa-info-circle"
                                          style={{ color: "#000000" }}
                                        ></i>
                                      </Popover>
                                    ) : (
                                      Pitem.prioritylistitem
                                    )}
                                    {Pitem.ismatched == true &&
                                      Pitem.isallmatched == false && (
                                        <i className="fa fa-check yellow_clr"></i>
                                      )}
                                    {Pitem.ismatched == true &&
                                      Pitem.isallmatched == true && (
                                        <i className="fa fa-check"></i>
                                      )}
                                    {Pitem.ismatched == true &&
                                      Pitem.isallmatched == null && (
                                        <i className="fa fa-check"></i>
                                      )}
                                    {Pitem.ismatched == false && (
                                      <i className="fa fa-times"></i>
                                    )}
                                  </p>
                                );
                              }
                            )}
                          </div>
                        }
                        title="Matched Priorities"
                        trigger="hover"
                      >
                        {Math.trunc(
                          state.myPropertyDetails.propertyrankitems.matchpercent
                        )}
                        %
                      </Popover>
                    </b>
                  </span>
                ) : (
                  ""
                )}
              </h1>
              <p>{state.myPropertyDetails.address}</p>
            </header>
          )}
          <h1 className="backBtn">
            <button className="btn " onClick={goBack}>
              <i class="fa-regular fa-angle-left"></i>
            </button>{" "}
          </h1>

          <h1 className="location">
            <select
              className="form-control  text-end pe-5"
              dir="rtl"
              placeholder="Select a property"
              onChange={handlePropertyChange}
              style={{ width: 200 }}
              defaultValue={state.selectedProperty}
            >
              {state.myPropertiesList.map((property) => (
                <option
                  key={property.propertyuniqueId}
                  value={property.propertyname}
                  selected={
                    state.selectedProperty == property.propertyname
                      ? "selected"
                      : ""
                  }
                >
                  {property.propertyname}
                </option>
              ))}
              {/* {state.myPropertiesListInactive.map((property) => (
                <option
                  key={property.propertyuniqueId}
                  value={property.propertyuniqueId}
                  disabled={true}
                >
                  {property.propertyname}
                </option>
              ))} */}
              {state.saPropertiesList.map((properties) => (
                <option
                  key={properties.propertyuniqueId}
                  value={properties.propertyname}
                  selected={
                    state.selectedProperty == properties.propertyname
                      ? "selected"
                      : ""
                  }
                >
                  {properties.propertyname}
                </option>
              ))}
            </select>

            {/* <Select
              placeholder="Select a city"
              onChange={handlePropertyChange}
              style={{ width: 200 }}
              defaultValue={"abc"}
            >
              {state.myPropertiesList.map((property) => (
                <Option
                  key={property.propertyuniqueId}
                  value={property.propertyuniqueId}
                >
                  {property.propertyname}
                </Option>
              ))}
              {state.myPropertiesListInactive.map((property) => (
                <Option
                  key={property.propertyuniqueId}
                  value={property.propertyuniqueId}
                  disabled={true}
                >
                  {property.propertyname}
                </Option>
              ))}
              {state.saPropertiesList.map((properties) => (  $(document).ready(function () {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 575) {
      $(".client-testimonial-carousel").owlCarousel({
        items: 2,
        //      autoplay:false,
        margin: 30,
        loop: false,
        dots: false,
        //      nav:true,
        //      navText:["<i class='fas fa-long-arrow-alt-left'></i>","<i class='fas fa-long-arrow-alt-right'></i>" ]
      });
    } else {
      $(".client-testimonial-carousel").owlCarousel({
        items: 3,
        //      autoplay:false,
        margin: 30,
        loop: false,
        dots: false,
        //      nav:true,
        //      navText:["<i class='fas fa-long-arrow-alt-left'></i>","<i class='fas fa-long-arrow-alt-right'></i>" ]
      });
    }
  });

                <Option
                  key={properties.propertyuniqueId}
                  value={properties.propertyuniqueId}
                >
                  {properties.propertyname}
                </Option>
              ))}
            </Select> */}
          </h1>
          <div className="tabs-view">
            <ul className="nav nav-pills">
              <div className="ulSlider">
                <OwlCarousel
                  className="owl-theme"
                  dots={false}
                  autoWidth={true}
                  items={state.tabItems}
                  startPosition={state.startPositiondrag}
                  key={state.SchoolDisplay} // Adding key to force re-render on state change
                  onDragged={(object) => updateCarouselPositiondrag(object)}
                  onTranslated={(object) => updateCarouselPositiondrag(object)}
                >
                  {state.mainMenu.map((menu, index) => {
                    // Conditional logic to skip rendering the 'schools' menu if SchoolDisplay is false
                    if (menu.id === "schools" && state.SchoolDisplay != true) {
                      return null; // Skip rendering this item
                    }

                    return (
                      <li
                        key={menu.name}
                        className={
                          state.ViewStatus == menu.id
                            ? "nav-link active"
                            : "nav-link"
                        }
                        onClick={() => changeStatus(menu.id)}
                      >
                        <a onClick={() => changeStatus(menu.id)}>{menu.name}</a>
                      </li>
                    );
                  })}
                </OwlCarousel>
              </div>
            </ul>

            <div className="tab-content ">
              {state.ViewStatus == "overview" && (
                <div id="overview" className="tab-pane active container">
                  <div>
                    <div>
                      <div className="row">
                        <div className="col-md-8 ">
                          <div className="">
                            {state.myPropertyDetails.property_image != null &&
                            state.myPropertyDetails.property_image != "" ? (
                              <div className="image">
                                <OwlCarousel
                                  className="owl-theme"
                                  nav
                                  dots={false}
                                  items={1}
                                  startPosition={state.startPosition}
                                  onDragged={(object) =>
                                    updateCarouselPosition(object)
                                  }
                                  onTranslated={(object) =>
                                    updateCarouselPosition(object)
                                  }
                                >
                                  {state.myPropertyDetails.property_image
                                    .split(",")
                                    .map((pImg, pIndex) => {
                                      return (
                                        <div className="item" key={pIndex}>
                                          <img src={pImg} alt="" />
                                        </div>
                                      );
                                    })}
                                </OwlCarousel>
                              </div>
                            ) : (
                              <div className="my_property_details cityImage">
                                <img
                                  src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
                                  alt=""
                                />
                              </div>
                            )}
                          </div>

                          <div className="city-bg mt-4 amenitiesView">
                            <h1 className="h1">Amenities</h1>
                            {state.myPropertyDetails.amenities != undefined &&
                              state.myPropertyDetails.amenities != "" &&
                              state.myPropertyDetails.amenities != null && (
                                <div className="">
                                  {state.myPropertyDetails.amenities !=
                                    undefined &&
                                    state.myPropertyDetails.amenities != "" &&
                                    state.myPropertyDetails.amenities !=
                                      null && (
                                      <div className="amenities">
                                        <ul className="d-flex">
                                          {state.myPropertyDetails.amenities
                                            .split(",")
                                            .map((matcheditem) => {
                                              return (
                                                matcheditem != "" && (
                                                  <li key={matcheditem}>
                                                    <img
                                                      src={
                                                        "./assets/images/amenities/" +
                                                        matcheditem
                                                          .replace(/\s/g, "")
                                                          .replace("/", "") +
                                                        ".svg"
                                                      }
                                                      alt=""
                                                    />
                                                    <span> {matcheditem}</span>
                                                  </li>
                                                )
                                              );
                                            })}
                                        </ul>
                                      </div>
                                    )}
                                </div>
                              )}
                          </div>

                          {state.transitDetails.length > 0 && (
                            <div className="">
                              <h1 className="h1">What's Nearby? </h1>

                              <h1>Bus & Train lines</h1>

                              <ul>
                                {state.transitDetails.map(function (
                                  RoutDetails,
                                  index
                                ) {
                                  return (
                                    <li key={index}>
                                      <span>
                                        {_.hasIn(
                                          RoutDetails.route_summary[0],
                                          "category"
                                        )
                                          ? RoutDetails.route_summary[0]
                                              .category +
                                            " - " +
                                            RoutDetails.route_summary[0].name
                                          : ""}{" "}
                                        - {RoutDetails.name}
                                      </span>
                                      <span>
                                        {RoutDetails.distance.toFixed(2)} mi
                                      </span>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}
                          {/* <div className="city-bg mt-4">
                            <h1 className="h1">Sound Score</h1>
                            {state.soundScoreDetails != undefined &&
                              state.soundScoreDetails.result != undefined &&
                              state.soundScoreDetails.result.length > 0 && (
                                <div className="">
                                  <div className="propertypage">
                                    <div className="d-flex justify-content-between text-center ">
                                      <div>
                                        <div className="soundscore">
                                          <i className="fa fa-bell"></i>
                                          <h1>
                                            SOUNDSCORE <sub>TM</sub>
                                          </h1>
                                          <p>
                                            Powered by How Loud, Inc.
                                            {state.soundScoreDetails.result !=
                                            undefined
                                              ? state.soundScoreDetails.result
                                                  .length
                                              : ""}
                                          </p>

                                          <h1>
                                            {state.soundScoreDetails !=
                                              undefined &&
                                            state.soundScoreDetails.result !=
                                              undefined &&
                                            state.soundScoreDetails.result
                                              .length > 0 &&
                                            state.soundScoreDetails.result[0]
                                              .score != undefined
                                              ? state.soundScoreDetails
                                                  .result[0].score
                                              : 0}
                                          </h1>
                                          <h3>
                                            {state.soundScoreDetails !=
                                              undefined &&
                                            state.soundScoreDetails.result !=
                                              undefined &&
                                            state.soundScoreDetails.result
                                              .length > 0 &&
                                            state.soundScoreDetails.result[0]
                                              .scoretext != undefined
                                              ? state.soundScoreDetails
                                                  .result[0].scoretext
                                              : "BUSY"}
                                          </h3>
                                        </div>
                                        <div className="soundscorepro">
                                          <div className="item">
                                            <h1>TRAFFIC</h1>
                                            <p>
                                              {state.soundScoreDetails !=
                                                undefined &&
                                              state.soundScoreDetails.result !=
                                                undefined &&
                                              state.soundScoreDetails.result
                                                .length > 0 &&
                                              state.soundScoreDetails.result[0]
                                                .traffictext != undefined
                                                ? state.soundScoreDetails
                                                    .result[0].traffictext
                                                : "BUSY"}
                                            </p>
                                          </div>
                                          <div className="item">
                                            <h1>AIRPORT</h1>
                                            <p>
                                              {state.soundScoreDetails !=
                                                undefined &&
                                              state.soundScoreDetails.result !=
                                                undefined &&
                                              state.soundScoreDetails.result
                                                .length > 0 &&
                                              state.soundScoreDetails.result[0]
                                                .airportstext != undefined
                                                ? state.soundScoreDetails
                                                    .result[0].airportstext
                                                : "BUSY"}
                                            </p>
                                          </div>
                                          <div className="item">
                                            <h1>SCORE</h1>
                                            <p>
                                              {state.soundScoreDetails !=
                                                undefined &&
                                              state.soundScoreDetails.result !=
                                                undefined &&
                                              state.soundScoreDetails.result
                                                .length > 0 &&
                                              state.soundScoreDetails.result[0]
                                                .scoretext != undefined
                                                ? state.soundScoreDetails
                                                    .result[0].scoretext
                                                : "BUSY"}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="soundscore-image-view">
                                        <img
                                          src="/assets/images/scorebar.png"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div> */}
                        </div>
                        <div className="col-md-4 ">
                          <div className="city-bg">
                            <div className="">
                              <div className="">
                                <h1 className="mb-3">My Property Details</h1>
                                <span>
                                  {state.myPropertyDetails.propertylink !=
                                    null &&
                                    state.myPropertyDetails.propertylink !=
                                      "" && (
                                      <a
                                        href={
                                          state.myPropertyDetails.propertylink
                                        }
                                        target="_blank"
                                        className=""
                                      >
                                        Property Link{" "}
                                        <span className="side-arrow-view-icon"></span>
                                      </a>
                                    )}
                                  {state.myPropertyDetails.virtual360tour !=
                                    null &&
                                    state.myPropertyDetails.virtual360tour !=
                                      "" && (
                                      <span className="ms-4">
                                        <a
                                          href={
                                            state.myPropertyDetails
                                              .virtual360tour
                                          }
                                          target="_blank"
                                        >
                                          360view
                                        </a>{" "}
                                        <span className="side-arrow-view-icon"></span>
                                      </span>
                                    )}
                                </span>
                              </div>
                            </div>
                            <ul className="propertyDetails mt-4">
                              <li>
                                <label>Available Date</label>
                                <label className="lbl">
                                  {" "}
                                  {state.myPropertyDetails
                                    .available_till_date != null
                                    ? moment(
                                        state.myPropertyDetails
                                          .available_till_date
                                      ).format("MM/DD/YYYY")
                                    : "---"}
                                </label>
                              </li>
                              <li>
                                <label>Monthly Rent($)</label>
                                <label className="lbl">
                                  {state.myPropertyDetails.monthlyrent}
                                </label>
                              </li>
                              <li>
                                <label>Home Type </label>
                                <label className="lbl">
                                  {state.myPropertyDetails.hometype}
                                </label>
                              </li>
                              <li>
                                <label>Bed Rooms</label>
                                <label className="lbl">
                                  {state.myPropertyDetails.bedrooms}
                                </label>
                              </li>
                              <li>
                                <label>Floor</label>
                                <label className="lbl">
                                  {state.myPropertyDetails.flooring}
                                </label>
                              </li>
                              <li>
                                <label>Unit Number</label>
                                <label className="lbl">
                                  {state.myPropertyDetails.unitnumber}
                                </label>
                              </li>
                              <li>
                                <label>Square Feet</label>
                                <label className="lbl">
                                  {state.myPropertyDetails.sqfeet}
                                </label>
                              </li>
                              <li>
                                <label>Note</label>
                                <label className="lbl">
                                  {state.myPropertyDetails.notes}
                                </label>
                              </li>
                            </ul>
                          </div>
                          {state.walkScoreDetails.length > 0 ? (
                            <div className="city-bg mb-4 mt-4">
                              <h1 className="h1">
                                Scores{" "}
                                <i
                                  className="fa fa-info-circle"
                                  data-toggle="modal"
                                  data-target="#walkscoreInfoMadal"
                                  onClick={() => walkScoreModal()}
                                ></i>
                              </h1>{" "}
                              <div className="scoreView">
                                {state.walkScoreDetails.map((row, index) => {
                                  return row.iconImg == 1 ? (
                                    <div className="item" key={index}>
                                      <i className="">
                                        <FontAwesomeIcon icon={faWalking} />
                                      </i>

                                      <div className="new-walkcircle">
                                        <h5
                                          className={
                                            row.Score < 30 ? "belowScore" : ""
                                          }
                                        >
                                          WalkScore
                                        </h5>
                                        <Progress
                                          // type="circle"
                                          percent={row.Score}
                                          format={(percent) => `${percent}`}
                                        />
                                      </div>
                                    </div>
                                  ) : row.iconImg == 2 ? (
                                    <div className="item" key={index}>
                                      <i className="">
                                        <FontAwesomeIcon icon={faBicycle} />
                                      </i>

                                      <div className="new-walkcircle">
                                        <h5
                                          className={
                                            row.Score < 30 ? "belowScore" : ""
                                          }
                                        >
                                          BikeScore
                                        </h5>
                                        <Progress
                                          // type="circle"
                                          percent={row.Score}
                                          format={(percent) => `${percent}`}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="item" key={index}>
                                      <i className="">
                                        <FontAwesomeIcon icon={faTrain} />
                                      </i>

                                      <div className="new-walkcircle">
                                        <h5
                                          className={
                                            row.Score < 30 ? "belowScore" : ""
                                          }
                                        >
                                          TransitScore
                                        </h5>
                                        <Progress
                                          // type="circle"
                                          percent={row.score}
                                          format={(percent) => `${percent}`}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          ) : (
                            <div className="min-height-200 divSpin">
                              <Spin />
                            </div>
                          )}

                          <div className="city-bg">
                            {state.nearByCitiesWithStates &&
                            state.nearByCitiesWithStates.length > 0 &&
                            _.sortBy(
                              state.nearByCitiesWithStates,
                              function (obj) {
                                return parseFloat(obj.miles, 10);
                              }
                            ).length > 0 ? (
                              <div className="d-flex justify-content-between align-content-center">
                                <h1>Cities </h1>{" "}
                                {state.nearByCitiesWithStates.length >
                                  displayCount && (
                                  <a
                                    className=""
                                    href="javascript:void(0)"
                                    onClick={handleShowMore}
                                  >
                                    More
                                  </a>
                                )}
                              </div>
                            ) : (
                              <div>
                                {state.nearByCitiesWithStates &&
                                state.nearByCitiesWithStates.length === 0 ? (
                                  <label className="checklist">
                                    We are building your personalized city list.
                                    Please check back later.
                                  </label>
                                ) : (
                                  <div className="min-height-200 divSpin">
                                    <Spin />
                                  </div>
                                )}
                              </div>
                            )}
                            <ul className="near-city">
                              {_.sortBy(
                                state.nearByCitiesWithStates,
                                function (obj) {
                                  return parseFloat(obj.miles, 10);
                                }
                              )
                                .slice(0, displayCount)
                                .map((row, index) => {
                                  return (
                                    <li
                                      onClick={() => cityDetails(row)}
                                      key={index}
                                    >
                                      <span>
                                        <a href="javascript:void(0)">
                                          {row.city}{" "}
                                        </a>{" "}
                                      </span>
                                      <span className="side-arrow-view-icon"></span>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </div>
                      </div>
                      {state.weather && state.weather_current != undefined ? (
                        <NewWeather
                          {...state}
                          weather={state.weather}
                          weather_current={state.weather_current}
                          weather_currentCityDetails={
                            state.weather_currentCityDetails
                          }
                        />
                      ) : (
                        <div className="min-height-200 divSpin">
                          <Spin />
                        </div>
                      )}{" "}
                    </div>
                  </div>
                </div>
              )}
              {state.scoreModal === true && (
                <div
                  id="walkscoreInfoMadal"
                  className="modal fade"
                  style={{
                    zIndex: 1060,
                    transition: "none",
                    opacity: 1,
                  }}
                  role="dialog"
                >
                  <WalkScorePopUp state={state} setState={setState} />
                </div>
              )}
              {state.ViewStatus == "schools" && (
                <div id="school" className="">
                  {state.ispoi == false &&
                  state.data.latitude != "" &&
                  state.data.longitude != "" ? (
                    // <SchoolsSearch {...state} isPoi={false} />
                    // <SchoolsSearch2 {...state} />
                    <SchoolSearchMapBox {...state} />
                  ) : // <SchoolSearchNew {...state} />
                  null}
                </div>
              )}
              {state.ViewStatus == "poi" && (
                <div id="">
                  {state.ispoi == true &&
                  state.data.latitude != "" &&
                  state.data.longitude != "" ? (
                    <PoiSearch {...state} />
                  ) : null}
                </div>
              )}
              {state.ViewStatus == "transit" && (
                <div id="transit">
                  {state.travelMapLoading && (
                    <PublicTransit
                      lines={state.lines}
                      latitude={state.data.latitude}
                      longitude={state.data.longitude}
                      isProperty={state.isProperty}
                      isSaproperty={false}
                    />
                  )}
                </div>
              )}
              {state.ViewStatus == "explore" && (
                <div id="explore-the-area">
                  <div className="">
                    {/* <div id="mapExplore" className="b-radius-8"></div> */}

                    <div className="map_top_view">
                      <Mapview
                        {...state}
                        latitude={state.communityObject.latitude}
                        longitude={state.communityObject.longitude}
                        communityName={state.communityObject.community_name}
                        communityObject={state.communityObject}
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* {console.log(state.ViewStatus, "neighborr")} */}
              {/* {state.ViewStatus == "Neighborhood" && (
                <div id="Neighborhood">
                  <div className="neighborhood-page-view">
                    <div className="bb-white city-padding b-radius-8 m-p-d-page">
                      <p className="mb-5" style={{ fontSize: "16px" }}>
                        ReloNavigator is now partnered with NeighborhoodScout to
                        save your time spent on researching for a safe and best
                        neighborhood for you and your family. You can now
                        generate a neighborhood report that comprises of Real
                        Estate Data, Demographics, Crime Data, Public School
                        Ratings and many more relating to this property &
                        neighborhood.
                      </p>
                      {state.myPropertyDetails.pdffilepath != null &&
                      state.myPropertyDetails.pdffilepath != "" ? (
                        <div className="form-group text-center mb-5  mb-4">
                          <a
                            href={state.myPropertyDetails.pdffilepath}
                            className="btn theme-btn"
                            type="button"
                          >
                            Download Report
                          </a>
                        </div>
                      ) : state.viewNeighborhoodGenerate == false ? (
                        <div className="form-group text-center mb-5  mb-4">
                          <button
                            className="btn theme-btn"
                            type="button"
                            onClick={GeneratePdf}
                          >
                            Generate Report
                          </button>
                        </div>
                      ) : (
                        <div className="form-group text-center mb-5  mb-4">
                          <Progress
                            type="circle"
                            percent={state.ProgressValue}
                            width={80}
                          />
                        </div>
                      )}

                      {state.myPropertyDetails.pdffilepath != null &&
                      state.myPropertyDetails.pdffilepath != "" ? (
                        ""
                      ) : (
                        <p className="disclimerpara">
                          Click on Generate Report above, to generate the
                          neighborhood report. You will have access to View or
                          Download it any time within 30 days from generating
                          the report. But you can always come back and generate
                          the report again after 30 days.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
      {state.ViewStatus == "overview" && <Footer />}
    </div>
  );
};
export default MyPropertyDetails;
