import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWalking,
  faBicycle,
  faTrain,
} from "@fortawesome/fontawesome-free-solid";
import { Progress } from "antd";

function WalkScoreCity(props) {
  // console.log(props, "propsof walkscore");
  const { WalkScore, BikeScore, TransitScore } = props;
  return (
    <div className="scoreView">
      <div className="item" key={0}>
        <i className="">
          <FontAwesomeIcon icon={faWalking} />
        </i>

        <div className="new-walkcircle">
          <h5 className={WalkScore < 30 ? "belowScore" : "hello"}>WalkScore</h5>
          <Progress
            // type="circle"
            percent={WalkScore}
            format={(percent) => `${percent}`}
          />
          {/* <div className="progress-value">
            {WalkScore >= 90 ? (
              <p>Walker's Paradise</p>
            ) : WalkScore >= 70 && WalkScore < 90 ? (
              <p>Very Walkable</p>
            ) : WalkScore >= 50 && WalkScore < 70 ? (
              <p>Somewhat Walkable</p>
            ) : WalkScore >= 25 && WalkScore < 50 ? (
              <p>Car-Dependent</p>
            ) : (
              <p>Car-Dependent</p>
            )}
          </div> */}
        </div>
      </div>

      <div className="item" key={1}>
        <i className="">
          <FontAwesomeIcon icon={faBicycle} />
        </i>

        <div className="new-walkcircle">
          <h5 className={BikeScore < 30 ? "belowScore" : ""}>BikeScore</h5>
          <Progress
            // type="circle"
            percent={BikeScore}
            format={(percent) => `${percent}`}
          />
          {/* <div className="progress-value">
            {BikeScore >= 90 ? (
              <p>Biker's Paradise</p>
            ) : BikeScore >= 70 && BikeScore < 90 ? (
              <p>Very Bikeable</p>
            ) : BikeScore >= 50 && BikeScore < 70 ? (
              <p>Bikeable</p>
            ) : (
              <p>Somewhat Bikeable</p>
            )}
          </div> */}
        </div>
      </div>
      {TransitScore != "" &&
        TransitScore != undefined &&
        TransitScore != null && (
          <div className="item" key={2}>
            <i className="">
              <FontAwesomeIcon icon={faTrain} />
            </i>

            <div className="new-walkcircle">
              <h5 className={TransitScore < 30 ? "belowScore" : ""}>
                TransitScore
              </h5>
              <Progress
                // type="circle"
                percent={TransitScore}
                format={(percent) => `${percent}`}
              />
              {/* <div className="progress-value">
                {TransitScore >= 90 ? (
                  <p>Rider's Paradise</p>
                ) : TransitScore >= 70 && TransitScore < 90 ? (
                  <p>Excellent Transit</p>
                ) : TransitScore >= 50 && TransitScore < 70 ? (
                  <p>Good Transit</p>
                ) : TransitScore >= 25 && TransitScore < 50 ? (
                  <p>Some Transit</p>
                ) : (
                  <p>Minimal Transit</p>
                )}
              </div> */}
            </div>
          </div>
        )}
    </div>
  );
}

export default WalkScoreCity;
