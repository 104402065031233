import React, { useEffect, useState } from "react";
import "./home.css";
import "./contact.css";
import "./pricing.css";
import ThirdParty from "../../../../services/thirdPartyService";

const Pricing = () => {
  const [state, setState] = useState({
    firstname: "",
    email: "",
    description: "",
    succesMsg: "",
    error: "",
    subject: "",
    sessionObject: true,
    moreInfo: false,
  });
  useEffect(() => {
    async function fetchData() {
      try {
        window.scrollTo(0, 0);
        let getSessionData = await ThirdParty.getLoginInfo({});
        if (Object.keys(getSessionData).length > 0) {
          setState((prev) => ({ ...prev, sessionObject: false }));
        }
        // Rest of your async code here
      } catch (error) {
        // Handle errors here
      }
    }

    fetchData();
  }, []);
  // const inputHandler = async (event) => {
  //   // console.log(event, "event");
  //   let field = event.target.name;
  //   let value = event.target.value;
  //   setState((prev) => ({
  //     ...prev,
  //     [field]: value,
  //   }));
  // };
  const inputHandler = (event) => {
    let inputVal = event.target.value;
    let inputName = event.target.name;
    let newState = { ...state };
    newState[inputName] = inputVal;
    if (inputVal !== "" && inputVal < 1) {
      $("#" + inputName).addClass("is-invalid");
    } else {
      $("#" + inputName).removeClass("is-invalid");
    }
    setState(newState);
  };
  const sendContact = async (event, value) => {
    event.preventDefault();
    await setState({
      moreInfo: false,
      succesMsg: "",
      subject: value,
      contactError: "",
      error: "",
      description: "",
      firstname: "",
      email: "",
    });
    $("document").ready(function () {
      $("#contact-us-pricing").modal("show");
      $("#contact-us-pricing").find("input,textarea").val("").end();
    });
  };
  const sendContactMore = async (event, value) => {
    event.preventDefault();
    await setState({
      moreInfo: true,
      succesMsg: "",
      subject: value,
      contactError: "",
      error: "",
      description: "",
      firstname: "",
      email: "",
    });
    $("document").ready(function () {
      $("#contact-us-pricing").modal("show");
      $("#contact-us-pricing").find("input,textarea").val("").end();
    });
  };
  const submitContactBtn = async (event) => {
    event.preventDefault();

    let errorUpdate = 0;
    if (state.firstname.trim() == "") {
      console.log("errorname");
      $("#firstname").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#firstname").removeClass("is-invalid");
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.email)) {
      $("#email").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#email").removeClass("is-invalid");
    }
    if (state.description.trim() == "") {
      $("#description").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#description").removeClass("is-invalid");
    }
    if (errorUpdate == 0) {
      var data = {
        description: state.description,
        firstname: state.firstname,
        theContactus: true,
        FromEmail: state.email,
        subject: state.subject,
        email: "geetha.kunda@devrabbit.com",
        cc: state.email,
      };
      let sendSms = await ThirdParty.sendEmail(data);
      if (sendSms.response == true) {
        setState({
          ...state,
          succesMsg: "Mail has been sent Successfully!",
          succesMsgs: "",
          contactError: "",
          error: "",
          subject: "",
          description: "",
          firstname: "",
          lastname: "",
          email: "",
        });
      }
    }
  };
  const closePopUp = () => {
    $("document").ready(function () {
      $("#contact-us-pricing").modal("hide");
      $("#contact-us-pricing").find("input,textarea").val("").end();
    });
  };
  const listItems = [
    {
      itemName: "manage Your Preferences",
      navigator: true,
      navigatorPlus: true,
    },
    { itemName: "Area Information", navigator: true, navigatorPlus: true },
    { itemName: "Schools & Ratings", navigator: true, navigatorPlus: true },
    { itemName: "Neighborhood Details", navigator: true, navigatorPlus: true },
    { itemName: "Home Finding", navigator: true, navigatorPlus: true },
    {
      itemName: "Rent Score & Compare Rentals",
      navigator: true,
      navigatorPlus: true,
    },
    { itemName: "Custom Maps", navigator: true, navigatorPlus: true },
    { itemName: "CheckLists", navigator: true, navigatorPlus: true },
    {
      itemName: "Dedicated Personal Consultant",
      navigator: false,
      navigatorPlus: true,
    },
    { itemName: "Virtual Assistance", navigator: false, navigatorPlus: true },
    {
      itemName: "Schedule Appointments",
      navigator: false,
      navigatorPlus: true,
    },
    { itemName: "Message Center", navigator: false, navigatorPlus: true },
    {
      itemName: "High Touch In-Person Support",
      navigator: false,
      navigatorPlus: true,
    },
    { itemName: "Chauffeured Tours", navigator: false, navigatorPlus: true },
    { itemName: "Concierge Services", navigator: false, navigatorPlus: true },
  ];
  const svgYes = "/assets/images/yes.svg";
  const svgNo = "/assets/images/no.svg";
  return (
    <div id="outer-container">
      <section className="pricing pricing-bg pt-5 pb-1" id="#section7">
        <div>
          <h2 className="text-center py-5"> Powerful Tool in Simplest Form</h2>
        </div>
        <div className="pricing-views">
          <div className="container">
            <div className="row row-pricing">
              <div className="col-lg-3 col-md-3 item item-first">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-body">
                    <ul className="side-list-view">
                      {listItems.map((item, index) => (
                        <li key={index}>{item.itemName}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 item">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-body">
                    <h5 className="text-uppercase text-center">NAVIGATOR</h5>
                    <ul className="fa-ul">
                      {listItems.map((item, index) => (
                        <li>
                          <span className="fa-ul-li-span">{item.itemName}</span>
                          <img src={item.navigator ? svgYes : svgNo} alt="" />
                        </li>
                      ))}
                    </ul>
                    <div className="text-center">
                      <a
                        href="javascript:void(0)"
                        className="btn btn-block btn-primary text-uppercase"
                        onClick={(event) =>
                          sendContact(event, "Pricing for Navigator")
                        }
                      >
                        Contact us <span>For Pricing</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 item">
                <div className="card">
                  <div className="card-body">
                    <h5 className="text-uppercase text-center">NAVIGATOR+</h5>
                    <ul className="fa-ul">
                      {listItems.map((item, index) => (
                        <li>
                          <span className="fa-ul-li-span">{item.itemName}</span>
                          <img src={item.navigator ? svgYes : svgNo} alt="" />
                        </li>
                      ))}
                    </ul>
                    <div>
                      <a
                        href="javascript:void(0)"
                        className="btn btn-block btn-primary text-uppercase"
                        onClick={(event) =>
                          sendContact(event, "Pricing for Commander")
                        }
                      >
                        Contact us <span>For Pricing</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="dark_section-pricing-view py-3">
        <div className="container pricing-bottom-box">
          <h1>"Destination Services"- as a Service</h1>
          <p style={{ textAlign: "center" }}>Tailored to fit your needs!</p>
          <div className="main-view">
            <div className="item">
              <img src="/assets/images/pricing-box-view1.jpg" alt="" />
              <p>
                Individual/Bulk <span> Subscriptions </span>{" "}
              </p>
            </div>
            <div className="item">
              <img src="/assets/images/pricing-box-view2.jpg" alt="" />
              <p>White Labeling</p>
            </div>
            <div className="item">
              <img src="/assets/images/pricing-box-view3.jpg" alt="" />

              <p>Licensing</p>
            </div>
          </div>
        </div>

        <p className="contactus-more" style={{ textTransform: "none" }}>
          {" "}
          <a
            style={{ textTransform: "none" }}
            href="javascript:void(0)"
            onClick={(event) =>
              sendContactMore(event, "Contact us for more details")
            }
          >
            Contact us for more details
          </a>{" "}
        </p>
      </div>

      <div
        id="contact-us-pricing"
        className="modal fade"
        role="dialog"
        data-backdrop="static"
        style={{ zIndex: 1060 }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="width-80per pd-y-50">
              <div className="only_close">
                <span
                  className="pointer close"
                  data-dismiss="modal"
                  onClick={closePopUp}
                >
                  ×
                </span>
              </div>
              <div className="focus-input-label mt-3 padding-x-50">
                {state.succesMsg != "" && (
                  <h6 className="alert alert-success">{state.succesMsg}</h6>
                )}
                {state.error != "" && (
                  <h6 className="alert alert-danger">{state.error}</h6>
                )}
                {state.succesMsg == "" && (
                  <form
                    method="post"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <h2>
                      {state.moreInfo == true
                        ? "Contact us for more details"
                        : "Contact us for pricing"}
                    </h2>
                    <div className="">
                      <div className="main-row">
                        <div className="item">
                          <div className="floating-label">
                            <input
                              type="text"
                              placeholder="name"
                              value={state.firstname}
                              className="form-control"
                              id="firstname"
                              name="firstname"
                              onChange={(event) => inputHandler(event)}
                            />
                            <label>Name</label>
                          </div>
                          <div className="floating-label">
                            <input
                              placeholder="email"
                              type="text"
                              className="form-control"
                              id="email"
                              name="email"
                              value={state.email}
                              onChange={(event) => inputHandler(event)}
                            />
                            <label>Email</label>
                          </div>
                          <div className="floating-label">
                            <textarea
                              className="form-control"
                              rows="6"
                              placeholder="Enter message"
                              value={state.description}
                              id="description"
                              name="description"
                              onChange={(event) => inputHandler(event)}
                            />
                            <label>Message</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group text-center mt-5  mb-4">
                      <button
                        className="btn theme-btn"
                        type="button"
                        onClick={submitContactBtn}
                      >
                        Submit <span></span>
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
