import React from "react";
// import Modal from "react-modal";
import { Radio, Spin, Select, Modal } from "antd";
import moment from "moment";
import * as $ from "jquery";
import axios from "axios";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ApiServices from "../../../../../services/apiServices";
import ReactDatePicker from "react-datepicker";
import S3Upload from "react-s3-uploader/s3upload";

const AddEditModal = ({
  isOpen,
  onClose,
  state,
  getDistance,
  setState,
  getMyProperties,
}) => {
  const [isHometypeValid, setHometypeValid] = useState(true);
  const validateHometype = (value) => {
    if (value === "") {
      setHometypeValid(false);
    } else {
      setHometypeValid(true);
    }
  };
  let url = "";
  const addMyProperties = async (value) => {
    let errorUpdate = 0;
    await setState((prevState) => ({
      ...prevState,
      loader: true,
      isPropertyError: false,
      propertySuccess: false,
    }));

    if (state.propertyName.trim() == "" || state.propertyName == null) {
      $("#propertyName").addClass("is-invalid");
      await setState((prevState) => ({ ...prevState, loader: false }));
      errorUpdate++;
    } else {
      $("#propertyName").removeClass("is-invalid");
    }
    if (
      state.address.trim() == "" ||
      state.address == null ||
      state.state == "" ||
      state.city == "" ||
      state.latitude == "" ||
      state.longitude == ""
    ) {
      await setState((prevState) => ({ ...prevState, loader: false }));
      $("#address").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#address").removeClass("is-invalid");
    }
    if (!state.hometype.trim()) {
      $("#hometype").addClass("is-invalid");
      await setState((prevState) => ({ ...prevState, loader: false }));
      errorUpdate++;
    } else {
      $("#hometype").removeClass("is-invalid");
    }
    if (!state.bedrooms) {
      $("#bedrooms").addClass("is-invalid");
      console.log($("#bedrooms"));
      await setState((prevState) => ({ ...prevState, loader: false }));
      errorUpdate++;
    } else {
      $("#bedrooms").removeClass("is-invalid");
    }
    if (!state.bathrooms) {
      $("#bathrooms").addClass("is-invalid");
      await setState((prevState) => ({ ...prevState, loader: false }));
      errorUpdate++;
    } else {
      $("#bathrooms").removeClass("is-invalid");
    }
    if (state.monthlyrent == "" || state.monthlyrent == null) {
      await setState((prevState) => ({ ...prevState, loader: false }));
      $("#monthlyrent").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#monthlyrent").removeClass("is-invalid");
    }

    let checkDistance = 0;
    let checkBudget = 0;
    if (errorUpdate == 0) {
      let propertyObject = {
        transfereeuniqueId: state.userUniqueId,
        apiId: Math.floor(1000000000000 + Math.random() * 9000000000000),
        source: "my_property",
        city: state.city,
        isdelete: false,
        propertyname: state.propertyName,
        address: state.address,
        state: state.state,
        latitude: state.latitude,
        longitude: state.longitude,
        propertylink: state.propertyLink,
        notes: state.propertyNotes,
        isIncluded: false,
        amenities: Array.isArray(state.amenities)
          ? state.amenities.join(", ")
          : state.amenities,
        monthlyrent: state.monthlyrent,
        hometype: state.hometype,
        isDelete: state.isDelete,
        isactive: state.isDelete,
        bedrooms: state.bedrooms,
        bathrooms: state.bathrooms,
        unitnumber: state.unitnumber,
        flooring: state.flooring,
        sqfeet: state.sqfeet,
        virtual360tour: state.virtual360tour,
        property_image: prepareUploadImagesString(state.uploadImages),
        available_till_date: state.availableTillDate,
        isinterested: state.isinterested,
      };

      if (
        (checkDistance == 0 || checkBudget == 0) &&
        state.anywayProceed == false
      ) {
        console.log("checking");
        let DistanceProperty = getDistance(
          { lat: propertyObject.latitude, lng: propertyObject.longitude },
          state.workAddress
        );
        console.log(DistanceProperty, "DistanceProperty");
        let preferenecesBudget = propertyObject.monthlyrent;

        let messageDistance =
          (DistanceProperty > 100
            ? "Commute distance is greater than 100 miles from work location."
            : "") +
          (parseInt(state.preferenecesBudget) < parseInt(preferenecesBudget)
            ? " Monthly rent is more than your preference."
            : "");
        if (messageDistance != "") {
          console.log(messageDistance, "messageDistance");
          await setState((prevState) => ({
            ...prevState,
            checkExceed: true,
            messageDistance: messageDistance + ". Do you want to continue?",
            // showProceedBtn: true,
          }));
        } else {
          await setState((prevState) => ({
            ...prevState,
            checkExceed: false,
            ShowSuccess: true,
          }));
        }
      }
      if (state.propertyanalyticsuniqueId != "" && state.checkExceed == false) {
        console.log("updted");
        propertyObject.propertyuniqueId = state.propertyuniqueId;
        propertyObject.propertyanalyticsuniqueId =
          state.propertyanalyticsuniqueId;
        propertyObject.apiId = state.apiId;

        let addPropertyResultUpdate = await ApiServices.UpdateProperty(
          propertyObject
        );
        if (addPropertyResultUpdate.error == 0) {
          $("document").ready(function () {
            $("#add-property-modal").find("input").val("").end();
            $("#add-property-modal").modal("hide");
          });

          getMyProperties();
          setState((prevState) => ({
            ...prevState,
            // checkExceed: false,
            //ShowSuccess: true,
            loader: false,
            propertySuccess: true,
            propertyName: "",
            propertyLink: "",
            address: "",
            propertyNotes: "",
            uploadImages: [],
            availableTillDate: null,
            EditMoveDate: null,
            imageSizeError: "",
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            checkExceed: false,
            isPropertyError: true,
            loader: false,
            errorMessage: addPropertyResultUpdate.message.status,
          }));
        }
      } else if (state.isDelete == false && state.checkExceed == false) {
        console.log("created");
        let addPropertyResult = await ApiServices.CreateTransfereeProperties(
          propertyObject
        );
        if (addPropertyResult.error == 0) {
          $("document").ready(function () {
            $("#add-property-modal").find("input").val("").end();
            $("#add-property-modal").modal("hide");
          });

          getMyProperties();
          setState((prevState) => ({
            ...prevState,
            //ShowSuccess: true,
            // checkExceed: false,
            loader: false,
            propertySuccess: true,
            propertyName: "",
            propertyLink: "",
            address: "",
            propertyNotes: "",
            uploadImages: [],
            availableTillDate: null,
            EditMoveDate: null,
            imageSizeError: "",
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            // checkExceed: false,
            isPropertyError: true,
            loader: false,
            errorMessage: addPropertyResult.message.status,
          }));
        }
      }
    }
  };

  const onChangeTypes = async (type, value) => {
    // console.log(type, typeof value, "=====value", value.join(", "));
    let inputVal = value;
    let inputObject = {};
    inputObject[type] = inputVal.toString();
    if (inputVal == null || inputVal == "") {
      $("#" + type).addClass("is-invalid");
    } else {
      $("#" + type).removeClass("is-invalid");
    }
    setState((prev) => ({ ...prev, [type]: value }));
  };
  const onChangeCategoryType = async (e) => {
    e.persist();
    let InputValue = e.target.value;
    await setState((prevState) => ({
      ...prevState,
      hometype: InputValue,
      //isCompTask: false,
    }));
  };
  const setLocation = async (Id, e) => {
    // let Id = e.target.name;
    let address = e.target.value;
    setState((prev) => ({
      ...prev,
      [Id]: address,
    }));
    if (e.target.value.trim() == null || e.target.value.trim() == "") {
      $("#" + Id).addClass("is-invalid");
    } else {
      $("#" + Id).removeClass("is-invalid");
    }
    // let LocationObje = {};
    // LocationObje[Id] = e.target.value;
    // setState((prev) => ({ ...prev, LocationObje }));
    var places = new google.maps.places.Autocomplete(
      document.getElementById(Id),
      { types: ["address"] }
    );
    places.setComponentRestrictions({ country: ["us"] });
    google.maps.event.addListener(places, "place_changed", async () => {
      var place = places.getPlace();
      var placeDetails = {
        locality: "long_name",
        administrative_area_level_1: "short_name",
        country: "long_name",
      };
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (placeDetails[addressType]) {
          var val = place.address_components[i][placeDetails[addressType]];
          placeDetails[addressType] = val;
        }
      }
      let relocatedAddress = place.formatted_address;
      setState((prevState) => ({
        ...prevState,
        address: relocatedAddress,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        state: placeDetails.administrative_area_level_1,
        city: placeDetails.locality,
      }));
    });
  };

  const inputHandler = (event) => {
    let inputVal = event.target.value;
    let inputName = event.target.name;

    // Create a copy of the previous state
    let newState = { ...state };

    // Update the corresponding field in the newState
    newState[inputName] = inputVal;

    // Perform validation based on inputName
    if (
      (inputName === "monthlyrent" || inputName === "flooring") &&
      inputVal !== "" &&
      inputVal < 1
    ) {
      $("#" + inputName).addClass("is-invalid");
    } else {
      $("#" + inputName).removeClass("is-invalid");
    }

    // Update the state with the new values
    setState(newState);
  };

  const onChange = async (e) => {
    await setState((prevState) => ({
      ...prevState,
      isinterested: e.target.value,
    }));
  };
  const handleDateChange = async (editDate) => {
    await setState((prevState) => ({
      ...prevState,
      EditMoveDate: new Date(editDate),
      availableTillDate: moment(editDate).format("YYYY/MM/DD"),
    }));
  };
  const onProgress = async (type, fileIndex, progress) => {
    setState((prev) => ({ ...prev, uploadLoader: true }));
  };
  const onError = async (type, fileIndex, err) => {
    setState((prev) => ({ ...prev, uploadLoader: false }));
  };
  const onFinish = async (type, fileIndex, FileDetails, info) => {
    console.log(FileDetails.name, "FileDetails", info);
    const filepath =
      "https://relonavigator.s3.amazonaws.com/" + FileDetails.name;
    await filesListUpdate(filepath, FileDetails, info, -1);
  };
  const filesListUpdate = async (filepath, FileDetails, info, dataIndex) => {
    let data = Object.assign([], state.uploadImages);
    data.push(filepath);
    setState((prev) => ({ ...prev, uploadImages: data, uploadLoader: false }));
  };
  const onImageChangeHandler = async (event) => {
    event.preventDefault();
    let files = event.target.files;
    if (files.length > 10) {
      await setState((prevState) => ({
        ...prevState,
        uploadImageError: "Only 5 images can be uploaded at a time.",
      }));
      $("#optionimages").addClass("is-invalid");
    } else {
      let err = "";
      const types = ["image/png", "image/jpeg", "image/gif"];
      const data = new FormData();
      for (var x = 0; x <= 10 - state.uploadImages.length; x++) {
        if (files[x] != undefined) {
          if (types.every((type) => files[x].type !== type)) {
            err += "Only supported png, jpeg, gif files format supported.";
          } else {
            data.append("file", files[x]);
          }
        }
      }
      if (err !== "") {
        await setState((prevState) => ({
          ...prevState,
          uploadImageError: err,
        }));
        $("#propertyImage").addClass("is-invalid");
      } else {
        await setState((prevState) => ({
          ...prevState,
          uploadImageError: "",
          uploadLoader: true,
        }));
        $("#propertyImage").removeClass("is-invalid");
        const _this = this;
        let userUniqueId = state.userUniqueId;
        _.forEach(files, function (file) {
          console.log(file, "====");
          let timestamp = Date.now().toString();
          let path = `userinfo/${userUniqueId}/${timestamp}-lg`;
          const S3Uploader = new S3Upload({
            files: [file],
            signingUrl: "/s3/sign",
            s3path: path,
            signingUrlQueryParams: {},
            onProgress: onProgress(2, 0),
            onFinishS3Put: onFinish(2, 0, file),
            onError: onError(2, 0),
            uploadRequestHeaders: {
              "x-amz-acl": "public-read",
            },
            contentDisposition: file.type,
            server: "",
          });
        });
      }
    }
  };
  async function imageChangeHandler(e) {
    let files = e.target.files;
    // if (isFileLimitExceeded(e)) {
    //   return;
    // }
    // if (!isFileFormatSupported(e)) {
    //   return;
    // }
    // uploadImages(e);

    // Validate file limits and formats (including size)
    const validFiles = isFileLimitExceeded(e);
    const formatValid = isFileFormatSupported(e);
    // console.log(validFiles, "validFiles", formatValid);
    // uploadImages(e);
    if (!validFiles && formatValid.length > 0) {
      // console.log(formatValid, "formatValid files");

      uploadImages(formatValid);
    }
  }
  function isFileLimitExceeded(event) {
    let files = event.target.files;
    if (files.length > 5) {
      event.target.value = null;
      updateState({
        uploadImageError: "Only 5 images can be uploaded at a time.",
        imageSizeError: "Only 5 images can be uploaded at a time.",
      });
      return true;
    }
    return false;
  }

  function isFileFormatSupported(event) {
    const types = ["image/png", "image/jpeg", "image/gif"];
    const maxFileSize = 1 * 1024 * 1024; // 1MB in bytes
    let files = event.target.files;
    let validFiles = [];
    let errorMessages = [];

    for (let i = 0; i < files.length; i++) {
      if (!types.includes(files[i].type)) {
        errorMessages.push(`${files[i].name}: Unsupported file format.`);
      } else if (files[i].size > maxFileSize) {
        errorMessages.push(`${files[i].name}: File size exceeds 1MB.`);
      } else {
        // Add valid files to the array
        validFiles.push(files[i]);
      }
    }

    // If there are error messages, update the state with the errors
    if (errorMessages.length > 0) {
      updateState({
        uploadImageError: errorMessages.join(" "),
        imageSizeError: errorMessages.join(" "),
      });
    }

    // Return the valid files for further processing
    return validFiles;
  }

  // function isFileFormatSupported(event) {
  //   const types = ["image/png", "image/jpeg", "image/gif"];
  //   let files = event.target.files;
  //   let err = "";
  //   for (let i = 0; i < files.length; i++) {
  //     if (types.every((type) => files[i].type !== type)) {
  //       err = "Only supported png, jpeg, gif files format supported.";
  //     }
  //   }
  //   if (err) {
  //     updateState({
  //       uploadImageError:
  //         "Only supported png, jpeg, gif files format supported.",
  //     });
  //     $("#propertyImage").addClass("is-invalid");
  //     return false;
  //   }
  //   return true;
  // }
  async function updateState(values) {
    setState((prev) => ({ ...prev, ...values }));
  }

  function uploadImages(correctfiles) {
    let files = correctfiles;
    // console.log(files, "files uploaded ");

    const data = new FormData();
    for (var x = 0; x < files.length; x++) {
      data.append("file", files[x]);
    }
    updateState({ uploadImageError: "", uploadLoader: true });
    try {
      axios
        .post(`/profileimagesInfo`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          let data = [...state.uploadImages];
          _.forEach(response.data, async (response) => {
            data.push(response);
          });

          updateState({
            uploadImages: data,
            uploadImageError: "Successfully uploaded.",

            uploadLoader: false,
          });
        })
        .catch((error) => {
          console.log(error, "error");

          setState({
            uploadImageError: "something went wrong while uploading images",
            uploadLoader: false,
          });
        });
    } catch (error) {
      console.log(error);
      setState({
        uploadImageError: "something went wrong while uploading images",
        uploadLoader: false,
      });
    }
  }

  // function uploadImages(event) {
  //   // Set the loading state
  //   updateState({ uploadLoader: true });
  //   let files = event.target.files;
  //   const data = new FormData();
  //   const maxFileSize = 1 * 1024 * 1024; // 1MB in bytes
  //   let errorMessages = [];

  //   // Process each file
  //   for (let x = 0; x < files.length; x++) {
  //     if (files[x].size <= maxFileSize) {
  //       // Add valid files to FormData
  //       data.append("file", files[x]);
  //     } else {
  //       // Track errors for files exceeding the size limit
  //       errorMessages.push(`${files[x].name} exceeds the 1MB size limit.`);
  //     }
  //   }

  //   // If there are no valid files, show the error and exit
  //   if (data.has("file") === false) {
  //     updateState({
  //       imageSizeError: errorMessages.join(" "),
  //       uploadLoader: false,
  //     });
  //     return;
  //   }

  //   // Show errors for oversized files but continue with valid uploads
  //   if (errorMessages.length > 0) {
  //     updateState({
  //       uploadImageError: errorMessages.join(" "),
  //       uploadLoader: false,
  //     });
  //   } else {
  //     updateState({ uploadImageError: "", uploadLoader: false });
  //   }

  //   try {
  //     axios
  //       .post(`/profileimagesInfo`, data, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       })
  //       .then((response) => {
  //         let uploadedData = [...state.uploadImages];
  //         _.forEach(response.data, (imageResponse) => {
  //           uploadedData.push(imageResponse);
  //         });

  //         updateState({
  //           uploadImages: uploadedData,
  //           uploadImageError: "Successfully uploaded.",
  //           uploadLoader: false,
  //         });
  //       })
  //       .catch((error) => {
  //         updateState({
  //           uploadImageError: "Something went wrong while uploading images",
  //           uploadLoader: false,
  //         });
  //       });
  //   } catch (error) {
  //     console.log(error);
  //     updateState({
  //       uploadImageError: "Something went wrong while uploading images",
  //       uploadLoader: false,
  //     });
  //   }
  // }

  function prepareUploadImagesString(obj) {
    let arr = [];
    obj.forEach((item) => {
      arr.push(item.Location);
    });
    return arr.toString();
  }
  const removeimage = async (value, id) => {
    let list = Object.assign([], state.uploadImages);
    let arr = list.filter((item) => item !== value);
    await setState((prevState) => ({ ...prevState, uploadImages: arr }));
  };
  const addMyPropertiesProceed = async () => {
    // await setState((prevState) => ({
    //   ...prevState,
    //   loader: false,
    //   anywayProceed: true,
    //   checkExceed: false,
    //   propertyLoader: false,
    //   ShowSuccess: true,
    // }));
    // addMyProperties("true");
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        loader: true,
        anywayProceed: true,
        checkExceed: false,
      }));
    }, 10000);

    // Proceed with adding properties
    await addMyProperties("true");

    // Hide loader and update state after adding properties
    // setTimeout(() => {
    setState((prevState) => ({
      ...prevState,
      loader: false,
      ShowSuccess: true,
      anywayProceed: false,
    }));
    //setState((prev) => ({ ...prev, propertyLoader: false }));
  };
  const children = [];
  var dataSource = ["APARTMENT", "CONDO", , "DUPLEX", "TOWNHOME", "HOUSE"];
  for (let i = 0; i < dataSource.length; i++) {
    children.push(
      <Select.Option key={dataSource[i]}>{dataSource[i]}</Select.Option>
    );
  }
  const amenitieslist = [];
  var dataSource1 = [
    "Has AC",
    "Business Center",
    "Carpeted",
    "Ceiling Fan",
    "Clubhouse",
    "Concierge",
    "Fireplace",
    "Fitness Center",
    "Gated Community",
    "Granite Counters",
    "Hardwood Floors",
    "Laundry Facility",
    "Non Smoking",
    "Parking / Garage",
    "Patio",
    "Pet Friendly",
    "Pool",
    "Renovated",
    "Spa",
    "Stainless Appliances",
    "Has Storage",
    "Walk-in Closet",
    "Washer / Dryer",
    "Playground / Tot Lot",
  ];
  for (let i = 0; i < dataSource1.length; i++) {
    amenitieslist.push(
      <Select.Option key={dataSource1[i]}>{dataSource1[i]}</Select.Option>
    );
  }
  const bedrromslist = [];
  var bedrromslist1 = ["1", "2", "3", "4+"];
  for (let i = 0; i < bedrromslist1.length; i++) {
    bedrromslist.push(
      <Select.Option key={bedrromslist1[i]}>{bedrromslist1[i]}</Select.Option>
    );
  }
  const bothroomslist = [];
  var bothroomslist1 = ["1", "1.5", "2", "2.5", "3", "3.5", "4", "4.5", "5+"];
  for (let i = 0; i < bothroomslist1.length; i++) {
    bothroomslist.push(
      <Select.Option key={bothroomslist1[i]}>{bothroomslist1[i]}</Select.Option>
    );
  }
  const Close = () => {
    $("#add-property-modal").modal("hide");
    $(
      "#propertyName, #address, #hometype, #bedrooms,#bathrooms, #monthlyrent"
    ).removeClass("is-invalid");
    setState((prev) => ({ ...prev, ShowSuccess: false, imageSizeError: "" }));
  };

  return (
    <div
      className="modal fade"
      id="add-property-modal"
      tabIndex="-1"
      style={{
        opacity: "1",
        transition: "none",
        zIndex: 1060,
      }}
      role="dialog"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="width-70per pd-y-50">
            <div class="only_close">
              <span
                class="pointer close"
                data-dismiss="modal"
                onClick={() => Close()}
              >
                ×
              </span>
            </div>
            <form
              method="post"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              {state.isPropertyError == true ? (
                <h1 className="valid">{state.errorMessage}</h1>
              ) : (
                ""
              )}
              {state.checkExceed == true || state.anywayProceed == true ? (
                ""
              ) : (
                <h6>
                  {" "}
                  {state.propertyanalyticsuniqueId == ""
                    ? "Please fill the rental option details below."
                    : "Edit your rental"}
                </h6>
              )}
              {state.ShowSuccess == true ? (
                <div className="pop-form-controls focus-input-label mt-4">
                  <div className="floating-label">
                    <h6 className="alert alert-success">
                      {state.propertyanalyticsuniqueId == ""
                        ? "Rental added successfully!"
                        : "Rental details updated successfully."}
                    </h6>
                  </div>
                </div>
              ) : state.checkExceed == true || state.anywayProceed == true ? (
                <div className="pop-form-controls focus-input-label mt-4">
                  <div className="floating-label">
                    <h6 className="alert alert-warning">
                      {state.messageDistance}{" "}
                    </h6>
                  </div>
                  <div className="text-center mt-3 mb-3">
                    {state.anywayProceed ? (
                      <Spin />
                    ) : (
                      <button
                        type="submit"
                        className="btn theme-btn"
                        onClick={() => addMyPropertiesProceed("true")}
                      >
                        Proceed<span></span>{" "}
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                <div className="pop-form-controls focus-input-label mt-4">
                  <div className="">
                    <label>Name of Property/Community*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="propertyName"
                      placeholder="Name of property/community"
                      value={state.propertyName || ""}
                      autoComplete="new-password"
                      id="propertyName"
                      onChange={(e) => inputHandler(e)}
                    />
                  </div>
                  <div className="">
                    <label>Address*</label>
                    <input
                      placeholder="Enter Location"
                      autoComplete="off"
                      name="address"
                      className="form-control"
                      type="text"
                      value={state.address || ""}
                      id="address"
                      onChange={(e) => setLocation("address", e)}
                    />
                  </div>
                  <div className="">
                    <label>Please copy the website url to here</label>
                    <input
                      className="form-control"
                      placeholder="If you found a property online,paste the link here"
                      value={state.propertyLink || ""}
                      // autoComplete="off"
                      type="text"
                      name="propertyLink"
                      id="propertyLink"
                      onChange={(e) => inputHandler(e)}
                    />
                  </div>
                  <div className="">
                    <label>Virtual/360 Tour</label>
                    <input
                      className="form-control"
                      placeholder="Virtual/360 Tour link here"
                      value={state.virtual360tour || ""}
                      autoComplete="off"
                      name="virtual360tour"
                      type="text"
                      id="virtual360tour"
                      onChange={(e) => inputHandler(e)}
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="label_cls">Available Date</label>
                      <div className="date-pic-mypro mb-2">
                        <ReactDatePicker
                          selected={state.EditMoveDate}
                          onChange={handleDateChange}
                          // minDate={new Date()}
                          dateFormat="MM/dd/yyyy"
                          placeholderText={"MM/DD/YYYY"}
                          onKeyDown={(e) => e.preventDefault()}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="label_cls">Monthly Rent ($)*</label>
                      <input
                        className="form-control"
                        type="number"
                        name="monthlyrent"
                        id="monthlyrent"
                        min="1"
                        value={state.monthlyrent || ""}
                        autoComplete="off"
                        onChange={(e) => inputHandler(e)}
                      />
                    </div>
                  </div>
                  <label className="label_cls">Amenities</label>
                  <Select
                    className="form-control"
                    id="amenities"
                    mode="multiple"
                    style={{ width: "100%" }}
                    value={
                      state.amenities === null ||
                      state.amenities == "" ||
                      state.amenities == undefined
                        ? []
                        : Array.isArray(state.amenities)
                        ? state.amenities
                        : state.amenities.split(",").map((item) => item.trim())
                    }
                    onChange={(e) => onChangeTypes("amenities", e)}
                  >
                    {amenitieslist}
                  </Select>

                  <div className="row">
                    <div className="col-md-4">
                      <label className="label_cls">Unit Number</label>
                      <input
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        name="unitnumber"
                        value={state.unitnumber}
                        id="unitnumber"
                        onChange={(e) => inputHandler(e)}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="label_cls">Bed Rooms*</label>
                      <div
                        id="bedrooms"
                        className="form-control ant-form-control"
                      >
                        <Select
                          //name="bedrooms"
                          style={{ width: "100%" }}
                          value={state.bedrooms}
                          onChange={(e) => onChangeTypes("bedrooms", e)}
                        >
                          {bedrromslist}
                        </Select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <label className="label_cls">Bath Rooms*</label>
                      <div
                        id="bathrooms"
                        className="form-control ant-form-control"
                      >
                        <Select
                          style={{ width: "100%" }}
                          value={state.bathrooms}
                          onChange={(e) => onChangeTypes("bathrooms", e)}
                        >
                          {bothroomslist}
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <label className="label_cls">Home Type*</label>
                      <div
                        id="hometype"
                        className="form-control ant-form-control"
                      >
                        <Select
                          className="custom-select"
                          value={state.hometype}
                          style={{ width: "100%" }}
                          //options={dataSource}
                          onChange={(e) => onChangeTypes("hometype", e)}
                          //onChange={handleSingleSelect}
                        >
                          {/* {children} */}
                          <option key={0} value=""></option>
                          {dataSource.sort().map((row, index) => {
                            return (
                              <option key={index} value={row}>
                                {row}
                              </option>
                            );
                          })}
                        </Select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <label className="label_cls">Floor</label>
                      <input
                        type="number"
                        className="form-control"
                        autoComplete="off"
                        value={state.flooring}
                        name="flooring"
                        id="flooring"
                        min="1"
                        max="99"
                        size="1"
                        maxLength="2"
                        onChange={(e) => inputHandler(e)}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="label_cls">Square Feet</label>
                      <input
                        type="number"
                        name="sqfeet"
                        className="form-control"
                        autoComplete="off"
                        value={state.sqfeet}
                        id="sqfeet"
                        min="1"
                        onChange={(e) => inputHandler(e)}
                      />
                    </div>
                  </div>
                  <div></div>
                  <label className="label_cls">Notes</label>
                  <textarea
                    className="form-control"
                    rows="4"
                    name="propertyNotes"
                    placeholder="Notes"
                    value={state.propertyNotes}
                    id="propertyNotes"
                    onChange={(e) => inputHandler(e)}
                  />
                  <label className="label_cls">
                    Upload Property Images <span>(only images) </span>{" "}
                  </label>
                  {/* {console.log(state.uploadImages, "resppp")} */}
                  {state.uploadImages && state.uploadImages.length < 11 ? (
                    <div className="custom-file">
                      {state.uploadLoader ? (
                        <Spin />
                      ) : (
                        <div>
                          <input
                            type="file"
                            multiple
                            name="propertyImage"
                            id="propertyImage"
                            accept=".png, .jpeg, .jpg"
                            className="form-control fileupload"
                            onChange={(e) => imageChangeHandler(e)}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="custom-file" style={{ color: "red" }}>
                      Maximum 10 pictures only
                    </div>
                  )}

                  <div className="custom-file-upimg_multy d-flex">
                    {state.uploadImages &&
                      state.uploadImages.map((row, index) => {
                        return (
                          <div key={index} className="custom-file-upimg">
                            <img
                              src={`${state.uploadImages[index].Location}`}
                              alt={`Image ${index}`}
                            />
                            <i
                              className="fa fa-trash"
                              onClick={() => removeimage(row, index)}
                            ></i>
                          </div>
                        );
                      })}
                  </div>
                  <span style={{ color: "red" }}>{state.imageSizeError}</span>
                  <div>
                    {state.propertyanalyticsuniqueId != "" ? (
                      <Radio.Group
                        onChange={onChange}
                        value={state.isinterested}
                      >
                        <Radio value={true}>Active</Radio>
                        <Radio value={false}>Inactive</Radio>
                      </Radio.Group>
                    ) : null}
                  </div>
                  {state.checkExceed == true ? (
                    <div className="text-center mt-3 mb-3">
                      <button
                        type="submit"
                        className="btn theme-btn"
                        onClick={(e) => addMyPropertiesProceed("true")}
                      >
                        Proceed<span></span>{" "}
                      </button>
                    </div>
                  ) : (
                    <div className="text-center mt-3 mb-3">
                      {state.loader ? (
                        <Spin />
                      ) : (
                        <button
                          type="submit"
                          className="btn theme-btn"
                          onClick={() => addMyProperties()}
                        >
                          {state.propertyanalyticsuniqueId != ""
                            ? "SAVE"
                            : "ADD TO MY LIST"}{" "}
                          <span></span>{" "}
                        </button>
                      )}
                    </div>
                  )}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditModal;
