import React, { useEffect, useState } from "react";
import { Select } from "antd";
import ApiServices from "../../../../services/apiServices";
function ScoreCards(props) {
  const [state, setState] = useState({
    city: "California",
    DoesNotSupport: [],
    PartiallySupport: [],
    Support: [],
    Loader: false,
    statename: "",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      try {
        if (props.selectedState.length > 0) {
          setState((prev) => ({
            ...prev,
            city: props.selectedState[0].State,
          }));
          getSupportedData(props.selectedState[0].State);
        } else {
          getSupportedData(state.city);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  const getSupportedData = async (city) => {
    let data = {
      state: city,
    };
    let GetSupportedLawsByState = await ApiServices.GetSupportedLawsByState(
      data
    );
    // console.log(GetSupportedLawsByState, "GetSupportedLawByState");
    if (GetSupportedLawsByState.error == 0) {
      const DoesNotSupport = _.filter(
        GetSupportedLawsByState.message.SupportedLaw,
        function (o) {
          return o.DoesNotSupport != null;
        }
      );
      const PartiallySupport = _.filter(
        GetSupportedLawsByState.message.SupportedLaw,
        function (o) {
          return o.PartiallySupport != null;
        }
      );
      const Support = _.filter(
        GetSupportedLawsByState.message.SupportedLaw,
        function (o) {
          return o.Support != null;
        }
      );
      // console.log(Support, "Support");

      setState((prev) => ({
        ...prev,
        DoesNotSupport: _.orderBy(DoesNotSupport, ["Category"], ["asc"]),
        PartiallySupport: _.orderBy(PartiallySupport, ["Category"], ["asc"]),
        Support: _.orderBy(Support, ["Category"], ["asc"]),
      }));
    }
  };
  let one = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District of Columbia",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Missouri",
    "Mississippi",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];
  const onearray = [];
  for (let i = 0; i < one.length; i++) {
    onearray.push(<Select.Option key={one[i]}>{one[i]}</Select.Option>);
  }
  const onChangeTypes = (event) => {
    let selectedCity = event;
    setState((prev) => ({
      ...prev,
      city: selectedCity,
    }));
    getSupportedData(selectedCity);
  };

  return (
    <div>
      <div className="di_E_main">
        <div className="container">
          <div className="dropdown di_E_dropdown">
            <Select
              showSearch
              className="autoComplete"
              id="city"
              value={state.city}
              onChange={(e) => onChangeTypes(e)}
            >
              {onearray}
            </Select>
          </div>

          <div className="di_E_score_card_gridView">
            <div className="item">
              <h1>Does Not Support</h1>
              {state.DoesNotSupport.length > 0 ? (
                <ul>
                  {state.DoesNotSupport.map((row, index) => {
                    return (
                      <li key={index}>
                        <p>{row.Category}</p>
                        <span>{row.DoesNotSupport}</span>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <p className="nodataFound">
                  There are no laws that are not supported in this state
                </p>
              )}
            </div>

            <div className="item">
              <h1>Partially Support</h1>
              {state.PartiallySupport.length > 0 ? (
                <ul>
                  {state.PartiallySupport.map((row, index) => {
                    return (
                      <li key={index}>
                        <p>{row.Category}</p>
                        <span>{row.PartiallySupport}</span>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <p className="nodataFound">
                  There are no laws that are partially supported in this state
                </p>
              )}
            </div>

            <div className="item">
              <h1>Support</h1>
              {state.Support.length > 0 ? (
                <ul>
                  {state.Support.map((row, index) => {
                    return (
                      <li key={index}>
                        <p>{row.Category}</p>
                        <span>{row.Support}</span>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <p className="nodataFound">
                  There are no laws that are supported in this state
                </p>
              )}
            </div>
          </div>
          <div className="SourceBottom">
            <b>Source:</b>{" "}
            {/* <a
              href={"https://www.hrc.org/resources/state-scorecards"}
              target="_blank"
            >
              https://www.hrc.org/resources/state-scorecards
            </a>{" "}
            Accessed 1 December 2020. */}
            <a
              href={"https://www.hrc.org/resources/state-scorecards "}
              target="_blank"
            >
              https://www.hrc.org/resources/state-scorecards
            </a>{" "}
            Accessed December 2023.
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScoreCards;
