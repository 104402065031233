import React from "react";
import PublicHeader from "../common/publicHeader";
import { Outlet } from "react-router-dom";
import PublicFooter from "../common/publicFooter";

function PublicLayout() {
  return (
    <div className="new-r-n-h light-bg">
      <PublicHeader />
      <Outlet />
      <PublicFooter />
    </div>
  );
}

export default PublicLayout;
