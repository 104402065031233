import React from "react";
import { Table } from "antd";
const TableComponent = ({ reportslist, columns }) => {
  return (
    <div className="reports supply-table">
      <div className="table-responsive">
        <Table
          dataSource={reportslist}
          columns={columns}
          pagination={{
            defaultPageSize: 20,
            locale: { items_per_page: "" },
          }}
        />
      </div>
    </div>
  );
};

export default TableComponent;
