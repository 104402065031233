import React from "react";

function BudgetPopUp(props) {
  const { isModalOpen, closeModal, state, setState } = props;
  const handleKeyPress = (e) => {
    const key = e.key;
    const regex = /[0-9.]/; // Allow numeric digits and a period (dot)

    if (e.keyCode === 8 || regex.test(key)) {
      return;
    }
    e.preventDefault();
  };
  const CalculateMonthlyBudget = async (inputVal) => {
    // console.log(parseInt(inputVal));
    if (inputVal < 1 && inputVal != "" && inputVal == "e" && inputVal == "E") {
      inputVal = "";
    } else if (
      inputVal === "" ||
      (parseInt(inputVal) >= 0 && !isNaN(parseInt(inputVal)))
    ) {
      setState((prev) => ({
        ...prev,
        HouseMonthlyRent: inputVal,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        HouseMonthlyRent: "", // Reset to an empty string if the input is invalid
      }));
    }
  };
  const closeBudgetPop = () => {
    $("document").ready(function () {
      $("#HomeRentalCal").modal("hide");
    });
  };
  return (
    <div
      id="HomeRentalCal"
      className="modal new_modal_pop fade"
      role="dialog"
      style={{ zIndex: 1060 }}
    >
      <div className="modal-dialog">
        <div className="modal-content solopage1cont">
          <div className="width-80per pd-y-50">
            <div className="only_close">
              <span
                className="pointer close"
                data-dismiss="modal"
                onClick={closeBudgetPop}
              >
                &times;{" "}
              </span>
            </div>
            <h6>How much rent should I budget?</h6>

            <div>
              <h4>Annual household income ($)</h4>
              <input
                type="tel"
                placeholder="Enter your Annual income here..."
                className="form-control"
                min="500"
                value={state.HouseMonthlyRent}
                onKeyDown={handleKeyPress}
                onChange={(e) => CalculateMonthlyBudget(e.target.value)}
              />

              <h5>
                We recommend budgeting upto 30% of your monthly income towards
                your rent.{" "}
              </h5>
              {state.HouseMonthlyRent != "" ? (
                <h4 style={{ color: "#1e81bb" }}>
                  i.e $
                  {Number(((30 / 100) * state.HouseMonthlyRent) / 12).toFixed(
                    0
                  )}
                  /Month
                </h4>
              ) : (
                ""
              )}
              <p style={{ paddingTop: "20px" }}>
                * We do not store any of the above entered information. It is
                solely used to calculate & recommend the rent.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BudgetPopUp;
