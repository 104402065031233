import React from "react";

function UtilitiesPopUp() {
  const closeModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#myModalsettingUpUtilities").modal("hide");
      });
    });
  };
  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header border-0">
          <div class="only_close">
            <span
              aria-hidden="true"
              className="pointer close"
              onClick={() => closeModal()}
              data-dismiss="modal"
            >
              ×
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div className="widthModal">
            <div className="item-view">
              <h2>Setting Up Utilities</h2>
              <p>
                Below are links and information about how to set up your
                utilities.
              </p>
            </div>

            <div className="tails-row">
              <div className="tails">
                <div className="item-view">
                  <h2>How to Set Up Utilities</h2>
                  <p>
                    When moving into a new home, you will also have to set up
                    and pay for monthly utilities. The rates and costs of these
                    utilities vary depending on where you live and, in some
                    cases, like electricity, how much you use.{" "}
                  </p>
                  <p>
                    Before even moving into your home, you can contact utility
                    companies, schedule your utilities to start on move-in day,
                    and set up an account. 
                  </p>
                </div>

                <div className="item-view">
                  <h2>Basic home utilities consist of the following: </h2>
                  <ul>
                    <li>Water and Sewage</li>
                    <li>Electricity</li>
                    <li>Trash pick-up</li>
                    <li>Natural gas</li>
                    <li>Cable and Internet</li>
                  </ul>
                  <p>
                    These utilities vary by city, county, and home type. Ask
                    your realtor which utilities you will be responsible for
                    when buying your home and which company you need to contact
                    for each utility. If you are renting, your lease will state
                    which utilities you will be covering in addition to rent.
                    Your landlord or the property management company will know
                    the local providers that you will need to contact to set up
                    utilities.{" "}
                  </p>
                  <p>
                    More details about each type of utility and what it does can
                    be found{" "}
                    <a
                      target="_blank"
                      href="https://www.energybot.com/blog/what-are-utilities-in-a-home-or-business.html"
                    >
                      here
                    </a>
                    .
                  </p>
                </div>
              </div>
              <div className="tails">
                <div className="item-view">
                  <h2>1. Find Your Providers</h2>
                  <p>
                    To find out which utilities you will need to set up and
                    which companies run the utilities in your area, check out
                    your new city’s or county’s websites for utility providers
                    in the area. For the most part, cable and internet are the
                    only utility where there might be more than one option for a
                    provider.
                  </p>
                  <p>
                    Here is a directory of common utility providers in each
                    state:{" "}
                    <a
                      target="_blank"
                      href="http://www.bestenergynews.com/solar/utility_co/utility_companies.php"
                    >
                      bestenergynews.com
                    </a>
                    .
                  </p>
                </div>

                <div className="item-view">
                  <h2>2. Set Up Service</h2>
                  <p>
                    Once you have purchased a home or signed a lease on a rental
                    home, you should reach out to utility providers. Visit their
                    website or call, let them know the address of where you are
                    setting up utilities, and the date you need the service to
                    start on. They may require payment information at this time.
                    Some may even require a deposit or credit check.{" "}
                  </p>
                  <p>
                    Most utility companies have you create an online account
                    where you can view your bill each month, set up automatic
                    payments, and see your usage rates.{" "}
                  </p>
                  <p>
                    You may need your Social Security Number ready when setting
                    up utilities. Depending on your credit history (and if you
                    have a credit card from a foreign country), the utility
                    provider may use your Social Security Number. If you have
                    not already, you can apply for a Social Security Number{" "}
                    <a target="_blank" href="https://www.ssa.gov/ssnumber/">
                      here
                    </a>
                    .
                  </p>
                </div>

                <div className="item-view">
                  <h2>3. Moving Day Check</h2>
                  <p>
                    Double check on moving day that all your utilities are
                    working properly. Turn on the lights, stove, and shower to
                    be sure electricity and water are all working and on.{" "}
                  </p>
                  <p>
                    Don’t forget you will have utility bills every single month.
                    You can pay them online through your account. Here is more
                    info on how to set up online accounts and how to set up
                    automatic payments:{" "}
                    <a
                      target="_blank"
                      href="https://www.aarp.org/money/credit-loans-debt/info-2020/how-to-pay-bills-online.html"
                    >
                      aarp.org
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="modal-footer"></div> */}
      </div>
    </div>
  );
}

export default UtilitiesPopUp;
