import React, { useEffect, useState } from "react";
import moment from "moment";
import ApiServices from "../../../../../services/apiServices";
import ScheduleCard from "./ScheduleCard";
import IterenaryProperties from "./ItineraryProps";

const ConsultantmyItenaries = ({ userUniqueId }) => {
  const [state, setState] = useState({
    events: [],
    showDetails: false,
    isLoading: false,
  });

  async function getScheduleList() {
    updateState({ isLoading: true });
    let getScheduleObject = {
      transfereeuniqueid: userUniqueId,
    };
    let events;
    try {
      let schedules = await ApiServices.GetTransfereeSchedules(
        getScheduleObject
      );
      events = getEvents(schedules.message.scheduleslist);
      updateState({ events });
    } catch (e) {
      console.log(e);
    }
    updateState({ events, isLoading: false });
  }

  function getEvents(scheduleslist) {
    let events = [];
    scheduleslist = scheduleslist.sort(
      (a, b) => new Date(b.scheduledate) - new Date(a.scheduledate)
    );

    scheduleslist.forEach((schedule) => {
      let event = modifySchedule(schedule);
      events.push(event);
    });
    return events;
  }

  function modifySchedule(schedule) {
    let EditMoveDate = moment(schedule.scheduledate).format("YYYY/MM/DD");
    let serviceTitle = "";
    if (schedule.scheduleServicesList.length > 0) {
      _.forEach(schedule.scheduleServicesList, async (service) => {
        serviceTitle = service.ServiceName + "," + serviceTitle;
      });
    }
    let cuurentDate = parseInt(moment(new Date()).format("YYYYMMDD"));
    let startDate = parseInt(moment(EditMoveDate).format("YYYYMMDD"));

    schedule.start = new Date(EditMoveDate + " " + schedule.schedulefromtime);
    schedule.end = new Date(EditMoveDate + " " + schedule.scheduletotime);
    // Calculate the allotted time using absolute difference
    let timeDiff = Math.abs(
      schedule.end.getTime() - schedule.start.getTime()
    );
    let hours = timeDiff / (1000 * 3600); // Extract hours
    // Format as "1:25 Hours"
    schedule.allotedHours = ` ${hours} Hours`;
    schedule.title =
      serviceTitle != ""
        ? serviceTitle.replace(/,\s*$/, "")
        : schedule.servicename;
    schedule.serviceDrodown = schedule.packageserviceuniqueid;
    schedule.pickuplocation = schedule.pickuplocation;
    schedule.serviceNote = schedule.serviceNote;
    schedule.type = "guest";
    schedule.scheduleuniqueId = schedule.scheduleuniqueId;
    schedule.id = schedule.scheduleuniqueId;
    schedule.backgroundColor = cuurentDate <= startDate ? "" : "#808080";

    return schedule;
  }

  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }
  const showDeatils = async (row) => {
    console.log(row, "row123");
    setState((prev) => ({
      ...prev,
      showDetails: false,
      itineraryPropertiesList: [],
      scheduleuniqueId: "",
      overallfeedback: row.overallfeedback,
    }));
    console.log(row);
    if (row.itineraryPropertiesList.length > 0) {
      let transfareeName = row.firstname + " " + row.lastname;
      let consultantName =
        row.consultantfirstname + " " + row.consultantlastname;
      setState((prev) => ({
        ...prev,
        scheduleuniqueId: row.scheduleuniqueId,
        showDetails: true,
        itineraryPropertiesList: row.itineraryPropertiesList,
        phonenumber: row.phonenumber,
        emailId: row.emailid,
        pickupLocation: row.pickuplocation,
        transfareeName: transfareeName,
        consultantphone: row.consultantphone,
        consultantName: consultantName,
        consultantpic: row.consultantprofilepic,
        consultantemail: row.consultantemailid,
        RowResult: row,
      }));
    }
  };
  const showItenaries = async () => {
    await getScheduleList();
    updateState({ showDetails: false });
  };
  useEffect(() => {
    getScheduleList();
  }, []);

  return (
    <div className="main_content trans-anttable">
      {!state.showDetails && (
        <div className="sch_appointments">
          {state.isLoading ? <div className="loading">Loading</div> : null}

          <div className="top mt-5">
            <h1>Scheduled Appointments</h1>
          </div>

          {state.events.length === 0 && !state.isLoading && (
            <div className="top">
              <h1></h1>
              <h2> </h2>
              <h2>No Appointments have been scheduled yet.</h2>
            </div>
          )}

          <div className="container">
            {state.events.length > 0 && !state.isLoading && (
              <div className="sch_appointments_thumbs">
                {state.events.map((schedule, index) => (
                  <ScheduleCard
                    schedule={schedule}
                    key={index}
                    showDeatils={showDeatils}
                    cardNo={state.events.length - index}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      )}
      {state.showDetails && (
        <IterenaryProperties state1={state} showItenaries={showItenaries} />
      )}
    </div>
  );
};

export default ConsultantmyItenaries;
