import React from "react";
import { Link, useLocation } from "react-router-dom";
import * as $ from "jquery";
import { useState, useEffect } from "react";
// import "./footer.css";
import FeedBackPopUp from "./feedBackPopUp";
import { Select } from "antd";

const FooterNochat = (props) => {
  const [state, setState] = useState({
    popUpType: "",
    feedBackPopUp: false,
    openPropetyChatList: false,
    openChatBox: false,
    ChartLitst: [],
    ChatProperty: {},
    sessionData: {},
    chatItineraryPropertyID: 0,
    TextMessage: "",
    propertyChat: [],
    openChatboxPopup: false,
    chatMessage: [],
    chatIdSignalR: "",
    getSessionData: "",
    FromEmail: "",
    transfereeProperties: [],
    responseTransfreeProperties: [],
    countUnread: [],
    isPropertySelected: false,
    TotalUnreadCount: 0,
    countReadSort: true,
    showChatMsg: false,
    generalProperty: {
      id: "0000111122223333",
      apiId: "0000111122223333",
      propertyanalyticsuniqueId: "0000111122223333",
      property_image: "../../public/assets/images/Sharlotte_stamp.png",
      address: "General",
      countUnread: 0,
    },
    profilePic: "",
    openLoader: false,
    PropertiesSortDate: [],
    popoverText: "",
    displayname: "",

    feedbacktype: "General feedback",
    experience: "",
    feeddescription: "",
  });
  const location = useLocation();

  const feedBack = async (type) => {
    console.log(type, "type of the feedback ");
    if (type == "feedback") {
      $("document").ready(function () {
        $("#contact-us-modal-feedback").modal("show");
      });
      setState((prev) => ({
        ...prev,
        popUpType: type,
        feedBackPopUp: true,
        succesMsg: "",
        contactError: "",
        subject: "",
        description: "",
        firstname: "",
        lastname: "",
        phoneNumber: "",
        FromEmail: "",
      }));
    } else if (type == "policy") {
      $(function () {
        $("document").ready(async function () {
          $("#ModalTersm-of-use2").modal("show");
        });
      });
      setState((prev) => ({
        ...prev,
        popUpType: type,
        feedBackPopUp: true,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        popUpType: type,
        feedBackPopUp: true,
      }));
      $(function () {
        $("document").ready(async function () {
          $("#ModalTersm-of-use").modal("show");
        });
      });
    }
  };

  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  const closeFeedBack = () => {
    setState((prev) => ({
      ...prev,
      feedBackPopUp: false,
      succesMsg: "",
      experience: "",
      feedbacktype: "General feedback",
      feeddescription: "",
      FromEmail: "",
    }));
  };

  let backgroundColor = location.pathname == "/city-landing" ? "clrchange" : "";
  let displayone = { propertyname: "General" };
  const children = [];
  var dataSource = [
    "Feature request",
    "General feedback",
    "Report an issue",
    "Support/Account/Billing",
    "UI feedback",
  ];
  const Close = () => {
    $(function () {
      $("document").ready(async function () {
        $("#ModalTersm-of-use").modal("hide");
      });
    });
  };
  const closePopUP = () => {
    $(function () {
      $("document").ready(async function () {
        $("#ModalTersm-of-use2").modal("hide");
      });
    });
  };
  for (let i = 0; i < dataSource.length; i++) {
    children.push(
      <Select.Option key={dataSource[i]}>{dataSource[i]}</Select.Option>
    );
  }
  return (
    <div className="ds-profile-page chat-footer-icon">
      {localStorage.getItem("LoggedInMobile") !== "true" && (
        <div className="footer_main">
          <div className="container ">
            <div
              className={
                "footer_copyright text-center pt-2 pb-1 " + backgroundColor
              }
            >
              <div className="footer_copyright ">
                <ul className="footer-ul-list">
                  <li>
                    <a
                      to="#"
                      data-toggle="modal"
                      data-target="#ModalTersm-of-use2"
                      onClick={() => feedBack("policy")}
                    >
                      Privacy Policy{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      to="#"
                      data-toggle="modal"
                      data-target="#ModalTersm-of-use"
                      style={{ zIndex: 1060 }}
                      onClick={() => feedBack("terms")}
                    >
                      Terms of Use{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      className="feedback-view"
                      href="javascript:void(0)"
                      onClick={() => feedBack("feedback")}
                    >
                      Give feedback
                    </a>
                  </li>
                  <li>
                    <a>Patent Pending</a>
                  </li>
                </ul>
              </div>
              <p style={{ fontSize: "12px" }}>
                Made with <i className="fa fa-heart"></i> in California, USA
              </p>
            </div>
          </div>
        </div>
      )}
      {state.popUpType == "policy" && (
        <div
          className="modal fade "
          id="ModalTersm-of-use2"
          tabIndex="-1"
          style={{
            opacity: "1",
            transition: "none",
            zIndex: 1060,
          }}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="width-80per pd-y-50">
                <div className="only_close">
                  <span
                    className="pointer close"
                    data-dismiss="modal"
                    onClick={() => closePopUP()}
                  >
                    {" "}
                    ×{" "}
                  </span>
                </div>
                <h6>Privacy Policy</h6>
                <div className="ifram-html-view">
                  <iframe
                    src="https://app.termly.io/document/privacy-policy/ba9c43e8-c650-4086-8e0e-dc0d4e603322"
                    title="Terms and Conditions"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {state.popUpType == "feedback" && (
        <FeedBackPopUp
          state={state}
          closeFeedBack={closeFeedBack}
          setState={setState}
        />
      )}
      {state.popUpType == "terms" && (
        <div
          className="modal fade "
          id="ModalTersm-of-use"
          tabIndex="-1"
          style={{
            opacity: "1",
            transition: "none",
            zIndex: 1060,
          }}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="width-80per pd-y-50">
                <div className="only_close">
                  <span
                    className="pointer close"
                    data-dismiss="modal"
                    onClick={() => Close()}
                  >
                    {" "}
                    ×{" "}
                  </span>
                </div>
                <h6>Terms of use</h6>
                <div className="ifram-html-view">
                  <iframe
                    src="https://app.termly.io/document/terms-and-conditions/eedb777d-0087-44e5-8a20-0168e9e1a364"
                    title="Terms and Conditions"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default FooterNochat;
