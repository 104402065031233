// import "./dsProfile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { faEdit, faUser } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../../common/footer/Footer";
import Schedules from "../schedules/Schedules";
import ApiServices from "../../../../services/apiServices";
import LoginStore from "../../../../services/thirdPartyService";
const publicIp = require("react-public-ip");
import DsSearchProperties from "./searchedProperties";
import DsSolo from "./dsSoloProfile";
import DsCities from "./dsCities";
import DsGetSettled from "./dsGetSettled";
import DsMyProperties from "./dsMyProperties";
import DsConsultant from "./dsConsultant";
import SaProperties from "./saProperties";
import MyItenaries from "./myItenaries";
import MessageCenterNew from "./dsMessageCenter";
import NewMessageCenter from "./dsMessageCenternew";
import AppController from "../../../controllers/appController";
import FooterNochat from "../../../common/footer/footerWithoutchat";

const DsProfile = () => {
  console.log("commander");
  var _isMounted = false;
  const [state, setState] = useState({
    serviceslist: [],
    showHome: false,
    showProfile: false,
    showMessage: false,
    showSearchesPge: false,
    showCalendar: false,
    userName: "",
    changePasswordErrorStatus: false,
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    errorMessage: "Password and Confirm Password mismatch",
    nearByCities: [],
    nearByCitiesWithStates: [],
    latitude: "",
    longitude: "",
    cityLoader: false,
    showGetSettled: false,
    subscriptioncode: "",
    showChat: false,
  });
  const checkBox = () => {};
  const showSavedSearchesPage = async () => {
    let activeTabObJ = {
      showHome: false,
      showChat: false,
      showitenaries: false,
      showProfile: false,
      showMessage: false,
      showSearchesPge: true,
      showCalendar: false,
      showGetSettled: false,
    };
    await localStorage.setItem("activeTab", JSON.stringify(activeTabObJ));

    await setState((prevState) => ({
      ...prevState,
      showHome: false,
      showChat: false,
      showitenaries: false,
      showProfile: false,
      showMessage: false,
      showSearchesPge: true,
      showCalendar: false,
      showGetSettled: false,
    }));
    await AppController.logData(
      "My Consultant",
      "Commander",
      location.pathname,
      state.getSessionData.userUniqueId,
      ""
    );
  };
  const showSchedules = async () => {
    let activeTabObJ = {
      showHome: false,
      showChat: false,
      showitenaries: false,
      showProfile: false,
      showMessage: false,
      showSearchesPge: false,
      showCalendar: true,
      showGetSettled: false,
    };
    await localStorage.setItem("activeTab", JSON.stringify(activeTabObJ));
    await setState((prevState) => ({
      ...prevState,
      showHome: false,
      showChat: false,
      showitenaries: false,
      showProfile: false,
      showMessage: false,
      showSearchesPge: false,
      showCalendar: true,
      showGetSettled: false,
    }));
    await AppController.logData(
      "Schedule Appointment",
      "Commander",
      location.pathname,
      state.getSessionData.userUniqueId,
      ""
    );
  };

  const showItenaries = async () => {
    let activeTabObJ = {
      showHome: false,
      showChat: false,
      showitenaries: true,
      showProfile: false,
      showMessage: false,
      showSearchesPge: false,
      showCalendar: false,
      showGetSettled: false,
    };
    await localStorage.setItem("activeTab", JSON.stringify(activeTabObJ));
    await setState((prevState) => ({
      ...prevState,
      showHome: false,
      showChat: false,
      showitenaries: true,
      showProfile: false,
      showMessage: false,
      showSearchesPge: false,
      showCalendar: false,
      showGetSettled: false,
    }));
    await AppController.logData(
      "My Itinerary",
      "Commander",
      location.pathname,
      state.getSessionData.userUniqueId,
      ""
    );
  };
  const showChat = async () => {
    let activeTabObJ = {
      showHome: false,
      showChat: true,
      showitenaries: false,
      showProfile: false,
      showMessage: false,
      showSearchesPge: false,
      showCalendar: false,
      showGetSettled: false,
    };
    await localStorage.setItem("activeTab", JSON.stringify(activeTabObJ));
    await setState((prevState) => ({
      ...prevState,
      showHome: false,
      showChat: true,
      showitenaries: false,
      showProfile: false,
      showMessage: false,
      showSearchesPge: false,
      showCalendar: false,
      showGetSettled: false,
    }));
    await AppController.logData(
      "Message Center",
      "Commander",
      location.pathname,
      state.getSessionData.userUniqueId,
      ""
    );
  };
  useEffect(() => {
    const fetch = async () => {
      const ipv4 = await publicIp.v4().then(
        (ip) => {
          return ip;
        },
        function (error) {
          return "Coudn't find your IP";
        }
      );
      let getSessionData = await LoginStore.getLoginInfo({});
      let getServiceObject = {
        transfereeuniqueid: getSessionData.userUniqueId,
      };
      let services = await ApiServices.GetTransfereeSubscrptionServices(
        getServiceObject
      );

      if (services.error == 0) {
        let serviceslist = services.message.serviceslist;
        await setState((prevState) => ({
          ...prevState,
          serviceslist: serviceslist,
        }));
      }

      setState((prevState) => ({
        ...prevState,
        getSessionData: getSessionData,
        userName:
          getSessionData.userFirstName + " " + getSessionData.userLastName,
      }));
    };
    fetch();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    _isMounted = true;
    const fetch = async () => {
      if (_isMounted) {
        setState((prevState) => ({ ...prevState, cityLoader: true }));
      }

      let subscriptioncode = JSON.parse(
        localStorage.getItem("loginSubscribeDetails")
      ).subscriptioncode;
      await setState((prevState) => ({
        ...prevState,
        subscriptioncode: subscriptioncode,
      }));

      let getSessionData = await LoginStore.getLoginInfo({});
      let activeTabObj = JSON.parse(await localStorage.getItem("activeTab"));
      // console.log(activeTabObj, "activeTab");
      if (activeTabObj != null || activeTabObj != undefined) {
        if (_isMounted) {
          await setState((prevState) => ({
            ...prevState,
            showitenaries: activeTabObj.showitenaries,
            showCalendar: activeTabObj.showCalendar,
            showGetSettled: activeTabObj.showGetSettled,
            showHome: activeTabObj.showHome,
            showMessage: activeTabObj.showMessage,
            showProfile: activeTabObj.showProfile,
            showSearchesPge: activeTabObj.showSearchesPge,
            showChat: activeTabObj.showChat,
          }));
        }
        let ObjectLog = "";
        if (activeTabObj.showitenaries == true) {
          ObjectLog = "My Itinerary";
        }
        if (activeTabObj.showCalendar == true) {
          ObjectLog = "Schedule Appointment";
        }
        if (activeTabObj.showChat == true) {
          ObjectLog = "Message Center";
        }
        if (activeTabObj.showSearchesPge == true) {
          ObjectLog = "My Consultant";
        }
        if (activeTabObj.showHome == true) {
          ObjectLog = "Profile Page";
          await AppController.logData(
            ObjectLog,
            "Profile Page",
            location.pathname,
            getSessionData.userUniqueId,
            ""
          );
        } else {
          await AppController.logData(
            ObjectLog,
            "Commander",
            location.pathname,
            getSessionData.userUniqueId,
            ""
          );
        }
      }
    };
    fetch();
    return () => {
      _isMounted = true;
    };
  }, []);

  const UpdateHeader = async () => {
    let getSessionData = await LoginStore.getLoginInfo({});
    setState((prevState) => ({
      ...prevState,
      userName:
        getSessionData.userFirstName + " " + getSessionData.userLastName,
    }));
  };

  return (
    <div id="outer-container">
      <div className="ds-profile-page">
        <div className="container-fluid">
          <div className="tabs-view">
            <ul className="nav nav-pills">
              {state.subscriptioncode != "ReloNavigator" &&
                state.showHome != true &&
                state.showGetSettled != true && (
                  <li>
                    <a
                      className={
                        state.showSearchesPge ? "nav-link active" : "nav-link"
                      }
                      onClick={showSavedSearchesPage}
                      aria-controls="savedsearches"
                    >
                      My Consultant
                    </a>
                  </li>
                )}
              {state.subscriptioncode != "ReloNavigator" &&
                state.showHome != true &&
                state.showGetSettled != true && (
                  <li>
                    <a
                      className={
                        state.showCalendar ? "nav-link active" : "nav-link"
                      }
                      onClick={showSchedules}
                      aria-controls="scheduleappointment"
                    >
                      Schedule Appointment
                    </a>
                  </li>
                )}
              {state.subscriptioncode != "ReloNavigator" &&
                state.showHome != true &&
                state.showGetSettled != true && (
                  <li>
                    <a
                      className={
                        state.showitenaries ? "nav-link active" : "nav-link"
                      }
                      onClick={showItenaries}
                      aria-controls="scheduleappointment"
                    >
                      My Itinerary
                    </a>
                  </li>
                )}

              {state.subscriptioncode != "ReloNavigator" &&
                state.showHome != true &&
                state.showGetSettled != true && (
                  <li>
                    <a
                      className={
                        state.showChat ? "nav-link active" : "nav-link"
                      }
                      onClick={showChat}
                      aria-controls="scheduleappointment"
                    >
                      Message Center
                    </a>
                  </li>
                )}

              {!state.showCalendar &&
                !state.showChat &&
                !state.showitenaries &&
                state.showSearchesPge != true && (
                  <li>
                    {/* <a className={state.showGetSettled ? 'nav-link active' : 'nav-link'} onClick={showSettled} aria-controls="scheduleappointment">
										Get Settled
					  </a> */}
                  </li>
                )}
            </ul>

            <div className="nav-pillsFixedHeight"></div>
            {/* <h1 className="d-md-none text-center mt-5 pt-3 fs-2">Commander</h1> */}

            <div className="tab-content pb-5 mt-5">
              {state.showProfile && (
                <div className="tab-content tab-pane" role="tabpanel">
                  ..2
                </div>
              )}
              {state.showMessage && (
                <div className="tab-content tab-pane" role="tabpanel">
                  <div className="savedsearch">
                    <div className="profilepic">
                      <i className="fa fa-user">
                        <FontAwesomeIcon icon={faUser} />
                      </i>
                      <h1>{state.userName}</h1>
                    </div>
                    <DsSearchProperties />
                    <DsCities />
                    <DsMyProperties />
                    <SaProperties />
                  </div>
                </div>
              )}
              {state.showSearchesPge && (
                <div id="my-consultant">
                  <DsConsultant
                    showSchedules={showSchedules}
                    showChat={showChat}
                  />
                </div>
              )}
              {state.showGetSettled && (
                <div className="soloprofilepage soloprofilepage_tranfereenww">
                  <DsGetSettled />
                </div>
              )}
              {state.showCalendar && (
                <div>
                  <Schedules
                    serviceslist={state.serviceslist}
                    showChat={showChat}
                    //renderCalendar={renderCalendar}
                  />
                </div>
              )}
              {state.showitenaries && (
                <div>
                  <MyItenaries showSchedules={showSchedules} />
                </div>
              )}
              {state.showChat && (
                <div>
                  <MessageCenterNew />
                  {/* <NewMessageCenter /> */}
                </div>
              )}
            </div>
          </div>

          {/* </div> */}
        </div>
      </div>
      {localStorage.getItem("LoggedInMobile") !== "true" &&
        (state.showChat ? <FooterNochat /> : <Footer />)}
      {/* <Footer /> */}
      {/* {state.showChat ? <FooterWithoutChat /> : <Footer />} */}
    </div>
  );
};
export default DsProfile;
