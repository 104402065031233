import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import RentalCard from "./RentalCard";

const RecommendedRentals = ({
  saPropertiesList,
  saPropertiesListInactive,
  isLoading,
  changeEditMode,
  getMyProperties,
}) => {
  return (
    <>
      {saPropertiesList.length > 0 && !isLoading ? (
        <div className="main_grid">
          {saPropertiesList.map(
            (data, index) =>
              data.isinterested && (
                <RentalCard
                  propertyDetails={data}
                  key={index}
                  changeEditMode={changeEditMode}
                  getMyProperties={getMyProperties}
                />
              )
          )}
        </div>
      ) : null}
      {saPropertiesList.length === 0 && !isLoading ? (
        <div className="main_grid">No data available</div>
      ) : null}
      {isLoading ? (
        <div className="min-height-300 divSpin">
          <Spin />
        </div>
      ) : null}
    </>
  );
};

export default RecommendedRentals;
