import React, { useEffect, useState } from "react";
import apiServices from "../../../../services/apiServices";
import LoginStore from "../../../../services/thirdPartyService";
import AppController from "../../../controllers/appController";
import GoogleMapsPlaces from "../../../../services/mapsPlaces";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken =
  "sk.eyJ1IjoidGVqYWt1bWFyNjIiLCJhIjoiY2x4b2I2M3phMDZ5cDJqcHMxZnVpMjB3bCJ9.uscOlHG0Tdfk3s2LEGX98w";

function ReligiousCenters() {
  const [state, setState] = useState({
    address: "",
    latitude: "",
    longitude: "",
    state: "",
    city: "",
    submitEnable: false,
    pointofin: [],
    activeName: "",
    Loader: true,
    worklatitude: "",
    worklongitude: "",
    workstate: "",
    workcity: "",
    workaddress: "",
    data: {
      latitude: 37.7739852,
      longitude: -122.3912407,
      state_name: "CA",
      city_name: "San Francisco",
      city: "San Francisco",
      address: "185 Channel Street",
    },
    onSelectfromdropdown: true,
    activeMaker: null,
  });
  const [map, setMap] = useState(null);
  const [currentPopup, setCurrentPopup] = useState(null);
  useEffect(() => {
    async function fetchData() {
      try {
        let getSessionData = await LoginStore.getLoginInfo({});
        let userObject = {
          transfereeuniqeuid: getSessionData.userUniqueId,
          istemporary: getSessionData.istemporary,
        };
        let getQuestionList = await apiServices.GetPreferencesList(userObject);
        let userPreferanceObject = {
          transfereeuniqeuid: getSessionData.userUniqueId,
          istemporary: getSessionData.istemporary,
        };
        let userSavedPreferances = await apiServices.GetTransfereePreferences(
          userPreferanceObject
        );
        if (userSavedPreferances.error == 0) {
          let userAnswerPreferanceObject =
            userSavedPreferances.message.preferences;
          if (userAnswerPreferanceObject != undefined) {
            let answersPrefer = {};
            if (userAnswerPreferanceObject.length > 0) {
              _.forEach(userAnswerPreferanceObject, function (answerObject) {
                answersPrefer[answerObject.preferencesmasteruniqueid] =
                  answerObject;
              });
              let sampleAnswerArray = _.filter(
                getQuestionList.message.preferencesList,
                function (o) {
                  return answersPrefer[o.preferenceUId] == undefined;
                }
              );
              if (sampleAnswerArray.length > 0) {
                let obj = sampleAnswerArray[0];
                obj.preferencesanswer = "";
                answersPrefer[sampleAnswerArray[0].preferenceUId] = obj;
              }
              let savedAnswers = answersPrefer;
              updateLocationState(getQuestionList, savedAnswers);

              setState((prev) => ({
                ...prev,
                answersPrefer: answersPrefer,
              }));
            } else {
              _.forEach(
                getQuestionList.message.preferencesList,
                function (answerObject) {
                  let answerDefaultVal = "";
                  if (answerObject.type == "money-range") {
                    answerDefaultVal = "500,1000";
                  }
                  if (answerObject.type == "Time-range") {
                    answerDefaultVal = "15,30";
                  }
                  answerObject.preferencesanswer = answerDefaultVal;
                  answerObject.transfereeuniqeuid = getSessionData.userUniqueId;
                  answerObject.preferencesmasteruniqueid =
                    answerObject.preferenceUId;
                  answerObject.latitude = null;
                  answerObject.longitude = null;
                  answersPrefer[answerObject.preferenceUId] = answerObject;
                }
              );
              setState((prev) => ({
                ...prev,
                answersPrefer: answersPrefer,
              }));
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  const updateLocationState = (getQuestionList, answersPrefer) => {
    // console.log(answersPrefer, "answersPrefer");
    if (getQuestionList.message.preferencesList != undefined) {
      let questionList = getQuestionList.message.preferencesList;
      let workAddressArray = _.filter(questionList, function (o) {
        return o.question == "Work address";
      });
      var data = answersPrefer[workAddressArray[0].preferenceUId];
      // console.log(data, "datadatadata");

      let type = "4bf58dd8d48988d131941735";
      // let type = "spiritual";
      let datamakers = {
        latitude: data.latitude,
        longitude: data.longitude,
        state_name: data.state,
        city_name: data.city,
        city: data.city,
        address: data.preferencesanswer,
        radius: 8000,
      };
      loadSchoolMarkers(datamakers, type);
      // console.log(data, "data");
      setState((prev) => ({
        ...prev,
        latitude: data.latitude,
        longitude: data.longitude,
        state: data.preferencesanswer.split(",")[2],
        city: data.preferencesanswer.split(",")[1],
        address: data.preferencesanswer,
        data: {
          latitude: data.latitude,
          longitude: data.longitude,
          state_name: data.preferencesanswer.split(",")[2],
          city_name: data.preferencesanswer.split(",")[1],
          city: data.preferencesanswer.split(",")[1],
          address: data.preferencesanswer,
        },
      }));
    }
  };
  const setLocation = async (Id, e) => {
    let address = e.target.value;
    setState((prev) => ({
      ...prev,
      [Id]: address,
    }));

    var places = new google.maps.places.Autocomplete(
      document.getElementById(Id),
      { types: ["address"] }
    );
    places.setComponentRestrictions({ country: ["us"] });
    google.maps.event.addListener(places, "place_changed", async () => {
      var place = places.getPlace();
      var placeDetails = {
        locality: "long_name",
        administrative_area_level_1: "short_name",
        country: "long_name",
      };
      // console.log(place.address_components, "place.address_components");

      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (placeDetails[addressType]) {
          var val = place.address_components[i][placeDetails[addressType]];
          placeDetails[addressType] = val;
        }
      }
      let relocatedAddress = place.formatted_address || "";
      // console.log(relocatedAddress, "relocated");
      // setState((prev) => ({ ...prev, onSelectfromdropdown: true }));
      //await _ setState({ address: relocatedAddress,submitEnable:relocatedAddress !== '' || relocatedAddress !== null ? false : true });

      setState((prev) => ({
        ...prev,
        address: relocatedAddress,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        state: placeDetails.administrative_area_level_1,
        city: placeDetails.locality,
        submitEnable:
          relocatedAddress !== "" || relocatedAddress !== null ? false : true,
      }));
      let data = {
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        state_name: placeDetails.administrative_area_level_1,
        city_name: placeDetails.locality,
        city: placeDetails.locality,
        address: relocatedAddress,
        radius: 8000,
      };
      // Call handleSubmitLocation() when a place is selected
      handleLocation(data);
    });
  };
  const insertMap = (markersData, locationdata) => {
    // console.log(locationdata, "locationdata");

    const mapInstance = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/examples/clg45vm7400c501pfubolb0xz",
      center:
        locationdata.latitude !== 0 &&
        locationdata.latitude !== null &&
        locationdata.longitude !== 0 &&
        locationdata.longitude !== null
          ? {
              lat: locationdata.latitude,
              lng: locationdata.longitude,
            }
          : { lat: 37.7739852, lng: -122.3912407 }, // Initial map center [lng, lat]
      zoom: 13, // Initial map zoom level
    });
    // let markersData = mapState.poi;
    setMap(mapInstance);

    const nav = new mapboxgl.NavigationControl({
      showZoom: true,
      // showCompass: true,
    });
    mapInstance.addControl(nav, "bottom-left");

    const geoJsonData = {
      type: "FeatureCollection",
      features: markersData.map((data) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [data.geometry.location.lng, data.geometry.location.lat],
        },
        properties: {
          name: data.name,
          lat: data.geometry.location.lat,
          lng: data.geometry.location.lng,
          vicinity: data.vicinity,
          place_id: data.place_id,
          rating: data.rating,
          price_level: data.price_level,
          icon: data.icon,
        },
      })),
    };

    mapInstance.on("load", () => {
      mapInstance.addSource("markers", {
        type: "geojson",
        data: geoJsonData,
        cluster: true,
        clusterMaxZoom: 14, // Max zoom level to cluster points
        clusterRadius: 50, // Cluster radius in pixels
      });

      // Add the cluster circles to the map
      mapInstance.addLayer({
        id: "clusters",
        type: "circle",
        source: "markers",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#000000", // color for small clusters
            100,
            "#000000", // color for medium clusters
            750,
            "#000000", // color for large clusters
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            20, // radius for small clusters
            100,
            30, // radius for medium clusters
            750,
            40, // radius for large clusters
          ],
        },
      });

      // Add the cluster count label
      mapInstance.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "markers",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Bold"],
          "text-size": 15,
        },
        paint: {
          "text-color": "#FFFFFF", // Set text color to white
        },
      });

      // Add unclustered points as markers with custom icons
      mapInstance.addLayer({
        id: "unclustered-point",
        type: "symbol",
        source: "markers",
        filter: ["!", ["has", "point_count"]],
        layout: {
          "icon-image": "custom-marker", // Use your custom marker image here
          "icon-size": 0.8,
        },
      });

      // Add custom image for the unclustered markers
      mapInstance.loadImage(
        "/assets/images/location_pin_2.png",
        (error, image) => {
          if (error) throw error;
          mapInstance.addImage("custom-marker", image);
        }
      );

      // When a cluster is clicked, zoom into the cluster
      mapInstance.on("click", "clusters", (e) => {
        const features = mapInstance.queryRenderedFeatures(e.point, {
          layers: ["clusters"],
        });
        const clusterId = features[0].properties.cluster_id;
        mapInstance
          .getSource("markers")
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;
            mapInstance.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
      });

      // Show popup for unclustered points
      mapInstance.on("click", "unclustered-point", (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const { name, vicinity, place_id, lat, lng } = e.features[0].properties;

        const popup = new mapboxgl.Popup({ offset: [0, -15] })
          .setLngLat(coordinates)
          .setHTML(
            `
            <h6>${name}</h6>
            <p>${vicinity}</p>
            <a href="https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}" target="_blank">Get Directions</a>
          `
          )
          .addTo(mapInstance);
        setCurrentPopup(popup);
        setState((prev) => ({
          ...prev,
          // activeMaker: data,
          activeName: place_id,
          latitude: coordinates[1],
          longitude: coordinates[0],
        }));
      });

      // Change the cursor to pointer when hovering over a cluster
      mapInstance.on("mouseenter", "clusters", () => {
        mapInstance.getCanvas().style.cursor = "pointer";
      });

      mapInstance.on("mouseleave", "clusters", () => {
        mapInstance.getCanvas().style.cursor = "";
      });
      // Function to load all custom marker images based on typeName
    });

    const propertyMarker = new mapboxgl.Marker({
      element: createpropertyMarkerElement(),
    })
      .setLngLat([locationdata.longitude, locationdata.latitude])
      .addTo(mapInstance);

    if (currentPopup) {
      currentPopup.remove();
    }

    const propertypopup = new mapboxgl.Popup({ offset: [0, -15] }).setHTML(`
      <p>${locationdata.address}</p>
    `);
    propertyMarker.setPopup(propertypopup);

    propertypopup.on("open", () => {
      setCurrentPopup(propertypopup);
      setState((prev) => ({ ...prev, propertyInfo: true }));
    });

    propertypopup.on("close", () => {
      setState((prev) => ({ ...prev, propertyInfo: false }));
    });
    function createCustomMarkerElement() {
      const el = document.createElement("div");
      el.className = "marker";
      el.style.backgroundImage = `url(https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/location_pin_2.svg)`;
      el.style.backgroundSize = "contain";
      el.style.backgroundRepeat = "no-repeat"; // Prevent repeating
      el.style.backgroundPosition = "center"; // Center the background image
      el.style.width = "32px"; // Adjust size as needed
      el.style.height = "32px"; // Adjust size as needed
      return el;
    }
    function createpropertyMarkerElement() {
      const el = document.createElement("div");
      el.className = "marker";
      el.style.backgroundImage = `url(/assets/images/propertyMarker.svg)`;
      el.style.backgroundSize = "contain";
      el.style.backgroundRepeat = "no-repeat"; // Prevent repeating
      el.style.backgroundPosition = "center"; // Center the background image
      el.style.width = "32px"; // Adjust size as needed
      el.style.height = "32px"; // Adjust size as needed
      return el;
    }
  };
  const loadSchoolMarkers = async (datass, typess) => {
    // console.log(datass, "datass");
    try {
      let PoiFourSquare = await AppController.fourSquarData(datass, typess);
      // let PoiFourSquare = await GoogleMapsPlaces.GetNearByPlaces({
      //   latitude: datass.latitude,
      //   longitude: datass.longitude,
      //   type: typess,
      //   keyword: typess,
      //   radius: 4000,
      // });
      const transformedData = PoiFourSquare.map((item) => ({
        business_status: "OPERATIONAL",
        geometry: {
          location: {
            lat: item.geocodes.main.latitude,
            lng: item.geocodes.main.longitude,
          },
          // viewport: {
          //   northeast: {
          //     lat:
          //       item.venue.location.labeledLatLngs[0]?.lat ||
          //       item.venue.location.lat,
          //     lng:
          //       item.venue.location.labeledLatLngs[0]?.lng ||
          //       item.venue.location.lng,
          //   },
          //   southwest: {
          //     lat: item.venue.location.lat,
          //     lng: item.venue.location.lng,
          //   },
          // },
        },
        name: item.name,
        place_id: item.fsq_id,
        vicinity: item.location.formatted_address.toString(),
      }));

      // console.log(transformedData, "PoiFourSquare");
      setState((prev) => ({
        ...prev,
        pointofin: transformedData,
        poiList: [],
        mapRes: [],
        Loader: false,
      }));
      insertMap(transformedData, datass);
      if (state.pointofin.length > 0) {
        setState((prev) => ({
          ...prev,
          Loader: false,
          mapRes: _.concat(state.mapRes, {
            address: datass.address,
            addressCity: "",
            city: datass.city,
            latitude: datass.latitude,
            longitude: datass.longitude,
            name: datass.city,
            images: "",
            zoom: 10,
            iconShowCustom: true,
            isWork: false,
            isProperty: true,
            communityObject: {
              latitude: datass.latitude,
              longitude: datass.longitude,
            },
          }),
        }));
        setState((prev) => ({
          ...prev,
          mapRes: _.concat(state.mapRes, {
            address: state.data.address,
            addressCity: "",
            city: state.data.city,
            latitude: state.data.latitude,
            longitude: state.data.longitude,
            name: state.data.address,
            images: "",
            zoom: 10,
            iconShowCustom: false,
            isWork: true,
            isProperty: true,
            communityObject: {
              latitude: state.data.latitude,
              longitude: state.data.longitude,
            },
          }),
        }));
      }
      return transformedData;
    } catch (error) {
      console.log(error, "error");
    }
  };
  const handleLocation = async (data) => {
    setState((prev) => ({ ...prev, onSelectfromdropdown: true }));
    let type = "4bf58dd8d48988d131941735";
    // let type = "spiritual";

    loadSchoolMarkers(data, type);
  };
  const handleSubmitLocation = async () => {
    setState((prev) => ({
      ...prev,
      onSelectfromdropdown: true,
      activeName: "",
    }));
    let type = "4bf58dd8d48988d131941735";
    // let type = "spiritual";
    let data = {
      latitude: state.latitude,
      longitude: state.longitude,
      state_name: state.state,
      city_name: state.city,
      city: state.city,
      address: state.address,
      radius: 8000,
    };
    loadSchoolMarkers(data, type);
  };
  const handleMarkerInfo = (data) => {
    if (!map) {
      console.error("Map instance is not available.");
      return;
    }
    setState((prev) => ({
      ...prev,
      activeMaker: data,
      activeName: data.place_id,
      data: {
        latitude: data.geometry.location.lat,
        longitude: data.geometry.location.lng,
      },
    }));

    const { name, vicinity, geometry } = data; // Extract relevant info from row
    // Center the map on the coordinates
    map.flyTo({
      center: [geometry.location.lng, geometry.location.lat], // Update coordinates to your data
      zoom: 13, // Adjust zoom level as needed
    });
    // Close the current popup if it's open
    if (currentPopup) {
      currentPopup.remove();
    }

    // Create a popup with the information
    const popup = new mapboxgl.Popup()
      .setLngLat([geometry.location.lng, geometry.location.lat])
      .setHTML(
        ` <h6>${name}</h6>
          <p>${vicinity}</p>
          <a href="https://www.google.com/maps/dir/?api=1&destination=${geometry.location.lat},${geometry.location.lng}" target="_blank">Get Directions</a>
        `
      )
      .addTo(map);
    // Update the currentPopup state
    setCurrentPopup(popup);
  };
  const clearActiveMarker = () => {
    setState((prev) => ({ ...prev, activeMaker: null }));
  };
  function isNumeric(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }
  const propertyDetailsInfo = () => {
    setState((prev) => ({ ...prev, propertyDetails: true }));
  };
  const clearpropertyDetails = () => {
    setState((prev) => ({ ...prev, propertyDetails: false }));
  };
  const heightView = () => {
    $(".toggleArrow").toggleClass("full");
  };
  return (
    <div>
      {state.Loader == true ? <div className="loading">Loading</div> : null}
      {/* <div className="">
        <div className=""> */}
      <div className="religious_mainView ">
        <div className="row m-0">
          <div className="col-md-8  p-0 rounded-start-4 overflow-hidden">
            <div id="mapus" className="b-radius-8 relomap-height">
              {/* <GoogleMap
                zoom={12}
                center={
                  isNumeric(state.latitude) && isNumeric(state.longitude)
                    ? {
                        lat: parseFloat(state.latitude),
                        lng: parseFloat(state.longitude),
                      }
                    : { lat: 37.7739852, lng: -122.3912407 } // Provide default coordinates
                }
                mapContainerStyle={{
                  height: "100%",
                  width: "100%",
                }}
                options={{
                  zoomControl: true, // Enable zoom controls
                  zoomControlOptions: {
                    position: window.google.maps.ControlPosition.LEFT_BOTTOM, // Position of zoom controls
                  },
                }}
              >
                {state.pointofin.length > 0 &&
                  state.pointofin.map((place) => {
                    const lat = place.venue.location.lat;
                    const lng = place.venue.location.lng;
                    const position = { lat, lng };
                    return (
                      <Marker
                        key={place.venue.id}
                        position={position}
                        title={place.venue.name}
                        onClick={() => handleMarkerInfo(place.venue)}
                        icon={{
                          url: "https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/location_pin_2.svg",
                          scaledSize: new window.google.maps.Size(30, 30),
                        }}
                      >
                        {state.activeMaker &&
                        state.activeMaker.id === place.venue.id ? (
                          <InfoWindow onCloseClick={clearActiveMarker}>
                            <>
                              <h6
                                style={{
                                  fontSize: "20px",
                                  textAlign: "center",
                                }}
                              >
                                {place.venue.name}
                              </h6>
                              <div>
                                <p>
                                  {" "}
                                  {place.venue.location.formattedAddress.toString()}
                                </p>
                                <a
                                  href={`https://www.google.com/maps/place/${place.venue.location.formattedAddress}`}
                                  target="_blank"
                                >
                                  Get Directions
                                </a>
                              </div>
                            </>
                          </InfoWindow>
                        ) : null}
                      </Marker>
                    );
                  })}
                {state.data && (
                  <Marker
                    position={{
                      lat: isNumeric(state.latitude)
                        ? parseFloat(state.latitude)
                        : 0,
                      lng: isNumeric(state.longitude)
                        ? parseFloat(state.longitude)
                        : 0,
                    }}
                    onClick={() => propertyDetailsInfo()}
                    icon="/assets/images/propertyMarker.svg"
                  >
                    {state.propertyDetails ? (
                      <InfoWindow onCloseClick={clearpropertyDetails}>
                        <p> {state.address}</p>
                      </InfoWindow>
                    ) : null}
                  </Marker>
                )}
              </GoogleMap> */}
              <div id="map" style={{ width: "100%", height: "100%" }}></div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="map-right-view rounded-end-4">
              <span className="toggleArrow" onClick={() => heightView()}>
                <i className="fa-solid fa-angle-up"></i>
              </span>
              <div className="heightGrid">
                <div className="explore-nearby-view m-0">
                  <form onSubmit={(event) => event.preventDefault()}>
                    <div className="input-group add-on">
                      <input
                        onChange={(e) => setLocation("address", e)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            handleSubmitLocation();
                          }
                        }}
                        className="form-control"
                        value={state.address}
                        placeholder="Search"
                        name="address"
                        id="address"
                        type="text"
                      />
                      <div className="input-group-btn">
                        <button
                          className="btn btn-default"
                          type="button"
                          onClick={() => handleSubmitLocation()}
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="point-tails">
                  <div className="map_rightSide_tails">
                    {state.pointofin.length > 0 ? (
                      state.pointofin.map((row, index) => {
                        return (
                          <div
                            className={
                              state.activeName == row.place_id
                                ? "tail-view active"
                                : "tail-view"
                            }
                            key={index}
                            // onClick={() => mapIndexChange(row, index)}
                            onClick={() => handleMarkerInfo(row)}
                          >
                            {/* {row.photos && (
                              <div className="image">
                                <img
                                  src={
                                    "https://maps.googleapis.com/maps/api/place/photo?maxwidth=1000&photoreference=" +
                                    row.photos[0].photo_reference +
                                    "&key=AIzaSyDiSo4yN97tS6CIfMqJCXItYCwgzVkIJVc"
                                  }
                                />
                              </div>
                            )} */}
                            <ul>
                              <li>
                                <h1>{row.name}</h1>
                              </li>
                              <li>
                                {/* <i
                                  className="fa-solid fa-location-dot"
                                  aria-hidden="true"
                                ></i> */}
                                {/* <i
                                  className="fa fa-map-marker"
                                  aria-hidden="true"
                                ></i> */}
                                <span>{row.vicinity.toString()}</span>
                              </li>
                            </ul>
                          </div>
                        );
                      })
                    ) : (
                      <h3 className="no-content-msg MiddleText">
                        There is no data available at this time.
                      </h3>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    //   </div>
    // </div>
  );
}

export default ReligiousCenters;
