// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal.new_modal_pop .modal-content h6 {
    color: #505050;
    text-align: center;
    font-size: 20px;
    position: relative;
    margin-bottom: 25px;
  }
  .width-80per {
    width: 80% !important;
    margin: auto;
}
.pd-y-50 {
    padding: 50px 0px;
}
  .modal.new_modal_pop .modal-content .only_close {
    height: 30px;
  }

  .modal.new_modal_pop .modal-content {
    background: #ffffff;
    border-radius: 15px;
  }
  
  .modal.new_modal_pop .pointer.close {
    position: absolute;
    top: 10px;
    right: 15px;
  
    line-height: 20px;
   
    
    font-size: 32px !important;
    opacity: 1;
  }
  
  .modal.new_modal_pop .padding-x-50 {
    padding: 0px 50px;
  }
  
  .modal.new_modal_pop .btn.theme-btn {
    
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 14px;
    padding: 8px 15px;
  }
  .modal.new_modal_pop .form-control {
    border: solid 1px rgba(0, 0, 0, 0.2);
    outline: 0px;
    box-shadow: none !important;
    border-radius: 6px;
    margin-bottom: 15px;
  }
`, "",{"version":3,"sources":["webpack://./app/pages/DestinationServicesStatic/pricing/pricing.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,mBAAmB;EACrB;EACA;IACE,qBAAqB;IACrB,YAAY;AAChB;AACA;IACI,iBAAiB;AACrB;EACE;IACE,YAAY;EACd;;EAEA;IACE,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;;IAEX,iBAAiB;;;IAGjB,0BAA0B;IAC1B,UAAU;EACZ;;EAEA;IACE,iBAAiB;EACnB;;EAEA;;IAEE,eAAe;IACf,gBAAgB;IAChB,qBAAqB;IACrB,iBAAiB;IACjB,iBAAiB;EACnB;EACA;IACE,oCAAoC;IACpC,YAAY;IACZ,2BAA2B;IAC3B,kBAAkB;IAClB,mBAAmB;EACrB","sourcesContent":[".modal.new_modal_pop .modal-content h6 {\n    color: #505050;\n    text-align: center;\n    font-size: 20px;\n    position: relative;\n    margin-bottom: 25px;\n  }\n  .width-80per {\n    width: 80% !important;\n    margin: auto;\n}\n.pd-y-50 {\n    padding: 50px 0px;\n}\n  .modal.new_modal_pop .modal-content .only_close {\n    height: 30px;\n  }\n\n  .modal.new_modal_pop .modal-content {\n    background: #ffffff;\n    border-radius: 15px;\n  }\n  \n  .modal.new_modal_pop .pointer.close {\n    position: absolute;\n    top: 10px;\n    right: 15px;\n  \n    line-height: 20px;\n   \n    \n    font-size: 32px !important;\n    opacity: 1;\n  }\n  \n  .modal.new_modal_pop .padding-x-50 {\n    padding: 0px 50px;\n  }\n  \n  .modal.new_modal_pop .btn.theme-btn {\n    \n    font-size: 14px;\n    font-weight: 500;\n    letter-spacing: 0.5px;\n    line-height: 14px;\n    padding: 8px 15px;\n  }\n  .modal.new_modal_pop .form-control {\n    border: solid 1px rgba(0, 0, 0, 0.2);\n    outline: 0px;\n    box-shadow: none !important;\n    border-radius: 6px;\n    margin-bottom: 15px;\n  }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
