// import "../../DestinationServicesStatic/Dsprofile/Dsprofile.css";
import React, { useState, useEffect } from "react";
import loginStore from "../../../../services/thirdPartyService";
import ApiServices from "../../../../services/apiServices";
import axios from "axios";
import { Select, Spin } from "antd";
import IntTelDropDown from "../../../common/internationalPhone/IntTelDropDown";
import authDetails from "../../../common/authDetails";
import Header from "../header and footer/Header";
// import Footer from "../header and footer/Footer";
import Footer from "../../../common/footer/Footer";
import Modal from "antd/es/modal/Modal";
import { faL } from "@fortawesome/free-solid-svg-icons";
const publicIp = require("react-public-ip");
const ConsultantProfile = () => {
  const initialState = {
    updateEdit: false,
    profileLoader: false,
    firstName: "",
    lastName: "",
    middleName: "",
    EmailId: "",
    phoneNumber: "",
    consultantstate: "",
    consultantcity: "",
    userUniqueId: "",
    consultantaddress1: "",
    countrycode: "",
    type: "",
    ErrorUpdateStatus: false,
    UpdateStatusSuccess: false,
    statesList: [],
    stateuid: "",
    citiesLIst: [],
    cityuid: "",
    countryIso: "",
    loaderEdit: false,
    consultantfavcities: [],
    consultantnotes: "",
    profilepic: "",
    zipcode: "",
    userName: "",
    PasswordStatusSuccess: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    errorMsg: "",
    citiesLoader: false,
    favCities: [],
    supplieruid: "",
    notes: "",
    uploadLoader: false,
    zones: [
      {
        name: "(GMT -12:00) Eniwetok, Kwajalein",
        value: "-12:00",
        timezone: "Pacific/Kwajalein",
      },
      {
        name: "(GMT -11:00) Midway Island, Samoa",
        value: "-11:00",
        timezone: "Pacific/Midway",
      },
      {
        name: "(GMT -10:00) Hawaii",
        value: "-10:00",
        timezone: "Pacific/Honolulu",
      },
      {
        name: "(GMT -9:30) Taiohae",
        value: "-09:50",
        timezone: "Pacific/Marquesas",
      },
      {
        name: "(GMT -8:00) Alaska",
        value: "-09:00",
        timezone: "America/Anchorage",
      },
      {
        name: "(GMT -7:00) Pacific Time (US & Canada)",
        value: "-08:00",
        timezone: "America/Los_Angeles",
      },
      {
        name: "(GMT -6:00) Mountain Time (US & Canada)",
        value: "-07:00",
        timezone: "America/Edmonton",
      },
      {
        name: "(GMT -5:00) Central Time (US & Canada), Mexico City",
        value: "-06:00",
        timezone: "America/Chicago",
      },
      {
        name: "(GMT -4:00) Eastern Time (US & Canada), Bogota, Lima",
        value: "-05:00",
        timezone: "America/New_York",
      },
      {
        name: "(GMT -4:00) Caracas",
        value: "-04:50",
        timezone: "America/Caracas",
      },
      {
        name: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz",
        value: "-04:00",
        timezone: "America/La_Paz",
      },
      {
        name: "(GMT -2:30) Newfoundland",
        value: "-03:50",
        timezone: "America/St_Johns",
      },
      {
        name: "(GMT -4:00) Brazil, Buenos Aires, Georgetown",
        value: "-03:00",
        timezone: "America/Guyana",
      },
      {
        name: "(GMT -2:00) Mid-Atlantic",
        value: "-02:00",
        timezone: "Atlantic/South_Georgia",
      },
      {
        name: "(GMT -1:00) Azores, Cape Verde Islands",
        value: "-01:00",
        timezone: "Atlantic/Cape_Verde",
      },
      {
        name: "(GMT+1:00) Western Europe Time, London, Lisbon, Casablanca",
        value: "+00:00",
        timezone: "Europe/Lisbon",
      },
      {
        name: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris",
        value: "+01:00",
        timezone: "Europe/Brussels",
      },
      {
        name: "(GMT +2:00) Kaliningrad, South Africa",
        value: "+02:00",
        timezone: "Europe/Kaliningrad",
      },
      {
        name: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg",
        value: "+03:00",
        timezone: "Asia/Baghdad",
      },
      { name: "(GMT +4:30) Tehran", value: "+03:50", timezone: "Asia/Tehran" },
      {
        name: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi",
        value: "+04:00",
        timezone: "Asia/Dubai",
      },
      { name: "(GMT +4:30) Kabul", value: "+04:50", timezone: "Asia/Kabul" },
      {
        name: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent",
        value: "+05:00",
        timezone: "Asia/Karachi",
      },
      {
        name: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi",
        value: "+05:50",
        timezone: "Asia/Calcutta",
      },
      {
        name: "(GMT +5:45) Kathmandu, Pokhara",
        value: "+05:75",
        timezone: "Asia/Kathmandu",
      },
      {
        name: "(GMT +6:00) Almaty, Dhaka, Colombo",
        value: "+06:00",
        timezone: "Asia/Almaty",
      },
      {
        name: "(GMT +6:30) Yangon, Mandalay",
        value: "+06:50",
        timezone: "Asia/Yangon",
      },
      {
        name: "(GMT +7:00) Bangkok, Hanoi, Jakarta",
        value: "+07:00",
        timezone: "Asia/Bangkok",
      },
      {
        name: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong",
        value: "+08:00",
        timezone: "Asia/Singapore",
      },
      {
        name: "(GMT +8:45) Eucla",
        value: "+08:75",
        timezone: "Australia/Eucla",
      },
      {
        name: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk",
        value: "+09:00",
        timezone: "Asia/Tokyo",
      },
      {
        name: "(GMT +9:30) Adelaide, Darwin",
        value: "+09:50",
        timezone: "Australia/Adelaide",
      },
      {
        name: "(GMT +10:00) Eastern Australia, Guam, Vladivostok",
        value: "+10:00",
        timezone: "Pacific/Guam",
      },
      {
        name: "(GMT +10:30) Lord Howe Island",
        value: "+10:50",
        timezone: "Australia/Lord_Howe",
      },
      {
        name: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia",
        value: "+11:00",
        timezone: "Pacific/Guadalcanal",
      },
      {
        name: "(GMT +11:00) Norfolk Island",
        value: "+11:50",
        timezone: "Pacific/Norfolk",
      },
      {
        name: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka",
        value: "+12:00",
        timezone: "Pacific/Auckland",
      },
    ],
    filterzones: [],
    timezone: "",
  };

  const [state, setState] = useState(initialState);
  const [displayData, setDisplaydata] = useState(initialState);
  useEffect(() => {
    getUsers();
  }, []);

  async function getUsers() {
    await updateState({ profileLoader: true });
    let getSessionData = await loginStore.getLoginInfo({});
    console.log("getSessionData", getSessionData);
    let cities = [];
    if (getSessionData.consultantstateuid != "") {
      _.forEach(getSessionData.consultantfavcities, function (city) {
        console.log(city.cityuid, "citiess");

        if (city.cityuid != undefined) {
          cities.push(city.cityuid);
        } else {
          cities = getSessionData.consultantfavcities;
        }
      });
      console.log(cities, "citiess");
      await updateState({
        stateuid: getSessionData.consultantstateuid,
        cityuid: "",
      });
      await getCities();
    }
    await updateState({
      firstName: getSessionData.userFirstName,
      lastName: getSessionData.userLastName,
      middleName: getSessionData.userMiddleName,
      EmailId: getSessionData.EmailId,
      phoneNumber: getSessionData.PhoneNumber,
      consultantstate: getSessionData.consultantstate,
      consultantcity: getSessionData.consultantcity,
      consultantaddress1: getSessionData.consultantaddress1,
      userUniqueId: getSessionData.userUniqueId,
      countrycode: getSessionData.countrycode,
      type: getSessionData.type,
      consultantfavcities: getSessionData.consultantfavcities,
      consultantnotes: getSessionData.consultantnotes,
      profilepic: getSessionData.profilepic,
      zipcode: getSessionData.consultantzipcode,
      stateuid: getSessionData.consultantstateuid,
      cityuid: getSessionData.consultantcityuid,
      supplieruid: getSessionData.supplieruid,
      favCities: cities,
      notes: getSessionData.consultantnotes,
      timezone: getSessionData.timezone,
    });
    await setDisplaydata((prev)=>({...prev,
      firstName: getSessionData.userFirstName,
      lastName: getSessionData.userLastName,
      middleName: getSessionData.userMiddleName,
      EmailId: getSessionData.EmailId,
      phoneNumber: getSessionData.PhoneNumber,
      consultantstate: getSessionData.consultantstate,
      consultantcity: getSessionData.consultantcity,
      consultantaddress1: getSessionData.consultantaddress1,
      userUniqueId: getSessionData.userUniqueId,
      countrycode: getSessionData.countrycode,
      type: getSessionData.type,
      consultantfavcities: getSessionData.consultantfavcities,
      consultantnotes: getSessionData.consultantnotes,
      profilepic: getSessionData.profilepic,
      zipcode: getSessionData.consultantzipcode,
      stateuid: getSessionData.consultantstateuid,
      cityuid: getSessionData.consultantcityuid,
      supplieruid: getSessionData.supplieruid,
      favCities: cities,
      notes: getSessionData.consultantnotes,
      timezone: getSessionData.timezone,

    }))
    updateState({ profileLoader: false });
  }

  const setLocation = async (Id, e) => {
    let LocationObje = {};
    LocationObje[Id] = e.target.value;
    await updateState(LocationObje);

    var places = new google.maps.places.Autocomplete(
      document.getElementById(Id)
    );
    google.maps.event.addListener(places, "place_changed", async function () {
      var place = places.getPlace();
      let relocatedAddress = place.formatted_address;
      Id == "consultantaddress1"
        ? updateState({ consultantaddress1: relocatedAddress })
        : updateState({ worklocationaddress2: relocatedAddress });
    });
  };

  const handleSearching = async (value) => {
    const { zones } = state;
    const filterzones = zones.filter((o) =>
      o.name.toLowerCase().includes(value.toLowerCase())
    );
    updateState({ filterzones: filterzones });
    for (let i = 0; i < zones.length; i++) {
      if (zones[i].name.toLowerCase() == value.toLowerCase()) {
        await updateState({ timezone: value });
      } else {
        updateState({ timezone: "" });
      }
    }
  };

  const handleChangeing = async (value) => {
    await updateState({ timezone: value });
  };

  async function inputHandler(inputName, e) {
    const inputVal = e;
    const inputObject = { [inputName]: inputVal };

    if (inputName === "" && inputVal < 1 && inputVal !== "") {
      inputObject[inputName] = "";
      $("#" + inputName).addClass("is-invalid");
    } else if (!inputVal.trim()) {
      $("#" + inputName).addClass("is-invalid");
    } else {
      $("#" + inputName).removeClass("is-invalid");
    }

    updateState(inputObject);
  }

  async function updateState(values) {
    await setState((prev) => ({ ...prev, ...values }));
  }

  const getCities = async () => {
    await updateState({ citiesLoader: true, citiesLIst: [], favCities: [] });
    let CitiesReq = {
      stateuid: state.stateuid,
    };
    let citiesResult = await ApiServices.GetCities(CitiesReq);
    if (citiesResult.error == 0) {
      await updateState({
        citiesLIst: citiesResult.message.citieslist,
        citiesLoader: false,
      });
      console.log(state.citiesLIst, "citiesResult");
    }
  };

  const UpdateHeader = async () => {
    let getSessionData = await loginStore.getLoginInfo({});
    updateState({
      userName:
        getSessionData.userFirstName + " " + getSessionData.userLastName,
    });
  };
  async function editProfile() {
    getUsers()
    // updateState({ updateEdit: true });
    setTimeout(()=>{
      $("document").ready(function () {
        $("#edit-profile").modal("show");
      });
    },2000)
   
  }

  async function onClose() {
    updateState({ updateEdit: false, UpdateStatusSuccess: false });
    $("document").ready(function () {
      $("#edit-profile").modal("hide");
    });
    // updateState(initialState);
  }

  const onImageChangeHandler = async (event) => {
    //console.log(event.target.files,'files');
    let files = event.target.files;
    if (files.length > 5) {
      await updateState({
        uploadImageError: "Only 5 images can be uploaded at a time.",
      });
      $("#optionimages").addClass("is-invalid");
      //event.target.value = null ;
    } else {
      let err = "";
      const types = ["image/png", "image/jpeg", "image/gif"];
      const data = new FormData();

      for (var x = 0; x < files.length; x++) {
        data.append("file", files[x]);
        // compare file type find doesn't matach
        if (types.every((type) => files[x].type !== type)) {
          // create error message and assign to container
          err += "Only supported png, jpeg, gif files format supported.";
        }
      }
      if (err !== "") {
        //console.log(files,"no");
        //event.target.value = null ;

        await updateState({ uploadImageError: err });
        $("#propertyImage").addClass("is-invalid");
      } else {
        //console.log(files,"yes");

        await updateState({ uploadImageError: "", uploadLoader: true });
        $("#propertyImage").removeClass("is-invalid");

        axios
          .post(`/uploadUserInfo`, data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            let data = [];
            _.forEach(response.data, async (response) => {
              console.log(response.Location, "response.Location");
              updateState({ profilepic: response.Location });
            });
            updateState({
              uploadImageError: "Successfully uploaded.",
              uploadLoader: false,
            });
          })
          .catch((error) => {
            updateState({ uploadLoader: false });
          });
      }
    }
  };

  async function UpdateConsultant(event) {
    event.preventDefault();
    await updateState({ loaderEdit: true, UpdateStatusSuccess: false });
    let errorUpdate = 0;

    if (state.firstName.trim() == "") {
      $("#firstName").addClass("is-invalid");
      await updateState({ loaderEdit: false });
      errorUpdate++;
    } else {
      $("#firstName").removeClass("is-invalid");
    }
    if (state.lastName.trim() == "") {
      await updateState({ loaderEdit: false });
      $("#lastName").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#lastName").removeClass("is-invalid");
    }
    if (
      /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(
        state.phoneNumber
      ) !== true
    ) {
      $("#phoneNumber").addClass("is-invalid");
      await updateState({ loaderEdit: false });
      errorUpdate++;
    } else {
      $("#phoneNumber").removeClass("is-invalid");
    }
    if (state.countrycode.trim() == "") {
      $("#countrycode").addClass("is-invalid");
      errorUpdate++;
      await updateState({ loaderEdit: false });
    } else {
      $("#countrycode").removeClass("is-invalid");
    }

    if (
      state.consultantaddress1.trim() == "" ||
      state.consultantaddress1 == null
    ) {
      $("#consultantaddress1").addClass("is-invalid");
      await updateState({ loaderEdit: false });
      errorUpdate++;
    } else {
      $("#consultantaddress1").removeClass("is-invalid");
    }

    if (
      state.zipcode.trim() == "" ||
      state.zipcode == null ||
      state.zipcode.trim().length < 4
    ) {
      await updateState({ loaderEdit: false });
      $("#zipcode").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#zipcode").removeClass("is-invalid");
    }

    if (errorUpdate == 0) {
      let updateProfileObj = {
        consultantuid: state.userUniqueId,
        firstname: state.firstName,
        lastname: state.lastName,
        middlename: state.middleName,
        emailid: state.EmailId,
        countrycode: state.countrycode,
        phonenumber: state.phoneNumber,
        employeecode: "",
        address: state.consultantaddress1,
        cityuid: state.cityuid,
        stateuid: state.stateuid,
        zipcode: state.zipcode,
        favcities: state.favCities,
        notes: state.consultantnotes,
        profilepic: state.profilepic,
        zipcode: state.zipcode,
        timezone: state.timezone,
        supplieruid: state.supplieruid,
        isdelete: false,
        notes: state.notes,
      };
      let updateProfileResult = await ApiServices.UpdateConsultantInfo(
        updateProfileObj
      );

      if (updateProfileResult.error == 0) {
        let userInfo = {
          userFirstName: state.firstName,
          userId: state.EmailId,
          userLastName: state.lastName,
          userMiddleName: state.middleName,
          EmailId: state.EmailId,
          worklocationaddress1: state.worklocationaddress1,
          worklocationaddress2: state.worklocationaddress2,
          zipcode: state.zipcode,
          timezone: state.timezone,
          PhoneNumber: state.phoneNumber,
          userUniqueId: state.userUniqueId,
          consultantUId: state.consultantUId,
          countrycode: state.countrycode,
          type: state.type,
          consultantaddress1: state.consultantaddress1,
          consultantemail: state.EmailId,
          consultantnotes: state.notes,
          consultantprofilepic: state.profilepic,
          profilepic: state.profilepic,
          consultantfavcities: state.favCities,
          consultantzipcode: state.zipcode,
          consultantstateuid: state.stateuid,
          consultantcityuid: state.cityuid,
          consultantstate: state.consultantstate,
          consultantcity: state.consultantcity,
          supplieruid: state.supplieruid,
        };
        await loginStore.LoginDetailsStore(userInfo);
        let getSessionData = await loginStore.getLoginInfo({});
        getUsers();
        await updateState({
          ErrorUpdateStatus: false,
          loaderEdit: false,
          UpdateStatusSuccess: true,
        });
        UpdateHeader();
      } else if (updateProfileResult.message) {
        await updateState({
          ErrorUpdateStatus: true,
          errorMsg: updateProfileResult.message.status,
          loaderEdit: false,
        });
      }
    } else {
      await updateState({ loaderEdit: false });
    }
  }
  const handleKeyPress = (e) => {
    const key = e.key;
    const regex = /[0-9.]/; // Allow numeric digits and a period (dot)

    if (e.keyCode === 8 || regex.test(key)) {
      return;
    }
    e.preventDefault();
  };
  function handleCountryCodeChange(code) {
    updateState({ countrycode: code }); 
  }
  let dataaa = state.filterzones.length > 0 ? state.filterzones : state.zones;
  const zonelists = dataaa.map((d) => (
    <Select.Option key={d.name}>{d.name}</Select.Option>
  ));
  return (
    <div className="">
      <div className="ds-solo-profile mt-5 pt-5">
        <div>
          {state.profileLoader == true ? (
            <div className="loading">Loading</div>
          ) : null}
          {/* <div className="profile-main-div">
            <div className="pic">
              <img src={state.profilepic} width="100%" alt="" />
            </div>
            <div className="content">
              <a className="edit-icon" onClick={editProfile}>
                Edit
              </a>
              <div className="">
                <h1>
                  {state.firstName} {state.middleName} {state.lastName}
                </h1>

                <ul>
                  <li>
                    <b>Email</b>
                    <span>{state.EmailId}</span>
                  </li>
                  <li>
                    <b>Phone</b>
                    <span>{state.phoneNumber}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="profile-main-div notable">
            <div className="pic">
              <img
                src={
                  displayData.profilepic != ""
                    ? displayData.profilepic
                    : "../assets/images/profile-pic.svg"
                }
                width="100%"
                alt=""
              />
              <span>
                <h1>
                  {displayData.firstName} {displayData.middleName} {displayData.lastName}
                </h1>
                <h6>{displayData.EmailId}</h6>
                <h6>+{displayData.countrycode}{" "}{displayData.phoneNumber}</h6>
                <button
                  className="btn theme-btn rounded-btn  mt-3 btn-dark"
                  onClick={editProfile}
                >
                  Update
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        id="edit-profile"
        className="modal fade"
        role="dialog"
        data-backdrop="static"
        style={{ zIndex: 1060 }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="width-80per pd-y-50">
              <div className="only_close">
                <span
                  className="pointer close"
                  data-dismiss="modal"
                  onClick={onClose}
                >
                  &times;
                </span>
              </div>
             {state.UpdateStatusSuccess ?"": <h6>Edit Profile </h6>}

              <div className="focus-input-label mt-3 padding-x-50">
                {state.UpdateStatusSuccess == true ? (
                  <h6 className="alert alert-success">
                    Profile Updated Successfully
                  </h6>
                ) : (
                  ""
                )}
                {state.ErrorUpdateStatus == true ? (
                  <h6 className="alert alert-danger">{this.state.errorMsg}</h6>
                ) : (
                  ""
                )}

                {state.UpdateStatusSuccess == false && (
                  <form
                    method="post"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                    id="EditForm"
                  >
                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="floating-label">
                          <input
                            type="text"
                            placeholder="First name"
                            autoComplete="new-password"
                            className="form-control"
                            value={state.firstName}
                            id="firstName"
                            onChange={(e) =>
                              inputHandler("firstName", e.target.value)
                            }
                          />
                          <label>First name</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="floating-label">
                          <input
                            type="text"
                            placeholder=" Last name"
                            autoComplete="new-password"
                            className="form-control"
                            value={state.lastName}
                            id="lastName"
                            onChange={(e) =>
                              inputHandler("lastName", e.target.value)
                            }
                          />
                          <label>Last name</label>
                        </div>
                      </div>
                    </div>

                    <div className="floating-label">
                      <input
                        type="email"
                        placeholder="Email address"
                        autoComplete="new-password"
                        className="form-control"
                        disabled
                        id="emailid"
                        value={state.EmailId}
                        onChange={(e) =>
                          inputHandler("EmailId", e.target.value)
                        }
                      />
                      <label>Email address</label>
                    </div>
                    <div className="form-row">
                      <div className="col-lg-6 phonefieldWidth">
                        <div className="">
                          <label>Phone Number</label>
                          <div className="phone-control ">
                            <div className="phone-input">
                              <IntTelDropDown
                                handleCountryCodeChange={
                                  handleCountryCodeChange
                                }
                                countrycode={state.countrycode}
                              />
                              <input
                                type="tel"
                                maxLength={10}
                                placeholder="Phone Number "
                                autoComplete="new-password"
                                className="form-control pl-5"
                                id="phoneNumber"
                                value={state.phoneNumber}
                                onKeyDown={handleKeyPress}
                                onChange={(e) =>
                                  inputHandler("phoneNumber", e.target.value)
                                }
                                onBlur={(e) =>
                                  inputHandler("phoneNumber", e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 phonefieldWidth">
                        <div className="floating-label">
                          <input
                            type="text"
                            placeholder="Consultant Address1"
                            autoComplete="new-password"
                            className="form-control"
                            id="consultantaddress1"
                            value={state.consultantaddress1}
                            onChange={(e) =>
                              setLocation("consultantaddress1", e)
                            }
                          />
                          <label>Consultant Address1</label>
                        </div>
                      </div>
                    </div>

                    <div className="floating-label form-group">
                      <label className="s_label">Time Zone</label>

                      <Select
                        id="time-Zone"
                        showSearch
                        value={state.timezone}
                        defaultActiveFirstOption={false}
                        //showArrow={false}
                        filterOption={false}
                        onSearch={handleSearching}
                        onChange={handleChangeing}
                        notFoundContent={null}
                        size="default"
                      >
                        {zonelists}
                      </Select>
                    </div>
                    <div className="floating-label">
                      <input
                        type="text"
                        placeholder="Zip Code"
                        className="form-control"
                        autoComplete="new-password"
                        id="zipcode"
                        value={state.zipcode}
                        maxLength={6}
                        minLength={4}
                        onChange={(e) =>
                          inputHandler("zipcode", e.target.value)
                        }
                      />
                      <label>Zip Code</label>
                    </div>
                    <div className="floating-label form-group">
                      <label className="top_lable-focus">About me</label>

                      <textarea
                        className="form-control"
                        rows="4"
                        placeholder="About me "
                        value={state.notes}
                        id="notes"
                        onChange={(e) => inputHandler("notes", e.target.value)}
                      />
                    </div>
                    <label className="label_cls">Upload Picture</label>
                    <div className="custom-file">
                      {state.uploadLoader == false && (
                        <input
                          type="file"
                          onChange={(e) => onImageChangeHandler(e)}
                          id="propertyImage"
                          className="form-control fileupload"
                        />
                      )}
                    </div>
                    <div className="custom-file-upimg_multy">
                      {state.uploadLoader == false ? (
                        <div className="custom-file-upimg">
                          <img src={state.profilepic} />
                        </div>
                      ) : (
                        <div className="custom-file-upimg text-center">
                          <Spin />
                        </div>
                      )}
                    </div>
                    <div className="form-group text-center ">
                      {state.loaderEdit == true ? (
                        <Spin />
                      ) : (
                        <button
                          type="button"
                          className="btn theme-btn rounded-btn  mt-3"
                          onClick={(event) => UpdateConsultant(event)}
                        >
                          {" "}
                          Submit{" "}
                        </button>
                      )}
                    </div>
                  </form>
                )}
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ConsultantProfile;
