import React, { useEffect, useState } from "react";
// import "./citysLanding.css";
import LoginStore from "../../../../services/thirdPartyService";
import { useNavigate, useLocation } from "react-router-dom";
import WalkScoreCity from "../../../common/walkScoreCity";
import WalkScore from "../../../common/walkscore";
import apiServices from "../../../../services/apiServices";
import _ from "lodash";
import { Spin, Modal, Select } from "antd";
import AppController from "../../../controllers/appController";
import * as $ from "jquery";
import axios from "axios";
import Weather from "../../../common/weather";
import PoiSearch from "./poinOfInterestsSearch";
import PublicTransit from "./publicTransit";
// import SchoolsSearch from "./schoolsSearch";
import SchoolsSearch2 from "./schoolsSearch2";
// import MapViewExplore from "./mapView";
import Mapview from "../../../common/mapview";
import Header from "../../../common/header/Header";
import Footer from "../../../common/footer/Footer";
import WellKnowPopUp from "./modalPopUp/wellKnowPopUp";
import WalkScorePopUp from "./modalPopUp/walkScorePopUp";
import SchoolSearchNew from "./schoolSearchNew";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import SchoolSearchMapBox from "./schoolSearchMapbox";
// import MapViewFunctional from "../../../common/mapViewFunc";

function CityLanding() {
  const [state, setState] = useState({
    startPosition: 0,
    tabItems: "",
    mainMenu: [
      { name: "Overview", id: "overview" },
      { name: "Points of Interest", id: "poi" },
      { name: "Commute", id: "transit" },
      { name: "Schools", id: "schools" },
      { name: "Explore the Area", id: "explore" },
    ],
    items: [
      {
        name: "Overview",
        type: "overview",
      },
      {
        name: "Points of Interest",
        type: "poi",
      },
      {
        name: "Commute",
        type: "transit",
      },
      {
        name: "Explore the Area",
        type: "explore",
      },
    ],
    scoreModal: false,
    imageUrl: "",
    cityData: "",
    activitePopUp: false,
    popUPtype: "",
    ViewStatus: "overview",
    data: {
      latitude: "",
      longitude: "",
      state_name: "CA",
      city_name: "San Francisco",
      city: "San Francisco",
      address: "185 Channel Street",
    },
    communityObject: {
      latitude: 37.7739852,
      longitude: -122.3912407,
    },
    communityName: "",
    greatSchools: [],
    poi: [],
    poiCategories: [],
    isSuccessPoi: false,
    preferCities: [],
    prefernearByCitiesWithStates: [],
    nearByCities: [],
    nearByCitiesWithStates: [],
    citiesLoader: false,
    selectedCity: "",
    cityLatitude: "",
    cityLongitude: "",
    demographics: "",
    cityHighlights: "",
    cityImage: "/assets/images/dscityLbanner.png",
    nearByNeighbourHoods: [],
    neighbourhoodLoader: false,
    ageLivingPelople: [],
    HomeValueForecast: [],
    crimeAssult: [],
    commuteReport: [],
    rentBedRooms: [],
    latitude: 37.7739852,
    longitude: -122.3912407,
    lines: "",
    travelMapLoading: false,
    cityName: "",
    barValue: [],
    barlabels: [],
    cityxlsData: [],
    bedroomData: [],
    bedroomLabels: [],
    singleFamilyData: [],
    singleFamilyLabels: [],
    allMultiHomesData: [],
    allMultiHomesLabels: [],
    multiHomeRentalsData: [],
    multiHomeRentalsLabels: [],
    homeValues: [
      "1bedroom",
      "2bedroom",
      "3bedroom",
      "4bedroom",
      "5BedroomOrMore",
    ],
    selectedHomeValue: "1bedroom",
    isChanged: false,
    isBedRoom: true,
    rentalLabels: [],
    rentalData: [],
    homeLoader: false,
    rentalValue: "AllHomesPlusMultifamily",
    allHomesValue: "",
    ispoi: false,
    explore: false,
    Restaurants: [],
    isCityLanding: true,
    flightPlanCoordinates: [],
    rentalDataShow: false,
    showWalkScore: false,
    showWalkScore: [],
    image: "",
    greatSchoolsOview: {},
    publicSchview: [],
    SchoolDisplay: false,
    cityinfo: "",
    WellKnownAttractions: "",
    ProfessionalTeams: "",
    WalkScore: "",
    BikeScore: "",
    TransitScore: "",
    schoolOverviewLoder: true,
    onSelectfromdropdown: true,
    cityaddress: false,
    // weather: "",
    // weather_current: "",
    // weather_currentCityDetails: "",
    types: [
      {
        name: "Restaurants",
        type: "restaurant",
        faicon: "fa fa-cutlery",
        icon: "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/restarent.png",
      },
      {
        name: "Bars",
        type: "bar",
        faicon: "fa fa-glass",
        icon: "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/bars.png",
      },
      {
        name: "Groceries",
        type: "supermarket",
        faicon: "fa fa-shopping-bag",
        icon: "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/groceries.png",
      },
      {
        name: "Parks",
        type: "park",
        faicon: "fa fa-tree",
        icon: "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/parks.png",
      },
      {
        name: "Shopping",
        type: "shopping_mall",
        faicon: "fa fa-shopping-cart",
        icon: "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/shopping.png",
      },
      {
        name: "Entertainment",
        type: "night_club",
        faicon: "fa fa-film",
        icon: "https://s3-us-west-1.amazonaws.com/suiteam-website/landing-images/mapview/entertainment.png",
      },
    ],
  });
  // const navigate = useNavigate();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const script = document.createElement("script");
    script.src =
      "https://apicdn.walkscore.com/api/v1/traveltime_widget/js?wsid=8daf3ec7b708129023cb6ebcf8e99b56";
    script.async = true;
    document.body.appendChild(script);
    tab();
    const fetchData = async () => {
      try {
        let cityDetails = JSON.parse(await localStorage.getItem("cDetails"));
        // console.log(cityDetails, "cityDetails");
        if (cityDetails == null || cityDetails == undefined) {
          navigate("/citylist");
        } else {
          let loglatData = {
            latitude: Number(cityDetails.latitude),
            longitude: Number(cityDetails.longitude),
            state_name: cityDetails.state,
            city_name: cityDetails.city,
            city: cityDetails.city,
            address: cityDetails.city,
          };
          setState((prev) => ({
            ...prev,
            citiesLoader: true,
            cityName: cityDetails.city,
            address: cityDetails.cityaddress == true ? cityDetails.address : "",
            city: cityDetails.city,
            state: cityDetails.state,
            cityaddress: cityDetails.cityaddress == true ? true : false,
            selectedCity: cityDetails.city,
            data: {
              latitude: Number(cityDetails.latitude),
              longitude: Number(cityDetails.longitude),
              state_name: cityDetails.state,
              city_name: cityDetails.city,
              city: cityDetails.city,
              address: cityDetails.city,
            },
            communityObject: {
              latitude: Number(cityDetails.latitude),
              longitude: Number(cityDetails.longitude),
            },
          }));
          let communityData = {
            latitude: Number(cityDetails.latitude),
            longitude: Number(cityDetails.longitude),
            state_name: cityDetails.state,
            city_name: cityDetails.city,
            city: cityDetails.city,
          };
          let lines = await AppController.busrailslines(communityData);
          setState((prev) => ({
            ...prev,
            lines: lines,
            latitude: communityData.latitude,
            longitude: communityData.longitude,
          }));
          getSchoolData(loglatData);
          getNearByCityList(loglatData);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getWorkAdress();
    fetchData();
    getScoreDetails();
    getAnswersPreferData();
  }, [state.ViewStatus]);
  useEffect(() => {
    const fetch = async () => {
      const isFromCommute = location.state && location.state.fromCommute;
      if (isFromCommute) {
        // handleSubmitLocation();
        setState({ ...state, ViewStatus: "transit", travelMapLoading: true });
      } else {
        setState({ ...state, ViewStatus: "overview" });
      }
      $(".modal-backdrop").remove(), $("body").removeAttr("style");
    };
    fetch();
  }, [location]);
  const getWorkAdress = async () => {
    let newWorkAddress = await localStorage.getItem("workAddress");
    if (newWorkAddress != undefined) {
      let workAddress;
      workAddress = JSON.parse(await localStorage.getItem("workAddress"));

      let latitude = JSON.parse(
        await localStorage.getItem("workAddress")
      ).latitude;
      let longitude = JSON.parse(
        await localStorage.getItem("workAddress")
      ).longitude;
      let data = {
        latitude: Number(latitude),
        longitude: Number(longitude),
      };
      getCityListByWorkAddress(data);
    } else {
      setState((prev) => ({
        ...prev,
        citiesLoader: false,
        nearByCitiesWithStates: [],
      }));
    }
  };
  const getCityListByWorkAddress = async (data) => {
    let commuteTime = "undefined";
    if (
      localStorage.getItem("commuteTime") != "undefined" &&
      localStorage.getItem("commuteTime") != null &&
      localStorage.getItem("commuteTime") != ""
    ) {
      commuteTime = JSON.parse(await localStorage.getItem("commuteTime"));
    }

    let commuteTimeInMin =
      commuteTime == "undefined" || 15
        ? 15
        : commuteTime.preferencesanswer.split(",")[1];
    let commuteRadius = (commuteTimeInMin * 45) / 60;
    // console.log(commuteTime.preferencesanswer.split(",")[1], "commuteTime");
    let getSessionData = await LoginStore.getLoginInfo({});
    let reqObj = {
      latitude: data.latitude,
      longitude: data.longitude,
      radious: Math.round(commuteRadius),
      transfereeuid: getSessionData.userUniqueId,
    };
    let nearbyCity = await apiServices.GetAllCities(reqObj);
    setState((prev) => ({
      ...prev,
      preferCities: nearbyCity.message.citieslist,
    }));
    let nearByList = nearbyCity.message.citieslist;
    if (nearbyCity.error == "0") {
      let nearbycities = nearByList.map(function (item) {
        item.city = item.city;
        item.state = item.state_id;
        item.latitude = item.lat;
        item.longitude = item.lng;
        item.imageUrl = item.imageUrl;
        item.cityHighlights = item.cityHighlights;
        item.demoGraphics = item.demoGraphics;
        item.cityDetails = item.cityDetails;
        item.WalkScore = item.WalkScore;
        item.BikeScore = item.BikeScore;
        item.miles = Math.floor(item.distanc_in_mils);

        return item;
      });
      setState((prev) => ({
        ...prev,
        prefernearByCitiesWithStates: _.sortBy(nearbycities, function (obj) {
          return parseFloat(obj.miles, 10);
        }),
        citiesLoader: false,
      }));
    }
  };
  const getScoreDetails = async () => {
    let cityDetails = JSON.parse(await localStorage.getItem("cDetails"));
    // console.log(cityDetails, "cityDetails");

    if (
      cityDetails.imageUrl == "" ||
      cityDetails.imageUrl == null ||
      cityDetails.imageUrl == undefined
    ) {
      let cityName = {
        city: cityDetails.city,
        lat: cityDetails.latitude,
        lng: cityDetails.longitude,
        // address: cityDetails.address, // Include the address for more specific results
      };
      // console.log(cityName, "cityName");
      axios
        .get("/city-image", { params: cityName })
        .then((response) => {
          // console.log(response.data, "image");
          setState((prev) => ({ ...prev, imageUrl: response.data.photo }));
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (cityDetails != null || cityDetails != undefined) {
      setState((prev) => ({
        ...prev,
        image: cityDetails.imageUrl,
        cityinfo: cityDetails.CityInfo,
        WellKnownAttractions: cityDetails.WellKnownAttractions,
        ProfessionalTeams: cityDetails.ProfessionalTeams,
        WalkScore: cityDetails.WalkScore,
        BikeScore: cityDetails.BikeScore,
        TransitScore: cityDetails.TransitScore,
        cityName: cityDetails.city,
        selectedCity: cityDetails.city,
        data: {
          latitude: Number(cityDetails.latitude),
          longitude: Number(cityDetails.longitude),
          state_name: cityDetails.state,
          city_name: cityDetails.city,
          city: cityDetails.city,
          address: cityDetails.city,
        },
        communityObject: {
          latitude: Number(cityDetails.latitude),
          longitude: Number(cityDetails.longitude),
        },
      }));
    }
    if (
      cityDetails.demoGraphics != undefined ||
      cityDetails.image != undefined
    ) {
      setState((prev) => ({
        ...prev,
        demographics: cityDetails.demoGraphics,
        cityImage: cityDetails.image,
        cityHighlights: cityDetails.cityHighlights,
      }));
    }
  };
  const getAnswersPreferData = async () => {
    let getSessionData = await LoginStore.getLoginInfo({});
    let userObject = {
      transfereeuniqeuid: getSessionData.userUniqueId,
      istemporary: getSessionData.istemporary,
    };
    let getQuestionList = await apiServices.GetPreferencesList(userObject);

    let userPreferanceObject = {
      transfereeuniqeuid: getSessionData.userUniqueId,
      istemporary: getSessionData.istemporary,
    };
    let userSavedPreferances = await apiServices.GetTransfereePreferences(
      userPreferanceObject
    );
    if (userSavedPreferances.error == 0) {
      let userAnswerPreferanceObject = userSavedPreferances.message.preferences;
      if (userAnswerPreferanceObject != undefined) {
        let answersPrefer = {};
        _.forEach(userAnswerPreferanceObject, function (answerObject) {
          answersPrefer[answerObject.preferencesmasteruniqueid] = answerObject;
        });
        let savedAnswers = answersPrefer;
        getDataInLocalStorage(savedAnswers, getQuestionList);
        await setState((prev) => ({
          ...prev,
          answersPrefer: answersPrefer,
        }));
      }
    }
  };
  const getDataInLocalStorage = async (savedAnswers, getQuestionList) => {
    if (getQuestionList.message.preferencesList != undefined) {
      let questionList = getQuestionList.message.preferencesList;
      let Whoismoving = _.filter(questionList, function (o) {
        return o.question == "Who is moving?";
      });
      let workAddressArray = _.filter(questionList, function (o) {
        return o.question == "Work address";
      });
      let commuteTimeArray = _.filter(questionList, function (o) {
        return o.question == "Commute time";
      });
      await localStorage.setItem(
        "workAddress",
        JSON.stringify(savedAnswers[workAddressArray[0].preferenceUId])
      );
      await localStorage.setItem(
        "commuteTime",
        JSON.stringify(savedAnswers[commuteTimeArray[0]?.preferenceUId])
      );
      let whoismovingObject = savedAnswers[Whoismoving[0].preferenceUId];
      if (whoismovingObject.preferencesanswer == "FAMILY") {
        await setState((prev) => ({
          ...prev,
          SchoolDisplay: true,
        }));
      }
    }
  };
  const getNearByCityList = async (data) => {
    let commuteTime = "undefined";
    if (
      localStorage.getItem("commuteTime") != "undefined" &&
      localStorage.getItem("commuteTime") != null &&
      localStorage.getItem("commuteTime") != ""
    ) {
      commuteTime = JSON.parse(await localStorage.getItem("commuteTime"));
    }

    let commuteTimeInMin =
      commuteTime == "undefined" || 15
        ? 15
        : commuteTime.preferencesanswer.split(",")[1];
    let commuteRadius = (commuteTimeInMin * 45) / 60;
    // console.log(commuteTime.preferencesanswer.split(",")[1], "commuteTime");
    let getSessionData = await LoginStore.getLoginInfo({});
    let reqObj = {
      latitude: data.latitude,
      longitude: data.longitude,
      radious: Math.round(commuteRadius),
      transfereeuid: getSessionData.userUniqueId,
    };
    let nearbyCity = await apiServices.GetAllCities(reqObj);
    if (nearbyCity.error == "0") {
      setState((prev) => ({
        ...prev,
        nearByCities: nearbyCity.message.citieslist,
        citiesLoader: false,
      }));
    }

    let nearByList = nearbyCity.message.citieslist;

    if (Array.isArray(nearByList) && nearByList.length > 1) {
      let nearbycities = nearByList.map(function (item) {
        item.city = item.city;
        item.state = item.state_id;
        item.latitude = item.lat;
        item.longitude = item.lng;
        item.imageUrl = item.imageUrl;
        item.cityHighlights = item.cityHighlights;
        item.demoGraphics = item.demoGraphics;
        item.cityDetails = item.cityDetails;
        item.WalkScore = item.WalkScore;
        item.BikeScore = item.BikeScore;
        item.miles = Math.floor(item.distanc_in_mils);

        return item;
      });
      setState((prev) => ({
        ...prev,
        nearByCitiesWithStates: _.sortBy(nearbycities, function (obj) {
          return parseFloat(obj.miles, 10);
        }),
        citiesLoader: false,
      }));
    }
  };
  const cityDetails = async (row) => {
    // console.log(row);
    await localStorage.setItem("cDetails", JSON.stringify(row));
    navigate("/city-landing");
    window.location.reload();
    // window.open("/city-landing");
  };
  const getSchoolData = async (data) => {
    //  console.log(data, "0000");
    const greatSchoolsOview = await AppController.greatSchoolsOverView(data);
    let greatSchView = [];
    let publicSchview = [];
    const walkScoreDetails = await AppController.wallsckoreFun(data);
    const weather = await AppController.weather(data);
    const weather2 = await AppController.weather2(data);
    // console.log(weather, "====", weather2);
    if (weather.list != undefined) {
      var results = weather.list.map(function (el) {
        var o = Object.assign({}, el);
        o.date = o.dt_txt.split(" ")[0];
        return o;
      });
      var data1 = _.chain(results)
        .groupBy("date")
        .map(function (currentItem) {
          let minTEMP = _.minBy(currentItem, function (o) {
            return o.main.temp_min;
          });
          let maxTEMP = _.maxBy(currentItem, function (o) {
            return o.main.temp_max;
          });
          return {
            weatherData: currentItem[0],
            weatherDataMax: maxTEMP.main.temp_max,
            weatherDataMin: minTEMP.main.temp_min,
          };
        })
        .value();
      // console.log(data1, "data1");
      await setState((prev) => ({
        ...prev,
        weather: data1.splice(1, 4),
        weather_current: weather2,
        weather_currentCityDetails: weather.city,
      }));
    }
    const poi = await AppController.poi(state.data);
    let Categories = [];
    poi.results.forEach((element) => {
      for (let i = 0; i < element.types.length; i++) {
        if (Categories.indexOf(element.types[i]) === -1) {
          Categories.push(element.types[i]);
        }
      }
    });

    setState((prev) => ({
      ...prev,
      poiCategories: Categories,
      walkScoreDetails,
      poi: poi.results,
      isSuccessPoi: poi.isSuccess,
      greatSchoolsOview: greatSchView,
      publicSchview: publicSchview,
      schoolOverviewLoder: false,
    }));
  };
  const setLocation = async (Id, e) => {
    // console.log(Id, e.target.value, "====");

    let address = e.target.value;
    setState((prev) => ({
      ...prev,
      [Id]: address,
    }));

    var places = new google.maps.places.Autocomplete(
      document.getElementById(Id),
      { types: ["address"] }
    );
    places.setComponentRestrictions({ country: ["us"] });
    google.maps.event.addListener(places, "place_changed", async () => {
      var place = places.getPlace();
      var placeDetails = {
        locality: "long_name",
        administrative_area_level_1: "short_name",
        country: "long_name",
      };
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (placeDetails[addressType]) {
          var val = place.address_components[i][placeDetails[addressType]];
          placeDetails[addressType] = val;
        }
      }
      let relocatedAddress = place.formatted_address;
      // console.log(
      //   relocatedAddress,
      //   "relocated",
      //   placeDetails.locality,
      //   state.onSelectfromdropdown
      // );
      // setState((prev) => ({ ...prev, onSelectfromdropdown: true }));
      //await _ setState({ address: relocatedAddress,submitEnable:relocatedAddress !== '' || relocatedAddress !== null ? false : true });

      setState((prev) => ({
        ...prev,
        address: relocatedAddress,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        state: placeDetails.administrative_area_level_1,
        city: placeDetails.locality,
        submitEnable:
          relocatedAddress !== "" || relocatedAddress !== null ? false : true,
      }));
    });
  };
  const handleSubmitLocation = async () => {
    let row = {
      city: state.city?.trim(),
      state: state.state?.trim(),
      latitude: state.latitude,
      longitude: state.longitude,
      imageUrl: "",
      cityHighlights: 0,
      demoGraphics: 0,
      cityDetails: 0,
      miles: 0,
      bikescore: 0,
      walkscore: 0,
      address: state.address,
      cityaddress: state.cityaddress,
    };
    console.log(row, "row");
    if (
      state.city != "long_name" &&
      state.address !== undefined &&
      state.address !== "" &&
      state.onSelectfromdropdown == true
    ) {
      console.log("hello search");

      await localStorage.setItem("cDetails", JSON.stringify(row));
      //location.href = '/city-landing';
      // window.open("/city-landing");
      // navigate("/city-landing");
      window.location.reload();
      // setTimeout(() => {
      //   window.location.reload();
      // }, 3000);
    }
  };
  // const changeStatus = async (typeMenu) => {
  //   console.log(typeMenu, "===");
  //   setState((prev) => ({ ...prev, ViewStatus: typeMenu }));
  //   let cityDetails = JSON.parse(await localStorage.getItem("cDetails"));
  //   let getSessionData = await LoginStore.getLoginInfo({});

  //   if (typeMenu == "poi") {
  //     setState({
  //       ...state,
  //       ViewStatus: typeMenu,
  //       schoolApi: false,
  //       ispoi: true,
  //       explore: false,
  //     });
  //     await AppController.logData(
  //       "Points of Interest",
  //       "CityPage",
  //       location.pathname,
  //       getSessionData.userUniqueId,
  //       cityDetails.city
  //     );
  //   }
  //   if (typeMenu == "schools") {
  //     setState({
  //       ...state,
  //       ViewStatus: typeMenu,
  //       schoolApi: true,
  //       ispoi: false,
  //       explore: false,
  //     });
  //     await AppController.logData(
  //       "Schools",
  //       "CityPage",
  //       location.pathname,
  //       getSessionData.userUniqueId,
  //       cityDetails.city
  //     );
  //   }
  //   if (typeMenu == "explore") {
  //     setState({
  //       ...state,
  //       ViewStatus: typeMenu,
  //       schoolApi: false,
  //       ispoi: false,
  //       explore: true,
  //     });
  //     await AppController.logData(
  //       "Explore the Area",
  //       "CityPage",
  //       location.pathname,
  //       getSessionData.userUniqueId,
  //       cityDetails.city
  //     );
  //   }
  //   if (typeMenu == "transit") {
  //     setState({
  //       ...state,
  //       ViewStatus: typeMenu,
  //       schoolApi: false,
  //       ispoi: false,
  //       travelMapLoading: true,
  //       explore: false,
  //     });
  //     await AppController.logData(
  //       "Commute",
  //       "CityPage",
  //       location.pathname,
  //       getSessionData.userUniqueId,
  //       cityDetails.city
  //     );
  //   }
  //   if (typeMenu == "overview") {
  //     setState({ ...state, ViewStatus: typeMenu });
  //     await AppController.logData(
  //       "Overview",
  //       "CityPage",
  //       location.pathname,
  //       getSessionData.userUniqueId,
  //       cityDetails.city
  //     );
  //   }
  // };
  const changeStatus = async (typeMenu) => {
    setState((prev) => ({ ...prev, ViewStatus: typeMenu }));
    const cityDetails = JSON.parse(localStorage.getItem("cDetails"));
    const getSessionData = await LoginStore.getLoginInfo({});
    setState((prev) => ({
      ...prev,
      ViewStatus: typeMenu,
      schoolApi: typeMenu == "schools",
      ispoi: typeMenu == "poi",
      explore: typeMenu == "explore",
      travelMapLoading: typeMenu == "transit",
    }));

    await AppController.logData(
      typeMenu == "poi"
        ? "Points of Interest"
        : typeMenu == "schools"
        ? "Schools"
        : typeMenu == "explore"
        ? "Explore the Area"
        : typeMenu == "transit"
        ? "Commute"
        : "Overview",
      "CityPage",
      location.pathname,
      getSessionData.userUniqueId,
      cityDetails.city
    );
  };

  const handlePopUp = (type) => {
    if (type == "wellknown") {
      $("#myModalWell-known").modal("show");

      setState((prev) => ({ ...prev, popUPtype: type, activitePopUp: true }));
    } else if (type == "professional") {
      $("#myModalWell-known").modal("show");

      setState((prev) => ({ ...prev, popUPtype: type, activitePopUp: true }));
    }
  };
  const walkScoreModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#walkscoreInfoMadal").modal("show");
      });
    });
    setState((prev) => ({ ...prev, scoreModal: true }));
  };
  const handleCityChange = async (e) => {
    // console.log(e.target.value, "e.target.value city");
    let value = e.target.value;
    const selectedCity = state.nearByCitiesWithStates.find(
      (city) => city.city === value
    );
    // console.log("Selected city:", selectedCity);
    setState((prev) => ({ ...prev, selectedCity: selectedCity.city }));
    await localStorage.setItem("cDetails", JSON.stringify(selectedCity));
    // navigate("/city-landing");
    window.location.reload();
  };
  const cityData = JSON.parse(localStorage.getItem("cDetails"));
  // console.log(cityData.city, "cityData", state.selectedCity);
  const nearByCitiesWithStates = state.nearByCitiesWithStates;
  // Extract city names from cityData object
  const cityNamesInData = cityData.city;

  // Filter out cities from nearByCitiesWithStates that are present in cityData
  const filteredCities = nearByCitiesWithStates.filter((city) => {
    return !cityNamesInData.includes(city.city);
  });

  const goBack = () => {
    navigate("/citylist"); // Go back to the previous route
  };
  const updateCarouselPosition = (object) => {
    if (object.item.index != state.startPosition) {
      setState((prevState) => ({
        ...prevState,
        startPosition: object.item.index,
      }));
    }
  };
  // const tab = () => {
  //   let tabItems =
  //     parseInt(screen.availWidth) <= 767
  //       ? 2
  //       : parseInt(screen.availWidth) <= 991
  //       ? 2
  //       : 3;
  //   setState((prevState) => ({
  //     ...prevState,
  //     tabItems: tabItems,
  //   }));
  // };
  const tab = () => {
    let tabItems;
    const screenWidth = window.innerWidth;
    if (screenWidth <= 767) {
      tabItems = 2;
    } else {
      tabItems = 5;
    }
    setState((prevState) => ({
      ...prevState,
      tabItems: tabItems,
    }));
  };
  $(document).ready(function () {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 575) {
      $(".client-testimonial-carousel").owlCarousel({
        items: 2,
        //      autoplay:false,
        margin: 30,
        loop: false,
        dots: false,
        //      nav:true,
        //      navText:["<i class='fas fa-long-arrow-alt-left'></i>","<i class='fas fa-long-arrow-alt-right'></i>" ]
      });
    } else if (screenWidth <= 767) {
      $(".client-testimonial-carousel").owlCarousel({
        items: 3,
        //      autoplay:false,
        margin: 30,
        loop: false,
        dots: false,
        //      nav:true,
        //      navText:["<i class='fas fa-long-arrow-alt-left'></i>","<i class='fas fa-long-arrow-alt-right'></i>" ]
      });
    } else {
      $(".client-testimonial-carousel").owlCarousel({
        items: 3,
        //      autoplay:false,
        margin: 30,
        loop: false,
        dots: false,
        //      nav:true,
        //      navText:["<i class='fas fa-long-arrow-alt-left'></i>","<i class='fas fa-long-arrow-alt-right'></i>" ]
      });
    }
  });

  // $(document).ready(function () {
  //   // Your Slick slider initialization code here
  //   $(".slider-for").slick({
  //     slidesToShow: 1,
  //     slidesToScroll: 1,
  //     arrows: false,
  //     fade: true,
  //     asNavFor: ".slider-nav",
  //   });
  //   $(".slider-nav").slick({
  //     slidesToShow: 3,
  //     slidesToScroll: 1,
  //     asNavFor: ".slider-for",
  //     dots: true,
  //     focusOnSelect: true,
  //   });

  //   $("a[data-slide]").click(function (e) {
  //     e.preventDefault();
  //     var slideno = $(this).data("slide");
  //     $(".slider-nav").slick("slickGoTo", slideno - 1);
  //   });
  // });

  return (
    <div>
      <div className="city-landing-page newOne">
        {state.citiesLoader ? <div className="loading"></div> : null}
        <div className="">
          {state.cityaddress == true && (
            <div
              className={
                state.cityaddress == true
                  ? "explore-nearby-view mapsearchbar"
                  : "explore-nearby-view"
              }
            >
              <form
                className="navbar-form"
                role="search"
                onSubmit={(event) => event.preventDefault()}
              >
                <div className="input-group add-on">
                  <i className="fa fa-search"></i>
                  <input
                    onChange={(e) => setLocation("address", e)}
                    className="form-control"
                    value={state.address}
                    placeholder="Search"
                    name="address"
                    id="address"
                    type="text"
                  />
                  <div className="input-group-btn">
                    <button
                      className="btn btn-default"
                      style={{
                        backgroundColor:
                          state.onSelectfromdropdown == true ? "" : "lightgrey",
                      }}
                      type="button"
                      onClick={() => handleSubmitLocation()}
                      disabled={
                        state.onSelectfromdropdown == true ? false : true
                      }
                    >
                      Search
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
          <h1 className="backBtn">
            <button className="btn " onClick={goBack}>
              <i className="fa-regular fa-angle-left"></i>
            </button>{" "}
          </h1>

          <h1 className="location">
            <select
              className="form-control text-end pe-5"
              dir="rtl"
              placeholder="Select a city"
              onChange={handleCityChange}
              style={{ width: 200 }}
              defaultValue={state.selectedCity}
            >
              {state.nearByCitiesWithStates.map((city) => (
                <option
                  key={city.UniqueId}
                  value={city.city}
                  selected={state.selectedCity == city.city ? "selected" : ""}
                >
                  {city.city}
                </option>
              ))}
            </select>
          </h1>

          <div className="tabs-view">
            {/* <ul className="nav nav-pills" role="tablist">
              <li>
                <a
                  className={
                    state.ViewStatus == "overview"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  data-toggle="pill"
                  onClick={() => changeStatus("overview")}
                >
                  Overview
                </a>
              </li>

              <li>
                <a
                  className={
                    state.ViewStatus == "poi" ? "nav-link active" : "nav-link"
                  }
                  data-toggle="pill"
                  onClick={() => changeStatus("poi")}
                >
                  Points of Interests
                </a>
              </li>
              <li>
                <a
                  className={
                    state.ViewStatus == "transit"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  data-toggle="pill"
                  onClick={() => changeStatus("transit")}
                >
                  Commute
                </a>
              </li>
              {state.SchoolDisplay == true && (
                <li>
                  <a
                    className={
                      state.ViewStatus == "schools"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    data-toggle="pill"
                    onClick={() => changeStatus("schools")}
                  >
                    Schools
                  </a>
                </li>
              )}
              <li>
                <a
                  className={
                    state.ViewStatus == "explore"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  data-toggle="pill"
                  onClick={() => changeStatus("explore")}
                >
                  Explore the Area
                </a>
              </li>
            </ul> */}
            <ul className="nav nav-pills">
              <div className="ulSlider">
                <OwlCarousel
                  className="owl-theme"
                  dots={false}
                  autoWidth={true}
                  items={state.tabItems}
                  startPosition={state.startPosition}
                  key={state.SchoolDisplay} // Adding key to force re-render on state change
                  onDragged={(object) => updateCarouselPosition(object)}
                  onTranslated={(object) => updateCarouselPosition(object)}
                >
                  {state.mainMenu.map((menu, index) => {
                    // Conditional logic to skip rendering the 'schools' menu if SchoolDisplay is false
                    if (menu.id === "schools" && state.SchoolDisplay != true) {
                      return null; // Skip rendering this item
                    }

                    return (
                      <li
                        key={menu.name}
                        className={
                          state.ViewStatus == menu.id
                            ? "nav-link active"
                            : "nav-link"
                        }
                        onClick={() => changeStatus(menu.id)}
                      >
                        <a onClick={() => changeStatus(menu.id)}>{menu.name}</a>
                      </li>
                    );
                  })}
                </OwlCarousel>
              </div>
            </ul>

            {/* <div className="tabSliders">
              <div class="owl-carousel client-testimonial-carousel">
                <div class="item">
                  <a
                    className={
                      state.ViewStatus == "overview"
                        ? "nav-item active"
                        : "nav-item"
                    }
                    data-toggle="pill"
                    onClick={() => changeStatus("overview")}
                  >
                    Overview
                  </a>
                </div>
                <div class="item">
                  <a
                    className={
                      state.ViewStatus == "poi" ? "nav-item active" : "nav-item"
                    }
                    data-toggle="pill"
                    onClick={() => changeStatus("poi")}
                  >
                    Points of Interests
                  </a>
                </div>
                <div class="item">
                  <a
                    className={
                      state.ViewStatus == "transit"
                        ? "nav-item active"
                        : "nav-item"
                    }
                    data-toggle="pill"
                    onClick={() => changeStatus("transit")}
                  >
                    Commute
                  </a>
                </div>
                <div class="item">
                  <a
                    className={
                      state.ViewStatus == "explore"
                        ? "nav-item active"
                        : "nav-item"
                    }
                    data-toggle="pill"
                    onClick={() => changeStatus("explore")}
                  >
                    Explore the Area
                  </a>
                </div>
              </div>
            </div> */}
            <div className="tab-content">
              {state.ViewStatus == "overview" && (
                <div id="overview" className="tab-pane active container">
                  {/* {state.cityinfo != "" &&
                    state.cityinfo != undefined &&
                    state.cityinfo != null && ( */}
                  <div className="">
                    <div className="">
                      <div className="">
                        <div className="row">
                          {state.image != undefined &&
                            state.image != null &&
                            state.image != "" && (
                              <div className="col-md-8">
                                <div className="image">
                                  <img src={state.image} alt="" />
                                </div>
                              </div>
                            )}
                          {state.imageUrl != undefined &&
                            state.imageUrl != null &&
                            state.imageUrl != "" && (
                              <div className="col-md-8">
                                <div className="image">
                                  <img src={state.imageUrl} alt="" />
                                </div>
                              </div>
                            )}
                          <div className="mobileView">
                            {state.cityinfo != "" &&
                              state.cityinfo != undefined &&
                              state.cityinfo != null && (
                                <div className="">
                                  <div className="col-md-12">
                                    {state.cityinfo != null && (
                                      <div className="banner-text mt-4 city-bg ">
                                        {state.cityinfo}

                                        <div className="text-popup-view">
                                          {state.WellKnownAttractions != null &&
                                            state.WellKnownAttractions !=
                                              "" && (
                                              <a
                                                className="me-5"
                                                href="javascript:void(0)"
                                                onClick={() =>
                                                  handlePopUp("wellknown")
                                                }
                                              >
                                                Well-known Attractions
                                                <span className="side-arrow-view-icon"></span>
                                              </a>
                                            )}
                                          {state.ProfessionalTeams != null &&
                                            state.ProfessionalTeams != "" && (
                                              <a
                                                href="javascript:void(0)"
                                                onClick={() =>
                                                  handlePopUp("professional")
                                                }
                                              >
                                                Professional Sports Teams
                                                <span className="side-arrow-view-icon"></span>
                                              </a>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                          </div>
                          <div
                            // className={
                            //   state.image != undefined &&
                            //   state.image != null &&
                            //   state.image != ""
                            //     ? "col-md-4"
                            //     : "cityrow"
                            // }
                            className="col-md-4"
                          >
                            <div className="city-bg mb-4">
                              <h1>
                                Scores{" "}
                                <i
                                  className="fa fa-info-circle"
                                  data-toggle="modal"
                                  data-target="#walkscoreInfoMadal"
                                  onClick={() => walkScoreModal()}
                                ></i>
                              </h1>
                              {state.WalkScore != undefined &&
                              state.WalkScore != null &&
                              state.WalkScore != "" ? (
                                <WalkScoreCity {...state} />
                              ) : (
                                <WalkScore {...state} />
                              )}
                            </div>
                            <div className="city-bg citylist">
                              <h1>Nearby Cities </h1>
                              {state.nearByCitiesWithStates.length > 0 ? (
                                <div className="">
                                  {!state.citiesLoader ? (
                                    <ul className="near-city">
                                      {filteredCities.map((row, index) => {
                                        return (
                                          <li
                                            key={index}
                                            onClick={() => cityDetails(row)}
                                          >
                                            <a href="javascript:void(0)">
                                              {row.city}{" "}
                                              <i class="fa-regular fa-angle-right"></i>
                                            </a>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  ) : (
                                    <div className="min-height-200 divSpin">
                                      <Spin />
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <p>
                                  {" "}
                                  We are building your personalized city list.
                                  Please check back later.
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        {state.greatSchoolsOview.length > 0 && (
                          <div className="top-view">
                            <div>
                              <div className="col-md-12 col-md-view">
                                <div className="row">
                                  <div
                                    className={
                                      state.greatSchoolsOview.length > 0
                                        ? "col-md-7"
                                        : state.schoolOverviewLoder == true
                                        ? "col-md-7"
                                        : "col-md-12"
                                    }
                                  ></div>

                                  {state.greatSchoolsOview.length > 0 ? (
                                    <div className="col-md-5">
                                      <div className="bb-white city-padding b-radius-8 height100per">
                                        <div className="propertypage">
                                          <div className="nearbypoints schools-overview">
                                            <h1>City Schools Overview</h1>
                                            <div>
                                              {" "}
                                              <table width="100%">
                                                <tbody>
                                                  {state.greatSchoolsOview.map(
                                                    function (key, i) {
                                                      return (
                                                        <tr key={i}>
                                                          <th width="70%">
                                                            {key.Name.charAt(
                                                              0
                                                            ).toUpperCase() +
                                                              key.Name.slice(
                                                                1
                                                              ).replace(
                                                                "Schools",
                                                                " Schools"
                                                              )}
                                                          </th>
                                                          <td>{key.NumText}</td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                                </tbody>
                                              </table>
                                              <table width="100%">
                                                <tbody>
                                                  {state.publicSchview.map(
                                                    function (key, i) {
                                                      return (
                                                        <tr key={i}>
                                                          <th width="70%">
                                                            {key.Name.charAt(
                                                              0
                                                            ).toUpperCase() +
                                                              key.Name.slice(
                                                                1
                                                              ).replace(
                                                                "Schools",
                                                                " Schools"
                                                              )}
                                                          </th>
                                                          <td>{key.NumText}</td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : state.schoolOverviewLoder == true ? (
                                    <div className="min-height-200 divSpin">
                                      <Spin />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {state.cityinfo != "" &&
                          state.cityinfo != undefined &&
                          state.cityinfo != null && (
                            <div className="row">
                              <div className="col-md-12">
                                {state.cityinfo != null && (
                                  <div className="banner-text mt-lg-4 city-bg ">
                                    {state.cityinfo}

                                    <div className="text-popup-view">
                                      {state.WellKnownAttractions != null &&
                                        state.WellKnownAttractions != "" && (
                                          <a
                                            className="me-5"
                                            href="javascript:void(0)"
                                            onClick={() =>
                                              handlePopUp("wellknown")
                                            }
                                          >
                                            Well-known Attractions
                                            <span className="side-arrow-view-icon"></span>
                                          </a>
                                        )}
                                      {state.ProfessionalTeams != null &&
                                        state.ProfessionalTeams != "" && (
                                          <a
                                            href="javascript:void(0)"
                                            onClick={() =>
                                              handlePopUp("professional")
                                            }
                                          >
                                            Professional Sports Teams
                                            <span className="side-arrow-view-icon"></span>
                                          </a>
                                        )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                        <div className="row">
                          <div className="col-md-12">
                            {state.weather_current != undefined ? (
                              <Weather
                                {...state}
                                weather={state.weather}
                                weather_current={state.weather_current}
                                weather_currentCityDetails={
                                  state.weather_currentCityDetails
                                }
                              />
                            ) : (
                              <div className="min-height-200 divSpin">
                                <Spin />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="city-bg mobileCitylist">
                          <h1>Nearby Cities </h1>
                          {state.nearByCitiesWithStates.length > 0 ? (
                            <div className="">
                              {!state.citiesLoader ? (
                                <ul className="near-city">
                                  {filteredCities.map((row, index) => {
                                    return (
                                      <li
                                        key={index}
                                        onClick={() => cityDetails(row)}
                                      >
                                        <a href="javascript:void(0)">
                                          {row.city}{" "}
                                          <i className="fa-regular fa-angle-right"></i>
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              ) : (
                                <div className="min-height-200 divSpin">
                                  <Spin />
                                </div>
                              )}
                            </div>
                          ) : (
                            <p>
                              We are building your personalized city list.
                              Please check back later.
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {state.ViewStatus == "schools" && (
                <div id="school" className="">
                  {state.ispoi == false &&
                  state.data.latitude != "" &&
                  state.data.longitude != "" ? (
                    // <SchoolsSearch {...state} isPoi={false} />
                    // <SchoolsSearch2 {...state} />
                    <SchoolSearchMapBox {...state} />
                  ) : // <SchoolSearchNew {...state} />
                  null}
                </div>
              )}

              {state.ViewStatus == "poi" && (
                <div className="explore-the-area-tab">
                  {state.ispoi == true &&
                  state.data.latitude != "" &&
                  state.data.longitude != "" ? (
                    <PoiSearch {...state} />
                  ) : null}
                </div>
              )}
              {state.ViewStatus == "transit" && (
                <div id="transit" className="">
                  {state.travelMapLoading == true && (
                    <PublicTransit
                      lines={state.lines}
                      latitude={state.data.latitude}
                      longitude={state.data.longitude}
                    />
                  )}
                </div>
              )}
              {state.ViewStatus == "explore" && (
                <div id="explore-the-area" className="explore-the-area-tab">
                  <div className="">
                    <div className="map_top_view">
                      <Mapview
                        {...state}
                        latitude={state.communityObject.latitude}
                        longitude={state.communityObject.longitude}
                        communityName={state.communityObject.community_name}
                        communityObject={state.communityObject}
                      />
                      {/* <MapViewFunctional
                        state={state}
                        latitude={state.communityObject.latitude}
                        longitude={state.communityObject.longitude}
                        communityName={state.communityObject.community_name}
                        communityObject={state.communityObject}
                      /> */}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {state.ViewStatus == "overview" && <Footer />}
      {state.scoreModal === true && (
        <div
          id="walkscoreInfoMadal"
          className="modal fade"
          style={{
            zIndex: 1060,
            transition: "none",
            opacity: 1,
          }}
          role="dialog"
        >
          <WalkScorePopUp state={state} setState={setState} />
        </div>
      )}

      {state.activitePopUp && (
        <div
          id="myModalWell-known"
          className="modal fade"
          style={{
            zIndex: 1060,
            transition: "none",
            opacity: 1,
          }}
          role="dialog"
        >
          <WellKnowPopUp
            state={state}
            setState={setState}
            handlePopUp={handlePopUp}
          />
        </div>
      )}
    </div>
  );
}

export default CityLanding;
