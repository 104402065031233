import React, { useEffect, useState } from "react";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import moment from "moment";
import OwlCarousel from "react-owl-carousel";
import { Select } from "antd";
import AppController from "../../../controllers/appController";
import ApiServices from "../../../../services/apiServices";
import LoginStore from "../../../../services/thirdPartyService";
import GoogleMapsPlaces from "../../../../services/mapsPlaces";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as turf from "@turf/turf";
import fourSquarePlaces from "../../../../services/fourSquarePlaces";

mapboxgl.accessToken =
  "sk.eyJ1IjoidGVqYWt1bWFyNjIiLCJhIjoiY2x4b2I2M3phMDZ5cDJqcHMxZnVpMjB3bCJ9.uscOlHG0Tdfk3s2LEGX98w"; // Replace with your Mapbox access token

function MapPointofInterests(props) {
  const { poiData, state, setState } = props;

  const [mapState, setMapState] = useState({
    data: {
      latitude: 37.7739852,
      longitude: -122.3912407,
      state_name: "CA",
      city_name: "San Francisco",
      city: "San Francisco",
      address: "185 Channel Street",
    },
    items: "",
    tabItems: "",
    startPosition: 0,
    latitude: 37.7739852,
    longitude: -122.3912407,
    schoolsList: [],
    greatSchools: [],
    selectedCategory: "ATM",
    Date: moment(new Date()).format("YYYYMMDD"),
    OriginalCategories: [
      "Arts & Entertainment",
      "Amphitheater",
      "Aquarium",
      "Arcade",
      "Art Gallery",
      "Bowling Alley",
      "Casino",
      "Circus",
      "Comedy Club",
      "Concert Hall",
      "Country Dance Club",
      "Disc Golf",
      "Exhibit",
      "General Entertainment",
      "Go Kart Track",
      "Historic Site",
      "Karaoke Box",
      "Laser Tag",
      "Memorial Site",
      "Mini Golf",
      "Movie Theater",
      "Museum",
      "Music Venue",
      "Pachinko Parlor",
      "Performing Arts Venue",
      "Pool Hall",
      "Public Art",
      "Racecourse",
      "Racetrack",
      "Roller Rink",
      "Salsa Club",
      "Samba School",
      "Stadium",
      "Theme Park",
      "Tour Provider",
      "Water Park",
      "Zoo",
      "Event",
      "Christmas Market",
      "Conference",
      "Convention",
      "Festival",
      "Line / Queue",
      "Music Festival",
      "Other Event",
      "Parade",
      "Sporting Event",
      "Stoop Sale",
      "Street Fair",
      "Trade Fair",
      "Food",
      "Afghan Restaurant",
      "African Restaurant",
      "American Restaurant",
      "Asian Restaurant",
      "Australian Restaurant",
      "Austrian Restaurant",
      "BBQ Joint",
      "Bagel Shop",
      "Bakery",
      "Bangladeshi Restaurant",
      "Belgian Restaurant",
      "Bistro",
      "Breakfast Spot",
      "Bubble Tea Shop",
      "Buffet",
      "Burger Joint",
      "Cafeteria",
      "Café",
      "Cajun / Creole Restaurant",
      "Caribbean Restaurant",
      "Cuban Restaurant",
      "Caucasian Restaurant",
      "Coffee Shop",
      "Comfort Food Restaurant",
      "Creperie",
      "Czech Restaurant",
      "Deli / Bodega",
      "Dessert Shop",
      "Diner",
      "Donut Shop",
      "Dumpling Restaurant",
      "Dutch Restaurant",
      "Eastern European Restaurant",
      "English Restaurant",
      "Falafel Restaurant",
      "Fast Food Restaurant",
      "Fish & Chips Shop",
      "Fondue Restaurant",
      "Food Court",
      "Food Stand",
      "Food Truck",
      "French Restaurant",
      "Fried Chicken Joint",
      "Friterie",
      "Gastropub",
      "German Restaurant",
      "Gluten-free Restaurant",
      "Greek Restaurant",
      "Halal Restaurant",
      "Hawaiian Restaurant",
      "Hot Dog Joint",
      "Hungarian Restaurant",
      "Indian Restaurant",
      "Irish Pub",
      "Italian Restaurant",
      "Jewish Restaurant",
      "Juice Bar",
      "Kebab Restaurant",
      "Latin American Restaurant",
      "Mac & Cheese Joint",
      "Mediterranean Restaurant",
      "Mexican Restaurant",
      "Middle Eastern Restaurant",
      "Modern European Restaurant",
      "Molecular Gastronomy Restaurant",
      "Pakistani Restaurant",
      "Pet Café",
      "Pizza Place",
      "Polish Restaurant",
      "Portuguese Restaurant",
      "Poutine Place",
      "Restaurant",
      "Russian Restaurant",
      "Salad Place",
      "Sandwich Place",
      "Scandinavian Restaurant",
      "Scottish Restaurant",
      "Seafood Restaurant",
      "Slovak Restaurant",
      "Snack Place",
      "Soup Place",
      "Southern / Soul Food Restaurant",
      "Spanish Restaurant",
      "Sri Lankan Restaurant",
      "Steakhouse",
      "Swiss Restaurant",
      "Tea Room",
      "Theme Restaurant",
      "Truck Stop",
      "Turkish Restaurant",
      "Ukrainian Restaurant",
      "Vegetarian / Vegan Restaurant",
      "Wings Joint",
      "Nightlife Spot",
      "Bar",
      "Brewery",
      "Lounge",
      "Night Market",
      "Nightclub",
      "Other Nightlife",
      "Strip Club",
      "Outdoors & Recreation",
      "Athletics & Sports",
      "Bathing Area",
      "Bay",
      "Beach",
      "Bike Trail",
      "Botanical Garden",
      "Bridge",
      "Campground",
      "Canal Lock",
      "Canal",
      "Castle",
      "Cave",
      "Cemetery",
      "Dive Spot",
      "Dog Run",
      "Farm",
      "Field",
      "Fishing Spot",
      "Forest",
      "Fountain",
      "Garden",
      "Gun Range",
      "Harbor / Marina",
      "Hill",
      "Hot Spring",
      "Indoor Play Area",
      "Island",
      "Lake",
      "Lighthouse",
      "Mountain Hut",
      "Mountain",
      "National Park",
      "Nature Preserve",
      "Other Great Outdoors",
      "Palace",
      "Park",
      "Pedestrian Plaza",
      "Playground",
      "Plaza",
      "Pool",
      "Rafting",
      "Recreation Center",
      "Reservoir",
      "River",
      "Rock Climbing Spot",
      "Roof Deck",
      "Scenic Lookout",
      "Sculpture Garden",
      "Ski Area",
      "Skydiving Drop Zone",
      "Stables",
      "State / Provincial Park",
      "States & Municipalities",
      "Summer Camp",
      "Trail",
      "Tree",
      "Vineyard",
      "Volcano",
      "Waterfall",
      "Waterfront",
      "Well",
      "Windmill",
      "Professional & Other Places",
      "Animal Shelter",
      "Art Studio",
      "Auditorium",
      "Ballroom",
      "Building",
      "Business Center",
      "Cidery",
      "Club House",
      "Community Center",
      "Convention Center",
      "Meeting Room",
      "Cultural Center",
      "Distillery",
      "Distribution Center",
      "Event Space",
      "Factory",
      "Fair",
      "Funeral Home",
      "Government Building",
      "Industrial Estate",
      "Laboratory",
      "Library",
      "Meadery",
      "Medical Center",
      "Military Base",
      "Non-Profit",
      "Observatory",
      "Office",
      "Parking",
      "Post Office",
      "Power Plant",
      "Prison",
      "Radio Station",
      "Recruiting Agency",
      "Research Station",
      "School",
      "Social Club",
      "Spiritual Center",
      "TV Station",
      "Voting Booth",
      "Warehouse",
      "Waste Facility",
      "Wedding Hall",
      "Winery",
      "Assisted Living",
      "Shop & Service",
      "ATM",
      "Adult Boutique",
      "Antique Shop",
      "Arts & Crafts Store",
      "Astrologer",
      "Auto Dealership",
      "Auto Garage",
      "Auto Workshop",
      "Automotive Shop",
      "Baby Store",
      "Bank",
      "Bath House",
      "Batik Shop",
      "Betting Shop",
      "Big Box Store",
      "Bike Shop",
      "Board Shop",
      "Bookstore",
      "Bridal Shop",
      "Business Service",
      "Camera Store",
      "Candy Store",
      "Car Wash",
      "Carpet Store",
      "Check Cashing Service",
      "Child Care Service",
      "Chocolate Shop",
      "Clothing Store",
      "Comic Shop",
      "Construction & Landscaping",
      "Convenience Store",
      "Cosmetics Shop",
      "Costume Shop",
      "Credit Union",
      "Currency Exchange",
      "Department Store",
      "Design Studio",
      "Discount Store",
      "Dive Shop",
      "Drugstore",
      "Dry Cleaner",
      "EV Charging Station",
      "Electronics Store",
      "Entertainment Service",
      "Event Service",
      "Fabric Shop",
      "Film Studio",
      "Financial or Legal Service",
      "Fireworks Store",
      "Fishing Store",
      "Flea Market",
      "Floating Market",
      "Flower Shop",
      "Food & Drink Shop",
      "Frame Store",
      "Fruit & Vegetable Store",
      "Furniture / Home Store",
      "Gaming Cafe",
      "Garden Center",
      "Gas Station",
      "Gift Shop",
      "Gun Shop",
      "Hardware Store",
      "Health & Beauty Service",
      "Herbs & Spices Store",
      "Hobby Shop",
      "Home Service",
      "Hunting Supply",
      "IT Services",
      "Insurance Office",
      "Internet Cafe",
      "Jewelry Store",
      "Kitchen Supply Store",
      "Knitting Store",
      "Laundromat",
      "Laundry Service",
      "Lawyer",
      "Leather Goods Store",
      "Locksmith",
      "Lottery Retailer",
      "Luggage Store",
      "Marijuana Dispensary",
      "Market",
      "Massage Studio",
      "Mattress Store",
      "Medical Supply Store",
      "Miscellaneous Shop",
      "Mobile Phone Shop",
      "Mobility Store",
      "Motorcycle Shop",
      "Motorsports Shop",
      "Music Store",
      "Nail Salon",
      "Newsstand",
      "Notary",
      "Optical Shop",
      "Other Repair Shop",
      "Outdoor Supply Store",
      "Outlet Mall",
      "Outlet Store",
      "Paper / Office Supplies Store",
      "Pawn Shop",
      "Perfume Shop",
      "Pet Service",
      "Pet Store",
      "Pharmacy",
      "Photography Lab",
      "Photography Studio",
      "Piercing Parlor",
      "Pop-Up Shop",
      "Print Shop",
      "Public Bathroom",
      "Real Estate Office",
      "Record Shop",
      "Recording Studio",
      "Recycling Facility",
      "Rental Service",
      "Salon / Barbershop",
      "Sauna / Steam Room",
      "Shipping Store",
      "Shoe Repair",
      "Shopping Mall",
      "Shopping Plaza",
      "Skate Shop",
      "Ski Shop",
      "Smoke Shop",
      "Smoothie Shop",
      "Souvenir Shop",
      "Spa",
      "Sporting Goods Shop",
      "Stationery Store",
      "Storage Facility",
      "Supplement Shop",
      "Tailor Shop",
      "Tanning Salon",
      "Tattoo Parlor",
      "Thrift / Vintage Store",
      "Toy / Game Store",
      "Travel Agency",
      "Used Bookstore",
      "Vape Store",
      "Video Game Store",
      "Video Store",
      "Warehouse Store",
      "Watch Shop",
      "Travel & Transport",
      "Airport",
      "Baggage Locker",
      "Bike Rental / Bike Share",
      "Boat Rental",
      "Boat or Ferry",
      "Border Crossing",
      "Bus Station",
      "Bus Stop",
      "Cable Car",
      "Cruise",
      "Duty-free Shop",
      "General Travel",
      "Heliport",
      "Hotel",
      "Intersection",
      "Light Rail Station",
      "Metro Station",
      "Moving Target",
      "Pier",
      "Port",
      "RV Park",
      "Rental Car Location",
      "Rest Area",
      "Road",
      "Taxi Stand",
      "Taxi",
      "Toll Booth",
      "Toll Plaza",
      "Tourist Information Center",
      "Train Station",
      "Tram Station",
      "Transportation Service",
      "Travel Lounge",
      "Tunne",
      "Gym / Fitness Center",
      "Hospital",
    ],
    poiCategoriesIds: {
      "Arts & Entertainment": "4d4b7104d754a06370d81259",
      Amphitheater: "56aa371be4b08b9a8d5734db",
      Aquarium: "4fceea171983d5d06c3e9823",
      Arcade: "4bf58dd8d48988d1e1931735",
      "Art Gallery": "4bf58dd8d48988d1e2931735",
      "Bowling Alley": "4bf58dd8d48988d1e4931735",
      Casino: "4bf58dd8d48988d17c941735",
      Circus: "52e81612bcbc57f1066b79e7",
      "Comedy Club": "4bf58dd8d48988d18e941735",
      "Concert Hall": "5032792091d4c4b30a586d5c",
      "Country Dance Club": "52e81612bcbc57f1066b79ef",
      "Disc Golf": "52e81612bcbc57f1066b79e8",
      Exhibit: "56aa371be4b08b9a8d573532",
      "General Entertainment": "4bf58dd8d48988d1f1931735",
      "Go Kart Track": "52e81612bcbc57f1066b79ea",
      "Historic Site": "4deefb944765f83613cdba6e",
      "Karaoke Box": "5744ccdfe4b0c0459246b4bb",
      "Laser Tag": "52e81612bcbc57f1066b79e6",
      "Memorial Site": "5642206c498e4bfca532186c",
      "Mini Golf": "52e81612bcbc57f1066b79eb",
      "Movie Theater": "4bf58dd8d48988d17f941735",
      Museum: "4bf58dd8d48988d181941735",
      "Music Venue": "4bf58dd8d48988d1e5931735",
      "Pachinko Parlor": "5744ccdfe4b0c0459246b4b8",
      "Performing Arts Venue": "4bf58dd8d48988d1f2931735",
      "Pool Hall": "4bf58dd8d48988d1e3931735",
      "Public Art": "507c8c4091d498d9fc8c67a9",
      Racecourse: "56aa371be4b08b9a8d573514",
      Racetrack: "4bf58dd8d48988d1f4931735",
      "Roller Rink": "52e81612bcbc57f1066b79e9",
      "Salsa Club": "52e81612bcbc57f1066b79ec",
      "Samba School": "56aa371be4b08b9a8d5734f9",
      Stadium: "4bf58dd8d48988d184941735",
      "Theme Park": "4bf58dd8d48988d182941735",
      "Tour Provider": "56aa371be4b08b9a8d573520",
      "Water Park": "4bf58dd8d48988d193941735",
      Zoo: "4bf58dd8d48988d17b941735",
      Event: "4d4b7105d754a06373d81259",
      "Christmas Market": "52f2ab2ebcbc57f1066b8b3b",
      Conference: "5267e4d9e4b0ec79466e48c6",
      Convention: "5267e4d9e4b0ec79466e48c9",
      Festival: "5267e4d9e4b0ec79466e48c7",
      "Line / Queue": "58daa1558bbb0b01f18ec1fa",
      "Music Festival": "5267e4d9e4b0ec79466e48d1",
      "Other Event": "5267e4d9e4b0ec79466e48c8",
      Parade: "52741d85e4b0d5d1e3c6a6d9",
      "Sporting Event": "5bae9231bedf3950379f89c5",
      "Stoop Sale": "52f2ab2ebcbc57f1066b8b54",
      "Street Fair": "5267e4d8e4b0ec79466e48c5",
      "Trade Fair": "5bae9231bedf3950379f89c3",
      Food: "4d4b7105d754a06374d81259",
      "Afghan Restaurant": "503288ae91d4c4b30a586d67",
      "African Restaurant": "4bf58dd8d48988d1c8941735",
      "American Restaurant": "4bf58dd8d48988d14e941735",
      "Asian Restaurant": "4bf58dd8d48988d142941735",
      "Australian Restaurant": "4bf58dd8d48988d169941735",
      "Austrian Restaurant": "52e81612bcbc57f1066b7a01",
      "BBQ Joint": "4bf58dd8d48988d1df931735",
      "Bagel Shop": "4bf58dd8d48988d179941735",
      Bakery: "4bf58dd8d48988d16a941735",
      "Bangladeshi Restaurant": "5e179ee74ae8e90006e9a746",
      "Belgian Restaurant": "52e81612bcbc57f1066b7a02",
      Bistro: "52e81612bcbc57f1066b79f1",
      "Breakfast Spot": "4bf58dd8d48988d143941735",
      "Bubble Tea Shop": "52e81612bcbc57f1066b7a0c",
      Buffet: "52e81612bcbc57f1066b79f4",
      "Burger Joint": "4bf58dd8d48988d16c941735",
      Cafeteria: "4bf58dd8d48988d128941735",
      Café: "4bf58dd8d48988d16d941735",
      "Cajun / Creole Restaurant": "4bf58dd8d48988d17a941735",
      "Caribbean Restaurant": "4bf58dd8d48988d144941735",
      "Cuban Restaurant": "4bf58dd8d48988d154941735",
      "Caucasian Restaurant": "5293a7d53cf9994f4e043a45",
      "Coffee Shop": "4bf58dd8d48988d1e0931735",
      "Comfort Food Restaurant": "52e81612bcbc57f1066b7a00",
      Creperie: "52e81612bcbc57f1066b79f2",
      "Czech Restaurant": "52f2ae52bcbc57f1066b8b81",
      "Deli / Bodega": "4bf58dd8d48988d146941735",
      "Dessert Shop": "4bf58dd8d48988d1d0941735",
      Diner: "4bf58dd8d48988d147941735",
      "Donut Shop": "4bf58dd8d48988d148941735",
      "Dumpling Restaurant": "4bf58dd8d48988d108941735",
      "Dutch Restaurant": "5744ccdfe4b0c0459246b4d0",
      "Eastern European Restaurant": "4bf58dd8d48988d109941735",
      "English Restaurant": "52e81612bcbc57f1066b7a05",
      "Falafel Restaurant": "4bf58dd8d48988d10b941735",
      "Fast Food Restaurant": "4bf58dd8d48988d16e941735",
      "Fish & Chips Shop": "4edd64a0c7ddd24ca188df1a",
      "Fondue Restaurant": "52e81612bcbc57f1066b7a09",
      "Food Court": "4bf58dd8d48988d120951735",
      "Food Stand": "56aa371be4b08b9a8d57350b",
      "Food Truck": "4bf58dd8d48988d1cb941735",
      "French Restaurant": "4bf58dd8d48988d10c941735",
      "Fried Chicken Joint": "4d4ae6fc7a7b7dea34424761",
      Friterie: "55d25775498e9f6a0816a37a",
      Gastropub: "4bf58dd8d48988d155941735",
      "German Restaurant": "4bf58dd8d48988d10d941735",
      "Gluten-free Restaurant": "4c2cd86ed066bed06c3c5209",
      "Greek Restaurant": "4bf58dd8d48988d10e941735",
      "Halal Restaurant": "52e81612bcbc57f1066b79ff",
      "Hawaiian Restaurant": "52e81612bcbc57f1066b79fe",
      "Hot Dog Joint": "4bf58dd8d48988d16f941735",
      "Hungarian Restaurant": "52e81612bcbc57f1066b79fa",
      "Indian Restaurant": "4bf58dd8d48988d10f941735",
      "Irish Pub": "52e81612bcbc57f1066b7a06",
      "Italian Restaurant": "4bf58dd8d48988d110941735",
      "Jewish Restaurant": "52e81612bcbc57f1066b79fd",
      "Juice Bar": "4bf58dd8d48988d112941735",
      "Kebab Restaurant": "5283c7b4e4b094cb91ec88d7",
      "Latin American Restaurant": "4bf58dd8d48988d1be941735",
      "Mac & Cheese Joint": "4bf58dd8d48988d1bf941735",
      "Mediterranean Restaurant": "4bf58dd8d48988d1c0941735",
      "Mexican Restaurant": "4bf58dd8d48988d1c1941735",
      "Middle Eastern Restaurant": "4bf58dd8d48988d115941735",
      "Modern European Restaurant": "52e81612bcbc57f1066b79f9",
      "Molecular Gastronomy Restaurant": "4bf58dd8d48988d1c2941735",
      "Pakistani Restaurant": "52e81612bcbc57f1066b79f8",
      "Pet Café": "56aa371be4b08b9a8d573508",
      "Pizza Place": "4bf58dd8d48988d1ca941735",
      "Polish Restaurant": "52e81612bcbc57f1066b7a04",
      "Portuguese Restaurant": "4def73e84765ae376e57713a",
      "Poutine Place": "56aa371be4b08b9a8d5734c7",
      Restaurant: "4bf58dd8d48988d1c4941735",
      "Russian Restaurant": "5293a7563cf9994f4e043a44",
      "Salad Place": "4bf58dd8d48988d1bd941735",
      "Sandwich Place": "4bf58dd8d48988d1c5941735",
      "Scandinavian Restaurant": "4bf58dd8d48988d1c6941735",
      "Scottish Restaurant": "5744ccdde4b0c0459246b4a3",
      "Seafood Restaurant": "4bf58dd8d48988d1ce941735",
      "Slovak Restaurant": "56aa371be4b08b9a8d57355a",
      "Snack Place": "4bf58dd8d48988d1c7941735",
      "Soup Place": "4bf58dd8d48988d1dd931735",
      "Southern / Soul Food Restaurant": "4bf58dd8d48988d14f941735",
      "Spanish Restaurant": "4bf58dd8d48988d150941735",
      "Sri Lankan Restaurant": "5413605de4b0ae91d18581a9",
      Steakhouse: "4bf58dd8d48988d1cc941735",
      "Swiss Restaurant": "4bf58dd8d48988d158941735",
      "Tea Room": "4bf58dd8d48988d1dc931735",
      "Theme Restaurant": "56aa371be4b08b9a8d573538",
      "Truck Stop": "57558b36e4b065ecebd306dd",
      "Turkish Restaurant": "4f04af1f2fb6e1c99f3db0bb",
      "Ukrainian Restaurant": "52e928d0bcbc57f1066b7e96",
      "Vegetarian / Vegan Restaurant": "4bf58dd8d48988d1d3941735",
      "Wings Joint": "4bf58dd8d48988d14c941735",
      "Nightlife Spot": "4d4b7105d754a06376d81259",
      Bar: "4bf58dd8d48988d116941735",
      Brewery: "50327c8591d4c4b30a586d5d",
      Lounge: "4bf58dd8d48988d121941735",
      "Night Market": "53e510b7498ebcb1801b55d4",
      Nightclub: "4bf58dd8d48988d11f941735",
      "Other Nightlife": "4bf58dd8d48988d11a941735",
      "Strip Club": "4bf58dd8d48988d1d6941735",
      "Outdoors & Recreation": "4d4b7105d754a06377d81259",
      "Athletics & Sports": "4f4528bc4b90abdf24c9de85",
      "Bathing Area": "52e81612bcbc57f1066b7a28",
      Bay: "56aa371be4b08b9a8d573544",
      Beach: "4bf58dd8d48988d1e2941735",
      "Bike Trail": "56aa371be4b08b9a8d57355e",
      "Botanical Garden": "52e81612bcbc57f1066b7a22",
      Bridge: "4bf58dd8d48988d1df941735",
      Campground: "4bf58dd8d48988d1e4941735",
      "Canal Lock": "56aa371be4b08b9a8d57353b",
      Canal: "56aa371be4b08b9a8d573562",
      Castle: "50aaa49e4b90af0d42d5de11",
      Cave: "56aa371be4b08b9a8d573511",
      Cemetery: "4bf58dd8d48988d15c941735",
      "Dive Spot": "52e81612bcbc57f1066b7a12",
      "Dog Run": "4bf58dd8d48988d1e5941735",
      Farm: "4bf58dd8d48988d15b941735",
      Field: "4bf58dd8d48988d15f941735",
      "Fishing Spot": "52e81612bcbc57f1066b7a0f",
      Forest: "52e81612bcbc57f1066b7a23",
      Fountain: "56aa371be4b08b9a8d573547",
      Garden: "4bf58dd8d48988d15a941735",
      "Gun Range": "52e81612bcbc57f1066b7a11",
      "Harbor / Marina": "4bf58dd8d48988d1e0941735",
      Hill: "5bae9231bedf3950379f89cd",
      "Hot Spring": "4bf58dd8d48988d160941735",
      "Indoor Play Area": "5744ccdfe4b0c0459246b4b5",
      Island: "50aaa4314b90af0d42d5de10",
      Lake: "4bf58dd8d48988d161941735",
      Lighthouse: "4bf58dd8d48988d15d941735",
      "Mountain Hut": "55a5a1ebe4b013909087cb77",
      Mountain: "4eb1d4d54b900d56c88a45fc",
      "National Park": "52e81612bcbc57f1066b7a21",
      "Nature Preserve": "52e81612bcbc57f1066b7a13",
      "Other Great Outdoors": "4bf58dd8d48988d162941735",
      Palace: "52e81612bcbc57f1066b7a14",
      Park: "4bf58dd8d48988d163941735",
      "Pedestrian Plaza": "52e81612bcbc57f1066b7a25",
      Playground: "4bf58dd8d48988d1e7941735",
      Plaza: "4bf58dd8d48988d164941735",
      Pool: "4bf58dd8d48988d15e941735",
      Rafting: "52e81612bcbc57f1066b7a29",
      "Recreation Center": "52e81612bcbc57f1066b7a26",
      Reservoir: "56aa371be4b08b9a8d573541",
      River: "4eb1d4dd4b900d56c88a45fd",
      "Rock Climbing Spot": "50328a4b91d4c4b30a586d6b",
      "Roof Deck": "4bf58dd8d48988d133951735",
      "Scenic Lookout": "4bf58dd8d48988d165941735",
      "Sculpture Garden": "4bf58dd8d48988d166941735",
      "Ski Area": "4bf58dd8d48988d1e9941735",
      "Skydiving Drop Zone": "58daa1558bbb0b01f18ec1b9",
      Stables: "4eb1baf03b7b2c5b1d4306ca",
      "State / Provincial Park": "5bae9231bedf3950379f89d0",
      "States & Municipalities": "530e33ccbcbc57f1066bbfe4",
      "Summer Camp": "52e81612bcbc57f1066b7a10",
      Trail: "4bf58dd8d48988d159941735",
      Tree: "52e81612bcbc57f1066b7a24",
      Vineyard: "4bf58dd8d48988d1de941735",
      Volcano: "5032848691d4c4b30a586d61",
      Waterfall: "56aa371be4b08b9a8d573560",
      Waterfront: "56aa371be4b08b9a8d5734c3",
      Well: "4fbc1be21983fc883593e321",
      Windmill: "5bae9231bedf3950379f89c7",
      "Professional & Other Places": "4d4b7105d754a06375d81259",
      "Animal Shelter": "4e52d2d203646f7c19daa8ae",
      "Art Studio": "58daa1558bbb0b01f18ec1d6",
      Auditorium: "4bf58dd8d48988d173941735",
      Ballroom: "56aa371be4b08b9a8d5734cf",
      Building: "4bf58dd8d48988d130941735",
      "Business Center": "56aa371be4b08b9a8d573517",
      Cidery: "5e189fd6eee47d000759bbfd",
      "Club House": "52e81612bcbc57f1066b7a35",
      "Community Center": "52e81612bcbc57f1066b7a34",
      "Convention Center": "4bf58dd8d48988d1ff931735",
      "Meeting Room": "4bf58dd8d48988d100941735",
      "Cultural Center": "52e81612bcbc57f1066b7a32",
      Distillery: "4e0e22f5a56208c4ea9a85a0",
      "Distribution Center": "52e81612bcbc57f1066b7a37",
      "Event Space": "4bf58dd8d48988d171941735",
      Factory: "4eb1bea83b7b6f98df247e06",
      Fair: "4eb1daf44b900d56c88a4600",
      "Funeral Home": "4f4534884b9074f6e4fb0174",
      "Government Building": "4bf58dd8d48988d126941735",
      "Industrial Estate": "56aa371be4b08b9a8d5734d7",
      Laboratory: "5744ccdfe4b0c0459246b4d6",
      Library: "4bf58dd8d48988d12f941735",
      Meadery: "5e189d71eee47d000759b7e2",
      "Medical Center": "4bf58dd8d48988d104941735",
      "Military Base": "4e52adeebd41615f56317744",
      "Non-Profit": "50328a8e91d4c4b30a586d6c",
      Observatory: "5744ccdfe4b0c0459246b4d9",
      Office: "4bf58dd8d48988d124941735",
      Parking: "4c38df4de52ce0d596b336e1",
      "Post Office": "4bf58dd8d48988d172941735",
      "Power Plant": "58daa1548bbb0b01f18ec1a9",
      Prison: "5310b8e5bcbc57f1066bcbf1",
      "Radio Station": "5032856091d4c4b30a586d63",
      "Recruiting Agency": "52f2ab2ebcbc57f1066b8b57",
      "Research Station": "58daa1558bbb0b01f18ec1b2",
      School: "4bf58dd8d48988d13b941735",
      "Social Club": "52e81612bcbc57f1066b7a33",
      "Spiritual Center": "4bf58dd8d48988d131941735",
      "TV Station": "52e81612bcbc57f1066b7a31",
      "Voting Booth": "4cae28ecbf23941eb1190695",
      Warehouse: "52e81612bcbc57f1066b7a36",
      "Waste Facility": "58daa1558bbb0b01f18ec1ac",
      "Wedding Hall": "56aa371be4b08b9a8d5734c5",
      Winery: "4bf58dd8d48988d14b941735",
      "Assisted Living": "5032891291d4c4b30a586d68",
      "Shop & Service": "4d4b7105d754a06378d81259",
      ATM: "52f2ab2ebcbc57f1066b8b56",
      "Adult Boutique": "5267e446e4b0ec79466e48c4",
      "Antique Shop": "4bf58dd8d48988d116951735",
      "Arts & Crafts Store": "4bf58dd8d48988d127951735",
      Astrologer: "52f2ab2ebcbc57f1066b8b43",
      "Auto Dealership": "4eb1c1623b7b52c0e1adc2ec",
      "Auto Garage": "52f2ab2ebcbc57f1066b8b44",
      "Auto Workshop": "56aa371be4b08b9a8d5734d3",
      "Automotive Shop": "4bf58dd8d48988d124951735",
      "Baby Store": "52f2ab2ebcbc57f1066b8b32",
      Bank: "4bf58dd8d48988d10a951735",
      "Bath House": "52e81612bcbc57f1066b7a27",
      "Batik Shop": "56aa371be4b08b9a8d5734cb",
      "Betting Shop": "52f2ab2ebcbc57f1066b8b40",
      "Big Box Store": "52f2ab2ebcbc57f1066b8b42",
      "Bike Shop": "4bf58dd8d48988d115951735",
      "Board Shop": "4bf58dd8d48988d1f1941735",
      Bookstore: "4bf58dd8d48988d114951735",
      "Bridal Shop": "4bf58dd8d48988d11a951735",
      "Business Service": "5453de49498eade8af355881",
      "Camera Store": "4eb1bdf03b7b55596b4a7491",
      "Candy Store": "4bf58dd8d48988d117951735",
      "Car Wash": "4f04ae1f2fb6e1c99f3db0ba",
      "Carpet Store": "52f2ab2ebcbc57f1066b8b2a",
      "Check Cashing Service": "52f2ab2ebcbc57f1066b8b2d",
      "Child Care Service": "5744ccdfe4b0c0459246b4c7",
      "Chocolate Shop": "52f2ab2ebcbc57f1066b8b31",
      "Clothing Store": "4bf58dd8d48988d103951735",
      "Comic Shop": "52f2ab2ebcbc57f1066b8b18",
      "Construction & Landscaping": "5454144b498ec1f095bff2f2",
      "Convenience Store": "4d954b0ea243a5684a65b473",
      "Cosmetics Shop": "4bf58dd8d48988d10c951735",
      "Costume Shop": "52f2ab2ebcbc57f1066b8b17",
      "Credit Union": "5032850891d4c4b30a586d62",
      "Currency Exchange": "5744ccdfe4b0c0459246b4be",
      "Department Store": "4bf58dd8d48988d1f6941735",
      "Design Studio": "4bf58dd8d48988d1f4941735",
      "Discount Store": "52dea92d3cf9994f4e043dbb",
      "Dive Shop": "52f2ab2ebcbc57f1066b8b1a",
      Drugstore: "5745c2e4498e11e7bccabdbd",
      "Dry Cleaner": "52f2ab2ebcbc57f1066b8b1d",
      "EV Charging Station": "5032872391d4c4b30a586d64",
      "Electronics Store": "4bf58dd8d48988d122951735",
      "Entertainment Service": "56aa371be4b08b9a8d573554",
      "Event Service": "5454152e498ef71e2b9132c6",
      "Fabric Shop": "52f2ab2ebcbc57f1066b8b26",
      "Film Studio": "56aa371be4b08b9a8d573523",
      "Financial or Legal Service": "503287a291d4c4b30a586d65",
      "Fireworks Store": "52f2ab2ebcbc57f1066b8b3a",
      "Fishing Store": "52f2ab2ebcbc57f1066b8b16",
      "Flea Market": "4bf58dd8d48988d1f7941735",
      "Floating Market": "56aa371be4b08b9a8d573505",
      "Flower Shop": "4bf58dd8d48988d11b951735",
      "Food & Drink Shop": "4bf58dd8d48988d1f9941735",
      "Frame Store": "52f2ab2ebcbc57f1066b8b24",
      "Fruit & Vegetable Store": "52f2ab2ebcbc57f1066b8b1c",
      "Furniture / Home Store": "4bf58dd8d48988d1f8941735",
      "Gaming Cafe": "4bf58dd8d48988d18d941735",
      "Garden Center": "4eb1c0253b7b52c0e1adc2e9",
      "Gas Station": "4bf58dd8d48988d113951735",
      "Gift Shop": "4bf58dd8d48988d128951735",
      "Gun Shop": "52f2ab2ebcbc57f1066b8b19",
      "Hardware Store": "4bf58dd8d48988d112951735",
      "Health & Beauty Service": "54541900498ea6ccd0202697",
      "Herbs & Spices Store": "52f2ab2ebcbc57f1066b8b2c",
      "Hobby Shop": "4bf58dd8d48988d1fb941735",
      "Home Service": "545419b1498ea6ccd0202f58",
      "Hunting Supply": "50aaa5234b90af0d42d5de12",
      "IT Services": "52f2ab2ebcbc57f1066b8b36",
      "Insurance Office": "58daa1558bbb0b01f18ec1f1",
      "Internet Cafe": "4bf58dd8d48988d1f0941735",
      "Jewelry Store": "4bf58dd8d48988d111951735",
      "Kitchen Supply Store": "58daa1558bbb0b01f18ec1b4",
      "Knitting Store": "52f2ab2ebcbc57f1066b8b25",
      Laundromat: "52f2ab2ebcbc57f1066b8b33",
      "Laundry Service": "4bf58dd8d48988d1fc941735",
      Lawyer: "52f2ab2ebcbc57f1066b8b3f",
      "Leather Goods Store": "52f2ab2ebcbc57f1066b8b2b",
      Locksmith: "52f2ab2ebcbc57f1066b8b1e",
      "Lottery Retailer": "52f2ab2ebcbc57f1066b8b38",
      "Luggage Store": "52f2ab2ebcbc57f1066b8b29",
      "Marijuana Dispensary": "52c71aaf3cf9994f4e043d17",
      Market: "50be8ee891d4fa8dcc7199a7",
      "Massage Studio": "52f2ab2ebcbc57f1066b8b3c",
      "Mattress Store": "52f2ab2ebcbc57f1066b8b27",
      "Medical Supply Store": "58daa1558bbb0b01f18ec206",
      "Miscellaneous Shop": "4bf58dd8d48988d1ff941735",
      "Mobile Phone Shop": "4f04afc02fb6e1c99f3db0bc",
      "Mobility Store": "56aa371be4b08b9a8d57354a",
      "Motorcycle Shop": "5032833091d4c4b30a586d60",
      "Motorsports Shop": "59d79d6b2e268052fa2a3332",
      "Music Store": "4bf58dd8d48988d1fe941735",
      "Nail Salon": "4f04aa0c2fb6e1c99f3db0b8",
      Newsstand: "4f04ad622fb6e1c99f3db0b9",
      Notary: "5ae95d208a6f17002ce792b2",
      "Optical Shop": "4d954afda243a5684865b473",
      "Other Repair Shop": "52f2ab2ebcbc57f1066b8b2f",
      "Outdoor Supply Store": "52f2ab2ebcbc57f1066b8b22",
      "Outlet Mall": "5744ccdfe4b0c0459246b4df",
      "Outlet Store": "52f2ab2ebcbc57f1066b8b35",
      "Paper / Office Supplies Store": "4bf58dd8d48988d121951735",
      "Pawn Shop": "52f2ab2ebcbc57f1066b8b34",
      "Perfume Shop": "52f2ab2ebcbc57f1066b8b23",
      "Pet Service": "5032897c91d4c4b30a586d69",
      "Pet Store": "4bf58dd8d48988d100951735",
      Pharmacy: "4bf58dd8d48988d10f951735",
      "Photography Lab": "4eb1bdde3b7b55596b4a7490",
      "Photography Studio": "554a5e17498efabeda6cc559",
      "Piercing Parlor": "52f2ab2ebcbc57f1066b8b20",
      "Pop-Up Shop": "52f2ab2ebcbc57f1066b8b3d",
      "Print Shop": "52f2ab2ebcbc57f1066b8b28",
      "Public Bathroom": "5744ccdfe4b0c0459246b4c4",
      "Real Estate Office": "5032885091d4c4b30a586d66",
      "Record Shop": "4bf58dd8d48988d10d951735",
      "Recording Studio": "52f2ab2ebcbc57f1066b8b37",
      "Recycling Facility": "4f4531084b9074f6e4fb0101",
      "Rental Service": "56aa371be4b08b9a8d573552",
      "Salon / Barbershop": "4bf58dd8d48988d110951735",
      "Sauna / Steam Room": "58daa1558bbb0b01f18ec1ae",
      "Shipping Store": "52f2ab2ebcbc57f1066b8b1f",
      "Shoe Repair": "52f2ab2ebcbc57f1066b8b39",
      "Shopping Mall": "4bf58dd8d48988d1fd941735",
      "Shopping Plaza": "5744ccdfe4b0c0459246b4dc",
      "Skate Shop": "5bae9231bedf3950379f89d2",
      "Ski Shop": "56aa371be4b08b9a8d573566",
      "Smoke Shop": "4bf58dd8d48988d123951735",
      "Smoothie Shop": "52f2ab2ebcbc57f1066b8b41",
      "Souvenir Shop": "52f2ab2ebcbc57f1066b8b1b",
      Spa: "4bf58dd8d48988d1ed941735",
      "Sporting Goods Shop": "4bf58dd8d48988d1f2941735",
      "Stationery Store": "52f2ab2ebcbc57f1066b8b21",
      "Storage Facility": "4f04b1572fb6e1c99f3db0bf",
      "Supplement Shop": "5744ccdfe4b0c0459246b4cd",
      "Tailor Shop": "5032781d91d4c4b30a586d5b",
      "Tanning Salon": "4d1cf8421a97d635ce361c31",
      "Tattoo Parlor": "4bf58dd8d48988d1de931735",
      "Thrift / Vintage Store": "4bf58dd8d48988d101951735",
      "Toy / Game Store": "4bf58dd8d48988d1f3941735",
      "Travel Agency": "4f04b08c2fb6e1c99f3db0bd",
      "Used Bookstore": "52f2ab2ebcbc57f1066b8b30",
      "Vape Store": "56aa371be4b08b9a8d57355c",
      "Video Game Store": "4bf58dd8d48988d10b951735",
      "Video Store": "4bf58dd8d48988d126951735",
      "Warehouse Store": "52e816a6bcbc57f1066b7a54",
      "Watch Shop": "52f2ab2ebcbc57f1066b8b2e",
      "Travel & Transport": "4d4b7105d754a06379d81259",
      Airport: "4bf58dd8d48988d1ed931735",
      "Baggage Locker": "5744ccdfe4b0c0459246b4e8",
      "Bike Rental / Bike Share": "4e4c9077bd41f78e849722f9",
      "Boat Rental": "5744ccdfe4b0c0459246b4c1",
      "Boat or Ferry": "4bf58dd8d48988d12d951735",
      "Border Crossing": "52f2ab2ebcbc57f1066b8b4b",
      "Bus Station": "4bf58dd8d48988d1fe931735",
      "Bus Stop": "52f2ab2ebcbc57f1066b8b4f",
      "Cable Car": "52f2ab2ebcbc57f1066b8b50",
      Cruise: "55077a22498e5e9248869ba2",
      "Duty-free Shop": "589ddde98ae3635c072819ee",
      "General Travel": "4bf58dd8d48988d1f6931735",
      Heliport: "56aa371ce4b08b9a8d57356e",
      Hotel: "4bf58dd8d48988d1fa931735",
      Intersection: "52f2ab2ebcbc57f1066b8b4c",
      "Light Rail Station": "4bf58dd8d48988d1fc931735",
      "Metro Station": "4bf58dd8d48988d1fd931735",
      "Moving Target": "4f2a23984b9023bd5841ed2c",
      Pier: "4e74f6cabd41c4836eac4c31",
      Port: "56aa371be4b08b9a8d57353e",
      "RV Park": "52f2ab2ebcbc57f1066b8b53",
      "Rental Car Location": "4bf58dd8d48988d1ef941735",
      "Rest Area": "4d954b16a243a5684b65b473",
      Road: "4bf58dd8d48988d1f9931735",
      "Taxi Stand": "53fca564498e1a175f32528b",
      Taxi: "4bf58dd8d48988d130951735",
      "Toll Booth": "52f2ab2ebcbc57f1066b8b4d",
      "Toll Plaza": "52f2ab2ebcbc57f1066b8b4e",
      "Tourist Information Center": "4f4530164b9074f6e4fb00ff",
      "Train Station": "4bf58dd8d48988d129951735",
      "Tram Station": "52f2ab2ebcbc57f1066b8b51",
      "Transportation Service": "54541b70498ea6ccd0204bff",
      "Travel Lounge": "4f04b25d2fb6e1c99f3db0c0",
      Tunnel: "52f2ab2ebcbc57f1066b8b4a",
      "Gym / Fitness Center": "4bf58dd8d48988d175941735",
      Hospital: "4bf58dd8d48988d196941735",
    },
    poiCategories: [],

    poi: [],
    poiList: [],
    poii: [],
    answersPrefer: [],
    keyWordsArray: [],
    activitiesArray: [],
    activeMaker: null,
    activeNumber: -1,
    propertyInfo: false,
  });
  const [map, setMap] = useState(null);
  const [currentPopup, setCurrentPopup] = useState(null);

  useEffect(() => {
    setMapState((prev) => ({ ...prev, data: poiData }));
    async function fetchData() {
      getQuestionsData();
      tab();
    }

    fetchData();
  }, []);

  const insertMap = (markersData, selectedCategory) => {
    if (map) {
      const geoJsonData = {
        type: "FeatureCollection",
        features: markersData.map((data) => ({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              data.geocodes.main.longitude,
              data.geocodes.main.latitude,
            ],
          },
          properties: {
            name: data.name,
            lat: data.geocodes.main.latitude,
            lng: data.geocodes.main.longitude,
            vicinity: data.location.formatted_address.toString(),
            place_id: data.fsq_id,
          },
        })),
      };

      // Ensure the map source exists before setting the data
      if (map.getSource("markers")) {
        map.getSource("markers").setData(geoJsonData); // Update markers source without reloading map
      } else {
        console.error("Markers source not found on the map.");
      }

      return; // Skip map creation
    }

    const mapInstance = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/examples/clg45vm7400c501pfubolb0xz",
      center:
        poiData.latitude !== 0 &&
        poiData.latitude !== null &&
        poiData.longitude !== 0 &&
        poiData.longitude !== null
          ? {
              lat: poiData.latitude,
              lng: poiData.longitude,
            }
          : { lat: 37.7739852, lng: -122.3912407 }, // Initial map center [lng, lat]
      zoom: 13,
    });

    setMap(mapInstance);
    const nav = new mapboxgl.NavigationControl({
      showZoom: true,
    });
    mapInstance.addControl(nav, "bottom-left");

    // Initialize Mapbox Draw
    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
      defaultMode: "simple_select",
    });
    mapInstance.addControl(draw);

    let allMarkers = []; // Array to store all markers
    let drawnShapes = []; // Store drawn shapes
    const defaultMarkersData = markersData;
    let newMarkers = [];

    const geoJsonData = {
      type: "FeatureCollection",
      features: markersData.map((data) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            data.geocodes.main.longitude,
            data.geocodes.main.latitude,
          ],
        },
        properties: {
          name: data.name,
          lat: data.geocodes.main.latitude,
          lng: data.geocodes.main.longitude,
          vicinity: data.location.formatted_address.toString(),
          place_id: data.fsq_id,
          // rating: data.rating,
          // price_level: data.price_level,
          // icon: data.icon,
        },
      })),
    };
    allMarkers = geoJsonData;

    let selectedFeatures = { features: [] };
    mapInstance.on("draw.selectionchange", (e) => {
      selectedFeatures = draw.getSelected();
      // console.log("Selection changed:", selectedFeatures);
    });

    const trashButton = document.querySelector(".mapbox-gl-draw_trash");
    if (trashButton) {
      trashButton.addEventListener(
        "click",
        () => {
          const allFeatures = draw.getAll();
          // console.log("Selected features:", selectedFeatures);
          // console.log("All features:", allFeatures, selectedFeatures);

          if (allFeatures.features.length === 0) {
            selectedFeatures = { features: [] }; // Reset selected features
            return;
          }

          if (selectedFeatures.features.length === 0) {
            alert("Please select the boundary you would like to delete.");
            return;
          }

          // Delete selected features and clear selectedFeatures
          draw.delete(selectedFeatures.features.map((f) => f.id));
          selectedFeatures = { features: [] }; // Reset after deletion
        },
        true
      );
    }

    mapInstance.on("load", () => {
      mapInstance.addSource("markers", {
        type: "geojson",
        data: geoJsonData, // Your GeoJSON data
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 50, // Radius of each cluster when clustering points
      });

      // Add cluster circle layer
      mapInstance.addLayer({
        id: "clusters",
        type: "circle",
        source: "markers",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#000000", // color for small clusters
            100,
            "#000000", // color for medium clusters
            750,
            "#000000", // color for large clusters
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            20, // radius for small clusters
            100,
            30, // radius for medium clusters
            750,
            40, // radius for large clusters
          ],
        },
      });

      // Add a label to show the number of points in each cluster
      mapInstance.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "markers",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Bold"],
          "text-size": 15,
        },
        paint: {
          "text-color": "#FFFFFF", // Set text color to white
        },
      });

      // Add unclustered points as markers
      mapInstance.addLayer({
        id: "unclustered-point",
        type: "symbol",
        source: "markers",
        filter: ["!", ["has", "point_count"]],
        layout: {
          "icon-image": "custom-marker", // Use your custom marker image here
          "icon-size": 0.8,
        },
      });

      // Add custom image for the unclustered markers
      mapInstance.loadImage(
        "/assets/images/location_pin_2.png",
        (error, image) => {
          if (error) throw error;
          mapInstance.addImage("custom-marker", image);
        }
      );

      // When a cluster is clicked, zoom in
      mapInstance.on("click", "clusters", (e) => {
        const features = mapInstance.queryRenderedFeatures(e.point, {
          layers: ["clusters"],
        });
        const clusterId = features[0].properties.cluster_id;
        mapInstance
          .getSource("markers")
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            mapInstance.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
      });

      // Show popup for unclustered points
      mapInstance.on("click", "unclustered-point", (ele) => {
        const coordinates = ele.features[0].geometry.coordinates.slice();
        const { name, vicinity, place_id, lat, lng } =
          ele.features[0].properties;

        // Create and display the popup
        const popup = new mapboxgl.Popup({ offset: [0, -15] })
          .setLngLat(coordinates)
          .setHTML(
            `
            <h6>${name}</h6>
            <p>${vicinity}</p>
            <a href="https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}" target="_blank">Get Directions</a>
          `
          )
          .addTo(mapInstance);

        setCurrentPopup(popup);
        setMapState((prev) => ({
          ...prev,
          activeNumber: place_id,
          data: {
            latitude: coordinates[1],
            longitude: coordinates[0],
          },
        }));
      });

      // Change the cursor to a pointer when hovering over clusters
      mapInstance.on("mouseenter", "clusters", () => {
        mapInstance.getCanvas().style.cursor = "pointer";
      });
      mapInstance.on("mouseleave", "clusters", () => {
        mapInstance.getCanvas().style.cursor = "";
      });

      // Draw functionality: add polygons and filter markers
      mapInstance.on("draw.create", async (event) => {
        const shape = event.features[0];
        drawnShapes.push(shape); // Add new shape to the array

        const coordinates = shape.geometry.coordinates[0];
        const coordinatesString = `${coordinates
          .map((coord) => `${coord[1]},${coord[0]}`) // Format: "latitude,longitude"
          .join("~")}`;
        let apiObject = {
          polygon: coordinatesString,
          categories: mapState.poiCategoriesIds[selectedCategory],
        };

        const newAPiCall = await fourSquarePlaces.getFourSquarePlaces(
          apiObject
        );
        setMapState((prev) => ({
          ...prev,
          activeName: "",
        }));
        addNewMarkersToMap(newAPiCall, mapInstance, shape);
        // draw.changeMode("simple_select");
      });

      mapInstance.on("draw.delete", (event) => {
        console.log("hello delete");

        const deletedFeatures = event.features; // Get deleted shapes
        const existingMarkers = mapInstance.getSource("markers")._data; // Get existing markers data

        // Create a new array to hold the markers that will be kept
        const markersToKeep = existingMarkers.features.filter((data) => {
          const markerPosition = [
            data.geometry.coordinates[0],
            data.geometry.coordinates[1],
          ];

          // Check if the marker is within any of the deleted shapes
          return !deletedFeatures.some((deletedShape) => {
            if (deletedShape.geometry.type === "Polygon") {
              return turf.booleanPointInPolygon(
                turf.point(markerPosition),
                turf.polygon(deletedShape.geometry.coordinates)
              );
            }
            return false; // Non-polygon shapes shouldn't be processed
          });
        });

        // Update existingMarkers to only include markers that are not deleted
        existingMarkers.features = markersToKeep;
        const outputData = transformData(markersToKeep);
        updateMarkerList(outputData);
        // Update the markers source with the remaining markers
        mapInstance.getSource("markers").setData(existingMarkers);

        // Remove deleted shapes from the localdrawnShapes array
        drawnShapes = drawnShapes.filter(
          (shape) => !deletedFeatures.some((deleted) => deleted.id === shape.id)
        );

        // Restore the markers if there are no drawn shapes left
        if (drawnShapes.length === 0) {
          updateMarkerList(defaultMarkersData);
          // Restore all original markers
          mapInstance.getSource("markers").setData(allMarkers);
          newMarkers = [];
        }
      });
    });

    const addNewMarkersToMap = async (
      newMarkersData,
      mapInstance,
      drawnPolygon
    ) => {
      // Clear existing markers before adding new ones
      const apiMarkersGeoJson = {
        type: "FeatureCollection",
        features: [], // Initialize with an empty feature collection
      };

      // Filter new markers based on whether they are inside the drawn shape
      const filteredMarkers = newMarkersData.filter((position) => {
        const markerPosition = [
          position.geocodes.main.longitude,
          position.geocodes.main.latitude,
        ];

        // Use Turf.js to check if the marker is within the drawn polygon
        return turf.booleanPointInPolygon(
          turf.point(markerPosition),
          turf.polygon(drawnPolygon.geometry.coordinates)
        );
      });
      newMarkers.push(filteredMarkers);
      const flattenedData = await flattenArray(newMarkers);

      // Convert filtered markers to GeoJSON format
      flattenedData.forEach((data) => {
        apiMarkersGeoJson.features.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              data.geocodes.main.longitude,
              data.geocodes.main.latitude,
            ],
          },
          properties: {
            name: data.name,
            lat: data.geocodes.main.latitude,
            lng: data.geocodes.main.longitude,
            vicinity: data.location.formatted_address.toString(),
            place_id: data.fsq_id,
          },
        });
      });
      const outputData = transformData(apiMarkersGeoJson.features);
      updateMarkerList(outputData);

      // Check if markers source exists before updating
      if (mapInstance.getSource("markers")) {
        mapInstance.getSource("markers").setData(apiMarkersGeoJson);
      } else {
        console.error("Markers source not found on the map.");
      }
    };
  };

  const transformData = (data) => {
    return data.map((item) => ({
      fsq_id: item.properties.place_id,
      geocodes: {
        main: {
          latitude: item.properties.lat,
          longitude: item.properties.lng,
        },
        roof: {
          latitude: item.properties.lat,
          longitude: item.properties.lng,
        },
      },
      location: {
        formatted_address: item.properties.vicinity,
        locality: item.properties.vicinity.split(",")[1]?.trim(), // Extract city
      },
      name: item.properties.name,
    }));
  };

  const updateMarkerList = (filteredMarkers) => {
    setMapState((prev) => ({
      ...prev,

      poi: filteredMarkers,
    }));
  };

  // Logic to flatten the array of arrays into a single array of objects
  const flattenArray = (nestedArray) => {
    // Use reduce to combine all sub-arrays into a single array
    return nestedArray.reduce((acc, currentArray) => {
      return acc.concat(currentArray);
    }, []);
  };

  const getQuestionsData = async () => {
    let myPropertyDetails = JSON.parse(
      await localStorage.getItem("myPropertyDetails")
    );
    let getSessionData = await LoginStore.getLoginInfo({});
    let userObject = {
      transfereeuniqeuid:
        getSessionData.hasOwnProperty("istemporary") &&
        getSessionData.istemporary !== ""
          ? getSessionData.userUniqueId
          : myPropertyDetails.transfereeUId,
      istemporary:
        getSessionData.hasOwnProperty("istemporary") &&
        getSessionData.istemporary !== ""
          ? getSessionData.istemporary
          : false,
    };
    let getQuestionList = await ApiServices.GetPreferencesList(userObject);
    let questionList = getQuestionList.message.preferencesList;
    let keyWordsArray = _.filter(questionList, function (o) {
      return o.questionlabel == "KEYWORDS";
    });
    let activitiesArray = _.filter(questionList, function (o) {
      return o.questionlabel == "ACTIVITIES";
    });
    setMapState((prev) => ({
      ...prev,
      keyWordsArray: keyWordsArray,
      activitiesArray: activitiesArray,
    }));
    getPreferencesAnswers(
      getQuestionList,
      keyWordsArray,
      activitiesArray,
      myPropertyDetails
    );
  };

  const getPreferencesAnswers = async (
    getQuestionList,
    keyWordsArray,
    activitiesArray,
    myPropertyDetails
  ) => {
    let getSessionData = await LoginStore.getLoginInfo({});
    let userPreferanceObject = {
      transfereeuniqeuid:
        getSessionData.hasOwnProperty("istemporary") &&
        getSessionData.istemporary !== ""
          ? getSessionData.userUniqueId
          : myPropertyDetails.transfereeUId,
      istemporary:
        getSessionData.hasOwnProperty("istemporary") &&
        getSessionData.istemporary !== ""
          ? getSessionData.istemporary
          : false,
    };
    let userSavedPreferances = await ApiServices.GetTransfereePreferences(
      userPreferanceObject
    );
    if (userSavedPreferances.error == 0) {
      let userAnswerPreferanceObject = userSavedPreferances.message.preferences;
      if (userAnswerPreferanceObject != undefined) {
        let answersPrefer = {};
        if (userAnswerPreferanceObject.length > 0) {
          _.forEach(userAnswerPreferanceObject, function (answerObject) {
            answersPrefer[answerObject.preferencesmasteruniqueid] =
              answerObject;
          });
          let sampleAnswerArray = _.filter(
            getQuestionList.message.preferencesList,
            function (o) {
              return answersPrefer[o.preferenceUId] == undefined;
            }
          );
          if (sampleAnswerArray.length > 0) {
            let obj = sampleAnswerArray[0];
            obj.preferencesanswer = "";
            answersPrefer[sampleAnswerArray[0].preferenceUId] = obj;
          }
          setMapState((prev) => ({ ...prev, answersPrefer: answersPrefer }));
        } else {
          _.forEach(
            getQuestionList.message.preferencesList,
            function (answerObject) {
              let answerDefaultVal = "";
              if (answerObject.type == "money-range") {
                answerDefaultVal = "500, 1000";
              }
              if (answerObject.type == "Time-range") {
                answerDefaultVal = "15, 30";
              }
              answerObject.preferencesanswer = answerDefaultVal;
              answerObject.transfereeuniqeuid = getSessionData.userUniqueId;
              answerObject.preferencesmasteruniqueid =
                answerObject.preferenceUId;
              answersPrefer[answerObject.preferenceUId] = answerObject;
            }
          );
          setMapState((prev) => ({ ...prev, answersPrefer: answersPrefer }));
        }
        getAnsCategories(keyWordsArray, activitiesArray, answersPrefer);
      }
    }
  };

  const getAnsCategories = async (
    keyWordsArray,
    activitiesArray,
    answersPrefer
  ) => {
    let ansCategories = [];
    ansCategories =
      answersPrefer[keyWordsArray[0].preferenceUId].preferencesanswer.split(
        ","
      );
    ansCategories = ansCategories.concat(
      answersPrefer[activitiesArray[0].preferenceUId].preferencesanswer.split(
        ","
      )
    );
    let cat = mapState.OriginalCategories.filter((x) =>
      ansCategories.includes(x)
    );
    let sortvalues = _.sortBy(cat, [
      function (o) {
        return o;
      },
    ]);
    if (cat.length > 0) {
      setMapState((prev) => ({
        ...prev,
        poiCategories: cat,
        selectedCategory: sortvalues[0],
      }));
      onChangeCategory(sortvalues[0]);
    } else {
      setMapState((prev) => ({
        ...prev,
        poiCategories: mapState.OriginalCategories,
      }));
      if (mapState.OriginalCategories.length > 0)
        onChangeCategory(
          _.sortBy(mapState.OriginalCategories, [
            function (o) {
              return o;
            },
          ])[0]
        );
    }
  };

  const closePopupIfOpen = () => {
    const popupElement = document.querySelector(".mapboxgl-popup");
    if (popupElement) {
      popupElement.parentNode.removeChild(popupElement);
      setCurrentPopup(null); // Reset the state if you're tracking it
    } else {
      console.log("No popup is currently open.");
    }
  };

  const onChangeCategory = async (event) => {
    closePopupIfOpen();
    // Check if the selected category is valid
    if (!event) {
      setMapState((prev) => ({
        ...prev,
        selectedCategory: "",
        poi: [],
        data: poiData,
      }));
      insertMap([], "");
      return; // Early return if "Please Select" is chosen
    }
    setMapState((prev) => ({
      ...prev,
      selectedCategory: event,
      poi: [],
      activeNumber: "",
    }));
    props.loadSchoolMarkers(mapState.poiCategoriesIds[event]);

    let apiObject = {
      latitude: poiData.latitude,
      longitude: poiData.longitude,
      radius: 8000,
    };
    let GetNearPlaces = await AppController.fourSquarData(
      apiObject,
      mapState.poiCategoriesIds[event]
    );

    setMapState((prev) => ({
      ...prev,
      selectedCategory: event,
      poi: GetNearPlaces,
      data: poiData,
    }));
    // console.log(GetNearPlaces, "GetNearPlaces");

    insertMap(GetNearPlaces, event);
  };

  const handleMarkerInfo = (data) => {
    if (!map) {
      console.error("Map instance is not available.");
      return;
    }
    setMapState((prev) => ({
      ...prev,
      activeMaker: data,
      activeNumber: data.fsq_id,
      data: {
        latitude: data.geocodes.main.latitude,
        longitude: data.geocodes.main.longitude,
      },
    }));
    const { name, vicinity, geometry } = data; // Extract relevant info from row
    map.flyTo({
      center: [data.geocodes.main.longitude, data.geocodes.main.latitude], // Update coordinates to your data
      zoom: 13, // Adjust zoom level as needed
    });
    if (currentPopup) {
      currentPopup.remove();
    }

    // Create a popup with the information
    const popup = new mapboxgl.Popup()
      .setLngLat([data.geocodes.main.longitude, data.geocodes.main.latitude])
      .setHTML(
        ` <h6>${data.name}</h6>
          <p>${data.location.formatted_address}</p>
          <a href="https://www.google.com/maps/dir/?api=1&destination=${data.geocodes.main.latitude},${data.geocodes.main.longitude}" target="_blank">Get Directions</a>
        `
      )
      .addTo(map);
    // Update the currentPopup state
    setCurrentPopup(popup);
  };

  const heightView = () => {
    $(".toggleArrow").toggleClass("full");
  };

  const updateCarouselPosition = (object) => {
    if (object.item.index != mapState.startPosition) {
      setMapState((prevState) => ({
        ...prevState,
        startPosition: object.item.index,
      }));
    }
  };

  const tab = () => {
    const screenWidth = window.innerWidth;

    let tabItems =
      parseInt(screenWidth) <= 576
        ? 1
        : parseInt(screenWidth) <= 767
        ? 2
        : parseInt(screenWidth) <= 991
        ? 3
        : parseInt(screenWidth) <= 1199
        ? 5
        : 10;
    setMapState((prevState) => ({
      ...prevState,
      tabItems: tabItems,
    }));
  };

  return (
    <div className="row m-0 position-relative trashDelete">
      <div className="col-md-8  p-0 rounded-start-4 overflow-hidden">
        <div className="listViewOnMap_poi">
          {mapState.poiCategories.length > 0 && (
            <ul>
              <OwlCarousel
                className="owl-theme"
                dots={false}
                nav={true}
                autoWidth={true}
                navText={[
                  "<i class='fa-solid fa-angle-left'></i>",
                  "<i class='fa-solid fa-angle-right'></i>",
                ]}
                items={mapState.tabItems}
                startPosition={mapState.startPosition}
                onDragged={(object) => updateCarouselPosition(object)}
                onTranslated={(object) => updateCarouselPosition(object)}
              >
                {_.sortBy(mapState.poiCategories, [
                  function (o) {
                    return o;
                  },
                ]).map((row, i) => {
                  return (
                    <li
                      key={`${row}-${i}`}
                      className={`nav-item${
                        mapState.selectedCategory == row ? " active" : ""
                      }`}
                      onClick={() => onChangeCategory(row)}
                    >
                      {row}
                    </li>
                  );
                })}
              </OwlCarousel>
            </ul>
          )}
        </div>

        {mapState.poiCategories.length > 0 ? (
          <div className="relomap-height">
            <div id="map" style={{ width: "100%", height: "100%" }}></div>
          </div>
        ) : (
          <div className="loading">Loading </div>
        )}
      </div>
      <div className="col-md-4 p-0">
        <div className="map-right-view rounded-end-4">
          <span className="toggleArrow" onClick={() => heightView()}>
            <i className="fa-solid fa-angle-up"></i>
          </span>

          <div className="heightGrid">
            <div className="point-tails">
              {mapState.poi.length > 0 ? (
                mapState.poi.map((row, index) => {
                  return (
                    <div
                      className={
                        mapState.activeNumber == row.fsq_id
                          ? "tail-view active"
                          : "tail-view"
                      }
                      key={index}
                      // onClick={() => mapIndexChange(row, index)}
                      onClick={() => handleMarkerInfo(row)}
                    >
                      <ul>
                        <li>
                          <h1>{row.name}</h1>
                        </li>
                        <li>
                          <span>
                            {" "}
                            {row.location.formatted_address.toString()}
                          </span>
                        </li>
                      </ul>
                    </div>
                  );
                })
              ) : (
                <h3 className="no-content-msg MiddleText">
                  There are no points of interest available. Please select
                  another category.
                </h3>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapPointofInterests;
