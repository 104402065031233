import React, { useEffect, useState, useRef } from "react";

import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken =
  "pk.eyJ1Ijoia3VuZGFnZWV0aGEtMTQiLCJhIjoiY20wZ2Mwc2VzMGs5cTJucjF6dGk4cDc0dyJ9.ASH3-A0NLmHMHwXD9vZw1g";

function MapPointofInterests(props) {
  const { poiData, markers } = props;
  const mapRef = useRef(null);
  const [mapLoadStatus, setMapLoadStatus] = useState(false);

  useEffect(() => {
    if (!mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/examples/clg45vm7400c501pfubolb0xz",
        center: {
          lat: poiData?.latitude || 37.7739852,
          lng: poiData?.longitude || -122.3912407,
        },
        zoom: 13,
      });

      mapRef.current.on("load", () => {
        setMapLoadStatus(true);
      });
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
      }
    };
  }, [poiData]);

  useEffect(() => {
    if (!mapLoadStatus) return;

    const geoJsonData = {
      type: "FeatureCollection",
      features: markers.map((data) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            data.geocodes.main.longitude,
            data.geocodes.main.latitude,
          ],
        },
        properties: {
          name: data.name,
          lat: data.geocodes.main.latitude,
          lng: data.geocodes.main.longitude,
          vicinity: data.location.formatted_address.toString(),
          place_id: data.fsq_id,
        },
      })),
    };
    mapRef.current.addSource("markers", {
      type: "geojson",
      data: geoJsonData,
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 50,
    });

    //----------------------------------------
    // Zoom controls
    //----------------------------------------
    const nav = new mapboxgl.NavigationControl({
      showZoom: true,
    });
    mapRef.current.addControl(nav, "bottom-left");

    //----------------------------------------
    // polygon drawing
    //----------------------------------------
    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
      defaultMode: "simple_select",
    });
    mapRef.current.addControl(draw);

    let selectedFeatures;
    mapRef.current.on("draw.selectionchange", (e) => {
      selectedFeatures = draw.getSelected();
      console.log("Selection changed:", selectedFeatures);
    });

    const trashButton = document.querySelector(".mapbox-gl-draw_trash");
    if (trashButton) {
      trashButton.addEventListener(
        "click",
        () => {
          const allFeatures = draw.getAll();
          console.log("Selected features:", selectedFeatures);
          console.log("All features:", allFeatures);

          if (allFeatures.features.length === 0) {
            return;
          }

          if (selectedFeatures.features.length === 0) {
            alert("Please select a polygon to delete");
            return;
          }

          // Delete selected features
          selectedFeatures.features.forEach((feature) => {
            draw.delete(feature.id);
          });
        },
        true
      );
    }

    //----------------------------------------
    // Layers
    //----------------------------------------
    mapRef.current.addLayer({
      id: "clusters",
      type: "circle",
      source: "markers",
      filter: ["has", "point_count"],
      paint: {
        "circle-color": [
          "step",
          ["get", "point_count"],
          "#000000", // color for small clusters
          100,
          "#000000", // color for medium clusters
          750,
          "#000000", // color for large clusters
        ],
        "circle-radius": [
          "step",
          ["get", "point_count"],
          20, // radius for small clusters
          100,
          30, // radius for medium clusters
          750,
          40, // radius for large clusters
        ],
      },
    });

    // Add a label to show the number of points in each cluster
    mapRef.current.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: "markers",
      filter: ["has", "point_count"],
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Bold"],
        "text-size": 15,
      },
      paint: {
        "text-color": "#FFFFFF", //text color
      },
    });

    // Add unclustered points as markers
    mapRef.current.addLayer({
      id: "unclustered-point",
      type: "symbol",
      source: "markers",
      filter: ["!", ["has", "point_count"]],
      layout: {
        "icon-image": "custom-marker",
        "icon-size": 0.8,
      },
    });

    // Add custom image for the unclustered markers
    mapRef.current.loadImage(
      "/assets/images/location_pin_2.png",
      (error, image) => {
        if (error) throw error;
        mapRef.current.addImage("custom-marker", image);
      }
    );

    // When a cluster is clicked, zoom in
    mapRef.current.on("click", "clusters", (e) => {
      const features = mapRef.current.queryRenderedFeatures(e.point, {
        layers: ["clusters"],
      });
      const clusterId = features[0].properties.cluster_id;
      mapRef.current
        .getSource("markers")
        .getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          mapRef.current.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          });
        });
    });

    // Show popup for unclustered points
    mapRef.current.on("click", "unclustered-point", (ele) => {
      const coordinates = ele.features[0].geometry.coordinates.slice();
      const { name, vicinity, place_id, lat, lng } = ele.features[0].properties;

      // Create and display the popup
      const popup = new mapboxgl.Popup({ offset: [0, -15] })
        .setLngLat(coordinates)
        .setHTML(
          `
            <h6>${name}</h6>
            <p>${vicinity}</p>
            <a href="https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}" target="_blank">Get Directions</a>
          `
        )
        .addTo(mapRef.current);

      // setCurrentPopup(popup);
      // setMapState((prev) => ({
      //   ...prev,
      //   activeNumber: place_id,
      //   data: {
      //     latitude: coordinates[1],
      //     longitude: coordinates[0],
      //   },
      // }));
    });
  }, [mapLoadStatus]);

  return <div id="map" style={{ width: "100%", height: "100%" }}></div>;
}

function BaseMap() {
  const [markers, setMarkers] = useState([
    {
      fsq_id: "5747b464498efe1302c3e268",
      categories: [
        {
          id: 13009,
          name: "Cocktail Bar",
          short_name: "Cocktail",
          plural_name: "Cocktail Bars",
          icon: {
            prefix:
              "https://ss3.4sqi.net/img/categories_v2/nightlife/cocktails_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
        {
          id: 13302,
          name: "Mediterranean Restaurant",
          short_name: "Mediterranean",
          plural_name: "Mediterranean Restaurants",
          icon: {
            prefix:
              "https://ss3.4sqi.net/img/categories_v2/food/mediterranean_",
            suffix: ".png",
          },
        },
      ],
      distance: 438,
      geocodes: {
        main: {
          latitude: 47.624447,
          longitude: -122.321269,
        },
        roof: {
          latitude: 47.624447,
          longitude: -122.321269,
        },
      },
      location: {
        address: "601 Broadway E",
        census_block: "530330074031000",
        country: "US",
        cross_street: "at E. Mercer",
        dma: "Seattle-Tacoma",
        formatted_address: "601 Broadway E (at E. Mercer), Seattle, WA 98102",
        locality: "Seattle",
        postcode: "98102",
        region: "WA",
      },
      name: "Corvus & Co",
      photos: [
        {
          id: "63a6435698140a2049d27b8b",
          created_at: "2022-12-24T00:09:58.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2711266_KBhqY7fjeb5fDDprgAvdHv1rWcZS4-vveJy6U59MhxU.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "621296616923226d987925d7",
          created_at: "2022-02-20T19:28:33.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/3957266_ajH1ZUL4NREV9uwoUTKcyj1gAcpKghwJ17Oxnnj553A.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "6168f0d1ceee8570fd78ff8f",
          created_at: "2021-10-15T03:09:05.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1370814056_GOK4cv0_9ZZwTKwEhY9CWsuDXxjCJvSeQroDP0EDcIo.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "5f56cc45d6945b33238d81bf",
          created_at: "2020-09-08T00:11:49.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/29353911_gTMaVHCn49vuR9Op9cR0IvF0bWAkUZBsIrX-DBb_3Zg.jpg",
          width: 1079,
          height: 1920,
        },
        {
          id: "5df57c68e91ae30008ac25ec",
          created_at: "2019-12-15T00:20:56.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/21645524_TaCfUpjxFVEiZHtCZtpaEJFKINFQLlGseq2q6qSfcyo.jpg",
          width: 1440,
          height: 1920,
          classifications: ["food"],
        },
      ],
      rating: 8.4,
      tel: "(206) 420-8488",
      website: "http://www.corvusandcompany.com",
    },
    {
      fsq_id: "50bf9b21582ff054194651e8",
      categories: [
        {
          id: 13010,
          name: "Dive Bar",
          short_name: "Dive Bar",
          plural_name: "Dive Bars",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/nightlife/divebar_",
            suffix: ".png",
          },
        },
        {
          id: 13016,
          name: "Lounge",
          short_name: "Lounge",
          plural_name: "Lounges",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/nightlife/default_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 470,
      geocodes: {
        main: {
          latitude: 47.624642,
          longitude: -122.320724,
        },
        roof: {
          latitude: 47.624642,
          longitude: -122.320724,
        },
      },
      location: {
        address: "606 Broadway E",
        census_block: "530330065002012",
        country: "US",
        dma: "Seattle-Tacoma",
        formatted_address: "606 Broadway E, Seattle, WA 98102",
        locality: "Seattle",
        postcode: "98102",
        region: "WA",
      },
      name: "Bait Shop",
      photos: [
        {
          id: "55bc3962498ef058418f4bd4",
          created_at: "2015-08-01T03:13:38.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/13076545_7zdIhMcqnyM0bo_XtW0lmUaN-fKZ5cTREE8Rjb5G-1Q.jpg",
          width: 1440,
          height: 1440,
        },
        {
          id: "512edad2e4b0ac135b40d093",
          created_at: "2013-02-28T04:19:30.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/40858706_VsPWkK1GF1wDLYVY3oPa_u85hd85voXhESxgR-L9_1w.jpg",
          width: 1152,
          height: 1536,
          classifications: ["food"],
        },
        {
          id: "66921ee1183bf73ceabc060a",
          created_at: "2024-07-13T06:29:53.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/25857168_BBBxdcdbhh4-KlbLSnMbginacudstTMY1YcPLeuBn_M.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "66856efefcd90d1958eb5e85",
          created_at: "2024-07-03T15:32:14.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/25857168_fQwMtztZR66iHu_I4tEg6BIrCy-ql89wRgRJx36uSgI.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "664306f32df1e10ef5f2ae20",
          created_at: "2024-05-14T06:38:43.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/25857168_QAh_-MprEbW7knwl1o66YABqvT_53Itvuz7DNcYaKF0.jpg",
          width: 1440,
          height: 1920,
        },
      ],
      rating: 8.3,
      tel: "(206) 420-8742",
      website: "http://baitshopseattle.com",
    },
    {
      fsq_id: "4f566c9ae4b01a8c7fd8b325",
      categories: [
        {
          id: 13006,
          name: "Beer Bar",
          short_name: "Beer Bar",
          plural_name: "Beer Bars",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/nightlife/pub_",
            suffix: ".png",
          },
        },
        {
          id: 13007,
          name: "Beer Garden",
          short_name: "Beer Garden",
          plural_name: "Beer Gardens",
          icon: {
            prefix:
              "https://ss3.4sqi.net/img/categories_v2/nightlife/beergarden_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 665,
      geocodes: {
        main: {
          latitude: 47.615447,
          longitude: -122.327641,
        },
        roof: {
          latitude: 47.615447,
          longitude: -122.327641,
        },
      },
      location: {
        address: "1600 Melrose Ave",
        census_block: "530330084022000",
        country: "US",
        cross_street: "at Pine St",
        dma: "Seattle-Tacoma",
        formatted_address: "1600 Melrose Ave (at Pine St), Seattle, WA 98122",
        locality: "Seattle",
        postcode: "98122",
        region: "WA",
      },
      name: "The Pine Box",
      photos: [
        {
          id: "51789e8be4b02ed3ae1de26a",
          created_at: "2013-04-25T03:10:03.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/15130535_Xk37V_oaUOQDoy4KhQ0kLvYLw96jDOV5QlwUhtQNdW0.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "65166047d1fe0705345609ea",
          created_at: "2023-09-29T05:27:35.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/563347327_w_tnQY048DI2N5fsL-wFFMM7yOowtIzIOMcJFR1LJ0k.jpg",
          width: 1440,
          height: 1920,
        },
      ],
      rating: 8.5,
      tel: "(206) 588-0375",
      website: "http://pineboxbar.com",
    },
    {
      fsq_id: "4e33193662845cff5a3b4927",
      categories: [
        {
          id: 13031,
          name: "Burger Joint",
          short_name: "Burgers",
          plural_name: "Burger Joints",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/burger_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
        {
          id: 13145,
          name: "Fast Food Restaurant",
          short_name: "Fast Food",
          plural_name: "Fast Food Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/fastfood_",
            suffix: ".png",
          },
        },
      ],
      distance: 738,
      geocodes: {
        main: {
          latitude: 47.614926,
          longitude: -122.328242,
        },
        roof: {
          latitude: 47.614926,
          longitude: -122.328242,
        },
      },
      location: {
        address: "1211 Pine St",
        census_block: "530330084022005",
        country: "US",
        dma: "Seattle-Tacoma",
        formatted_address: "1211 Pine St, Seattle, WA 98101",
        locality: "Seattle",
        postcode: "98101",
        region: "WA",
      },
      name: "Li'l Woody's",
      photos: [
        {
          id: "63fbcabb40a09800ce306aa8",
          created_at: "2023-02-26T21:10:19.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1214764_mKJ_6kxfb13NK7At90Eq-8-d64V5fPsiJM4WfjjaPmc.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "637849633e83726bad197358",
          created_at: "2022-11-19T03:11:31.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/7384126_n24VJ9Ji-Nkqu_Ap0HWt8wnmolcg8ofMvPxfZWYkQy0.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "634a2c37a2039268085c77ed",
          created_at: "2022-10-15T03:42:47.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/18053864_qXcFj5_i18jJ4nr8AI0YT2ru0LaqBRtm-RYEMMfTALc.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "6250ccb4d05967199d71bb25",
          created_at: "2022-04-09T00:00:52.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/23930109_5ZL-hA6j8DuWvM6eKmlZItJtI5jJjxMQuDYnS92ieVo.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "611ace17a38fb3003723f694",
          created_at: "2021-08-16T20:44:07.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/95602140_LMkhIy8ZPpj-mrPZncSIwPMW3M2yESUI7lPq3jcKao0.jpg",
          width: 2016,
          height: 1020,
        },
      ],
      rating: 8.6,
      tel: "(206) 457-4148",
      website: "http://www.lilwoodys.com",
    },
    {
      fsq_id: "596684d82e26801ba35903ca",
      categories: [
        {
          id: 13006,
          name: "Beer Bar",
          short_name: "Beer Bar",
          plural_name: "Beer Bars",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/nightlife/pub_",
            suffix: ".png",
          },
        },
        {
          id: 13029,
          name: "Brewery",
          short_name: "Brewery",
          plural_name: "Breweries",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/brewery_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 774,
      geocodes: {
        drop_off: {
          latitude: 47.614079,
          longitude: -122.32277,
        },
        main: {
          latitude: 47.614293,
          longitude: -122.32277,
        },
        roof: {
          latitude: 47.614293,
          longitude: -122.32277,
        },
      },
      location: {
        address: "714 E Pike St",
        census_block: "530330084011002",
        country: "US",
        cross_street: "btwn Boyston Ave & Harvard Ave",
        dma: "Seattle-Tacoma",
        formatted_address:
          "714 E Pike St (btwn Boyston Ave & Harvard Ave), Seattle, WA 98122",
        locality: "Seattle",
        postcode: "98122",
        region: "WA",
      },
      name: "Redhook Brewlab",
      photos: [
        {
          id: "66e4a9bdb4bbfb1385db3361",
          created_at: "2024-09-13T21:08:13.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/24131741_xSYEUaj7mVA0aK3ROiEY4KbkD3TdHrv4tYrPHCauOCI.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "66e4a9bca43ae3162224c44f",
          created_at: "2024-09-13T21:08:12.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/24131741_eMXo_sA2lDn0ZQuP5CyLgLxQETp8pU59OQAbr024qw8.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "66e4a9bb723ed608392bfd85",
          created_at: "2024-09-13T21:08:11.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/24131741_BRmD8BisbHMgWx1H-T_SW5x8qrFx8I1EF6LL7Etm3Gw.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "66e39a925405542e0465e819",
          created_at: "2024-09-13T01:51:14.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/8547_qj8WC6YvuYIaG5E_ISKzvGpbdBLacjwh94_V0IQslXU.jpg",
          width: 1920,
          height: 1080,
        },
      ],
      rating: 8.9,
      tel: "(206) 823-3026",
      website: "http://redhook.com",
    },
    {
      fsq_id: "5986b87f16ef6778abe2c885",
      categories: [
        {
          id: 13046,
          name: "Ice Cream Parlor",
          short_name: "Ice Cream",
          plural_name: "Ice Cream Parlors",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/icecream_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 775,
      geocodes: {
        main: {
          latitude: 47.614409,
          longitude: -122.322779,
        },
        roof: {
          latitude: 47.614409,
          longitude: -122.322779,
        },
      },
      location: {
        address: "714 E Pike St",
        address_extended: "Unit A",
        census_block: "530330084011002",
        country: "US",
        cross_street: "",
        dma: "Seattle-Tacoma",
        formatted_address: "714 E Pike St, Seattle, WA 98122",
        locality: "Seattle",
        postcode: "98122",
        region: "WA",
      },
      name: "Salt & Straw",
      photos: [
        {
          id: "66f4d56c8edbdd15b28e7479",
          created_at: "2024-09-26T03:30:52.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/35760175_urU97vKggiVjKYWMikbpYZ-2H7MMlhYCJ0VfQibrnuw.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "66e3554abda1ea1aa2a2f1ad",
          created_at: "2024-09-12T20:55:38.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/34943530_HAbnI8hSgGRcmzUT7LlQF8wG0o9vWKJUk34QFW475ks.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "66d694d651df174208a984c5",
          created_at: "2024-09-03T04:47:18.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/139449_pRUVauAjiFQQK1yfnYmokEpA9krcgvl0yT44Pc42Caw.jpg",
          width: 1080,
          height: 1434,
        },
        {
          id: "66b8cac9016ebe05ea7370a1",
          created_at: "2024-08-11T14:29:29.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1395833502_BX5fJPUkWSB6NuRRMCRYExTC7cxix26RRarj2JozanA.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "66b0424edb66841678ef2262",
          created_at: "2024-08-05T03:09:02.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/8547_9E5iLZcg-GO-3fxtrdFAMgnnnwQodtT-afTaMylkaW4.jpg",
          width: 1080,
          height: 1920,
        },
      ],
      rating: 9.2,
      tel: "(206) 258-4574",
      website: "http://www.saltandstraw.com",
    },
    {
      fsq_id: "5cf1ca3ca5a315002cb9fdb1",
      categories: [
        {
          id: 13009,
          name: "Cocktail Bar",
          short_name: "Cocktail",
          plural_name: "Cocktail Bars",
          icon: {
            prefix:
              "https://ss3.4sqi.net/img/categories_v2/nightlife/cocktails_",
            suffix: ".png",
          },
        },
        {
          id: 13016,
          name: "Lounge",
          short_name: "Lounge",
          plural_name: "Lounges",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/nightlife/default_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 787,
      geocodes: {
        drop_off: {
          latitude: 47.61408,
          longitude: -122.322367,
        },
        main: {
          latitude: 47.61424,
          longitude: -122.322368,
        },
        roof: {
          latitude: 47.61424,
          longitude: -122.322368,
        },
      },
      location: {
        address: "722 E Pike St",
        census_block: "530330084011002",
        country: "US",
        cross_street: "Harvard Ave",
        dma: "Seattle-Tacoma",
        formatted_address: "722 E Pike St (Harvard Ave), Seattle, WA 98122",
        locality: "Seattle",
        postcode: "98122",
        region: "WA",
      },
      name: "Life On Mars",
      photos: [
        {
          id: "66c153797c078a68c3b2c3f2",
          created_at: "2024-08-18T01:50:49.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/116588_TlI4-k9qHwBruG7PpnAEhkA5ZwW_jarwSSDmnzKHk5s.jpg",
          width: 1920,
          height: 1204,
        },
        {
          id: "6624a5087f84937d61f6a7fd",
          created_at: "2024-04-21T05:32:56.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/6795_ac0tw9LkmopPp2yVJsAcuOwJE2-dtoGPRcMcYlieVfE.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "6612d3ed32168a5098fd2711",
          created_at: "2024-04-07T17:12:13.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/5495508_Knx71l7JnXg9pWS28P3GMHtUYmTmbTYiqYyil_JGDUY.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "65e4155eec732a6daddb6d04",
          created_at: "2024-03-03T06:14:54.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/6148279_ETznT9XhQJvpzwFU2Wc4CWLTh3IDHY5MBBdsGg6BFro.jpg",
          width: 1920,
          height: 1440,
        },
      ],
      rating: 8.5,
      tel: "(206) 323-9166",
      website: "https://www.lifeonmarsseattle.com",
    },
    {
      fsq_id: "4e20c7af88774a43b7632dd0",
      categories: [
        {
          id: 13314,
          name: "New American Restaurant",
          short_name: "New American",
          plural_name: "New American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/newamerican_",
            suffix: ".png",
          },
        },
      ],
      distance: 792,
      geocodes: {
        main: {
          latitude: 47.614474,
          longitude: -122.32805,
        },
        roof: {
          latitude: 47.614474,
          longitude: -122.32805,
        },
      },
      location: {
        address: "1501 Melrose Ave",
        address_extended: "# 3",
        census_block: "530330084022005",
        country: "US",
        cross_street: "at Minor Ave",
        dma: "Seattle-Tacoma",
        formatted_address: "1501 Melrose Ave (at Minor Ave), Seattle, WA 98122",
        locality: "Seattle",
        postcode: "98122",
        region: "WA",
      },
      name: "Terra Plata",
      photos: [
        {
          id: "6265b3ed96563233115278b6",
          created_at: "2022-04-24T20:32:45.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/3403033_dFUqmSNskBplyJYTly3ynBGwUUtEWy06Q_eMjyHMxWc.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "5946e3cd840fc227de6ce176",
          created_at: "2017-06-18T20:34:21.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2542353_AjrP29Gm6PdQ7Q2MeNWjTuVjEY_a7FXIm3dt-w-5L2Y.jpg",
          width: 1899,
          height: 1900,
        },
        {
          id: "55a48c21498e2975ce5ac8ac",
          created_at: "2015-07-14T04:12:17.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/497272_vsXQD5fGx10xiSLkpdrty2mHjr0WNrFSvwzefGuStUU.jpg",
          width: 1920,
          height: 1440,
          classifications: ["food"],
        },
        {
          id: "58d814133bd4ab566d70972e",
          created_at: "2017-03-26T19:18:43.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/23140670_uQwh0RnVUXFnZmhyrd-6fY9a89C5djpBy2ZiGNIFUyw.jpg",
          width: 1080,
          height: 1920,
        },
        {
          id: "61bfa24ec9ad34745ee1e182",
          created_at: "2021-12-19T21:21:18.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/403991234_k_jnfk8sCRY9nWJtIS32Pe8CPBy5-UhKR7FGZejC1Ws.jpg",
          width: 1920,
          height: 1440,
        },
      ],
      rating: 8.7,
      tel: "(206) 325-1501",
      website: "http://www.terraplata.com",
    },
    {
      fsq_id: "513121328302950c208f90d1",
      categories: [
        {
          id: 13006,
          name: "Beer Bar",
          short_name: "Beer Bar",
          plural_name: "Beer Bars",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/nightlife/pub_",
            suffix: ".png",
          },
        },
        {
          id: 13049,
          name: "Diner",
          short_name: "Diner",
          plural_name: "Diners",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/diner_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 835,
      geocodes: {
        main: {
          latitude: 47.614302,
          longitude: -122.319659,
        },
        roof: {
          latitude: 47.614302,
          longitude: -122.319659,
        },
      },
      location: {
        address: "1505 10th Ave",
        census_block: "530330075031003",
        country: "US",
        cross_street: "at Pike St",
        dma: "Seattle-Tacoma",
        formatted_address: "1505 10th Ave (at Pike St), Seattle, WA 98122",
        locality: "Seattle",
        postcode: "98122",
        region: "WA",
      },
      name: "Lost Lake Cafe & Lounge",
      photos: [
        {
          id: "518f0527498ea83b5976ac74",
          created_at: "2013-05-12T02:57:43.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/974005_qRTbeb1uQxN746-OX6cU5FR5BCpWMulaTtFxoVm0RDM.jpg",
          width: 1440,
          height: 1920,
          classifications: ["outdoor"],
        },
        {
          id: "518d9b8f498ef0333eacfb8e",
          created_at: "2013-05-11T01:14:55.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/10313986_e--Oveb590qmSdjSzSrI10SFVjNh2Smbzm3xdkvSZ8Q.jpg",
          width: 1918,
          height: 1434,
        },
        {
          id: "525f6928498e50824612f48f",
          created_at: "2013-10-17T04:35:52.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/7738103_VqihTbnPTcPT2gNYsHdv91yGpJjrhZINgapvrBHPbkE.jpg",
          width: 1918,
          height: 1434,
        },
        {
          id: "518c8624498ebcacd4fdbe6c",
          created_at: "2013-05-10T05:31:16.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/5456679_TppDZW81zFG8jn3c29t9EAiF4KFbrggChMFI8s3TPzA.jpg",
          width: 1918,
          height: 1434,
        },
        {
          id: "5192472b498e7bb61d7902d7",
          created_at: "2013-05-14T14:16:11.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2133295_kkotBjIKQSKiiMtekKb9m-rWWduQdFC8GaHbnDY1o8k.jpg",
          width: 1440,
          height: 1920,
        },
      ],
      rating: 7.9,
      tel: "(206) 323-5678",
      website: "http://lostlakecafe.com",
    },
    {
      fsq_id: "56b3aac7498e1af5c6db1fcd",
      categories: [
        {
          id: 13314,
          name: "New American Restaurant",
          short_name: "New American",
          plural_name: "New American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/newamerican_",
            suffix: ".png",
          },
        },
        {
          id: 13057,
          name: "Gastropub",
          short_name: "Gastropub",
          plural_name: "Gastropubs",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/gastropub_",
            suffix: ".png",
          },
        },
      ],
      distance: 954,
      geocodes: {
        main: {
          latitude: 47.622229,
          longitude: -122.336829,
        },
        roof: {
          latitude: 47.622229,
          longitude: -122.336829,
        },
      },
      location: {
        address: "404 Terry Ave N",
        census_block: "530330073031008",
        country: "US",
        cross_street: "at Harrison St",
        dma: "Seattle-Tacoma",
        formatted_address:
          "404 Terry Ave N (at Harrison St), Seattle, WA 98109",
        locality: "Seattle",
        postcode: "98109",
        region: "WA",
      },
      name: "LOCAL Public Eatery Terry Ave",
      photos: [
        {
          id: "66f079bcc18bdf04dee092db",
          created_at: "2024-09-22T20:10:36.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/39966514_OsOFUx8uh6c7pRt4o1DtauPGcuGqQ5ysFBURcbBtle0.jpg",
          width: 1440,
          height: 1572,
        },
        {
          id: "63d1f3fbf123fd30d70e63ed",
          created_at: "2023-01-26T03:31:07.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/139123_gtvA4zxmTuuZ92UEOmzdwF1RjjOjGD3NYLrC7rYZPT8.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "6303e6ddc1647b453a99c3da",
          created_at: "2022-08-22T20:28:13.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2022245_OwVLAD3pnSwQhQq66Jx4aZleRLTxek30KZwzoMOwqdE.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "630017b00c28105556fab72f",
          created_at: "2022-08-19T23:07:28.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2022245_TzdfwvaKdttPLZh06Lj-MIs901oKb7YRpjxpH-WdFNQ.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "62e750c39f438d4eca3a9467",
          created_at: "2022-08-01T04:04:19.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1387379090_6-N3RFXlQP2qUiKytolRNudledUbp_fuMQPNauGEn7c.jpg",
          width: 1440,
          height: 1920,
        },
      ],
      rating: 7.1,
      tel: "(206) 257-1888",
      website: "https://localpubliceatery.com/neighbourhoods/terry-ave",
    },
    {
      fsq_id: "4e3a0b50b3ade614e126e05c",
      categories: [
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
        {
          id: 13307,
          name: "Tex-Mex Restaurant",
          short_name: "Tex-Mex",
          plural_name: "Tex-Mex Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/mexican_",
            suffix: ".png",
          },
        },
      ],
      distance: 955,
      geocodes: {
        main: {
          latitude: 47.621808,
          longitude: -122.336958,
        },
        roof: {
          latitude: 47.621808,
          longitude: -122.336958,
        },
      },
      location: {
        address: "350 Terry Ave N",
        census_block: "530330073033000",
        country: "US",
        cross_street: "at Harrison St",
        dma: "Seattle-Tacoma",
        formatted_address:
          "350 Terry Ave N (at Harrison St), Seattle, WA 98109",
        locality: "Seattle",
        postcode: "98109",
        region: "WA",
      },
      name: "Cactus",
      photos: [
        {
          id: "519f904e498eace6061fd816",
          created_at: "2013-05-24T16:07:42.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/11266685_8ZFrd-hgO4DumDhCaiSnDLraNB_JcanRHj4i5qdBYOQ.jpg",
          width: 1918,
          height: 1434,
          classifications: ["food"],
        },
        {
          id: "51720979e4b0bf64460011df",
          created_at: "2013-04-20T03:20:25.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/53717921_IwQczkM-3LVTtj9i_i9gRwGRqiEjJwCFfPV3NkCcvyo.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "661c2da3ad583538ae156a59",
          created_at: "2024-04-14T19:25:23.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2302857_CcbQP7BRBdnKJ_kUCDPS1QZVp5K-LckIZGRlIj0Abzw.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "65729255b10afa59764071aa",
          created_at: "2023-12-08T03:49:41.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/510434257_gOD9LPAHi6DGpCONeO5mVtHXhUIs4Jpv3dPa6zBcL8c.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "65075f87203ba67a9ac6aee5",
          created_at: "2023-09-17T20:20:23.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/52893855_Ya67f9LLdE1S9sMMM6GxqMjKiyXYSsYmGSRYHXknCTU.jpg",
          width: 1920,
          height: 1440,
        },
      ],
      rating: 8.2,
      tel: "(206) 913-2250",
      website: "http://www.cactusrestaurants.com",
    },
    {
      fsq_id: "48945785f964a52067511fe3",
      categories: [
        {
          id: 13030,
          name: "Buffet",
          short_name: "Buffet",
          plural_name: "Buffets",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
        {
          id: 13034,
          name: "Café",
          short_name: "Café",
          plural_name: "Cafés",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/cafe_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 972,
      geocodes: {
        main: {
          latitude: 47.62186,
          longitude: -122.33736,
        },
        roof: {
          latitude: 47.62186,
          longitude: -122.33736,
        },
      },
      location: {
        address: "391 Terry Ave N",
        census_block: "530330073033001",
        country: "US",
        cross_street: "btwn Harrison & Thomas",
        dma: "Seattle-Tacoma",
        formatted_address:
          "391 Terry Ave N (btwn Harrison & Thomas), Seattle, WA 98109",
        locality: "Seattle",
        postcode: "98109",
        region: "WA",
      },
      name: "Portage Bay Cafe",
      photos: [
        {
          id: "670be75ab053f03e0791f317",
          created_at: "2024-10-13T15:29:30.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/74304828_buBRWfanZNqmKD7ITAyrbCAYg0TRrW__XetTLmiQFLA.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "66ec3fd09196690aa80e6f9d",
          created_at: "2024-09-19T15:14:24.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/140979370_giVNSrVWGmb5oa8BG3lmws4eP3RJrN_GnuTDIpMOgKs.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "66be22066b55b9112079b5f4",
          created_at: "2024-08-15T15:43:02.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/338504_IxeQzRnVV_rO4RDiNVezxrGQZdhqQ-UnAMfeZLORlJQ.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "667f16f06a413257cbc74875",
          created_at: "2024-06-28T20:02:56.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/7384126_lOzWrUBl4Z7oiEdTgVW7T7xr7Ikj4ctRzrf5S1W8MvI.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "66535c602b3caf6aff877de2",
          created_at: "2024-05-26T15:59:28.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/82291514_nQkcQ-DzFfibZQMtS-X-614IrnchrJgZgPl4ctyP8w0.jpg",
          width: 1008,
          height: 1339,
        },
      ],
      rating: 8.3,
      tel: "(206) 462-6400",
      website: "http://www.portagebaycafe.com",
    },
    {
      fsq_id: "40b3de00f964a5202a001fe3",
      categories: [
        {
          id: 13003,
          name: "Bar",
          short_name: "Bar",
          plural_name: "Bars",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/nightlife/pub_",
            suffix: ".png",
          },
        },
        {
          id: 13029,
          name: "Brewery",
          short_name: "Brewery",
          plural_name: "Breweries",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/brewery_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 1016,
      geocodes: {
        main: {
          latitude: 47.61396,
          longitude: -122.315884,
        },
        roof: {
          latitude: 47.61396,
          longitude: -122.315884,
        },
      },
      location: {
        address: "1221 E Pike St",
        census_block: "530330075032004",
        country: "US",
        cross_street: "at 13th Ave E",
        dma: "Seattle-Tacoma",
        formatted_address: "1221 E Pike St (at 13th Ave E), Seattle, WA 98122",
        locality: "Seattle",
        postcode: "98122",
        region: "WA",
      },
      name: "Elysian Brewing Company",
      photos: [
        {
          id: "51885b07498efd58e8ad2ad0",
          created_at: "2013-05-07T01:38:15.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/501420_tJu4RgtzGmQrokayt5VZfvIXYQ1RP6kjPD5FF3Sl1v4.jpg",
          width: 1920,
          height: 1440,
          classifications: ["outdoor"],
        },
        {
          id: "66e4b167723ed608394bbfb6",
          created_at: "2024-09-13T21:40:55.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/24131741_lBFIqEhNmNQfR1l3fd5vVyzYkmyJ83z1j5IUuiw-Hbo.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "6689b3cfcc9c79634f3160cf",
          created_at: "2024-07-06T21:14:55.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/17623546_jaj5wqYvEpIHl_62drHEpq0AIjI4kEiJfP5U53oM170.jpg",
          width: 1440,
          height: 1920,
        },
      ],
      rating: 7.5,
      tel: "(206) 860-1920",
      website: "http://www.elysianbrewing.com",
    },
    {
      fsq_id: "564a4bd3498ef828c298c5e1",
      categories: [
        {
          id: 13025,
          name: "Wine Bar",
          short_name: "Wine Bar",
          plural_name: "Wine Bars",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/winery_",
            suffix: ".png",
          },
        },
        {
          id: 13035,
          name: "Coffee Shop",
          short_name: "Coffee Shop",
          plural_name: "Coffee Shops",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/coffeeshop_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 1069,
      geocodes: {
        main: {
          latitude: 47.614201,
          longitude: -122.334812,
        },
        roof: {
          latitude: 47.614201,
          longitude: -122.334812,
        },
      },
      location: {
        address: "720 Olive Way",
        address_extended: "Ste 103",
        census_block: "530330073021004",
        country: "US",
        cross_street: "",
        dma: "Seattle-Tacoma",
        formatted_address: "720 Olive Way, Seattle, WA 98101",
        locality: "Seattle",
        postcode: "98101",
        region: "WA",
      },
      name: "Mr. West Cafe Bar",
      photos: [
        {
          id: "672e41ccf59e9b6fc70c75b4",
          created_at: "2024-11-08T16:52:28.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1517632_FAYboSUWKitwzQ5OUxD_2EKtEoBB6tEE0zZXoMdFXQo.jpg",
          width: 1080,
          height: 1920,
        },
        {
          id: "66f0ad2f0e0c501e0b7bd3d9",
          created_at: "2024-09-22T23:50:07.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/533527930_xsnNWtYqTl7YIAPYi-T5uOJCjdToZ9i9I5zM3QOriAQ.jpg",
          width: 1080,
          height: 1920,
        },
        {
          id: "66cce4280b8c2f1944c63ec7",
          created_at: "2024-08-26T20:23:04.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/3715623_C7I9CYOwC3CFES7l1i551SWCH_zcVUsBPeMggx2um-k.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "66c8bb61c91c520d66591c3f",
          created_at: "2024-08-23T16:40:01.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/465210779_MTyRpYX5cCh_WDjKhE_GIJkX4a_EOHu3RDcO5IIZDao.jpg",
          width: 1080,
          height: 1920,
        },
        {
          id: "669a9fff2095722f92a743dd",
          created_at: "2024-07-19T17:18:55.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1403804_Vua0O8jfJDLBok3ssz4G-QGyPbAOuXiorBIDVBTp2dk.jpg",
          width: 1084,
          height: 1440,
        },
      ],
      rating: 8.3,
      tel: "(206) 900-9378",
      website: "http://mrwestcafebar.com",
    },
    {
      fsq_id: "40b3de00f964a52037001fe3",
      categories: [
        {
          id: 13028,
          name: "Breakfast Spot",
          short_name: "Breakfast",
          plural_name: "Breakfast Spots",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/breakfast_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 1089,
      geocodes: {
        main: {
          latitude: 47.611864,
          longitude: -122.319723,
        },
        roof: {
          latitude: 47.611864,
          longitude: -122.319723,
        },
      },
      location: {
        address: "950 E Madison St",
        census_block: "530330075031011",
        country: "US",
        cross_street: "at Broadway",
        dma: "Seattle-Tacoma",
        formatted_address: "950 E Madison St (at Broadway), Seattle, WA 98122",
        locality: "Seattle",
        postcode: "98122",
        region: "WA",
      },
      name: "IHOP",
      photos: [
        {
          id: "6706c166c524ac7062f858d4",
          created_at: "2024-10-09T17:46:14.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1254144_VyvuAPMfnEi0IpZ7deUu7QkO91F1L7ZZQSqxC4DATn0.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "6532d2113638db7d49dacbb2",
          created_at: "2023-10-20T19:16:33.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/478787887_9OYI6-GpNHxQPXTKN8fZVLFyOCWc8mqK7oXHcoVfcWs.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "632aa135b359f0746c312bb8",
          created_at: "2022-09-21T05:29:25.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/36254620_Zh7IGnsp1v-KlZdRis-dSjDbPTk9GSLZQ-pHxOlXX1U.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "621a79427ef4586cf102e50a",
          created_at: "2022-02-26T19:02:26.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/3715623_11HnSRjhUT2sbmsbyvM069jkunaYvO2SnPk3kL3DCGE.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "61f3bbaa823c9d1403e967ec",
          created_at: "2022-01-28T09:47:22.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/13479_Y4Gz5bHMEVO7QnTKrVcFiF9t6UrLvXEKTD-T9YVT848.jpg",
          width: 1440,
          height: 1920,
        },
      ],
      rating: 7.1,
      tel: "(206) 323-9323",
      website: "http://www.ihop.com/",
    },
    {
      fsq_id: "5badd98cfe3740002cb34dfe",
      categories: [
        {
          id: 13031,
          name: "Burger Joint",
          short_name: "Burgers",
          plural_name: "Burger Joints",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/burger_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
        {
          id: 13145,
          name: "Fast Food Restaurant",
          short_name: "Fast Food",
          plural_name: "Fast Food Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/fastfood_",
            suffix: ".png",
          },
        },
      ],
      distance: 1135,
      geocodes: {
        drop_off: {
          latitude: 47.617127,
          longitude: -122.338211,
        },
        main: {
          latitude: 47.617075,
          longitude: -122.33845,
        },
        roof: {
          latitude: 47.617075,
          longitude: -122.33845,
        },
      },
      location: {
        address: "2115 Westlake Ave",
        census_block: "530330072023014",
        country: "US",
        cross_street: "",
        dma: "Seattle-Tacoma",
        formatted_address: "2115 Westlake Ave, Seattle, WA 98121",
        locality: "Seattle",
        postcode: "98121",
        region: "WA",
      },
      name: "Shake Shack",
      photos: [
        {
          id: "5d53069fa5524e0008b12e69",
          created_at: "2019-08-13T18:51:11.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1405086_cRGQ93pm3UaYwJnccTVWywK9BJjzSoDGHeVbaymfPAg.jpg",
          width: 1440,
          height: 1920,
          classifications: ["food"],
        },
        {
          id: "5d72d1a3fae52c000839c292",
          created_at: "2019-09-06T21:37:39.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/5735300_a_SIUv28hL3j3wCG5KdRwSNYgOAs1CHobhm_je2-gzQ.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "5bf60cb2fd9d730038f678fb",
          created_at: "2018-11-22T01:56:02.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/16318_vXYDKkOpk-U6iIr_YoRP6d98n6IEc5cbDiO3S-UyLDo.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "5bcab07c66fc65002c2cb806",
          created_at: "2018-10-20T04:35:08.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/6734903_tjdr3LpogxNw5vWlEgRGTSowlzU5MxN8AF6y2vcXeq8.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "5bdfd1bf3e67410039fb7f5a",
          created_at: "2018-11-05T05:14:39.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/66094684_mxM4q6xUw0KubGu4vPRaxMUFLmUcDW03UMznjlc5c8g.jpg",
          width: 1440,
          height: 1920,
        },
      ],
      rating: 6,
      tel: "(206) 279-2313",
      website: "http://www.shakeshack.com/",
    },
    {
      fsq_id: "4dc1ee181838ada6b2583d08",
      categories: [
        {
          id: 13049,
          name: "Diner",
          short_name: "Diner",
          plural_name: "Diners",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/diner_",
            suffix: ".png",
          },
        },
        {
          id: 13314,
          name: "New American Restaurant",
          short_name: "New American",
          plural_name: "New American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/newamerican_",
            suffix: ".png",
          },
        },
      ],
      distance: 1180,
      geocodes: {
        drop_off: {
          latitude: 47.61313,
          longitude: -122.314206,
        },
        main: {
          latitude: 47.613137,
          longitude: -122.31391,
        },
        roof: {
          latitude: 47.613137,
          longitude: -122.31391,
        },
      },
      location: {
        address: "1400 E Union St",
        census_block: "530330075032007",
        country: "US",
        cross_street: "",
        dma: "Seattle-Tacoma",
        formatted_address: "1400 E Union St, Seattle, WA 98122",
        locality: "Seattle",
        postcode: "98122",
        region: "WA",
      },
      name: "Skillet Diner @ Capitol Hill",
      photos: [
        {
          id: "5172ebe4e4b010ebcbe1fb76",
          created_at: "2013-04-20T19:26:28.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/13577061_egzvf-LmMBTTS8LoYwqN5GhMuYnXH770cEbK-ZTY22g.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "51db0a1f8bbd2baecc86b12a",
          created_at: "2013-07-08T18:51:11.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/57107532_bkth8zwYf5sqhnlSxZR7ypCFOvu3lsciG2HA_HpI-ck.jpg",
          width: 2448,
          height: 2448,
          classifications: ["food"],
        },
        {
          id: "51955043498e7188b62d50a7",
          created_at: "2013-05-16T21:31:47.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1074554_7QcSJptXV6jOKSgbowwXL9MCRcQUpwSYvWFozV9S9ug.jpg",
          width: 1920,
          height: 1440,
          classifications: ["food"],
        },
        {
          id: "672511b9becbbd7e97c942a0",
          created_at: "2024-11-01T17:36:57.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1191348_0IdfwHNul2ihJr7TKYjskg01_9q07zl7ym0P2yhL2_k.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "65ee1895e2f6584db275ab2d",
          created_at: "2024-03-10T20:31:17.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/5223844_1nzR3EedCY4-v43DVo1vRFQu_0Z1wrzWjTk2xGN1GTk.jpg",
          width: 1440,
          height: 1920,
        },
      ],
      rating: 8.6,
      tel: "(206) 512-2001",
      website: "https://www.skilletfood.com/restaurant/capitol-hill",
    },
    {
      fsq_id: "43125000f964a52055271fe3",
      categories: [
        {
          id: 13002,
          name: "Bakery",
          short_name: "Bakery",
          plural_name: "Bakeries",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/bakery_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 1226,
      geocodes: {
        main: {
          latitude: 47.611903,
          longitude: -122.333289,
        },
        roof: {
          latitude: 47.611903,
          longitude: -122.333289,
        },
      },
      location: {
        address: "700 Pike St",
        address_extended: "# 98101-2311",
        census_block: "530330082001006",
        country: "US",
        cross_street: "at 7th Ave",
        dma: "Seattle-Tacoma",
        formatted_address: "700 Pike St (at 7th Ave), Seattle, WA 98101",
        locality: "Seattle",
        postcode: "98101",
        region: "WA",
      },
      name: "The Cheesecake Factory",
      photos: [
        {
          id: "56861317498e0dc6278a61a8",
          created_at: "2016-01-01T05:48:07.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2343902_I792-xW_yBwapUUX1F646d4G8NCRhevk52c_ozBVKW4.jpg",
          width: 1920,
          height: 1440,
          classifications: ["food"],
        },
        {
          id: "517de16be4b0a90ae93e252b",
          created_at: "2013-04-29T02:56:43.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/16656849_Ai8T-JSNKQ64L93WWz3TxgK0yC8MelcVcWNL7m_5WV4.jpg",
          width: 1440,
          height: 1920,
          classifications: ["food"],
        },
        {
          id: "66ea133913bb0d7f496bf0fd",
          created_at: "2024-09-17T23:39:37.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1191348_paLwDfnkcrF6eN6_Ziu8wcEodP_ohHv1NdaZqmT_ESY.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "66e5e48902ce2f338e2969cc",
          created_at: "2024-09-14T19:31:21.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/140979370_-FWyarZglSMh_cAeFJkjq6rNQ0e60GcAo2gA8RtDKx0.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "6699c6e9858b924210decdb7",
          created_at: "2024-07-19T01:52:41.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1403804_Q7w3HbfpUhtRMAl_gYVnlZ5qhJ4wfuaLLaErFNEDuAw.jpg",
          width: 1084,
          height: 1440,
        },
      ],
      rating: 8.2,
      tel: "(206) 652-5400",
      website: "http://www.thecheesecakefactory.com/",
    },
    {
      fsq_id: "4e18cb79d1648b834836786e",
      categories: [
        {
          id: 13009,
          name: "Cocktail Bar",
          short_name: "Cocktail",
          plural_name: "Cocktail Bars",
          icon: {
            prefix:
              "https://ss3.4sqi.net/img/categories_v2/nightlife/cocktails_",
            suffix: ".png",
          },
        },
        {
          id: 13031,
          name: "Burger Joint",
          short_name: "Burgers",
          plural_name: "Burger Joints",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/burger_",
            suffix: ".png",
          },
        },
        {
          id: 13314,
          name: "New American Restaurant",
          short_name: "New American",
          plural_name: "New American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/newamerican_",
            suffix: ".png",
          },
        },
      ],
      distance: 1238,
      geocodes: {
        main: {
          latitude: 47.611291,
          longitude: -122.31657,
        },
        roof: {
          latitude: 47.611291,
          longitude: -122.31657,
        },
      },
      location: {
        address: "928 12th Ave",
        census_block: "530330086001003",
        country: "US",
        cross_street: "btwn E Spring & Marion St",
        dma: "Seattle-Tacoma",
        formatted_address:
          "928 12th Ave (btwn E Spring & Marion St), Seattle, WA 98122",
        locality: "Seattle",
        postcode: "98122",
        region: "WA",
      },
      name: "Canon",
      photos: [
        {
          id: "566e75c5498e3a2b62bc166c",
          created_at: "2015-12-14T07:54:45.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/4996_Gw1tQmw1AgJ7893bpFAVJOTRxgXkx8enwmIs6pJvr00.jpg",
          width: 1920,
          height: 1440,
          classifications: ["indoor"],
        },
        {
          id: "5191a46d498ed7a6282af508",
          created_at: "2013-05-14T02:41:49.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/13869888_0MGkySKDqunWBa32RIgsuy-BhquvfON1uWbdYf7j8Jo.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "521c1f8511d2dea00b8a9959",
          created_at: "2013-08-27T03:39:49.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/40858706_gByoAaweRz6kxujB2JYCCKaQWzeQZvjPR2eDp3Sluvs.jpg",
          width: 1536,
          height: 1536,
          classifications: ["food"],
        },
        {
          id: "672d85f54e41d65dee44147a",
          created_at: "2024-11-08T03:31:01.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/604533_zU147X4br1nyxpl_TZL_7nekCNq1mgTWOhhqsQTitE8.jpg",
          width: 1440,
          height: 1920,
        },
      ],
      rating: 9.2,
      tel: "(206) 552-9755",
      website: "http://www.canonseattle.com",
    },
    {
      fsq_id: "5516025e498ece57a4b84979",
      categories: [
        {
          id: 13009,
          name: "Cocktail Bar",
          short_name: "Cocktail",
          plural_name: "Cocktail Bars",
          icon: {
            prefix:
              "https://ss3.4sqi.net/img/categories_v2/nightlife/cocktails_",
            suffix: ".png",
          },
        },
        {
          id: 13031,
          name: "Burger Joint",
          short_name: "Burgers",
          plural_name: "Burger Joints",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/burger_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 1285,
      geocodes: {
        main: {
          latitude: 47.626423,
          longitude: -122.339446,
        },
        roof: {
          latitude: 47.626423,
          longitude: -122.339446,
        },
      },
      location: {
        address: "731 Westlake Ave N",
        census_block: "530330067022000",
        country: "US",
        cross_street: "9th Ave N.",
        dma: "Seattle-Tacoma",
        formatted_address: "731 Westlake Ave N (9th Ave N.), Seattle, WA 98109",
        locality: "Seattle",
        postcode: "98109",
        region: "WA",
      },
      name: "Art Marble 21",
      photos: [
        {
          id: "652f74f2ecc72a330b39104a",
          created_at: "2023-10-18T06:02:26.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/38025_QiqHjRhL1ilg5GZiKYczastyiRGVVsGzAqhrQYo6CEs.jpg",
          width: 1080,
          height: 1920,
        },
        {
          id: "62aeb9e1766f5d6c3141f3f2",
          created_at: "2022-06-19T05:53:37.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1367915083_jsd2MmtVwuVFWxrWnZx1U4iVMrxPK4AmYidr4IXY5dE.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "623eb9b95fda2a290c75e9b5",
          created_at: "2022-03-26T06:59:05.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1382895104__VJTyKv3USDkRtXKDc6V_2fmhhdDnYOFhR5z-FD9pPY.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "61fe24a39086a709b0406afc",
          created_at: "2022-02-05T07:17:55.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2947896_vI6ks4CaiUTXE6DkvIg8mo72qPddG_BnVblyPcfAE-E.jpg",
          width: 1920,
          height: 1440,
        },
      ],
      rating: 6.5,
      tel: "(206) 223-0300",
      website: "https://www.artmarblebarseattle.com",
    },
    {
      fsq_id: "5b93019c2b274a002c0ea8f8",
      categories: [
        {
          id: 13002,
          name: "Bakery",
          short_name: "Bakery",
          plural_name: "Bakeries",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/bakery_",
            suffix: ".png",
          },
        },
        {
          id: 13034,
          name: "Café",
          short_name: "Café",
          plural_name: "Cafés",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/cafe_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 1432,
      geocodes: {
        drop_off: {
          latitude: 47.626553,
          longitude: -122.307301,
        },
        main: {
          latitude: 47.626552,
          longitude: -122.306976,
        },
        roof: {
          latitude: 47.626552,
          longitude: -122.306976,
        },
      },
      location: {
        address: "746 19th Ave E",
        census_block: "530330064003016",
        country: "US",
        cross_street: "E Aloha St",
        dma: "Seattle-Tacoma",
        formatted_address: "746 19th Ave E (E Aloha St), Seattle, WA 98112",
        locality: "Seattle",
        postcode: "98112",
        region: "WA",
      },
      name: "Macrina Bakery & Cafe",
      photos: [
        {
          id: "6699b972d495cd658ef12670",
          created_at: "2024-07-19T00:55:14.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/190152_rniDLlB9uU9siPQEmUnytvpix_ymc6OgpGnm3DqiWrU.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "65821cb5234bc67a89e071c0",
          created_at: "2023-12-19T22:44:05.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/13006584_D5uK-_oR6CFzEhOv-inLoPW_EVVgJWtgLxcGQbYyX-0.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "6553b65743b3b157828dd3ae",
          created_at: "2023-11-14T18:03:03.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/62128531__0YT_2GdK80YBMPKKId6KQ2M3HpdJWEkiSEF60iYG38.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "6547d7a846884e154bf684db",
          created_at: "2023-11-05T17:58:00.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/62128531_fVlSUPPrXRqPZ9khnaR9c7qx0PDYI-_EmF4b7h7wM6k.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "653470a09660a9701d5898e9",
          created_at: "2023-10-22T00:45:20.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1375840085_NVmmbpCxraGU1Gq0oGZJDl4O7Ff5zSXFPPv_2JaGUhA.jpg",
          width: 1440,
          height: 1920,
        },
      ],
      rating: 8.8,
      tel: "(206) 328-7573",
      website: "http://www.macrinabakery.com",
    },
    {
      fsq_id: "419a9480f964a520281e1fe3",
      categories: [
        {
          id: 13177,
          name: "Greek Restaurant",
          short_name: "Greek",
          plural_name: "Greek Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/greek_",
            suffix: ".png",
          },
        },
        {
          id: 13302,
          name: "Mediterranean Restaurant",
          short_name: "Mediterranean",
          plural_name: "Mediterranean Restaurants",
          icon: {
            prefix:
              "https://ss3.4sqi.net/img/categories_v2/food/mediterranean_",
            suffix: ".png",
          },
        },
        {
          id: 13314,
          name: "New American Restaurant",
          short_name: "New American",
          plural_name: "New American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/newamerican_",
            suffix: ".png",
          },
        },
      ],
      distance: 1450,
      geocodes: {
        main: {
          latitude: 47.613391,
          longitude: -122.340019,
        },
        roof: {
          latitude: 47.613391,
          longitude: -122.340019,
        },
      },
      location: {
        address: "2000 4th Ave",
        address_extended: "# B",
        census_block: "530330072022005",
        country: "US",
        cross_street: "at Virginia St",
        dma: "Seattle-Tacoma",
        formatted_address: "2000 4th Ave (at Virginia St), Seattle, WA 98121",
        locality: "Seattle",
        postcode: "98121",
        region: "WA",
      },
      name: "Lola",
      photos: [
        {
          id: "5a59b7301108ba5892be24bb",
          created_at: "2018-01-13T07:37:20.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/477680744_hKc_K8yOfuPUWbW_NbVs4Z13dXHyY8XkRPYCgKwdqKE.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "66e715e1da957f5b37ca4d22",
          created_at: "2024-09-15T17:14:09.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/140979370_WVjcD6C8S5J0h4s593vhIEkAO4S2meufKYyrtXgKTUE.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "66e353542a0e1978c37506af",
          created_at: "2024-09-12T20:47:16.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2285357_Y4O53y9NQMGjV58RrPiBhzjne-N2PuZi5WkTf988rqk.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "6670d9383573cb1ba9e44bc7",
          created_at: "2024-06-18T00:47:52.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/11431939_6TY9vDN9RYXhm5_GWGD6syYrj71LV2qWtkk474FKn_g.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "665378a172e23e579e15b633",
          created_at: "2024-05-26T18:00:01.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/841443__YVCXmHWeo8Ts1O4pRy9ns5XfJ07xVhPQ_lPtt2QHGo.jpg",
          width: 1440,
          height: 1920,
        },
      ],
      rating: 8.9,
      tel: "(206) 441-1430",
      website: "http://www.lolaseattle.com",
    },
    {
      fsq_id: "40b13b00f964a52073f61ee3",
      categories: [
        {
          id: 13018,
          name: "Pub",
          short_name: "Pub",
          plural_name: "Pubs",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/nightlife/pub_",
            suffix: ".png",
          },
        },
        {
          id: 13057,
          name: "Gastropub",
          short_name: "Gastropub",
          plural_name: "Gastropubs",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/gastropub_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 1484,
      geocodes: {
        main: {
          latitude: 47.609968,
          longitude: -122.335623,
        },
        roof: {
          latitude: 47.609968,
          longitude: -122.335623,
        },
      },
      location: {
        address: "1415 5th Ave",
        census_block: "530330081021007",
        country: "US",
        cross_street: "in The Red Lion Hotel",
        dma: "Seattle-Tacoma",
        formatted_address:
          "1415 5th Ave (in The Red Lion Hotel), Seattle, WA 98101",
        locality: "Seattle",
        postcode: "98101",
        region: "WA",
      },
      name: "Elephant & Castle",
      photos: [
        {
          id: "6704d4cfcd32607527ec16fa",
          created_at: "2024-10-08T06:44:31.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/60029219_fqnEXnV5ZF7729qaReRq6BDhPWVfsMfF15RzaLpj26k.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "66147be7d1b40b185869e40f",
          created_at: "2024-04-08T23:21:11.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2301888_4pccrhuLN43M3n5J8HuqjUFwRiim9cH6CE_Imxnx2Uo.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "6524b713cb330e18fb1cd549",
          created_at: "2023-10-10T02:29:39.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/55317_0q1u-lrIli3ZZYYXXxsPBMsQZFdQMjRi_19Ndqr4XQE.jpg",
          width: 1440,
          height: 1919,
        },
        {
          id: "64cc895ebdc1f37cf2f6c44a",
          created_at: "2023-08-04T05:15:10.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/66094684_ny8KnGlL1pTzXUOF-J5bhXmuGFYv_BWrufWZhnIpQHg.jpg",
          width: 1080,
          height: 1920,
        },
        {
          id: "63ad4ae2c459fd5a9825dff7",
          created_at: "2022-12-29T08:08:02.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/4923903_dJ2MDB1pCGob2FBEi4gVCrmkhzqFnB72sS9HL_wKY1A.jpg",
          width: 2718,
          height: 1908,
        },
      ],
      rating: 6.8,
      tel: "(206) 624-9977",
      website:
        "https://www.elephantcastle.com/en/locations/wa/seattle/1415-fifth-avenue",
    },
    {
      fsq_id: "50fddbf9e4b07b833be8f025",
      categories: [
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
        {
          id: 13022,
          name: "Sports Bar",
          short_name: "Sports Bar",
          plural_name: "Sports Bars",
          icon: {
            prefix:
              "https://ss3.4sqi.net/img/categories_v2/nightlife/sportsbar_",
            suffix: ".png",
          },
        },
      ],
      distance: 1498,
      geocodes: {
        main: {
          latitude: 47.610245,
          longitude: -122.337478,
        },
        roof: {
          latitude: 47.610245,
          longitude: -122.337478,
        },
      },
      location: {
        address: "1501 4th Ave",
        address_extended: "Ste 118",
        census_block: "530330081021004",
        country: "US",
        cross_street: "",
        dma: "Seattle-Tacoma",
        formatted_address: "1501 4th Ave, Seattle, WA 98101",
        locality: "Seattle",
        postcode: "98101",
        region: "WA",
      },
      name: "Yard House",
      photos: [
        {
          id: "516afc2fe4b01d6127182669",
          created_at: "2013-04-14T18:57:51.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/5950896_n6-IXN-CnoicMXNfhd_d_pY27KV-YhlCt7K0fX0CbfU.jpg",
          width: 1440,
          height: 1920,
          classifications: ["food"],
        },
        {
          id: "5179f0cae4b0c57409dfba98",
          created_at: "2013-04-26T03:13:14.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/974005_PhTvftM5C1zhRQOKqavRKY_1zOt66YGqbxPDEBbkgq8.jpg",
          width: 1920,
          height: 1166,
          classifications: ["indoor"],
        },
        {
          id: "5175d500e4b06d2755b7c842",
          created_at: "2013-04-23T00:25:36.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/9975633_byTXSkEy1VRIa8XKqNjCM06C0XhvMpO879rktaIpiE4.jpg",
          width: 1920,
          height: 1440,
          classifications: ["indoor"],
        },
        {
          id: "6646bbc4e2612448b35fa31b",
          created_at: "2024-05-17T02:07:00.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1391269246_EMduHt8FZgUZiq3hxcxPwTfVZY-e9KT3n0wbh4K_o0o.jpg",
          width: 1440,
          height: 1920,
        },
      ],
      rating: 7.8,
      tel: "(206) 682-2087",
      website: "http://www.yardhouse.com/",
    },
    {
      fsq_id: "59b5be7b47f8761788c58b6c",
      categories: [
        {
          id: 13035,
          name: "Coffee Shop",
          short_name: "Coffee Shop",
          plural_name: "Coffee Shops",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/coffeeshop_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 1609,
      geocodes: {
        main: {
          latitude: 47.629974,
          longitude: -122.341379,
        },
        roof: {
          latitude: 47.629974,
          longitude: -122.341379,
        },
      },
      location: {
        address: "1201 Westlake Ave N",
        census_block: "530330067012002",
        country: "US",
        cross_street: "",
        dma: "Seattle-Tacoma",
        formatted_address: "1201 Westlake Ave N, Seattle, WA 98109",
        locality: "Seattle",
        postcode: "98109",
        region: "WA",
      },
      name: "Caffe Umbria",
      photos: [
        {
          id: "66c3cf50dd2c613aa2fcdcec",
          created_at: "2024-08-19T23:03:44.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/482716529_0clsdMqW2VV7zYOu2ucHAGeKpqZ0xK3xL6eB_jkVFAs.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "63275e577bda511932d0d51f",
          created_at: "2022-09-18T18:07:19.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/178791_kBDFAISpt1uQ5Uc4Wuie3roRxPjGWyR79AftSIpdljo.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "5f5264ac3b1dbc7967d4f5a8",
          created_at: "2020-09-04T16:00:44.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1027709_4U74zyBPb839ZEZYd9XI2zItan1HVbNC4YzDkdIHj3M.jpg",
          width: 1440,
          height: 1440,
        },
        {
          id: "5c69dbe82619ee002c3049bf",
          created_at: "2019-02-17T22:10:48.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/504045625_42XDBcawitEDO6oN2Vk38WYVtsoWo-t755fMwn147Rg.jpg",
          width: 1440,
          height: 1920,
          classifications: ["food"],
        },
        {
          id: "5be4d1adf2554e002c3aa161",
          created_at: "2018-11-09T00:15:41.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1845552_AE52hBS0BA5PBUhcKCkyTQfIehoalrDnFfc4MBiC6P4.jpg",
          width: 1440,
          height: 1920,
          classifications: ["outdoor"],
        },
      ],
      rating: 7.6,
      tel: "(206) 708-6141",
      website: "http://caffeumbria.com",
    },
    {
      fsq_id: "46950309f964a520dc481fe3",
      categories: [
        {
          id: 13034,
          name: "Café",
          short_name: "Café",
          plural_name: "Cafés",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/cafe_",
            suffix: ".png",
          },
        },
        {
          id: 13314,
          name: "New American Restaurant",
          short_name: "New American",
          plural_name: "New American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/newamerican_",
            suffix: ".png",
          },
        },
        {
          id: 13334,
          name: "Sandwich Spot",
          short_name: "Sandwich Spot",
          plural_name: "Sandwich Spots",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/deli_",
            suffix: ".png",
          },
        },
      ],
      distance: 1655,
      geocodes: {
        main: {
          latitude: 47.632452,
          longitude: -122.310194,
        },
        roof: {
          latitude: 47.632452,
          longitude: -122.310194,
        },
      },
      location: {
        address: "1501 17th Ave E",
        census_block: "530330062003002",
        country: "US",
        cross_street: "at E Galer St.",
        dma: "Seattle-Tacoma",
        formatted_address:
          "1501 17th Ave E (at E Galer St.), Seattle, WA 98112",
        locality: "Seattle",
        postcode: "98112",
        region: "WA",
      },
      name: "Volunteer Park Cafe & Pantry",
      photos: [
        {
          id: "65a4800edb92f76277124f04",
          created_at: "2024-01-15T00:45:02.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/4545393_qYmvPZjf5RgUfN3JXF6gRSBpkLWMprrebRW6G16uCVQ.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "65a041079e3956176322ae0b",
          created_at: "2024-01-11T19:27:03.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/4545393_K0EC17tYcrOTqLLQp6d3_P91Gbksws3YzZVLg-l36Ac.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "6510e0b2a82c24721627bb80",
          created_at: "2023-09-25T01:21:54.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1375840085_ELjyJNTibIoakkKeMQTPCRw7bT2bBElk5RfP9l5_IeU.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "63436a40872c405b207675a0",
          created_at: "2022-10-10T00:41:36.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1375838995_3jrlAXeAtLv29Bz95f90FsEWsEeOSEgtbDRjQ5DDj4A.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "61d6112cbeb663009ae95caf",
          created_at: "2022-01-05T21:44:12.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/569141_yXBKZKZO1ufI6_ncogd_fVDbL4W1O6PsQrp7hWa8SKg.jpg",
          width: 1440,
          height: 1920,
        },
      ],
      rating: 9,
      tel: "(206) 328-3155",
      website: "http://www.alwaysfreshgoodness.com",
    },
    {
      fsq_id: "41f98080f964a5203b1f1fe3",
      categories: [
        {
          id: 10037,
          name: "Concert Hall",
          short_name: "Concert Hall",
          plural_name: "Concert Halls",
          icon: {
            prefix:
              "https://ss3.4sqi.net/img/categories_v2/arts_entertainment/musicvenue_",
            suffix: ".png",
          },
        },
        {
          id: 10039,
          name: "Music Venue",
          short_name: "Music Venue",
          plural_name: "Music Venues",
          icon: {
            prefix:
              "https://ss3.4sqi.net/img/categories_v2/arts_entertainment/musicvenue_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 1717,
      geocodes: {
        main: {
          latitude: 47.60806,
          longitude: -122.33704,
        },
        roof: {
          latitude: 47.608037,
          longitude: -122.336919,
        },
      },
      location: {
        address: "200 University St",
        census_block: "530330081021010",
        country: "US",
        cross_street: "btwn 2nd & 3rd Ave",
        dma: "Seattle-Tacoma",
        formatted_address:
          "200 University St (btwn 2nd & 3rd Ave), Seattle, WA 98101",
        locality: "Seattle",
        po_box: "P.O. Box 21669",
        postcode: "98101",
        region: "WA",
      },
      name: "Benaroya Hall",
      photos: [
        {
          id: "5172b766e4b00ac5fb3f0916",
          created_at: "2013-04-20T15:42:30.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2447649_4JWxTEysNqGBf2iU50qNK2-T9qLKjUI2Q3qzMelAA6M.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "66921c75b12b792fcd7c13cb",
          created_at: "2024-07-13T06:19:33.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/5978666_DBDZjP_WO9Np8t-gAfADeprdGvFVyRCLtcyDIW4D2iM.jpg",
          width: 1723,
          height: 1440,
        },
        {
          id: "665bc42457eaef7297fcbb99",
          created_at: "2024-06-02T01:00:20.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/78733_jfhsQk7y0YEp-sUTvyVEOyeeiOeyyBh0QAFaEMvjpb8.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "65714158cba5c40cf67e7662",
          created_at: "2023-12-07T03:51:52.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/7183279_rY5867MSdZF15GAKIa6_1PUXGcg4bVkfMyzbJ-rEqK8.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "651b92536c73e939c4fcee70",
          created_at: "2023-10-03T04:02:27.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/563347327_XWNA1Y2YUb_P_EiHFV1-s-Kr03MLipXkWCwDU2e-FT8.jpg",
          width: 1440,
          height: 1920,
        },
      ],
      rating: 9.1,
      tel: "(206) 215-4800",
      website: "http://www.benaroyahall.org",
    },
    {
      fsq_id: "6067c6944e5b15312015af89",
      categories: [
        {
          id: 13022,
          name: "Sports Bar",
          short_name: "Sports Bar",
          plural_name: "Sports Bars",
          icon: {
            prefix:
              "https://ss3.4sqi.net/img/categories_v2/nightlife/sportsbar_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 1828,
      geocodes: {
        main: {
          latitude: 47.625683,
          longitude: -122.347851,
        },
        roof: {
          latitude: 47.625683,
          longitude: -122.347851,
        },
      },
      location: {
        address: "701 5th Ave N",
        address_extended: "Unit 100",
        census_block: "530330067021012",
        country: "US",
        cross_street: "",
        dma: "Seattle-Tacoma",
        formatted_address: "701 5th Ave N, Seattle, WA 98109",
        locality: "Seattle",
        postcode: "98109",
        region: "WA",
      },
      name: "Cairde Public House",
      photos: [
        {
          id: "6376dfe1d3576d525364641b",
          created_at: "2022-11-18T01:29:05.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1903793_jA36BGjQMv8kV8WS3dOQn3jglOYDRHxDTqrTmkVp3i8.jpg",
          width: 1134,
          height: 2016,
        },
        {
          id: "636b54d65ce2cb0a0bc029d5",
          created_at: "2022-11-09T07:20:54.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/37999404_hgYM3ctdrG5tYcwFkB5ucx7y5rY2CYAwb1UgQ_wZBwk.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "62c3ba71367f87136b8e4600",
          created_at: "2022-07-05T04:13:37.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/500342_ecSa3J-U5dHj81sb-Wi6uUT7pFRAfofR8L_xwo1q8eU.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "629d50ca6898d9580cb218e0",
          created_at: "2022-06-06T00:56:42.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2022245_sg5_NWCWeF9AyFOO0w4hKrkTEjisZ7q4m3VaKFKH4sk.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "6247a0121ee5dd267ca7f7b7",
          created_at: "2022-04-02T01:00:02.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/8932711_QsneHedgZaXvSUlz9GqA810jUpQYv6rG9xXpvTxjbGs.jpg",
          width: 1512,
          height: 2016,
        },
      ],
      tel: "(206) 257-4217",
      website: "https://www.cphbarseattle.com",
    },
    {
      fsq_id: "49ee65a3f964a52058681fe3",
      categories: [
        {
          id: 13002,
          name: "Bakery",
          short_name: "Bakery",
          plural_name: "Bakeries",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/bakery_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
        {
          id: 13329,
          name: "Russian Restaurant",
          short_name: "Russian",
          plural_name: "Russian Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/russian_",
            suffix: ".png",
          },
        },
      ],
      distance: 1839,
      geocodes: {
        main: {
          latitude: 47.609906,
          longitude: -122.34232,
        },
        roof: {
          latitude: 47.609906,
          longitude: -122.34232,
        },
      },
      location: {
        address: "1908 Pike Pl",
        census_block: "530330080021004",
        country: "US",
        cross_street: "btwn Virginia & Stewart St",
        dma: "Seattle-Tacoma",
        formatted_address:
          "1908 Pike Pl (btwn Virginia & Stewart St), Seattle, WA 98101",
        locality: "Seattle",
        postcode: "98101",
        region: "WA",
      },
      name: "Piroshky Piroshky",
      photos: [
        {
          id: "550b2b7e498ee57f1c59dab3",
          created_at: "2015-03-19T20:03:10.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/17007880_3Mubx3X8vjkUsSxS6V02JJTcqqRhvaP0hIuphcjOo-8.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "518837f3498e17ced986b8e2",
          created_at: "2013-05-06T23:08:35.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/40504581_44v9fQKH1rIyFX3K_sd82Oga0cUM51GY8KyH2pjWm9g.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "516995c4e4b039022022f44b",
          created_at: "2013-04-13T17:28:36.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2886013_m9gxzyuvSdL8HqsBxeBEr6Jw1Tcw8at0Bs_r7uC8ij4.jpg",
          width: 1440,
          height: 1920,
          classifications: ["food"],
        },
        {
          id: "5991bb27c0f1632f70fc988d",
          created_at: "2017-08-14T15:00:55.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/53151016_XZN_rLm5uZMzpHC3mrdABv3PuPoWB0PL1jzc_JOjhJs.jpg",
          width: 1424,
          height: 1900,
          classifications: ["outdoor"],
        },
        {
          id: "6726712d1c69ad208b529632",
          created_at: "2024-11-02T18:36:29.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/56178__Jym2c5vdUO_x95mU0i-ah4u1DvtFtnVIe3TdT-gFUw.jpg",
          width: 1080,
          height: 1920,
        },
      ],
      rating: 8.6,
      tel: "(206) 441-6068",
      website: "http://www.piroshkybakery.com",
    },
    {
      fsq_id: "49bc015af964a52007541fe3",
      categories: [
        {
          id: 13022,
          name: "Sports Bar",
          short_name: "Sports Bar",
          plural_name: "Sports Bars",
          icon: {
            prefix:
              "https://ss3.4sqi.net/img/categories_v2/nightlife/sportsbar_",
            suffix: ".png",
          },
        },
        {
          id: 13031,
          name: "Burger Joint",
          short_name: "Burgers",
          plural_name: "Burger Joints",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/burger_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 1841,
      geocodes: {
        main: {
          latitude: 47.614502,
          longitude: -122.34704,
        },
        roof: {
          latitude: 47.614502,
          longitude: -122.34704,
        },
      },
      location: {
        address: "2331 2nd Ave",
        census_block: "530330080031002",
        country: "US",
        cross_street: "at Battery St",
        dma: "Seattle-Tacoma",
        formatted_address: "2331 2nd Ave (at Battery St), Seattle, WA 98121",
        locality: "Seattle",
        postcode: "98121",
        region: "WA",
      },
      name: "Buckley's",
      photos: [
        {
          id: "66dddc2430c30a5de8d22f14",
          created_at: "2024-09-08T17:17:24.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/8547_GbCvZpHIMsE4HfXOK2x62ZIm7R64TDexagkr9RhkZb4.jpg",
          width: 1079,
          height: 1920,
        },
        {
          id: "66da69e8b15680135ea40bc2",
          created_at: "2024-09-06T02:33:12.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2947896_7-kaWZwp1X9ITxXuBL1JqASlGpeJaUaucRsLc-D1uYU.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "66775f491f04b2686b028338",
          created_at: "2024-06-22T23:33:29.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2902033_Q2mKCDVEvJrvo0GUYHn0Ac643SaQD02VI2QJWpnccx4.jpg",
          width: 1080,
          height: 1920,
        },
        {
          id: "6650faf478be114d43c23cbc",
          created_at: "2024-05-24T20:39:16.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2902033_tmjXdGf09-lNtPe2tYg5KuejKgsUAkYHKguEuQrBaXA.jpg",
          width: 1080,
          height: 1920,
        },
        {
          id: "66087e95c27ef64933271fec",
          created_at: "2024-03-30T21:05:25.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2902033_p9YmVEkbxQtrUyInRA4V7aK9Nu9eWe6D03_9H7Il1kU.jpg",
          width: 1080,
          height: 1920,
        },
      ],
      rating: 7.6,
      tel: "(206) 588-8879",
      website: "http://www.buckleysseattle.com",
    },
    {
      fsq_id: "4c69a2db2c29d13a09c10b41",
      categories: [
        {
          id: 13314,
          name: "New American Restaurant",
          short_name: "New American",
          plural_name: "New American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/newamerican_",
            suffix: ".png",
          },
        },
        {
          id: 13338,
          name: "Seafood Restaurant",
          short_name: "Seafood",
          plural_name: "Seafood Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/seafood_",
            suffix: ".png",
          },
        },
      ],
      distance: 1864,
      geocodes: {
        main: {
          latitude: 47.610749,
          longitude: -122.343737,
        },
        roof: {
          latitude: 47.610749,
          longitude: -122.343737,
        },
      },
      location: {
        address: "2010 Western Ave",
        census_block: "530330080021003",
        country: "US",
        cross_street: "at Virginia St.",
        dma: "Seattle-Tacoma",
        formatted_address:
          "2010 Western Ave (at Virginia St.), Seattle, WA 98121",
        locality: "Seattle",
        postcode: "98121",
        region: "WA",
      },
      name: "Seatown Seabar & Rotisserie",
      photos: [
        {
          id: "5172faf4e4b076a9ba3f4928",
          created_at: "2013-04-20T20:30:44.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/9810_MrjVgJHnI0qyopDuQpilyDc-5TJtZ4AmJ70RwOrs6xg.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "66a6fddeb6f92a51d974017d",
          created_at: "2024-07-29T02:26:38.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/3805006_fYnj8XWGWjap5HmTy9ZY45YUvzRmsVEuhGNQ81v0B64.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "66a6fddd38f1a7390b4120b7",
          created_at: "2024-07-29T02:26:37.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/3805006_WPBd1WQw8mFE9d-slYuizEEnwPke5xR4IwBBQYiFamk.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "66230fdffe4c361dade16305",
          created_at: "2024-04-20T00:44:15.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/157032_G_0brbHKTXoBjDRXmNukeawgUTWcRMfj8qv9cTrHx4Q.jpg",
          width: 1440,
          height: 1920,
        },
      ],
      rating: 8,
      tel: "(206) 436-0390",
      website: "http://tomdouglas.com/index.php/restaurants/seatown",
    },
    {
      fsq_id: "416dc180f964a5209b1d1fe3",
      categories: [
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 1866,
      geocodes: {
        main: {
          latitude: 47.620506,
          longitude: -122.349304,
        },
        roof: {
          latitude: 47.620449,
          longitude: -122.349386,
        },
      },
      location: {
        address: "400 Broad St",
        census_block: "530330071012001",
        country: "US",
        cross_street: "",
        dma: "Seattle-Tacoma",
        formatted_address: "400 Broad St, Seattle, WA 98109",
        locality: "Seattle",
        postcode: "98109",
        region: "WA",
      },
      name: "Space Needle",
      photos: [
        {
          id: "51a956b4498ebe0679965eb7",
          created_at: "2013-06-01T02:04:36.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/8489344_inianSZMZyNeXLsHvo8iym8czF7vbJhL3Lyzo-XHC2w.jpg",
          width: 1918,
          height: 1434,
        },
        {
          id: "594b353d04d1ae5204e773de",
          created_at: "2017-06-22T03:10:53.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/141092307_hZE6NojqOpiVKdsiHvk1XZTkHPlgDIZXzMm8UIl9quc.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "55d38185498ec9bdf4a9e289",
          created_at: "2015-08-18T19:03:33.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/96769_g1Ent-3LgJn_eMNSNrY-7YolBPjmyZfY9jChX9-t6Jk.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "5555479d498e2ea6088bcbad",
          created_at: "2015-05-15T01:10:53.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/165177_LpCsa771wWxUxwdZon9Q8BJHi2-XX98btcFCX9g9hls.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "561afb5b498e136892bbc637",
          created_at: "2015-10-12T00:14:19.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/11047303_RU_wPcvlcGGIBijfhiCytkrPhewNxeM5SS0RnZ_tHIU.jpg",
          width: 1440,
          height: 1920,
          classifications: ["outdoor"],
        },
      ],
      rating: 8.8,
      tel: "(206) 905-2100",
      website: "http://www.spaceneedle.com",
    },
    {
      fsq_id: "4a735223f964a52020dc1fe3",
      categories: [
        {
          id: 13034,
          name: "Café",
          short_name: "Café",
          plural_name: "Cafés",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/cafe_",
            suffix: ".png",
          },
        },
        {
          id: 13035,
          name: "Coffee Shop",
          short_name: "Coffee Shop",
          plural_name: "Coffee Shops",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/coffeeshop_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 1889,
      geocodes: {
        main: {
          latitude: 47.607376,
          longitude: -122.339105,
        },
        roof: {
          latitude: 47.607376,
          longitude: -122.339105,
        },
      },
      location: {
        address: "1321 1st Ave",
        census_block: "530330081012005",
        country: "US",
        cross_street: "btwn Union & University St",
        dma: "Seattle-Tacoma",
        formatted_address:
          "1321 1st Ave (btwn Union & University St), Seattle, WA 98101",
        locality: "Seattle",
        postcode: "98101",
        region: "WA",
      },
      name: "Fonté Café - Seattle",
      photos: [
        {
          id: "51683e16e4b00992535e254c",
          created_at: "2013-04-12T17:02:14.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/5815957_WQQK41ojzmyOFzMUKKKPbXtSzeq9WiIP_ZEOZKJzEWg.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "517eb41fe4b0f03483704ac4",
          created_at: "2013-04-29T17:55:43.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/21575989_MbIrzTnpim7bYOkIP3xFFvFwxWN5V24h7GhnwmMK6-Q.jpg",
          width: 1440,
          height: 1920,
          classifications: ["indoor"],
        },
        {
          id: "5169e67be4b01d37b4622dec",
          created_at: "2013-04-13T23:12:59.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/21779582_9eRxRs7rERpvycOvF8JQpVPuC7XEUcWKAXJH6KV1oBo.jpg",
          width: 1440,
          height: 1920,
          classifications: ["food"],
        },
        {
          id: "51683ed6e4b01d70bde7c20b",
          created_at: "2013-04-12T17:05:26.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2854241_N39TiieZACPjLvHVt69IJemu239tzbEOGEIvmzDahYU.jpg",
          width: 1434,
          height: 1918,
          classifications: ["food"],
        },
        {
          id: "66a00ad554e2d4117b4f4aa5",
          created_at: "2024-07-23T19:56:05.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/4962262_S2Om_MuQa3EChmQCV9mKVuoM9jB6t-Hr1KoQLkKpSaM.jpg",
          width: 1440,
          height: 1920,
        },
      ],
      rating: 8,
      tel: "(206) 777-6193",
      website: "http://www.fontecoffee.com",
    },
    {
      fsq_id: "511d2f0db0ed4a4d357f263f",
      categories: [
        {
          id: 13003,
          name: "Bar",
          short_name: "Bar",
          plural_name: "Bars",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/nightlife/pub_",
            suffix: ".png",
          },
        },
        {
          id: 13057,
          name: "Gastropub",
          short_name: "Gastropub",
          plural_name: "Gastropubs",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/gastropub_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 1901,
      geocodes: {
        main: {
          latitude: 47.606558,
          longitude: -122.338408,
        },
        roof: {
          latitude: 47.606558,
          longitude: -122.338408,
        },
      },
      location: {
        address: "1225 1st Ave",
        census_block: "530330081013001",
        country: "US",
        cross_street: "at University St",
        dma: "Seattle-Tacoma",
        formatted_address: "1225 1st Ave (at University St), Seattle, WA 98101",
        locality: "Seattle",
        postcode: "98101",
        region: "WA",
      },
      name: "Von’s 1000 Spirits",
      photos: [
        {
          id: "5a0144a69ef8ef350483e9d9",
          created_at: "2017-11-07T05:29:10.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/53834745_PJseA8OV1x9Zb598BLi74HP8T1DrzWlkgDFejOc7Nf8.jpg",
          width: 1900,
          height: 1068,
          classifications: ["indoor"],
        },
        {
          id: "5b1dc8c1c0af5700392b031f",
          created_at: "2018-06-11T00:56:33.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/19924183_BanF0oM-pplQqt3v6iDS6qIINq0HDcuE-EREWR6_138.jpg",
          width: 1920,
          height: 1440,
          classifications: ["indoor"],
        },
        {
          id: "517f2236e4b0b86933694b0f",
          created_at: "2013-04-30T01:45:26.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2165640_vfbwnRvZMPIH3RCK84-eKC1ETUbNQe5eqF9oFqGLXnY.jpg",
          width: 1434,
          height: 1918,
        },
        {
          id: "517c8c4be4b04f1b6946d0db",
          created_at: "2013-04-28T02:41:15.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/7920998_aGLH5gQjLBT7Z2bGJIXRkgq2OlX50hNriiouSiZ6w-k.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "516a25d3e4b0e47bfb5bac98",
          created_at: "2013-04-14T03:43:15.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/52720766_RtloiMle6xu2kg_Xf_vnJuDizcpTmjsLrc6tKM34JJc.jpg",
          width: 1440,
          height: 1920,
          classifications: ["indoor"],
        },
      ],
      rating: 8.5,
      tel: "(206) 621-8667",
      website: "https://www.vons1000spirits.com",
    },
    {
      fsq_id: "40b13b00f964a5203df71ee3",
      categories: [
        {
          id: 13009,
          name: "Cocktail Bar",
          short_name: "Cocktail",
          plural_name: "Cocktail Bars",
          icon: {
            prefix:
              "https://ss3.4sqi.net/img/categories_v2/nightlife/cocktails_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
        {
          id: 13302,
          name: "Mediterranean Restaurant",
          short_name: "Mediterranean",
          plural_name: "Mediterranean Restaurants",
          icon: {
            prefix:
              "https://ss3.4sqi.net/img/categories_v2/food/mediterranean_",
            suffix: ".png",
          },
        },
      ],
      distance: 1929,
      geocodes: {
        main: {
          latitude: 47.608367,
          longitude: -122.34149,
        },
        roof: {
          latitude: 47.608367,
          longitude: -122.34149,
        },
      },
      location: {
        address: "1501 Western Ave",
        address_extended: "Ste 202",
        census_block: "530330081011005",
        country: "US",
        dma: "Seattle-Tacoma",
        formatted_address: "1501 Western Ave, Seattle, WA 98101",
        locality: "Seattle",
        postcode: "98101",
        region: "WA",
      },
      name: "Zig Zag Cafe",
      photos: [
        {
          id: "56e4d1ae498e0feda62fea5c",
          created_at: "2016-03-13T02:34:22.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2255894_SJV20oqfYXOEQD1nwMHu-Xke47gyGllcT6UMKXfprWQ.jpg",
          width: 1920,
          height: 1440,
          classifications: ["indoor"],
        },
        {
          id: "56ee4c0fcd10ffe7a5a76537",
          created_at: "2016-03-20T07:06:55.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/68377548_4Fl6AhoUZKKGUPpPHHDX_SML_pziX6eZESddTiOS0c4.jpg",
          width: 1920,
          height: 1440,
          classifications: ["food"],
        },
        {
          id: "56ee24e3cd106dcec86dee1f",
          created_at: "2016-03-20T04:19:47.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/19625100_gNov3kRNrVLPvGCc9VY-cTxMj1aXKEcMw3tOmsqjs3Q.jpg",
          width: 1440,
          height: 1920,
          classifications: ["food"],
        },
        {
          id: "57958976498ef657b09d5172",
          created_at: "2016-07-25T03:37:26.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/22335703_wcLCBVV60KPXh_WKznGq6d6z4W0cv7UrWE_Mrhc0jxM.jpg",
          width: 1440,
          height: 1440,
          classifications: ["food"],
        },
        {
          id: "67284bd738f9937c152f7e2c",
          created_at: "2024-11-04T04:21:43.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/869629_iq3UlUbPkf9op-gwOa51z-O8hWtBXePsxXft-kDMfrU.jpg",
          width: 1080,
          height: 1440,
        },
      ],
      rating: 8.9,
      tel: "(206) 625-1146",
      website: "http://zigzagseattle.com",
    },
    {
      fsq_id: "51dcc3c8498e8458c328b441",
      categories: [
        {
          id: 13016,
          name: "Lounge",
          short_name: "Lounge",
          plural_name: "Lounges",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/nightlife/default_",
            suffix: ".png",
          },
        },
        {
          id: 13057,
          name: "Gastropub",
          short_name: "Gastropub",
          plural_name: "Gastropubs",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/gastropub_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 2013,
      geocodes: {
        drop_off: {
          latitude: 47.615336,
          longitude: -122.349859,
        },
        main: {
          latitude: 47.615524,
          longitude: -122.349747,
        },
        roof: {
          latitude: 47.615524,
          longitude: -122.349747,
        },
      },
      location: {
        address: "2604 1st Ave",
        census_block: "530330080032002",
        country: "US",
        cross_street: "",
        dma: "Seattle-Tacoma",
        formatted_address: "2604 1st Ave, Seattle, WA 98121",
        locality: "Seattle",
        postcode: "98121",
        region: "WA",
      },
      name: "Some Random Bar",
      photos: [
        {
          id: "64e049252396107d45a99ff7",
          created_at: "2023-08-19T04:46:29.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/406912_4RMF2GDiaN6hTFNGZxivP8mVLaXE3-wqJ-LVuNw4AoM.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "64dc148d71b7fd1049fa245f",
          created_at: "2023-08-16T00:13:01.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/248122_hs-7Dmik9294xuOGEFvWpdFDgV0GLAXq6VPMANi0E0I.jpg",
          width: 1006,
          height: 1920,
        },
        {
          id: "64bcacae22bd6f4d0f76e298",
          created_at: "2023-07-23T04:29:34.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1401131424_LLnXmGcC4nOiOCWD16esXip9EYwET0IEUYnHvqXCJO0.jpg",
          width: 1440,
          height: 1828,
        },
        {
          id: "6393681100ba1268c03daf29",
          created_at: "2022-12-09T16:53:37.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2902033_mlMGfVb2PKyyl4nYxk7Plb7pOEHp1dZyOY8yDYcaEI8.jpg",
          width: 1920,
          height: 1080,
        },
      ],
      rating: 8.8,
      tel: "(206) 745-2185",
      website: "http://somerandombar.com",
    },
    {
      fsq_id: "42e18880f964a52066261fe3",
      categories: [
        {
          id: 10039,
          name: "Music Venue",
          short_name: "Music Venue",
          plural_name: "Music Venues",
          icon: {
            prefix:
              "https://ss3.4sqi.net/img/categories_v2/arts_entertainment/musicvenue_",
            suffix: ".png",
          },
        },
        {
          id: 13067,
          name: "African Restaurant",
          short_name: "African",
          plural_name: "African Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/african_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 2031,
      geocodes: {
        main: {
          latitude: 47.621524,
          longitude: -122.35082,
        },
        roof: {
          latitude: 47.621524,
          longitude: -122.35082,
        },
      },
      location: {
        address: "305 Harrison St",
        census_block: "530330071012001",
        country: "US",
        cross_street: "at 5th Ave",
        dma: "Seattle-Tacoma",
        formatted_address: "305 Harrison St (at 5th Ave), Seattle, WA 98109",
        locality: "Seattle",
        postcode: "98109",
        region: "WA",
      },
      name: "Seattle Center",
      photos: [
        {
          id: "67329f7d3216367631083c1a",
          created_at: "2024-11-12T00:21:17.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/494627634_5BZGXzsvEMWQnncrmBA0rYvEFnxrHaU3I0CApSCZZRY.jpg",
          width: 1919,
          height: 1072,
        },
        {
          id: "67258506f8d762390b1d2184",
          created_at: "2024-11-02T01:48:54.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/532976941_UGpLq5ThOWvmKNnRyCZzh0pllPjVlb9mnQYC-jdh4sA.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "6715a4c0654ac24a8bd88b15",
          created_at: "2024-10-21T00:48:00.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/589896705_xiUYnUKNxnXkByzK28ZqhvsLBPG338PAHg2DC61l_HE.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "671263cef036152264ee0cc6",
          created_at: "2024-10-18T13:34:06.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/776854_-HmUVuWu9URbsYGWrpHcli3SneLLjmV1A-5z53nbhC4.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "66d78c165c891a25479d8d55",
          created_at: "2024-09-03T22:22:14.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/776854_q6IfQJgL8xkmsgzyUpjieJHfTZMoitBr5SbdZ43tAs4.jpg",
          width: 1440,
          height: 1920,
        },
      ],
      rating: 9.3,
      tel: "(206) 684-7200",
      website: "http://www.seattlecenter.com",
    },
    {
      fsq_id: "50631209e4b043ec7fdba68d",
      categories: [
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 2059,
      geocodes: {
        drop_off: {
          latitude: 47.610357,
          longitude: -122.346884,
        },
        main: {
          latitude: 47.610694,
          longitude: -122.346685,
        },
        roof: {
          latitude: 47.610694,
          longitude: -122.346685,
        },
      },
      location: {
        address: "2100 Alaskan Way",
        census_block: "530330080022009",
        country: "US",
        cross_street: "",
        dma: "Seattle-Tacoma",
        formatted_address: "2100 Alaskan Way, Seattle, WA 98121",
        locality: "Seattle",
        postcode: "98121",
        region: "WA",
      },
      name: "Marriott Concierge Lounge",
      photos: [
        {
          id: "67018b8a0030b8682aa59774",
          created_at: "2024-10-05T18:55:06.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/7468507_M2eW6soNTH_TqPp9j33YiqYhEXYaXTiBnFNUoGBuiKw.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "67018b885c3e6761afb23613",
          created_at: "2024-10-05T18:55:04.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/7468507_Jx1_T-R3hzOswFAd6YOIhYLJ6uBZ8pLaH93FivHxGU4.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "65b87935c5df1138da6e4336",
          created_at: "2024-01-30T04:21:09.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/21891252_0o4yXok13ftvH4-avNV95cR7A2Zzakz0eYzd9ImRs58.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "63ed02129ce8ac6eab2a820a",
          created_at: "2023-02-15T16:02:26.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/12101057_fF1JH0carI6XabGUSn-J1pmrkq_uqPPGkcoqrVG_GBo.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "622cb85abe921405d522e885",
          created_at: "2022-03-12T15:12:26.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1384417145_Jcsi65toYovARQ9eCIahd1USnn_H0lp2SiDU1O3hJLc.jpg",
          width: 1048,
          height: 1920,
        },
      ],
      rating: 8.2,
    },
    {
      fsq_id: "49e7ab3df964a520ff641fe3",
      categories: [
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
        {
          id: 13338,
          name: "Seafood Restaurant",
          short_name: "Seafood",
          plural_name: "Seafood Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/seafood_",
            suffix: ".png",
          },
        },
      ],
      distance: 2085,
      geocodes: {
        main: {
          latitude: 47.605993,
          longitude: -122.341159,
        },
        roof: {
          latitude: 47.605993,
          longitude: -122.341159,
        },
      },
      location: {
        address: "1301 Alaskan Way",
        census_block: "530330081013017",
        country: "US",
        cross_street: "at Pier 57",
        dma: "Seattle-Tacoma",
        formatted_address: "1301 Alaskan Way (at Pier 57), Seattle, WA 98101",
        locality: "Seattle",
        postcode: "98101",
        region: "WA",
      },
      name: "The Crab Pot",
      photos: [
        {
          id: "5ab72a4031ac6c018266bb0f",
          created_at: "2018-03-25T04:49:04.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/50752671_eeWMWLpI6a2V3kuosXq_5f0RFWSFdTPVlxf9bQZVDvw.jpg",
          width: 1440,
          height: 1920,
          classifications: ["menu"],
        },
        {
          id: "571408bd498e057aaf01d385",
          created_at: "2016-04-17T22:05:49.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/3217673_kAgBe0wP8gM-z3J38R-axHT3X_awTnBkijngOSt9ndQ.jpg",
          width: 1920,
          height: 1440,
          classifications: ["outdoor"],
        },
        {
          id: "67219c4f0a70513baf713520",
          created_at: "2024-10-30T02:39:11.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/532976941_972lBdgyuGDZAApuW3Y-pdCVc26kZL1LSTQ1RRqA0yA.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "671c54fb0ce65631563ff79f",
          created_at: "2024-10-26T02:33:31.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/10873766_zo2Wk-E46WcXXuJIXkKC9RLAwt2DRlcwqo7hJh_P-Fs.jpg",
          width: 1008,
          height: 1792,
        },
        {
          id: "671c460dbaf3f50077e4cbe9",
          created_at: "2024-10-26T01:29:49.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/206520824_VIpa8uOsyPdaXIMhpRqwsFul5Zkbaef4nB_ALqxdzbE.jpg",
          width: 1440,
          height: 1920,
        },
      ],
      rating: 7.9,
      tel: "(206) 624-1890",
      website: "http://www.thecrabpotseattle.com",
    },
    {
      fsq_id: "45b7e1dbf964a520cb411fe3",
      categories: [
        {
          id: 13022,
          name: "Sports Bar",
          short_name: "Sports Bar",
          plural_name: "Sports Bars",
          icon: {
            prefix:
              "https://ss3.4sqi.net/img/categories_v2/nightlife/sportsbar_",
            suffix: ".png",
          },
        },
        {
          id: 13029,
          name: "Brewery",
          short_name: "Brewery",
          plural_name: "Breweries",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/brewery_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 2099,
      geocodes: {
        main: {
          latitude: 47.625691,
          longitude: -122.35208,
        },
        roof: {
          latitude: 47.625691,
          longitude: -122.35208,
        },
      },
      location: {
        address: "200 Roy St",
        address_extended: "Ste 105",
        census_block: "530330070023009",
        country: "US",
        cross_street: "at 3rd Ave. N",
        dma: "Seattle-Tacoma",
        formatted_address: "200 Roy St (at 3rd Ave. N), Seattle, WA 98109",
        locality: "Seattle",
        postcode: "98109",
        region: "WA",
      },
      name: "McMenamins Queen Anne",
      photos: [
        {
          id: "654708095de51f705eaba6e8",
          created_at: "2023-11-05T03:12:09.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/68377548_QnbmilShoKBjU9GLiUhFoVhpYmhngpjQkdYju-DmT2I.jpg",
          width: 1080,
          height: 1920,
        },
        {
          id: "6529a146d680760a0ab6f266",
          created_at: "2023-10-13T19:57:58.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/409942496_53sccsdSeuII_bQvZJK90h2iexSTJ7ZK4h4a9Yj6ZMI.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "629d4c71ca22f32b50dd5e9f",
          created_at: "2022-06-06T00:38:09.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2022245_ClV68jgTgcJrYkWj6LByo6NRBB1ygJqdZyyEGchZBko.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "6288081e7ba59a61ce5d09e3",
          created_at: "2022-05-20T21:29:02.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/566692979_nP4ANyHImrpz2zCAIUdeYW4axzpSzyD0ZN-9UUnJob4.jpg",
          width: 1512,
          height: 2016,
        },
      ],
      rating: 7.5,
      tel: "(206) 285-4722",
      website: "http://www.mcmenamins.com",
    },
    {
      fsq_id: "44db996df964a5209c361fe3",
      categories: [
        {
          id: 13003,
          name: "Bar",
          short_name: "Bar",
          plural_name: "Bars",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/nightlife/pub_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
        {
          id: 13338,
          name: "Seafood Restaurant",
          short_name: "Seafood",
          plural_name: "Seafood Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/seafood_",
            suffix: ".png",
          },
        },
      ],
      distance: 2202,
      geocodes: {
        main: {
          latitude: 47.604116,
          longitude: -122.339507,
        },
        roof: {
          latitude: 47.604116,
          longitude: -122.339507,
        },
      },
      location: {
        address: "1001 Alaskan Way",
        address_extended: "Ste 200",
        census_block: "530330081022015",
        country: "US",
        cross_street: "at Madison St",
        dma: "Seattle-Tacoma",
        formatted_address:
          "1001 Alaskan Way (at Madison St), Seattle, WA 98104",
        locality: "Seattle",
        postcode: "98104",
        region: "WA",
      },
      name: "Ivar's Acres of Clams",
      photos: [
        {
          id: "5172ea63e4b0182f5a7d8be5",
          created_at: "2013-04-20T19:20:03.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/37777324_8Tdl0clne8v3dVs663YrgaV24LhuOdDf3rSdHKcCfkw.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "672e9070ab60e50abcf59f9a",
          created_at: "2024-11-08T22:28:00.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/40328978_jWAweh7A5fZK5BFzJLqYTPiVoPI2g_Z8M67xtOH-6tA.jpg",
          width: 1440,
          height: 1084,
        },
        {
          id: "66f377451e327673493abced",
          created_at: "2024-09-25T02:36:53.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/39966514_dUTAUcJ8DsO8mNYj_gNnL8c0jmPfIYiCZIX5D_1224s.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "66cb88c1a25c2a00662f0115",
          created_at: "2024-08-25T19:40:49.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/38025_f8iQsndeBBkItRvOzfP0o7RKVFQsi2_NMZ_kSievzxY.jpg",
          width: 1920,
          height: 1080,
        },
        {
          id: "66a9842f8975d136c2fe7578",
          created_at: "2024-07-31T00:24:15.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/29636848_FXndb7bqumLBbHzJ5bA13QVNn3Qow_feiYm_Bx6X0-c.jpg",
          width: 1440,
          height: 1920,
        },
      ],
      rating: 8.6,
      tel: "(206) 624-6852",
      website: "https://www.ivars.com/locations/acres-of-clams",
    },
    {
      fsq_id: "53572efc498ef17f993a4ebf",
      categories: [
        {
          id: 13003,
          name: "Bar",
          short_name: "Bar",
          plural_name: "Bars",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/nightlife/pub_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
        {
          id: 13338,
          name: "Seafood Restaurant",
          short_name: "Seafood",
          plural_name: "Seafood Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/seafood_",
            suffix: ".png",
          },
        },
      ],
      distance: 2253,
      geocodes: {
        main: {
          latitude: 47.623466,
          longitude: -122.354392,
        },
        roof: {
          latitude: 47.623466,
          longitude: -122.354392,
        },
      },
      location: {
        address: "124 Republican St",
        census_block: "530330071012004",
        country: "US",
        cross_street: "",
        dma: "Seattle-Tacoma",
        formatted_address: "124 Republican St, Seattle, WA 98109",
        locality: "Seattle",
        postcode: "98109",
        region: "WA",
      },
      name: "Taylor Shellfish Oyster Bar",
      photos: [
        {
          id: "66c64b63ad56453c14b004aa",
          created_at: "2024-08-21T20:17:39.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/926190_atXjJimenB0DJAyE6qbacWQsFIzi5eQTtc19xbvXo4Y.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "66c6483f414e493c609904a5",
          created_at: "2024-08-21T20:04:15.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/926190_kO2ibEbK9cR8DxvN5wRVZ15ztW6VyxCTHipZ9eZ6IQU.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "662eee87fddd985026b9a989",
          created_at: "2024-04-29T00:49:11.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/975241_m0wQypKUnPR979qbwZDfIkFiiCMLGz9ErnRZAP4FvJk.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "6622ce775d097d02b860efe7",
          created_at: "2024-04-19T20:05:11.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/11046315_lMlrkemTRaRD1mdnFqph_TWesECoOG0LBtT2we8afpc.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "64d167fe8f50940c489018c0",
          created_at: "2023-08-07T21:54:06.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/55317_LGBNaxnHogwq5wL_ZXnPJNL_CLdKX1ZDvagn7QZqYf0.jpg",
          width: 1440,
          height: 1920,
        },
      ],
      rating: 9,
      tel: "(206) 501-4442",
      website: "https://www.taylorshellfishfarms.com/location/queen-anne",
    },
    {
      fsq_id: "439be173f964a520e62b1fe3",
      categories: [
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
        {
          id: 13338,
          name: "Seafood Restaurant",
          short_name: "Seafood",
          plural_name: "Seafood Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/seafood_",
            suffix: ".png",
          },
        },
      ],
      distance: 2327,
      geocodes: {
        main: {
          latitude: 47.61241,
          longitude: -122.352154,
        },
        roof: {
          latitude: 47.61241,
          longitude: -122.352154,
        },
      },
      location: {
        address: "2411 Alaskan Way",
        census_block: "530330080043014",
        country: "US",
        cross_street: "at Edgewater Hotel",
        dma: "Seattle-Tacoma",
        formatted_address:
          "2411 Alaskan Way (at Edgewater Hotel), Seattle, WA 98121",
        locality: "Seattle",
        postcode: "98121",
        region: "WA",
      },
      name: "Six Seven restaurant",
      photos: [
        {
          id: "666e6c6cdf93b7171e0ed12f",
          created_at: "2024-06-16T04:39:08.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/5029386_L7HenDYVPWsfBnaYBJ7hCKieceAjr3VUpQLtHfIsgJU.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "65e41a6720cb31025a3bd561",
          created_at: "2024-03-03T06:36:23.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/17202125_h5aIYic2bDjTckLXGGbxFg84zDFjS7VW0TxBTYeBMVg.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "65e41a58ac1e095419c3cf14",
          created_at: "2024-03-03T06:36:08.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/17202125_6tRbbpq2KlnKMu16FIi19Ci6B_kGQcrZXADuO_MBlbU.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "64e23387ffdf322ba577e767",
          created_at: "2023-08-20T15:38:47.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/7240803_Ok3ANS1yktqmqEcQKwrsoIfvGUPo51GjN9-TYrEQ-qo.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "64e23387bfb29f2b999e2be3",
          created_at: "2023-08-20T15:38:47.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/7240803_afaXo5KVbGvDfDsz3yQ-gQEyER09c4reiwDPxs-yhps.jpg",
          width: 1920,
          height: 1440,
        },
      ],
      rating: 8.4,
      tel: "(206) 269-4575",
      website:
        "https://www.edgewaterhotel.com/seattle-six-seven-restaurant.aspx",
    },
    {
      fsq_id: "40b3de00f964a52017001fe3",
      categories: [
        {
          id: 13015,
          name: "Karaoke Bar",
          short_name: "Karaoke",
          plural_name: "Karaoke Bars",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/nightlife/karaoke_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 2356,
      geocodes: {
        main: {
          latitude: 47.608142,
          longitude: -122.299347,
        },
        roof: {
          latitude: 47.608142,
          longitude: -122.299347,
        },
      },
      location: {
        address: "2514 E Cherry St",
        census_block: "530330088002005",
        country: "US",
        cross_street: "at 25th Ave",
        dma: "Seattle-Tacoma",
        formatted_address: "2514 E Cherry St (at 25th Ave), Seattle, WA 98122",
        locality: "Seattle",
        postcode: "98122",
        region: "WA",
      },
      name: "Twilight Exit",
      photos: [
        {
          id: "66d64e0d81e70f60a6be525c",
          created_at: "2024-09-02T23:45:17.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/428507697_GDjXIL6ZNOE3gWnrLJ1EiCaiwfr_r-j93dVWogojL9A.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "609dd1f25f96114054f037f8",
          created_at: "2021-05-14T01:27:14.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/8210821_oprJX_yyhMLRTP9adbPMKvkpYcne3zJEwBrvm5Z1rqo.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "5c302273a30619002cf17bba",
          created_at: "2019-01-05T03:20:19.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/13861948_3SvV_qEZZeb-baHGdyf45ZVpgRYoLLwaioiQIw3YuCA.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "5bf600abda7080002c3df7a7",
          created_at: "2018-11-22T01:04:43.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/7085_9b1qDWG6l7fYFMOEgIdua_RYX1V4c3jW34NOnkzwjpg.jpg",
          width: 1425,
          height: 1900,
          classifications: ["menu"],
        },
      ],
      rating: 9,
      tel: "(206) 324-7462",
      website: "http://www.twilightexit.com",
    },
    {
      fsq_id: "56b101bb498e4905510a5798",
      categories: [
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 2380,
      geocodes: {
        drop_off: {
          latitude: 47.600238,
          longitude: -122.331577,
        },
        main: {
          latitude: 47.600234,
          longitude: -122.331329,
        },
        roof: {
          latitude: 47.600234,
          longitude: -122.331329,
        },
      },
      location: {
        address: "240 2nd Ave S",
        census_block: "530330092002010",
        country: "US",
        cross_street: "Main",
        dma: "Seattle-Tacoma",
        formatted_address: "240 2nd Ave S (Main), Seattle, WA 98104",
        locality: "Seattle",
        postcode: "98104",
        region: "WA",
      },
      name: "Flatstick Pub - Pioneer Square",
      photos: [
        {
          id: "649b48a8dd9201427bda6c60",
          created_at: "2023-06-27T20:38:00.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/27328890_1KtI-sybc92WoRqlUAwsaoWBGE6x6XCFDJ3LVvGKRA8.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "649b474b29170c551c283af0",
          created_at: "2023-06-27T20:32:11.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/27328890_Lv75fhcRoW9OBxAcrgJWwUDLINw0M9Qo3HZJM1wWito.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "63d873536ef4223a5e21a0d3",
          created_at: "2023-01-31T01:48:03.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/566692979_TM2eo9L7bVr4u1WubdzY_BNv4AORML8Mn3HA37HCmKA.jpg",
          width: 1512,
          height: 2016,
        },
      ],
      rating: 9,
      tel: "(206) 682-0608",
      website: "https://flatstickpub.com/pioneer-square",
    },
    {
      fsq_id: "4287e280f964a52041231fe3",
      categories: [
        {
          id: 13031,
          name: "Burger Joint",
          short_name: "Burgers",
          plural_name: "Burger Joints",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/burger_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
        {
          id: 13145,
          name: "Fast Food Restaurant",
          short_name: "Fast Food",
          plural_name: "Fast Food Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/fastfood_",
            suffix: ".png",
          },
        },
      ],
      distance: 2424,
      geocodes: {
        main: {
          latitude: 47.623495,
          longitude: -122.356389,
        },
        roof: {
          latitude: 47.623495,
          longitude: -122.356389,
        },
      },
      location: {
        address: "500 Queen Anne Ave N",
        census_block: "530330071012005",
        country: "US",
        cross_street: "at Republican St",
        dma: "Seattle-Tacoma",
        formatted_address:
          "500 Queen Anne Ave N (at Republican St), Seattle, WA 98109",
        locality: "Seattle",
        postcode: "98109",
        region: "WA",
      },
      name: "Dick's Drive-In",
      photos: [
        {
          id: "552c4774498e48c2fb455a9b",
          created_at: "2015-04-13T22:47:16.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/167526_9Ee7_e_8S5f383CGgL9-EhrLLckfeiElCiyooiGf2P4.jpg",
          width: 1920,
          height: 1440,
          classifications: ["food"],
        },
        {
          id: "568b3506498e95f04d035524",
          created_at: "2016-01-05T03:14:14.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/39194217_w0_u74hVmIo1bwk9EywmZSaEcyUofAfgT3O07cAMfFk.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "5593524e498ed3351cfbfb1f",
          created_at: "2015-07-01T02:37:02.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2194035_lXBwXHnGKYh_cn-tKZUt-sWmMs1d-CvOXziE_djIL5c.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "55454710498ef7bf97a18377",
          created_at: "2015-05-02T21:52:16.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/8338400_E1VIr7TOP_vDmkzqekNjwgH8wCKF5Sj7wo9nRCgsRwU.jpg",
          width: 1440,
          height: 1920,
          classifications: ["indoor"],
        },
        {
          id: "51949c43498e685d402f6fe5",
          created_at: "2013-05-16T08:43:47.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/9547145_CsCjpeoK-x4fziOmWJm-KLJ2IredMM7Mo5OPOHyBAg8.jpg",
          width: 1918,
          height: 1434,
        },
      ],
      rating: 7.9,
      tel: "(206) 285-5155",
      website: "http://www.ddir.com",
    },
    {
      fsq_id: "4ae60430f964a52018a421e3",
      categories: [
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
        {
          id: 13095,
          name: "Cajun and Creole Restaurant",
          short_name: "Cajun / Creole",
          plural_name: "Cajun and Creole Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/cajun_",
            suffix: ".png",
          },
        },
      ],
      distance: 2462,
      geocodes: {
        main: {
          latitude: 47.624792,
          longitude: -122.356962,
        },
        roof: {
          latitude: 47.624792,
          longitude: -122.356962,
        },
      },
      location: {
        address: "601 Queen Anne Ave N",
        census_block: "530330070013005",
        country: "US",
        cross_street: "at Mercer St",
        dma: "Seattle-Tacoma",
        formatted_address:
          "601 Queen Anne Ave N (at Mercer St), Seattle, WA 98109",
        locality: "Seattle",
        postcode: "98109",
        region: "WA",
      },
      name: "Toulouse Petit Kitchen & Lounge",
      photos: [
        {
          id: "59bdded058aa7f10d5ba5648",
          created_at: "2017-09-17T02:32:48.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2542353_70gh_ok7J2S0M0i9977RhkDyGMz834vbaKwpR0fHpiI.jpg",
          width: 1900,
          height: 1424,
        },
        {
          id: "672c33eabc13ca660a497651",
          created_at: "2024-11-07T03:28:42.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1389414456_gLWbGQ-IroQTABvUypGOR4dSfwpSwIJXEocsQyJkS-g.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "672c2bca2a301b65920a9e8d",
          created_at: "2024-11-07T02:54:02.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1389414456_LdKpVAUP-n0jMsAI_OhmQbCL07vZOTpcKLRAxn34DQg.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "672c2bc80307f668208afda4",
          created_at: "2024-11-07T02:54:00.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1389414456_MjQXb6MLFYGhz2xB7tty9AQsZTkmQGc7_V-YAMJYBZM.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "66f9b0935ef32d703432f0b3",
          created_at: "2024-09-29T19:54:59.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/53151016_y20_iuQuUdinmpWgILjkiwcl8fn4hq-LaqWNlSOHekM.jpg",
          width: 1008,
          height: 1792,
        },
      ],
      rating: 8.8,
      tel: "(206) 432-9069",
      website: "http://toulousepetit.com",
    },
    {
      fsq_id: "42893400f964a52067231fe3",
      categories: [
        {
          id: 13030,
          name: "Buffet",
          short_name: "Buffet",
          plural_name: "Buffets",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
        {
          id: 13031,
          name: "Burger Joint",
          short_name: "Burgers",
          plural_name: "Burger Joints",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/burger_",
            suffix: ".png",
          },
        },
        {
          id: 13314,
          name: "New American Restaurant",
          short_name: "New American",
          plural_name: "New American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/newamerican_",
            suffix: ".png",
          },
        },
      ],
      distance: 2514,
      geocodes: {
        main: {
          latitude: 47.620771,
          longitude: -122.357707,
        },
        roof: {
          latitude: 47.620771,
          longitude: -122.357707,
        },
      },
      location: {
        address: "232 1st Ave W",
        address_extended: "232 1st Ave W",
        census_block: "530330071011006",
        country: "US",
        cross_street: "at Thomas St",
        dma: "Seattle-Tacoma",
        formatted_address: "232 1st Ave W (at Thomas St), Seattle, WA 98119",
        locality: "Seattle",
        postcode: "98119",
        region: "WA",
      },
      name: "Buckley's on Queen Anne",
      photos: [
        {
          id: "67083249ef7a4f1ab4b727b5",
          created_at: "2024-10-10T20:00:09.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/589896705_mMs6rZxU8BsPIWrpfDATAOqjnj-aZI4swcs1VRB2eZw.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "65756e8270555b4838ffd0d1",
          created_at: "2023-12-10T07:53:38.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/14657222_l378xBmCsfkcvVQ8jb36sO41xYMxKDqMq72FbEl2Lyc.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "65756e81800a0460b79f84c3",
          created_at: "2023-12-10T07:53:37.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/14657222_PQVpRWP9QSU027Vbgfok_n_u_rRPtIrRSL4zFk1GfUA.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "65756e7edb45e219640f09f1",
          created_at: "2023-12-10T07:53:34.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/14657222_JqVqoJQbbdqblrFDJ7VVAxUKQU6V0CbwfIQi-zE2zns.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "65756e7ce0d2c834e42b3397",
          created_at: "2023-12-10T07:53:32.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/14657222_xVBTHXFed1ahX02zwnbyYJvXaa6RPiiLrrbFUeahtIc.jpg",
          width: 1920,
          height: 1440,
        },
      ],
      rating: 7.5,
      tel: "(206) 691-0232",
      website: "http://www.buckleysseattle.com/mainpage.htm",
    },
    {
      fsq_id: "4137b400f964a520cf1a1fe3",
      categories: [
        {
          id: 13010,
          name: "Dive Bar",
          short_name: "Dive Bar",
          plural_name: "Dive Bars",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/nightlife/divebar_",
            suffix: ".png",
          },
        },
        {
          id: 13015,
          name: "Karaoke Bar",
          short_name: "Karaoke",
          plural_name: "Karaoke Bars",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/nightlife/karaoke_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
      ],
      distance: 2566,
      geocodes: {
        drop_off: {
          latitude: 47.624591,
          longitude: -122.358285,
        },
        main: {
          latitude: 47.624353,
          longitude: -122.358286,
        },
        roof: {
          latitude: 47.624353,
          longitude: -122.358286,
        },
      },
      location: {
        address: "105 W Mercer St",
        census_block: "530330071022000",
        country: "US",
        dma: "Seattle-Tacoma",
        formatted_address: "105 W Mercer St, Seattle, WA 98119",
        locality: "Seattle",
        postcode: "98119",
        region: "WA",
      },
      name: "Ozzie's",
      photos: [
        {
          id: "63631d144dec8379bb579a63",
          created_at: "2022-11-03T01:44:52.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/116588_fyHpqKK41M0TV4I1qNobVWStTBQ408Zjml4OmbVnwfc.jpg",
          width: 1325,
          height: 1920,
        },
        {
          id: "630a8b7d976bec1071e70066",
          created_at: "2022-08-27T21:24:13.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1901794_OClCwOUJrtXBxoHE9oBIaCbqDXu289pW6rQWIMsw8Ek.jpg",
          width: 1512,
          height: 2016,
        },
        {
          id: "62f042538ae3c20936c9b6a7",
          created_at: "2022-08-07T22:53:07.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/2528263_apte8WFBk23CDQsuCRARlhhHEhbdbjXHNXOqwXhNkTI.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "626456b4dce86767a92ac1ed",
          created_at: "2022-04-23T19:42:44.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/539638719_jv4AjuUm9tYQIEPpe7uMijplXp6ImlKH2qvfjKTE68o.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "624a26946ef48f6efaa32ac2",
          created_at: "2022-04-03T22:58:28.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/8597951_PFJ7X-Au5XvMxisVrx36359_m9ZX2t7GyrNJYv0NOd8.jpg",
          width: 1920,
          height: 1440,
        },
      ],
      rating: 6.2,
      tel: "(206) 284-4618",
      website: "http://www.ozziesinseattle.com",
    },
    {
      fsq_id: "4a8f1db7f964a520051420e3",
      categories: [
        {
          id: 13033,
          name: "Bubble Tea Shop",
          short_name: "Bubble Tea",
          plural_name: "Bubble Tea Shops",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/bubble_",
            suffix: ".png",
          },
        },
        {
          id: 13068,
          name: "American Restaurant",
          short_name: "American",
          plural_name: "American Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/default_",
            suffix: ".png",
          },
        },
        {
          id: 13072,
          name: "Asian Restaurant",
          short_name: "Asian",
          plural_name: "Asian Restaurants",
          icon: {
            prefix: "https://ss3.4sqi.net/img/categories_v2/food/asian_",
            suffix: ".png",
          },
        },
      ],
      distance: 2611,
      geocodes: {
        main: {
          latitude: 47.597733,
          longitude: -122.326761,
        },
        roof: {
          latitude: 47.597733,
          longitude: -122.326761,
        },
      },
      location: {
        address: "519 6th Ave S",
        address_extended: "Ste 120",
        census_block: "530330091002003",
        country: "US",
        cross_street: "At S Weller St",
        dma: "Seattle-Tacoma",
        formatted_address: "519 6th Ave S (At S Weller St), Seattle, WA 98104",
        locality: "Seattle",
        postcode: "98104",
        region: "WA",
      },
      name: "Oasis Tea Zone",
      photos: [
        {
          id: "659a41bad36dd12507e98e86",
          created_at: "2024-01-07T06:16:26.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1401131424_hrhIhUc_BO4R4tAVXD7PXLMyOuMFkIcuC5p0He6bum0.jpg",
          width: 1280,
          height: 1920,
        },
        {
          id: "6529c48a52220414fb95c999",
          created_at: "2023-10-13T22:28:26.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/7085_Q-Mimce9JuqySRX4XkWveL9wkAlMXrxiTtNDYemvk_E.jpg",
          width: 1080,
          height: 1440,
        },
        {
          id: "62e614e77cb02c50ce249f87",
          created_at: "2022-07-31T05:36:39.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/85244903_MgcY1xd1GiVChSUJzqdrhB6WyzGyZ2N-2ZhZaFmVSDA.jpg",
          width: 1920,
          height: 1440,
        },
        {
          id: "6284860ff4559712379ec997",
          created_at: "2022-05-18T05:37:19.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/1367915083_YZlCLFOHJnAbgvVslOnvVtUrpC_zS3060kBvJdbd6vA.jpg",
          width: 1440,
          height: 1920,
        },
        {
          id: "6275b6197b019300c0ff4dbc",
          created_at: "2022-05-06T23:58:17.000Z",
          prefix: "https://fastly.4sqi.net/img/general/",
          suffix: "/7085_RFoGQzN32Eok7smTHsRJxSonsDD-n07IXE6AfYfEylw.jpg",
          width: 1080,
          height: 1440,
        },
      ],
      rating: 8,
      tel: "(206) 447-8098",
      website: "http://www.oasisteazone.com",
    },
  ]);
  return (
    <div className="relomap-height">
      <MapPointofInterests poiData={null} markers={markers} />
    </div>
  );
}

export default BaseMap;
