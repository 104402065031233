import React, { useEffect } from "react";
import AOS from "aos";

function ForIndividuals() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);

  const demoBtn = () => {
    location.href = "/contact-us";
  };
  return (
    <div>
      <div className="bannerPart innerPage">
        <div className="container d-flex justify-content-center align-items-center text-center h-100">
          <div className="" data-aos="fade-up">
            <h1 className="mb-3">Relocation Made Simple.</h1>
            <p className="m-auto col-lg-8">
              Relocating can be overwhelming, but with ReloNavigator, you're
              never alone. Our tools provide the information and resources you
              need to make confident decisions and stay organized every step of
              the way.
            </p>
            <button
              type="button"
              className="btn mt-3  "
              onClick={() => demoBtn()}
            >
              Request a Demo
            </button>
          </div>
        </div>
      </div>

      <div className="container py-5">
        <div className="bg-black rounded-3 p-5">
          <h2 className="text-white text-center mt-4">
            The Tools You Need for a Seamless Relocation
          </h2>
          <p className="text-white text-center col-lg-10 m-auto">
            ReloNavigator offers personalized tools to help you settle into your
            new home with ease. Whether you're finding a house, managing your
            moving tasks, or exploring your new neighborhood, our platform is
            here to guide you every step of the way.
          </p>

          <div className="sr-box-tails mb-4">
            <div className="items" data-aos="fade-up">
              <img src="assets/publicImages/individuals/sr-img-1.png" alt="" />
              <div className="tailsText">
                <h3>
                  <span className="text-uppercase">Explore Your New Area</span>
                  Get to Know Your New Neighborhood
                </h3>
                <p>
                  Before making any big decisions, learn all about your new
                  area. From demographic data to points of interest, get a
                  complete picture of what your new neighborhood has to offer.
                </p>
              </div>
            </div>
            <div className="items flex-lg-row-reverse" data-aos="fade-up">
              <img src="assets/publicImages/individuals/sr-img-2.png" alt="" />
              <div className="tailsText">
                <h3>
                  <span className="text-uppercase">Find the Perfect Home</span>
                  Find and Compare Homes with Ease
                </h3>
                <p>
                  Use ReloNavigator to explore housing options, compare
                  properties, and see what's nearby—from schools to restaurants.
                  Make confident decisions with detailed property and
                  neighborhood insights.
                </p>
              </div>
            </div>
            <div className="items" data-aos="fade-up">
              <img src="assets/publicImages/individuals/sr-img-3.png" alt="" />
              <div className="tailsText">
                <h3>
                  <span className="text-uppercase">Personalize Your Move</span>
                  Tailor Your Move to Fit Your Needs
                </h3>
                <p>
                  Customize your relocation experience by setting personal
                  preferences and creating a move tailored to you. Whether it's
                  finding a school for your children, adjusting your budget or
                  finding your best fit, you're in control.
                </p>
              </div>
            </div>
            <div className="items flex-lg-row-reverse" data-aos="fade-up">
              <img src="assets/publicImages/individuals/sr-img-4.png" alt="" />
              <div className="tailsText">
                <h3>
                  <span className="text-uppercase">
                    Stay Organized and On Track
                  </span>
                  Stay Organized with Checklists and Reminders
                </h3>
                <p>
                  Manage every detail of your move with personalized checklists
                  and reminders. From utility setup to notifying your bank,
                  these tools help keep you on track and organized.
                </p>
              </div>
            </div>
            <div className="items mb-0  border-0" data-aos="fade-up">
              <img src="assets/publicImages/individuals/sr-img-5.png" alt="" />
              <div className="tailsText">
                <h3>
                  <span className="text-uppercase">
                    Access Essential Moving Resources
                  </span>
                  Access Resources to Make Your Move Easy
                </h3>
                <p>
                  ReloNavigator provides you with essential moving resources,
                  such as sample renters and pet resumes, packing guides, and
                  step-by-step advice on how to set up utilities and many more.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="text-center pb-5">
          <h2 className="col-lg-5 m-auto">
            Why Choose ReloNavigator for Your Relocation?
          </h2>
          <p className="mt-2">
            Your Trusted Partner for a Seamless Relocation Experience
          </p>

          <div className="choose-tails tials">
            <div className="box" data-aos="fade-up">
              <img
                src="assets/publicImages/individuals/choose-icon-1.png"
                alt=""
              />
              <h3>Stay Organized from Start to Finish</h3>
              <p>
                Our platform keeps you on track with personalized checklists,
                reminders, and resources. From managing appointments to staying
                on top of important tasks, you'll never miss a step.
              </p>
            </div>
            <div className="box" data-aos="fade-up">
              <img
                src="assets/publicImages/individuals/choose-icon-2.png"
                alt=""
              />
              <h3>A Better Relocation Experience</h3>
              <p>
                With real-time updates, support, and intuitive tools, we
                streamline the moving process, making it simpler and less
                stressful. Focus on what matters while we help you manage the
                rest.
              </p>
            </div>
            <div className="box" data-aos="fade-up">
              <img
                src="assets/publicImages/individuals/choose-icon-3.png"
                alt=""
              />
              <h3>Empowering Informed Decisions</h3>
              <p>
                Get access to comprehensive data on neighborhoods, schools, and
                points of interest, helping you make confident choices for
                yourself and your family.
              </p>
            </div>
            <div className="box" data-aos="fade-up">
              <img
                src="assets/publicImages/individuals/choose-icon-4.png"
                alt=""
              />
              <h3>Tailored to Your Needs</h3>
              <p>
                ReloNavigator is built with flexibility, offering personalized
                features that adapt to your specific relocation journey. Whether
                you're finding the perfect home or managing your moving tasks,
                we provide the right tools for your unique situation.
              </p>
            </div>
            <div className="box" data-aos="fade-up">
              <img
                src="assets/publicImages/individuals/choose-icon-5.png"
                alt=""
              />
              <h3>All-in-One Solution</h3>
              <p>
                Everything you need in one place—home finding, school
                exploration, area data, and checklists—so you can stay organized
                without juggling multiple platforms.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForIndividuals;
