import React from "react";
import * as $ from "jquery";

function WalkScorePopUp(props) {
  const { state, setState } = props;
  const closeScoreModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#walkscoreInfoMadal").modal("hide");
      });
    });
    // setState((prev) => ({ ...prev, scoreModal: false }));
  };
  return (
    <div className="modal-dialog " role="document">
      <div className="modal-content">
        <div className="width-80per pd-y-60">
          <div className="only_close">
            <span
              className="pointer close"
              data-dismiss="modal"
              onClick={() => closeScoreModal()}
            >
              &times;
            </span>
          </div>
          <h6>Scores</h6>
          <div className="modal-body">
            <div className="all-score-table">
              <table width="100%">
                <thead>
                  <tr>
                    <th width="30%">Walk Score</th>
                    <th width="70%">Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>90–100</b>
                    </td>
                    <td>
                      <b>Walker's Paradise</b>
                      <span>Daily errands do not require a car.</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>70–89</b>
                    </td>
                    <td>
                      <b>Very Walkable</b>
                      <span>Most errands can be accomplished on foot.</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>50–69</b>
                    </td>
                    <td>
                      <b>Somewhat Walkable</b>
                      <span>Some errands can be accomplished on foot.</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>25–49</b>
                    </td>
                    <td>
                      <b>Car-Dependent</b>
                      <span>Most errands require a car.</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>0–24</b>
                    </td>
                    <td>
                      <b>Car-Dependent</b>
                      <span>Almost all errands require a car.</span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table width="100%">
                <thead>
                  <tr>
                    <th width="30%">Bike Score</th>
                    <th width="70%">Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>90–100</b>
                    </td>
                    <td>
                      <b>Biker's Paradise</b>
                      <span>Daily errands can be accomplished on a bike.</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>70–89</b>
                    </td>
                    <td>
                      <b>Very Bikeable</b>
                      <span>Biking is convenient for most trips.</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>50–69</b>
                    </td>
                    <td>
                      <b>Bikeable</b>
                      <span>Some bike infrastructure.</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>0–49</b>
                    </td>
                    <td>
                      <b>Somewhat Bikeable</b>
                      <span>Minimal bike infrastructure.</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table width="100%">
                <thead>
                  <tr>
                    <th width="30%">Transit Score</th>
                    <th width="70%">Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>90–100</b>
                    </td>
                    <td>
                      <b>Rider's Paradise</b>
                      <span>World-class public transportation.</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>70–89</b>
                    </td>
                    <td>
                      <b>Excellent Transit</b>
                      <span>Transit is convenient for most trips.</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>50–69</b>
                    </td>
                    <td>
                      <b>Good Transit</b>
                      <span>Many nearby public transportation options.</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>25–49</b>
                    </td>
                    <td>
                      <b>Some Transit</b>
                      <span>A few nearby public transportation options.</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>0–24</b>
                    </td>
                    <td>
                      <b>Minimal Transit</b>
                      <span>It is possible to get on a bus.</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WalkScorePopUp;
