/* Importing the node modules, child components, services and controllers used 
   inside SuccessModal component */
const ReservationSuccesModal = () => {
  const closeModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#reservationsuccessmodal").modal("hide");
      });
    });
  };
  return (
    <div
      className="modal fade savesearch"
      id="reservationsuccessmodal"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content corporateHousing_Updates">
          <div className="modal-header border-0">
            <div class="only_close">
              <span
                aria-hidden="true"
                className="pointer close"
                onClick={() => closeModal()}
                data-dismiss="modal"
              >
                ×
              </span>
            </div>
          </div>
          <div className="modal-body">
            <div className="widthModal">
              <div className="p-request-form" id="">
                <form className="form-horizontal">
                  <div className="">
                    <p className="text-left">
                      {" "}
                      Thank you for your request. We have received your data
                      removal request and will process it promptly.
                    </p>
                    <span className="d-block mb-2">
                      {" "}
                      Please note that it may take up to 30 days for us to
                      complete the removal of your data from our systems. If we
                      require any further information, we will reach out to you
                      via the contact details you have provided.
                    </span>{" "}
                    Thank you.
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* <div className="modal-footer"></div> */}
        </div>
      </div>
    </div>
  );
};
export default ReservationSuccesModal;
