import React from "react";

const Tab = ({ tabName, activeTab, tabId }) => {
  return (
    <li className="nav-item">
      <a
        className={activeTab == tabName ? "nav-link active" : "nav-link"}
        data-toggle="tab"
        id={tabId}
      >
        {tabName}
      </a>
    </li>
  );
};

export default Tab;
