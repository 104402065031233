import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import ThirdPartyApiService from "../../../../../services/thirdPartyService";

function PublicContactUs() {
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNo: "",
    message: "",
    aggered: false,
  });
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);

  const inputHandler = (value, type) => {
    // console.log(value, type, "value,type");

    if (type === "emailId") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(value);
      if (!isValidEmail) {
        $(`#${type}`).addClass("is-invalid");
      } else {
        $(`#${type}`).removeClass("is-invalid");
      }
    }
    // else if (type === "phoneNo") {
    //   const phoneRegex = /^\d{0,10}$/;
    //   const isValidPhone = phoneRegex.test(value);
    //   if (!isValidPhone) {
    //     $(`#${type}`).addClass("is-invalid");
    //   } else {
    //     $(`#${type}`).removeClass("is-invalid");
    //   }
    // }

    setState((prev) => ({ ...prev, [type]: value }));
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    const regex = /[0-9.]/; // Allow numeric digits and a period (dot)

    if (e.keyCode === 8 || regex.test(key)) {
      return;
    }
    e.preventDefault();
  };

  const submitBtn = async () => {
    let isValid = true;

    // Reset previous validation states
    Object.keys(state).forEach((key) => {
      $(`#${key}`).removeClass("is-invalid");
    });

    // Validate each field
    if (state.firstName === "") {
      $(`#firstName`).addClass("is-invalid");
      isValid = false;
    }
    if (state.lastName === "") {
      $(`#lastName`).addClass("is-invalid");
      isValid = false;
    }
    if (state.emailId === "") {
      $(`#emailId`).addClass("is-invalid");
      isValid = false;
    }
    // if (state.phoneNo === "") {
    //   $(`#phoneNo`).addClass("is-invalid");
    //   isValid = false;
    // }
    // if (state.message === "") {
    //   $(`#message`).addClass("is-invalid");
    //   isValid = false;
    // }
    if (!state.aggered) {
      $(`#aggered`).addClass("is-invalid");
      isValid = false;
    }

    if (isValid) {
      // console.log(state, "state");
      let data = {
        firstName: state.firstName,
        lastName: state.lastName,
        emailId: state.emailId,
        phoneNo: state.phoneNo,
        message: state.message,
        publicContact: true,
        subject: "Contact Details",
      };
      // Here, you can handle form submission (e.g., send to API)
      let mailSendTo = await ThirdPartyApiService.sendEmail(data);
      // console.log(mailSendTo, "mailSendTo");
      if (mailSendTo.response) {
        setState((prev) => ({
          ...prev,
          firstName: "",
          lastName: "",
          emailId: "",
          phoneNo: "",
          message: "",
          aggered: false,
        }));
      }
    }
    //  else {
    //   console.log("Please fill in all required fields correctly.");
    // }
  };

  return (
    <div className="bannerPart contactUs">
      <div className="container">
        <div className="row">
          <div className="col-lg-6" data-aos="fade-up">
            <h2>We're Just a Message Away!</h2>
            {/* <p className="mb-4">
              Lorem ipsum dolor sit amet consectetur. Elementum ac viverra sed
              netus elementum. Sed velit tellus integer lectus molestie eget.
            </p>
            <ul>
              <li>
                <span>Address</span> 4517 Washington Ave. Manchester, Kentucky
                39495
              </li>
              <li>
                <span>Chat Support</span> admin@relonavigator.com
              </li>
              <li>
                <span>Call Support</span> (406) 555-0120
              </li>
              <li>
                <span>Social Media</span>

                <ul>
                  <li>
                    <a href="#">
                      <img
                        src="assets/publicImages/contactus/linkedin.png"
                        alt=""
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src="assets/publicImages/contactus/facebook.png"
                        alt=""
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src="assets/publicImages/contactus/instagram.png"
                        alt=""
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src="assets/publicImages/contactus/youtube.png"
                        alt=""
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src="assets/publicImages/contactus/twitter.png"
                        alt=""
                      />
                    </a>
                  </li>
                </ul>
              </li>
            </ul> */}
          </div>
          <div className="col-lg-6" data-aos="fade-up">
            <div className="form">
              <div className="row mb-4">
                <div className="col">
                  <label htmlFor="firstName">First Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter First Name"
                    value={state.firstName}
                    id="firstName"
                    onChange={(e) => inputHandler(e.target.value, "firstName")}
                  />
                </div>
                <div className="col">
                  <label htmlFor="lastName">Last Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Last Name"
                    value={state.lastName}
                    id="lastName"
                    onChange={(e) => inputHandler(e.target.value, "lastName")}
                  />
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="emailId">Email *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Email"
                  value={state.emailId}
                  id="emailId"
                  onChange={(e) => inputHandler(e.target.value, "emailId")}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="phoneNo">Phone</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Phone Number"
                  value={state.phoneNo}
                  onKeyDown={handleKeyPress}
                  id="phoneNo"
                  maxLength={10}
                  onChange={(e) => inputHandler(e.target.value, "phoneNo")}
                />
              </div>

              <div className="mb-4">
                <label htmlFor="message">Message</label>
                <textarea
                  className="form-control"
                  rows="5"
                  id="message"
                  value={state.message}
                  onChange={(e) => inputHandler(e.target.value, "message")}
                ></textarea>
              </div>

              <div className="form-check mb-4">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={state.aggered}
                    id="aggered"
                    onChange={(e) => inputHandler(e.target.checked, "aggered")}
                  />
                  I'd like to receive more information about company. I
                  understand and agree to the <a href="#">Privacy Policy</a>
                </label>
              </div>

              <button
                type="submit"
                className="btn w-100 fs-5 py-2"
                onClick={() => submitBtn()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublicContactUs;
