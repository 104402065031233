import React from "react";
import ConsultantSaProperties from "../components/ConsultantSaProperties";

const Properties = ({ userUniqueId, isauthgenerated, subscriptioncode }) => {
  function isPropertiesTabVisible() {
    return userUniqueId && subscriptioncode !== "ReloNavigator";
  }
  return (
    <>
      <div id="tabProperties" className=" tab-pane fade mt-4 show active ">
        {isPropertiesTabVisible() ? (
          <ConsultantSaProperties transfereeuniqueId={userUniqueId} />
        ) : null}
      </div>
    </>
  );
};

export default Properties;
