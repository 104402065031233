import React, { useState, useEffect } from "react";
// import "antd/dist/antd.css";
import { Spin, Select } from "antd";
import ApiServices from "../../../../services/apiServices";
import ConsultantHeader from "../header and footer/Header";
import ConsultantFooter from "../header and footer/Footer";
import Footer from "../../../common/footer/Footer";
import * as $ from "jquery";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import DatePicker from "react-datepicker";
import "./moredetails.css";
import LoginStore from "../../../../services/thirdPartyService";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import Tabs from "./Tabs";
import Tab from "./components/Tab";
import TabMenu from "./components/TabMenu";
import CompanyHours from "./components/CompanyHoursCard";
import IndividualHours from "./components/IndividualHoursCard";
import DetailsCard from "./components/DetailsCard";
const moredetails = () => {
  const location = useLocation();

  const storedUserDetails = localStorage.getItem("moredetails");
  const userDetails = JSON.parse(storedUserDetails);
  const user =
    location.state && location.state.row ? location.state.row : userDetails;
  const {
    userFirstName,
    userLastName,
    PhoneNumber,
    countrycode,
    EmailId,
    subscriptioncode,
    accessfromdate,
    accesstodate,
    po,
    referenceid,
    usernotes,
    individualvirtualhours,
    companyvirtualhours,
    userUniqueId,
    clientuniqueid,
    transfereehours,
    istemporary,
  } = user;

  // console.log(location.state.row, "location of the state");

  const intialState = {
    getSessionData: "",
    editid: false,
    errorMessage: "",
    existsmsg: "",
    ShowSuccess: "",
    showing: "",
    uploadLoader: false,
    activity: "",
    selectdate: null,
    fromavailableTillDate: null,
    toavailableTillDate: null,
    contactType: "",
    fromtimeselect: "",
    totimeselect: "",
    activityusernotes: "",
    allActivitieslist: [],
    activityerrorMessage: false,
    activityShowSuccess: false,
    transfereeactivityUId: "",
    editActivityid: false,
    Activityshowingerror: "",
    Activityshowing: "",

    activityuploadLoader: false,
    shortname: "",
    dataSource: [],

    moreDetails: user,
    allstatuslist: [],
    TransfereeStatus: [],
    getSessionData: {},
    companydata: [],
    Loader: false,

    firstname: userFirstName,
    middlename: "",
    lastname: userLastName,
    email: EmailId,
    phoneNumber: countrycode + "" + PhoneNumber,
    countrycode: "",
    clientuniqueid: clientuniqueid,
    compyname: "",
    approvedhours: transfereehours,
    istemporary: istemporary,
    companyinpersonhours: "",
    companyvirtualhours: companyvirtualhours,
    individualinpersonhours: "",
    individualvirtualhours: individualvirtualhours,
    servicelevel: subscriptioncode,
    ShowSuccess: "",
    usernotes: usernotes,
    accessfromdate: accessfromdate,
    accesstodate: accesstodate,
    po: po,
    referenceid: referenceid,
    startDate: null,
    endDate: null,
    existsmsg: "",
    editaccount: true,
    middleloader: false,

    notesuccess: "",
    transfereeuid: "",
    notess: "",
    notesslist: [],
    billableevent: false,
    timeLabels: [],
    activeTabs: "Account",
  };
  const [state, setState] = useState(intialState);

  async function getmoredata(value) {
    updateState({ Loader: true, middleloader: true });
    let getSessionData = await LoginStore.getLoginInfo({});
    updateState({ getSessionData: getSessionData });
    let getTransfereeObj = {
      consultantuniqueid: getSessionData.userUniqueId,
    };

    let TransfereesList_Result = await ApiServices.GetConsultantTransferees(
      getTransfereeObj
    );

    let dataSource = [];

    if (TransfereesList_Result.error == 0) {
      let transferees = TransfereesList_Result.message.transfereeslist;
      transferees.forEach(async (transferee, index) => {
        if (transferee.userFirstName != "") {
          transferee.key = transferee.userId;
          transferee.name =
            transferee.userFirstName + " " + transferee.userLastName;
          transferee.transfereescheduledhours =
            transferee.transfereescheduledhours + " Hrs";
          transferee.transfereehours = transferee.transfereehours + " Hrs";
          dataSource.push(transferee);
        }
      });

      const uniqueArray = dataSource.filter((thing, index) => {
        const _thing = JSON.stringify(thing);
        return (
          index ===
          dataSource.findIndex((obj) => {
            return JSON.stringify(obj) === _thing;
          })
        );
      });

      let periodsInADay = moment.duration(8, "hours").as("minutes");
      let timeLabels = [];
      let startTimeMoment = moment("0:00", "h:mm");
      for (let i = 0; i <= periodsInADay; i += 5) {
        startTimeMoment.add(i === 0 ? 0 : 15, "minutes");
        timeLabels.push(startTimeMoment.format("hh:mm A"));
      }
      let uniquetimeLabels = [...new Set(timeLabels)];
      updateState({
        dataSource: _.orderBy(uniqueArray, ["userId"], ["asc"]),
        timeLabels: uniquetimeLabels,
      });
    }
    // --------------------------------------------------------
    // var shortname = moredetails.name.match(/\b(\w)/g).join("");
    // updateState({
    //   shortname: shortname,
    // });
    // onClickEditProperty(state.moredetails);
    const GetStatusList = await ApiServices.GetStatusList();
    if (GetStatusList.error == 0) {
      _.forEach(GetStatusList.message.noteslist, function (valueObject) {
        if (valueObject.identifier == "Registration email sent")
          valueObject.orderId = 1;
        if (valueObject.identifier == "Transferee Registered successfully")
          valueObject.orderId = 2;
        if (valueObject.identifier == "Transferee logged in for the first time")
          valueObject.orderId = 3;
        if (valueObject.identifier == "Updated Home Preferences")
          valueObject.orderId = 4;
        if (valueObject.identifier == "Utilized digital checklists")
          valueObject.orderId = 5;
        if (valueObject.identifier == "Scheduled appointments with consultant")
          valueObject.orderId = 6;
      });
      let SortStatus = _.sortBy(GetStatusList.message.noteslist, [
        function (o) {
          return parseInt(o.orderId, 10);
        },
      ]);
      updateState({ middleloader: false, allstatuslist: SortStatus });
    }
    // console.log(state.moreDetails, "moreDetails");
    let data = {
      transfereeuid: state.moreDetails.userUniqueId,
    };
    const GetTransfereeStatus = await ApiServices.GetTransfereeStatus(data);
    if (GetTransfereeStatus.error == 0) {
      updateState({
        TransfereeStatus: GetTransfereeStatus.message.noteslist,
      });
    }

    let getTransfereeObjs = {
      supplieruid: getSessionData.supplieruid,
    };
    let ClientProfile_Result = await ApiServices.GetAllClientProfile(
      getTransfereeObjs
    );
    let mainn = ClientProfile_Result.message.clientProfileList;
    if (mainn != undefined) {
      var newArray = mainn.sort(function (first, second) {
        var a = first.companyname.toLowerCase();
        var b = second.companyname.toLowerCase();
        if (a > b) {
          return 1;
        } else if (a < b) {
          return -1;
        } else {
          return 0;
        }
      });

      let companydataname =
        state.moreDetails.clientuniqueid != undefined
          ? newArray.find(
              (o) => o.clientuniqueId == state.moreDetails.clientuniqueid
            )
          : { companyname: "" };
      updateState({
        Loader: false,
        compyname: companydataname.companyname,
        companydata: newArray,
      });
    } else {
      updateState({ Loader: false, compyname: "", companydata: [] });
    }
  }

  function middleloader() {
    if (location.state != null) {
      updateState({ middleloader: false });
    } else {
      updateState({ middleloader: true });
    }
  }

  const getactivitys = async () => {
    updateState({ Loader: true });
    let data = {
      transfereeuid: state.moreDetails.userUniqueId,
    };
    const GetTransfereeActivities = await ApiServices.GetTransfereeActivities(
      data
    );
    if (GetTransfereeActivities.error == 0) {
      updateState({
        allActivitieslist: GetTransfereeActivities.statuslist,
        Loader: false,
      });
    } else {
      updateState({
        allActivitieslist: [],
        Loader: false,
      });
    }
  };

  const handleMail = async (record) => {
    confirmAlert({
      title: "",
      message: "Are you sure do you want to resend welcome email?",
      buttons: [
        {
          label: "Yes",
          onClick: () => welMail(record),
        },
        {
          label: "No",
        },
      ],
    });
  };

  async function welMail(record) {
    updateState({ Loader: true });
    let onedata =
      record.individualvirtualhours == null
        ? 0
        : parseInt(record.individualvirtualhours);
    let dataone =
      record.companyvirtualhours == null
        ? 0
        : parseInt(record.companyvirtualhours);
    let sumoff = onedata + dataone;
    let data = {
      transfereeUId: record.userUniqueId,
      firstname: record.userFirstName,
      lastname: record.userLastName,
      middlename: record.userMiddleName,
      password: "",
      age: record.age,
      dob: record.dob,
      gender: record.gender,
      emailid: record.EmailId,
      countrycode: record.countrycode,
      phonenumber: record.PhoneNumber,
      worklocationaddress1: record.worklocationaddress1,
      worklocationaddress2: record.worklocationaddress2,
      city: record.city,
      state: record.state,
      zipcode: record.zipcode,
      profilepic: record.profilepic,
      clientuniqueid: record.clientuniqueid,
      referenceid: record.referenceid,
      supplieruid: state.getSessionData.supplieruid,
      po: record.po,
      sendemail: true,
      approvedhours:
        record.subscriptioncode == "ReloNavigator"
          ? 0
          : record.subscriptioncode == "Explorer"
          ? sumoff
          : parseInt(record.transfereehours, 10),
      companyinpersonhours:
        record.subscriptioncode == "ReloNavigator"
          ? 0
          : record.subscriptioncode == "Explorer"
          ? 0
          : record.companyinpersonhours == null
          ? 0
          : record.companyinpersonhours,
      companyvirtualhours:
        record.subscriptioncode == "ReloNavigator"
          ? 0
          : record.companyvirtualhours == null
          ? 0
          : record.companyvirtualhours,
      individualinpersonhours:
        record.subscriptioncode == "ReloNavigator"
          ? 0
          : record.subscriptioncode == "Explorer"
          ? 0
          : record.individualinpersonhours == null
          ? 0
          : record.individualinpersonhours,
      individualvirtualhours:
        record.subscriptioncode == "ReloNavigator"
          ? 0
          : record.individualvirtualhours == null
          ? 0
          : record.individualvirtualhours,
      usernotes: record.usernotes == null ? "" : record.usernotes,
      accessfromdate:
        record.accessfromdate != null
          ? moment(record.accessfromdate).format("MM/DD/YYYY")
          : null,
      accesstodate:
        record.accesstodate != null
          ? moment(record.accesstodate).format("MM/DD/YYYY")
          : null,
    };

    let data2 = {
      transfereeuid: state.moreDetails.userUniqueId,
    };

    const GetTransfereeStatus = await ApiServices.GetTransfereeStatus(data2);

    let ress = [];

    if (GetTransfereeStatus.error == 0) {
      ress = GetTransfereeStatus.message.noteslist.filter((o) => {
        return o.statusuid == "deb8d951-c80b-43df-b711-dad2c49baa03";
      });

      let StatusUpdateSendWelcome = {
        uniqueid:
          ress.length == 0 ? "" : "bd3ac619-7173-4a1c-b72c-ef0d9c2f8393",
        transfereeuid: state.moreDetails.userUniqueId,
        statusuid: "bd3ac619-7173-4a1c-b72c-ef0d9c2f8393",
        isactive: true,
      };
      const ManageTransfereeStatus = await ApiServices.ManageTransfereeStatus(
        StatusUpdateSendWelcome
      );
      const CreateTransferee = await ApiServices.CreateTransferee(data);
      console.log("CreateTransferee", CreateTransferee);
      if (CreateTransferee.error == 0) {
        updateState({ Loader: false });
        confirmAlert({
          title: "",
          message: "Welcome email has been sent successfully.",
          buttons: [
            {
              label: "Close",
            },
          ],
        });
      }
    }
  }

  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  useEffect(() => {
    console.log(userUniqueId, "====");
    getmoredata(userUniqueId);
    getactivitys();
    middleloader();
  }, []);

  useEffect(() => {
    if (state.dataSource.length > 0) {
      let moreDetails = state.dataSource.find(
        (o) => o.userUniqueId == userUniqueId
      );
      updateState({ moreDetails });
    }
  }, [state.dataSource]);

  return (
    <div>
      {/* <ConsultantHeader /> */}
      <div>
        {state.Loader == true ? <div className="loading">Loading</div> : null}
        <div className="container" id="upstart">
          <div className="consultant-tails-tab">
            <div className="profile-details">
              <div
                className={
                  state.moreDetails.subscriptioncode == "Commander"
                    ? "pd-top-view pd-top-view-2"
                    : "pd-top-view"
                }
              >
                <DetailsCard
                  moredetails={state.moreDetails}
                  companyName={state.compyname}
                />
                <CompanyHours moredetails={state.moreDetails} />
                <IndividualHours moredetails={state.moreDetails} />
              </div>

              <div className="pd-tabs-view">
                <TabMenu
                  updateState={updateState}
                  activeTab={state.activeTabs}
                  moredetails={state.moreDetails}
                />
                <Tabs
                  // getactivitys={getactivitys}
                  moredetails={state.moreDetails}
                  // data={location.state.row}
                  data={user}
                  tabss={state.activeTabs}
                  companyname={state.compyname}
                  timeLabels={state.timeLabels}
                  handleMail={handleMail}
                  getmoredata={getmoredata}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default moredetails;

// import React, { useState, useEffect } from "react";
// // import "antd/dist/antd.css";
// import { Spin, Select } from "antd";
// import ApiServices from "../../../../services/apiServices";
// import ConsultantHeader from "../header and footer/Header";
// import ConsultantFooter from "../header and footer/Footer";
// import * as $ from "jquery";
// import { confirmAlert } from "react-confirm-alert";
// import moment from "moment";
// import DatePicker from "react-datepicker";
// import "./moredetails.css";
// import LoginStore from "../../../../services/thirdPartyService";
// import _ from "lodash";
// import { useLocation } from "react-router-dom";
// import Tabs from "./Tabs";
// import Tab from "./components/Tab";
// import TabMenu from "./components/TabMenu";
// import CompanyHours from "./components/CompanyHoursCard";
// import IndividualHours from "./components/IndividualHoursCard";
// import DetailsCard from "./components/DetailsCard";
// const moredetails = () => {
//   const location = useLocation();

//   const {
//     userFirstName,
//     userLastName,
//     PhoneNumber,
//     countrycode,
//     EmailId,
//     subscriptioncode,
//     accessfromdate,
//     accesstodate,
//     po,
//     referenceid,
//     usernotes,
//     individualvirtualhours,
//     companyvirtualhours,
//     userUniqueId,
//   } = location.state.row;

//   // console.log(location.state.row);

//   const intialState = {
//     editid: false,
//     errorMessage: "",
//     existsmsg: "",
//     ShowSuccess: "",
//     showing: "",
//     uploadLoader: false,
//     activity: "",
//     selectdate: null,
//     fromavailableTillDate: null,
//     toavailableTillDate: null,
//     contactType: "",
//     fromtimeselect: "",
//     totimeselect: "",
//     activityusernotes: "",
//     allActivitieslist: [],
//     activityerrorMessage: false,
//     activityShowSuccess: false,
//     transfereeactivityUId: "",
//     editActivityid: false,
//     Activityshowingerror: "",
//     Activityshowing: "",

//     activityuploadLoader: false,
//     shortname: "",
//     dataSource: [],

//     moredetails: location.state.row,
//     allstatuslist: [],
//     TransfereeStatus: [],
//     getSessionData: {},
//     companydata: [],
//     Loader: false,

//     firstname: userFirstName,
//     middlename: "",
//     lastname: userLastName,
//     email: EmailId,
//     phoneNumber: countrycode + "" + PhoneNumber,
//     countrycode: "",
//     clientuniqueid: "",
//     compyname: "",
//     approvedhours: "",
//     companyinpersonhours: "",
//     companyvirtualhours: companyvirtualhours,
//     individualinpersonhours: "",
//     individualvirtualhours: individualvirtualhours,
//     servicelevel: subscriptioncode,
//     ShowSuccess: "",
//     usernotes: usernotes,
//     accessfromdate: accessfromdate,
//     accesstodate: accesstodate,
//     po: po,
//     referenceid: referenceid,
//     startDate: null,
//     endDate: null,
//     existsmsg: "",
//     editaccount: true,
//     middleloader: false,

//     notesuccess: "",
//     transfereeuid: "",
//     notess: "",
//     notesslist: [],
//     billableevent: false,
//     timeLabels: [],
//     activeTabs: "Account",
//   };
//   const [state, setState] = useState(intialState);

//   async function getmoredata(value) {
//     console.log(value);
//     updateState({ Loader: true, middleloader: true });
//     let getSessionData = await LoginStore.getLoginInfo({});
//     let getTransfereeObj = {
//       consultantuniqueid: getSessionData.userUniqueId,
//     };
//     let TransfereesList_Result = await ApiServices.GetConsultantTransferees(
//       getTransfereeObj
//     );
//     let dataSource = [];

//     if (TransfereesList_Result.error == 0) {
//       let transferees = TransfereesList_Result.message.transfereeslist;
//       transferees.forEach(async (transferee, index) => {
//         if (transferee.userFirstName != "") {
//           transferee.key = transferee.userId;
//           transferee.name =
//             transferee.userFirstName + " " + transferee.userLastName;
//           transferee.transfereescheduledhours =
//             transferee.transfereescheduledhours + " Hrs";
//           transferee.transfereehours = transferee.transfereehours + " Hrs";
//           dataSource.push(transferee);
//         }
//       });

//       const uniqueArray = dataSource.filter((thing, index) => {
//         const _thing = JSON.stringify(thing);
//         return (
//           index ===
//           dataSource.findIndex((obj) => {
//             return JSON.stringify(obj) === _thing;
//           })
//         );
//       });

//       let periodsInADay = moment.duration(8, "hours").as("minutes");
//       let timeLabels = [];
//       let startTimeMoment = moment("0:00", "h:mm");
//       for (let i = 0; i <= periodsInADay; i += 5) {
//         startTimeMoment.add(i === 0 ? 0 : 15, "minutes");
//         timeLabels.push(startTimeMoment.format("hh:mm A"));
//       }
//       let uniquetimeLabels = [...new Set(timeLabels)];
//       updateState({
//         dataSource: _.orderBy(uniqueArray, ["userId"], ["asc"]),
//         timeLabels: uniquetimeLabels,
//       });
//     }
//     // --------------------------------------------------------
//     // var shortname = moredetails.name.match(/\b(\w)/g).join("");
//     // updateState({
//     //   shortname: shortname,
//     // });
//     // onClickEditProperty(state.moredetails);
//     const GetStatusList = await ApiServices.GetStatusList();
//     if (GetStatusList.error == 0) {
//       _.forEach(GetStatusList.message.noteslist, function (valueObject) {
//         if (valueObject.identifier == "Registration email sent")
//           valueObject.orderId = 1;
//         if (valueObject.identifier == "Transferee Registered successfully")
//           valueObject.orderId = 2;
//         if (valueObject.identifier == "Transferee logged in for the first time")
//           valueObject.orderId = 3;
//         if (valueObject.identifier == "Updated Home Preferences")
//           valueObject.orderId = 4;
//         if (valueObject.identifier == "Utilized digital checklists")
//           valueObject.orderId = 5;
//         if (valueObject.identifier == "Scheduled appointments with consultant")
//           valueObject.orderId = 6;
//       });
//       let SortStatus = _.sortBy(GetStatusList.message.noteslist, [
//         function (o) {
//           return parseInt(o.orderId, 10);
//         },
//       ]);
//       updateState({ middleloader: false, allstatuslist: SortStatus });
//     }
//     let data = {
//       transfereeuid: moredetails.userUniqueId,
//     };
//     const GetTransfereeStatus = await ApiServices.GetTransfereeStatus(data);
//     if (GetTransfereeStatus.error == 0) {
//       updateState({
//         TransfereeStatus: GetTransfereeStatus.message.noteslist,
//       });
//     }

//     let getTransfereeObjs = {
//       supplieruid: getSessionData.supplieruid,
//     };
//     let ClientProfile_Result = await ApiServices.GetAllClientProfile(
//       getTransfereeObjs
//     );
//     let mainn = ClientProfile_Result.message.clientProfileList;
//     if (mainn != undefined) {
//       var newArray = mainn.sort(function (first, second) {
//         var a = first.companyname.toLowerCase();
//         var b = second.companyname.toLowerCase();
//         if (a > b) {
//           return 1;
//         } else if (a < b) {
//           return -1;
//         } else {
//           return 0;
//         }
//       });

//       let companydataname =
//         moredetails.clientuniqueid != undefined
//           ? newArray.find((o) => o.clientuniqueId == moredetails.clientuniqueid)
//           : { companyname: "" };
//       updateState({
//         Loader: false,
//         compyname: companydataname.companyname,
//         companydata: newArray,
//       });
//     } else {
//       updateState({ Loader: false, compyname: "", companydata: [] });
//     }
//   }

//   function middleloader() {
//     if (location.state != null) {
//       updateState({ middleloader: false });
//     } else {
//       updateState({ middleloader: true });
//     }
//   }

//   const getactivitys = async () => {
//     updateState({ Loader: true });
//     let data = {
//       transfereeuid: state.moredetails.userUniqueId,
//     };
//     const GetTransfereeActivities = await ApiServices.GetTransfereeActivities(
//       data
//     );
//     if (GetTransfereeActivities.error == 0) {
//       updateState({
//         allActivitieslist: GetTransfereeActivities.statuslist,
//         Loader: false,
//       });
//     } else {
//       updateState({
//         allActivitieslist: [],
//         Loader: false,
//       });
//     }
//   };

//   const handleMail = async (record) => {
//     confirmAlert({
//       title: "",
//       message: "Are you sure do you want to resend welcome email?",
//       buttons: [
//         {
//           label: "Yes",
//           onClick: () => welMail(record),
//         },
//         {
//           label: "No",
//         },
//       ],
//     });
//   };

//   async function welMail(record) {
//     updateState({ ...prev, Loader: true });
//     let onedata =
//       record.individualvirtualhours == null
//         ? 0
//         : parseInt(record.individualvirtualhours);
//     let dataone =
//       record.companyvirtualhours == null
//         ? 0
//         : parseInt(record.companyvirtualhours);
//     let sumoff = onedata + dataone;
//     let data = {
//       transfereeUId: record.userUniqueId,
//       firstname: record.userFirstName,
//       lastname: record.userLastName,
//       middlename: record.userMiddleName,
//       password: "",
//       age: record.age,
//       dob: record.dob,
//       gender: record.gender,
//       emailid: record.EmailId,
//       countrycode: record.countrycode,
//       phonenumber: record.PhoneNumber,
//       worklocationaddress1: record.worklocationaddress1,
//       worklocationaddress2: record.worklocationaddress2,
//       city: record.city,
//       state: record.state,
//       zipcode: record.zipcode,
//       profilepic: record.profilepic,
//       clientuniqueid: record.clientuniqueid,
//       referenceid: record.referenceid,
//       supplieruid: state.getSessionData.supplieruid,
//       po: record.po,
//       sendemail: true,
//       approvedhours:
//         record.subscriptioncode == "ReloNavigator"
//           ? 0
//           : record.subscriptioncode == "Explorer"
//           ? sumoff
//           : parseInt(record.transfereehours, 10),
//       companyinpersonhours:
//         record.subscriptioncode == "ReloNavigator"
//           ? 0
//           : record.subscriptioncode == "Explorer"
//           ? 0
//           : record.companyinpersonhours == null
//           ? 0
//           : record.companyinpersonhours,
//       companyvirtualhours:
//         record.subscriptioncode == "ReloNavigator"
//           ? 0
//           : record.companyvirtualhours == null
//           ? 0
//           : record.companyvirtualhours,
//       individualinpersonhours:
//         record.subscriptioncode == "ReloNavigator"
//           ? 0
//           : record.subscriptioncode == "Explorer"
//           ? 0
//           : record.individualinpersonhours == null
//           ? 0
//           : record.individualinpersonhours,
//       individualvirtualhours:
//         record.subscriptioncode == "ReloNavigator"
//           ? 0
//           : record.individualvirtualhours == null
//           ? 0
//           : record.individualvirtualhours,
//       usernotes: record.usernotes == null ? "" : record.usernotes,
//       accessfromdate:
//         record.accessfromdate != null
//           ? moment(record.accessfromdate).format("MM/DD/YYYY")
//           : null,
//       accesstodate:
//         record.accesstodate != null
//           ? moment(record.accesstodate).format("MM/DD/YYYY")
//           : null,
//     };

//     let data2 = {
//       transfereeuid: state.moredetails.userUniqueId,
//     };

//     const GetTransfereeStatus = await ApiServices.GetTransfereeStatus(data2);

//     let ress = [];

//     if (GetTransfereeStatus.error == 0) {
//       ress = GetTransfereeStatus.message.noteslist.filter((o) => {
//         return o.statusuid == "deb8d951-c80b-43df-b711-dad2c49baa03";
//       });

//       let StatusUpdateSendWelcome = {
//         uniqueid:
//           ress.length == 0 ? "" : "bd3ac619-7173-4a1c-b72c-ef0d9c2f8393",
//         transfereeuid: state.moredetails.userUniqueId,
//         statusuid: "bd3ac619-7173-4a1c-b72c-ef0d9c2f8393",
//         isactive: true,
//       };
//       const ManageTransfereeStatus = await ApiServices.ManageTransfereeStatus(
//         StatusUpdateSendWelcome
//       );
//       const CreateTransferee = await ApiServices.CreateTransferee(data);

//       if (CreateTransferee.error == 0) {
//         updateState({ Loader: false });
//         confirmAlert({
//           title: "",
//           message: "Welcome email has been sent successfully.",
//           buttons: [
//             {
//               label: "Close",
//             },
//           ],
//         });
//       }
//     }
//   }

//   function updateState(updateObj) {
//     setState((prevState) => ({ ...prevState, ...updateObj }));
//   }

//   useEffect(() => {
//     getmoredata(userUniqueId);
//     getactivitys();
//     middleloader();
//   }, []);

//   useEffect(() => {
//     if (state.dataSource.length > 0) {
//       let moredetails = state.dataSource.find(
//         (o) => o.userUniqueId == userUniqueId
//       );
//       updateState({ moredetails });
//     }
//   }, [state.dataSource]);

//   return (
//     <div>
//       {/* <ConsultantHeader /> */}
//       <div>
//         {state.Loader == true ? <div className="loading">Loading</div> : null}
//         <div className="container" id="upstart">
//           <div className="consultant-tails-tab">
//             <div className="profile-details">
//               <div
//                 className={
//                   state.moredetails.subscriptioncode == "Commander"
//                     ? "pd-top-view pd-top-view-2"
//                     : "pd-top-view"
//                 }
//               >
//                 <DetailsCard
//                   moredetails={state.moredetails}
//                   companyName={state.compyname}
//                 />
//                 <CompanyHours moredetails={state.moredetails} />
//                 <IndividualHours moredetails={state.moredetails} />
//               </div>

//               <div className="pd-tabs-view">
//                 <TabMenu
//                   updateState={updateState}
//                   activeTab={state.activeTabs}
//                   moredetails={state.moredetails}
//                 />
//                 <Tabs
//                   // getactivitys={getactivitys}
//                   moredetails={state.moredetails}
//                   data={location.state.row}
//                   tabss={state.activeTabs}
//                   timeLabels={state.timeLabels}
//                   handleMail={handleMail}
//                   getmoredata={getmoredata}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <ConsultantFooter />
//     </div>
//   );
// };

// export default moredetails;
