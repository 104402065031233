import React from "react";
import { useState, useEffect } from "react";
import moment from "moment";
import { Modal, Spin, Select } from "antd";
import * as $ from "jquery";
import _ from "lodash";
import ApiServices from "../../../../services/apiServices";
import Activity from "../../Consultant/Transeferee/Modals/Activity";
import Note from "../../Consultant/Transeferee/Modals/Note";

import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import EditTransferee from "../../Consultant/Transeferee/Modals/EditTransferee";
import Account from "./tabs/Account";
import Preferences from "./tabs/Preferences";
import Properties from "./tabs/Properties";
import Status from "./tabs/Status";
import Itinerary from "./tabs/Itinerary";

const Tabs = (props) => {
  // console.log(props, "props of the tabs");
  const location = useLocation();
  const storedUserDetails = localStorage.getItem("moredetails");
  const userDetails = JSON.parse(storedUserDetails);
  const user =
    location.state && location.state.row ? location.state.row : userDetails;
  const {
    tabss,
    timeLabels,
    handleMail,
    getmoredata,
    companyname,
    moredetails,
  } = props;
  const {
    userFirstName,
    userLastName,
    PhoneNumber,
    countrycode,
    EmailId,
    subscriptioncode,
    accessfromdate,
    accesstodate,
    po,
    referenceid,
    usernotes,
    individualvirtualhours,
    companyinpersonhours,
    individualinpersonhours,
    companyvirtualhours,
    clientuniqueid,
    transfereehours,
    istemporary,
  } = props.data;
  const intialState = {
    handlenote: false,
    activeModal: false,
    editid: false,
    errorMessage: "",
    existsmsg: "",
    ShowSuccess: "",
    showing: "",
    uploadLoader: false,
    activity: "",
    selectdate: null,
    fromavailableTillDate: null,
    toavailableTillDate: null,
    contactType: "",
    fromtimeselect: "",
    totimeselect: "",
    activityusernotes: "",
    allActivitieslist: [],
    activityerrorMessage: false,
    activityShowSuccess: false,
    transfereeactivityUId: "",
    editActivityid: false,
    Activityshowingerror: "",
    Activityshowing: "",

    activityuploadLoader: false,
    shortname: "",
    dataSource: [],

    moredetails: props.data,
    allstatuslist: [],
    TransfereeStatus: [],
    getSessionData: {},
    companydata: [],
    Loader: false,

    firstname: userFirstName,
    middlename: "",
    lastname: userLastName,
    email: EmailId,
    phoneNumber: countrycode + "" + PhoneNumber,
    countrycode: "",
    clientuniqueid: "",
    companyname: "",
    approvedhours: transfereehours,
    istemporary: istemporary,
    companyinpersonhours: companyinpersonhours,
    companyvirtualhours: companyvirtualhours,
    individualinpersonhours: individualinpersonhours,
    individualvirtualhours: individualvirtualhours,
    clientuniqueid: clientuniqueid,
    servicelevel: subscriptioncode,
    ShowSuccess: "",
    usernotes: usernotes,
    accessfromdate: accessfromdate,
    accesstodate: accesstodate,
    po: po,
    referenceid: referenceid,
    startDate: null,
    endDate: null,
    existsmsg: "",
    editaccount: true,
    middleloader: false,

    notesuccess: "",
    transfereeuid: "",
    notess: "",
    notesslist: [],
    billableevent: false,
    timeLabels: [],
  };
  const [state, setState] = useState(intialState);
  const edit = {
    Activityshowingerror: "",
    editActivityid: false,
    activityShowSuccess: false,
    Activityshowing: "",
    fromavailableTillDate: null,
    toavailableTillDate: null,
    billableevent: false,
    activityusernotes: "",
    activityuploadLoader: false,
    transfereeactivityUId: "",
    moredetails: user, //location.state.row,
    // timeLabels: timeLabels,
    fromtimeselect: "",
    totimeselect: "",
    twoarray: ["Select", "Phone", "Email", "In-Person", "Video call"],
    contactType: "",
    activityDropDown: [
      "Select ",
      "Introductory Call",
      "Pre-Move preparations",
      "Area Tours",
      "Property Tours",
      "Settling In services",
      "Touch Base",
      "Other",
    ],
    activity: "",
    loader: false,
    note: "",
  };

  const [data, setData] = useState(edit);

  function handleDateChange(date, e) {
    let object = {};
    object[date] = e;
    if (date == "fromavailableTillDate") {
      updateState(object);
    } else {
      updateState(object);
    }
  }

  function handleActivity() {
    $("document").ready(function () {
      $(".is-invalid").removeClass("is-invalid");
      $("#myModalAddActivity").modal("show");
    });
    setState((prev) => ({ ...prev, activeModal: true, editActivityid: false }));
  }

  function handleNote() {
    $("#myModalAddNotes").modal("show");
    setState((prev) => ({ ...prev, handlenote: true }));
  }

  async function onClickNotes(record) {
    await setState((prev) => ({ ...prev, Loader: true }));
    let data = {
      transfereeuid: record,
    };
    const GetTransfereeNotes = await ApiServices.GetTransfereeNotes(data);
    if (GetTransfereeNotes.error == 0) {
      await setState((prev) => ({
        ...prev,
        notesuccess: "",
        transfereeuid: record.userUniqueId,
        notess: "",
        notesslist: GetTransfereeNotes.message.noteslist
          .sort((a, b) => new Date(b.createddate) - new Date(a.createddate))
          .reverse(),
        Loader: false,
      }));
    } else {
      await setState((prev) => ({ ...prev, Loader: false }));
    }
  }
  const getactivitys = async () => {
    await setState((prev) => ({ ...prev, Loader: true }));
    let data = {
      transfereeuid: state.moredetails.userUniqueId,
    };
    const GetTransfereeActivities = await ApiServices.GetTransfereeActivities(
      data
    );
    if (GetTransfereeActivities.error == 0) {
      await setState((prev) => ({
        ...prev,
        allActivitieslist: GetTransfereeActivities.statuslist,
        Loader: false,
      }));
    } else {
      await setState((prev) => ({
        ...prev,
        allActivitieslist: [],
        Loader: false,
      }));
    }
  };

  async function handleChange(name, e) {
    let value = e.target.value;

    let object = {};
    object[name] = value;
    if (value == null || value == "") {
      $("#" + name).addClass("is-invalid");
    } else {
      $("#" + name).removeClass("is-invalid");
    }
    await updateState(object);
  }

  async function updateState(data) {
    await setData((prevState) => ({
      ...prevState,
      ...data,
    }));
  }

  async function editActivity(row) {
    console.log(row, "edit row details");
    await updateState({
      activityShowSuccess: false,
      activityerrorMessage: false,
      activity: row.activity,
      editActivityid: true,
      activeModal: true,
      billableevent: row.billableevent == null ? false : row.billableevent,
      fromtimeselect: row.activityfromtime,
      totimeselect: row.activitytotime,
      contactType: row.contacttype,
      note: row.notes,
      fromavailableTillDate:
        row.activityfromdate != null ? new Date(row.activityfromdate) : null,
      toavailableTillDate:
        row.activitytodate == null ? null : new Date(row.activitytodate),
      transfereeactivityUId: row.transfereeactivityUId,
    });

    $("document").ready(function () {
      $(".is-invalid").removeClass("is-invalid");
      $("#myModalAddActivity").modal("show");
    });
  }
  async function updatesendbillableevent(e) {
    e.persist();
    updateState({ billableevent: !data.billableevent });
  }

  async function editUpdate(e) {
    e.preventDefault();

    let errorUpdate = 0;
    if (data.activity == "" || data.activity == null) {
      $("#activity").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#activity").removeClass("is-invalid");
    }
    if (data.contactType == "" || data.contactType == null) {
      $("#contactType").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#contactType").removeClass("is-invalid");
    }
    if (data.fromavailableTillDate == null) {
      $("#fromavailableTillDate").addClass("is-invalid");
      await updateState({ loader: false });
      errorUpdate++;
    } else {
      $("#fromavailableTillDate").removeClass("is-invalid");
    }

    if (data.toavailableTillDate == null) {
      $("#toavailableTillDate").addClass("is-invalid");
      await updateState({ loader: false });
      errorUpdate++;
    } else {
      $("#toavailableTillDate").removeClass("is-invalid");
    }
    if (data.fromtimeselect == "" || data.fromtimeselect == null) {
      $("#fromtimeselect").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#fromtimeselect").removeClass("is-invalid");
    }
    if (data.totimeselect == "" || data.totimeselect == null) {
      $("#totimeselect").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#totimeselect").removeClass("is-invalid");
    }

    if (errorUpdate == 0) {
      let dataUpdate = {
        transfereeactivityUId: data.transfereeactivityUId,
        activity: data.activity,
        transfereeuid: data.moredetails.userUniqueId,
        activityfromdate: moment(data.fromavailableTillDate).format(
          "MM/DD/YYYY"
        ),
        activitytodate: moment(data.toavailableTillDate).format("MM/DD/YYYY"),
        activityfromtime: data.fromtimeselect,
        activitytotime: data.totimeselect,
        billableevent: data.billableevent,
        contacttype: data.contactType,
        // supplieruid: this.state.getSessionData.supplieruid,
        supplieruid: "90f04e8c-0f75-44a8-92d0-4e089a17d113",
        notes: data.note,
      };

      await updateState({ activityuploadLoader: true });
      const UpdateTransfereeActivities =
        await ApiServices.UpdateTransfereeActivities(dataUpdate);
      if (UpdateTransfereeActivities.error == 0) {
        await getactivitys();
        await updateState({
          activityerrorMessage: false,
          activityShowSuccess: true,
          activityuploadLoader: false,
          Activityshowing: UpdateTransfereeActivities.message,
        });
      } else {
        await updateState({
          activityerrorMessage: true,
          activityShowSuccess: false,
          activityuploadLoader: false,
          Activityshowingerror: UpdateTransfereeActivities.message,
        });
      }
    } else {
      // console.log("not api");
      await {
        activityerrorMessage: true,

        activityuploadLoader: false,
        Activityshowingerror: "please update the data",
      };
    }
  }

  async function handleClose(close) {
    // console.log(close, "close");
    if (close == "edit") {
      $("document").ready(function () {
        $(".is-invalid").removeClass("is-invalid");
        $("#myModalAddActivity").modal("hide");
      });
      setData(edit);
    } else if (close == "note") {
      $("document").ready(function () {
        $(".is-invalid").removeClass("is-invalid");
        $("#myModalAddNotes").modal("hide");
      });

      setState((prev) => ({ ...prev, handlenote: false }));
    } else {
      setState((prev) => ({ ...prev, activeModal: false }));
    }
  }
  const [editTransferee, setEditTransferee] = useState({
    firstname: "",
    middlename: "",
    lastname: "",
    email: "",
    phoneNumber: "",
    countrycode: "",
    clientuniqueid: "",
    approvedhours: "",
    istemporary: "",
    companyinpersonhours: "",
    companyvirtualhours: "",
    individualinpersonhours: "",
    individualvirtualhours: "",
    servicelevel: "",
    ShowSuccess: false,
    usernotes: "",
    po: "",
    referenceid: "",
    startDate: null,
    endDate: null,
  });
  async function editingTransferee(record) {
    console.log("ppppppp", record);
    $("#myModaledit").modal("show");
    let onedata =
      record.individualvirtualhours == null
        ? 0
        : parseInt(record.individualvirtualhours);
    let dataone =
      record.companyvirtualhours == null
        ? 0
        : parseInt(record.companyvirtualhours);
    let sumoff = onedata + dataone;
    await setEditTransferee((prev) => ({
      ...prev,
      recordval: record,
      firstname: record.userFirstName,
      middlename: record.middlename,
      lastname: record.userLastName,
      email: record.EmailId,
      phoneNumber: record.PhoneNumber,
      countrycode: record.countrycode,
      clientuniqueid: record.clientuniqueid,
      approvedhours:
        record.subscriptioncode == "ReloNavigator"
          ? 0
          : record.subscriptioncode == "Explorer"
          ? sumoff
          : parseInt(record.transfereehours, 10),
      istemporary: record.istemporary,
      companyinpersonhours:
        record.subscriptioncode == "ReloNavigator"
          ? 0
          : record.subscriptioncode == "Explorer"
          ? 0
          : record.companyinpersonhours == null
          ? 0
          : record.companyinpersonhours,
      companyvirtualhours:
        record.subscriptioncode == "ReloNavigator"
          ? 0
          : record.companyvirtualhours == null
          ? 0
          : record.companyvirtualhours,
      individualinpersonhours:
        record.subscriptioncode == "ReloNavigator"
          ? 0
          : record.subscriptioncode == "Explorer"
          ? 0
          : record.individualinpersonhours == null
          ? 0
          : record.individualinpersonhours,
      individualvirtualhours:
        record.subscriptioncode == "ReloNavigator"
          ? 0
          : record.individualvirtualhours == null
          ? 0
          : record.individualvirtualhours,
      servicelevel: record.subscriptioncode,
      ShowSuccess: false,
      usernotes: record.usernotes == null ? "" : record.usernotes,
      po: record.po == null ? "" : record.po,
      referenceid: record.referenceid == null ? "" : record.referenceid,
      startDate:
        record.accessfromdate != null ? new Date(record.accessfromdate) : null,
      endDate:
        record.accesstodate != null ? new Date(record.accesstodate) : null,
    }));
  }
  return (
    <div className="tab-content">
      {tabss == "Account" ? (
        <Account
          companyname={companyname}
          state={state}
          handleMail={handleMail}
          editingTransferee={editingTransferee}
          moredetails={moredetails}
        />
      ) : null}
      {tabss == "Preferences" ? (
        <Preferences userUniqueId={props.data.userUniqueId} />
      ) : null}
      {tabss == "Properties" ? (
        <Properties
          userUniqueId={props.moredetails?.userUniqueId}
          isauthgenerated={props.moredetails?.isauthgenerated}
          subscriptioncode={props.moredetails?.subscriptioncode}
        />
      ) : null}
      {tabss == "Itinerary" ? (
        <Itinerary userUniqueId={props.moredetails?.userUniqueId} />
      ) : null}
      {tabss == "Activity" ? (
        <div
          id="tabActivity"
          className={
            tabss == "Activity"
              ? " tab-pane fade mt-5 active show "
              : "tab-pane fade mt-5"
          }
        >
          <div className="text-right mb-3">
            <button
              type="button"
              className="btn theme-btn"
              onClick={() => handleActivity()}
              data-toggle="modal"
              data-target="#myModalAddActivity"
            >
              + Activity
            </button>
          </div>
          {state.allActivitieslist.length > 0 ? (
            state.allActivitieslist.map((row, i) => {
              return (
                <div className="tA-gird-view" key={i}>
                  <div className="top">
                    <h1>{row.activity}</h1>
                    <ul>
                      <li>{row.contacttype}</li>
                      {/* <li>{moment(row.activitydate).format("MM/DD/YYYY")}</li>
                                  <li>{row.activityfromtime}</li> */}
                      <li style={{ border: "none" }}>
                        <a>
                          {" "}
                          <i
                            className="fa fa-edit"
                            onClick={() => editActivity(row)}
                          ></i>
                        </a>
                      </li>
                      {/* <li><a href="#"> <i className="fa fa-trash" onClick={() => this.deleteActivity(row)}></i></a></li> */}
                    </ul>
                  </div>
                  <div className="content">
                    {row.notes}
                    <ul className="date_time">
                      <li>
                        {row.activityfromdate != null
                          ? moment(row.activityfromdate).format("MM/DD/YYYY")
                          : ""}{" "}
                        -{" "}
                        {row.activitytodate != null
                          ? moment(row.activitytodate).format("MM/DD/YYYY")
                          : ""}
                      </li>
                      <li>
                        {row.activityfromtime != null
                          ? row.activityfromtime
                          : ""}{" "}
                        - {row.activitytotime != null ? row.activitytotime : ""}
                      </li>
                    </ul>
                  </div>
                </div>
              );
            })
          ) : (
            <p className="nodataFound"> No data avaliable</p>
          )}
        </div>
      ) : null}
      {tabss == "Notes" ? (
        <div
          id="tabNotes"
          className={
            tabss == "Notes"
              ? " tab-pane fade mt-5 active show "
              : "tab-pane fade mt-5"
          }
        >
          <div className="text-end mb-3">
            <button
              type="button"
              className="btn theme-btn"
              onClick={() => handleNote()}
              data-toggle="modal"
              data-target="#myModalAddNotes"
            >
              + Notes
            </button>
          </div>
          {state.notesslist.length > 0 ? (
            [...state.notesslist].reverse().map((row, i) => {
              // console.log(moment().calendar());
              return (
                <div className="tA-gird-view" key={i}>
                  <div className="top justify-content-end">
                    {moment(row.createddate).calendar()}
                  </div>
                  <div className="content">{row.notes}</div>
                </div>
              );
            })
          ) : (
            <p className="nodataFound">No data avaliable</p>
          )}

          {/* <Modal
            open={state.handlenote}
            footer={null}
            onCancel={handleClose("note")}
          >
            {state.notesShowSuccess == true ? (
              ""
            ) : (
              <h2 className="modal-title" id="exampleModalLongTitle">
                Notes
              </h2>
            )}
            {state.noteserrorMessage == true ? (
              <div className="pop-form-controls focus-input-label">
                <div className="pt-3">
                  <h6 className="alert alert-warning">
                    {state.notesshowingerror}
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}

            {state.notesShowSuccess == true ? (
              <div className="pop-form-controls focus-input-label mb-4">
                <div className="">
                  <h6 className="alert alert-success mb-5">
                    Notes added successfully!
                  </h6>
                </div>
              </div>
            ) : (
              <div className="modal-body">
                <div className="formAddActivity">
                  <form
                    method="post"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <textarea
                          className="form-control"
                          rows="6"
                          value={state.notess}
                          placeholder="Update notes here"
                          id="notess"
                        />
                      </div>
                    </div>
                    <div className="text-center mt-3 mb-3">
                      {state.uploadLoader ? (
                        <Spin />
                      ) : (
                        <button
                          type="submit"
                          className="btn theme-btn"
                          //  onClick={() => addNotes("true")}
                        >
                          SAVE<span></span>
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            )}
          </Modal> */}
        </div>
      ) : null}
      {tabss == "Status" ? (
        <Status userUniqueId={props.data.userUniqueId} />
      ) : null}

      {/* <div
        className="modal fade modalAddActivity new_modal_pop"
        id="myModalAddEdit"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content ">
            <div className="width-70per pd-y-50">
              <div className="only_close">
                <span
                  className="close pointer"
                  aria-hidden="true"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  &times;
                </span>
              </div>
              {state.activityShowSuccess == false ? (
                state.editActivityid == true ? (
                  <h2 className="modal-title" id="exampleModalLongTitle">
                    Edit Activity
                  </h2>
                ) : (
                  <h2 className="modal-title" id="exampleModalLongTitle">
                    Add Activity
                  </h2>
                )
              ) : (
                ""
              )}
              {state.activityerrorMessage == true ? (
                <div className="pop-form-controls focus-input-label">
                  <div className="pt-3">
                    <h6 className="alert alert-warning">
                      {state.Activityshowingerror}
                    </h6>
                  </div>
                </div>
              ) : (
                ""
              )}

              {state.activityShowSuccess == true ? (
                <div className="pop-form-controls focus-input-label mb-4">
                  <div className="">
                    <h6 className="alert alert-success">
                      {state.editActivityid == true
                        ? state.Activityshowing
                        : state.Activityshowing}{" "}
                    </h6>
                  </div>
                </div>
              ) : (
                <div className="modal-body">
                  <div className="formAddActivity">
                    <form>
                      <div className="form-group">
                        <label>Activity</label>
                        <Select
                          className="autoComplete"
                          id="activity"
                          value={state.activity}
                          style={{ width: "100%" }}
                          onChange={(e) => onChangeTypes("activity", e)}
                        >
                          {onearray}
                        </Select>
                      </div>
                      <div className="row form-group">
                        <div className="col-sm-6 calendar-icon-needed">
                          <label>From Date</label>
                          <DatePicker
                            id="fromavailableTillDate"
                            dateFormat="MM/dd/yyyy"
                            selected={state.fromavailableTillDate}
                            selectsStart
                            minDate={new Date()}
                            maxDate={state.toavailableTillDate}
                            onChange={handleDateChange}
                            placeholderText="Select start date ..."
                            className="form-control"
                            autoComplete="off"
                          />
                        </div>
                        <div className="col-sm-6 calendar-icon-needed">
                          <label>To Date</label>
                          <DatePicker
                            id="toavailableTillDate"
                            dateFormat="MM/dd/yyyy"
                            selected={state.toavailableTillDate}
                            selectsEnd
                            autoComplete="off"
                            startDate={state.fromavailableTillDate}
                            endDate={state.toavailableTillDate}
                            onChange={handleDateChangeTo}
                            minDate={
                              state.fromavailableTillDate == null
                                ? new Date()
                                : state.fromavailableTillDate
                            }
                            placeholderText="Select end date ..."
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row form-group">
                        <div className="col-sm-6">
                          <label>From Time</label>
                          <Select
                            className="autoComplete"
                            id="fromtimeselect"
                            value={state.fromtimeselect}
                            style={{ width: "100%" }}
                            onChange={(e) => onChangeTypes("fromtimeselect", e)}
                          >
                            {state.timeLabels.map((option) => (
                              <Select.Option key={option} value={option}>
                                {option}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                        <div className="col-sm-6">
                          <label>To Time</label>
                          <Select
                            className="autoComplete"
                            id="totimeselect"
                            value={state.totimeselect}
                            style={{ width: "100%" }}
                            onChange={(e) => onChangeTypes("totimeselect", e)}
                          >
                            {state.timeLabels.map((option) => (
                              <Select.Option key={option} value={option}>
                                {option}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-sm-8">
                          <label>Contact Type</label>
                          <Select
                            className="autoComplete"
                            id="contactType"
                            value={state.contactType}
                            style={{ width: "100%" }}
                            onChange={(e) => onChangeTypes("contactType", e)}
                          >
                            {twoarray}
                          </Select>
                        </div>
                        <div className="col-sm-4">
                          <div className="checbox-label">
                            <input
                              type="checkbox"
                              className="btn btn-clr mrgrt-10"
                              value={state.billableevent}
                              checked={state.billableevent}
                              onChange={(e) => updatesendbillableevent(e)}
                            />
                            <label className="label_cls">Billable Event</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Notes</label>
                        <textarea
                          className="form-control"
                          rows="4"
                          value={state.activityusernotes}
                          placeholder="Notes"
                          id="activityusernotes"
                          onChange={inputHandler.bind(
                            this,
                            "activityusernotes"
                          )}
                        />
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer mt-3 mb-3">
                    {state.activityuploadLoader ? (
                      <Spin />
                    ) : (
                      <button
                        type="submit"
                        className="btn theme-btn"
                        onClick={() => addtheone("true")}
                      >
                        {state.editActivityid != "" ? "UPDATE" : "SAVE"}{" "}
                        <span></span>{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div> */}
      <Activity
        data={data}
        setData={setData}
        handleClose={handleClose}
        editActivityid={data.editActivityid}
        getactivitys={getactivitys}
        timeLabels={timeLabels}
        allActivitieslist={state.allActivitieslist}
        modal={state.activeModal}
      />
      <EditTransferee transferee={editTransferee} getmoredata={getmoredata} />
      <Note
        handleClose={handleClose}
        onClickNotes={onClickNotes}
        // getStatusList={getStatusList}
      />
    </div>
  );
};

export default Tabs;

// import React from "react";
// import { useState, useEffect } from "react";
// import moment from "moment";
// import { Spin } from "antd";
// import * as $ from "jquery";
// import _ from "lodash";
// import ApiServices from "../../../../services/apiServices";
// import Activity from "../../Consultant/Transeferee/Modals/Activity";
// import Note from "../../Consultant/Transeferee/Modals/Note";

// import { useLocation } from "react-router-dom";
// import DatePicker from "react-datepicker";
// import EditTransferee from "../../Consultant/Transeferee/Modals/EditTransferee";
// import Account from "./tabs/Account";
// import Preferences from "./tabs/Preferences";
// import Properties from "./tabs/Properties";
// import Status from "./tabs/Status";
// import Itinerary from "./tabs/Itinerary";

// const Tabs = (props) => {
//   const location = useLocation();
//   const { tabss, timeLabels, handleMail, getmoredata } = props;
//   const {
//     userFirstName,
//     userLastName,
//     PhoneNumber,
//     countrycode,
//     EmailId,
//     subscriptioncode,
//     accessfromdate,
//     accesstodate,
//     po,
//     referenceid,
//     usernotes,
//     individualvirtualhours,
//     companyvirtualhours,
//   } = props.data;
//   const intialState = {
//     editid: false,
//     errorMessage: "",
//     existsmsg: "",
//     ShowSuccess: "",
//     showing: "",
//     uploadLoader: false,
//     activity: "",
//     selectdate: null,
//     fromavailableTillDate: null,
//     toavailableTillDate: null,
//     contactType: "",
//     fromtimeselect: "",
//     totimeselect: "",
//     activityusernotes: "",
//     allActivitieslist: [],
//     activityerrorMessage: false,
//     activityShowSuccess: false,
//     transfereeactivityUId: "",
//     editActivityid: false,
//     Activityshowingerror: "",
//     Activityshowing: "",

//     activityuploadLoader: false,
//     shortname: "",
//     dataSource: [],

//     moredetails: props.data,
//     allstatuslist: [],
//     TransfereeStatus: [],
//     getSessionData: {},
//     companydata: [],
//     Loader: false,

//     firstname: userFirstName,
//     middlename: "",
//     lastname: userLastName,
//     email: EmailId,
//     phoneNumber: countrycode + "" + PhoneNumber,
//     countrycode: "",
//     clientuniqueid: "",
//     compyname: "",
//     approvedhours: "",
//     companyinpersonhours: "",
//     companyvirtualhours: companyvirtualhours,
//     individualinpersonhours: "",
//     individualvirtualhours: individualvirtualhours,
//     servicelevel: subscriptioncode,
//     ShowSuccess: "",
//     usernotes: usernotes,
//     accessfromdate: accessfromdate,
//     accesstodate: accesstodate,
//     po: po,
//     referenceid: referenceid,
//     startDate: null,
//     endDate: null,
//     existsmsg: "",
//     editaccount: true,
//     middleloader: false,

//     notesuccess: "",
//     transfereeuid: "",
//     notess: "",
//     notesslist: [],
//     billableevent: false,
//     timeLabels: [],
//   };
//   const [state, setState] = useState(intialState);
//   const edit = {
//     Activityshowingerror: "",
//     editActivityid: false,
//     activityShowSuccess: false,
//     Activityshowing: "",
//     fromavailableTillDate: null,
//     toavailableTillDate: null,
//     billableevent: false,
//     activityusernotes: "",
//     activityuploadLoader: false,
//     transfereeactivityUId: "",
//     moredetails: location.state.row,
//     // timeLabels: timeLabels,
//     fromtimeselect: "",
//     totimeselect: "",
//     twoarray: ["Select", "Phone", "Email", "In-Person", "Video call"],
//     contactType: "",
//     activityDropDown: [
//       "Select ",
//       "Introductory Call",
//       "Pre-Move preparations",
//       "Area Tours",
//       "Property Tours",
//       "Settling In services",
//       "Touch Base",
//       "Other",
//     ],
//     activity: "",
//     loader: false,
//     note: "",
//   };

//   const [data, setData] = useState(edit);

//   function handleDateChange(date, e) {
//     let object = {};
//     object[date] = e;
//     if (date == "fromavailableTillDate") {
//       updateState(object);
//     } else {
//       updateState(object);
//     }
//   }

//   function handleActivity() {
//     $("#myModalAddActivity").modal("show");
//   }

//   function handleNote() {
//     $("#myModalAddNotes").modal("show");
//   }

//   async function onClickNotes(record) {
//     await setState((prev) => ({ ...prev, Loader: true }));
//     let data = {
//       transfereeuid: record.userUniqueId,
//     };
//     const GetTransfereeNotes = await ApiServices.GetTransfereeNotes(data);
//     if (GetTransfereeNotes.error == 0) {
//       await setState((prev) => ({
//         ...prev,
//         notesuccess: "",
//         transfereeuid: record.userUniqueId,
//         notess: "",
//         notesslist: GetTransfereeNotes.message.noteslist
//           .sort((a, b) => new Date(b.createddate) - new Date(a.createddate))
//           .reverse(),
//         Loader: false,
//       }));
//     } else {
//       await setState((prev) => ({ ...prev, Loader: false }));
//     }
//   }
//   const getactivitys = async () => {
//     await setState((prev) => ({ ...prev, Loader: true }));
//     let data = {
//       transfereeuid: state.moredetails.userUniqueId,
//     };
//     const GetTransfereeActivities = await ApiServices.GetTransfereeActivities(
//       data
//     );
//     if (GetTransfereeActivities.error == 0) {
//       await setState((prev) => ({
//         ...prev,
//         allActivitieslist: GetTransfereeActivities.statuslist,
//         Loader: false,
//       }));
//     } else {
//       await setState((prev) => ({
//         ...prev,
//         allActivitieslist: [],
//         Loader: false,
//       }));
//     }
//   };

//   async function handleChange(name, e) {
//     let value = e.target.value;

//     let object = {};
//     object[name] = value;
//     if (value == null || value == "") {
//       $("#" + name).addClass("is-invalid");
//     } else {
//       $("#" + name).removeClass("is-invalid");
//     }
//     await updateState(object);
//   }

//   async function updateState(data) {
//     await setData((prevState) => ({
//       ...prevState,
//       ...data,
//     }));
//   }

//   async function editActivity(row) {
//     updateState({
//       activity: row.activity,

//       billableevent: row.billableevent == null ? false : row.billableevent,
//       fromtimeselect: row.activityfromtime,
//       totimeselect: row.activitytotime,
//       contactType: row.contacttype,
//       note: row.notes,
//       fromavailableTillDate:
//         row.activityfromdate != null ? new Date(row.activityfromdate) : null,
//       toavailableTillDate:
//         row.activitytodate == null ? null : new Date(row.activitytodate),
//       transfereeactivityUId: row.transfereeactivityUId,
//     });

//     $("document").ready(function () {
//       $(".is-invalid").removeClass("is-invalid");
//       $("#myModalAddEdit").modal("show");
//     });
//   }
//   async function updatesendbillableevent(e) {
//     e.persist();
//     updateState({ billableevent: !data.billableevent });
//   }

//   async function editUpdate(e) {
//     e.preventDefault();

//     let errorUpdate = 0;
//     if (data.activity == "" || data.activity == null) {
//       $("#activity").addClass("is-invalid");
//       errorUpdate++;
//     } else {
//       $("#activity").removeClass("is-invalid");
//     }
//     if (data.contactType == "" || data.contactType == null) {
//       $("#contactType").addClass("is-invalid");
//       errorUpdate++;
//     } else {
//       $("#contactType").removeClass("is-invalid");
//     }
//     if (data.fromavailableTillDate == null) {
//       $("#fromavailableTillDate").addClass("is-invalid");
//       await updateState({ loader: false });
//       errorUpdate++;
//     } else {
//       $("#fromavailableTillDate").removeClass("is-invalid");
//     }

//     if (data.toavailableTillDate == null) {
//       $("#toavailableTillDate").addClass("is-invalid");
//       await updateState({ loader: false });
//       errorUpdate++;
//     } else {
//       $("#toavailableTillDate").removeClass("is-invalid");
//     }
//     if (data.fromtimeselect == "" || data.fromtimeselect == null) {
//       $("#fromtimeselect").addClass("is-invalid");
//       errorUpdate++;
//     } else {
//       $("#fromtimeselect").removeClass("is-invalid");
//     }
//     if (data.totimeselect == "" || data.totimeselect == null) {
//       $("#totimeselect").addClass("is-invalid");
//       errorUpdate++;
//     } else {
//       $("#totimeselect").removeClass("is-invalid");
//     }

//     if (errorUpdate == 0) {
//       let dataUpdate = {
//         transfereeactivityUId: data.transfereeactivityUId,
//         activity: data.activity,
//         transfereeuid: data.moredetails.userUniqueId,
//         activityfromdate: moment(data.fromavailableTillDate).format(
//           "MM/DD/YYYY"
//         ),
//         activitytodate: moment(data.toavailableTillDate).format("MM/DD/YYYY"),
//         activityfromtime: data.fromtimeselect,
//         activitytotime: data.totimeselect,
//         billableevent: data.billableevent,
//         contacttype: data.contactType,
//         // supplieruid: this.state.getSessionData.supplieruid,
//         supplieruid: "90f04e8c-0f75-44a8-92d0-4e089a17d113",
//         notes: data.note,
//       };

//       await updateState({ activityuploadLoader: true });
//       const UpdateTransfereeActivities =
//         await ApiServices.UpdateTransfereeActivities(dataUpdate);
//       if (UpdateTransfereeActivities.error == 0) {
//         await getactivitys();
//         await updateState({
//           activityerrorMessage: false,
//           activityShowSuccess: true,
//           activityuploadLoader: false,
//           Activityshowing: UpdateTransfereeActivities.message,
//         });
//       } else {
//         await updateState({
//           activityerrorMessage: true,
//           activityShowSuccess: false,
//           activityuploadLoader: false,
//           Activityshowingerror: UpdateTransfereeActivities.message,
//         });
//       }
//     } else {
//       console.log("not api");
//       await {
//         activityerrorMessage: true,

//         activityuploadLoader: false,
//         Activityshowingerror: "please update the data",
//       };
//     }
//   }

//   async function handleClose(close) {
//     if (close == "edit") {
//       $("document").ready(function () {
//         $(".is-invalid").removeClass("is-invalid");
//         $("#myModalAddEdit").modal("hide");
//       });
//       setData(edit);
//     } else if (close == "note") {
//       $("document").ready(function () {
//         $(".is-invalid").removeClass("is-invalid");
//         $("#myModalAddNotes").modal("hide");
//       });
//     } else {
//       $("document").ready(function () {
//         $(".is-invalid").removeClass("is-invalid");
//         $("#myModalAddActivity").modal("hide");
//       });
//     }
//   }
//   const [editTransferee, setEditTransferee] = useState({
//     firstname: "",
//     middlename: "",
//     lastname: "",
//     email: "",
//     phoneNumber: "",
//     countrycode: "",
//     clientuniqueid: "",
//     approvedhours: "",
//     companyinpersonhours: "",
//     companyvirtualhours: "",
//     individualinpersonhours: "",
//     individualvirtualhours: "",
//     servicelevel: "",
//     ShowSuccess: false,
//     usernotes: "",
//     po: "",
//     referenceid: "",
//     startDate: null,
//     endDate: null,
//   });
//   async function editingTransferee(record) {
//     console.log("ppppppp", record);
//     $("#myModaledit").modal("show");
//     let onedata =
//       record.individualvirtualhours == null
//         ? 0
//         : parseInt(record.individualvirtualhours);
//     let dataone =
//       record.companyvirtualhours == null
//         ? 0
//         : parseInt(record.companyvirtualhours);
//     let sumoff = onedata + dataone;
//     await setEditTransferee((prev) => ({
//       ...prev,
//       recordval: record,
//       firstname: record.userFirstName,
//       middlename: record.middlename,
//       lastname: record.userLastName,
//       email: record.EmailId,
//       phoneNumber: record.PhoneNumber,
//       countrycode: record.countrycode,
//       clientuniqueid: record.clientuniqueid,
//       approvedhours:
//         record.subscriptioncode == "ReloNavigator"
//           ? 0
//           : record.subscriptioncode == "Explorer"
//           ? sumoff
//           : parseInt(record.transfereehours, 10),
//       companyinpersonhours:
//         record.subscriptioncode == "ReloNavigator"
//           ? 0
//           : record.subscriptioncode == "Explorer"
//           ? 0
//           : record.companyinpersonhours == null
//           ? 0
//           : record.companyinpersonhours,
//       companyvirtualhours:
//         record.subscriptioncode == "ReloNavigator"
//           ? 0
//           : record.companyvirtualhours == null
//           ? 0
//           : record.companyvirtualhours,
//       individualinpersonhours:
//         record.subscriptioncode == "ReloNavigator"
//           ? 0
//           : record.subscriptioncode == "Explorer"
//           ? 0
//           : record.individualinpersonhours == null
//           ? 0
//           : record.individualinpersonhours,
//       individualvirtualhours:
//         record.subscriptioncode == "ReloNavigator"
//           ? 0
//           : record.individualvirtualhours == null
//           ? 0
//           : record.individualvirtualhours,
//       servicelevel: record.subscriptioncode,
//       ShowSuccess: false,
//       usernotes: record.usernotes == null ? "" : record.usernotes,
//       po: record.po == null ? "" : record.po,
//       referenceid: record.referenceid == null ? "" : record.referenceid,
//       startDate:
//         record.accessfromdate != null ? new Date(record.accessfromdate) : null,
//       endDate:
//         record.accesstodate != null ? new Date(record.accesstodate) : null,
//     }));
//   }
//   return (
//     <div className="tab-content">
//       {tabss == "Account" ? <Account state={state} /> : null}
//       {tabss == "Preferences" ? (
//         <Preferences userUniqueId={props.data.userUniqueId} />
//       ) : null}
//       {tabss == "Properties" ? (
//         <Properties
//           userUniqueId={props.moredetails?.userUniqueId}
//           isauthgenerated={props.moredetails?.isauthgenerated}
//           subscriptioncode={props.moredetails?.subscriptioncode}
//         />
//       ) : null}
//       {tabss == "Itinerary" ? (
//         <Itinerary userUniqueId={props.moredetails?.userUniqueId} />
//       ) : null}
//       {tabss == "Activity" ? (
//         <div
//           id="tabActivity"
//           className={
//             tabss == "Activity"
//               ? " tab-pane fade mt-5 active show "
//               : "tab-pane fade mt-5"
//           }
//         >
//           <div className="text-right">
//             <button
//               type="button"
//               className="btn"
//               onClick={() => handleActivity()}
//               data-toggle="modal"
//               data-target="#myModalAddActivity"
//             >
//               + Activity
//             </button>
//           </div>
//           {state.allActivitieslist.length > 0 &&
//             state.allActivitieslist.map((row, i) => {
//               return (
//                 <div className="tA-gird-view" key={i}>
//                   <div className="top">
//                     <h1>{row.activity}</h1>
//                     <ul>
//                       <li>{row.contacttype}</li>
//                       {/* <li>{moment(row.activitydate).format("MM/DD/YYYY")}</li>
//                                   <li>{row.activityfromtime}</li> */}
//                       <li style={{ border: "none" }}>
//                         <a>
//                           {" "}
//                           <i
//                             className="fa fa-edit"
//                             onClick={() => editActivity(row)}
//                           ></i>
//                         </a>
//                       </li>
//                       {/* <li><a href="#"> <i className="fa fa-trash" onClick={() => this.deleteActivity(row)}></i></a></li> */}
//                     </ul>
//                   </div>
//                   <div className="content">
//                     {row.notes}
//                     <ul className="date_time">
//                       <li>
//                         {row.activityfromdate != null
//                           ? moment(row.activityfromdate).format("MM/DD/YYYY")
//                           : ""}{" "}
//                         -{" "}
//                         {row.activitytodate != null
//                           ? moment(row.activitytodate).format("MM/DD/YYYY")
//                           : ""}
//                       </li>
//                       <li>
//                         {row.activityfromtime != null
//                           ? row.activityfromtime
//                           : ""}{" "}
//                         - {row.activitytotime != null ? row.activitytotime : ""}
//                       </li>
//                     </ul>
//                   </div>
//                 </div>
//               );
//             })}
//         </div>
//       ) : null}
//       {tabss == "Notes" ? (
//         <div
//           id="tabNotes"
//           className={
//             tabss == "Notes"
//               ? " tab-pane fade mt-5 active show "
//               : "tab-pane fade mt-5"
//           }
//         >
//           <div className="text-right">
//             <button
//               type="button"
//               className="btn"
//               onClick={() => handleNote()}
//               data-toggle="modal"
//               data-target="#myModalAddNotes"
//             >
//               + Notes
//             </button>
//           </div>
//           {state.notesslist.length > 0 &&
//             state.notesslist.reverse().map((row, i) => {
//               console.log(moment().calendar());
//               return (
//                 <div className="tA-gird-view" key={i}>
//                   <div className="top justify-content-end">
//                     {moment(row.createddate).calendar()}
//                   </div>
//                   <div className="content">{row.notes}</div>
//                 </div>
//               );
//             })}

//           {/* <div
//             className="modal fade modalAddActivity new_modal_pop"
//             id="myModalAddNotes"
//             tabIndex="-1"
//             role="dialog"
//             aria-labelledby="exampleModalCenterTitle"
//             aria-hidden="true"
//           >
//             <div
//               className="modal-dialog modal-dialog-centered modal-lg"
//               role="document"
//             >
//               <div className="modal-content ">
//                 <div className="width-70per pd-y-50">
//                   <div className="only_close">
//                     <span
//                       className="close pointer"
//                       aria-hidden="true"
//                       data-dismiss="modal"
//                       aria-label="Close"
//                     >
//                       &times;
//                     </span>
//                   </div>

//                   {state.notesShowSuccess == true ? (
//                     ""
//                   ) : (
//                     <h2 className="modal-title" id="exampleModalLongTitle">
//                       Notes
//                     </h2>
//                   )}
//                   {state.noteserrorMessage == true ? (
//                     <div className="pop-form-controls focus-input-label">
//                       <div className="pt-3">
//                         <h6 className="alert alert-warning">
//                           {state.notesshowingerror}
//                         </h6>
//                       </div>
//                     </div>
//                   ) : (
//                     ""
//                   )}

//                   {state.notesShowSuccess == true ? (
//                     <div className="pop-form-controls focus-input-label mb-4">
//                       <div className="">
//                         <h6 className="alert alert-success mb-5">
//                           Notes added successfully!
//                         </h6>
//                       </div>
//                     </div>
//                   ) : (
//                     <div className="modal-body">
//                       <div className="formAddActivity">
//                         <form
//                           method="post"
//                           onSubmit={(e) => {
//                             e.preventDefault();
//                           }}
//                         >
//                           <div className="row">
//                             <div className="col-md-12">
//                               <textarea
//                                 className="form-control"
//                                 rows="6"
//                                 value={state.notess}
//                                 placeholder="Update notes here"
//                                 id="notess"
//                               />
//                             </div>
//                           </div>
//                           <div className="text-center mt-3 mb-3">
//                             {state.uploadLoader ? (
//                               <Spin />
//                             ) : (
//                               <button
//                                 type="submit"
//                                 className="btn theme-btn"
//                                 //  onClick={() => addNotes("true")}
//                               >
//                                 SAVE<span></span>
//                               </button>
//                             )}
//                           </div>
//                         </form>
//                       </div>
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div> */}
//         </div>
//       ) : null}
//       {tabss == "Status" ? (
//         <Status userUniqueId={props.data.userUniqueId} />
//       ) : null}
//       <Activity
//         handleClose={handleClose}
//         getactivitys={getactivitys}
//         timeLabels={timeLabels}
//         allActivitieslist={state.allActivitieslist}
//       />
//       <EditTransferee transferee={editTransferee} getmoredata={getmoredata} />
//       <Note
//         handleClose={handleClose}
//         onClickNotes={onClickNotes}
//         // getStatusList={getStatusList}
//       />
//     </div>
//   );
// };

// export default Tabs;
