import React, { useEffect, useState } from "react";
import { Spin, Radio, Select } from "antd";
import ApiServices from "../../../../services/apiServices";
import IntTelDropDown from "../../../common/internationalPhone/IntTelDropDown";

const EditConsultantModal = ({
  getSessionData,
  record,
  fetchConsultantsData,
}) => {
  const [state, setState] = useState({
    editid: "",
    errorMessage: false,
    ShowSuccess: false,
    showing: "",
    firstname: "",
    lastname: "",
    email: "",
    phoneNumber: "",
    stateuid: "",
    statesList: [],
    citiesLoader: "",
    favCities: [],
    citiesLIst: [],
    filterzones: [],
    address: "",
    timezone: "",
    isdelete: "",
    uploadLoader: false,
    countrycode: "+1",
  });

  const zonelists = [
    {
      name: "(GMT -12:00) Eniwetok, Kwajalein",
      value: "-12:00",
      timezone: "Pacific/Kwajalein",
    },
    {
      name: "(GMT -11:00) Midway Island, Samoa",
      value: "-11:00",
      timezone: "Pacific/Midway",
    },
    {
      name: "(GMT -10:00) Hawaii",
      value: "-10:00",
      timezone: "Pacific/Honolulu",
    },
    {
      name: "(GMT -9:30) Taiohae",
      value: "-09:50",
      timezone: "Pacific/Marquesas",
    },
    {
      name: "(GMT -8:00) Alaska",
      value: "-09:00",
      timezone: "America/Anchorage",
    },
    {
      name: "(GMT -7:00) Pacific Time (US & Canada)",
      value: "-08:00",
      timezone: "America/Los_Angeles",
    },
    {
      name: "(GMT -6:00) Mountain Time (US & Canada)",
      value: "-07:00",
      timezone: "America/Edmonton",
    },
    {
      name: "(GMT -5:00) Central Time (US & Canada), Mexico City",
      value: "-06:00",
      timezone: "America/Chicago",
    },
    {
      name: "(GMT -4:00) Eastern Time (US & Canada), Bogota, Lima",
      value: "-05:00",
      timezone: "America/New_York",
    },
    {
      name: "(GMT -4:00) Caracas",
      value: "-04:50",
      timezone: "America/Caracas",
    },
    {
      name: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz",
      value: "-04:00",
      timezone: "America/La_Paz",
    },
    {
      name: "(GMT -2:30) Newfoundland",
      value: "-03:50",
      timezone: "America/St_Johns",
    },
    {
      name: "(GMT -4:00) Brazil, Buenos Aires, Georgetown",
      value: "-03:00",
      timezone: "America/Guyana",
    },
    {
      name: "(GMT -2:00) Mid-Atlantic",
      value: "-02:00",
      timezone: "Atlantic/South_Georgia",
    },
    {
      name: "(GMT -1:00) Azores, Cape Verde Islands",
      value: "-01:00",
      timezone: "Atlantic/Cape_Verde",
    },
    {
      name: "(GMT+1:00) Western Europe Time, London, Lisbon, Casablanca",
      value: "+00:00",
      timezone: "Europe/Lisbon",
    },
    {
      name: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris",
      value: "+01:00",
      timezone: "Europe/Brussels",
    },
    {
      name: "(GMT +2:00) Kaliningrad, South Africa",
      value: "+02:00",
      timezone: "Europe/Kaliningrad",
    },
    {
      name: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg",
      value: "+03:00",
      timezone: "Asia/Baghdad",
    },
    { name: "(GMT +4:30) Tehran", value: "+03:50", timezone: "Asia/Tehran" },
    {
      name: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi",
      value: "+04:00",
      timezone: "Asia/Dubai",
    },
    { name: "(GMT +4:30) Kabul", value: "+04:50", timezone: "Asia/Kabul" },
    {
      name: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent",
      value: "+05:00",
      timezone: "Asia/Karachi",
    },
    {
      name: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi",
      value: "+05:50",
      timezone: "Asia/Calcutta",
    },
    {
      name: "(GMT +5:45) Kathmandu, Pokhara",
      value: "+05:75",
      timezone: "Asia/Kathmandu",
    },
    {
      name: "(GMT +6:00) Almaty, Dhaka, Colombo",
      value: "+06:00",
      timezone: "Asia/Almaty",
    },
    {
      name: "(GMT +6:30) Yangon, Mandalay",
      value: "+06:50",
      timezone: "Asia/Yangon",
    },
    {
      name: "(GMT +7:00) Bangkok, Hanoi, Jakarta",
      value: "+07:00",
      timezone: "Asia/Bangkok",
    },
    {
      name: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong",
      value: "+08:00",
      timezone: "Asia/Singapore",
    },
    {
      name: "(GMT +8:45) Eucla",
      value: "+08:75",
      timezone: "Australia/Eucla",
    },
    {
      name: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk",
      value: "+09:00",
      timezone: "Asia/Tokyo",
    },
    {
      name: "(GMT +9:30) Adelaide, Darwin",
      value: "+09:50",
      timezone: "Australia/Adelaide",
    },
    {
      name: "(GMT +10:00) Eastern Australia, Guam, Vladivostok",
      value: "+10:00",
      timezone: "Pacific/Guam",
    },
    {
      name: "(GMT +10:30) Lord Howe Island",
      value: "+10:50",
      timezone: "Australia/Lord_Howe",
    },
    {
      name: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia",
      value: "+11:00",
      timezone: "Pacific/Guadalcanal",
    },
    {
      name: "(GMT +11:00) Norfolk Island",
      value: "+11:50",
      timezone: "Pacific/Norfolk",
    },
    {
      name: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka",
      value: "+12:00",
      timezone: "Pacific/Auckland",
    },
  ];

  function inputHandler(field, value) {
    if (field === "email") {
      isValidEmail(value)
        ? removeInvalidFieldsClass("email")
        : addInvalidFieldsClass("email");
    } else {
      removeInvalidFieldsClass(field);
    }
    updateState({ [field]: value });
  }

  function setLocation(field, value) {
    updateState({ [field]: value });
    let places = new google.maps.places.Autocomplete(
      document.getElementById("edit-consultant-address", {
        types: ["address", "regions"],
      })
    );
    google.maps.event.addListener(places, "place_changed", async () => {
      let place = places.getPlace();
      let placeDetails = {
        locality: "long_name",
        administrative_area_level_1: "short_name",
        country: "long_name",
        postal_code: "short_name",
      };
      for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0];
        if (placeDetails[addressType]) {
          let val = place.address_components[i][placeDetails[addressType]];
          placeDetails[addressType] = val;
        }
      }
      let relocatedAddress = place.formatted_address;
      updateState({
        address: relocatedAddress,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        state: placeDetails.administrative_area_level_1,
        city: placeDetails.locality,
        zipcode:
          placeDetails.postal_code != "short_name"
            ? placeDetails.postal_code
            : "",
      });
    });
  }

  function handleSearching(value) {
    const filterzones = zonelists.filter((o) =>
      o.name.toLowerCase().includes(value.toLowerCase())
    );
    updateState({ filterzones: filterzones });
  }

  function handleChangeing(value) {
    updateState({ timezone: value });
  }

  function createZoneListSelectOptions(zonelists) {
    let data = state.filterzones.length > 0 ? state.filterzones : zonelists;
    return data.map((d) => (
      <Select.Option key={d.name}>{d.name}</Select.Option>
    ));
  }

  function validateInputs() {
    let errors = 0;
    const mandatoryFields = [
      "firstname",
      "lastname",
      "email",
      // "phoneNumber",
      "address",
      "timezone",
    ];
    for (let i = 0; i < mandatoryFields.length; i++) {
      if (state[mandatoryFields[i]].trim() === "") {
        errors++;
        addInvalidFieldsClass(mandatoryFields[i]);
      } else {
        removeInvalidFieldsClass(mandatoryFields[i]);
      }

      if (mandatoryFields[i] === "email") {
        isValidEmail(state[mandatoryFields[i]].trim())
          ? removeInvalidFieldsClass("email")
          : addInvalidFieldsClass("email");
      }
    }
    return errors;
  }

  function isValidEmail(email) {
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return false;
    }
    return true;
  }

  function addInvalidFieldsClass(id) {
    if (id === "timezone") {
      $("[id='time-zone']").addClass("is-invalid");
    } else {
      $(`[id='${id}']`).addClass("is-invalid");
    }
  }

  function removeInvalidFieldsClass(id) {
    if (id === "timezone") {
      $("[id='time-zone']").removeClass("is-invalid");
    } else {
      $(`[id='${id}']`).removeClass("is-invalid");
    }
  }

  async function updateConsultant() {
    let errors = validateInputs();
    console.log(errors);
    updateState({ uploadLoader: true });
    if (!errors) {
      let data = {
        firstname: state.firstname,
        lastname: state.lastname,
        middlename: "",
        employeecode: "",
        address: state.address,
        countrycode: state.countrycode,
        phonenumber: state.phoneNumber,
        emailid: state.email,
        cityuid: state.city,
        favcities: state.favCities,
        supplieruid: getSessionData.userUniqueId,
        stateuid: state.stateuid,
        notes: "",
        zipcode: state.zipcode,
        profilepic: "",
        isdelete: state.isdelete,
        consultantuid: state.consultantuid,
        timezone: state.timezone,
      };
      const UpdateTransferee = await ApiServices.UpdateConsultantInfo(data);
      if (UpdateTransferee.error == 0) {
        fetchConsultantsData();
        updateState({
          ShowSuccess: true,
          uploadLoader: false,
          showing: "successfully updated",
        });
        setTimeout(() => {
          $("#edit-consultant-modal").modal("hide");
          updateState({
            ShowSuccess: false,
            showing: "",
          });
        }, 6000);
      } else {
        updateState({
          errorMessage: true,
          ShowSuccess: false,
          uploadLoader: false,
        });
      }
    } else {
      updateState({ uploadLoader: false });
    }
  }

  function prepareStateForEdit(record) {
    let cities = [];

    if (record.stateuid != "") {
      _.forEach(record.consultantfavcities, function (city) {
        cities.push(city.cityuid);
      });
      updateState({
        stateuid: record.stateuid,
        cityuid: "",
        citiesLIst: [],
      });
      getCities(record.stateuid);
      updateState({
        uploadLoader: false,
        errorMessage: false,
        recordval: record,
        firstname: record.firstname,
        middlename: record.middlename,
        lastname: record.lastname,
        email: record.emailid,
        phoneNumber: record.phonenumber,
        countrycode: record.countrycode,
        editid: true,
        ShowSuccess: false,
        favCities: cities,
        stateuid: record.stateuid,
        address: record.address,
        zipcode: record.zipcode,
        city: record.cityuid,
        consultantuid: record.uniqueId,
        timezone: record.timezone,
        isdelete: record.isdelete,
      });
    }

    console.log(cities, "cities");
  }

  async function getCities(stateuid) {
    updateState({ citiesLoader: true });
    let CitiesReq = {
      stateuid: stateuid,
    };
    let citiesResult = await ApiServices.GetCities(CitiesReq);
    if (citiesResult.error == 0) {
      updateState({
        citiesLIst: citiesResult.message.citieslist,
        citiesLoader: false,
      });
    }
  }

  function onChange(value) {
    updateState({
      isdelete: value,
    });
  }

  function handleCountryCodeChange(code) {
    updateState({ countrycode: code });
  }

  function updateState(updateObj) {
    setState((prevObj) => ({ ...prevObj, ...updateObj }));
  }

  useEffect(() => {
    if (record) {
      prepareStateForEdit(record);
    }
  }, [record]);

  return (
    <>
      <div
        className="modal fade "
        id="edit-consultant-modal"
        aria-labelledby="edit-consultant-modal"
        aria-hidden="true"
        style={{ zIndex: 1060 }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="width-80per pd-y-70">
              <div className="only_close">
                <span
                  className="pointer close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  ×
                </span>
              </div>

              <form
                method="post"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                {state.errorMessage ? (
                  <div className="pop-form-controls focus-input-label mt-4">
                    <div className="floating-label">
                      <h6 className="alert alert-warning">
                        something went wrong
                      </h6>
                    </div>
                  </div>
                ) : null}
                {state.ShowSuccess ? (
                  <div className="pop-form-controls focus-input-label mt-4">
                    <div className="floating-label">
                      <h6 className="alert alert-success">{state.showing} </h6>
                    </div>
                  </div>
                ) : (
                  <>
                    <h6>Edit Consultant</h6>
                    <div className="pop-form-controls focus-input-label mt-4">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="">
                            <label>First Name</label>
                            <input
                              placeholder="First Name"
                              type="text"
                              autoComplete="off"
                              className="form-control"
                              value={state.firstname}
                              id="firstname"
                              onChange={(e) =>
                                inputHandler("firstname", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="">
                            <label>Last Name</label>
                            <input
                              placeholder="Last Name"
                              type="text"
                              autoComplete="off"
                              className="form-control"
                              value={state.lastname}
                              id="lastname"
                              onChange={(e) =>
                                inputHandler("lastname", e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="">
                            <label>Email</label>
                            <input
                              placeholder="Email"
                              type="text"
                              autoComplete="off"
                              className="form-control"
                              value={state.email}
                              id="email"
                              onChange={(e) =>
                                inputHandler("email", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="">
                            <label>Phone Number</label>
                            <div className="form-control flag-posotion2">
                              <div className="phone-flag">
                                <IntTelDropDown
                                  handleCountryCodeChange={
                                    handleCountryCodeChange
                                  }
                                  countrycode={state.countrycode}
                                />
                                <input
                                  type="tel"
                                  className="border-0 ps-2"
                                  autoComplete="off"
                                  value={state.phoneNumber}
                                  placeholder="phoneNumber"
                                  id="phoneNumber"
                                  onChange={(e) =>
                                    inputHandler("phoneNumber", e.target.value)
                                  }
                                  onBlur={(e) =>
                                    inputHandler("phoneNumber", e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="">
                            <label>Address</label>
                            <input
                              placeholder="Enter Location"
                              autoComplete="off"
                              className="form-control"
                              type="text"
                              value={state.address}
                              id="edit-consultant-address"
                              onChange={(e) =>
                                setLocation("address", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6" id="testPosition">
                          <div className="">
                            <label className="s_label">Time Zone</label>
                            <div
                              id="time-Zone"
                              className="form-control"
                              style={{ padding: 0 }}
                            >
                              <Select
                                showSearch
                                value={state.timezone}
                                defaultActiveFirstOption={false}
                                filterOption={false}
                                onSearch={handleSearching}
                                onChange={handleChangeing}
                                notFoundContent={null}
                                size="default"
                                style={{ width: "100%" }}
                              >
                                {createZoneListSelectOptions(zonelists)}
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row" style={{ paddingLeft: "15px" }}>
                        <Radio.Group
                          onChange={(e) => onChange(e.target.value)}
                          value={state.isdelete}
                        >
                          <Radio value={false}>Active</Radio>
                          <Radio value={true}>Inactive</Radio>
                        </Radio.Group>
                      </div>

                      <div className="text-center mt-3 mb-3">
                        {state.uploadLoader ? (
                          <Spin />
                        ) : (
                          <button
                            type="submit"
                            className="btn theme-btn"
                            onClick={() => updateConsultant("true")}
                          >
                            UPDATE
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditConsultantModal;
