import "./Schedules.css";
import React, { Component, useState, useEffect, useRef } from "react";
import moment from "moment";
import { Switch, Input, Select, Spin, Icon, Alert } from "antd";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
import DatePicker from "react-datepicker";
import * as $ from "jquery";
import ApiServices from "../../../../services/apiServices";
import ThirdParty from "../../../../services/thirdPartyService";
import FullCalendar from "@fullcalendar/react";
import "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import "@fullcalendar/daygrid";
import "@fullcalendar/timegrid";
import SchedulesSearchProperties from "./SchedulesSearchProperties";
import AppointmentPopUp from "./modalPopUp/appointmentPopUp";
import Premove from "./modalPopUp/Premove";
import Predetails from "./modalPopUp/detailsModal";

const Schedules = (props) => {
  const { serviceslist } = props;
  const calendarComponentRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [state, setState] = useState({
    modalPopUp: false,
    events: [],
    serviceDrodown: "",
    serviceNote: "",
    start: "",
    end: "",
    Modalerror: false,
    ErrorMsg: "",
    EditEvent: "",
    EditMoveDate: new Date(),
    eventDetails: "",
    ErrorMsgExitsMsg: "",
    moveModalerror: false,
    packageserviceuniqueid: [],
    serviceList: serviceslist,
    pickupLocation: "",
    pickupLocation2: "",
    businessHours: [],
    calendarWeekends: true,
    selectable: true,
    firstDay: 2,
    calendarEvents: [],
    businessHoursLoader: true,
    consultantName: "",
    timeSlots: [],
    startTimeId: "",
    startTime: "",
    endTimeId: "",
    endTime: "",
    showProperties: false,
    propertyListIds: [],
    Title: "",
    transfereehours: "",
    transfereescheduledhours: "",
    isConfirmed: false,
    succesMsg: "",
    confirmError: "",
    PhoneNumber: "",
    countrycode: "",
    EmailId: "",
    checkTransfareeHours: "",
    itineraryPropertiesList: [],
    showRestriction: true,
    dayNameLabel: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    eventset: [],
    scheduleType: "In Person",
    subscriptioncode: "",
    typesofSchedules: ["In Person", "Virtual"],
    appointmentType: [
      "Pre Move Preparation",
      "Area Tours",
      "Property Tours",
      "Setting in Services",
    ],
    masterServiceType: [
      "Airport Pickup",
      "Banking",
      "Cell & Internet setup",
      "Driver’s license",
      "Home furnishing",
      "Insurance assistance",
      "Meet and Greet",
      "School research",
      "Social Security card",
      "Utilities setup",
      "Vehicle registration",
    ],
    selectdate: moment(),
    bookingslots: [],
    busidates: [],
    consultantname: "",
    timessslot: [],
    ExitsErrorMsg: "",
    consultantprofilepic: "",
    availableLoader: false,
    scheduleLoader: false,
    updateAppointmentDetails: false,
    MonthlybusinessHours: [],
    timezone: "",
    consultantemail: "",
    accesstodate: "",
    showServiceType: false,
    servicetype: "",
    timezoneslist: [
      {
        name: "(GMT -12:00) Eniwetok, Kwajalein",
        value: "-12:00",
        timezone: "Pacific/Kwajalein",
      },
      {
        name: "(GMT -11:00) Midway Island, Samoa",
        value: "-11:00",
        timezone: "Pacific/Midway",
      },
      {
        name: "(GMT -10:00) Hawaii",
        value: "-10:00",
        timezone: "Pacific/Honolulu",
      },
      {
        name: "(GMT -9:30) Taiohae",
        value: "-09:50",
        timezone: "Pacific/Marquesas",
      },
      {
        name: "(GMT -8:00) Alaska",
        value: "-09:00",
        timezone: "America/Anchorage",
      },
      {
        name: "(GMT -7:00) Pacific Time (US & Canada)",
        value: "-08:00",
        timezone: "America/Los_Angeles",
      },
      {
        name: "(GMT -6:00) Mountain Time (US & Canada)",
        value: "-07:00",
        timezone: "America/Edmonton",
      },
      {
        name: "(GMT -5:00) Central Time (US & Canada), Mexico City",
        value: "-06:00",
        timezone: "America/Chicago",
      },
      {
        name: "(GMT -4:00) Eastern Time (US & Canada), Bogota, Lima",
        value: "-05:00",
        timezone: "America/New_York",
      },
      {
        name: "(GMT -4:00) Caracas",
        value: "-04:50",
        timezone: "America/Caracas",
      },
      {
        name: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz",
        value: "-04:00",
        timezone: "America/La_Paz",
      },
      {
        name: "(GMT -2:30) Newfoundland",
        value: "-03:50",
        timezone: "America/St_Johns",
      },
      {
        name: "(GMT -4:00) Brazil, Buenos Aires, Georgetown",
        value: "-03:00",
        timezone: "America/Guyana",
      },
      {
        name: "(GMT -2:00) Mid-Atlantic",
        value: "-02:00",
        timezone: "Atlantic/South_Georgia",
      },
      {
        name: "(GMT -1:00) Azores, Cape Verde Islands",
        value: "-01:00",
        timezone: "Atlantic/Cape_Verde",
      },
      {
        name: "(GMT+1:00) Western Europe Time, London, Lisbon, Casablanca",
        value: "+00:00",
        timezone: "Europe/Lisbon",
      },
      {
        name: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris",
        value: "+01:00",
        timezone: "Europe/Brussels",
      },
      {
        name: "(GMT +2:00) Kaliningrad, South Africa",
        value: "+02:00",
        timezone: "Europe/Kaliningrad",
      },
      {
        name: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg",
        value: "+03:00",
        timezone: "Asia/Baghdad",
      },
      {
        name: "(GMT +4:30) Tehran",
        value: "+03:50",
        timezone: "Asia/Tehran",
      },
      {
        name: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi",
        value: "+04:00",
        timezone: "Asia/Dubai",
      },
      { name: "(GMT +4:30) Kabul", value: "+04:50", timezone: "Asia/Kabul" },
      {
        name: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent",
        value: "+05:00",
        timezone: "Asia/Karachi",
      },
      {
        name: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi",
        value: "+05:50",
        timezone: "Asia/Calcutta",
      },
      {
        name: "(GMT +5:45) Kathmandu, Pokhara",
        value: "+05:75",
        timezone: "Asia/Kathmandu",
      },
      {
        name: "(GMT +6:00) Almaty, Dhaka, Colombo",
        value: "+06:00",
        timezone: "Asia/Almaty",
      },
      {
        name: "(GMT +6:30) Yangon, Mandalay",
        value: "+06:50",
        timezone: "Asia/Yangon",
      },
      {
        name: "(GMT +7:00) Bangkok, Hanoi, Jakarta",
        value: "+07:00",
        timezone: "Asia/Bangkok",
      },
      {
        name: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong",
        value: "+08:00",
        timezone: "Asia/Singapore",
      },
      {
        name: "(GMT +8:45) Eucla",
        value: "+08:75",
        timezone: "Australia/Eucla",
      },
      {
        name: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk",
        value: "+09:00",
        timezone: "Asia/Tokyo",
      },
      {
        name: "(GMT +9:30) Adelaide, Darwin",
        value: "+09:50",
        timezone: "Australia/Adelaide",
      },
      {
        name: "(GMT +10:00) Eastern Australia, Guam, Vladivostok",
        value: "+10:00",
        timezone: "Pacific/Guam",
      },
      {
        name: "(GMT +10:30) Lord Howe Island",
        value: "+10:50",
        timezone: "Australia/Lord_Howe",
      },
      {
        name: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia",
        value: "+11:00",
        timezone: "Pacific/Guadalcanal",
      },
      {
        name: "(GMT +11:00) Norfolk Island",
        value: "+11:50",
        timezone: "Pacific/Norfolk",
      },
      {
        name: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka",
        value: "+12:00",
        timezone: "Pacific/Auckland",
      },
    ],
    zoneFromArray: "America/Los_Angeles",
  });
  const [selectedDate, setSelectedDate] = useState(null);
  useEffect(() => {
    const fetch = async () => {
      getScheduleList();
      // $(".fc-toolbar h2").hide();
      let event = { start: moment().format("YYYY-MM-DD") };
      handleSelectdate(event);
      await getMonthlybusinessHours();
      await getAppointmentType();
      await getTransfareeData();
    };
    fetch();
  }, []);
  useEffect(() => {
    const fetch = async () => {};
    fetch();
  }, []);
  const getMonthlybusinessHours = async () => {
    //await setState((prev)=>({...prev, businessHoursLoader: true });
    let userInfo = await ThirdParty.getLoginInfo({});
    let calendarApi = calendarComponentRef.current.getApi();

    let fromDate = moment(calendarApi.view.currentStart).format("MM-DD-YYYY");
    let toDate = moment(fromDate, "MM-DD-YYYY")
      .add(6, "month")
      .format("MM-DD-YYYY");

    let businessHoursObject = {
      consultantUId: userInfo.consultantUId,
      fromDate: fromDate,
      toDate: toDate,
      isconsultant: false,
    };

    let getbusinessHours = await ApiServices.GetConsultantAvaliability(
      businessHoursObject
    );
    if (getbusinessHours.error == 0) {
      let businessHours_result = getbusinessHours.message.preferencesList;
      let businessHours = [];
      let event = [];
      let count = 0;
      let cuurentDate = parseInt(moment(new Date()).format("YYYYMMDD"));

      _.forEach(businessHours_result, async (Bhours) => {
        let BusinessDate = parseInt(
          moment(Bhours.availabledate).format("YYYYMMDD")
        );
        let availabledate = moment(Bhours.availabledate).format("YYYY-MM-DD");
        let startDate = parseInt(
          moment(new Date(Bhours.availabledate)).format("YYYYMMDDHHMM")
        );
        let startTime = new Date(
          availabledate + " " + Bhours.availablefromtime
        );
        let endTime = new Date(availabledate + " " + Bhours.availabletotime);
        // console.log(startTime, "event", endTime);
        if (cuurentDate < BusinessDate) {
          if (
            Bhours.availablefromtime != null &&
            Bhours.availabletotime != null &&
            startTime < endTime
          ) {
            let NewHours = {
              startTime: moment(Bhours.availablefromtime, ["h:mm A"]).format(
                "HH:mm"
              ),
              endTime: moment(Bhours.availabletotime, ["h:mm A"]).format(
                "HH:mm"
              ),
              daysOfWeek: [moment(Bhours.availabledate).day()],
              availabledateStart: new Date(
                moment(Bhours.availabledate).format("MM-DD-YYYY") +
                  " " +
                  Bhours.availablefromtime
              ),
              availabledateEnd: new Date(
                moment(Bhours.availabledate).format("MM-DD-YYYY") +
                  " " +
                  Bhours.availabletotime
              ),
            };
            businessHours.push(
              moment(Bhours.availabledate).format("YYYY/MM/DD")
            );

            Bhours.start = availabledate;
            Bhours.end = availabledate;
            Bhours.title = "title display";
            Bhours.id = count;
            Bhours.display = "background";
            Bhours.backgroundColor = "black";
          }
          // event.push(Bhours);
          // state.events.push(Bhours);
        }
        count++;
      });
      // console.log(businessHours, "businessHours", _.uniq(businessHours), event);
      // await state.events.push(event);
      await setState((prev) => ({
        ...prev,
        MonthlybusinessHours: _.uniq(businessHours),
        // events: [...prev.events, ...event],
        // events: event,
      }));
    }
    // console.log(calendarComponentRef.current, "9009");
  };

  const sendEmail = async () => {
    await setState((prev) => ({ ...prev, succesMsg: "" }));
    let getSessionData = await ThirdParty.getLoginInfo({});
    let data = {
      email: state.EmailId,
      title: state.Title,
      pickupLocation: state.pickupLocation,
      notes: state.serviceNote,
      startTime: state.startTime,
      endTime: state.endTime,
      popupDate: state.popupDate,
      subject:
        state.updateAppointmentDetails == true
          ? "Updated appointment Details"
          : "Appointment Details",
      consultantName: state.consultantName,
      isTransfareeSchedule: true,
      timezone: state.timezone,
      consultantemailid: state.consultantemail,
      zone: state.zoneFromArray,
      calStart:
        moment(state.popupDate).format("YYYYMMDD") +
        "T" +
        moment(state.startTime, ["h:mm A"]).format("HHmm") +
        "00",
      calEnd:
        moment(state.popupDate).format("YYYYMMDD") +
        "T" +
        moment(state.endTime, ["h:mm A"]).format("HHmm") +
        "00",
      cc: getSessionData.supplieremailId,
      servicetype: state.servicetype,
    };
    // console.log(data, "data");
    let sendSms = await ThirdParty.sendEmail(data);
    console.log(sendSms, "sendSms");
    if (sendSms.response == true) {
      setState((prev) => ({
        ...prev,
        updateAppointmentDetails: false,
        succesMsg: "Mail has been sent successfully!",
      }));
      await getScheduleList();
      await getTransfareeData();
      await getbusinessHours();
      await getMonthlybusinessHours();
    } else {
      setState((prev) => ({
        ...prev,
        updateAppointmentDetails: false,
        confirmError: "Please try again!",
      }));
    }
  };
  const sendSms = async () => {
    try {
      await setState((prev) => ({ ...prev, succesMsg: "" }));
      if (state.PhoneNumber != "" && state.PhoneNumber != undefined) {
        let data = {
          phoneNo:
            "+" +
            state.countrycode.replace("+", "") +
            state.PhoneNumber.replace(" ", ""),
          type: 1,
          subject:
            "Your Appointment has been confirmed on " +
            state.popupDate +
            " " +
            state.startTime +
            "-" +
            state.endTime,
        };

        // console.log(data);
        let sendSms = await ThirdParty.sendSms(data);
        if (sendSms.response == true) {
          await setState((prev) => ({
            ...prev,
            succesMsg: "Sms has been sent successfully!",
          }));
        } else {
          await setState((prev) => ({
            ...prev,
            confirmError: "Please try again!",
          }));
        }
      } else {
        await setState((prev) => ({
          ...prev,
          confirmError: "Please enter the phone number in the consultant side",
        }));
      }
    } catch (error) {
      console.log(error, "error");
    }

    // console.log(sendSms);
  };

  const getbusinessHours = async (selectedDate) => {
    await setState((prev) => ({
      ...prev,
      businessHoursLoader: true,
      availableLoader: false,
    }));
    let userInfo = await ThirdParty.getLoginInfo({});
    let fromDate = moment(selectedDate).format("MM-DD-YYYY");
    let toDate = moment(fromDate, "MM-DD-YYYY")
      .add(6, "days")
      .format("MM-DD-YYYY");
    let businessHoursObject = {
      consultantUId: userInfo.consultantUId,
      fromDate: fromDate,
      toDate: fromDate,
      isconsultant: false,
    };

    let getbusinessHours = await ApiServices.GetConsultantAvaliability(
      businessHoursObject
    );
    // console.log(getbusinessHours, "getbusinessHours");
    if (getbusinessHours.error == 0) {
      let businessHours_result = getbusinessHours.message.preferencesList;
      let businessHours = [];
      let cuurentDate = parseInt(moment(new Date()).format("YYYYMMDD"));

      _.forEach(businessHours_result, async (Bhours) => {
        // console.log(Bhours.availabledate, "Bhours.availabledate");
        let BusinessDate = parseInt(
          moment(Bhours.availabledate).format("YYYYMMDD")
        );

        let availabledate = moment(Bhours.availabledate).format("YYYY-MM-DD");
        let startTime = new Date(
          availabledate + " " + Bhours.availablefromtime
        );
        let endTime = new Date(availabledate + " " + Bhours.availabletotime);

        if (cuurentDate < BusinessDate) {
          if (
            Bhours.availablefromtime != null &&
            Bhours.availabletotime != null &&
            startTime < endTime
          ) {
            let NewHours = {
              startTime: moment(Bhours.availablefromtime, ["h:mm A"]).format(
                "HH:mm"
              ),
              endTime: moment(Bhours.availabletotime, ["h:mm A"]).format(
                "HH:mm"
              ),
              daysOfWeek: [moment(Bhours.availabledate).day()],
              availabledateStart: moment(
                moment(Bhours.availabledate).format("YYYY-MM-DD") +
                  " " +
                  Bhours.availablefromtime,
                "YYYY-MM-DD HH:mm"
              ).toDate(),
              availabledateEnd: moment(
                moment(Bhours.availabledate).format("YYYY-MM-DD") +
                  " " +
                  Bhours.availabletotime,
                "YYYY-MM-DD HH:mm"
              ).toDate(),
            };
            businessHours.push(NewHours);
          }
        }
      });
      let businessHours2 = businessHours.sort(function (a, b) {
        var dateA = new Date(a.availabledateStart),
          dateB = new Date(b.availabledateStart);
        return dateA - dateB;
      });

      await setState((prev) => ({
        ...prev,
        businessHours: businessHours2,
        businessHoursLoader: false,
        availableLoader: true,
      }));
    }
  };
  // const getScheduleList = async () => {
  //   try {
  //     // Fetch user information and update state
  //     let userInfo = await ThirdParty.getLoginInfo({});
  //     setState((prev) => ({
  //       ...prev,
  //       consultantName: userInfo.consultantname,
  //       businessHoursLoader: true,
  //     }));

  //     // Prepare the request for schedules
  //     let getScheduleObject = {
  //       transfereeuniqueid: userInfo.userUniqueId,
  //     };

  //     // Fetch business hours if needed
  //     await getbusinessHours();

  //     // Fetch schedules
  //     let schedules = await ApiServices.GetTransfereeSchedules(
  //       getScheduleObject
  //     );

  //     // Process and transform schedule data
  //     let events = await Promise.all(
  //       schedules.message.scheduleslist.map(async (localEvent) => {
  //         let EditMoveDate = moment(localEvent.scheduledate).format(
  //           "YYYY-MM-DD"
  //         );
  //         let serviceTitle = "";

  //         if (localEvent.scheduleServicesList.length > 0) {
  //           serviceTitle = localEvent.scheduleServicesList
  //             .map((service) => service.ServiceName)
  //             .join(", ");
  //         }

  //         let currentDate = parseInt(moment().format("YYYYMMDDHHmm"));
  //         let startDate = parseInt(
  //           moment(
  //             `${EditMoveDate} ${localEvent.schedulefromtime}`,
  //             "YYYY-MM-DD h:mm A"
  //           ).format("YYYYMMDDHHmm")
  //         );

  //         return {
  //           title: serviceTitle || localEvent.servicename,
  //           start: new Date(`${EditMoveDate} ${localEvent.schedulefromtime}`),
  //           end: new Date(`${EditMoveDate} ${localEvent.scheduletotime}`),
  //           backgroundColor: currentDate <= startDate ? "green" : "#808080",
  //           borderColor: "black", // Optional
  //           textColor: "white", // Optional
  //           serviceDrodown: localEvent.packageserviceuniqueid,
  //           pickuplocation: localEvent.pickuplocation,
  //           serviceNote: localEvent.serviceNote,
  //           type: "guest",
  //           id: localEvent.scheduleuniqueId,
  //           display: "background",
  //         };
  //       })
  //     );

  //     // Remove duplicates based on `scheduledate` if necessary
  //     events = _.uniqBy(events, "id"); // Use 'id' to ensure uniqueness

  //     // Update state with events and finish loading
  //     setState((prev) => ({
  //       ...prev,
  //       events: events,
  //       businessHoursLoader: false,
  //     }));

  //     console.log(events, "events");
  //   } catch (error) {
  //     console.error("Error fetching or processing schedules:", error);
  //     setState((prev) => ({
  //       ...prev,
  //       businessHoursLoader: false,
  //     }));
  //   }
  // };
  const getScheduleList = async () => {
    let userInfo = await ThirdParty.getLoginInfo({});
    await setState((prev) => ({
      ...prev,
      consultantName: userInfo.consultantname,
      businessHoursLoader: true,
    }));
    let getScheduleObject = {
      transfereeuniqueid: userInfo.userUniqueId,
    };
    await getbusinessHours();

    let schedules = await ApiServices.GetTransfereeSchedules(getScheduleObject);
    // console.log(schedules, "1111schedules");
    let events = [];
    // let _this = this;
    _.forEach(schedules.message.scheduleslist, async (localEvent) => {
      let EditMoveDate = moment(localEvent.scheduledate).format("YYYY-MM-DD");
      // console.log(
      //   EditMoveDate,
      //   "EditMoveDate",
      //   new Date(localEvent.scheduledate)
      // );

      let serviceTitle = "";
      if (localEvent.scheduleServicesList.length > 0) {
        _.forEach(localEvent.scheduleServicesList, async (service) => {
          serviceTitle = service.ServiceName + "," + serviceTitle;
        });
      }
      let cuurentDate = parseInt(moment(new Date()).format("YYYYMMDDHHMM"));
      let startDate = parseInt(
        moment(
          new Date(EditMoveDate + " " + localEvent.schedulefromtime)
        ).format("YYYYMMDDHHMM")
      );

      localEvent.started = new Date(
        EditMoveDate + " " + localEvent.schedulefromtime
      );
      localEvent.ended = new Date(
        EditMoveDate + " " + localEvent.scheduletotime
      );
      localEvent.start = EditMoveDate;
      localEvent.end = EditMoveDate;
      (localEvent.title =
        serviceTitle != ""
          ? serviceTitle.replace(/,\s*$/, "")
          : localEvent.servicename),
        (localEvent.serviceDrodown = localEvent.packageserviceuniqueid),
        (localEvent.pickuplocation = localEvent.pickuplocation),
        (localEvent.serviceNote = localEvent.serviceNote),
        (localEvent.type = "guest");
      localEvent.scheduleuniqueId = localEvent.scheduleuniqueId;
      localEvent.id = localEvent.scheduleuniqueId;
      localEvent.display = "background";
      localEvent.backgroundColor =
        cuurentDate <= startDate ? "#1967b3" : "#7f7f7f";

      events.push(localEvent);
      // state.events.push(localEvent);
    });
    // console.log(events, "events", state.events);

    // await state.events.push(events);
    await setState((prev) => ({
      ...prev,
      events: [...prev.events, ...events],
      // events: events,
      businessHoursLoader: false,
    }));
  };
  const getAppointmentType = async () => {
    let userInfo = await ThirdParty.getLoginInfo({});
    let subscriptioncode = JSON.parse(
      localStorage.getItem("loginSubscribeDetails")
    ).subscriptioncode;
    await setState((prev) => ({
      ...prev,
      EmailId: userInfo.EmailId,
      PhoneNumber: userInfo.PhoneNumber,
      countrycode: userInfo.countrycode,
      subscriptioncode: subscriptioncode,
      scheduleType: subscriptioncode == "Explorer" ? "Virtual" : "In Person",
      typesofSchedules:
        subscriptioncode == "Explorer" ? ["Virtual"] : ["In Person", "Virtual"],
    }));
  };

  const handleSelect = async (e, row) => {
    //await getTimeStamps();
    // console.log(row, "row", state.accesstodate);
    await setState((prev) => ({
      ...prev,
      packageserviceuniqueid: [],
      showServiceType: false,
      isConfirmed: false,
      succesMsg: "",
      servicetype: "",
      updateAppointmentDetails: false,
      checkTransfareeHours: "",
      propertyListIds: [],
      showRestriction: true,
    }));
    let result = await filterEvents(
      row.availabledateStart,
      row.availabledateEnd
    );

    let date1Access = new Date(
      moment(row.availabledateStart).format("MM/DD/YYYY")
    );
    let date2Access = new Date(moment(state.accesstodate).format("MM/DD/YYYY"));
    let timeDiffAccess = date2Access - date1Access;
    let diffDaysAccess = Math.ceil(timeDiffAccess / (1000 * 60 * 60 * 24));

    // console.log(date1Access, date2Access, diffDaysAccess);
    if (diffDaysAccess < 0) {
      $("document").ready(function () {
        $("#appointmentPopUp").modal("show");
      });
      await setState((prev) => ({
        ...prev,
        Modalerror: true,
        ErrorMsg:
          "Your access to ReloNavigator will expire on " +
          (state.accesstodate != null && state.accesstodate != ""
            ? moment(state.accesstodate).format("MM/DD/YYYY")
            : moment(new Date()).format("MM/DD/YYYY")) +
          ". Please schedule the appointment before your access end date.",
        showRestriction: false,
        modalPopUp: true,
      }));
    } else if (result.length == 0) {
      let date1 = new Date(row.availabledateStart);
      let date2 = new Date(row.availabledateEnd);
      // console.log(date1, "date1", date2);
      let timeDiff = Math.abs(date2.getTime() - date1.getTime());
      // Calculate total minutes
      let totalMinutes = Math.floor(timeDiff / (1000 * 60));
      // Get the hours and minutes
      let hours = Math.floor(totalMinutes / 60);
      let minutes = totalMinutes % 60;
      let totalHours = `${hours} hours and ${minutes} minutes`;
      // console.log(totalHours, "totalHours");
      let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

      let startTime = moment(date1).format("h:mm A")
        ? moment(date1).format("h:mm A").split(":")[0] <= 9
          ? "0" + moment(date1).format("h:mm A")
          : moment(date1).format("h:mm A")
        : moment(date1).format("h:mm A");
      let endTime = moment(date2).format("h:mm A")
        ? moment(date2).format("h:mm A").split(":")[0] <= 9
          ? "0" + moment(date2).format("h:mm A")
          : moment(date2).format("h:mm A")
        : moment(date2).format("h:mm A");
      let dayName = date1.getDay();
      let popupDate = moment(date1).format("LL");
      let periodsInADay = moment.duration(8, "hours").as("minutes");
      let timeLabels = [];
      let timessslot = [];
      let startTimeMoment = moment(startTime, "h:mm");
      for (let i = 0; i <= periodsInADay; i += 5) {
        startTimeMoment.add(i === 0 ? 0 : 15, "minutes");
        if (
          startTimeMoment.format("HHmm") >= moment(date1).format("HHmm") &&
          startTimeMoment.format("HHmm") <= moment(date2).format("HHmm")
        )
          timeLabels.push(startTimeMoment.format("hh:mm A"));
      }
      for (let j = 0; j < timeLabels.length; j++) {
        timessslot.push(timeLabels[j]);
        if (
          timeLabels[j] ==
          moment(row.availabledateEnd, "h:mm").format("hh:mm A")
        ) {
          break;
        }
      }
      await setState((prev) => ({
        ...prev,
        timessslot: timessslot,
        succesMsg: "",
        confirmError: "",
        isConfirmed: false,
        showProperties: false,
        isConfirmed: false,
        pickupLocation: "",
        Modalerror: false,
        packageserviceuniqueid: [],
        ErrorMsg: "",
        serviceDrodown: "",
        serviceNote: "",
        start: row.availabledateStart,
        end: row.availabledateEnd,
        diffTime: timeDiff / (1000 * 3600),
        // diffTime:totalHours,
        startTime: startTime,
        endTime: endTime,
        dayName: dayName,
        popupDate: popupDate,
      }));
      let checkTransfareeHours =
        Number(timeDiff / (1000 * 3600)) +
        Number(state.transfereescheduledhours);

      if (checkTransfareeHours > state.transfereehours) {
        await setState((prev) => ({
          ...prev,
          checkTransfareeHours: checkTransfareeHours,
          Modalerror: true,
          ErrorMsg: "Allotted Transferee hours got exceeded!",
        }));
      }
      if (diffDays > 0) {
        $("document").ready(function () {
          $("#appointmentPopUp").modal("show");
        });
        setState((prev) => ({ ...prev, modalPopUp: true }));

        if (state.transfereehours - state.transfereescheduledhours < 1) {
          await setState((prev) => ({
            ...prev,
            Modalerror: true,
            ErrorMsg: 1,
            showRestriction: false,
          }));
        } else {
          if (timeDiff / (1000 * 3600) < 1) {
            await setState((prev) => ({
              ...prev,
              Modalerror: true,
              ErrorMsg:
                "The minimum duration for an appointment is one hour. Please schedule appointments for at least an hour with your consultant.",
              showRestriction: false,
            }));
          }
        }
      }
    } else {
      $("document").ready(function () {
        $("#appointmentPopUp").modal("show");
      });
      await setState((prev) => ({
        ...prev,
        Modalerror: true,
        ErrorMsg:
          "Another event exists during this time slot. Please choose another time slot.",
        showRestriction: false,
        modalPopUp: true,
      }));
    }
  };

  const handleDateChange = async (editDate) => {
    await setState((prev) => ({ ...prev, EditMoveDate: new Date(editDate) }));
  };
  // const moveEdit = async (e, row) => {
  //   await setState((prev) => ({
  //     ...prev,
  //     serviceNote: row.servicenotes == null ? "" : row.servicenotes,
  //   }));
  //   let cuurentDate = parseInt(moment(new Date()).format("YYYYMMDD"));
  //   let startDate = parseInt(moment(row.scheduledate).format("YYYYMMDD"));
  //   let startDatewe = moment(row.scheduledate).format("YYYY-MM-DD");
  //   const dateTime = moment(
  //     `${startDatewe} ${row.start}`,
  //     "YYYY-MM-DD h:mm A"
  //   ).format();
  //   const dateTime2 = moment(
  //     `${startDatewe} ${row.end}`,
  //     "YYYY-MM-DD h:mm A"
  //   ).format();
  //   let date1 =
  //     new Date(row.start) == "Invalid Date"
  //       ? new Date(dateTime)
  //       : new Date(row.start);
  //   let date2 =
  //     new Date(row.end) == "Invalid Date"
  //       ? new Date(dateTime2)
  //       : new Date(row.end);
  //   let timeDiff = Math.abs(date2.getTime() - date1.getTime());
  //   let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  //   let startTime = moment(row.start, ["h:mm A"]).format("h:mm A");
  //   let endTime = moment(row.end, ["h:mm A"]).format("h:mm A");
  //   let dayName = date1.getDay();
  //   let popupDate = moment(date1).format("LL");
  //   row.start = moment(row.start, ["h:mm A"]).format("h:mm A");
  //   row.end = moment(row.end, ["h:mm A"]).format("h:mm A");
  //   if (row.type == "guest" && cuurentDate < startDate) {
  //     await localStorage.setItem("rowDetails", JSON.stringify(row));
  //     await setState((prev) => ({
  //       ...prev,
  //       serviceNote: row.servicenotes == null ? "" : row.servicenotes,
  //       pickupLocation: row.pickuplocation,
  //       itineraryPropertiesList: row.itineraryPropertiesList,
  //       moveModalerror: false,
  //       ErrorMsgExitsMsg: "",
  //       EditEvent: row.title,
  //       EditMoveDate: date1,
  //       eventDetails: row,
  //       startTimeId: row.schedulefromtime,
  //       endTimeId: row.scheduletotime,
  //     }));
  //     getTimeStamps();
  //     setModalOpen(true);
  //     // $("document").ready(function () {
  //     //   $("#moveDelete").modal("show");
  //     // });
  //   } else if (row.type == "guest" && cuurentDate >= startDate) {
  //     await setState((prev) => ({
  //       ...prev,
  //       itineraryPropertiesList: row.itineraryPropertiesList,
  //       pickupLocation: row.pickuplocation,
  //       serviceNote: row.servicenotes,
  //       diffTime: timeDiff / (1000 * 3600),
  //       startTime: startTime,
  //       endTime: endTime,
  //       dayName: dayName,
  //       popupDate: popupDate,
  //       moveModalerror: false,
  //       ErrorMsgExitsMsg: "",
  //       EditEvent: row.title,
  //       EditMoveDate: date1,
  //       eventDetails: row,
  //     }));
  //     // $("document").ready(function () {
  //     //   $("#showDetails").modal("show");
  //     // });
  //     setModalOpen(true);
  //   }
  //   let totalone = [];
  //   totalone.push({
  //     startTime: moment(date1).format("HH:mm"),
  //     endTime: moment(date2).format("HH:mm"),
  //     availabledateStart: date1,
  //     availabledateEnd: date2,
  //     editable: true,
  //   });
  //   let two = state.businessHours.filter((d) => {
  //     return d.startTime == totalone[0].endTime;
  //   });
  //   let three = state.businessHours.filter((d) => {
  //     return d.endTime == totalone[0].startTime;
  //   });
  //   two.length > 0 ? totalone.push(two[0]) : "";
  //   three.length > 0 ? totalone.push(three[0]) : "";
  //   let high = totalone.sort(
  //     (a, b) =>
  //       new Date(b.availabledateEnd).getTime() -
  //       new Date(a.availabledateEnd).getTime()
  //   )[0];
  //   let lowww = totalone
  //     .sort(
  //       (a, b) =>
  //         new Date(b.availabledateStart).getTime() -
  //         new Date(a.availabledateStart).getTime()
  //     )
  //     .reverse()[0];
  //   let set = [];
  //   set.push({
  //     startTime: moment(lowww.availabledateStart).format("HH:mm"),
  //     endTime: moment(high.availabledateEnd).format("HH:mm"),
  //     availabledateStart: lowww.availabledateStart,
  //     availabledateEnd: high.availabledateEnd,
  //   });
  //   await setState((prev) => ({ ...prev, eventset: set }));
  // };

  const moveEdit = async (e, row) => {
    //setIsModalOpen(true);
    await setState((prev) => ({
      ...prev,
      serviceNote: row.servicenotes == null ? "" : row.servicenotes,
    }));
    let cuurentDate = parseInt(moment(new Date()).format("YYYYMMDD"));
    let startDate = parseInt(moment(row.scheduledate).format("YYYYMMDD"));
    let startDatewe = moment(row.scheduledate).format("YYYY-MM-DD");
    const dateTime = moment(
      `${startDatewe} ${row.start}`,
      "YYYY-MM-DD h:mm A"
    ).format();
    const dateTime2 = moment(
      `${startDatewe} ${row.end}`,
      "YYYY-MM-DD h:mm A"
    ).format();
    let date1 =
      new Date(row.start) == "Invalid Date"
        ? new Date(dateTime)
        : new Date(row.start);
    let date2 =
      new Date(row.end) == "Invalid Date"
        ? new Date(dateTime2)
        : new Date(row.end);
    let timeDiff = Math.abs(date2.getTime() - date1.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    let startTime = moment(row.start, ["h:mm A"]).format("h:mm A");
    let endTime = moment(row.end, ["h:mm A"]).format("h:mm A");
    let dayName = date1.getDay();
    let popupDate = moment(date1).format("LL");
    row.start = moment(row.start, ["h:mm A"]).format("h:mm A");
    row.end = moment(row.end, ["h:mm A"]).format("h:mm A");
    if (row.type == "guest" && cuurentDate < startDate) {
      await localStorage.setItem("rowDetails", JSON.stringify(row));
      await setState((prev) => ({
        ...prev,
        serviceNote: row.servicenotes == null ? "" : row.servicenotes,
        pickupLocation: row.pickuplocation,
        itineraryPropertiesList: row.itineraryPropertiesList,
        moveModalerror: false,
        ErrorMsgExitsMsg: "",
        EditEvent: row.title,
        EditMoveDate: date1,
        eventDetails: row,
        startTimeId: row.schedulefromtime,
        endTimeId: row.scheduletotime,
      }));
      getTimeStamps();
      setIsModalOpen(true);
      // $("document").ready(function () {
      //   $("#moveDelete").modal("show");
      // });
    } else if (row.type == "guest" && cuurentDate >= startDate) {
      await setState((prev) => ({
        ...prev,
        itineraryPropertiesList: row.itineraryPropertiesList,
        pickupLocation: row.pickuplocation,
        serviceNote: row.servicenotes,
        diffTime: timeDiff / (1000 * 3600),
        startTime: startTime,
        endTime: endTime,
        dayName: dayName,
        popupDate: popupDate,
        moveModalerror: false,
        ErrorMsgExitsMsg: "",
        EditEvent: row.title,
        EditMoveDate: date1,
        eventDetails: row,
      }));
      // $("document").ready(function () {
      //   $("#showDetails").modal("show");
      // });
      setModalOpen(true);
    }
    let totalone = [];
    totalone.push({
      startTime: moment(date1).format("HH:mm"),
      endTime: moment(date2).format("HH:mm"),
      availabledateStart: date1,
      availabledateEnd: date2,
      editable: true,
    });
    let two = state.businessHours.filter((d) => {
      return d.startTime == totalone[0].endTime;
    });
    let three = state.businessHours.filter((d) => {
      return d.endTime == totalone[0].startTime;
    });
    two.length > 0 ? totalone.push(two[0]) : "";
    three.length > 0 ? totalone.push(three[0]) : "";
    let high = totalone.sort(
      (a, b) =>
        new Date(b.availabledateEnd).getTime() -
        new Date(a.availabledateEnd).getTime()
    )[0];
    let lowww = totalone
      .sort(
        (a, b) =>
          new Date(b.availabledateStart).getTime() -
          new Date(a.availabledateStart).getTime()
      )
      .reverse()[0];
    let set = [];
    set.push({
      startTime: moment(lowww.availabledateStart).format("HH:mm"),
      endTime: moment(high.availabledateEnd).format("HH:mm"),
      availabledateStart: lowww.availabledateStart,
      availabledateEnd: high.availabledateEnd,
    });
    await setState((prev) => ({ ...prev, eventset: set }));
  };
  const inputHandler = async (inputName, event) => {
    event.persist();
    let inputVal = event.target.value;
    let inputObject = {};
    inputObject[inputName] = inputVal;
    setState((prev) => ({ ...prev, [inputName]: inputVal }));
  };
  const deleteEventConfirm = () => {
    let eventDetails = state.eventDetails;
    confirmAlert({
      title: "",
      message: "Are you sure you want to cancel the appointment?",
      buttons: [
        {
          label: "Yes",
          onClick: deleteEvent(eventDetails),
        },
        {
          label: "No",
          onClick: () => "",
        },
      ],
    });
  };
  const deleteEvent = async (eventDetails) => {
    let indexEvent = _.findIndex(state.events, function (o) {
      return o.scheduleuniqueId == eventDetails.id;
    });

    let events = Object.assign([], state.events);
    events.splice(indexEvent, 1);
    getScheduleList();
    getbusinessHours();
    getTransfareeData();
    // await setState((prev)=>({...prev, events});
    let event = {
      start: moment(eventDetails.scheduledate).format("YYYY-MM-DD"),
    };
    handleSelectdate(event);
    $("document").ready(function () {
      $("#moveDelete").modal("hide");
    });
    if (eventDetails.id != undefined) {
      let delScheduleObject = {
        scheduleuniqueId: eventDetails.id,
        IsDelete: true,
      };
      let Title = "";

      _.each(eventDetails.scheduleServicesList, async (serviceId, index) => {
        Title = serviceId.ServiceName + "," + Title;

        await setState((prev) => ({
          ...prev,
          Title: Title.replace(/,([^,]*)$/, "$1"),
        }));
      });

      //console.log(eventDetails._def.extendedProps.scheduleServicesList, "serviceId.ServiceName")
      let delschedules = await ApiServices.UpdateTransfereeSchedule(
        delScheduleObject
      );
      let getSessionData = await ThirdParty.getLoginInfo({});
      if (delschedules.error == 0) {
        let data = {
          email: eventDetails.emailid,
          title: Title,
          pickupLocation: eventDetails.pickuplocation,
          notes: eventDetails.servicenotes,
          startTime: eventDetails.schedulefromtime,
          endTime: eventDetails.scheduletotime,
          popupDate: moment(eventDetails.scheduledate).format("LL"),
          subject: "Appointment is Cancelled",
          appointmenttype: state.scheduleType,
          consultantemailid: eventDetails.consultantemailid,
          consultantName:
            eventDetails.consultantfirstname +
            " " +
            eventDetails.consultantlastname,
          theDelete: true,
          timezone: state.timezone,
          cc: getSessionData.supplieremailId,
        };

        let sendSms = await ThirdParty.sendEmail(data);
        await getMonthlybusinessHours();
      }
    }
  };
  const filterEvents = async (start, end) => {
    let result = state.events.filter((d) => {
      if (
        (d.started.getTime() >= start.getTime() &&
          d.started.getTime() < end.getTime()) ||
        (d.ended.getTime() >= start.getTime() + 1 &&
          d.ended.getTime() <= end.getTime() + 1) ||
        (d.started.getTime() <= start.getTime() &&
          d.ended.getTime() >= end.getTime())
      ) {
        return d;
      }
    });
    return result;
  };
  const filterEventsBusinessHours = async (start, end, EditMoveDate) => {
    let BusinessHours = Object.assign([], state.businessHours);
    let ObjectBusinessHours = {
      startTime: moment(state.eventDetails.startTime, ["h:mm A"]).format(
        "HH:mm"
      ),
      endTime: moment(state.eventDetails.endTime, ["h:mm A"]).format("HH:mm"),
      daysOfWeek: [moment(state.eventDetails.start).day()],
      availabledateStart: new Date(
        moment(state.eventDetails.start).format("MM-DD-YYYY") +
          " " +
          moment(state.eventDetails.startTime, ["h:mm A"]).format("HH:mm")
      ),
      availabledateEnd: new Date(
        moment(state.eventDetails.start).format("MM-DD-YYYY") +
          " " +
          moment(state.eventDetails.endTime, ["h:mm A"]).format("HH:mm")
      ),
    };
    BusinessHours.push(ObjectBusinessHours);
    let sortedDates = BusinessHours.sort(function (a, b) {
      var dateA = new Date(a.availabledateStart),
        dateB = new Date(b.availabledateStart);
      return dateA - dateB;
    });

    let buss = state.eventset;
    let result = buss.filter((d) => {
      var format = "hh:mm:ss";

      var time = moment(start, ["h:mm A"]).format("HH:mm"),
        endTime = moment(end, ["h:mm A"]).format("HH:mm"),
        beforeTime = moment(d.startTime, "hh:mm"),
        afterTime = moment(d.endTime, "hh:mm");

      if (
        ((moment(time, "hh:mm").isBetween(beforeTime, afterTime) &&
          moment(endTime, "hh:mm").isBetween(beforeTime, afterTime)) ||
          (moment(time, "hh:mm").isSame(beforeTime) &&
            moment(endTime, "hh:mm").isSame(afterTime)) ||
          (moment(endTime, "hh:mm").isSame(afterTime) &&
            moment(time, "hh:mm").isAfter(beforeTime)) ||
          (moment(time, "hh:mm").isSame(beforeTime) &&
            moment(endTime, "hh:mm").isBefore(afterTime))) &&
        moment(d.availabledateStart).format("YYYY/MM/DD") == EditMoveDate
      ) {
        return d;
      }
    });
    return result;
  };
  const moveEvent = async () => {
    let eventDetails = state.eventDetails;
    let EditMoveDate = moment(state.EditMoveDate).format("YYYY/MM/DD");
    let startTime =
      new Date(eventDetails.start).getHours() +
      ":" +
      new Date(this).getMinutes();
    let endTime =
      new Date(eventDetails.end).getHours() +
      ":" +
      new Date(eventDetails.end).getMinutes();
    let start = new Date(EditMoveDate + " " + state.startTimeId);
    let end = new Date(EditMoveDate + " " + state.endTimeId);
    let result = await filterEvents(start, end);
    result = _.filter(result, function (o) {
      return o.scheduleuniqueId != eventDetails.scheduleuniqueId;
    });
    let resultBusinessHours = await filterEventsBusinessHours(
      state.startTimeId,
      state.endTimeId,
      EditMoveDate
    );
    let eventPreviousData = Object.assign([], state.events);
    let indexEvent = _.findIndex(state.events, function (o) {
      return o.scheduleuniqueId == eventDetails.id;
    });
    if (result.length > 0) {
      await setState((prev) => ({
        ...prev,
        moveModalerror: true,
        ErrorMsgExitsMsg:
          "Another event exists during this time slot. Please choose another time slot.",
      }));
    } else if (resultBusinessHours.length == 0) {
      await setState((prev) => ({
        ...prev,
        moveModalerror: true,
        ErrorMsgExitsMsg:
          "There is no availability. Please adjust the appointment time.",
      }));
    } else if (
      indexEvent >= 0 &&
      start != "Invalid Date" &&
      !state.moveModalerror &&
      state.ErrorMsgExitsMsg == ""
    ) {
      let newObject = Object.assign({}, eventPreviousData[indexEvent]);
      eventPreviousData.splice(indexEvent, 1);
      newObject.start = start;
      newObject.end = end;
      newObject.scheduledate = moment(EditMoveDate).format("MM/DD/YYYY");
      eventPreviousData.push(newObject);
      await setState((prev) => ({ ...prev, events: eventPreviousData }));

      let userInfo = await ThirdParty.getLoginInfo({});
      let moveEventObject = {
        scheduledate: moment(start).format("YYYY/MM/DD"),
        schedulefromtime: state.startTimeId,
        scheduletotime: state.endTimeId,
        transfereeuniqueid: userInfo.userUniqueId,
        scheduleuniqueId: eventDetails.id,
        pickuplocation: state.pickupLocation,
        servicenotes: state.serviceNote,
        IsDelete: false,
      };
      let Moveschedules = await ApiServices.UpdateTransfereeSchedule(
        moveEventObject
      );
      await getMonthlybusinessHours();
      $("document").ready(function () {
        $("#moveDelete").modal("hide");
      });
      let startDatewe = moment(start).format("YYYY-MM-DD");
      const dateTime = moment(
        `${startDatewe} ${state.startTimeId}`,
        "YYYY-MM-DD h:mm A"
      ).format();
      const dateTime2 = moment(
        `${startDatewe} ${state.endTimeId}`,
        "YYYY-MM-DD h:mm A"
      ).format();
      let date1 = new Date(dateTime);
      let date2 = new Date(dateTime2);
      let timeDiff = Math.abs(date2.getTime() - date1.getTime());
      let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      await setState((prev) => ({
        ...prev,
        EmailId: eventDetails.emailid,
        diffTime: timeDiff / (1000 * 3600),
        Title: eventDetails.title,
        scheduleType: "",
        popupDate: moment(start).format("LL"),
        startTime: state.startTimeId,
        endTime: state.endTimeId,
        pickupLocation: eventDetails.pickuplocation,
        serviceNote: state.serviceNote,
        dayName: new Date(start).getDay(),
        isConfirmed: true,
        countrycode: eventDetails.countrycode,
        updateAppointmentDetails: true,
        events: state.events,
      }));
      // console.log(eventDetails, "eventDetails");
      if (Moveschedules.error == 0) {
        getTransfareeData();
        let event = {
          start: moment(eventDetails.scheduledate).format("YYYY-MM-DD"),
        };
        handleSelectdate(event);
        $("document").ready(function () {
          $("#myModalService").modal("show");
        });
      }
    } else {
    }
  };

  const saveEvent = async () => {
    if (
      (state.pickupLocation2 != "" || state.scheduleType == "Virtual") &&
      state.serviceDrodown != "" &&
      state.diffTime >= 1
    ) {
      let userInfo = await ThirdParty.getLoginInfo({});
      let createEventObject = {
        scheduledate: moment(state.start).format("YYYY/MM/DD"),
        schedulefromtime: state.startTime,
        scheduletotime: state.endTime,
        transfereeuniqueid: userInfo.userUniqueId,
        packageserviceuniqueid: state.serviceDrodown,
        pickuplocation: state.pickupLocation,
        servicenotes: state.serviceNote,
        consultantUId: userInfo.consultantUId,
        appointmenttype: state.scheduleType,
        itinerary_properties: state.propertyListIds,
        servicetype: state.servicetype,
      };

      // console.log(createEventObject, "createEventObject");
      let swsheck = moment(state.start).format("YYYY/MM/DD");

      const dateTime = moment(
        `${swsheck} ${state.startTime}`,
        "YYYY-MM-DD h:mm A"
      ).format();
      const dateTime2 = moment(
        `${swsheck} ${state.endTime}`,
        "YYYY-MM-DD h:mm A"
      ).format();

      let date1 = new Date(dateTime);
      let date2 = new Date(dateTime2);
      let timeDiff = Math.abs(date2.getTime() - date1.getTime());
      let diffTime = timeDiff / (1000 * 3600);
      let checkTransfareeHours =
        Number(state.diffTime) + Number(state.transfereescheduledhours);
      await localStorage.setItem("events", JSON.stringify(state.events));

      if (checkTransfareeHours > state.transfereehours) {
        await setState((prev) => ({ ...prev, Modalerror: true, ErrorMsg: 1 }));
      } else {
        await localStorage.setItem("events", JSON.stringify(state.events));
        // console.log(createEventObject, "createEventObject");

        let createEvent = await ApiServices.CreateTransfereeSchedule(
          createEventObject
        );
        //props.checkBoxClick();
        if (createEvent.error == 0) {
          await setState((prev) => ({ ...prev, isConfirmed: true }));
          let event = { start: moment(state.start).format("YYYY-MM-DD") };
          await handleSelectdate(event);

          let data = {
            transfereeuid: userInfo.userUniqueId,
          };
          const GetTransfereeStatus = await ApiServices.GetTransfereeStatus(
            data
          );
          let sawc = [];
          if (GetTransfereeStatus.error == 0) {
            sawc = await _.filter(
              GetTransfereeStatus.message.noteslist,
              function (o) {
                return o.statusuid == "c7416ff9-bf31-41ed-9151-1b7e17449d66";
              }
            );
          }

          let StatusUpdateSendWelcome = {
            uniqueid:
              sawc.length == 0 ? "" : "c7416ff9-bf31-41ed-9151-1b7e17449d66",
            transfereeuid: userInfo.userUniqueId,
            statusuid: "c7416ff9-bf31-41ed-9151-1b7e17449d66",
            isactive: true,
          };
          const ManageTransfereeStatus =
            await ApiServices.ManageTransfereeStatus(StatusUpdateSendWelcome);

          await setState((prev) => ({
            ...prev,
            packageserviceuniqueid: [],
            pickupLocation2: "",
          }));
          await getScheduleList();
          await getTransfareeData();
          await getbusinessHours();
          await getMonthlybusinessHours();
        } else if (createEvent.error == 1) {
          await setState((prev) => ({
            ...prev,
            Modalerror: true,
            ErrorMsg: createEvent.message,
          }));
        }
      }
    } else if (state.diffTime < 1) {
      await setState((prev) => ({
        ...prev,
        Modalerror: true,
        ErrorMsg:
          "The minimum duration for an appointment is one hour. Please schedule appointments for at least an hour with your consultant.",
      }));
    } else if (state.checkTransfareeHours > state.transfereehours) {
      await setState((prev) => ({ ...prev, Modalerror: true, ErrorMsg: 1 }));
    } else {
      let ErrorMsgTxt = "";
      if (state.serviceDrodown == "") {
        ErrorMsgTxt = "Please select appointment type";
      } else if (
        state.pickupLocation2 == "" &&
        state.scheduleType == "In Person"
      ) {
        ErrorMsgTxt = "Please select pickup location from the dropdown";
      } else {
        ErrorMsgTxt = "Please enter valid event details";
      }

      await setState((prev) => ({
        ...prev,
        Modalerror: true,
        ErrorMsg: ErrorMsgTxt,
      }));
    }
  };
  const getTransfareeData = async () => {
    let userInfo = await ThirdParty.getLoginInfo({});
    // console.log(userInfo, "userInfo");
    let getTransfereeObj = {
      consultantuniqueid: userInfo.consultantUId,
      transfereeuniqueid: userInfo.userUniqueId,
    };
    let TransfereesList_Result = await ApiServices.GetConsultantTransferees(
      getTransfereeObj
    );
    // console.log(TransfereesList_Result, "TransfereesList_Result");
    let arr = _.filter(state.timezoneslist, function (o) {
      return o.name == TransfereesList_Result.message.userInfo.timezone;
    });
    let zoneResult = "";
    if (Array.isArray(arr) && arr.length > 0) {
      zoneResult = arr[0].timezone;
      await setState((prev) => ({ ...prev, zoneFromArray: zoneResult }));
    }
    await setState((prev) => ({
      ...prev,
      accesstodate: TransfereesList_Result.message.userInfo.accesstodate,
      consultantemail: TransfereesList_Result.message.userInfo.consultantemail,
      timezone: TransfereesList_Result.message.userInfo.timezone,
      consultantprofilepic:
        TransfereesList_Result.message.userInfo.consultantprofilepic,
      transfereescheduledhours:
        TransfereesList_Result.message.userInfo.transfereescheduledhours,
      transfereehours: TransfereesList_Result.message.userInfo.transfereehours,
      consultantname: TransfereesList_Result.message.userInfo.consultantname,
    }));
  };
  const getTimeStamps = async () => {
    let periodsInADay = moment.duration(8, "hours").as("minutes");

    let timeLabels = [];
    let startTimeMoment = moment("0:00", "h:mm");
    for (let i = 0; i <= periodsInADay; i += 5) {
      startTimeMoment.add(i === 0 ? 0 : 15, "minutes");
      timeLabels.push(startTimeMoment.format("hh:mm A"));
    }
    await setState((prev) => ({ ...prev, timeSlots: timeLabels }));
  };

  const onChangeTimeSlotEvent = async (type, value) => {
    if (type == "startTimeId") {
      await setState((prev) => ({ ...prev, startTimeId: value }));
    } else {
      await setState((prev) => ({ ...prev, endTimeId: value }));
    }
    let startTime = moment(state.startTimeId, "HH:mm A");
    let endTime = moment(state.endTimeId, "HH:mm A");
    if (endTime.diff(startTime, "hours") < 1) {
      await setState((prev) => ({
        ...prev,
        moveModalerror: true,
        ErrorMsgExitsMsg:
          "The minimum duration for an appointment is one hour. Please schedule appointments for at least an hour with your consultant.",
      }));
    } else {
      await setState((prev) => ({
        ...prev,
        moveModalerror: false,
        ErrorMsgExitsMsg: "",
        moveModalerror: false,
        ErrorMsgExitsMsg: "",
      }));
    }
  };
  const showMessage = async () => {
    $("#myModalService").modal("hide");
  };
  const handleSelectdate = async (event) => {
    // console.log(moment(event.start).format("YYYY-MM-DD"), "event");
    let selectedDate = moment(event.start).format("YYYY-MM-DD");
    await setState((prev) => ({
      ...prev,
      scheduleLoader: false,
      bookingslots: [],
      businessHoursLoader: true,
      businessHours: [],
      selectdate: moment(event.start).format("YYYY-MM-DD"),
    }));
    // console.log(state.selectdate, "heyy");
    let userInfo = await ThirdParty.getLoginInfo({});
    let getScheduleObject = {
      transfereeuniqueid: userInfo.userUniqueId,
    };

    let schedules = await ApiServices.GetTransfereeSchedules(getScheduleObject);
    let events = [];
    let _this = this;
    _.forEach(schedules.message.scheduleslist, async (localEvent) => {
      let EditMoveDate = moment(localEvent.scheduledate).format("YYYY/MM/DD");
      let serviceTitle = "";
      if (localEvent.scheduleServicesList.length > 0) {
        _.forEach(localEvent.scheduleServicesList, async (service) => {
          serviceTitle = service.ServiceName + "," + serviceTitle;
        });
      }
      let cuurentDate = parseInt(moment(new Date()).format("YYYYMMDDHHMM"));
      let startDate = parseInt(
        moment(
          new Date(EditMoveDate + " " + localEvent.schedulefromtime)
        ).format("YYYYMMDDHHMM")
      );

      localEvent.start = new Date(
        EditMoveDate + " " + localEvent.schedulefromtime
      );
      localEvent.end = new Date(EditMoveDate + " " + localEvent.scheduletotime);
      (localEvent.title =
        serviceTitle != ""
          ? serviceTitle.replace(/,\s*$/, "")
          : localEvent.servicename),
        (localEvent.serviceDrodown = localEvent.packageserviceuniqueid),
        (localEvent.pickuplocation = localEvent.pickuplocation),
        (localEvent.serviceNote = localEvent.serviceNote),
        (localEvent.type = "guest");
      localEvent.scheduleuniqueId = localEvent.scheduleuniqueId;
      localEvent.id = localEvent.scheduleuniqueId;
      localEvent.backgroundColor = cuurentDate < startDate ? "" : "#252525";

      events.push(localEvent);
    });
    await getbusinessHours(selectedDate);
    await getAppointmentType();
    let datess = events.filter((d) => {
      return (
        moment(d.start).format("YYYY/MM/DD") ==
        moment(selectedDate).format("YYYY/MM/DD")
      );
    });
    let datesss = state.businessHours.filter((d) => {
      return (
        moment(d.availabledateStart).format("YYYY/MM/DD") ==
        moment(event.start).format("YYYY/MM/DD")
      );
    });
    await setState((prev) => ({
      ...prev,
      scheduleLoader: true,
      bookingslots: datess,
      busidates: datesss,
      businessHoursLoader: false,
    }));
  };
  const handleDateSelect = (info) => {
    // console.log(info, "info");
    handleSelectdate(info);
    setSelectedDate(info.start);
  };

  const handleButtonClick = (action) => {
    let calendarApi = calendarComponentRef.current.getApi();
    getMonthlybusinessHours();
    switch (action) {
      case "prev":
        calendarApi.prev();
        break;
      case "next":
        calendarApi.next();
        break;
      case "today":
        calendarApi.today();
        break;
      default:
        break;
    }
  };
  const renderEventContent = (eventInfo) => {
    return <></>;
  };
  const dayCellClassNames = (date) => {
    // console.log(date, "date");

    const isSelected =
      state.selectdate &&
      moment(date.date).isSame(moment(state.selectdate), "day");
    return isSelected ? "selected" : "";
  };
  const dayCellDidMount = (info) => {
    const today = moment();
    const renderedDate = moment(info.date);
    const ispast = renderedDate.isBefore(today, "day");
    const isSelected =
      state.selectdate &&
      moment(info.date).isSame(moment(state.selectdate), "day");

    if (isSelected) {
      // Find the background element of the day cell
      const dayBgElement = info.el.querySelector(".fc-daygrid-day-bg");

      if (dayBgElement) {
        // Create a new div with the class 'fc-highlight'
        const highlightDiv = document.createElement("div");
        highlightDiv.className = "fc-highlight";

        // // Add any custom styles to the highlight div if needed
        // highlightDiv.style.left = "0";
        // highlightDiv.style.right = "0";
        // highlightDiv.style.position = "absolute";
        // highlightDiv.style.top = "0";
        // highlightDiv.style.bottom = "0";
        // highlightDiv.style.backgroundColor = "rgba(0, 135, 255, 0.1)"; // Adjust the color as needed

        // Append the div inside the day background element
        dayBgElement.appendChild(highlightDiv);
      }
    }
  };

  const handleEventDidMount = (info) => {
    // console.log(info, "info");
  };
  const handleDatesSet = (info) => {
    const dateMap = new Set(
      state.MonthlybusinessHours.map((date) =>
        moment(date).format("YYYY-MM-DD")
      )
    );

    // Loop through each day cell
    document.querySelectorAll(".fc-daygrid-day").forEach((cell) => {
      const dateStr = cell.getAttribute("data-date"); // This attribute should match your calendar's date attribute

      // Apply inline style to the selected date
      if (selectedDate && moment(dateStr).isSame(selectedDate, "day")) {
        cell.style.backgroundColor = "#2facf50";
        cell.style.borderRadius = "10px";
        // cell.style.color = "white";
      } else {
        cell.style.backgroundColor = "";
        cell.style.color = "";
      }
      if (dateStr && dateMap.has(dateStr)) {
        cell.classList.add("avaliableDay");
      } else {
        cell.classList.remove("avaliableDay");
      }
    });
    // console.log(dateMap, "dateMap", state.MonthlybusinessHours);

    // info.el.classList.add("activeDay");
  };

  useEffect(() => {
    if (calendarComponentRef.current) {
      // Access FullCalendar instance and update
      const calendarApi = calendarComponentRef.current.getApi();
      calendarApi.refetchEvents(); // Refresh events
    }
  }, [state.MonthlybusinessHours]);
  const initialDateStr = moment().format("YYYY-MM-DD");

  return (
    <div>
      <div className="calendar-view-page-schedule">
        <div className="main-view">
          <div className="item ">
            <div className="bg-color">
              <div className="image-circle-view">
                {state.consultantprofilepic != "" &&
                state.consultantprofilepic != null ? (
                  <img src={state.consultantprofilepic} alt="" />
                ) : (
                  <img src="assets/images/profile-pic.svg" alt="" />
                )}
              </div>
              <h2>{state.consultantname}</h2>
            </div>
            <div className="bg-color mt-3 bg-color2">
              <ul
                className="calendarIndicators main d-block"
                style={{ paddingBottom: "10px" }}
              >
                <li>* Calendar is based on local time of the consultant.</li>
                <li>{state.timezone}</li>
              </ul>
            </div>
            <div className="bg-color mt-3">
              <ul className="calendarIndicators main2  hours-view">
                <li>
                  Allotted Hours : <span> {state.transfereehours} Hrs</span>
                </li>
                <li>
                  Used Hours :{" "}
                  <span> {state.transfereescheduledhours} Hrs</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="item">
            {state.businessHoursLoader == true ? (
              <div className="loading">Loading</div>
            ) : null}
            <div className="app ">
              <div className="app__main">
                <div className="calendar">
                  {/* <FullCalendar
                    defaultView="dayGridMonth"
                    customButtons={{
                      prev: {
                        text: "prev",
                        click: () => {
                          let calendarApi =
                            calendarComponentRef.current.getApi();
                          getMonthlybusinessHours();
                          calendarApi.prev();
                        },
                      },
                      next: {
                        text: "next",
                        click: () => {
                          let calendarApi =
                            calendarComponentRef.current.getApi();
                          getMonthlybusinessHours();
                          calendarApi.next();
                        },
                      },
                      today: {
                        text: "",
                        click: () => {
                          let calendarApi =
                            calendarComponentRef.current.getApi();
                          getMonthlybusinessHours();
                          calendarApi.today();
                        },
                      },
                    }}
                    ref={calendarComponentRef}
                    select={(e) => handleSelectdate(e)}
                    headerToolbar={{
                      left: "prev",
                      center: "title",
                      right: "next,dayGridMonth",
                    }}
                    titleFormat={{
                      month: "long",
                      year: "numeric",
                    }}
                    eventRender={(info) => {
                      info.el.innerText = "";
                      info.el;
                    }}
                    visibleRange={(newDate) => {
                      return {
                        start: moment(new Date()).subtract(1, "days"),
                        end: moment(new Date()).add(5, "days"), // exclusive end, so 3
                      };
                    }}
                    selectLongPressDelay="25"
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    events={_.uniqBy(state.events, "scheduledate")}
                    businessHours={state.businessHours}
                    selectable={true}
                    selectAllow={(e) => {
                      if (
                        e.end.getTime() / 1000 - e.start.getTime() / 1000 <=
                        86400
                      ) {
                        return true;
                      }
                    }}
                    dayRender={(date) => {
                      const today = moment();
                      const renderedDate = moment(date.date);
                      const ispast = renderedDate.isBefore(today, "day");
                      for (
                        let i = 0;
                        i < state.MonthlybusinessHours.length;
                        i++
                      ) {
                        if (
                          moment(date.date).format("YYYY/MM/DD") ==
                          state.MonthlybusinessHours[i]
                        ) {
                          date.el.innerHTML = "<p class='activeDay'></p>";
                        }
                        else if (
                          moment(date.date).format("YYYY/MM/DD") <
                          state.MonthlybusinessHours[i]
                        ) {
                          data.el.innerHTML = "<p class='Pastday'></p>";
                        } else {
                          data.el.innerHTML = "<p class='futureDay'></p>";
                        }
                      }
                    }}
                  /> */}
                  <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    initialDate={initialDateStr}
                    initialView={"dayGridMonth"}
                    customButtons={{
                      prev: {
                        text: "prev",
                        click: () => handleButtonClick("prev"),
                      },
                      next: {
                        text: "next",
                        click: () => handleButtonClick("next"),
                      },
                      today: {
                        text: "",
                        click: () => handleButtonClick("today"),
                      },
                    }}
                    ref={calendarComponentRef}
                    select={handleDateSelect}
                    headerToolbar={{
                      left: "prev",
                      center: "title",
                      right: "next,dayGridMonth",
                    }}
                    titleFormat={{
                      month: "long",
                      year: "numeric",
                    }}
                    events={state.events}
                    eventContent={renderEventContent}
                    eventDidMount={handleEventDidMount}
                    // dayCellClassNames={dayCellClassNames}
                    // dayCellDidMount={dayCellDidMount}
                    datesSet={handleDatesSet}
                    visibleRange={(currentDate) => {
                      return {
                        start: moment(currentDate).subtract(1, "days").toDate(),
                        end: moment(currentDate).add(5, "days").toDate(), // exclusive end, so 3
                      };
                    }}
                    selectable={true}
                    selectAllow={(selectInfo) => {
                      return (
                        selectInfo.end.getTime() - selectInfo.start.getTime() <=
                        86400000
                      ); // 86400 seconds = 1 day
                    }}
                    dayCellDidMount={(info) => {
                      const today = moment();
                      const renderedDate = moment(info.date);
                      const ispast = renderedDate.isBefore(today, "day");
                    }}
                    businessHours={state.MonthlybusinessHours}
                  />
                </div>
              </div>
            </div>
            <div className="calender-rightside main_cal_indicate">
              <div className="">
                <ul className="indicators mt-5">
                  <li className="one">
                    {" "}
                    <span></span> <b>---</b> Past Appointments
                  </li>
                  <li className="green">
                    {" "}
                    <span></span> <b>---</b> Upcoming Appointments
                  </li>
                  <li className="white">
                    {" "}
                    <span></span> <b>---</b>Available Slots
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="item">
            <h5>{moment(state.selectdate).format("dddd , MMMM D")}</h5>
            <div className="clr-slots-view-bg">
              <h6>Available Slots</h6>
              {_.filter(state.businessHours, function (o) {
                return o.editable != true;
              }).length > 0 && state.scheduleLoader == true ? (
                <div className="a-slots">
                  {_.filter(state.businessHours, function (o) {
                    return o.editable != true;
                  }).map((row, index) => {
                    return (
                      <button
                        key={index}
                        type="button"
                        onClick={(event) => handleSelect(event, row)}
                        className="btn"
                      >
                        {moment(row.availabledateStart).format("hh:mm A")} -{" "}
                        {moment(row.availabledateEnd).format("hh:mm A")}
                      </button>
                    );
                  })}
                </div>
              ) : (
                <div className="a-slots">{"There are no available slots"}</div>
              )}
            </div>
            <div className="clr-slots-view-bg mt-2">
              <h6>Your Appointments</h6>
              {state.bookingslots.length > 0 ? (
                <div className="y-appointment">
                  {state.bookingslots
                    .sort(function (a, b) {
                      var dateA = new Date(a.start),
                        dateB = new Date(b.start);
                      return dateA - dateB;
                    })
                    .map((row, index) => {
                      return (
                        <ul className="time-slot" key={index}>
                          <li>
                            <a
                              href="javascript:void(0)"
                              style={{ backgroundColor: row.backgroundColor }}
                              onClick={(e) => moveEdit(e, row)}
                            >
                              {row.schedulefromtime} - {row.scheduletotime}
                              <b style={{ textAlign: "center" }}>{row.title}</b>
                            </a>
                          </li>
                        </ul>
                      );
                    })}
                </div>
              ) : (
                <div className="y-appointment">
                  {state.scheduleLoader == false ? (
                    <Spin />
                  ) : (
                    "There are no appointments"
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-md-12">
                    <div className="calender-rightside main_cal_indicate">
                        <div className="item">
                            <ul className="indicators">
                                <li className="one"> <span></span> <b>---</b> Past Appointments</li>
                                <li className="green">  <span></span>  <b>---</b> Upcoming Appointments</li>
                                <li className="white">  <span></span>  <b>---</b> Available slots</li>
                            </ul>
                        </div>
                    </div>
                </div> */}

      <div
        id="appointmentPopUp"
        className="modal fade appointmentModalView"
        role="dialog"
        style={{ zIndex: 1060 }}
      >
        <AppointmentPopUp
          state={state}
          serviceslist={serviceslist}
          saveEvent={saveEvent}
          setState={setState}
          sendSms={sendSms}
          sendEmail={sendEmail}
        />
      </div>

      <Predetails
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        state={state}
      />
      <Premove
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        state={state}
        setState={setState}
        getScheduleList={getScheduleList}
        getbusinessHours={getbusinessHours}
        getTransfareeData={getTransfareeData}
        filterEvents={filterEvents}
        handleSelectdate={handleSelectdate}
        filterEventsBusinessHours={filterEventsBusinessHours}
        getMonthlybusinessHours={getMonthlybusinessHours}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};

export default Schedules;
