import moment from "moment";
import { useEffect } from "react";

const Account = ({
  state,
  handleMail,
  editingTransferee,
  companyname,
  moredetails,
}) => {
  // console.log(moredetails, "moreedet15");
  // useEffect(() => {
  //   const fetch = async () => {
  //     return moredetails, companyname;
  //   };
  //   fetch();
  // }, []);
  return (
    <div id="tabEdit" className=" tab-pane active mt-5">
      <div className="a_edit_resendBtn">
        {state.moredetails.userUniqueId != undefined &&
        state.moredetails.isauthgenerated == true ? (
          state.moredetails.subscriptioncode != "ReloNavigator" ? (
            ""
          ) : (
            ""
          )
        ) : state.middleloader == false ? (
          <a
            className="a-resend-btn"
            onClick={() => handleMail(state.moredetails)}
          >
            Resend Welcome Email{" "}
          </a>
        ) : (
          ""
        )}
        <a
          onClick={() => editingTransferee(moredetails)}
          href="javascript:void(0)"
          data-bs-toggle="modal"
          data-bs-target="#myModaledit"
          className="edit_faIcon"
        >
          <i className="fa fa-edit" style={{ paddingLeft: "10px" }}></i>
        </a>
      </div>
      {state.middleloader == false ? (
        <form className="non_edit-form pop-form-controls focus-input-label ">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group row">
                <label className="label_cls col-sm-6 col-form-label">
                  First Name
                </label>
                <label className="label_cls col-sm-6 col-form-label">
                  {moredetails.userFirstName}
                </label>
              </div>
              <div className="form-group row">
                <label className="label_cls col-sm-6 col-form-label">
                  Email
                </label>
                <label className="label_cls col-sm-6 col-form-label">
                  {state.email}
                </label>
              </div>
              <div className="form-group row">
                <label className="label_cls col-sm-6 col-form-label">
                  Company
                </label>
                <label className="label_cls col-sm-6 col-form-label">
                  {companyname}
                </label>
              </div>
              {moredetails.subscriptioncode == "Commander" ||
              moredetails.subscriptioncode == "Explorer" ? (
                <div className="form-group row">
                  <label className="label_cls col-sm-6 col-form-label">
                    Company Virtual Hours
                  </label>
                  <label className="label_cls col-sm-6 col-form-label">
                    {moredetails.companyvirtualhours}
                  </label>
                </div>
              ) : (
                ""
              )}
              {moredetails.subscriptioncode == "Commander" ? (
                <div className="form-group row">
                  <label className="label_cls col-sm-6 col-form-label">
                    Company in Person Hours
                  </label>
                  <label className="label_cls col-sm-6 col-form-label">
                    {moredetails.companyinpersonhours}
                  </label>
                </div>
              ) : (
                ""
              )}
              <div className="form-group row">
                <label className="label_cls col-sm-6 col-form-label">
                  Access From Date{" "}
                </label>
                <label className="label_cls col-sm-6 col-form-label">
                  {moment(moredetails.accessfromdate).format("MM/DD/YYYY") !=
                  "Invalid date"
                    ? moment(moredetails.accessfromdate).format("MM/DD/YYYY")
                    : ""}
                </label>
              </div>
              <div className="form-group row">
                <label className="label_cls col-sm-6 col-form-label">PO </label>
                <label className="label_cls col-sm-6 col-form-label">
                  {moredetails.po}{" "}
                </label>
              </div>
              <div className="form-group row">
                <label className="label_cls col-sm-6 col-form-label">
                  Approved Hours
                </label>
                <label className="label_cls col-sm-6 col-form-label">
                  {state.approvedhours}
                </label>
              </div>
              <div className="form-group row">
                <label className="label_cls col-sm-6 col-form-label">
                  Is Temporary
                </label>
                <label className="label_cls col-sm-6 col-form-label">
                  {moredetails.istemporary == true ? "True" : "False"}
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="label_cls col-sm-6 col-form-label">
                  Last Name{" "}
                </label>
                <label className="label_cls col-sm-6 col-form-label">
                  {moredetails.userLastName}{" "}
                </label>
              </div>
              <div className="form-group row">
                <label className="label_cls col-sm-6 col-form-label">
                  Phone Number
                </label>
                <label className="label_cls col-sm-6 col-form-label">
                  {"+" +
                    moredetails.countrycode +
                    " " +
                    moredetails.PhoneNumber}
                </label>
              </div>
              <div className="form-group row">
                <label className="label_cls col-sm-6 col-form-label">
                  Service Level{" "}
                </label>
                <label className="label_cls col-sm-6 col-form-label">
                  {moredetails.subscriptioncode}
                </label>
              </div>
              {moredetails.subscriptioncode == "Commander" ||
              moredetails.subscriptioncode == "Explorer" ? (
                <div className="form-group row">
                  <label className="label_cls col-sm-6 col-form-label">
                    Individual Virtual hours
                  </label>
                  <label className="label_cls col-sm-6 col-form-label">
                    {moredetails.individualvirtualhours}
                  </label>
                </div>
              ) : (
                ""
              )}
              {moredetails.subscriptioncode == "Commander" ? (
                <div className="form-group row">
                  <label className="label_cls col-sm-6 col-form-label">
                    Individual in Person Hours{" "}
                  </label>
                  <label className="label_cls col-sm-6 col-form-label">
                    {moredetails.individualinpersonhours}
                  </label>
                </div>
              ) : (
                ""
              )}
              <div className="form-group row">
                <label className="label_cls col-sm-6 col-form-label">
                  Access To Date{" "}
                </label>
                <label className="label_cls col-sm-6 col-form-label">
                  {moment(moredetails.accesstodate).format("MM/DD/YYYY") !=
                  "Invalid date"
                    ? moment(moredetails.accesstodate).format("MM/DD/YYYY")
                    : ""}
                </label>
              </div>
              <div className="form-group row">
                <label className="label_cls col-sm-6 col-form-label">
                  ReferenceId{" "}
                </label>
                <label className="label_cls col-sm-6 col-form-label">
                  {moredetails.referenceid}
                </label>
              </div>
              <div className="form-group row">
                <label className="label_cls col-sm-6 col-form-label">
                  Notes{" "}
                </label>
                <label className="label_cls col-sm-6 col-form-label">
                  {moredetails.usernotes}
                </label>
              </div>
            </div>
          </div>
        </form>
      ) : (
        ""
      )}
    </div>
  );
};

export default Account;

// import moment from "moment";

// const Account = ({ state }) => {
//   return (
//     <div id="tabEdit" className=" tab-pane active mt-5">
//       <div className="a_edit_resendBtn">
//         {state.moredetails.userUniqueId != undefined &&
//         state.moredetails.isauthgenerated == true ? (
//           state.moredetails.subscriptioncode != "ReloNavigator" ? (
//             ""
//           ) : (
//             ""
//           )
//         ) : state.middleloader == false ? (
//           <a
//             className="a-resend-btn"
//             onClick={() => handleMail(state.moredetails)}
//           >
//             Resend Welcome Email{" "}
//           </a>
//         ) : (
//           ""
//         )}
//         <a
//           onClick={() => editingTransferee(state.moredetails)}
//           href="#"
//           data-bs-toggle="modal"
//           data-bs-target="#myModaledit"
//           className="edit_faIcon"
//         >
//           <i className="fa fa-edit" style={{ paddingLeft: "10px" }}></i>
//         </a>
//       </div>
//       {state.middleloader == false ? (
//         <form className="non_edit-form pop-form-controls focus-input-label ">
//           <div className="row">
//             <div className="col-sm-6">
//               <div className="form-group row">
//                 <label className="label_cls col-sm-6 col-form-label">
//                   First Name
//                 </label>
//                 <label className="label_cls col-sm-6 col-form-label">
//                   {state.firstname}
//                 </label>
//               </div>
//               <div className="form-group row">
//                 <label className="label_cls col-sm-6 col-form-label">
//                   Email
//                 </label>
//                 <label className="label_cls col-sm-6 col-form-label">
//                   {state.email}
//                 </label>
//               </div>
//               <div className="form-group row">
//                 <label className="label_cls col-sm-6 col-form-label">
//                   Company
//                 </label>
//                 <label className="label_cls col-sm-6 col-form-label">
//                   {state.compyname}
//                 </label>
//               </div>
//               {state.servicelevel == "Commander" ||
//               state.servicelevel == "Explorer" ? (
//                 <div className="form-group row">
//                   <label className="label_cls col-sm-6 col-form-label">
//                     Company Virtual Hours
//                   </label>
//                   <label className="label_cls col-sm-6 col-form-label">
//                     {state.companyvirtualhours}
//                   </label>
//                 </div>
//               ) : (
//                 ""
//               )}
//               {state.servicelevel == "Commander" ? (
//                 <div className="form-group row">
//                   <label className="label_cls col-sm-6 col-form-label">
//                     Company in Person Hours
//                   </label>
//                   <label className="label_cls col-sm-6 col-form-label">
//                     {state.companyinpersonhours}
//                   </label>
//                 </div>
//               ) : (
//                 ""
//               )}
//               <div className="form-group row">
//                 <label className="label_cls col-sm-6 col-form-label">
//                   Access From Date{" "}
//                 </label>
//                 <label className="label_cls col-sm-6 col-form-label">
//                   {moment(state.accessfromdate).format("MM/DD/YYYY") !=
//                   "Invalid date"
//                     ? moment(state.accessfromdate).format("MM/DD/YYYY")
//                     : ""}
//                 </label>
//               </div>
//               <div className="form-group row">
//                 <label className="label_cls col-sm-6 col-form-label">PO </label>
//                 <label className="label_cls col-sm-6 col-form-label">
//                   {state.po}{" "}
//                 </label>
//               </div>
//               <div className="form-group row">
//                 <label className="label_cls col-sm-6 col-form-label">
//                   Approved Hours
//                 </label>
//                 <label className="label_cls col-sm-6 col-form-label">
//                   {state.approvedhours}
//                 </label>
//               </div>
//             </div>
//             <div className="col-sm-6">
//               <div className="form-group row">
//                 <label className="label_cls col-sm-6 col-form-label">
//                   Last Name{" "}
//                 </label>
//                 <label className="label_cls col-sm-6 col-form-label">
//                   {state.lastname}{" "}
//                 </label>
//               </div>
//               <div className="form-group row">
//                 <label className="label_cls col-sm-6 col-form-label">
//                   Phone Number
//                 </label>
//                 <label className="label_cls col-sm-6 col-form-label">
//                   {"+" + state.countrycode + " " + state.phoneNumber}
//                 </label>
//               </div>
//               <div className="form-group row">
//                 <label className="label_cls col-sm-6 col-form-label">
//                   Service Level{" "}
//                 </label>
//                 <label className="label_cls col-sm-6 col-form-label">
//                   {state.servicelevel}
//                 </label>
//               </div>
//               {state.servicelevel == "Commander" ||
//               state.servicelevel == "Explorer" ? (
//                 <div className="form-group row">
//                   <label className="label_cls col-sm-6 col-form-label">
//                     Individual Virtual hours
//                   </label>
//                   <label className="label_cls col-sm-6 col-form-label">
//                     {state.individualvirtualhours}
//                   </label>
//                 </div>
//               ) : (
//                 ""
//               )}
//               {state.servicelevel == "Commander" ? (
//                 <div className="form-group row">
//                   <label className="label_cls col-sm-6 col-form-label">
//                     Individual in Person Hours{" "}
//                   </label>
//                   <label className="label_cls col-sm-6 col-form-label">
//                     {state.individualinpersonhours}
//                   </label>
//                 </div>
//               ) : (
//                 ""
//               )}
//               <div className="form-group row">
//                 <label className="label_cls col-sm-6 col-form-label">
//                   Access To Date{" "}
//                 </label>
//                 <label className="label_cls col-sm-6 col-form-label">
//                   {moment(state.accesstodate).format("MM/DD/YYYY") !=
//                   "Invalid date"
//                     ? moment(state.accesstodate).format("MM/DD/YYYY")
//                     : ""}
//                 </label>
//               </div>
//               <div className="form-group row">
//                 <label className="label_cls col-sm-6 col-form-label">
//                   ReferenceId{" "}
//                 </label>
//                 <label className="label_cls col-sm-6 col-form-label">
//                   {state.referenceid}
//                 </label>
//               </div>
//               <div className="form-group row">
//                 <label className="label_cls col-sm-6 col-form-label">
//                   Notes{" "}
//                 </label>
//                 <label className="label_cls col-sm-6 col-form-label">
//                   {state.usernotes}
//                 </label>
//               </div>
//             </div>
//           </div>
//         </form>
//       ) : (
//         ""
//       )}
//     </div>
//   );
// };

// export default Account;
