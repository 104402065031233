import React from "react";

function InternationalPetModal() {
  const closeModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#internationalPetModal").modal("hide");
      });
    });
  };
  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header border-0">
          <div class="only_close">
            <span
              aria-hidden="true"
              className="pointer close"
              onClick={() => closeModal()}
              data-dismiss="modal"
            >
              ×
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div className="widthModal">
            <div className="item-view">
              <h2>International Pet Relocation </h2>
              <p className="mb-0">
                Moving internationally with a pet involves more than just
                booking a flight—it requires careful planning and knowledge of
                country-specific regulations. From health checks to customs,
                here’s everything you need to know to ensure a smooth relocation
                for your furry friend.
              </p>
            </div>

            <div className="tails-row">
              <div className="tails">
                <div className="item-view">
                  <h2>1. Research Import Requirements </h2>
                  <p className="ps-4">
                    Each country has its own set of rules regarding the
                    importation of pets. These regulations can include mandatory
                    vaccinations, health certifications, and, in some cases,
                    quarantine periods.
                  </p>
                  <ul>
                    <li>
                      <b>Check Country-Specific Requirements: </b> Research your
                      destination country’s pet import rules well in advance.
                      You can typically find this information on government or
                      consulate websites. Some countries may require:
                      <ul>
                        <li>Microchipping </li>
                        <li>Proof of rabies vaccination </li>
                        <li>
                          Blood tests or titer tests to confirm rabies immunity{" "}
                        </li>
                        <li>Import permits </li>
                      </ul>
                    </li>
                    <li>
                      <b>Pet Passports: </b> Many countries in the EU, for
                      example, require pets to have an official "pet passport"
                      that lists their vaccinations and health records.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>2. Visit the Vet for a Health Check-Up </h2>
                  <p className="ps-4">
                    Schedule an appointment with your vet as soon as possible to
                    ensure your pet is ready for travel.
                  </p>
                  <ul>
                    <li>
                      <b>Health Certificate:</b> Most countries require a health
                      certificate issued by a veterinarian within a certain
                      timeframe (often 10 days before travel). The certificate
                      should verify that your pet is fit to fly and free of
                      diseases.
                    </li>
                    <li>
                      <b>Rabies Vaccination: </b> Ensure your pet’s rabies
                      vaccination is up to date, as this is a universal
                      requirement. Some countries require the vaccination to
                      have been administered at least 30 days before arrival.
                    </li>
                    <li>
                      <b>Parasite Treatments: </b> Some countries, especially
                      those in the EU, may require treatments for ticks, fleas,
                      and tapeworms within a specific time window before entry.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>3. Understand Quarantine Requirements </h2>
                  <p className="ps-4">
                    Certain countries require pets to undergo quarantine upon
                    arrival. This is particularly common in rabies-free
                    countries like Australia, New Zealand, and some island
                    nations.
                  </p>
                  <ul>
                    <li>
                      <b>Quarantine Duration: </b> Quarantine periods can range
                      from a few days to several months, depending on the
                      country and the pet’s health documentation. For instance,
                      Australia requires pets to stay in quarantine for at least
                      10 days.
                    </li>
                    <li>
                      <b>Pre-Arrival Preparations: </b>To minimize quarantine
                      time, ensure your pet has received all necessary
                      vaccinations and tests ahead of time, and provide all the
                      required documents to the relevant authorities before
                      departure.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>4. Choose the Right Travel Crate </h2>
                  <p className="ps-4">
                    Choosing a travel crate that complies with International Air
                    Transport Association (IATA) guidelines is essential for
                    your pet’s safety and comfort during the flight.
                  </p>
                  <ul>
                    <li>
                      <b>Crate Size: </b> The crate should be large enough for
                      your pet to stand, turn around, and lie down comfortably.
                      Check with your airline for specific size restrictions.
                    </li>
                    <li>
                      <b>Ventilation and Labeling: </b>Ensure the crate has
                      proper ventilation on all sides and is clearly labeled
                      with “Live Animal” stickers. Attach your contact details
                      and your pet’s information to the crate.
                    </li>
                    <li>
                      <b>Crate Familiarization: </b>Get your pet used to the
                      travel crate several weeks before the trip by letting them
                      spend time in it daily. This will reduce their anxiety on
                      the travel day.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="tails">
                <div className="item-view">
                  <h2>5. Booking Air Travel for Your Pet </h2>
                  <p className="ps-4">
                    Airlines have different policies regarding pet travel, and
                    it’s important to choose an airline that is pet-friendly and
                    experienced in international pet relocation.
                  </p>
                  <ul>
                    <li>
                      <b>Cabin vs. Cargo Travel: </b> Smaller pets may be
                      allowed to travel in the cabin with you if they fit under
                      the seat in a carrier. Larger pets will need to travel in
                      the cargo hold.
                    </li>
                    <li>
                      <b>Direct Flights: </b>Whenever possible, book a direct
                      flight to minimize travel time and reduce the stress on
                      your pet. Avoid layovers, especially in extreme weather
                      conditions.
                    </li>
                    <li>
                      <b>Contact the Airline Early: </b>Notify the airline in
                      advance that you will be traveling with a pet, as they
                      often have limits on how many animals they allow per
                      flight.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>
                    6. Prepare for Customs and Documentation Upon Arrival{" "}
                  </h2>
                  <p className="ps-4">
                    Upon arrival at your destination, you will likely need to
                    clear customs with your pet. Be sure to have all your
                    documentation ready:
                  </p>
                  <ul>
                    <li>
                      <b>Health Certificate and Vaccination Records: </b>{" "}
                      Present your pet’s health certificate and vaccination
                      records to customs officials upon arrival.
                    </li>
                    <li>
                      <b>Customs Fees: </b>Some countries charge fees for pet
                      imports, so be prepared to pay any required duties or
                      taxes.
                    </li>
                    <li>
                      <b>Check for Additional Inspections: </b>Some countries
                      require further health inspections upon arrival. Make sure
                      you’re aware of any additional checks that might be
                      required.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>7. Hiring a Pet Relocation Service </h2>
                  <p className="ps-4">
                    For long-distance international moves, especially those
                    involving complex regulations, you may want to consider
                    hiring a professional pet relocation service.
                  </p>
                  <ul>
                    <li>
                      <b>Benefits of a Pet Relocation Service: </b> These
                      companies specialize in handling all aspects of your pet’s
                      move, including paperwork, crate arrangements, travel
                      booking, and even quarantine accommodations.
                    </li>
                    <li>
                      <b>Cost Considerations: </b>While pet relocation services
                      can be expensive, they help reduce the stress and ensure
                      your pet’s safe arrival.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>Final Checklist Before Departure </h2>
                  <ul>
                    <li>
                      <b>Vet check-up and health certificate obtained. </b>
                    </li>
                    <li>
                      <b>
                        All vaccinations, tests, and treatments completed as per
                        destination country’s requirements.{" "}
                      </b>
                    </li>
                    <li>
                      <b>Pet’s travel crate is IATA-compliant and ready. </b>
                    </li>
                    <li>
                      <b>Flight booked with a pet-friendly airline. </b>
                    </li>
                    <li>
                      <b>Required customs documents are prepared. </b>
                    </li>
                    <li>
                      <b>Microchip and ID tags are updated. </b>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="item-view mt-2">
              <h2>Final Thoughts </h2>
              <p className="mb-0">
                International pet relocation requires early planning and
                attention to detail, but with the right preparation, your pet
                can safely and comfortably join you on your new adventure
                abroad. Be sure to start the process well in advance to account
                for vaccinations, paperwork, and potential quarantine periods.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InternationalPetModal;
