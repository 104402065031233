import React from "react";

function NewPublicHeader() {
  const signInBtn = () => {
    location.href = "/login";
  };
  return (
    <div>
      <nav className="navbar navbar-dark relo-bg navbar-expand-lg px-4 py-4 border-bottom border-secondary ">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src="assets/publicImages/Logo_public.png" alt="" width="250px" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item px-lg-2">
                <a className="nav-link text-white" href="/">
                  Home{" "}
                </a>
              </li>
              {/* <li className="nav-item px-lg-2">
                <a className="nav-link text-white" href="#">
                  Pricing
                </a>
              </li> */}
              <li className="nav-item px-lg-2">
                <a className="nav-link text-white" href="/contact-us">
                  Contact
                </a>
              </li>
              <li className="nav-item ps-lg-2">
                <button
                  type="button"
                  className="relo-btn"
                  onClick={() => signInBtn()}
                >
                  Login
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NewPublicHeader;
