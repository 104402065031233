// import "./resume.css";
import { useState, useEffect } from "react";
import * as $ from "jquery";
import LoginStore from "../../../../services/thirdPartyService";
import { Radio, Modal } from "antd";
import ThirdParty from "../../../../services/thirdPartyService";
import PdfService from "../../../../services/pdfService";
import AppController from "../../../controllers/appController";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Header from "../../../common/header/Header";
import Footer from "../../../common/footer/Footer";
import ResumePopUp from "./modalPopUp/resumePopUp/resumePopUp";
import IntTelDropDown from "../../../common/internationalPhone/IntTelDropDown";

const PetResume = () => {
  const [state, setState] = useState({
    typePop: "",
    popUp: false,
    success: false,
    pageNumber: 1,
    firstname: "",
    age: "",
    Breed: "",
    Weight: "",
    Gender: "",
    About: "",
    OwnerName: "",
    OwnerMobile: "",
    Ownercountrycode: "",
    OwnerEmail: "",
    OwnerAddress: "",
    Qualities: [],
    Healthissues: "",
    CareRoutine: "",
    graduated: "",
    Groomername: "",
    GroomerMobile: "",
    Groomercountrycode: "",
    Vetname: "",
    VetMobile: "",
    Vetcountrycode: "",
    landlord: "",
    ReferencesMobile: "",
    Refcountrycode: "",
    youremail: "",
    requested: "No",
    latitude: "",
    longitude: "",
    address: "",
    spanloader: false,
  });

  useEffect(() => {
    const fetch = async () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      let getSessionData = await LoginStore.getLoginInfo({});
      AppController.logData(
        "Pet Resume",
        "Resources",
        location.pathname,
        getSessionData.userUniqueId,
        ""
      );
      setState((prevState) => ({
        ...prevState,
        OwnerName:
          getSessionData.userFirstName + " " + getSessionData.userLastName,
        OwnerMobile: getSessionData.PhoneNumber,
        Ownercountrycode: getSessionData.countrycode,
        OwnerEmail: getSessionData.EmailId,
      }));
    };

    fetch();
  }, []);
  function handleCountryCodeChange(code) {
    updateState({ Refcountrycode: code });
  }
  function updateState(data) {
    setState((prevState) => ({
      ...prevState,
      ...data,
    }));
  }
  const nextPage = async (page) => {
    // console.log(page, "===");
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (page == 2) {
      let errorUpdate = 0;
      if (state.firstname.trim() == "") {
        $("#firstname").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#firstname").removeClass("is-invalid");
      }
      if (state.age.trim() == "") {
        $("#age").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#age").removeClass("is-invalid");
      }
      if (state.Breed.trim() == "") {
        $("#Breed").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#Breed").removeClass("is-invalid");
      }
      if (state.Weight.trim() == "") {
        $("#Weight").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#Weight").removeClass("is-invalid");
      }
      if (state.Gender.trim() == "") {
        $("#Gender").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#Gender").removeClass("is-invalid");
      }
      if (state.About.trim() == "") {
        $("#About").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#About").removeClass("is-invalid");
      }
      if (errorUpdate === 0) {
        console.log(page, "page", errorUpdate, "===");
        let newPage = page;
        setState((prev) => ({
          ...prev,
          pageNumber: newPage,
        }));
      }
    } else if (page == 3) {
      let errorUpdate = 0;
      if (state.OwnerName.trim() == "") {
        $("#OwnerName").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#OwnerName").removeClass("is-invalid");
      }
      if (
        /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(
          state.OwnerMobile
        ) !== true
      ) {
        $("#OwnerMobile").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#OwnerMobile").removeClass("is-invalid");
      }
      if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.OwnerEmail)
      ) {
        $("#OwnerEmail").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#OwnerEmail").removeClass("is-invalid");
      }
      if (state.OwnerAddress.trim() == "") {
        $("#OwnerAddress").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#OwnerAddress").removeClass("is-invalid");
      }
      if (errorUpdate == 0) {
        setState((prevState) => ({ ...prevState, pageNumber: page }));
      }
    } else if (page == 5) {
      let errorUpdate = 0;
      if (state.GroomerMobile.trim() != "") {
        if (
          /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(
            state.GroomerMobile
          ) !== true
        ) {
          $("#GroomerMobile").addClass("is-invalid");
          errorUpdate++;
        } else {
          $("#GroomerMobile").removeClass("is-invalid");
        }
      } else {
        $("#GroomerMobile").removeClass("is-invalid");
      }
      if (state.VetMobile.trim() != "") {
        if (
          /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(
            state.VetMobile
          ) !== true
        ) {
          $("#VetMobile").addClass("is-invalid");
          errorUpdate++;
        } else {
          $("#VetMobile").removeClass("is-invalid");
        }
      } else {
        $("#VetMobile").removeClass("is-invalid");
      }
      if (state.ReferencesMobile.trim() != "") {
        if (
          /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(
            state.ReferencesMobile
          ) !== true
        ) {
          $("#ReferencesMobile").addClass("is-invalid");
          errorUpdate++;
        } else {
          $("#ReferencesMobile").removeClass("is-invalid");
        }
      } else {
        $("#ReferencesMobile").removeClass("is-invalid");
      }
      if (errorUpdate == 0) {
        setState((prevState) => ({ ...prevState, pageNumber: page }));
        // Scroll to the top of the page
        // window.scrollTo({ top: 0, behavior: "smooth" }); // Smooth scrolling
        // scrollToTop();
      }
    } else {
      setState((prevState) => ({ ...prevState, pageNumber: page }));
    }
  };
  function scrollToTop() {
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, scrollTop - scrollTop / 2);
    }
  }
  const openPopModal = () => {
    setState((prev) => ({
      ...prev,
      popUp: true,
      typePop: "pet",
    }));
  };
  const sendmail = async () => {
    let errorUpdate = 0;
    if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.youremail)
    ) {
      $("#youremail").addClass("is-invalid");
      errorUpdate++;
    }
    if (errorUpdate == 0) {
      let data = {
        firstname: state.firstname,
        age: state.age,
        Breed: state.Breed,
        Weight: state.Weight,
        Gender: state.Gender,
        About: state.About,
        OwnerName: state.OwnerName,
        OwnerMobile: "+" + state.Ownercountrycode + " " + state.OwnerMobile,
        OwnerEmail: state.OwnerEmail,
        OwnerAddress: state.OwnerAddress,
        Qualities: state.Qualities.toString(" ,"),
        Healthissues: state.Healthissues,
        CareRoutine: state.CareRoutine,
        graduated: state.graduated,
        Groomername: state.Groomername,
        GroomerMobile:
          state.GroomerMobile != ""
            ? "+" + state.Groomercountrycode + " " + state.GroomerMobile
            : "",
        Vetname: state.Vetname,
        VetMobile:
          state.VetMobile != ""
            ? "+" + state.Vetcountrycode + " " + state.VetMobile
            : "",
        landlord: state.landlord,
        ReferencesMobile:
          state.ReferencesMobile != ""
            ? "+" + state.Refcountrycode + " " + state.ReferencesMobile
            : "",
        email: state.youremail,
        thepetresume: true,
        subject: "Pet Resume",
      };

      let sendSms = await ThirdParty.sendEmail(data);
      if (sendSms.response == true) {
        await setState((prevState) => ({ ...prevState, success: true }));
      } else {
        await setState((prevState) => ({
          ...prevState,
          success: true,
          error: true,
        }));
      }
    }
  };

  const pdfmethod = async () => {
    console.log("click");
    await setState((prevState) => ({ ...prevState, spanloader: false }));
    var doc = new jsPDF("p", "pt");
    doc.text(39, 30, "Pet Resume");
    var img = document.createElement("img");
    img.src = "https://relonavigator.com/assets/newImages/relo-b&WLogin.png";
    doc.addImage(img, "JPEG", 400, 10, 150, 13);
    doc.autoTable({
      columnStyles: {
        petdetails: { fillColor: [255, 255, 255], cellWidth: 190 },
        colon: { fillColor: [255, 255, 255], cellWidth: 50 },
        text: { fillColor: [255, 255, 255] },
      },
      headStyles: { fillColor: [220, 220, 220], textColor: [0, 0, 0] },
      body: [
        { petdetails: "Name", colon: ":", text: state.firstname },
        { petdetails: "Age", colon: ":", text: state.age },
        { petdetails: "Breed", colon: ":", text: state.Breed },
        { petdetails: "Weight", colon: ":", text: state.Weight },
        { petdetails: "Gender", colon: ":", text: state.Gender },
        { petdetails: "About:", colon: "", text: "" },
        [{ content: state.About, colSpan: 4, styles: {} }],
      ],
      columns: [
        { header: "Pet Details", dataKey: "petdetails" },
        { header: "", dataKey: "colon" },
        { header: "", dataKey: "text" },
      ],
    });
    doc.autoTable({
      columnStyles: {
        ownersdetails: { fillColor: [255, 255, 255], cellWidth: 190 },
        colon: { fillColor: [255, 255, 255], cellWidth: 50 },
        text: { fillColor: [255, 255, 255] },
      },
      headStyles: { fillColor: [220, 220, 220], textColor: [0, 0, 0] },

      body: [
        { ownersdetails: "Pet owner", colon: "", text: "" },
        { ownersdetails: "Name", colon: ":", text: state.OwnerName },
        {
          ownersdetails: "Mobile",
          colon: ":",
          text: "+" + state.Ownercountrycode + " " + state.OwnerMobile,
        },
        { ownersdetails: "Email", colon: ":", text: state.OwnerEmail },
        { ownersdetails: "Address", colon: ":", text: state.OwnerAddress },
      ],
      columns: [
        { header: "Owners Details", dataKey: "ownersdetails" },
        { header: "", dataKey: "colon" },
        { header: "", dataKey: "text" },
      ],
    });
    doc.autoTable({
      columnStyles: {
        healthroutine: { fillColor: [255, 255, 255], cellWidth: 190 },
        colon: { fillColor: [255, 255, 255], cellWidth: 50 },
        text: { fillColor: [255, 255, 255] },
      },
      headStyles: { fillColor: [220, 220, 220], textColor: [0, 0, 0] },
      body: [
        {
          healthroutine: "Qualities",
          colon: ":",
          text: state.Qualities.length > 0 ? state.Qualities : "",
        },
        { healthroutine: "Health issues in the past?:", colon: "", text: "" },
        [{ content: state.Healthissues, colSpan: 4, styles: {} }],
        { healthroutine: "Care Routine:", colon: "", text: "" },
        [{ content: state.CareRoutine, colSpan: 4, styles: {} }],
      ],
      columns: [
        { header: "Health & Routine (Optional)", dataKey: "healthroutine" },
        { header: "", dataKey: "colon" },
        { header: "", dataKey: "text" },
      ],
    });
    doc.autoTable({
      columnStyles: {
        trainingroutine: { fillColor: [255, 255, 255], cellWidth: 190 },
        colon: { fillColor: [255, 255, 255], cellWidth: 50 },
        text: { fillColor: [255, 255, 255] },
      },
      headStyles: { fillColor: [220, 220, 220], textColor: [0, 0, 0] },
      body: [
        {
          trainingroutine: "Has your pet graduated from a training class?",
          colon: ":",
          text: state.graduated,
        },
        { trainingroutine: "Groomer", colon: "", text: "" },
        {
          trainingroutine: "Groomer name",
          colon: ":",
          text: state.Groomername,
        },
        {
          trainingroutine: "Mobile",
          colon: ":",
          text:
            state.GroomerMobile != ""
              ? "+" + state.Groomercountrycode + " " + state.GroomerMobile
              : "",
        },
        { trainingroutine: "Vet", colon: "", text: "" },
        { trainingroutine: "Vet name", colon: ":", text: state.Vetname },
        {
          trainingroutine: "Mobile",
          colon: ":",
          text:
            state.VetMobile != ""
              ? "+" + state.Vetcountrycode + " " + state.VetMobile
              : "",
        },
        { trainingroutine: "References", colon: "", text: "" },
        {
          trainingroutine: "Previous landlord name",
          colon: ":",
          text: state.landlord,
        },
        {
          trainingroutine: "Mobile",
          colon: ":",
          text:
            state.ReferencesMobile != ""
              ? "+" + state.Refcountrycode + " " + state.ReferencesMobile
              : "",
        },
      ],
      columns: [
        { header: "Training & Other (Optional)", dataKey: "trainingroutine" },
        { header: "", dataKey: "colon" },
        { header: "", dataKey: "text" },
      ],
    });
    var specialElementHandlers = {
      "#editor": function (element, renderer) {
        return true;
      },
    };
    // $("#cmd").on("click", function () {
    doc.fromHTML($("#content").html(), 15, 15, {
      width: 170,
      elementHandlers: specialElementHandlers,
    });
    doc.save("petresume.pdf");
    // });
    await setState((prevState) => ({ ...prevState, spanloader: true }));
  };
  const onChange = (e) => {
    //e.preventDefault();
    setState((prevState) => ({ ...prevState, graduated: e.target.value }));
  };
  const inputHandler = (event) => {
    event.preventDefault();
    const inputVal = event.target.value;
    const inputName = event.target.name;

    // Create a copy of the previous state
    const newState = { ...state };

    // Update the corresponding field in the newState
    newState[inputName] = inputVal;

    // Perform validation based on inputName
    if (
      inputName === "firstname" ||
      inputName === "Breed" ||
      inputName === "OwnerName" ||
      inputName === "Groomername" ||
      inputName === "Vetname"
    ) {
      const value = inputVal.replace(/[^A-Za-z ]/gi, "");
      newState[inputName] = value;
    }

    if (
      inputName === "OwnerMobile" ||
      inputName === "GroomerMobile" ||
      inputName === "VetMobile" ||
      inputName === "ReferencesMobile"
    ) {
      const x = inputVal
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      const formattedValue = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
      newState[inputName] = formattedValue;
    }

    // Update the state with the new values
    setState(newState);

    // Validate email input
    if (
      inputName === "OwnerEmail" &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputVal.trim())
    ) {
      $("#" + inputName).addClass("is-invalid");
    } else {
      $("#" + inputName).removeClass("is-invalid");
    }
  };
  const handleKeyPress = (e) => {
    const key = e.key;
    const regex = /[0-9.]/; // Allow numeric digits and a period (dot)

    if (e.keyCode === 8 || regex.test(key)) {
      return;
    }
    e.preventDefault();
  };
  const handleQualitie = (value) => {
    let tmp = state.Qualities;
    if (state.Qualities.includes(value)) {
      setState((prevState) => ({
        ...prevState,
        Qualities: state.Qualities.filter((el) => el !== value),
      }));
    } else {
      tmp.push(value);
      setState((prevState) => ({ ...prevState, Qualities: tmp }));
    }
  };
  const listofQualities = [
    { id: 0, title: "Spayed or Neutered" },
    { id: 1, title: "Vaccinated" },
    { id: 2, title: "Microchipped" },
    { id: 3, title: "Insured" },
    { id: 4, title: "House broken" },
  ];
  const setLocation = async (Id, e) => {
    // console.log(e, "e value of the location");
    if (e.target.value.trim() == null || e.target.value.trim() == "") {
      $("#" + Id).addClass("is-invalid");
    } else {
      $("#" + Id).removeClass("is-invalid");
    }
    let address = e.target.value;
    setState((prev) => ({
      ...prev,
      [Id]: address,
    }));
    const _this = this;
    var places = new google.maps.places.Autocomplete(
      document.getElementById(Id),
      { types: ["address"] }
    );
    places.setComponentRestrictions({ country: ["us"] });
    google.maps.event.addListener(places, "place_changed", async () => {
      var place = places.getPlace();
      var placeDetails = {
        locality: "long_name",
        administrative_area_level_1: "short_name",
        country: "long_name",
      };
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (placeDetails[addressType]) {
          var val = place.address_components[i][placeDetails[addressType]];
          placeDetails[addressType] = val;
        }
      }
      let relocatedAddress = place.formatted_address;
      setState((prev) => ({
        ...prev,
        OwnerAddress: relocatedAddress,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      }));
    });
  };
  return (
    <div id="outer-container">
      {/* <Header /> */}
      <div className="relo-step-from" style={{ paddingBottom: "20px" }}>
        <div className="container">
          {state.pageNumber != 5 && (
            <div className="tracker-view">
              <h1 className="heading">Pet Resume</h1>
              <ul>
                <li
                  className={
                    state.pageNumber == 1 ? "is-active" : "is-complete"
                  }
                >
                  <div className="bar"></div>
                  <div className="text">
                    <h3>Details</h3>
                  </div>
                </li>
                <li
                  className={
                    state.pageNumber == 2
                      ? "is-active"
                      : state.pageNumber == 1
                      ? ""
                      : "is-complete"
                  }
                >
                  <div className="bar"></div>
                  <div className="text">
                    <h3>Owner</h3>
                  </div>
                </li>
                <li
                  className={
                    state.pageNumber == 3
                      ? "is-active"
                      : state.pageNumber == 2 || state.pageNumber == 1
                      ? ""
                      : "is-complete"
                  }
                >
                  <div className="bar"></div>
                  <div className="text">
                    <h3>Health & Routine</h3>
                  </div>
                </li>
                <li
                  className={
                    state.pageNumber == 4
                      ? "is-active"
                      : state.pageNumber == 2 ||
                        state.pageNumber == 1 ||
                        state.pageNumber == 3
                      ? ""
                      : "is-complete"
                  }
                >
                  <div className="bar"></div>
                  <div className="text">
                    <h3>Training & Other</h3>
                  </div>
                </li>
              </ul>
              <p className="disclaimer">
                *We do not store any of the below information. It is solely used
                to generate the resume.
              </p>
            </div>
          )}
          {state.pageNumber == 1 && (
            <div>
              <form className="">
                <h1>Pet Details</h1>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Name*</label>
                      <input
                        type="text"
                        value={state.firstname || ""}
                        className="form-control"
                        id="firstname"
                        name="firstname"
                        onChange={(e) => inputHandler(e)}
                        placeholder="Enter Name"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Age*</label>
                      <input
                        type="text"
                        value={state.age || ""}
                        className="form-control"
                        onKeyDown={handleKeyPress}
                        id="age"
                        name="age"
                        onChange={(e) => inputHandler(e)}
                        placeholder="Enter Age"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Breed*</label>
                      <input
                        type="text"
                        value={state.Breed || ""}
                        className="form-control"
                        id="Breed"
                        name="Breed"
                        onChange={(e) => inputHandler(e)}
                        placeholder="Enter Breed"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Weight (lb)*</label>
                      <input
                        type="text"
                        value={state.Weight || ""}
                        className="form-control"
                        onKeyDown={handleKeyPress}
                        id="Weight"
                        name="Weight"
                        onChange={(e) => inputHandler(e)}
                        placeholder="Enter Weight"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    {" "}
                    <div className="form-group">
                      <label>Gender*</label>
                      <select
                        value={state.Gender}
                        className="form-control"
                        id="Gender"
                        name="Gender"
                        onChange={(e) => inputHandler(e)}
                        placeholder="Enter Gender"
                      >
                        <option value="">Choose...</option>
                        <option value={"Male"}>Male</option>
                        <option value={"Female"}>Female</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    {" "}
                    <div className="form-group">
                      <label>About*</label>
                      <textarea
                        value={state.About || ""}
                        className="form-control"
                        id="About"
                        name="About"
                        rows="3"
                        onChange={(e) => inputHandler(e)}
                        placeholder="Enter details about how long you’ve owned your pet, if they are an indoor or outdoor pet, if they shed, favorite pastime and what you love most about them."
                      />
                    </div>
                  </div>
                </div>

                <div className="text-right">
                  <button
                    type="button"
                    className="btn"
                    onClick={() => nextPage(2)}
                  >
                    {" "}
                    Next{" "}
                  </button>
                </div>
              </form>
            </div>
          )}
          {state.pageNumber == 2 && (
            <div>
              <form>
                <h1>Owner’s Details</h1>
                <h2>Pet owner</h2>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>Name*</label>
                    <input
                      type="text"
                      value={state.OwnerName || ""}
                      className="form-control"
                      id="OwnerName"
                      name="OwnerName"
                      onChange={(e) => inputHandler(e)}
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Mobile*</label>
                    <div className="position-relative flag-posotion">
                      <div className="phone-flag">
                        <IntTelDropDown
                          handleCountryCodeChange={handleCountryCodeChange}
                        />
                        <input
                          type="tel"
                          className="form-control pl-5"
                          autoComplete="off"
                          value={state.OwnerMobile || ""}
                          placeholder="Enter Mobile"
                          id="OwnerMobile"
                          name="OwnerMobile"
                          onChange={(e) => inputHandler(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label>Email*</label>
                    <input
                      type="text"
                      value={state.OwnerEmail || ""}
                      className="form-control"
                      id="OwnerEmail"
                      name="OwnerEmail"
                      onChange={(e) => inputHandler(e)}
                      placeholder="Enter Email"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Address*</label>
                    <input
                      placeholder="Enter Location"
                      autoComplete="off"
                      className="form-control"
                      type="text"
                      name="OwnerAddress"
                      value={state.OwnerAddress}
                      id="OwnerAddress"
                      onChange={(e) => setLocation("OwnerAddress", e)}
                    />
                  </div>
                </div>
                <div className="text-right two-btns-view">
                  <button
                    type="button"
                    className="btn btn-back"
                    onClick={() => nextPage(1)}
                  >
                    Back{" "}
                  </button>
                  <button
                    type="button"
                    className="btn"
                    onClick={() => nextPage(3)}
                  >
                    Next{" "}
                  </button>
                </div>
              </form>
            </div>
          )}
          {state.pageNumber == 3 && (
            <div>
              <form>
                <h1>Health & Routine (Optional)</h1>
                <h2>Qualities</h2>
                <ul className="qualities">
                  {listofQualities.map((bt) => (
                    <li key={bt.title}>
                      {" "}
                      <button
                        type="button"
                        id="Qualities"
                        onClick={() => handleQualitie(bt.title)}
                        className={
                          state.Qualities.includes(bt.title)
                            ? "btn active"
                            : "btn"
                        }
                      >
                        {bt.title}
                      </button>
                    </li>
                  ))}
                </ul>
                <div className="health-info">
                  <div className="form-group">
                    <label>Health issues in the past?</label>
                    <textarea
                      rows="3"
                      type="text"
                      value={state.Healthissues || ""}
                      className="form-control"
                      id="Healthissues"
                      name="Healthissues"
                      onChange={(e) => inputHandler(e)}
                      placeholder="type here"
                    />
                  </div>
                  <div className="form-group">
                    <label>Care Routine</label>
                    <textarea
                      rows="3"
                      value={state.CareRoutine || ""}
                      className="form-control"
                      id="CareRoutine"
                      name="CareRoutine"
                      onChange={(e) => inputHandler(e)}
                      placeholder="Explain your care routine so your property manager knows they will be well cared for. Include your usual routine."
                    />
                  </div>
                </div>
                <div className="text-right two-btns-view">
                  <button
                    type="button"
                    className="btn btn-back"
                    onClick={() => nextPage(2)}
                  >
                    Back{" "}
                  </button>
                  <button
                    type="button"
                    className="btn"
                    onClick={() => nextPage(4)}
                  >
                    Next{" "}
                  </button>
                </div>
              </form>
            </div>
          )}
          {state.pageNumber == 4 && (
            <div>
              <form>
                <h1>Training & Other (Optional)</h1>
                <div className="form-group">
                  <label>Has your pet graduated from a training class?</label>
                  <div>
                    <Radio.Group
                      onChange={onChange}
                      id="graduated"
                      value={state.graduated || ""}
                    >
                      <label className="radio-inline">
                        {" "}
                        <Radio value={"Yes"}>Yes</Radio>
                      </label>
                      <label className="radio-inline">
                        {" "}
                        <Radio value={"No"}>No</Radio>
                      </label>
                    </Radio.Group>
                  </div>
                </div>
                <h2>Groomer</h2>
                <div className="two-tails">
                  <div className="form-group">
                    <label>Groomer Name</label>
                    <input
                      type="text"
                      value={state.Groomername || ""}
                      className="form-control"
                      id="Groomername"
                      name="Groomername"
                      onChange={(e) => inputHandler(e)}
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="form-group">
                    <label>Mobile</label>
                    <div className="position-relative flag-posotion">
                      <div className="phone-flag">
                        <IntTelDropDown
                          handleCountryCodeChange={handleCountryCodeChange}
                        />
                        <input
                          type="tel"
                          className="form-control pl-5"
                          autoComplete="off"
                          value={state.GroomerMobile || ""}
                          placeholder="Enter Mobile"
                          id="GroomerMobile"
                          name="GroomerMobile"
                          onChange={(e) => inputHandler(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <h2>Vet</h2>
                <div className="two-tails">
                  <div className="form-group">
                    <label>Vet Name</label>
                    <input
                      type="text"
                      value={state.Vetname || ""}
                      className="form-control"
                      id="Vetname"
                      name="Vetname"
                      onChange={(e) => inputHandler(e)}
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="form-group">
                    <label>Mobile</label>
                    <div className="position-relative flag-posotion">
                      <div className="phone-flag">
                        <IntTelDropDown
                          handleCountryCodeChange={handleCountryCodeChange}
                        />
                        <input
                          type="tel"
                          className="form-control pl-5"
                          autoComplete="off"
                          value={state.VetMobile || ""}
                          placeholder="Enter Mobile"
                          id="VetMobile"
                          name="VetMobile"
                          onChange={(e) => inputHandler(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <h2>References</h2>
                <div className="two-tails">
                  <div className="form-group">
                    <label>Previous Landlord Name</label>
                    <input
                      type="text"
                      value={state.landlord || ""}
                      className="form-control"
                      id="landlord"
                      name="landlord"
                      onChange={(e) => inputHandler(e)}
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="form-group">
                    <label>Mobile</label>
                    <div className="position-relative flag-posotion">
                      <div className="phone-flag">
                        <IntTelDropDown
                          handleCountryCodeChange={handleCountryCodeChange}
                        />
                        <input
                          type="tel"
                          className="form-control pl-5"
                          autoComplete="off"
                          value={state.ReferencesMobile || ""}
                          placeholder="Enter Mobile"
                          id="ReferencesMobile"
                          name="ReferencesMobile"
                          onChange={(e) => inputHandler(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-right two-btns-view">
                  <button
                    type="button"
                    className="btn btn-back"
                    onClick={() => nextPage(3)}
                  >
                    Back{" "}
                  </button>
                  <button
                    type="button"
                    className="btn"
                    onClick={() => nextPage(5)}
                  >
                    Next{" "}
                  </button>
                </div>
              </form>
            </div>
          )}
          {state.pageNumber == 5 && (
            <div>
              <div className="submit-download-view">
                <div className="item">
                  <img
                    className="btnImg"
                    src="/assets/images/pdf-download.png"
                  />
                  <div>
                    <div id="content" className="divContent"></div>
                    <div id="editor"></div>
                    <button
                      id="cmd"
                      className="btn"
                      onClick={() => pdfmethod()}
                    >
                      Download
                    </button>
                  </div>
                </div>

                <div className="item">
                  <img
                    className="btnImgEmail"
                    src="/assets/images/send-email.png"
                  />
                  <button
                    type="button"
                    className="btn"
                    onClick={(e) => openPopModal()}
                  >
                    Send
                  </button>
                </div>
              </div>
              <div className="mt-5 text-center">
                <button
                  type="button"
                  className="btn btn-back"
                  onClick={() => nextPage(4)}
                >
                  <i class="fa-regular fa-arrow-left me-2"></i> Go Back and Edit
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {/*  */}
      <Footer />
      <ResumePopUp
        state={state}
        closePop={() => setState({ ...state, popUp: false, success: false })}
        inputHandler={inputHandler}
        setState={setState}
        sendmail={sendmail}
      />
    </div>
  );
};
export default PetResume;
