import "./iternaryProperties.css";
import ApiServices from "../../../../services/apiServices";
import ThirdParty from "../../../../services/thirdPartyService";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as $ from "jquery";
import _ from "lodash";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";

const IterenaryProperties = (props) => {
  var _isMounted = false;
  const [state, setState] = useState({
    consultantName: props.consultantName,
    consultantpic: props.consultantpic,
    consultantemail: props.consultantemail,
    itineraryPropertiesList: props.itineraryPropertiesList,
    consultantphone: props.consultantphone,
    transfareeName: props.transfareeName,
    pickupLocation: props.pickupLocation,
    phonenumber: props.phonenumber,
    emailId: props.emailId,
    notes: "",
    propertyAnalytics: [],
    scheduleuniqueId: props.scheduleuniqueId,
    stateObject: [],
    overallfeedback: props.overallfeedback,
    stateFeedback: "",
    isUpdated: false,
    transfereeEmail: "",
    succesMsg: "Your Feedback have been saved successfully!",
  });

  const confirmationFunc = async () => {
    confirmAlert({
      title: "",
      message: state.succesMsg,
      buttons: [
        {
          label: "Close",
          onClick: () => "",
        },
      ],
    });
  };

  useEffect(() => {
    const fetch = async () => {
      let getSessionData = await ThirdParty.getLoginInfo({});
      await setState({ transfereeEmail: getSessionData.EmailId });
      updatePropertyData();
    };
    fetch();
  }, []);

  const updatePropertyData = async (e) => {
    await setState({
      stateObject: [],
      stateFeedback: "",
      isUpdated: false,
    });
    let stateObject = [];
    let updateProperties = Object.assign([], state.itineraryPropertiesList);
    _.forEach(updateProperties, function (value) {
      stateObject[value.propertyanalyticsuniqueId] = {
        propertyanalyticuniqueId: value.propertyanalyticsuniqueId,
        rating: value.rating != undefined ? value.rating : 0,
        notes: value.notes != undefined ? value.notes : "",
        isvisited: false,
        isinterested: false,
        isliked: false,
        isstaying: false,
        property_image: value.property_image,
        address: value.address,
        source: value.source,
        propertyjson: value.propertyjson,
      };
    });
    await setState({
      stateObject: stateObject,
      stateFeedback: state.overallfeedback,
      isUpdated: true,
    });
  };

  //async componentWillMount() {}

  useEffect(() => {
    return () => {
      _isMounted = false;
    };
  });

  const sendEmail = async (finalObject) => {
    let propertyDetailsArray = state.stateObject;

    await setState({ succesMsg: "" });
    let data = {
      propertyDetailsArray: finalObject,
      overallfeedback: state.stateFeedback,
      scheduledDetails: props.RowResult,
      consultantName: state.consultantName,
      consultantemail: state.consultantemail,
      consultantphone: state.consultantphone,
      subject: "Feedback details",
      isFeedback: true,
      email: state.consultantemail,
      transfereeEmail: state.transfereeEmail,
      cc: state.transfereeEmail,
    };
    let sendSms = await ThirdParty.sendEmail(data);
    if (sendSms.response == true) {
      await setState({ succesMsg: "Feedback Submitted successfully" });
      confirmationFunc();
    } else {
      await setState({ confirmError: "Please try again!" });
    }
  };

  const saveProperties = async (data) => {
    await setState({ succesMsg: "" });
    let propertyAnalyticsArray = state.stateObject;

    let transfereepropertiesrating = [];
    Object.keys(propertyAnalyticsArray).map(function (key, value) {
      transfereepropertiesrating.push(propertyAnalyticsArray[key]);
    });
    let finalObject = {
      transfereepropertiesrating,
      transfereescheduleuniqueId: state.scheduleuniqueId,
      overallfeedback: state.stateFeedback,
    };

    let result = await ApiServices.UpdateTransfereePropertyAnalytics(
      finalObject
    );
    if (result.error == 0) {
      if (data.isMail) {
        await sendEmail(finalObject);
      } else {
        await setState({
          succesMsg: "Your Feedback have been saved successfully",
        });
        confirmationFunc();
      }
    } else {
      await setState({ succesMsg: result.message });
      confirmationFunc();
    }
  };
  const updateRating = async (e, row, rating) => {
    // $('.rating-num-block li.rating').removeClass('rating');

    $("#" + row.propertyanalyticsuniqueId + rating).addClass("rating");
    let propertyArray = state.stateObject;
    if (propertyArray[row.propertyanalyticsuniqueId] == undefined) {
      let propertyObj = {
        propertyanalyticuniqueId: row.propertyanalyticsuniqueId,
        rating: rating,
        notes: "",
        isvisited: false,
        isinterested: false,
        isliked: false,
        isstaying: false,
      };
      propertyArray[row.propertyanalyticsuniqueId] = propertyObj;
    } else {
      propertyArray[row.propertyanalyticsuniqueId].notes =
        propertyArray[row.propertyanalyticsuniqueId].notes;
      propertyArray[row.propertyanalyticsuniqueId].rating = rating;
    }
    await setState({ stateObject: propertyArray });
  };
  const getMoreInfo = async (e, row) => {
    let GetTransfereePropertiesObject = {
      transfereeuniqueId: row.transfereeUId,
      propertyuniqueId: row.propertyuniqueId,
    };
    let GetTransfereeProperties = await ApiServices.GetTransfereeProperties(
      GetTransfereePropertiesObject
    );
    if (GetTransfereeProperties.error == 0) {
      if (GetTransfereeProperties.message != undefined) {
        if (
          GetTransfereeProperties.message.transfereepropertyList[0] != undefined
        ) {
          let propertyDetails =
            GetTransfereeProperties.message.transfereepropertyList[0];
          await localStorage.setItem(
            "myPropertyDetails",
            JSON.stringify(propertyDetails)
          );
          window.open("/my_property_details");
        }
      }
    }
  };
  const inputHandler = async (inputName, row, e) => {
    e.preventDefault();
    if (inputName == "overallfeedback") {
      await setState({ stateFeedback: e.target.value });
    } else {
      let propArray = state.stateObject;
      if (propArray[row.propertyanalyticsuniqueId] != undefined) {
        propArray[row.propertyanalyticsuniqueId].notes = e.target.value;
      } else {
        let propertyObj = {
          propertyanalyticuniqueId: row.propertyanalyticsuniqueId,
          rating: "",
          notes: e.target.value,
          isvisited: false,
          isinterested: false,
          isliked: false,
          isstaying: false,
        };
        propArray[row.propertyanalyticsuniqueId] = propertyObj;
      }
      await setState({ stateObject: propArray });
    }
  };
  return (
    <div className="ds_newlanding">
      <div className="DSitinerary-today">
        <div className="container">
          <div
            className="sch_intinery_back"
            onClick={() => props.showItenaries()}
          >
            <i className="fa fa-long-arrow-left"></i> Back
          </div>
          <div className="sch_appointments_thumbs viewmore">
            <div className="item">
              <h1>{props.RowResult.title}</h1>
              <ul>
                <li>{moment(props.RowResult.scheduledate).format("LL")}</li>
                <li> {props.RowResult.schedulefromtime} </li>
                <li>{props.RowResult.pickuplocation}</li>
              </ul>

              <h2>With</h2>
              <p> {state.consultantName} </p>
              <p>
                {" "}
                <i className="fa fa-phone"></i> {state.consultantphone}{" "}
              </p>
              <p>
                <i className="fa fa-envelope-open"></i> {state.consultantemail}{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="ItineraryMap-block">
        <div className="container">
          {state.itineraryPropertiesList.length > 0 && (
            <div className="row">
              {state.itineraryPropertiesList.map((row, index) => {
                return row.address != "" && row.address != null ? (
                  <div className="col-md-6" key={index}>
                    <a href="javascript:void(0)">
                      <div className="propertyMapbox-block">
                        <div className="propertyMapbox-head">
                          <div className="mapbox-address">
                            <div className="mapbox-pointer">
                              <div className="mapbox-count">
                                <span className="font-blod-x">{index + 1}</span>
                              </div>
                            </div>
                            <span>{row.address} </span>{" "}
                          </div>
                          <div className="mapbox-time">
                            <span className="font-blod color-dark-grey">
                              {row.available_till_date != null
                                ? moment(row.available_till_date).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </span>
                          </div>
                        </div>

                        <div className="propertyMapbox-map">
                          <img
                            src={
                              row.property_image != null &&
                              row.property_image != ""
                                ? row.property_image.split(",")[0]
                                : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
                            }
                            alt=""
                          />
                        </div>

                        <div
                          className="propertyMapbox-head"
                          style={{ paddingTop: "20px", textAlign: "center" }}
                        >
                          <div
                            className="mapbox-time"
                            style={{ width: "100%" }}
                          >
                            <span
                              className="font-blod color-dark-grey"
                              onClick={(e) => getMoreInfo(e, row)}
                            >
                              View More
                            </span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                ) : (
                  ""
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="ItineraryList-block">
        <div className="container">
          {state.itineraryPropertiesList.map((row, index) => {
            return row.address != "" && row.address != null ? (
              <div className="property-listbox-block" key={index}>
                <div className="property-listbox-flex">
                  <div className="propertyMaplist-block">
                    <div className="propertyMaplist-block_first-c">
                      <div className="mapbox-pointer">
                        <div className="mapbox-count">
                          <span className="font-blod-x">{index + 1}</span>
                        </div>
                      </div>

                      <div className="propertyMaplist-image-block">
                        {" "}
                        <img
                          src={
                            row.property_image != null &&
                            row.property_image != ""
                              ? row.property_image.split(",")[0]
                              : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
                          }
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="propertyMaplist-address-block">
                      <div>
                        <h1 className="font-blod">{row.propertyname}</h1>
                        <p> {row.address}</p>
                      </div>
                      <div className="rating-block">
                        <div className="property-rate-head">
                          {" "}
                          <FontAwesomeIcon icon={faStar} /> Rate this property{" "}
                        </div>
                        <div className="rating-num-block">
                          {/* {state.stateObject[row.propertyanalyticsuniqueId] != undefined ? JSON.stringify(state.stateObject[row.propertyanalyticsuniqueId].rating) : ""} */}
                          <ul>
                            <li
                              className={
                                (state.stateObject[
                                  row.propertyanalyticsuniqueId
                                ] != undefined
                                  ? state.stateObject[
                                      row.propertyanalyticsuniqueId
                                    ].rating
                                  : 0) == "1"
                                  ? "font-blod rating"
                                  : "font-blod"
                              }
                              value="1"
                              id={row.propertyanalyticsuniqueId + "1"}
                              onClick={(e) => updateRating(e, row, "1")}
                            >
                              1
                            </li>
                            <li
                              className={
                                (state.stateObject[
                                  row.propertyanalyticsuniqueId
                                ] != undefined
                                  ? state.stateObject[
                                      row.propertyanalyticsuniqueId
                                    ].rating
                                  : 0) == "2"
                                  ? "font-blod rating"
                                  : "font-blod"
                              }
                              value="2"
                              id={row.propertyanalyticsuniqueId + "2"}
                              onClick={(e) => updateRating(e, row, "2")}
                            >
                              2
                            </li>
                            <li
                              className={
                                (state.stateObject[
                                  row.propertyanalyticsuniqueId
                                ] != undefined
                                  ? state.stateObject[
                                      row.propertyanalyticsuniqueId
                                    ].rating
                                  : 0) == "3"
                                  ? "font-blod rating"
                                  : "font-blod"
                              }
                              value="3"
                              id={row.propertyanalyticsuniqueId + "3"}
                              onClick={(e) => updateRating(e, row, "3")}
                            >
                              3
                            </li>
                            <li
                              className={
                                (state.stateObject[
                                  row.propertyanalyticsuniqueId
                                ] != undefined
                                  ? state.stateObject[
                                      row.propertyanalyticsuniqueId
                                    ].rating
                                  : 0) == "4"
                                  ? "font-blod rating"
                                  : "font-blod"
                              }
                              value="4"
                              id={row.propertyanalyticsuniqueId + "4"}
                              onClick={(e) => updateRating(e, row, "4")}
                            >
                              4
                            </li>
                            <li
                              className={
                                (state.stateObject[
                                  row.propertyanalyticsuniqueId
                                ] != undefined
                                  ? state.stateObject[
                                      row.propertyanalyticsuniqueId
                                    ].rating
                                  : 0) == "5"
                                  ? "font-blod rating"
                                  : "font-blod"
                              }
                              value="5"
                              id={row.propertyanalyticsuniqueId + "5"}
                              onClick={(e) => updateRating(e, row, "5")}
                            >
                              5
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {state.stateObject[row.propertyanalyticsuniqueId] !=
                    undefined && (
                    <div className="propertyMaplist-rating-block">
                      <textarea
                        name=""
                        id="text0"
                        cols="35"
                        rows="5"
                        className="form-control"
                        placeholder="Notes"
                        defaultValue={
                          state.stateObject[row.propertyanalyticsuniqueId] !=
                          undefined
                            ? state.stateObject[row.propertyanalyticsuniqueId]
                                .notes
                            : ""
                        }
                        onChange={(e) => inputHandler("notes", row, e)}
                      ></textarea>
                    </div>
                  )}
                </div>
              </div>
            ) : null;
          })}
          <div className="bottom_line">
            <h1>How was your overall tour with {state.consultantName}?</h1>
            {state.isUpdated && (
              <textarea
                className="form-control"
                rows="5"
                defaultValue={
                  state.stateFeedback != "" ? state.stateFeedback : ""
                }
                onChange={(e) => inputHandler("overallfeedback", {}, e)}
              ></textarea>
            )}
            <div className="text-center">
              {/* <button className="btn" onClick={() => saveProperties({ isMail: true })} style={{ textTransform: "none" }}>Send to my Email</button> */}
              <button
                className="btn"
                onClick={() => saveProperties({ isMail: true })}
              >
                Submit
              </button>
              <button className="btn" onClick={(e) => updatePropertyData(e)}>
                {" "}
                <i className="fa fa-refresh"></i>{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IterenaryProperties;
