import React, { useEffect, useState } from "react";
import RecommendedRentals from "./RecommendedRentals";
import ApiServices from "../../../../../services/apiServices";
import RecommendEditRental from "../modals/RecommendEditRentalModal";
import NotIntrestedRentalsModal from "../modals/NotIntrestedRentalsModal";

const ConsultantSaProperties = ({ transfereeuniqueId }) => {
  const [state, setState] = useState({
    saPropertiesList: [],
    saPropertiesListInactive: [],
    isLoading: false,
    editMode: false,
    editRentalData: null,
  });

  function changeEditMode(mode, editData = null) {
    updateState({ editMode: mode, editRentalData: editData });
  }

  function updateLoaderState(action) {
    if (action === "start") {
      updateState({ isLoading: true });
    } else if (action === "stop") {
      updateState({ isLoading: false });
    }
  }

  function filterProperties(transfereepropertyList) {
    return transfereepropertyList.filter((element) => {
      if (element.propertyjson) {
        element.property_image =
          JSON.parse(element.propertyjson).Photo != null
            ? "https://s3-us-west-1.amazonaws.com/destination-services-itinerary/" +
              JSON.parse(element.propertyjson).Photo +
              ".jpg"
            : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png";
      }
      return (
        element.source == "SA_Properties" &&
        element.isinterested &&
        element.isactive &&
        element.apiId
      );
    });
  }

  function filterInactiveProperties(transfereepropertyList) {
    return transfereepropertyList.filter((element) => {
      return (
        element.source == "SA_Properties" &&
        element.isinterested == false &&
        element.isactive == true
      );
    });
  }

  async function getMyProperties(transfereeuniqueId) {
    updateLoaderState("start");
    let GetTransfereePropertiesObject = {
      transfereeuniqueId: transfereeuniqueId,
    };

    try {
      let GetTransfereeProperties = await ApiServices.GetTransfereeProperties(
        GetTransfereePropertiesObject
      );
      if (GetTransfereeProperties.error == 0) {
        let saPropertiesList = filterProperties(
          GetTransfereeProperties.message.transfereepropertyList
        );

        let saPropertiesListInactive = filterInactiveProperties(
          GetTransfereeProperties.message.transfereepropertyList
        );

        console.log(saPropertiesList, saPropertiesListInactive);
        updateState({
          saPropertiesList,
          saPropertiesListInactive,
        });
      }
    } catch (e) {
      console.log(e);
    }

    updateLoaderState("stop");
  }

  function openNotInterested() {}

  function onClickAddProperty() {
    changeEditMode(false);
  }

  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  useEffect(() => {
    getMyProperties(transfereeuniqueId);
  }, []);

  return (
    <>
      <div className="my_properties_lists">
        <div className="main_content">
          <div className="container">
            <h1>
              <b>Rentals</b>
              <span className="cursor add-list">
                {state.saPropertiesListInactive.length > 0 ? (
                  <span
                    data-bs-toggle="modal"
                    data-bs-target="#not-intrested-rentals"
                    className="inactiveRentals"
                    style={{ paddingRight: "12px" }}
                    onClick={openNotInterested}
                  >
                    Not Interested Rentals
                  </span>
                ) : null}
                <button
                  type="submit"
                  className="btn theme-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#recommend-edit-rental"
                  onClick={onClickAddProperty}
                >
                  <i className="fa fa-plus-square-o" aria-hidden="true"></i>{" "}
                  Recommend Rental
                </button>
              </span>
            </h1>

            {/* recommended properties */}
            <RecommendedRentals
              saPropertiesList={state.saPropertiesList}
              saPropertiesListInactive={state.saPropertiesListInactive}
              isLoading={state.isLoading}
              changeEditMode={changeEditMode}
              getMyProperties={getMyProperties}
            />

            {/* modals */}
          </div>
        </div>
      </div>
      <RecommendEditRental
        editMode={state.editMode}
        transfereeuniqueId={transfereeuniqueId}
        editRentalData={state.editRentalData}
        getMyProperties={getMyProperties}
      />
      <NotIntrestedRentalsModal
        saPropertiesListInactive={state.saPropertiesListInactive}
      />
    </>
  );
};

export default ConsultantSaProperties;
