import React from "react";
import { Popover } from "antd";

import { confirmAlert } from "react-confirm-alert";

import ApiServices from "../../../../../services/apiServices";

const RentalCard = ({
  propertyDetails,
  changeEditMode,
  isNotIntrested,
  getMyProperties,
}) => {
  function renderPriorityItems(propertyDetails) {
    return propertyDetails.propertyrankitems.propertyrankingitems.map(
      (item) => (
        <div key={`priority-${item.prioritylistitem}`}>
          {/* <span>{item.prioritylistitem}</span> */}
          <p>
            {item.prioritylistitem == "Must Haves" ? (
              <Popover
                placement="leftTop"
                content={
                  <div>
                    {item.matcheditems.map((matcheditem) => {
                      return (
                        matcheditem.itemname != "" && (
                          <p>
                            {matcheditem.itemname}
                            {matcheditem.ismatched == true && (
                              <i className="fa fa-check"></i>
                            )}
                            {matcheditem.ismatched == false && (
                              <i className="fa fa-times"></i>
                            )}
                          </p>
                        )
                      );
                    })}
                  </div>
                }
                title="Matched Must Haves"
                trigger="hover"
              >
                {item.prioritylistitem}{" "}
                <i
                  className="fa fa-info-circle"
                  style={{ color: "#000000" }}
                ></i>
              </Popover>
            ) : (
              item.prioritylistitem
            )}{" "}
            {item.ismatched ? (
              <i
                className={`fa ${item.isallmatched === true ? "fa-check" : ""}
                ${item.isallmatched === false ? "fa-check yellow_clr" : ""}
                ${item.isallmatched === null ? "fa-check" : ""}
                `}
              ></i>
            ) : (
              <i className="fa fa-times"></i>
            )}
          </p>
        </div>
      )
    );
  }

  function renderMatchedPriorities(propertyDetails) {
    return (
      <Popover
        placement="leftTop"
        content={renderPriorityItems(propertyDetails)}
        title="Matched Priorities"
        trigger="hover"
      >
        {Math.trunc(propertyDetails.propertyrankitems.matchpercent)}%
      </Popover>
    );
  }

  function renderScoreLabel(propertyDetails) {
    return (
      <span className="ScoreLabel">
        {renderMatchedPriorities(propertyDetails)}
      </span>
    );
  }

  function onClickEditProperty(propertyDetails) {
    changeEditMode(true, propertyDetails);
  }

  function deleteConfirmation(propertyDetails) {
    confirmAlert({
      title: "",
      message: "Are you sure do you want to delete this rental.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteSaProperties(propertyDetails),
        },
        {
          label: "No",
        },
      ],
    });
  }

  async function deleteSaProperties(row) {
    let propertyObjectDelete = {
      apiId: row.apiId,
      transfereeuniqueId: row.transfereeUId,
      propertyanalyticsuniqueId: row.propertyanalyticsuniqueId,
      propertyuniqueId: row.propertyuniqueId,
      isdelete: true,
    };
    let addPropertyResultDelete = await ApiServices.CreateTransfereeProperties(
      propertyObjectDelete
    );
    getMyProperties(row.transfereeUId);
  }

  async function moreInfoWithDetails(propertyDetails) {
    await localStorage.setItem(
      "myPropertyDetails",
      JSON.stringify(propertyDetails)
    );
    window.open("/SADetails", "myWindow");
  }

  return (
    <div className="item">
      {renderScoreLabel(propertyDetails)}
      <label>
        <img
          src={
            propertyDetails.property_image != null &&
            propertyDetails.property_image != ""
              ? propertyDetails.property_image.split(",")[0]
              : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
          }
          alt=""
        />
        <div className="content">
          <h5 className="fw-bold">{propertyDetails.propertyname} </h5>
          <p>
            {/* {propertyDetails.city} {propertyDetails.state} */}
            {propertyDetails.address}
          </p>
          <span>
            {!isNotIntrested && (
              <div className="d-flex align-items-center">
                <div
                  onClick={() => deleteConfirmation(propertyDetails)}
                  className="me-3"
                >
                  <i className="fa fa-trash"></i>
                </div>
                <div
                  data-bs-toggle="modal"
                  data-bs-target="#recommend-edit-rental"
                  onClick={() => onClickEditProperty(propertyDetails)}
                >
                  <i className="fa fa-edit"></i>
                </div>
              </div>
            )}

            {isNotIntrested && (
              <>
                <div></div>
              </>
            )}
            {propertyDetails.latitude != "" &&
            propertyDetails.latitude != null ? (
              <a
                style={{ color: "#0066cc" }}
                onClick={() => moreInfoWithDetails(propertyDetails)}
              >
                More
              </a>
            ) : (
              ""
            )}
          </span>
        </div>
      </label>
    </div>
  );
};

export default RentalCard;
