// import "./dsProfile.css";
import ApiServices from "../../../../services/apiServices";
import LoginStore from "../../../../services/thirdPartyService";
import { useState, useEffect } from "react";
import { Spin } from "antd";
import {
  faUser,
  faTasks,
  faPlusCircle,
  faComment,
  faCalculator,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as $ from "jquery";
import DatePicker from "react-datepicker";
import axios from "axios";
import _ from "lodash";
import moment from "moment";

const DsMyProperties = () => {
  var _isMounted = false;
  const [state, setState] = useState({
    cityLoader: false,
    latitude: "",
    longitude: "",
    nearByCitiesWithStates: [],
    nearByCities: [],
    workLocationAddress: "",
    updatedDate: "",
    propertyName: "",
    propertyLink: "",
    address: "",
    propertyNotes: "",
    loader: false,
    latitude: "",
    longitude: "",
    isPropertyError: false,
    userUniqueId: "",
    errorMessage: "",
    propertySuccess: false,
    myPropertiesList: "",
    propertyLoader: false,
    state: "",
    city: "",
    country: "",
    EditMoveDate: null,
    uploadImages: [],
    uploadLoader: false,
    availableTillDate: null,
    modalpopupInfo: {},
    showModal: false,
  });
  const cityDetails = async (row) => {
    await localStorage.setItem("cDetails", JSON.stringify(row));
    location.href = "/city-landing";
  };
  const onClickAddProperty = async () => {
    await setState({ propertySuccess: false, isPropertyError: false });
    $("document").ready(function () {
      $("#add-property-modal").modal("show");

      $("#add-property-modal").find("input").val("").end();
    });
  };
  const setLocation = async (Id, e) => {
    if (e.target.value.trim() == null || e.target.value.trim() == "") {
      $("#" + Id).addClass("is-invalid");
    } else {
      $("#" + Id).removeClass("is-invalid");
    }
    let LocationObje = {};
    LocationObje[Id] = e.target.value;
    await setState(LocationObje);
    const _this = this;
    var places = new google.maps.places.Autocomplete(
      document.getElementById(Id)
    );
    google.maps.event.addListener(places, "place_changed", async () => {
      var place = places.getPlace();
      var placeDetails = {
        locality: "long_name",
        administrative_area_level_1: "short_name",
        country: "long_name",
      };
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (placeDetails[addressType]) {
          var val = place.address_components[i][placeDetails[addressType]];
          placeDetails[addressType] = val;
        }
      }
      let relocatedAddress = place.formatted_address;
      await setState({
        address: relocatedAddress,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        state: placeDetails.administrative_area_level_1,
        city: placeDetails.locality,
      });
    });
  };
  const inputHandler = async (inputName, event) => {
    event.persist();
    let inputVal = event.target.value;
    let inputObject = {};
    inputObject[inputName] = inputVal;
    if (inputVal.trim() == null || inputVal.trim() == "") {
      $("#" + inputName).addClass("is-invalid");
    } else {
      $("#" + inputName).removeClass("is-invalid");
    }
    await setState(inputObject);
    event.persist();
  };

  const addMyProperties = async (value) => {
    let errorUpdate = 0;
    await setState({
      loader: true,
      isPropertyError: false,
      propertySuccess: false,
    });

    if (state.propertyName.trim() == "" || state.propertyName == null) {
      $("#propertyName").addClass("is-invalid");
      await setState({ loader: false });
      errorUpdate++;
    } else {
      $("#propertyName").removeClass("is-invalid");
    }
    if (state.propertyLink.trim() == "" || state.propertyLink == null) {
      await setState({ loader: false });
      $("#propertyLink").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#propertyLink").removeClass("is-invalid");
    }
    if (
      state.address.trim() == "" ||
      state.address == null ||
      state.state == "" ||
      state.city == "" ||
      state.latitude == "" ||
      state.longitude == ""
    ) {
      await setState({ loader: false });
      $("#address").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#address").removeClass("is-invalid");
    }
    if (state.propertyNotes.trim() == "" || state.propertyNotes == null) {
      await setState({ loader: false });
      $("#propertyNotes").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#propertyNotes").removeClass("is-invalid");
    }
    if (state.uploadImages.length == 0 || state.uploadImages == null) {
      await setState({ loader: false });
      $("#propertyImage").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#propertyImage").removeClass("is-invalid");
    }
    console.log(state);
    if (errorUpdate == 0) {
      let propertyObject = {
        transfereeuniqueId: state.userUniqueId,
        apiId: Math.floor(1000000000000 + Math.random() * 9000000000000),
        source: "my_property",
        city: state.city,
        isdelete: false,
        propertyname: state.propertyName,
        address: state.address,
        state: state.state,
        latitude: state.latitude,
        longitude: state.longitude,
        propertylink: state.propertyLink,
        notes: state.propertyNotes,
        isIncluded: value,
        property_image: state.uploadImages.toString(),
        available_till_date: state.availableTillDate,
      };

      let addPropertyResult = await ApiServices.CreateTransfereeProperties(
        propertyObject
      );
      if (addPropertyResult.error == 0) {
        $("document").ready(function () {
          $("#add-property-modal").find("input").val("").end();
        });
        getMyProperties();
        setState({
          loader: false,
          propertySuccess: true,
          propertyName: "",
          propertyLink: "",
          address: "",
          propertyNotes: "",
          uploadImages: [],
          availableTillDate: null,
          EditMoveDate: null,
        });
      } else {
        setState({
          isPropertyError: true,
          loader: false,
          errorMessage: addPropertyResult.message.status,
        });
      }
    }
  };

  const updateDate = async (date) => {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
    if (_isMounted) {
      await setState({
        updatedDate: day + " " + monthNames[monthIndex] + " " + year,
      });
    }
    return day + " " + monthNames[monthIndex] + " " + year;
  };
  const getMyProperties = async () => {
    if (_isMounted) {
      await setState({ propertyLoader: true });
    }
    let GetTransfereePropertiesObject = {
      transfereeuniqueId: state.userUniqueId,
    };
    let GetTransfereeProperties = await ApiServices.GetTransfereeProperties(
      GetTransfereePropertiesObject
    );

    if (GetTransfereeProperties.error == 0) {
      let myPropertiesList = _.filter(
        GetTransfereeProperties.message.transfereepropertyList,
        function (element) {
          return element.source == "my_property";
        }
      );
      //console.log(myPropertiesList, "myPropertiesList")
      if (_isMounted) {
        await setState({
          myPropertiesList: myPropertiesList,
          propertyLoader: false,
        });
      }
    }
  };
  useEffect(() => {
    _isMounted = true;
    const fetch = async () => {
      updateDate(new Date());
      let getSessionData = await LoginStore.getLoginInfo({});
      if (_isMounted) {
        setState({ userUniqueId: getSessionData.userUniqueId });
      }
      getMyProperties();
    };
    fetch();
    return async () => {
      _isMounted = false;
    };
  });

  const moreInfoWithDetails = async (row) => {
    $(".carousel-item").addClass("active");
    row.images =
      row.property_image != undefined
        ? row.property_image.split(",")
        : [
            "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png",
          ];
    row.amenities = ["No amenities added"];
    await setState({ modalpopupInfo: row, showModal: true });
    $("#moreInfoModal").modal("show");
  };

  const changeActiveImg = (id) => {
    $(".thumb").removeClass("active");
    $(".carousel-item").removeClass("active");
    $("#MIG" + id).addClass("active");
    $("#thumb" + id).addClass("active");
  };
  const handleDateChange = async (editDate) => {
    await setState({
      EditMoveDate: new Date(editDate),
      availableTillDate: moment(editDate).format("YYYY/MM/DD"),
    });
  };
  /** image upload */
  const onImageChangeHandler = async (event) => {
    let files = event.target.files;
    if (files.length > 5) {
      await setState({
        uploadImageError: "Only 5 images can be uploaded at a time.",
      });
      $("#optionimages").addClass("is-invalid");
    } else {
      let err = "";
      const types = ["image/png", "image/jpeg", "image/gif"];
      const data = new FormData();

      for (var x = 0; x < files.length; x++) {
        data.append("file", files[x]);
        // compare file type find doesn't matach
        if (types.every((type) => files[x].type !== type)) {
          // create error message and assign to container
          err += "Only supported png, jpeg, gif files format supported.";
        }
      }
      if (err !== "") {
        await setState({ uploadImageError: err });
        $("#propertyImage").addClass("is-invalid");
      } else {
        //console.log(files,"yes");

        await setState({ uploadImageError: "", uploadLoader: true });
        $("#propertyImage").removeClass("is-invalid");

        axios
          .post(`/upload`, data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            let data = [];
            _.forEach(response.data, async (response) => {
              data.push(response.Location);
              setState({ uploadImages: data });
            });
            setState({
              uploadImageError: "Successfully uploaded.",
              uploadLoader: false,
            });
          })
          .catch((error) => {
            console.log(error, "error");
            setState({ uploadLoader: false });
          });
      }
    }
  };
  return (
    <div className="savedsearchthumb">
      <h1>
        <b>MY PROPERTIES</b>{" "}
        <i className="cursor fa fa-user" onClick={onClickAddProperty}>
          <FontAwesomeIcon icon={faPlusCircle} />
        </i>{" "}
      </h1>

      <i>Updated {state.updatedDate}</i>
      {state.myPropertiesList.length > 0 ? (
        <div className="d-flex flex-wrap">
          {state.myPropertiesList.map((row, index) => {
            return (
              <div
                className="item pointercss"
                key={index}
                onClick={() => moreInfoWithDetails(row)}
              >
                <img
                  src={
                    row.property_image != null
                      ? row.property_image.split(",")[0]
                      : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
                  }
                  alt=""
                />
                <p>
                  {row.propertyname} <br />
                  {row.city} {row.state}
                </p>
              </div>
            );
          })}
        </div>
      ) : state.propertyLoader ? (
        <Spin />
      ) : null}

      <div
        id="add-property-modal"
        className="modal fade"
        role="dialog"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content ">
            {/* <div className="modal-header">
                <span className="title">MY PROPERTIES  </span>
                <span className="modal-close" data-dismiss="modal">&times;</span>
              </div> */}
            <div className="only_close">
              <span className="pointer close" data-dismiss="modal">
                &times;
              </span>
            </div>

            <form
              method="post"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              {state.isPropertyError == true ? (
                <h1 className="valid">{state.errorMessage}</h1>
              ) : (
                ""
              )}

              <h2> Have you already found some properties you like? </h2>
              <h3>Note them or their website below </h3>
              {state.propertySuccess == true ? (
                <h6 className="alert alert-success">
                  Added Property Successfully
                </h6>
              ) : (
                ""
              )}
              <div className="pop-form-controls focus-input-label mt-4">
                <div className="floating-label">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name of property/community"
                    autoComplete="new-password"
                    id="propertyName"
                    onChange={(e) => inputHandler("propertyName", e)}
                  />
                  <label>Name of property/community</label>
                </div>
                <div className="floating-label">
                  <input
                    placeholder="Enter Location"
                    className="form-control"
                    type="text"
                    autoComplete="new-password"
                    id="address"
                    onChange={setLocation.bind(this, "address")}
                  />
                  <label>Address</label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    placeholder="If you found a property online,paste the link here"
                    type="text"
                    id="propertyLink"
                    onChange={(e) => inputHandler("propertyLink", e)}
                  />
                  <label>
                    If you found a property online,paste the link here
                  </label>
                </div>
                <label className="label_cls">Available Till Date</label>
                <div className="date-pic-mypro mb-2">
                  <DatePicker
                    selected={state.EditMoveDate}
                    onChange={handleDateChange}
                    minDate={new Date()}
                    dateFormat="MM/dd/yyyy"
                    placeholderText={"MM/DD/YYYY"}
                    onKeyDown={(e) => e.preventDefault()}
                    className="form-control"
                  />
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </div>
                <label className="label_cls">
                  Upload Property Image <span>(only images) </span>{" "}
                </label>
                <div className="custom-file">
                  {state.uploadLoader ? (
                    <Spin />
                  ) : (
                    <input
                      type="file"
                      onChange={onImageChangeHandler}
                      multiple
                      id="propertyImage"
                      className="form-control fileupload"
                    />
                  )}
                </div>
                <label className="label_cls">Notes</label>
                <input
                  className="form-control"
                  type="text"
                  id="propertyNotes"
                  onChange={(e) => inputHandler("propertyNotes", e)}
                />
                <div className="text-center mt-3 mb-3">
                  {state.loader || state.uploadLoader ? (
                    <Spin />
                  ) : (
                    <button
                      type="submit"
                      className="btn theme-btn"
                      onClick={() => addMyProperties("true")}
                    >
                      ADD TO MY LIST <span></span>{" "}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/** details my property */}
      {state.showModal && (
        <div
          className="fade modal property_rrpopup propertyDetailsPopup"
          id="moreInfoModal"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="position-relative">
                      <div
                        id="carouselExampleFade"
                        className="carousel slide carousel-fade"
                        data-ride="carousel"
                      >
                        <div className="carousel-inner">
                          {state.modalpopupInfo.images.map(
                            (imgSignle, Imgindex) => {
                              return (
                                <div
                                  key={"tkey" + Imgindex}
                                  className={
                                    Imgindex == 0
                                      ? "carousel-item active"
                                      : "carousel-item "
                                  }
                                  id={"MIG" + Imgindex}
                                >
                                  <img
                                    src={imgSignle}
                                    className="d-block w-100"
                                    alt="Second slide"
                                  />
                                </div>
                              );
                            }
                          )}
                        </div>
                        <a
                          className="carousel-control-prev"
                          href="#carouselExampleFade"
                          role="button"
                          data-slide="prev"
                        >
                          <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="sr-only">Previous</span>
                        </a>
                        <a
                          className="carousel-control-next"
                          href="#carouselExampleFade"
                          role="button"
                          data-slide="next"
                        >
                          <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="sr-only">Next</span>
                        </a>
                        <ol className="carousel-indicators">
                          {state.modalpopupInfo.images.map(
                            (thumbimgSignle, thumbImgindex) => {
                              return (
                                <li
                                  data-target="#carousel-thumb"
                                  key={"thumbkey" + thumbImgindex}
                                  data-slide-to={thumbImgindex}
                                  id={"thumb" + thumbImgindex}
                                  onClick={() => changeActiveImg(thumbImgindex)}
                                  className={
                                    thumbImgindex == 0
                                      ? "thumb active"
                                      : "thumb active"
                                  }
                                >
                                  {" "}
                                  <img
                                    className="d-block w-100"
                                    src={thumbimgSignle}
                                  />
                                </li>
                              );
                            }
                          )}
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="popupmaintext">
                      <div className="form-group row">
                        <label className="col-4 col-form-label">
                          Property Name <b>:</b>{" "}
                        </label>
                        <div className="col-8">
                          <label className="cLabel-label col-form-label">
                            {state.modalpopupInfo.propertyname}
                          </label>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-4 col-form-label">
                          Address <b>:</b>{" "}
                        </label>
                        <div className="col-8">
                          <label className="cLabel-label col-form-label">
                            {state.modalpopupInfo.address}
                          </label>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-4 col-form-label">
                          Property Link <b>:</b>{" "}
                        </label>
                        <div className="col-8">
                          <label className="cLabel-label col-form-label">
                            <a
                              href={state.modalpopupInfo.propertylink}
                              target="_blank"
                            >
                              {state.modalpopupInfo.propertylink}{" "}
                            </a>
                          </label>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-4 col-form-label">
                          Available Date <b>:</b>{" "}
                        </label>
                        <div className="col-8">
                          <label className="cLabel-label col-form-label">
                            {state.modalpopupInfo.available_till_date != null
                              ? moment(
                                  state.modalpopupInfo.available_till_date
                                ).format("MM/DD/YYYY")
                              : "---"}{" "}
                          </label>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-4 col-form-label">
                          Notes <b>:</b>{" "}
                        </label>
                        <div className="col-8">
                          <label className="cLabel-label col-form-label">
                            {state.modalpopupInfo.notes}{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default DsMyProperties;
