import React from "react";
// import Modal from "react-modal";
import { Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ApiServices from "../../../../../services/apiServices";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { Radio, Spin } from "antd";

const AddEditModal = ({
  isOpen,
  onClose,
  state,
  setState,
  getTasks,
  ChangeCategory,
  changeType,
}) => {
  const handleDateChange = async (editDate) => {
    await setState((prevState) => ({
      ...prevState,
      editDate: new Date(editDate),
      deadlinedate: moment(editDate).format("YYYY/MM/DD"),
    }));
  };
  const onReminderType = async (e) => {
    e.persist();
    let InputValue = e.target.value;
    setState((prevState) => ({
      ...prevState,
      remindertype: InputValue,
    }));
  };
  const onChange = async (e) => {
    await setState((prevState) => ({
      ...prevState,
      reminder: e.target.value,
      reminderState: e.target.value,
    }));
  };
  const onDateChange = async (editDate) => {
    setState((prevState) => ({
      ...prevState,
      reminderby: new Date(editDate),
      reminderbyDate: moment(editDate).format("YYYY/MM/DD"),
    }));
  };
  const addTask = async (e) => {
    e.preventDefault();
    let errorVal = 0;
    await setState((prevState) => ({
      ...prevState,
      loader: true,
      TaskStatusSuccess: false,
    }));
    if (state.category.trim() == "") {
      $("#category").addClass("is-invalid");
      await setState((prevState) => ({ ...prevState, loader: false }));
      errorVal++;
    } else {
      $("#category").removeClass("is-invalid");
    }
    if (state.reminder == null) {
      $("#remainder").addClass("is-invalid");
      await setState((prevState) => ({ ...prevState, loader: false }));
      errorVal++;
    }
    if (state.ChecklistTypeId.trim() == "") {
      await setState((prevState) => ({ ...prevState, loader: false }));
      errorVal++;
    } else {
      $("#remainder").removeClass("is-invalid");
    }
    if (state.checklistdescription.trim() == "") {
      $("#checklistdescription").addClass("is-invalid");
      await setState((prevState) => ({ ...prevState, loader: false }));
      errorVal++;
    } else {
      $("#checklistdescription").removeClass("is-invalid");
    }
    if (state.deadlinedate == null) {
      $("#dead-line-date").addClass("is-invalid");
      await setState((prevState) => ({ ...prevState, loader: false }));
      errorVal++;
    } else {
      $("#dead-line-date").removeClass("is-invalid");
    }
    if (
      state.reminder == true &&
      (state.reminderby == null || state.reminderby == "")
    ) {
      $("#reminderby").addClass("is-invalid");
      await setState((prevState) => ({ ...prevState, loader: false }));
      errorVal++;
    } else {
      $("#reminderby").removeClass("is-invalid");
    }
    if (
      state.reminder == true &&
      (state.remindertype == null || state.remindertype == "")
    ) {
      $("#remindertype").addClass("is-invalid");
      await setState((prevState) => ({ ...prevState, loader: false }));
      errorVal++;
    } else {
      $("#remindertype").removeClass("is-invalid");
    }
    if (errorVal == 0) {
      let taskObject = {
        transfereechecklistuid: "",
        transfereeuid: state.userUniqueId,
        checklistdescription: state.checklistdescription,
        checklisttype: state.ChecklistTypeId,
        category: state.category,
        reminder: state.reminder,
        deadlinedate: state.deadlinedate,
        completeddate: state.completeddate,
        status: state.status,
        isdelete: false,
        remindertype: state.remindertype,
        reminderon: state.reminderbyDate,
      };
      if (state.transfereechecklistuid != "") {
        await setState((prevState) => ({
          ...prevState,
          succesMsg: "Task has been updated successfully",
        }));
        taskObject.transfereechecklistuid = state.transfereechecklistuid;
      }
      if (state.transfereechecklistuid == "") {
        await setState((prevState) => ({
          ...prevState,
          succesMsg: "Task has been added successfully",
        }));
      }
      let addTaskResult = await ApiServices.ManagerTransfereeCheckList(
        taskObject
      );
      if (addTaskResult.error == 0) {
        let data = {
          transfereeuid: state.userUniqueId,
        };
        const GetTransfereeStatus = await ApiServices.GetTransfereeStatus(data);
        let udc = [];
        if (GetTransfereeStatus.error == 0) {
          udc = await _.filter(
            GetTransfereeStatus.message.noteslist,
            function (o) {
              return o.statusuid == "deb8d951-c80b-43df-b711-dad2c49baa03";
            }
          );
        }

        if (state.selectedCategoty != "") {
          await setState((prevState) => ({ ...prevState, isCompTask: true }));
          await getTasks();
          await ChangeCategory(state.selectedCategoty);
          await changeType(state.selectedType);
        } else {
          await getTasks();
          await changeType(state.selectedType);
        }

        if (state.transfereechecklistuid == "") {
          setState((prevState) => ({
            ...prevState,
            loader: false,
            category: "",
            reminderby: "",
            remindertype: "",
            deadlinedate: null,
            editDate: "",
            reminder: "",
            TaskStatusSuccess: true,
            checklistdescription: "",
            ChecklistTypeId: "",
            confirmPassword: "",
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            loader: false,
            TaskStatusSuccess: true,
          }));
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          changePasswordErrorStatus: true,
          loader: false,
          errorMessage: addTaskResult.message,
        }));
      }
    }
  };
  const onChangeCategoryType = async (e) => {
    e.persist();
    let InputValue = e.target.value;
    await setState((prevState) => ({
      ...prevState,
      category: InputValue,
      isCompTask: false,
    }));
  };
  const onChangeChecklistType = (e) => {
    e.persist();
    let InputValue = e.target.value;
    setState((prevState) => ({
      ...prevState,
      ChecklistTypeId: InputValue,
    }));
  };
  const Close = () => {
    $(
      "#remindertype, #reminderby,#dead-line-date, #checklistdescription, #category"
    ).removeClass("is-invalid");
    $("#add-check-list").modal("hide");
  };
  const inputHandler = async (inputName, event) => {
    //event.preventDefault();
    let inputVal = event.target.value;
    let inputObject = {};
    inputObject[inputName] = inputVal;
    if (inputVal.trim() == null || inputVal.trim() == "") {
      event.target.classList.add("is-invalid");
    } else {
      event.target.classList.remove("is-invalid");
    }
    setState((prevState) => ({
      ...prevState,
      ...inputObject,
    }));
    event.preventDefault();
  };
  return (
    <div
      id="add-check-list"
      className="modal fade"
      role="dialog"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="width-60per pd-y-60">
            <div className="only_close">
              <span
                className="close"
                data-dismiss="modal"
                onClick={() => Close()}
              >
                &times;
              </span>
            </div>
            {state.transfereechecklistuid != "" ? (
              <h6>Edit Task</h6>
            ) : (
              <h6>Add Task</h6>
            )}
            <div className="px-5 ">
              {state.changePasswordErrorStatus == true ? (
                <h2 className="valid">{state.errorMessage}</h2>
              ) : (
                ""
              )}
              {state.TaskStatusSuccess == true ? (
                <h6 className="alert alert-success">{state.succesMsg}</h6>
              ) : (
                ""
              )}
              {state.TaskStatusSuccess != true ? (
                <form
                  method="post"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="">
                    <label className="d-block">
                      <b>Task Name</b>
                    </label>
                    <input
                      className="form-control"
                      placeholder="Task Name"
                      type="text"
                      id="checklistdescription"
                      value={state.checklistdescription}
                      onChange={(e) => inputHandler("checklistdescription", e)}
                    />
                  </div>

                  <label className="d-block">
                    <b>Task Type&nbsp;&nbsp;</b>
                  </label>
                  <select
                    className="custom-select"
                    id="ChecklistType"
                    name="endTimeId"
                    value={
                      state.ChecklistTypeId == "PostMove" ||
                      state.ChecklistTypeId == "Post-Move"
                        ? "Post-Move"
                        : state.ChecklistTypeId
                    }
                    onChange={onChangeChecklistType}
                  >
                    {state.checklistTypes.map((row, index) => {
                      return (
                        <option key={index} value={row}>
                          {row}
                        </option>
                      );
                    })}
                  </select>
                  <br />
                  <label className="d-block">
                    <b>Category&nbsp;&nbsp;</b>
                  </label>
                  <select
                    className="custom-select"
                    id="category"
                    name="Category"
                    value={state.category}
                    onChange={onChangeCategoryType}
                  >
                    <option key={0} value="">
                      Select Category
                    </option>
                    {state.categoryTypes.sort().map((row, index) => {
                      return (
                        <option key={index} value={row}>
                          {row}
                        </option>
                      );
                    })}
                  </select>
                  <div className="">
                    <label className="d-block">
                      <b>Deadline Date</b>
                    </label>
                    <div className="date-pic-mypro mb-2">
                      <ReactDatePicker
                        selected={state.editDate}
                        id="dead-line-date"
                        onChange={handleDateChange}
                        minDate={new Date()}
                        dateFormat="MM/dd/yyyy"
                        placeholderText={"MM/DD/YYYY"}
                        onKeyDown={(e) => e.preventDefault()}
                        className="form-control"
                      />
                      {/* <FontAwesomeIcon icon={faCalendarAlt} /> */}
                    </div>
                  </div>
                  <label className="d-block">
                    <b>Reminder</b>
                  </label>
                  <Radio.Group
                    onChange={onChange}
                    value={state.reminder}
                    id="remainder"
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                  {state.reminder == true ? (
                    <div>
                      <label className="d-block">
                        <b>Reminder Type&nbsp;&nbsp;</b>
                      </label>
                      <select
                        className="custom-select"
                        id="remindertype"
                        name="remindertype"
                        value={state.remindertype}
                        onChange={onReminderType}
                      >
                        <option value="">Select Reminder Type</option>
                        <option value="Phone">Phone</option>
                        <option value="Email">Email</option>
                      </select>
                      <br />
                      <label className="label_cls">
                        <b>Remind me on</b>
                      </label>
                      <div className="date-pic-mypro mb-2">
                        <ReactDatePicker
                          selected={state.reminderby}
                          id="reminderby"
                          onChange={onDateChange}
                          minDate={new Date()}
                          maxDate={
                            state.deadlinedate != null &&
                            state.deadlinedate != ""
                              ? new Date(state.deadlinedate)
                              : new Date()
                          }
                          dateFormat="MM/dd/yyyy"
                          placeholderText={"MM/DD/YYYY"}
                          onKeyDown={(e) => e.preventDefault()}
                          className="form-control"
                        />
                        {/* <FontAwesomeIcon icon={faCalendarAlt} /> */}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="text-center mt-3 mb-3">
                    {state.loader == true ? (
                      <Spin />
                    ) : (
                      <div>
                        {" "}
                        <button
                          type="submit"
                          className="btn theme-btn rounded-btn  mt-3"
                          onClick={(event) => addTask(event)}
                        >
                          Submit<span></span>
                        </button>{" "}
                      </div>
                    )}
                  </div>
                </form>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditModal;
