import React from "react";

function RentalScamsPopUp() {
  const closeModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#RentalScams").modal("hide");
      });
    });
  };
  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header border-0">
          <div class="only_close">
            <span
              aria-hidden="true"
              className="pointer close"
              onClick={() => closeModal()}
              data-dismiss="modal"
            >
              ×
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div className="widthModal">
            <div className="item-view">
              <h2>Rental Scams </h2>
              <p>
                Finding the right rental property can be a challenge, and
                unfortunately, scammers are always looking for ways to take
                advantage of renters. Here’s how to recognize common rental
                scams, protect yourself, and ensure you’re dealing with
                legitimate landlords.
              </p>
            </div>

            <div className="tails-row">
              <div className="tails">
                <div className="item-view">
                  <h2>Common Types of Rental Scams </h2>
                  <h3>1. Phantom Rentals </h3>
                  <ul className="list-unstyled">
                    <li>
                      In this scam, the scammer creates a listing for a property
                      that doesn’t exist or isn’t available for rent. They might
                      pull photos from other legitimate listings or make up fake
                      details to lure victims in.
                    </li>
                  </ul>
                  <h3>2. Too-Good-to-Be-True Deals</h3>
                  <ul className="list-unstyled">
                    <li>
                      If the rent is much lower than other similar properties in
                      the area, or if the landlord is offering “unbelievable”
                      amenities for a low price, it’s likely a scam. Scammers
                      use these deals to draw attention and create a sense of
                      urgency.
                    </li>
                  </ul>
                  <h3>3. Request for Upfront Payments </h3>
                  <ul className="list-unstyled">
                    <li>
                      Be wary of anyone asking for money before you’ve seen the
                      property. Scammers often ask for deposits, first month’s
                      rent, or even application fees before you’ve had a chance
                      to verify the rental or meet the landlord in person.
                    </li>
                  </ul>
                  <h3>4. Fake Landlords or Property Managers </h3>
                  <ul className="list-unstyled">
                    <li>
                      In some cases, scammers pose as landlords or property
                      managers for homes they don’t actually own. They might
                      break into a vacant property or simply pose as the owner
                      online, hoping to collect money before disappearing.
                    </li>
                  </ul>
                  <h3>5. Rental Listings That Ask for Personal Information </h3>
                  <ul className="list-unstyled">
                    <li>
                      Some scammers ask for sensitive personal information
                      upfront, like your Social Security number, bank account
                      details, or credit card number. They use this information
                      for identity theft or financial fraud.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="tails">
                <div className="item-view">
                  <h2>Red Flags to Watch For </h2>
                  <h3>1. Landlord or Agent Is Out of Town </h3>
                  <ul className="list-unstyled">
                    <li>
                      If the landlord claims they’re out of town and can’t show
                      you the property in person, this is a major red flag.
                      Scammers often say they’re traveling or based overseas to
                      avoid face-to-face meetings.
                    </li>
                  </ul>
                  <h3>2. No In-Person Viewing </h3>
                  <ul className="list-unstyled">
                    <li>
                      A legitimate landlord will always allow you to see the
                      property in person before asking for any money. If the
                      “landlord” insists that you send money without seeing the
                      rental, it’s likely a scam.
                    </li>
                  </ul>
                  <h3>3. High Pressure to Pay Quickly </h3>
                  <ul className="list-unstyled">
                    <li>
                      Scammers create a sense of urgency, pressuring you to pay
                      immediately to “secure” the rental. Be cautious of any
                      landlord who pushes for quick decisions or payments,
                      especially if they ask for untraceable payment methods
                      like wire transfers or gift cards.
                    </li>
                  </ul>
                  <h3>4. Request for Untraceable Payments </h3>
                  <ul className="list-unstyled">
                    <li>
                      Legitimate landlords typically accept checks, direct bank
                      transfers, or credit card payments. Be wary if someone
                      asks for payments via wire transfer, cash, gift cards, or
                      cryptocurrency. These methods are often used by scammers
                      because they are hard to trace.
                    </li>
                  </ul>
                  <h3>5. Listing Details Don’t Match </h3>
                  <ul className="list-unstyled">
                    <li>
                      Pay attention to inconsistencies in the listing, such as
                      vague or incorrect descriptions, blurry photos, or
                      different addresses in communication versus the listing.
                      Scammers may not be familiar with the actual property and
                      will make mistakes that legitimate landlords wouldn’t.
                    </li>
                  </ul>
                  <h3>6. The Deal Seems Too Good to Be True </h3>
                  <ul className="list-unstyled">
                    <li>
                      If the rent seems unusually low compared to similar
                      properties in the area, it’s worth investigating further.
                      Scammers often advertise below-market rates to lure
                      victims into acting quickly.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="tails">
                <div className="item-view">
                  <h2>How to Verify a Legitimate Rental </h2>
                  <h3>1. Research the Landlord or Property Manager </h3>
                  <ul className="list-unstyled">
                    <li>
                      Look up the landlord or property manager online, and check
                      for reviews or other listings they may have. If they’re
                      part of a property management company, make sure the
                      company is legitimate and has a solid reputation.{" "}
                    </li>
                  </ul>
                  <h3>2. Visit the Property in Person </h3>
                  <ul className="list-unstyled">
                    <li>
                      Always view the property in person before agreeing to
                      anything. Make sure the person showing you the property
                      has the legal right to rent it out and that the property
                      matches the description in the listing.
                    </li>
                  </ul>
                  <h3>3. Check Local Property Records </h3>
                  <ul className="list-unstyled">
                    <li>
                      You can verify property ownership through public property
                      records, which are available through most city or county
                      websites. Ensure the person you’re dealing with is the
                      actual owner or a legitimate representative.
                    </li>
                  </ul>
                  <h3>4. Ask for Proper Documentation </h3>
                  <ul className="list-unstyled">
                    <li>
                      Legitimate landlords will provide a formal lease
                      agreement, and you should be able to ask questions and
                      review the terms before signing. Avoid anyone who refuses
                      to provide paperwork or seems overly secretive.
                    </li>
                  </ul>
                  <h3>5. Trust Your Instincts </h3>
                  <ul className="list-unstyled">
                    <li>
                      If something feels off, it probably is. Don’t ignore gut
                      feelings or red flags. Walk away if you feel pressured or
                      uncomfortable with any part of the process.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="tails">
                <div className="item-view">
                  <h2>What to Do If You’ve Been Scammed </h2>
                  <h3>1. Report the Scam</h3>
                  <ul className="list-unstyled">
                    <li>
                      If you’ve been scammed, report the incident to local
                      authorities, your state's consumer protection agency, or a
                      housing regulatory board. You can also file a complaint
                      with the Federal Trade Commission (FTC).{" "}
                    </li>
                  </ul>
                  <h3>2. Contact Your Bank </h3>
                  <ul className="list-unstyled">
                    <li>
                      If you sent money via bank transfer, contact your bank
                      immediately to see if the transaction can be reversed.
                      Unfortunately, if the scammer used an untraceable payment
                      method, recovering your money may be difficult.
                    </li>
                  </ul>
                  <h3>3. Monitor Your Identity </h3>
                  <ul className="list-unstyled">
                    <li>
                      If you shared personal information with a scammer, monitor
                      your credit report and bank accounts for signs of identity
                      theft. Consider freezing your credit to prevent further
                      damage.{" "}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="tails">
                <div className="item-view">
                  <h2>Final Tips to Avoid Rental Scams </h2>

                  <ul>
                    <li>
                      <b>Do your research: </b>
                      Investigate the landlord, property manager, and property
                      before moving forward.
                    </li>
                    <li>
                      <b>Always see the property in person: </b>
                      Don’t send money until you’ve verified the rental is real.
                    </li>
                    <li>
                      <b>Use traceable payment methods: </b>
                      Avoid wire transfers or cash payments.
                    </li>
                    <li>
                      <b>Trust your gut: </b>
                      If something doesn’t feel right, walk away.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* <div className="item-view mt-4">
              <p>
                By following these tips, you can reduce the stress of moving and
                focus on making the most of your new home. With thoughtful
                preparation and a positive outlook, you’ll be ready to thrive in
                your new community and begin this exciting new chapter. Welcome
                to your new home, and we’re here to support you every step of
                the way.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RentalScamsPopUp;
