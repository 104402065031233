// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom Dropdown Styles */
.custom-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-selected {
  /* border: 1px solid #ccc; */
  /* padding: 8px; */
  cursor: pointer;
  /* min-width: 150px; */
  text-align: left;
}

.dropdown-options {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
}

.dropdown-option {
  padding: 8px;
  cursor: pointer;
  white-space: nowrap;
}

.dropdown-option:hover {
  background-color: #f0f0f0;
}

/* Show options when the dropdown is open */
.dropdown-selected.open + .dropdown-options {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./app/common/internationalPhone/IntTelDropDown.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,4BAA4B;EAC5B,kBAAkB;EAClB,eAAe;EACf,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,sBAAsB;EACtB,sBAAsB;EACtB,wCAAwC;EACxC,iBAAiB;EACjB,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,2CAA2C;AAC3C;EACE,cAAc;AAChB","sourcesContent":["/* Custom Dropdown Styles */\n.custom-dropdown {\n  position: relative;\n  display: inline-block;\n}\n\n.dropdown-selected {\n  /* border: 1px solid #ccc; */\n  /* padding: 8px; */\n  cursor: pointer;\n  /* min-width: 150px; */\n  text-align: left;\n}\n\n.dropdown-options {\n  display: none;\n  position: absolute;\n  top: 100%;\n  left: 0;\n  background-color: #fff;\n  border: 1px solid #ccc;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  max-height: 200px;\n  overflow-y: auto;\n  z-index: 1;\n}\n\n.dropdown-option {\n  padding: 8px;\n  cursor: pointer;\n  white-space: nowrap;\n}\n\n.dropdown-option:hover {\n  background-color: #f0f0f0;\n}\n\n/* Show options when the dropdown is open */\n.dropdown-selected.open + .dropdown-options {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
