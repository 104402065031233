import React, { useEffect, useState } from "react";
// import "./resorce.css";
// import "./modalPopUp/popUpStyles.css";
import { Link } from "react-router-dom";
import * as $ from "jquery";
import LoginStore from "../../../../services/thirdPartyService";
// import Modal from "react-modal";
import { Select, Modal } from "antd";
import PopUpData from "./popUp";
import Header from "../../../common/header/Header";
import Footer from "../../../common/footer/Footer";
import SocialPopUp from "./modalPopUp/socialPopUp";
import DriverLicensePopUp from "./modalPopUp/driverLicensePopUp";
import RegisterPopUp from "./modalPopUp/registerPopUp";
import ForwardingPopUp from "./modalPopUp/forwardingPopUp";
import BankingPopUp from "./modalPopUp/bankingPopUp";
import UtilitiesPopUp from "./modalPopUp/utilitiesPopUP";
import MovingTips from "./modalPopUp/movingTipsPopUp";
import PackingRecommendationsPopUpTips from "./modalPopUp/PackingRecommendationsPopUp";
import NonTAMovingTips from "./modalPopUp/nonTAmovingTipsPopUp";
import NonTAPackingRecommendations from "./modalPopUp/nonTAPackingRecommendatios";
import RentersInsuranceModal from "./modalPopUp/rentersInsurancePopUp";
import RentalApplicationProcessModal from "./modalPopUp/rentalApplicationPopUp";
import TenantRightsPopUP from "./modalPopUp/tenantRightsPopUp";
import RentalScamsPopUp from "./modalPopUp/rentalScamsPopUp";
import PetRelocationModal from "./modalPopUp/petRelocationPopUp";
import PetInsuranceModal from "./modalPopUp/petInsurancePopUp";
import LocalPetRegulationModal from "./modalPopUp/localPetRegulationPopUp";
import InternationalPetModal from "./modalPopUp/internationalPetPopUp";
import PetFriendlyModal from "./modalPopUp/petFriendlyPopUp";
import apiServices from "../../../../services/apiServices";

function ResourceLanding() {
  const [showModal, setShowModal] = useState(false);
  const [activePopUp, setActivePopUp] = useState("");
  const [sessionData, setSesstionData] = useState("");
  const [mobileView, setMobileView] = useState(false);
  const [petDisplay, setPetDisplay] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    let getSessionData = LoginStore.getLoginInfo({})
      .then((result) => {
        // console.log(result, "result");
        getPreferencesData(result);
        setSesstionData(result); // Assuming `setSesstionData` is a function to set session data
      })
      .catch((error) => {
        console.error(error); // Handling errors if the promise is rejected
      });

    viewAdjust();
  }, []);

  const viewAdjust = () => {
    const screenWidth = window.innerWidth;
    // console.log(screenWidth, "screenWidth");
    if (screenWidth <= 767) {
      setMobileView(true);
    }
  };

  const getPreferencesData = async (getSessionData) => {
    let userPreferanceObject = {
      transfereeuniqeuid: getSessionData.userUniqueId,
      istemporary: getSessionData.istemporary,
    };
    let userSavedPreferances = await apiServices.GetTransfereePreferences(
      userPreferanceObject
    );
    if (userSavedPreferances.error == 0) {
      let userAnswerPreferanceObject = userSavedPreferances.message.preferences;
      if (userAnswerPreferanceObject != undefined) {
        let answersPrefer = {};
        _.forEach(userAnswerPreferanceObject, function (answerObject) {
          answersPrefer[answerObject.preferencesmasteruniqueid] = answerObject;
          if (answerObject.question == "Do you have a pet?") {
            if (answerObject.preferencesanswer == "Yes") {
              console.log(
                answerObject.preferencesanswer,
                "answerObject.preferencesanswer"
              );

              setPetDisplay(true);
            }
          }
        });
      }
    }
  };

  const handlePopUp = (typeMenu) => {
    // setShowModal(true);
    if (typeMenu == "petRelocation") {
      $(function () {
        $("document").ready(async function () {
          $("#petRelocationModal").modal("show");
        });
      });
    } else if (typeMenu == "petInsurance") {
      $(function () {
        $("document").ready(async function () {
          $("#petInsuranceModal").modal("show");
        });
      });
    } else if (typeMenu == "localPet") {
      $(function () {
        $("document").ready(async function () {
          $("#localPetModal").modal("show");
        });
      });
    } else if (typeMenu == "internationalPet") {
      $(function () {
        $("document").ready(async function () {
          $("#internationalPetModal").modal("show");
        });
      });
    } else if (typeMenu == "petFriendly") {
      $(function () {
        $("document").ready(async function () {
          $("#petFriendlyModal").modal("show");
        });
      });
    } else if (typeMenu == "frequentlyQuestions") {
      location.href = "/rental-faq";
    } else if (typeMenu == "tenantRights") {
      $(function () {
        $("document").ready(async function () {
          $("#TenantRights").modal("show");
        });
      });
    } else if (typeMenu == "rentalScams") {
      $(function () {
        $("document").ready(async function () {
          $("#RentalScams").modal("show");
        });
      });
    } else if (typeMenu == "rentersinsurance") {
      $(function () {
        $("document").ready(async function () {
          $("#RentersInsurance").modal("show");
        });
      });
    } else if (typeMenu == "rentalapplicationprocess") {
      $(function () {
        $("document").ready(async function () {
          $("#RentalApplicationProcess").modal("show");
        });
      });
    } else if (typeMenu == "nontamovingtips") {
      $(function () {
        $("document").ready(async function () {
          $("#NonTAModalMovingTips").modal("show");
        });
      });
    } else if (typeMenu == "nontaPackingRecommendations") {
      $(function () {
        $("document").ready(async function () {
          $("#NonTAPackingRecommendations").modal("show");
        });
      });
    } else if (typeMenu == "movingtips") {
      $(function () {
        $("document").ready(async function () {
          $("#myModalMovingTips").modal("show");
        });
      });
    } else if (typeMenu == "PackingRecommendations") {
      $(function () {
        $("document").ready(async function () {
          $("#PackingRecommendations").modal("show");
        });
      });
      // setActivePopUp("social");
    } else if (typeMenu == "social") {
      $(function () {
        $("document").ready(async function () {
          $("#myModalSocialSecurity").modal("show");
        });
      });
      // setActivePopUp("social");
    } else if (typeMenu == "driverlicense") {
      $(function () {
        $("document").ready(async function () {
          $("#myModalDriversLicense").modal("show");
        });
      });
      // setActivePopUp("driverlicense");
    } else if (typeMenu == "register") {
      $(function () {
        $("document").ready(async function () {
          $("#myModalRegisteringEmbassy").modal("show");
        });
      });
      // setActivePopUp("register");
    } else if (typeMenu == "forwarding") {
      $(function () {
        $("document").ready(async function () {
          $("#myModalForwardingMail").modal("show");
        });
      });
      // setActivePopUp("forwarding");
    } else if (typeMenu == "banking") {
      $(function () {
        $("document").ready(async function () {
          $("#myModalsettingUpBanking").modal("show");
        });
      });
      // setActivePopUp("banking");
    } else {
      $(function () {
        $("document").ready(async function () {
          $("#myModalsettingUpUtilities").modal("show");
        });
      });
      // setActivePopUp("utilities");
    }
  };
  const rentCalculator = () => {
    location.href = "/rentCalculator";
  };

  return (
    <div id="outer-container">
      {/* <Header /> */}
      <div className="container">
        <div className="resources-tab-view">
          {localStorage.getItem("LoggedInMobile") !== "true" && (
            <h1>Resources</h1>
          )}
          <p>
            Welcome to our Resources Section! Explore a curated collection of
            tools and information to help you navigate your relocation journey
            with ease.
          </p>
          <div className="">
            <h2>Resumes</h2>
            <div className="text-box">
              <p>
                Please use the resources below to create your personalized
                Renters Resume or Pet Resume. Click on "Create," enter your
                information, and you’ll have the option to download or email the
                resumes.
              </p>
              <div className="view-details">
                {petDisplay && (
                  <div
                    className="item"
                    onClick={
                      mobileView ? () => (location.href = "/petresume") : null
                    }
                  >
                    <div className="item-image">
                      <img src="/assets/images/pet-resume.png" alt="" />
                    </div>
                    <div className="item-text">
                      <p>Pet Resume</p>
                      {!mobileView && (
                        <Link to="/petresume">
                          <button className="btn" type="button">
                            Create
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                )}

                <div
                  className="item"
                  onClick={
                    mobileView ? () => (location.href = "/rentersresume") : null
                  }
                >
                  <div className="item-image">
                    <img src="/assets/images/renter-resume.png" alt="" />
                  </div>
                  <div className="item-text">
                    <p>Renters Resume</p>
                    {!mobileView && (
                      <Link to="/rentersresume">
                        <button className="btn" type="button">
                          Create
                        </button>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* mobile view  && web view*/}

            <div
              className="accordion accordion-flush "
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle={mobileView ? "collapse" : undefined}
                    data-bs-target={
                      mobileView ? "#flush-collapseOne" : undefined
                    }
                    aria-expanded={mobileView ? "false" : "true"}
                    aria-controls="flush-collapseOne"
                  >
                    <h2>Housing Resources</h2>
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className={`accordion-collapse ${
                    mobileView ? "collapse" : "show"
                  }`}
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <div className="botton-tab-view">
                      <div className="item" onClick={() => rentCalculator()}>
                        <span>
                          <img
                            src="/assets/images/calculator.png"
                            alt=""
                            width={"95px"}
                          />
                        </span>
                        <h3>Rent Affordability calculator</h3>
                      </div>
                      <div
                        className="item"
                        onClick={() => handlePopUp("rentersinsurance")}
                      >
                        <span>
                          <img
                            src="/assets/images/insurance.png"
                            alt=""
                            width={"95px"}
                          />
                        </span>
                        <h3>Renters Insurance</h3>
                      </div>
                      <div
                        className="item"
                        onClick={() => handlePopUp("rentalapplicationprocess")}
                      >
                        <span>
                          <img
                            src="/assets/images/application-proces.png"
                            alt=""
                            width={"95px"}
                          />
                        </span>
                        <h3>Rental Application Process</h3>
                      </div>
                      <div
                        className="item"
                        onClick={() => handlePopUp("tenantRights")}
                      >
                        <span>
                          <img
                            src="/assets/images/flat-lawyers-day-background.png"
                            alt=""
                            width={"95px"}
                          />
                        </span>
                        <h3>Tenant Rights and Responsibilities</h3>
                      </div>
                      <div
                        className="item"
                        onClick={() => handlePopUp("rentalScams")}
                      >
                        <span>
                          <img
                            src="/assets/images/Scam.png"
                            alt=""
                            width={"95px"}
                          />
                        </span>
                        <h3>Rental Scams</h3>
                      </div>
                      <div
                        className="item"
                        onClick={() => handlePopUp("frequentlyQuestions")}
                      >
                        <span>
                          <img
                            src="/assets/images/faq.png"
                            alt=""
                            width={"95px"}
                          />
                        </span>
                        <h3>Frequently Asked Questions</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="accordion accordion-flush "
              id="accordionFlushExample2"
            >
              <div className="accordion-item">
                <h2 className="accordion-header pt-4" id="flush-headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle={mobileView ? "collapse" : undefined}
                    data-bs-target={
                      mobileView ? "#flush-collapseTwo" : undefined
                    }
                    aria-expanded={mobileView ? "false" : "true"}
                    aria-controls="flush-collapseTwo"
                  >
                    <h2>Settling In Resources</h2>
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  className={`accordion-collapse ${
                    mobileView ? "collapse" : "show"
                  }`}
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample2"
                >
                  <div className="accordion-body">
                    <div className="botton-tab-view">
                      {sessionData.istemporary == true ? (
                        <div
                          className="item"
                          onClick={() => handlePopUp("movingtips")}
                        >
                          <span>
                            <img
                              src="/assets/images/moving-tips.png"
                              alt=""
                              width={"95px"}
                            />
                          </span>
                          <h3>Moving Tips</h3>
                        </div>
                      ) : (
                        ""
                      )}
                      {sessionData.istemporary == true && (
                        <div
                          className="item"
                          onClick={() => handlePopUp("PackingRecommendations")}
                        >
                          <span>
                            <img
                              src="/assets/images/order-tracking.png"
                              alt=""
                              width={"95px"}
                            />
                          </span>
                          <h3>Packing Recommendations</h3>
                        </div>
                      )}

                      {sessionData.istemporary == false && (
                        <div
                          className="item"
                          onClick={() => handlePopUp("nontamovingtips")}
                        >
                          <span>
                            <img
                              src="/assets/images/moving-tips.png"
                              alt=""
                              width={"95px"}
                            />
                          </span>
                          <h3>Moving Tips</h3>
                        </div>
                      )}
                      {sessionData.istemporary == false && (
                        <div
                          className="item"
                          onClick={() =>
                            handlePopUp("nontaPackingRecommendations")
                          }
                        >
                          <span>
                            <img
                              src="/assets/images/order-tracking.png"
                              alt=""
                              width={"95px"}
                            />
                          </span>
                          <h3>Packing Recommendations</h3>
                        </div>
                      )}

                      <div
                        className="item"
                        onClick={() => handlePopUp("social")}
                      >
                        <span>
                          <img
                            src="/assets/images/social-security.svg"
                            alt=""
                            width={"95px"}
                          />
                        </span>
                        <h3>Social Security</h3>
                      </div>

                      <div
                        className="item"
                        onClick={() => handlePopUp("driverlicense")}
                      >
                        <span>
                          <img
                            src="/assets/images/driver-licence.svg"
                            alt=""
                            width={"95px"}
                          />
                        </span>
                        <h3> Driver’s License</h3>
                      </div>

                      <div
                        className="item"
                        onClick={() => handlePopUp("register")}
                      >
                        <span>
                          <img
                            src="/assets/images/embacy.png"
                            alt=""
                            width={"90px"}
                          />
                        </span>
                        <h3> Registering with an Embassy</h3>
                      </div>

                      <div
                        className="item"
                        onClick={() => handlePopUp("forwarding")}
                      >
                        <span>
                          <img
                            src="/assets/images/email-forward.png"
                            alt=""
                            width={"85px"}
                          />
                        </span>
                        <h3> Forwarding Your Mail</h3>
                      </div>

                      <div
                        className="item"
                        onClick={() => handlePopUp("banking")}
                      >
                        <span>
                          <img
                            src="/assets/images/banking.svg"
                            alt=""
                            width={"130px"}
                          />
                        </span>
                        <h3> Setting Up Banking</h3>
                      </div>
                      <div
                        className="item"
                        onClick={() => handlePopUp("utilities")}
                      >
                        <span>
                          <img
                            src="/assets/images/utilities.svg"
                            alt=""
                            width={"95px"}
                          />
                        </span>
                        <h3> Setting Up Utilities</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {petDisplay && (
              <div
                className="accordion accordion-flush "
                id="accordionFlushExample3"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header pt-4" id="flush-headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle={mobileView ? "collapse" : undefined}
                      data-bs-target={
                        mobileView ? "#flush-collapseThree" : undefined
                      }
                      aria-expanded={mobileView ? "false" : "true"}
                      aria-controls="flush-collapseThree"
                    >
                      <h2>Pet Resources</h2>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className={`accordion-collapse ${
                      mobileView ? "collapse" : "show"
                    }`}
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample3"
                  >
                    <div className="accordion-body">
                      <div className="botton-tab-view petImages">
                        <div
                          className="item"
                          onClick={() => handlePopUp("petRelocation")}
                        >
                          <span>
                            <img
                              src="/assets/images/PetRelocationGuide.png"
                              alt=""
                              // width={"95px"}
                            />
                          </span>
                          <h3>Pet Relocation Guide </h3>
                        </div>
                        <div
                          className="item"
                          onClick={() => handlePopUp("petInsurance")}
                        >
                          <span>
                            <img
                              src="/assets/images/PetInsurance.png"
                              alt=""
                              // width={"95px"}
                            />
                          </span>
                          <h3>Pet Insurance </h3>
                        </div>
                        <div
                          className="item"
                          onClick={() => handlePopUp("localPet")}
                        >
                          <span>
                            <img
                              src="/assets/images/LocalPetRegulations.png"
                              alt=""
                              // width={"95px"}
                            />
                          </span>
                          <h3>Local Pet Regulations </h3>
                        </div>
                        <div
                          className="item"
                          onClick={() => handlePopUp("internationalPet")}
                        >
                          <span>
                            <img
                              src="/assets/images/InternationalPetRelocation.png"
                              alt=""
                              // width={"95px"}
                            />
                          </span>
                          <h3>International Pet Relocation </h3>
                        </div>
                        <div
                          className="item"
                          onClick={() => handlePopUp("petFriendly")}
                        >
                          <span>
                            <img
                              src="/assets/images/Pet-FriendlyHousing.png"
                              alt=""
                              // width={"95px"}
                            />
                          </span>
                          <h3>Pet-Friendly Housing </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* web view  */}
            {/* <h2>Housing Resources</h2>
            <div className="botton-tab-view">
              <div className="item" onClick={() => rentCalculator()}>
                <span>
                  <img
                    src="/assets/images/calculator.png"
                    alt=""
                    width={"95px"}
                  />
                </span>
                <h3>Rent Affordability calculator</h3>
              </div>
              <div
                className="item"
                onClick={() => handlePopUp("rentersinsurance")}
              >
                <span>
                  <img
                    src="/assets/images/insurance.png"
                    alt=""
                    width={"95px"}
                  />
                </span>
                <h3>Renters Insurance</h3>
              </div>
              <div
                className="item"
                onClick={() => handlePopUp("rentalapplicationprocess")}
              >
                <span>
                  <img
                    src="/assets/images/application-proces.png"
                    alt=""
                    width={"95px"}
                  />
                </span>
                <h3>Rental Application Process</h3>
              </div>
              <div className="item" onClick={() => handlePopUp("tenantRights")}>
                <span>
                  <img
                    src="/assets/images/flat-lawyers-day-background.png"
                    alt=""
                    width={"95px"}
                  />
                </span>
                <h3>Tenant Rights and Responsibilities</h3>
              </div>
              <div className="item" onClick={() => handlePopUp("rentalScams")}>
                <span>
                  <img src="/assets/images/Scam.png" alt="" width={"95px"} />
                </span>
                <h3>Rental Scams</h3>
              </div>
              <div
                className="item"
                onClick={() => handlePopUp("frequentlyQuestions")}
              >
                <span>
                  <img src="/assets/images/faq.png" alt="" width={"95px"} />
                </span>
                <h3>Frequently Asked Questions</h3>
              </div>
            </div>

            <h2 className="pt-4">Settling In Resources</h2>
            <div className="botton-tab-view">
              {sessionData.istemporary == true ? (
                <div className="item" onClick={() => handlePopUp("movingtips")}>
                  <span>
                    <img
                      src="/assets/images/moving-tips.png"
                      alt=""
                      width={"95px"}
                    />
                  </span>
                  <h3>Moving Tips</h3>
                </div>
              ) : (
                ""
              )}
              {sessionData.istemporary == true && (
                <div
                  className="item"
                  onClick={() => handlePopUp("PackingRecommendations")}
                >
                  <span>
                    <img
                      src="/assets/images/order-tracking.png"
                      alt=""
                      width={"95px"}
                    />
                  </span>
                  <h3>Packing Recommendations</h3>
                </div>
              )}

              {sessionData.istemporary == false && (
                <div
                  className="item"
                  onClick={() => handlePopUp("nontamovingtips")}
                >
                  <span>
                    <img
                      src="/assets/images/moving-tips.png"
                      alt=""
                      width={"95px"}
                    />
                  </span>
                  <h3>Moving Tips</h3>
                </div>
              )}
              {sessionData.istemporary == false && (
                <div
                  className="item"
                  onClick={() => handlePopUp("nontaPackingRecommendations")}
                >
                  <span>
                    <img
                      src="/assets/images/order-tracking.png"
                      alt=""
                      width={"95px"}
                    />
                  </span>
                  <h3>Packing Recommendations</h3>
                </div>
              )}

              <div className="item" onClick={() => handlePopUp("social")}>
                <span>
                  <img
                    src="/assets/images/social-security.svg"
                    alt=""
                    width={"95px"}
                  />
                </span>
                <h3>Social Security</h3>
              </div>

              <div
                className="item"
                onClick={() => handlePopUp("driverlicense")}
              >
                <span>
                  <img
                    src="/assets/images/driver-licence.svg"
                    alt=""
                    width={"95px"}
                  />
                </span>
                <h3> Driver’s License</h3>
              </div>

              <div className="item" onClick={() => handlePopUp("register")}>
                <span>
                  <img src="/assets/images/embacy.png" alt="" width={"90px"} />
                </span>
                <h3> Registering with an Embassy</h3>
              </div>

              <div className="item" onClick={() => handlePopUp("forwarding")}>
                <span>
                  <img
                    src="/assets/images/email-forward.png"
                    alt=""
                    width={"85px"}
                  />
                </span>
                <h3> Forwarding Your Mail</h3>
              </div>

              <div className="item" onClick={() => handlePopUp("banking")}>
                <span>
                  <img
                    src="/assets/images/banking.svg"
                    alt=""
                    width={"130px"}
                  />
                </span>
                <h3> Setting Up Banking</h3>
              </div>
              <div className="item" onClick={() => handlePopUp("utilities")}>
                <span>
                  <img
                    src="/assets/images/utilities.svg"
                    alt=""
                    width={"95px"}
                  />
                </span>
                <h3> Setting Up Utilities</h3>
              </div>
            </div>

            <h2 className="pt-4">Pet Resources</h2>
            <div className="botton-tab-view petImages">
              <div
                className="item"
                onClick={() => handlePopUp("petRelocation")}
              >
                <span>
                  <img
                    src="/assets/images/PetRelocationGuide.png"
                    alt=""
                    // width={"95px"}
                  />
                </span>
                <h3>Pet Relocation Guide </h3>
              </div>
              <div className="item" onClick={() => handlePopUp("petInsurance")}>
                <span>
                  <img
                    src="/assets/images/PetInsurance.png"
                    alt=""
                    // width={"95px"}
                  />
                </span>
                <h3>Pet Insurance </h3>
              </div>
              <div className="item" onClick={() => handlePopUp("localPet")}>
                <span>
                  <img
                    src="/assets/images/LocalPetRegulations.png"
                    alt=""
                    // width={"95px"}
                  />
                </span>
                <h3>Local Pet Regulations </h3>
              </div>
              <div
                className="item"
                onClick={() => handlePopUp("internationalPet")}
              >
                <span>
                  <img
                    src="/assets/images/InternationalPetRelocation.png"
                    alt=""
                    // width={"95px"}
                  />
                </span>
                <h3>International Pet Relocation </h3>
              </div>
              <div className="item" onClick={() => handlePopUp("petFriendly")}>
                <span>
                  <img
                    src="/assets/images/Pet-FriendlyHousing.png"
                    alt=""
                    // width={"95px"}
                  />
                </span>
                <h3>Pet-Friendly Housing </h3>
              </div>
            </div> */}

            <div style={{ height: "50px" }}></div>
          </div>
        </div>
      </div>

      <Footer />

      {/* Housing Resources Modal Popup */}
      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="RentersInsurance"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <RentersInsuranceModal />
      </div>
      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="RentalApplicationProcess"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <RentalApplicationProcessModal />
      </div>
      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="TenantRights"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <TenantRightsPopUP />
      </div>
      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="RentalScams"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <RentalScamsPopUp />
      </div>

      {/* Resources  modal pop up */}
      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="NonTAModalMovingTips"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <NonTAMovingTips />
      </div>

      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="NonTAPackingRecommendations"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <NonTAPackingRecommendations />
      </div>

      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="myModalMovingTips"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <MovingTips />
      </div>

      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="PackingRecommendations"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <PackingRecommendationsPopUpTips />
      </div>

      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="myModalSocialSecurity"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <SocialPopUp />
      </div>

      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="myModalDriversLicense"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <DriverLicensePopUp />
      </div>

      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="myModalRegisteringEmbassy"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <RegisterPopUp />
      </div>

      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="myModalForwardingMail"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <ForwardingPopUp />
      </div>

      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="myModalsettingUpBanking"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <BankingPopUp />
      </div>

      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="myModalsettingUpUtilities"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <UtilitiesPopUp />
      </div>

      {/* pet Resources */}

      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="petRelocationModal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <PetRelocationModal />
      </div>
      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="petInsuranceModal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <PetInsuranceModal />
      </div>
      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="localPetModal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <LocalPetRegulationModal />
      </div>
      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="internationalPetModal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <InternationalPetModal />
      </div>
      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="petFriendlyModal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <PetFriendlyModal />
      </div>
    </div>
  );
}

export default ResourceLanding;
