import React, { useEffect, useState } from "react";
import { Modal, Spin } from "antd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faHome,
  faMoneyBill,
  faCheck,
  faBed,
} from "@fortawesome/free-solid-svg-icons";
import LoginStore from "../../../../../services/thirdPartyService";
import ApiServices from "../../../../../services/apiServices";
import AppController from "../../../../controllers/appController";

function MyPreferencesModal(props) {
  const { state, setModalIsOpen, modalIsOpen, getMyProperties } = props;
  const allOptions = [
    { id: "1", icon: "fa-thin fa-person-walking", name: "Commute" },
    { id: "2", icon: "fa-light fa-house", name: "Home Type" },
    { id: "3", icon: "fa-light fa-money-check-dollar", name: "Budget" },
    { id: "4", icon: "fa-light fa-circle-check", name: "Must Haves" },
    { id: "5", icon: "fa-light fa-bed-front", name: "Bedrooms" },
  ];
  const [leftItems, setLeftItems] = useState([]);
  const [rightItems, setRightItems] = useState([]);
  const [optinoState, setOptionState] = useState({
    answersPrefer: [],
    listOfProperties: [],
    feedbackPop: false,
    loader: true,
  });

  useEffect(() => {
    // console.log("hello");
    getBasincInfo();
  }, []);
  useEffect(() => {
    if (optinoState?.answersPrefer["03b0317a-9dd7-401f-bf46-f06ec35ca802"]) {
      let preferredNames =
        optinoState.answersPrefer["03b0317a-9dd7-401f-bf46-f06ec35ca802"]
          .preferencesanswer;
      filterOptionsByNames(preferredNames, allOptions);
    }
  }, [optinoState?.answersPrefer["03b0317a-9dd7-401f-bf46-f06ec35ca802"]]);

  const getBasincInfo = async () => {
    let getSessionData = await LoginStore.getLoginInfo({});
    let userObject = {
      transfereeuniqeuid: getSessionData.userUniqueId,
      istemporary: getSessionData.istemporary,
    };
    let getQuestionList = await ApiServices.GetPreferencesList(userObject);
    await AppController.logData(
      "My Preferences",
      "My Preferences",
      location.pathname,
      getSessionData.userUniqueId,
      ""
    );
    setOptionState((prevState) => ({
      ...prevState,
      isSharedLiving: getSessionData.issharedliving,
    }));
    let userPreferanceObject = {
      transfereeuniqeuid: getSessionData.userUniqueId,
      istemporary: getSessionData.istemporary,
    };

    let userSavedPreferances = await ApiServices.GetTransfereePreferences(
      userPreferanceObject
    );
    if (userSavedPreferances.error == 0) {
      let userAnswerPreferanceObject = userSavedPreferances.message.preferences;
      if (userAnswerPreferanceObject != undefined) {
        let answersPrefer = {};
        if (userAnswerPreferanceObject.length > 0) {
          _.forEach(userAnswerPreferanceObject, function (answerObject) {
            answersPrefer[answerObject.preferencesmasteruniqueid] =
              answerObject;
          });
          let sampleAnswerArray = _.filter(
            getQuestionList.message.preferencesList,
            function (o) {
              return answersPrefer[o.preferenceUId] == undefined;
            }
          );
          if (sampleAnswerArray.length > 0) {
            let obj = sampleAnswerArray[0];
            obj.preferencesanswer = "";
            answersPrefer[sampleAnswerArray[0].preferenceUId] = obj;
          }
          // console.log(answersPrefer, "=====1");
          setOptionState((prevsState) => ({
            ...prevsState,
            answersPrefer: answersPrefer,
            draganswersPrefer: answersPrefer,
          }));
        } else {
          _.forEach(
            getQuestionList.message.preferencesList,
            function (answerObject) {
              let answerDefaultVal = "";
              if (answerObject.type == "money-range") {
                answerDefaultVal = "500, 1000";
              }
              if (answerObject.type == "Time-range") {
                answerDefaultVal = "15, 30";
              }
              answerObject.preferencesanswer = answerDefaultVal;
              answerObject.transfereeuniqeuid = getSessionData.userUniqueId;
              answerObject.preferencesmasteruniqueid =
                answerObject.preferenceUId;
              answerObject.latitude = null;
              answerObject.longitude = null;
              answersPrefer[answerObject.preferenceUId] = answerObject;
            }
          );
          setOptionState((prevsState) => ({
            ...prevsState,
            answersPrefer: answersPrefer,
            draganswersPrefer: answersPrefer,
          }));
        }
        getPreferAnswer(getQuestionList, answersPrefer, getSessionData);
      }
    }
  };

  const getPreferAnswer = async (
    getQuestionList,
    answersPrefer,
    getSessionData
  ) => {
    if (getQuestionList.message.preferencesList != undefined) {
      let questionList = getQuestionList.message.preferencesList;
      let listOfPropertiesArray = _.filter(questionList, function (o) {
        return o.type == "DragOptions";
      });
      let workAddressArray = _.filter(questionList, function (o) {
        return o.question == "Work address";
      });
      let keyWordsArray = _.filter(questionList, function (o) {
        return o.questionlabel == "KEYWORDS";
      });
      let activitiesArray = _.filter(questionList, function (o) {
        return o.questionlabel == "ACTIVITIES";
      });
      let whoisMovingArray = _.filter(questionList, function (o) {
        return o.questionlabel == "WHO IS MOVING";
      });
      // console.log(listOfPropertiesArray, "listofProperties");
      await localStorage.setItem(
        "workAddress",
        JSON.stringify(answersPrefer[workAddressArray[0]?.preferenceUId])
      );

      listOfPropertiesArray[0].AnswerObject =
        answersPrefer[listOfPropertiesArray[0].preferenceUId];
      var questionResult = await chunkArray(questionList, 2);
      let answerString =
        answersPrefer[whoisMovingArray[0].preferenceUId] != undefined
          ? answersPrefer[whoisMovingArray[0].preferenceUId].preferencesanswer
              .replace(/^[,\s]+|[,\s]+$/g, "")
              .split(",")
          : [];
      if (answerString.length == 1 && answerString[0] == "FAMILY") {
        setOptionState((prev) => ({ ...prev, showCheck: true }));
      }
      let option;
      if (listOfPropertiesArray.length > 0) {
        option = listOfPropertiesArray[0].options
          .split(",")
          .map((option) => option.trim());
      } else {
        console.log("====");
      }
      setOptionState((prevsState) => ({
        ...prevsState,
        keyWordsArray: keyWordsArray,
        activitiesArray: activitiesArray,
        listOfProperties: listOfPropertiesArray,
        questions: questionResult,
        getQuestionListStatus: true,
        getSessionData: getSessionData,
        options: option,
      }));
    }
  };

  const chunkArray = (myArray, chunk_size) => {
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];
    var myChunk = [];
    for (index = 0; index < arrayLength; index += chunk_size) {
      myChunk = myArray.slice(index, index + chunk_size);
      tempArray.push(myChunk);
    }

    return tempArray;
  };

  const filterOptionsByNames = (preferredNames, allOptions) => {
    const preferredNamesArray = preferredNames
      .split(",")
      .map((name) => name.trim());

    // Initialize arrays for filtered and remaining options
    const filteredOptions = [...rightItems];
    const remainingOptions = [...leftItems];

    // Loop through allOptions and categorize based on preferredNames
    allOptions.forEach((option) => {
      if (preferredNamesArray.includes(option.name)) {
        filteredOptions.push(option);
      } else {
        remainingOptions.push(option);
      }
    });
    // Sort filteredOptions based on the order in preferredNamesArray
    const sortedFilteredOptions = preferredNamesArray
      .map((name) => filteredOptions.find((option) => option.name === name))
      .filter((option) => option !== undefined);

    // Update the ID for sorted filtered options based on their new positions
    const updatedFilteredOptions = sortedFilteredOptions.map(
      (option, index) => ({
        ...option,
        id: (index + 1).toString(), // Update ID based on position (1-based index)
      })
    );

    const updatedRemainingOptions = remainingOptions.map((option, index) => ({
      ...option,
      id: (updatedFilteredOptions.length + index + 1).toString(), // Continue ID numbering from where filtered options left off
    }));

    setLeftItems(updatedRemainingOptions);
    setRightItems(updatedFilteredOptions);

    setOptionState((prev) => ({ ...prev, loader: false }));
  };
  const handleDragEnd = (result) => {
    let answersPrefer = state.answersPrefer;
    // console.log(result, "123");
    if (!result.destination) {
      // Item was dropped outside a valid droppable area.
      return;
    }

    const sourceList = result.source.droppableId;
    const destinationList = result.destination.droppableId;
    const draggedItem =
      sourceList === "left"
        ? leftItems[result.source.index]
        : rightItems[result.source.index];

    if (sourceList !== destinationList) {
      // Move item between lists
      if (sourceList === "left") {
        const newLeftItems = [...leftItems];
        newLeftItems.splice(result.source.index, 1);
        setLeftItems(newLeftItems);

        setRightItems((prevRightItems) => {
          const newRightItems = [...prevRightItems];
          newRightItems.splice(result.destination.index, 0, draggedItem);
          return newRightItems;
        });
      } else {
        const newRightItems = [...rightItems];
        newRightItems.splice(result.source.index, 1);
        setRightItems(newRightItems);

        setLeftItems((prevLeftItems) => {
          const newLeftItems = [...prevLeftItems];
          newLeftItems.splice(result.destination.index, 0, draggedItem);
          return newLeftItems;
        });
      }
    } else {
      // Reorder items within the same list
      const itemsToReorder = sourceList === "left" ? leftItems : rightItems;
      const reorderedItems = [...itemsToReorder];
      const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
      reorderedItems.splice(result.destination.index, 0, reorderedItem);

      if (sourceList === "left") {
        setLeftItems(reorderedItems);
      } else {
        setRightItems(reorderedItems);
      }
    }
  };

  const submitHandler = async () => {
    let answersPrefer = {};
    var activitiesError = 0;
    var showError = 0;
    var preferedOptions = rightItems;
    let stringObject = preferedOptions.map((item) => item.name);
    // coverting object to string
    const DragOptions = stringObject.join(",");
    setOptionState((prev) => ({
      ...prev,
      DragOptionsCheck: DragOptions,
      submitPopUp: true,
    }));
    let questionUID = optinoState.listOfProperties[0].preferenceUId;
    // console.log(questionUID, "questionUID");
    answersPrefer[questionUID] = Object.assign(
      [questionUID],
      optinoState.draganswersPrefer[questionUID]
    );
    if (answersPrefer[questionUID] != undefined) {
      let previousAnswer =
        answersPrefer[questionUID].preferencesanswer != undefined
          ? answersPrefer[questionUID].preferencesanswer.split(",")
          : [];
      // let checkValue = previousAnswer.indexOf(DragOptions.trim());
      previousAnswer = DragOptions;

      answersPrefer[questionUID] = {
        transfereeuniqeuid: optinoState.getSessionData.userUniqueId,
        preferencesmasteruniqueid: questionUID,
        preferencesanswer: DragOptions.replace(/\s*,\s*/gi, ",").replace(
          /\d+/g,
          ""
        ),
        type: optinoState.listOfProperties[0].type,
        options: optinoState.listOfProperties[0].options,
      };
    } else {
      answersPrefer[questionUID] = {
        transfereeuniqeuid: optinoState.getSessionData.userUniqueId,
        preferencesmasteruniqueid: questionUID,
        preferencesanswer: DragOptions.replace(/\s*,\s*/gi, ",").replace(
          /\d+/g,
          ""
        ),
        type: optinoState.listOfProperties[0].type,
        options: optinoState.listOfProperties[0].options,
      };
    }
    if (Object.keys(optinoState.answersPrefer).length > 0) {
      let finalCreateObject = [];
      let stateObject = Object.assign({}, optinoState.answersPrefer);
      let dragObject = Object.assign({}, answersPrefer);
      let keywordsObj = Object.assign({}, optinoState.keyWordsArray[0]);
      let activitiesObj = Object.assign({}, optinoState.activitiesArray[0]);
      Object.keys(dragObject).map(function (key, value) {
        stateObject[key] = dragObject[key];
      });
      Object.keys(stateObject).map(function (key) {
        if (
          key != keywordsObj.preferenceUId &&
          key != activitiesObj.preferenceUId
        ) {
          if (
            (stateObject[key].preferencesanswer == undefined ||
              stateObject[key].preferencesanswer == "") &&
            stateObject[key].preferenceUId ==
              "e6198e16-43fe-43de-9287-a2cdbc635d48" &&
            optinoState.showCheck == true
          ) {
            showError = 1;
          } else if (
            stateObject["a7fbe2dc-c3e1-43a8-a862-f068b79bce38"]
              .preferencesanswer == "ME" &&
            stateObject[key].preferencesmasteruniqueid ==
              "e6198e16-43fe-43de-9287-a2cdbc635d48"
          ) {
          } else if (
            stateObject[key].preferencesmasteruniqueid !=
              "f75ade99-8607-4756-9daf-9feeef3e1d1b" &&
            stateObject[key].preferenceUId !=
              "e6198e16-43fe-43de-9287-a2cdbc635d48" &&
            (stateObject[key].preferencesanswer == undefined ||
              stateObject[key].preferencesanswer == "") &&
            (stateObject[key].type != "money-range" ||
              stateObject[key].type != "Time-range")
          ) {
            showError = 1;
          }
        }

        if (
          stateObject[activitiesObj.preferenceUId].preferencesanswer == "" &&
          stateObject[keywordsObj.preferenceUId].preferencesanswer == ""
        ) {
          activitiesError = 1;
        }
        finalCreateObject.push(stateObject[key]);
      });

      // console.log(finalCreateObject, "finalCreateObject");
      let QuestionResult = await ApiServices.CreateTransfereePreferences(
        finalCreateObject
      );
      let question = _.filter(finalCreateObject, function (o) {
        return (
          o.preferencesmasteruniqueid == "a7fbe2dc-c3e1-43a8-a862-f068b79bce38"
        );
      });
      let answerString =
        question[0] != undefined
          ? question[0].preferencesanswer
              .replace(/^[,\s]+|[,\s]+$/g, "")
              .split(",")
          : [];
      getMyProperties("priorties");
      confirmationFunc();
      setModalIsOpen(false);
      $("#preference-modal").modal("hide");
      setOptionState((prev) => ({ ...prev, feedbackPop: true }));
    }
  };
  const confirmationFunc = async () => {
    let getSessionData = await LoginStore.getLoginInfo({});
    let data = {
      transfereeuid: getSessionData.userUniqueId,
    };
    const GetTransfereeStatus = await ApiServices.GetTransfereeStatus(data);
    let uhp = [];

    if (GetTransfereeStatus.error == 0) {
      uhp = await _.filter(GetTransfereeStatus.message.noteslist, function (o) {
        return o.statusuid == "f085be8b-4a33-4826-87ad-cdd6bde1b033";
      });
    }
    let StatusUpdateSendWelcome = {
      uniqueid: uhp.length == 0 ? "" : "f085be8b-4a33-4826-87ad-cdd6bde1b033",
      transfereeuid: getSessionData.userUniqueId,
      statusuid: "f085be8b-4a33-4826-87ad-cdd6bde1b033",
      isactive: true,
    };
    const ManageTransfereeStatus = await ApiServices.ManageTransfereeStatus(
      StatusUpdateSendWelcome
    );
  };
  const Close = () => {
    $("#preference-modal").modal("hide");
  };
  return (
    <>
      <div
        id="preference-modal"
        style={{
          //opacity: "1",
          transition: "none",
          zIndex: 1060,
        }}
        className="modal fade"
        role="dialog"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content demo-drag-drop">
            <div className="width-70per pd-y-60">
              <div className="only_close">
                <span
                  className="pointer close"
                  data-dismiss="modal"
                  onClick={() => Close()}
                >
                  ×
                </span>
              </div>
              <h6>Change Your Priorities </h6>
              <span className="hint">
                Drag and Drop the categories in to the box below in the order of
                importance.
              </span>
              {optinoState.loader && (
                <div className="loading">
                  <Spin />
                </div>
              )}
              <div className="list-group container mt-5 onlyGetStartedPage">
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="left" direction="horizontal">
                    {(provided) => (
                      <div
                        className=" letfitems list-priority-view "
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {leftItems.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                className="item"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="drag">
                                  <i class={item.icon}></i>
                                  {/* <FontAwesomeIcon
                                  icon={item.icon}
                                  style={{
                                    backgroundColor: snapshot.isDragging
                                      ? "lightGreen"
                                      : "white",
                                  }}
                                /> */}
                                  <p>
                                    {/* <span>{index + 1}</span> */}
                                    {item.name}
                                  </p>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  <Droppable droppableId="right" direction="horizontal">
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        id="example2-right"
                        className=" rightitems list-priority-view width100per"
                      >
                        <span className="drag-droptext">
                          Drag and Drop here
                        </span>
                        {rightItems &&
                          rightItems.length > 0 &&
                          rightItems.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  className="item"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className="drag">
                                    <i class={item.icon}></i>
                                    {/* <FontAwesomeIcon
                                    icon={item.icon}
                                    style={{
                                      backgroundColor: snapshot.isDragging
                                        ? "lightGreen"
                                        : "white",
                                    }}
                                  /> */}
                                    <p>
                                      {/* <span>{index + 1}</span> */}

                                      {item.name}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              <div className="text-center mt-4">
                <button
                  type="button"
                  className="btn mr-2 theme-btn"
                  onClick={submitHandler}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={optinoState.feedbackPop}
        footer={null}
        onCancel={() => setOptionState({ ...optinoState, feedbackPop: false })}
      >
        <p className="fs-5">Your preferences have been saved successfully!</p>
      </Modal>
    </>
  );
}

export default MyPreferencesModal;
