import React, { useEffect, useState } from "react";
import Header from "../../common/header/Header";
import Footer from "../../common/footer/Footer";
import { Outlet, redirect, Navigate, useLocation } from "react-router-dom";
import { Spin } from "antd";

import LoginStore from "../../../services/thirdPartyService";
import TransfereeHeader from "../../common/header/transfereeHeader";

const TransfereeLayout = ({ children }) => {
  const [loginStatus, setLoginStatus] = useState(null);
  const [userType, setUserType] = useState(null);
  const loaction = useLocation();
  const { pathname } = location;

  function isLoggedIn(loginStatus) {
    // console.log(loginStatus, "loginStatus");
    if (loginStatus === "loggedIn") {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    LoginStore.getLoginInfo({})
      .then((response) => {
        // console.log(response);
        if (response.userId) {
          setLoginStatus("loggedIn");
          setUserType(response.type);
        } else {
          setLoginStatus("loggedOut");
          setUserType(null);
        }
      })
      .catch((err) => console.log(err));
  }, [pathname]);

  if (loginStatus === null) {
    return <Spin />;
  }
  if (!isLoggedIn(loginStatus)) {
    return <Navigate to="/login" />;
  } else if (userType === "TRANSFEREE") {
    return (
      <>
        <Header user="transferee" />
        {/* <TransfereeHeader user="transferee" /> */}
        <Outlet />
        {/* <Footer /> */}
      </>
    );
  } else if (userType === "SUPPLIER") {
    return <Navigate to="/Dashboard" />;
  } else if (userType === "CONSULTANT") {
    return <Navigate to="/ConsultantDashboard" />;
  }
};

export default TransfereeLayout;
