import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import FilterComponent from "./common/FilterComponent";
import TableComponent from "./common/TableComponent";
import { Spin } from "antd";
import moment from "moment";
import LoginStore from "../../../../services/thirdPartyService";
import ApiServices from "../../../../services/apiServices";
import "./common/css/sreports.css";
import "./common/css/reports.css";
import { useNavigate } from "react-router-dom";
const Reports = () => {
  const [state, setState] = useState({
    supplierid: null,
    loader: false,
    reportslist: [],
    columns: [
      {
        title: "Consultant Email",
        dataIndex: "consultantemail",
        key: Math.random(),
      },
      {
        title: "Transferee Email",
        dataIndex: "transfereeemail",
        key: "transfereeemail",
      },
      {
        title: "Service level	",
        dataIndex: "servicelevel",
        key: "servicelevel",
      },
      {
        title: "Sign up date",
        dataIndex: "signupdate",
        key: "signupdate",
        render: (signupdate) => {
          return <span>{moment(signupdate).format("MM/DD/YYYY")}</span>;
        },
      },
      {
        title: "Company",
        dataIndex: "company",
        key: "company",
      },
      {
        title: "Access from",
        dataIndex: "accessfrom",
        key: "accessfrom",
      },
      {
        title: "Access to",
        dataIndex: "accessto",
        key: "accessto",
      },
    ],
    startDate: null,
    endDate: null,
    companydata: [],
    company: "",
    servicelevel: "",
  });

  function handleChangeStart(startDate) {
    updateState({ startDate });
  }
  function handleChangeEnd(endDate) {
    updateState({ endDate });
  }

  function inputHandler(field, value) {
    updateState({ [field]: value });
  }

  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  async function fetchUserInfoAndUpdateToState() {
    let getSessionData = await LoginStore.getLoginInfo({});
    updateState({ supplierid: getSessionData.userUniqueId });
  }

  async function fetchCompanyDataAndUpdateToState() {
    let getTransfereeObj = {
      supplieruid: state.supplierid,
    };
    let ClientProfile_Result = await ApiServices.GetAllClientProfile(
      getTransfereeObj
    );
    let mainn = ClientProfile_Result.message.clientProfileList;
    let newArray = mainn.sort(function (first, second) {
      let a = first.companyname.toLowerCase();
      let b = second.companyname.toLowerCase();
      if (a > b) {
        return 1;
      } else if (a < b) {
        return -1;
      } else {
        return 0;
      }
    });
    updateState({ companydata: newArray });
  }

  useEffect(() => {
    fetchUserInfoAndUpdateToState();
  }, []);

  useEffect(() => {
    fetchCompanyDataAndUpdateToState();
  }, [state.supplierid]);

  return (
    <div className="dashboard-ds">
      <div className="consultant-report-views">
        <div className="container">
          <div className="consultant-report-views-inputvalues">
            <div className="heaidng-view">
              <h1>SignUp Report</h1>
              {state.reportslist.length > 0 && (
                <CSVLink
                  filename={"TableContent.csv"}
                  data={state.reportslist}
                  className="btn"
                >
                  Export CSV
                </CSVLink>
              )}
            </div>
            <FilterComponent
              reportType={"signup"}
              fromDate={{
                startDate: state.startDate,
                endDate: state.endDate,
                handleChangeStart,
              }}
              toDate={{
                startDate: state.startDate,
                endDate: state.endDate,
                handleChangeEnd,
              }}
              company={{
                company: state.company,
                companydata: state.companydata,
                inputHandler: inputHandler,
              }}
              servicelevel={{
                inputHandler: inputHandler,
                servicelevel: state.servicelevel,
              }}
              updateState={updateState}
              supplierid={state.supplierid}
            />
          </div>
          {state.loader ? (
            <Spin />
          ) : (
            <TableComponent
              reportslist={state.reportslist}
              columns={state.columns}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Reports;
