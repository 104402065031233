import React from "react";
import { Modal } from "antd";

function WellKnowPopUp(props) {
  const { state, setState, handlePopUp } = props;
  const closeModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#myModalWell-known").modal("hide");
      });
    });
    // setState((prev) => ({ ...prev, activitePopUp: false, popUPtype: "" }));
  };
  return (
    <>
      <div className="modal-dialog " role="document">
        <div className="modal-content">
          <div className="width-80per pd-y-60">
            <div className="only_close">
              <span
                className="pointer close"
                data-dismiss="modal"
                onClick={() => closeModal()}
              >
                &times;
              </span>
            </div>
            {state.popUPtype === "wellknown" ? (
              <h6 className="modal-title">Well-known Attractions</h6>
            ) : (
              <h6 className="modal-title">Professional Sports Teams</h6>
            )}
            <div className="modal-body">
              {state.WellKnownAttractions != null &&
              state.WellKnownAttractions != "" &&
              state.popUPtype === "wellknown" ? (
                <ul className="text-ul-view">
                  {state.WellKnownAttractions.split(",").map(function (key, i) {
                    return <li key={i}>{key}</li>;
                  })}
                </ul>
              ) : (
                state.ProfessionalTeams != null &&
                state.ProfessionalTeams != "" &&
                state.popUPtype == "professional" && (
                  <ul className="text-ul-view">
                    {state.ProfessionalTeams.split(",").map(function (key, i) {
                      return <li key={i}>{key}</li>;
                    })}
                  </ul>
                )
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <Modal open={handlePopUp} footer={null} onCancel={closeModal}>
        <div id="myModalWell-known">
          <div style={{ top: "25%" }}>
            <div>
              <div>
                {state.popUPtype === "wellknown" ? (
                  <h4 className="modal-title">Well-known Attractions</h4>
                ) : (
                  <h4 className="modal-title">Professional Sports Teams</h4>
                )}
              </div>
              <div className="modal-body">
                {state.WellKnownAttractions != null &&
                state.WellKnownAttractions != "" &&
                state.popUPtype === "wellknown" ? (
                  <ul className="text-ul-view">
                    {state.WellKnownAttractions.split(",").map(function (
                      key,
                      i
                    ) {
                      return <li key={i}>{key}</li>;
                    })}
                  </ul>
                ) : (
                  state.ProfessionalTeams != null &&
                  state.ProfessionalTeams != "" &&
                  state.popUPtype == "professional" && (
                    <ul className="text-ul-view">
                      {state.ProfessionalTeams.split(",").map(function (
                        key,
                        i
                      ) {
                        return <li key={i}>{key}</li>;
                      })}
                    </ul>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal> */}
    </>
  );
}

export default WellKnowPopUp;
