// import "../myProperties/myProperties.css";
import ApiServices from "../../../../services/apiServices";
import LoginStore from "../../../../services/thirdPartyService";
import * as types from "../../../constants/types";
import { useState, useEffect } from "react";
import AppController from "../../../controllers/appController";
import { Spin, Popover } from "antd";
import { confirmAlert } from "react-confirm-alert";

const DsSearchProperties = (props) => {
  var _isMounted = false;

  const [state, setState] = useState({
    serviceslist: [],
    GetTransfereeProperties: [],
    responseTransfreeProperties: [],
    isShowSchedule: false,
    isShowfavProperties: false,
    updatedDate: "",
  });
  const updateDate = async (date) => {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
    if (_isMounted) {
      await setState({
        updatedDate: day + " " + monthNames[monthIndex] + " " + year,
      });
    }
    return day + " " + monthNames[monthIndex] + " " + year;
  };
  useEffect(() => {
    return () => {
      _isMounted = false;
    };
  }, []);

  const BungalowData = async () => {
    _isMounted = true;
    if (_isMounted) {
      await setState({ isShowfavProperties: true });
    }
    updateDate(new Date());
    let getSessionData = await LoginStore.getLoginInfo({});
    let GetTransfereePropertiesObject = {
      transfereeuniqueId: getSessionData.userUniqueId,
    };
    let GetTransfereeProperties = await ApiServices.GetTransfereeProperties(
      GetTransfereePropertiesObject
    );

    if (GetTransfereeProperties.error == 0) {
      if (_isMounted) {
        setState({
          GetTransfereeProperties:
            GetTransfereeProperties.message.transfereepropertyList,
        });
      }
    }

    let responseTransfreeProperties = [];
    let loopInterations = 0;
    _.forEach(state.GetTransfereeProperties, async (Properties) => {
      if (
        Properties.apiId != undefined &&
        Properties.isactive == true &&
        (Properties.source == "bungalow" || Properties.source == "zillow")
      ) {
        let propertyDetails =
          Properties.source == "bungalow"
            ? await propertyDetailsBangalow(Properties.apiId)
            : Properties;
        console.log(Properties, "Properties");
        if (propertyDetails.error == undefined) {
          propertyDetails.propertyuniqueId = Properties.propertyuniqueId;
          propertyDetails.monthlyrent = "N/A";
          propertyDetails.property_image = Properties.property_image;
          propertyDetails.latitude = propertyDetails.address.latitude;
          propertyDetails.longitude = propertyDetails.address.longitude;
          propertyDetails.city = propertyDetails.city;
          propertyDetails.state = propertyDetails.city;
          propertyDetails.propertyname = propertyDetails.headline;
          propertyDetails.transfereeUId = Properties.transfereeUId;
          propertyDetails.propertyrankpercent = Properties.propertyrankpercent;
          propertyDetails.propertyrankitems = Properties.propertyrankitems;
          propertyDetails.propertyanalyticsuniqueId =
            Properties.propertyanalyticsuniqueId;
          responseTransfreeProperties.push(propertyDetails);
        }

        loopInterations++;
      }
      if (_isMounted) {
        setState({ responseTransfreeProperties });
      }
    });
  };

  useEffect(() => {
    const fetch = () => {
      BungalowData();
    };
    fetch();
  }, []);
  useEffect(
    (nextProps) => {
      if (props.PrioritiesStatus == "priorties") {
        BungalowData();
      }
    },
    [props.PrioritiesStatus]
  );
  const moreInfoWithDetails = async (row, index) => {
    if (row.sharedLiving == true) {
      await localStorage.setItem("pDetails", JSON.stringify(row));
      window.open("/bungalow_details", "myWindow");
      //location.href = '/bungalow_details'
    } else {
      window.open(row.detailspage_link);
    }
  };
  const propertyDetails = async (zipid) => {
    const URL = types.getUpdatedPropertyDetails;
    const QueryString = "zws-id=" + types.zillowKey + "&zpid=" + zipid;
    const zillowURL = URL + "?" + QueryString;
    let responsedata = await AppController.zillowApi({ URL: zillowURL });
    responsedata["SearchResults"] =
      responsedata["UpdatedPropertyDetails:updatedPropertyDetails"];
    if (
      responsedata["SearchResults"].response != undefined &&
      responsedata["SearchResults"].response.address.city != undefined
    ) {
      responsedata["SearchResults"].sharedLiving = false;
      responsedata["SearchResults"].address =
        responsedata["SearchResults"].response.address;
      responsedata["SearchResults"].property = {
        response: responsedata["SearchResults"].response,
      };

      responsedata["SearchResults"].images2 =
        responsedata["SearchResults"].response.images.image.url[0];
      responsedata["SearchResults"].sharedLiving = false;
      responsedata["SearchResults"].images =
        responsedata["SearchResults"].response.images.image.url.toString();
      responsedata["SearchResults"].city =
        responsedata["SearchResults"].response.address.city +
        " " +
        responsedata["SearchResults"].response.address.state;

      return responsedata.SearchResults;
    } else {
      responsedata.error = 1;
      return responsedata;
    }
  };
  const propertyDetailsBangalow = async (id) => {
    let responsedata = await AppController.banglowProperyApi({ URLDATA: id });
    if (responsedata.error == undefined) {
      let imagesMerge = [];
      _.forEach(responsedata.images, function (Image) {
        imagesMerge.push(Image.sm_url);
      });
      responsedata.images2 = imagesMerge[0];
      responsedata.images = imagesMerge;
      responsedata.property = {
        address: responsedata.address,
        market: responsedata.market,
      };
      responsedata.sharedLiving = true;
      responsedata.city =
        responsedata.address.city + " " + responsedata.market.region_code;
    }

    return responsedata;
  };
  const deleteConfirmation = async (row) => {
    confirmAlert({
      title: "",
      message:
        "Are you sure you want to remove this Rental option from your list?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteSaProperties(row),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const deleteSaProperties = async (row) => {
    let propertyObjectDelete = {
      transfereeuniqueId: row.transfereeUId,
      propertyuniqueId: row.propertyuniqueId,
      propertyanalyticsuniqueId: row.propertyanalyticsuniqueId,
      isdelete: true,
      isactive: false,
    };
    let addPropertyResultDelete = await ApiServices.CreateTransfereeProperties(
      propertyObjectDelete
    );

    BungalowData();
  };

  return (
    <div className="my_properties_lists">
      <h1>
        <b>Bungalow Rentals</b>
      </h1>
      {/** onClick={() => moreInfoWithDetails(row)} */}
      <div className="main_grid">
        {state.responseTransfreeProperties.length > 0 ? (
          state.responseTransfreeProperties.map((row, index) => {
            return (
              <div className="item" key={index}>
                <input
                  type="checkbox"
                  id={"samyCheckbox" + index}
                  onChange={() => props.compareFunc(row)}
                  checked={
                    props.compareProperties[row.propertyanalyticsuniqueId] !=
                    undefined
                      ? true
                      : false
                  }
                />
                <label htmlFor={"samyCheckbox" + index}>
                  <img
                    src={
                      row.images2 != undefined
                        ? row.images2
                        : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
                    }
                  />

                  <div className="content">
                    <p>
                      {row.address != undefined ? row.address.street : ""}
                      {row.city}
                    </p>

                    <span>
                      <a
                        href="javascript:void(0)"
                        onClick={() => deleteConfirmation(row)}
                      >
                        <i className="fa fa-thumbs-down"></i>
                      </a>{" "}
                      <a
                        href="javascript:void(0)"
                        className="linkColor"
                        onClick={() => moreInfoWithDetails(row)}
                      >
                        More
                      </a>{" "}
                    </span>
                  </div>
                </label>
              </div>
            );
          })
        ) : state.isShowfavProperties == false ? (
          <Spin />
        ) : null}
      </div>
    </div>
  );
};
export default DsSearchProperties;
