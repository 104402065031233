import React, { useState, useEffect } from "react";
// import "./dsHome.css";
// import "./sharlotte-home.css";
import { useNavigate } from "react-router-dom";
import Header from "../../../common/header/Header";
import Footer from "../../../common/footer/Footer";
import { Popover } from "antd";
import ThirdParty from "../../../../services/thirdPartyService";
import ApiServices from "../../../../services/apiServices";
import AppController from "../../../controllers/appController";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";
import SocialPopUp from "../resume/modalPopUp/socialPopUp";
import ForwardingPopUp from "../resume/modalPopUp/forwardingPopUp";
import BankingPopUp from "../resume/modalPopUp/bankingPopUp";

const Dslanding = () => {
  const [state, setState] = useState({
    loader: true,
    email: "geetha.kunda@devrabbit.com",
    subject: "",
    description: "",
    succesMsg: "",
    contactError: "",
    FromEmail: "",
    firstname: "",
    lastname: "",
    phoneNumber: "",
    countrycode: "",
    sessionObject: true,
    completedTasks: [],
    nearByCities: [],
    myPropertiesList: [],
    userName: "",
    consultantName: "",
    consultantprofilepic: "",
    consultantemail: "",
    SAmyPropertiesList: [],
    getSessionData: "",
  });

  const [workLocation, setWorkLocation] = useState({
    latitude: "",
    longitude: "",
    city: "",
    state: "",
    address: "", //preferencesanswer
  });
  const [commuteTime, setCommuteTime] = useState(15);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    async function sessionData() {
      try {
        let getSessionData = await ThirdParty.getLoginInfo({});
        if (Object.keys(getSessionData).length > 0) {
          setState((prevState) => ({
            ...prevState,
            sessionObject: false,
            userName: getSessionData.userFirstName,
            getSessionData: getSessionData,
          }));
        }
        await AppController.logData(
          "Home Page",
          "Home Page",
          location.pathname,
          getSessionData.userUniqueId,
          ""
        );
      } catch (error) {
        console.log(error);
      }
    }
    sessionData();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (workLocation.latitude && workLocation.longitude && commuteTime) {
      getCityList(workLocation.latitude, workLocation.longitude);
    }
  }, [workLocation, commuteTime]);

  async function fetchData() {
    try {
      getTasks();
      getMyProperties();
      getTransfareeData();
      let getSessionData = await ThirdParty.getLoginInfo({});
      if (getSessionData.istemporary == true) {
        localStorage.setItem("commuteTime", 15);
      }
      setState((prev) => ({
        ...prev,
        workAddress: getSessionData.worklocationaddress1,
      }));
      let userObject = {
        transfereeuniqeuid: getSessionData.userUniqueId,
        istemporary: getSessionData.istemporary,
      };
      let getQuestionList = await ApiServices.GetPreferencesList(userObject);
      let userPreferanceObject = {
        transfereeuniqeuid: getSessionData.userUniqueId,
        istemporary: getSessionData.istemporary,
      };
      let userSavedPreferances = await ApiServices.GetTransfereePreferences(
        userPreferanceObject
      );
      if (userSavedPreferances.error == "0") {
        let userAnswerPreferanceObject =
          userSavedPreferances.message.preferences;
        if (userAnswerPreferanceObject != undefined) {
          let answersPrefer = {};
          _.forEach(userAnswerPreferanceObject, function (answerObject) {
            answersPrefer[answerObject.preferencesmasteruniqueid] =
              answerObject;

            if (answerObject.question == "Work address") {
              const { latitude, longitude, preferencesanswer } = answerObject;
              const [street, city, state] = preferencesanswer.split(",");
              localStorage.setItem("workAddress", JSON.stringify(answerObject));
              setWorkLocation({
                latitude,
                longitude,
                city: city,
                state: preferencesanswer,
                address: preferencesanswer,
              });
            } else if (answerObject.question == "Commute time") {
              localStorage.setItem(
                "commuteTime",
                JSON.stringify(answerObject.preferencesanswer?.split(",")[1])
              );
              setCommuteTime(
                answerObject.preferencesanswer?.split(",")[1] || 15
              );
            }
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getTasks = async () => {
    try {
      let getSessionData = await ThirdParty.getLoginInfo({});
      let taskObject = {
        transfereeuid: getSessionData.userUniqueId,
      };
      let getTasksResult = await ApiServices.GetTransfereeCheckList(taskObject);
      getTasksResult.message.checklist.sort(function (a, b) {
        var dateA = new Date(a.deadlinedate),
          dateB = new Date(b.deadlinedate);
        return dateA - dateB;
      });
      let completedTasks = _.filter(
        getTasksResult.message.checklist,
        function (element) {
          return element.status != "completed";
        }
      );

      setState((prev) => ({
        ...prev,
        checklist: getTasksResult.message.checklist,
        completedTasks: completedTasks,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const getCityList = async (latitude, longitude) => {
    try {
      let getSessionData = await ThirdParty.getLoginInfo({});
      let commuteRadius = (commuteTime * 45) / 60;
      setTimeout(async () => {
        let reqObj = {
          latitude: latitude,
          longitude: longitude,
          radious: Math.round(commuteRadius),
          transfereeuid: getSessionData.userUniqueId,
        };
        let nearbyCity = await ApiServices.GetAllCities(reqObj);
        // console.log("success", nearbyCity);
        let citylist = nearbyCity.message.citieslist;
        // console.log(citylist, "citylist");
        // let citylist = [];
        if (nearbyCity.error == "0" && citylist.length > 0) {
          setState((prev) => ({
            ...prev,
            nearbycities: nearbyCity.message.citieslist.slice(0, 5),
          }));
          getCitiesWithState(nearbyCity);
        } else {
          setState((prev) => ({
            ...prev,
            loader: false,
          }));
        }
      });
    } catch (error) {
      setState((prev) => ({
        ...prev,
        loader: false,
      }));
    }
  };

  const getCitiesWithState = (nearbyCity) => {
    try {
      let nearbyCityData = nearbyCity.message.citieslist;

      if (Array.isArray(nearbyCityData) && nearbyCityData.length > 0) {
        let nearbycities = nearbyCityData.map((item) => {
          item.city = item.city;
          item.state = item.state_id;
          item.latitude = item.lat;
          item.longitude = item.lng;
          item.imageUrl = item.imageUrl;
          item.cityHighlights = item.cityHighlights;
          item.demoGraphics = item.demoGraphics;
          item.cityDetails = item.cityDetails;
          item.miles = Math.floor(item.distanc_in_mils);
          return item;
        });
        setState((prev) => ({
          ...prev,
          loader: false,
          nearByCities: _.sortBy(nearbycities, function (obj) {
            return parseFloat(obj.miles, 10);
          }).slice(0, 4),
        }));
      }
    } catch (error) {
      setState((prev) => ({
        ...prev,
        loader: false,
      }));
    }
  };

  const getMyProperties = async (PrioritiesStatus = null) => {
    await setState((prevState) => ({
      ...prevState,
      PrioritiesStatus: PrioritiesStatus,
    }));
    let getSessionData = await ThirdParty.getLoginInfo({});
    let GetTransfereePropertiesObject = {
      transfereeuniqueId: getSessionData.userUniqueId,
    };
    let GetTransfereeProperties = await ApiServices.GetTransfereeProperties(
      GetTransfereePropertiesObject
    );
    if (GetTransfereeProperties.error == 0) {
      let myPropertiesList = _.filter(
        GetTransfereeProperties.message.transfereepropertyList,
        function (element) {
          return (
            element.source == "my_property" &&
            element.isinterested == true &&
            element.apiId != "" &&
            element.isactive == true
          );
        }
      );

      let SAmyPropertiesList = _.filter(
        GetTransfereeProperties.message.transfereepropertyList,
        function (element) {
          return (
            element.source == "SA_Properties" &&
            element.isinterested == true &&
            element.apiId != "" &&
            element.isactive == true
          );
        }
      );
      await setState((prevState) => ({
        ...prevState,
        SAmyPropertiesList: SAmyPropertiesList.slice(0, 3),
        myPropertiesList: myPropertiesList.slice(0, 3),
        propertyLoader: false,
        PrioritiesStatus: null,
      }));
    }
  };

  const getTransfareeData = async () => {
    try {
      let userInfo = await ThirdParty.getLoginInfo({});
      let getTransfereeObj = {
        consultantuniqueid: userInfo.consultantUId,
        transfereeuniqueid: userInfo.userUniqueId,
      };
      let TransfereesList_Result = await ApiServices.GetConsultantTransferees(
        getTransfereeObj
      );

      setState((prevState) => ({
        ...prevState,
        consultantName: TransfereesList_Result.message.userInfo.consultantname,
        consultantemail:
          TransfereesList_Result.message.userInfo.consultantemail,
        consultantprofilepic:
          TransfereesList_Result.message.userInfo.consultantprofilepic,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const moreInfoWithDetails = async (row) => {
    await localStorage.setItem("myPropertyDetails", JSON.stringify(row));
    navigate("/my_property_details");
    // window.open("/my_property_details", "_blank");
  };

  const cityDetails = async (city) => {
    await localStorage.setItem("cDetails", JSON.stringify(city));
    navigate("/city-landing");
  };

  const showSchedules = async () => {
    let activeTabObJ = {
      showHome: false,
      showChat: false,
      showitenaries: false,
      showProfile: false,
      showMessage: false,
      showSearchesPge: false,
      showCalendar: true,
      showGetSettled: false,
    };
    await localStorage.setItem("activeTab", JSON.stringify(activeTabObJ));
    navigate("/dsProfile");
  };

  const showChat = async () => {
    let activeTabObJ = {
      showHome: false,
      showChat: true,
      showitenaries: false,
      showProfile: false,
      showMessage: false,
      showSearchesPge: false,
      showCalendar: false,
      showGetSettled: false,
    };
    await localStorage.setItem("activeTab", JSON.stringify(activeTabObJ));
    navigate("/dsProfile");
  };

  const showMessagesPage = async () => {
    let activeTabObJ = {
      showHome: false,
      showChat: false,
      showitenaries: false,
      showProfile: false,
      showMessage: false,
      showSearchesPge: true,
      showCalendar: false,
      showGetSettled: false,
    };
    await localStorage.setItem("activeTab", JSON.stringify(activeTabObJ));
    navigate("/dsProfile");
  };

  const handlePopUp = (typeMenu) => {
    if (typeMenu == "social") {
      $(function () {
        $("document").ready(async function () {
          $("#myModalSocialSecurity").modal("show");
        });
      });
    } else if (typeMenu == "forwarding") {
      $(function () {
        $("document").ready(async function () {
          $("#myModalForwardingMail").modal("show");
        });
      });
    } else if (typeMenu == "banking") {
      $(function () {
        $("document").ready(async function () {
          $("#myModalsettingUpBanking").modal("show");
        });
      });
    } else if (typeMenu == "rentersresume") {
      navigate("/rentersresume");
    }
  };

  let subscriptioncode =
    localStorage.getItem("loginSubscribeDetails") != null
      ? JSON.parse(localStorage.getItem("loginSubscribeDetails"))
          .subscriptioncode
      : "";
  return (
    <div className="relo-dsLanding">
      {/* <Header /> */}
      {state.loader ? <div className="loading"></div> : null}
      {/* <h1 className="d-md-none text-center">Home</h1> */}
      {subscriptioncode == "ReloNavigator" ? (
        <div className="container">
          <h1 className="main-heading">Welcome {state.userName}!</h1>
          <a href="/DSHelp" target="_blank" className="need-help">
            {" "}
            Need Help?{" "}
          </a>
          <div className="main-grid-view">
            <div className="item welcome-home-2 my_properties_lists">
              <h1 className="h1">
                Nearby cities{" "}
                {state.nearByCities.length >= 4 && (
                  <a href="/citylist">View More </a>
                )}
              </h1>{" "}
              {state.nearByCities.length > 0 ? (
                <div className="my_properties_lists">
                  <div className="main_grid">
                    {_.sortBy(state.nearByCities, function (obj) {
                      return parseFloat(obj.miles, 10);
                    }).map((row, index) => {
                      return (
                        <div className="main-item item" key={index}>
                          <div className="item2">
                            <label>
                              {row.imageUrl != undefined &&
                                row.imageUrl != null && (
                                  <img
                                    src={row.imageUrl}
                                    alt=""
                                    onClick={() => cityDetails(row)}
                                  />
                                )}
                            </label>

                            <div
                              className={
                                row.imageUrl != undefined &&
                                row.imageUrl != null
                                  ? "content mt-2"
                                  : "content mt-2 no-space"
                              }
                            >
                              <a onClick={() => cityDetails(row)}>
                                {row.city}{" "}
                                <i className="fa-regular fa-arrow-up-right"></i>
                              </a>

                              <div className="d-flex justify-content-between score">
                                <p>
                                  Walk Score - {"   "}
                                  <span>
                                    {row.WalkScore != null ? row.WalkScore : 0}
                                  </span>
                                  <Tooltip
                                    title={
                                      row.WalkScore >= 90
                                        ? "Walker's Paradise, Daily errands do not require a car."
                                        : row.WalkScore >= 70 &&
                                          row.WalkScore < 90
                                        ? "Very Walkable, Most errands can be accomplished on foot."
                                        : row.WalkScore >= 50 &&
                                          row.WalkScore < 70
                                        ? "Somewhat Walkable, Some errands can be accomplished on foot."
                                        : row.WalkScore >= 25 &&
                                          row.WalkScore < 50
                                        ? "Car-Dependent, Most errands require a car."
                                        : "Car-Dependent, Almost all errands require a car."
                                    }
                                  >
                                    <i
                                      className="fa-solid fa-info-circle"
                                      aria-hidden="true"
                                    ></i>
                                  </Tooltip>
                                </p>
                                <p>
                                  Bike Score - {"   "}{" "}
                                  <span>
                                    {row.BikeScore != null ? row.BikeScore : 0}
                                  </span>
                                  <Tooltip
                                    title={
                                      row.BikeScore >= 90
                                        ? "Biker's Paradise, Daily errands can be accomplished on a bike."
                                        : row.BikeScore >= 70 &&
                                          row.bikescore < 90
                                        ? "Very Bikeable, Biking is convenient for most trips."
                                        : row.BikeScore >= 50 &&
                                          row.BikeScore < 70
                                        ? "Bikeable, Some bike infrastructure."
                                        : "Somewhat Bikeable, Minimal bike infrastructure."
                                    }
                                  >
                                    <i
                                      className="fa-solid fa-info-circle"
                                      aria-hidden="true"
                                    ></i>{" "}
                                  </Tooltip>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <label className="checklist">
                  We are building your personalized city list. Please check back
                  later.
                </label>
              )}
              {/* {state.nearByCities.length >= 5 && (
                <p className="show-more">
                  <a href="/citylist">Show More </a>
                </p>
              )} */}
            </div>
          </div>
          {!state.getSessionData.istemporary && (
            <div className="my_properties_lists mt-5">
              <h1 className="h1">
                My Rentals{" "}
                {state.myPropertiesList.length > 0 ? (
                  <a href="/myProperty">Show More </a>
                ) : (
                  <a href="/myProperty">Add Rental </a>
                )}
              </h1>
              {state.myPropertiesList.length > 0 ? (
                <div className="main_grid">
                  {state.myPropertiesList.map((row, index) => {
                    return (
                      <div className="item" key={index}>
                        <span className="ScoreLabel">
                          <Popover
                            placement="leftTop"
                            content={
                              <div>
                                {row.propertyrankitems.propertyrankingitems.map(
                                  (Pitem) => {
                                    return (
                                      <p
                                        key={
                                          "mmy" + index + Pitem.prioritylistitem
                                        }
                                      >
                                        {Pitem.prioritylistitem ==
                                        "Must Haves" ? (
                                          <Popover
                                            placement="leftTop"
                                            content={
                                              <div>
                                                {Pitem.matcheditems.map(
                                                  (matcheditem) => {
                                                    return (
                                                      matcheditem.itemname !=
                                                        "" && (
                                                        <p
                                                          key={
                                                            "mmypps" +
                                                            index +
                                                            matcheditem.itemname
                                                          }
                                                        >
                                                          {matcheditem.itemname}
                                                          {matcheditem.ismatched ==
                                                            true && (
                                                            <i className="fa fa-check"></i>
                                                          )}
                                                          {matcheditem.ismatched ==
                                                            false && (
                                                            <i className="fa fa-times"></i>
                                                          )}
                                                        </p>
                                                      )
                                                    );
                                                  }
                                                )}
                                              </div>
                                            }
                                            title="Matched Must Haves"
                                            trigger="hover"
                                          >
                                            {Pitem.prioritylistitem}{" "}
                                            <i
                                              className="fa fa-info-circle"
                                              style={{ color: "#000000" }}
                                            ></i>
                                          </Popover>
                                        ) : (
                                          Pitem.prioritylistitem
                                        )}
                                        {Pitem.ismatched == true &&
                                          Pitem.isallmatched == false && (
                                            <i className="fa fa-check yellow_clr"></i>
                                          )}
                                        {Pitem.ismatched == true &&
                                          Pitem.isallmatched == true && (
                                            <i className="fa fa-check"></i>
                                          )}
                                        {Pitem.ismatched == true &&
                                          Pitem.isallmatched == null && (
                                            <i className="fa fa-check"></i>
                                          )}
                                        {Pitem.ismatched == false && (
                                          <i className="fa fa-times"></i>
                                        )}
                                      </p>
                                    );
                                  }
                                )}
                              </div>
                            }
                            title="Matched Priorities"
                            trigger="hover"
                          >
                            {Math.trunc(row.propertyrankitems.matchpercent)}%
                          </Popover>
                        </span>
                        {/* <div className="item-pic"> */}
                        <label>
                          <img
                            src={
                              row.property_image != null &&
                              row.property_image != ""
                                ? row.property_image.split(",")[0]
                                : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
                            }
                            alt=""
                            onClick={() => moreInfoWithDetails(row)}
                          />
                          {/* </div> */}
                          <div className="content">
                            <a
                              href="javascript:void(0)"
                              onClick={() => moreInfoWithDetails(row)}
                            >
                              {row.propertyname}{" "}
                              <i class="fa-regular fa-arrow-up-right"></i>{" "}
                            </a>
                            <p>
                              {row.city},{row.state}
                            </p>
                            {/* <div className="text-end">
                            <a
                              className="moretext"
                              href="javascript:void(0)"
                              onClick={() => moreInfoWithDetails(row)}
                            >
                              More
                            </a>
                          </div> */}
                          </div>
                        </label>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="my-5 pt-4 width-50per text-center fs-5 text-secondary">
                  Please add Rentals from your own research here. ReloNavigator
                  will help you to choose housing option based on your criteria.
                </div>
              )}
            </div>
          )}

          <div className="my_properties_lists mt-5 resources-tab-view">
            <h1 className="h1 fs-2 d-flex position-static">
              Resources <a href="/resources">View More </a>
            </h1>
            <div className="botton-tab-view">
              <div
                className="item"
                onClick={() => handlePopUp("rentersresume")}
              >
                <span>
                  <img src="/assets/images/renter-resume.png" alt="" />
                  {/* </div> */}
                </span>
                <h3>Renters Resume</h3>
              </div>

              <div className="item" onClick={() => handlePopUp("social")}>
                <span>
                  <img src="/assets/images/social-security.svg" alt="" />
                </span>
                <h3>Social Security</h3>
              </div>

              <div className="item" onClick={() => handlePopUp("forwarding")}>
                <span>
                  <img src="/assets/images/email-forward.png" alt="" />
                </span>
                <h3>Forwarding Your Mail</h3>
              </div>

              <div className="item" onClick={() => handlePopUp("banking")}>
                <span>
                  <img src="/assets/images/banking.svg" alt="" />
                </span>
                <h3>Setting Up Banking</h3>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <h1 className="main-heading">Welcome {state.userName}!</h1>
          <a href="/DSHelp" className="need-help">
            {" "}
            Need Help?
          </a>

          <div className="grid-view">
            <div className="item">
              <h3>Let's find your </h3>
              <h1>New Home!</h1>
              <p className=" mt-5">
                Update your home preferences{" "}
                <a className="btn" href="/getStarted">
                  Update{" "}
                </a>
              </p>
            </div>
            {subscriptioncode == "Commander" && (
              <div className="item">
                <h3>
                  Your consultant
                  <a
                    href="javascript:void(0)"
                    onClick={() => showMessagesPage()}
                  >
                    Know More
                  </a>{" "}
                </h3>
                <h1>
                  {state.consultantName}
                  <span>{state.consultantemail}</span>
                </h1>

                <ul className="consultant-details">
                  <li>
                    <i class="fa-regular fa-calendar-days"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() => showSchedules()}
                    >
                      Schedule Appointment{" "}
                    </a>
                  </li>
                  <li>
                    <i class="fa-regular fa-comment-dots"></i>
                    <a href="javascript:void(0)" onClick={() => showChat()}>
                      Chat With Me{" "}
                    </a>
                  </li>
                </ul>
              </div>
            )}

            <div className="item item-nearcities">
              <h3>
                Nearby cities
                {state.nearByCities.length >= 4 && (
                  <a href="/citylist">Know More </a>
                )}
              </h3>

              {state.nearByCities.length > 0 ? (
                <ul className="near-cities">
                  {state.nearByCities.map((row, index) => {
                    return (
                      <li key={index} onClick={() => cityDetails(row)}>
                        <a>
                          {" "}
                          {
                            row.city
                          } <i class="fa-regular fa-chevron-right"></i>{" "}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <label className="checklist">
                  We are building your personalized city list. Please check back
                  later.
                </label>
              )}
            </div>
          </div>

          {state.SAmyPropertiesList.length > 0 && (
            <div className="my_properties_lists  mt-5">
              <h1 className="h1">
                Recommended Rentals <a href="/myProperty">Show More</a>
              </h1>
              <div className="main_grid">
                {state.SAmyPropertiesList.map((row, index) => {
                  return (
                    <div className="item" key={index}>
                      <span className="ScoreLabel">
                        <Popover
                          placement="leftTop"
                          content={
                            <div>
                              {row.propertyrankitems.propertyrankingitems.map(
                                (Pitem) => {
                                  return (
                                    <p
                                      key={
                                        "SAmmy" + index + Pitem.prioritylistitem
                                      }
                                    >
                                      {Pitem.prioritylistitem ==
                                      "Must Haves" ? (
                                        <Popover
                                          placement="leftTop"
                                          content={
                                            <div>
                                              {Pitem.matcheditems.map(
                                                (matcheditem) => {
                                                  return (
                                                    matcheditem.itemname !=
                                                      "" && (
                                                      <p
                                                        key={
                                                          "SAmmypps" +
                                                          index +
                                                          matcheditem.itemname
                                                        }
                                                      >
                                                        {matcheditem.itemname}
                                                        {matcheditem.ismatched ==
                                                          true && (
                                                          <i className="fa fa-check"></i>
                                                        )}
                                                        {matcheditem.ismatched ==
                                                          false && (
                                                          <i className="fa fa-times"></i>
                                                        )}
                                                      </p>
                                                    )
                                                  );
                                                }
                                              )}
                                            </div>
                                          }
                                          title="Matched Must Haves"
                                          trigger="hover"
                                        >
                                          {Pitem.prioritylistitem}{" "}
                                          <i
                                            className="fa fa-info-circle"
                                            style={{ color: "#000000" }}
                                          ></i>
                                        </Popover>
                                      ) : (
                                        Pitem.prioritylistitem
                                      )}
                                      {Pitem.ismatched == true &&
                                        Pitem.isallmatched == false && (
                                          <i className="fa fa-check yellow_clr"></i>
                                        )}
                                      {Pitem.ismatched == true &&
                                        Pitem.isallmatched == true && (
                                          <i className="fa fa-check"></i>
                                        )}
                                      {Pitem.ismatched == true &&
                                        Pitem.isallmatched == null && (
                                          <i className="fa fa-check"></i>
                                        )}
                                      {Pitem.ismatched == false && (
                                        <i className="fa fa-times"></i>
                                      )}
                                    </p>
                                  );
                                }
                              )}
                            </div>
                          }
                          title="Matched Priorities"
                          trigger="hover"
                        >
                          {Math.trunc(row.propertyrankitems.matchpercent)}%
                        </Popover>
                      </span>
                      {/* <div className="item-pic"> */}
                      <label>
                        <img
                          src={
                            row.property_image != null &&
                            row.property_image != ""
                              ? row.property_image.split(",")[0]
                              : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
                          }
                          alt=""
                          onClick={() => moreInfoWithDetails(row)}
                        />
                        {/* </div> */}
                        <div className="content">
                          <a
                            href="javascript:void(0)"
                            onClick={() => moreInfoWithDetails(row)}
                          >
                            {row.propertyname}{" "}
                            <i class="fa-regular fa-arrow-up-right"></i>{" "}
                          </a>
                          <p>
                            {row.city}, {row.state}
                          </p>
                          {/* <div className="text-end">
                            <a
                              className=" moretext"
                              href="javascript:void(0)"
                              onClick={() => moreInfoWithDetails(row)}
                            >
                              More
                            </a>
                          </div> */}
                        </div>
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {!state.getSessionData.istemporary && (
            <div className="my_properties_lists mt-5">
              <h1 className="h1">
                My Rentals{" "}
                {state.myPropertiesList.length > 0 ? (
                  <a href="/myProperty">Show More </a>
                ) : (
                  <a href="/myProperty">Add Rental </a>
                )}
              </h1>
              {state.myPropertiesList.length > 0 ? (
                <div className="main_grid">
                  {state.myPropertiesList.map((row, index) => {
                    return (
                      <div className="item" key={index}>
                        <span className="ScoreLabel">
                          <Popover
                            placement="leftTop"
                            content={
                              <div>
                                {row.propertyrankitems.propertyrankingitems.map(
                                  (Pitem) => {
                                    return (
                                      <p
                                        key={
                                          "mmy" + index + Pitem.prioritylistitem
                                        }
                                      >
                                        {Pitem.prioritylistitem ==
                                        "Must Haves" ? (
                                          <Popover
                                            placement="leftTop"
                                            content={
                                              <div>
                                                {Pitem.matcheditems.map(
                                                  (matcheditem) => {
                                                    return (
                                                      matcheditem.itemname !=
                                                        "" && (
                                                        <p
                                                          key={
                                                            "mmypps" +
                                                            index +
                                                            matcheditem.itemname
                                                          }
                                                        >
                                                          {matcheditem.itemname}
                                                          {matcheditem.ismatched ==
                                                            true && (
                                                            <i className="fa fa-check"></i>
                                                          )}
                                                          {matcheditem.ismatched ==
                                                            false && (
                                                            <i className="fa fa-times"></i>
                                                          )}
                                                        </p>
                                                      )
                                                    );
                                                  }
                                                )}
                                              </div>
                                            }
                                            title="Matched Must Haves"
                                            trigger="hover"
                                          >
                                            {Pitem.prioritylistitem}{" "}
                                            <i
                                              className="fa fa-info-circle"
                                              style={{ color: "#000000" }}
                                            ></i>
                                          </Popover>
                                        ) : (
                                          Pitem.prioritylistitem
                                        )}
                                        {Pitem.ismatched == true &&
                                          Pitem.isallmatched == false && (
                                            <i className="fa fa-check yellow_clr"></i>
                                          )}
                                        {Pitem.ismatched == true &&
                                          Pitem.isallmatched == true && (
                                            <i className="fa fa-check"></i>
                                          )}
                                        {Pitem.ismatched == true &&
                                          Pitem.isallmatched == null && (
                                            <i className="fa fa-check"></i>
                                          )}
                                        {Pitem.ismatched == false && (
                                          <i className="fa fa-times"></i>
                                        )}
                                      </p>
                                    );
                                  }
                                )}
                              </div>
                            }
                            title="Matched Priorities"
                            trigger="hover"
                          >
                            {Math.trunc(row.propertyrankitems.matchpercent)}%
                          </Popover>
                        </span>
                        {/* <div className="item-pic"> */}
                        <label>
                          <img
                            src={
                              row.property_image != null &&
                              row.property_image != ""
                                ? row.property_image.split(",")[0]
                                : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
                            }
                            alt=""
                            onClick={() => moreInfoWithDetails(row)}
                          />
                          {/* </div> */}
                          <div className="content">
                            <a
                              href="javascript:void(0)"
                              onClick={() => moreInfoWithDetails(row)}
                            >
                              {row.propertyname}{" "}
                              <i class="fa-regular fa-arrow-up-right"></i>{" "}
                            </a>
                            <p>
                              {row.city}, {row.state}
                            </p>
                            {/* <div className="text-end">
                            <a
                              className="moretext"
                              href="javascript:void(0)"
                              onClick={() => moreInfoWithDetails(row)}
                            >
                              More
                            </a>
                          </div> */}
                          </div>
                        </label>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="my-5 pt-4 width-50per text-center fs-5 text-secondary">
                  Please add Rentals from your own research here. ReloNavigator
                  will help you to choose housing option based on your criteria.
                </div>
              )}
            </div>
          )}
          <div className="my_properties_lists mt-5 resources-tab-view">
            <h1 className="h1 fs-2 d-flex position-static">
              Resources <a href="/resources">View More </a>
            </h1>
            <div className="botton-tab-view">
              <div
                className="item"
                onClick={() => handlePopUp("rentersresume")}
              >
                <span>
                  <img src="/assets/images/renter-resume.png" alt="" />
                  {/* </div> */}
                </span>
                <h3>Renters Resume</h3>
              </div>

              <div className="item" onClick={() => handlePopUp("social")}>
                <span>
                  <img src="/assets/images/social-security.svg" alt="" />
                </span>
                <h3>Social Security</h3>
              </div>

              <div className="item" onClick={() => handlePopUp("forwarding")}>
                <span>
                  <img src="/assets/images/email-forward.png" alt="" />
                </span>
                <h3>Forwarding Your Mail</h3>
              </div>

              <div className="item" onClick={() => handlePopUp("banking")}>
                <span>
                  <img src="/assets/images/banking.svg" alt="" />
                </span>
                <h3>Setting Up Banking</h3>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="myModalSocialSecurity"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <SocialPopUp />
      </div>

      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="myModalForwardingMail"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <ForwardingPopUp />
      </div>
      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="myModalsettingUpBanking"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <BankingPopUp />
      </div>
      <Footer sessionObject={state.sessionObject} />
    </div>
  );
};

export default Dslanding;
