import React, { useEffect, useState } from "react";
import Header from "../../common/header/Header";
import { Outlet, redirect, Navigate, useLocation } from "react-router-dom";
import { Spin } from "antd";

import LoginStore from "../../../services/thirdPartyService";
import ApiServices from "../../../services/apiServices";

const ConsultantLayout = ({ children }) => {
  const [loginStatus, setLoginStatus] = useState(null);
  const [user, setUser] = useState(null);
  const [transfereeUrl, setTransfereeUrl] = useState(null);
  const loaction = useLocation();
  const { pathname } = location;

  function isLoggedIn(loginStatus) {
    if (loginStatus === "loggedIn") {
      return true;
    } else {
      return false;
    }
  }

  async function fetchUserSavedPreferences(userPreferanceObject) {
    let userSavedPreferances = await ApiServices.GetTransfereePreferences(
      userPreferanceObject
    );

    return userSavedPreferances;
  }

  useEffect(() => {
    LoginStore.getLoginInfo({})
      .then((response) => {
        if (response.userId) {
          setLoginStatus("loggedIn");
          setUser(response);
        } else {
          setLoginStatus("loggedOut");
          setUser(null);
        }
      })
      .catch((err) => console.log(err));
  }, [pathname]);

  useEffect(() => {
    if (user && user.type === "TRANSFEREE") {
      let userPreferanceObject = {
        transfereeuniqeuid: user.userUniqueId,
      };

      fetchUserSavedPreferences(userPreferanceObject)
        .then((userSavedPreferances) => {
          if (
            userSavedPreferances.error == 0 &&
            userSavedPreferances.message.preferences.length < 1
          ) {
            setTransfereeUrl("/getStartedFirst");
          } else {
            setTransfereeUrl("/dsLanding");
          }
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  if (loginStatus === null) {
    return <Spin />;
  }
  if (!isLoggedIn(loginStatus)) {
    return <Navigate to="/login" />;
  } else if (user.type === "CONSULTANT") {
    return (
      <>
        <Header user="consultant" />
        <Outlet />
      </>
    );
  } else if (user.type === "SUPPLIER") {
    return <Navigate to="/Dashboard" />;
  } else if (user.type === "TRANSFEREE") {
    return transfereeUrl ? <Navigate to={transfereeUrl} /> : <Spin />;
  }
};

export default ConsultantLayout;
