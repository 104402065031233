export const ZillowURL = 'http://www.zillow.com/webservice/';
export const getChart = ZillowURL + 'GetChart.htm';
export const getComps = ZillowURL + 'GetComps.htm';
export const getDeepComps = ZillowURL + 'GetDeepComps.htm';
export const getDeepSearchResults = ZillowURL + 'GetDeepSearchResults.htm';
export const getRegionChildren = ZillowURL + 'GetRegionChildren.htm';
export const getSearchResults = ZillowURL + 'GetSearchResults.htm';
export const getUpdatedPropertyDetails = ZillowURL + 'GetUpdatedPropertyDetails.htm';
export const getZestimate = ZillowURL + 'GetZestimate.htm';
export const zillowKey = 'X1-ZWz17ue3ly3i8b_82rpe';
export const zillowWidgetKEY = 'X1-ZWz1hj572v0s23_26nu3';
export const getSearchResultFromWidget = ZillowURL + 'FMRWidget.htm';
