import React from "react";
import Tab from "./Tab";

const TabMenu = ({ updateState, activeTab, moredetails }) => {
  const tabsList = [
    "Account",
    "Preferences",
    "Properties",
    "Itinerary",
    "Activity",
    "Notes",
    "Status",
  ];

  function handleActiveTab(event) {
    let targetId = event.target.id;
    if (targetId) {
      updateState({ activeTabs: targetId });
    }
  }

  function isPropertyTabVisible() {
    return (
      moredetails.userUniqueId != undefined &&
      //moredetails.isauthgenerated == true &&
      moredetails.subscriptioncode != "ReloNavigator"
    );
  }

  function isItineraryTabVisible() {
    return moredetails.subscriptioncode != "ReloNavigator";
  }

  return (
    <ul
      className="nav nav-tabs"
      role="tablist"
      onClick={(e) => handleActiveTab(e)}
    >
      {tabsList.map((tab, index) => {
        if (tab === "Properties" && !isPropertyTabVisible()) {
          return null;
        } else if (tab === "Itinerary" && !isItineraryTabVisible()) {
          return null;
        } else {
          return (
            <Tab tabName={tab} tabId={tab} activeTab={activeTab} key={index} />
          );
        }
      })}
    </ul>
  );
};

export default TabMenu;
