import React from "react";

const IndividualHoursCard = ({ moredetails }) => {
  function isIndividualHoursVisible() {
    return moredetails.subscriptioncode == "Commander";
  }
  if (!isIndividualHoursVisible()) return null;

  return (
    <div className="profile-item-2">
      <ul>
        <li>
          <p>Individual Hours</p>
        </li>
        <li>
          {" "}
          <span>In Person </span> <b className="dot"> : </b>{" "}
          <b>{moredetails.individualinpersonhours}</b>{" "}
        </li>
        <li>
          {" "}
          <span>Virtual </span> <b className="dot"> : </b>
          <b>{moredetails.individualvirtualhours}</b>{" "}
        </li>
      </ul>
    </div>
  );
};

export default IndividualHoursCard;
