import React, { useState } from "react";
// import Header from "../../header and footer/Header";
// import Footer from "../../header and footer/Footer";
import LoginStore from "../../../../services/thirdPartyService";
import ApiServices from "../../../../services/apiServices";
import * as $ from "jquery";
import { Spin } from "antd";
import moment from "moment";
import "./usagedashboard.css";
import Chart from "react-apexcharts";
import DatePicker from "react-datepicker";
// import Footer from "../header and footer/Footer";
import Footer from "../../../common/footer/footerWithoutchat";

const UsageDashboard = () => {
  const intialState = {
    pageloader: false,
    usageanalytics: {},
    dateError: { startDate: "", endDate: "" },
    startDate: null,
    endDate: null,
    servicelevel: "",
    uploadLoader: false,
    PageAnalyatics: [],
    FeedBack: [],
    series: [100],
    options: {
      colors: ["#7172B4", "#FFC300", "#C1A2E1"],
      chart: { width: 380, type: "pie" },
      labels: ["Service Level"],
      responsive: [
        {
          breakpoint: 480,
          options: { chart: { width: 200 }, legend: { position: "bottom" } },
          dataLabels: {
            enabled: false,
            style: { colors: ["red", "#E91E63", "#9C27B0"] },
          },
        },
      ],
    },
  };
  const [state, setState] = useState(intialState);

  // function handleDateChange(e, date) {
  //   let object = {};
  //   object[date] = e;
  //   if (date == "startDate") {
  //     updateState(object);
  //   } else {
  //     updateState(object);
  //   }
  // }
  function handleDateChange(e, date) {
    let object = {};
    object[date] = e;

    if (date === "startDate") {
      if (!e) {
        setState((prevState) => ({
          ...prevState,
          ...object,
          dateError: {
            ...prevState.dateError,
            startDate: "From date is required",
          },
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          ...object,
          dateError: {
            ...prevState.dateError,
            startDate: "",
          },
        }));
      }
    } else {
      if (!e) {
        setState((prevState) => ({
          ...prevState,
          ...object,
          dateError: {
            ...prevState.dateError,
            endDate: "To date is required",
          },
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          ...object,
          dateError: {
            ...prevState.dateError,
            endDate: "",
          },
        }));
      }
    }
  }

  async function updateState(values) {
    await setState((prev) => ({ ...prev, ...values }));
  }

  async function getAnalytics(e) {
    e.preventDefault();
    let errorUpdate = 0;

    // if (state.endDate == null) {
    //   $("#enddate").addClass("is-invalid");
    //   await updateState({ loader: false });
    //   errorUpdate++;
    // } else {
    //   $("#enddate").removeClass("is-invalid");
    // }
    // if (state.startDate == null) {
    //   $("#startdate").addClass("is-invalid");
    //   await updateState({ loader: false });
    //   errorUpdate++;
    // } else {
    //   $("#startdate").removeClass("is-invalid");
    // }
    if (state.startDate == null) {
      $("#startdate").addClass("is-invalid");
      setState((prevState) => ({
        ...prevState,
        dateError: {
          ...prevState.dateError,
          startDate: "From date is required",
        },
      }));
      errorUpdate++;
    } else {
      $("#startdate").removeClass("is-invalid");
    }
    if (state.endDate == null) {
      $("#enddate").addClass("is-invalid");
      setState((prevState) => ({
        ...prevState,
        dateError: {
          ...prevState.dateError,
          endDate: "To date is required",
        },
      }));
      errorUpdate++;
    } else {
      $("#enddate").removeClass("is-invalid");
    }

    if (errorUpdate == 0) {
      let getSessionData = await LoginStore.getLoginInfo({});
      let data = {
        startdatetime: moment(state.startDate).format("MM/DD/YYYY"),
        enddatetime: moment(state.endDate).format("MM/DD/YYYY"),
        servicelevel: state.servicelevel,
        consultantuid: getSessionData.userUniqueId,
      };

      updateState({ uploadLoader: true });
      const Analyticsdata = await ApiServices.GetUserAnalytics(data);
      const PageAnalyatics = await ApiServices.GetPageAnalyatics(data);
      const FeedBack = await ApiServices.GetFeedBackByTransferee(data);

      const countData = {
        startdatetime: moment(state.startDate).format("MM/DD/YYYY"),
        enddatetime: moment(state.endDate).format("MM/DD/YYYY"),
        consultantuid: getSessionData.userUniqueId,
      };
      const getCount = await ApiServices.GetCountByDevices(countData);
      let filterData =
        getCount.error == 0
          ? getCount.message.filter(
              (obj) => obj.Browser != null && obj.Browser != "web"
            )
          : [];

      if (
        PageAnalyatics.error == 0 ||
        FeedBack.error == 0 ||
        Analyticsdata.error == 0
      ) {
        if (PageAnalyatics.error == 0) {
          let datas = PageAnalyatics.message;
          for (var i = 0; i < datas.length; i++) {
            let hhmmss = new Date(datas[i].TimeInMilleSecond * 1000)
              .toISOString()
              .substr(11, 8);
            datas[i].hhmm = hhmmss;
            datas[i].PageUrl = datas[i].PageUrl.substring(1);
          }
          updateState({ PageAnalyatics: datas });
        } else {
          updateState({ PageAnalyatics: [] });
        }
        if (getCount.error == 0) {
          updateState({
            FeedBack: FeedBack.message,
            series: filterData.map((obj, i) => obj.PageCount),
            options: {
              colors: ["#4BC5BC", "#FF8A1B", "#C1A2E1"],
              chart: { width: 380, type: "pie" },
              labels: filterData.map((obj, i) => obj.Browser),
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    chart: { width: 200 },
                    legend: { position: "bottom" },
                  },
                  dataLabels: {
                    enabled: false,
                    style: { colors: ["red", "#E91E63", "#9C27B0"] },
                  },
                },
              ],
            },
          });
        } else {
          updateState({ FeedBack: [] });
        }
        if (Analyticsdata.error == 0) {
          //   console.log("used hors", Analyticsdata);

          let hhmmss = new Date(Analyticsdata.message.usedInMilliSeconds * 1000)
            .toISOString()
            .substr(11, 8);
          //   console.log("hhmmss", hhmmss);
          Analyticsdata.message.hhmm = hhmmss;

          updateState({ usageanalytics: Analyticsdata.message });
        } else {
          updateState({ usageanalytics: {} });
        }
        updateState({ uploadLoader: false });
      } else {
        updateState({
          PageAnalyatics: [],
          FeedBack: [],
          usageanalytics: {},
          uploadLoader: false,
        });
      }
    }
  }

  return (
    <div className="dashboard-ds usage_db">
      {/* <Header /> */}
      {state.pageloader == true && <div className="loading">Loading</div>}
      <div className="container">
        <div className="usage_db_top_form focus-input-label">
          <div className="floating-label">
            <label className="label_cls">From</label>
            <div className="date-pic-mypro mb-2">
              <DatePicker
                id="startdate"
                dateFormat="MM/dd/yyyy"
                selected={state.startDate}
                selectsStart
                maxDate={state.endDate}
                onChange={(e) => handleDateChange(e, "startDate")}
                placeholderText="Select start date ..."
                className="form-control"
                autoComplete="off"
              />
            </div>
            {state.dateError.startDate && (
              <div className="text-danger">{state.dateError.startDate}</div>
            )}
          </div>
          <div className="floating-label">
            <label className="label_cls">To</label>
            <div className="date-pic-mypro mb-2">
              <DatePicker
                id="enddate"
                dateFormat="MM/dd/yyyy"
                selected={state.endDate}
                selectsEnd
                autoComplete="off"
                startDate={state.startDate}
                endDate={state.endDate}
                onChange={(e) => handleDateChange(e, "endDate")}
                minDate={state.startDate == null ? new Date() : state.startDate}
                placeholderText="Select end date ..."
                className="form-control"
              />
            </div>
            {state.dateError.endDate && (
              <div className="text-danger">{state.dateError.endDate}</div>
            )}
          </div>
          <div className="floating-label" style={{ width: "40%", top: "-4px" }}>
            {state.uploadLoader ? (
              <Spin style={{ float: "left" }} />
            ) : (
              <button
                type="button"
                onClick={(e) => getAnalytics(e)}
                className="btn"
              >
                Submit
              </button>
            )}
          </div>

          <div></div>
        </div>
        <h4 className="page-title">Usage Overview</h4>
        <div className="row-tails">
          <div className="card-body">
            <div className="mini-stat-desc">
              <div className="mini-stat-icon">
                <img src="assets/images/user-group.svg" alt="" />{" "}
              </div>
              <div className="text-white">
                <h3 className="mb-1 mt-0 text-dark">
                  {Object.keys(state.usageanalytics).length > 0
                    ? state.usageanalytics.userCount
                    : 0}
                </h3>
                <h6 className="mt-0 mb-0 "> No. of users</h6>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="mini-stat-desc">
              <div className="mini-stat-icon">
                <img src="assets/images/user-login.svg" width="100%" alt="" />
              </div>
              <div className="text-white">
                <h3 className="mb-1 mt-0 text-dark">
                  {Object.keys(state.usageanalytics).length > 0
                    ? state.usageanalytics.noOfLogin
                    : 0}
                </h3>
                <h6 className=" mt-0 mb-0 "> No. of logins </h6>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="mini-stat-desc">
              <div className="mini-stat-icon">
                <img src="assets/images/usages.svg" width="100%" alt="" />
              </div>
              <div className="text-white">
                <h3 className="mb-1 mt-0 text-dark">
                  {Object.keys(state.usageanalytics).length > 0
                    ? state.usageanalytics.hhmm
                    : 0}
                </h3>
                <h6 className=" mt-0 mb-0 ">Total Usage </h6>
              </div>
            </div>
          </div>
        </div>

        <h4 className="page-title">Usage Analytics</h4>
        <div className="row chart-row chart-box">
          <div className="col-xl-12 col-md-12">
            <div className="card">
              <div className="card-body">
                <h4 className="mt-0 header-title">By Page Level</h4>
                <div className="table-responsive ct-chart">
                  <table className="table mb-0">
                    <thead className="thead-default">
                      <tr>
                        <th width="20%" style={{ textAlign: "center" }}>
                          Category
                        </th>
                        <th width="20%" style={{ textAlign: "center" }}>
                          Sub-Category
                        </th>
                        <th width="20%" style={{ textAlign: "center" }}>
                          No. of Clicks
                        </th>
                        <th width="20%" style={{ textAlign: "center" }}>
                          Time Spent
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {state.PageAnalyatics.length > 0 ? (
                        state.PageAnalyatics.map((row, i) => {
                          return (
                            <tr key={i}>
                              <td
                                style={{
                                  textAlign: "center",
                                  textTransform: "capitalize",
                                }}
                              >
                                {row.ModuleName}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  textTransform: "capitalize",
                                }}
                              >
                                {row.TabName}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {row.PageCount}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {row.hhmm}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td
                            style={{
                              textAlign: "center",
                              textTransform: "capitalize",
                            }}
                          >
                            {"------"}
                          </td>
                          <td style={{ textAlign: "center" }}>{0}</td>
                          <td style={{ textAlign: "center" }}>{0}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            <div className="row chart-row chart-box">
              <div className="col-xl-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">By service level</h4>
                    <div className="table-responsive ct-chart">
                      <table className="table mb-0">
                        <thead className="thead-default">
                          <tr>
                            <th width="10%"> </th>
                            <th width="20%" style={{ textAlign: "center" }}>
                              Good
                            </th>
                            <th width="20%" style={{ textAlign: "center" }}>
                              Average
                            </th>
                            <th width="20%" style={{ textAlign: "center" }}>
                              Below Average
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.FeedBack.length > 0 ? (
                            state.FeedBack.map((row, i) => {
                              return (
                                <tr key={i}>
                                  <th scope="row">{row.ServiceLevel}</th>
                                  <td style={{ textAlign: "center" }}>
                                    {row.Good}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {row.Average}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {row.Poor}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <th scope="row"></th>
                              <td style={{ textAlign: "center" }}>--</td>
                              <td style={{ textAlign: "center" }}>--</td>
                              <td style={{ textAlign: "center" }}>--</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card piechart_card">
              <Chart
                options={state.options}
                series={state.series}
                type="pie"
                width={state.FeedBack.length > 0 ? 410 : 318}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default UsageDashboard;
