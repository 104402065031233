import React, { useState } from "react";
import { Select } from "antd";

function DriverLicensePopUp(props) {
  //   const { onChangeTypes, selectedState, statelist1 } = props;
  const [selectedState, setSelectedState] = useState("CA");
  const statelist1 = [];
  var stateList = ["CA", "OR", "WA"];
  let statename = ["California (CA)", "Oregon (OR)", "Washington (WA)"];
  for (let i = 0; i < stateList.length; i++) {
    statelist1.push(
      <Select.Option key={stateList[i]}>{statename[i]}</Select.Option>
    );
  }
  const onChangeTypes = async (value) => {
    setSelectedState(value);
  };
  const closeModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#myModalDriversLicense").modal("hide");
      });
    });
  };
  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header border-0">
          <div class="only_close">
            <span
              aria-hidden="true"
              className="pointer close"
              onClick={() => closeModal()}
              data-dismiss="modal"
            >
              ×
            </span>
          </div>
        </div>

        <div className="modal-body">
          <div className="widthModal">
            {/* <div className="form-group selectState">
              <label className="label_cls">Select state :</label>
              <Select
                className="autoComplete"
                id="selectedState"
                style={{ width: "25%" }}
                value={selectedState}
                onChange={onChangeTypes}
              >
                {statelist1}
              </Select>
            </div> */}

            <div className="item-view">
              <h2>Driver’s License </h2>
              <p>
                Obtaining, renewing, or transferring your driver’s license is a
                key step when relocating to a new place. Each state has its own
                set of rules and regulations, but most follow a similar process.
                Below is a general guide to help you understand what to expect
                when dealing with driver’s licenses, no matter which state
                you're moving to. Always check your specific state’s Department
                of Motor Vehicles (DMV) or equivalent agency for details.
              </p>
            </div>
            <div className="tails-row">
              <div className="tails">
                <div className="item-view">
                  <h2>How to Apply for a New Driver’s License </h2>
                  <p>
                    If you’re a first-time driver or need to apply for a new
                    license in your state, here are the typical steps you’ll
                    need to follow:{" "}
                  </p>
                  <h3>1. Proof of Identity and Residency</h3>
                  <p>
                    Most states require you to provide documents proving your
                    identity, age, and residency. These may include:{" "}
                  </p>
                  <ul>
                    <li>A valid passport or birth certificate. </li>
                    <li>
                      Social Security card or proof of Social Security number.{" "}
                      <li>
                        Two documents that show your new residential address
                        (e.g., utility bills, lease agreements, or bank
                        statements).{" "}
                      </li>
                    </li>
                  </ul>
                  <h3>2. Pass a Written Test</h3>
                  <p>
                    First-time drivers or those applying for a license after an
                    extended period may need to take a written knowledge test.
                    The test covers road signs, traffic laws, and safe driving
                    practices.
                  </p>
                  <h3>3. Complete a Vision Exam</h3>
                  <p>
                    A vision test is required in most states to ensure that you
                    meet the minimum eyesight requirements for driving.
                  </p>
                  <h3>4. Pass a Driving Test</h3>
                  <p>
                    New drivers, or those applying for their first U.S. license,
                    will need to complete a road test to demonstrate their
                    ability to operate a vehicle safely. Practice is recommended
                    before scheduling the exam.
                  </p>
                  <h3>5. Submit Fees and Forms</h3>
                  <p>
                    Fees for driver’s licenses vary by state. Be prepared to pay
                    for the license itself, as well as for any testing or
                    additional services.
                  </p>
                </div>
              </div>

              <div className="tails">
                <div className="item-view">
                  <h2>How to Transfer an Out-of-State Driver’s License </h2>
                  <p>
                    If you’re moving to a new state, you typically have 30 to 60
                    days to transfer your out-of-state driver’s license to your
                    new state of residence. Here’s what you’ll need:{" "}
                  </p>
                  <h3>1. Visit the Local DMV </h3>
                  <ul>
                    <li>
                      You’ll need to visit your new state’s Department of Motor
                      Vehicles (or equivalent office) in person to transfer your
                      license.{" "}
                    </li>
                  </ul>
                  <h3>2. Bring Required Documents </h3>
                  <ul>
                    <li>
                      Your current driver’s license from your previous state.
                    </li>
                    <li>
                      Proof of identity (passport, birth certificate, etc.).{" "}
                    </li>
                    <li>
                      Proof of residency in your new state (lease, utility bill,
                      or similar documents).{" "}
                    </li>
                    <li>
                      Social Security card or proof of Social Security number.{" "}
                    </li>
                  </ul>
                  <h3>3. Take a Vision Test </h3>
                  <ul>
                    <li>
                      A vision exam is often required, even if you had one when
                      obtaining your previous license.
                    </li>
                  </ul>
                  <h3>4. Submit Application Fees </h3>
                  <ul>
                    <li>
                      Fees for transferring a license also vary by state, so
                      check your state’s DMV website for specific costs.
                    </li>
                  </ul>
                  <h3>5. Surrender Your Old License </h3>
                  <ul>
                    <li>
                      When transferring your license, most states will require
                      you to surrender your previous state’s driver’s license.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="tails">
                <div className="item-view">
                  <h2>Renewing Your Driver’s License </h2>
                  <p>
                    Driver’s licenses typically need to be renewed every 4 to 8
                    years, depending on the state. Here’s a general outline of
                    the renewal process:
                  </p>
                  <h3>1. Eligibility for Online Renewal </h3>
                  <ul>
                    <li>
                      Many states allow license renewal online, provided you
                      meet certain criteria (e.g., no changes to your name or
                      address, and no significant traffic violations).{" "}
                    </li>
                  </ul>
                  <h3>2. In-Person Renewal Requirements </h3>
                  <ul>
                    <li>
                      If your state requires you to renew in person, you’ll
                      typically need:
                      <ul>
                        <li>Your current driver’s license.</li>
                        <li>
                          Proof of identity and residency (for some states).
                        </li>
                        <li>Completion of a vision test.</li>
                      </ul>
                    </li>
                  </ul>

                  <h3>3. Pay the Renewal Fee </h3>
                  <ul>
                    <li>
                      Renewal fees vary by state and the type of license you
                      hold (standard, commercial, etc.).{" "}
                    </li>
                  </ul>
                  <h3>4. Check for Real ID Compliance </h3>
                  <ul>
                    <li>
                      Many states now issue Real ID-compliant driver’s licenses.
                      A Real ID allows you to use your license for federal
                      purposes, like boarding domestic flights. If you don’t
                      have a Real ID, you may need to provide additional
                      documents when renewing your license.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="tails">
                <div className="item-view">
                  <h2>International Drivers: Converting to a U.S. License </h2>
                  <p>
                    If you hold a foreign driver’s license and plan to reside in
                    the U.S. long-term, you’ll likely need to convert your
                    international license into a state-issued driver’s license.
                    Here’s a general outline of what’s involved:{" "}
                  </p>
                  <h3>1. Check Reciprocity Agreements </h3>
                  <ul>
                    <li>
                      Some states have reciprocal agreements with certain
                      countries, meaning you may be able to exchange your
                      foreign license without taking a driving test. Check your
                      state’s DMV website to see if this applies to you.
                    </li>
                  </ul>
                  <h3>2. Provide Translation of Documents </h3>
                  <ul>
                    <li>
                      If your foreign license or documents are not in English,
                      you may need to provide a certified translation.
                    </li>
                  </ul>
                  <h3>3. Pass Written, Vision, and Driving Tests </h3>
                  <ul>
                    <li>
                      In most cases, international drivers will need to pass the
                      state’s written, vision, and road tests to obtain a U.S.
                      driver’s license.
                    </li>
                  </ul>
                  <h3>4. Submit Required Documents </h3>
                  <ul>
                    <li>
                      Proof of identity, Social Security number (if applicable),
                      proof of residency, and your foreign driver’s license will
                      be required.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="tails">
                <div className="item-view">
                  <h2>Additional Tips for Driver’s License Applicants </h2>
                  <h3>1. Check Your State’s Specific Requirements </h3>
                  <ul>
                    <li>
                      Each state has its own regulations regarding driver’s
                      licenses. Visit your state’s DMV website for up-to-date
                      information, fees, and document requirements.{" "}
                    </li>
                  </ul>
                  <h3>2. Real ID vs. Standard License </h3>
                  <ul>
                    <li>
                      Consider applying for a Real ID if you plan to travel
                      domestically or access federal buildings, as it will
                      become a requirement for these purposes starting in 2025.
                      Real ID compliance requires additional documentation, so
                      make sure to check what’s needed in your state.
                    </li>
                  </ul>
                  <h3>3. Understand Renewal Timeframes </h3>
                  <ul>
                    <li>
                      Some states allow you to renew your driver’s license up to
                      a year before it expires, while others have shorter
                      renewal windows. Avoid penalties by checking your
                      license’s expiration date well in advance.{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="item-view">
              <h2>Conclusion </h2>
              <p>
                While driver’s license processes can vary slightly from state to
                state, this general guide provides you with a roadmap to
                obtaining, transferring, or renewing your license. Make sure to
                visit your state’s DMV website to check specific requirements,
                fees, and timelines. A driver’s license is not only a legal
                requirement for driving but also a key form of identification,
                so it’s important to keep yours up to date.{" "}
              </p>
            </div>
          </div>
          {/* {selectedState == "CA" ? (
            <div className="widthModal">
              <div className="item-view">
                <h2>Obtaining Your Driver’s License</h2>
                <p>
                  Below are links and information to get a driver’s license in
                  your new state. As soon as you become a California resident,
                  you have 10 days to become a registered driver. Check{" "}
                  <a
                    target="_blank"
                    href="https://www.dmv.ca.gov/portal/file/residency-documents-list-pdf/"
                  >
                    here
                  </a>{" "}
                  to see the list of acceptable documents you can use to prove
                  your California residency.
                </p>
              </div>

              <div className="tails-row">
                <div className="tails">
                  <div className="item-view">
                    <h2>If you’ve never had a Driver’s License</h2>
                    <p>
                      If you are over 18 years of age and you have never had a
                      DL in any state or country, you may obtain an instruction
                      permit until you are ready to take your driving test. 
                    </p>
                    <p>
                      An instruction permit would be issued to a person over age
                      18. Once you complete the requirements of the instruction
                      permit, you can take the driving test to get a California
                      DL.{" "}
                    </p>
                  </div>
                  <div className="item-view">
                    <h2>International Transferees </h2>
                    <p>
                      If you are from outside of the U.S. and moving to the U.S.
                      and have a license issued by another country, follow the
                      process noted for Domestic Transferees. You will also have
                      to take a Behind the Wheel driving test after passing the
                      Knowledge Test.
                    </p>
                    <ol>
                      <li>
                        To help reduce wait times and make sure you secure an
                        appointment, schedule your driving test ahead of time.
                        Schedule your driving test{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.ca.gov/portal/make-an-appointment/"
                        >
                          here
                        </a>
                        . You must schedule a test, as they are not given
                        without an appointment.
                      </li>
                      <li>
                        The vehicle you bring for your driving test must meet
                        the requirements outlined{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.ca.gov/portal/driver-education-and-safety/educational-materials/fast-facts/preparing-for-your-driving-test-ffdl-22/"
                        >
                          here
                        </a>
                        .
                      </li>
                    </ol>
                    <p>
                      Similar to the Knowledge Test, you will have three
                      opportunities to pass the Behind the Wheel Test. If you
                      fail all three attempts, you will have to reapply. 
                    </p>
                    <h2>Don’t Forget</h2>
                    <p>
                      While rental cars may be used for the driving test, you
                      must show that the vehicle is properly insured before the
                      test begins. Find out more about what documents are
                      accepted as proof of insurance{" "}
                      <a
                        target="_blank"
                        href="https://www.dmv.ca.gov/portal/driver-education-and-safety/educational-materials/fast-facts/preparing-for-your-driving-test-ffdl-22/"
                      >
                        here
                      </a>
                      .
                    </p>
                  </div>
                </div>
                <div className="tails">
                  <div className="item-view">
                    <h2>Domestic Transferees </h2>
                    <p>
                      If you are from the U.S. and moving within the U.S and
                      have a license issued by another state, here is how to
                      apply for a California Driver's License.
                    </p>

                    <ol>
                      <li>
                        Fill out an application, found{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.ca.gov/portal/driver-licenses-identification-cards/dl-id-online-app-edl-44/"
                        >
                          here
                        </a>
                        .{" "}
                      </li>
                      <li>
                        It's highly suggested to schedule an appointment for
                        your Knowledge Test. This will help you avoid lines and
                        wait times. Find a DMV location and schedule your
                        appointment{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.ca.gov/portal/locations/"
                        >
                          here
                        </a>
                        .{" "}
                      </li>
                      <li>
                        Study the CA Driver's Handbook to prepare for the test.{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.ca.gov/portal/file/california-driver-handbook-pdf/"
                        >
                          Here is a copy to the handbook
                        </a>
                        .{" "}
                      </li>
                      <li>
                        Take some{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.ca.gov/portal/driver-education-and-safety/educational-materials/sample-driver-license-dl-knowledge-tests/"
                        >
                          practice tests
                        </a>{" "}
                        beforehand.{" "}
                      </li>
                    </ol>
                    <p>
                      You will pay a fee when taking your Knowledge Test, and
                      you will have three opportunities to pass the test. If you
                      fail all three attempts, you will have to reapply.
                    </p>
                    <h2>Don't Forget</h2>

                    <p>
                      Once you pass, don't forget to register your vehicle. More
                      information about registering your vehicle can be found{" "}
                      <a
                        target="_blank"
                        href="https://www.dmv.ca.gov/portal/vehicle-registration/new-registration/"
                      >
                        here
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : selectedState == "OR" ? (
            <div className="widthModal">
              <div className="item-view">
                <h2>Obtaining Your Driver’s License</h2>
                <p>
                  Below are links and information to get a driver’s license in
                  your new state. As soon as you become an Oregon resident, you
                  have 30 days to become a registered driver. Check{" "}
                  <a
                    target="_blank"
                    href="https://www.oregon.gov/odot/dmv/pages/driverid/residency.aspx"
                  >
                    here
                  </a>{" "}
                  to see if you qualify as a resident.
                </p>
              </div>

              <div className="tails-row">
                <div className="tails">
                  <div className="item-view">
                    <h2>If you’ve never had a Driver’s License</h2>
                    <p>
                      If you are over 18 years of age and you have never had a
                      DL in any state or country, you will have to pass both the
                      Knowledge and Driving Tests. How to apply and take these
                      tests are noted in the Domestic and International
                      Transferees areas.
                    </p>
                  </div>
                  <div className="item-view">
                    <h2>International Transferees</h2>
                    <p>
                      If you are from outside of the U.S. and moving to the U.S.
                      and have a license issued by another country, follow the
                      process noted for Domestic Transferees. You will also have
                      to take a Drive Test after passing the Knowledge Test.{" "}
                    </p>
                    <ol>
                      <li>
                        To help reduce wait times and make sure you secure an
                        appointment, schedule your driving test ahead of time.
                        Schedule your driving test{" "}
                        <a
                          target="_blank"
                          href="https://dmv2u.oregon.gov/eServices/_/"
                        >
                          here
                        </a>
                        . You must schedule a test, as they are not given
                        without an appointment.
                      </li>
                      <li>
                        The vehicle you bring for your driving test must meet
                        the requirements outlined{" "}
                        <a
                          target="_blank"
                          href="https://www.oregon.gov/odot/DMV/pages/driverid/licenseget.aspx#Drive"
                        >
                          here
                        </a>
                        .
                      </li>
                    </ol>
                    <p>
                      Similar to the Knowledge Test, you will have three
                      opportunities to pass the Drive Test. If you fail all
                      three attempts, you will have to reapply. 
                    </p>
                    <h2>Don’t Forget</h2>
                    <p>
                      While rental cars may be used for the driving test, you
                      must show that the vehicle is properly insured before the
                      test begins. Find out more about what documents are
                      accepted as proof of insurance{" "}
                      <a
                        target="_blank"
                        href="https://www.oregon.gov/odot/DMV/pages/driverid/licenseget.aspx#Drive"
                      >
                        here
                      </a>
                      .
                    </p>
                  </div>
                </div>
                <div className="tails">
                  <div className="item-view">
                    <h2>Domestic Transferees </h2>
                    <p>
                      If you are from the U.S. and moving within the U.S and
                      have a license issued by another state, here is how to
                      apply for an Oregon Driver's License. You will have to
                      apply and pass the Knowledge Test.
                    </p>

                    <ol>
                      <li>
                        Fill out an application, found{" "}
                        <a
                          target="_blank"
                          href="https://dmv2u.oregon.gov/eServices/_/"
                        >
                          here
                        </a>
                        .{" "}
                      </li>
                      <li>
                        It's highly suggested to schedule an appointment for
                        your Knowledge Test. This will help you avoid lines and
                        wait times. Find a DMV location and schedule your
                        appointment{" "}
                        <a
                          target="_blank"
                          href="https://dmv2u.oregon.gov/eServices/_/"
                        >
                          here
                        </a>
                        .
                      </li>
                      <li>
                        Study the OR Driver's Manual to prepare for the test.{" "}
                        <a
                          target="_blank"
                          href="https://www.oregon.gov/odot/Forms/DMV/37.pdf"
                        >
                          Here is a copy to the handbook
                        </a>{" "}
                      </li>
                      <li>
                        Take some{" "}
                        <a
                          target="_blank"
                          href="https://www.oregon.gov/odot/DMV/Pages/practice_test.aspx"
                        >
                          practice tests
                        </a>{" "}
                        beforehand.{" "}
                      </li>
                    </ol>
                    <p>
                      You will pay a fee when taking your Knowledge Test, and
                      you will have three opportunities to pass the test. If you
                      fail all three attempts, you will have to reapply.
                    </p>
                    <h2>Don't Forget</h2>

                    <p>
                      Once you pass, don't forget to register your vehicle. More
                      information about registering your vehicle can be found{" "}
                      <a
                        target="_blank"
                        href="https://www.oregon.gov/odot/DMV/Pages/Vehicle/index.aspx"
                      >
                        here
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : selectedState == "WA" ? (
            <div className="widthModal">
              <div className="item-view">
                <h2>Obtaining Your Driver’s License</h2>
                <p>
                  Below are links and information to get a driver’s license in
                  your new state. As soon as you become a Washington resident,
                  you have 30 days to become a registered driver. Check{" "}
                  <a
                    target="_blank"
                    href="https://dor.wa.gov/contact-us/washington-state-residency-definition"
                  >
                    here
                  </a>{" "}
                  to see if you qualify as a WA resident.
                </p>
              </div>

              <div className="tails-row">
                <div className="tails">
                  <div className="item-view">
                    <h2>If you’ve never had a Driver’s License</h2>
                    <p>
                      If you are over 18 years of age and you have never had a
                      DL in any state or country, you will have to pass the
                      Knowledge and Driving Tests. How to apply and take these
                      tests are noted under International Transferees.{" "}
                    </p>
                  </div>
                  <div className="item-view">
                    <h2>International Transferees</h2>
                    <p>
                      If you are from outside of the U.S. and moving to the U.S.
                      and have a license issued by another country, here are the
                      steps for getting a Washington driver’s license. You will
                      need to pass a Knowledge and Drive Test.{" "}
                    </p>
                    <ol>
                      <li>
                        Fill out an application, found{" "}
                        <a
                          target="_blank"
                          href="https://www.dol.wa.gov/driverslicense/preapply.html"
                        >
                          here
                        </a>
                        .
                      </li>
                      <li>
                        To help reduce wait times and make sure you secure an
                        appointment, schedule your driving test ahead of time.
                        Schedule your driving test{" "}
                        <a
                          target="_blank"
                          href="https://www.dol.wa.gov/appointments/index.html"
                        >
                          here
                        </a>
                        . You must schedule a test, as they are not given
                        without an appointment.
                      </li>
                      <li>
                        Study the WA Driver's Manual to prepare for the test.{" "}
                        <a
                          target="_blank"
                          href="https://www.dol.wa.gov/driverslicense/guide.html"
                        >
                          Here is a copy to the handbook.
                        </a>
                      </li>
                      <li>
                        Take some{" "}
                        <a
                          target="_blank"
                          href="https://www.dol.wa.gov/driverslicense/practicetest.html"
                        >
                          practice tests
                        </a>{" "}
                        beforehand.
                      </li>
                    </ol>
                    <p>
                      You will pay a fee when taking your Knowledge Test, and
                      you will have three opportunities to pass the test. If you
                      fail all three attempts, you will have to reapply.
                    </p>
                    <ol start="5">
                      <li>
                        The vehicle you bring for your driving test must meet
                        the requirements outlined{" "}
                        <a
                          target="_blank"
                          href="https://www.dol.wa.gov/driverslicense/writtentest.html"
                        >
                          here
                        </a>
                        .
                      </li>
                    </ol>
                    <p>
                      Similar to the Knowledge Test, you will have three
                      opportunities to pass the Drive Test. If you fail all
                      three attempts, you will have to reapply. 
                    </p>
                    <h2>Don’t Forget</h2>
                    <p>
                      While rental cars may be used for the driving test, you
                      must show that the vehicle is properly insured before the
                      test begins. Find out more about what documents are
                      accepted as proof of insurance{" "}
                      <a
                        target="_blank"
                        href="https://www.dol.wa.gov/driverslicense/writtentest.html"
                      >
                        here
                      </a>
                      .
                    </p>
                  </div>
                </div>
                <div className="tails">
                  <div className="item-view">
                    <h2>Domestic Transferees </h2>
                    <p>
                      If you are from the U.S. and moving within the U.S and
                      have a license issued by another state, you might qualify
                      for a Washington driver’s license.
                    </p>
                    <p>
                      If you have a valid license from another U.S. state, the
                      District of Columbia, U.S. Territory, U.S. Department of
                      State, British Columbia, Germany, South Korea, Taiwan, and
                      Japan, you DO NOT need to pass any tests to get your
                      license.
                    </p>
                    <p>
                      Simply, apply online for your license. You can apply{" "}
                      <a
                        target="_blank"
                        href="https://www.dol.wa.gov/driverslicense/preapply.html"
                      >
                        here
                      </a>
                      .
                    </p>
                    <h2>Don't Forget</h2>

                    <p>
                      Don’t forget to register your vehicle. More information
                      about registering your vehicle can be found{" "}
                      <a
                        target="_blank"
                        href="https://www.dol.wa.gov/vehicleregistration/registervehicle.html"
                      >
                        here
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : selectedState == "VA" ? (
            <div className="widthModal">
              <div className="item-view">
                <h1>Obtaining Your Driver’s License</h1>
                <p>
                  Below are links and information to get a driver’s license in
                  your new state. As soon as you become a Virginia resident, you
                  have 60 days to become a registered driver. Check{" "}
                  <a
                    target="_blank"
                    href="https://www.dmv.virginia.gov/webdoc/pdf/dmv141.pdf"
                  >
                    here
                  </a>{" "}
                  to see what documents you will need to provide as a VA
                  resident.
                </p>
              </div>

              <div className="tails-row">
                <div className="tails">
                  <div className="item-view">
                    <h2>If you’ve never had a Driver’s License</h2>
                    <p>
                      If you’ve never had a driver’s license issued by another
                      state or country, you will have to pass both the Knowledge
                      and Driving Tests. The steps to take the Knowledge and
                      Driving Test are outlined under International Transferees.{" "}
                    </p>
                  </div>
                  <div className="item-view">
                    <h2>International Transferees</h2>
                    <p>
                      If you are from outside of the U.S. and moving to the U.S.
                      and have a license issued by another country, you will
                      have to pass both the Knowledge and Driving Tests.
                    </p>
                    <ol>
                      <li>
                        Fill out an application, which can be found{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.virginia.gov/webdoc/pdf/dl1p.pdf"
                        >
                          here
                        </a>{" "}
                        .
                      </li>
                      <li>
                        It is highly suggested to schedule an appointment for
                        your Knowledge Test. This will help you avoid lines and
                        wait times. Find a DMV location and schedule your
                        appointment{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.virginia.gov/general/#appointments.asp"
                        >
                          here
                        </a>
                        .
                      </li>
                      <li>
                        Review and study the Virginia Driver’s Manual{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.virginia.gov/drivers/manual.html"
                        >
                          here
                        </a>
                        .
                      </li>
                      <li>
                        Take some{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.virginia.gov/general/#ske_intro.asp"
                        >
                          practice tests
                        </a>{" "}
                        beforehand.
                      </li>
                      <li>
                        After passing the knowledge test, you will{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.virginia.gov/general/#appointments.asp"
                        >
                          schedule your driving test
                        </a>
                        . More information on the road skills test can be found{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.virginia.gov/general/#skillstest.asp"
                        >
                          here
                        </a>
                        .{" "}
                      </li>
                    </ol>
                    <p>
                      There are fees when taking the Driving Test. Fees and
                      charges can be found{" "}
                      <a
                        target="_blank"
                        href="https://www.dmv.virginia.gov/webdoc/pdf/dmv201.pdf"
                      >
                        here
                      </a>
                      . Once you’ve passed your driving test, your license will
                      be sent to you in the mail.
                    </p>
                    <h2>Don’t Forget</h2>
                    <p>
                      You will have to provide documentation that you are a
                      Virginia resident. Acceptable documents and proof of
                      residency are provided{" "}
                      <a
                        target="_blank"
                        href="https://www.dmv.virginia.gov/webdoc/pdf/dmv141.pdf"
                      >
                        here
                      </a>
                      .
                    </p>
                  </div>
                </div>
                <div className="tails">
                  <div className="item-view">
                    <h2>Domestic Transferees </h2>
                    <p>
                      If you are from the U.S. and moving within the U.S and
                      have a license issued by another state, here is how to
                      apply for a Virginia Driver’s License.
                    </p>
                    <ol>
                      <li>
                        You will exchange your out-of-state license while
                        proving your VA residency. Acceptable documents to prove
                        residency can be found{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.virginia.gov/webdoc/pdf/dmv141.pdf"
                        >
                          here
                        </a>
                        .
                      </li>
                      <li>
                        Fill out an application{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.virginia.gov/webdoc/pdf/dl1p.pdf"
                        >
                          here
                        </a>{" "}
                        and find a DMV near you{" "}
                        <a
                          target="_blank"
                          href="https://www.dmv.virginia.gov/general/#appointments.asp"
                        >
                          here
                        </a>
                        .
                      </li>
                    </ol>
                    <h2>Don't Forget</h2>

                    <p>
                      Don’t forget to register your vehicle. More information
                      about registering your vehicle can be found{" "}
                      <a
                        target="_blank"
                        href="https://www.dmv.virginia.gov/vehicles/#vehiclereg.asp"
                      >
                        here
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : null} */}
        </div>
        {/* <div className="modal-footer"></div> */}
      </div>
    </div>
  );
}

export default DriverLicensePopUp;
