import React from "react";
import { Modal } from "antd";
import moment from "moment";
import ThirdParty from "../../../../../../services/thirdPartyService";

function ResumePopUp(props) {
  const { state, closePop, inputHandler, setState, sendmail } = props;

  return (
    <Modal open={state.popUp} footer={null} onCancel={closePop}>
      <div id="help-rent-box" className="new_modal_pop">
        <div>
          {state.success == true ? (
            state.error == true ? (
              <h6 className="alert alert-danger">Some thing went wrong!</h6>
            ) : (
              <h6 className="alert alert-success mt-4 mx-5">
                Email sent successfully!
              </h6>
            )
          ) : (
            <div>
              <h6>{state.typePop == "renters" ? "Renters" : "Pet"} Resume</h6>
              <div className="focus-input-label">
                <div className="floating-label">
                  <label>
                    <b>Email Address</b>
                  </label>
                  <input
                    type="text"
                    value={state.youremail}
                    className="form-control"
                    id="youremail"
                    name="youremail"
                    onChange={(e) => inputHandler(e)}
                    placeholder="Email Address"
                  />
                </div>
              </div>
              <div className="text-center mt-3 mb-3">
                <button
                  type="button"
                  onClick={(e) => sendmail(e)}
                  className="btn theme-btn rounded-btn  mt-3"
                >
                  Send
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default ResumePopUp;
