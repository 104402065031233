function closeModal(id) {
  let element = document.getElementById(id);
  if (element) {
    element.classList.remove("show");
    element.style.display = "none";
  }

  // Select the modal backdrop element
  var backdrop = document.querySelector(".modal-backdrop.fade.show");

  // Check if the backdrop element exists before trying to remove it
  if (backdrop) {
    // Remove the backdrop from the DOM
    backdrop.parentNode.removeChild(backdrop);
  }

  removeClassAndInlineStyle();
}

function removeClassAndInlineStyle() {
  // Select the element by its class name
  var element = document.querySelector(".modal-open");

  if (element) {
    // Remove the class
    element.classList.remove("modal-open");

    // Remove the inline style
    element.removeAttribute("style");
  }
}

export { closeModal };
