import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExpand,
  faTimes,
  faPaperPlane,
  faChevronLeft,
} from "@fortawesome/fontawesome-free-solid";

import { Popover, Spin } from "antd";
import moment from "moment";

import LoginStore from "../../../../services/thirdPartyService";
import ApiServices from "../../../../services/apiServices";

// import * as signalR from "@aspnet/signalr/dist/browser/signalr.js";

var ChatProxy;
var SignalrConnection;

var sessionData = {};
var chatItineraryPropertyID = "0000111122223333";
var chatMessagesMap = new Map();
var responseTransfreeProperties = [];

const MessageCenterNew = () => {
  let displayone = { propertyname: "General" };
  const chatMsgsRef = useRef(null);

  const [state, setState] = useState({
    loader: false,
    sessionData: {},
    transfereeProperties: [],
    generalProperty: {
      id: "0000111122223333",
      apiId: "0000111122223333",
      propertyanalyticsuniqueId: "0000111122223333",
      property_image: "assets/images/default-img.jpg",
      address: "General",
      countUnread: 0,
    },
    countUnread: [],
    openChatboxPopup: true,
    openPropetyChatList: false,
    openChatBox: false,
    TotalUnreadCount: 0,
    onlineusers: [],
    chatItineraryPropertyID: "0000111122223333",
    responseTransfreeProperties: [],
  });

  const [TextMessage, setTextMessage] = useState("");

  function deepCopy(source) {
    return JSON.parse(JSON.stringify(source));
  }

  function changeLoaderState(mode) {
    if (mode === "start") {
      updateState({ loader: true });
    } else {
      updateState({ loader: false });
    }
  }

  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  async function getSessionData() {
    try {
      let sessionData = await LoginStore.getLoginInfo({});
      sessionData.subscriptioncode =
        localStorage.getItem("loginSubscribeDetails") != null
          ? JSON.parse(localStorage.getItem("loginSubscribeDetails"))
              .subscriptioncode
          : "";
      return sessionData;
    } catch (err) {
      console.log(err);
    }
  }

  function getUreadCount(AppId, TransfereeId) {
    let countUnread = state.countUnread.filter(function (o) {
      return (
        o.APISource == AppId &&
        o.FromId == sessionData.consultantUId &&
        o.ToId == TransfereeId
      );
    });
    return countUnread.length > 0 ? countUnread[0].Count : 0;
  }

  async function getData(transfereeProperties) {
    let ZillowBanglow = transfereeProperties.filter(function (element) {
      return element.source != "my_property34" && element.propertyname;
    });

    responseTransfreeProperties = deepCopy(ZillowBanglow);

    let generalCategory = { ...state.generalProperty };
    generalCategory.countUnread = getUreadCount(
      generalCategory.id,
      sessionData.userUniqueId
    );

    updateState({
      generalProperty: generalCategory,
      responseTransfreeProperties: deepCopy(ZillowBanglow),
    });
  }

  async function getChatPropertiesList() {
    changeLoaderState("start");

    try {
      sessionData = await getSessionData();

      updateState({ sessionData: { ...sessionData } });

      let GetTransfereePropertiesObject = {
        transfereeuniqueId: sessionData.userUniqueId,
      };
      let transfereeProperties = await ApiServices.GetTransfereeProperties(
        GetTransfereePropertiesObject
      );
      let transfereepropertyList =
        transfereeProperties.message.transfereepropertyList;

      let myPropertiesList = transfereepropertyList.filter(function (element) {
        return (
          element.source != "zillow" &&
          element.isinterested == true &&
          element.isactive == true &&
          element.apiId != ""
        );
      });
      if (transfereeProperties.error == 0) {
        if (sessionData.subscriptioncode != "ReloNavigator") {
          updateState({ transfereeProperties: myPropertiesList });
        }
      }
      getData(myPropertiesList);
    } catch (err) {
      console.log(err);
    }
  }

  // --------------------------------

  function closeChatList() {}

  function componentDidMountLogic() {
    let subscriptioncode =
      localStorage.getItem("loginSubscribeDetails") != null
        ? JSON.parse(localStorage.getItem("loginSubscribeDetails"))
            .subscriptioncode
        : "";

    if (subscriptioncode != "ReloNavigator") {
      makeConnectionSignalR();
    }
  }

  async function logoutFromChat() {
    let changeSession = localStorage.getItem("ChatSession");

    if (changeSession != null && changeSession != undefined) {
      let responseText = await ChatProxy.invoke("LogoutUserChatt", {
        userId: changeSession,
      });
      localStorage.removeItem("ChatSession");
    }
  }

  function changeConsultantOnlineStatus(isOnline) {
    const onlineStatus = isOnline ? "Currently Online" : "Currently Offline";
    updateState({ popoverText: onlineStatus });

    const userElement = $("#online" + sessionData.consultantUId);
    if (isOnline) {
      userElement.removeClass("offline");
      userElement.addClass("online");
    } else {
      userElement.removeClass("online");
      userElement.addClass("offline");
    }
  }

  function processLogoutUsersData(onlineusers) {
    console.log("Processing logout users");
    if (onlineusers && onlineusers.length) {
      localStorage.setItem("onlineusers", onlineusers);
      let onlineUserIndex = _.indexOf(onlineusers, sessionData.consultantUId);
      onlineUserIndex > -1
        ? changeConsultantOnlineStatus(true)
        : changeConsultantOnlineStatus(false);
    }
  }

  function isMessageForCurrentChat(row) {
    return (
      row.apiId == chatItineraryPropertyID &&
      row.toId == sessionData.userUniqueId
    );
  }

  function isMessageFromGeneralProperty(row) {
    return (
      row.apiId != chatItineraryPropertyID &&
      row.apiId == "0000111122223333" &&
      row.toId == sessionData.userUniqueId
    );
  }

  function updatePropertyWithCountUnread(row) {
    let updatedPropertiesList = increaseUreadCountForProperty(
      responseTransfreeProperties,
      row.apiId
    );

    updatedPropertiesList = movePropertyToTop(updatedPropertiesList, row.apiId);

    responseTransfreeProperties = deepCopy(updatedPropertiesList);
    updateState({
      TotalUnreadCount: state.TotalUnreadCount + 1,
      responseTransfreeProperties: deepCopy(updatedPropertiesList),
    });
  }

  function updateGeneralPropertyWithCountUnread() {
    let generalProperty = { ...state.generalProperty };

    generalProperty.countUnread = generalProperty.countUnread + 1;
    generalProperty.updatedDate = moment()
      .tz("America/Los_Angeles")
      .format("MM/DD/YYYY HH:mm:ss");

    updateState({
      generalProperty,
      TotalUnreadCount: state.TotalUnreadCount + 1,
    });
  }

  function handleMessageFromCurrentChat(row) {
    let updatedPropertiesList = movePropertyToTop(
      responseTransfreeProperties,
      row.apiId
    );

    responseTransfreeProperties = deepCopy(updatedPropertiesList);
    updateState({
      responseTransfreeProperties: deepCopy(updatedPropertiesList),
    });

    let ReadObj = {
      toId: sessionData.userUniqueId,
      fromId: sessionData.consultantUId,
      apiId: chatItineraryPropertyID,
    };
    ChatProxy.invoke("MessageReadComplete", ReadObj);
  }

  function processArrayResponse(data) {
    data.map((row, index) => {
      if (!isMessageForCurrentChat(row)) {
        console.log("message is not for current chat", data);
        updatePropertyWithCountUnread(row);
      } else if (isMessageFromGeneralProperty(row)) {
        console.log("message is for general property", data);
        updateGeneralPropertyWithCountUnread();
      } else {
        // message is from currently open chat
        console.log("message is for current chat", data);
        handleMessageFromCurrentChat(row);
      }
    });

    updateChatMessagesMap(chatItineraryPropertyID, data);

    updateState({
      showChatMsg: true,
    });
  }

  function processObjectResponse(data) {
    console.log(data);
    updateState({ countUnread: data.count });
    updateNewCount();

    if (data.messagesList) {
      updateState({ loader: false });
      chatMessagesMap = prepareMapFromMessagesList(data.messagesList);

      // sortResponseTransfereeProperties(chatMessagesMap);
    }

    updateState({ showChatMsg: true });
  }

  // function sortResponseTransfereeProperties(chatMessagesMap) {
  //   // console.log(responseTransfreeProperties, chatMessagesMap);

  //   responseTransfreeProperties.sort((a, b) => {
  //     let a_apiId = a.propertyanalyticsuniqueId,
  //       b_apiId = b.propertyanalyticsuniqueId;

  //     let a_msgList = chatMessagesMap.get(a_apiId),
  //       b_msgList = chatMessagesMap.get(b_apiId);
  //     let a_msgListLength = a_msgList?.length,
  //       b_msgListLength = b_msgList?.length;

  //     let a_lastMsgTime = a_msgList[a_msgListLength - 1].datetime,
  //       b_lastMsgTime = b_msgList[b_msgListLength - 1].datetime;

  //     return new Date(b_lastMsgTime) - new Date(a_lastMsgTime);
  //   });

  //   // console.log(responseTransfreeProperties);

  //   responseTransfreeProperties = deepCopy(responseTransfreeProperties);
  // }

  function sortResponseTransfereeProperties(chatMessagesMap) {
    // Ensure responseTransfreeProperties is an array and contains elements
    if (
      !Array.isArray(responseTransfreeProperties) ||
      responseTransfreeProperties.length === 0
    )
      return;

    responseTransfreeProperties.sort((a, b) => {
      let a_apiId = a.propertyanalyticsuniqueId,
        b_apiId = b.propertyanalyticsuniqueId;

      let a_msgList = chatMessagesMap.get(a_apiId),
        b_msgList = chatMessagesMap.get(b_apiId);

      // Handle undefined or empty message lists
      let a_msgListLength = a_msgList?.length || 0,
        b_msgListLength = b_msgList?.length || 0;

      let a_lastMsgTime =
          a_msgListLength > 0 ? a_msgList[a_msgListLength - 1].datetime : null,
        b_lastMsgTime =
          b_msgListLength > 0 ? b_msgList[b_msgListLength - 1].datetime : null;

      // Handle cases where there is no valid datetime
      if (!a_lastMsgTime && !b_lastMsgTime) return 0;
      if (!a_lastMsgTime) return 1;
      if (!b_lastMsgTime) return -1;

      return new Date(b_lastMsgTime) - new Date(a_lastMsgTime);
    });

    // Deep copy if needed
    responseTransfreeProperties = deepCopy(responseTransfreeProperties);
    updateState({
      responseTransfreeProperties: deepCopy(responseTransfreeProperties),
    });
  }

  function processMessageFromServer(data) {
    console.log("Processing message from server", data);
    if (Array.isArray(data)) {
      console.log("Proceeding with array logic");
      // handle logic for the incomming message [{apiId: ,}]
      processArrayResponse(data);
    } else {
      console.log("Proceeding with object logic");

      processObjectResponse(data);
      processLogoutUsersData(data.onlineusers);
      updateState({ onlineusers: data.onlineusers });
    }

    scrollDivBottom();
  }

  async function makeConnectionSignalR() {
    console.log(chatItineraryPropertyID, "chatItineraryPropertyID");
    var ChatServerUrl = "https://apis.relonavigator.com/";
    var ChatUrl = ChatServerUrl + "signalr";
    SignalrConnection = $.hubConnection(ChatUrl, {
      useDefaultPath: false,
    });

    ChatProxy = SignalrConnection.createHubProxy("ChatHub");

    ChatProxy.on("getLogoutUsersList", (data) =>
      processLogoutUsersData(data.onlineusers)
    );

    ChatProxy.on("messageFromServer", processMessageFromServer.bind(this));

    SignalrConnection.start()
      .done(function (data) {
        if (true || _this._isMounted) {
          updateState({ chatIdSignalR: data.id });
        }
        var objC = {};
        objC.userId = sessionData.userUniqueId;
        objC.apiId = chatItineraryPropertyID;
        objC.connectionId = data.id;
        objC.isconsultent = false;
        let changeSession = localStorage.getItem("ChatSession");
        if (changeSession == null || changeSession == undefined) {
          ChatProxy.invoke("RegisterOnlineUser", objC);
          ChatProxy.invoke("LogoutUserChatt", { userId: "" });
        }
        openChatBox(
          state.generalProperty.propertyanalyticsuniqueId,
          state.generalProperty.property_image,
          displayone
        );
        logoutFromChat();
      })
      .fail(function () {
        console.log("failed in connecting to the signalr server");
      });

    changeLoaderState("stop");
  }

  function getSignalRConnection() {
    SignalrConnection.start()
      .done(function (data) {
        updateState({ chatIdSignalR: data.id });
        var objC = {};
        objC.userId = sessionData.userUniqueId;
        objC.apiId = 0;
        objC.connectionId = data.id;
        objC.isconsultent = false;

        ChatProxy.invoke("RegisterOnlineUser", objC);
      })
      .fail(function () {
        console.log("failed in connecting to the signalr server");
      });
  }

  function openChatBox(Id, property_image_pic, row) {
    console.log(Id, property_image_pic, row, "Id, property_image_pic, row");

    let property_image_img = property_image_pic
      ? property_image_pic.split(",")[0]
      : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png";

    updateState({
      displayname: row.propertyname,
      showChatMsg: false,
      profilePic: property_image_img,
      isPropertySelected: true,
      chatItineraryPropertyID: Id,
      ChatProperty: "",
      openChatBox: true,
      openPropetyChatList: false,
    });

    clearBadgeOnSeeingMessages(Id);

    chatItineraryPropertyID = Id;
    console.log(Id, "chatItineraryPropertyID");

    let ReadObj = {
      toId: sessionData.userUniqueId,
      fromId: sessionData.consultantUId,
      apiId: Id,
    };
    ChatProxy.invoke("MessageReadComplete", ReadObj);
    getSignalRConnection();

    $("#left1").addClass("chat-left-md-view");
    $("#right1").removeClass("chat-right-md-view");

    let TotalUnreadCountOriginal = state.TotalUnreadCount;
    if (Id == "0000111122223333") {
      TotalUnreadCountOriginal =
        TotalUnreadCountOriginal - state.generalProperty.countUnread;
      updateState({ TotalUnreadCount: TotalUnreadCountOriginal });
    } else {
      let filterObject = _.findIndex(responseTransfreeProperties, function (o) {
        return o.propertyanalyticsuniqueId == Id;
      });

      TotalUnreadCountOriginal =
        TotalUnreadCountOriginal -
        responseTransfreeProperties[filterObject].countUnread;

      responseTransfreeProperties[filterObject].countUnread = 0;
      updateState({
        TotalUnreadCount: TotalUnreadCountOriginal,
        responseTransfreeProperties: responseTransfreeProperties,
      });
    }
  }

  function scrollDivBottom() {
    var objDiv = $("#chatPopup").height();
    $("#chatPopup").scrollTop(objDiv * 100);

    var objDiv2 = $("#chat_msgs").height();
    $("#chat_msgs").scrollTop(objDiv2 * 100);
  }

  function updateNewCount() {}

  function clearBadgeOnSeeingMessages(Id) {
    const updatedPropertiesList = state.responseTransfreeProperties.map(
      (property) => {
        if (property.propertyanalyticsuniqueId === Id) {
          return {
            ...property,
            countUnread: 0,
          };
        }
        return property;
      }
    );

    // responseTransfreeProperties = deepCopy(updatedPropertiesList);
    updateState({
      responseTransfreeProperties: deepCopy(updatedPropertiesList),
    });
  }

  function messageText(event) {
    event.persist();
    let textMessage = event.target.value;
    setTextMessage(textMessage);
  }

  function sendMessage() {
    let sendMessageObj = {
      apiId: chatItineraryPropertyID,
      message: TextMessage.trim(),
      fromId: sessionData.userUniqueId,
      toId: sessionData.consultantUId,
      datetime: moment()
        .tz("America/Los_Angeles")
        .format("MM/DD/YYYY HH:mm:ss"),
      isconsultant: false,
    };

    if (TextMessage.trim() !== "") {
      let updatedPropertiesList = movePropertyToTop(
        state.responseTransfreeProperties,
        chatItineraryPropertyID
      );

      // responseTransfreeProperties = deepCopy(updatedPropertiesList);

      updateState({
        responseTransfreeProperties: deepCopy(updatedPropertiesList),
      });
      updateChatMessagesMap(chatItineraryPropertyID, sendMessageObj);

      ChatProxy.invoke("SendMessage", sendMessageObj);

      scrollDivBottom();
      $("#InputMsg").val("");
      $("#InputMsg2").val("");
      $("#InputMsg2").focus();

      setTextMessage("");
    }
  }

  function movePropertyToTop(propertiesList, propertyUniqueId) {
    let propertyWithUnreadMsg = propertiesList.filter(
      (property) => property.propertyanalyticsuniqueId === propertyUniqueId
    );

    let otherProperties = propertiesList.filter(
      (property) => property.propertyanalyticsuniqueId !== propertyUniqueId
    );

    return [...propertyWithUnreadMsg, ...otherProperties];
  }

  function increaseUreadCountForProperty(propertiesList, propertyUniqueId) {
    return propertiesList.map((property) => {
      if (property.propertyanalyticsuniqueId === propertyUniqueId) {
        return {
          ...property,
          countUnread: property.countUnread ? property.countUnread + 1 : 1,
        };
      }
      return property;
    });
  }

  function prepareMapFromMessagesList(messages) {
    const groupedMessages = messages.reduce((grouped, message) => {
      const { apiId } = message;
      if (!grouped.has(apiId)) {
        grouped.set(apiId, []);
      }
      grouped.get(apiId).push(message);
      return grouped;
    }, new Map());

    // Sort each group by datetime
    groupedMessages.forEach((messages) => {
      messages.sort((a, b) => new Date(a.datetime) - new Date(b.datetime));
    });

    return groupedMessages;
  }

  function updateChatMessagesMap(apiId, sendMessageObj) {
    let propertyMessagesList = chatMessagesMap.get(apiId) || [];

    if (Array.isArray(sendMessageObj)) {
      propertyMessagesList.push(sendMessageObj[0]);
    } else {
      propertyMessagesList.push(sendMessageObj);
    }
    chatMessagesMap.set(apiId, propertyMessagesList);
  }
  function searchProperty(value) {
    const propertyList = responseTransfreeProperties;
    let list = state.responseTransfreeProperties;
    if (value != "") {
      const filterProperty = propertyList.filter(
        (o) =>
          // Object.keys(o).some((k) =>
          //   String(o[k]).toLowerCase().includes(value.toLowerCase())
          o.propertyname.toLowerCase().includes(value.toLowerCase()) ||
          o.address.toLowerCase().includes(value.toLowerCase())
      );
      console.log(filterProperty, "filterProperty", propertyList);

      updateState({
        responseTransfreeProperties: filterProperty,
      });
    } else {
      console.log("no value", propertyList);
      updateState({
        responseTransfreeProperties: propertyList,
      });
    }
  }
  function backChatBox() {
    $("#left1").removeClass("chat-left-md-view");
    $("#right1").addClass("chat-right-md-view");
  }

  useEffect(() => {
    getChatPropertiesList();
    componentDidMountLogic();
  }, []);

  useEffect(() => {
    if (state.sessionData.consultantUId && state.onlineusers?.length) {
      processLogoutUsersData(state.onlineusers);
    }
  }, [state.sessionData.consultantUId, state.onlineusers]);

  useEffect(() => {
    if (
      responseTransfreeProperties.length &&
      chatMessagesMap.size &&
      responseTransfreeProperties.length <= chatMessagesMap.size
    ) {
      sortResponseTransfereeProperties(chatMessagesMap);
    }
  }, [chatMessagesMap]);

  return (
    <div className="b-radius-8 chat-main-div mt-3">
      {state.loader && <div className="loading">Loading</div>}

      <div className="chat_footer chat_footer-fullview">
        {state.openChatboxPopup == true ? (
          <div className="communitie_pop">
            {/* <div className="communities_header d-flex justify-content-between">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeChatList.bind(this)}
              >
                <span aria-hidden="true">
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              </button>
            </div> */}

            <div className="d-flex d-flex-chat">
              {/* left container */}
              <div className="item properties" id="left1">
                <h5>Message center</h5>

                <div class="input-group mb-3">
                  <span class="input-group-text">
                    <i class="bi bi-search"></i>
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Propertyname"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onChange={(e) => searchProperty(e.target.value)}
                  />
                </div>

                <div className="contacts_list">
                  {/* messages ---general tab---start */}
                  <div
                    key={1000}
                    className={
                      // "chat_item d-flex align-items-center justify-content-start "
                      chatItineraryPropertyID ==
                      state.generalProperty.propertyanalyticsuniqueId
                        ? "chat_item d-flex align-items-center justify-content-start activeItem"
                        : "chat_item d-flex align-items-center justify-content-start"
                    }
                    id={
                      "highlight" +
                      state.generalProperty.propertyanalyticsuniqueId
                    }
                    onClick={() =>
                      openChatBox(
                        state.generalProperty.propertyanalyticsuniqueId,
                        state.generalProperty.property_image,
                        displayone
                      )
                    }
                  >
                    <div className="item_img bd-highlight">
                      <i className="bi bi-check-circle-fill"></i>
                      <img src={state.generalProperty.property_image} alt="" />
                      <div
                        className={
                          "msg_count" +
                          (state.generalProperty.countUnread > 0
                            ? ""
                            : " hideBadge")
                        }
                        id={
                          "bw" + state.generalProperty.propertyanalyticsuniqueId
                        }
                      >
                        <span
                          className="badge"
                          id={
                            "bbd" +
                            state.generalProperty.propertyanalyticsuniqueId
                          }
                        >
                          {state.generalProperty.countUnread}
                        </span>
                      </div>
                    </div>

                    <div className="chat_dtls">
                      <span>{state.generalProperty.address}</span>
                    </div>
                  </div>
                  {/* messages ---general tab--- end */}

                  {/* messages ---individual tabs ---start*/}
                  {state.responseTransfreeProperties.map((row, index) => {
                    return (
                      <IndividualPropertyTab
                        openChatBox={openChatBox}
                        state={state}
                        row={row}
                        key={index}
                      />
                    );
                  })}
                  {/* messages --- individual tabs --end */}
                </div>
              </div>

              {/* right container */}
              <div id="right1" className="item ">
                {/* <div
                  onClick={() => backChatBox()}
                  className="chat-heading-view"
                >
                  <img src="../assets/images/back-arrow.svg" alt="" />
                  <span>{state.displayname}</span>
                </div> */}

                <div className="contactDP">
                  <img
                    src={
                      sessionData.consultantProfileImage != "" &&
                      sessionData.consultantProfileImage != null
                        ? sessionData.consultantProfileImage
                        : "../assets/images/profile-pic.svg"
                    }
                    alt=""
                  />{" "}
                  <h5>
                    {sessionData.consultantname}

                    <div
                      className="circle offline"
                      id={"online" + sessionData.consultantUId}
                    >
                      {" "}
                      Online{" "}
                    </div>
                    {/* </Popover> */}
                  </h5>
                  <i className="bi bi-three-dots-vertical"></i>
                </div>

                {state.isPropertySelected == true ? (
                  <div>
                    {/* chat messages history */}
                    <div className="chat_msgs" id="chatPopup" ref={chatMsgsRef}>
                      {chatMessagesMap.size &&
                        chatMessagesMap
                          .get(chatItineraryPropertyID)
                          ?.map((row, index) => {
                            return (
                              <div key={index}>
                                {sessionData != "" &&
                                sessionData.userUniqueId == row.fromId ? (
                                  <div className="right_chat_view ">
                                    <div className="me-3">
                                      <div className="right-user-time">
                                        {moment(row.datetime).calendar()}
                                      </div>

                                      <div className="right_chat">
                                        {row.message}{" "}
                                        <i className="bi bi-check-all ms-2"></i>
                                      </div>
                                    </div>
                                    <img
                                      src="../assets/images/profilePic.png"
                                      alt=""
                                    />
                                  </div>
                                ) : (
                                  <div className="left_chat_view ">
                                    <div className="left-image">
                                      <img
                                        src={
                                          sessionData.consultantprofilepic !=
                                            "" &&
                                          sessionData.consultantprofilepic !=
                                            null
                                            ? sessionData.consultantprofilepic
                                            : "../assets/images/profilePic.png"
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div>
                                      <div className="left-user-time">
                                        {row.firstname} {row.lastname},{" "}
                                        {moment(row.datetime).calendar()}
                                      </div>
                                      <div key={index} className="left_chat">
                                        {row.message}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}

                      {state.showChatMsg == false ? (
                        <div style={{ paddingTop: "100px" }}>
                          <Spin />
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="clearfix"></div>
                    </div>

                    {/* chat message send box */}
                    <div className="sent_box d-flex align-items-center">
                      <div className="position-relative w-100">
                        <i class="bi bi-emoji-smile"></i>
                        <input
                          tabIndex="1"
                          type="text"
                          id="InputMsg2"
                          defaultValue={TextMessage}
                          placeholder="Send message"
                          onChange={messageText}
                        />
                        <i class="bi bi-plus-circle"></i>
                      </div>

                      <span
                        tabIndex="2"
                        className={TextMessage != "" ? "" : "chatIconColor"}
                        onClick={sendMessage}
                      >
                        {/* <FontAwesomeIcon icon={faPaperPlane} /> */}
                        <img src="../assets/images/Vector.svg" />
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const IndividualPropertyTab = ({ openChatBox, state, row }) => {
  return (
    <div
      className={
        chatItineraryPropertyID == row.propertyanalyticsuniqueId
          ? "chat_item d-flex align-items-center justify-content-start activeItem"
          : "chat_item d-flex align-items-center justify-content-start"
      }
      id={
        "highlight" +
        (row.id != undefined ? row.id : row.propertyanalyticsuniqueId)
      }
      onClick={() =>
        openChatBox(
          row.id != undefined ? row.id : row.propertyanalyticsuniqueId,
          row.property_image,
          row
        )
      }
    >
      <div className="item_img bd-highlight">
        <i className="bi bi-check-circle-fill"></i>
        <img
          src={
            row.property_image
              ? row.property_image.split(",")[0]
              : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
          }
          alt=""
        />
        <div
          className={"msg_count" + (row.countUnread > 0 ? "" : " hideBadge")}
          id={
            "bw" +
            (row.id != undefined ? row.id : row.propertyanalyticsuniqueId)
          }
        >
          <span
            className="badge"
            id={
              "bbd" +
              (row.id != undefined ? row.id : row.propertyanalyticsuniqueId)
            }
          >
            {row.countUnread}
          </span>
        </div>
      </div>

      <div className="chat_dtls">
        <span>
          {row.propertyname} - {row.address}
          {/* <span className="dataTime"> 25 Aug </span> */}
        </span>
      </div>
    </div>
  );
};

export default MessageCenterNew;
