import React from "react";
import DatePicker from "react-datepicker";

const ToDateComponent = ({ startDate, endDate, handleChangeEnd }) => {
  return (
    <div className="form-group col">
      <label>To: </label>
      <div
        id="datepicker"
        className="input-group date"
        data-date-format="mm-dd-yyyy"
      >
        <DatePicker
          id="enddate"
          dateFormat="MM/dd/yyyy"
          selected={endDate}
          selectsEnd
          autoComplete="off"
          startDate={startDate}
          endDate={endDate}
          onChange={handleChangeEnd}
          minDate={startDate}
          placeholderText="Select end date ..."
          className="form-control"
        />
        {/* <FontAwesomeIcon icon={faCalendarDays} /> */}
      </div>
    </div>
  );
};

export default ToDateComponent;
