// Import routing components
import AllRoutes from "./routes";
import "jquery";
import "../public/assets/css/allstyles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container);
root.render(<div>{AllRoutes()}</div>);
