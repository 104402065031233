import React from "react";
// import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faClock } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Tooltip, Modal } from "antd";

const CompletedTasksModal = ({
  isOpen,
  onClose,
  completedTasks,
  onClickCheck,
  onSelectEditTask,
  onDelete,
}) => {
  const Close = () => {
    $("#completed-check-list").modal("hide");
  };
  return (
    <div
      id="completed-check-list"
      style={{
        transition: "none",
        zIndex: 1060,
      }}
      className="modal fade"
      role="dialog"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="width-60per pd-y-60">
            <div className="only_close">
              <span
                className="pointer close"
                data-dismiss="modal"
                onClick={() => Close()}
              >
                &times;
              </span>
            </div>
            <h6>Completed Tasks</h6>
            <div className="container">
              {completedTasks.length > 0 ? (
                <ul>
                  {completedTasks.map((row, index) => {
                    return (
                      <li key={index}>
                        <span className="main">
                          {row.checklistdescription.charAt(0).toUpperCase() +
                            row.checklistdescription.slice(1)}
                        </span>
                        <span>{moment(row.completeddate).format("LLLL")}</span>
                        <button
                          type="button"
                          className={
                            row.checklisttype == "Pre-Move"
                              ? "btn premove"
                              : "btn postmove"
                          }
                        >
                          {row.checklisttype}
                        </button>

                        {/* <div className="top_label-div" key={index}>
                          <label className="checklist">
                            {row.checklistdescription.charAt(0).toUpperCase() +
                              row.checklistdescription.slice(1)}
                            <input
                              type="checkbox"
                              id={index}
                              onChange={() => onClickCheck(row)}
                              checked={row.status === "completed"}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <span>
                            {row.status !== "completed" && (
                              <a>
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  onClick={() => onSelectEditTask(row)}
                                />{" "}
                              </a>
                            )}
                            {row.status !== "completed" ? (
                              <a>
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  onClick={() => onDelete(row)}
                                />{" "}
                              </a>
                            ) : (
                              <a>
                                <Tooltip
                                  title={
                                    "Completed on " +
                                    moment(row.completeddate).format("LLLL")
                                  }
                                >
                                  <span>
                                    <i class="fa-regular fa-clock"></i>
                                  </span>
                                </Tooltip>{" "}
                              </a>
                            )}
                          </span>
                        </div> */}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <label
                  className="checklist"
                  style={{ textAlign: "center", width: "100%" }}
                >
                  There are no completed Tasks at this time!
                </label>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletedTasksModal;
