import React from "react";
import DatePicker from "react-datepicker";

const FromDateComponent = ({ startDate, endDate, handleChangeStart }) => {
  return (
    <div className="form-group col">
      <label>From: </label>

      <div
        id="datepickera"
        className="input-group date"
        data-date-format="mm-dd-yyyy"
      >
        <DatePicker
          id="startdate"
          dateFormat="MM/dd/yyyy"
          selected={startDate}
          selectsStart
          maxDate={endDate}
          onChange={handleChangeStart}
          placeholderText="Select start date ..."
          className="form-control"
          autoComplete="off"
        />
        {/* <FontAwesomeIcon icon={faCalendarDays} /> */}
      </div>
    </div>
  );
};

export default FromDateComponent;
