import React from "react";

function PublicBanner() {
  const demoBtn = () => {
    location.href = "/contact-us";
  };
  return (
    <div className="bannerPart">
      <div className="container">
        <div className="view">
          <div className="box-1" data-aos="fade-up">
            <h1>Transform the Way You Manage Destination Services</h1>
            <p>
              For companies, consultants, and individuals—our patented platform
              streamlines relocations, making every move efficient and seamless.
            </p>
            <button type="button" className="btn" onClick={() => demoBtn()}>
              Request a Demo
            </button>
          </div>
          <div className="box">
            <div className="items">
              <img
                src="assets/publicImages/items-1.png"
                alt=""
                width="100%"
                data-aos="fade-down"
              />
            </div>
            <div className="items">
              <img
                src="assets/publicImages/items-2.png"
                alt=""
                width="100%"
                data-aos="fade-right"
              />
            </div>
            <div className="items">
              <img
                src="assets/publicImages/items-3.png"
                alt=""
                width="100%"
                data-aos="fade-up"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublicBanner;
