import React from "react";

function NonTAMovingTips() {
  const closeModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#NonTAModalMovingTips").modal("hide");
      });
    });
  };
  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header border-0">
          <div class="only_close">
            <span
              aria-hidden="true"
              className="pointer close"
              onClick={() => closeModal()}
              data-dismiss="modal"
            >
              ×
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div className="widthModal">
            <div className="item-view">
              <h2>Moving Tips</h2>
              <p>
                Relocating to a new city or country is an exciting experience,
                but it can also come with many challenges. To make your
                transition as smooth as possible, we’ve compiled a list of
                moving tips designed to help you prepare and settle into your
                new location.
              </p>
            </div>

            <div className="tails-row">
              <div className="tails">
                <div className="item-view">
                  <h2>Pre-Move Planning </h2>
                  <h3>Create a Relocation Timeline</h3>

                  <ul>
                    <li>
                      Start planning early by setting a timeline that outlines
                      key tasks such as booking movers, securing housing, and
                      finalizing travel arrangements. This will help ensure you
                      stay organized and meet important deadlines.
                    </li>
                  </ul>

                  <h3>Research Your New Area</h3>
                  <ul>
                    <li>
                      Familiarize yourself with your new location by researching
                      neighborhoods, schools, healthcare facilities, and
                      essential services. Understanding the area before you
                      arrive will make the adjustment easier.
                    </li>
                  </ul>
                  <h3>Notify Key Contacts </h3>
                  <ul>
                    <li>
                      Inform your current landlord, utility companies, and
                      service providers about your move-out date. Be sure to
                      update your bank, insurance, and other institutions with
                      your new address, and forward your mail through the postal
                      service.
                    </li>
                  </ul>

                  <h3>Gather Important Documents </h3>
                  <ul>
                    <li>
                      Collect important paperwork like identification, housing
                      contracts, medical records, and insurance documents. Keep
                      these in a safe, easily accessible place during your move.
                    </li>
                  </ul>
                  <h3>Budget for Your Move </h3>
                  <ul>
                    <li>
                      Plan your relocation budget, accounting for moving
                      services, travel expenses, and any potential unexpected
                      costs. Be sure to include a buffer for emergencies.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="tails">
                <div className="item-view">
                  <h2>Packing Strategies </h2>

                  <h3>Declutter and Downsize </h3>
                  <ul>
                    <li>
                      Moving is the perfect time to sort through your
                      belongings. Donate, sell, or dispose of items you no
                      longer need. This will not only reduce the number of items
                      you need to pack but also make it easier to organize your
                      new space.
                    </li>
                  </ul>
                  <h3>Pack in Stages </h3>
                  <ul>
                    <li>
                      Start packing well in advance, beginning with items you
                      use least often. Save everyday essentials like toiletries,
                      clothes, and kitchen items for last.
                    </li>
                  </ul>

                  <h3>Use Quality Packing Materials </h3>
                  <ul>
                    <li>
                      Invest in sturdy boxes, bubble wrap, and packing tape to
                      protect your belongings. Label each box with its contents
                      and the room it belongs to for efficient unpacking.
                    </li>
                  </ul>

                  <h3>Create a First-Night Box </h3>
                  <ul>
                    <li>
                      Pack a box with everything you’ll need for the first night
                      in your new home, including essentials like toiletries, a
                      change of clothes, basic kitchen supplies, and important
                      documents. This will save you from rummaging through boxes
                      after a long day of moving.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="tails">
                <div className="item-view">
                  <h2>Settling Into Your New Home </h2>
                  <h3>Inspect Your New Home Upon Arrival </h3>
                  <ul>
                    <li>
                      Before unpacking, inspect your new home for any issues or
                      repairs needed. If you’re renting, take note of any
                      damages and report them to your landlord to avoid future
                      disputes.
                    </li>
                  </ul>

                  <h3>Unpack Essentials First </h3>
                  <ul>
                    <li>
                      Focus on setting up key areas like the kitchen, bathroom,
                      and bedroom first. Unpacking these essentials will help
                      your new space feel like home more quickly.
                    </li>
                  </ul>
                  <h3>Explore Your Neighborhood </h3>
                  <ul>
                    <li>
                      Take some time to explore your new surroundings. Locate
                      nearby grocery stores, restaurants, parks, and public
                      transportation options. Getting to know your neighborhood
                      will help you feel more at home and ease your daily
                      routines.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="tails">
                <div className="item-view">
                  <h2>Staying Organized and Connected </h2>
                  <h3>Set Up Utilities and Services </h3>
                  <ul>
                    <li>
                      Arrange for utility services such as electricity, water,
                      internet, and waste collection to be set up in your new
                      home before or shortly after your arrival.
                    </li>
                  </ul>

                  <h3>Update Personal Information </h3>
                  <ul>
                    <li>
                      Ensure your new address is updated on important documents,
                      such as identification, insurance policies, and any
                      necessary registrations. Notify relevant institutions, as
                      well as friends and family, of your new contact details.
                    </li>
                  </ul>
                  <h3>Stay Connected with Your Community </h3>
                  <ul>
                    <li>
                      Join local clubs, groups, or events to meet new people and
                      get involved in the community. Building connections will
                      make the adjustment easier and help you feel settled more
                      quickly.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="tails">
                <div className="item-view">
                  <h2>Final Tips </h2>
                  <h3>Stay Patient During the Process</h3>
                  <ul>
                    <li>
                      Moving can be stressful, so it’s important to stay patient
                      and flexible throughout the process. Accept that there may
                      be bumps along the way and focus on the exciting
                      opportunities ahead.
                    </li>
                  </ul>
                  <h3>Take Care of Yourself </h3>
                  <ul>
                    <li>
                      Amid the chaos of moving, remember to take care of your
                      physical and mental health. Get enough sleep, eat well,
                      and make time for self-care to keep your energy levels up
                      during the transition.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="item-view mt-4">
              <p>
                By following these tips, you can reduce the stress of moving and
                focus on making the most of your new home. With thoughtful
                preparation and a positive outlook, you’ll be ready to thrive in
                your new community and begin this exciting new chapter. Welcome
                to your new home, and we’re here to support you every step of
                the way.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NonTAMovingTips;
