import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import axios from "axios";
import * as $ from "jquery";
import LoginStore from "../../../../services/thirdPartyService";
import ApiServices from "../../../../services/apiServices";

const AddClientModal = ({ getSuppliers, selectedClient, editid }) => {
  const initialState = {
    errorMessage: "",
    ShowSuccess: "",
    editid: false,
    showing: "",
    companyname: "",
    email: "",
    address: "",
    latitude: null,
    longitude: null,
    state: "",
    city: "",
    zipcode: "",
    uploadImages: [],
    uploadLoader: false,
    uploadImageError: "",
  };
  const [state, setState] = useState(initialState);

  function closeModal() {
    $("#companyname,#email, #address").removeClass("is-invalid");
    $("#add-client-modal").modal("hide");
    //restoreInitialState();
  }

  function restoreInitialState() {
    updateState(initialState);
  }

  function inputHandler(field, value) {
    if (field === "email") {
      isValidEmail(value)
        ? removeInvalidFieldsClass("email")
        : addInvalidFieldsClass("email");
    } else {
      value == ""
        ? addInvalidFieldsClass(field)
        : removeInvalidFieldsClass(field);
    }
    updateState({ [field]: value });
  }
  function isValidEmail(email) {
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return false;
    }
    return true;
  }

  function addInvalidFieldsClass(id) {
    $(`[id='${id}']`).addClass("is-invalid");
  }

  function removeInvalidFieldsClass(id) {
    $(`[id='${id}']`).removeClass("is-invalid");
  }

  function setLocation(field, value) {
    value == ""
      ? addInvalidFieldsClass(field)
      : removeInvalidFieldsClass(field);
    updateState({ [field]: value });
    let places = new google.maps.places.Autocomplete(
      document.getElementById("address", {
        types: ["address", "regions"],
      })
    );
    google.maps.event.addListener(places, "place_changed", async () => {
      let place = places.getPlace();
      let placeDetails = {
        locality: "long_name",
        administrative_area_level_1: "short_name",
        country: "long_name",
        postal_code: "short_name",
      };
      for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0];
        if (placeDetails[addressType]) {
          let val = place.address_components[i][placeDetails[addressType]];
          placeDetails[addressType] = val;
        }
      }
      let relocatedAddress = place.formatted_address;
      // console.log(placeDetails, "placeDetails");
      updateState({
        address: relocatedAddress,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        state: placeDetails.administrative_area_level_1,
        city: placeDetails.locality,
        zipcode:
          placeDetails.postal_code != "short_name"
            ? placeDetails.postal_code
            : "",
      });
    });
  }

  function imageChangeHandler(e) {
    let files = e.target.files;
    if (isFileLimitExceeded(e)) {
      return;
    }
    if (!isFileFormatSupported(e)) {
      return;
    }
    uploadImages(e);
  }

  function isFileLimitExceeded(event) {
    let files = event.target.files;
    if (files.length > 5) {
      event.target.value = null;
      updateState({
        uploadImageError: "Only 5 images can be uploaded at a time.",
      });
      return true;
    }
    return false;
  }

  function isFileFormatSupported(event) {
    const types = ["image/png", "image/jpeg", "image/gif"];
    let files = event.target.files;
    let err = "";
    for (let i = 0; i < files.length; i++) {
      if (types.every((type) => files[i].type !== type)) {
        err = "Only supported png, jpeg, gif files format supported.";
      }
    }
    if (err) {
      updateState({
        uploadImageError:
          "Only supported png, jpeg, gif files format supported.",
      });
      $("#propertyImage").addClass("is-invalid");
      return false;
    }
    return true;
  }

  function uploadImages(event) {
    let files = event.target.files;
    const data = new FormData();
    for (var x = 0; x < files.length; x++) {
      data.append("file", files[x]);
    }
    updateState({ uploadImageError: "", uploadLoader: true });
    axios
      .post(`/profileimagesInfo`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        let data = [...state.uploadImages];
        _.forEach(response.data, async (response) => {
          data.push(response);
        });
        updateState({
          uploadImages: data,
          uploadImageError: "Successfully uploaded.",
          uploadLoader: false,
        });
      })
      .catch((error) => {
        updateState({
          uploadImageError: "something went wrong while uploading images",
          uploadLoader: false,
        });
      });
  }

  const removeimage = async (value, id) => {
    let list = Object.assign([], state.uploadImages);
    let arr = list.filter((item) => item !== value);
    setState((prev) => ({ ...prev, uploadImages: arr }));
  };

  function isInputFieldsValid() {
    let errors = 0;
    const mandatoryFields = ["companyname", "email", "address"];

    mandatoryFields.forEach((field) => {
      if (field !== "email" && !state[field].trim()) {
        // console.log(field + " is required");
        addInvalidFieldsClass(field);
        errors++;
        return;
      }

      if (field === "email" && !isValidEmail(state[field])) {
        addInvalidFieldsClass(field);
        errors++;
        return;
      }
      removeInvalidFieldsClass(field);
    });

    return errors === 0;
  }

  function prepareUploadImagesString(obj) {
    let arr = [];
    obj.forEach((item) => {
      item.Location && arr.push(item.Location);
    });
    return arr.toString();
  }

  function prepareListFromImageUrlString(str) {
    if (!str) {
      return [];
    }
    let images = str.split(",");
    let list = [];
    if (images.length > 0) {
      images.forEach((image) => {
        list.push({
          Location: image,
          key: image.replace(
            /^https:\/\/relonavigator\.s3\.us-west-1\.amazonaws\.com\//,
            ""
          ),
        });
      });
      return list;
    }
    return [];
  }

  async function addSupplier() {
    isInputFieldsValid();
    if (isInputFieldsValid()) {
      let getSessionData = await LoginStore.getLoginInfo({});
      if (state.editid == true) {
        let data = {
          companyname: state.companyname,
          address: state.address,
          email: state.email,
          clientuniqueId: state.clientuniqueId,
          supplieruid: state.supplieruid,
          isdelete: 0,
          imageurl: prepareUploadImagesString(state.uploadImages),
        };
        updateState({ uploadLoader: true });
        const UpdateTransferee = await ApiServices.UpdateClientProfile(data);
        if (UpdateTransferee.error == 0) {
          getSuppliers();
          updateState({
            ShowSuccess: true,
            uploadLoader: false,
            uploadImages: [],
            showing: "successfully updated",
          });
        } else {
          updateState({
            errorMessage: true,
            ShowSuccess: false,
            uploadImages: [],
            uploadLoader: false,
          });
        }
      } else if (state.editid == false) {
        let data = {
          companyname: state.companyname,
          address: state.address,
          email: state.email,
          supplieruid: getSessionData.userUniqueId,
          imageurl: prepareUploadImagesString(state.uploadImages),
        };
        updateState({ uploadLoader: true });
        const CreateTransferee = await ApiServices.CreateClientProfile(data);
        if (CreateTransferee.error == 0) {
          getSuppliers();
          updateState({
            ShowSuccess: true,
            uploadLoader: false,
            showing: "Supplier created",
            uploadImages: [],
          });
        } else {
          updateState({
            errorMessage: true,
            ShowSuccess: false,
            uploadLoader: false,
            uploadImages: [],
          });
        }
      }
    }
  }

  function updateState(updateObj) {
    setState((prevObj) => ({ ...prevObj, ...updateObj }));
  }

  useEffect(() => {
    console.log(selectedClient, "selectedClient", editid);
    if (editid) {
      updateState({
        editid: editid,
        companyname: selectedClient.companyname,
        email: selectedClient.email,
        address: selectedClient.address,
        uploadImages: prepareListFromImageUrlString(selectedClient.imageurl),
        clientuniqueId: selectedClient.clientuniqueId,
        supplieruid: selectedClient.supplieruid,
      });
    } else {
      updateState({
        editid: "",
        companyname: "",
        email: "",
        address: "",
        uploadImages: "",
        clientuniqueId: "",
        supplieruid: "",
      });
    }
  }, [selectedClient]);

  return (
    <div
      id="add-supplier-modal"
      className="modal fade  "
      tabIndex="-1"
      aria-labelledby="add-supplier-modal"
      aria-hidden="true"
      style={{ zIndex: 1060 }}
    >
      <div className="modal-dialog modal-lg modal-lg-2">
        <div className="modal-content">
          <div className="width-80per pd-y-70">
            <div className="only_close">
              <span
                className="pointer close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              >
                &times;
              </span>
            </div>
            <form
              method="post"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              {state.editid == true ? (
                <h6>Edit Client</h6>
              ) : (
                <h6>Add Client</h6>
              )}
              {state.errorMessage == true ? (
                <div className="pop-form-controls focus-input-label mt-4">
                  <div className="floating-label">
                    <h6 className="alert alert-warning">
                      something went wrong
                    </h6>
                  </div>
                </div>
              ) : (
                ""
              )}
              {state.ShowSuccess == true ? (
                <div className="pop-form-controls focus-input-label mt-4">
                  <div className="floating-label">
                    <h6 className="alert alert-success">
                      {state.editid == true ? state.showing : state.showing}{" "}
                    </h6>
                  </div>
                </div>
              ) : (
                <div className="pop-form-controls focus-input-label mt-4">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="floating-label">
                        <input
                          placeholder="Company name"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          value={state.companyname}
                          id="companyname"
                          onChange={(e) =>
                            inputHandler("companyname", e.target.value)
                          }
                        />
                        <label>Company name</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="floating-label">
                        <input
                          placeholder="Email"
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          value={state.email}
                          id="email"
                          onChange={(e) =>
                            inputHandler("email", e.target.value)
                          }
                        />
                        <label>Email</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="floating-label">
                        <input
                          placeholder="Enter Location"
                          autoComplete="off"
                          className="form-control"
                          type="text"
                          value={state.address}
                          id="address"
                          onChange={(e) =>
                            setLocation("address", e.target.value)
                          }
                        />
                        <label>Address</label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label className="label_cls">Upload Logo </label>
                    {state.uploadImages.length < 5 ? (
                      <div className="custom-file">
                        {state.uploadLoader ? (
                          <Spin />
                        ) : (
                          <input
                            type="file"
                            onChange={(e) => imageChangeHandler(e)}
                            id="propertyImage"
                            className="form-control fileupload"
                            multiple
                            accept=".png, .jpeg, .jpg, .gif"
                          />
                        )}
                      </div>
                    ) : (
                      <div className="custom-file">
                        Maximum 5 pictures allow
                      </div>
                    )}
                    <div className="custom-file-upimg_multy">
                      {state.uploadImages &&
                        state.uploadImages.map((row, index) => {
                          return (
                            <div key={index} className="custom-file-upimg">
                              <img
                                src={`${state.uploadImages[index].Location}`}
                              />
                              <i
                                className="fa fa-trash"
                                onClick={() => removeimage(row, index)}
                              ></i>
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  {/* {state.uploadImages.length > 0 ? (
                    <ImageTable
                      images={state.uploadImages}
                      removeimage={removeimage}
                    />
                  ) : null} */}

                  <div className="text-center mt-3 mb-3">
                    {state.uploadLoader ? (
                      <Spin />
                    ) : (
                      <button
                        type="submit"
                        className="btn theme-btn"
                        onClick={() => addSupplier("true")}
                      >
                        {state.editid != "" ? "UPDATE" : "SAVE"} <span></span>{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddClientModal;

const ImageTable = ({ images, removeimage }) => {
  const [deletingRows, setDeletingRows] = useState([]);

  function onDelete(key) {
    removeimage(key);
    setDeletingRows([...deletingRows, key]);
  }
  return (
    <table>
      <thead>
        <tr>
          <th>Serial Number</th>
          <th>Key</th>
          <th>Image</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {images.map((image, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{image.key}</td>
            <td>
              <img
                src={image.Location}
                alt={`Image ${index + 1}`}
                style={{ maxWidth: "50px" }}
              />
            </td>
            <td>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => onDelete(image.key)}
                disabled={deletingRows.includes(image.key)}
              >
                Delete
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
