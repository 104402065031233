'use strict'

import ThirdParty from '../services/thirdPartyService'
require('es6-promise').polyfill();
require('isomorphic-fetch');
const credentials = {
  credentials: 'same-origin'
}

//const apiUrl = "https://sharlotteapi.suite.solutions/api"
//const tokenUrl = "https://sharlotteapi.suite.solutions/token"

//const apiUrl = "https://relonav-testapi.suite.solutions/api"
//const tokenUrl = "https://relonav-testapi.suite.solutions/token"

const apiUrl = "https://apis.relonavigator.com/api"
const tokenUrl = "https://apis.relonavigator.com/token"

async function checkStatus(response) {
  let dataResponce;
  if (response.status >= 200 && response.status < 300) {
    dataResponce = await response;
  } else {
    dataResponce = await response;
  }
  return dataResponce;
}


async function getToken() {
  let envAWS = await ThirdParty.envAWS();

  const username = 'admin';
  const password = 'admin';
  const granttype = 'password'
  var data = "username=" + username + "&password=" + password + "&grant_type=" + granttype + "";
  var obj = {
    method: 'POST',
    body: data,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  };

  return await fetch(tokenUrl, obj).then(function (response) {
    return response.json()
  });

}
function getJwtToken() {
  //return appController.jwtToken
}

async function getHeaders() {

  const TokenData = await getToken();
  const tokenString = 'bearer ' + TokenData.access_token;
  const APIKey = 'A374034C-C1E6-47F8-9FED-90889E5648ED'
  return { Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': tokenString, 'APIKey': APIKey }
}

function getUrl(url) {

  const timestamp = new Date().getTime()
  const separator = url.includes('?') ? '&' : '?'
  //noinspection JSUnresolvedVariable

  return `${apiUrl}${url}${separator}t=${timestamp}`

}

/**
 * Base functionality for the server request communications (GET, POST, ...).
 * @type {{get: (function()), postPutDelete: (function()), post: (function()), put: (function()), delete: (function())}}
 */
const serviceBase = {

  get: async url => {
    credentials.headers = await getHeaders()
    let response = await fetch(getUrl(url), credentials).then(function (response) {
      return response;
    }).catch(function (error) {
      return null;
    })
    if (response.status != undefined && response.status >= 200 && response.status < 300) {

      response = await checkStatus(response)
      return response.json()
    } else {
      response = await checkStatus(response)
      return response.json()
      //location.href = '/error/'+(response != undefined  ? response.status:'ERR_INTERNET_DISCONNECTED')
    }
  },

  postPutDelete: async (url, method, request) => {

    const options = {
      headers: await getHeaders(),
      method: method,
      body: JSON.stringify(request)
    }

    let response = await fetch(getUrl(url), Object.assign(options, credentials))
    response = await checkStatus(response)

    if (response.status != undefined && response.status >= 200 && response.status < 300) {

      response = await checkStatus(response)
      return response.json()
    } else {
      response = await checkStatus(response)
      return response.json()
      // location.href = '/error/'+(response != undefined  ? response.status:'ERR_INTERNET_DISCONNECTED')
    }
  },

  post: (url, request) => serviceBase.postPutDelete(url, 'POST', request),

  put: async (url, request) => serviceBase.postPutDelete(url, 'PUT', request),

  delete: (url, request) => serviceBase.postPutDelete(url, 'DELETE', request)
}

export default serviceBase