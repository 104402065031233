import React from "react";
import * as $ from "jquery";

function PackingRecommendationsPopUpTips() {
    const closeModal = () => {
        $(function () {
            $("document").ready(async function () {
                $("#PackingRecommendations").modal("hide");
            });
        });
    };
    return (
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header border-0">
                    <div class="only_close">
                        <span
                            aria-hidden="true"
                            className="pointer close"
                            onClick={() => closeModal()}
                            data-dismiss="modal"
                        >
                            ×
                        </span>
                    </div>
                </div>
                <div className="modal-body">
                    <div className="widthModal">
                        <div className="item-view">
                            <h2>Packing Recommendations</h2>
                            <p>
                                Relocating can be both exciting and overwhelming. To help you prepare for your move, we’ve compiled a list of packing recommendations that will ensure you bring everything you need while staying organized and stress-free.
                            </p>
                        </div>

                        <div className="tails-row">
                            
                            
                            <div className="tails">
                                <div className="item-view">
                                    <h2>Essential Packing Tips</h2>


                                    <ul>
                                        <li>
                                            <b>Start Early:</b> Begin packing well in advance to avoid last-minute stress. Create a timeline and tackle one room at a time to make the process manageable.
                                        </li>
                                        <li><b>Declutter:</b> Before you start packing, go through your belongings and decide what to keep, donate, or discard. This will reduce the volume of items you need to move and help you start fresh in your new place.
                                        </li>
                                        <li><b>Label Boxes Clearly:</b> Label each box with its contents and the room it belongs to. This will make unpacking easier and more efficient. Consider using a color-coding system for different rooms.
                                        </li>
                                        <li><b>Pack Smart:</b> Use sturdy boxes and high-quality packing materials to protect your belongings. Place heavier items at the bottom of boxes and lighter items on top. Fill any empty spaces with packing paper or bubble wrap to prevent items from shifting.

                                        </li>

                                    </ul>


                                </div>
                            </div>
                            
                            <div className="tails">
                                <div className="item-view">
                                    <h2>Packing for Specific Needs</h2>
                                    <p><b>Climate Considerations:</b> Research the climate of your new location and pack accordingly. Include items like a winter coat, rain gear, or lightweight clothing as needed.
                                    </p><p><b>Professional Attire:</b> Pack work-appropriate clothing and accessories for your internship. Ensure you have a mix of outfits suitable for various professional settings.
                                    </p><p><b>Personal Comfort:</b> Bring items that provide comfort and a sense of home, such as favorite pillows, blankets, or photos. These can help you feel more settled in your new environment.</p>
                                </div>
                            </div>
                            <div className="tails">
                                <div className="item-view">
                                <h2>Packing for Travel</h2>
                                    <p><b>Carry-On Essentials:</b> Pack a carry-on bag with important items you'll need immediately upon arrival. Include toiletries, a change of clothes, medications, chargers, and valuable electronics.
                                    </p><p><b>Safety and Security:</b>  Keep valuable items, important documents, and fragile belongings in your carry-on to prevent loss or damage during the move.
                                    </p>
                                </div>
                            </div>
                            


                            <div className="tails">
                                <div className="item-view">
                                    <h2>Room-by-Room Packing Guide</h2>

                                    <h3>Bedroom:</h3>
                                    <ul>
                                        <li>
                                            <b>Clothing:</b> Pack enough seasonal clothing, work attire, casual wear, and accessories for your stay. Consider vacuum-sealed bags to save space.
                                        </li>
                                        <li><b>Personal Items:</b> Bring jewelry, watches, and other small personal items.
                                        </li>
                                        <li><b>Comfort Items:</b> Include any personal bedding like a favorite pillow or blanket for added comfort.

                                        </li>

                                    </ul>
                                    <h3>Bathroom:</h3>
                                    <ul>
                                        <li>
                                            <b>Toiletries:</b> Pack your toothbrush, toothpaste, shampoo, conditioner, soap, and other personal care items. Don’t forget a toiletry bag to keep them organized.
                                        </li>
                                        <li><b>Personal Care:</b> Include any specific skincare or grooming products you use regularly.

                                        </li>

                                    </ul>

                                    <h3>Kitchen:</h3>
                                    <ul>
                                        <li>
                                            <b>Food:</b> Bring any specialty ingredients you prefer. Consider packing some snacks and easy-to-prepare meals that you like for the first few days.
                                        </li>
                                        <li><b>Personal Gadgets:</b> If you have any small appliances that you use frequently, bring it along.

                                        </li>

                                    </ul>
                                    <h3>Living Room:</h3>
                                    <ul>
                                        <li>
                                            <b>Electronics:</b> Bring your laptop, chargers, gaming consoles, remote controls, and any other electronics you use regularly.
                                        </li>
                                        <li><b>Entertainment:</b> Pack books, magazines, and other entertainment items to make your space feel more like home.
                                        </li>
                                        <li><b>Decor:</b> Include small decorative items, such as photos, artwork, or personal mementos, to personalize your living space.

                                        </li>

                                    </ul>
                                    <h3>Office/Study Area:</h3>
                                    <ul>
                                        <li>
                                            <b>Work Supplies:</b>  Ensure you have all necessary work supplies, including your laptop, charger, notepads, pens, and other office essentials.
                                        </li>
                                        <li><b>Important Documents:</b>  Bring along any important documents, such as your offer letter, identification, and any other crucial paperwork.
                                        </li>
                                        <li><b>Organizational Tools:</b>  Pack desk organizers, file folders, and planners to help keep your workspace tidy and efficient.

                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div className="tails">
                                <div className="item-view">
                                <h2>Moving Day Tips</h2>
                                <p><b>Stay Organized:</b> Keep a list of packed items and check them off as they are loaded. This ensures nothing is left behind and makes unpacking easier.
                                </p><p><b>Essentials Box:</b> Pack a box with essentials for the first few days in your new home. Include toiletries, basic kitchen items, a few changes of clothes, and any other necessities.
                                </p><p><b>Stay Hydrated and Energized:</b> Moving can be physically demanding. Keep water and snacks on hand to stay hydrated and maintain your energy levels throughout the day.
                                </p><p>By focusing on these key areas, you'll have all the essentials to settle into your new home smoothly and comfortably. Wishing you a seamless move and a great start in your new environment!</p>

                                </div>
                            </div>
                        
                        </div>

                    </div>
                </div>
                {/* <div className="modal-footer"></div> */}
            </div>
        </div>
    );
}

export default PackingRecommendationsPopUpTips;
