// import "../myProperties/myProperties.css";
import ApiServices from "../../../../services/apiServices";
import LoginStore from "../../../../services/thirdPartyService";
import { Spin } from "antd";
import { useState, useEffect } from "react";
import bayAreaCitiesJSON from "../../../../public/assets/bayAreaCities.json";

const DsCities = () => {
  var _isMounted = false;
  const [state, setState] = useState({
    cityLoader: false,
    latitude: "",
    longitude: "",
    nearByCitiesWithStates: [],
    nearByCities: [],
    workLocationAddress: "",
    updatedDate: "",
  });
  const cityDetails = async (row) => {
    let cityObject = {
      city: row.city,
    };
    let cityListResult = await ApiServices.GetCitiesInformation(cityObject);
    if (cityListResult.message.FavCityList.length == 0) {
      await localStorage.setItem("cDetails", JSON.stringify(row));
      location.href = "/city-landing";
    } else {
      cityListResult.message.FavCityList[0].cityHighlights =
        cityListResult.message.FavCityList[0].cityHighlights
          .replace(/"/g, "'")
          .replace("“/g", "'")
          .replace(/\[/g, " ")
          .replace(/\]/g, " ")
          .split(",");
      cityListResult.message.FavCityList[0].demoGraphics = JSON.parse(
        cityListResult.message.FavCityList[0].demoGraphics
      );
      await localStorage.setItem(
        "cDetails",
        JSON.stringify(cityListResult.message.FavCityList[0])
      );
      location.href = "/city-landing";
    }
  };
  const updateDate = async (date) => {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
    if (_isMounted) {
      await setState((prevState) => ({
        ...prevState,
        updatedDate: day + " " + monthNames[monthIndex] + " " + year,
      }));
    }
    return day + " " + monthNames[monthIndex] + " " + year;
  };
  useEffect(() => {
    _isMounted = true;
    const fetch = async () => {
      updateDate(new Date());
      if (_isMounted) {
        setState({ cityLoader: true });
      }
      let getSessionData = await LoginStore.getLoginInfo({});
      let data = {
        transfereeuniqueId: getSessionData.userUniqueId,
      };
      let cityResult = await ApiServices.CreateTransfereeFavoriteCities(data);
      if (cityResult.error == 0) {
        var nearbyCities = _.uniqBy(
          cityResult.message.FavCityList,
          function (x) {
            return x.city.toLowerCase();
          }
        );
        if (_isMounted) {
          setState((prevState) => ({
            ...prevState,
            nearByCitiesWithStates: nearbyCities,
            cityLoader: false,
          }));
        }
      }
    };
    fetch();
    return async () => {
      _isMounted = false;
    };
  }, []);

  return (
    <div className="my_properties_lists">
      <h1>
        <b>Cities</b>
      </h1>

      {!state.cityLoader ? (
        <div className="main_grid">
          {state.nearByCitiesWithStates.map((row, index) => {
            return (
              <div
                className="item"
                key={index}
                onClick={() => cityDetails(row)}
              >
                {bayAreaCitiesJSON.Cities[row.city.toLowerCase()] !=
                undefined ? (
                  <img
                    src={bayAreaCitiesJSON.Cities[row.city.toLowerCase()].image}
                    alt=""
                  />
                ) : (
                  <img src="/assets/images/dtsecimg2.png" alt="" />
                )}
                <p>
                  {row.city}, {row.state}
                </p>
              </div>
            );
          })}
        </div>
      ) : (
        <Spin />
      )}
    </div>
  );
};
export default DsCities;
