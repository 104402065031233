import React, { useEffect, useState } from "react";
import { AutoComplete, Table, Button, Select, Spin, Popover } from "antd";
import ApiServices from "../../../../services/apiServices";
import LoginStore from "../../../../services/thirdPartyService";
import AddConsultantModal from "./AddConsultantModal";
import "./ConsultantList.css";
import EditConsultantModal from "./EditConsultantModal";

import { confirmAlert } from "react-confirm-alert";

const ConsultantList = () => {
  const [state, setState] = useState({
    filterTable: null,
    dataSource: [],
    columns: [
      {
        title: "Name",
        dataIndex: "name",
        key: Math.random(),
        render: (text, record) => (
          <a onClick={() => onClickRow(record)}>{text}</a>
        ),
      },
      {
        title: "EmailId",
        dataIndex: "emailid",
        key: "emailid",
      },
      {
        title: "PhoneNumber",
        dataIndex: "phonenumber",
        key: "phonenumber",
      },
      {
        title: "Action",
        dataIndex: "",
        key: "x",
        render: (text, record) => (
          <span>
            {record.isauthgenerated == false ? (
              <a onClick={() => handleMail(record)}>
                <i className="fa fa-envelope"></i>
              </a>
            ) : (
              ""
            )}{" "}
            &nbsp;
            <a
              onClick={() => onClickEditProperty(record)}
              data-bs-toggle="modal"
              data-bs-target="#edit-consultant-modal"
            >
              <i className="fa fa-edit"></i>
            </a>
          </span>
        ),
      },
    ],
    getSessionData: null,
    loader: false,
    record: null,
  });

  async function onClickRow(record) {}

  function handleMail(record) {
    confirmAlert({
      title: "",
      message: "Are you sure do you want to resend the welcome email?",
      buttons: [
        {
          label: "Yes",
          onClick: () => welMail(record),
        },
        {
          label: "No",
        },
      ],
    });
  }

  async function welMail(record) {
    let getSessionData = await LoginStore.getLoginInfo({});
    let data = {
      firstname: record.firstname,
      lastname: record.lastname,
      middlename: record.middlename,
      employeecode: record.employeecode,
      address: record.address,
      countrycode: record.countrycode,
      phonenumber: record.phonenumber,
      emailid: record.emailid,
      cityuid: record.cityuid,
      favcities: record.consultantfavcities,
      supplieruid: getSessionData.userUniqueId,
      stateuid: record.stateuid,
      notes: record.notes,
      zipcode: record.zipcode,
      profilepic: record.profilepic,
      isdelete: false,
      sendemail: true,
      consultantuid: record.uniqueId,
    };
    const CreateConsultant = await ApiServices.CreateConsultantInfo(data);
    if (CreateConsultant.error == 0) {
      confirmAlert({
        title: "",
        message: "Welcome email has been sent successfully.",
        buttons: [
          {
            label: "Close",
          },
        ],
      });
    }
  }

  function onClickEditProperty(record) {
    updateState({ record: record });
    const mandatoryFields = [
      "firstname",
      "lastname",
      "email",
      "phoneNumber",
      "address",
      "timezone",
    ];

    mandatoryFields.forEach((id) => {
      removeInvalidFieldsClass(id);
    });
  }

  function handleSearch(value) {
    const { dataSource } = state;
    const filterTable = dataSource.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    updateState({ filterTable: filterTable });
  }

  function removeInvalidFieldsClass(id) {
    if (id === "timezone") {
      $("[id='time-zone']").removeClass("is-invalid");
    } else {
      $(`[id='${id}']`).removeClass("is-invalid");
    }
  }

  function onClickAddClient() {
    const mandatoryFields = [
      "firstname",
      "lastname",
      "email",
      "phoneNumber",
      "address",
      "timezone",
    ];

    mandatoryFields.forEach((id) => {
      removeInvalidFieldsClass(id);
    });
  }

  async function fetchUserInfoAndUpdateToState() {
    let getSessionData = await LoginStore.getLoginInfo({});
    updateState({ getSessionData });
  }

  async function fetchConsultantsData() {
    updateState({ loader: true });
    let getTransfereeObj = {
      supplieruid: state.getSessionData.userUniqueId,
    };
    let ConsultantList_Result = await ApiServices.GetAllConsultants(
      getTransfereeObj
    );
    let dataSource = [];
    if (ConsultantList_Result.error == 0) {
      let consultants = ConsultantList_Result.message.consultants;
      _.forEach(consultants, async (transferee, index) => {
        if (consultants.userFirstName != "") {
          transferee.key = transferee.uniqueId;
          transferee.name = transferee.firstname + " " + transferee.lastname;

          dataSource.push(transferee);
        }
      });
      const uniqueArray = dataSource.filter((thing, index) => {
        const _thing = JSON.stringify(thing);
        return (
          index ===
          dataSource.findIndex((obj) => {
            return JSON.stringify(obj) === _thing;
          })
        );
      });
      updateState({ dataSource: uniqueArray, loader: false });
    } else {
      updateState({ loader: false });
    }
  }

  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  useEffect(() => {
    fetchUserInfoAndUpdateToState();
  }, []);

  useEffect(() => {
    if (state.getSessionData) fetchConsultantsData();
  }, [state.getSessionData]);
  return (
    <>
      <div className="main_content trans-anttable">
        <div className="container">
          <div className="table-top-input-btn">
            <AutoComplete
              style={{ marginTop: "10px", marginBottom: "10px", width: 400 }}
              onSearch={handleSearch}
              placeholder="Search by..."
            />
            <span className="cursor add-list">
              <button
                type="submit"
                onClick={onClickAddClient}
                className="btn theme-btn"
                data-bs-toggle="modal"
                data-bs-target="#add-consultant-modal"
              >
                Add Consultant{" "}
                <i className="fa fa-plus-square-o" aria-hidden="true"></i>{" "}
              </button>{" "}
            </span>
          </div>
          {state.loader ? (
            <Spin />
          ) : (
            <Table
              dataSource={
                state.filterTable ? state.filterTable : state.dataSource
              }
              columns={state.columns}
              pagination={{
                defaultPageSize: 20,
                pageSizeOptions: ["10", "20", "25", "30"],
                showSizeChanger: true,
                locale: { items_per_page: "" },
              }}
            />
          )}
        </div>
      </div>

      <AddConsultantModal
        getSessionData={state.getSessionData}
        fetchConsultantsData={fetchConsultantsData}
      />

      <EditConsultantModal
        getSessionData={state.getSessionData}
        record={state.record}
        fetchConsultantsData={fetchConsultantsData}
      />
    </>
  );
};

export default ConsultantList;
