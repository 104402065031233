import React, { useEffect, useState } from "react";
import ApiServices from "../../../../../services/apiServices";
import { Spin } from "antd";
const Status = ({ userUniqueId }) => {
  const [state, setState] = useState({
    allstatuslist: [],
    transfereeStatus: [],
    isLoading: false,
  });
  async function getStatusList() {
    updateLoaderState("start");

    const GetStatusList = await ApiServices.GetStatusList();
    try {
      if (GetStatusList.error == 0) {
        let noteslist = GetStatusList.message.noteslist;

        noteslist.forEach((valueObject) => {
          if (valueObject.identifier == "Registration email sent") {
            valueObject.orderId = 1;
          } else if (
            valueObject.identifier == "Transferee registered successfully"
          ) {
            valueObject.orderId = 2;
          } else if (
            valueObject.identifier == "Transferee logged in for the first time"
          ) {
            valueObject.orderId = 3;
          } else if (valueObject.identifier == "Updated home preferences") {
            valueObject.orderId = 4;
          } else if (valueObject.identifier == "Utilized digital checklists") {
            valueObject.orderId = 5;
          } else if (
            valueObject.identifier == "Scheduled appointments with consultant"
          ) {
            valueObject.orderId = 6;
          }
        });

        let SortStatus = noteslist.sort((a, b) => a.orderId - b.orderId);

        updateState({
          allstatuslist: SortStatus,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function getTransfereeStatus(userUniqueId) {
    let data = {
      transfereeuid: userUniqueId,
    };

    try {
      const GetTransfereeStatus = await ApiServices.GetTransfereeStatus(data);
      if (GetTransfereeStatus.error == 0) {
        updateState({
          transfereeStatus: GetTransfereeStatus.message.noteslist,
        });
      }
    } catch (e) {
      console.log(e);
    }
    updateLoaderState("stop");
  }

  function updateLoaderState(action) {
    if (action === "start") {
      updateState({ isLoading: true });
    } else if (action === "stop") {
      updateState({ isLoading: false });
    }
  }

  function updateState(updateObj) {
    console.log(updateObj);
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  useEffect(() => {
    getStatusList();
    getTransfereeStatus(userUniqueId);
  }, []);
  return (
    <div id="tabStatus" className=" tab-pane fade mt-5 active show ">
      {state.isLoading ? <Spin /> : null}
      {!state.isLoading &&
      state.transfereeStatus.length > 0 &&
      state.allstatuslist.length > 0 ? (
        <div className="status_view">
          <table className="table">
            <thead>
              <tr>
                <th>Action</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {state.allstatuslist.map((row, i) => {
                const capitalizedIdentifier = row.identifier
                  .trim()
                  .toLowerCase()
                  .replace(/^\w/, (c) => c.toUpperCase());
                return (
                  <tr key={i}>
                    <td>{capitalizedIdentifier}</td>
                    <td>
                      <i
                        className={
                          _.filter(state.transfereeStatus, function (o) {
                            return o.statusuid == row.statusUId;
                          }).length > 0
                            ? "fa fa-check"
                            : "fa fa-times"
                        }
                        aria-hidden="true"
                      ></i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  );
};

export default Status;
