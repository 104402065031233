import React from "react";

function NewPublicFooter() {
  const contacUsBtn = () => {
    location.href = "/contact-us";
  };
  return (
    <div>
      <div className="container">
        <div className="footerSigup">
          <img src="assets/publicImages/footer-bgnew.png" alt="" width="100%" />
          <div className="view">
            <h1 data-aos="fade-up">Ready to Start Your Move?</h1>
            <p data-aos="fade-up">
              Discover the power of ReloNavigator. Request a demo today.
            </p>
            <div className="btns mt-3">
              <button
                type="button"
                className="relo-btn btn-border"
                data-aos="fade-up"
                onClick={() => contacUsBtn()}
              >
                Request a Demo
              </button>
            </div>
          </div>
        </div>
      </div>

      <footer>
        <img src="assets/publicImages/footer-logo.png" alt="" width="310px" />
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          {/* <li>
            <a href="#">Pricing</a>
          </li> */}
          <li>
            <a href="/contact-us">Contact</a>
          </li>
          <li>
            <a href="#">Privacy Policy</a>
          </li>

          <li>
            <a href="#">Terms of service</a>
          </li>
        </ul>
        <p>
          ReloNavigator: Making Relocation Seamless for Individuals Everywhere.
        </p>
        <span className="copyRight">
          {" "}
          &copy; 2025 Relonavigator All rights reserved
        </span>
      </footer>
    </div>
  );
}

export default NewPublicFooter;
