import React from "react";
import { Outlet } from "react-router-dom";
import NewPublicHeader from "./common/newPublicHeader";
import NewPublicFooter from "./common/newPublicFooter";

function NewPublicLayout() {
  return (
    <div className="reloPublicPage">
      <NewPublicHeader />
      <Outlet />
      <NewPublicFooter />
    </div>
  );
}

export default NewPublicLayout;
