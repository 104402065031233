import React, { useEffect, useState } from "react";
// import "./getStartedFirstTime.css";
import jData from "./data.json";
import LoginStore from "../../../../services/thirdPartyService";
import ApiServices from "../../../../services/apiServices";
import AppController from "../../../controllers/appController";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Select, Input } from "antd";
const { Option } = Select;
import Slider from "rc-slider";
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
import DragDrop from "./components/dragDropComponent";
import HelpModal from "../helpModal/helpModal";
import NewDragDrop from "./components/newdragDropComponent";

function GetStartedFirstTime() {
  const [state, setState] = useState({
    jData: _.sortBy(jData.data, ["name"]),
    questions: [],
    getQuestionList: "",
    getQuestionListStatus: false,
    answersPrefer: [],
    draganswersPrefer: [],
    getSessionData: {},
    listOfProperties: [],
    preferencesanswer: [],
    isSharedLiving: false,
    isError: false,
    DragOptionsCheck: "",
    currentTab: 0,
    questionList: [],
    toolTipText: "Please fill this preference!",
    faIconsList: {
      ENVIRONMENT: "faBuilding",
      ACTIVITIES: "faBicycle",
      "HOME TYPE": "faHome",
      KEYWORDS: "faKeyboard",
      BEDROOMS: "faBed",
      "MUST-HAVES": "faClipboardList",
      DEFAULT: "faBuilding",
    },
    keyWordsArray: [],
    workAddressArray: [],
    activitiesArray: [],
    activitiesError: false,
    index: 1,
    TimeRangeMarks: { 0: "0 min", 60: "60 min" },
    MoneyRangeMarks: { 500: "$500 ", 15000: "$15000" },
    showCheck: false,
    showQuestion: false,
    HouseMonthlyRent: "",
    showTour: true,
  });
  const [rightItemsFromChild, setRightItemsFromChild] = useState([]);

  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [minRange, setMinRange] = useState("");
  const [maxRange, setMaxRange] = useState("");
  useEffect(() => {
    $("document").ready(function () {
      $("#help-Modal-box").modal("show");
      // $("#logo").removeClass("d-none");
    });

    async function fetchData() {
      await getBasicInfo();
    }
    fetchData();
  }, []);

  const handleRightItemsUpdate = (updatedRightItems) => {
    // console.log(updatedRightItems, "updatedrightitems");
    setRightItemsFromChild(updatedRightItems);
  };

  const getBasicInfo = async () => {
    $("#logo").addClass("d-none");
    $("#loader").removeClass("d-none");
    let getSessionData = await LoginStore.getLoginInfo({});

    // console.log(getSessionData, "getSessionData", getQuestionList);
    await AppController.logData(
      "My Preferences",
      "My Preferences",
      location.pathname,
      getSessionData.userUniqueId,
      ""
    );
    setState((prev) => ({
      ...prev,
      isSharedLiving: false,
      getSessionData: getSessionData,
    }));
    let userPreferanceObject = {
      transfereeuniqeuid: getSessionData.userUniqueId,
    };
    let data = {
      transfereeuid: getSessionData.userUniqueId,
    };
    const GetTransfereeStatus = await ApiServices.GetTransfereeStatus(data);
    let trs = [];
    let tlft = [];
    if (GetTransfereeStatus.error == 0) {
      trs = await _.filter(GetTransfereeStatus.message.noteslist, function (o) {
        return o.statusuid == "5ab74d70-eece-47a5-b97b-9e3453b814dd";
      });
      tlft = await _.filter(
        GetTransfereeStatus.message.noteslist,
        function (o) {
          return o.statusuid == "4ce9a80d-1708-48d6-bed1-c2528dda73c5";
        }
      );
    }
    let StatusUpdateSendWelcome = {
      uniqueid: trs.length == 0 ? "" : "5ab74d70-eece-47a5-b97b-9e3453b814dd",
      transfereeuid: getSessionData.userUniqueId,
      statusuid: "5ab74d70-eece-47a5-b97b-9e3453b814dd",
      isactive: true,
    };
    if (trs.length == 0) {
      await ApiServices.ManageTransfereeStatus(StatusUpdateSendWelcome);
    }
    let StatusUpdateSendWelcome2 = {
      uniqueid: tlft.length == 0 ? "" : "4ce9a80d-1708-48d6-bed1-c2528dda73c5",
      transfereeuid: getSessionData.userUniqueId,
      statusuid: "4ce9a80d-1708-48d6-bed1-c2528dda73c5",
      isactive: true,
    };
    if (tlft.length == 0) {
      await ApiServices.ManageTransfereeStatus(StatusUpdateSendWelcome2);
    }
    userSavedPreference(userPreferanceObject);
  };

  const userSavedPreference = async (userPreferanceObject) => {
    let getSessionData = await LoginStore.getLoginInfo({});
    let userObject = {
      transfereeuniqeuid: getSessionData.userUniqueId,
      istemporary: getSessionData.istemporary,
    };
    let getQuestionList = await ApiServices.GetPreferencesList(userObject);
    let userSavedPreferances = await ApiServices.GetTransfereePreferences(
      userPreferanceObject
    );

    if (userSavedPreferances.error == 0) {
      let userAnswerPreferanceObject = userSavedPreferances.message.preferences;
      if (userAnswerPreferanceObject != undefined) {
        let answersPrefer = {};
        if (userAnswerPreferanceObject.length > 0) {
          _.forEach(userAnswerPreferanceObject, function (answerObject) {
            answersPrefer[answerObject.preferencesmasteruniqueid] =
              answerObject;
          });
          let sampleAnswerArray = _.filter(
            getQuestionList.message.preferencesList,
            function (o) {
              return answersPrefer[o.preferenceUId] == undefined;
            }
          );
          if (sampleAnswerArray.length > 0) {
            let obj = sampleAnswerArray[0];
            obj.preferencesanswer = "";
            answersPrefer[sampleAnswerArray[0].preferenceUId] = obj;
          }
          setState((prev) => ({
            ...prev,
            answersPrefer: answersPrefer,
            draganswersPrefer: answersPrefer,
          }));
          setMinValue(
            answersPrefer["99b770d5-6fad-4bd3-8ae1-3baaa57d1cff"] !== undefined
              ? parseInt(
                  answersPrefer[
                    "99b770d5-6fad-4bd3-8ae1-3baaa57d1cff"
                  ]?.preferencesanswer.split(",")[0]
                )
              : 500
          );
          setMaxValue(
            answersPrefer["99b770d5-6fad-4bd3-8ae1-3baaa57d1cff"] !== undefined
              ? parseInt(
                  answersPrefer[
                    "99b770d5-6fad-4bd3-8ae1-3baaa57d1cff"
                  ]?.preferencesanswer.split(",")[1]
                )
              : 1000
          );
          setMinRange(
            answersPrefer["0e8b1fbd-ec28-4aa3-a1bd-b79ffe1fd45d"] != undefined
              ? parseInt(
                  answersPrefer[
                    "0e8b1fbd-ec28-4aa3-a1bd-b79ffe1fd45d"
                  ].preferencesanswer.split(",")[0]
                )
              : 0
          );
          setMaxRange(
            answersPrefer["0e8b1fbd-ec28-4aa3-a1bd-b79ffe1fd45d"] != undefined
              ? parseInt(
                  answersPrefer[
                    "0e8b1fbd-ec28-4aa3-a1bd-b79ffe1fd45d"
                  ].preferencesanswer.split(",")[1]
                )
              : 30
          );
        } else {
          _.forEach(
            getQuestionList.message.preferencesList,
            function (answerObject) {
              let answerDefaultVal = "";
              if (answerObject.type == "money-range") {
                answerDefaultVal = "500, 1000";
              }
              if (answerObject.type == "Time-range") {
                answerDefaultVal = "15, 30";
              }
              answerObject.preferencesanswer = answerDefaultVal;
              answerObject.transfereeuniqeuid = getSessionData.userUniqueId;
              answerObject.preferencesmasteruniqueid =
                answerObject.preferenceUId;
              answerObject.latitude = null;
              answerObject.longitude = null;
              answersPrefer[answerObject.preferenceUId] = answerObject;
            }
          );
          setState((prev) => ({
            ...prev,
            answersPrefer: answersPrefer,
            draganswersPrefer: answersPrefer,
          }));
        }
        setMinValue(
          answersPrefer["99b770d5-6fad-4bd3-8ae1-3baaa57d1cff"] !== undefined
            ? parseInt(
                answersPrefer[
                  "99b770d5-6fad-4bd3-8ae1-3baaa57d1cff"
                ]?.preferencesanswer.split(",")[0]
              )
            : 500
        );
        setMaxValue(
          answersPrefer["99b770d5-6fad-4bd3-8ae1-3baaa57d1cff"] !== undefined
            ? parseInt(
                answersPrefer[
                  "99b770d5-6fad-4bd3-8ae1-3baaa57d1cff"
                ]?.preferencesanswer.split(",")[1]
              )
            : 1000
        );
        setMinRange(
          answersPrefer["0e8b1fbd-ec28-4aa3-a1bd-b79ffe1fd45d"] != undefined
            ? parseInt(
                answersPrefer[
                  "0e8b1fbd-ec28-4aa3-a1bd-b79ffe1fd45d"
                ].preferencesanswer.split(",")[0]
              )
            : 0
        );
        setMaxRange(
          answersPrefer["0e8b1fbd-ec28-4aa3-a1bd-b79ffe1fd45d"] != undefined
            ? parseInt(
                answersPrefer[
                  "0e8b1fbd-ec28-4aa3-a1bd-b79ffe1fd45d"
                ].preferencesanswer.split(",")[1]
              )
            : 30
        );
        getListOfPriporites(answersPrefer, getQuestionList);
      }
    }
  };

  const getListOfPriporites = async (answersPrefer, getQuestionList) => {
    // console.log(answersPrefer, "answersPrefer", getQuestionList);
    let getSessionData = await LoginStore.getLoginInfo({});
    if (getQuestionList.message.preferencesList != undefined) {
      let questionList = getQuestionList.message.preferencesList;
      let data = getQuestionList.message.preferencesList;
      // console.log(questionList, "questionList");

      // Find the objects to merge
      let workAddressObj = data.find(
        (obj) => obj.questionlabel === "WORK ADDRESS"
      );
      let inclusionObj = data.find(
        (obj) =>
          obj.questionlabel === "Include Diversity & Inclusion information"
      );

      // If both objects are found, merge them
      if (workAddressObj && inclusionObj) {
        // Add a new property 'preferenceUId_check' with the content of the inclusion object
        workAddressObj.preferenceUId_check = inclusionObj.preferenceUId;
        workAddressObj.question_check = inclusionObj.question;
        workAddressObj.type_check = inclusionObj.type;
        workAddressObj.options_check = inclusionObj.options;

        // Remove the inclusion object from the array
        data = data.filter(
          (obj) =>
            obj.questionlabel !== "Include Diversity & Inclusion information"
        );
      }
      // console.log(data, "dataupdated data");

      setState((prev) => ({ ...prev, getQuestionList: data }));
      // let filteredQuestionList = _.filter(questionList, function (o) { return o.questionlabel != 'KEYWORDS'; });

      let listOfPropertiesArray = _.filter(questionList, function (o) {
        return o.type == "DragOptions";
      });
      let workAddressArray = _.filter(questionList, function (o) {
        return o.question == "Work address";
      });
      let keyWordsArray = _.filter(questionList, function (o) {
        return o.questionlabel == "KEYWORDS";
      });
      let activitiesArray = _.filter(questionList, function (o) {
        return o.questionlabel == "ACTIVITIES";
      });
      let whoisMovingArray = _.filter(questionList, function (o) {
        return o.questionlabel == "WHO IS MOVING";
      });
      // console.log(answersPrefer[workAddressArray[0].preferenceUId], "&&&");
      let answer = answersPrefer[workAddressArray[0].preferenceUId];
      if (answer.preferencesanswer != "") {
        // console.log("only after the answer");
        await localStorage.setItem(
          "workAddress",
          JSON.stringify(answersPrefer[workAddressArray[0].preferenceUId])
        );
      }

      if (listOfPropertiesArray.length > 0) {
        {
          getSessionData.istemporary != true
            ? (listOfPropertiesArray[0].AnswerObject =
                answersPrefer[listOfPropertiesArray[0].preferenceUId])
            : "";
        }
      }

      var questionResult = await chunkArray(questionList, 2);
      let answerString =
        answersPrefer[whoisMovingArray[0].preferenceUId] != undefined
          ? answersPrefer[whoisMovingArray[0].preferenceUId].preferencesanswer
              .replace(/^[,\s]+|[,\s]+$/g, "")
              .split(",")
          : [];
      if (answerString.length == 1 && answerString[0] == "FAMILY") {
        setState((prev) => ({ ...prev, showCheck: true }));
      }
      setState((prev) => ({
        ...prev,
        workAddressArray: workAddressArray,
        keyWordsArray: keyWordsArray,
        activitiesArray: activitiesArray,
        listOfProperties: listOfPropertiesArray,
        questions: questionResult,
        getQuestionListStatus: true,
        getSessionData: getSessionData,
        questionList: questionList,
      }));
      $("#loader").addClass("d-none");
      $("#logo").removeClass("d-none");
    }
  };

  const chunkArray = (myArray, chunk_size) => {
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];
    var myChunk = [];
    for (index = 0; index < arrayLength; index += chunk_size) {
      myChunk = myArray.slice(index, index + chunk_size);
      tempArray.push(myChunk);
    }

    return tempArray;
  };

  const ShowQuestion = async () => {
    if (state.questionList.length > 0) {
      setState((prev) => ({ ...prev, showQuestion: true }));
    } else {
      location.href = "/login";
    }
  };

  const back = async (n, questions) => {
    // console.log(n, "=");
    if (n == 13) {
      setState((prev) => ({ ...prev, index: state.index - 1 }));
      $("#proirities").addClass("d-none");
      $("#mainQuestion" + (n - 1)).removeClass("d-none");
    } else if (
      questions.preferenceUId == "1b446dad-cd66-4360-8d2b-4f2f0a04933f"
    ) {
      if (state.showCheck == true) {
        setState((prev) => ({ ...prev, index: state.index + 1 }));
        $("#mainQuestion" + n).addClass("d-none");
        $("#mainQuestion" + (n - 1)).removeClass("d-none");
      } else {
        setState((prev) => ({ ...prev, index: state.index + 1 }));
        $("#mainQuestion" + n).addClass("d-none");
        $("#mainQuestion" + (n - 2)).removeClass("d-none");
      }
    } else if (
      questions != undefined &&
      questions.preferenceUId == "33ede76d-7ac0-45a3-9d7b-594eb74c90f2" &&
      state.getSessionData.istemporary != true
    ) {
      console.log("add class");
      setState((prev) => ({ ...prev, index: state.index - 1 }));
      $("#mainQuestion" + n).addClass("d-none");
      $("#mainQuestion" + (n - 2)).removeClass("d-none");
    } else {
      console.log("remove class");
      setState((prev) => ({ ...prev, index: state.index - 1 }));
      if (
        questions.preferenceUId == "5f97a1cc-2c27-4733-9af1-de48fed956a0" &&
        state.getSessionData.istemporary == true
      ) {
        $("#mainQuestion" + n).addClass("d-none");
        $("#mainQuestion" + (n - 2)).removeClass("d-none");
      }
      $("#mainQuestion" + n).addClass("d-none");
      $("#mainQuestion" + (n - 1)).removeClass("d-none");
    }
    console.log("last");
  };

  const next = async (n, questions) => {
    // console.log(n, questions, "n, questions");

    $("#logo").addClass("d-none");
    $("#loader").removeClass("d-none");
    var showError = 0;
    var activityError = 0;
    var preferedOptions = rightItemsFromChild;
    let stringObject = preferedOptions.map((item) => item.name);
    // coverting object to string
    const DragOptions = stringObject.join(",");
    setState((prev) => ({
      ...prev,
      DragOptionsCheck: DragOptions,
    }));
    if (Object.keys(state.answersPrefer).length > 0) {
      let stateObject = Object.assign({}, state.answersPrefer);
      let dragObject = Object.assign({}, state.answersPrefer);
      Object.keys(dragObject).map(function (key, value) {
        stateObject[key] = dragObject[key];
      });
      // Object.keys(stateObject).map(function (key) {
      if (
        stateObject[questions.preferenceUId] != undefined &&
        stateObject[questions.preferenceUId].preferencesanswer == "" &&
        (stateObject[questions.preferenceUId].type != "money-range" ||
          stateObject[questions.preferenceUId].type != "Time-range")
      ) {
        console.log(
          stateObject[questions.preferenceUId].preferencesmasteruniqueid,
          "===33"
        );
        if (questions.questionlabel == "ACTIVITIES") {
          if (
            stateObject[questions.preferenceUId].preferencesanswer == "" &&
            stateObject[state.keyWordsArray[0].preferenceUId]
              .preferencesanswer == ""
          ) {
            activityError = 1;
          }
        } else if (
          stateObject[questions.preferenceUId_check]
            .preferencesmasteruniqueid == "f75ade99-8607-4756-9daf-9feeef3e1d1b"
        ) {
          showError = 1;
        } else if (
          questions.type_check == "CheckBox" &&
          stateObject[questions.preferenceUId_check].preferencesanswer == false
        ) {
          console.log("hello check");

          showError = 1;
        }
      }
      if (
        stateObject[questions.preferenceUId].preferencesmasteruniqueid ==
          "99b770d5-6fad-4bd3-8ae1-3baaa57d1cff" ||
        stateObject[questions.preferenceUId].preferencesmasteruniqueid ==
          "0e8b1fbd-ec28-4aa3-a1bd-b79ffe1fd45d"
      ) {
        const preferences =
          stateObject[questions.preferenceUId].preferencesanswer.split(",");
        if (preferences.length === 2) {
          console.log(preferences, "preferences");

          // Convert the split values to numbers
          const value1 = parseFloat(preferences[0]);
          const value2 = parseFloat(preferences[1]);

          // Check if the converted values are valid numbers
          if (isNaN(value1) || isNaN(value2)) {
            showError = 1; // or handle the error accordingly
          }
        }
      }
      // });
      if (showError == 0) {
        //  errorMsgFunc();
        setState((prev) => ({ ...prev, isError: false }));
      }
      if (showError == 1) {
        console.log("errorr");

        //  errorMsgFunc();
        setState((prev) => ({ ...prev, isError: true }));
      } else if (activityError == 1) {
        //  errorMsgFunc();
        setState((prev) => ({ ...prev, activitiesError: true }));
      } else {
        if (n == 12) {
          setState((prev) => ({ ...prev, index: state.index + 1 }));

          // console.log(2, n, questions.preferenceUId);
          $("#mainQuestion" + n).addClass("d-none");
          $("#proirities").removeClass("d-none");
        } else if (
          questions.preferenceUId == "a7fbe2dc-c3e1-43a8-a862-f068b79bce38"
        ) {
          if (state.showCheck == true) {
            setState((prev) => ({ ...prev, index: state.index + 1 }));
            $("#mainQuestion" + n).addClass("d-none");
            $("#mainQuestion" + (n + 1)).removeClass("d-none");
          } else {
            setState((prev) => ({ ...prev, index: state.index + 1 }));
            $("#mainQuestion" + n).addClass("d-none");
            $("#mainQuestion" + (n + 2)).removeClass("d-none");
          }
        } else if (
          questions.preferenceUId == "ea32aa03-c1d4-4458-a7eb-80a65074a11a"
        ) {
          // console.log(22, n, questions.preferenceUId);
          setState((prev) => ({ ...prev, index: state.index + 1 }));
          $("#mainQuestion" + n).addClass("d-none");
          $("#mainQuestion" + (n + 2)).removeClass("d-none");
        } else {
          // console.log(222, n, questions.preferenceUId);
          setState((prev) => ({ ...prev, index: state.index + 1 }));
          $("#mainQuestion" + n).addClass("d-none");
          $("#mainQuestion" + (n + 1)).removeClass("d-none");
        }
      }
    }
    $("#logo").removeClass("d-none");
    $("#loader").addClass("d-none");
  };

  const handleMinChange = (questionUID, type, e) => {
    const value = parseInt(e.target.value) || 500;
    // Allow empty input to clear the value
    const newMinValue = value === "" ? 500 : parseInt(value, 10);
    if (!isNaN(newMinValue)) {
      $("#minValue").removeClass("is-invalid");
    } else {
      $("#minValue").addClass("is-invalid");
    }
    setMinValue(newMinValue);
    // Only update onChange if both min and max values are set
    if (newMinValue !== "" && maxValue !== "") {
      onChange(questionUID, type, [newMinValue, maxValue]);
    }
  };

  const handleMaxChange = (questionUID, type, e) => {
    const value = parseInt(e.target.value);
    // Allow empty input to clear the value
    const newMaxValue = value === "" ? "" : parseInt(value, 10);
    if (!isNaN(newMaxValue)) {
      $("#maxValue").removeClass("is-invalid");
    } else {
      $("#maxValue").addClass("is-invalid");
    }
    setMaxValue(newMaxValue);
    // Only update onChange if both min and max values are set
    if (minValue !== "" && newMaxValue !== "") {
      onChange(questionUID, type, [minValue, newMaxValue]);
    }
  };
  const handleMinRange = (questionUID, type, e) => {
    const value = parseInt(e.target.value);
    // Allow empty input to clear the value
    const newMinValue = value === "" ? "" : parseInt(value, 10);
    if (!isNaN(newMinValue)) {
      $("#minRange").removeClass("is-invalid");
    } else {
      $("#minRange").addClass("is-invalid");
    }
    setMinRange(newMinValue);
    // Only update onChange if both min and max values are set
    if (newMinValue !== "" && maxRange !== "") {
      onChange(questionUID, type, [newMinValue, maxRange]);
    }
  };
  const handleMaxRange = (questionUID, type, e) => {
    const value = parseInt(e.target.value);
    // Allow empty input to clear the value
    const newMaxValue = value === "" ? "" : parseInt(value, 10);
    if (!isNaN(newMaxValue)) {
      $("#maxRange").removeClass("is-invalid");
    } else {
      $("#maxRange").addClass("is-invalid");
    }
    setMinValue(newMaxValue);
    // Only update onChange if both min and max values are set
    if (newMaxValue !== "" && minRange !== "") {
      onChange(questionUID, type, [minRange, newMaxValue]);
    }
  };
  const onChange = async (questionUID, type, value) => {
    //await  setState({ showCheck: false })

    let answersPrefer = state.answersPrefer;
    if (type == "options") {
      if (answersPrefer[questionUID] != undefined) {
        if (answersPrefer[questionUID].questionlabel == "WHO IS MOVING") {
          let previousAnswer = value.trim();
          let answerString =
            answersPrefer[questionUID] != undefined
              ? previousAnswer.replace(/^[,\s]+|[,\s]+$/g, "").split(",")
              : [];
          // console.log(answerString, "answerString");
          if (answerString.length == 1 && answerString[0] == "FAMILY") {
            setState((prev) => ({ ...prev, showCheck: true }));
          } else {
            setState((prev) => ({ ...prev, showCheck: false }));
          }
          answersPrefer[questionUID] = {
            transfereeuniqeuid: state.getSessionData.userUniqueId,
            preferencesmasteruniqueid: questionUID,
            preferencesanswer: previousAnswer,
            questionlabel: answersPrefer[questionUID].questionlabel,
          };
        } else {
          let previousAnswer =
            answersPrefer[questionUID].preferencesanswer.split(",");
          let checkValue = previousAnswer.indexOf(value.trim());
          if (checkValue == -1) {
            previousAnswer.push(value.trim());
          } else {
            previousAnswer.splice(checkValue, 1);
          }
          answersPrefer[questionUID] = {
            transfereeuniqeuid: state.getSessionData.userUniqueId,
            preferencesmasteruniqueid: questionUID,
            preferencesanswer: previousAnswer.toString(),
          };
        }
      } else {
        answersPrefer[questionUID] = {
          transfereeuniqeuid: state.getSessionData.userUniqueId,
          preferencesmasteruniqueid: questionUID,
          preferencesanswer: value,
        };
      }
    } else if (type == "Time-range" || type == "money-range") {
      console.log(value, "value");

      answersPrefer[questionUID] = {
        transfereeuniqeuid: state.getSessionData.userUniqueId,
        preferencesmasteruniqueid: questionUID,
        preferencesanswer: value.toString(),
      };
    } else if (
      type == "RadioButtons" &&
      questionUID == "1b446dad-cd66-4360-8d2b-4f2f0a04933f"
    ) {
      answersPrefer[questionUID] = {
        transfereeuniqeuid: state.getSessionData.userUniqueId,
        preferencesmasteruniqueid: questionUID,
        preferencesanswer: value.toString(),
      };

      if (value.toString() == "Yes") {
        let previousAnswer =
          answersPrefer[
            "a11a3694-282e-4260-b67b-72a40363dd8d"
          ]?.preferencesanswer?.split(",") ?? [];
        // console.log(previousAnswer, "previousAnswer");
        let checkValue = previousAnswer.indexOf("Pet Friendly");
        if (checkValue < 0) {
          // console.log(checkValue, "checkValue");
          await onChange(
            "a11a3694-282e-4260-b67b-72a40363dd8d",
            "options",
            "Pet Friendly"
          );
        }
      }
    } else {
      answersPrefer[questionUID] = {
        transfereeuniqeuid: state.getSessionData.userUniqueId,
        preferencesmasteruniqueid: questionUID,
        preferencesanswer: value.toString(),
      };
    }
    setState((prev) => ({ ...prev, answersPrefer }));
    // console.log(state.answersPrefer);
  };

  const showAnnualMonthlyRent = async () => {
    $("document").ready(function () {
      $("#HomeRentalCal").modal("show");
    });
  };

  const CalculateMonthlyBudget = async (event) => {
    let inputVal = event.target.value;
    console.log(parseInt(inputVal));
    if (inputVal < 1 && inputVal != "" && inputVal == "e" && inputVal == "E") {
      inputVal = "";
    } else if (parseInt(inputVal) >= 0 || inputVal == "") {
      setState((prev) => ({
        ...prev,
        HouseMonthlyRent: Number.isInteger(parseInt(inputVal))
          ? parseInt(inputVal)
          : "",
      }));
    }
  };

  const setLocation = async (Id, e, questionUID) => {
    // console.log(questionUID, "questionUID");
    e.persist();
    let getSessionData = await LoginStore.getLoginInfo({});
    let LocationObje = {};
    let answersPrefer = state.answersPrefer;
    LocationObje[Id] = e.target.value;
    setState((prev) => ({ ...prev, LocationObje }));
    const _this = this;
    var places = new google.maps.places.Autocomplete(
      document.getElementById(Id),
      { types: ["address"] }
    );
    places.setComponentRestrictions({ country: ["us"] });
    answersPrefer[questionUID].preferencesanswer = "";
    google.maps.event.addListener(places, "place_changed", async function () {
      var place = places.getPlace();
      var placeDetails = {
        locality: "long_name",
        administrative_area_level_1: "short_name",
        country: "long_name",
      };
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (placeDetails[addressType]) {
          var val = place.address_components[i][placeDetails[addressType]];
          placeDetails[addressType] = val;
        }
      }

      // console.log(place, "place", place.geometry);
      let relocatedAddress = place.formatted_address;
      answersPrefer[questionUID] = {
        preferencesmasteruniqueid: questionUID,
        preferencesanswer: e.target.value,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        transfereeuniqeuid: getSessionData.userUniqueId,
      };
      let workAddress = {
        preferencesmasteruniqueid: questionUID,
        preferencesanswer: e.target.value,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        transfereeuniqeuid: getSessionData.userUniqueId,
      };
      let cDetails = {
        city: placeDetails.locality,
        state: placeDetails.administrative_area_level_1,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        address: e.target.value,
        imageUrl: 0,
        cityHighlights: 0,
        demoGraphics: 0,
        cityDetails: 0,
        miles: 0,
        bikescore: 0,
        walkscore: 0,
        cityaddress: true,
      };
      await localStorage.setItem("workAddress", JSON.stringify(workAddress));
      await localStorage.setItem("cDetails", JSON.stringify(cDetails));
      // console.log(workAddress, "workAddress");
    });

    // setWorkAddress(answersPrefer);
    setState((prev) => ({ ...prev, answersPrefer }));
  };

  const handleChangeStart = async (Id, e, questionUID) => {
    let getSessionData = await LoginStore.getLoginInfo({});

    let answersPrefer = state.answersPrefer;

    answersPrefer[questionUID] = {
      preferencesmasteruniqueid: questionUID,
      preferencesanswer: moment(e).format("MM/DD/YYYY"),
      latitude: null,
      longitude: null,
      transfereeuniqeuid: getSessionData.userUniqueId,
    };
    // console.log(moment(e).format("MM/DD/YYYY"), answersPrefer[questionUID]);
    setState((prev) => ({ ...prev, answersPrefer }));
  };

  const setDie = async (Id, e, questionUID) => {
    e.persist();
    let valueCheck = e.target.checked;

    let getSessionData = await LoginStore.getLoginInfo({});
    let answersPrefer = state.answersPrefer;

    answersPrefer[questionUID] = {
      preferencesmasteruniqueid: questionUID,
      preferencesanswer: valueCheck,
      latitude: null,
      longitude: null,
      transfereeuniqeuid: getSessionData.userUniqueId,
    };

    setState((prev) => ({ ...prev, answersPrefer }));
  };

  const submitHandler = async () => {
    var showError = 0;
    let answersPrefer = [];
    var preferedOptions = rightItemsFromChild;
    let stringObject = preferedOptions.map((item) => item.name);
    // coverting object to string
    const DragOptions = stringObject.join(",");
    setState((prev) => ({
      ...prev,
      DragOptionsCheck: DragOptions,
    }));
    let questionUID = state.listOfProperties[0]?.preferenceUId;
    answersPrefer[questionUID] = Object.assign(
      [questionUID],
      state.draganswersPrefer[questionUID]
    );
    if (answersPrefer[questionUID] != undefined) {
      let previousAnswer =
        answersPrefer[questionUID].preferencesanswer != undefined
          ? answersPrefer[questionUID].preferencesanswer.split(",")
          : [];
      let checkValue = previousAnswer.indexOf(DragOptions.trim());
      previousAnswer = DragOptions;

      answersPrefer[questionUID] = {
        transfereeuniqeuid: state.getSessionData.userUniqueId,
        preferencesmasteruniqueid: questionUID,
        preferencesanswer: DragOptions.replace(/\s*,\s*/gi, ",").replace(
          /\d+/g,
          ""
        ),
        type: state.listOfProperties[0]?.type,
        options: state.listOfProperties[0]?.options,
      };
    } else {
      answersPrefer[questionUID] = {
        transfereeuniqeuid: state.getSessionData.userUniqueId,
        preferencesmasteruniqueid: questionUID,
        preferencesanswer: DragOptions.replace(/\s*,\s*/gi, ",").replace(
          /\d+/g,
          ""
        ),
        type: state.listOfProperties[0]?.type,
        options: state.listOfProperties[0]?.options,
      };
    }
    // await  setState({draganswersPrefer:answersPrefer});
    if (Object.keys(state.answersPrefer).length > 0) {
      let finalCreateObject = [];
      let stateObject = Object.assign({}, state.answersPrefer);
      let dragObject = Object.assign({}, answersPrefer);
      let keywordsObj = Object.assign({}, state.keyWordsArray[0]);
      let activitiesObj = Object.assign({}, state.activitiesArray[0]);
      Object.keys(dragObject).map(function (key, value) {
        stateObject[key] = dragObject[key];
      });
      Object.keys(stateObject).map(function (key) {
        if (
          key != keywordsObj.preferenceUId &&
          key != activitiesObj.preferenceUId
        ) {
          if (
            stateObject[key].preferencesmasteruniqueid !=
              "f75ade99-8607-4756-9daf-9feeef3e1d1b" &&
            stateObject[key].preferencesanswer == "" &&
            (stateObject[key].type != "money-range" ||
              stateObject[key].type != "Time-range")
          ) {
            showError = 1;
          }
        }
        finalCreateObject.push(stateObject[key]);
      });
      if (showError == 3) {
        //  errorMsgFunc();
        setState((prev) => ({ ...prev, isError: true }));
      } else {
        let PriortiesArray = DragOptions.trim()
          .replace(/\s*,\s*/gi, ",")
          .replace(/\d+/g, "")
          .split(",");
        let RankingObject = {
          trnasfereeuid: state.getSessionData.userUniqueId,
          propertyrankingitems: [],
        };
        let preferUid = {
          "Must Haves": "a11a3694-282e-4260-b67b-72a40363dd8d",
          "Home Type": "33ede76d-7ac0-45a3-9d7b-594eb74c90f2",
          Bedrooms: "63c5351e-1687-46c2-92e2-e9ed6c733330",
          Commute: "0e8b1fbd-ec28-4aa3-a1bd-b79ffe1fd45d",
          Budget: "99b770d5-6fad-4bd3-8ae1-3baaa57d1cff",
        };
        PriortiesArray.map((Priorty, index) => {
          if (Priorty.replace("-", " ").trim() == "Commute") {
            RankingObject.propertyrankingitems.push({
              prioritylistitem: "Work Address",
              prioritylistitemrank: 0,
              preferencesmasteruid: "5f97a1cc-2c27-4733-9af1-de48fed956a0",
            });
          }
          RankingObject.propertyrankingitems.push({
            prioritylistitem: Priorty.replace("-", " ").trim(),
            prioritylistitemrank: Math.abs(PriortiesArray.length - index),
            preferencesmasteruid: preferUid[Priorty.replace("-", " ").trim()],
          });
        });

        let RankingObjectResult = await ApiServices.ManageTransfereePropRanking(
          RankingObject
        );
        // console.log(RankingObjectResult, "RankingObjectResult");
        let QuestionResult = await ApiServices.CreateTransfereePreferences(
          finalCreateObject
        );
        let question = _.filter(finalCreateObject, function (o) {
          return (
            o.preferencesmasteruniqueid ==
            "a7fbe2dc-c3e1-43a8-a862-f068b79bce38"
          );
        });
        let answerString =
          question[0] != undefined
            ? question[0].preferencesanswer
                .replace(/^[,\s]+|[,\s]+$/g, "")
                .split(",")
            : [];
        if (answerString.length == 1 && answerString[0] == "ME") {
          // $("#success").removeClass("d-none");
          // $("#proirities").addClass("d-none");
          //  sharedLivingConfirmation();
          $("#success").removeClass("d-none");
          $("#proirities").addClass("d-none");
          $("#logo").addClass("d-none");
          $("#loader").removeClass("d-none");

          $("#mainQuestion" + (state.questionList.length - 1)).addClass(
            "d-none"
          );
          await sendEmail();
          setState((prev) => ({ ...prev, Mainindex: 0 }));
          setTimeout(() => {
            localStorage.setItem("getStartedFirst", true);
            location.href = "/citylist";
          }, 2000);
        } else {
          setState((prev) => ({ ...prev, Mainindex: 0 }));
          await sendEmail();
          $("#success").removeClass("d-none");
          $("#proirities").addClass("d-none");
          $("#logo").addClass("d-none");
          $("#loader").removeClass("d-none");

          $("#mainQuestion" + (state.questionList.length - 1)).addClass(
            "d-none"
          );
          setTimeout(() => {
            localStorage.setItem("getStartedFirst", true);
            location.href = "/citylist";
          }, 2000);
          //  confirmationFunc();
        }
      }
    }
  };

  const sendEmail = async () => {
    let getSessionData = await LoginStore.getLoginInfo({});
    let subscriptioncode = JSON.parse(
      localStorage.getItem("loginSubscribeDetails")
    ).subscriptioncode;
    let data = {
      name: getSessionData.userFirstName + "" + getSessionData.userLastName,
      consultantname: getSessionData.consultantname,
      isgetStartedFirst: true,
      transfereeEmail: getSessionData.EmailId,
      email: getSessionData.consultantemail,
      cc: "hello@relonavigator.com",
      subject: "Transferee registration successful.",
      description: "Following transferee has signed up for ReloNavigator",
      subscriptioncode: subscriptioncode,
    };
    // console.log(data, "data");
    let sendSms = await LoginStore.sendEmail(data);

    let StatusUpdateSendWelcome = {
      uniqueid: "",
      transfereeuid: state.getSessionData.userUniqueId,
      statusuid: "5ab74d70-eece-47a5-b97b-9e3453b814dd",
      isactive: true,
    };
    const ManageTransfereeStatus = await ApiServices.ManageTransfereeStatus(
      StatusUpdateSendWelcome
    );
    let uhp = [];
    let dataStatus = {
      transfereeuid: getSessionData.userUniqueId,
    };
    const GetTransfereeStatus = await ApiServices.GetTransfereeStatus(
      dataStatus
    );
    if (GetTransfereeStatus.error == 0) {
      uhp = await _.filter(GetTransfereeStatus.message.noteslist, function (o) {
        return o.statusuid == "f085be8b-4a33-4826-87ad-cdd6bde1b033";
      });
    }
    let StatusUpdateSendWelcome3 = {
      uniqueid: uhp.length == 0 ? "" : "f085be8b-4a33-4826-87ad-cdd6bde1b033",
      transfereeuid: getSessionData.userUniqueId,
      statusuid: "f085be8b-4a33-4826-87ad-cdd6bde1b033",
      isactive: true,
    };
    await ApiServices.ManageTransfereeStatus(StatusUpdateSendWelcome3);
  };

  const updateShowQuestions = async () => {
    setState((prev) => ({ ...prev, showTour: true }));
  };

  const closePopUp = () => {
    $("document").ready(function () {
      $("#HomeRentalCal").modal("hide");
    });
  };

  const children = [];
  const values = state.jData;
  var dataSource = values;
  for (let i = 0; i < dataSource.length; i++) {
    children.push(
      <Select.Option key={dataSource[i].name}>
        {dataSource[i].name}
      </Select.Option>
    );
  }
  return (
    <div id="outer-container">
      <HelpModal updateShowQuestions={updateShowQuestions} />
      <div className="home_sharloote_img">
        <div className="image-bg"></div>
        <div className="content_main">
          <div className="" id="logo">
            <img src="assets/images/new-logo.svg" alt="" />
          </div>
          <form id="regForm" className="solopage1cont">
            {/* <div className="img_select_loader d-none" id="loader"> */}
            {/* <div className="loading"></div> */}
            {/* <img src="assets/images/Sharlotte_stamp_loader.svg" alt="" />
              <div>
                <img
                  className="img222"
                  src="assets/images/Group 16937.svg"
                  alt=""
                />
              </div> */}
            {/* </div> */}
            {state.showQuestion == false && state.showTour == true && (
              <div className="">
                <h2>Welcome to Relonavigator</h2>
                <p>
                  {/* Welcome to ReloNavigator, your Destination Services assistance
                  platform. ReloNavigator will ask you a few questions about
                  what you’re looking for in a home and neighborhood. Based on
                  your preferences, your experience will be personalized through
                  out the platform.{" "} */}
                  {/* Welcome to ReloNavigator, your premier Destination Services
                  assistance platform. ReloNavigator will ask you a few
                  questions about your preferences and needs. Based on your
                  responses, we will tailor your experience throughout the
                  platform to ensure it meets your specific requirements. */}
                  Your all-in-one relocation companion. Access city insights,
                  checklists, DE&I information, and connect with experts—all in
                  one place.
                </p>
                <div className="block-btns">
                  <button
                    type="button"
                    className="btns"
                    onClick={() => ShowQuestion()}
                  >
                    Start
                  </button>
                </div>
              </div>
            )}

            <div className="stepForm">
              {state.questionList.map((questionList, Mainindex) => {
                return (
                  <div
                    key={Mainindex}
                    id={"mainQuestion" + Mainindex}
                    className={
                      Mainindex == 0 && state.showQuestion == true
                        ? ""
                        : "d-none"
                    }
                  >
                    <div className="sliderNumbers">
                      <span>{state.index}</span>/
                      <span>{state.questionList.length - 1}</span>
                    </div>
                    {questionList.type == "options" &&
                    questionList.questionlabel != "ACTIVITIES" ? (
                      <div
                        className={
                          questionList.sequence == Mainindex + 1
                            ? "tab tab-1"
                            : "tab tab-1"
                        }
                      >
                        <h1>{questionList.question}</h1>
                        <div className="scrollDiv">
                          {(" " + questionList.options)
                            .split(",")
                            .sort()
                            .map((optionText, optionIndex) => {
                              const capitalizeOption = optionText
                                .trim()
                                .toLowerCase()
                                .split(" ")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ");
                              return (
                                <button
                                  type="button"
                                  className={
                                    state.answersPrefer[
                                      questionList.preferenceUId
                                    ] != undefined
                                      ? state.answersPrefer[
                                          questionList.preferenceUId
                                        ].preferencesanswer
                                          .split(",")
                                          .indexOf(optionText.trim()) > -1
                                        ? "btn btn-clr"
                                        : "btn"
                                      : "btn"
                                  }
                                  key={optionIndex}
                                  onClick={(e) =>
                                    onChange(
                                      questionList.preferenceUId,
                                      "options",
                                      optionText.trim()
                                    )
                                  }
                                >
                                  {capitalizeOption}
                                </button>
                              );
                            })}
                        </div>
                        {state.isError &&
                          state.answersPrefer[questionList.preferenceUId]
                            .preferencesanswer == "" && (
                            <div className="tooltip-validation text-center">
                              <div className="tooltip-inner">
                                {state.toolTipText}
                              </div>
                            </div>
                          )}
                        <div className="block-btns">
                          {/* {Mainindex != 0 && ( */}
                          <button
                            type="button"
                            className="btns btns-back me-3"
                            onClick={() => back(Mainindex, questionList)}
                          >
                            {" "}
                            Back{" "}
                          </button>
                          {/* // )} */}
                          <button
                            type="button"
                            className="btns"
                            onClick={() => next(Mainindex, questionList)}
                          >
                            {" "}
                            Continue <span></span>{" "}
                          </button>
                        </div>
                      </div>
                    ) : questionList.type == "options" &&
                      questionList.questionlabel == "ACTIVITIES" ? (
                      <div
                        className={
                          questionList.sequence == Mainindex + 1
                            ? "tab tab-1"
                            : "tab tab-1"
                        }
                      >
                        <h1>{questionList.question}</h1>
                        <div className="scrollDiv">
                          {(" " + questionList.options)
                            .split(",")
                            .sort()
                            .map((optionText, optionIndex) => {
                              return (
                                <button
                                  type="button"
                                  className={
                                    state.answersPrefer[
                                      questionList.preferenceUId
                                    ] != undefined
                                      ? state.answersPrefer[
                                          questionList.preferenceUId
                                        ].preferencesanswer
                                          .split(",")
                                          .indexOf(optionText.trim()) > -1
                                        ? "btn btn-clr"
                                        : "btn"
                                      : "btn"
                                  }
                                  key={optionIndex}
                                  onClick={(e) =>
                                    onChange(
                                      questionList.preferenceUId,
                                      "options",
                                      optionText.trim()
                                    )
                                  }
                                >
                                  {optionText}
                                </button>
                              );
                            })}
                          {state.keyWordsArray[0].description != null ? (
                            <h6 className="mt-3">
                              {state.keyWordsArray[0].description}
                            </h6>
                          ) : null}

                          <Select
                            className="autoComplete"
                            defaultValue={
                              state.answersPrefer[
                                state.keyWordsArray[0].preferenceUId
                              ] != undefined
                                ? state.answersPrefer[
                                    state.keyWordsArray[0].preferenceUId
                                  ].preferencesanswer
                                    .trim()
                                    .split(",")
                                    .sort()
                                    .filter(function (el) {
                                      return el != "";
                                    })
                                : ""
                            }
                            mode="multiple"
                            style={{ width: "100%" }}
                            onChange={(e) =>
                              onChange(
                                state.keyWordsArray[0].preferenceUId,
                                "input",
                                e
                              )
                            }
                            tokenSeparators={[","]}
                          >
                            {children}
                          </Select>
                        </div>

                        {state.activitiesError &&
                          state.answersPrefer[
                            state.activitiesArray[0].preferenceUId
                          ].preferencesanswer == "" &&
                          state.answersPrefer[
                            state.keyWordsArray[0].preferenceUId
                          ].preferencesanswer == "" && (
                            <div className="tooltip-validation text-center">
                              <div className="tooltip-inner">
                                {state.toolTipText}
                              </div>
                            </div>
                          )}
                        <div className="block-btns">
                          {Mainindex != 0 && (
                            <button
                              type="button"
                              className="btns btns-back me-3"
                              onClick={() => back(Mainindex, questionList)}
                            >
                              {" "}
                              Back{" "}
                            </button>
                          )}
                          <button
                            type="button"
                            className="btns"
                            onClick={() => next(Mainindex, questionList)}
                          >
                            {" "}
                            Continue <span></span>{" "}
                          </button>
                        </div>
                      </div>
                    ) : questionList.type == "money-range" ? (
                      <div className="tab tab-2">
                        <h1>
                          {questionList.question}
                          <span
                            className="spanHeading"
                            onClick={() => showAnnualMonthlyRent()}
                          >
                            Help me choose
                          </span>
                        </h1>
                        {questionList.description != null ? (
                          <h6>{questionList.description}</h6>
                        ) : null}
                        <div>
                          <Range
                            marks={state.MoneyRangeMarks}
                            step={50}
                            min={500}
                            max={15000}
                            onChange={(value) =>
                              onChange(
                                questionList.preferenceUId,
                                "money-range",
                                value
                              )
                            }
                            defaultValue={[
                              state.answersPrefer[questionList.preferenceUId] !=
                              undefined
                                ? parseInt(
                                    state.answersPrefer[
                                      questionList.preferenceUId
                                    ].preferencesanswer.split(",")[0]
                                  )
                                : 500,
                              state.answersPrefer[questionList.preferenceUId] !=
                              undefined
                                ? parseInt(
                                    state.answersPrefer[
                                      questionList.preferenceUId
                                    ].preferencesanswer.split(",")[1]
                                  )
                                : 1000,
                            ]}
                            tipFormatter={(value) => `$${value}`}
                          />
                          {/* <div className="position-relative">
                            <input
                              type="number"
                              className="form-control mb-3"
                              id="minValue"
                              value={
                                state.answersPrefer[
                                  questionList.preferenceUId
                                ] !== undefined
                                  ? parseInt(
                                      state.answersPrefer[
                                        questionList.preferenceUId
                                      ].preferencesanswer.split(",")[0]
                                    )
                                  : 500
                              }
                              onChange={(e) =>
                                handleMinChange(
                                  questionList.preferenceUId,
                                  "money-range",
                                  e
                                )
                              }
                              min={500}
                            />
                            <span>
                              {" "}
                              <i class="fa-solid fa-dollar-sign dollar-sign"></i>{" "}
                            </span>
                          </div>
                          <div className="position-relative">
                            <input
                              type="number"
                              className="form-control mb-3"
                              id="maxValue"
                              value={
                                state.answersPrefer[
                                  questionList.preferenceUId
                                ] !== undefined
                                  ? parseInt(
                                      state.answersPrefer[
                                        questionList.preferenceUId
                                      ].preferencesanswer.split(",")[1]
                                    )
                                  : 1000
                              }
                              onChange={(e) =>
                                handleMaxChange(
                                  questionList.preferenceUId,
                                  "money-range",
                                  e
                                )
                              }
                              max={15000}
                            />
                            <span>
                              {" "}
                              <i class="fa-solid fa-dollar-sign dollar-sign"></i>{" "}
                            </span>
                          </div> */}
                        </div>
                        {state.isError && (
                          <div className="tooltip-validation text-center">
                            <div className="tooltip-inner">
                              {state.toolTipText}
                            </div>
                          </div>
                        )}
                        <div className="block-btns">
                          {Mainindex != 0 && (
                            <button
                              type="button"
                              className="btns btns-back me-3"
                              onClick={() => back(Mainindex, questionList)}
                            >
                              {" "}
                              Back{" "}
                            </button>
                          )}
                          <button
                            type="button"
                            className="btns"
                            onClick={() => next(Mainindex, questionList)}
                          >
                            {" "}
                            Continue <span></span>{" "}
                          </button>
                        </div>
                      </div>
                    ) : questionList.type == "Time-range" ? (
                      <div className="tab tab-3">
                        <h1>{questionList.question} </h1>
                        {questionList.description != null ? (
                          <h6>{questionList.description}</h6>
                        ) : null}
                        <div className="">
                          <Range
                            min={0}
                            max={60}
                            marks={state.TimeRangeMarks}
                            onChange={(value) =>
                              onChange(
                                questionList.preferenceUId,
                                "Time-range",
                                value
                              )
                            }
                            defaultValue={[
                              state.answersPrefer[questionList.preferenceUId] !=
                              undefined
                                ? parseInt(
                                    state.answersPrefer[
                                      questionList.preferenceUId
                                    ].preferencesanswer.split(",")[0]
                                  )
                                : 0,
                              state.answersPrefer[questionList.preferenceUId] !=
                              undefined
                                ? parseInt(
                                    state.answersPrefer[
                                      questionList.preferenceUId
                                    ].preferencesanswer.split(",")[1]
                                  )
                                : 30,
                            ]}
                            tipFormatter={(value) => `${value} min`}
                          />
                          {/* <div className="position-relative">
                            <input
                              type="number"
                              className="form-control mb-3"
                              id="minRange"
                              value={
                                state.answersPrefer[
                                  questionList.preferenceUId
                                ] !== undefined
                                  ? parseInt(
                                      state.answersPrefer[
                                        questionList.preferenceUId
                                      ].preferencesanswer.split(",")[0]
                                    )
                                  : 0
                              }
                              onChange={(e) =>
                                handleMinRange(
                                  questionList.preferenceUId,
                                  "Time-range",
                                  e
                                )
                              }
                              min={0}
                            />
                            <span>
                              {" "}
                              <i class="dollar-sign"> min </i>{" "}
                            </span>
                          </div>
                          <div className="position-relative">
                            <input
                              type="number"
                              className="form-control"
                              id="maxRange"
                              value={
                                state.answersPrefer[
                                  questionList.preferenceUId
                                ] !== undefined
                                  ? parseInt(
                                      state.answersPrefer[
                                        questionList.preferenceUId
                                      ].preferencesanswer.split(",")[1]
                                    )
                                  : 30
                              }
                              onChange={(e) =>
                                handleMaxRange(
                                  questionList.preferenceUId,
                                  "Time-range",
                                  e
                                )
                              }
                              max={60}
                            />
                            <span>
                              {" "}
                              <i class="dollar-sign"> min </i>{" "}
                            </span>
                          </div> */}
                        </div>
                        {state.isError && (
                          <div className="tooltip-validation text-center">
                            <div className="tooltip-inner">
                              {state.toolTipText}
                            </div>
                          </div>
                        )}
                        <div className="block-btns">
                          {Mainindex != 0 && (
                            <button
                              type="button"
                              className="btns btns-back me-3"
                              onClick={() => back(Mainindex, questionList)}
                            >
                              {" "}
                              Back{" "}
                            </button>
                          )}
                          <button
                            type="button"
                            className="btns"
                            onClick={() => next(Mainindex, questionList)}
                          >
                            {" "}
                            Continue <span></span>{" "}
                          </button>
                        </div>
                      </div>
                    ) : questionList.type == "input" &&
                      questionList.questionlabel == "WORK ADDRESS" ? (
                      <div className="tab tab-5">
                        <h1>Work Location</h1>

                        {questionList.description != null ? (
                          <h6>{questionList.description}</h6>
                        ) : null}
                        <div className="addressInput">
                          <input
                            className="form-control"
                            type="text"
                            defaultValue={
                              state.answersPrefer[questionList.preferenceUId] !=
                              undefined
                                ? state.answersPrefer[
                                    questionList.preferenceUId
                                  ].preferencesanswer
                                : ""
                            }
                            id="address"
                            onChange={(e) =>
                              setLocation(
                                "address",
                                e,
                                questionList.preferenceUId
                              )
                            }
                          />
                          <i class="fa-solid fa-location-dot"></i>
                        </div>
                        {state.isError &&
                          state.answersPrefer[questionList.preferenceUId]
                            .preferencesanswer == "" && (
                            <div className="tooltip-validation text-center">
                              <div className="tooltip-inner">
                                {state.toolTipText}
                              </div>
                            </div>
                          )}

                        {/* -----------------checkbox start---------------------- */}
                        <div className="tab tab-1">
                          <label className="custom-checkbox mt-3">
                            <input
                              type="checkbox"
                              checked={
                                state.answersPrefer[
                                  questionList.preferenceUId_check
                                ] != undefined &&
                                (state.answersPrefer[
                                  questionList.preferenceUId_check
                                ].preferencesanswer == true ||
                                  state.answersPrefer[
                                    questionList.preferenceUId_check
                                  ].preferencesanswer == "true")
                                  ? true
                                  : false
                              }
                              onChange={(e) =>
                                setDie(
                                  "die",
                                  e,
                                  questionList.preferenceUId_check
                                )
                              }
                            />
                            <span className="checkmark"></span>
                            <label> {questionList.question_check} </label>
                          </label>

                          {state.isError &&
                            state.answersPrefer[
                              questionList.preferenceUId_check
                            ] != undefined &&
                            state.answersPrefer[
                              questionList.preferenceUId_check
                            ].preferencesanswer == false && (
                              <div className="tooltip-validation text-center">
                                <div className="tooltip-inner">
                                  {state.toolTipText}
                                </div>
                              </div>
                            )}
                        </div>
                        {/* -----------------checkbox start---------------------- */}

                        {/* buttons */}
                        <div className="block-btns">
                          {Mainindex != 0 && (
                            <button
                              type="button"
                              className="btns btns-back me-3"
                              onClick={() => back(Mainindex, questionList)}
                            >
                              {" "}
                              Back{" "}
                            </button>
                          )}
                          {state.getSessionData.istemporary != true ? (
                            <button
                              type="button"
                              className="btns"
                              onClick={() => next(Mainindex, questionList)}
                            >
                              {" "}
                              Continue <span></span>{" "}
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btns"
                              onClick={submitHandler}
                            >
                              {" "}
                              Submit <span></span>
                            </button>
                          )}
                        </div>
                      </div>
                    ) : questionList.type == "input" &&
                      questionList.questionlabel == "Current living address" ? (
                      <div className="tab tab-6">
                        <h1>{questionList.question}</h1>

                        {questionList.description != null ? (
                          <h6>{questionList.description}</h6>
                        ) : null}
                        <input
                          className="form-control"
                          type="text"
                          defaultValue={
                            state.answersPrefer[questionList.preferenceUId] !=
                            undefined
                              ? state.answersPrefer[questionList.preferenceUId]
                                  .preferencesanswer
                              : ""
                          }
                          id="current-address"
                          onChange={(e) =>
                            setLocation(
                              "current-address",
                              e,
                              questionList.preferenceUId
                            )
                          }
                        />
                        {state.isError &&
                          state.answersPrefer[questionList.preferenceUId]
                            .preferencesanswer == "" && (
                            <div className="tooltip-validation text-center">
                              <div className="tooltip-inner">
                                {state.toolTipText}
                              </div>
                            </div>
                          )}
                        <div className="block-btns">
                          {Mainindex != 0 && (
                            <button
                              type="button"
                              className="btns btns-back me-3"
                              onClick={() => back(Mainindex, questionList)}
                            >
                              {" "}
                              Back{" "}
                            </button>
                          )}
                          <button
                            type="button"
                            className="btns"
                            onClick={() => next(Mainindex, questionList)}
                          >
                            {" "}
                            Continue <span></span>{" "}
                          </button>
                        </div>
                      </div>
                    ) : questionList.type == "RadioButtons" ? (
                      <div
                        className={
                          questionList.sequence == Mainindex + 1
                            ? "tab tab-1"
                            : "tab tab-1"
                        }
                      >
                        <h1>{questionList.question}</h1>
                        {(" " + questionList.options)
                          .split(",")
                          .sort()
                          .map((optionText, optionIndex) => {
                            return (
                              <button
                                type="button"
                                className={
                                  state.answersPrefer[
                                    questionList.preferenceUId
                                  ] != undefined
                                    ? state.answersPrefer[
                                        questionList.preferenceUId
                                      ].preferencesanswer
                                        .split(",")
                                        .indexOf(optionText.trim()) > -1
                                      ? "btn btn-clr"
                                      : "btn"
                                    : "btn"
                                }
                                key={optionIndex}
                                onClick={(e) =>
                                  onChange(
                                    questionList.preferenceUId,
                                    "RadioButtons",
                                    optionText.trim()
                                  )
                                }
                              >
                                {optionText}
                              </button>
                            );
                          })}
                        {state.isError &&
                          state.answersPrefer[questionList.preferenceUId] !=
                            undefined &&
                          state.answersPrefer[questionList.preferenceUId]
                            .preferencesanswer == "" && (
                            <div className="tooltip-validation text-center">
                              <div className="tooltip-inner">
                                {state.toolTipText}
                              </div>
                            </div>
                          )}

                        <div className="block-btns">
                          {Mainindex != 0 && (
                            <button
                              type="button"
                              className="btns btns-back me-3"
                              onClick={() => back(Mainindex, questionList)}
                            >
                              {" "}
                              Back{" "}
                            </button>
                          )}
                          <button
                            type="button"
                            className="btns"
                            onClick={() => next(Mainindex, questionList)}
                          >
                            {" "}
                            Continue <span></span>{" "}
                          </button>
                        </div>
                      </div>
                    ) : questionList.type == "DatePicker" ? (
                      <div
                        className={
                          questionList.sequence == Mainindex + 1
                            ? "tab tab-1"
                            : "tab tab-1"
                        }
                      >
                        <h1>
                          {" "}
                          {questionList.question.charAt(0).toUpperCase() +
                            questionList.question.slice(1).toLowerCase()}
                        </h1>
                        <DatePicker
                          id="startdate"
                          dateFormat="MM/dd/yyyy"
                          selected={
                            state.answersPrefer[questionList.preferenceUId] !=
                              undefined &&
                            state.answersPrefer[questionList.preferenceUId]
                              .preferencesanswer != null &&
                            state.answersPrefer[questionList.preferenceUId]
                              .preferencesanswer != "" &&
                            state.answersPrefer[questionList.preferenceUId]
                              .preferencesanswer != "Invalid date"
                              ? new Date(
                                  state.answersPrefer[
                                    questionList.preferenceUId
                                  ].preferencesanswer
                                )
                              : null
                          }
                          selectsStart
                          minDate={new Date()}
                          onChange={(e) =>
                            handleChangeStart(
                              "address",
                              e,
                              questionList.preferenceUId
                            )
                          }
                          placeholderText="Select start date ..."
                          className="form-control"
                          autoComplete="off"
                        />
                        {/* <i className="fa fa-calendar preferenceCalendarNav" ></i> */}
                        {state.isError &&
                          state.answersPrefer[questionList.preferenceUId] !=
                            undefined &&
                          state.answersPrefer[questionList.preferenceUId]
                            .preferencesanswer == "" && (
                            <div className="tooltip-validation text-center">
                              <div className="tooltip-inner">
                                {state.toolTipText}
                              </div>
                            </div>
                          )}

                        <div className="block-btns">
                          {Mainindex != 0 && (
                            <button
                              type="button"
                              className="btns btns-back me-3"
                              onClick={() => back(Mainindex, questionList)}
                            >
                              {" "}
                              Back{" "}
                            </button>
                          )}
                          <button
                            type="button"
                            className="btns"
                            onClick={() => next(Mainindex, questionList)}
                          >
                            {" "}
                            Continue <span></span>{" "}
                          </button>
                        </div>
                      </div>
                    ) : (
                      // questionList.type == "CheckBox" ? (
                      //   <div
                      //     className={
                      //       questionList.sequence == Mainindex + 1
                      //         ? "tab tab-1"
                      //         : "tab tab-1"
                      //     }
                      //   >
                      //     <label className="custom-checkbox">
                      //       <input
                      //         type="checkbox"
                      //         checked={
                      //           state.answersPrefer[questionList.preferenceUId] !=
                      //             undefined &&
                      //           (state.answersPrefer[questionList.preferenceUId]
                      //             .preferencesanswer == true ||
                      //             state.answersPrefer[questionList.preferenceUId]
                      //               .preferencesanswer == "true")
                      //             ? true
                      //             : false
                      //         }
                      //         onChange={(e) =>
                      //           setDie("die", e, questionList.preferenceUId)
                      //         }
                      //       />
                      //       <span className="checkmark"></span>
                      //       <label> {questionList.question} </label>
                      //     </label>

                      //     {state.isError &&
                      //       state.answersPrefer[questionList.preferenceUId] !=
                      //         undefined &&
                      //       state.answersPrefer[questionList.preferenceUId]
                      //         .preferencesanswer == false && (
                      //         <div className="tooltip-validation text-center">
                      //           <div className="tooltip-inner">
                      //             {state.toolTipText}
                      //           </div>
                      //         </div>
                      //       )}
                      //     <div className="block-btns">
                      //       {Mainindex != 0 && (
                      //         <button
                      //           type="button"
                      //           className="btns btns-back me-3"
                      //           onClick={() => back(Mainindex, questionList)}
                      //         >
                      //           {" "}
                      //           Back{" "}
                      //         </button>
                      //       )}

                      //       {state.getSessionData.istemporary != true ? (
                      //         <button
                      //           type="button"
                      //           className="btns"
                      //           onClick={() => next(Mainindex, questionList)}
                      //         >
                      //           {" "}
                      //           Continue <span></span>{" "}
                      //         </button>
                      //       ) : (
                      //         <button
                      //           type="button"
                      //           className="btns"
                      //           onClick={submitHandler}
                      //         >
                      //           {" "}
                      //           Submit <span></span>
                      //         </button>
                      //       )}
                      //     </div>
                      //   </div>
                      // ) :
                      ""
                    )}
                  </div>
                );
              })}
            </div>

            <div className="tab tab-7 d-none height100per" id="proirities">
              {/* <div className="sliderNumbers">
                <span>{state.index}</span>/
                <span>{state.questionList.length - 1}</span>
              </div>
              <h1>List Your Priorities...</h1>

              <p>
                Drag and Drop the categories in to the box below in the order of
                importance.
              </p> */}
              <div
                id="shared-lists"
                className="row flex-column padtop-40 new-drag-drop"
              >
                {/* <DragDrop
                  state={state}
                  handleRightItemsUpdate={handleRightItemsUpdate}
                /> */}
                <NewDragDrop
                  state={state}
                  handleRightItemsUpdate={handleRightItemsUpdate}
                />

                {state.isError && state.DragOptionsCheck == "" && (
                  <div className="tooltip-validation text-center">
                    <div className="tooltip-inner">{state.toolTipText}</div>
                  </div>
                )}
                <div className="block-btns col-md-12">
                  <button
                    type="button"
                    className="btns btns-back me-3"
                    onClick={() => back(13)}
                  >
                    {" "}
                    Back{" "}
                  </button>
                  <button
                    type="button"
                    className="btns"
                    onClick={submitHandler}
                  >
                    {" "}
                    Submit <span></span>
                  </button>
                </div>
              </div>
            </div>

            {/* <div id="success" className="d-none">
              <h1>
                Please wait, we are building your Home Preferences profile!
              </h1>
            </div> */}
            <div id="success" className="d-none">
              <div className="text-center" id="logo">
                <img src="assets/images/reloLogo.png" alt="" />
              </div>
              <h1>Please wait, while we build your profile!</h1>
            </div>
          </form>
        </div>
      </div>

      <div
        id="HomeRentalCal"
        className="modal new_modal_pop fade"
        role="dialog"
        style={{ zIndex: 1060 }}
      >
        <div className="modal-dialog" style={{ background: "transparent" }}>
          <div className="modal-content solopage1cont">
            <div className="width-90per pd-y-50">
              <h2>How much rent should I budget?</h2>

              <div className="only_close">
                <span
                  className="pointer close"
                  data-dismiss="modal"
                  onClick={closePopUp}
                >
                  {" "}
                  &times;{" "}
                </span>
              </div>

              <div>
                <h4>Annual household income ($)</h4>
                <input
                  type="text"
                  placeholder="Enter your Annual income here..."
                  className="form-control"
                  min="500"
                  value={state.HouseMonthlyRent}
                  onChange={(e) => CalculateMonthlyBudget(e)}
                />

                <h5>
                  We recommend budgeting upto 30% of your monthly income towards
                  your rent.{" "}
                </h5>
                {state.HouseMonthlyRent != "" ? (
                  <h4 style={{ color: "#1e81bb" }}>
                    i.e $
                    {Number(((30 / 100) * state.HouseMonthlyRent) / 12).toFixed(
                      0
                    )}
                    /Month
                  </h4>
                ) : (
                  ""
                )}
                <p style={{ paddingTop: "20px" }}>
                  * We do not store any of the above entered information. It is
                  solely used to calculate & recommend the rent.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetStartedFirstTime;
