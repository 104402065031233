'use strict'
import serviceBase from './serviceBase'
const ThirdPartyApiService = {
    getzillowResp: (data) => serviceBase.post('/thirdpartyApi/zillow', data),
    getzillowRespWidget: (data) => serviceBase.post('/thirdpartyApi/zillow', data),
    getbanglowResp: (data) => serviceBase.get(`${'https://cors-anywhere.herokuapp.com/'}https://fieldstone.bungalow.com/api/v1/listings/properties/?market__slug=${data.urldata}`, {}),
    getbangalowPraperty: (data) => serviceBase.get(`${'https://cors-anywhere.herokuapp.com/'}https://fieldstone.bungalow.com/api/v1/listings/properties/${data.urldata}`, {}),
    thirdPartyApi: (data) => serviceBase.post('/thirdpartyapi', data),
    pointofinterest: (data) => serviceBase.post('/thirdpartyapi/poi', data),
    getIp: (data) => serviceBase.post('/thirdpartyapi/getIp', data),
    LoginDetailsStore: (data) => serviceBase.post('/loginStore/sessionStore', data),
    getLoginInfo: (data) => serviceBase.post('/loginStore/getstore', data),
    sessionDestory: (data) => serviceBase.post('/loginStore/sessionDestory', data),
    transitTada: (data) => serviceBase.get(`${'https://cors-anywhere.herokuapp.com/'}${data.urldata}`, {}),
    getbangalowMarkets: () => serviceBase.get(`${'https://cors-anywhere.herokuapp.com/'}https://fieldstone.bungalow.com/api/v1/markets/`),
    sendEmail: (data) => serviceBase.post('/EmailSms/sendEmail', data),
    sendSms: (data) => serviceBase.post('/EmailSms/sendSms', data),
    envAWS: (data) => serviceBase.post('/EmailSms/getAWSEnvVariables', data),
    getcsvData: (data) => serviceBase.post('/cityDetailsStore/getcityDetailsStore', data),
    GeneratePdf: (data) => serviceBase.post('/generatepdf/getpdf', data),

    GetToken: (data) => serviceBase.post('/loginfun/GetToken', data),
    auth0UserExist: (data) => serviceBase.post('/loginfun/auth0UserExist', data),
    sentemailotp: (data) => serviceBase.post('/loginfun/sentemailotp', data),
    sentphoneotp: (data) => serviceBase.post('/loginfun/sentphoneotp', data),
    sentotpverify: (data) => serviceBase.post('/loginfun/sentotpverify', data),
    SignUpWithAutho: (data) => serviceBase.post('/loginfun/SignUpWithAutho', data),

    GetTokensocial: (data) => serviceBase.post('/loginfun/GetTokensocial', data),
    auth0UserExistsocial: (data) => serviceBase.post('/loginfun/auth0UserExistsocial', data),
    greatSchools: (data) => serviceBase.post("/thirdpartyapi/greatschools", data),

}
export default ThirdPartyApiService;