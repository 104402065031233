import React from "react";
// import Modal from "react-modal";
import { Spin, Modal } from "antd";
import IntTelDropDown from "../../../common/internationalPhone/IntTelDropDown";

const EditModal = ({
  isOpen,
  closeModal,
  state,
  inputHandler,
  UpdateTransferee,
  handleCountryCodeChange,
}) => {
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <Modal open={isOpen} footer={null} onCancel={closeModal}>
          <div className="width-70per pd-y-70">
            <h6 className="editprofileheading">Edit Profile </h6>
            <div className="focus-input-label mt-3 padding-x-50">
              {state.EditStatusSuccess == true ? (
                <h6 className="alert alert-success">
                  Profile updated successfully
                </h6>
              ) : (
                ""
              )}
              {state.ErrorEditStatus == true ? (
                <h6 className="alert alert-danger">{state.errorMsg}</h6>
              ) : (
                ""
              )}

              {state.EditStatusSuccess != true && (
                <form
                  method="post"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                  id="EditForm"
                >
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="floating-label">
                        <input
                          type="text"
                          placeholder="First name"
                          name="firstName"
                          autoComplete="new-password"
                          className="form-control"
                          value={state.firstName}
                          id="firstName"
                          onChange={(e) => inputHandler(e)}
                        />
                        <label>First name</label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="floating-label">
                        <input
                          type="text"
                          autoComplete="new-password"
                          className="form-control"
                          name="lastName"
                          placeholder=" Last name"
                          value={state.lastName}
                          id="lastName"
                          onChange={(e) => inputHandler(e)}
                        />
                        <label>Last name</label>
                      </div>
                    </div>
                  </div>

                  <div className="floating-label">
                    <input
                      type="email"
                      autoComplete="new-password"
                      name="emailid"
                      className="form-control"
                      disabled
                      placeholder="Email address"
                      id="emailid"
                      value={state.EmailId}
                      onChange={(e) => inputHandler(e)}
                    />
                    <label>Email address</label>
                  </div>

                  <div className="phone-control">
                    <label>Phone Number</label>
                    <div className="phone-input">
                      <IntTelDropDown
                        handleCountryCodeChange={handleCountryCodeChange}
                        countrycode={state.countrycode}
                      />
                      <input
                        type="tel"
                        autoComplete="new-password"
                        className="form-control pl-5"
                        placeholder="Phone Number "
                        name="phoneNumber"
                        id="phoneNumber"
                        value={state.phoneNumber}
                        onChange={(e) => inputHandler(e)}
                      />
                    </div>
                  </div>

                  <div className="form-group text-center mt-5 mb-0">
                    {state.loaderEdit == true ? (
                      <Spin />
                    ) : (
                      <button
                        type="button"
                        className="btn theme-btn "
                        onClick={(event) => UpdateTransferee(event)}
                      >
                        {" "}
                        Submit <span></span>{" "}
                      </button>
                    )}
                  </div>
                </form>
              )}
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default EditModal;
