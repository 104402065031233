import React, { useState, useEffect } from "react";
import moment from "moment";
import FromDateComponent from "./FromDateComponent";
import ToDateComponent from "./ToDateComponent";
import ServiceLevelDropDownComponent from "./ServiceLevelDropDownComponent";
import CompanyDropDownComponent from "./CompanyDropDownComponent";
import "react-datepicker/dist/react-datepicker.min.css";
import ApiServices from "../../../../../services/apiServices";

const FilterComponent = ({
  reportType,
  fromDate,
  toDate,
  company,
  servicelevel,
  updateState,
  supplierid,
}) => {
  function onSubmit(reportType) {
    console.log(reportType);
    const data = {
      supplierid: supplierid,
      reportType: reportType,
      startDate: fromDate.startDate,
      endDate: toDate.endDate,
      company: company.company,
      servicelevel: servicelevel.servicelevel,
    };
    fetchTableData(data, updateState);
  }

  return (
    <>
      <form>
        <div className="container">
          <div className="row">
            <div className="col-10">
              <div className="form-row row">
                {designFilterBasedOnReportType(
                  reportType,
                  fromDate,
                  toDate,
                  company,
                  servicelevel
                )}
              </div>
            </div>
            <div className="col-2">
              <button
                type="button"
                onClick={() => onSubmit(reportType)}
                className="btn"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default FilterComponent;

const designFilterBasedOnReportType = (
  reportType,
  fromDate,
  toDate,
  company,
  servicelevel
) => {
  if (reportType === "signup") {
    return (
      <>
        <FromDateComponent {...fromDate} />
        <ToDateComponent {...toDate} />
        <CompanyDropDownComponent {...company} />
        <ServiceLevelDropDownComponent {...servicelevel} />
      </>
    );
  } else if (reportType === "appointment") {
    return (
      <>
        <FromDateComponent {...fromDate} />
        <ToDateComponent {...toDate} />
      </>
    );
  } else if (reportType === "transferee") {
    return (
      <>
        <FromDateComponent {...fromDate} />
        <ToDateComponent {...toDate} />
        <ServiceLevelDropDownComponent {...servicelevel} />
      </>
    );
  } else if (reportType === "billing") {
    return (
      <>
        <FromDateComponent {...fromDate} />
        <ToDateComponent {...toDate} />
        <CompanyDropDownComponent {...company} />
      </>
    );
  }
};

const fetchTableData = (data, updateState) => {
  // console.log(data, "====", updateState);
  if (data.reportType === "signup") {
    fetchTableDataSignUp(data, updateState);
  } else if (data.reportType === "appointment") {
    fetchTableDataAppointment(data, updateState);
  } else if (data.reportType === "transferee") {
    fetchTableDataTransferee(data, updateState);
  } else if (data.reportType === "billing") {
    fetchTableDataBilling(data, updateState);
  }
};

const fetchTableDataSignUp = async (data, updateState) => {
  let errorUpdate = 0;
  if (data.startDate == null) {
    // console.log(data, "===data");
    $("#startdate").addClass("is-invalid");
    errorUpdate++;
  } else {
    $("#startdate").removeClass("is-invalid");
  }
  if (data.endDate == null) {
    $("#enddate").addClass("is-invalid");
    errorUpdate++;
  } else {
    $("#enddate").removeClass("is-invalid");
  }
  if (errorUpdate == 0) {
    const reqData = {
      supplieruniqueId: data.supplierid,
      fromdate: moment(data.startDate).format("MM/DD/YYYY"),
      todate: moment(data.endDate).format("MM/DD/YYYY"),
      servicelevel: data.servicelevel,
      company: data.company,
    };
    updateState({ loader: true });
    const GetSupplierSignupReport = await ApiServices.GetSupplierSignupReport(
      reqData
    );

    if (GetSupplierSignupReport.error == 0) {
      let SignUpReports = GetSupplierSignupReport.signupreportlist;
      SignUpReports = SignUpReports.sort(
        (a, b) =>
          new Date(b.signupdate).getTime() - new Date(a.signupdate).getTime()
      );
      SignUpReports.map(function (item) {
        item.accessfrom = moment(item.accessfrom).format("MM/DD/YYYY");
        item.accessto = moment(item.accessto).format("MM/DD/YYYY");
        item.signupdate = moment(item.signupdate).format("MM/DD/YYYY HH:MM");
        return item;
      });
      updateState({ reportslist: SignUpReports, loader: false });
    } else {
      updateState({ loader: false });
    }
  }
};

const fetchTableDataAppointment = async (data, updateState) => {
  let errorUpdate = 0;
  if (data.startDate == null) {
    // console.log(data, "===data");
    $("#startdate").addClass("is-invalid");
    errorUpdate++;
  } else {
    $("#startdate").removeClass("is-invalid");
  }
  if (data.endDate == null) {
    $("#enddate").addClass("is-invalid");
    errorUpdate++;
  } else {
    $("#enddate").removeClass("is-invalid");
  }
  if (errorUpdate == 0) {
    const reqData = {
      supplieruniqueId: data.supplierid,
      fromdate: moment(data.startDate).format("MM/DD/YYYY"),
      todate: moment(data.endDate).format("MM/DD/YYYY"),
    };
    updateState({ loader: true });
    const GetSupplierAppointmentsReport =
      await ApiServices.GetSupplierAppointmentsReport(reqData);
    if (GetSupplierAppointmentsReport.error == 0) {
      let GSAReport = GetSupplierAppointmentsReport.transfereeappoinmentsList;
      GSAReport = GSAReport.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      GSAReport.map(function (item) {
        item.date = moment(item.date).format("MM/DD/YYYY");
        return item;
      });
      updateState({ reportslist: GSAReport, loader: false });
    } else {
      updateState({ loader: false });
    }
  }
};

const fetchTableDataTransferee = async (data, updateState) => {
  let errorUpdate = 0;
  if (data.startDate == null) {
    // console.log(data, "===data");
    $("#startdate").addClass("is-invalid");
    errorUpdate++;
  } else {
    $("#startdate").removeClass("is-invalid");
  }
  if (data.endDate == null) {
    $("#enddate").addClass("is-invalid");
    errorUpdate++;
  } else {
    $("#enddate").removeClass("is-invalid");
  }
  if (errorUpdate == 0) {
    const reqData = {
      supplieruniqueId: data.supplierid,
      fromdate: moment(data.startDate).format("MM/DD/YYYY"),
      todate: moment(data.endDate).format("MM/DD/YYYY"),
      servicelevel: data.servicelevel,
    };
    updateState({ loader: true });

    const GetSupplierTransfereesReport =
      await ApiServices.GetSupplierTransfereesReport(reqData);

    if (GetSupplierTransfereesReport.error == 0) {
      let GSTReport = GetSupplierTransfereesReport.transfereesList;
      GSTReport = GSTReport.sort(
        (a, b) =>
          new Date(b.AccessFromDate).getTime() -
          new Date(a.AccessFromDate).getTime()
      );
      GSTReport.map(function (item) {
        item.AccessFromDate = moment(item.AccessFromDate).format("MM/DD/YYYY");
        item.AccessToDate = moment(item.AccessToDate).format("MM/DD/YYYY");
        return item;
      });
      updateState({ reportslist: GSTReport, loader: false });
    } else {
      updateState({ loader: false });
    }
  }
};

const fetchTableDataBilling = async (data, updateState) => {
  let errorUpdate = 0;
  if (data.startDate == null) {
    // console.log(data, "===data");
    $("#startdate").addClass("is-invalid");
    errorUpdate++;
  } else {
    $("#startdate").removeClass("is-invalid");
  }
  if (data.endDate == null) {
    $("#enddate").addClass("is-invalid");
    errorUpdate++;
  } else {
    $("#enddate").removeClass("is-invalid");
  }
  if (errorUpdate == 0) {
    const reqData = {
      supplieruid: data.supplierid,
      activityfromdate: moment(data.startDate).format("YYYY-MM-DD"),
      activitytodate: moment(data.endDate).format("YYYY-MM-DD"),
      company: data.company,
    };
    updateState({ loader: true });

    const GetBillingEventsBySupplierId =
      await ApiServices.GetBillingEventsBySupplierId(reqData);
    if (GetBillingEventsBySupplierId.error == 0) {
      let item = GetBillingEventsBySupplierId.statuslist;
      for (var x = 0; x < item.length; x++) {
        let now = item[x].ActivityToDate;
        let then = item[x].ActivityFromDate;
        let ms = moment(now, "DD/MM/YYYY hh:mm A").diff(
          moment(then, "DD/MM/YYYY hh:mm A")
        );
        let d = moment.duration(ms);
        let s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
        item[x].ActivityFromDate = moment(
          item[x].ActivityFromDate,
          "DD/MM/YYYY hh:mm A"
        ).format("MM/DD/YYYY hh:mm A");
        item[x].ActivityToDate = moment(
          item[x].ActivityToDate,
          "DD/MM/YYYY hh:mm A"
        ).format("MM/DD/YYYY hh:mm A");
        item[x].duration = s;
      }
      updateState({ reportslist: item, loader: false });
    } else {
      updateState({ loader: false });
    }
  }
};
