import React from "react";

function ShowDetailsPopUp(props) {
  const { closeDetailsPopUp, state } = props;
  return (
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="only_close">
          <span
            className="pointer close"
            data-dismiss="modal"
            onClick={closeDetailsPopUp}
          >
            &times;
          </span>
        </div>
        <h6>Appointment with {state.consultantName} </h6>

        <div className="modal-body">
          <div className="ul_select_list text_overfloww">
            <span>Appointment Type</span>
            <span className="main_span"> {state.EditEvent}</span>
          </div>
          <ul className="appoint_list">
            <li>
              <a href="javascript:void(0)">
                {" "}
                Date <span>{state.popupDate}</span>{" "}
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                {" "}
                Time{" "}
                <span>
                  {state.startTime} - {state.endTime}
                </span>{" "}
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                {" "}
                Total Hours <span>{state.diffTime} Hours</span>{" "}
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                {" "}
                Day <span>{state.dayNameLabel[state.dayName]}</span>{" "}
              </a>
            </li>
          </ul>
          <div className="ul_select_list text_overfloww">
            <span>Pickup Location</span>
            <span className="main_span"> {state.pickupLocation}</span>
          </div>
          <div className="ul_select_list text_overfloww">
            <span>Notes</span>
            <span className="main_span"> {state.serviceNote}</span>
          </div>
        </div>
        <div className="col-md-12">
          <div id="accordion" className="accordion">
            <div className="card mb-0">
              <div
                className="card-header collapsed"
                data-toggle="collapse"
                href="#collapseOne"
              >
                <a className="card-title">Transferee Details</a>
              </div>

              <div
                id="collapseOne"
                className="card-body collapse"
                data-parent="#accordion"
              >
                <div className="form-group form-group2">
                  <label style={{ color: "#a7a7a7" }}>Name:</label>
                  <p>
                    {state.transfareefirstname} {state.transfareelastname}
                  </p>
                </div>
                <div className="form-group form-group2">
                  <label style={{ color: "#a7a7a7" }}>Email</label>
                  <p> {state.transfareeemailId}</p>
                </div>
                <div className="form-group form-group2">
                  <label style={{ color: "#a7a7a7" }}>Location</label>
                  <p> {state.pickupLocation}</p>
                </div>
                <div className="form-group form-group2">
                  <label style={{ color: "#a7a7a7" }}>Notes</label>
                  <p> {state.serviceNote}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-danger"
            data-dismiss="modal"
            onClick={closeDetailsPopUp}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default ShowDetailsPopUp;
