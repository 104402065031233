import React from "react";

function RentalApplicationProcessModal() {
  const closeModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#RentalApplicationProcess").modal("hide");
      });
    });
  };
  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header border-0">
          <div class="only_close">
            <span
              aria-hidden="true"
              className="pointer close"
              onClick={() => closeModal()}
              data-dismiss="modal"
            >
              ×
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div className="widthModal">
            <div className="item-view">
              <h2>Rental Application Process </h2>
              <p className="mb-0">
                Securing the perfect rental home requires preparation and
                attention to detail during the application process. Whether
                you’re applying to a competitive rental market or simply looking
                for the right fit, understanding how to navigate the rental
                application process is crucial. Follow these steps to improve
                your chances of getting approved and moving into your ideal
                rental property.
              </p>
            </div>

            <div className="tails-row">
              <div className="tails">
                <div className="item-view">
                  <h2>Step 1: Prepare Your Documents </h2>
                  <p>
                    Before you even start applying for rentals, it’s essential
                    to gather all the necessary documents that landlords and
                    property managers typically require. Being prepared ensures
                    a smooth and efficient application process.{" "}
                  </p>
                  <p>
                    Here’s a list of common documents you should have ready:{" "}
                  </p>
                  <ul>
                    <li>
                      <b> Proof of Income: </b>Landlords want to see that you
                      can afford the rent, so be prepared to provide recent pay
                      stubs, an offer letter from your employer, or tax returns
                      if you're self-employed. Some landlords require proof of
                      income showing that you make 2.5 to 3 times the monthly
                      rent.
                    </li>
                    <li>
                      <b> Identification:</b> A government-issued photo ID (such
                      as a driver's license or passport) is usually required as
                      part of the application to verify your identity.
                    </li>
                    <li>
                      <b>Credit Report:</b> Some landlords will pull your credit
                      report as part of their background check. If you’re
                      concerned about your credit score, consider running your
                      own credit report in advance to identify and address any
                      issues.
                    </li>
                    <li>
                      <b>Rental History:</b> Have a list of your previous
                      addresses and landlord contact information for reference
                      checks. Some landlords may also ask for documentation that
                      shows you've paid rent on time in the past.
                    </li>
                    <li>
                      <b>References:</b> Landlords might request references from
                      past landlords, employers, or personal contacts to vouch
                      for your reliability as a tenant.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>Step 2: Create a Strong Renter’s Resume </h2>
                  <p>
                    A renter’s resume is an excellent way to stand out in a
                    competitive market by presenting yourself as a reliable and
                    organized tenant. Your renter’s resume should include:
                  </p>

                  <ul>
                    <li>Personal details (name, contact information)</li>
                    <li>Employment history</li>
                    <li>Proof of income</li>
                    <li>Previous rental history</li>
                    <li>References (both personal and professional)</li>
                  </ul>
                  <p>
                    Use our Renter’s Resume Generator to create a polished and
                    professional resume in minutes. This tool makes it easy to
                    compile all the necessary information in a clean,
                    presentable format, boosting your chances of being selected
                    by landlords.{" "}
                  </p>
                </div>
                <div className="item-view">
                  <h2>Step 3: Complete the Rental Application </h2>
                  <p>
                    Once you’ve found a rental property you’re interested in,
                    it’s time to complete the application. Be sure to fill out
                    the application form accurately and thoroughly. Mistakes or
                    incomplete sections can delay the process or result in
                    rejection.{" "}
                  </p>
                  <p>Here’s what most rental applications will ask for: </p>
                  <ul>
                    <li>
                      <b> Personal Information: </b>Your name, current address,
                      contact information, and Social Security number (for
                      background checks).
                    </li>
                    <li>
                      <b> Employment Information: </b> Details about your
                      current employment, including job title, employer’s name,
                      address, and contact information.
                    </li>
                    <li>
                      <b>Income Information: </b> Landlords typically ask for
                      your monthly or yearly income to ensure you meet their
                      income-to-rent ratio requirements.
                    </li>
                    <li>
                      <b>Rental History: </b>Information about your previous
                      rental experiences, including landlord contact details and
                      the duration of your previous leases.
                    </li>
                    <li>
                      <b>Other Occupants: </b> If applicable, you may be asked
                      to provide information about additional occupants,
                      including family members or roommates who will be living
                      with you.
                    </li>
                    <li>
                      <b>Pets (If Applicable): </b> If you have pets, you’ll
                      likely need to provide details such as breed, size, and
                      possibly vaccination records. Some landlords require pet
                      deposits or additional rent.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="tails">
                <div className="item-view">
                  <h2>Step 4: Pay the Application Fee and Deposit </h2>

                  <p>
                    Many landlords charge a non-refundable application fee to
                    cover the costs of background and credit checks. This fee
                    typically ranges between $30 to $75, but it can vary
                    depending on your location.
                  </p>
                  <p>
                    In competitive rental markets, some landlords may also ask
                    for a holding deposit to reserve the unit while your
                    application is processed. Make sure you get a written
                    agreement regarding the terms of the holding deposit and
                    whether it’s refundable if your application is not accepted.{" "}
                  </p>
                </div>

                <div className="item-view">
                  <h2>Step 5: Prepare for Background and Credit Checks </h2>
                  <p>
                    Landlords often conduct background and credit checks to
                    verify your financial stability and ensure that you’re a
                    reliable tenant. They will check for:{" "}
                  </p>
                  <ul>
                    <li>
                      <b>Credit Score: </b> A good credit score shows that
                      you’re financially responsible and capable of paying rent
                      on time. If your credit score isn’t great, consider
                      offering a co-signer or explaining any past financial
                      issues upfront.
                    </li>
                    <li>
                      <b>Criminal History: </b> Landlords may look into your
                      criminal record to determine if you have any convictions.
                      Note that tenant rights laws vary by location, so make
                      sure you’re aware of the specific legal protections in
                      your area.
                    </li>
                    <li>
                      <b>Rental History: </b> A thorough rental history check
                      includes contacting past landlords to confirm timely rent
                      payments and ensure there were no lease violations.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>Step 6: Follow Up </h2>
                  <p>
                    After submitting your application, it’s important to follow
                    up with the landlord or property manager to confirm that
                    they received everything. A polite email or phone call can
                    demonstrate your interest and ensure that your application
                    is reviewed promptly.
                  </p>
                </div>
                <div className="item-view">
                  <h2>Step 7: Lease Signing and Moving In </h2>
                  <p>
                    If your application is approved, the final step is signing
                    the lease agreement. Be sure to read the lease carefully
                    before signing to understand your obligations and rights as
                    a tenant. Pay attention to key clauses, such as:
                  </p>
                  <ul>
                    <li>
                      <b>Rent Amount and Due Date:</b> Confirm the monthly rent
                      amount and the date it’s due, along with any grace periods
                      or late fees.
                    </li>
                    <li>
                      <b>Lease Term: </b> Verify the length of the lease,
                      whether it’s a short-term, 12-month, or month-to-month
                      agreement.
                    </li>
                    <li>
                      <b>Security Deposit: </b> Ensure that the terms for the
                      security deposit are clear, including the conditions for
                      its return when you move out.
                    </li>
                    <li>
                      <b>Maintenance and Repairs: </b> Understand your
                      responsibilities versus the landlord’s obligations when it
                      comes to repairs and general upkeep.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>Final Tips: </h2>

                  <ul>
                    <li>
                      <b>Be Prompt and Organized:</b> Rental markets can move
                      quickly. Ensure that you have all your documents ready in
                      advance and that you submit your application as soon as
                      possible.
                    </li>
                    <li>
                      <b>Use the Renter’s Resume Generator: </b>Having a
                      well-prepared renter’s resume will streamline the
                      application process and make a great impression on
                      potential landlords. Use our{" "}
                      <a href="/rentersresume"> Renter’s Resume Generator</a> to
                      put your best foot forward.
                    </li>
                    <li>
                      <b>Ask Questions: </b>Don’t hesitate to ask the landlord
                      or property manager questions about the lease, property,
                      or application process. It shows you’re engaged and helps
                      you clarify any concerns before committing to a rental.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="item-view mt-2">
              <p className="mb-0">
                By following these steps and being proactive, you’ll increase
                your chances of securing the rental home that’s right for you.
                Start preparing today by using our Renter’s Resume Generator to
                create a professional resume that highlights your qualifications
                as a tenant!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RentalApplicationProcessModal;
