import React, { useState } from "react";
// import "./helpModal.css";

function HelpModal(props) {
  const [state, setState] = useState({
    pageNumber: 1,
  });
  const nextPage = async (page) => {
    // console.log(page);
    setState((prev) => ({ ...prev, pageNumber: page }));
    // console.log(page);
  };
  const closeModal = async () => {
    $("document").ready(function () {
      $("#help-Modal-box").modal("hide");
      $("#logo").removeClass("d-none");
    });
    props.updateShowQuestions();
  };
  let subscriptioncode =
    localStorage.getItem("loginSubscribeDetails") != null
      ? JSON.parse(localStorage.getItem("loginSubscribeDetails"))
          .subscriptioncode
      : "";
  return (
    <div
      className="modal fade help-Modal-box"
      id="help-Modal-box"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog" role="document">
        {state.pageNumber == 1 && (
          <div className="modal-content d-block">
            <h1>
              Welcome <span>To ReloNavigator</span>
            </h1>

            <div className="letsstart">
              Let's Get <span>Started....</span>
            </div>

            <ul className="pagenation">
              <li>
                <div className="block-btns">
                  <button
                    type="button"
                    className="btn mr-2 px-4 theme-btn"
                    onClick={(e) => nextPage(2)}
                  >
                    {" "}
                    Next{" "}
                  </button>
                </div>
              </li>
            </ul>
          </div>
        )}
        {state.pageNumber == 2 && (
          <div className="modal-content d-block">
            <h1>
              <span>My Prefrences</span>
            </h1>

            <div className="image-view">
              <img
                src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/HelpSlides/1.png"
                alt=""
              />

              {/* <p>
                In order to get city and rental recommendations, you will need
                to fill out your preferences in the 'My Preferences' section.
                Your responses can be changed at any time.
              </p> */}
              <p>
                Please fill out your preferences in the 'My Preferences' section
                to curate your experience. Your responses can be changed at any
                time.
              </p>
            </div>
            <ul className="pagenation">
              <li>
                <div className="block-btns">
                  <button
                    type="button"
                    className="btns btns-back me-3"
                    onClick={(e) => nextPage(1)}
                  >
                    {" "}
                    Back{" "}
                  </button>
                  <button
                    type="button"
                    className="btns"
                    onClick={(e) => nextPage(3)}
                  >
                    {" "}
                    Next <span></span>{" "}
                  </button>
                </div>
              </li>
            </ul>
          </div>
        )}
        {state.pageNumber == 3 && (
          <div className="modal-content d-block">
            <h1>
              <span>EXPLORE</span>
            </h1>

            <div className="image-view">
              <img
                src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/HelpSlides/2.png"
                alt=""
              />

              <p>
                An Overview of cities is provided to help you decide which best
                fits your lifestyle. Click on the city name to see more details
                related to schools, points of interest, weather, nearby cities
                and more.
              </p>
            </div>
            <ul className="pagenation">
              <li>
                <div className="block-btns">
                  <button
                    type="button"
                    className="btns btns-back me-3"
                    onClick={(e) => nextPage(2)}
                  >
                    {" "}
                    Back{" "}
                  </button>
                  <button
                    type="button"
                    className="btns"
                    onClick={(e) => nextPage(4)}
                  >
                    {" "}
                    Next <span></span>{" "}
                  </button>
                </div>
              </li>
            </ul>
          </div>
        )}
        {/* {state.pageNumber == 4 && (
          <div className="modal-content d-block">
            <h1>
              <span>Home Finding</span>
            </h1>

            <div className="image-view">
              {subscriptioncode == "ReloNavigator" ? (
                <img
                  src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/HelpSlides/7.png"
                  alt=""
                />
              ) : (
                <img
                  src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/HelpSlides/3.png"
                  alt=""
                />
              )}
              {subscriptioncode == "ReloNavigator" ? (
                <p>
                  Based on your research, you can add rentals by clicking on Add
                  Rental. You can always compare rentals by clicking on Compare
                  Rentals option. This will allow you to see a quick comparison
                  of the rentals. Percentages in the top right corner of the
                  rentals represents how well they match with your preferences.
                </p>
              ) : (
                <p>
                  Based on your preferences, ReloNavigator will show you
                  recommended rentals that can be compared to one another. If
                  you find any rentals in your own research, you can add them as
                  well. By clicking the more button at the bottom, you can learn
                  more about what that property offers.
                </p>
              )}
            </div>
            <ul className="pagenation">
              <li>
                <div className="block-btns">
                  <button
                    type="button"
                    className="btns btns-back me-3"
                    onClick={(e) => nextPage(3)}
                  >
                    {" "}
                    Back{" "}
                  </button>
                  <button
                    type="button"
                    className="btns"
                    onClick={(e) => nextPage(5)}
                  >
                    {" "}
                    Next <span></span>{" "}
                  </button>
                </div>
              </li>
            </ul>
          </div>
        )} */}

        {state.pageNumber == 4 && (
          <div className="modal-content d-block">
            <h1>
              <span>Checklist</span>
            </h1>

            <div className="image-view">
              <img
                src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/HelpSlides/5.png"
                alt=""
              />

              {/* <p>
                Predefined checklists are provided to help you with your
                relocation. You can add your own task to the checklist by
                clicking on +Task.
              </p> */}
              <p>
                Predefined checklists are provided to help you with your
                relocation. You can click on +Task to add your own task to the
                checklist and add reminders.
              </p>
            </div>

            <ul className="pagenation">
              <li>
                <div className="block-btns">
                  <button
                    type="button"
                    className="btns btns-back me-3"
                    onClick={(e) => nextPage(3)}
                  >
                    {" "}
                    Back{" "}
                  </button>
                  <button
                    type="button"
                    className="btns"
                    onClick={(e) => nextPage(5)}
                  >
                    {" "}
                    Next <span></span>{" "}
                  </button>
                </div>
              </li>
            </ul>
          </div>
        )}

        {state.pageNumber == 5 && (
          <div className="modal-content d-block">
            <h1>
              <span>Resources</span>
            </h1>

            <div className="image-view">
              <img
                src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/HelpSlides/4-1.png"
                alt=""
              />

              {/* <p>
                By Clicking on Resources, you will be directed to a page where
                you will be able to create your personalized Renters and Pet
                Resume.
              </p> */}
              <p>
                Visit ‘Resources’ section to find various resources that can
                help you during your relocation. Some of the resources include
                Moving Tips, Packing Recommendations, Creating Rental and Pet
                resumes and many more.
              </p>
            </div>
            <ul className="pagenation">
              <li>
                <div className="block-btns">
                  <button
                    type="button"
                    className="btns btns-back me-3"
                    onClick={(e) => nextPage(4)}
                  >
                    {" "}
                    Back{" "}
                  </button>

                  <button
                    type="button"
                    className="btns"
                    onClick={(e) => nextPage(6)}
                  >
                    {" "}
                    Next <span></span>{" "}
                  </button>
                </div>
              </li>
            </ul>
          </div>
        )}

        {state.pageNumber == 6 && (
          <div className="modal-content d-block">
            <h1>
              <span>Diversity & Inclusion</span>
            </h1>

            <div className="image-view">
              <img src="./assets/images/getstarted-D&I.png" alt="" />

              <p>
                Based on your preferences, ReloNavigator will provide you with
                comprehensive information related to Statewide equality laws,
                Religious centers in a location, Diversity Index In your
                neighborhood and many more.
              </p>
            </div>
            <ul className="pagenation">
              <li>
                <div className="block-btns">
                  <button
                    type="button"
                    className="btns btns-back me-3"
                    onClick={(e) => nextPage(5)}
                  >
                    {" "}
                    Back{" "}
                  </button>
                  {subscriptioncode == "ReloNavigator" ? (
                    <button
                      type="button"
                      className="btns"
                      onClick={(e) => closeModal()}
                    >
                      {" "}
                      DONE
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btns"
                      onClick={(e) => nextPage(7)}
                    >
                      {" "}
                      Next <span></span>{" "}
                    </button>
                  )}
                </div>
              </li>
            </ul>
          </div>
        )}

        {state.pageNumber == 7 && (
          <div className="modal-content d-block">
            <h1>
              <span>Explorer / Commander</span>
            </h1>

            <div className="image-view">
              <img
                src="https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/HelpSlides/6.png"
                alt=""
              />

              <p>
                Your consultant is always here to assist. You can view their
                calendar, schedule appointment and talk to them at any time.
              </p>
            </div>
            <ul className="pagenation">
              <li>
                <div className="block-btns">
                  <button
                    type="button"
                    className="btns btns-back me-3"
                    onClick={(e) => nextPage(6)}
                  >
                    {" "}
                    Back{" "}
                  </button>
                  <button
                    type="button"
                    className="btns"
                    onClick={(e) => closeModal()}
                  >
                    {" "}
                    Done
                  </button>
                </div>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default HelpModal;
