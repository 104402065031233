import React, { useState } from "react";
import { Modal } from "antd";
import ThirdParty from "../../../../services/thirdPartyService";

const Home = () => {
  function sendContactUs() {}

  const [state, setState] = useState({
    succesMsg: "",
    error: "",
    firstname: "",
    email: "",
    description: "",
    sessionObject: "",
    contactPopUp: false,
  });

  const howItWorksData = [
    {
      imageUrl: "/assets/images/svg/smartphone.svg",
      description:
        "Whether you want the freedom of relocating at your own pace or need some guidance - Pick your plan, and let your adventure begin.",
      cardFooter: "CUSTOMIZE YOUR PLAN",
    },
    {
      imageUrl: "/assets/images/svg/support.svg",
      description:
        " Trained and well-equipped consultants provide recommendations and assistance at all times to guide you throughout your journey.",
      cardFooter: "GUIDANCE & EXPERTISE",
    },
    {
      imageUrl: "/assets/images/svg/home-insurance.svg",
      description:
        "Home is much more than a building, and relocating is much more than a move. Together, we can get you settled and find your home.",
      cardFooter: "FIND YOUR ‘SUITE’ SPOT",
    },
  ];

  const navigatorListItems = [
    "Manage Preferences",
    "Area Information",
    "Schools & Ratings",
    "Neighborhood Details",
    "Home Finding",
    "Checklists",
    "",
    "",
  ];

  const navigatorPlusListItems = [
    <>
      <a className="p-0-fource cursorDefault">
        <i className="fa fa-long-arrow-left"></i>
      </a>
      <a className="cursorDefault">
        Everything in
        <b> NAVIGATOR </b>
      </a>
    </>,
    <a className="cursorDefault">Dedicated Personal Consultant</a>,
    <a className="cursorDefault">Virtual Assistance</a>,
    <a className="cursorDefault">Schedule Appointments</a>,
    <a className="cursorDefault">Message Center</a>,
    <a className="cursorDefault">High Touch In-Person Support</a>,
    <a className="cursorDefault">Chauffeured Tours</a>,
    <a className="cursorDefault">Concierge Services</a>,
  ];
  const sendContact = () => {
    setState({ ...state, contactPopUp: true });
  };
  // const inputHandler = (event) => {
  //   let field = event.target.name;
  //   let value = event.target.value;
  //   setState((prev) => ({
  //     ...prev,
  //     [field]: value,
  //   }));
  // };
  const inputHandler = (event) => {
    let inputVal = event.target.value;
    let inputName = event.target.name;
    let newState = { ...state };
    newState[inputName] = inputVal;
    if (inputVal !== "" && inputVal < 1) {
      $("#" + inputName).addClass("is-invalid");
    } else {
      $("#" + inputName).removeClass("is-invalid");
    }
    setState(newState);
  };
  const submitContactBtn = async () => {
    console.log("error1");
    let errorUpdate = 0;
    if (state.firstname.trim() == "") {
      console.log("errorname");
      $("#firstname").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#firstname").removeClass("is-invalid");
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.email)) {
      $("#email").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#email").removeClass("is-invalid");
    }
    if (state.description.trim() == "") {
      $("#description").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#description").removeClass("is-invalid");
    }
    if (errorUpdate == 0) {
      var data = {
        description: state.description,
        firstname: state.firstname,
        theContactus: true,
        FromEmail: state.email,
        subject: state.subject,
        email: "geetha.kunda@devrabbit.com",
        cc: state.email,
      };
      let sendSms = await ThirdParty.sendEmail(data);
      if (sendSms.response == true) {
        setState({
          ...state,
          succesMsg: "Mail has been sent Successfully!",
          succesMsgs: "",
          contactError: "",
          error: "",
          subject: "",
          description: "",
          firstname: "",
          lastname: "",
          email: "",
        });
      }
    }
  };
  const handleClose = () => {
    setState({
      ...state,
      contactPopUp: false,
      succesMsg: "",
      succesMsgs: "",
      contactError: "",
      error: "",
      subject: "",
      description: "",
      firstname: "",
      lastname: "",
      email: "",
    });
  };
  return (
    <div id="outer-container">
      {/* <Header /> */}
      <div className="mt-5 pt-4 clearfix"></div>
      <div className="new-ds-login-home-banner-text">
        <div className="text-center">
          <img
            src={"/assets/newImages/relo-b&W.png"}
            style={{ width: "70%", marginBottom: "10px" }}
          />
        </div>
        <p>Your Companion to help with Relocation!</p>
      </div>
      <div className="dark_section-pricing-view pt-5 pb-5">
        <div className="container howitworks">
          <h2
            className="head1 text-center mb-5"
            style={{ fontFamily: "Poppins", fontWeight: "600" }}
          >
            How It Works
          </h2>
          <div className="row home-top-box-view">
            {howItWorksData.map((item, index) => {
              return (
                <div className="col-md-4 pd-t-90" key={index}>
                  <div className="card card_item">
                    <div className="halftop_img">
                      <img src={item.imageUrl} alt="" />
                    </div>
                    <p className="p-4">{item.description}</p>
                    <div className="card_footer pt-5 pb-5">
                      <h5>{item.cardFooter}</h5>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="container">
          <h2
            className="head1 text-center mb-2 mt-5"
            style={{
              fontFamily: "Poppins",
              fontWeight: "600",
              textTransform: "none",
            }}
          >
            Destination Services Management Platform
          </h2>
          <p style={{ textAlign: "center" }}>YOU SET THE COURSE!</p>
          <div className="row p-4 home_course text-center justify-content-center">
            <div className="col-md-4 ">
              <div className="item">
                <a href="/solo-landing">
                  <img src="/assets/images/svg/solo_course.svg" alt="" />
                  <h5>Navigator</h5>
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="item">
                <a href="/commander-landing">
                  <img src="/assets/images/svg/navigator_course.svg" alt="" />
                  <h5>Navigator+</h5>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="pricing pricing-another pricing-bg">
        <div>
          <h2 className="text-center py-5 ">
            Your Personal host, in your Pocket!{" "}
          </h2>
        </div>
        <div className=" pricing-views ">
          <div className="container">
            <div className="row row-pricing">
              <div className="col-lg-4 col-md-5 col-sm-5 item">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-body">
                    <h5 className="text-uppercase text-center">NAVIGATOR</h5>

                    <ul className="fa-ul">
                      {navigatorListItems.map((item, index) => {
                        return (
                          <li key={index}>
                            <a className="cursorDefault">{item}</a>
                          </li>
                        );
                      })}
                    </ul>
                    <div className="text-center">
                      <a
                        href="javascript:void(0)"
                        className="btn btn-block btn-primary text-uppercase"
                        onClick={(event) =>
                          sendContact(event, "Pricing for Solo")
                        }
                      >
                        {" "}
                        Contact us <span>For Pricing</span>{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-5 item">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-body">
                    <h5 className=" text-uppercase text-center">NAVIGATOR+</h5>

                    <ul className="fa-ul">
                      {navigatorPlusListItems.map((item, index) => {
                        return <li key={index}>{item}</li>;
                      })}
                    </ul>
                    <div className="text-center">
                      <a
                        href="javascript:void(0)"
                        className="btn btn-block btn-primary text-uppercase"
                        onClick={(event) =>
                          sendContact(event, "Pricing for Navigator")
                        }
                      >
                        {" "}
                        Contact us <span>For Pricing</span>{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="dark_section-pricing-view py-3">
        <div className="container iphoneX-view">
          <div className="item">
            <h2>Coming in Q1 2022</h2>
            <h1>AI empowered Mobile App.</h1>
          </div>
          <div className="item">
            <img src="/assets/images/iphone-x-view.png" alt="" />
          </div>
        </div>
      </div>

      {/* modal */}
      {/* <div
        id="contact-us-pricing"
        className="modal fade new_modal_pop"
        role="dialog"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="width-80per pd-y-50">
              <div className="only_close">
                <span className="pointer close" data-dismiss="modal">
                  ×
                </span>
              </div>
              <h6></h6>
              <div className="focus-input-label mt-3 padding-x-50">
                {state.succesMsg != "" && (
                  <h6 className="alert alert-success">{state.succesMsg}</h6>
                )}
                {state.error != "" && (
                  <h6 className="alert alert-danger">{state.error}</h6>
                )}
                {state.succesMsg == "" && (
                  <form
                    method="post"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <h2> Contact Us for pricing </h2>
                    <div className="">
                      <div className="main-row">
                        <div className="item">
                          <div className="floating-label">
                            <input
                              placeholder="name"
                              type="text"
                              value={state.firstname}
                              className="form-control"
                              id="firstname"
                              onChange={() => inputHandler("firstname")}
                            />
                            <label> Name</label>
                          </div>
                        </div>
                      </div>
                      <div className="floating-label">
                        <input
                          placeholder="email"
                          type="text"
                          className="form-control"
                          id="email"
                          value={state.email}
                          onChange={() => inputHandler("email")}
                        />
                        <label>Email</label>
                      </div>
                      <div className="floating-label">
                        <textarea
                          className="form-control"
                          rows="6"
                          placeholder="Enter message"
                          value={state.description}
                          id="description"
                          onChange={() => inputHandler("description")}
                        />
                        <label>Message</label>
                      </div>
                    </div>
                    <div className="form-group text-center mt-5  mb-4">
                      <button
                        className="btn theme-btn"
                        type="button"
                        onClick={(event) => sendContactUs(event, "feedback")}
                      >
                        Submit <span></span>{" "}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Modal open={state.contactPopUp} footer={null} onCancel={handleClose}>
        <div id="contact-us-pricing" className="new_modal_pop " role="dialog">
          <div className="modal-body width-80per pd-y-50">
            <div className="focus-input-label mt-3 padding-x-50">
              {state.succesMsg != "" && (
                <h6 className="alert alert-success">{state.succesMsg}</h6>
              )}
              {state.error != "" && (
                <p className="alert alert-danger" style={{ color: "red" }}>
                  {state.error}
                </p>
              )}
              {state.succesMsg == "" && (
                <form
                  method="post"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <h6>
                    {state.moreInfo == true
                      ? "Contact us for more details"
                      : "Contact us for pricing"}
                  </h6>
                  <div className="">
                    <div className="main-row">
                      <div className="item">
                        <div className="floating-label">
                          <label>Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="name"
                            value={state.firstname}
                            // style={
                            //   state.error != ""
                            //     ? { border: "1px solid red" }
                            //     : {}
                            // }
                            name="firstname"
                            id="firstname"
                            onChange={(e) => inputHandler(e)}
                          />
                        </div>
                        <div className="floating-label">
                          <label>Email</label>
                          <input
                            className="form-control"
                            placeholder="email"
                            type="text"
                            // style={
                            //   state.error != ""
                            //     ? { border: "1px solid red" }
                            //     : {}
                            // }
                            name="email"
                            id="email"
                            value={state.email}
                            onChange={(e) => inputHandler(e)}
                          />
                        </div>
                        <div className="floating-label">
                          <label>Message</label>
                          <textarea
                            className="form-control"
                            // style={
                            //   state.error != ""
                            //     ? { border: "1px solid red" }
                            //     : {}
                            // }
                            rows="6"
                            placeholder="Enter message"
                            value={state.description}
                            name="description"
                            id="description"
                            onChange={(e) => inputHandler(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group text-center mt-5  mb-4">
                    <button
                      className="btn theme-btn"
                      type="button"
                      onClick={submitContactBtn}
                    >
                      Submit <span></span>
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Home;
