import React, { useState, useEffect } from "react";
import Header from "../header and footer/Header";
// import Footer from "../header and footer/Footer";
import Footer from "../../../common/footer/footerWithoutchat";
// import "../../DestinationServicesStatic/Dsprofile/Dsprofile.css";
// import "../../DestinationServicesStatic/getStarted/getStarted.css";
// import "../../DestinationServicesStatic/myProperties/myProperties.css";
// import "rc-slider/assets/index.css";
// import "rc-tooltip/assets/bootstrap.css";
// import "./Transferee.css";
import { Table, Spin, AutoComplete, Select } from "antd";
// import DatePicker from "react-datepicker";
// import { confirmAlert } from "react-confirm-alert";
// import Slider from "rc-slider";
import LogInStore from "../../../../services/thirdPartyService";
import ApiStore from "../../../../services/apiServices";
import QuestionConsultant from "./Modals/QuestionConsultant";
import AllStatus from "./Modals/AllStatus";
import AddTransferee from "./Modals/AddTransferee";
import { useNavigate } from "react-router-dom";
import TransfereeModal from "./Modals/TransfereeModal";

const TransfereesList = () => {
  const navigate = useNavigate();
  const [State, setState] = useState({
    filterTable: null,
    dataSource: [],
    columns: [
      {
        title: "Id",
        dataIndex: "userId",
        key: "userId",
        sorter: (a, b) => a.userId - b.userId,
      },
      {
        title: "Name",
        dataIndex: "name",
        key: Math.random(),
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text, record) => (
          <a
            onClick={() => onClickRow(record)}
            data-bs-toggle="modal"
            data-bs-target="#transferee-preferences-modal"
          >
            {" "}
            {text}
          </a>
        ),
      },
      {
        title: "Email",
        dataIndex: "EmailId",
        key: "EmailId",
        sorter: (a, b) => a.EmailId.localeCompare(b.EmailId),
      },
      {
        title: "Phone",
        dataIndex: "PhoneNumber",
        key: "PhoneNumber",
        sorter: (a, b) => a.PhoneNumber - b.PhoneNumber,
      },
      {
        title: "Type",
        dataIndex: "subscriptioncode",
        key: "subscriptioncode",
        sorter: (a, b) => a.subscriptioncode.localeCompare(b.subscriptioncode),
      },
      {
        title: "Total",
        dataIndex: "transfereehours",
        key: "transfereehours",
        sorter: (a, b) => {
          const extractHours = (str) => parseInt(str.split(" ")[0]);

          const hoursA = extractHours(a.transfereehours);
          const hoursB = extractHours(b.transfereehours);

          return hoursA - hoursB;
        },
      },
      {
        title: "Used",
        dataIndex: "transfereescheduledhours",
        key: "transfereescheduledhours",
        sorter: (a, b) => {
          const extractHours = (str) => parseInt(str.split(" ")[0]);

          const hoursA = extractHours(a.transfereescheduledhours);
          const hoursB = extractHours(b.transfereescheduledhours);

          return hoursA - hoursB;
        },
      },
      {
        title: "Action",
        dataIndex: "",
        key: "x",
        render: (text, record) => (
          <span>
            <a style={{ color: "black" }} onClick={() => onClickmore(record)}>
              <i className="fa fa-edit" aria-hidden="true"></i>
            </a>
            <a
              style={{ color: "black" }}
              onClick={() => onClickStatus(record)}
              data-bs-toggle="modal"
              data-bs-target="#all-Status"
            >
              <i className="fa fa-bell"></i>
            </a>
          </span>
        ),
      },
    ],
    answersPrefer: [],
    answersPreferLoading: false,
    QuestionPop: false,
    keyWordsArray: [],
    activitiesArray: [],
    listOfProperties: [],
    questions: [],
    TransfereeStatus: [],
    getQuestionListStatus: false,
    allStatus: false,
    allstatuslist: [],
    addTransfereeModal: false,
    loader: false,
    messageShow: false,
  });

  useEffect(() => {
    GetStatusList();
    getTransferee();
  }, []);
  async function GetStatusList() {
    const GetStatusList = await ApiStore.GetStatusList();

    if (GetStatusList.error == 0) {
      GetStatusList.message.noteslist.forEach((valueObject) => {
        if (valueObject.identifier == "Registration email sent")
          valueObject.orderId = 1;
        if (valueObject.identifier == "Transferee Registered successfully")
          valueObject.orderId = 2;
        if (valueObject.identifier == "Transferee logged in for the first time")
          valueObject.orderId = 3;
        if (valueObject.identifier == "Updated Home Preferences")
          valueObject.orderId = 4;
        if (valueObject.identifier == "Utilized digital checklists")
          valueObject.orderId = 5;
        if (valueObject.identifier == "Scheduled appointments with consultant")
          valueObject.orderId = 6;
      });

      let SortStatus = GetStatusList.message.noteslist.sort((o) => {
        return parseInt(o.orderId, 10);
      });
      updateState({ allstatuslist: SortStatus });
    }
  }

  const onClickStatus = async (record) => {
    updateState({ loader: true });
    let data = {
      transfereeuid: record.userUniqueId,
    };
    const GetTransfereeStatus = await ApiStore.GetTransfereeStatus(data);

    if (GetTransfereeStatus.error == 0) {
      updateState({
        loader: false,
        TransfereeStatus: GetTransfereeStatus.message.noteslist,
        allStatus: true,
      });
    }
  };

  async function getTransferee() {
    updateState({ loader: true });
    let getsession = await LogInStore.getLoginInfo();

    let getTransfereeObj = {
      consultantuniqueid: getsession.userUniqueId,
    };
    let TransfereesList_Result = await ApiStore.GetConsultantTransferees(
      getTransfereeObj
    );

    let dataSource = State.dataSource;
    if (TransfereesList_Result.error == 0) {
      let transferees = TransfereesList_Result.message.transfereeslist;
      // let transferees = []
      if (transferees.length > 0) {
        transferees.forEach((each) => {
          if (each.userFirstName != "") {
            each.key = each.userId;
            each.name = each.userFirstName + " " + each.userLastName;
            each.transfereescheduledhours =
              each.transfereescheduledhours + " Hrs";
            each.transfereehours = each.transfereehours + " Hrs";
            if (each.userFirstName != "v5QCdDCaM9UdpHurW0z8nw==")
              dataSource.push(each);
          }
        });

        const uniqueArray = dataSource.filter((thing, index) => {
          const _thing = JSON.stringify(thing);
          return (
            index ===
            dataSource.findIndex((obj) => {
              return JSON.stringify(obj) === _thing;
            })
          );
        });
        updateState({
          dataSource: _.orderBy(uniqueArray, ["userId"], ["desc"]),
          loader: false,
        });
      } else {
        updateState({ messageShow: true ,loader: false,});
      }
    }
  }

  async function onClickRow(record) {
    getUserPreferences(record.userUniqueId);
    // updateState({
    //   answersPrefer: [],
    //   answersPreferLoading: false,
    //   QuestionPop: true,
    // });

    // let getQuestionList = await ApiStore.GetPreferencesList();
    // let userPreferanceObject = {
    //   transfereeuniqeuid: record.userUniqueId,
    // };
    // let userSavedPreferances = await ApiStore.GetTransfereePreferences(
    //   userPreferanceObject
    // );

    // if (userSavedPreferances.error == 0) {
    //   let userAnswerPreferanceObject = userSavedPreferances.message.preferences;

    //   updateState({
    //     answersPrefer: userAnswerPreferanceObject,
    //     answersPreferLoading: true,
    //   });

    //   if (getQuestionList.message.preferencesList != undefined) {
    //     let questionList = getQuestionList.message.preferencesList;
    //     let listOfPropertiesArray = questionList.filter((o) => {
    //       o.type == "DragOptions";
    //     });
    //     let workAddressArray = questionList.filter(
    //       (each) => each.question == "Work address"
    //     );
    //     let keyWordsArray = questionList.filter(
    //       (each) => each.questionlabel == "KEYWORDS"
    //     );
    //     let activitiesArray = questionList.filter(
    //       (each) => each.questionlabel == "ACTIVITIES"
    //     );

    //     listOfPropertiesArray[0].AnswerObject =
    //       userAnswerPreferanceObject[listOfPropertiesArray[0].preferenceUId];
    //     var questionResult = chunkArray(questionList, 2);
    //     updateState({
    //       keyWordsArray: keyWordsArray,
    //       activitiesArray: activitiesArray,
    //       listOfProperties: listOfPropertiesArray,
    //       questions: questionResult,
    //       getQuestionListStatus: true,
    //     });
    //   }
    // }
  }

  async function getUserPreferences(userUniqueId) {
    updateLoaderState("start");
    let userPreferanceObject = {
      transfereeuniqeuid: userUniqueId,
      istemporary: false,
    };

    try {
      let userSavedPreferances = await ApiStore.GetTransfereePreferences(
        userPreferanceObject
      );
      if (userSavedPreferances.error == 0) {
        let userAnswerPreferanceObject =
          userSavedPreferances.message.preferences;
        console.log(userAnswerPreferanceObject, "userAnswerPreferanceObject");

        updateState({
          answersPrefer: userAnswerPreferanceObject,
        });
      }
    } catch (e) {
      console.log(e);
    }
    updateLoaderState("stop");
  }

  function updateLoaderState(action) {
    if (action === "start") {
      updateState({ answersPreferLoading: true });
    } else if (action === "stop") {
      updateState({ answersPreferLoading: false });
    }
  }

  const chunkArray = (myArray, chunk_size) => {
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];
    var myChunk = [];
    for (index = 0; index < arrayLength; index += chunk_size) {
      myChunk = myArray.slice(index, index + chunk_size);
      tempArray.push(myChunk);
    }
    return tempArray;
  };

  const handleSearch = (value) => {
    const { dataSource } = State;
    const filterTable = dataSource.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    updateState({ filterTable });
  };

  async function onClickmore(row) {
    console.log(row, "row of the data");
    await localStorage.setItem("moredetails", JSON.stringify(row));
    navigate("/more-details", { state: { row } });
    // window.open("/more-details", "myWindow", { state: { row } });
  }

  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  return (
    <div>
      {State.loader ? <div className="loading">Loading</div> : ""}
      <div className="main_content trans-anttable">
        <div className="container">
          <div className="table-top-input-btn">
            <AutoComplete
              style={{ marginTop: "10px", marginBottom: "10px", width: 400 }}
              onSearch={handleSearch}
              placeholder="Search by..."
            />
            <span className="cursor add-list">
              <button
                type="submit"
                data-bs-toggle="modal"
                data-bs-target="#add-transferee-modal"
                className="btn theme-btn"
              >
                Add Transferee{" "}
                <i className="fa fa-plus-square-o" aria-hidden="true"></i>{" "}
              </button>{" "}
            </span>
          </div>

          {State.dataSource.length > 0 ? (
            <Table
              dataSource={
                State.filterTable == null ? State.dataSource : State.filterTable
              }
              columns={State.columns}
              pagination={{
                defaultPageSize: 20,
                pageSizeOptions: ["20", "25", "30"],
                showSizeChanger: true,
                locale: { items_per_page: "" },
              }}
            />
          ) : (
            // <div className="loading">Loading</div>
            // <div>No records found</div>
            ""
          )}
          {State.messageShow && <div>No records found</div>}
        </div>
      </div>

      <Footer />
      <QuestionConsultant state={State} updateState={updateState} />

      <AddTransferee
        State={State}
        setState={setState}
        getTransferee={getTransferee}
      />
      <TransfereeModal State={State} />
    </div>
  );
};

export default TransfereesList;
