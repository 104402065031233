import React, { useState, useEffect } from "react";
import moment from "moment";
import { Spin } from "antd";
// import * as $ from "jquery";
import axios from "axios";
import Select from "react-select";
import ApiServices from "../../../../../services/apiServices";
import LoginStore from "../../../../../services/thirdPartyService";
const AddProperty = (props) => {
  const { getMyProperties } = props;
  const intialState = {
    checkExceed: false,
    uploadLoader: false,
    loader: false,
    anywayProceed: false,
    transfereeuniqueId: "",
    apiId: "",
    propertyanalyticsuniqueId: "",
    source: "SA_Properties",
    city: "",
    propertyName: "",
    address: "",
    state: "",
    latitude: "",
    longitude: "",
    propertylink: "",
    propertyLink: "",
    notes: "",
    propertyNotes: "",
    uploadImages: [],
    hometype: "",
    monthlyrent: "",
    isIncluded: false,
    property_image: "",
    available_till_date: "",
    bathrooms: "",
    bedrooms: "",
    issharedliving: false,
    showProceedBtn: false,
    EditMoveDate: null,
    availableTillDate: moment().format("YYYY/MM/DD"),
    myPropertiesList: [],
    propertyanalyticsuniqueId: "",
    supplieruid: "",
    consultantuid: "",
    propertyuniqueId: "",
    isDelete: false,
    amenities: "",
    filterTable: null,
    dataSource: [],
    managementcompany: "",
    amenitityList: "",
    showfortransferee: false,
    ShowSuccess: false,
    successMessage: "",
    uploadImageError: "",
    imageError: false,
  };
  const [state, setState] = useState(intialState);

  useEffect(() => {
    supplier();
    getMyProperties();
  }, []);

  async function supplier() {
    let userInfo = await LoginStore.getLoginInfo({});
    await updateState({
      supplieruid: userInfo.supplieruid,
      consultantuid: userInfo.userUniqueId,
    });
  }

  async function setLocation(Id, e) {
    if (e.target.value.trim() == null || e.target.value.trim() == "") {
      $("#" + Id).addClass("is-invalid");
    } else {
      $("#" + Id).removeClass("is-invalid");
    }
    let LocationObje = {};
    LocationObje[Id] = e.target.value;
    updateState(LocationObje);
    var places = await new google.maps.places.Autocomplete(
      document.getElementById(Id),
      { types: ["address"] }
    );
    google.maps.event.addListener(places, "place_changed", async () => {
      var place = places.getPlace();
      var placeDetails = {
        locality: "long_name",
        administrative_area_level_1: "short_name",
        country: "long_name",
      };
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (placeDetails[addressType]) {
          var val = place.address_components[i][placeDetails[addressType]];
          placeDetails[addressType] = val;
        }
      }
      let relocatedAddress = place.formatted_address;

      await updateState({
        address: relocatedAddress,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        state: placeDetails.administrative_area_level_1,
        city: placeDetails.locality,
      });
    });
  }

  async function handleChange(inputName, e) {
    const inputVal = e.target.value;
    const inputObject = { [inputName]: inputVal };
    if (inputName === "" && inputVal < 1 && inputVal !== "") {
      inputObject[inputName] = "";
      $("#" + inputName).addClass("is-invalid");
    } else if (!inputVal.trim()) {
      $("#" + inputName).addClass("is-invalid");
    } else {
      $("#" + inputName).removeClass("is-invalid");
    }
    updateState(inputObject);
  }

  async function updateState(values) {
    await setState((prev) => ({ ...prev, ...values }));
  }

  async function handleSelect(amenities) {
    if (amenities == null || amenities == "") {
      $("#amenities").addClass("is-invalid");
    } else {
      $("#amenities").removeClass("is-invalid");
    }

    updateState({ amenities: amenities });

    const datas = state.amenities.map((each) => {
      return each["value"];
    });

    updateState({ amenitityList: datas.toString() });
  }

  async function handleSingleSelect(singleVal) {
    if (singleVal == null || singleVal == "") {
      $("#managementcompany").addClass("is-invalid");
    } else {
      $("#managementcompany").removeClass("is-invalid");
    }
    updateState({ managementcompany: singleVal });
  }

  function imageChangeHandler(e) {
    let files = e.target.files;
    if (isFileLimitExceeded(e)) {
      return;
    }
    if (!isFileFormatSupported(e)) {
      return;
    }
    uploadImages(e);
  }

  function isFileLimitExceeded(event) {
    let files = event.target.files;
    if (files.length > 5) {
      event.target.value = null;
      updateState({
        uploadImageError: "Only 5 images can be uploaded at a time.",
        imageError: true,
      });
      return true;
    }
    return false;
  }

  function isFileFormatSupported(event) {
    const types = ["image/png", "image/jpeg", "image/gif"];
    const maxFileSize = 1 * 1024 * 1024; // 1MB in bytes
    let files = event.target.files;
    let err = "";
    for (let i = 0; i < files.length; i++) {
      if (types.every((type) => files[i].type !== type)) {
        err = "Only supported png, jpeg, gif files format supported.";
      } else if (files[i].size > maxFileSize) {
        err = `${files[i].name}: File size exceeds 1MB.`;
      }
    }
    if (err) {
      updateState({
        uploadImageError: err,
        imageError: true,
      });
      $("#propertyImage").addClass("is-invalid");
      return false;
    }
    return true;
  }

  function uploadImages(event) {
    let files = event.target.files;
    const data = new FormData();
    for (var x = 0; x < files.length; x++) {
      data.append("file", files[x]);
    }
    updateState({
      uploadImageError: "",
      uploadLoader: true,
      imageError: false,
    });
    axios
      .post(`/profileimagesInfo`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        let data = [...state.uploadImages];
        _.forEach(response.data, async (response) => {
          data.push(response);
        });
        updateState({
          uploadImages: data,
          // uploadImageError: "Successfully uploaded.",
          uploadLoader: false,
        });
      })
      .catch((error) => {
        updateState({
          uploadImageError: "something went wrong while uploading images",
          uploadLoader: false,
          imageError: true,
        });
      });
  }

  function removeimage(value, id) {
    console.log(value, "value");
    let list = Object.assign([], state.uploadImages);
    let arr = list.filter((item) => item !== value);
    console.log(arr, "98089");
    updateState({ uploadImages: arr });
  }
  function prepareUploadImagesString(obj) {
    let arr = [];
    obj.forEach((item) => {
      item.Location && arr.push(item.Location);
    });
    return arr.toString();
  }

  // DROP DOWN

  var dataSource1 = [
    "Has AC",
    "Business Center",
    "Carpeted",
    "Ceiling Fan",
    "Clubhouse",
    "Concierge",
    "Fireplace",
    "Fitness Center",
    "Gated Community",
    "Granite Counters",
    "Hardwood Floors",
    "Laundry Facility",
    "Non Smoking",
    "Parking / Garage",
    "Patio",
    "Pet Friendly",
    "Pool",
    "Renovated",
    "Spa",
    "Stainless Appliances",
    "Has Storage",
    "Walk-in Closet",
    "Washer / Dryer",
    "Playground / Tot Lot",
  ];
  const options = [];

  for (let i = 0; i < dataSource1.length; i++) {
    options.push({ value: dataSource1[i], label: dataSource1[i] });
  }
  const managementcompanylist = [];
  var managementcompanies = [
    "Greystar Real Estate Partners",
    "Lincoln Property Company",
    "Pinnacle",
    "FPI Management",
    "Winn Companies",
    "MAA",
    "Apartment Management Consultants LLC",
    "BH Management",
    "Equity Residential",
    "AvalonBay Communities Inc",
    "The Bozzuto Group",
    "Starwood Capital Group",
    "Prometheus Real Estate Group",
    "Owner",
  ];
  for (let i = 0; i < managementcompanies.length; i++) {
    managementcompanylist.push({
      value: managementcompanies[i],
      label: managementcompanies[i],
    });
  }

  async function addMyProperties(e) {
    e.preventDefault();

    let errorUpdate = 0;
    await updateState({
      loader: true,
      isPropertyError: false,
      propertySuccess: false,
    });
    if (state.propertyName.trim() == "" || state.propertyName == null) {
      $("#propertyName").addClass("is-invalid");
      await updateState({ loader: false });
      errorUpdate++;
    } else {
      $("#propertyName").removeClass("is-invalid");
    }
    // if (state.propertyLink.trim() == "" || state.propertyLink == null) {
    //   await updateState({ loader: false });
    //   $("#propertyLink").addClass("is-invalid");
    //   errorUpdate++;
    // } else {
    //   $("#propertyLink").removeClass("is-invalid");
    // }
    if (
      state.address.trim() == "" ||
      state.address == null ||
      state.state == "" ||
      state.city == "" ||
      state.latitude == "" ||
      state.longitude == ""
    ) {
      await updateState({ loader: false });
      $("#address").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#address").removeClass("is-invalid");
    }
    // if (state.propertyNotes == "" || state.propertyNotes == null) {
    //   await updateState({ loader: false });
    //   $("#propertyNotes").addClass("is-invalid");
    //   errorUpdate++;
    // } else {
    //   $("#propertyNotes").removeClass("is-invalid");
    // }
    // if (state.amenities == "" || state.amenities == null) {
    //   await updateState({ loader: false });
    //   $("#amenities").addClass("is-invalid");
    //   errorUpdate++;
    // } else {
    //   $("#amenities").removeClass("is-invalid");
    // }
    // if (state.managementcompany == "" || state.managementcompany == null) {
    //   await updateState({ loader: false });
    //   $("#managementcompany").addClass("is-invalid");
    //   errorUpdate++;
    // } else {
    //   $("#managementcompany").removeClass("is-invalid");
    // }

    if (errorUpdate == 0) {
      let propertyObject = {
        propertyuniqueId: "",
        apiId: Math.floor(1000000000000 + Math.random() * 9000000000000),
        transfereeuniqueId: "",
        source: state.source,
        city: state.city,
        propertyname: state.propertyName,
        address: state.address,
        state: state.state,
        latitude: state.latitude,
        longitude: state.longitude,
        propertylink: state.propertyLink,
        notes: state.propertyNotes,
        isincluded: false,
        detailspage_link: "",
        dsassignmentId: "",
        dspropertyId: "",
        propertyjson: "",
        amenities: state.amenitityList,
        monthlyrent: "",
        hometype: state.hometype,
        bedrooms: state.bedrooms,
        property_image: state.uploadImages.toString(),
        available_till_date: state.availableTillDate,
        supplieruid: state.supplieruid,
        createdby: state.consultantuid,
        modifiedby: state.supplieruid,
        issupplierproperty: true,
        isdelete: state.isDelete,
        isactive: state.isDelete,
        managementcompany: JSON.stringify(state.managementcompany["value"]),
        showfortransferee: state.showfortransferee,
        property_image: prepareUploadImagesString(state.uploadImages),
      };
      // console.log(propertyObject,'propertyObject');

      propertyObject.apiId = Math.floor(
        1000000000000 + Math.random() * 9000000000000
      );
      let addPropertyResult = await ApiServices.CreateConsultantProperties(
        propertyObject
      );

      if (addPropertyResult.error == 0) {
        $("document").ready(function () {
          $("#add-property-modal").find("input").val("").end();
        });

        await getMyProperties();
        updateState({
          ShowSuccess: true,
          checkExceed: false,
          loader: false,
          propertySuccess: true,
          propertyName: "",
          propertyLink: "",
          address: "",
          propertyNotes: "",
          uploadImages: [],
          availableTillDate: null,
          EditMoveDate: null,
          // successMessage: addPropertyResult.message.status,
          successMessage: "Property created successfully!",
        });
      } else {
        updateState({
          checkExceed: false,
          isPropertyError: true,
          loader: false,
          errorMessage: addPropertyResult.message,
        });
      }
    }
  }
  async function onClickClose() {
    updateState(intialState);
    $(document).ready(function () {
      // Clear input values
      $("#add-property-modal").find("input,select").val("");
      $("#propertyImage").removeClass("is-invalid");
      // Remove validation classes
      $("#add-property-modal").find("input,select").removeClass("is-invalid");
    });
  }

  return (
    <div
      className="modal fade "
      id="add-property-modal"
      tabIndex="-1"
      aria-labelledby="add-property-modal"
      aria-hidden="true"
      data-bs-backdrop="static"
      style={{ zIndex: 1060 }}
    >
      <div className="modal-dialog modal-lg modal-lg-2">
        <div className="modal-content ">
          <div className="width-80per pd-y-60">
            <div className="only_close">
              <span
                className="pointer close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => onClickClose()}
              >
                &times;
              </span>
            </div>
            {state.ShowSuccess ? (
              ""
            ) : (
              <h6> Please add the property details below </h6>
            )}
            <form
              method="post"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              {state.isPropertyError == true ? (
                <h2 className="alert alert-danger fs-5 text-center">
                  {state.errorMessage}
                </h2>
              ) : (
                ""
              )}

              {state.ShowSuccess ? (
                <h2 className="alert alert-success fs-5 text-center">
                  {state.successMessage}
                </h2>
              ) : (
                <div className="pop-form-controls focus-input-label mt-4">
                  <div className="floating-label">
                    <input
                      type="text"
                      className="form-control"
                      // placeholder="Name of property/community"
                      value={state.propertyName}
                      autoComplete="new-password"
                      id="propertyName"
                      onChange={(e) => handleChange("propertyName", e)}
                    />
                    <label>Name of property/community *</label>
                  </div>
                  <div className="floating-label">
                    <input
                      placeholder="Enter Location"
                      autoComplete="off"
                      className="form-control"
                      type="text"
                      value={state.address}
                      // autoComplete="new-password"
                      id="address"
                      onChange={(e) => setLocation("address", e)}
                    />
                    <label>Address *</label>
                  </div>
                  <div className="floating-label">
                    <input
                      className="form-control"
                      // placeholder="If you found a property online,paste the link here"
                      value={state.propertyLink}
                      autoComplete="off"
                      type="text"
                      id="propertyLink"
                      onChange={(e) => handleChange("propertyLink", e)}
                    />
                    <label>
                      If you found a property online, paste the link here
                    </label>
                  </div>

                  <label className="label_cls">Amenities</label>
                  <Select
                    id="amenities"
                    className="autoComplete mb-3"
                    placeholder="Select"
                    isMulti // Enable multiple selection
                    options={options}
                    value={state.amenities}
                    onChange={handleSelect}
                    closeMenuOnSelect={false}
                  ></Select>

                  <label className="label_cls">Management company</label>

                  <Select
                    id="managementcompany"
                    className="autoComplete mb-3"
                    placeholder="Select"
                    options={managementcompanylist}
                    value={state.managementcompany}
                    onChange={handleSingleSelect}
                  />

                  <label className="label_cls">Notes</label>
                  <input
                    type="text"
                    className="form-control mb-3"
                    id="propertyNotes"
                    value={state.propertyNotes}
                    autoComplete="off"
                    onChange={(e) => handleChange("propertyNotes", e)}
                  />

                  <div className="floating-label">
                    <label className="label_cls">Property Images</label>
                  </div>

                  <div className="custom-file">
                    {state.uploadLoader ? (
                      <Spin />
                    ) : (
                      <input
                        type="file"
                        onChange={(e) => imageChangeHandler(e)}
                        id="propertyImage"
                        className="form-control fileupload"
                        multiple
                        accept=".png, .jpeg, .jpg, .gif"
                      />
                    )}
                  </div>

                  <div className="custom-file-upimg_multy d-flex">
                    {state.uploadImages &&
                      state.uploadImages.map((row, index) => {
                        return (
                          <div key={index} className="custom-file-upimg">
                            <img
                              src={`${state.uploadImages[index].Location}`}
                            />
                            <i
                              className="fa fa-trash"
                              onClick={() => removeimage(row, index)}
                            ></i>
                          </div>
                        );
                      })}
                  </div>
                  {state.imageError && (
                    <span className="" style={{ color: "red" }}>
                      {state.uploadImageError}
                    </span>
                  )}

                  <div className="text-center mt-3 mb-3">
                    {state.loader || state.uploadLoader ? (
                      <Spin />
                    ) : (
                      <button
                        type="submit"
                        className="btn theme-btn"
                        onClick={(e) => addMyProperties(e)}
                      >
                        {" "}
                        Save
                        <span></span>{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProperty;
