import React from "react";

function RegisterPopUp() {
  const closeModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#myModalRegisteringEmbassy").modal("hide");
      });
    });
  };
  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header border-0">
          <div class="only_close">
            <span
              aria-hidden="true"
              className="pointer close"
              onClick={() => closeModal()}
              data-dismiss="modal"
            >
              ×
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div className="widthModal">
            <div className="item-view">
              <h2>Registering with an Embassy</h2>
              <p>
                Expat citizens are often advised to register with the embassy or
                consulate. Registering with the embassy is sometimes mandatory
                and offers several benefits. Swiss expats must always register
                with their local Swiss representation abroad.
              </p>
            </div>

            <div className="tails-row">
              <div className="tails">
                <div className="item-view">
                  <h2>Why register with the embassy or consulate? </h2>
                  <p>
                    Depending on the country you are from, registering with the
                    embassy might grant expats access to several services, such
                    as:
                  </p>

                  <ul>
                    <li>
                      Assistance in the event of an emergency (natural
                      disasters, dangerous situations in your country of
                      residence) or an accident abroad,
                    </li>
                    <li>
                      Information from the nearest embassy or consulate (travel
                      alerts and warnings, voting deadlines),
                    </li>
                    <li>
                      Easier completion of various administrative formalities
                      (passport issuance, registration of birth abroad, study
                      grant requests, registration to vote abroad, completion of
                      tax forms).
                    </li>
                  </ul>
                  {/* <p>Registration mostly is voluntary, expect for Swiss expats who must always register with their local Swiss representation abroad. </p> */}
                </div>
                {/* <div className="text-center"> */}

                {/* </div> */}
              </div>

              <div className="tails">
                <div className="item-view">
                  <h2>How to register with the embassy or consulate</h2>
                  <p>
                    You will complete a form and in some cases, you will need to
                    provide additional documentation to prove your identity,
                    nationality, and place of residence. Most embassies have
                    several options for registration, including post, email, or
                    fax, and some even have online registration. 
                  </p>
                  <p>
                    Some countries with online registration for expats include:{" "}
                  </p>
                  <ul>
                    <li>
                      USA -{" "}
                      <a target="_blank" href="https://step.state.gov/step/">
                        Smart Traveler Enrollment Program (STEP)
                      </a>{" "}
                    </li>
                    <li>
                      Ireland -{" "}
                      <a
                        target="_blank"
                        href="https://www.dfa.ie/travel/citizens-registration/"
                      >
                        Citizen’s Registration
                      </a>{" "}
                    </li>
                    <li>
                      Singapore -{" "}
                      <a
                        target="_blank"
                        href="https://eregister.mfa.gov.sg/eregisterportal/common/preLoginEregisterView.action"
                      >
                        eRegister
                      </a>
                    </li>
                  </ul>
                  <p>
                    After registering with your embassy or consulate, you will
                    need to update your information with them if:
                  </p>
                  <ul>
                    <li>your contact details change,</li>
                    <li>your civil status changes,</li>
                    <li>you are going back to your home country.</li>
                  </ul>
                </div>
              </div>
            </div>

            <p>
              Source:&nbsp;
              <a
                target="_blank"
                href="https://fr.april-international.com/en/expat-life/settling-abroad-registering-embassy-or-consulate#:~:text=Nevertheless%2C%20expat%20citizens%20of%20many,registration%20usually%20offers%20several%20benefits"
              >
                fr.april-international.com
              </a>{" "}
              & &nbsp;
              <a
                target="_blank"
                href="https://goexpat.com/expat-resources/embassy-information-for-expats/"
              >
                goexpat.com
              </a>
            </p>
          </div>
        </div>
        {/* <div className="modal-footer"></div> */}
      </div>
    </div>
  );
}

export default RegisterPopUp;
