import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import { Table, Spin } from "antd";
import DatePicker from "react-datepicker";
import LoginStore from "../../../../services/thirdPartyService";
import ApiStore from "../../../../services/apiServices";
import * as $ from "jquery";
import Header from "../header and footer/Header";
import { CSVLink } from "react-csv";
import "./reports.css";
// import Footer from "../header and footer/Footer";
import Footer from "../../../common/footer/footerWithoutchat";
const Reports = () => {
  const intialState = {
    startDate: null,
    endDate: null,
    Company: "",
    Servicelevel: "",
    dateError: { startDate: "", endDate: "" },
    reportslist: [],
    supplierid: "",
    loader: false,
    companydata: [],
    columns: [
      // {
      //   title: "Consultant Email",
      //   dataIndex: "consultantemail",
      //   key: Math.random(),
      // },
      {
        title: "Transferee Email",
        dataIndex: "transfereeemail",
        key: "transfereeemail",
        sorter: (a, b) => a.transfereeemail.localeCompare(b.transfereeemail),
        columnTitle: "Transferee Email",
      },
      {
        title: "Service level	",
        dataIndex: "servicelevel",
        key: "service level",
        sorter: (a, b) => a.servicelevel.localeCompare(b.servicelevel),
      },
      {
        title: "Sign up date",
        dataIndex: "signupdate",
        key: "signupdate",
        render: (signupdate) => {
          return <span>{moment(signupdate).format("MM/DD/YYYY")}</span>;
        },
        sorter: (a, b) => {
          const dateA = new Date(a.signupdate);
          const dateB = new Date(b.signupdate);
          if (dateA < dateB) {
            return -1;
          } else if (dateA > dateB) {
            return 1;
          } else return 0;
        },
      },
      {
        title: "Company",
        dataIndex: "company",
        key: "company",
        sorter: (a, b) => a.company.localeCompare(b.company),
      },
      {
        title: "Access from",
        dataIndex: "accessfrom",
        key: "access from",
        sorter: (a, b) => {
          const dateA = new Date(a.accessfrom);
          const dateB = new Date(b.accessfrom);
          if (dateA < dateB) {
            return -1;
          } else if (dateA > dateB) {
            return 1;
          } else return 0;
        },
      },
      {
        title: "Access to",
        dataIndex: "accessto",
        key: "access to",
        sorter: (a, b) => {
          const dateA = new Date(a.accessto);
          const dateB = new Date(b.accessto);
          if (dateA < dateB) {
            return -1;
          } else if (dateA > dateB) {
            return 1;
          } else return 0;
        },
      },
    ],
  };

  const [state, setState] = useState(intialState);

  async function handleChange(name, e) {
    let value = e.target.value;
    let object = {};
    object[name] = value;
    updateState(object);

    if (value.trim() == null || value.trim() == "") {
      $("#" + name).addClass("is-invalid");
    } else {
      $("#" + name).removeClass("is-invalid");
    }
  }

  // function handleDateChange(e, date) {
  //   let object = {};
  //   object[date] = e;
  //   if (date == "startDate") {
  //     updateState(object);
  //   } else {
  //     updateState(object);
  //   }
  // }
  function handleDateChange(e, date) {
    let object = {};
    object[date] = e;

    if (date === "startDate") {
      if (!e) {
        setState((prevState) => ({
          ...prevState,
          ...object,
          dateError: {
            ...prevState.dateError,
            startDate: "From date is required",
          },
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          ...object,
          dateError: {
            ...prevState.dateError,
            startDate: "",
          },
        }));
      }
    } else {
      if (!e) {
        setState((prevState) => ({
          ...prevState,
          ...object,
          dateError: {
            ...prevState.dateError,
            endDate: "To date is required",
          },
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          ...object,
          dateError: {
            ...prevState.dateError,
            endDate: "",
          },
        }));
      }
    }
  }

  async function updateState(values) {
    await setState((prev) => ({ ...prev, ...values }));
  }

  useEffect(() => {
    details();
  }, []);

  async function details() {
    let getSessionData = await LoginStore.getLoginInfo({});
    await updateState({
      supplierid: getSessionData.supplieruid,
      consultantuniqueId: getSessionData.userUniqueId,
    });

    if (getSessionData.supplieruid) {
      let getTransfereeObj = {
        supplieruid: getSessionData.supplieruid,
      };
      let ClientProfile_Result = await ApiStore.GetAllClientProfile(
        getTransfereeObj
      );
      let mainn = ClientProfile_Result.message.clientProfileList;
      var newArray = mainn.sort(function (first, second) {
        var a = first.companyname.toLowerCase();
        var b = second.companyname.toLowerCase();
        if (a > b) {
          return 1;
        } else if (a < b) {
          return -1;
        } else {
          return 0;
        }
      });
      await updateState({ companydata: newArray });
    }
  }

  async function onSubmit(e) {
    e.preventDefault();
    let errorUpdate = 0;
    if (state.startDate == null) {
      $("#startdate").addClass("is-invalid");
      setState((prevState) => ({
        ...prevState,
        dateError: {
          ...prevState.dateError,
          startDate: "From date is required",
        },
      }));
      errorUpdate++;
    } else {
      $("#startdate").removeClass("is-invalid");
    }
    if (state.endDate == null) {
      $("#enddate").addClass("is-invalid");
      setState((prevState) => ({
        ...prevState,
        dateError: {
          ...prevState.dateError,
          endDate: "To date is required",
        },
      }));
      errorUpdate++;
    } else {
      $("#enddate").removeClass("is-invalid");
    }

    if (errorUpdate == 0) {
      let data = {
        supplieruniqueId: state.supplierid,
        // supplieruniqueId: "89ee285f-0c2c-4359-b464-f7512d4e4239", // change
        fromdate: moment(state.startDate).format("MM/DD/YYYY"),
        todate: moment(state.endDate).format("MM/DD/YYYY"),
        servicelevel: state.Servicelevel,
        company: state.Company,
      };
      await updateState({ loader: true });
      const GetSupplierSignupReport = await ApiStore.GetSupplierSignupReport(
        data
      );
      console.log("GetSupplierSignupReport", GetSupplierSignupReport);
      if (GetSupplierSignupReport.error == 0) {
        let SignUpReports = GetSupplierSignupReport.signupreportlist;
        SignUpReports = SignUpReports.sort(
          (a, b) =>
            new Date(b.signupdate).getTime() - new Date(a.signupdate).getTime()
        );
        SignUpReports.map(function (item) {
          item.accessfrom = moment(item.accessfrom).format("MM/DD/YYYY");
          item.accessto = moment(item.accessto).format("MM/DD/YYYY");
          item.signupdate = moment(item.signupdate).format("MM/DD/YYYY HH:MM");
          return item;
        });
        await updateState({ reportslist: SignUpReports, loader: false });
      } else {
        await updateState({ loader: false });
      }
    }
  }
  return (
    <div>
      {/* <Header /> */}
      <div className="consultant-report-views">
        <div className="container">
          <div className="consultant-report-views-inputvalues">
            <div className="heaidng-view">
              <h1>SignUp Report</h1>
              {state.reportslist.length > 0 && (
                <CSVLink
                  filename={"SignupReport.csv"}
                  data={state.reportslist}
                  className="btn"
                >
                  Export CSV
                </CSVLink>
              )}
              {/* <button type="button" className="btn">Export CSV</button> */}
            </div>

            <form className="consultant-reports-data-select">
              <div className="row">
                <div className="col-md-10">
                  <div className="form-row row">
                    <div className="form-group col-md-3">
                      <label>From: </label>

                      <div
                        id="datepickera"
                        className="input-group date"
                        data-date-format="mm-dd-yyyy"
                      >
                        <DatePicker
                          id="startdate"
                          dateFormat="MM/dd/yyyy"
                          selected={state.startDate}
                          selectsStart
                          // minDate={new Date()}
                          maxDate={state.endDate}
                          onChange={(e) => handleDateChange(e, "startDate")}
                          placeholderText="Select start date ..."
                          className="form-control"
                          autoComplete="off"
                        />
                      </div>
                      {state.dateError.startDate && (
                        <div className="text-danger">
                          {state.dateError.startDate}
                        </div>
                      )}
                    </div>
                    <div className="form-group col-md-3">
                      <label>To: </label>
                      <div
                        id="datepicker"
                        className="input-group date"
                        data-date-format="mm-dd-yyyy"
                      >
                        <DatePicker
                          id="enddate"
                          dateFormat="MM/dd/yyyy"
                          selected={state.endDate}
                          selectsEnd
                          autoComplete="off"
                          startDate={state.startDate}
                          endDate={state.endDate}
                          onChange={(e) => handleDateChange(e, "endDate")}
                          minDate={state.startDate}
                          placeholderText="Select end date ..."
                          className="form-control"
                        />
                      </div>
                      {state.dateError.endDate && (
                        <div className="text-danger">
                          {state.dateError.endDate}
                        </div>
                      )}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="inputStatea">Company</label>
                      <select
                        value={state.Company}
                        className="form-control"
                        id="Company"
                        onChange={(e) => handleChange("Company", e)}
                      >
                        <option value="">Choose...</option>
                        {state.companydata.map((row, index) => {
                          return (
                            <option key={index} value={row.companyname}>
                              {row.companyname}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="form-group col-md-3">
                      <label htmlFor="inputState">Service level</label>
                      <select
                        value={state.Servicelevel}
                        className="form-control"
                        id="Servicelevel"
                        onChange={(e) => handleChange("Servicelevel", e)}
                      >
                        <option value="">Choose...</option>
                        <option value={"ReloNavigator"}>ReloNavigator</option>
                        <option value={"Explorer"}>Explorer</option>
                        <option value={"Commander"}>Commander</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <button
                    type="button"
                    onClick={(e) => onSubmit(e)}
                    className="btn"
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="form-row row"></div>
            </form>
          </div>
          {state.loader == false ? (
            <div className="reports supply-table">
              <div className="table-responsive">
                <Table
                  dataSource={state.reportslist}
                  columns={state.columns}
                  pagination={{
                    defaultPageSize: 20,
                    locale: { items_per_page: "" },
                  }}
                />
              </div>
            </div>
          ) : (
            <Spin />
          )}
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Reports;
