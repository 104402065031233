import React from "react";
import { Link, useLocation } from "react-router-dom";
import * as $ from "jquery";
import { useState, useEffect } from "react";
import MessageCenterNew from "../../pages/Transferee/dsProfile/dsMessageCenter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoginStore from "../../../services/thirdPartyService";
import ApiServices from "../../../services/apiServices";
import { faPaperPlane, faTimes } from "@fortawesome/free-solid-svg-icons";
//import AppController from "../controllers/appController";
import moment from "moment-timezone";
//import ThirdParty from "../../services/thirdPartyService";
import FeedBackPopUp from "./feedBackPopUp";
// import "../../public/assets/css/JquerySignalR";
// import "../../public/assets/css/jquery.signalR-2.4.1";
import { Spin, Popover, Select, Radio, Modal } from "antd";

var SignalrConnection;
var ChatProxy;
var sessionData = {};
var chatItineraryPropertyID = "";
var propertyChat = [];

const Footer = (props) => {
  var _isMounted = false;
  const [state, setState] = useState({
    popUpType: "",
    feedBackPopUp: false,
    openPropetyChatList: false,
    openChatBox: false,
    ChartLitst: [],
    ChatProperty: {},
    sessionData: {},
    chatItineraryPropertyID: 0,
    TextMessage: "",
    propertyChat: [],
    openChatboxPopup: false,
    chatMessage: [],
    chatIdSignalR: "",
    getSessionData: "",
    FromEmail: "",
    transfereeProperties: [],
    responseTransfreeProperties: [],
    totalTransfereeProperties: [],
    countUnread: [],
    isPropertySelected: false,
    TotalUnreadCount: 0,
    countReadSort: true,
    showChatMsg: false,
    generalProperty: {
      id: "0000111122223333",
      apiId: "0000111122223333",
      propertyanalyticsuniqueId: "0000111122223333",
      property_image: "assets/images/default-img.jpg",
      address: "General",
      countUnread: 0,
    },
    profilePic: "",
    openLoader: false,
    PropertiesSortDate: [],
    popoverText: "",
    displayname: "",

    feedbacktype: "General feedback",
    experience: "",
    feeddescription: "",
  });
  const location = useLocation();
  const isContactUsRoute = location.pathname === "/contactus";
  const isHomeRoute = location.pathname === "/home";
  const pricing = location.pathname === "/pricing";
  const empty = location.pathname === "/";

  const feedBack = async (type) => {
    console.log(type, "type of the feedback ");
    if (type == "feedback") {
      $("document").ready(function () {
        $("#contact-us-modal-feedback").modal("show");
      });
      setState((prev) => ({
        ...prev,
        popUpType: type,
        feedBackPopUp: true,
        succesMsg: "",
        contactError: "",
        subject: "",
        description: "",
        firstname: "",
        lastname: "",
        phoneNumber: "",
        FromEmail: "",
      }));
    } else if (type == "policy") {
      $(function () {
        $("document").ready(async function () {
          $("#ModalTersm-of-use2").modal("show");
        });
      });
      setState((prev) => ({
        ...prev,
        popUpType: type,
        feedBackPopUp: true,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        popUpType: type,
        feedBackPopUp: true,
      }));
      $(function () {
        $("document").ready(async function () {
          $("#ModalTersm-of-use").modal("show");
        });
      });
    }
  };
  function deepCopy(source) {
    return JSON.parse(JSON.stringify(source));
  }
  function changeLoaderState(mode) {
    if (mode === "start") {
      updateState({ loader: true });
    } else {
      updateState({ loader: false });
    }
  }
  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  async function getSessionData() {
    try {
      let sessionData = await LoginStore.getLoginInfo({});
      sessionData.subscriptioncode =
        localStorage.getItem("loginSubscribeDetails") != null
          ? JSON.parse(localStorage.getItem("loginSubscribeDetails"))
              .subscriptioncode
          : "";
      return sessionData;
    } catch (err) {
      console.log(err);
    }
  }

  function getUreadCount(AppId, TransfereeId) {
    let countUnread = state.countUnread.filter(function (o) {
      return (
        o.APISource == AppId &&
        o.FromId == state.sessionData.consultantUId &&
        o.ToId == TransfereeId
      );
    });
    return countUnread.length > 0 ? countUnread[0].Count : 0;
  }

  async function getData(transfereeProperties) {
    let ZillowBanglow = transfereeProperties.filter(function (element) {
      return element.source != "my_property34" && element.propertyname;
    });

    updateState({
      responseTransfreeProperties: ZillowBanglow,
      totalTransfereeProperties: ZillowBanglow,
    });

    let generalCategory = { ...state.generalProperty };
    generalCategory.countUnread = getUreadCount(
      generalCategory.id,
      state.sessionData.userUniqueId
    );

    updateState({ generalProperty: generalCategory });

    localStorage.setItem(
      "responseTransfreeProperties",
      JSON.stringify(ZillowBanglow)
    );
  }
  function backChatBox() {
    $("#left1").removeClass("chat-left-md-view");
    $("#right1").addClass("chat-right-md-view");
  }
  async function getChatPropertiesList() {
    changeLoaderState("start");

    try {
      sessionData = await getSessionData();

      // sessionData = deepCopy(sessionData);

      updateState({ sessionData: { ...sessionData } });

      let GetTransfereePropertiesObject = {
        transfereeuniqueId: sessionData.userUniqueId,
      };
      let transfereeProperties = await ApiServices.GetTransfereeProperties(
        GetTransfereePropertiesObject
      );
      let transfereepropertyList =
        transfereeProperties.message.transfereepropertyList;

      let myPropertiesList = transfereepropertyList.filter(function (element) {
        return (
          element.source != "zillow" &&
          element.isinterested == true &&
          element.isactive == true &&
          element.apiId != ""
        );
      });
      if (transfereeProperties.error == 0) {
        if (sessionData.subscriptioncode != "ReloNavigator") {
          updateState({ transfereeProperties: myPropertiesList });
        }
      }
      getData(myPropertiesList);
    } catch (err) {
      console.log(err);
    }
  }

  // --------------------------------

  async function closeChatList() {
    $("body").removeClass("modal-open");

    await setState((prev) => ({
      ...prev,
      openPropetyChatList: false,
      openChatBox: false,
      openChatboxPopup: false,
      chatItineraryPropertyID: 0,
      isPropertySelected: false,
    }));
  }

  function componentDidMountLogic() {
    let subscriptioncode =
      localStorage.getItem("loginSubscribeDetails") != null
        ? JSON.parse(localStorage.getItem("loginSubscribeDetails"))
            .subscriptioncode
        : "";

    if (subscriptioncode != "ReloNavigator") {
      makeConnectionSignalR();
    }
  }

  async function logoutFromChat() {
    let changeSession = localStorage.getItem("ChatSession");

    if (changeSession != null && changeSession != undefined) {
      let responseText = await ChatProxy.invoke("LogoutUserChatt", {
        userId: changeSession,
      });
      console.log(responseText, "responseText");
      localStorage.removeItem("ChatSession");
      console.log(changeSession, "changeSession");
    }
  }

  function changeConsultantOnlineStatus(isOnline) {
    const onlineStatus = isOnline ? "Currently Online" : "Currently Offline";
    updateState({ popoverText: onlineStatus });

    const userElement = $("#online" + state.sessionData.consultantUId);
    if (isOnline) {
      userElement.removeClass("offline");
      userElement.addClass("online");
    } else {
      userElement.removeClass("online");
      userElement.addClass("offline");
    }
  }

  function processLogoutUsersData(onlineusers) {
    if (onlineusers && onlineusers.length) {
      localStorage.setItem("onlineusers", onlineusers);
      let onlineUserIndex = _.indexOf(
        onlineusers,
        state.sessionData.consultantUId
      );
      onlineUserIndex > -1
        ? changeConsultantOnlineStatus(true)
        : changeConsultantOnlineStatus(false);
    }
  }

  function isMessageForCurrentChat(row) {
    console.log(row.apiId, chatItineraryPropertyID, row.toId, sessionData);
    return (
      row.apiId == chatItineraryPropertyID &&
      row.toId == sessionData.userUniqueId
    );
  }

  function isMessageFromGeneralProperty(row) {
    return (
      row.apiId != chatItineraryPropertyID &&
      row.apiId == "0000111122223333" &&
      row.toId == sessionData.userUniqueId
    );
  }

  function updatePropertyWithCountUnread(row) {
    let responseTransfreeProperties = JSON.parse(
      localStorage.getItem("responseTransfreeProperties")
    );

    const updatedPropertiesList = responseTransfreeProperties.map(
      (property) => {
        if (property.propertyanalyticsuniqueId === row.apiId) {
          return {
            ...property,
            countUnread: property.countUnread ? property.countUnread + 1 : 1,
            updatedDate: moment()
              .tz("America/Los_Angeles")
              .format("MM/DD/YYYY HH:mm:ss"),
          };
        }
        return property;
      }
    );

    localStorage.setItem(
      "responseTransfreeProperties",
      JSON.stringify(updatedPropertiesList)
    );

    updateState({
      responseTransfreeProperties: updatedPropertiesList,
      TotalUnreadCount: state.TotalUnreadCount + 1,
    });
    $(".activeItem").removeClass("activeItem");
    $("#highlight" + state.chatItineraryPropertyID).addClass("activeItem");
  }

  function updateGeneralPropertyWithCountUnread() {
    let generalProperty = { ...state.generalProperty };

    generalProperty.countUnread = generalProperty.countUnread + 1;
    generalProperty.updatedDate = moment()
      .tz("America/Los_Angeles")
      .format("MM/DD/YYYY HH:mm:ss");

    updateState({
      generalProperty,
      TotalUnreadCount: state.TotalUnreadCount + 1,
    });

    $(".activeItem").removeClass("activeItem");
    $("#highlight" + state.chatItineraryPropertyID).addClass("activeItem");
  }

  function handleMessageFromCurrentChat(row) {
    let responseTransfreeProperties = JSON.parse(
      localStorage.getItem("responseTransfreeProperties")
    );

    const updatedPropertiesList = responseTransfreeProperties.map(
      (property) => {
        if (property.propertyanalyticsuniqueId === row.apiId) {
          return {
            ...property,
            updatedDate: moment()
              .tz("America/Los_Angeles")
              .format("MM/DD/YYYY HH:mm:ss"),
          };
        }
        return property;
      }
    );

    localStorage.setItem(
      "responseTransfreeProperties",
      JSON.stringify(updatedPropertiesList)
    );

    updateState({
      responseTransfreeProperties: updatedPropertiesList,
    });

    let ReadObj = {
      toId: state.sessionData.userUniqueId,
      fromId: state.sessionData.consultantUId,
      apiId: state.chatItineraryPropertyID,
    };
    ChatProxy.invoke("MessageReadComplete", ReadObj);
    setTimeout(function () {
      $(".activeItem").removeClass("activeItem");
      $("#highlight" + state.chatItineraryPropertyID).addClass("activeItem");
    }, 1000);
  }

  function processArrayResponse(data) {
    data.map((row, index) => {
      if (!isMessageForCurrentChat(row)) {
        console.log("message is not for current chat", data);
        updatePropertyWithCountUnread(row);
      } else if (isMessageFromGeneralProperty(row)) {
        console.log("message is for general property", data);
        updateGeneralPropertyWithCountUnread();
      } else {
        // message is from currently open chat
        console.log("message is for current chat", data);
        handleMessageFromCurrentChat(row);
      }
    });

    updateState({
      propertyChat: [...propertyChat, ...data],
      showChatMsg: true,
    });
  }

  function processObjectResponse(data) {
    // push the data.messagesList into propertyChat
    updateState({ countUnread: data.count });
    updateNewCount();

    console.log("propertyChat before update: ", propertyChat);

    if (data.messagesList) {
      updateState({ propertyChat: data.messagesList, loader: false });
      propertyChat = deepCopy(data.messagesList);
    }

    updateState({ showChatMsg: true });
  }

  function processMessageFromServer(data) {
    console.log("Processing message from server", data);
    if (Array.isArray(data)) {
      console.log("Proceeding with array logic");
      // handle logic for the incomming message [{apiId: ,}]
      processArrayResponse(data);
    } else {
      console.log("Proceeding with object logic");

      processObjectResponse(data);
      processLogoutUsersData(data.onlineusers);
      updateState({ onlineusers: data.onlineusers });
    }

    scrollDivBottom();
  }

  async function makeConnectionSignalR() {
    var ChatServerUrl = "https://apis.relonavigator.com/";
    var ChatUrl = ChatServerUrl + "signalr";
    SignalrConnection = $.hubConnection(ChatUrl, {
      useDefaultPath: false,
    });

    ChatProxy = SignalrConnection.createHubProxy("ChatHub");

    ChatProxy.on("getLogoutUsersList", (data) =>
      processLogoutUsersData(data.onlineusers)
    );

    ChatProxy.on("messageFromServer", processMessageFromServer.bind(this));

    SignalrConnection.start()
      .done(function (data) {
        if (true || __isMounted) {
          updateState({ chatIdSignalR: data.id });
        }
        var objC = {};
        objC.userId = state.sessionData.userUniqueId;
        objC.apiId = state.chatItineraryPropertyID;
        objC.connectionId = data.id;
        objC.isconsultent = false;
        let changeSession = localStorage.getItem("ChatSession");
        if (changeSession == null || changeSession == undefined) {
          ChatProxy.invoke("RegisterOnlineUser", objC);
          ChatProxy.invoke("LogoutUserChatt", { userId: "" });
        }
        logoutFromChat();
      })
      .fail(function () {
        console.log("failed in connecting to the signalr server");
      });

    changeLoaderState("stop");
  }

  function getSignalRConnection() {
    SignalrConnection.start()
      .done(function (data) {
        updateState({ chatIdSignalR: data.id });
        var objC = {};
        objC.userId = state.sessionData.userUniqueId;
        objC.apiId = 0;
        objC.connectionId = data.id;
        objC.isconsultent = false;

        ChatProxy.invoke("RegisterOnlineUser", objC);
      })
      .fail(function () {
        console.log("failed in connecting to the signalr server");
      });
  }
  const openChatboxPopup = async () => {
    let onlineusers =
      localStorage.getItem("onlineusers") != null
        ? localStorage.getItem("onlineusers")
        : "";
    let onlineUser = _.indexOf(
      onlineusers.split(","),
      state.sessionData.consultantUId
    );
    await setState((prev) => ({ ...prev, openLoader: true }));
    await getSignalRConnection();
    $(".activeItem").removeClass("activeItem");
    $("#highlight" + state.chatItineraryPropertyID).addClass("activeItem");
    $("body").addClass("modal-open");
    await setState((prev) => ({
      ...prev,
      openChatboxPopup: true,
      openPropetyChatList: false,
    }));
    if (onlineUser > -1) {
      $("#online" + state.sessionData.consultantUId).removeClass("offline");
      $("#online" + state.sessionData.consultantUId).addClass("online");
      //console.log(temperature.onlineusers, "onlineUser", onlineUser)
    }
    setTimeout(async () => {
      await setState((prev) => ({ ...prev, openLoader: false }));
    }, 5000);
  };
  function openChatBox(Id, property_image_pic, row) {
    let property_image_img = property_image_pic
      ? property_image_pic.split(",")[0]
      : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png";

    updateState({
      displayname: row.propertyname,
      showChatMsg: false,
      profilePic: property_image_img,
      isPropertySelected: true,
      propertyChat: [],
      chatItineraryPropertyID: Id,
      ChatProperty: "",
      openChatBox: true,
      openPropetyChatList: false,
    });

    clearBadgeOnSeeingMessages(Id);

    chatItineraryPropertyID = Id;

    let ReadObj = {
      toId: state.sessionData.userUniqueId,
      fromId: state.sessionData.consultantUId,
      apiId: Id,
    };
    ChatProxy.invoke("MessageReadComplete", ReadObj);
    getSignalRConnection();

    $(".activeItem").removeClass("activeItem");
    $("#highlight" + Id).addClass("activeItem");
    $("#left1").addClass("chat-left-md-view");
    $("#right1").removeClass("chat-right-md-view");

    let TotalUnreadCountOriginal = state.TotalUnreadCount;
    if (Id == "0000111122223333") {
      TotalUnreadCountOriginal =
        TotalUnreadCountOriginal - state.generalProperty.countUnread;
      updateState({ TotalUnreadCount: TotalUnreadCountOriginal });
    } else {
      let filterObject = _.findIndex(
        state.responseTransfreeProperties,
        function (o) {
          return o.propertyanalyticsuniqueId == Id;
        }
      );

      TotalUnreadCountOriginal =
        TotalUnreadCountOriginal -
        state.responseTransfreeProperties[filterObject].countUnread;
      updateState({ TotalUnreadCount: TotalUnreadCountOriginal });

      state.responseTransfreeProperties[filterObject].countUnread = 0;
    }
  }

  function scrollDivBottom() {
    var objDiv = $("#chatPopup").height();
    $("#chatPopup").scrollTop(objDiv * 100);

    var objDiv2 = $("#chat_msgs").height();
    $("#chat_msgs").scrollTop(objDiv2 * 100);
  }

  function updateNewCount() {}

  function clearBadgeOnSeeingMessages(Id) {
    let responseTransfreeProperties = JSON.parse(
      localStorage.getItem("responseTransfreeProperties")
    );

    const updatedPropertiesList = responseTransfreeProperties.map(
      (property) => {
        if (property.propertyanalyticsuniqueId === Id) {
          return {
            ...property,
            countUnread: 0,
            // updatedDate: moment()
            //   .tz("America/Los_Angeles")
            //   .format("MM/DD/YYYY HH:mm:ss"),
          };
        }
        return property;
      }
    );

    localStorage.setItem(
      "responseTransfreeProperties",
      JSON.stringify(updatedPropertiesList)
    );
    updateState({
      responseTransfreeProperties: updatedPropertiesList,
    });
  }

  function messageText(event) {
    event.persist();
    let textMessage = event.target.value;
    updateState({ TextMessage: textMessage });
  }

  function sendMessage() {
    let sendMessageObj = {
      apiId: state.chatItineraryPropertyID,
      message: state.TextMessage.trim(),
      fromId: state.sessionData.userUniqueId,
      toId: state.sessionData.consultantUId,
      datetime: moment()
        .tz("America/Los_Angeles")
        .format("MM/DD/YYYY HH:mm:ss"),
      isconsultant: false,
    };
    let _this = this;

    let filterObject = _.findIndex(
      state.responseTransfreeProperties,
      function (o) {
        return o.propertyanalyticsuniqueId == state.chatItineraryPropertyID;
      }
    );
    if (filterObject > -1)
      state.responseTransfreeProperties[filterObject].updatedDate = moment()
        .tz("America/Los_Angeles")
        .format("MM/DD/YYYY HH:mm:ss");

    if (state.TextMessage.trim() !== "") {
      // propertyChat = deepCopy(state.propertyChat);
      propertyChat.push(sendMessageObj);
      updateState({ propertyChat: propertyChat });
      ChatProxy.invoke("SendMessage", sendMessageObj);

      scrollDivBottom();
      $("#InputMsg").val("");
      $("#InputMsg2").val("");
      $("#InputMsg2").focus();

      updateState({ TextMessage: "" });

      $(".activeItem").removeClass("activeItem");
      $("#highlight" + state.chatItineraryPropertyID).addClass("activeItem");
    }
  }

  function searchProperty(value) {
    const propertyList = state.totalTransfereeProperties;
    let list = state.responseTransfreeProperties;
    if (value != "") {
      const filterProperty = propertyList.filter(
        (o) =>
          // Object.keys(o).some((k) =>
          //   String(o[k]).toLowerCase().includes(value.toLowerCase())
          o.propertyname.toLowerCase().includes(value.toLowerCase()) ||
          o.address.toLowerCase().includes(value.toLowerCase())
      );
      console.log(filterProperty, "filterProperty", propertyList);

      updateState({
        responseTransfreeProperties: filterProperty,
      });
    } else {
      console.log("no value", propertyList);
      updateState({
        responseTransfreeProperties: propertyList,
      });
    }
  }

  useEffect(() => {
    getChatPropertiesList();
    componentDidMountLogic();
  }, []);

  useEffect(() => {
    if (state.sessionData.consultantUId && state.onlineusers?.length) {
      processLogoutUsersData(state.onlineusers);
    }
  }, [state.sessionData.consultantUId, state.onlineusers]);

  const closeFeedBack = () => {
    setState((prev) => ({
      ...prev,
      feedBackPopUp: false,
      succesMsg: "",
      experience: "",
      feedbacktype: "General feedback",
      feeddescription: "",
      FromEmail: "",
    }));
  };

  let backgroundColor = location.pathname == "/city-landing" ? "clrchange" : "";
  let displayone = { propertyname: "General" };
  const children = [];
  var dataSource = [
    "Feature request",
    "General feedback",
    "Report an issue",
    "Support/Account/Billing",
    "UI feedback",
  ];
  const Close = () => {
    $(function () {
      $("document").ready(async function () {
        $("#ModalTersm-of-use").modal("hide");
      });
    });
  };
  const closePopUP = () => {
    $(function () {
      $("document").ready(async function () {
        $("#ModalTersm-of-use2").modal("hide");
      });
    });
  };
  for (let i = 0; i < dataSource.length; i++) {
    children.push(
      <Select.Option key={dataSource[i]}>{dataSource[i]}</Select.Option>
    );
  }
  return (
    <div className="ds-profile-page chat-footer-icon">
      {state.sessionData.subscriptioncode != "ReloNavigator" &&
      state.sessionData.subscriptioncode != undefined &&
      props.sessionObject != true ? (
        <div className="chat_footer chat_width chat_footer-fullview">
          {!isContactUsRoute &&
          !pricing &&
          !isHomeRoute &&
          !empty &&
          state.openChatboxPopup == false ? (
            <div>
              {state.openPropetyChatList == false &&
              state.openChatBox == false ? (
                <a
                  href="javascript:void(0)"
                  className="chaticon"
                  onClick={openChatboxPopup}
                >
                  <img src="/assets/images/Group 74.png" alt="" />
                  {state.TotalUnreadCount > 0 ? (
                    <sup>
                      <span className="badge badge-light" id="TotalUnreadCount">
                        {state.TotalUnreadCount}
                      </span>
                    </sup>
                  ) : (
                    ""
                  )}
                </a>
              ) : (
                <a
                  href="javascript:void(0)"
                  className="chaticon"
                  onClick={closeChatList}
                >
                  <img src="/assets/images/close.png" alt="" />
                </a>
              )}
            </div>
          ) : (
            ""
          )}

          {state.openChatboxPopup == true ? (
            <div className="bd-example-modal-lg communitie_pop modal-backdrop ">
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content max-width-initial ">
                  {/* <div className="communities_header d-flex justify-content-between">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={closeChatList}
                    >
                      &times;
                    </button>
                  </div> */}
                  <div className="only_close">
                    <span
                      className="close"
                      data-dismiss="modal"
                      onClick={closeChatList}
                    >
                      &times;
                    </span>
                  </div>
                  <div className="modal-body text-left p-0 ">
                    <div className="d-flex chat-footer-d-flex properties">
                      <div className="chatSm-item item" id="left1">
                        <h5>Message center</h5>
                        <div className="input-group mb-3">
                          <span className="input-group-text">
                            <i className="bi bi-search"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Propertyname"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={(e) => searchProperty(e.target.value)}
                          />
                        </div>
                        {state.openLoader == true ? (
                          <div
                            style={{
                              minHeight: "400px",
                              paddingTop: "100px",
                            }}
                          >
                            <Spin />
                          </div>
                        ) : (
                          <div className="contacts_list">
                            <div
                              key={1000}
                              className={
                                "chat_item d-flex align-items-center justify-content-start"
                              }
                              id={
                                "highlight" +
                                state.generalProperty.propertyanalyticsuniqueId
                              }
                              onClick={() =>
                                openChatBox(
                                  state.generalProperty
                                    .propertyanalyticsuniqueId,
                                  state.generalProperty.property_image,
                                  displayone
                                )
                              }
                            >
                              <div className="item_img bd-highlight">
                                <i className="bi bi-check-circle-fill"></i>
                                <img
                                  src={state.generalProperty.property_image}
                                  alt=""
                                />
                                <div
                                  className={
                                    "msg_count" +
                                    (state.generalProperty.countUnread > 0
                                      ? ""
                                      : " hideBadge")
                                  }
                                  id={
                                    "bw" +
                                    state.generalProperty
                                      .propertyanalyticsuniqueId
                                  }
                                >
                                  <span
                                    className="badge"
                                    id={
                                      "bbd" +
                                      state.generalProperty
                                        .propertyanalyticsuniqueId
                                    }
                                  >
                                    {state.generalProperty.countUnread}
                                  </span>
                                </div>
                              </div>

                              <div className="chat_dtls">
                                <span>{state.generalProperty.address}</span>
                              </div>
                            </div>

                            {state.responseTransfreeProperties
                              .sort((a, b) => {
                                let dateA = new Date(a.updatedDate),
                                  dateB = new Date(b.updatedDate);

                                return dateB - dateA;
                              })
                              .map((row, index) => {
                                return (
                                  <IndividualPropertyTab
                                    openChatBox={openChatBox}
                                    state={state}
                                    row={row}
                                    key={index}
                                  />
                                );
                              })}
                          </div>
                        )}
                      </div>

                      <div
                        id="right1"
                        className="chatSm-item item chat-right-md-view"
                      >
                        {/* <div
                          onClick={() => backChatBox()}
                          className="chat-heading-view"
                        >
                          <img src="../assets/images/back-arrow.svg" alt="" />
                          <span>{state.displayname}</span>
                        </div> */}
                        {/* <h2>
                          {state.sessionData.consultantname}
                          <span
                            className="offline"
                            id={"online" + state.sessionData.consultantUId}
                          >
                            <div className="ringring"></div>
                            <Popover
                              content={
                                <div id="currentOnline">
                                  {state.popoverText}
                                </div>
                              }
                              trigger="hover"
                            >
                              <div className="circle"></div>
                            </Popover>
                          </span>
                        </h2> */}
                        <div className="contactDP">
                          <img
                            src={
                              state.sessionData.consultantProfileImage != "" &&
                              state.sessionData.consultantProfileImage != null
                                ? state.sessionData.consultantProfileImage
                                : "../assets/images/profile-pic.svg"
                            }
                            alt=""
                          />{" "}
                          <h5>
                            {state.sessionData.consultantname}

                            <div
                              className="circle offline"
                              id={"online" + state.sessionData.consultantUId}
                            >
                              {" "}
                              Online{" "}
                            </div>
                            {/* </Popover> */}
                          </h5>
                          <i className="bi bi-three-dots-vertical"></i>
                        </div>

                        {state.isPropertySelected == true ? (
                          <div>
                            <div className="chat_msgs" id="chatPopup">
                              {state.propertyChat.map((row, index) => {
                                return (state.sessionData.consultantUId ==
                                  row.fromId &&
                                  row.toId == state.sessionData.userUniqueId &&
                                  row.apiId == state.chatItineraryPropertyID) ||
                                  (state.sessionData.consultantUId ==
                                    row.toId &&
                                    row.fromId ==
                                      state.sessionData.userUniqueId &&
                                    row.apiId ==
                                      state.chatItineraryPropertyID) ? (
                                  <div key={index}>
                                    {state.sessionData != "" &&
                                    state.sessionData.userUniqueId ==
                                      row.fromId ? (
                                      <div className="right_chat_view ">
                                        <div className="me-3">
                                          <div className="right-user-time">
                                            {moment(row.datetime).calendar()}
                                          </div>
                                          <div className="right_chat">
                                            {row.message}
                                            <div className="bi bi-check-all ms-2"></div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="left_chat_view ">
                                        <div className="left-image">
                                          <img
                                            src={
                                              state.sessionData
                                                .consultantprofilepic != "" &&
                                              state.sessionData
                                                .consultantprofilepic != null
                                                ? state.sessionData
                                                    .consultantprofilepic
                                                : "../assets/images/profilePic.png"
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <div>
                                          <div className="left-user-time">
                                            {row.firstname} {row.lastname},{" "}
                                            {moment(row.datetime).calendar()}
                                          </div>
                                          <div
                                            key={index}
                                            className="left_chat"
                                          >
                                            {row.message}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  ""
                                );
                              })}
                              {state.showChatMsg == false ? (
                                <div style={{ paddingTop: "100px" }}>
                                  <Spin />
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="clearfix"></div>
                            </div>
                            <div className="sent_box d-flex align-items-center">
                              <div class="position-relative w-100">
                                <i class="bi bi-emoji-smile"></i>
                                <input
                                  tabIndex="1"
                                  type="text"
                                  // onKeyPress={_handleKeyPress}
                                  // onKeyDown={_handleKeyPress}
                                  id="InputMsg2"
                                  defaultValue={state.TextMessage}
                                  placeholder="Send message"
                                  onChange={messageText}
                                />

                                <i class="bi bi-plus-circle"></i>
                              </div>

                              <span
                                tabIndex="2"
                                className={
                                  state.TextMessage != "" ? "" : "chatIconColor"
                                }
                                //onKeyPress={sendMessage}
                                onClick={sendMessage}
                              >
                                <img src="../assets/images/Vector.svg" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      {localStorage.getItem("LoggedInMobile") !== "true" && (
        <div className="footer_main">
          <div className="container ">
            <div
              className={
                "footer_copyright text-center pt-2 pb-1 " + backgroundColor
              }
            >
              <div className="footer_copyright ">
                <ul className="footer-app-icon">
                  {/* <li>
                    <a href="#">
                      <img src="/assets/images/google-play-store.png" />
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="https://apps.apple.com/us/app/relonavigator-app/id6499023704"
                      target="_blank"
                    >
                      <img src="/assets/images/app-store.png" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer_copyright ">
                <ul className="footer-ul-list">
                  <li>
                    <a
                      to="#"
                      data-toggle="modal"
                      data-target="#ModalTersm-of-use2"
                      onClick={() => feedBack("policy")}
                    >
                      Privacy Policy{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      to="#"
                      data-toggle="modal"
                      data-target="#ModalTersm-of-use"
                      style={{ zIndex: 1060 }}
                      onClick={() => feedBack("terms")}
                    >
                      Terms of Use{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      className="feedback-view"
                      href="javascript:void(0)"
                      onClick={() => feedBack("feedback")}
                    >
                      Give feedback
                    </a>
                  </li>
                  <li>
                    <a>Patented</a>
                  </li>
                </ul>
              </div>
              <p style={{ fontSize: "12px" }}>
                Made with <i className="fa fa-heart"></i> in California, USA
              </p>
            </div>
          </div>
        </div>
      )}
      {state.popUpType == "policy" && (
        <div
          className="modal fade  "
          id="ModalTersm-of-use2"
          tabIndex="-1"
          style={{
            opacity: "1",
            transition: "none",
            zIndex: 1060,
          }}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="width-80per pd-y-50">
                <div className="only_close">
                  <span
                    className="pointer close"
                    data-dismiss="modal"
                    onClick={() => closePopUP()}
                  >
                    {" "}
                    ×{" "}
                  </span>
                </div>
                <h6>Privacy Policy</h6>
                <div className="ifram-html-view">
                  <iframe
                    src="https://app.termly.io/document/privacy-policy/ba9c43e8-c650-4086-8e0e-dc0d4e603322"
                    title="Terms and Conditions"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {state.popUpType == "feedback" && (
        <FeedBackPopUp
          state={state}
          closeFeedBack={closeFeedBack}
          setState={setState}
        />
      )}
      {state.popUpType == "terms" && (
        <div
          className="modal fade "
          id="ModalTersm-of-use"
          tabIndex="-1"
          style={{
            opacity: "1",
            transition: "none",
            zIndex: 1060,
          }}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="width-80per pd-y-50">
                <div className="only_close">
                  <span
                    className="pointer close"
                    data-dismiss="modal"
                    onClick={() => Close()}
                  >
                    {" "}
                    ×{" "}
                  </span>
                </div>
                <h6>Terms of use</h6>
                <div className="ifram-html-view">
                  <iframe
                    src="https://app.termly.io/document/terms-and-conditions/eedb777d-0087-44e5-8a20-0168e9e1a364"
                    title="Terms and Conditions"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
const IndividualPropertyTab = ({ openChatBox, state, row }) => {
  // console.log(row, "row");

  return (
    <div
      className={
        state.chatItineraryPropertyID == row.propertyanalyticsuniqueId
          ? "chat_item d-flex align-items-center justify-content-start activeItem"
          : "chat_item d-flex align-items-center justify-content-start"
      }
      id={
        "highlight" +
        (row.id != undefined ? row.id : row.propertyanalyticsuniqueId)
      }
      onClick={() =>
        openChatBox(
          row.id != undefined ? row.id : row.propertyanalyticsuniqueId,
          row.property_image,
          row
        )
      }
    >
      <div className="item_img bd-highlight">
        <i className="bi bi-check-circle-fill"></i>
        <img
          src={
            row.property_image
              ? row.property_image.split(",")[0]
              : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
          }
          alt=""
        />
        <div
          className={"msg_count" + (row.countUnread > 0 ? "" : " hideBadge")}
          id={
            "bw" +
            (row.id != undefined ? row.id : row.propertyanalyticsuniqueId)
          }
        >
          <span
            className="badge"
            id={
              "bbd" +
              (row.id != undefined ? row.id : row.propertyanalyticsuniqueId)
            }
          >
            {row.countUnread}
          </span>
        </div>
      </div>

      <div className="chat_dtls">
        <span>
          {row.propertyname} - {row.address}
          {/* <span className="dataTime"> 25 Aug </span> */}
        </span>
      </div>
    </div>
  );
};
export default Footer;
