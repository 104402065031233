import React from "react";

function PetInsuranceModal() {
  const closeModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#petInsuranceModal").modal("hide");
      });
    });
  };
  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header border-0">
          <div class="only_close">
            <span
              aria-hidden="true"
              className="pointer close"
              onClick={() => closeModal()}
              data-dismiss="modal"
            >
              ×
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div className="widthModal">
            <div className="item-view">
              <h2>Pet Insurance </h2>
              <p className="mb-0">
                Pet insurance provides peace of mind by helping cover the cost
                of veterinary care, allowing you to focus on your pet’s health
                without worrying about expensive bills. Whether you’re
                relocating or just want to be prepared for any unexpected
                events, understanding pet insurance options can save you time,
                money, and stress.
              </p>
            </div>

            <div className="tails-row">
              <div className="tails">
                <div className="item-view">
                  <h2>1. What Is Pet Insurance? </h2>
                  <p className="ps-4">
                    Pet insurance is a health care policy that helps cover the
                    costs of veterinary bills in case of accidents, illnesses,
                    or routine care, depending on the policy you choose. Just
                    like health insurance for humans, you pay a monthly premium,
                    and the insurer helps cover a portion of your pet’s medical
                    expenses.
                  </p>
                  <p>
                    There are typically three main types of pet insurance
                    policies:{" "}
                  </p>
                  <ul>
                    <li>
                      <b>Accident-Only Coverage: </b> Covers treatment costs
                      related to accidents, such as broken bones, injuries, or
                      poisoning.
                    </li>
                    <li>
                      <b>Accident and Illness Coverage: </b> This is the most
                      common type of pet insurance and covers both accidents and
                      illnesses, including conditions like cancer, infections,
                      and chronic diseases.
                    </li>
                    <li>
                      <b>Comprehensive or Wellness Plans: </b> Some policies
                      also include wellness care, covering routine veterinary
                      visits, vaccinations, flea treatments, and dental care.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>2. Why You Need Pet Insurance </h2>
                  <p className="ps-4">
                    Veterinary care costs can add up quickly, especially in
                    emergency situations or when dealing with chronic
                    conditions. Pet insurance helps manage the financial burden,
                    ensuring that your pet gets the best care possible when they
                    need it.
                  </p>
                  <b>Key benefits include: </b>
                  <ul>
                    <li>
                      <b>Emergency Medical Coverage: </b> Accidents and
                      illnesses can happen unexpectedly, and pet insurance can
                      help cover high emergency vet bills.{" "}
                    </li>
                    <li>
                      <b>Chronic Condition Coverage: </b> For pets with ongoing
                      medical needs, pet insurance can help cover long-term
                      treatment for chronic conditions such as diabetes or
                      arthritis.
                    </li>
                    <li>
                      <b>Preventive Care: </b> Some comprehensive plans include
                      coverage for routine care, helping with the costs of
                      vaccinations, check-ups, and preventive treatments.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>3. Factors to Consider When Choosing Pet Insurance </h2>
                  <p className="ps-4">
                    Not all pet insurance plans are created equal. Here are the
                    key factors to consider when selecting the right insurance
                    for your pet:
                  </p>
                  <ul>
                    <li>
                      <b>Monthly Premiums: </b> Pet insurance premiums vary
                      based on your pet’s age, breed, and location. Make sure
                      the monthly cost fits within your budget while offering
                      adequate coverage.
                    </li>
                    <li>
                      <b>Deductibles: </b> This is the amount you need to pay
                      out of pocket before your insurance kicks in. Higher
                      deductibles often lead to lower monthly premiums, but they
                      mean you’ll pay more upfront during a claim.
                    </li>
                    <li>
                      <b>Reimbursement Rates: </b> Most pet insurance companies
                      reimburse a percentage of the vet bill, often ranging from
                      70% to 90%. Check the reimbursement rate to understand how
                      much you’ll need to cover yourself.
                    </li>
                    <li>
                      <b>Coverage Limits: </b> Some policies have annual,
                      lifetime, or per-incident coverage limits, meaning the
                      insurer will only pay up to a certain amount. Look for
                      policies with sufficient limits to cover unexpected large
                      expenses.
                    </li>
                    <li>
                      <b>Exclusions and Waiting Periods: </b> Review the
                      policy’s exclusions (e.g., pre-existing conditions or
                      breed-specific issues) and the waiting period before
                      coverage begins (usually between 14 and 30 days).
                    </li>
                  </ul>
                </div>
              </div>

              <div className="tails">
                <div className="item-view">
                  <h2>4. How to Make the Most of Your Pet Insurance </h2>
                  <p className="ps-4">
                    To maximize the benefits of your pet insurance:
                  </p>
                  <ul>
                    <li>
                      <b>Enroll Early: </b>The younger your pet is when you sign
                      up, the more likely you are to get better coverage at
                      lower rates. It also helps avoid exclusions for
                      pre-existing conditions.
                    </li>
                    <li>
                      <b>Understand Your Policy: </b> Familiarize yourself with
                      the policy’s fine print, including what’s covered, what’s
                      excluded, and any reimbursement processes. Knowing this
                      will help you avoid surprises when you need to file a
                      claim.
                    </li>
                    <li>
                      <b>File Claims Promptly: </b> Submit claims as soon as
                      possible after a vet visit to ensure quick reimbursement.
                      Keep all receipts and vet documentation for easy filing.
                    </li>
                  </ul>
                </div>
                <div className="item-view">
                  <h2>5. Finding the Right Pet Insurance Provider </h2>
                  <p className="ps-4">
                    There are many pet insurance providers available. Here are a
                    few well-known options that offer comprehensive coverage:
                  </p>
                  <ul>
                    <li>
                      <b>Trupanion: </b>Known for covering 90% of eligible
                      expenses with no payout limits and flexible deductibles.
                    </li>
                    <li>
                      <b>Healthy Paws: </b> Offers unlimited lifetime benefits
                      and has a quick claims processing system.
                    </li>
                    <li>
                      <b>Nationwide Pet Insurance: </b> Provides comprehensive
                      plans that include wellness care and accident/illness
                      coverage.
                    </li>
                    <li>
                      <b>Embrace Pet Insurance: </b> Offers customizable plans
                      with wellness and dental care options.
                    </li>
                  </ul>
                  <p>
                    Each provider has its own benefits, so it’s essential to
                    compare policies and choose the one that best fits your
                    pet’s needs.{" "}
                  </p>
                </div>
                <div className="item-view">
                  <h2>6. Pet Insurance Tips for Relocating </h2>
                  <p className="ps-4">
                    If you’re moving, be sure to check how your pet insurance
                    policy will be affected by your new location:
                  </p>
                  <ul>
                    <li>
                      <b>Policy Portability: </b>Most pet insurance providers
                      offer coverage nationwide, but it’s important to check
                      whether your coverage will stay the same if you’re moving
                      to a new state or country.
                    </li>
                    <li>
                      <b>Premium Adjustments: </b>Relocating to a different area
                      can impact your premium rates, especially if the cost of
                      veterinary care varies significantly in your new location.
                    </li>
                    <li>
                      <b>Provider Network: </b> If your policy has a network of
                      preferred vets, make sure to find a new vet in your area
                      that is part of the network, or check if the policy allows
                      you to visit any licensed veterinarian.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="item-view mt-2">
              <h2>Final Thoughts </h2>
              <p className="mb-0">
                Pet insurance can be a lifesaver when it comes to managing your
                pet’s health care costs, especially when unexpected accidents or
                illnesses occur. By choosing the right coverage, understanding
                your policy, and enrolling early, you’ll be prepared for
                whatever life throws at you and your furry friend.
              </p>
              <p>
                Be sure to compare providers, understand what’s covered, and
                take advantage of preventive care options where available. A
                little preparation goes a long way in keeping your pet healthy
                and your finances stable.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PetInsuranceModal;
