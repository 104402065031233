import React from "react";

function BankingPopUp() {
  const closeModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#myModalsettingUpBanking").modal("hide");
      });
    });
  };
  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header border-0">
          <div class="only_close">
            <span
              aria-hidden="true"
              className="pointer close"
              onClick={() => closeModal()}
              data-dismiss="modal"
            >
              ×
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div className="widthModal">
            <div className="item-view">
              <h2>Setting Up Banking</h2>
              <p>
                Below are links and information about how to set up a bank
                account and manage your credit.
              </p>
            </div>

            <div className="tails-row">
              <div className="tails">
                <div className="item-view">
                  <h2>Set Up a U.S. Bank Account</h2>
                  <p>
                    This is a high-level overview of setting up a bank account
                    and getting a credit card in the U.S. 
                  </p>
                  <p>Here are a few terms you should be familiar with:</p>
                  <div>
                    <span className="textBold">Checking Account:</span> This is
                    the traditional bank account where you can deposit and
                    withdraw funds at any time. This is typically the account
                    where your paychecks are deposited into.
                  </div>
                  <div>
                    <span className="textBold">Savings Account:</span> This is
                    an account where you leave the funds mostly untouched while
                    they earn interest. Unlike checking accounts, saving
                    accounts are not meant for daily transactions.
                  </div>
                  <br></br>
                  <div>
                    <p>
                      <a
                        target="_blank"
                        href="https://bettermoneyhabits.bankofamerica.com/en/personal-banking/the-difference-between-checking-and-savings-account#:~:text=A%20checking%20account%20is%20a,the%20goal%20of%20accumulating%20more."
                      >
                        Here
                      </a>{" "}
                      is further information about checking accounts versus
                      savings accounts. To open an account at a U.S. bank, you
                      will most likely need to have the following:{" "}
                    </p>
                  </div>
                  <ol>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.ssa.gov/ssnumber/ss5doc.htm"
                      >
                        Social Security Number (SSN)
                      </a>{" "}
                      or{" "}
                      <a
                        target="_blank"
                        href="https://www.irs.gov/individuals/individual-taxpayer-identification-number"
                      >
                        Individual Tax Identification Number (ITIN)
                      </a>
                    </li>
                    <li>Passport or Valid, Government-Issued Photo ID</li>
                    <li>
                      {" "}
                      US Address and proof of address (such as a utility bill)
                    </li>
                    <li>
                      {" "}
                      In some cases, you will need a US phone number to access
                      online banking{" "}
                    </li>
                  </ol>
                  <p>
                    However, if you are moving to the U.S. for the first time,
                    you may not have all of these items just yet. It is
                    important to note that each bank follows different
                    guidelines and requirements for what is needed to open an
                    account. If you do not have all of these requirements but
                    are in the process of getting them, some banks will allow
                    you to open an account without an SSN, with the expectation
                    that you are applying for one and will provide it as soon as
                    it is received. Again, this is not a standard practice and
                    is highly dependable on the bank.
                  </p>
                </div>

                <div className="item-view">
                  <h2>Credit Cards & Building Credit </h2>
                  <p>
                    If you have not already done so, consider opening an account
                    with a large international bank that will consider your
                    international credit history and allow you to sign up for a
                    U.S. credit card. That way, you can build U.S. credit and
                    transfer banks in the future if needed.{" "}
                  </p>
                  <p>
                    HSBC is an international bank that allows you to start a
                    U.S. bank account. More information about international
                    banking in the U.S. through{" "}
                    <a target="_blank" href="https://www.us.hsbc.com/">
                      HSBC
                    </a>{" "}
                    can be found{" "}
                    <a
                      target="_blank"
                      href="https://www.us.hsbc.com/international-banking/"
                    >
                      here
                    </a>
                    .{" "}
                  </p>
                  <p>
                    Many banks offer a{" "}
                    <a
                      target="_blank"
                      href="https://www.nerdwallet.com/article/credit-cards/secured-credit-cards-vs-unsecured-difference"
                    >
                      secured credit card
                    </a>
                    , which is a credit card started with a cash deposit. Your
                    credit limit will equal your deposit. This option allows you
                    to build credit and will help you apply for a credit card in
                    the future. Once you have demonstrated you are a reliable
                    customer, making payments consistently on time, you can
                    usually upgrade to a traditional credit card in a year or
                    so.
                  </p>
                </div>
                {/* <div className="text-center">
                            <button type="button" className="btn learnBtn">Learn More</button>
                        </div> */}
              </div>
              <div className="tails">
                <div className="item-view">
                  <h2>Finding a Bank</h2>
                  <p>
                    It is highly recommended to research different banks to find
                    the best fit for you and your needs. There are two types of
                    banks to research: traditional banks and credit unions. For
                    information about banks versus credit unions, click{" "}
                    <a
                      target="_blank"
                      href="https://www.investopedia.com/credit-unions-vs-banks-4590218"
                    >
                      here
                    </a>
                    .{" "}
                  </p>
                  <h3>Major U.S. Banks: </h3>
                  <ul>
                    <li>
                      <a target="_blank" href="https://www.bankofamerica.com/">
                        Bank of America
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.chase.com/">
                        Chase{" "}
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a target="_blank" href="https://www.citi.com/">
                        Citi Bank{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.usbank.com/index.html"
                      >
                        U.S. Bank{" "}
                      </a>{" "}
                    </li>
                    <li>
                      <a target="_blank" href="https://www.wellsfargo.com/">
                        Wells Fargo{" "}
                      </a>{" "}
                    </li>
                  </ul>
                  <h3>Major U.S. Credit Unions: </h3>
                  <ul>
                    <li>
                      <a target="_blank" href="https://www.golden1.com/">
                        Golden 1{" "}
                      </a>{" "}
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.schoolsfirstfcu.org/wps/portal/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8zinf0tzYP9vHyMDA2DLA0cLczCAvyDzI0tXEz0wwkpiAJKG-AAjgZA_VFgJbhM8DGGKsBjRkFuhEGmo6IiADbNJas!/dz/d5/L2dBISEvZ0FBIS9nQSEh/"
                      >
                        Schools Credit Union
                      </a>{" "}
                    </li>
                  </ul>
                  <p>
                    The bank you choose will depend on what your goals are. For
                    example, if you are looking to apply for a{" "}
                    <a
                      target="_blank"
                      href="https://www.nerdwallet.com/article/credit-cards/what-is-a-credit-card"
                    >
                      credit card
                    </a>
                    , many major banks will accept you if you only have an ITIN
                    and not an SSN. However, you will need to prove a strong
                    U.S.{" "}
                    <a
                      target="_blank"
                      href="https://www.investopedia.com/terms/c/credit-history.asp"
                    >
                      credit history
                    </a>
                    , which a lot of non-residents do not have yet.
                  </p>
                  <p>
                    Some branches or locations might have specialists who can
                    work with those who are new to the U.S and haven’t started
                    building credit yet. Call and ask if there are any
                    non-resident specialists at the bank or credit union. If you
                    are new to the country, you will most likely have to set up
                    a bank account in person, not online.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="modal-footer"></div> */}
      </div>
    </div>
  );
}

export default BankingPopUp;
