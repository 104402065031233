import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import "./login.css";
import ApiServices from "../../../../services/apiServices";
import AppController from "../../../controllers/appController";
import LoginStore from "../../../../services/thirdPartyService";
const publicIp = require("react-public-ip");
import moment from "moment";
import EmailAndSso from "./EmailAndSso";
import OtpCheck from "./OtpCheck";
import ThirdPartyApiService from "../../../../services/thirdPartyService";
import {decode as base64_decode, encode as base64_encode} from 'base-64';

const LoginForce = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  let googlecloudclientkey =
    "1243334706-cd5rlh1uru2ifkqbj8vphaficdmruhjh.apps.googleusercontent.com";
  const [state, setState] = useState({
    errorMessage: "",
    loaderlogin: false,
    userName: "",
    ifphonenull: false,
    validateOtp: false,
    showOtpCheckComponent: false,
    phoneNumber: "",
    email: "",
    countryCode: "",
    userType: "",
    userUniqueId: "",
    userInfo: {},
    emailAddress: "",
    otp: "",
    view: "emailView",
    isLoading: false,
    message: "",
    color: "red",
    isLoggedIn: null,
    resendOtpTimer: 90,
    showOtpSendLinks: true,
    OtpMsg: "",
    errMsg: "",
    userOtpText: "",
    loader: false,
    otpMode: "",
  });

  function navigateTo(url) {
    navigate(url);
  }

  async function fetchUserDetails(loginObject) {
   
   
    let LoginResult = await ApiServices.TransfereeLoginCheck(loginObject);
    console.log("LoginResult", LoginResult)
    return LoginResult;
  }

  async function navigateBasedOnUserType(userInfo) {
    console.log(userInfo, "userInfo");
    await AppController.logData(
      "Login",
      "Login",
      location.pathname,
      state.userUniqueId,
      ""
    );
    let logindata = await LoginStore.LoginDetailsStore(userInfo);
    // console.log(logindata, "logindata");
    let getLoginData = await LoginStore.getLoginInfo({});
    sendLoggedIndataToMobileApp(userInfo);
    // console.log(getLoginData, "getLoginData");
    if (logindata.status == "true") {
      if (userInfo.type == "TRANSFEREE") {
        let userPreferanceObject = {
          transfereeuniqeuid: userInfo.userUniqueId,
        };

        let userSavedPreferances = await ApiServices.GetTransfereePreferences(
          userPreferanceObject
        );
        console.log(userSavedPreferances, "userSavedPreferances");
        if (
          userSavedPreferances.error == 0 &&
          userSavedPreferances.message.preferences.length < 1
        ) {
          window.location.href = "/getStartedFirst";
        } else {
          window.location.href = "/dsLanding";
        }
      }
    }
  }

  async function fetchIPAddress() {
    const ipv6 = await publicIp.v4().then(
      (ip) => {
        return ip;
      },
      function (error) {
        return "Coudn't find your IP";
      }
    );
  }
  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }
  // check the user email id
  async function onSubmitHandler(emailId) {
    // updateState({ loaderlogin: true });

    const emailAddress = emailId;
    const loginObject = {
      username: emailId,
      authtoken: "",
      ipaddress: await fetchIPAddress(),
    };
    let userDetails = await fetchUserDetails(loginObject);
    console.log(userDetails);
  
    if (userDetails.error == "0") {

      if (loginObject.username !== "" && userDetails.error == "0") {
        console.log(userDetails, "userDetails")
        let userInfo = userDetails.message.userInfo;
        if (
          userInfo.type == "TRANSFEREE" &&
          (!userInfo.accessfromdate ||
            moment(new Date()).isBefore(userInfo.accessfromdate))
        ) {
          sendLoggedIndataToMobileApp("error");
        } else if (
          userInfo.type == "TRANSFEREE" &&
          (!userInfo.accesstodate ||
            moment(new Date()).isAfter(
              moment(userInfo.accesstodate).add(1, "days").format("MM/DD/YYYY")
            ))
        ) {
          sendLoggedIndataToMobileApp("error");
        } else {
        updateState({
          showOtpCheckComponent: true,
          phoneNumber: userInfo.PhoneNumber,
          email: userInfo.EmailId,
          countryCode: userInfo.countrycode,
          validateOtp: false,
          userType: userInfo.type,
          loader: true,
          userUniqueId: userInfo.userUniqueId,
          userInfo: userInfo,
        });
        navigateBasedOnUserType(userDetails.message.userInfo);
      }
      }else{
       
        sendLoggedIndataToMobileApp("error");
       
       // window.location.href = "/login";
      }
    }else{
      
      sendLoggedIndataToMobileApp("error");
      
     // window.location.href = "/login";
    }
  }
  const encodeData = (data) => {
    try {
      let encodeString = data
    for (var i = 0; i < 10; i++) {
      encodeString = base64_encode(encodeString)
    }
    return encodeString
    } catch (error) {
      return "error";
    }
    
  }
  const decodeData=(data)=> {
    try {
      let decodeString = data
    for (var i = 0; i < 10; i++) {
      decodeString = base64_decode(decodeString)
    }
    return decodeString;
    } catch (error) {
      return "error";
    }
    
  }
  async function sendLoggedIndataToMobileApp(str) {

  
if(str=='error'){
  try {
    window.ReactNativeWebView.postMessage('error')
  } catch (error) {
    
  }
 
}else{
  try {
    let encodeString = encodeData(str.EmailId);
    window.ReactNativeWebView.postMessage(JSON.stringify({type:str.type,subscriptioncode:str.subscriptioncode,istemporary:str.istemporary, EmailId:encodeString}));
    localStorage.setItem("LoggedInMobile", "true");
  } catch (error) {

  }
}

   
  }
 
  useEffect(() => {
    
      try{
        let userData = searchParams.get("userData");
          // let forceLoginValue = JSON.parse(userData);
          // console.log("forceLoginValue", forceLoginValue, forceLoginValue.EmailId);
          if(userData!=""){
             //console.log(forceLoginValue.EmailId);
              onSubmitHandler(decodeData(userData));
          }
      }catch (error) {
        console.log(error);
      }
      
  
  }, []);

  return (
  
    <div className="relonav-login ">
     <div className="mt-3">
      <span
        style={{
          fontSize: "20px",
          width: "100%",
          margin: "auto",
          textAlign: "center",
        }}
      >
        <i className="fa fa-spinner fa-spin" />
        <span style={{ marginLeft: "10px" }}> please wait ...!</span>{" "}
      </span>
    </div>
      
    </div>
  );
};



export default LoginForce;
