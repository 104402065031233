import React from "react";
import moment from "moment";

const ScheduleCard = ({ schedule, cardNo, showDeatils }) => {
  return (
    <div className="item">
      <b>{cardNo}</b>
      <h1>
        <i className="fa fa-circle"></i> {schedule.title}{" "}
      </h1>
      <ul>
        <li>{moment(schedule.scheduledate).format("LL")}</li>
        {/* <li>{schedule.pickuplocation}</li>
        <li> {schedule.schedulefromtime} </li> */}
        <li>{schedule.allotedHours}</li>
      </ul>
      <p>
        {schedule.schedulefromtime}-{schedule.scheduletotime}
      </p>
      <p>{schedule.pickuplocation}</p>
      <h2>With</h2>
      <p>
        {schedule.consultantfirstname} {schedule.consultantlastname}
      </p>
      <p>
        <i className="fa fa-phone"></i> {schedule.consultantphone}
      </p>
      <p>
        <i className="fa fa-envelope-open"></i> {schedule.consultantemailid}
      </p>
      {schedule.meetinglink != null && schedule.meetinglink != "" ? (
        <p className="view-more">
          <a href={schedule.meetinglink} target="_blank">
            Meeting Link
          </a>
        </p>
      ) : null}
      {schedule.itineraryPropertiesList.length > 0 ? (
        <p className="view-more">
          <a href="javascript:void(0)" onClick={() => showDeatils(schedule)}>
            View More
          </a>
        </p>
      ) : null}
    </div>
  );
};

export default ScheduleCard;
