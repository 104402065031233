import React from "react";

function ForwardingPopUp() {
  const closeModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#myModalForwardingMail").modal("hide");
      });
    });
  };
  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header border-0">
          <div class="only_close">
            <span
              aria-hidden="true"
              className="pointer close"
              onClick={() => closeModal()}
              data-dismiss="modal"
            >
              ×
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div className="widthModal">
            <div className="item-view">
              <h2>Standard Forward Mail and Change of Address</h2>
              <p>
                If you're moving, submit a permanent change-of-address request
                so your USPS mail is properly rerouted to your new address.{" "}
                <br />
                <br />
                If you're only relocating (anywhere from 15 days to 1 year) to
                visit your second home or do an extended stay with relatives,
                submit a temporary change-of-address request so you can receive
                your mail while you’re away.
              </p>
            </div>

            <div className="tails-row">
              <div className="tails">
                <div className="item-view">
                  <h2>Getting Started</h2>
                  <p>
                    It’s important to note that while mail forwarding may begin
                    within 3 business days of your submitted request, it's best
                    to allow up to 2 weeks. Mail will be forwarded as it comes,
                    piece by piece to your new or temporary address. 
                  </p>
                  <p>
                    You can either fill out these requests online at{" "}
                    <a
                      target="_blank"
                      href="https://moversguide.usps.com/mgo/disclaimer"
                    >
                      moversguide.usps.com
                    </a>{" "}
                    or by visiting your local Post Office. 
                  </p>
                </div>

                <div className="item-view">
                  <h2>How to Change Your Address Online </h2>
                  <ul>
                    <li>
                      Go to the Official USPS Change of Address® website at{" "}
                      <a
                        target="_blank"
                        href="https://moversguide.usps.com/mgo/disclaimer"
                      >
                        moversguide.usps.com
                      </a>
                    </li>
                    <li>Select Permanent or Temporary.</li>
                    <li>Pay the identity verification fee.</li>
                    <li>Complete the appropriate online form.</li>
                    <li>
                      USPS will email you a confirmation code. Use this code to
                      modify or cancel your request.
                    </li>
                    <li>
                      A welcome kit with coupons from USPS partners will arrive
                      at your new address.
                    </li>
                    <li>
                      Mail will be rerouted to your new address piece by piece.
                    </li>
                  </ul>
                </div>
                {/* <div className="text-center">
                                                    <button type="button" className="btn learnBtn">Learn More</button>
                                                </div> */}
              </div>
              <div className="tails">
                <div className="item-view">
                  <h2>What Mail is forwarded</h2>
                  <ul>
                    <li>
                      First-Class™ mail and periodicals (newsletters and
                      magazines) are forwarded for free.
                    </li>
                    <li>
                      Premium shipping services (Priority Mail, Priority Mail
                      Express, First-Class Package) are forwarded for free.
                    </li>
                    <li>
                      Media Mail and USPS Retail Ground are forwarded. (However,
                      you must pay the cost for shipping from your local Post
                      Office to your temporary address.)
                    </li>
                    <li>USPS Marketing Mail is not forwarded.</li>
                  </ul>
                  <p>
                    Your change-of-address order does NOT update any state or
                    personal records. You are still responsible for updating
                    your Department of Motor Vehicles (DMV), voter registration,
                    and banking information.
                  </p>
                </div>
                <div className="item-view">
                  <h2>How to Change Your Address at the Post Office </h2>
                  <ul>
                    <li>Visit your local Post Office location. </li>
                    <li>Request a free Mover's Guide packet.</li>
                    <li>
                      Fill out the PS Form 3575 found inside the packet and
                      submit. 
                    </li>
                  </ul>

                  {/* <div className="text-center">
                                                        <a href="#">View change of Address FAQs<br />
                                                            Change of Address Exceptions and Details (DMM 504.2.0)</a>
                                                    </div> */}
                </div>
              </div>
            </div>
            <p>
              Source:&nbsp;
              <a target="_blank" href="https://www.usps.com/manage/forward.htm">
                usps.com
              </a>
            </p>
          </div>
        </div>
        {/* <div className="modal-footer"></div> */}
      </div>
    </div>
  );
}

export default ForwardingPopUp;
