import React from "react";
// import Modal from "react-modal";
import { Modal } from "antd";

const Predetails = ({ isOpen, onClose, state }) => {
  return (
    <div className="modal-content">
      {/* <Modal isOpen={isOpen} onAfterClose={onClose} ariaHideApp={false}> */}
      <Modal open={isOpen} footer={null} onCancel={onClose}>
        <div className="new_modal_pop">
          <div>
            <h6>Appointment with {state.consultantName} </h6>
            <div className="modal-body">
              <div className="ul_select_list text_overfloww">
                <span>Appointment Type</span>
                <span className="main_span"> {state.EditEvent}</span>
              </div>
              <ul className="appoint_list">
                <li>
                  <a href="javascript:void(0)">
                    {" "}
                    Date <span>{state.popupDate}</span>{" "}
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    {" "}
                    Time{" "}
                    <span>
                      {state.startTime} - {state.endTime}
                    </span>{" "}
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    {" "}
                    Total Hours <span>{state.diffTime} Hours</span>{" "}
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    {" "}
                    Day <span>{state.dayNameLabel[state.dayName]}</span>
                  </a>
                </li>
              </ul>
              <div className="ul_select_list text_overfloww">
                <span>Pickup Location</span>
                <span className="main_span"> {state.pickupLocation}</span>
              </div>
              <div className="ul_select_list text_overfloww">
                <span>Notes</span>
                <span className="main_span"> {state.serviceNote}</span>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* </Modal> */}
    </div>
  );
};

export default Predetails;
