import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import jsPDF from "jspdf";
import moment from "moment";

function RentersPDFDowload() {
  const location = useLocation();
  //   const state = location.state;

  const queryParams = new URLSearchParams(location.search);

  // Convert query parameters into an object
  const state = Object.fromEntries(queryParams.entries());
  console.log(state, "state");

  useEffect(() => {
    // Generate and download PDF immediately when component mounts
    if (state) {
      generatePDF();

      // Optionally redirect back or to another page after a short delay
      setTimeout(() => {
        window.history.back(); // Go back to previous page
        // OR window.location.href = '/some-other-page'; // Redirect elsewhere
      }, 100);
    }
  }, []);

  const generatePDF = () => {
    const doc = new jsPDF("p", "pt");
    doc.text(39, 30, "Renters Resume");

    // Create all tables first
    createPDFContent(doc);

    // Try to add image, but don't wait for it
    try {
      var img = document.createElement("img");
      img.src = "https://relonavigator.com/assets/newImages/relo-b&WLogin.png";
      doc.addImage(img, "JPEG", 400, 10, 150, 13);
    } catch (e) {
      console.error("Failed to add image:", e);
    }

    // Save the PDF
    doc.save("rentersresume.pdf");
  };

  const createPDFContent = (doc) => {
    // Contact info table
    doc.autoTable({
      columnStyles: {
        contactinfo: { fillColor: [255, 255, 255], cellWidth: 190 },
        colon: { fillColor: [255, 255, 255], cellWidth: 50 },
        text: { fillColor: [255, 255, 255] },
      },
      headStyles: { fillColor: [220, 220, 220], textColor: [0, 0, 0] },
      body: [
        { contactinfo: "Name", colon: ":", text: state?.firstname || "" },
        { contactinfo: "Email address", colon: ":", text: state?.Email || "" },
        {
          contactinfo: "Phone number",
          colon: ":",
          text:
            state?.countrycode && state?.Phonenumber
              ? "+" + state.countrycode + " " + state.Phonenumber
              : "",
        },
      ],
      columns: [
        { header: "Contact Info", dataKey: "contactinfo" },
        { header: "", dataKey: "colon" },
        { header: "", dataKey: "text" },
      ],
    });

    // Preferences table
    doc.autoTable({
      columnStyles: {
        preferences: { fillColor: [255, 255, 255], cellWidth: 190 },
        colon: { fillColor: [255, 255, 255], cellWidth: 50 },
        text: { fillColor: [255, 255, 255] },
      },
      headStyles: { fillColor: [220, 220, 220], textColor: [0, 0, 0] },
      body: [
        { preferences: "Objective/ Housing desires:", colon: "", text: "" },
        [{ content: state?.Housing || "", colSpan: 3, styles: {} }],
        { preferences: "Preferences", colon: "", text: "" },
        {
          preferences: "Desired move-in date",
          colon: ":",
          text: state?.moveindate
            ? moment(state.moveindate).format("MM/DD/YYYY")
            : "",
        },
        { preferences: "Budget ($)", colon: ":", text: state?.Budget || "" },
        { preferences: "Bedrooms", colon: ":", text: state?.Bedrooms || "" },
        { preferences: "Bathrooms", colon: ":", text: state?.Bathrooms || "" },
        { preferences: "Specifications", colon: "", text: "" },
        { preferences: "Pets", colon: ":", text: state?.Pets || "" },
        {
          preferences: "Desired Lease term (Yrs.)",
          colon: ":",
          text: state?.DesiredLeaseterm || "",
        },
      ],
      columns: [
        { header: "Preferences", dataKey: "preferences" },
        { header: "", dataKey: "colon" },
        { header: "", dataKey: "text" },
      ],
    });

    // Other info table
    doc.autoTable({
      columnStyles: {
        otherinfo: { fillColor: [255, 255, 255], cellWidth: 190 },
        colon: { fillColor: [255, 255, 255], cellWidth: 50 },
        text: { fillColor: [255, 255, 255] },
      },
      headStyles: { fillColor: [220, 220, 220], textColor: [0, 0, 0] },
      body: [
        { otherinfo: "Background:", colon: "", text: "" },
        [{ content: state?.Background || "", colSpan: 3, styles: {} }],
        { otherinfo: "Employment", colon: "", text: "" },
        {
          otherinfo: "Employer name",
          colon: ":",
          text: state?.Employername || "",
        },
        {
          otherinfo: "Annual Income",
          colon: ":",
          text: state?.AnnualIncome || "",
        },
        { otherinfo: "Rental History", colon: "", text: "" },
        {
          otherinfo: "Previous rental property name",
          colon: ":",
          text: state?.Previousrentalpropertyname || "",
        },
        {
          otherinfo: "How long did you stay here?",
          colon: ":",
          text: state?.longstay || "",
        },
        {
          otherinfo: "Monthly rent ($)",
          colon: ":",
          text: state?.Monthlyrent || "",
        },
        {
          otherinfo: "Contact information",
          colon: ":",
          text: state?.Contactinformation || "",
        },
        {
          otherinfo: "Reason for leaving",
          colon: ":",
          text: state?.leaving || "",
        },
        { otherinfo: "References", colon: "", text: "" },
        {
          otherinfo: "Reference name",
          colon: ":",
          text: state?.Referencename || "",
        },
        { otherinfo: "Relation", colon: ":", text: state?.Relation || "" },
        {
          otherinfo: "Phone number",
          colon: ":",
          text:
            state?.RefPhonenumber && state?.Refcountrycode
              ? "+" + state.Refcountrycode + " " + state.RefPhonenumber
              : "",
        },
        { otherinfo: "Email address", colon: ":", text: state?.refEmail || "" },
        {
          otherinfo: "Additional Documents available upon request",
          colon: ":",
          text: state?.requested || "",
        },
      ],
      columns: [
        { header: "Other Info", dataKey: "otherinfo" },
        { header: "", dataKey: "colon" },
        { header: "", dataKey: "text" },
      ],
    });
  };

  // Return null - no HTML rendering at all
  return null;
}

export default RentersPDFDowload;
