import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import FilterComponent from "./common/FilterComponent";
import TableComponent from "./common/TableComponent";
import { Spin } from "antd";
import moment from "moment";
import LoginStore from "../../../../services/thirdPartyService";
import "./common/css/sreports.css";
import "./common/css/reports.css";
const TransfereeReports = () => {
  const [state, setState] = useState({
    supplierid: null,
    loader: false,
    reportslist: [],
    columns: [
      {
        title: "Consultant Email",
        dataIndex: "ConsultantEmailId",
        key: Math.random(),
      },
      {
        title: "Transferee Email",
        dataIndex: "TransfereeEmailId",
        key: "TransfereeEmailId",
      },
      {
        title: "Service level",
        dataIndex: "ServiceLevel",
        key: "ServiceLevel",
      },
      {
        title: "Access From",
        dataIndex: "AccessFromDate",
        key: "AccessFromDate",
      },
      {
        title: "Access to",
        dataIndex: "AccessToDate",
        key: "AccessToDate",
      },
      {
        title: "Total Hours",
        dataIndex: "TotalActualHrs",
        key: "TotalActualHrs",
        render: (TotalActualHrs) => {
          return (
            <span>
              {TotalActualHrs != null
                ? TotalActualHrs.substring(0, TotalActualHrs.indexOf(":"))
                : TotalActualHrs}
            </span>
          );
        },
      },
      {
        title: "Remaining",
        dataIndex: "TotalRemainingHrs",
        key: "TotalRemainingHrs",
        render: (TotalRemainingHrs) => {
          return (
            <span>
              {TotalRemainingHrs != null
                ? TotalRemainingHrs.substring(0, TotalRemainingHrs.indexOf(":"))
                : TotalRemainingHrs}
            </span>
          );
        },
      },
      {
        title: "Used",
        dataIndex: "TotalUsedHrs",
        key: "TotalUsedHrs",
        render: (TotalUsedHrs) => {
          return (
            <span>
              {TotalUsedHrs != null
                ? TotalUsedHrs.substring(0, TotalUsedHrs.indexOf(":"))
                : TotalUsedHrs}
            </span>
          );
        },
      },
    ],
    startDate: null,
    endDate: null,
    companydata: [],
    company: "",
    servicelevel: "",
  });

  function handleChangeStart(startDate) {
    updateState({ startDate });
  }
  function handleChangeEnd(endDate) {
    updateState({ endDate });
  }

  function inputHandler(field, value) {
    updateState({ [field]: value });
  }

  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  async function fetchUserInfoAndUpdateToState() {
    let getSessionData = await LoginStore.getLoginInfo({});
    updateState({ supplierid: getSessionData.userUniqueId });
  }

  useEffect(() => {
    fetchUserInfoAndUpdateToState();
  }, []);

  return (
    <div className="dashboard-ds">
      <div className="consultant-report-views">
        <div className="container">
          <div className="consultant-report-views-inputvalues">
            <div className="heaidng-view">
              <h1>Transeferee Report</h1>
              {state.reportslist.length > 0 && (
                <CSVLink
                  filename={"TableContent.csv"}
                  data={state.reportslist}
                  className="btn"
                >
                  Export CSV
                </CSVLink>
              )}
            </div>
            <FilterComponent
              reportType={"transferee"}
              fromDate={{
                startDate: state.startDate,
                endDate: state.endDate,
                handleChangeStart,
              }}
              toDate={{
                startDate: state.startDate,
                endDate: state.endDate,
                handleChangeEnd,
              }}
              company={{
                company: state.company,
                companydata: state.companydata,
                inputHandler: inputHandler,
              }}
              servicelevel={{
                inputHandler: inputHandler,
                servicelevel: state.servicelevel,
              }}
              updateState={updateState}
              supplierid={state.supplierid}
            />
          </div>
          {state.loader ? (
            <Spin />
          ) : (
            <TableComponent
              reportslist={state.reportslist}
              columns={state.columns}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TransfereeReports;
