import React, { useEffect, useState } from "react";
import ApiServices from "../../../../../services/apiServices";
import { Spin } from "antd";
const Preferences = ({ userUniqueId }) => {
  const [state, setState] = useState({
    answersPrefer: [],
    isLoading: false,
  });

  function updateLoaderState(action) {
    if (action === "start") {
      updateState({ isLoading: true });
    } else if (action === "stop") {
      updateState({ isLoading: false });
    }
  }

  async function getUserPreferences(userUniqueId) {
    updateLoaderState("start");

    let userPreferanceObject = {
      transfereeuniqeuid: userUniqueId,
      istemporary: false,
    };

    try {
      let userSavedPreferances = await ApiServices.GetTransfereePreferences(
        userPreferanceObject
      );
      if (userSavedPreferances.error == 0) {
        let userAnswerPreferanceObject =
          userSavedPreferances.message.preferences;
        console.log(userAnswerPreferanceObject, "userAnswerPreferanceObject");

        updateState({
          answersPrefer: userAnswerPreferanceObject,
        });
      }
    } catch (e) {
      console.log(e);
    }

    updateLoaderState("stop");
  }

  function updateState(updateObj) {
    console.log(updateObj);
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  useEffect(() => {
    if (userUniqueId) {
      getUserPreferences(userUniqueId);
    }
  }, []);

  return (
    <div
      id="Preferences"
      className="tab-pane fade mt-5 tabPreferences active show"
    >
      <div className="lis-view">
        {state.isLoading ? <Spin /> : null}
        {state.answersPrefer.length > 0 && !state.isLoading
          ? state.answersPrefer.map((row, index) => {
              return (
                <div key={"question" + index}>
                  <label>{row.question}</label>
                  {row.type == "money-range" ? (
                    <span>
                      $
                      {row.preferencesanswer
                        .replace(/(^,)|(,$)/g, "")
                        .replace(",", " To $")}
                    </span>
                  ) : row.type == "Time-range" ? (
                    <span>
                      From{" "}
                      {row.preferencesanswer
                        .replace(/(^,)|(,$)/g, "")
                        .replace(",", " Mins To ")}{" "}
                      Mins
                    </span>
                  ) : (
                    <span>
                      {row.type == "CheckBox"
                        ? row.preferencesanswer == "true"
                          ? "Yes"
                          : "No"
                        : row.preferencesanswer.replace(/(^,)|(,$)/g, "")}
                    </span>
                  )}
                </div>
              );
            })
          : null}
        {state.answersPrefer.length === 0 && !state.isLoading ? (
          <div className="modal-body">
            <label>Yet to fill the preferences</label>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Preferences;
