import React, { useState, useEffect } from "react";
import * as $ from "jquery";
import moment from "moment";
import { Spin } from "antd";
import ApiServices from "../../../../../services/apiServices";
import { useLocation } from "react-router-dom";
const Note = ({ onClickNotes, handleClose }) => {
  const location = useLocation();

  const intialState = {
    notesShowSuccess: false,
    noteserrorMessage: "",
    notesshowingerror: "",
    notess: "",
  };
  const storedUserDetails = localStorage.getItem("moredetails");
  const userDetails = JSON.parse(storedUserDetails);
  const user =
    location.state && location.state.row ? location.state.row : userDetails;
  const transfereeuid = user.userUniqueId; //location.state.row.userUniqueId;

  useEffect(() => {
    onClickNotes(transfereeuid);
  }, []);

  const [state, setState] = useState(intialState);

  async function addNotes(e) {
    e.preventDefault();
    const transfereeuid = user.userUniqueId; //location.state.row.userUniqueId;
    let errorUpdate = 0;
    if (state.notess.trim() == "") {
      $("#notess").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#notess").removeClass("is-invalid");
    }

    if (errorUpdate == 0) {
      let data = {
        transfereenotesuid: "",
        transfereeuid: transfereeuid,
        notes: state.notess,
        createddate: moment()
          .tz("America/Los_Angeles")
          .format("MM/DD/YYYY HH:mm:ss"),
        isactive: true,
      };
      updateState({ uploadLoader: true });
      console.log(data, "data of the note");
      const ManageTransfereeNotes = await ApiServices.ManageTransfereeNotes(
        data
      );

      if (ManageTransfereeNotes.error == 0) {
        let data = { transfereeuid };
        const GetTransfereeNotes = await ApiServices.GetTransfereeNotes(data);

        onClickNotes(transfereeuid);
        if (GetTransfereeNotes.error == 0) {
          await updateState({
            notesShowSuccess: true,
            notess: "",
            uploadLoader: false,
          });
          $("#noteschat").animate(
            { scrollTop: $("#noteschat").prop("scrollHeight") },
            1000
          );
        }
      } else {
        updateState({
          noteserrorMessage: true,
          notesshowingerror: "something went wrong!",
          uploadLoader: false,
        });
      }
    } else {
      updateState({
        noteserrorMessage: true,
        notesshowingerror: "Please enter note..!",
        uploadLoader: false,
      });
    }
  }

  async function handleChanger(name, e) {
    // console.log(name, "====", e.target.value);
    let value = e.target.value;

    let object = {};
    object[name] = value;
    if (value == null || value == "") {
      $("#" + name).addClass("is-invalid");
    } else {
      $("#" + name).removeClass("is-invalid");
    }
    updateState(object);
  }

  async function updateState(data) {
    await setState((prevState) => ({
      ...prevState,
      ...data,
    }));
  }

  async function closeModal() {
    // $("#myModalAddNotes").modal("hide");
    handleClose("note");
    setState(intialState);
  }

  return (
    <div
      className="modal fade modalAddActivity"
      id="myModalAddNotes"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      style={{ zIndex: 1060 }}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content ">
          <div className="width-70per pd-y-50">
            <div className="only_close">
              <span
                onClick={() => closeModal()}
                className="close pointer"
                aria-hidden="true"
                data-dismiss="modal"
                aria-label="Close"
              >
                &times;
              </span>
            </div>

            {state.notesShowSuccess == true ? (
              ""
            ) : (
              <h6 className="modal-title" id="exampleModalLongTitle">
                Notes
              </h6>
            )}
            {state.noteserrorMessage == true ? (
              <div className="pop-form-controls focus-input-label">
                <div className="pt-3">
                  <h6>{state.notesshowingerror}</h6>
                </div>
              </div>
            ) : (
              ""
            )}

            {state.notesShowSuccess == true ? (
              <div className="pop-form-controls focus-input-label mb-4">
                <div className="">
                  <h6 className="alert alert-success mb-5">
                    Note added successfully!
                  </h6>
                </div>
              </div>
            ) : (
              <div className="modal-body">
                <div className="formAddActivity">
                  <form
                    method="post"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <textarea
                          className="form-control"
                          rows="6"
                          value={state.notess}
                          placeholder="Update notes here"
                          id="notess"
                          onChange={(e) => {
                            handleChanger("notess", e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="text-center mt-3 mb-3">
                      {state.uploadLoader ? (
                        <Spin />
                      ) : (
                        <button
                          type="submit"
                          className="btn theme-btn"
                          onClick={(e) => addNotes(e)}
                        >
                          SAVE<span></span>
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Note;

// import React, { useState, useEffect } from "react";
// import * as $ from "jquery";
// import moment from "moment";
// import { Spin } from "antd";
// import ApiServices from "../../../../../services/apiServices";
// import { useLocation } from "react-router-dom";
// const Note = ({ onClickNotes, handleClose }) => {
//   const location = useLocation();

//   const intialState = {
//     notesShowSuccess: false,
//     noteserrorMessage: "",
//     notesshowingerror: "",
//     notess: "",
//   };
//   const transfereeuid = location.state.row.userUniqueId;
//   useEffect(() => {
//     onClickNotes(transfereeuid);
//   }, []);

//   const [state, setState] = useState(intialState);

//   async function addNotes(e) {
//     e.preventDefault();
//     let errorUpdate = 0;
//     if (state.notess.trim() == "") {
//       $("#notess").addClass("is-invalid");
//       errorUpdate++;
//     } else {
//       $("#notess").removeClass("is-invalid");
//     }

//     if (errorUpdate == 0) {
//       let data = {
//         transfereenotesuid: "",
//         transfereeuid: state.transfereeuid,
//         notes: state.notess,
//         createddate: moment()
//           .tz("America/Los_Angeles")
//           .format("MM/DD/YYYY HH:mm:ss"),
//         isactive: true,
//       };
//       updateState({ uploadLoader: true });
//       const ManageTransfereeNotes = await ApiServices.ManageTransfereeNotes(
//         data
//       );
//       const transfereeuid = location.state.row.userUniqueId;
//       if (ManageTransfereeNotes.error == 0) {
//         let data = { transfereeuid };
//         const GetTransfereeNotes = await ApiServices.GetTransfereeNotes(data);

//         onClickNotes(transfereeuid);
//         if (GetTransfereeNotes.error == 0) {
//           await updateState({
//             notesShowSuccess: true,
//             notess: "",
//             uploadLoader: false,
//           });
//           $("#noteschat").animate(
//             { scrollTop: $("#noteschat").prop("scrollHeight") },
//             1000
//           );
//         }
//       } else {
//         updateState({
//           noteserrorMessage: true,
//           notesshowingerror: "something went wrong!",
//           uploadLoader: false,
//         });
//       }
//     } else {
//       updateState({
//         noteserrorMessage: true,
//         notesshowingerror: "Please enter note..!",
//         uploadLoader: false,
//       });
//     }
//   }

//   async function handleChanger(name, e) {
//     let value = e.target.value;

//     let object = {};
//     object[name] = value;
//     if (value == null || value == "") {
//       $("#" + name).addClass("is-invalid");
//     } else {
//       $("#" + name).removeClass("is-invalid");
//     }
//     updateState(object);
//   }

//   async function updateState(data) {
//     await setState((prevState) => ({
//       ...prevState,
//       ...data,
//     }));
//   }

//   async function closeModal() {
//     handleClose("note");
//     setState(intialState);
//   }

//   return (
//     <div
//       className="modal fade modalAddActivity new_modal_pop"
//       id="myModalAddNotes"
//       tabIndex="-1"
//       role="dialog"
//       aria-labelledby="exampleModalCenterTitle"
//       aria-hidden="true"
//     >
//       <div
//         className="modal-dialog modal-dialog-centered modal-lg"
//         role="document"
//       >
//         <div className="modal-content ">
//           <div className="width-70per pd-y-50">
//             <div className="only_close">
//               <span
//                 onClick={() => closeModal()}
//                 className="close pointer"
//                 aria-hidden="true"
//                 data-dismiss="modal"
//                 aria-label="Close"
//               >
//                 &times;
//               </span>
//             </div>

//             {state.notesShowSuccess == true ? (
//               ""
//             ) : (
//               <h2 className="modal-title" id="exampleModalLongTitle">
//                 Notes
//               </h2>
//             )}
//             {state.noteserrorMessage == true ? (
//               <div className="pop-form-controls focus-input-label">
//                 <div className="pt-3">
//                   <h6 className="alert alert-warning">
//                     {state.notesshowingerror}
//                   </h6>
//                 </div>
//               </div>
//             ) : (
//               ""
//             )}

//             {state.notesShowSuccess == true ? (
//               <div className="pop-form-controls focus-input-label mb-4">
//                 <div className="">
//                   <h6 className="alert alert-success mb-5">
//                     Notes added successfully!
//                   </h6>
//                 </div>
//               </div>
//             ) : (
//               <div className="modal-body">
//                 <div className="formAddActivity">
//                   <form
//                     method="post"
//                     onSubmit={(e) => {
//                       e.preventDefault();
//                     }}
//                   >
//                     <div className="row">
//                       <div className="col-md-12">
//                         <textarea
//                           className="form-control"
//                           rows="6"
//                           value={state.notess}
//                           placeholder="Update notes here"
//                           id="notess"
//                           onChange={(e) => {
//                             handleChanger("notess", e);
//                           }}
//                         />
//                       </div>
//                     </div>
//                     <div className="text-center mt-3 mb-3">
//                       {state.uploadLoader ? (
//                         <Spin />
//                       ) : (
//                         <button
//                           type="submit"
//                           className="btn theme-btn"
//                           onClick={(e) => addNotes(e)}
//                         >
//                           SAVE<span></span>
//                         </button>
//                       )}
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Note;
