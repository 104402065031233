import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
// import "./resume.css";
import * as $ from "jquery";
import CountriesJson from "./Countries.json";
import LoginStore from "../../../../services/thirdPartyService";
import AppController from "../../../controllers/appController";
import DatePicker from "react-datepicker";
import { faCalendarAlt } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Radio, Modal } from "antd";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";
// import Modal from "react-modal";
import ThirdParty from "../../../../services/thirdPartyService";
import Header from "../../../common/header/Header";
import Footer from "../../../common/footer/Footer";
import ResumePopUp from "./modalPopUp/resumePopUp/resumePopUp";
import "intl-tel-input/build/css/intlTelInput.css"; // Import CSS file
import intlTelInput from "intl-tel-input";
import IntTelDropDown from "../../../common/internationalPhone/IntTelDropDown";

function RentersResume() {
  // const phoneNumberRef1 = useRef(null);
  // const phoneNumberRef2 = useRef(null);
  const [state, setState] = useState({
    typePop: "",
    popUp: false,
    success: false,
    error: false,
    pageNumber: 1,
    firstname: "",
    Email: "",
    Phonenumber: "",
    countrycode: "",

    Housing: "",
    moveindate: null,
    Budget: "",
    Bedrooms: "",
    Bathrooms: "",
    Pets: "",
    DesiredLeaseterm: "",

    Background: "",
    Employername: "",
    AnnualIncome: "",
    Previousrentalpropertyname: "",
    longstay: "",
    Contactinformation: "",
    Monthlyrent: "",
    leaving: "",
    Referencename: "",
    Relation: "",
    RefPhonenumber: "",
    Refcountrycode: "",
    refEmail: "",
    proof: "",

    youremail: "",
    yournotes: "",
    requested: "No",
    spanloader: false,
  });
  useEffect(() => {
    async function getSessionData() {
      window.scrollTo({ top: 0, behavior: "smooth" });
      try {
        let getSessionData = await LoginStore.getLoginInfo({});
        await AppController.logData(
          "Renters Resume",
          "Resources",
          location.pathname,
          getSessionData.userUniqueId,
          ""
        );
        setState((prevState) => ({
          ...prevState,
          firstname:
            getSessionData.userFirstName + " " + getSessionData.userLastName,
          Email: getSessionData.EmailId,
          Phonenumber: getSessionData.PhoneNumber,
          countrycode: getSessionData.countrycode,
        }));
      } catch (error) {
        console.log(error);
      }
    }
    getSessionData();
  }, []);
  // useEffect(() => {
  //   const iti1 = intlTelInput(phoneNumberRef1.current, {
  //     separateDialCode: true,
  //     initialCountry: "us",
  //   });

  //   phoneNumberRef1.current.addEventListener("countrychange", () => {
  //     const selectedCountryData = iti1.getSelectedCountryData();
  //     updateState({ countrycode: selectedCountryData["dialCode"] });
  //   });

  //   return () => {
  //     iti1.destroy();
  //   };
  // }, []);

  function updateState(data) {
    setState((prevState) => ({
      ...prevState,
      ...data,
    }));
  }
  function handleCountryCodeChange(code) {
    updateState({ Refcountrycode: code });
  }
  const nextPage = async (page) => {
    // console.log(page, "===");
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (state.countrycode != "") {
      let errorUpdate = 0;
      $("#Phonenumber").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#Phonenumber").removeClass("is-invalid");
    }
    if (state.Refcountrycode != "") {
      let errorUpdate = 0;
      $("#RefPhonenumber").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#RefPhonenumber").removeClass("is-invalid");
    }
    if (page == 2) {
      let errorUpdate = 0;
      if (state.firstname == "") {
        $("#firstname").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#firstname").removeClass("is-invalid");
      }
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.Email)) {
        $("#Email").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#Email").removeClass("is-invalid");
      }
      if (
        /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(
          state.Phonenumber
        ) !== true
      ) {
        $("#Phonenumber").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#Phonenumber").removeClass("is-invalid");
      }
      if (errorUpdate == 0) {
        await await setState((prevState) => ({
          ...prevState,
          pageNumber: page,
        }));
      }
    } else if (page == 3) {
      let errorUpdate = 0;
      if (state.Housing == "") {
        $("#Housing").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#Housing").removeClass("is-invalid");
      }
      if (state.moveindate == null) {
        $("#moveindate").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#moveindate").removeClass("is-invalid");
      }
      if (state.Budget == "") {
        $("#Budget").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#Budget").removeClass("is-invalid");
      }
      if (state.Bedrooms == "") {
        $("#Bedrooms").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#Bedrooms").removeClass("is-invalid");
      }
      if (state.Bathrooms == "") {
        $("#Bathrooms").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#Bathrooms").removeClass("is-invalid");
      }
      if (state.Pets == "") {
        $("#Pets").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#Pets").removeClass("is-invalid");
      }
      if (state.DesiredLeaseterm == "") {
        $("#DesiredLeaseterm").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#DesiredLeaseterm").removeClass("is-invalid");
      }
      if (errorUpdate == 0) {
        // console.log(page, "++++");
        await setState((prevState) => ({
          ...prevState,
          pageNumber: page,
        }));
      }
    } else if (page == 4) {
      // console.log(page, "page");
      let errorUpdate = 0;
      // $(document).ready(function () {
      //   setTimeout(function () {
      //     $("#cmd").click();
      //   }, 1);
      // });

      // console.log("hello");
      if (
        /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(
          state.RefPhonenumber
        ) !== true
      ) {
        // console.log("error");
        $("#RefPhonenumber").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#RefPhonenumber").removeClass("is-invalid");
      }

      if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.refEmail)
      ) {
        $("#refEmail").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#refEmail").removeClass("is-invalid");
      }

      if (state.requested == "") {
        $("#requested").addClass("is-invalid");
        errorUpdate++;
        await setState((prevState) => ({
          ...prevState,
          requestChecked: false,
        }));
      } else {
        $("#requested").removeClass("is-invalid");
        await setState((prevState) => ({
          ...prevState,
          requestChecked: true,
        }));
      }
      if (errorUpdate == 0) {
        await setState((prevState) => ({
          ...prevState,
          pageNumber: page,
        }));
        // Scroll to the top of the page
        window.scrollTo({ top: 0, behavior: "smooth" }); // Smooth scrolling
        // scrollToTop();
      }
    } else {
      await setState((prevState) => ({
        ...prevState,
        pageNumber: page,
      }));
    }
  };

  function scrollToTop() {
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, scrollTop - scrollTop / 2);
    }
  }
  const inputHandler = async (event) => {
    const inputVal = event.target.value;
    const inputName = event.target.name;
    const phoneRegex = /^\d{0,10}$/;
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    setState((prevState) => {
      const newState = { ...prevState };

      if (inputName === "firstname") {
        newState.firstname = inputVal;
      } else if (inputName === "Email" || inputName === "refEmail") {
        if (!emailRegex.test(inputVal.trim())) {
          $("#" + inputName).addClass("is-invalid");
        } else {
          $("#" + inputName).removeClass("is-invalid");
        }
        newState[inputName] = inputVal;
      } else if (inputName === "Phonenumber") {
        if (phoneRegex.test(inputVal.trim())) {
          $("#" + inputName).addClass("is-invalid");
        } else {
          $("#" + inputName).removeClass("is-invalid");
        }
        newState.Phonenumber = inputVal;
      } else if (inputName === "Relation" || inputName === "Referencename") {
        const value = inputVal.replace(/[^A-Za-z ]/gi, "");
        newState[inputName] = value;
      } else if (inputName !== "" || inputVal !== "") {
        newState[inputName] = inputVal;
      }

      if (inputVal.trim() == null || inputVal.trim() == "") {
        $("#" + inputName).addClass("is-invalid");
      } else {
        $("#" + inputName).removeClass("is-invalid");
      }

      return newState;
    });
  };
  const handleDateChange = async (moveindate) => {
    await setState((prevState) => ({
      ...prevState,
      moveindate: new Date(moveindate),
    }));
  };
  const handleKeyPress = (e) => {
    const key = e.key;
    const regex = /[0-9.]/; // Allow numeric digits and a period (dot)

    if (e.keyCode === 8 || regex.test(key)) {
      return;
    }
    e.preventDefault();
  };
  const onChange = async (e) => {
    await setState((prevState) => ({
      ...prevState,
      requested: e.target.value,
    }));
  };
  const backPage = async (page) => {
    await setState((prevState) => ({
      ...prevState,
      pageNumber: page,
    }));
  };
  const pdfmethod = async () => {
    console.log("Click");
    var doc = new jsPDF("p", "pt");
    doc.text(39, 30, "Renters Resume");
    var img = document.createElement("img");
    img.src = "https://relonavigator.com/assets/newImages/relo-b&WLogin.png";
    doc.addImage(img, "JPEG", 400, 10, 150, 13);
    doc.autoTable({
      columnStyles: {
        contactinfo: { fillColor: [255, 255, 255], cellWidth: 190 },
        colon: { fillColor: [255, 255, 255], cellWidth: 50 },
        text: { fillColor: [255, 255, 255] },
      },
      headStyles: { fillColor: [220, 220, 220], textColor: [0, 0, 0] },
      body: [
        { contactinfo: "Name", colon: ":", text: state.firstname },
        { contactinfo: "Email address", colon: ":", text: state.Email },
        {
          contactinfo: "Phone number",
          colon: ":",
          text: "+" + state.countrycode + " " + state.Phonenumber,
        },
      ],
      columns: [
        { header: "Contact Info", dataKey: "contactinfo" },
        { header: "", dataKey: "colon" },
        { header: "", dataKey: "text" },
      ],
    });
    doc.autoTable({
      columnStyles: {
        preferences: { fillColor: [255, 255, 255], cellWidth: 190 },
        colon: { fillColor: [255, 255, 255], cellWidth: 50 },
        text: { fillColor: [255, 255, 255] },
      },
      headStyles: { fillColor: [220, 220, 220], textColor: [0, 0, 0] },
      body: [
        { preferences: "Objective/ Housing desires:", colon: "", text: "" },
        [{ content: state.Housing, colSpan: 3, styles: {} }],
        { preferences: "Preferences", colon: "", text: "" },
        {
          preferences: "Desired move-in date",
          colon: ":",
          text: moment(state.moveindate).format("MM/DD/YYYY"),
        },
        { preferences: "Budget ($)", colon: ":", text: state.Budget },
        { preferences: "Bedrooms", colon: ":", text: state.Bedrooms },
        { preferences: "Bathrooms", colon: ":", text: state.Bathrooms },
        { preferences: "Specifications", colon: "", text: "" },
        { preferences: "Pets", colon: ":", text: state.Pets },
        {
          preferences: "Desired Lease term (Yrs.)",
          colon: ":",
          text: state.DesiredLeaseterm,
        },
      ],
      columns: [
        { header: "Preferences", dataKey: "preferences" },
        { header: "", dataKey: "colon" },
        { header: "", dataKey: "text" },
      ],
    });
    doc.autoTable({
      columnStyles: {
        otherinfo: { fillColor: [255, 255, 255], cellWidth: 190 },
        colon: { fillColor: [255, 255, 255], cellWidth: 50 },
        text: { fillColor: [255, 255, 255] },
      },
      headStyles: { fillColor: [220, 220, 220], textColor: [0, 0, 0] },
      body: [
        { otherinfo: "Background:", colon: "", text: "" },
        [{ content: state.Background, colSpan: 3, styles: {} }],
        { otherinfo: "Employment", colon: "", text: "" },
        {
          otherinfo: "Employer name",
          colon: ":",
          text: state.Employername,
        },
        {
          otherinfo: "Annual Income",
          colon: ":",
          text: state.AnnualIncome,
        },
        { otherinfo: "Rental History", colon: "", text: "" },
        {
          otherinfo: "Previous rental property name",
          colon: ":",
          text: state.Previousrentalpropertyname,
        },
        {
          otherinfo: "How long did you stay here?",
          colon: ":",
          text: state.longstay,
        },
        {
          otherinfo: "Monthly rent ($)",
          colon: ":",
          text: state.Monthlyrent,
        },
        {
          otherinfo: "Contact information",
          colon: ":",
          text: state.Contactinformation,
        },
        {
          otherinfo: "Reason for leaving",
          colon: ":",
          text: state.leaving,
        },
        { otherinfo: "References", colon: "", text: "" },
        {
          otherinfo: "Reference name",
          colon: ":",
          text: state.Referencename,
        },
        { otherinfo: "Relation", colon: ":", text: state.Relation },
        {
          otherinfo: "Phone number",
          colon: ":",
          text:
            state.RefPhonenumber != ""
              ? "+" + state.Refcountrycode + " " + state.RefPhonenumber
              : "",
        },
        { otherinfo: "Email address", colon: ":", text: state.refEmail },
        {
          otherinfo: "Additional Documents available upon request",
          colon: ":",
          text: state.requested,
        },
      ],
      columns: [
        { header: "Other Info", dataKey: "otherinfo" },
        { header: "", dataKey: "colon" },
        { header: "", dataKey: "text" },
      ],
    });
    var specialElementHandlers = {
      "#editor": function (element, renderer) {
        return true;
      },
    };
    doc.fromHTML($("#content").html(), 15, 15, {
      width: 170,
      elementHandlers: specialElementHandlers,
    });
    doc.save("rentersresume.pdf");
  };

  // $("#cmd")
  //   .off("click")
  //   .on("click", function () {
  //     console.log("clicked");
  //     pdfmethod();
  //   });
  const sendmail = async () => {
    let errorUpdate = 0;
    if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.youremail)
    ) {
      $("#youremail").addClass("is-invalid");
      errorUpdate++;
    } else {
      $("#youremail").removeClass("is-invalid");
    }
    if (errorUpdate == 0) {
      let data = {
        firstname: state.firstname,
        Email: state.Email,
        Phonenumber: "+" + state.countrycode + " " + state.Phonenumber,
        countrycode: state.countrycode,

        Housing: state.Housing,
        moveindate: moment(state.moveindate).format("MM/DD/YYYY"),
        Budget: state.Budget,
        Bedrooms: state.Bedrooms,
        Bathrooms: state.Bathrooms,
        Pets: state.Pets,
        DesiredLeaseterm: state.DesiredLeaseterm,

        Background: state.Background,
        Employername: state.Employername,
        AnnualIncome: state.AnnualIncome,
        Previousrentalpropertyname: state.Previousrentalpropertyname,
        longstay: state.longstay,
        Contactinformation: state.Contactinformation,
        Monthlyrent: state.Monthlyrent,
        leaving: state.leaving,
        Referencename: state.Referencename,
        Relation: state.Relation,
        RefPhonenumber: "+" + state.Refcountrycode + " " + state.RefPhonenumber,
        Refcountrycode: state.Refcountrycode,
        refEmail: state.refEmail,
        requested: state.requested,
        requestChecked: true,
        // proof:   state.proof,

        email: state.youremail,
        yournotes: state.yournotes,
        therentalresume: true,
        subject: "Renters Resume",
      };
      // console.log(data, "data");
      let sendSms = await ThirdParty.sendEmail(data);
      if (sendSms.response == true) {
        setState((prev) => ({
          ...prev,
          success: true,
          popUp: true,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          success: true,
          error: true,
          popUp: true,
        }));
      }
    }
  };
  const openPopModal = () => {
    setState((prev) => ({
      ...prev,
      popUp: true,
      typePop: "renters",
    }));
  };
  return (
    <div className="outer-container">
      {/* <Header /> */}
      <div className="relo-step-from">
        <div className="container">
          {state.pageNumber != 4 && (
            <div className="tracker-view">
              <h1>Renters Resume</h1>
              <ul>
                <li
                  className={
                    state.pageNumber == 2 || state.pageNumber == 3
                      ? "is-complete"
                      : "is-active"
                  }
                >
                  <div className="bar"></div>
                  <div className="text">
                    <h3>Contact Info</h3>
                  </div>
                </li>
                <li
                  className={
                    state.pageNumber == 2
                      ? "is-active"
                      : state.pageNumber == 1
                      ? ""
                      : "is-complete"
                  }
                >
                  <div className="bar"></div>
                  <div className="text">
                    <h3>Perference</h3>
                  </div>
                </li>
                <li className={state.pageNumber == 3 ? "is-active" : ""}>
                  <div className="bar"></div>
                  <div className="text">
                    <h3>Other Info</h3>
                  </div>
                </li>
              </ul>
              <p className="disclaimer">
                *We do not store any of the below information. It is solely used
                to generate the resume.
              </p>
            </div>
          )}
          {state.pageNumber == 1 && (
            <div>
              <form className="c-info">
                <h1>Contact Info</h1>
                <div className="row">
                  <div className="col-md-6">
                    {" "}
                    <div className="form-group">
                      <label>Full Name*</label>
                      <input
                        placeholder="Full Name"
                        type="text"
                        value={state.firstname}
                        className="form-control"
                        id="firstname"
                        name="firstname"
                        onChange={(e) => inputHandler(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    {" "}
                    <div className="form-group">
                      <label>Email Address*</label>
                      <input
                        placeholder="Email Address"
                        type="text"
                        value={state.Email}
                        className="form-control"
                        id="Email"
                        name="Email"
                        onChange={(e) => inputHandler(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    {" "}
                    <div className="form-group">
                      <label>Phone Number*</label>
                      <div className="phone-flag">
                        <IntTelDropDown
                          handleCountryCodeChange={handleCountryCodeChange}
                        />
                        <input
                          // ref={phoneNumberRef1}
                          type="tel"
                          placeholder="Phone Number"
                          onKeyDown={handleKeyPress}
                          className="form-control"
                          id="Phonenumber"
                          name="Phonenumber"
                          maxLength={10}
                          value={state.Phonenumber || ""}
                          onChange={(e) => inputHandler(e)}
                          // onBlur={(e) => inputHandler("Phonenumber", e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-right my-4">
                    <button
                      type="button"
                      className="btn"
                      onClick={(e) => nextPage(2)}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
          {state.pageNumber == 2 && (
            <div>
              <form>
                <h1>Preferences</h1>
                <div className="form-group">
                  <label>Objective*</label>
                  <textarea
                    className="form-control"
                    value={state.Housing}
                    id="Housing"
                    name="Housing"
                    rows="3"
                    onChange={(e) => inputHandler(e)}
                    placeholder="Define your goals in living at this residence and why you’re a great choice as a tenant."
                  ></textarea>
                </div>
                <h2>Preferences</h2>
                <div className="inner-view">
                  <div className="form-group">
                    <label>Desired Move-In Date*</label>
                    <DatePicker
                      selected={state.moveindate}
                      onChange={handleDateChange}
                      minDate={new Date()}
                      id={"moveindate"}
                      dateFormat="MM/dd/yyyy"
                      placeholderText={"MM/DD/YYYY"}
                      onKeyDown={(e) => e.preventDefault()}
                      className="form-control"
                    />
                    {/* <FontAwesomeIcon icon={faCalendarAlt} /> */}
                  </div>

                  <div className="form-group">
                    <label>Budget ($)*</label>
                    <input
                      placeholder="Enter Amount"
                      onKeyDown={handleKeyPress}
                      type="text"
                      value={state.Budget}
                      className="form-control"
                      id="Budget"
                      name="Budget"
                      onChange={(e) => inputHandler(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Bed Rooms*</label>
                    <select
                      value={state.Bedrooms}
                      className="form-control"
                      id="Bedrooms"
                      name="Bedrooms"
                      onChange={(e) => inputHandler(e)}
                    >
                      <option value="">Choose...</option>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={"5+"}>5+</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Bath Rooms*</label>
                    <select
                      value={state.Bathrooms}
                      className="form-control"
                      id="Bathrooms"
                      name="Bathrooms"
                      onChange={(e) => inputHandler(e)}
                    >
                      <option value="">Choose...</option>
                      <option value={1}>1</option>
                      <option value={1.5}>1.5</option>
                      <option value={2}>2</option>
                      <option value={2.5}>2.5</option>
                      <option value={3}>3</option>
                      <option value={3.5}>3.5</option>
                      <option value={4}>4</option>
                      <option value={4.5}>4.5</option>
                      <option value={5}>5</option>
                      <option value={"5+"}>5+</option>
                    </select>
                  </div>
                </div>
                <h2>Specifications</h2>
                <div className="form-group width-47per">
                  <label>Desired Lease Term (Yrs.)*</label>
                  <select
                    value={state.DesiredLeaseterm}
                    className="form-control"
                    id="DesiredLeaseterm"
                    name="DesiredLeaseterm"
                    onChange={(e) => inputHandler(e)}
                  >
                    <option value="">Choose...</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Pets*</label>
                  <textarea
                    type="text"
                    rows="2"
                    value={state.Pets}
                    className="form-control"
                    id="Pets"
                    name="Pets"
                    placeholder="Enter Pets Details"
                    onChange={(e) => inputHandler(e)}
                  ></textarea>
                </div>
                <div className="text-right two-btns-view my-4">
                  <button
                    type="button"
                    className="btn btn-back"
                    onClick={(e) => backPage(1)}
                  >
                    Back{" "}
                  </button>
                  <button
                    type="button"
                    className="btn"
                    onClick={(e) => nextPage(3)}
                  >
                    Next{" "}
                  </button>
                </div>
              </form>
            </div>
          )}
          {state.pageNumber == 3 && (
            <div>
              <form>
                <h1>Other Info</h1>
                <div className="form-group">
                  <label>Background</label>
                  <textarea
                    value={state.Background}
                    className="form-control"
                    id="Background"
                    name="Background"
                    onChange={(e) => inputHandler(e)}
                    placeholder="Briefly explain the property manager about where you are from, your current employment, Education and Interests."
                  ></textarea>
                </div>
                <h2>Employment</h2>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>Employer Name</label>
                    <input
                      type="text"
                      value={state.Employername}
                      className="form-control"
                      id="Employername"
                      name="Employername"
                      onChange={(e) => inputHandler(e)}
                      placeholder="Employer Name"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Annual Income ($)</label>
                    <input
                      type="text"
                      onKeyDown={handleKeyPress}
                      value={state.AnnualIncome}
                      className="form-control"
                      id="AnnualIncome"
                      name="AnnualIncome"
                      onChange={(e) => inputHandler(e)}
                      placeholder="Annual Income"
                    />
                  </div>
                </div>
                <h2>Rental History</h2>
                <div className="row rental-view">
                  <div className="form-group col-md-4">
                    <label>Previous rental property name</label>
                    <input
                      type="text"
                      value={state.Previousrentalpropertyname}
                      className="form-control"
                      id="Previousrentalpropertyname"
                      name="Previousrentalpropertyname"
                      onChange={(e) => inputHandler(e)}
                      placeholder=""
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label>How long did you stay here? (Yrs.)</label>
                    <select
                      value={state.longstay}
                      className="form-control"
                      id="longstay"
                      name="longstay"
                      onChange={(e) => inputHandler(e)}
                    >
                      <option value="">Choose...</option>
                      <option value={1}>1</option>
                      <option value={"1+"}>1+</option>
                      <option value={"2+"}>2+</option>
                      <option value={"3+"}>3+</option>
                      <option value={"4+"}>4+</option>
                      <option value={"5+"}>5+</option>
                    </select>
                  </div>

                  <div className="form-group col-md-4">
                    <label>Monthly Rent ($)</label>
                    <input
                      type="text"
                      onKeyDown={handleKeyPress}
                      value={state.Monthlyrent}
                      className="form-control"
                      id="Monthlyrent"
                      name="Monthlyrent"
                      onChange={(e) => inputHandler(e)}
                      placeholder="Enter amount"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Contact Information</label>
                    <textarea
                      value={state.Contactinformation}
                      className="form-control"
                      id="Contactinformation"
                      name="Contactinformation"
                      onChange={(e) => inputHandler(e)}
                      placeholder="Type here"
                    ></textarea>
                  </div>
                  <div className="form-group col-md-6">
                    <label>Reason for leaving</label>
                    <textarea
                      value={state.leaving}
                      className="form-control"
                      id="leaving"
                      name="leaving"
                      onChange={(e) => inputHandler(e)}
                      placeholder="Type here"
                    ></textarea>
                  </div>
                </div>
                <h2>References</h2>
                <div className="inner-view">
                  <div className="form-group">
                    <label>Reference Name</label>
                    <input
                      type="text"
                      value={state.Referencename}
                      className="form-control"
                      id="Referencename"
                      name="Referencename"
                      onChange={(e) => inputHandler(e)}
                      placeholder="Reference Name"
                    />
                  </div>
                  <div className="form-group">
                    <label>Relation</label>
                    <input
                      type="text"
                      value={state.Relation}
                      className="form-control"
                      id="Relation"
                      name="Relation"
                      onChange={(e) => inputHandler(e)}
                      placeholder="Relation"
                    />
                  </div>
                  <div className="form-group">
                    <label>Phone Number*</label>
                    <div className="position-relative flag-posotion">
                      <div className="phone-flag">
                        <IntTelDropDown
                          handleCountryCodeChange={handleCountryCodeChange}
                        />
                        <input
                          // ref={phoneNumberRef2}
                          type="tel"
                          placeholder="Phone Number"
                          onKeyDown={handleKeyPress}
                          className="form-control pl-5"
                          id="RefPhonenumber"
                          name="RefPhonenumber"
                          maxLength={10}
                          value={state.RefPhonenumber || ""}
                          onChange={(e) => inputHandler(e)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Email Address*</label>
                    <input
                      type="text"
                      value={state.refEmail}
                      className="form-control"
                      id="refEmail"
                      name="refEmail"
                      onChange={(e) => inputHandler(e)}
                      placeholder="Email Address"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Additional Documents available upon request</label>
                  <div>
                    <Radio.Group
                      onChange={onChange}
                      id="requested"
                      value={state.requested}
                    >
                      <label className="radio-inline">
                        {" "}
                        <Radio value={"Yes"}>Yes</Radio>
                      </label>
                      <label className="radio-inline">
                        {" "}
                        <Radio value={"No"} checked>
                          No
                        </Radio>
                      </label>
                    </Radio.Group>
                  </div>
                  {state.requestChecked == "" ? (
                    <span className="radioValidate">
                      Please choose Additional Documents available request
                    </span>
                  ) : null}
                </div>
                <div className="text-right two-btns-view my-4">
                  <button
                    type="button"
                    className="btn btn-back"
                    onClick={(e) => backPage(2)}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    className="btn"
                    onClick={(e) => nextPage(4)}
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          )}
          {state.pageNumber == 4 && (
            <div>
              <div className="submit-download-view">
                <div className="item">
                  <img
                    className="btnImg"
                    src="/assets/images/pdf-download.png"
                  />
                  <div>
                    <div id="content" className="divContent"></div>
                    <div id="editor"></div>
                    <button
                      id="cmd"
                      onClick={() => pdfmethod()}
                      className="btn"
                    >
                      Download
                    </button>
                  </div>
                </div>
                <div className="item">
                  <img
                    className="btnImgEmail"
                    src="/assets/images/send-email.png"
                  />
                  <button
                    type="button"
                    className="btn "
                    onClick={(e) => openPopModal()}
                  >
                    Send
                  </button>
                </div>
              </div>
              <div className="mt-5 text-center">
                <button
                  type="button"
                  className="btn btn-back"
                  onClick={(e) => backPage(3)}
                >
                  <i class="fa-regular fa-arrow-left me-2"></i> {"  "}Go Back
                  and Edit
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
      <ResumePopUp
        state={state}
        closePop={() => setState({ ...state, popUp: false })}
        inputHandler={inputHandler}
        setState={setState}
        sendmail={sendmail}
      />
    </div>
  );
}

export default RentersResume;
