import React, { useState, useEffect } from "react";
import ThirdParty from "../../../../services/thirdPartyService";
import "./home.css";
import "./contact.css";
import Header from "../../../common/header/Header";
import Footer from "../../../common/footer/Footer";
const ContactUs = () => {
  const [state, setState] = useState({
    lastname: "",
    subject: "",
    firstname: "",
    email: "",
    description: "",
    contactError: false,
    succesMsg: "",
    error: "",
    emailfirst: "",
    namefirst: "",
    descriptions: "",
    succesMsgs: "",
    errors: "",
    sessionObject: true,
  });
  const feedBack = async () => {
    await setState((prev) => ({
      ...prev,
      succesMsg: "",
      contactError: "",
      error: "",
      subject: "",
      description: "",
      firstname: "",
      lastname: "",
      email: "",
    }));
    $("document").ready(function () {
      $("#contact-us-modal-feed").modal("show");
      $("#contact-us-modal-feed").find("input,textarea").val("").end();
    });
  };
  const inputHandler = async (event) => {
    let inputVal = event.target.value;
    let inputName = event.target.name;
    let newState = { ...state };
    newState[inputName] = inputVal;
    if (
      inputName == "FromEmail" &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputVal.trim())
    ) {
      await setState((prev) => ({
        ...prev,
        contactError: "Please enter a valid emailid",
      }));
      $("#" + inputName).addClass("is-invalid");
    } else {
      await setState((prev) => ({ ...prev, contactError: "" }));
      $("#" + inputName).removeClass("is-invalid");
    }
    if (inputName == "phoneNumber") {
      jQuery(function ($) {
        var x = event.target.value
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        event.target.value = !x[2]
          ? x[1]
          : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        var countryData = $("#phoneNumber").intlTelInput(
          "getSelectedCountryData"
        );

        setState((prev) => ({
          ...prev,
          phoneNumber: event.target.value,
          countrycode: countryData.dialCode,
        }));
      });
    }
    if (inputVal.trim() == null || inputVal.trim() == "") {
      $("#" + inputName).addClass("is-invalid");
    } else {
      $("#" + inputName).removeClass("is-invalid");
    }
    setState(newState);
  };
  const saveContactUs = async (event) => {
    event.preventDefault();
    let errorUpdate = 0;
    if (state.firstname.trim() == "") {
      $("#firstname").addClass("is-invalid");
      await setState((prev) => ({ ...prev, loader: false }));
      errorUpdate++;
    } else {
      $("#firstname").removeClass("is-invalid");
    }
    if (state.lastname.trim() == "") {
      $("#lastname").addClass("is-invalid");
      await setState((prev) => ({ ...prev, loader: false }));
      errorUpdate++;
    } else {
      $("#lastname").removeClass("is-invalid");
    }

    if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.FromEmail)
    ) {
      $("#FromEmail").addClass("is-invalid");
      await setState((prev) => ({ ...prev, loader: false }));
      errorUpdate++;
    } else {
      $("#FromEmail").removeClass("is-invalid");
    }
    if (state.description.trim() == "") {
      $("#description").addClass("is-invalid");
      await setState((prev) => ({ ...prev, loader: false }));
      errorUpdate++;
    } else {
      $("#description").removeClass("is-invalid");
    }
    if (state.subject.trim() == "") {
      $("#subject").addClass("is-invalid");
      await setState((prev) => ({ ...prev, loader: false }));
      errorUpdate++;
    } else {
      $("#subject").removeClass("is-invalid");
    }
    if (errorUpdate == 0) {
      let data = {
        subject: state.subject,
        description: state.description,
        firstname: state.firstname,
        lastname: state.lastname,
        istheFeedback: true,
        FromEmail: state.FromEmail,
        email: "hello@relonavigator.com",
        cc: state.FromEmail,
      };
      let sendSms = await ThirdParty.sendEmail(data);
      await setState((prev) => ({
        ...prev,
        succesMsg: "Thank you for your feedback",
        subject: "",
        description: "",
        firstname: "",
        lastname: "",
        phoneNumber: "",
        FromEmail: "",
      }));
      $("document").ready(function () {
        $("#contact-us-modal").find("input,textarea").val("").end();
      });
    }
  };
  const sendContactUs = async (event, value) => {
    event.preventDefault();
    if (value == "feedback") {
      let errorUpdate = 0;
      if (state.firstname.trim() == "") {
        $("#firstname").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#firstname").removeClass("is-invalid");
      }
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.email)) {
        $("#email").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#email").removeClass("is-invalid");
      }
      if (state.description.trim() == "") {
        $("#description").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#description").removeClass("is-invalid");
      }

      if (state.lastname.trim() == "") {
        $("#lastname").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#lastname").removeClass("is-invalid");
      }
      if (state.subject.trim() == "") {
        $("#subject").addClass("is-invalid");
        errorUpdate++;
      } else {
        $("#subject").removeClass("is-invalid");
      }
      if (errorUpdate == 0) {
        var datase = {
          subject: state.subject,
          description: state.description,
          firstname: state.firstname,
          lastname: state.lastname,
          theContactus: true,
          FromEmail: state.email,
          email: "hello@relonavigator.com",
          cc: state.email,
        };
        let sendSms = await ThirdParty.sendEmail(datase);
        if (sendSms.response == true) {
          await setState((prev) => ({
            ...prev,
            succesMsg: "Mail has been sent Successfully!",
            succesMsgs: "",
            contactError: "",
            error: "",
            subject: "",
            description: "",
            firstname: "",
            lastname: "",
            email: "",
          }));
        } else {
          await setState((prev) => ({ ...prev, error: "Please try again!" }));
        }
      }
    }
    if (value == "send") {
      let errorUpdat = 0;
      if (state.namefirst.trim() == "") {
        $("#namefirst").addClass("is-invalid");
        errorUpdat++;
      } else {
        $("#namefirst").removeClass("is-invalid");
      }
      if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.emailfirst)
      ) {
        $("#emailfirst").addClass("is-invalid");
        errorUpdat++;
      } else {
        $("#emailfirst").removeClass("is-invalid");
      }
      if (state.descriptions.trim() == "") {
        $("#descriptions").addClass("is-invalid");
        errorUpdat++;
      } else {
        $("#descriptions").removeClass("is-invalid");
      }
      if (errorUpdat == 0) {
        var datase = {
          FromEmail: state.emailfirst,
          description: state.descriptions,
          theContactusHelp: true,
          firstname: state.namefirst,
          subject: "How can we help?",
          email: "hello@relonavigator.com",
          cc: state.emailfirst,
        };
        let sendSms = await ThirdParty.sendEmail(datase);
        if (sendSms.response == true) {
          await setState((prev) => ({
            ...prev,
            succesMsgs: "Mail has been sent Successfully!",
            succesMsg: "",
            errors: "",
            emailfirst: "",
            descriptions: "",
            namefirst: "",
          }));
        } else {
          await setState((prev) => ({ ...prev, errors: "Please try again!" }));
        }
      }
    }
  };
  const Pclose = () => {
    $("document").ready(function () {
      $("#firstname, #lastname, #email, #description, #subject").removeClass(
        "is-invalid"
      );
      $("#contact-us-modal-feed").modal("hide");
    });
  };
  return (
    <div id="outer-container">
      <div style={{ fontFamily: "Poppins, sans-serif" }}>
        <div className="mt-5 pt-4 clearfix"></div>
        <div className="contact-page-banner">
          <div className="contact-page-banner-layer">
            <div className="container">
              <div className="contact-page-banner-text">
                <h1> Contact Us</h1>
                <p>
                  Whether you need assistance or have a question about our
                  service, we're here to help.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="article-list contact-feedback">
          <div className="container">
            <div className="intro"></div>
            <div className="row articles justify-content-center">
              <div className="col-sm-6 col-md-4 item">
                <i className="fa fa-comment-o fa-3x"></i>
                <h3
                  className="name theme-clr"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => feedBack(e)}
                >
                  Send Us Feedback
                </h3>
                <p className="description">
                  Questions, suggestion, feedback? Send it over, we'll get back
                  to you.
                  <br />
                </p>
              </div>
              <div className="col-sm-6 col-md-4 item">
                <i className="fa fa-phone fa-3x"></i>
                <h3 className="name theme-clr">(800) 367-9501</h3>
                <p className="description">
                  Talk to a real person or leave us a message 24X7
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-clean">
          <form
            method="post"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <h2 className="text-center mb-3">
              How can we help?
              <br />
            </h2>
            <h4 className="text-center mb-4">
              Send us your inquiry and we'll promptly get back to you.
              <br />
            </h4>
            {state.succesMsgs != "" && (
              <h6 className="alert alert-success">{state.succesMsgs}</h6>
            )}
            {state.errors != "" && (
              <h6 className="alert alert-danger">{state.errors}</h6>
            )}
            <br />
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="namefirst"
                value={state.namefirst}
                id="namefirst"
                style={{ justifyContent: "center" }}
                onChange={(event) => inputHandler(event)}
                placeholder="Name"
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                id="emailfirst"
                style={{ justifyContent: "center" }}
                value={state.emailfirst}
                onChange={(event) => inputHandler(event)}
                name="emailfirst"
                placeholder="Email"
              />
              {state.contactError ? (
                <small className="form-text text-danger">
                  Please enter a correct email address.
                </small>
              ) : (
                ""
              )}
            </div>
            <div className="form-group">
              <textarea
                value={state.descriptions}
                style={{ justifyContent: "center" }}
                onChange={(event) => inputHandler(event)}
                id="descriptions"
                className="form-control"
                name="descriptions"
                placeholder="Message"
                rows="14"
              ></textarea>
            </div>
            <div className="form-group text-center">
              <button
                className="btn btn-primary btn-block"
                style={{ marginLeft: "-1px" }}
                onClick={(event) => sendContactUs(event, "send")}
                type="submit"
              >
                send{" "}
              </button>
            </div>
          </form>
        </div>
      </div>

      <div
        id="contact-us-modal-feed"
        className="modal fade"
        role="dialog"
        style={{ zIndex: 1060 }}
        data-backdrop="static"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="width-80per pd-y-50">
              <div className="only_close">
                <span
                  className="pointer close"
                  data-dismiss="modal"
                  onClick={Pclose}
                >
                  ×
                </span>
              </div>
              <h6>Feedback Form</h6>
              <div className="focus-input-label mt-3 padding-x-50">
                {state.succesMsg != "" && (
                  <h6 className="alert alert-success">{state.succesMsg}</h6>
                )}
                {state.error != "" && (
                  <h6 className="alert alert-danger">{state.error}</h6>
                )}
                {state.succesMsg == "" && (
                  <form
                    method="post"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <h1> </h1>
                    <div className="">
                      <div className="main-row">
                        <div className="item">
                          <div className="floating-label">
                            <input
                              placeholder="First Name"
                              type="text"
                              value={state.firstname}
                              className="form-control"
                              name="firstname"
                              id="firstname"
                              onChange={(e) => inputHandler(e)}
                            />
                            <label>First Name</label>
                          </div>
                        </div>
                        <div className="item">
                          <div className="floating-label">
                            <input
                              placeholder="Last Name"
                              type="text"
                              name="lastname"
                              value={state.lastname}
                              className="form-control"
                              id="lastname"
                              onChange={(e) => inputHandler(e)}
                            />
                            <label>Last Name</label>
                          </div>
                        </div>
                      </div>
                      <div className="floating-label">
                        <input
                          placeholder="email"
                          type="text"
                          name="email"
                          className="form-control"
                          id="email"
                          value={state.email}
                          onChange={(e) => inputHandler(e)}
                        />
                        <label>Email</label>
                      </div>
                      <div className="floating-label">
                        <input
                          type="text"
                          placeholder="Subject"
                          name="subject"
                          className="form-control"
                          id="subject"
                          value={state.subject}
                          onChange={(e) => inputHandler(e)}
                        />
                        <label>Subject</label>
                      </div>
                      <div className="floating-label">
                        <textarea
                          className="form-control"
                          rows="6"
                          name="description"
                          placeholder="Describe Feedback"
                          value={state.description}
                          id="description"
                          onChange={(e) => inputHandler(e)}
                        />
                        <label>Note</label>
                      </div>
                    </div>
                    <div className="form-group text-center mt-5  mb-4">
                      <button
                        className="btn theme-btn"
                        type="button"
                        onClick={(event) => sendContactUs(event, "feedback")}
                      >
                        Submit <span></span>{" "}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
