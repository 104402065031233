import React, { useEffect, useState } from "react";
// import "./citysList.css";
import ApiServices from "../../../../services/apiServices";
import LoginStore from "../../../../services/thirdPartyService";
import _ from "lodash";
import { Tooltip } from "antd";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import Header from "../../../common/header/Header";
import Footer from "../../../common/footer/Footer";
import axios from "axios";

function Citylist() {
  const [state, setState] = useState({
    cityLoader: false,
    latitude: "",
    longitude: "",
    nearbycities: [],
    nearByCitiesWithStates: [],
    tabItems: "",
    answerPrefer: [],
    noCitiesMsg: "",
    userFirstName: "",
    userLastName: "",
    EmailId: "",
    workAddress: "",
    commuteTime: "",
    cityListStopDataNot: false,
    onSelectfromdropdown: true,
    data: {
      latitude: "",
      city_name: "",
      city: "",
      address: "185 Channel Street",
    },
    soundScoreDetails: {
      result: [],
    },
    cityCount: false,
    citiesWithImages: [],
    citiesWithoutImages: [],
  });
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    setState((prev) => ({
      ...prev,
      cityLoader: true,
    }));
    async function fetchData() {
      try {
        let getSessionData = await LoginStore.getLoginInfo({});
        setState((prev) => ({
          ...prev,
          EmailId: getSessionData.EmailId,
          userFirstName: getSessionData.userFirstName,
          userLastName: getSessionData.userLastName,
          workAddress: getSessionData.worklocationaddress1,
        }));
        let userPreferanceObject = {
          transfereeuniqeuid: getSessionData.userUniqueId,
          istemporary: getSessionData.istemporary,
        };
        let userSavedPreferances = await ApiServices.GetTransfereePreferences(
          userPreferanceObject
        );
        if (userSavedPreferances.error == "0") {
          let userAnswerPreferanceObject =
            userSavedPreferances.message.preferences;
          if (userAnswerPreferanceObject != undefined) {
            let answersPrefer = { ...state.answerPrefer };
            _.forEach(userAnswerPreferanceObject, function (answerObject) {
              answersPrefer[answerObject.preferencesmasteruniqueid] =
                answerObject;
            });
            let savedAnswers = answersPrefer;
            getWorkAddress(savedAnswers);
            setState((prev) => ({
              ...prev,
              answerPrefer: answersPrefer,
            }));
          }
        }
        // save work address in localstorage
        async function getWorkAddress(savedAnswers) {
          let getSessionData = await LoginStore.getLoginInfo({});
          let userObject = {
            transfereeuniqeuid: getSessionData.userUniqueId,
            istemporary: getSessionData.istemporary,
          };
          let getQuestionList = await ApiServices.GetPreferencesList(
            userObject
          );
          let answersprefered = savedAnswers;
          if (getQuestionList.message.preferencesList != undefined) {
            let questionList = getQuestionList.message.preferencesList;

            let workAddressArray = _.filter(questionList, function (o) {
              return o.question == "Work address";
            });
            let commuteTimeArray = _.filter(questionList, function (o) {
              return o.question == "Commute time";
            });
            await localStorage.setItem(
              "workAddress",
              JSON.stringify(answersprefered[workAddressArray[0].preferenceUId])
            );

            if (commuteTimeArray && commuteTimeArray.length > 0) {
              // Your code here

              await localStorage.setItem(
                "commuteTime",
                JSON.stringify(
                  answersprefered[commuteTimeArray[0].preferenceUId]
                )
              );
            } else {
              await localStorage.setItem("commuteTime", 15);
            }

            let preferenceAnswer =
              answersprefered[workAddressArray[0].preferenceUId]
                .preferencesanswer;
            setState((prev) => ({
              ...prev,
              workAddress: preferenceAnswer,
            }));
          }
        }
        // getting latitude & longitude values from localstorage
        let newWorkAddress = await localStorage.getItem("workAddress");
        if (newWorkAddress != undefined) {
          let workAddress;
          workAddress = JSON.parse(await localStorage.getItem("workAddress"));
          let workAddressupdate = workAddress.preferencesanswer;
          let latitude = JSON.parse(
            await localStorage.getItem("workAddress")
          ).latitude;
          let longitude = JSON.parse(
            await localStorage.getItem("workAddress")
          ).longitude;
          // console.log(workAddress.preferencesanswer, "workAddressupdate");
          const address = workAddressupdate;
          // console.log(address, "address");

          const city = address.split(",")[1]?.trim(); // Splits the address by comma and extracts the second part, then trims any leading/trailing whitespace
          // console.log(city, "city"); // Output: Los Angeles
          // if (city != undefined) {
          setState((prev) => ({
            ...prev,
            latitude: workAddress.latitude,
            longitude: workAddress.longitude,
            state: workAddressupdate,
            city: city,
            address: workAddress.preferencesanswer,
          }));
          getCityList(latitude, longitude);
          // } else {
          //   window.location.reload();
          // }

          // setState((prev) => ({
          //   ...prev,
          //   latitude: workAddress.latitude,
          //   longitude: workAddress.longitude,
          //   state: workAddressupdate,
          //   city: city,
          //   address: workAddress.preferencesanswer,
          // }));
          // getCityList(latitude, longitude);
        }
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  }, []);
  const noCitiesList = async () => {
    try {
      let newWorkAddress = await localStorage.getItem("workAddress");
      if (newWorkAddress != undefined) {
        let workAddress;
        workAddress = JSON.parse(await localStorage.getItem("workAddress"));
        let workAddressupdate = workAddress.preferencesanswer;

        const address = workAddressupdate;

        const city = address.split(",")[1]?.trim(); // Splits the address by comma and extracts the second part, then trims any leading/trailing whitespace
        let cityData = {
          city: city,
          state: workAddressupdate,
          latitude: workAddress.latitude,
          longitude: workAddress.longitude,
          address: workAddress.preferencesanswer,
          imageUrl: 0,
          cityHighlights: 0,
          demoGraphics: 0,
          cityDetails: 0,
          miles: 0,
          bikescore: 0,
          walkscore: 0,
          cityaddress: true,
        };
        await localStorage.setItem("cDetails", JSON.stringify(cityData));
        navigate("/city-landing", { state: { fromCommute: true } });
        // console.log(cityData, "cityData");
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  // getting radius from localstorage
  async function getCityList(latitude, longitude) {
    try {
      let commuteTime = JSON.parse(
        localStorage.getItem("commuteTime") || "null"
      );
      let commutePreferAnswer = commuteTime?.preferencesanswer;
      let commuteTimeInMin =
        commuteTime == "undefined" || 15
          ? 15
          : commutePreferAnswer?.split(",")[1];

      let commuteRadius = (commuteTimeInMin * 45) / 60;
      let getSessionData = await LoginStore.getLoginInfo({});
      let reqObj = {
        latitude: latitude,
        longitude: longitude,
        radious: Math.round(commuteRadius),
        transfereeuid: getSessionData.userUniqueId,
      };
      let nearbyCity = await ApiServices.GetAllCities(reqObj);
      // console.log("success", nearbyCity);
      let citylist = nearbyCity.message.citieslist;
      // let citylist = [];
      if (nearbyCity.error == "0" && citylist.length > 0) {
        setState((prev) => ({
          ...prev,
          nearbycities: nearbyCity.message.citieslist,
          cityCount: true,
        }));
        getCitiesWithState(nearbyCity);
      } else {
        console.log("no cities ");
        await noCitiesList();

        setState((prev) => ({
          ...prev,
          cityLoader: false,
        }));
      }
    } catch (error) {
      setState((prev) => ({
        ...prev,
        cityLoader: false,
      }));
    }
  }
  // get citieswithstates
  async function getCitiesWithState(nearbyCity) {
    try {
      let nearbyCityData = nearbyCity.message.citieslist;
      if (Array.isArray(nearbyCityData) && nearbyCityData.length > 0) {
        let nearbycities = nearbyCityData.map((item) => {
          item.city = item.city;
          item.state = item.state_id;
          item.latitude = item.lat;
          item.longitude = item.lng;
          item.imageUrl = item.imageUrl;
          item.cityHighlights = item.cityHighlights;
          item.demoGraphics = item.demoGraphics;
          item.cityDetails = item.cityDetails;
          item.miles = Math.floor(item.distanc_in_mils);
          item.soundscore = (
            <i className="fa fa-spinner fa-spin" style={{ fontSize: "14px" }} />
          );
          item.bikescore = item.BikeScore > 0 ? item.BikeScore : 0;
          item.walkscore = item.WalkScore > 0 ? item.WalkScore : 0;
          return item;
        });

        // Separate cities into arrays based on presence of image
        let citiesWithImages = nearbycities.filter(
          (item) =>
            item.imageUrl &&
            item.imageUrl.trim() !== "" &&
            item.imageUrl !== undefined
        );
        let citiesWithoutImages = nearbycities.filter(
          (item) =>
            !item.imageUrl ||
            item.imageUrl.trim() === "" ||
            item.imageUrl === undefined
        );
        // console.log(nearbycities, "nearbycities");
        setState((prev) => ({
          ...prev,
          cityLoader: false,
          nearByCitiesWithStates: nearbycities,
          citiesWithImages: citiesWithImages,
          citiesWithoutImages: citiesWithoutImages,
          // nearByCitiesWithStates: [],
        }));
        // console.log(nearbycities, "55");
      }
    } catch (error) {
      setState((prev) => ({
        ...prev,
        cityLoader: false,
      }));
    }
  }
  const cityDetails = async (row) => {
    // console.log(row, "row");
    await localStorage.setItem("cDetails", JSON.stringify(row));
    navigate("/city-landing");
    window.scrollTo({ top: 0, behavior: "smooth" }); // Smooth scrolling
  };
  const handleSubmitLocation = async () => {
    let row = {
      city: state.city,
      state: state.state,
      latitude: state.latitude,
      longitude: state.longitude,
      address: state.address,
      imageUrl: 0,
      cityHighlights: 0,
      demoGraphics: 0,
      cityDetails: 0,
      miles: 0,
      bikescore: 0,
      walkscore: 0,
      cityaddress: true,
    };
    if (
      state.onSelectfromdropdown == true &&
      state.city != "" &&
      state.city != undefined &&
      state.latitude != "" &&
      state.latitude != undefined &&
      state.longitude != "" &&
      state.longitude != undefined
    ) {
      await localStorage.setItem("cDetails", JSON.stringify(row));
      //location.href = '/city-landing';
      if (state.noCitiesMsg != "") {
        // location.href = "/city-landing";
        // navigate("/city-landing");
        navigate("/city-landing", { state: { fromCommute: true } });
      } else {
        // window.open("/city-landing", "myWindow");
        // navigate("/city-landing");
        navigate("/city-landing", { state: { fromCommute: true } });
      }
    }
  };
  const setLocation = async (Id, e) => {
    let address = e.target.value;
    setState((prev) => ({
      ...prev,
      [Id]: address,
      onSelectfromdropdown: false,
    }));

    var places = new google.maps.places.Autocomplete(
      document.getElementById(Id),
      { types: ["address"] }
    );
    places.setComponentRestrictions({ country: ["us"] });
    google.maps.event.addListener(places, "place_changed", async () => {
      var place = places.getPlace();
      var placeDetails = {
        locality: "long_name",
        administrative_area_level_1: "short_name",
        country: "long_name",
      };
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (placeDetails[addressType]) {
          var val = place.address_components[i][placeDetails[addressType]];
          placeDetails[addressType] = val;
        }
      }
      let relocatedAddress = place.formatted_address;
      // console.log(relocatedAddress, "relocated");
      setState((prev) => ({ ...prev, onSelectfromdropdown: true }));
      //await _ setState({ address: relocatedAddress,submitEnable:relocatedAddress !== '' || relocatedAddress !== null ? false : true });

      setState((prev) => ({
        ...prev,
        address: relocatedAddress,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        state: placeDetails.administrative_area_level_1,
        city: placeDetails.locality,
        submitEnable:
          relocatedAddress !== "" || relocatedAddress !== null ? false : true,
      }));
    });
  };
  return (
    <div id="outer-container">
      {/* <Header /> */}
      {state.cityLoader && <div className="loading"></div>}
      <div className="container">
        <div className="" style={{ minHeight: "600px" }}>
          {state.nearByCitiesWithStates.length > 0 &&
          state.noCitiesMsg == "" ? (
            <p className="cityListDesclimber">
              Below are the cities within your preferable commute time from your
              work location. Choose a city below to explore detailed information
              including schools, weather, points of interest, and more. <br />
              <br /> <b>Cities are listed from closest to farthest.</b>
            </p>
          ) : (
            ""
          )}
          {state.cityListStopDataNot == true ||
          state.nearByCitiesWithStates.length > 0 ? (
            <div className="explore-nearby-view">
              <form
                className="navbar-form"
                role="search"
                onSubmit={(event) => event.preventDefault()}
              >
                <div className="input-group add-on">
                  <i className="fa fa-search"></i>
                  <input
                    className="form-control"
                    value={state.address}
                    placeholder="Search"
                    name="address"
                    id="address"
                    onChange={(e) => setLocation("address", e)}
                    type="text"
                    autoComplete="off"
                  />
                  <div className="input-group-btn">
                    <button
                      className="btn btn-default"
                      style={{
                        backgroundColor:
                          state.onSelectfromdropdown == true ? "" : "lightgrey",
                      }}
                      type="button"
                      onClick={() => handleSubmitLocation()}
                      disabled={
                        state.onSelectfromdropdown == true ? false : true
                      }
                    >
                      Search
                    </button>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            ""
          )}
          {state.nearByCitiesWithStates.length > 0 &&
          state.noCitiesMsg == "" ? (
            <div className="min-height-50vh">
              <div className="my_properties_lists">
                <div className="main_grid">
                  {_.sortBy(state.citiesWithImages, function (obj) {
                    return parseFloat(obj.miles, 10);
                  }).map((row, index) => {
                    return (
                      <div className="main-item item" key={index}>
                        <div className="item2 cursor-pointer">
                          <label>
                            {row.imageUrl != undefined &&
                              row.imageUrl != null && (
                                <img
                                  src={row.imageUrl}
                                  alt=""
                                  onClick={() => cityDetails(row)}
                                />
                              )}
                          </label>

                          <div
                            className={
                              row.imageUrl != undefined && row.imageUrl != null
                                ? "content mt-2"
                                : "content mt-2 no-space"
                            }
                          >
                            <a onClick={() => cityDetails(row)}>
                              {row.city}{" "}
                              <i className="fa-regular fa-arrow-up-right"></i>
                            </a>

                            <div className="d-flex justify-content-between score">
                              <p>
                                Walk Score - {"   "}
                                <span>{row.walkscore}</span>
                                <Tooltip
                                  title={
                                    row.walkscore >= 90
                                      ? "Walker's Paradise, Daily errands do not require a car."
                                      : row.walkscore >= 70 &&
                                        row.walkscore < 90
                                      ? "Very Walkable, Most errands can be accomplished on foot."
                                      : row.walkscore >= 50 &&
                                        row.walkscore < 70
                                      ? "Somewhat Walkable, Some errands can be accomplished on foot."
                                      : row.walkscore >= 25 &&
                                        row.walkscore < 50
                                      ? "Car-Dependent, Most errands require a car."
                                      : "Car-Dependent, Almost all errands require a car."
                                  }
                                >
                                  <i
                                    className="fa-solid fa-info-circle"
                                    aria-hidden="true"
                                  ></i>
                                </Tooltip>
                              </p>
                              <p>
                                Bike Score - {"   "}{" "}
                                <span>{row.bikescore}</span>
                                <Tooltip
                                  title={
                                    row.bikescore >= 90
                                      ? "Biker's Paradise, Daily errands can be accomplished on a bike."
                                      : row.bikescore >= 70 &&
                                        row.bikescore < 90
                                      ? "Very Bikeable, Biking is convenient for most trips."
                                      : row.bikescore >= 50 &&
                                        row.bikescore < 70
                                      ? "Bikeable, Some bike infrastructure."
                                      : "Somewhat Bikeable, Minimal bike infrastructure."
                                  }
                                >
                                  <i
                                    className="fa-solid fa-info-circle"
                                    aria-hidden="true"
                                  ></i>{" "}
                                </Tooltip>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="main_grid no-images">
                  {_.sortBy(state.citiesWithoutImages, function (obj) {
                    return parseFloat(obj.miles, 10);
                  }).map((row, index) => {
                    return (
                      <div className="main-item item" key={index}>
                        <div className="item2 cursor-pointer">
                          <label>
                            {row.imageUrl != undefined &&
                              row.imageUrl != null && (
                                <img
                                  src={row.imageUrl}
                                  alt=""
                                  onClick={() => cityDetails(row)}
                                />
                              )}
                          </label>

                          <div
                            className={
                              row.imageUrl != undefined && row.imageUrl != null
                                ? "content mt-2"
                                : "content mt-2 no-space"
                            }
                          >
                            <a onClick={() => cityDetails(row)}>
                              {row.city}{" "}
                              <i className="fa-regular fa-arrow-up-right"></i>
                            </a>

                            <div className="d-flex justify-content-between score">
                              <p>
                                Walk Score - {"   "}
                                <span>{row.walkscore}</span>
                                <Tooltip
                                  title={
                                    row.walkscore >= 90
                                      ? "Walker's Paradise, Daily errands do not require a car."
                                      : row.walkscore >= 70 &&
                                        row.walkscore < 90
                                      ? "Very Walkable, Most errands can be accomplished on foot."
                                      : row.walkscore >= 50 &&
                                        row.walkscore < 70
                                      ? "Somewhat Walkable, Some errands can be accomplished on foot."
                                      : row.walkscore >= 25 &&
                                        row.walkscore < 50
                                      ? "Car-Dependent, Most errands require a car."
                                      : "Car-Dependent, Almost all errands require a car."
                                  }
                                >
                                  <i
                                    className="fa-solid fa-info-circle"
                                    aria-hidden="true"
                                  ></i>
                                </Tooltip>
                              </p>
                              <p>
                                Bike Score - {"   "}{" "}
                                <span>{row.bikescore}</span>
                                <Tooltip
                                  title={
                                    row.bikescore >= 90
                                      ? "Biker's Paradise, Daily errands can be accomplished on a bike."
                                      : row.bikescore >= 70 &&
                                        row.bikescore < 90
                                      ? "Very Bikeable, Biking is convenient for most trips."
                                      : row.bikescore >= 50 &&
                                        row.bikescore < 70
                                      ? "Bikeable, Some bike infrastructure."
                                      : "Somewhat Bikeable, Minimal bike infrastructure."
                                  }
                                >
                                  <i
                                    className="fa-solid fa-info-circle"
                                    aria-hidden="true"
                                  ></i>{" "}
                                </Tooltip>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div className="">
              {/* {(localStorage.getItem("workAddress") == "" ||
                localStorage.getItem("workAddress") == undefined ||
                localStorage.getItem("workAddress") == "undefined") &&
              state.workAddress == "" ? (
                ""
              ) : state.cityListStopDataNot == true ? (
                ""
              ) : (
                <div className="loading"></div>
              )} */}
              <p>
                We are building your personalized city list. Please check back
                later.
              </p>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Citylist;
