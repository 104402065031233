import React from "react";

function ModalService(props) {
  const { state, onClose, saveEvent, sendPhoneSms, sendEmailSms } = props;
  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="width-60per pd-y-60">
          <div className="only_close">
            <span
              className="pointer close"
              data-dismiss="modal"
              onClick={onClose}
            >
              &times;
            </span>
          </div>
          <h6>
            {state.isConfirmed
              ? "Available slots details"
              : "Create Available slots"}{" "}
          </h6>

          <div className="modal-body">
            {state.succesMsg != "" && state.isConfirmed && (
              <h5 className="alert alert-success">{state.succesMsg}</h5>
            )}
            {state.isConfirmed && state.confirmError != "" ? (
              <div className="alert alert-danger" role="alert">
                {state.confirmError}
              </div>
            ) : null}
            <ul className="appoint_list">
              <li>
                <a href="javascript:void(0)">
                  {" "}
                  Appointment <span>Working</span>{" "}
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  {" "}
                  Date <span>{state.popupDate}</span>{" "}
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  {" "}
                  Time{" "}
                  <span>
                    {state.startTime} - {state.endTime}
                  </span>{" "}
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  {" "}
                  Day <span>{state.dayNameLabel[state.dayName]}</span>{" "}
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  {" "}
                  Total Hours <span>{state.diffTime} Hours</span>{" "}
                </a>
              </li>
            </ul>
          </div>
          {!state.isConfirmed && (
            <div className="text-center justify-content-center mb-4">
              <button
                className="btn theme-btn rounded-btn  mt-3"
                onClick={saveEvent}
              >
                Confirm
              </button>
            </div>
          )}
          {state.isConfirmed && (
            <div className="text-center justify-content-center mb-3">
              <button
                className="btn theme-btn rounded-btn me-1"
                onClick={sendPhoneSms}
              >
                Send to my phone
              </button>
              <button
                className="btn theme-btn rounded-btn"
                onClick={sendEmailSms}
              >
                Send to my email
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModalService;
